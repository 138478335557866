import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {SearchArticleFetch, RouteDeadlines, TrueDeadlines} from '@ActionsDeadlinesindex/Search';

import {HandleLevelsRoute} from '@/components/DeadLines/CreateDeadline/DeadlinesRoute/HandleLevelsRoute';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {CardComponent} from '@/components/DeadLines/CreateDeadline/DeadlinesRoute/CardComponent';
import {CreateDeadline} from '@CreateDeadline/CreateDeadline';
import {TreeBranch} from '@/components/General/CheckboxTree';
import {SaveBranch} from '@/components/General/CheckboxTree/interface';
import {getTreeComponent} from '@/components/General/BranchTree/Redux/Actions/getOrganizationsAction';
import {deadlinesID} from '@CreateDeadline/interfaces';
import {
    SearchElements,
    breadCrumbsElements,
} from '@components/DeadLines/DeadlinesIndex/redux/Interface';

export const DeadlineSearch = () => {
    const dispatch: AppDispatch = useDispatch();
    const [Search, setSearch] = useState('');

    const [checked, setChecked] = useState<string[]>([]);
    const [branchesId, setBranch] = useState<number[]>([]);
    const [levelsId, setLevel] = useState<number[]>([]);
    const [branchesData, setBranchData] = useState<SaveBranch[]>([]);

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const routes = useSelector((state: RootState) => state.deadLinesRoutes);
    const enterpriceName = useSelector(
        (state: RootState) =>
            state.getUsersValidation.userData?.enterpriceDetails?.[0]?.enterpriceName
    );
    const deadlineById: deadlinesID = useSelector((state: RootState) => {
        return state.getDeadlinedAll;
    });
    const TreeComponent = useSelector((state: RootState) => {
        return state.getOrganizationsTree.tree;
    });
    const Branch = branchesId[0];
    const handleSearch = () => {
        dispatch(SearchArticleFetch(token, Search, String(Branch)));
        setSearch('');
    };

    const SearchRoutes = (element: SearchElements) => {
        if (element) {
            const routeLast = element.data[0].breadCrumbs.map((item: breadCrumbsElements) => {
                return {
                    description: item.description,
                    id: item.id,
                    icon: item.urlIcon,
                    step: item.step,
                };
            });
            dispatch(RouteDeadlines(routeLast));
            HandleLevelsRoute(
                dispatch,
                token,
                routeLast[routeLast.length - 1]?.step + 1,
                routeLast[routeLast.length - 1]?.id,
                String(Branch)
            );
        }
    };

    useEffect(() => {
        if (routes.ItemsArticle?.data !== null) SearchRoutes(routes.ItemsArticle);
    }, [routes.ItemsArticle]);

    useEffect(() => {
        dispatch(getTreeComponent(token, String(companyId)));

        if (deadlineById.deadlineId !== null && (deadlineById.edit || deadlineById.copy))
            dispatch(TrueDeadlines());
    }, []);

    const titleDeadline = () => {
        if (deadlineById.edit) return 'Visualización';
        if (deadlineById.copy) return 'Duplicación';
        return 'Creación';
    };

    return (
        <ContainerFlex Align="left" Justify="left" Padding="1.25rem 0 1rem 0">
            <ContainerFlex
                Width="20%"
                FlexDir="column"
                Border="solid 1px #e5e7e9"
                Justify="start"
                Height="600px"
                Radius="4px"
                Align="start"
                OverFlow="auto"
                Margin="0 25px 0 0"
            >
                <ContainerFlex
                    Height="48px"
                    Padding="16px"
                    Bb="solid 1px #e5e7e9"
                    Justify="flex-start"
                >
                    <Text FontSize="0.875rem" Color="#414042">
                        Organizaciones
                    </Text>
                </ContainerFlex>
                {TreeComponent.data && TreeComponent.data.length && (
                    <TreeBranch
                        checked={checked}
                        branchesId={branchesId}
                        levelsId={levelsId}
                        branchesData={branchesData}
                        setChecked={setChecked}
                        setBranch={setBranch}
                        setLevel={setLevel}
                        setBranchData={setBranchData}
                        isEdit={false}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex Width="80%" Height="fit-content" FlexDir="column" Align="left">
                <Text FontWeight="400" FontSize="1rem" Color="#414042">
                    {titleDeadline()} de plazos para
                    <Text FontSize="1rem" FontWeight="600" Padding="0 0 0 0.5rem">
                        {enterpriceName ? enterpriceName : 'Mi empresa de empeño'}
                    </Text>
                </Text>
                {!routes.AddDinamics ? (
                    <>
                        {routes.RouteItemsPromotions.length === 0 && (
                            <Fragment>
                                <Text
                                    FontWeight="400"
                                    Color="#4A4A4A"
                                    Margin="1.5rem 0 0 0"
                                    FontSize="1rem"
                                >
                                    ¿A qué Artículo quieres crear un plazo?
                                </Text>
                                <Text
                                    required
                                    FontWeight="400"
                                    Color="#6D6E71"
                                    Margin="1.5rem 0 0 0"
                                    FontSize="0.75rem"
                                >
                                    Indica artículo, marca, modelo o características principales.
                                </Text>
                                <ContainerFlex Justify="space-between" Height="fit-content">
                                    <Input
                                        Outline="none"
                                        Padding="0 0 0 0.5rem"
                                        Margin="0 2rem 0 0"
                                        Width="33.813rem"
                                        type="text"
                                        value={Search}
                                        onChange={(e: {
                                            target: {value: React.SetStateAction<string>};
                                        }) => setSearch(e.target.value)}
                                        placeholder={`Ej. Celular Samsung`}
                                        autoFocus
                                        FontSize="0.875rem"
                                        maxLength={160}
                                    />
                                    <ButtonGenerals
                                        Transform="none"
                                        FontSize="0.875rem"
                                        Width="4.938rem"
                                        onClick={handleSearch}
                                        Cursor="pointer"
                                    >
                                        Buscar
                                    </ButtonGenerals>
                                </ContainerFlex>
                            </Fragment>
                        )}
                        <CardComponent Hiden={false} showLevelNumber={false} Branch={Branch} />
                    </>
                ) : (
                    <CreateDeadline Branch={branchesId} />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
