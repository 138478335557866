import {AxiosError, AxiosResponse} from 'axios';
import {GET_COLONY, GET_COLONY_ERROR, GET_COLONY_SUCCEED} from '@components/NewUser/Redux/Types';

const initialState = {
    colonies: null,
    loading: false,
    error: false,
};

const getColonyReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_COLONY:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_COLONY_SUCCEED:
            return {
                ...state,
                colonies: action.payload.data.data,
                loading: false,
            };
        case GET_COLONY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getColonyReducer;
