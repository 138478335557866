import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ClickAwayListener} from '@mui/material';

import {SearchInput} from '@/components/Customer/Styles';
import {REQUESTS_ARTICLE} from '@Quoter/Search/constants';
import {IconSvg} from '@/components/Quoter/styles';
import {IGroupInput} from '@Quoter/Search/interfaces';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IGroup} from '@Quoter/inferfaces';
import {MessageInactiveArticle} from '@Quoter/Search/messageInactiveArticle';
import * as style from '@/components/Quoter/stylesConstants';
import {FamilyRequest} from '@Quoter/Redux/Actions/PropiertiesArticleRequest';

export const GroupInput = ({
    register,
    setValue,
    errors,
    token,
    branchId,
    watch,
    reset,
    setIsOpcional,
    isOptional,
    requiredFields,
    setLabelValues,
    labelValues,
}: IGroupInput) => {
    const [viewList, setView] = useState(false);
    const [viewfield, setField] = useState(false);
    const [isInput, setisInput] = useState(false);

    const [provisionalValue, setProvisionalVlue] = useState('');

    const dispatch = useDispatch();
    const handleNew = (data: string) => {
        setProvisionalVlue(data);
    };
    const handleSearch = (data: IGroup) => {
        if (watch('Family') || watch('SubFamily') || watch('Brand') || watch('Article')) {
            reset({Family: '', SubFamily: '', Brand: '', Article: ''});
        }
        setValue('Group', String(data.groupId));
        dispatch(FamilyRequest(token, {branchId: branchId, groupId: data.groupId}));
        setView(false);
        setLabelValues({
            Group: data.nameGroup,
            Family: '',
            Brand: '',
            Article: '',
            SubFamily: '',
        });
        setIsOpcional(5);
    };
    const {group} = useSelector((state: RootState) => state.propertiesArticleRequest);
    return (
        <ContainerFlex
            Justify="start"
            Height="auto"
            Width="100%"
            FlexDir="column"
            Gap="4px"
            Align="start"
        >
            <Text FontSize="0.75rem" Color="#54575C" FontWeight="500">
                {REQUESTS_ARTICLE.FIRST_LEVEL}
                {requiredFields.Group && (
                    <Text color="#A1A5AA" FontSize="0.75rem" FontWeight="500">
                        {REQUESTS_ARTICLE.OPTIONAL}
                    </Text>
                )}
            </Text>
            <SearchInput
                Height="40px"
                GridColumn="95% 5%"
                FontSize="1rem"
                PlaceholderColor="#A1A5AA"
                Right=""
                WBoxShadow=""
            >
                {!requiredFields.Group && isInput ? (
                    <input
                        type="text"
                        autoComplete="off"
                        defaultValue={labelValues.Group}
                        {...register('Group')}
                        disabled={requiredFields.Group}
                    />
                ) : (
                    <Text
                        FontSize={labelValues.Group.length > 0 ? '1rem' : '0.875rem'}
                        Color={labelValues.Group.length > 0 ? '#54575C' : '#A1A5AA'}
                    >
                        {labelValues.Group.length > 0 ? labelValues.Group : 'Selecciona'}
                    </Text>
                )}
                {!requiredFields.Group && isInput ? null : (
                    <IconSvg
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            if (!requiredFields.Group) setView(!viewList);
                        }}
                    >
                        <path
                            d="M5.7002 9.70005L7.10019 8.30005L11.7002 12.9L16.3002 8.30005L17.7002 9.70005L11.7002 15.7L5.7002 9.70005Z"
                            fill="#0D166B"
                        />
                    </IconSvg>
                )}

                {viewList && (
                    <ClickAwayListener
                        onClickAway={() => {
                            setView(false);
                            setField(false);
                        }}
                    >
                        <ContainerFlex
                            Position="absolute"
                            Height=""
                            Width="100%"
                            PositionTop={'100%'}
                            BoxShadow=""
                            BoxS="0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
                            backG="#fff"
                            FlexDir="column"
                            OverFlow="auto"
                            Justify="start"
                            MaxH="13rem"
                            Radius="0.5rem"
                            ZIndex="2"
                        >
                            {!viewfield ? (
                                <ContainerFlex
                                    {...style.contentList}
                                    Justify="start"
                                    HBackground="#f0f0ff"
                                    HBorderLeft="2px solid #5a5aff"
                                    Cursor="pointer"
                                    onClick={() => setField(true)}
                                >
                                    <Text Color="#5a5aff" FontSize="0.875rem" Cursor="pointer">
                                        Nuevo nivel
                                    </Text>
                                    <IconSvg Width="24px" Height="24px" cursor="pointer">
                                        <path
                                            d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                                            fill="#5A5AFF"
                                        />
                                    </IconSvg>
                                </ContainerFlex>
                            ) : (
                                <ContainerFlex Padding="8px">
                                    <SearchInput
                                        Height="40px"
                                        GridColumn="95% 5%"
                                        FontSize="1rem"
                                        Right=""
                                        PlaceholderColor="#A1A5AA"
                                        WBoxShadow=""
                                    >
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e: {target: {value: string}}) => {
                                                handleNew(e.target.value);
                                            }}
                                        />
                                        <IconSvg
                                            Width="24px"
                                            Height="24px"
                                            Cursor="pointer"
                                            onClick={() => {
                                                setValue('Group', provisionalValue);
                                                setIsOpcional(1);
                                                setView(false);
                                                setField(false);
                                                setisInput(true);
                                            }}
                                        >
                                            <path
                                                d="M9.54961 18L3.84961 12.3L5.27461 10.875L9.54961 15.15L18.7246 5.97498L20.1496 7.39998L9.54961 18Z"
                                                fill="#5A5AFF"
                                            />
                                        </IconSvg>
                                    </SearchInput>
                                </ContainerFlex>
                            )}
                            {group &&
                                group.groupList.length > 0 &&
                                group.groupList.map((item: IGroup, index: number) =>
                                    item.groupId ? (
                                        <ContainerFlex
                                            key={index}
                                            {...style.contentList}
                                            onClick={() => handleSearch(item)}
                                            HBackground="#f0f0ff"
                                            HBorderLeft="2px solid #5a5aff"
                                        >
                                            <Text {...style.wordSearch}>{item.nameGroup}</Text>
                                        </ContainerFlex>
                                    ) : (
                                        index === 0 && <MessageInactiveArticle key={index} />
                                    )
                                )}
                        </ContainerFlex>
                    </ClickAwayListener>
                )}
            </SearchInput>

            {errors.Group?.message && (
                <Text Color="#FF6357" FontSize=".75rem" MarginTop="4px">
                    {errors.Group.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
