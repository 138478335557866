export const SEARCH_ALL_USERS = 'SEARCH_ALL_USERS';
export const SEARCH_ALL_USERS_SUCCESS = 'SEARCH_ALL_USERS_SUCCESS';
export const SEARCH_ALL_USERS_ERROR = 'SEARCH_ALL_USERS_ERROR';
export const SEARCH_ALL_USERS_STATUS_200 = 'SEARCH_ALL_USERS_STATUS_200';

export const GET_ALL_FORM = 'GET_ALL_FORM';
export const GET_ALL_FORM_ADDRESS = 'GET_ALL_FORM_ADDRESS';

export const DELETE_ALL_USERS = 'DELETE_ALL_USERS';
export const DELETE_ALL_USERS_SUCCESS = 'DELETE_ALL_USERS_SUCCESS';
export const DELETE_ALL_USERS_ERROR = 'DELETE_ALL_USERS_ERROR';

export const UPDATE_STATUS_ALL_USERS = 'UPDATE_STATUS_ALL_USERS';
export const UPDATE_STATUS_ALL_USERS_SUCCESS = 'UPDATE_STATUS_ALL_USERS_SUCCESS';
export const UPDATE_STATUS_ALL_USERS_ERROR = 'UPDATE_STATUS_ALL_USERS_ERROR';

export const GET_SCREEN = 'GET_SCREEN';

export const GET_INFO_USER = 'UPDATE_STATUS_ALL_USERS';
export const GET_INFO_USER_SUCCESS = 'UPDATE_STATUS_ALL_USERS_SUCCESS';
export const GET_INFO_USERS_ERROR = 'UPDATE_STATUS_ALL_USERS_ERROR';

export const UPDATE_INFO_USER = ' UPDATE_INFO_USER';
export const UPDATE_INFO_USER_SUCCESS = 'UPDATE_INFO_USER_SUCCESS';
export const UPDATE_INFO_USERS_ERROR = 'UPDATE_INFO_USERS_ERROR';

export const PUT_UPDATE_INFO_USER = ' UPDATE_INFO_USER';
export const PUT_UPDATE_INFO_USER_SUCCESS = 'UPDATE_INFO_USER_SUCCESS';
export const PUT_UPDATE_INFO_USERS_ERROR = 'UPDATE_INFO_USERS_ERROR';

export const ADRESS_USER = ' ADRESS_USE';
export const ADRESS_USER_SUCCESS = 'ADREES_USER_SUCCESS';
export const ADRESS_USERERROR = 'ADREES_USERERROR';
