import React, {useState} from 'react';
import {Modal} from '@components/CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text, TextEllipsis} from '@components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import closeIconGreen from '@components/Branches/Images/IconCloseModal.svg';
import ButtonGeneral from '@components/General/Atoms/Button';
import {IAddUser, IBranchs, ItemSearchSelect} from '@components/Branches/interfaces';
import {SearchInput} from '@/components/Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import closeMultiSelect from '@components/Branches/Images/CloseMultiSelect.svg';
import {useParams} from 'react-router-dom';
import {Highlight} from '@/components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';
import {GetAllUsers} from '@/components/CreateUsers/Redux/Actions/GetAllUsers';
import {CreateUserBranch} from '@components/Branches/ManageBranch/Redux/Actions/CreateUserBranch';
import {userList} from '@/components/CreateUsers/Interface';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const AddUserModal = ({addUserModal, setAddUserModal}: IAddUser) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const userLoad = useSelector((state: RootState) => {
        return state.CreateUserBranch?.loading;
    });
    const {idBranch} = useParams();
    const handleSearch = (e: string) => {
        setSearchName(e);
        if (e.length > 2) {
            const filtered = {
                searchName: e,
                orderType: 0,
                filterUser: 0,
                companyId: Number(companyId),
                pageSize: 20,
                pageNumber: 1,
            };
            dispatch(GetAllUsers(token, filtered));
        }
    };
    const {contact} = useSelector((state: RootState) => state.GetAllUsers);
    const [searchName, setSearchName] = useState('');
    const [filterSearch, setFilterSearch] = useState<ItemSearchSelect[]>([]);
    const dispatch = useDispatch();

    const SelectSearchNameValidation = (item: {userName: string; userId: number}) => {
        if (filterSearch.some((data: {userName: string; userId: number}) => data === item)) {
            setFilterSearch(
                filterSearch.filter((data: {userName: string; userId: number}) => data !== item)
            );
        } else {
            setFilterSearch([item, ...filterSearch]);
        }
    };
    const handleAssignmentUsers = () => {
        const jsondata = {
            branchId: Number(idBranch),
            usersId: filterSearch?.map((item: ItemSearchSelect) => item.userId),
        };
        dispatch(CreateUserBranch(jsondata, token, setAddUserModal, Number(idBranch)));
        setFilterSearch([]);
    };

    const gridMultiSelect = () => {
        if (filterSearch.length) {
            const GRID_SEARCH: {[key: number]: string} = {
                0: '90% 10%',
                1: '45% 45% 10%',
                2: '30% 30% 30% 10%',
                3: 'repeat(4,22.5%) 10%',
                4: 'repeat (5,18%) 10%',
            };
            return GRID_SEARCH[filterSearch.length];
        }
    };

    return (
        <Modal
            modalState={addUserModal ? true : false}
            changeModalState={() => {
                setAddUserModal(true);
            }}
            titleModalState={true}
            Height="35rem"
            Width="37.5rem"
            BoxShadow="none"
            BorderRadius="4px"
            background="none"
            Top="70px"
        >
            <ContainerFlex
                FlexDir="column"
                Align="center"
                BoxS="0 0 12px 0 rgba(0, 0, 0, 0.5)"
                Width="600px"
            >
                <ContainerFlex
                    Justify="space-between"
                    Height="3.5rem"
                    Bb="solid 1px #35cf44"
                    Padding="16.5px 24px"
                >
                    <Text Color="#35cf44" FontSize="20px">
                        Agregar usuario a sucursal
                    </Text>
                    <ImgLocation
                        Cursor="pointer"
                        src={closeIconGreen}
                        Width="24px"
                        Height="24px"
                        onClick={() => {
                            setAddUserModal(!addUserModal);
                        }}
                    />
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    Height="28.25rem"
                    Padding="0 24px"
                    FlexDir="column"
                >
                    <Text MarginTop="16px" FontSize="0.875rem" Color="#6d6e71">
                        Busca un usuario
                    </Text>
                    <ContainerFlex MarginTop="16px" Align="start" Justify="start" FlexDir="column">
                        <SearchInput
                            Width="552px"
                            Height="40px"
                            Position="none"
                            GridColumn={gridMultiSelect()}
                            BorderH="1px solid #35CF44"
                            BoxShadowH=""
                            BorderF="2px solid #35CF44"
                            BoxShadowF="0px 4px 4px rgba(53, 207, 68, 0.2)"
                            Padding="0 8px"
                        >
                            {filterSearch.length > 0 &&
                                filterSearch.map(
                                    (item: {userName: string; userId: number}, index: number) => (
                                        <ContainerFlex
                                            key={index}
                                            backG="#e5e7e9"
                                            Radius="50px"
                                            Width="auto"
                                            Height="25px"
                                            Justify="space-between"
                                            Padding="4.5px 8px"
                                        >
                                            <TextEllipsis
                                                Width="150px"
                                                FontSize=".875rem"
                                                FontWeight="400"
                                            >
                                                {item.userName}
                                            </TextEllipsis>
                                            <IconContainer
                                                Justify="flex-end"
                                                Width="auto"
                                                className="material-icons"
                                                Cursor="pointer"
                                                onClick={() => {
                                                    setSearchName('');
                                                    SelectSearchNameValidation(item);
                                                }}
                                            >
                                                close
                                            </IconContainer>
                                        </ContainerFlex>
                                    )
                                )}
                            <input
                                value={searchName}
                                placeholder="Buscar"
                                name="items"
                                onChange={(e: {target: {value: string}}) => {
                                    setSearchName(e.target.value);
                                    handleSearch(e.target.value);
                                }}
                            />
                            {searchName.length > 0 ? (
                                <ContainerFlex Justify="flex-end">
                                    <ImgLocation
                                        Width="16px"
                                        Height="16px"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSearchName('');
                                        }}
                                        src={closeMultiSelect}
                                    />
                                </ContainerFlex>
                            ) : (
                                <IconContainer
                                    Justify="flex-end"
                                    Width="100%"
                                    className="material-icons"
                                >
                                    search
                                </IconContainer>
                            )}
                        </SearchInput>
                        {searchName.length > 2 && (
                            <ContainerFlex
                                Width="552px"
                                Height="320px"
                                FlexDir="column"
                                backG="#fff"
                                Border="1px solid #F3F3F3"
                                Justify="start"
                                Align="start"
                                ZIndex="2"
                                Radius="4px"
                            >
                                <ContainerFlex
                                    Height={contact?.length < 5 ? 'auto' : '300px'}
                                    OverFlowY="scroll"
                                    FlexWrap="wrap"
                                >
                                    {contact
                                        ?.filter(
                                            (data: userList) =>
                                                !data.branchs
                                                    .map((branch: IBranchs) => branch.branchId)
                                                    .includes(Number(idBranch))
                                        )
                                        .map((item: userList, index: number) => (
                                            <ContainerFlex
                                                key={index}
                                                Justify="center"
                                                Align="start"
                                                Padding="0 16px"
                                                Height="56px"
                                                backG="#fff"
                                                BoxBackground="#f3f3f3"
                                                Cursor="pointer"
                                                FlexDir="column"
                                                onClick={() => {
                                                    SelectSearchNameValidation({
                                                        userName: item.userName,
                                                        userId: item.userId,
                                                    });
                                                }}
                                            >
                                                <Highlight
                                                    text={item.userName}
                                                    highlight={searchName}
                                                />
                                                <Text
                                                    FontSize="0.75rem"
                                                    Cursor="pointer"
                                                    Color="#a7a9ac"
                                                >
                                                    {item.totalRole}
                                                </Text>
                                            </ContainerFlex>
                                        ))}
                                </ContainerFlex>
                                {contact?.length > 4 && (
                                    <ContainerFlex
                                        Justify="start"
                                        Height="40px"
                                        backG="#fff"
                                        Padding="0 16px"
                                        Bt="1px solid #F3F3F3"
                                    >
                                        <Text wSpace="pre" FontSize="0.875rem" Color="#414042">
                                            {`${contact?.length - 5} ${
                                                contact?.length > 1 ? 'resultados' : 'resultado'
                                            } más de`}{' '}
                                            <Text
                                                FontSize="0.875rem"
                                                FontWeight="500"
                                                Color="#414042"
                                            >
                                                {'"'}
                                                {searchName}
                                                {'"'}
                                            </Text>
                                        </Text>
                                    </ContainerFlex>
                                )}
                                {contact?.length === 0 && (
                                    <ContainerFlex
                                        Justify="start"
                                        Height="40px"
                                        backG="#fff"
                                        Padding="0 16px"
                                        Bt="1px solid #F3F3F3"
                                    >
                                        <Text FontSize="0.875rem" Color="#414042">
                                            {'No se encontraron coincidencias.'}
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Padding="24px" Height="16%" Bt="1px solid #e5e7e9" Gap="20px">
                    <ButtonGeneral
                        width="93px"
                        text="Cancelar"
                        type="submit"
                        border="transparent"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        hColor="#35cf44"
                        transform=""
                        hBorder=""
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => {
                            setAddUserModal(false);
                            setSearchName('');
                            setFilterSearch([]);
                        }}
                        disabled={userLoad}
                        cursor={userLoad ? '' : 'pointer'}
                    />
                    <ButtonGeneral
                        width="77px"
                        text={userLoad ? <LoadingGeneralButtons /> : 'Añadir'}
                        type="submit"
                        hColor="#35cf44"
                        bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                        transform="capitalize"
                        hText="#35cf44"
                        hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                        clic={() => {
                            handleAssignmentUsers();
                        }}
                        disabled={userLoad}
                        cursor={userLoad ? '' : 'pointer'}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
