import React from 'react';

import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, ContainerGrid, Text} from '@Shopify/Ecommerce/styles';
import {ILocationsGroupsProps} from '@components/LocationsBranch/interfaces';
import {LOCATIONGROUP, MAX_GROUP_ROW} from '@components/LocationsBranch/constants';
import {LocationsGroupsItem} from '@components/LocationsBranch/LocationsGroupsItem';
import {LocationsGroupsAction} from '@components/LocationsBranch/LocationGroupAction';

export const LocationsGroupsList = ({
    showGroup,
    setShowLocationsModal,
    inheritType,
}: ILocationsGroupsProps) => {
    const companyName = useSelector(
        (state: RootState) =>
            state.getUsersValidation.userData?.enterpriceDetails?.[0]?.enterpriceName || ''
    );

    const {groupList} = useSelector((state: RootState) => ({
        groupList: state.getCompanyLevels.groupList,
    }));

    const isEmptyBranches = groupList && groupList[0].rows.length === 0;

    return (
        <>
            {showGroup && isEmptyBranches && (
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    backG="#FFFFFF"
                    Padding="0.5rem"
                    Radius="1rem"
                >
                    <ContainerFlex
                        Justify="start"
                        Align="start"
                        FlexDir="column"
                        Height="15rem"
                        OverFlowX="auto"
                    >
                        <LocationsGroupsAction
                            companyName={companyName}
                            setShowLocationsModal={setShowLocationsModal}
                            group={groupList[0]}
                        />
                        <ContainerFlex Padding="1.5rem">
                            <Text Color="#54575C">{LOCATIONGROUP.EMPTY_SELECTGROUP}</Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
            {!isEmptyBranches && groupList && (
                <ContainerGrid
                    GridColumns={`repeat(${
                        groupList.length > 1 ? 4 : 'auto-fit'
                    }, minmax(11.5rem, 1fr))`}
                    Justify="start"
                    Align="flex-start"
                    Gap="0.5rem"
                >
                    {groupList.map((group, index) => (
                        <ContainerFlex
                            key={index}
                            Justify="start"
                            Align="start"
                            backG="#FFFFFF"
                            Radius="1rem"
                            FlexWrap="wrap"
                        >
                            <ContainerFlex
                                FlexDir="column"
                                OverFlowX="auto"
                                Justify="start"
                                Height="15.125rem"
                            >
                                <LocationsGroupsAction
                                    companyName={group.name}
                                    setShowLocationsModal={setShowLocationsModal}
                                    group={group}
                                    index={index}
                                />
                                {group.rows.map((row, i) => (
                                    <>
                                        <LocationsGroupsItem
                                            key={`${row.typeLocation}-${i}`}
                                            selectedGroup={row}
                                            internalId={group.internalId}
                                            inheritType={inheritType}
                                        />
                                    </>
                                ))}
                            </ContainerFlex>
                        </ContainerFlex>
                    ))}
                    {groupList.length > 1 &&
                        groupList.length % MAX_GROUP_ROW !== 0 &&
                        Array.from({
                            length: MAX_GROUP_ROW - (groupList.length % MAX_GROUP_ROW),
                        }).map((_, i) => (
                            <ContainerFlex
                                key={i}
                                Justify="start"
                                Align="start"
                                backG="#F0F0FF"
                                Radius="1rem"
                                FlexWrap="wrap"
                                Height="15.125rem"
                            />
                        ))}
                </ContainerGrid>
            )}
        </>
    );
};
