import React, {useEffect} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import globalQuoterBanner from '@images/HeaderPublicNow.png';
import Add from '@images/Right.svg';
import {TITLES} from '@components/HomePublicClient/constants';
import {useNavigate} from 'react-router-dom';
import PicA from '@/images/Pic1.png';
import PicB from '@/images/Pic2.png';
import PicC from '@/images/pic3.png';
import {useDispatch} from 'react-redux';
import {GetRouter} from '@components/AdminNavbar/Redux/Actions/loginTypeRoute';

const HomePublic = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(GetRouter('/login/MarketUser'));
    }, []);
    return (
        <ContainerFlex FlexDir="column" Gap="24px" Position="relative">
            <ContainerFlex Justify="start" Align="start" Height="467px" FlexDir="column">
                <Image src={globalQuoterBanner} alt="header" Width="100%" />
                <ContainerFlex
                    Padding="16px"
                    ColumnGap="30px"
                    Align="end"
                    MarginTop="-32px"
                    Height="222px"
                >
                    <ContainerFlex
                        Radius="16px"
                        Height="222px"
                        Justify="start"
                        Align="start"
                        backG={`url(${PicA}) no-repeat`}
                        Width="30%"
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text
                            FontSize="1.75rem"
                            Color="#0D166B"
                            FontWeight="700"
                            wSpace=""
                            Width="5rem"
                        >
                            {TITLES.cards_one.question}
                        </Text>
                        <Text Color="#0D166B" FontWeight="700" wSpace="" Width="10rem">
                            {TITLES.cards_one.description}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            ColumnGap="8px"
                            Height="40px"
                            Width="9.875rem"
                            backG="#FFFFFF"
                            Cursor="pointer"
                            onClick={() => navigate('/login/MarketUser')}
                        >
                            {' '}
                            <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                                {TITLES.cards_one.cta}
                            </Text>
                            <Image src={Add} alt="arrow" Cursor="pointer" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex
                        Radius="16px"
                        Height="222px"
                        Justify="start"
                        Align="start"
                        Width="30%"
                        backG={`url(${PicB}) no-repeat`}
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text FontSize="1.75rem" Color="#0D166B" FontWeight="700" Width="13rem">
                            {TITLES.cards_two.question}
                        </Text>
                        <Text Color="#0D166B" FontWeight="700" wSpace="" Width="13rem">
                            {TITLES.cards_two.description}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            ColumnGap="8px"
                            Height="40px"
                            Width="9.875rem"
                            backG="#FFFFFF"
                            Cursor="pointer"
                            onClick={() => navigate('/login/MarketUser')}
                        >
                            {' '}
                            <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                                {TITLES.cards_two.cta}
                            </Text>
                            <Image src={Add} alt="arrow" Cursor="pointer" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex
                        Radius="16px"
                        Height="222px"
                        Justify="start"
                        Align="start"
                        backG={`url(${PicC}) no-repeat`}
                        Width="30%"
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text FontSize="1.75rem" Color="#0D166B" FontWeight="700" Width="13rem">
                            {TITLES.cards_three.question}
                        </Text>
                        <Text Color="#0D166B" FontWeight="700" wSpace="" Width="13rem">
                            {TITLES.cards_three.description}
                        </Text>
                        <ContainerFlex
                            Radius="32px"
                            Border="1px solid #5A5AFF"
                            ColumnGap="8px"
                            Height="40px"
                            Width="9.875rem"
                            backG="#FFFFFF"
                            Cursor="pointer"
                            onClick={() => navigate('/login/MarketUser')}
                        >
                            {' '}
                            <Text Color="#5A5AFF" FontWeight="700" wSpace="" Cursor="pointer">
                                {TITLES.cards_three.cta}
                            </Text>
                            <Image src={Add} alt="arrow" Cursor="pointer" />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default HomePublic;
