import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ABOUT_EMPLOYMENT_ERROR,
    ABOUT_EMPLOYMENT_START,
    ABOUT_EMPLOYMENT_SUCCESS,
    RESET_ABOUT_EMPLOYMENT_DATA,
} from '@components/PersonalLoans/Redux/Types/Types';
import {JobLoansPostProps} from '@components/PersonalLoans/interfaces';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';

export const fetchDataEmploymentStart = () => {
    return {
        type: ABOUT_EMPLOYMENT_START,
    };
};
export const fetchDataEmploymentSuccess = (result: AxiosResponse) => {
    return {
        type: ABOUT_EMPLOYMENT_SUCCESS,
        payload: result,
    };
};
export const fetchDataEmploymentError = (error: AxiosError) => {
    return {
        type: ABOUT_EMPLOYMENT_ERROR,
        error: error,
    };
};

export function AddEmploymentSituationForm(data: JobLoansPostProps, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataEmploymentStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddEmploymentSituation}
            `,
                data,
                {headers}
            );
            dispatch(fetchDataEmploymentSuccess(response));
            dispatch(ActualDataStepLoans(3));
        } catch (error) {
            dispatch(fetchDataEmploymentError(error as AxiosError));
        }
    };
}

export const ResetEmploymentSituation = () => {
    return {
        type: RESET_ABOUT_EMPLOYMENT_DATA,
    };
};
