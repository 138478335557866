import React from 'react';
import {Controller} from 'react-hook-form';

import {IPersonalDataNationRfcCurpInfoProps} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

const PersonalDataNationRfcCurpInfo = (props: IPersonalDataNationRfcCurpInfoProps) => {
    const {
        errors,
        control,
        setValue,
        onTypeChange,
        onIdNumberChange,
        documentsInfo,
        identificationOptions,
    } = props;
    return (
        <ContainerFlex Justify="space-between" Gap="1rem" Align="flex-start">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.TIPO_DE_IDENTIFICACION}</Text>
                <Controller
                    name="catIdentificationTypeId"
                    control={control}
                    render={() => (
                        <SelectGeneral
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!errors.catIdentificationTypeId?.message}
                            options={identificationOptions}
                            onChange={(selectedOption) => {
                                setValue('catIdentificationTypeId', String(selectedOption?.value));
                                onTypeChange(String(selectedOption?.value));
                            }}
                            value={identificationOptions.find(
                                (option) =>
                                    option.value === Number(documentsInfo?.catIdentificationTypeId)
                            )}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.catIdentificationTypeId?.message &&
                        String(errors.catIdentificationTypeId?.message)}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.NUMERO_DE_IDENTIFICACION}</Text>
                <Controller
                    name="identificationNumber"
                    control={control}
                    render={() => (
                        <Input
                            onChange={(e) => {
                                setValue('identificationNumber', e.target.value);
                                onIdNumberChange(e.target.value);
                            }}
                            error={!!errors.identificationNumber?.message}
                            value={documentsInfo?.identificationNumber}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.identificationNumber?.message &&
                        String(errors.identificationNumber?.message)}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalDataNationRfcCurpInfo;
