import axios from 'axios';
import {
    SEARCH_LEVEL_TREE_REQUEST,
    SEARCH_LEVEL_TREE_SOUCCES,
    SEARCH_LEVEL_TREE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

import process from 'process';

import {URL} from '@config/constants/index';

export const SearchBranchLevelResquest = () => {
    return {
        type: SEARCH_LEVEL_TREE_REQUEST,
    };
};
export const SearchBranchLevelSuccess = (userData) => {
    return {
        type: SEARCH_LEVEL_TREE_SOUCCES,
        payload: userData,
    };
};

export const SearchBranchLevelFailure = (err) => {
    return {
        type: SEARCH_LEVEL_TREE_ERROR,
        payload: err,
    };
};
export const SearchBranchLevel = (token, companyId, search) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(SearchBranchLevelResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetSearchLevelsAndBranchUser}`,
                {
                    headers,
                    params: {companyId, search},
                }
            );
            dispatch(SearchBranchLevelSuccess(response.data));
        } catch (error) {
            dispatch(SearchBranchLevelFailure(error));
        }
    };
};
