import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import Hand from '@ListClient/FingerprintRegistration/Hand';
import {IFingerprintsStep, FingersPositions, FingerClickEvent} from '@ListClient/interfaces';
import {HANDS} from '@ListClient/interfaces';
import LeftHandImage from '@images/left-hand.svg';
import RightHandImage from '@images/right-hand.svg';
import {FingersOnHand, FINGERPRINT_SELECTION} from '@ListClient/constants';
import FingerPrintSelector from '@ListClient/FingerprintRegistration/FingerPrintSelector';
import {getFingers} from '@ListClient/redux/Actions/getHandsFingers';

const leftHandFingers: FingersPositions[] = [
    {id: FingersOnHand.pinkie, top: '-1%', left: '-21%'},
    {id: FingersOnHand.ring, top: '-16%', left: '4%'},
    {id: FingersOnHand.middle, top: '-20%', left: '33%'},
    {id: FingersOnHand.index, top: '-13%', left: '61%'},
    {id: FingersOnHand.thumb, top: '25%', left: '84%'},
];

const rightHandFingers: FingersPositions[] = [
    {id: FingersOnHand.pinkie, top: '-1%', right: '-96%'},
    {id: FingersOnHand.ring, top: '-14%', right: '-69%'},
    {id: FingersOnHand.middle, top: '-20%', right: '-40%'},
    {id: FingersOnHand.index, top: '-13%', right: '-10%'},
    {id: FingersOnHand.thumb, top: '25%', right: '8%'},
];

const FingerprintRegistration = (props: IFingerprintsStep) => {
    const {token, errors, setValue, inPopup = false, clientEditId} = props;
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) dispatch(getFingers(token));
    }, [token]);

    const [selectedFinger, setSelectedFinger] = useState<FingerClickEvent>({
        finger: FingersOnHand.pinkie,
        hand: HANDS.LEFT,
    });

    const handleFingerClick = (event: FingerClickEvent) => {
        setSelectedFinger(event);
    };

    return (
        <ContainerFlex FlexDir="column" Align="flex-start" Gap="1rem">
            {!inPopup && (
                <Text
                    FontWeight="500"
                    FontSize="1.25rem"
                    Color="#2A2C2F"
                    LHeight="1.25rem"
                    Padding="1.5rem 1.5rem 0.5rem"
                    Self="center"
                >
                    {FINGERPRINT_SELECTION.HOW_TO_PROMPT}
                </Text>
            )}
            <ContainerFlex
                Width={!inPopup ? '75%' : '100%'}
                Padding="1.25rem 1.875rem"
                FlexDir="row"
                Justify="center"
                Align="center"
                Display="grid"
                GridColumns="repeat(auto-fit, minmax(370px, 1fr))"
                Gap="1.063rem"
                backG="#F0F0FF"
                Radius="0.625rem"
                Self="center"
                MaxW="53.438rem"
            >
                <ContainerFlex
                    Padding="1rem"
                    FlexDir="column"
                    Justify="center"
                    Align="center"
                    Gap="2.625rem"
                    backG="#FFF"
                    Radius="1.5rem"
                    Border="1px solid #5A5AFF"
                >
                    <Text FontWeight="400" FontSize="1rem" Color="#000" LHeight="1.5rem">
                        {FINGERPRINT_SELECTION.SELECT_FINGER}
                    </Text>
                    <ContainerFlex
                        Padding="0"
                        FlexDir="row"
                        Justify="center"
                        Align="center"
                        Gap="1rem"
                    >
                        <Hand
                            label={FINGERPRINT_SELECTION.LEFT_HAND}
                            hand={HANDS.LEFT}
                            imageSrc={LeftHandImage}
                            fingers={leftHandFingers}
                            onFingerClick={handleFingerClick}
                            selectedFinger={selectedFinger}
                        />
                        <Hand
                            label={FINGERPRINT_SELECTION.RIGHT_HAND}
                            hand={HANDS.RIGHT}
                            imageSrc={RightHandImage}
                            fingers={rightHandFingers}
                            onFingerClick={handleFingerClick}
                            selectedFinger={selectedFinger}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <FingerPrintSelector
                    selectedFinger={selectedFinger}
                    errors={errors}
                    setValue={setValue}
                    clientEditId={clientEditId}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default FingerprintRegistration;
