import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    DATA_FORM,
    InputStyle,
    TextInput,
    Grids,
    GENERAL_TITLES,
    TextInputEdit,
    GENDERS,
} from '@components/Users/UserProfile/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {
    IPersonalInfo,
    PersonalInfoFormProps,
    PhoneFieldName,
    PhoneInputProps,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import GeneralsInput from '@/components/Users/UserProfile/GeneralsInput';
import {usePersonalInfoForm} from '@/components/Users/UserProfile/UserProfileBranchAssignment/UserPersonalForm';
import {DateTime} from 'luxon';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';

const PersonalInfoForm: React.FC<PersonalInfoFormProps> = ({isEdit, dataPersonal, formMethods}) => {
    const {
        register,
        control,
        formState: {errors},
    } = formMethods;

    const today: Date = DateTime.now().toJSDate();
    const [date] = useState<Date | null>(today);

    const {formatPhoneNumber, formatDate, isEmpty, handlePhoneChange, getGenderOptions} =
        usePersonalInfoForm();

    const PhoneInput: React.FC<PhoneInputProps> = ({
        field,
        error = false,
        hasError,
        helperText,
    }) => (
        <GeneralsInput
            {...field}
            error={error}
            hasError={hasError || error}
            helperText={helperText}
            value={formatPhoneNumber(field.value || '')}
            onChange={(e) => handlePhoneChange(e, field.onChange)}
        />
    );
    const genderOptions = getGenderOptions(GENDERS);
    return (
        <ContainerFlex {...Grids}>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Gap="4px" Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.NAMES}</Text>
                </ContainerFlex>
                {isEdit ? (
                    <GeneralsInput
                        maxLength={50}
                        hasError={!!errors.name}
                        helperText={errors.name?.message}
                        error={!!errors.name?.message}
                        {...register('name')}
                    />
                ) : (
                    isEmpty(dataPersonal?.name)
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Gap="4px" Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.LAST_NAME}</Text>
                </ContainerFlex>

                {isEdit ? (
                    <GeneralsInput
                        maxLength={50}
                        helperText={errors.lastName?.message}
                        hasError={!!errors.lastName}
                        error={!!errors.lastName?.message}
                        {...register('lastName')}
                    />
                ) : (
                    isEmpty(dataPersonal?.lastName)
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Gap="4px" Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.OTHER_LAST_NAME}</Text>
                </ContainerFlex>

                {isEdit ? (
                    <GeneralsInput
                        maxLength={50}
                        helperText={errors.otherLastName?.message}
                        hasError={!!errors.otherLastName}
                        error={!!errors.otherLastName?.message}
                        {...register('otherLastName')}
                    />
                ) : (
                    isEmpty(dataPersonal?.motherLastName)
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.DATE}</Text>
                </ContainerFlex>

                {isEdit ? (
                    <ContainerFlex {...InputStyle} Justify="start" Align="start">
                        {isEdit ? (
                            <Controller
                                name="date"
                                control={control}
                                render={({field: {onChange, ref, value, ...field}}) => (
                                    <>
                                        <CalendarDateSelection
                                            {...field}
                                            placeholder=" /  /"
                                            format="dd/MM/yyyy"
                                            error={!!errors.date}
                                            value={date}
                                            limitDate={true}
                                            onSelect={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                        {errors.date && (
                                            <Text Color="#ef4f55" FontSize="0.75rem">
                                                {errors.date.message}
                                            </Text>
                                        )}
                                    </>
                                )}
                            />
                        ) : (
                            <Text {...TextInputEdit}>
                                {formatDate(dataPersonal?.birthdate?.split('T')[0])}
                            </Text>
                        )}
                    </ContainerFlex>
                ) : (
                    <Text {...TextInputEdit}>
                        {formatDate(dataPersonal?.birthdate?.split('T')[0])}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.GENDER}</Text>
                {isEdit ? (
                    <Controller
                        name="gender"
                        control={control}
                        render={({field}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                isSearchable={false}
                                hasError={!!errors.gender?.message}
                                options={genderOptions}
                                value={
                                    genderOptions.find(
                                        (option) => option.value === field.value?.label
                                    ) || null
                                }
                                onChange={(selectedOption) =>
                                    field.onChange({label: selectedOption?.label || ''})
                                }
                            />
                        )}
                    />
                ) : (
                    isEmpty(dataPersonal?.gender || '')
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.EMAIL}</Text>
                {isEdit ? (
                    <GeneralsInput
                        helperText={errors.email?.message}
                        hasError={!!errors.email}
                        error={!!errors.email?.message}
                        {...register('email')}
                    />
                ) : (
                    isEmpty(dataPersonal?.accessCredentials?.email)
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Gap="4px" Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.OTHER_EMAIL}</Text>
                </ContainerFlex>

                {isEdit ? (
                    <GeneralsInput
                        helperText={errors.otherEmail?.message}
                        hasError={!!errors.otherEmail}
                        {...register('otherEmail')}
                    />
                ) : (
                    isEmpty(dataPersonal?.accessCredentials?.alternateEmail)
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Gap="4px" Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.TEL}</Text>
                </ContainerFlex>
                {isEdit ? (
                    <Controller<IPersonalInfo, PhoneFieldName>
                        name="cel"
                        control={control}
                        render={({field}) => (
                            <PhoneInput
                                error={!!errors.cel?.message}
                                helperText={errors.cel?.message}
                                field={field}
                            />
                        )}
                    />
                ) : (
                    isEmpty(formatPhoneNumber(dataPersonal?.mainPhoneNumber || ''))
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <ContainerFlex Gap="4px" Justify="start" Height="max-content">
                    <Text {...TextInput}>{DATA_FORM.OTHER_TEL}</Text>
                    <Text FontSize="14px" FontWeight="700">
                        {GENERAL_TITLES.OPTION}
                    </Text>
                </ContainerFlex>

                {isEdit ? (
                    <Controller<IPersonalInfo, PhoneFieldName>
                        name="otherCelphone"
                        control={control}
                        render={({field}) => <PhoneInput field={field} />}
                    />
                ) : (
                    isEmpty(formatPhoneNumber(dataPersonal?.secondaryPhoneNumber || ''))
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalInfoForm;
