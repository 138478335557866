import React from 'react';
import {ContainerFlex, ContainerGrid, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import downloadFocused from '@/images/download_focused.svg';
import {
    CREDIT_DETAIL_TABLES,
    CREDIT_DETAIL_FIELD_STYLE_MAP,
    FORMAT_DATE,
} from '@/components/CreditsAndPawns/constants';
import {ICreditDetailTableRowProps} from '@/components/CreditsAndPawns/interfaces';
import {formatDate, numberToCurrency} from '@/hooks/currentFormatUtils';
import {DateTime} from 'luxon';

const CreditDetailTableRow = ({
    data,
    isFeesTable,
}: {
    data: ICreditDetailTableRowProps;
    isFeesTable: boolean;
}) => {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?$/;
    const {quotaStatus, cardTypeUrl} = data;
    const isPaid = quotaStatus ? quotaStatus === CREDIT_DETAIL_TABLES.FILTER.PAID : false;
    const fields = Object.entries({
        ...data,
        ...(isFeesTable
            ? {
                  [CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.VOUCHER_CTA]: isPaid
                      ? CREDIT_DETAIL_TABLES.FEE_TABLE.VOUCHER_CTA
                      : '',
              }
            : {}),
    }).filter(([key]) => key !== 'cardTypeUrl');

    const checkValue = (value: string | null | number, key: string): string => {
        const actions = new Map<string, (value: string | null | number) => string>([
            [
                CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.PAYMENT_DATE,
                (val) => (val ? String(val) : '-'),
            ],
            [
                CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.PAYMENT_METHOD,
                (val) => (val ? String(val).slice(-8) : ''),
            ],
            [
                CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.PAY,
                (val) => numberToCurrency(Number(val)),
            ],
        ]);

        if (value === null) {
            const action = actions.get(key);
            return action ? action(value) : '';
        }

        const newValue = isoDatePattern.test(String(value))
            ? formatDate(DateTime.fromISO(String(value)), FORMAT_DATE)
            : String(value);

        const action = actions.get(key);
        return action ? action(newValue) : newValue;
    };

    return (
        <ContainerGrid
            Padding="8px 16px"
            Height="40px"
            MinH="40px"
            Width="100%"
            GridColumns={`repeat(${fields.length}, 1fr)`}
            Justify="space-between"
            Align="center"
            BorderBt="1px solid #E8E9EA"
        >
            {fields.map(([key, value], index) => {
                const styleMapField =
                    CREDIT_DETAIL_FIELD_STYLE_MAP.get(
                        key.toLocaleLowerCase() ===
                            CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.STATUS.toLocaleLowerCase()
                            ? String(value).toLocaleLowerCase()
                            : key
                    ) ??
                    CREDIT_DETAIL_FIELD_STYLE_MAP.get(
                        CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.DEFAULT
                    );
                const newValue = checkValue(value, key);
                return (
                    <ContainerFlex
                        key={index}
                        Height="100%"
                        Width="100%"
                        Align="center"
                        Justify="center"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight={styleMapField?.fontWeight}
                            FontSize={styleMapField?.fontSize}
                            BorderRadius={styleMapField?.radius}
                            Color={styleMapField?.color}
                            bGround={styleMapField?.background}
                            Border={styleMapField?.borderColor}
                            Padding={styleMapField?.padding}
                            Cursor={styleMapField?.cursor}
                            Width={styleMapField?.width}
                            Height={styleMapField?.height}
                            Align="center"
                            Justify="center"
                            TextAlign="center"
                            Gap={styleMapField?.gap}
                            required={false}
                        >
                            {key === CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.PAYMENT_METHOD &&
                                newValue &&
                                cardTypeUrl && (
                                    <Image
                                        Width="24px"
                                        Height="16px"
                                        src={cardTypeUrl}
                                        alt={CREDIT_DETAIL_TABLES.ALTS.CREDIT_CARD}
                                    />
                                )}
                            {key === CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.VOUCHER_CTA &&
                                isPaid && (
                                    <Image
                                        Width="24px"
                                        Height="16px"
                                        src={downloadFocused}
                                        alt={CREDIT_DETAIL_TABLES.ALTS.DOWNLOAD_ICON}
                                        Cursor={styleMapField?.cursor}
                                    />
                                )}
                            {newValue}
                        </Text>
                    </ContainerFlex>
                );
            })}
        </ContainerGrid>
    );
};

export default CreditDetailTableRow;
