import {AppDispatch} from '@/config/store';
import axios, {AxiosError} from 'axios';

import {URL} from '@config/constants/index';
import {
    GET_HOLIDAYS_START,
    GET_HOLIDAYS_SUCCESS,
    GET_HOLIDAYS_ERROR,
    ADD_HOLIDAY_SUCCESS,
} from '@components/LocationsBranch/Redux/types';
import StringUtils from '@/GenericScripts/utils';
import {IHolidays} from '@components/LocationsBranch/interfaces';

export const holidayStart = () => ({
    type: GET_HOLIDAYS_START,
});

export const holidaySuccess = (result: {data: IHolidays[]}) => ({
    type: GET_HOLIDAYS_SUCCESS,
    holidays: result.data,
});

export const holidayError = (error: AxiosError) => ({
    type: GET_HOLIDAYS_ERROR,
    error: error,
});

export const getHolidays = (token: string, LevelId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({LevelId: LevelId});
    return async (dispatch: AppDispatch) => {
        dispatch(holidayStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetHolidaysByLevelId}${queryString}`,
                {headers}
            );
            dispatch(holidaySuccess(response.data));
        } catch (error) {
            dispatch(holidayError(error as AxiosError));
        }
    };
};

export const addHoliday = (newHoliday: IHolidays[]) => ({
    type: ADD_HOLIDAY_SUCCESS,
    newHoliday: newHoliday,
});
