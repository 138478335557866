import successIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/successIcon.svg';
import alertIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/alertIcon.svg';
import infoIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/infoIcon.svg';
import {CreditStatus} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {ICreditStatus, IStyleSetup} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {routers} from '@/appRoute';

export const MODALCART = {
    CART: 'Carrito',
    CLOSE_MODAL: 'x',
    PAY: 'Pago de Cuota',
    LOAN: 'Préstamo',
    SHOW_CAR: 'Ver carrito',
};
export const ENABLEDCREDTIS = {
    PRODUCT: 'Productos',
    UNSELECT: 'Deseleccionar productos',
    LOAN: 'Préstamo',
    PAYMENT: 'Pago de cuota',
    NUMBERPAY: 'No. de cuota:',
    DELETE: 'Eliminar',
};
export const RESUMECREDITS = {
    TITLE: 'Resumen de compra',
    PAY: 'Pago cuota ',
    TOTAL: 'Subtotal',
    BUTTON: 'Comenzar proceso de compra',
};

export const CREDIT_DETAILS_HEADER = {
    CTA_BACK_TO: 'Volver a Mis Créditos',
};
export const CREDIT_DETAILS_PAYMENT = {
    SUB_TITLE: 'Método de pago',
    PARAGRAPH:
        'Agregando un método de pago podrás domiciliar tu pago y evitar asi cobros extra por mora.',
    CTA_ADD_PAYMENT_METHOD: 'Agregar método de pago',
};
export const CREDIT_DETAILS_INFORMATION = {
    STATUS: {
        SUB_TITLE: 'Crédito personal',
        CTA_ADD_TO_CART: 'Pagar cuota',
        CTA_PAYMENT: 'Agregar al carrito',
        CTA_APPLY_FOR_LOAN: 'Solicitar un préstamo',
        CTA_REJECT_LOAN: 'Rechazar préstamo',
        CTA_ACCEPT_LOAN: 'Aceptar préstamo',
        FEES: 'Cuotas',
        OWE: 'Lo que debo',
        PAID: 'Pagado',
        CTA_VISIT_STORE: 'Visitar la tienda oficial',
    },
    LOAN: {
        SUB_TITLE: 'Detalle del préstamo',
        REQUESTED_QUANTITY: 'Cantidad solicitada',
        APPLICATION_DATE: 'Fecha de solicitud',
        STATE: 'Estado',
        REQUESTED_IN: 'Solicitado en',
        APPLICATION_FORM: 'Forma de solicitud',
        FEES: 'Cuotas',
        FEES_AMOUNT: 'Valor de cuota',
        PAYMENT_FREQUENCY: 'Frecuencia de pago',
        CTA_TEXT: 'Descargar contrato',
    },
    TERMS: {},
};

export const CREDIT_CART_SHOPPING = {
    TITLE: 'Carrito de compra',
    MESSAGE: {
        TITLE: 'No hay productos seleccionados',
        MESSAGE: 'Selecciona un producto para poner proceder con la pasarela de pago.',
    },
};

export const STATUS_CODE = 200;

export const ALTS = {
    DOWNLOAD_BUTTON: 'icono de descarga de archivos',
    ARROW_LEFT: 'icono de flecha a la izquierda',
    ADD_SHOPPING_CART: 'icono para agrear articulo al carro de compras',
    LOANS_ICON: 'logo de compania',
    WARNING_ICON: 'icono de alerta amarillo',
    CARD_ICON: 'icono de tarjeta de credito',
    CALENDER_ICON: 'icono de calendario',
    DELETE_ICON: 'icono de eliminacion',
};

export const MODAL_REJECT_LOAN = {
    TITLE: '¿Está seguro de rechazar este préstamo?',
    MESSAGE: 'Si rechaza la oferta, no podrá acceder a estas condiciones nuevamente.',
    CTA_CANCEL: 'Cancelar',
    CTA_REJECT: 'Rechazar préstamo',
};

export const STYLE_SETUP_MAP: Map<CreditStatus, IStyleSetup> = new Map([
    [
        'approved',
        {
            backgroundMessage: '#E4F7EC',
            colorTitle: '#174A2E',
            colorParagraph: '#2A2C2F',
            icon: successIcon,
            title: 'Solicitud de préstamo aprobada',
            message:
                'La solicitud de préstamo ha sido aprobado. Ahora debes aceptar o rechazar el préstamo',
            alt: 'icono de aprobacion',
        },
    ],
    [
        'under_review',
        {
            backgroundMessage: '#DBF4FF',
            colorTitle: '#004461 ',
            colorParagraph: '#2A2C2F',
            icon: infoIcon,
            title: 'Solicitud de préstamo en revisión',
            message:
                'La solicitud de préstamo esta siendo revisada, te avisaremos a tu correo electrónico cuando tengamos una respuesta. ',
            alt: 'icono de informacion azul',
        },
    ],
    [
        'rejected',
        {
            backgroundMessage: '#FFF5DB',
            colorTitle: '#614500',
            colorParagraph: '#2A2C2F',
            icon: alertIcon,
            title: 'Préstamo Rechazado',
            message: 'La solicitud de préstamo ha sido rechazada.',
            alt: 'icono de alerta amarillo',
        },
    ],
]);

export const CREDIT_STATUS = {
    UP_TO_DATE: 'up_to_date' as CreditStatus,
    LATE: 'late' as CreditStatus,
    LIQUIDATED: 'liquidated' as CreditStatus,
    REJECTED: 'rejected' as CreditStatus,
    UNDER_REVIEW: 'under_review' as CreditStatus,
    APPROVED: 'approved' as CreditStatus,
};

export const CREDIT_STATUS_MAP: Map<number, ICreditStatus> = new Map([
    [
        1,
        {
            creditStatusId: 1,
            creditStatus: CREDIT_STATUS.UP_TO_DATE,
            creditUrlNavigation: routers.CreditDetails,
            hasButtons: true,
            cta_primary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_ADD_TO_CART,
            },
            cta_secondary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_PAYMENT,
            },
        },
    ],
    [
        2,
        {
            creditStatusId: 2,
            creditStatus: CREDIT_STATUS.LATE,
            creditUrlNavigation: routers.CreditDetails,
            hasButtons: true,
            cta_primary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_ADD_TO_CART,
            },
            cta_secondary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_PAYMENT,
            },
        },
    ],
    [
        3,
        {
            creditStatusId: 3,
            creditStatus: CREDIT_STATUS.LIQUIDATED,
            creditUrlNavigation: routers.CreditDetails,
            hasButtons: false,
        },
    ],
    [
        4,
        {
            creditStatusId: 4,
            creditStatus: CREDIT_STATUS.REJECTED,
            creditUrlNavigation: routers.CreditDetailsRejected,
            hasButtons: true,
            cta_primary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_APPLY_FOR_LOAN,
            },
        },
    ],
    [
        5,
        {
            creditStatusId: 5,
            creditStatus: CREDIT_STATUS.UNDER_REVIEW,
            creditUrlNavigation: routers.CreditDetailsUnderReview,
            hasButtons: false,
        },
    ],
    [
        6,
        {
            creditStatusId: 6,
            creditStatus: CREDIT_STATUS.APPROVED,
            creditUrlNavigation: routers.CreditDetailsApproved,
            hasButtons: true,
            cta_primary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_ACCEPT_LOAN,
            },
            cta_secondary: {
                text: CREDIT_DETAILS_INFORMATION.STATUS.CTA_REJECT_LOAN,
            },
        },
    ],
]);
export const CREDIT_STATUS_DEFAULT: number = 1;
export const CREDIT_ID: string = ':creditId';
