import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import AdditionalDocumentErrorMessage from '@components/PersonalLoans/PersonalLoansAdditionalDocument/AdditionalDocumentErrorMessage';
import {UPLOADDROPDATA} from '@components/PersonalLoans/constants';
import {HiddenFileInput} from '@components/PersonalLoans/HiddenFileInput';
import * as content from '@components/PersonalLoans/styles';
import PhotosIcon from '@images/PhotosIconBlue.svg';
import UploadIcon from '@images/Uploadtocloud.svg';
import {IFileErrors} from '@components/PersonalLoans/interfaces';

interface NoFileUploaderProps {
    fileInputRef: React.RefObject<HTMLInputElement>;
    handleIncomeFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    fileErrors: IFileErrors;
}

const NoFileUploader = ({
    fileErrors,
    fileInputRef,
    handleIncomeFileChange,
}: NoFileUploaderProps) => {
    return (
        <>
            <ContainerFlex
                {...content.UploadDrop}
                Border={
                    fileErrors.imageSize || fileErrors.validImageFormat
                        ? '1px dashed #A82424'
                        : '1px dashed #ACACFF'
                }
                onClick={() => fileInputRef.current?.click()}
            >
                <HiddenFileInput onChange={handleIncomeFileChange} ref={fileInputRef} />
                <Image src={UploadIcon} alt="icon-upload" />
                <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                    {UPLOADDROPDATA.DROP}
                </Text>
                <Text Color="#A1A5AA" FontSize="0.75rem">
                    {UPLOADDROPDATA.TYPE}
                </Text>
            </ContainerFlex>
            {fileErrors.imageSize && (
                <AdditionalDocumentErrorMessage message={UPLOADDROPDATA.ERROR} />
            )}
            {fileErrors.validImageFormat && (
                <AdditionalDocumentErrorMessage message={UPLOADDROPDATA.FORMAT_ERROR} />
            )}
            <ContainerFlex
                Width="fit-content"
                Gap="0.25rem"
                Margin="0 1rem"
                Cursor="pointer"
                onClick={() => fileInputRef.current?.click()}
            >
                <HiddenFileInput onChange={handleIncomeFileChange} ref={fileInputRef} />
                <Image alt="icon-upload" src={PhotosIcon} />
                <Text {...content.UploadSelect}>{UPLOADDROPDATA.SELECT}</Text>
            </ContainerFlex>
        </>
    );
};

export default NoFileUploader;
