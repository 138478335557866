import React, {useState} from 'react';
import {INewProductDisbursementProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/disbursementInterfaces';
import {DisbursementPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/Preview/DisbursementPreview';
import {DisbursementEditingForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/EditingForm/DisbursementEditingForm';

export const NewProductDisbursement: React.FC<INewProductDisbursementProps> = ({
    disbursementsRef,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    return (
        <>
            {isEditing ? (
                <DisbursementEditingForm
                    disbursementsRef={disbursementsRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <DisbursementPreview
                    disbursementsRef={disbursementsRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </>
    );
};
