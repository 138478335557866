import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {Zoom} from '@mui/material';

import {IDataLoginOperational} from '@components/Login/Operational/interfaces';
import {schema} from '@components/Login/Operational/validationsYup';
import {RootState} from '@/config/store';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerImg,
    Image,
    Text,
    Input,
} from '@/components/Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {SearchInput} from '@/components/Customer/Styles';
import ArrowSmall from '@images/ArrowSmall.svg';
import AtaskateLogo from '@images/AtaskateLogo.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import VisibilityOn from '@images/VisibilityOn.svg';
import Warning from '@images/warning.svg';
import {getValidateLoginUsersOperative} from '@components/Login/Redux/Actions/GetValidateUsers';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {PrivacityContainer} from '@components/Login/Administrative/Privacity';

export const LoginOperational = () => {
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const [userExists, setUserExists] = useState(true);
    const loading = useSelector((state: RootState) => state.getUsersValidation.loading);
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IDataLoginOperational>({
        defaultValues: {
            UserName: '',
            Password: '',
            version: 1,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (data: IDataLoginOperational) => {
        dispatch(getValidateLoginUsersOperative(data, setUserExists));
    };

    return (
        <ContainerFlex Height="100vh" Width="100vw" backG="#ACACFF">
            <ContainerFlex
                Display="grid"
                Width="29rem"
                Height="auto"
                Radius="24px"
                backG="#FFF"
                BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
                Padding="24px"
                Gap="16px"
                GridRows="repeat(auto-fit)"
                GridColumns="88%"
                JustifyItems="center"
            >
                <ContainerImg Height="54px" AlignItems="start">
                    <Image src={AtaskateLogo} alt="ataskate-logo" />
                </ContainerImg>
                {!userExists && (
                    <Zoom in={!userExists}>
                        <ContainerCellGrid
                            Height="auto"
                            Gap="8px"
                            Padding="8px 16px"
                            BorderR="8px"
                            bGround="#F3F3F3"
                            Align="center"
                            Justify="center"
                        >
                            <ContainerImg>
                                <Image src={Warning} alt="icon-warning" />
                            </ContainerImg>

                            <Text FontSize="0.75rem" Height="12px" Color="#71767D" FontWeight="500">
                                No reconocemos esta combinación de credenciales.
                            </Text>
                        </ContainerCellGrid>
                    </Zoom>
                )}
                <Text Color="#54575C" FontWeight="500" Height="16px" Justify="center" Width="100%">
                    Inicia sesión
                </Text>
                <ContainerCellGrid Height="auto" Gap="4px" FlexDir="column">
                    <Text
                        required
                        FontSize="0.75rem"
                        Height="12px"
                        Color="#71767D"
                        FontWeight="500"
                    >
                        Correo electrónico, usuario o teléfono
                    </Text>
                    <Input {...register('UserName')} />
                    {errors.UserName?.message && (
                        <Text FontSize="0.75rem" Color="#FF6357" Height="18px" Align="end">
                            {errors.UserName.message}
                        </Text>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid Height="auto" Gap="4px" FlexDir="column">
                    <Text
                        required
                        FontSize="0.75rem"
                        Height="12px"
                        Color="#71767D"
                        FontWeight="500"
                    >
                        Contraseña
                    </Text>
                    <SearchInput Height="40px" Position="none" GridColumn="90% 10%" FontSize="1rem">
                        <input
                            type={viewPassword ? 'text' : 'password'}
                            {...register('Password')}
                        />
                        <ContainerImg
                            onClick={() => setViewPassword(!viewPassword)}
                            Cursor="pointer"
                        >
                            <Image
                                src={viewPassword ? VisibilityOn : VisibilityOff}
                                alt="icon-warning"
                                Cursor="pointer"
                            />
                        </ContainerImg>
                    </SearchInput>
                    {errors.Password?.message && (
                        <Text FontSize="0.75rem" Color="#FF6357" Height="18px" Align="end">
                            {errors.Password.message}
                        </Text>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    Height="24px"
                    Gap="8px"
                    Align="center"
                    Justify="center"
                    Cursor="pointer"
                >
                    <Text Color="#5A5AFF" FontWeight="500" Cursor="pointer">
                        ¿Olvidaste tu contraseña?
                    </Text>
                    <ContainerImg>
                        <Image src={ArrowSmall} alt="icon-arrow-small" />
                    </ContainerImg>
                </ContainerCellGrid>
                {loading ? (
                    <LoadingAtaskate />
                ) : (
                    <ButtonGeneral
                        text="Iniciar sesión"
                        width="84%"
                        cursor="pointer"
                        clic={handleSubmit(onSubmit)}
                    />
                )}
                <PrivacityContainer />
            </ContainerFlex>
        </ContainerFlex>
    );
};
