import {AppDispatch} from '@/config/store';
import axios from 'axios';
import {URL} from '@config/constants/index';

import {removeExtension} from '@components/ShoppingCart/Redux/Actions/deleteExtension';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {DELETE_DISCHARGE_ENDORSEMENT} from '@components/ShoppingCart/Redux/types';

const deleteStart = () => ({
    type: DELETE_DISCHARGE_ENDORSEMENT.START,
});

const deleteSuccess = () => ({
    type: DELETE_DISCHARGE_ENDORSEMENT.SUCCESS,
});

const deleteError = () => ({
    type: DELETE_DISCHARGE_ENDORSEMENT.ERROR,
});

export function removeDischargeExtension(
    token: string,
    dischargeEndorsementId: number,
    refreshCartId?: number,
    extensionId?: number
) {
    return async (dispatch: AppDispatch) => {
        dispatch(deleteStart());
        try {
            if (extensionId) {
                await dispatch(removeExtension(token, Number(extensionId)));
            }
            await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteDischargeEndorsement}`,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                    data: {dischargeEndorsementId},
                }
            );
            dispatch(deleteSuccess());
            if (refreshCartId) dispatch(fetchCartById(token, refreshCartId));
        } catch (error) {
            dispatch(deleteError());
        }
    };
}
