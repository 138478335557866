import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {AlertCoownerBeneficiary} from '@components/Quoter/CoownerBeneficiary/AlertCoownerBeneficiary';
import {getAllCoowners} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/GetAllCoOwnerActions';
import {CoOwnerContract} from '@components/Quoter/CoownerBeneficiary/CoOwnerContract';
import {IconSvg} from '@/components/Quoter/styles';
import {useNavigate} from 'react-router-dom';
import {COOWNER_BENEFICIARY} from '@components/Quoter/CoownerBeneficiary/constants';
import {CART_MESSAGES} from '@components/Loan/PaymentQuoter/constants';
import {coOwnerBeneficiaryProps, IContractCoowners} from '@Quoter/CoownerBeneficiary/interface';
import {addCoownerBeneficiarys} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/AddCoOwnerBeneficiaryActions';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {NewUploadImageProducts} from '@Quoter/CoownerBeneficiary/NewUploadImage';
import {getAllContract} from '@/components/Shopify/Products/redux/actions/GetCarContracts';
import {toggleLoading} from '@/components/General/Atoms/LoadingAtaskate/Redux/Actions/loadingActions';

export const CoownerBeneficiary: React.FC<coOwnerBeneficiaryProps> = ({
    setShowEdit,
    setShowAdd,
}) => {
    const [switchCheked, setSwitchChecked] = useState<boolean>(false);
    const userData = useSelector((state: RootState) => state.getUsersValidation.userData);
    const token = userData?.token;
    const carId = userData?.carShoopingId;
    const [contractCoownersList, setContractCoownersList] = useState<IContractCoowners[]>([]);
    const [isCheck, setIsCheck] = useState(false);
    const clientList = useSelector((state: RootState) => state.clientList);
    const {client} = clientList;
    const {id} = client;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const AllCoOwners = () => {
        if (token) {
            dispatch(getAllCoowners(token, {clientId: id}));
        }
    };
    const formAddCoowner = useSelector((state: RootState) => state.formAddCoOwner);
    const getAllCoowner = useSelector((state: RootState) => state.getAllCoowner);
    useEffect(() => {
        AllCoOwners();
    }, [switchCheked, formAddCoowner]);

    const fetchContracts = async () => {
        if (token && carId) {
            dispatch(toggleLoading(true, CART_MESSAGES.REDIRECT_TO_PAY));
            await dispatch(getAllContract(String(carId), token));
            dispatch(toggleLoading(false));
        }
    };

    const SaveCoOwners = async () => {
        if (contractCoownersList.length > 0) {
            const contractByCoowners = contractCoownersList.map((contractCoowners) => {
                let personTypes: {coownerId: number; contactType: number}[] = [];
                const {coownerId1, coownerId2, beneficiaryId1, beneficiaryId2, contractId} =
                    contractCoowners;

                const addPersonType = (
                    coownerId: number,
                    beneficiaryId1: number,
                    beneficiaryId2: number
                ) => {
                    if (coownerId > 0) {
                        const contactType =
                            coownerId === beneficiaryId1 || coownerId === beneficiaryId2 ? 3 : 1;
                        personTypes = [...personTypes, {coownerId, contactType}];
                    }
                };

                addPersonType(coownerId1, beneficiaryId1, beneficiaryId2);
                addPersonType(coownerId2, beneficiaryId1, beneficiaryId2);

                [beneficiaryId1, beneficiaryId2].forEach((beneficiaryId) => {
                    if (
                        beneficiaryId > 0 &&
                        beneficiaryId !== coownerId1 &&
                        beneficiaryId !== coownerId2
                    ) {
                        personTypes = [...personTypes, {coownerId: beneficiaryId, contactType: 2}];
                    }
                });

                return {contractId, personTypes};
            });

            dispatch(addCoownerBeneficiarys(token!, {contractByCoowners, carId: carId!}));
        }

        await fetchContracts();
        navigate('/PaymentQuoter');
    };

    return (
        <>
            <ContainerFlex
                backG="#FFF"
                FlexDir="column"
                Align="start"
                Padding="1rem"
                Gap="1.2rem"
                Radius="32px"
                Border="solid 1px #E8E9EA"
            >
                <Text Color="#54575C" FontSize="1.25rem" FontWeight="500">
                    {COOWNER_BENEFICIARY.Title}
                </Text>
                <Text FontWeight="500" FontSize="1rem" Color="#54575C">
                    {COOWNER_BENEFICIARY.Ask_Add_Coowner}
                </Text>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="24px">
                    <GreenSwitch
                        name={`require`}
                        checked={switchCheked}
                        onChange={() => setSwitchChecked(!switchCheked)}
                    />
                    <ContainerFlex FlexDir="column">
                        {switchCheked &&
                            getAllCoowner &&
                            getAllCoowner?.data[0]?.contactId === 0 && <AlertCoownerBeneficiary />}
                        <CoOwnerContract
                            setShowEdit={setShowEdit}
                            contractCoownersList={contractCoownersList}
                            setContractCoownersList={setContractCoownersList}
                            switchCheked={switchCheked}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Gap="0.5rem" Justify="start" Cursor="pointer">
                    <IconSvg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        Cursor="pointer"
                        onClick={() => {
                            setShowAdd(true);
                        }}
                    >
                        <path d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z" fill="#5A5AFF" />
                    </IconSvg>
                    <Text
                        Color="#5A5AFF"
                        FontSize="1rem"
                        FontWeight="500"
                        Cursor="pointer"
                        onClick={() => {
                            setShowAdd(true);
                        }}
                    >
                        {COOWNER_BENEFICIARY.Add_New}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <NewUploadImageProducts
                isCheck={isCheck}
                setIsCheck={setIsCheck}
                SaveCoOwners={SaveCoOwners}
            />
        </>
    );
};
