import {AxiosResponse} from 'axios';
import {
    CREATE_USER_COMMAND_START,
    CREATE_USER_COMMAND_SUCCESS,
    CREATE_USER_COMMAND_ERROR,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {ICreateUserCommandReducer} from '@components/NewUserEmployee/Redux/interfaces';
const initialState: ICreateUserCommandReducer = {
    data: false,
    loading: false,
    error: false,
};
const createUserCommandReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): ICreateUserCommandReducer => {
    switch (action.type) {
        case CREATE_USER_COMMAND_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case CREATE_USER_COMMAND_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case CREATE_USER_COMMAND_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default createUserCommandReducer;
