import {
    PUT_PAYMENT_DEFAULT,
    PUT_PAYMENT_DEFAULT_SUCCESS,
    PUT_PAYMENT_DEFAULT_ERROR,
} from '@components/AccountSettings/Redux/types/Types';
import {
    IAddCompanyPaymentMethod,
    AddPaymentMethodAction,
} from '@components/AccountSettings/Redux/interfaces';

const initialState: IAddCompanyPaymentMethod = {
    error: false,
    errorData: null,
    loading: false,
};

const PutPaymentDefaultReducer = (
    state = initialState,
    action: AddPaymentMethodAction
): IAddCompanyPaymentMethod => {
    switch (action.type) {
        case PUT_PAYMENT_DEFAULT:
            return {...state, loading: true, error: false};
        case PUT_PAYMENT_DEFAULT_SUCCESS:
            return {...state, loading: false, error: false};
        case PUT_PAYMENT_DEFAULT_ERROR:
            return {...state, loading: false, error: true, errorData: action.error};
        default:
            return state;
    }
};

export default PutPaymentDefaultReducer;
