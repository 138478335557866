import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {IClienctContactPay} from '@/components/Shopify/Products/redux/interface';
import {SHOPPING_CART} from '@/components/ShoppingCart/constants';
import phoneIcon from '@images/telephone.svg';
import mailEnvelope from '@components/Branches/Images/MailEnvelope.svg';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';

const CardsPayResume = () => {
    const {clientInfo, summary, pawnContracts} = useSelector((state: RootState) => ({
        clientInfo: state.cardClientProfile.clientProfile,
        summary: state.cartPaymentReducer,
        pawnContracts: state.getCarContracts as IClienctContactPay,
    }));
    const summaryPawns = pawnContracts.data;
    const summaryContracts = summary.paymentResult && summary.paymentResult.data;
    const pawnsPay =
        summaryContracts && summaryContracts.pawnsPay && summaryContracts.pawnsPay.length > 0;

    const renderInfo = (pawnInfo: string | number, clientInfo: string | number) => {
        if (pawnsPay) {
            return pawnInfo ? `${pawnInfo}` : '-';
        } else {
            return clientInfo ? `${clientInfo}` : '-';
        }
    };

    return (
        <ContainerFlex Height="4.688rem" Justify="start" Align="start" ColumnGap="0.5rem">
            <Image
                src={renderInfo(summaryPawns.imgProfile, clientInfo.image)}
                alt="image-profile"
                Height="3rem"
                Width="3rem"
                Radius="50%"
                ObjectFit="cover"
                AspectRatio="1 / 1"
            />
            <ContainerFlex Justify="start" Align="start" Gap="0.25rem" FlexDir="column">
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700" Transform="capitalize">
                    {renderInfo(summaryPawns.clientName, clientInfo.clientName)}
                </Text>
                <ContainerFlex Justify="start" Gap="0.5rem">
                    <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="500">
                        {`${SHOPPING_CART.NUC} ${renderInfo(summaryPawns.nuc, clientInfo.nuc)}`}
                    </Text>
                    <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="500">
                        <Image
                            src={phoneIcon}
                            alt="phone"
                            Width="0.875rem"
                            Height="0.875rem"
                            Padding="0 0.4rem 0 0"
                        />
                        {renderInfo(summaryPawns.clientPhone, clientInfo.phone)}
                    </Text>
                    <Text FontSize="0.875rem" Color="#1D1E20" FontWeight="500">
                        <Image
                            src={mailEnvelope}
                            alt="email"
                            Width="0.875rem"
                            Height="0.875rem"
                            Padding="0 0.4rem 0 0"
                        />
                        {renderInfo(summaryPawns.clientEmail, clientInfo.email)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CardsPayResume;
