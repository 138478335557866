import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import StringUtils from '@/GenericScripts/utils';
import {GET_GROPUPS_COMPANY} from '@components/LocationsBranch/Redux/types';
import {NavigateFunction} from 'react-router-dom';
import {IUpdateGroup} from '@components/LocationsBranch/interfaces';

const getGroupsStart = () => ({
    type: GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_START,
});

const getGroupsSuccess = (result: AxiosResponse) => ({
    type: GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_SUCCESS,
    payload: result.data,
});

const getGroupsError = () => ({
    type: GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_ERROR,
});

export const resetGroup = () => ({
    type: GET_GROPUPS_COMPANY.GET_COMPANY_LEVEL_DETAILS_RESET,
});

export const getCompanyGroupsById = (
    token: string,
    filters: {
        companyId: number;
        levelId: number;
    }
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...filters});
    return async (dispatch: AppDispatch) => {
        dispatch(getGroupsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetGroupsByCompanyId}${queryString}`,
                {headers}
            );
            dispatch(getGroupsSuccess(response.data));
        } catch (error: unknown) {
            dispatch(getGroupsError());
        }
    };
};

export const updateCompanyGroupsById = (
    token: string,
    navigate: NavigateFunction,
    body: IUpdateGroup
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getGroupsStart());
        try {
            await axios.put(`${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateGroup}`, body, {
                headers,
            });
            navigate('/LocationBranch');
        } catch (error) {
            dispatch(getGroupsError());
        }
    };
};
