import {SelectedLevel} from '@components/LocationsBranch/Redux/interfaces';

export const getLocationValues = (transferSelection: SelectedLevel) => {
    const typeLocation = transferSelection.breadcrum?.branch
        ? transferSelection.breadcrum?.branch?.typeLocationId
        : 1;
    const locationId = transferSelection.breadcrum?.branch
        ? transferSelection.breadcrum?.branch?.branchId || 0
        : transferSelection.levelId;
    return {
        LocationId: locationId,
        TypeLocation: typeLocation,
    };
};
