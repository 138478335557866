import {AxiosError, AxiosResponse} from 'axios';
import {GET_STATES, GET_STATES_ERROR, GET_STATES_SUCCEED} from '@components/NewUser/Redux/Types';

const initialState = {
    states: null,
    loading: false,
    error: false,
};

const getStateReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_STATES:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_STATES_SUCCEED:
            return {
                ...state,
                states: action.payload.data.data,
                loading: false,
            };
        case GET_STATES_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getStateReducer;
