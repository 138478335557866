import axios, {AxiosError, AxiosResponse} from 'axios';
import {NavigateFunction} from 'react-router-dom';
import {
    CREATE_TERM_PERSONAL_LOAN_START,
    CREATE_TERM_PERSONAL_LOAN_SUCCESS,
    CREATE_TERM_PERSONAL_LOAN_ERROR,
    VALIDATE_TERM_NAME_ERROR,
    VALIDATE_TERM_NAME_START,
    VALIDATE_TERM_NAME_SUCCESS,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {INewLoanSubmittedItem} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {resetLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {resetFrequencyPaymentTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {resetChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {resetDisbursementDocuments} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/DocumentDisbursementActions';
import {resetFrequencyPaymentsReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetFrequencyPaymentsActions';

export const createTermPersonalLoanStart = () => {
    return {
        type: CREATE_TERM_PERSONAL_LOAN_START,
    };
};

export const createTermPersonalLoanSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_TERM_PERSONAL_LOAN_SUCCESS,
        payload: result.data,
    };
};

export const createTermPersonalLoanError = (error: AxiosError) => {
    return {
        type: CREATE_TERM_PERSONAL_LOAN_ERROR,
        error,
    };
};

export const createTermPersonalLoan = (
    token: string,
    newLoanProductItem: INewLoanSubmittedItem,
    navigate: NavigateFunction
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(createTermPersonalLoanStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateTermPersonalLoan}`,
                newLoanProductItem,
                {headers}
            );
            dispatch(createTermPersonalLoanSuccess(response));
            navigate('/LoansProducts');
            dispatch(resetLoanSubmittedData());
            dispatch(resetFrequencyPaymentTable());
            dispatch(resetChargesAndPenalties());
            dispatch(resetDisbursementDocuments());
            dispatch(resetFrequencyPaymentsReducer());
        } catch (error) {
            dispatch(createTermPersonalLoanError(error as AxiosError));
        }
    };
};

export const validateTermNameStart = () => {
    return {
        type: VALIDATE_TERM_NAME_START,
    };
};

export const validateTermNameSuccess = (result: AxiosResponse) => {
    return {
        type: VALIDATE_TERM_NAME_SUCCESS,
        payload: result.data,
    };
};

export const validateTermNameError = (error: AxiosError) => {
    return {
        type: VALIDATE_TERM_NAME_ERROR,
        error,
    };
};

export const validateTermNameAction = (token: string, companyId: number, nameTerm: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        const params = {
            nameTerm,
            companyId,
        };
        dispatch(validateTermNameStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateNameTerm}`,
                {params, headers}
            );
            dispatch(validateTermNameSuccess(response));
        } catch (error) {
            dispatch(validateTermNameError(error as AxiosError));
        }
    };
};
