import React, {useState} from 'react';
import {
    ContainerFlex,
    Text,
    ContainerForm,
    Input,
    TextArea,
} from '@/components/Shopify/Ecommerce/styles';
import {BUTTON, MODAL_RETURN} from '@/components/CashFlowActions/Operational/Constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Modal, Overlay} from '@/components/CashFlowActions/Operational/styles';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {CustomSelect} from '@/components/CashFlowActions/InputSelect';
import {valideReturnOfSurplus} from '@/components/CashFlowActions/Operational/ValidationYup';
import {
    ClosedModalProps,
    Employee,
    FormData,
    typereturnFlow,
} from '@/components/CashFlowActions/Operational/interfaces';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import {TextReturn} from '@/components/CashFlowActions/TextRetun';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ReturnExcess} from '@/components/CashFlowActions/Redux/Actions/RetunrAddExecessAction';

export const ModalReturnOfSurplus = ({isOpen, closeModal}: ClosedModalProps) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const typereturn = useSelector(
        (state: RootState) => state.employeeReturnType.userData as typereturnFlow[]
    );
    const branchId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.branchDetails?.[0]?.branchId;
    });
    const employeeData = useSelector(
        (state: RootState) => state.employeeCashFunding.userData as Employee[]
    );
    const errorMesage = useSelector((state: RootState) => state.employeeReturnType.error);
    const formatCurrency = useFormatCurrency();
    const mappedMovementOptions = typereturn?.map((typeReturn) => ({
        label: typeReturn.type,
        value: typeReturn.id,
    }));
    const mappedEmployeeOptions = employeeData?.map((employee) => ({
        label: employee.userEmployeeName,
        value: employee.id,
    }));
    const [error, setError] = useState<string | null>(null);
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<FormData>({
        resolver: yupResolver(valideReturnOfSurplus),
    });
    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
            reset();
        }
    };
    const onSubmit = async (data: FormData) => {
        const parsedAmount = parseFloat(data.amount.replace(/[^0-9.-]+/g, ''));
        const employeeId = parseInt(data.userBox);
        const movementType = parseInt(data.movementType);
        const dataRetunr = {
            branchId: branchId,
            employeeId: employeeId,
            amount: parsedAmount,
            returnTypeId: movementType,
            observations: data.message,
        };
        try {
            await dispatch(ReturnExcess(token, dataRetunr));
            closeModal();
        } catch (error) {
            setError(errorMesage);
        } finally {
            reset();
        }
    };
    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Radius="16px 0px 0px 16px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Align="start"
                >
                    <TextReturn />
                    <ContainerForm
                        Gap="24px"
                        FlexDir="column"
                        Padding="24px"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ContainerFlex
                            Height="max-content"
                            Gap="8px"
                            FlexDir="column"
                            Align="start"
                        >
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_RETURN.INPUT_TEXT}
                            </Text>
                            <Controller
                                name="movementType"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona"
                                        options={mappedMovementOptions}
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                    />
                                )}
                            />
                            {errors.movementType && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.movementType.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex
                            Height="max-content"
                            Gap="8px"
                            FlexDir="column"
                            Align="start"
                        >
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_RETURN.TEXT_USER}
                            </Text>
                            <Controller
                                name="userBox"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <CustomSelect
                                        {...field}
                                        placeholder="Selecciona un empleado"
                                        options={mappedEmployeeOptions}
                                        onChange={(value) => field.onChange(value)}
                                        value={field.value}
                                    />
                                )}
                            />
                            {errors.userBox && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.userBox.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex
                            Height="max-content"
                            Gap="8px"
                            FlexDir="column"
                            Align="start"
                        >
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_RETURN.MONT}
                            </Text>
                            <Controller
                                name="amount"
                                control={control}
                                defaultValue=""
                                render={({field}) => (
                                    <Input
                                        {...field}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        onBlur={(e) => {
                                            const formattedValue = formatCurrency(e.target.value);
                                            field.onChange(formattedValue);
                                        }}
                                        placeholder="$0.00"
                                        value={field.value}
                                        type="text"
                                        TextAlign="start"
                                    />
                                )}
                            />
                            {errors.amount && (
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {errors.amount.message}
                                </Text>
                            )}
                        </ContainerFlex>
                        <ContainerFlex
                            Height="max-content"
                            Gap="8px"
                            FlexDir="column"
                            Align="start"
                        >
                            <Text color="#54575C" FontSize="1rem" FontWeight="500">
                                {MODAL_RETURN.NOTE}
                                <Text
                                    PaddingL="5px"
                                    color="#54575C"
                                    FontSize="1rem"
                                    FontWeight="500"
                                >
                                    {MODAL_RETURN.OPTION}
                                </Text>
                            </Text>
                            <Controller
                                name="message"
                                control={control}
                                defaultValue=""
                                render={({field}) => <TextArea {...field} Height="6.5rem" />}
                            />

                            {error && (
                                <ContainerFlex Justify="start" Height="max-content">
                                    <Text Color="#FF6357" FontSize="0.75rem">
                                        {error}
                                    </Text>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                        <ContainerFlex Align="end" ColumnGap="16px">
                            <ButtonGenerals
                                type="button"
                                onClick={() => closeModal()}
                                SecondaryButton="#FFFFFF"
                                Width="100%"
                                Cursor="pointer"
                            >
                                {BUTTON.CLOSE}
                            </ButtonGenerals>
                            <ButtonGenerals type="submit" Width="100%" Cursor="pointer">
                                {BUTTON.CONFIRM}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
