import {ProductFormValues} from '@components/AuctionPass/interface';
import {Option} from '@components/Users/UserProfile/RoleSettings/constants';

export const FILTER_TEXTS = {
    TITLE: 'Filtrar',
    CLEAR: 'Limpiar',
    SELECT: 'Selecciona',
    EMPITY_MESSAGE: '',
    PUBLICATION_DATE: {
        LABEL: 'Fecha de publicación',
        PLACEHOLDER: 'Elegir fecha',
    },
    PRICE_RANGE: {
        LABEL: 'Rango de precios',
        FROM: 'Desde',
        TO: 'Hasta',
    },
    ARTICLE_STATUS: {
        LABEL: 'Estado del artículo',
        PUBLISHED: 'Publicado',
        PAUSED: 'Pausado',
        RESERVED: 'Apartado',
    },
    CATEGORY: {
        LABEL: 'Por categoría',
        CATEGORY_PLACEHOLDER: 'Categoría',
        SUBCATEGORY_PLACEHOLDER: 'Sub categoría',
    },
    BUTTONS: {
        CANCEL: 'Cancelar',
        APPLY: 'Aplicar',
    },
};
export const AuctionPassText = {
    AuctionPassTitle: 'Pase Almoneda',
    AuctionPassDescription:
        'Define el porcentaje de comercialización y determina si el producto en cuestión necesita días de pausa para revisión previa a la venta',
    RulesTitle: 'Reglas',
    CatalogColumn: 'Catalogo',
    CommercializationColumn: 'Incremento % comercialización sobre',
    PercentageColumn: '% Porcentaje',
    DaysOfPauseColumn: 'Dias de pausa',
    EditIconAltText: 'Editar icono',
    SaveChanges: 'Cambios guardados.',
    days: ' dia(s)',
};

export const DinamicPadding = (treeLevel: number) => {
    const paddingMap: {[key: number]: string} = {
        1: '0',
        2: '29px',
        3: '55px',
        4: '80px',
        5: '105px',
        6: '130px',
        7: '155px',
    };
    return paddingMap[treeLevel];
};
export const DinamicPaddingNew = (treeLevel: number) => {
    const paddingMap: {[key: number]: string} = {
        1: '16px',
        2: '29px',
        3: '55px',
        4: '80px',
        5: '105px',
        6: '130px',
        7: '155px',
    };
    return paddingMap[treeLevel];
};

export const toolTipText = {
    title: 'Dias de pausa',
    text: 'Determina si el producto requiere un período de revisión antes de su lanzamiento al mercado.',
};

export const options: Option[] = [
    {id: 1, label: 'Avalúo'},
    {id: 2, label: 'Préstamos'},
];

export const TITLES = {
    HEADER: 'Configurador Almoneda',
    PLUS: '+2',
    BREAD_CRUMBS: 'Electrónicos / Celulares / Samsung',
    NAME: 'Nombre',
    CATEGORY: 'Categoría',
    BRAND: 'Marca',
    MODEL: 'Modelo',
    SKU: 'Sku',
    COLOR: 'Color',
    STATE: 'Condicion',
    DATE: 'Fecha pase almoneda',
    LOCATION: 'Ubicación',
    PRICE: 'Precio',
    SALE_PRICE: 'Precio venta',
    MIN_SALE: 'Mínimo para apartar',
    SALE_PRICE_TOTAL: 'Precio mínimo',
    OFFERTE_ACTIVE: 'Activar oferta',
    OFFER_MIN: 'Precio mínimo para ofertar',
    OFFER_MIN_APPRT: 'Mínimo para apartar',
    OFFER_MIN_APPRT_PERCENT: '% Mínimo para apartar',
    SALE_CHANNEL: 'Canal de venta',
    SELECT: 'Selecciona',
    PUBLICATION: 'Publicación Pase almoneda',
    RECOMMEND: 'Recomendaciones',
    TITUL_A: 'Usa fotografías con fondo blanco, usando un editor.',
    TITUL_B:
        'Usa una iluminación adecuada, que permita ver el color de tu producto y distintas caras de tu producto.',
    TITUL_C: 'Puedes agregar hasta 6 imágenes.',
    TITUL_D: 'Cuida tu ortografía ',
    DOT: '•',
    ON_LINE: 'En línea',
    SHOW: 'Mostrador',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    REQUIRED: 'Campo requerido',
    DESCRIPTION: 'Descripción',
    PAUSE: 'Si deseas pausar la publicación de este artículo, selecciona esta casilla y especifica una razón ',
};

export const defaultValues: ProductFormValues = {
    name: '',
    category: {value: 0, label: ''},
    brand: {value: 0, label: ''},
    model: {value: 0, label: ''},
    sku: '',
    color: {value: 0, label: ''},
    condition: {value: 0, label: ''},
    date: '',
    ubication: {value: 0, label: ''},
    salePrice: '',
    minAppartPrice: '',
    minPrice: '',
    minOffer: '',
    minAppartPercent: '',
    reason: {value: 0, label: ''},
};

export const CATEGORIES = [
    {value: 1, label: 'Celulares'},
    {value: 2, label: 'Celulares y accesorios'},
    {value: 3, label: 'Computación'},
    {value: 4, label: 'Linea blanca'},
    {value: 5, label: 'Otro'},
];

export const BRAND = [
    {value: 1, label: 'Samsung'},
    {value: 2, label: 'Apple'},
    {value: 3, label: 'LG'},
    {value: 4, label: 'Xiomi'},
    {value: 5, label: 'Poco'},
];

export const MODEL = [
    {value: 1, label: 'A01'},
    {value: 2, label: 'A02'},
    {value: 3, label: 'A15'},
    {value: 4, label: 'A24'},
    {value: 5, label: 'A24 PLUS'},
];

export const COLOR = [
    {value: 1, label: 'Aszul'},
    {value: 2, label: 'Amrillo'},
];

export const STATE = [
    {value: 1, label: 'Bueno'},
    {value: 2, label: 'Regular'},
];

export const REASON = [
    {value: 1, label: 'Bueno'},
    {value: 2, label: 'Regular'},
];
