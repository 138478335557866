import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {FETCH_CART_BY_ID} from '@components/ShoppingCart/Redux/types';

export const fetchCartByIdStart = () => ({
    type: FETCH_CART_BY_ID.FETCH_CART_START,
});

export const fetchCartByIdSuccess = (result: AxiosResponse) => ({
    type: FETCH_CART_BY_ID.FETCH_CART_SUCCESS,
    payload: result.data,
});

export const fetchCartByIdError = (error: AxiosError) => ({
    type: FETCH_CART_BY_ID.FETCH_CART_ERROR,
    error: error,
});

export const setRefreshCart = () => ({
    type: FETCH_CART_BY_ID.SET_REFRESH,
});

export const fetchCartById = (token: string, carshopingId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchCartByIdStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCarByCarId.replace(
                    '{carshopingId}',
                    carshopingId.toString()
                )}`,
                {
                    headers,
                }
            );
            dispatch(fetchCartByIdSuccess(response.data));
        } catch (error) {
            dispatch(fetchCartByIdError(error as AxiosError));
        }
    };
};
