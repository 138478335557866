import {useForm} from 'react-hook-form';
import React from 'react';
import {
    Text,
    Input,
    ContainerCellGrid,
    ContainerForm,
    Image,
} from '@components/Shopify/Ecommerce/styles';
import {yupResolver} from '@hookform/resolvers/yup';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {
    ADDING_TYPE_ACTION,
    TYPEDOCUMENTS,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import CloseIcon from '@images/closeDark.svg';
import DoneIcon from '@images/check_done_blue.svg';
import {
    IAddNewDocumentForm,
    IAddNewDocumentProps,
} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';
import {schemaAddNewDocumentForm} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/schemaAddNewDocumentForm';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {updateDocumentsCopyArray} from '@/components/PersonalLoans/Redux/Actions/UpdatePersonalDocumentsActions';

export const AddNewDocumentForm: React.FC<IAddNewDocumentProps> = ({
    addDocuments,
    setAddDocuments,
    documentsCopy,
    setDocumentsCopy,
    typeAction,
}) => {
    const dispatch = useDispatch();
    const {updatedDocuments} = useSelector((state: RootState) => {
        return state.UpdatePersonalDocumentsReducer;
    });
    const {
        register,
        formState: {errors},
        handleSubmit,
    } = useForm<IAddNewDocumentForm>({
        resolver: yupResolver(schemaAddNewDocumentForm),
        defaultValues: {
            name: '',
            description: '',
        },
    });
    const onSubmit = (data: IAddNewDocumentForm) => {
        const documentsSource =
            typeAction === ADDING_TYPE_ACTION ? documentsCopy : updatedDocuments;
        const newDocumentOption = {
            id: documentsSource.length + 1,
            documentId: 0,
            docName: data.name,
            docDescription: data.description,
            seniorityJob: '0',
            seniorityJobTime: {label: '', value: 0},
            isChecked: false,
        };
        setDocumentsCopy([...documentsCopy, newDocumentOption]);
        dispatch(updateDocumentsCopyArray([...updatedDocuments, newDocumentOption]));
        setAddDocuments(!addDocuments);
    };
    return (
        <ContainerForm
            Justify="space-between"
            MarginT="1rem"
            Padding="1rem"
            Border="1px solid #5A5AFF"
            Radius="1rem"
            onSubmit={handleSubmit(onSubmit)}
        >
            <ContainerCellGrid FlexDir="column" Align="start" Width="363px" Gap="0.25rem">
                <Text oFlow="normal" wSpace="pre" FontWeight="700" Color="#2A2C2F">
                    {TYPEDOCUMENTS.DOCUMENT}
                </Text>
                <Input placeholder="" {...register('name')} maxLength={100} />
                {errors.name && <ErrorMessage message={errors.name.message} />}
            </ContainerCellGrid>
            <ContainerCellGrid FlexDir="column" Align="start" Width="363px" Gap="0.25rem">
                <Text oFlow="normal" wSpace="pre" FontWeight="700" Color="#2A2C2F">
                    {TYPEDOCUMENTS.INFO}
                    <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                        {TYPEDOCUMENTS.OPTIONAL}
                    </Text>
                </Text>
                <Input placeholder="" {...register('description')} maxLength={100} />
                {errors.description && <ErrorMessage message={errors.description.message} />}
            </ContainerCellGrid>
            <ContainerCellGrid FlexDir="column" Align="end" Width="107px" Gap="0.25rem">
                <Image
                    src={CloseIcon}
                    alt="close-icon"
                    Cursor="pointer"
                    onClick={() => setAddDocuments(!addDocuments)}
                />
                <ButtonGenerals
                    SecondaryButton="#FFFFFF"
                    Width="107px"
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                >
                    <Image src={DoneIcon} alt="done-icon" Cursor="pointer" />
                    {TYPEDOCUMENTS.APPLY}
                </ButtonGenerals>
            </ContainerCellGrid>
        </ContainerForm>
    );
};
