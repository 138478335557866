import React, {useState} from 'react';
import {ModalType} from '@CreateBranch/Interfaces/Interfaces';
import {ContainerModal, ModalDialog} from '@BranchInformation/style';
import {useModalAnimation} from '@/hooks/useModalAnimation';

export const Modal = (props: ModalType) => {
    const {
        title,
        children,
        modalState,
        changeModalState = () => null,
        FlexDir,
        Align,
        Justify,
        background,
        Margin,
        titleModalState,
        Top,
        PaddingCont,
        Padding,
        Position,
        BorderRadius,
        Left,
        Gap,
        MaxH,
        HeightDialog,
        MaxHDialog,
        applyAnimation = false,
        closeOnOutsideClick = true,
        stopModalPropagation = true,
    } = props;

    const {shouldRender, animationState, handleAnimationEnd} = useModalAnimation(
        modalState,
        applyAnimation
    );

    const [Styles] = useState({});
    const handleModalDialogClick = (e: {stopPropagation: () => void}) => {
        if (stopModalPropagation) e.stopPropagation();
    };

    const handleContainerClick = () => {
        changeModalState(!modalState);
    };

    return (
        <>
            {shouldRender && (
                <ContainerModal
                    onClick={closeOnOutsideClick ? handleContainerClick : undefined}
                    Justify={Justify}
                    FlexDir={FlexDir}
                    Align={Align}
                    Background={background}
                    Margin={Margin}
                    Top={Top}
                    Left={Left}
                    Padding={Padding}
                    PaddingCont={PaddingCont}
                    Position={Position}
                    HeightCont="100vh"
                    MaxH={MaxH}
                    Gap={Gap}
                    ApplyAnimation={applyAnimation}
                    AnimationState={animationState}
                    onAnimationEnd={handleAnimationEnd}
                >
                    <ModalDialog
                        {...props}
                        HeightDialog={HeightDialog}
                        MaxHDialog={MaxHDialog}
                        {...Styles}
                        onClick={handleModalDialogClick}
                        BorderRadius={BorderRadius}
                        Cursor="pointer"
                    >
                        {titleModalState && <span {...Styles}>{title}</span>}
                        {children}
                    </ModalDialog>
                </ContainerModal>
            )}
        </>
    );
};
