import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import arrowChange from '@images/arrowTopLeft.svg';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {ContainerFlex, Text, Image, Line} from '@Shopify/Ecommerce/styles';
import {IContractsFinaly, IEndorment} from '@components/Loan/Redux/interfaces';

export const TotalCount = () => {
    const summary = useSelector((state: RootState) => state.cartPaymentReducer);
    const summaryPayment = summary.paymentResult && summary.paymentResult.data;
    const amountComparison = 0;
    const calculateTotalPass = (endorsements: IContractsFinaly): number => {
        return endorsements.endorsementsPay.reduce((total: number, endorsement: IEndorment) => {
            return total + (endorsement.pass ?? 0);
        }, 0);
    };
    const totalPass = calculateTotalPass(summaryPayment);
    const showTotalPass = summaryPayment.pawnsPay.length === 0 && totalPass > 0;
    return (
        <>
            <ContainerFlex FlexDir="column" Padding="0 0.5rem" Gap="0.5rem">
                <ContainerFlex Justify="space-between">
                    <Text Color="#2A2C2F">{SHOPPING_CART.SUB_TOTAL}</Text>
                    <Text Color="#2A2C2F">-{numberToCurrency(summaryPayment.subTotal)}</Text>
                </ContainerFlex>
                <ContainerFlex Justify="space-between">
                    <Text Color="#2A2C2F">{SHOPPING_CART.IVA_RESUME}</Text>
                    <Text Color="#2A2C2F">{numberToCurrency(summaryPayment.iva)}</Text>
                </ContainerFlex>
                {showTotalPass && (
                    <ContainerFlex
                        Justify="space-between"
                        Padding="0.5rem"
                        AlignSelf="stretch"
                        MinH="none"
                        backG="#F0F0FF"
                        Radius="0.5rem"
                    >
                        <Text Color="#2A2C2F">{SHOPPING_CART.PARTIAL_PAYMENT}</Text>
                        <Text Color="#2A2C2F">{numberToCurrency(totalPass)}</Text>
                    </ContainerFlex>
                )}
                <ContainerFlex Justify="space-between">
                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="700">
                        {SHOPPING_CART.TOTAL}
                    </Text>
                    <Text FontSize="1.25rem" FontWeight="700" Color="#2A2C2F">
                        -{numberToCurrency(summaryPayment.total)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <Line />
            <ContainerFlex FlexDir="column" Padding="0 0.5rem" Gap="1rem">
                {summaryPayment.cardPayment > amountComparison && (
                    <ContainerFlex Justify="space-between" Padding="0.5rem">
                        <Text Color="#2A2C2F">{SHOPPING_CART.PAY_CREDIT}</Text>
                        <Text Color="#2A2C2F" Gap="0.5rem">
                            {summaryPayment.cardAuthorization}
                            <Text Color="#2A2C2F" FontWeight="700">
                                {numberToCurrency(summaryPayment.cardPayment)}
                            </Text>
                        </Text>
                    </ContainerFlex>
                )}
                {summaryPayment.cashPayment > amountComparison && (
                    <ContainerFlex Justify="space-between" Padding="0.5rem">
                        <Text Color="#2A2C2F">{SHOPPING_CART.PAY_CASH}</Text>
                        <Text Color="#2A2C2F" FontWeight="700">
                            {numberToCurrency(summaryPayment.cashPayment)}
                        </Text>
                    </ContainerFlex>
                )}
                {summaryPayment.changePayment > amountComparison && (
                    <ContainerFlex Justify="space-between" Padding="0.5rem">
                        <Text Color="#2A2C2F">
                            <Image
                                src={arrowChange}
                                alt="arrow-change"
                                Width="0.938rem"
                                Height="1.063rem"
                                Padding="0.188rem 0.25rem"
                            />
                            {SHOPPING_CART.CHANGE}
                        </Text>
                        <Text Color="#2A2C2F" FontWeight="700">
                            {numberToCurrency(summaryPayment.changePayment)}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </>
    );
};
