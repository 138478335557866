import DoubleRangeSlider from '@/components/CreditsAndPawns/FilterModal/DoubleRangeSlider';
import {IFilterValues} from '@/components/CreditsAndPawns/interfaces';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IGlobaQuoterFilterValues} from '@/components/GlobalQuoter/interface';
import {setFiltersValues} from '@/components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';
import FilterCheckbox from '@/components/GlobalQuoter/FilterModal/FilterCheckbox';
import {FILTER_LABELS_AND_VALUES} from '@/components/GlobalQuoter/TempConstants';

const FilterContainer = () => {
    const dispatch = useDispatch();
    const {filtersValues, companyList, disbursementList, maxIinterestRate} = useSelector(
        (state: RootState) => state.globalQuoterFilterRedcuer
    );
    const dispatchFilterValues = useCallback(
        (filtersValues: IFilterValues | IGlobaQuoterFilterValues) =>
            dispatch(setFiltersValues(filtersValues as IGlobaQuoterFilterValues)),
        [dispatch]
    );

    return (
        <ContainerFlex
            Height={'calc(100vh - 69px)'}
            FlexDir="column"
            Gap="16px"
            OverFlowY="auto"
            Justify="flex-start"
        >
            <ContainerFlex
                FlexDir="column"
                Gap="8px"
                Justify="flex-start"
                Align="flex-start"
                Height="88px"
            >
                <Text
                    Color="#1D1E20"
                    FontFamily="Nunito"
                    FontWeight="700"
                    LHeight="24px"
                    LetterSpacing="0.3px"
                >
                    {FILTER_LABELS_AND_VALUES.RATE.LABEL}
                </Text>
                <DoubleRangeSlider
                    min={0}
                    max={maxIinterestRate}
                    sliderWidth="100%"
                    step={1}
                    minVal={filtersValues.rateMin}
                    maxVal={filtersValues.rateMax}
                    setVal={dispatchFilterValues}
                    filtersValues={filtersValues}
                    nameMin={FILTER_LABELS_AND_VALUES.RATE.VALUE_MIN}
                    nameMax={FILTER_LABELS_AND_VALUES.RATE.VALUE_MAX}
                    type={'percentage'}
                />
            </ContainerFlex>
            <FilterCheckbox
                keyName={FILTER_LABELS_AND_VALUES.COMPANY.VALUE}
                filterOptions={companyList}
                title={FILTER_LABELS_AND_VALUES.COMPANY.LABEL}
                handleFilter={dispatchFilterValues}
                filters={filtersValues}
                filtersValues={filtersValues.company}
            />
            <FilterCheckbox
                keyName={FILTER_LABELS_AND_VALUES.DISBURSTMENT_TYPE.VALUE}
                filterOptions={disbursementList}
                title={FILTER_LABELS_AND_VALUES.DISBURSTMENT_TYPE.LABEL}
                handleFilter={dispatchFilterValues}
                filters={filtersValues}
                filtersValues={filtersValues.disburstmentType}
            />
        </ContainerFlex>
    );
};

export default FilterContainer;
