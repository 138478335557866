import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import CreditDetailsHeader from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsHeader';
import CreditDetailsInformation from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformation';
import {GetCreditDetailCustomerProfileAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/GetPersonalCreditAmountActions';
import {ModalCartShopping} from '@/components/MyAccount/MyCredit/CreditDetails/ModalCartShopping';
import {
    CREDIT_STATUS_MAP,
    CREDIT_STATUS_DEFAULT,
} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {ICreditStatus} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

const CreditDetails = () => {
    const dispatch = useDispatch();
    const {creditId} = useParams<{creditId?: string}>();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {creditLoanDetailData} = useSelector(
        (state: RootState) => state?.GetPersonalCreditAmountReducer
    );
    const {showModal} = useSelector((state: RootState) => state?.shoppingCarClient);
    const [creditDetailStatus, setCreditDetailStatus] = useState<ICreditStatus>(
        CREDIT_STATUS_MAP.get(CREDIT_STATUS_DEFAULT) as ICreditStatus
    );

    useEffect(() => {
        if (creditId && token) {
            dispatch(GetCreditDetailCustomerProfileAction(Number(creditId), String(token)));
        }
    }, [creditId, token]);

    useEffect(() => {
        if (
            creditLoanDetailData &&
            creditLoanDetailData?.stateId &&
            creditLoanDetailData?.stateId !== creditDetailStatus.creditStatusId
        ) {
            setCreditDetailStatus(
                CREDIT_STATUS_MAP.get(creditLoanDetailData?.stateId) as ICreditStatus
            );
        }
    }, [creditLoanDetailData]);

    return (
        <ContainerFlex
            Height="calc(100vh - 56px)"
            Width="100%"
            FlexDir="column"
            backG="#E5E5FF"
            Justify="start"
            Align="center"
            Padding="24px 60px"
            Gap="24px"
            OverFlow="auto"
        >
            <CreditDetailsHeader />
            {creditDetailStatus && <CreditDetailsInformation {...creditDetailStatus} />}
            {showModal && <ModalCartShopping />}
        </ContainerFlex>
    );
};

export default CreditDetails;
