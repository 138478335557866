import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm, FormProvider} from 'react-hook-form';

import {RootState} from '@/config/store';
import {UpdateTaxData} from '@components/AccountSettings/Redux/Actions/UpdateTaxData';
import {SelectOption} from '@components/Shopify/ListClient/interfaces';
import {IUpdateTaxData} from '@components/AccountSettings/Redux/interfaces';
import {GetPostalInfo} from '@/components/Branches/ManageBranch/Redux/Actions/GetPostalInfo';
import {schemaEditTaxInfo} from '@components/AccountSettings/yupEditTaxInfo';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {IAddPaymentFormProps} from '@components/AccountSettings/interfaces';
import {
    TAX_INFO_LABELS,
    PAYMENT_AND_BILLING,
    formEditTaxInfo,
} from '@components/AccountSettings/constants';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import FormInput from '@components/AccountSettings/FormInput';
import FormSelect from '@components/AccountSettings/FormSelect';
import {taxCompany} from '@components/MyCompany/Redux/Actions/DetailsCompany';

const EditTaxInfoForm: React.FC<IAddPaymentFormProps> = ({onClose}) => {
    const dispatch = useDispatch();
    const [editError, setEditError] = useState(false);

    const postalInfo = useSelector((state: RootState) => state.GetPostalInfo?.postalInfo);
    const currentTaxData = useSelector((state: RootState) => state.GetCompanyTaxData?.taxData);
    const cityInfo = useSelector((state: RootState) => state.GetPostalInfo?.postalInfo);

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    const editTaxInfoErr = useSelector((state: RootState) => state.UpdateCompanyTaxData.error);
    const loading = useSelector((state: RootState) => state.UpdateCompanyTaxData.loading);
    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schemaEditTaxInfo),
        defaultValues: formEditTaxInfo,
    });

    const {
        register,
        reset,
        handleSubmit,
        watch,
        control,
        formState: {errors},
    } = formMethods;

    useEffect(() => {
        reset({
            rfc: currentTaxData.rfc,
        });

        if (editTaxInfoErr) setEditError(false);
    }, [currentTaxData, editTaxInfoErr, reset, dispatch, token]);

    const formattedStates: SelectOption[] =
        postalInfo.stateId && postalInfo.stateName
            ? [
                  {
                      value: postalInfo.stateId,
                      label: postalInfo.stateName,
                  },
              ]
            : [];

    const formattedCity: SelectOption[] =
        postalInfo.municipalityId && postalInfo.municipalityName
            ? [
                  {
                      value: postalInfo.municipalityId,
                      label: postalInfo.municipalityName,
                  },
              ]
            : [];

    let formatedColony: SelectOption[] = [];
    if (cityInfo.colonys) {
        formatedColony = cityInfo.colonys.map((nation: {colonyId: number; colonyName: string}) => ({
            value: nation.colonyId,
            label: nation.colonyName,
        }));
    }

    const handlePostalCodeBlur = () => {
        const postalCode = watch('cp');
        if (token && postalCode) {
            dispatch(GetPostalInfo(postalCode, token));
        }
    };

    const onSubmit = async (values: FieldValues) => {
        setEditError(false);
        const newTaxData: IUpdateTaxData = {
            companyId: companyId,
            claveRFC: values.rfc,
            postalCode: values.cp,
            streetName: values.street,
            internalNumber: values.internalNumber,
            externalNumber: parseInt(values.externalNumber),
            colonyId: parseInt(values.colonyId.value),
            email: values.email,
        };
        try {
            await dispatch(UpdateTaxData(newTaxData, token));
            await dispatch(taxCompany(token, companyId));
            onClose(false);
        } catch (error) {
            setEditError(true);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="400px"
                Height="100vh"
                Padding="24px"
                Justify="space-between"
                Align="center"
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="24px">
                    <Text FontSize="24px" FontWeight="500" Color="#2A2C2F">
                        {TAX_INFO_LABELS.TITLE}
                    </Text>
                    <ContainerFlex FlexDir="column" Justify="start" Align="flex-start" Gap="16px">
                        <FormInput
                            name="rfc"
                            label={TAX_INFO_LABELS.RFC}
                            control={control}
                            register={register}
                            error={errors.rfc?.message as string}
                            placeholder='Sin información'
                        />
                        <FormInput
                            name="email"
                            label={TAX_INFO_LABELS.EMAIL}
                            control={control}
                            register={register}
                            error={errors.email?.message as string}
                            placeholder='Sin información'
                        />
                        <Text FontSize="1rem" FontWeight="500" Color="#54575C">
                            {TAX_INFO_LABELS.ADDRESS}
                        </Text>
                        <FormInput
                            name="cp"
                            label={TAX_INFO_LABELS.POSTALCODE}
                            control={control}
                            register={register}
                            error={errors.cp?.message as string}
                            onBlur={handlePostalCodeBlur}
                            placeholder='Sin información'
                        />
                        <FormInput
                            name="street"
                            label={TAX_INFO_LABELS.STREET}
                            control={control}
                            register={register}
                            error={errors.street?.message as string}
                            placeholder='Sin información'
                        />
                        <ContainerFlex Align="flex-start" Height="60px" Gap="16px">
                            <FormInput
                                name="externalNumber"
                                label={TAX_INFO_LABELS.EXTERIOR_NUM}
                                control={control}
                                register={register}
                                error={errors.externalNumber?.message as string}
                                placeholder='Sin información'
                            />
                            <FormInput
                                name="internalNumber"
                                label={TAX_INFO_LABELS.INTERIOR_NUM}
                                mutedLabel={` (${TAX_INFO_LABELS.OPTIONAL})`}
                                control={control}
                                register={register}
                                error={errors.internalNumber?.message as string}
                                placeholder='Sin información'
                            />
                        </ContainerFlex>
                        <FormSelect
                            name="colonyId"
                            label={TAX_INFO_LABELS.COLONY}
                            options={formatedColony}
                            control={control}
                            error={errors.colonyId?.message as string}
                        />
                        <FormSelect
                            name="municipalityId"
                            label={TAX_INFO_LABELS.MUNICUPALITY}
                            options={formattedCity}
                            control={control}
                            error={errors.municipalityId?.message as string}
                        />
                        <FormSelect
                            name="stateId"
                            label={TAX_INFO_LABELS.STATE}
                            options={formattedStates}
                            control={control}
                            error={errors.stateId?.message as string}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="center" Gap="1rem" Height="4.831rem">
                    <Text Color="#FF6357" FontSize="1rem">
                        {errors['at-least-one'] && (errors['at-least-one']?.message as string)}
                        {editError && PAYMENT_AND_BILLING.TAX_INFO_ERROR}
                    </Text>
                    <ContainerFlex Justify="center" Align="center" Gap="24px" Height="40px">
                        <ButtonGenerals
                            Width="100%"
                            Height="40px"
                            Padding="8px 16px"
                            BackGC="transparent"
                            Justify="center"
                            Color="#FF6357"
                            HBackG="transparent"
                            FontWeight="500"
                            type="button"
                            Cursor="pointer"
                            onClick={() => {
                                onClose(false);
                            }}
                        >
                            {TAX_INFO_LABELS.CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals
                            Width="100%"
                            Height="40px"
                            Padding="8px 16px"
                            type="submit"
                            FontWeight="500"
                            disabled={loading}
                        >
                            {loading && <LoadingAtaskate width="0.625rem" padding="0.375rem" />}
                            {TAX_INFO_LABELS.SAVE}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerForm>
        </FormProvider>
    );
};

export default EditTaxInfoForm;
