import {
    PUT_EDIT_CATALOGUE,
    PUT_EDIT_CATALOGUE_SUCCESS,
    PUT_EDIT_CATALOGUE_ERROR,
} from '@components/AuctionPass/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
};

const putEditCatalogReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case PUT_EDIT_CATALOGUE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_EDIT_CATALOGUE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_EDIT_CATALOGUE_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default putEditCatalogReducer;
