import * as yup from 'yup';
import {TITLES} from '@/components/AuctionPass/AuctionConstants';

export const schemaArticle = yup.object().shape({
    name: yup.string().required(TITLES.REQUIRED),
    category: yup.object().shape({
        value: yup.string().required(TITLES.REQUIRED),
        label: yup.string().required(TITLES.REQUIRED),
    }),
    brand: yup.object().shape({
        value: yup.string().required(TITLES.REQUIRED),
        label: yup.string().required(TITLES.REQUIRED),
    }),
    model: yup.object().shape({
        value: yup.string().required(TITLES.REQUIRED),
        label: yup.string().required(TITLES.REQUIRED),
    }),
    sku: yup.string().required(TITLES.REQUIRED),
    color: yup.object().shape({
        value: yup.string().required(TITLES.REQUIRED),
        label: yup.string().required(TITLES.REQUIRED),
    }),
    condition: yup.object().shape({
        value: yup.string().required(TITLES.REQUIRED),
        label: yup.string().required(TITLES.REQUIRED),
    }),
    date: yup.string().required(TITLES.REQUIRED),
    ubication: yup.string().required(TITLES.REQUIRED),
    salePrice: yup.string().required(TITLES.REQUIRED),
    minAppartPrice: yup.string().required(TITLES.REQUIRED),
    minPrice: yup.string().required(TITLES.REQUIRED),
    minOffer: yup.string().required(TITLES.REQUIRED),
    minAppartPercent: yup.string().required(TITLES.REQUIRED),
    reason: yup.string().required(TITLES.REQUIRED),
});
