import React, {useEffect} from 'react';
import {Warehouse} from '@components/LocationsBranch/Warehouse';
import {useDispatch, useSelector} from 'react-redux';
import {getStorages} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {RootState} from '@/config/store';
import {ILocationStorageProps} from '@components/LocationsBranch/interfaces';

export const LocationStorage = ({
    isOptional,
    onClickTransfer,
    isEdit,
    setValue,
    errors,
}: ILocationStorageProps) => {
    const dispatch = useDispatch();
    const {token, levels, inventory} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        levels: state.getLevels.level,
        inventory: state.Warehouse.warehouses,
    }));
    const handleDataLoad = (filterId?: number) => {
        dispatch(getStorages({LevelId: levels.levelId}, token, filterId, true));
    };
    useEffect(() => {
        if (!isEdit && levels && levels.levelId && inventory.length === 0) handleDataLoad();
    }, [dispatch, token, levels?.levelId]);

    useEffect(() => {
        if (setValue) setValue('storages', inventory);
    }, [inventory]);

    return (
        <Warehouse
            isOptional={isOptional}
            onRefresh={handleDataLoad}
            onClickTransfer={onClickTransfer}
            errors={errors}
        />
    );
};
