import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {GET_BRANCH} from '@components/LocationsBranch/Redux/types';
import StringUtils from '@/GenericScripts/utils';
import {IGetBranches} from '@components/LocationsBranch/Redux/interfaces';

const getBranchByLevelStart = () => ({type: GET_BRANCH.BY_LEVEL_START});
const getBranchByLevelSuccess = (result: AxiosResponse) => ({
    type: GET_BRANCH.BY_LEVEL_SUCCESS,
    branchByLevel: result.data,
});
const getBranchByLevelError = (error: AxiosError) => ({
    type: GET_BRANCH.BY_LEVEL_ERROR,
    error: error,
});

export const resetBranchByLevel = () => ({
    type: GET_BRANCH.BY_LEVEL_RESET,
});

export const getBranchByLevel = (token: string, params: IGetBranches) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...params});
    return async (dispatch: AppDispatch) => {
        dispatch(getBranchByLevelStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchByLevelId}${queryString}`,
                {headers}
            );
            dispatch(getBranchByLevelSuccess(response.data));
        } catch (error) {
            dispatch(getBranchByLevelError(error as AxiosError));
        }
    };
};
