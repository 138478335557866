import React, {useState} from 'react';

import {Container, ContainerGeneral} from '@/components/Shopify/OptionMenu/styles/Styles';
import {Nav} from '@/components/Shopify/OptionMenu/styles/Styles';
import {IconContainer} from '@CreateBranch/CreateModal/style';

const NavMenu = (props: {key: number; titulo: string; ruta: string}) => {
    const {titulo, ruta} = props;
    const actualURL = window.location.href;
    const [Click, setClick] = useState(actualURL.slice(actualURL.indexOf('#'), 50) === ruta);

    return (
        <>
            <ContainerGeneral>
                <Container
                    Bg={Click}
                    Cursor="pointer"
                    onClick={() => {
                        setClick(true);
                        if (ruta !== '#') window.location.href = `${ruta}`;
                    }}
                    Width="auto"
                    ColorGreen
                >
                    <Container Padding="0 0.75rem">{titulo}</Container>
                    <IconContainer
                        className="material-icons"
                        FontSize="1.5rem"
                        Padding="0 0.75rem"
                        Color={Click ? '#35cf44' : '#6d6e71'}
                    >
                        {Click ? 'keyboard_arrow_left' : 'keyboard_arrow_right'}
                    </IconContainer>
                </Container>
            </ContainerGeneral>
        </>
    );
};

export const OptionMenu = () => {
    const menu = [
        {
            title: 'Datos',
            item: 1,
            route: '#/',
        },
        {
            title: 'Empeños',
            item: 2,
            route: '#/Endeavors',
        },
        {
            title: 'Desempeños',
            item: 3,
            route: '#/',
        },
    ];

    return (
        <>
            <Nav>
                {menu.map((element) => (
                    <NavMenu key={element.item} titulo={element.title} ruta={element.route} />
                ))}
            </Nav>
        </>
    );
};
