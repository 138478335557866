import {Controller} from 'react-hook-form';
import {useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {
    ContainerFlex,
    Text,
    Input,
    Image,
    ContainerGrid,
} from '@/components/Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    ADDING_TYPE_ACTION,
    TYPEDOCUMENTS,
    timeOptions,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import AddIcon from '@images/add - plus.svg';
import {RootState} from '@/config/store';
import {ITypeDocumentsProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {AddNewDocumentForm} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/AddNewDocumentForm';
import {
    IDocumentsCopyArray,
    ISelect,
} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';
import checkDisableCheckboxIcon from '@components/PersonalLoans/NewLoanProduct/icons/checkDisableCheckboxIcon.svg';

export const TypeDocuments: React.FC<ITypeDocumentsProps> = ({control, resetField, register}) => {
    const {documentOptions} = useSelector((state: RootState) => {
        return state.DocumentDisbursementReducer;
    });
    const [documentsCopy, setDocumentsCopy] = useState<IDocumentsCopyArray[]>([]);
    const [addDocuments, setAddDocuments] = useState<boolean>(false);
    const [mainCheckbox, setMainCheckbox] = useState<boolean>(false);
    const [lastDocumentLength, setLastDocumentLength] = useState<number>(0);
    const handleTextChange = (id: number, textField: string = '') => {
        const updatedArray = documentsCopy.map((item) => {
            if (item.id === id) {
                return {...item, seniorityJob: textField};
            }
            return item;
        });
        setDocumentsCopy(updatedArray);
    };
    const handleSeniorityTimeSelect = (id: number, seniorityJobTime: ISelect) => {
        const updatedArray = documentsCopy.map((item) => {
            if (item.id === id) {
                return {...item, seniorityJobTime};
            }
            return item;
        });
        setDocumentsCopy(updatedArray);
    };
    const handleCheckboxChange = (id: number) => {
        const newArrayIsChecked = documentsCopy.map((item) => {
            if (item.id === id) {
                return {...item, isChecked: !item.isChecked};
            }
            return item;
        });
        setDocumentsCopy(newArrayIsChecked);
    };
    const handleSelectAllCheckbox = () => {
        const allCheckedArray = documentsCopy.map((item) => {
            return {
                ...item,
                isChecked: item.documentId === 1 ? true : mainCheckbox,
            };
        });
        setDocumentsCopy(allCheckedArray);
    };
    useEffect(() => {
        handleSelectAllCheckbox();
    }, [mainCheckbox]);
    useEffect(() => {
        if (documentOptions.length === 0) return;
        const newDocumentsArray = documentOptions.map((item) => {
            return {
                id: item.documentId,
                documentId: item.documentId,
                docName: item.documentName,
                docDescription: item.documentDetails,
                seniorityJob: item.documentId === 3 ? '' : '0',
                seniorityJobTime: {label: '', value: 0},
                isChecked: item.documentId === 1 ? true : false,
            };
        });
        setDocumentsCopy(newDocumentsArray);
    }, [documentOptions]);
    useEffect(() => {
        if (documentsCopy.length > 0 && documentsCopy.length !== lastDocumentLength) {
            resetField('documents', {defaultValue: documentsCopy});
            setLastDocumentLength(documentsCopy.length);
        }
    }, [documentsCopy, lastDocumentLength]);
    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column">
            <ContainerGrid
                GridColumns="0.05fr 0.5fr 0.5fr"
                Align="center"
                Height="48px"
                Padding="0.5rem 1rem"
                BorderBt="solid 1px #E8E9EA"
            >
                <ContainerFlex Justify="start" Padding="0 8px">
                    <Checkbox
                        checked={mainCheckbox}
                        onChange={() => setMainCheckbox(!mainCheckbox)}
                    />
                </ContainerFlex>
                <Text
                    oFlow="normal"
                    wSpace="pre"
                    Color="#1D1E20"
                    FontSize="0.875rem"
                    FontWeight="700"
                >
                    {TYPEDOCUMENTS.DOCUMENT}
                </Text>
                <Text
                    oFlow="normal"
                    wSpace="pre"
                    Color="#1D1E20"
                    FontSize="0.875rem"
                    FontWeight="700"
                >
                    {TYPEDOCUMENTS.REQUIRED}
                    <Text oFlow="normal" wSpace="pre" FontSize="0.875rem" Color="#54575C">
                        {TYPEDOCUMENTS.OPTIONAL}
                    </Text>
                </Text>
                <ContainerFlex />
            </ContainerGrid>
            {documentsCopy.length > 0 &&
                documentOptions.length > 0 &&
                documentsCopy.map((item, index: number) => (
                    <ContainerGrid
                        key={index}
                        GridColumns="0.02fr 0.5fr 0.5fr"
                        Justify="start"
                        Height="70px"
                        Padding="0.5rem 1rem"
                        BorderBt="solid 1px #E8E9EA"
                        Gap="1rem"
                    >
                        <ContainerFlex Justify="start" Padding="0 8px">
                            {item.id === 1 ? (
                                <ContainerFlex
                                    backG="#E5E5FF"
                                    Width="18px"
                                    Height="18px"
                                    Radius="6px"
                                >
                                    <Image
                                        src={checkDisableCheckboxIcon}
                                        Width="16px"
                                        Height="16px"
                                    />
                                </ContainerFlex>
                            ) : (
                                <Controller
                                    name={`documents[${index}].isChecked`}
                                    control={control}
                                    render={({field: {onChange, ...field}}) => (
                                        <Checkbox
                                            {...field}
                                            checked={item.isChecked}
                                            onChange={(e) => {
                                                onChange(e);
                                                handleCheckboxChange(item.id);
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text Color="#2A2C2F">{item.docName}</Text>
                            <Text Color="#1D1E20" FontSize="0.875rem">
                                {item.docDescription}
                            </Text>
                        </ContainerFlex>
                        {item.docName === TYPEDOCUMENTS.JOB_DOC && (
                            <ContainerFlex>
                                <ContainerFlex Justify="center">
                                    <Text FontSize="0.875rem" Color="#2A2C2F" MarginRight="1rem">
                                        {TYPEDOCUMENTS.TIME}
                                    </Text>
                                    <Input
                                        Radius="32px 0 0 32px"
                                        type="number"
                                        Width="98px"
                                        placeholder="N.º"
                                        {...register(`documents[${index}].seniorityJob`)}
                                        onChange={(e) => {
                                            const {value} = e.target;
                                            handleTextChange(item.id, value);
                                        }}
                                    />
                                    <Controller
                                        name={`documents[${index}].seniorityJobTime`}
                                        control={control}
                                        render={({field: {value, onChange, ...field}}) => (
                                            <SelectGeneral
                                                {...field}
                                                options={timeOptions}
                                                value={value}
                                                borderRadius="0px 32px 32px 0px"
                                                border="1px solid #E8E9EA"
                                                background="#FFFFFF"
                                                width="150px"
                                                onChange={(e: ISelect) => {
                                                    onChange(e);
                                                    handleSeniorityTimeSelect(item.id, e);
                                                }}
                                            />
                                        )}
                                    />
                                </ContainerFlex>
                            </ContainerFlex>
                        )}
                    </ContainerGrid>
                ))}
            {addDocuments ? (
                <AddNewDocumentForm
                    addDocuments={addDocuments}
                    setAddDocuments={setAddDocuments}
                    documentsCopy={documentsCopy}
                    setDocumentsCopy={setDocumentsCopy}
                    typeAction={ADDING_TYPE_ACTION}
                />
            ) : (
                <ContainerFlex Justify="start" MarginTop="1rem" Gap="0.5rem" Height="1.5rem">
                    <Image
                        src={AddIcon}
                        alt="add-icon"
                        Cursor="pointer"
                        onClick={() => setAddDocuments(true)}
                    />
                    <Text
                        FontWeight="700"
                        Color="#5A5AFF"
                        Cursor="pointer"
                        onClick={() => setAddDocuments(true)}
                    >
                        {TYPEDOCUMENTS.ANOTHERDOC}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
