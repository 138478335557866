import React from 'react';

import {ContainerFlex, Text} from '@EcommerceShopify/styles';
import MyPaymentMethods from '@components/AccountSettings/MyPaymentMethods';
import MyTaxInformation from '@components/AccountSettings/MyTaxInformation';
import {PAYMENT_AND_BILLING} from '@components/AccountSettings/constants';

const PaymentAndBilling = () => {
    return (
        <ContainerFlex
            Align="start"
            Justify="start"
            FlexDir="column"
            Padding="16px 24px"
            Gap="16px"
            backG="#FAFAFA"
        >
            <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F" oFlow="visible">
                {PAYMENT_AND_BILLING.TITLE}
            </Text>
            <MyPaymentMethods />
            <MyTaxInformation />
        </ContainerFlex>
    );
};

export default PaymentAndBilling;
