import {useFormContext} from 'react-hook-form';
import React, {useState} from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import closeIcon from '@components/PersonalLoans/NewLoanProduct/icons/closeIcon.svg';
import {ICountLimitLoansProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {SearchInput} from '@/components/Customer/Styles';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';

export const CountLimitLoansField: React.FC<ICountLimitLoansProps> = ({
    name,
    showOptionalFields,
    setShowOptionalFields,
}) => {
    const {
        register,
        formState: {errors},
        resetField,
    } = useFormContext();
    const errorMessage = errors[name]?.message?.toString() || '';
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    return (
        <ContainerFlex
            Gap="0.25rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            gridColumnEnd="span 2"
        >
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.NO_LOANS_LIMIT}
                <Text Color="#54575C" MarginLeft="0.25rem">
                    {LOAN_DETAILS.FIELDS.OPTIONAL}
                </Text>
            </Text>
            <ContainerFlex Width="max-width" Height="max-content" Justify="start">
                <SearchInput Position="static" Width="335px" GridColumn="90% 10%" widthInput="100%">
                    <Input
                        {...register(name)}
                        type="number"
                        onKeyDown={(e) => {
                            if (e.key === 'e' || e.key === '+' || e.key === '-') {
                                e.preventDefault();
                            }
                        }}
                        Padding="0px"
                    />
                    <ContainerFlex
                        Width="24px"
                        Height="24px"
                        FlexDir="column"
                        Justify="start"
                        Position="relative"
                        onMouseEnter={() => setShowToolTip(true)}
                        onMouseLeave={() => setShowToolTip(false)}
                    >
                        <Image
                            src={questionIcon}
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                            alt="tooltip-icon"
                        />
                        {showToolTip && (
                            <ToolTip description={LOAN_DETAILS.TOOLTIPS.COUNT_LIMIT_LOANS} />
                        )}
                    </ContainerFlex>
                </SearchInput>
                <Image
                    src={closeIcon}
                    Cursor="pointer"
                    Width="24px"
                    Height="24px"
                    onClick={() => {
                        setShowOptionalFields({
                            ...showOptionalFields,
                            showNoLoanLimit: false,
                        });
                        resetField(`${name}`, {defaultValue: 0});
                    }}
                    alt="close-icon"
                />
            </ContainerFlex>
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </ContainerFlex>
    );
};
