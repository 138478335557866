import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {
    ContainerFlex,
    Text,
    Input,
    ContainerCellGrid,
    ContainerImg,
    Image,
} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import * as styles from '@/components/Quoter/stylesConstants';
import * as style from '@/components/Login/style';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import * as content from '@components/CreateUsers/Styles';
import {Icons} from '@CreateBranch/BranchInformation/style';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {CredentialDataProps} from '@components/CreateUsers/interfaces';
import {
    ACCESSCREDENTIALS,
    PIN,
    EMAILFORMAT,
    USER_TYPE,
    PASSWD,
} from '@components/CreateUsers/constants';
import {GetPasswordRandom} from '@components/CreateUsers/Redux/Actions/PostChangePassword';
import {credentials} from '@components/CreateUsers/Redux/Actions/saveUser';

export const CredentialAccess = ({
    register,
    getUserOptions,
    trigger,
    setValue,
    errors,
}: CredentialDataProps) => {
    const {credential, typeAction} = useSelector((state: RootState) => state.saveUser);
    const {editUsers} = useSelector((state: RootState) => state.editUsers);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const RandomPassword = useSelector((state: RootState) => state.PostChangePass.datapass);
    const optionsUserNames: {userName: string}[] = useSelector(
        (state: RootState) => state.GetOptionsUserNames.contact
    );
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const [addPin, setAddPin] = useState(true);
    const [selectedRole, setSelectedRole] = useState(0);
    const handleRoles = (newroleId: number) => {
        setSelectedRole(newroleId);
        dispatch(credentials({...credential, userType: newroleId}));
        setValue('userType', newroleId);
    };
    const handlerandomPass = () => dispatch(GetPasswordRandom(token));
    const {adminProfile} = useSelector((state: RootState) => state.adminUserProfileSettings);
    const CREDENTIALES = [
        {
            roleId: USER_TYPE.ADMIN,
            roleName: 'Administrativa',
            roleIcon: adminProfile.pictureAdministrator,
        },
        {
            roleId: USER_TYPE.POS,
            roleName: 'POS',
            roleIcon: adminProfile.picturePos,
        },
        {
            roleId: USER_TYPE.BOTH,
            roleName: 'Administrativa / POS',
            roleIcons: [adminProfile.pictureAdministrator, adminProfile.picturePos],
        },
    ];

    const isRepeatedDigit = (value: string) => {
        for (let i = 0; i < value.length - 2; i++) {
            const currentDigit = value[i];
            const nextTwoDigits = value.slice(i + 1, i + 3);
            if (nextTwoDigits === currentDigit.repeat(2)) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        if (RandomPassword) {
            setValue('accessCredentials.password', RandomPassword);
            trigger('accessCredentials.password');
        }
    }, [RandomPassword, trigger, setValue]);

    useEffect(() => {
        if (credential.userType) {
            handleRoles(credential.userType);
            setAddPin(credential.pin ? true : false);
        }
    }, [credential.userType]);

    return (
        <ContainerFlex {...content.contentSection}>
            <Text {...content.subtitles}>{ACCESSCREDENTIALS.TITLE}</Text>
            <Text {...content.instructions}>{ACCESSCREDENTIALS.DETAILS}</Text>
            <ContainerFlex Justify="start" Align="start" FlexDir="column">
                <Text {...content.instructions}>{ACCESSCREDENTIALS.SELECT}</Text>
                <ContainerFlex
                    Display="grid"
                    GridColumns="repeat(3,1fr)"
                    Justify="start"
                    Align="start"
                    Gap="1rem"
                >
                    {adminProfile &&
                        CREDENTIALES.map((role, index) => (
                            <ContainerFlex
                                key={index}
                                {...content.credential}
                                backG={selectedRole === role.roleId ? '#5A5AFF' : '#F4F5F5'}
                                onClick={() => handleRoles(role.roleId)}
                                Cursor="pointer"
                            >
                                {role.roleIcons ? (
                                    role.roleIcons.map((icon, index) => (
                                        <Image
                                            key={index}
                                            src={icon}
                                            {...content.credential.imgStart}
                                        />
                                    ))
                                ) : (
                                    <Image {...content.credential.imgAdd} src={role.roleIcon} />
                                )}
                                <Text
                                    {...content.credential.rolname}
                                    Color={selectedRole === role.roleId ? '#FFFFFF' : '#6D6E71'}
                                >
                                    {role.roleName}
                                </Text>
                            </ContainerFlex>
                        ))}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Display="grid" GridColumns="repeat(2,1fr)" Gap="1rem">
                <ContainerCellGrid {...content.personalData}>
                    <Text {...content.personalData.name}>{ACCESSCREDENTIALS.EMAIL}</Text>
                    <Input
                        autoComplete="off"
                        {...content.personalData.input}
                        {...register('accessCredentials.emailAdmin', {
                            required: {value: true, message: 'Campo obligatorio'},
                            pattern: {
                                value: EMAILFORMAT,
                                message: 'Indique formato de correo correcto: ejemplo@ejemplo.com',
                            },
                        })}
                        error={errors?.accessCredentials?.emailAdmin?.message}
                        disabled={typeAction === 'Edit' && !!editUsers.accessCredentials.email}
                        onChange={(e) =>
                            dispatch(
                                credentials({
                                    ...credential,
                                    accessCredentials: {
                                        ...credential.accessCredentials,
                                        emailAdmin: e.target.value,
                                    },
                                })
                            )
                        }
                    />
                    {errors?.accessCredentials?.emailAdmin?.message && (
                        <Text
                            Color="#ef4f55"
                            FontSize="0.75rem"
                            Align="start"
                            wSpace="normal"
                            Height="auto"
                            FontWeight="400"
                        >
                            {errors.accessCredentials?.emailAdmin?.message}
                        </Text>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    {...content.personalData}
                    Opacity={credential.userType === USER_TYPE.ADMIN ? '0.3' : ''}
                >
                    <Text {...content.personalData.name}>{ACCESSCREDENTIALS.USERNAME}</Text>
                    <Input
                        autoComplete="off"
                        {...content.personalData.input}
                        disabled={
                            (typeAction === 'Edit' && !!editUsers.accessCredentials.nameUser) ||
                            credential.userType === USER_TYPE.ADMIN
                        }
                        disabledBackG="#FFFFFF"
                        onClick={() => getUserOptions()}
                        Cursor="pointer"
                        {...register('accessCredentials.userName', {
                            required:
                                credential.userType !== USER_TYPE.ADMIN
                                    ? {
                                          value: true,
                                          message: 'Campo obligatorio.',
                                      }
                                    : undefined,
                        })}
                        error={errors.accessCredentials?.userName?.message}
                        onChange={(e) =>
                            dispatch(
                                credentials({
                                    ...credential,
                                    accessCredentials: {
                                        ...credential.accessCredentials,
                                        userName: e.target.value,
                                    },
                                })
                            )
                        }
                    />
                    {optionsUserNames[0] && (
                        <ContainerFlex {...content.sugestions}>
                            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                                Sugerencias nombre de usuario disponibles:
                                {optionsUserNames.slice(1, 4).map((user, index: number) => (
                                    <Text
                                        {...content.sugestions.name}
                                        key={index}
                                        onKeyUp={() => {
                                            if (trigger) trigger('accessCredentials.userName');
                                        }}
                                        onClick={() => {
                                            if (setValue) {
                                                setValue(
                                                    'accessCredentials.userName',
                                                    user.userName
                                                );
                                                dispatch(
                                                    credentials({
                                                        ...credential,
                                                        accessCredentials: {
                                                            ...credential.accessCredentials,
                                                            userName: user.userName,
                                                        },
                                                    })
                                                );
                                            }
                                        }}
                                        FontSize="0.75rem"
                                        Cursor="pointer"
                                    >
                                        {user.userName} {index < 2 && ' - '}
                                    </Text>
                                ))}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid {...content.personalData}>
                    <Text {...content.personalData.name}>{ACCESSCREDENTIALS.PASSWORD}</Text>
                    <SearchInput
                        {...style.searchInput}
                        Width="100%"
                        error={errors.accessCredentials?.password?.message}
                    >
                        <input
                            type={viewPassword ? 'Text' : 'Password'}
                            {...register('accessCredentials.password', {
                                required: {
                                    value: true,
                                    message: 'Campo obligatorio.',
                                },
                                maxLength: {
                                    value: 16,
                                    message: 'Indique máximo 16 caracteres.',
                                },
                                minLength: {
                                    value: 8,
                                    message:
                                        'Mínimo 8 caracteres, entre ellas 1 mayúscula, números y caracteres especiales.',
                                },
                                pattern: {
                                    value: PASSWD,
                                    message:
                                        'Incluya letras mayúsculas, minúsculas, números y caracteres especiales.',
                                },
                            })}
                            onChange={(e) =>
                                dispatch(
                                    credentials({
                                        ...credential,
                                        accessCredentials: {
                                            ...credential.accessCredentials,
                                            password: e.target.value,
                                        },
                                    })
                                )
                            }
                        />
                        <ContainerImg
                            onClick={() => setViewPassword(!viewPassword)}
                            Cursor="pointer"
                        >
                            <Image
                                src={viewPassword ? VisibilityOn : VisibilityOff}
                                alt="icon-warning"
                                Cursor="pointer"
                            />
                        </ContainerImg>
                    </SearchInput>
                    {errors?.accessCredentials?.password?.message && (
                        <Text
                            Color="#ef4f55"
                            FontSize="0.75rem"
                            Align="start"
                            wSpace="normal"
                            Height="auto"
                            FontWeight="400"
                        >
                            {errors.accessCredentials?.password?.message}
                        </Text>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid {...content.personalData}>
                    <Text {...content.personalData.name}>{ACCESSCREDENTIALS.REPEATPASSWORD}</Text>
                    <Input
                        autoComplete="off"
                        {...content.personalData.input}
                        type={viewPassword ? 'Text' : 'Password'}
                        {...register('accessCredentials.repeatPassword', {
                            required: {
                                value: true,
                                message: 'Campo obligatorio.',
                            },
                            maxLength: {
                                value: 16,
                                message: 'Indique máximo 16 caracteres.',
                            },
                            minLength: {
                                value: 8,
                                message:
                                    'Mínimo 8 caracteres, entre ellas 1 mayúscula, números y caracteres especiales.',
                            },
                            pattern: {
                                value: PASSWD,
                                message:
                                    'Incluya letras mayúsculas, minúsculas, números y caracteres especiales.',
                            },
                            validate: (value, {accessCredentials: {password}}) => {
                                if (value !== password) {
                                    return 'Las contraseñas no coinciden.';
                                }
                                return true;
                            },
                        })}
                        error={errors.accessCredentials?.repeatPassword?.message}
                        onChange={(e) =>
                            dispatch(
                                credentials({
                                    ...credential,
                                    accessCredentials: {
                                        ...credential.accessCredentials,
                                        repeatPassword: e.target.value,
                                    },
                                })
                            )
                        }
                    />
                    {errors?.accessCredentials?.repeatPassword?.message && (
                        <Text
                            Color="#ef4f55"
                            FontSize="0.75rem"
                            Align="start"
                            wSpace="normal"
                            Height="auto"
                            FontWeight="400"
                        >
                            {errors.accessCredentials?.repeatPassword?.message}
                        </Text>
                    )}
                </ContainerCellGrid>
            </ContainerFlex>
            <Text
                {...styles.openSection}
                Padding="0.75rem"
                Cursor="pointer"
                onClick={() => handlerandomPass()}
            >
                {ACCESSCREDENTIALS.GENERATE}
            </Text>
            <ContainerFlex
                Height="auto"
                Justify="start"
                Gap="1rem"
                Opacity={credential.userType === USER_TYPE.ADMIN ? '0.3' : ''}
            >
                <GreenSwitch
                    disabled={credential.userType === USER_TYPE.ADMIN}
                    checked={addPin}
                    onChange={() => setAddPin(!addPin)}
                />
                <Text FontSize="1rem" Color="#2A2C2F" FontWeight="500">
                    {PIN.DETAILS}
                </Text>
                <Icons className="material-icons" FontSize="1.5rem" Color="#5A5AFF">
                    help_outline
                </Icons>
            </ContainerFlex>
            <ContainerCellGrid
                {...content.personalData}
                Width="21.438rem"
                Opacity={credential.userType === USER_TYPE.ADMIN ? '0.3' : ''}
            >
                <Text {...content.personalData.name}>{PIN.PIN}</Text>
                <Input
                    autoComplete="off"
                    type="number"
                    {...content.personalData.input}
                    {...register('pin', {
                        required: addPin ? 'Campo obligatorio.' : false,
                        minLength: {
                            value: 4,
                            message: 'El pin debe tener al menos 4 caracteres.',
                        },
                        validate: (value: string) => {
                            if (addPin && isRepeatedDigit(value)) {
                                return 'No se permite la repetición del mismo dígito.';
                            }
                            return true;
                        },
                    })}
                    disabled={credential.userType === USER_TYPE.ADMIN || !addPin}
                    disabledBackG="#FFFFFF"
                    onChange={(e) => dispatch(credentials({...credential, pin: e.target.value}))}
                    onInput={(e) => {
                        const value = e.currentTarget.value.slice(0, 6);
                        setValue('pin', value);
                    }}
                    error={errors?.pin?.message}
                />
                {errors?.pin?.message && (
                    <Text
                        Color="#ef4f55"
                        FontSize="0.75rem"
                        Align="start"
                        wSpace="normal"
                        Height="auto"
                        FontWeight="400"
                    >
                        {errors?.pin?.message}
                    </Text>
                )}
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
