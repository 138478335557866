import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import AdminNavbarLogo from '@images/AdminNavbarLogo.svg';
import SuperAdminNavbarLogo from '@images/AtaskateSuperAdmin.png';

import hamburgerNavbar from '@images/hamburgerNavbar.svg';
import {AccountMenu} from '@components/AdminNavbar/AccountMenu';
import {INavbarProps} from '@components/AdminNavbar/interfaces';
import {NAV_SEARCH_PLACEHOLDER} from '@components/AdminNavbar/constants';
import {IconContainer, IconSvg} from '@/components/Quoter/styles';
import {infoAdminUserProfile} from '@components/AdminNavbar/Redux/adminUserProfileSettingsActions';
import ataskatePOSLogo from '@images/AtaskatePos.svg';
import {SearchGeneral} from '@components/NavBarPOS/SearchComponent/SearchGeneral';
import {infoposUserProfile} from '@components/NavBarPOS/Redux/Action/PosAdminNavInfo';
import {FastActionsMenu} from '@components/AdminNavbar/FastActionsMenu';
import {TYPE_USERS} from '@components/AdminNavbar/constants';
import {Notifications} from '@components/AdminNavbar/Notification/Notification';
import CartViewToggle from '@components/AdminNavbar/CartViewToggle';
import closeIcon from '@/images/closeBlueIcone.svg';

export const AdminNavbar: React.FC<INavbarProps> = ({
    isAdmin,
    openMenuPos,
    setOpenMenuPos,
    cartToggle,
}) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);
    const [openFastMenu, setOpenFastMenu] = useState(false);
    const [serachValue, setSearchValue] = useState('');
    const userType = useSelector((state: RootState) => state.getUsersValidation.userData?.userType);

    const openMenuAccount = () => {
        setOpenMenu(!openMenu);
    };
    const openNotificationAccount = () => {
        setOpenNotifications(!openNotifications);
    };
    const clickAwayFastMenu = () => {
        setOpenFastMenu(false);
    };
    const changeValue = (e: {target: {value: string}}) => {
        setSearchValue(e.target.value);
    };
    const handleClickAwayNotifications = () => {
        setOpenNotifications(false);
    };
    const handleClickAway = () => {
        setOpenMenu(false);
    };
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const {adminProfile} = useSelector((state: RootState) => state.adminUserProfileSettings);
    const posProfile = useSelector((state: RootState) => state.getNavBarPos);
    const dispatch = useDispatch();
    const TypeUser = () => {
        switch (userType) {
            case TYPE_USERS.ADMIN:
                return AdminNavbarLogo;
            case TYPE_USERS.POS:
                return ataskatePOSLogo;
            case TYPE_USERS.SUPER_ADMIN:
                return SuperAdminNavbarLogo;
            case TYPE_USERS.ADMIN_ERROR:
                return SuperAdminNavbarLogo;
        }
    };
    useEffect(() => {
        if (token) {
            if (userType === TYPE_USERS.POS) dispatch(infoposUserProfile(token));
            else dispatch(infoAdminUserProfile(token));
        }
    }, [userType]);

    const isSuper = userType === 'superAdministrador' || userType === 'superAdministrator';

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex
                Width="100%"
                Height="3.5rem"
                backG=" #E5E5FF"
                Padding="0.5rem 1.5rem"
                Display="flex"
                Justify="space-between"
                Gap="0.5rem"
            >
                {!isSuper && (
                    <Image
                        alt="menu-hamburger-navbar"
                        src={
                            isAdmin ? (openMenuPos ? closeIcon : hamburgerNavbar) : hamburgerNavbar
                        }
                        Width={isAdmin ? '30px' : ''}
                        Height={isAdmin ? '30px' : ''}
                        Cursor="pointer"
                        onClick={() => {
                            setOpenMenuPos(!openMenuPos);
                        }}
                    />
                )}
                <Image
                    alt="admin-navbar-logo"
                    src={TypeUser()}
                    onClick={() => {
                        window.location.href = '/#/';
                    }}
                    Width="auto"
                    Cursor="pointer"
                />
                <ContainerFlex>
                    <SearchGeneral
                        variant={'nav'}
                        hasQR={!isAdmin}
                        placeholder={
                            isAdmin
                                ? NAV_SEARCH_PLACEHOLDER.ADMIN_PLACEHOLDER
                                : NAV_SEARCH_PLACEHOLDER.EMPLOYEE_PLACEHOLDER
                        }
                        value={serachValue}
                        onChange={changeValue}
                        handleClose={() => setSearchValue('')}
                    />
                </ContainerFlex>

                <ContainerFlex Gap=".75rem" Width="auto">
                    <ContainerFlex
                        Width="2.5rem"
                        Height="2.5rem"
                        backG={openFastMenu ? '#ACACFF' : '#FFFFFF'}
                        Radius="2.3125rem"
                        Padding="0.5rem"
                        Cursor="pointer"
                        onClick={() => {
                            if (!isAdmin) {
                                setOpenFastMenu(true);
                            }
                        }}
                    >
                        <IconSvg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            Cursor="pointer"
                        >
                            {isAdmin ? (
                                <path
                                    id="chat_add_on"
                                    d="M3 20V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H17C17.55 3 18.0208 3.19583 18.4125 3.5875C18.8042 3.97917 19 4.45 19 5V10.075C18.8333 10.0417 18.6667 10.0208 18.5 10.0125C18.3333 10.0042 18.1667 10 18 10C17.8333 10 17.6667 10.0042 17.5 10.0125C17.3333 10.0208 17.1667 10.0417 17 10.075V5H5V15H12.075C12.0417 15.1667 12.0208 15.3333 12.0125 15.5C12.0042 15.6667 12 15.8333 12 16C12 16.1667 12.0042 16.3333 12.0125 16.5C12.0208 16.6667 12.0417 16.8333 12.075 17H6L3 20ZM7 9H15V7H7V9ZM7 13H12V11H7V13ZM17 20V17H14V15H17V12H19V15H22V17H19V20H17Z"
                                    fill="#5A5AFF"
                                />
                            ) : (
                                <path
                                    d="M11.0001 19V13H5.00006V11H11.0001V5H13.0001V11H19.0001V13H13.0001V19H11.0001Z"
                                    fill={openFastMenu ? '#FFFFFF' : '#5A5AFF'}
                                />
                            )}
                        </IconSvg>
                    </ContainerFlex>
                    {openFastMenu && <FastActionsMenu setOpenFastMenu={clickAwayFastMenu} />}

                    {cartToggle.showCartToggle && (
                        <CartViewToggle
                            isCartOpen={cartToggle.isCartOpen}
                            setCartView={cartToggle.setCartView}
                        />
                    )}

                    <ContainerFlex
                        Width="2.5rem"
                        Height="2.5rem"
                        backG={openNotifications ? '#ACACFF' : '#FFFF'}
                        Radius="2.3125rem"
                        Padding="0.5rem"
                        Cursor="pointer"
                        onClick={() => openNotificationAccount()}
                    >
                        <IconSvg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="20"
                            viewBox="0 0 16 20"
                            fill="none"
                            Cursor="pointer"
                        >
                            <path
                                d="M0 17V15H2V8C2 6.61667 2.41667 5.38733 3.25 4.312C4.08333 3.23733 5.16667 2.53333 6.5 2.2V1.5C6.5 1.08333 6.646 0.729333 6.938 0.438C7.22933 0.146 7.58333 0 8 0C8.41667 0 8.77067 0.146 9.062 0.438C9.354 0.729333 9.5 1.08333 9.5 1.5V2.2C10.8333 2.53333 11.9167 3.23733 12.75 4.312C13.5833 5.38733 14 6.61667 14 8V15H16V17H0ZM8 20C7.45 20 6.97933 19.8043 6.588 19.413C6.196 19.021 6 18.55 6 18H10C10 18.55 9.80433 19.021 9.413 19.413C9.021 19.8043 8.55 20 8 20ZM4 15H12V8C12 6.9 11.6083 5.95833 10.825 5.175C10.0417 4.39167 9.1 4 8 4C6.9 4 5.95833 4.39167 5.175 5.175C4.39167 5.95833 4 6.9 4 8V15Z"
                                fill="#5A5AFF"
                            />
                        </IconSvg>
                    </ContainerFlex>
                    <ContainerFlex
                        Width="5rem"
                        Padding="0.25rem 0.5rem"
                        Gap="0.5rem"
                        backG={openMenu ? '#ACACFF' : '#FFFF'}
                        Radius="2rem"
                        Cursor="pointer"
                        onClick={() => openMenuAccount()}
                    >
                        <Image
                            ObjectFit="contain"
                            alt="user-image"
                            src={
                                userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.picture
                                    : adminProfile && adminProfile.picture
                            }
                            width="32px"
                            height="32px"
                            Radius="50%"
                        />

                        <IconContainer Transform={openMenu ? 'rotate(180deg)' : 'rotate(0deg)'}>
                            <IconSvg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                Cursor="pointer"
                            >
                                <path
                                    d="M5.7 9.70005L7.1 8.30005L11.7 12.9L16.3 8.30005L17.7 9.70005L11.7 15.7L5.7 9.70005Z"
                                    fill={openMenu ? '#FAFAFF' : '#5A5AFF'}
                                />
                            </IconSvg>
                        </IconContainer>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {openMenu && <AccountMenu handleClickAway={handleClickAway} />}
            {openNotifications && (
                <Notifications handleClickAwayNotifications={handleClickAwayNotifications} />
            )}
        </ContainerFlex>
    );
};
