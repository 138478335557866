import {LEVEL_GROUP_TYPES as TYPE} from '@components/LocationsBranch/Redux/types';
import {LevelGroupsState, LevelGroupsAction} from '@components/LocationsBranch/Redux/interfaces';

const initialState: LevelGroupsState = {
    groupTypes: [],
    fillingGroups: false,
};

const levelGroups = (state = initialState, action: LevelGroupsAction): LevelGroupsState => {
    switch (action.type) {
        case TYPE.INIT_GROUPS: {
            const existingGroupTypeIndex = state.groupTypes.findIndex(
                (gt) => gt.groupType === action.groupType
            );
            if (existingGroupTypeIndex !== -1) {
                const updatedGroupTypes = [...state.groupTypes];
                updatedGroupTypes[existingGroupTypeIndex] = {
                    ...updatedGroupTypes[existingGroupTypeIndex],
                    levelGroups: action.payload,
                };
                return {
                    ...state,
                    groupTypes: updatedGroupTypes,
                };
            } else {
                return {
                    ...state,
                    groupTypes: [
                        ...state.groupTypes,
                        {
                            groupType: action.groupType,
                            levelGroups: action.payload,
                            selectedLevel: {levelId: 0, levelName: '', breadcrum: null},
                        },
                    ],
                };
            }
        }
        case TYPE.UPDATE_GROUP: {
            return {
                ...state,
                groupTypes: state.groupTypes.map((groupType) =>
                    groupType.groupType === action.groupType
                        ? {
                              ...groupType,
                              levelGroups: groupType.levelGroups.map((group) =>
                                  group.group === action.group
                                      ? {...group, levels: action.groupLevels, fetching: false}
                                      : group
                              ),
                          }
                        : groupType
                ),
            };
        }
        case TYPE.SELECT_GROUP_LEVEL: {
            return {
                ...state,
                groupTypes: state.groupTypes.map((groupType) =>
                    groupType.groupType === action.groupType
                        ? {
                              ...groupType,
                              levelGroups: groupType.levelGroups.map((group) =>
                                  group.group === action.group
                                      ? {...group, selectedLevelId: action.selectedLevel.levelId}
                                      : group.group > action.group
                                      ? {...group, levels: [], selectedLevelId: 0, fetching: false}
                                      : group
                              ),
                          }
                        : groupType
                ),
            };
        }
        case TYPE.CURRENT_SELECTED_LEVELID: {
            return {
                ...state,
                groupTypes: state.groupTypes.map((groupType) =>
                    groupType.groupType === action.groupType
                        ? {
                              ...groupType,
                              selectedLevel: action.selectedLevel,
                          }
                        : groupType
                ),
            };
        }
        case TYPE.FILLING_GROUP: {
            return {
                ...state,
                groupTypes: state.groupTypes.map((groupType) =>
                    groupType.groupType === action.groupType
                        ? {
                              ...groupType,
                              levelGroups: groupType.levelGroups.map((group) =>
                                  group.group === action.group ? {...group, fetching: true} : group
                              ),
                          }
                        : groupType
                ),
            };
        }
        case TYPE.ADD_LEVEL_GROUP: {
            const {groupType, groupLevels, group} = action;
            const updatedGroupTypes = state.groupTypes.map((groupTypeObj) => {
                if (groupTypeObj.groupType === groupType) {
                    const truncatedLevelGroups = groupTypeObj.levelGroups.filter(
                        (groupObj) => groupObj.group <= group
                    );

                    const maxGroup = truncatedLevelGroups.reduce(
                        (max, currentGroup) => Math.max(max, currentGroup.group),
                        0
                    );
                    const newGroupNumber = maxGroup + 1;

                    return {
                        ...groupTypeObj,
                        levelGroups: [
                            ...truncatedLevelGroups,
                            {
                                group: newGroupNumber,
                                levels: groupLevels,
                                selectedLevelId: 0,
                                fetching: false,
                            },
                        ],
                    };
                }
                return groupTypeObj;
            });

            return {
                ...state,
                groupTypes: updatedGroupTypes,
            };
        }
        case TYPE.RESET_STATE: {
            return initialState;
        }
        default:
            return state;
    }
};

export default levelGroups;
