import {
    GET_CAT_GROUP,
    GET_CAT_GROUP_ERROR,
    GET_CAT_GROUP_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {ICatGruop} from '@components/RequestNewCatalogLevel/Redux/Reducers/GetCatGroups';
import axios, {AxiosError} from 'axios';

export const getCatGroup = () => {
    return {
        type: GET_CAT_GROUP,
    };
};
export const getCatGroupSuccess = (result: ICatGruop[]) => {
    return {
        type: GET_CAT_GROUP_SUCCESS,
        payload: result,
    };
};

export const getCatGroupError = (error: AxiosError) => {
    return {
        type: GET_CAT_GROUP_ERROR,
        payload: error,
    };
};

export const getAuctionGroupAction = (companyId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCatGroup());
        const params = {
            companyId: companyId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAuctionGroups}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getCatGroupSuccess(response.data.data));
        } catch (error) {
            dispatch(getCatGroupError(error as AxiosError));
        }
    };
};
