import {AppDispatch} from '@/config/store';
import {
    PUT_PAYMENT_DEFAULT,
    PUT_PAYMENT_DEFAULT_ERROR,
    PUT_PAYMENT_DEFAULT_SUCCESS,
} from '@components/AccountSettings/Redux/types/Types';
import {URL} from '@config/constants/index';
import axios, {AxiosError} from 'axios';

const PutPaymentDefault = () => {
    return {type: PUT_PAYMENT_DEFAULT};
};
const PutPaymentDefaultSuccess = () => {
    return {type: PUT_PAYMENT_DEFAULT_SUCCESS};
};

const PutPaymentDefaultError = (error: AxiosError) => {
    return {type: PUT_PAYMENT_DEFAULT_ERROR, error: error};
};

export function PutPaymentDefaultAction(paymentId: number, token: string) {
    return async (dispatch: AppDispatch) => {
        const headers = {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        };
        dispatch(PutPaymentDefault());
        const params = {
            paymentId: paymentId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutPaymentMethodDefault}?${queryString}`;

        try {
            const response = await axios.put(url, {}, {headers});
            if (response.status) {
                dispatch(PutPaymentDefaultSuccess());
            }
        } catch (error) {
            dispatch(PutPaymentDefaultError(error as AxiosError));
            throw error;
        }
    };
}
