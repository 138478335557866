import {ROUTER} from '@components/AdminNavbar/types';
import {IRouter} from '@components/AdminNavbar/interfaces';

const initialState: IRouter = {
    Route: '/login',
};

const getRoute = (state = initialState, action: {type: string; router: string}) => {
    switch (action.type) {
        case ROUTER:
            return {...state, Route: action.router};

        default:
            return state;
    }
};
export default getRoute;
