import {AppDispatch} from '@/config/store';
import {
    GET_ROLE_USERS,
    GET_ROLE_USERS_SUCCESS,
    GET_ROLE_USERS_ERROR,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@/config/constants/index';

export const getRoleUsers = () => {
    return {
        type: GET_ROLE_USERS,
    };
};

export const getRoleUsersSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ROLE_USERS_SUCCESS,
        payload: result,
    };
};

export const getRoleUsersError = (error: AxiosError) => {
    return {
        type: GET_ROLE_USERS_ERROR,
        payload: error,
    };
};

type IRoleUserData = {
    companyId: number;
    searchName?: string;
    pageSize: number;
    pageNumber: number;
    filterUser?: string | undefined;
    orderType?: number;
};

export const GetRoleUsersAction = (data: IRoleUserData, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const params = {
        companyId: data.companyId.toString(),
        searchName: data.searchName ? data.searchName.toString() : '',
        pageSize: data.pageSize.toString(),
        pageNumber: data.pageNumber.toString(),
        filterUser: data.filterUser ? data.filterUser.toString() : '',
        orderType: data.orderType ? data.orderType.toString() : '',
    };
    const queryString = new URLSearchParams(params).toString();
    return async (dispatch: AppDispatch) => {
        dispatch(getRoleUsers());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRoleUsers}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getRoleUsersSuccess(response));
        } catch (error) {
            dispatch(getRoleUsersError(error as AxiosError));
        }
    };
};
