import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    UPDATE_STATUS_ALL_USERS,
    UPDATE_STATUS_ALL_USERS_SUCCESS,
    UPDATE_STATUS_ALL_USERS_ERROR,
} from '@components/Users/Redux/types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

export const updateStatusUsers = () => {
    return {
        type: UPDATE_STATUS_ALL_USERS,
    };
};
export const updateStatusUsersSuccess = (result: AxiosResponse) => {
    return {
        type: UPDATE_STATUS_ALL_USERS_SUCCESS,
        payload: result,
    };
};
export const updateStatusUsersError = (error: AxiosError) => {
    return {
        type: UPDATE_STATUS_ALL_USERS_ERROR,
        error: error,
    };
};
export function updateStatusAllUsers(token: string, data: {userId: number}) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(updateStatusUsers());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateStatus}`,
                data,
                {headers}
            );
            dispatch(updateStatusUsersSuccess(response));
        } catch (error) {
            dispatch(updateStatusUsersError(error as AxiosError));
        }
    };
}
