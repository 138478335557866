import React, {useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {EXPENSE_DETAIL_COMPONENT} from '@/components/CashFlowExpenseDetails/operational/constants';
import {UploadFile} from '@/components/CashFlowExpenseDetails/UploadFile';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const AttchmentExpenseDetails = () => {
    const [selectedInvoice, setSelectedInvoice] = useState<string | null>(null);
    const [selectedReceipt, setSelectedReceipt] = useState<string | null>(null);

    const expenseDetailData = useSelector(
        (state: RootState) => state.getExpenseDetails.expenseDetails
    );

    return (
        <ContainerFlex Padding="16px 24px" FlexDir="column" Gap="16px">
            <ContainerFlex Justify="start">
                <Text Color=" #2A2C2F" FontWeight="500" FontSize="1.25rem">
                    {EXPENSE_DETAIL_COMPONENT.ATTACHMENTS}
                </Text>
            </ContainerFlex>
            <ContainerFlex Justify="start">
                <Text FontSize="1rem" FontWeight="400" Color="#54575C">
                    {EXPENSE_DETAIL_COMPONENT.MB}
                </Text>
            </ContainerFlex>

            <ContainerFlex Gap="8px" FlexDir="column" Align="start">
                <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                    {EXPENSE_DETAIL_COMPONENT.VOUCHER}
                    <Text PaddingL="5px" FontSize="1rem" Color="#A1A5AA">
                        {EXPENSE_DETAIL_COMPONENT.OP}
                    </Text>
                </Text>
                <UploadFile
                    selectedFile={selectedInvoice}
                    setSelectedFile={setSelectedInvoice}
                    backendFileName={expenseDetailData.receiptFileName}
                    needReplaceButton={false}
                />
            </ContainerFlex>

            <ContainerFlex Gap="8px" FlexDir="column" Align="start">
                <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                    {EXPENSE_DETAIL_COMPONENT.BILL}
                </Text>
                <UploadFile
                    selectedFile={selectedReceipt}
                    setSelectedFile={setSelectedReceipt}
                    backendFileName={expenseDetailData.invoiceFileName}
                    needReplaceButton={false}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
