import React from 'react';
import {IPropsInput} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {
    HelperText,
    InputContainer,
    InputImage,
    InputWrapper,
    StyledInput,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/style';

export const GeneralsInput = React.forwardRef<
    HTMLInputElement,
    IPropsInput & React.InputHTMLAttributes<HTMLInputElement>
>(({hasError, helperText, error, Img, ...props}, ref) => {
    const isError = Boolean(hasError || error);
    const errorMessage = helperText || (typeof error === 'string' ? error : '');
    return (
        <InputContainer>
            <InputWrapper>
                <StyledInput ref={ref} isError={isError} hasImg={!!Img} error={error} {...props} />
                {Img && <InputImage src={Img} alt="input-icon" />}
            </InputWrapper>
            {errorMessage && <HelperText isError={isError}>{errorMessage}</HelperText>}
        </InputContainer>
    );
});
GeneralsInput.displayName = 'Input';
export default GeneralsInput;
