import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {URL} from '@config/constants/index';

import {GET_HOLIDAYS_LOCATION_ID} from '@components/LocationsBranch/Redux/types';
import {IBranchHolidays} from '@components/LocationsBranch/interfaces';

export const getHolidaysLocationStart = () => ({
    type: GET_HOLIDAYS_LOCATION_ID.HOLIDAYS_LOCATION_START,
});

export const getHolidaysLocationSuccess = (result: {data: AxiosResponse}) => ({
    type: GET_HOLIDAYS_LOCATION_ID.HOLIDAYS_LOCATION_SUCCESS,
    payload: {
        holidaysLocation: result.data,
    },
});

export const getHolidaysLocationError = (error: AxiosError) => ({
    type: GET_HOLIDAYS_LOCATION_ID.HOLIDAYS_LOCATION_ERROR,
    error: error,
});

export const getHolidaysLocation = (
    data: {
        LocationId: number;
        TypeLocation: number;
    },
    token: string,
    setHolidays: (data: IBranchHolidays[]) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getHolidaysLocationStart());
        const queryString = StringUtils.jsonToQueryString({...data});

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetHolydaysByLocationId}${queryString}`,
                {headers}
            );
            dispatch(getHolidaysLocationSuccess(response.data));
            setHolidays(response.data.data);
        } catch (error) {
            dispatch(getHolidaysLocationError(error as AxiosError));
        }
    };
};
