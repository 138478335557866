import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DUE_DETAILS} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/constants';
import {FeePaymentTable} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/FeePaymentTable';
import {MovementsHistorialTable} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/MovementsHistorialTable';
import {IOptionType} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/interfaces';
export const TermsDetails = () => {
    const {creditId: personalCreditId} = useParams<{creditId?: string}>();
    const [tabHeader, setTabHeader] = useState(DUE_DETAILS.TAB_HEADERS.PAYMENT);
    const filterOptions: IOptionType[] = [
        {label: DUE_DETAILS.FILTER_OPTIONS.ALL.LABEL, value: DUE_DETAILS.FILTER_OPTIONS.ALL.VALUE},
        {
            label: DUE_DETAILS.FILTER_OPTIONS.PAID.LABEL,
            value: DUE_DETAILS.FILTER_OPTIONS.PAID.VALUE,
        },
        {
            label: DUE_DETAILS.FILTER_OPTIONS.UNPAID.LABEL,
            value: DUE_DETAILS.FILTER_OPTIONS.UNPAID.VALUE,
        },
        {
            label: DUE_DETAILS.FILTER_OPTIONS.OVERDUE.LABEL,
            value: DUE_DETAILS.FILTER_OPTIONS.OVERDUE.VALUE,
        },
    ];

    return (
        <ContainerFlex Gap="1rem" FlexDir="column" Justify="start">
            <ContainerFlex Height="max-content" Justify="start">
                <Text Color="#000" FontSize="1.25rem" FontWeight="700" Height="28px" Width="217px">
                    {DUE_DETAILS.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex Justify="start" Height="max-content">
                <Text
                    Padding="0.5rem 1rem"
                    Height="40px"
                    Width="max-content"
                    wSpace="nowrap"
                    oFlow="visible"
                    Cursor="Pointer"
                    Color={tabHeader === DUE_DETAILS.TAB_HEADERS.PAYMENT ? '#5A5AFF' : '#2A2C2F'}
                    FontWeight={tabHeader === DUE_DETAILS.TAB_HEADERS.PAYMENT ? '700' : '400'}
                    BorderBt={
                        tabHeader === DUE_DETAILS.TAB_HEADERS.PAYMENT
                            ? '1.2px solid #5A5AFF'
                            : '1.2px solid #E8E9EA'
                    }
                    onClick={() => setTabHeader(DUE_DETAILS.TAB_HEADERS.PAYMENT)}
                >
                    {DUE_DETAILS.TAB_HEADERS.PAYMENT}
                </Text>
                <Text
                    Padding="0.5rem 1rem"
                    Height="40px"
                    wSpace="nowrap"
                    oFlow="visible"
                    Color={
                        tabHeader === DUE_DETAILS.TAB_HEADERS.MOVEMENTS_HISTORY
                            ? '#5A5AFF'
                            : '#2A2C2F'
                    }
                    FontWeight={
                        tabHeader === DUE_DETAILS.TAB_HEADERS.MOVEMENTS_HISTORY ? '700' : '400'
                    }
                    BorderBt={
                        tabHeader === DUE_DETAILS.TAB_HEADERS.MOVEMENTS_HISTORY
                            ? '1.2px solid #5A5AFF'
                            : '1.2px solid #E8E9EA'
                    }
                    Cursor="Pointer"
                    onClick={() => setTabHeader(DUE_DETAILS.TAB_HEADERS.MOVEMENTS_HISTORY)}
                >
                    {DUE_DETAILS.TAB_HEADERS.MOVEMENTS_HISTORY}
                </Text>
                <ContainerFlex Bb="1.2px solid #E8E9EA" MinH="40px" />
            </ContainerFlex>

            {tabHeader === DUE_DETAILS.TAB_HEADERS.PAYMENT && (
                <FeePaymentTable
                    filterOptions={filterOptions}
                    personalCreditId={Number(personalCreditId)}
                />
            )}
            {tabHeader === DUE_DETAILS.TAB_HEADERS.MOVEMENTS_HISTORY && (
                <MovementsHistorialTable
                    filterOptions={filterOptions}
                    personalCreditId={Number(personalCreditId)}
                />
            )}
        </ContainerFlex>
    );
};
