import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ClickAwayListener} from '@mui/material';

import {SearchInput} from '@/components/Customer/Styles';
import {REQUESTS_ARTICLE} from '@Quoter/Search/constants';
import {IconSvg} from '@/components/Quoter/styles';
import {IGroupInput} from '@Quoter/Search/interfaces';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IFamily} from '@Quoter/inferfaces';
import {MessageInactiveArticle} from '@Quoter/Search/messageInactiveArticle';
import * as style from '@/components/Quoter/stylesConstants';
import {SubfamilyRequest} from '@Quoter/Redux/Actions/PropiertiesArticleRequest';

export const FamilyInput = ({
    register,
    setValue,
    errors,
    token,
    branchId,
    watch,
    reset,
    requiredFields,
    labelValues,
    setLabelValues,
    setIsOpcional,
    inputView,
}: IGroupInput) => {
    const {family} = useSelector((state: RootState) => state.propertiesArticleRequest);
    const [viewList, setView] = useState(false);
    const [newLevel, setNewLevel] = useState(false);

    const [provisionalValue, setProvisionalVlue] = useState('');
    const dispatch = useDispatch();

    const handleSearch = (data: IFamily) => {
        if (watch('Brand') || watch('Article') || watch('SubFamily')) {
            reset({Brand: '', Article: ''});
        }
        setValue('Family', String(data.familyId));
        setLabelValues({
            ...labelValues,
            Article: '',
            Brand: '',
            SubFamily: '',
            Family: data.nameFamily,
        });
        dispatch(SubfamilyRequest(token, {branchId: branchId, familyId: data.familyId}));
        setView(false);
    };
    const handleNew = (data: string) => {
        setProvisionalVlue(data);
    };

    return (
        <ContainerFlex
            Justify="start"
            Height="auto"
            Width="100%"
            FlexDir="column"
            Gap="4px"
            Align="start"
        >
            <ContainerFlex ColumnGap="8px" Align="start" Justify="start" Height="12px">
                <Text FontSize="0.75rem" Color="#54575C" FontWeight="500">
                    {REQUESTS_ARTICLE.SECOND_LEVEL}
                </Text>
                {requiredFields.Family && (
                    <Text color="#A1A5AA" FontSize="0.75rem">
                        {REQUESTS_ARTICLE.OPTIONAL}
                    </Text>
                )}
            </ContainerFlex>
            <SearchInput
                Height="40px"
                GridColumn="95% 5%"
                FontSize="1rem"
                PlaceholderColor="#A1A5AA"
                Right=""
                WBoxShadow=""
            >
                {inputView.Family ? (
                    <input
                        type="text"
                        autoComplete="off"
                        defaultValue={labelValues.Family}
                        {...register('Family')}
                        disabled={inputView ? false : true}
                    />
                ) : (
                    <Text
                        FontSize={labelValues.Family.length > 0 ? '1rem' : '0.875rem'}
                        Color={labelValues.Family.length > 0 ? '#54575C' : '#A1A5AA'}
                    >
                        {labelValues.Family.length > 0 ? labelValues.Family : 'Selecciona'}
                    </Text>
                )}
                {inputView.Family ? null : (
                    <IconSvg
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setView(!viewList);
                        }}
                    >
                        <path
                            d="M5.7002 9.70005L7.10019 8.30005L11.7002 12.9L16.3002 8.30005L17.7002 9.70005L11.7002 15.7L5.7002 9.70005Z"
                            fill="#0D166B"
                        />
                    </IconSvg>
                )}
                {viewList && (
                    <ClickAwayListener
                        onClickAway={() => {
                            setView(false);
                            setNewLevel(false);
                        }}
                    >
                        <ContainerFlex
                            Position="absolute"
                            Height=""
                            Width="100%"
                            PositionTop="100%"
                            BoxShadow=""
                            BoxS="0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
                            backG="#fff"
                            FlexDir="column"
                            OverFlow="auto"
                            Justify="start"
                            MaxH="13rem"
                            Radius="0.5rem"
                            ZIndex="2"
                            Border={'1px solid #F3F3F3'}
                        >
                            {!newLevel ? (
                                <ContainerFlex
                                    {...style.contentList}
                                    Justify="start"
                                    HBackground="#f0f0ff"
                                    HBorderLeft="2px solid #5a5aff"
                                    Cursor="pointer"
                                    onClick={() => setNewLevel(true)}
                                >
                                    <Text Color="#5a5aff" FontSize="0.875rem" Cursor="pointer">
                                        Nuevo nivel
                                    </Text>
                                    <IconSvg Width="24px" Height="24px" cursor="pointer">
                                        <path
                                            d="M11 19V13H5V11H11V5H13V11H19V13H13V19H11Z"
                                            fill="#5A5AFF"
                                        />
                                    </IconSvg>
                                </ContainerFlex>
                            ) : (
                                <ContainerFlex Padding="8px">
                                    <SearchInput
                                        Height="40px"
                                        GridColumn="95% 5%"
                                        FontSize="1rem"
                                        Right=""
                                        PlaceholderColor="#A1A5AA"
                                        WBoxShadow=""
                                    >
                                        <input
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e: {target: {value: string}}) => {
                                                handleNew(e.target.value);
                                            }}
                                        />
                                        <IconSvg
                                            Width="24px"
                                            Height="24px"
                                            Cursor="pointer"
                                            onClick={() => {
                                                setValue('Family', provisionalValue);
                                                setView(false);
                                                setNewLevel(false);
                                                setIsOpcional(2);
                                            }}
                                        >
                                            <path
                                                d="M9.54961 18L3.84961 12.3L5.27461 10.875L9.54961 15.15L18.7246 5.97498L20.1496 7.39998L9.54961 18Z"
                                                fill="#5A5AFF"
                                            />
                                        </IconSvg>
                                    </SearchInput>
                                </ContainerFlex>
                            )}
                            {family &&
                                family.familyData.length > 0 &&
                                family.familyData.map((item: IFamily, index: number) =>
                                    item.familyId ? (
                                        <ContainerFlex
                                            key={index}
                                            {...style.contentList}
                                            onClick={() => handleSearch(item)}
                                            HBackground="#f0f0ff"
                                            HBorderLeft="2px solid #5a5aff"
                                        >
                                            <Text {...style.wordSearch}>{item.nameFamily}</Text>
                                        </ContainerFlex>
                                    ) : (
                                        index === 0 && <MessageInactiveArticle key={index} />
                                    )
                                )}
                        </ContainerFlex>
                    </ClickAwayListener>
                )}
            </SearchInput>
            {errors.SubFamily?.message && (
                <Text Color="#FF6357" FontSize=".75rem" MarginTop="4px">
                    {errors.SubFamily.message}
                </Text>
            )}
        </ContainerFlex>
    );
};
