import React from 'react';

import {numberToCurrency} from '@/hooks/currentFormatUtils';
import useFormatDate from '@/hooks/useFormatDate';
import {RenewalExtensionProps} from '@components/ShoppingCart/interface';
import {ContainerImg, Image, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {ENDORSEMENTS, OPTIONS} from '@components/ShoppingCart/constants';
import RenewalExtensionMenu from '@components/ShoppingCart/RenewalMenuOption/RenewalExtensionMenu';
import moreIcon from '@images/MoreMenuBlack.svg';
import arrowIcon from '@images/arrowTopLeft.svg';

const RenewalExtension: React.FC<RenewalExtensionProps> = ({
    isMenuOpen,
    endorsement,
    onMenuToggle,
    onSelectOption,
    onRemoveExtension,
    selectedSubMenuOption,
    onChangeToPayment,
}) => {
    const extension = endorsement.extension!;

    const isActive = isMenuOpen && selectedSubMenuOption === OPTIONS.EXTENSION;

    const handleOptionSelected = (option: string) => {
        onSelectOption(option);
    };

    return (
        <>
            <FlexPanel
                Padding="1rem 0.5rem"
                Justify="flex-end"
                Align="center"
                Gap="1.5rem"
                Self="stretch"
                Radius="0 0 0.25rem 0.25rem"
                Background={isActive ? '#FAFAFF' : '#FFFFFF'}
                Width="24rem"
            >
                <FlexPanel Align="center" Justify="flex-start" Gap="0.5rem" Flex="1 0 0">
                    <ContainerImg Height="3.5rem" Width="3.813rem">
                        <Image src={arrowIcon} alt="arrow" Width="0.688rem" Height="0.688rem" />
                    </ContainerImg>
                    <FlexPanel FlexDir="column" Justify="center" Align="flex-start" Gap="0.5rem">
                        <Text
                            Color="#1D1E20"
                            FontSize="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {ENDORSEMENTS.EXTENSION}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontSize="0.75rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {`${extension.numberDays} ${ENDORSEMENTS.DAYS}: ${useFormatDate(
                                extension.nextPaymentDate
                            )}`}
                        </Text>
                    </FlexPanel>
                </FlexPanel>
                <Text Color="#1D1E20" FontSize="1rem" FontWeight="700" LetterSpacing="0.019rem">
                    {numberToCurrency(extension.subExtension)}
                </Text>
                <Image
                    src={moreIcon}
                    alt="extend-options"
                    Cursor="pointer"
                    Width="1.5rem"
                    Height="1.5rem"
                    onClick={() => {
                        onMenuToggle(OPTIONS.EXTENSION);
                    }}
                />
            </FlexPanel>
            {isActive && (
                <RenewalExtensionMenu
                    endorsement={endorsement}
                    onSelectOption={handleOptionSelected}
                    onRemoveExtension={onRemoveExtension}
                    onChangeToPayment={onChangeToPayment}
                />
            )}
        </>
    );
};
export default RenewalExtension;
