import React, {useEffect, useMemo, useState} from 'react';
import {PaginatedForTransactionTable} from '@/components/CashFlow/PaginatedForTransactionTable';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {RootState} from '@/config/store';
import deleteIcon from '@/images/deleteRoleIcon.svg';
import {EditBreadCrumb} from '@components/RolesPermissions/EditRole/EditBreadCrumb';
import {BreadCrumb} from '@components/RolesPermissions/NewRole/BreadCrumb';
import ChangeRoleName from '@components/RolesPermissions/NewRole/ChangeRoleName';
import {
    breadCrumb,
    buttonsCreateRole,
    FIRST_PAGE,
    header,
    newRole,
    userList,
} from '@components/RolesPermissions/NewRole/constants';
import {IEditFormProps, IListData} from '@components/RolesPermissions/NewRole/interfaces';
import ModuleSwitch from '@components/RolesPermissions/NewRole/ModuleSwitch';
import UserList from '@components/RolesPermissions/NewRole/UserList';
import deleteRoleAction from '@components/RolesPermissions/Redux/Actions/DeleteRoleAction';
import GetRoleByIdAction, {
    GetRoleByIdReset,
} from '@components/RolesPermissions/Redux/Actions/GetRoleByIdAction';
import PostNewRoleAction, {
    IPostNewRoleProps,
} from '@components/RolesPermissions/Redux/Actions/PostNewRoleAction';
import PutUpdateRoleAction from '@components/RolesPermissions/Redux/Actions/PutUpdateRoleAction';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ITEM_PER_PAGE} from '@components/RolesPermissions/NewRole/constants';

const NewRole = () => {
    return <EditForm isEdit={false} Title={breadCrumb.newRole} />;
};

export const EditForm = ({isEdit, Title}: IEditFormProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );
    const selectedRole = useSelector((state: RootState) => state.GetRoleById.role);
    const memoizedSelectedRole = useMemo(() => selectedRole, [selectedRole]);

    const methods = useForm<IPostNewRoleProps>({
        defaultValues: {
            companyId: companyId,
            name: isEdit ? selectedRole.name : '',
            adminModules: [],
            operationalModules: [],
            users: [],
        },
    });

    const [listData, setListData] = useState<IListData>({
        adminModules: [],
        operationalModules: [],
        users: [],
    });
    const [headerOptions, setHeaderOptions] = useState<string>();
    const [currentPage, setCurrentPage] = useState<number>(FIRST_PAGE);
    const userData = useSelector((state: RootState) => state.GetRoleUsers.users);
    const {setError, formState, reset} = methods;

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    const roleToedit = useSelector((state: RootState) => state.RoleToEdit.number);

    const handlePrevPage = () => {
        if (currentPage <= FIRST_PAGE) {
            setCurrentPage(FIRST_PAGE);
        } else setCurrentPage(currentPage - FIRST_PAGE);
    };
    const handleNextPage = () => {
        setCurrentPage(currentPage + FIRST_PAGE);
    };

    const handleNavigatePage = (sectionId: string, e: React.MouseEvent) => {
        e.preventDefault();
        setHeaderOptions(sectionId);
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    };

    const handleDelete = () => {
        dispatch(deleteRoleAction(roleToedit, navigate, token));
    };

    const onSubmit: SubmitHandler<IPostNewRoleProps> = async (data: IPostNewRoleProps) => {
        if (companyId && data.name.length >= 2) {
            const dataToCreate = {
                ...data,
                users: listData.users,
                operationalModules: listData.operationalModules,
                adminModules: listData.adminModules,
            };
            if (!isEdit) {
                await dispatch(PostNewRoleAction(dataToCreate, navigate, token));
            } else {
                const editData = {
                    ...data,
                    id: roleToedit,
                    users: listData.users,
                    operationalModules: listData.operationalModules,
                    adminModules: listData.adminModules,
                };
                await dispatch(PutUpdateRoleAction(editData, navigate, token));
            }
        } else {
            setError('name', {type: 'manual'});
        }
    };

    useEffect(() => {
        if (isEdit) {
            dispatch(GetRoleByIdAction(roleToedit, token));
        } else {
            dispatch(GetRoleByIdReset());
        }
    }, [isEdit, roleToedit, dispatch, token]);

    useEffect(() => {
        reset({
            companyId: companyId,
            name: isEdit ? selectedRole?.name : '',
            adminModules: [],
            operationalModules: [],
            users: [],
        });
    }, [selectedRole, companyId, isEdit, reset]);

    return (
        <FormProvider {...methods}>
            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                backG="#FAFAFA"
                Padding="30px"
                Height=""
                Gap="15px"
            >
                {isEdit ? <EditBreadCrumb /> : <BreadCrumb />}

                <Text FontWeight="700" FontSize="1.5rem" MinH="2rem">
                    {Title}
                </Text>
                <ContainerFlex
                    backG="white"
                    Height="40px"
                    Radius="25px"
                    BoxS="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                >
                    <ContainerFlex
                        Cursor="pointer"
                        onClick={(e) => {
                            handleNavigatePage(header.name, e);
                        }}
                        backG={headerOptions === header.name ? '#E5E5FF' : 'white'}
                        Radius="40px"
                    >
                        <Text Cursor="pointer">{header.name}</Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Cursor="pointer"
                        onClick={(e) => {
                            handleNavigatePage(header.permissions, e);
                        }}
                        backG={headerOptions === header.permissions ? '#E5E5FF' : 'white'}
                        Radius="30px"
                    >
                        <Text Cursor="pointer">{header.permissions}</Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Cursor="pointer"
                        onClick={(e) => {
                            handleNavigatePage(header.assigned, e);
                        }}
                        backG={headerOptions === header.assigned ? '#E5E5FF' : 'white'}
                        Radius="30px"
                    >
                        <Text Cursor="pointer">{header.assigned}</Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ChangeRoleName isEdit={isEdit} error={formState.errors.name} />
                <ModuleSwitch
                    isEdit={isEdit}
                    listData={listData}
                    setListData={setListData}
                    selectedRole={memoizedSelectedRole}
                />
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    backG="white"
                    Border="1px solid #E8E9EA"
                    Radius="16px"
                    Padding="20px"
                    Gap="10px"
                    Height=""
                    id={header.assigned}
                >
                    <Text FontWeight="700" FontSize="1.2rem" MinH="2rem">
                        {userList.title}
                    </Text>
                    <Text MinH="2rem">{userList.subTitle}</Text>
                    <UserList
                        pageNumber={currentPage}
                        isEdit={isEdit}
                        setListData={setListData}
                        listData={listData}
                        selectedRole={memoizedSelectedRole}
                    />
                    <ContainerFlex>
                        <PaginatedForTransactionTable
                            pageNumber={ITEM_PER_PAGE * currentPage - 4}
                            totalPage={userData.totalItems}
                            firstPage={() => setCurrentPage(FIRST_PAGE)}
                            lastPage={() => setCurrentPage(userData.totalPages)}
                            totalItems={
                                userData.data && userData.data.length < ITEM_PER_PAGE
                                    ? userData.totalItems
                                    : ITEM_PER_PAGE * currentPage
                            }
                            nextPage={handleNextPage}
                            prevPage={handlePrevPage}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                {isEdit && (
                    <ContainerFlex
                        Gap="10px"
                        Padding="15px"
                        backG="white"
                        Height="5rem"
                        Border="1px solid #E8E9EA"
                        Radius="16px"
                        Justify="start"
                    >
                        <ContainerFlex
                            Justify="start"
                            Width="20%"
                            Cursor="pointer"
                            Gap="10px"
                            onClick={handleDelete}
                        >
                            <Image src={deleteIcon} alt="delete" />
                            <Text Color="#A82424" Cursor="pointer">
                                {newRole.deleteRole}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}
                <ContainerFlex
                    Gap="20px"
                    backG="white"
                    Height="7rem"
                    Border="1px solid #E8E9EA"
                    Radius="16px"
                >
                    <ButtonGenerals
                        Width="10rem"
                        BackGC="white"
                        Border="1px solid #5A5AFF"
                        Color="#5A5AFF"
                        HBackG="rgb(241, 241, 241)"
                        onClick={() => navigate('/Roles')}
                    >
                        {buttonsCreateRole.cancel}
                    </ButtonGenerals>
                    <ButtonGenerals Width="10rem" onClick={methods.handleSubmit(onSubmit)}>
                        {isEdit ? buttonsCreateRole.edit : buttonsCreateRole.save}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </FormProvider>
    );
};
export default NewRole;
