import React, {useEffect} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import FilterDropdown from '@/components/MyAccount/MyCredit/FilterDropdown';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    setFilterParams,
    resetFilterParams,
    getCreditProfileFilters,
    getCardCreditsClientProfile,
    setVisualDate,
    setFiltersParamsVisual,
} from '@/components/MyAccount/MyCredit/Redux/Actions/MyCreditActions';
import FilterDate from '@/components/MyAccount/MyCredit/FilterDate';
import {FilterModalProps, FilterParams} from '@/components/MyAccount/interfaces';
import {FILTER_ACTION_BTN, FILTER_LABELS, FILTER_NAMES} from '@/components/MyAccount/constants';
import {SearchInput} from '@/components/Customer/Styles';
import {formatDate} from '@components/CreditsAndPawns/FilterModal/FilterDatePicker';
import FilterLabelWrapper from '@/components/MyAccount/MyCredit/FilterLabelWrapper';

const FilterModal = ({setOpenFilterModal}: FilterModalProps) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch = useDispatch();
    const {filterValues, filterParams, visualDate, filterParamsVisual} = useSelector(
        (state: RootState) => state.MyCreditReducer
    );
    const handleFilter = (filterParams: FilterParams) => {
        dispatch(setFilterParams(filterParams));
    };
    const verifyNumber = (value: string) => {
        if (isNaN(+value)) {
            return '';
        }
        return value;
    };
    const getFilterResultHandler = () => {
        dispatch(getCreditProfileFilters(token, filterParams));
        setOpenFilterModal(false);
    };
    const clearFilterHandler = () => {
        dispatch(resetFilterParams());
        dispatch(getCardCreditsClientProfile(token));
        setOpenFilterModal(false);
    };
    const setSelectedDate = (date: Date | null) => {
        dispatch(setVisualDate(date));
    };

    const closeModal = () => {
        dispatch(setFilterParams(filterParamsVisual));
        dispatch(setVisualDate(filterParamsVisual.requestedDate as Date));
        setOpenFilterModal(false);
    };

    useEffect(() => {
        dispatch(
            setFilterParams({
                ...filterParams,
                requestedDate: visualDate ? formatDate(new Date(visualDate)) : null,
            })
        );
        dispatch(setFiltersParamsVisual(filterParams));
    }, [visualDate]);
    return (
        <ContainerFlex
            backG="#FFFFFF"
            Padding="24px"
            Radius="16px"
            FlexDir="column"
            Gap="16px"
            Justify="flex-start"
            Align="flex-start"
        >
            <ContainerFlex Justify="space-between" Align="center" Height="24px">
                <ButtonGenerals
                    BackGC="transparent"
                    HBackG="transparent"
                    Height="24px"
                    Padding="0"
                    onClick={clearFilterHandler}
                    Cursor="pointer"
                >
                    <Text
                        Color="#5A5AFF"
                        FontWeight="700"
                        FontFamily="Nunito"
                        FontSize="0.875rem"
                        Cursor="pointer"
                    >
                        {FILTER_ACTION_BTN.CLEAR}
                    </Text>
                </ButtonGenerals>
                <ButtonGenerals
                    BackGC="transparent"
                    HBackG="transparent"
                    Height="24px"
                    Padding="0"
                    onClick={closeModal}
                    Cursor="pointer"
                >
                    <Text Color="#2A2C2F" FontWeight="700" FontFamily="Nunito" Cursor="pointer">
                        {FILTER_ACTION_BTN.CLOSE}
                    </Text>
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Align="center" Height="68px">
                <FilterLabelWrapper title={FILTER_LABELS.STATUS}>
                    <FilterDropdown
                        options={filterValues.estadoSolicitud}
                        name={FILTER_NAMES.STATUS}
                        handleFilter={handleFilter}
                        value={filterParams.statusId}
                        filterParams={filterParams}
                    />
                </FilterLabelWrapper>
                <FilterLabelWrapper title={FILTER_LABELS.COMPANY}>
                    <FilterDropdown
                        options={filterValues.company}
                        name={FILTER_NAMES.COMPANY}
                        handleFilter={handleFilter}
                        value={filterParams.companyId}
                        filterParams={filterParams}
                    />
                </FilterLabelWrapper>
                <FilterLabelWrapper title={FILTER_LABELS.REQUESTED_DATE}>
                    <FilterDate
                        filterName={FILTER_NAMES.REQUESTED_DATE}
                        filters={filterParams}
                        handleFilter={handleFilter}
                        value={visualDate}
                        setSelectDate={setSelectedDate}
                    />
                </FilterLabelWrapper>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Align="center" Height="68px">
                <FilterLabelWrapper title={FILTER_LABELS.REQUESTED_AMOUNT}>
                    <SearchInput Right="unset">
                        <input
                            type="text"
                            value={filterParams.requestAmount}
                            onChange={(e) =>
                                handleFilter({
                                    ...filterParams,
                                    requestAmount: verifyNumber(e.target.value),
                                })
                            }
                        />
                    </SearchInput>
                </FilterLabelWrapper>
                <ButtonGenerals
                    Padding="4px 16px"
                    Height="32px"
                    FontSize="0.875rem"
                    FontWeight="700"
                    MT="32px"
                    onClick={getFilterResultHandler}
                    Cursor="pointer"
                >
                    {FILTER_ACTION_BTN.APPLY}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default FilterModal;
