import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_APPROVED_CREDIT_SOLICITUDE_ERROR,
    GET_APPROVED_CREDIT_SOLICITUDE_START,
    GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS,
    CLEAR_APPROVED_CREDIT_REQUEST,
    INITIAL_APPROVED_CREDIT_REQUEST,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IApprovedCreditSolicitudeAction} from '@components/CreditsAndPawns/interfaces';

const getRequestedCreditSolicitudeStart = () => {
    return {
        type: GET_APPROVED_CREDIT_SOLICITUDE_START,
    };
};

const getRequestedCreditSolicitudeSuccess = (result: AxiosResponse, isCreditApproved: boolean) => {
    return {
        type: GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS,
        payload: result,
        isCreditApproved,
    };
};

const getRequestedCreditSolicitudeError = (error: AxiosError) => {
    return {
        type: GET_APPROVED_CREDIT_SOLICITUDE_ERROR,
        error: error,
    };
};

export const getRequestedCreditSolicitudeAction = ({
    token,
    amountApprobate,
    solicitudeId,
    isApproved,
}: IApprovedCreditSolicitudeAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRequestedCreditSolicitude}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const data = {amountApprobate, solicitudeId, isApproved};
    return async (dispatch: AppDispatch) => {
        dispatch(getRequestedCreditSolicitudeStart());
        try {
            const response = await axios.post(url, data, {headers});
            dispatch(getRequestedCreditSolicitudeSuccess(response.data, isApproved));
        } catch (e) {
            dispatch(getRequestedCreditSolicitudeError(e as AxiosError));
        }
    };
};

export const clearApprovedCreditRequest = () => {
    return {
        type: CLEAR_APPROVED_CREDIT_REQUEST,
    };
};

export const initialApprovedCreditRequest = () => {
    return {
        type: INITIAL_APPROVED_CREDIT_REQUEST,
    };
};
