import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {
    CREDIT_DETAILS_INFORMATION,
    CREDIT_STATUS,
} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import loansBtnIcon from '@/components/PersonalLoans/icons/loansBtaIcon.svg';
import {
    ICreditStatus,
    IPersonalCreditDetailsReducer,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import CreditDetailsMessage from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsMessage';
import CreditDetailsInformationStatusButtons from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformationStatusButtons';

const CreditDetailsInformationStatus: React.FC<ICreditStatus> = ({
    creditStatus,
    hasButtons,
    cta_primary,
    cta_secondary,
}) => {
    const {personalCreditAmountData} = useSelector((state: RootState) => {
        return state.GetPersonalCreditAmountReducer as IPersonalCreditDetailsReducer;
    });
    return (
        <ContainerFlex Width="100%" Height="100%" FlexDir="column" Gap="24px">
            <ContainerFlex Width="100%" Justify="space-between" Align="center">
                <ContainerFlex FlexDir="column" Align="start" Justify="center">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.50rem" Color="#000000">
                        {`${CREDIT_DETAILS_INFORMATION.STATUS.SUB_TITLE} ${personalCreditAmountData?.crontactNumber}`}
                    </Text>
                </ContainerFlex>
                <ContainerFlex MaxW="150px" FlexDir="column" Align="start" Justify="end" Gap="8px">
                    <Image Width="147px" Height="37px" src={loansBtnIcon} />
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#5A5AFF"
                        Cursor="pointer"
                    >
                        {CREDIT_DETAILS_INFORMATION.STATUS.CTA_VISIT_STORE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {(creditStatus === CREDIT_STATUS.APPROVED ||
                creditStatus === CREDIT_STATUS.UNDER_REVIEW ||
                creditStatus === CREDIT_STATUS.REJECTED) && (
                <CreditDetailsMessage creditStatus={creditStatus} />
            )}
            <ContainerFlex
                Padding="16px"
                Border="1px solid #0D166B"
                backG="#fff"
                Width="100%"
                Height="100%"
                Flex="0"
                Radius="16px"
                Justify="space-between"
            >
                <ContainerFlex Width="100%" Align="start" Justify="start">
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Width="116px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                        >
                            {numberToCurrencyWithoutCents(
                                (personalCreditAmountData?.totalPaid as number) ?? 0
                            )}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.STATUS.PAID}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Width="116px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                        >
                            {numberToCurrencyWithoutCents(
                                personalCreditAmountData?.indebtedness ?? 0
                            )}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.STATUS.OWE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Width="116px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                        >
                            {personalCreditAmountData?.quotesCount}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.STATUS.FEES}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <CreditDetailsInformationStatusButtons
                    creditStatus={creditStatus}
                    hasButtons={hasButtons}
                    cta_primary={cta_primary}
                    cta_secondary={cta_secondary}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailsInformationStatus;
