import {AppDispatch} from '@/config/store';
import {
    DELETE_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD_ERROR,
    DELETE_PAYMENT_METHOD_SUCCESS,
} from '@components/AccountSettings/Redux/types/Types';
import {URL} from '@config/constants/index';
import axios, {AxiosError} from 'axios';

const deletePaymentMethod = () => {
    return {type: DELETE_PAYMENT_METHOD};
};
const deletePaymentMethodSuccess = () => {
    return {type: DELETE_PAYMENT_METHOD_SUCCESS};
};

const deletePaymentMethodError = (error: AxiosError) => {
    return {type: DELETE_PAYMENT_METHOD_ERROR, error: error};
};

export function deletePaymentMethodAction(paymentId: number, token: string) {
    return async (dispatch: AppDispatch) => {
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        };
        dispatch(deletePaymentMethod());
        const params = {
            paymentId: paymentId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteCompanyPaymentMethod}?${queryString}`;

        try {
            await axios.delete(url, {headers});
            dispatch(deletePaymentMethodSuccess());
        } catch (error) {
            dispatch(deletePaymentMethodError(error as AxiosError));
            throw error;
        }
    };
}
