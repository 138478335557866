import {
    GET_PRODUCTS_CUSTOMER_USER_START,
    GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
    GET_PRODUCTS_CUSTOMER_USER_ERROR,
    SET_ACTIVE_STATUS,
    GET_FILTER_LOAN_PRODUCTS_START,
    GET_FILTER_LOAN_PRODUCTS_SUCCESS,
    GET_FILTER_LOAN_PRODUCTS_ERROR,
    GET_SEARCH_LOAN_PRODUCTS_START,
    GET_SEARCH_LOAN_PRODUCTS_SUCCESS,
    GET_SEARCH_LOAN_PRODUCTS_ERROR,
    RESET_SEARCH_RESULTS,
    SET_PAGE_NUMBER,
    RESET_PAGE_NUMBER,
} from '@components/LoansProducts/Redux/Types/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IProductsCustomerUserInitialState} from '@components/LoansProducts/interfaces';
import {TABLE_FILTERS} from '@/components/LoansProducts/constants';

const initialState: IProductsCustomerUserInitialState = {
    data: [],
    filteredData: [],
    searchResults: [],
    loading: false,
    error: false,
    message: '',
    activeStatus: TABLE_FILTERS[0].value,
    page: 1,
    errorData: null,
    totalItems: 0,
    totalPages: 0,
    areFilters: false,
};

export const getProductsCustomerUserReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
        activeStatus: number;
        pageNumber: number;
    }
) => {
    switch (action.type) {
        case GET_PRODUCTS_CUSTOMER_USER_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PRODUCTS_CUSTOMER_USER_SUCCESS:
            return {
                ...state,
                data: action.payload.data ? action.payload.data.data : action.payload,
                totalItems: action.payload.data?.totalItems,
                totalPages: action.payload.data?.totalPages,
                areFilters: false,
                loading: false,
                error: false,
            };
        case GET_PRODUCTS_CUSTOMER_USER_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_FILTER_LOAN_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_FILTER_LOAN_PRODUCTS_SUCCESS:
            return {
                ...state,
                filteredData: action.payload.data.data,
                totalItems: action.payload.data.totalItems,
                totalPages: action.payload.data.totalPages,
                message: action.payload.data.message,
                error: false,
                data: action.payload.data.data,
                areFilters: true,
            };
        case GET_FILTER_LOAN_PRODUCTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case GET_SEARCH_LOAN_PRODUCTS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_SEARCH_LOAN_PRODUCTS_SUCCESS:
            return {
                ...state,
                searchResults: action.payload.data.data,
                message: action.payload.data.message,
                loading: false,
                error: false,
            };
        case GET_SEARCH_LOAN_PRODUCTS_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };

        case SET_ACTIVE_STATUS:
            return {
                ...state,
                activeStatus: action.activeStatus,
                page: 1,
            };
        case RESET_SEARCH_RESULTS:
            return initialState;
        case SET_PAGE_NUMBER:
            return {
                ...state,
                page: action.pageNumber,
            };
        case RESET_PAGE_NUMBER:
            return {
                ...state,
                page: 1,
            };
        default:
            return state;
    }
};
