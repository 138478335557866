import {
    UPDATE_INFO_USER,
    UPDATE_INFO_USER_SUCCESS,
    UPDATE_INFO_USERS_ERROR,
} from '@components/Users/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    error: null,
};

export const PutUpdateInfoUser = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_INFO_USER:
            return {...state, loading: true};
        case UPDATE_INFO_USER_SUCCESS:
            return {...state, loading: false};
        case UPDATE_INFO_USERS_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
