import {
    ScreenTableData,
    ScreenTableDataAccess,
} from './UserProfileBranchAssignment/Operational/typeInterface';

export const TITLES_USER = {
    NAME: 'Rogelio Molina Calva',
    NUMBER: '+52 55 1784 0623 ',
    EMAIL: 'afernandez@ejemplo.com',
    DATE: 'Creado el ',
    DATE_VALUE: '2 de enero 2021',
    HOUR: 'Ultima hora de acceso:',
    HOUR_VALUE: 'Hace 3 horas',
    ROL: 'Rol: ',
    ROL_VALUE: 'Valuador',
    PLACE: 'Ubicaciones: ',
    PLACE_VALUE: 'Coyuya 3+',
    STATE: 'Estado',
    STATE_VALUE: 'Activo',
    USER_PROFILE: 'Perfil',
    LAST_ACTIVE: 'Activo hace ',
    PHONE: 'Tel:',
    USER_EMAIL: 'E-mail: ',
};

export const genderOptions = [
    {label: 'Femenino', value: 1},
    {label: 'Masculino', value: 2},
    {label: 'Otro', value: 3},
];

interface IUser {
    name: string;
    Ubication: string;
}
export interface IDelete {
    setShow: (e: boolean) => void;
}
export const UserUbication: IUser[] = [
    {name: 'Juan Carlos', Ubication: 'Cholula'},
    {name: 'Juan Rafael', Ubication: 'Chalco'},
    {name: 'Luis Fernando', Ubication: 'CDMX'},
];
export const GENDERS = [
    {
        label: 'Masculino',
        value: 1,
    },
    {
        label: 'Femenino',
        value: 1,
    },
    {
        label: 'Otro',
        value: 1,
    },
];
export const SELECT_TYPE = {
    GENERAL: 'General',
    CONTACT: 'Datos de contacto',
    PASSWORD: 'Contraseña y seguridad',
    BRANCH: 'Locaciones',
    ROLES: 'Roles',
};

export const SCREEN = {
    GENERAL: 1,
    CONTACT: 2,
    PASSWORD: 3,
    BRANCH: 4,
    ROLES: 5,
};
export const SCREEN_TABLE = {
    DATE: 'Fecha ',
    BRANCH: 'Sucursal',
    PRIORITY: 'Prioridad',
    TASK: 'Tarea ',
    USERS: 'Usuario de remplazo',
};

export const SCREEN_TABLE_DATA: ScreenTableData[] = [
    {
        DATE: '23/05/2024',
        BRANCH: 'Coyuya',
        PRIORITY: 'Alta',
        TASK: '',
        USERS: 'Rogelio Molina Calva',
    },
    {
        DATE: '25/05/2024',
        BRANCH: 'Centro',
        PRIORITY: 'Alta',
        TASK: '',
        USERS: 'Rogelio Molina Calva',
    },
    {
        DATE: '26/05/2024',
        BRANCH: 'Reforma',
        PRIORITY: 'Baja',
        TASK: '',
        USERS: 'Rogelio Molina Calva',
    },
    {
        DATE: '27/05/2024',
        BRANCH: 'Polanco',
        PRIORITY: 'Alta',
        TASK: '',
        USERS: 'Rogelio Molina Calva',
    },
    {
        DATE: '28/05/2024',
        BRANCH: 'Roma Norte',
        PRIORITY: 'Media',
        TASK: '',
        USERS: 'Rogelio Molina Calva',
    },
];
export const FOCUSED_COLORS = {
    FOCUSED: '1px solid #5A5AFF',
    UNFOCUSED_TEXT: '#2A2C2F',
    FOCUSED_TEXT: '#5A5AFF',
    BORDER_LEFT: '1px solid  #E8E9EA',
    HOVER: '1px solid  #1D1E20',
};

export const GENERAL_TITLES = {
    TITLE: 'Información general',
    TITLE_ADDRES: 'Dirección',
    INDICATION_ADDRES: 'Agregar una dirección personal o laboral para este usuario.',
    SAVE_CHANGES: 'Guardar',
    CANCEL: 'Cancelar',
    EMPITY: '-',
    TITLE_CONTACT: 'Actualizar datos',
    USERS: 'Rogelio Molina Calva',
    DELETE_ALL: 'Eliminación de usario',
    DELETE_MESSAGE: 'Estas seguro que deseas eliminar a ',
    TITLE_CONTACT_HEADER: 'Datos personales',
    EDIT: 'Editar',
    TITLE_RECENT: 'Accesos recientes',
    SAVE: 'Descargar',
    USER: 'Estado',
    DESCATIVATE:
        'Si desactivas el estado del usuario, quedará inactivo y no podrá acceder a la plataforma hasta que lo reactives.',
    USER_ACTIVE: 'Activo',
    ACCOUNT: 'Eliminar mi cuenta e información',
    ACCOUNT_DESCRIPTION:
        'Al eliminar un usuario, tendrás un plazo de 30 días para revertir la acción. De lo contrario, su información se perderá de manera definitiva.',

    TEXT_DESCRIPTION_BOULD: ' tendrás un plazo de 30 días para revertir la acción',
    DELETE: 'Eliminar esta cuenta e información',
    PAWN: 'Empeños generados',
    IN: 'Ingresos generados',
    PENDING: 'Tareas pendientes',
    VALUE_PAW: '2,567',
    VALUE_IN: '$234, 234',
    VALUE_TAR: '0',
    CASH_SYMBOL: '$',
    OPTION: '(Opcional)',

    SUMMARY: 'Resumen',
};

export const DATA_FORM = {
    NAMES: 'Nombres(s)',
    LAST_NAME: 'Apellido paterno',
    OTHER_LAST_NAME: 'Apellido materno',
    DATE: 'Fecha de nacimiento',
    GENDER: 'Género',
    TEL: 'Télefono',
    EMAIL: 'Correo electrónico',
    OTHER_EMAIL: 'Correo electrónico secundario',
    OTHER_TEL: 'Télefono secundario',
    POSTAL: 'Código Postal.',
    STREET: 'Calle',
    NO: 'No. exterior',
    NO_IN: 'No. interior',
    COLONY: 'Colonia',
    TOWN: 'Alcaldia',
    STATE: 'Estado',
};
export const InputStyle = {
    Justify: 'start',
    Height: '68px',
    Align: 'start',
    FlexDir: 'column',
    padding: '36px',
};
export const TextInput = {
    FontSize: '1rem',
    FontWeight: '700',
};
export const TextInputEdit = {
    FontSize: '1rem',
    FontWeight: '400',
    Color: '#2A2C2F',
};
export const Grids = {
    Display: 'grid',
    GridColumns: 'repeat(3,1fr)',
    Justify: 'start',
    Align: 'start',
    ColumnGap: '24px',
    Height: '100%',
    Gap: '16px',
    Padding: '0 0 16px 0',
};

export const SCREEN_TABLE_ACCESS = {
    DATE: 'Fecha',
    IP_ADDRESS: 'Dirección IP',
    ISP: 'ISP',
    UBICATION: 'Ubicación',
};
export const SCREEN_TABLE_DATA_ACCESS: ScreenTableDataAccess[] = [
    {
        DATE: '23/05/2024',
        IP_ADDRESS: '201.137.132.183',
        ISP: 'Telmex',
        UBICATION: 'Coyoacán, 09, México',
    },
    {
        DATE: '24/05/2024',
        IP_ADDRESS: '189.203.92.101',
        ISP: 'Megacable',
        UBICATION: 'Guadalajara, JAL, México',
    },
    {
        DATE: '25/05/2024',
        IP_ADDRESS: '187.190.39.220',
        ISP: 'Izzi',
        UBICATION: 'Monterrey, NL, México',
    },
    {
        DATE: '26/05/2024',
        IP_ADDRESS: '186.145.76.34',
        ISP: 'AT&T',
        UBICATION: 'Tijuana, BC, México',
    },
    {
        DATE: '27/05/2024',
        IP_ADDRESS: '203.176.22.43',
        ISP: 'Movistar',
        UBICATION: 'Toluca, MEX, México',
    },
    {
        DATE: '28/05/2024',
        IP_ADDRESS: '201.174.88.200',
        ISP: 'Axtel',
        UBICATION: 'Mérida, YUC, México',
    },
    {
        DATE: '29/05/2024',
        IP_ADDRESS: '192.168.0.12',
        ISP: 'Telcel',
        UBICATION: 'Cancún, QR, México',
    },
    {
        DATE: '30/05/2024',
        IP_ADDRESS: '189.162.55.91',
        ISP: 'Sky',
        UBICATION: 'León, GTO, México',
    },
    {
        DATE: '31/05/2024',
        IP_ADDRESS: '186.127.77.34',
        ISP: 'Totalplay',
        UBICATION: 'Puebla, PUE, México',
    },
];

export const priorityStyles = {
    alta: {
        border: '1px solid #FE9A9A',
        background: '#FFE6E6',
        color: '#501111',
    },
    media: {
        border: '1px solid #9ACDFE',
        background: '#E6F4FF',
        color: '#004461',
    },
    baja: {
        border: '1px solid #A3E635',
        background: '#ECFCCB',
        color: '#365314',
    },
    default: {
        border: '1px solid #E8E9EA',
        background: '#F3F4F6',
        color: '#4B5563',
    },
};
