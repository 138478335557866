import * as yup from 'yup';
import {regexPatterns} from '@components/PersonalLoans/NewLoanProduct/schemaNewLoanProduct';
import {VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';

export const schemaChargesAndPenalties = yup.object().shape({
    typeCharge: yup.object().shape({
        value: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
        label: yup.string(),
    }),
    chargeName: yup
        .string()
        .required(VALIDATIONS_MSG.MANDATORY_FIELD)
        .max(100, VALIDATIONS_MSG.CHARGES_AND_PENALTIES.CHARGE_NAME.MAX_LENGTH)
        .test('no-spaces', VALIDATIONS_MSG.NO_SPACES, (value) => value.trim().length > 0)
        .matches(
            regexPatterns.termName,
            VALIDATIONS_MSG.CHARGES_AND_PENALTIES.CHARGE_NAME.INVALID_CHARACTERS
        ),
    amount: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
    condition: yup.object().shape({
        value: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
        label: yup.string(),
    }),
    eventType: yup
        .object()
        .shape({
            value: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
            label: yup.string(),
        })
        .test(
            'is-valid-selection',
            VALIDATIONS_MSG.CHARGES_AND_PENALTIES.EVENT_TYPE.VALID_SELECTION,
            function (value) {
                const {condition} = this.parent;
                if (condition.value === 1) {
                    return value.value < 5;
                }
                return value.value > 4;
            }
        ),
});

export const schemaDefaultCharges = yup.object().shape({
    amount: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
});
