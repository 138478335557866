import {AuctionPassText} from '@/components/AuctionPass/AuctionConstants';
import {RootState} from '@/config/store';
import {BreadCrumb} from '@components/AuctionPass/BreadCrumb';
import {getAuctionGroupAction} from '@components/AuctionPass/Redux/Actions/GetGroupsAction';
import {Tooltip} from '@components/AuctionPass/Tooltip';
import {TreeComponent} from '@components/AuctionPass/Tree';
import {ICatAuctionGruop} from '@components/AuctionPass/interfaces';
import {ImageLogo} from '@components/LoginClient/styles';
import {idSelected} from '@components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {ResetSelectedNodeLevel} from '@components/RequestNewCatalogLevel/Redux/Actions/GetSelectGroup';
import {ContainerFlex, ContainerGrid, Text} from '@components/Shopify/Ecommerce/styles';
import QuestionMark from '@images/QuestionIcon.svg';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const AuctionPass = () => {
    const dispatch = useDispatch();
    const [isVisible, setIsVisible] = useState(false);
    const TreeGroup: ICatAuctionGruop = useSelector(
        (state: RootState) => state.getGroupsAuctionReducer
    );

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );

    useEffect(() => {
        if (companyId) dispatch(getAuctionGroupAction(companyId, token));
    }, []);
    useEffect(() => {
        dispatch(ResetSelectedNodeLevel());
        dispatch(idSelected(0));
    }, []);
    return (
        <ContainerFlex
            Align="start"
            Justify="start"
            FlexDir="column"
            Gap="8px"
            backG="#FAFAFA"
            Padding="30px"
            Height=""
        >
            <BreadCrumb />
            <Text FontWeight="700" FontSize="2rem" MinH="3rem">
                {AuctionPassText.AuctionPassTitle}
            </Text>
            <Text MinH="2rem">{AuctionPassText.AuctionPassDescription}</Text>
            <ContainerFlex
                Justify="start"
                Align="start"
                backG="white"
                Padding="25px"
                Radius="24px"
                Border="1px solid #E8E9EA"
                Width="100%"
                FlexDir="column"
                Height=""
            >
                <Text FontWeight="700" FontSize="1.6rem" MinH="2rem">
                    {AuctionPassText.RulesTitle}
                </Text>
                <ContainerGrid
                    GridColumns="1.5fr 1fr 1fr 1.2fr minmax(100px, 0.3fr)"
                    Justify="start"
                    AlignContent="start"
                    Height="5rem"
                >
                    <ContainerFlex
                        Height="4rem"
                        FontWeight="700"
                        Width="80%"
                        Justify="start"
                        MarginL="35px"
                    >
                        {AuctionPassText.CatalogColumn}
                    </ContainerFlex>
                    <ContainerFlex Height="4rem" Justify="start">
                        <Text Width="60%" Height="2.5rem" FontWeight="800">
                            {AuctionPassText.CommercializationColumn}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Height="4rem" Justify="start" FontWeight="700">
                        {AuctionPassText.PercentageColumn}
                    </ContainerFlex>
                    <ContainerFlex
                        Height="4rem"
                        Justify="start"
                        FontWeight="700"
                        Gap="5px"
                        OverFlow="visible"
                    >
                        {AuctionPassText.DaysOfPauseColumn}
                        <ContainerFlex
                            Position="relative"
                            onMouseEnter={() => setIsVisible(true)}
                            onMouseLeave={() => setIsVisible(false)}
                            Height="25px"
                            Width="25px"
                        >
                            <ImageLogo
                                src={QuestionMark}
                                height="2px"
                                Width="20px"
                                Cursor="pointer"
                                alt="questionmark"
                            />
                            {isVisible && <Tooltip />}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerGrid>
                {<TreeComponent data={TreeGroup.catGroup} level={0} isView={false} fatherId={0} />}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AuctionPass;
