import React from 'react';

import useFormatDate from '@/hooks/useFormatDate';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {IActionDetailsProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {QUICK_ACTIONS} from '@Shopify/ClientProfile/constants';

const ActionDetails: React.FC<IActionDetailsProps> = ({card}) => {
    const productDetail = (title: string, detail: string) => {
        return (
            <ContainerFlex FlexDir="Column" Align="center">
                <Text Color="#2A2C2F" FontSize="0.875rem">
                    {title}
                </Text>
                <Text {...style.cardsTitle}>{detail}</Text>
            </ContainerFlex>
        );
    };
    return (
        <ContainerFlex>
            {productDetail(QUICK_ACTIONS.DATE_EXPIRATION, useFormatDate(card.dateExpitation))}
            {productDetail(QUICK_ACTIONS.PAYMENT, numberToCurrency(card.payment))}
            {productDetail(QUICK_ACTIONS.LIQUIDATION, numberToCurrency(card.liquidation))}
        </ContainerFlex>
    );
};
export default ActionDetails;
