import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
    toggleFilterModal,
    setVisualFiltersValues,
    setFiltersValues,
    setFilterList,
} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';
import {FOOTER_ACTIONS_BTN} from '@/components/GlobalQuoter/TempConstants';
import {RootState} from '@/config/store';

const Footer = () => {
    const dispatch = useDispatch();
    const {filtersValues, filterVisualStatus} = useSelector(
        (state: RootState) => state.globalQuoterFilterRedcuer
    );

    const cancelBtnHandler = () => {
        dispatch(setFiltersValues(filterVisualStatus));
        dispatch(toggleFilterModal());
    };

    const applyBtnHandler = () => {
        dispatch(setFilterList(filtersValues));
        dispatch(setVisualFiltersValues(filtersValues));
        dispatch(toggleFilterModal());
    };

    return (
        <ContainerFlex Height={'40px'} Gap="16px">
            <ButtonGenerals
                BackGC="transparent"
                HBackG="transparent"
                Width="50%"
                Border="1px solid #5A5AFF"
                onClick={cancelBtnHandler}
                Cursor="pointer"
            >
                <Text
                    LHeight="24px"
                    LetterSpacing="0.3px"
                    Color="#5A5AFF"
                    FontFamily="Nunito"
                    FontWeight="700"
                    Cursor="pointer"
                >
                    {FOOTER_ACTIONS_BTN.CANCEL}
                </Text>
            </ButtonGenerals>
            <ButtonGenerals Width="50%" Cursor="pointer" onClick={applyBtnHandler}>
                <Text
                    LHeight="24px"
                    LetterSpacing="0.3px"
                    FontFamily="Nunito"
                    FontWeight="700"
                    Color="#FFFFFF"
                    Cursor="pointer"
                >
                    {FOOTER_ACTIONS_BTN.APPLY}
                </Text>
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default Footer;
