import axios from 'axios';
import {
    GET_ROL_COMPANY_REQUEST,
    GET_ROL_COMPANY_SOUCCES,
    GET_ROL_COMPANY_RROR,
} from '@components/Users/UserProfile/Redux/types';

import process from 'process';
import {URL} from '@config/constants/index';
export const RoleResquest = () => {
    return {
        type: GET_ROL_COMPANY_REQUEST,
    };
};
export const RoleSuccess = (userData) => {
    return {
        type: GET_ROL_COMPANY_SOUCCES,
        payload: userData,
    };
};
export const RoleFailure = (err) => {
    return {
        type: GET_ROL_COMPANY_RROR,
        payload: err,
    };
};

export const RolesCompany = (token, companyId) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(RoleResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRolByCompany}`,
                {
                    headers,
                    params: {companyId},
                }
            );
            dispatch(RoleSuccess(response.data));
        } catch (error) {
            dispatch(RoleFailure(error));
        }
    };
};
