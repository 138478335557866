import React from 'react';
import {ContainerFlex, Text, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {METHODPAYMENT} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import {MethodPaymentCreditsProps} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {CardNumberField} from './PaymentMethod/Fields/CardNumberField';
import {CardOwnerField} from './PaymentMethod/Fields/CardOwnerField';
import {DatePickerVigency} from './PaymentMethod/Fields/DatePickerVigency';
import {CvvField} from './PaymentMethod/Fields/CvvField';
import visaIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/visaIcon.svg';

const CreditCardMethod: React.FC<MethodPaymentCreditsProps> = ({
    register,
    setValue,
    control,
    errors,
}) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Align="center"
            Width="100%"
            MaxH="25rem"
            Gap="1rem"
            Padding="0.5rem  1rem 1rem 1rem"
        >
            <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
            <ContainerFlex Justify="start" Align="start" Width="100%" FlexDir="column" Gap="1rem">
                <ContainerFlex Height="100%" Width="100%" Justify="start" Align="start" Gap="1rem">
                    <ContainerCellGrid FlexDir="column" Align="start" Height="100%" Width="20rem">
                        <CardNumberField control={control} errors={errors} cardIcon={visaIcon} />
                    </ContainerCellGrid>
                    <ContainerCellGrid FlexDir="column" Align="start" Height="100%" Width="20rem">
                        <CardOwnerField register={register} errors={errors} />
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex Height="100%" Width="100%" Justify="start" Align="start" Gap="1rem">
                    <ContainerCellGrid FlexDir="column" Align="start" Height="100%" Width="11rem">
                        <DatePickerVigency errors={errors} control={control} setValue={setValue} />
                    </ContainerCellGrid>
                    <ContainerCellGrid FlexDir="column" Align="start" Height="100%" Width="7rem">
                        <CvvField control={control} errors={errors} />
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="start" Gap="0.5rem" Height="1.5rem">
                <Checkbox {...register('defaultCard')} />
                <Text>{METHODPAYMENT.SAVE}</Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditCardMethod;
