import {AxiosError, AxiosResponse} from 'axios';
import {
    UPDATE_TERM_PERSONAL_LOANS_ERROR,
    UPDATE_TERM_PERSONAL_LOANS_START,
    UPDATE_TERM_PERSONAL_LOANS_SUCCESS,
} from '@components/LoansProducts/Redux/Types/Types';
import {UpdateTermPersonalLoanReducer} from '@components/LoansProducts/interfaces';

const initialState: UpdateTermPersonalLoanReducer = {
    updateTerm: null,
    loading: false,
    error: false,
    errorData: null,
};

export const UpdateTermPersonalLoan = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): UpdateTermPersonalLoanReducer => {
    switch (action.type) {
        case UPDATE_TERM_PERSONAL_LOANS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_TERM_PERSONAL_LOANS_SUCCESS:
            return {
                ...state,
                updateTerm: action.payload.data,
                loading: false,
                error: false,
            };
        case UPDATE_TERM_PERSONAL_LOANS_ERROR:
            return {
                ...state,
                errorData: action.error,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
