import {
    SAVE_FILTER_LOANS_RESET,
    SAVE_FILTER_LOANS_USER,
} from '@components/LoansProducts/Redux/Types/Types';
import {SubmitFilterLoans} from '@components/LoansProducts/interfaces';

export const AddFilterLoansForm = (result: SubmitFilterLoans) => {
    return {
        type: SAVE_FILTER_LOANS_USER,
        saveFiltered: result,
    };
};
export const resetFilterLoans = () => {
    return {
        type: SAVE_FILTER_LOANS_RESET,
    };
};
