import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import RatingStars from '@/components/CreditQuoter/RatingStars';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';

const Rating = ({startsCount}: {startsCount: number}) => {
    const rating = Math.floor(startsCount);
    return (
        <ContainerFlex
            Width="226px"
            Height="32px"
            Padding="4px 8px"
            Radius="16px"
            backG="#fff"
            Justify="start"
            Align="start"
            Gap="8px"
        >
            <ContainerFlex Gap="4px">
                <ContainerFlex>
                    <Text FontWeight="400" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.0rem">
                        {rating}
                    </Text>
                </ContainerFlex>

                <RatingStars rating={rating} justify="center" />
            </ContainerFlex>
            <ContainerFlex>
                <Text
                    FontWeight="700"
                    Color="#5A5AFF"
                    FontFamily="Nunito"
                    FontSize="0.75rem"
                    Cursor="pointer"
                >
                    {CREDIT_QUOTER.RATING}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Rating;
