import React from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {
    STATUS_CONTRACT,
    MENU_CONTRACT,
    TYPE_MOVEMENT_ADD_CART,
} from '@Shopify/ClientProfile/constants';
import {ClickAwayListener} from '@mui/material';
import useToast from '@/hooks/useToast';
import useCheckPawnsOnCart from '@/hooks/useCheckPawnsOnCart';
import arrowLeft from '@images/arrowTopLeft.svg';
import menuOptions from '@images/IconMenuOptions.svg';
import {savePawnId} from './Redux/Action/DetailsContract';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {IMoreOptionsEndeavors} from '@Shopify/ClientProfile/interfaces';
import {addDischargeOrEndorsement} from '@Shopify/ClientProfile/Redux/Action/AddDischargeEndorsement';
import {setIsCartOpen} from '@/components/ShoppingCart/Redux/Actions/CartState';

export const MoreOptionsEndeavors = ({isOpen, onOptionClick, pawn}: IMoreOptionsEndeavors) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {showToast, ToastContainer} = useToast();
    const {checkCartAndPawns} = useCheckPawnsOnCart();

    const {token, branchId, cartShoppingId} = useSelector((state: RootState) => ({
        token: String(state.getUsersValidation.userData?.token),
        branchId: Number(state.getUsersValidation.userData?.branchDetails?.[0]?.branchId),
        cartShoppingId: Number(state.getUsersValidation.userData?.carShoopingId),
    }));

    const handleSuccess = async () => {
        dispatch(setIsCartOpen(true));
        onOptionClick();
    };

    const handleError = (message: string) => {
        dispatch(setIsCartOpen(true));
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const handleEndorsementDischarge = async (
        typeMovement: number,
        options: {extend?: boolean; addPay?: boolean} = {}
    ) => {
        const {extend = false, addPay = false} = options;

        const {hasPawns, message} = await checkCartAndPawns();
        if (hasPawns && message) {
            handleError(message);
            return;
        }

        const payment =
            typeMovement === TYPE_MOVEMENT_ADD_CART.RENEWAL ? pawn.renewal : pawn.performance;

        const discharge = {
            carShoppingId: cartShoppingId,
            typeMovement,
            branchId: branchId,
            pawnId: pawn.contractId,
            paymentAmount: payment,
            iva: pawn.carShopDat.iva,
            moratoriumAmount: pawn.carShopDat.moratoriumAmount,
            amountStorage: pawn.carShopDat.amountStorage,
            amountInterest: pawn.carShopDat.amountInterest,
            insuranceAmount: pawn.carShopDat.insuranceAmount,
            amountReposition: pawn.carShopDat.amountReposition,
            amountRepositionIVA: pawn.carShopDat.amountRepositionIVA,
            amountDiscountInterest: pawn.carShopDat.amountDiscountInterest,
            amountDiscountInterestIVA: pawn.carShopDat.amountDiscountInterestIVA,
            maximumInterest: pawn.carShopDat.maximumInterest,
        };

        await dispatch(
            addDischargeOrEndorsement(discharge, token, handleSuccess, handleError, {
                extend,
                addPay,
            })
        );
        onOptionClick();
    };

    const OPTIONS_CONTRACTS = [
        {
            option: MENU_CONTRACT.VIEW_DETAILS,
            icom: '',
            permitions: [
                STATUS_CONTRACT.TO_WIN,
                STATUS_CONTRACT.CANCELED,
                STATUS_CONTRACT.DEFEATED,
                STATUS_CONTRACT.PAYMENTED,
                STATUS_CONTRACT.SALE,
            ],
            action: async () => {
                await dispatch(savePawnId(pawn.contractId));
                navigate('/ClientProfile/ContractDetail');
            },
        },
        {
            option: MENU_CONTRACT.DISCHARGE,
            icon: '',
            permitions: [STATUS_CONTRACT.TO_WIN],
            action: () => handleEndorsementDischarge(TYPE_MOVEMENT_ADD_CART.DISCHARGE),
        },
        {
            option: MENU_CONTRACT.RENEW,
            icon: '',
            permitions: [STATUS_CONTRACT.TO_WIN],
            action: () => handleEndorsementDischarge(TYPE_MOVEMENT_ADD_CART.RENEWAL),
        },
        {
            option: MENU_CONTRACT.EXTEND,
            icon: arrowLeft,
            permitions: [STATUS_CONTRACT.TO_WIN],
            action: () =>
                handleEndorsementDischarge(TYPE_MOVEMENT_ADD_CART.RENEWAL, {extend: true}),
        },
        {
            option: MENU_CONTRACT.MAKE_A_PAYMENT,
            icon: arrowLeft,
            permitions: [STATUS_CONTRACT.TO_WIN],
            action: () =>
                handleEndorsementDischarge(TYPE_MOVEMENT_ADD_CART.RENEWAL, {addPay: true}),
        },

        {
            option: MENU_CONTRACT.REACTIVATE,
            icon: '',
            permitions: [STATUS_CONTRACT.DEFEATED],
            action: null,
        },
        {
            option: MENU_CONTRACT.ACTIVE_INCIDENT,
            icon: '',
            permitions: [STATUS_CONTRACT.DEFEATED],
            action: null,
        },
    ];

    return (
        <>
            <FlexPanel Cursor="pointer" Position="relative" Align="start" Height="2rem">
                <Image
                    Cursor="pointer"
                    src={menuOptions}
                    alt="menuIcon"
                    onClick={() => onOptionClick()}
                />
                {isOpen && (
                    <ClickAwayListener onClickAway={onOptionClick}>
                        <FlexPanel
                            Position="relative"
                            Margin="1.9rem 0 -10rem -10.3rem"
                            Width="10.313rem"
                            backG="#FFFF"
                            FlexDir="column"
                            Justify="start"
                            Border="1px solid #F3F3F3"
                            zIndex="3"
                            Radius="0.5rem"
                            BoxShadow="0 0.688rem 1.663rem 0 rgba(0, 0, 0, 0.06)"
                        >
                            {OPTIONS_CONTRACTS.map(
                                (item, index) =>
                                    item.permitions.includes(pawn.status) && (
                                        <ContainerFlex
                                            key={index}
                                            Gap="0.5rem"
                                            Height="2.5rem"
                                            Justify="start"
                                            Cursor="pointer"
                                            HBackground="#f0f0ff"
                                            HBorderLeft="2px solid #5a5aff"
                                            Padding="0.5rem"
                                            onClick={() => {
                                                if (item.action) {
                                                    item.action();
                                                    onOptionClick();
                                                }
                                            }}
                                        >
                                            {item.icon && (
                                                <Image src={item.icon} alt="icon-arrow" />
                                            )}
                                            <Text Cursor="pointer" Color="#2A2C2F">
                                                {item.option}
                                            </Text>
                                        </ContainerFlex>
                                    )
                            )}
                        </FlexPanel>
                    </ClickAwayListener>
                )}
            </FlexPanel>

            <ToastContainer />
        </>
    );
};
