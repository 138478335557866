import {TableRowHead} from '@/components/CashFlow/FundingInquiry/styles';
import {
    filterOptions,
    listFilter,
    listsort,
    roleList,
    roleTable,
    searchBar,
} from '@/components/RolesPermissions/constants';
import {IRoleItems, OptionType} from '@/components/RolesPermissions/interfaces';
import {RootState} from '@/config/store';
import SearchImg from '@/images/search.svg';
import UnfoldMore from '@/images/unfold_more.svg';
import SelectGeneral from '@components/General/Atoms/Select/Select';
import {ContainerFlex, Image, Input, Text} from '@components/Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {SingleValue} from 'react-select';
import GetRolesAction from './Redux/Actions/GetRolesAction';
import RoleTable from './RoleTable';

const RoleList = () => {
    const dispatch = useDispatch();
    const rolesData: IRoleItems[] = useSelector(
        (state: RootState) => state.getRolesAndPermissions?.roles
    );
    const [search, setSearch] = useState('');
    const [roles, setRoles] = useState<IRoleItems[]>(rolesData);
    const [sortOrder, setSortOrder] = useState(listsort.Asc);
    const [selectedFilter, setSelectedFilter] = useState<number>(listFilter.AllRoles);
    const [sortBy, setSortBy] = useState<string | null>(null);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );

    const handleFilterChange = (selectedOption: SingleValue<OptionType>) => {
        if (selectedOption) setSelectedFilter(selectedOption.value);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchValue = e.target.value;
        setSearch(searchValue);
    };

    const handleSort = (key: keyof IRoleItems) => {
        const sortedRoles = [...roles].sort((a, b) => {
            if (a[key] < b[key]) return sortOrder === listsort.Asc ? -1 : 1;
            if (a[key] > b[key]) return sortOrder === listsort.Asc ? 1 : -1;
            return 0;
        });
        setRoles(sortedRoles);
        setSortOrder(sortOrder === listsort.Asc ? listsort.Desc : listsort.Asc);
        setSortBy(key);
        return sortBy;
    };

    useEffect(() => {
        if (companyId) {
            const data = {
                searchRoleName: search,
                enterpriseId: companyId,
                filterRoles: selectedFilter,
            };
            dispatch(GetRolesAction(data, token));
        }
    }, [companyId, search, selectedFilter, token, dispatch]);

    useEffect(() => {
        setRoles(rolesData);
    }, [rolesData]);

    return (
        <ContainerFlex
            FlexDir="column"
            Justify="start"
            Align="start"
            backG="white"
            Gap="8px"
            Padding="25px"
            Border="1px solid #E8E9EA"
            Radius="16px"
        >
            <Text FontWeight="700" FontSize="1.3rem" MinH="2rem">
                {roleList.Roles}
            </Text>
            <Text MinH="2rem">{roleList.SubText}</Text>
            <ContainerFlex
                Justify="space-between"
                Align="start"
                Height="2rem"
                MarginTop="10px"
                MarginBt="20px"
            >
                <ContainerFlex Width="16%" Align="start">
                    <SearchInput
                        Height="40px"
                        Position="none"
                        GridColumn="90% 10%"
                        Margin="0 0 4px 0"
                        Padding="0 1rem 0 0"
                        BackG="#FFFFFF"
                        Radius="32px"
                        InputPadding="0 1rem"
                    >
                        <Input
                            type="text"
                            placeholder={searchBar.Placeholder}
                            onChange={handleSearchChange}
                        />
                        <Image src={SearchImg} Cursor="pointer" alt="search" />
                    </SearchInput>
                </ContainerFlex>
                <SelectGeneral
                    width="16%"
                    options={filterOptions}
                    onChange={handleFilterChange}
                    defaultValue={filterOptions[0]}
                />
            </ContainerFlex>
            <TableRowHead BackG="none" GridColumn="1fr 1fr 1fr .5fr" Width="100%">
                <ContainerFlex
                    Gap="8px"
                    Justify="start"
                    onClick={() => handleSort('name')}
                    Cursor="pointer"
                >
                    <Text FontWeight="700" MarginLeft="25px">
                        {roleTable.Role}
                    </Text>
                    <Image src={UnfoldMore} alt="sort" />
                </ContainerFlex>
                <ContainerFlex
                    Gap="8px"
                    Justify="start"
                    onClick={() => handleSort('typeRoleName')}
                    Cursor="pointer"
                >
                    <Text FontWeight="700">{roleTable.Access}</Text>
                    <Image src={UnfoldMore} alt="sort" />
                </ContainerFlex>
                <ContainerFlex
                    Gap="8px"
                    Justify="start"
                    onClick={() => handleSort('quantityUsers')}
                    Cursor="pointer"
                >
                    <Text FontWeight="700">{roleTable.Users}</Text>
                    <Image src={UnfoldMore} alt="sort" />
                </ContainerFlex>
                <ContainerFlex
                    Gap="8px"
                    Justify="start"
                    onClick={() => handleSort('status')}
                    Cursor="pointer"
                >
                    <Text FontWeight="700">{roleTable.Active}</Text>
                    <Image src={UnfoldMore} alt="sort" />
                </ContainerFlex>
            </TableRowHead>
            <RoleTable
                selectedFilter={selectedFilter}
                roles={roles}
                setRoles={setRoles}
                token={token}
                searchRoleName={search}
                companyId={companyId}
            />
        </ContainerFlex>
    );
};

export default RoleList;
