import {Controller, useFormContext} from 'react-hook-form';
import {DropdownIndicatorProps, components} from 'react-select';
import React, {useMemo, useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS, VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Select} from '@/components/Quoter/Steps/styles';
import {IPeriodMaxFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import arrowDown from '@components/PersonalLoans/NewLoanProduct/icons/arrowDown.svg';
import closeIcon from '@components/PersonalLoans/NewLoanProduct/icons/closeIcon.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';

const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    return (
        <components.DropdownIndicator {...props}>
            <ContainerFlex Gap="0.25rem" Width="max-content" Height="max-content">
                <ContainerFlex
                    Width="24px"
                    Height="24px"
                    FlexDir="column"
                    Position="relative"
                    Justify="start"
                    onMouseEnter={() => setShowToolTip(true)}
                    onMouseLeave={() => setShowToolTip(false)}
                >
                    <Image
                        src={questionIcon}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        alt="question-icon"
                    />
                    {showToolTip && <ToolTip description={LOAN_DETAILS.TOOLTIPS.MAX_PERIOD} />}
                </ContainerFlex>
                <Image src={arrowDown} Width="24px" Height="24px" alt="arrow-down-icon" />
            </ContainerFlex>
        </components.DropdownIndicator>
    );
};

export const PeriodMaxField: React.FC<IPeriodMaxFieldProps> = ({
    name,
    isSubmitted,
    showOptionalFields,
    setShowOptionalFields,
}) => {
    const {
        control,
        formState: {errors},
        resetField,
        trigger,
    } = useFormContext();
    const periodsOptions = useMemo(() => {
        const periods = [];
        const maxPeriods = 84;
        for (let i = 3; i <= maxPeriods; i += 3) {
            periods.push({value: i, label: `${i} meses`});
        }
        return periods;
    }, []);
    return (
        <>
            <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                <Text Color="#2A2C2F" FontWeight="700">
                    {LOAN_DETAILS.FIELDS.MAX_TERM}
                    <Text Color="#54575C" MarginLeft="0.25rem">
                        {LOAN_DETAILS.FIELDS.OPTIONAL}
                    </Text>
                </Text>
                <ContainerFlex Justify="start" Height="max-content">
                    <Controller
                        name={name}
                        control={control}
                        render={({field: {value, onChange, ...field}}) => (
                            <Select
                                {...field}
                                noOptionsMessage={() => 'Sin opciones'}
                                options={periodsOptions}
                                placeholder="Selecciona o escribe"
                                padding="0px"
                                controlTop="-6px"
                                radius="2rem"
                                components={{DropdownIndicator}}
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    if (isSubmitted) {
                                        trigger('periodMin');
                                        trigger('periodMax');
                                    }
                                }}
                            />
                        )}
                    />
                    <Image
                        src={closeIcon}
                        Cursor="pointer"
                        Width="24px"
                        Height="24px"
                        onClick={() => {
                            resetField(`${'periodMax'}`, {defaultValue: {value: 0, label: ''}});
                            setShowOptionalFields({
                                ...showOptionalFields,
                                showMaxLoanTerm: false,
                            });
                        }}
                        alt="close-icon"
                    />
                </ContainerFlex>
                {errors[name] && (
                    <ErrorMessage
                        message={VALIDATIONS_MSG.PRODUCT_DETAILS.PERIOD_MAX.MORE_THAN_MIN_PERIOD}
                    />
                )}
            </ContainerFlex>
        </>
    );
};
