import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Select} from '@/components/Quoter/Steps/styles';
import {DropdownIndicator} from './DropDownIndicator';
import {AUCTION_TITLES, FOOTER_ONE, FOOTER_TWO} from './constants';
import PercentageInput from './inputPercent';

export type FooterType = 'ArticleReview' | 'AuctionPass' | 'IntegratedPromotions';

export interface FooterProps {
    bottomTitle?: string;
    onClose: () => void;
    onSubmit: (selectedItems: number[]) => void;
    isSubmitDisabled?: boolean;
    selectedItems: number[];
}

export const ArticleReview: React.FC<FooterProps> = ({
    onClose,
    onSubmit,
    isSubmitDisabled,
    selectedItems,
}) => (
    <ContainerFlex BoxS="0px 0px 9px 0px rgba(167, 169, 172, 0.25)" Height="149px" FlexDir="column">
        <ContainerFlex Padding="0 24px" Height="77px">
            <ContainerFlex Align="start" FlexDir="column" Gap="8px">
                <Text FontWeight="700" Color="#1D1E20">
                    {FOOTER_ONE.RESTORE}
                </Text>
                <Select
                    placeholder={AUCTION_TITLES.SELECT}
                    padding="0px"
                    controlTop="-6px"
                    radius="2rem"
                    isDisabled
                    components={{DropdownIndicator}}
                />
            </ContainerFlex>
            <ContainerFlex Height="40px" Align="end" Justify="end">
                <Text Color="#5A5AFF">{FOOTER_ONE.ASSIGNED}</Text>
            </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex Justify="space-between" Height="77px" Padding="0 24px 24px 24px">
            <ContainerFlex Width="50%" onClick={onClose}>
                <Text Cursor="pointer" Color="#FF6357" FontWeight="700">
                    {AUCTION_TITLES.CANCEL}
                </Text>
            </ContainerFlex>
            <ButtonGeneral
                type="submit"
                text={AUCTION_TITLES.SAVE}
                width="50%"
                disabled={isSubmitDisabled}
            />
        </ContainerFlex>
    </ContainerFlex>
);

export const AuctionPass: React.FC<FooterProps> = ({
    bottomTitle,
    onClose,
    onSubmit,
    isSubmitDisabled,
    selectedItems,
}) => (
    <ContainerFlex BoxS="0px 0px 9px 0px rgba(167, 169, 172, 0.25)" Height="149px" FlexDir="column">
        <ContainerFlex Padding="0 24px" Height="77px">
            <ContainerFlex Align="start" FlexDir="column" Gap="8px">
                <Text FontWeight="700" Color="#1D1E20">
                    {FOOTER_TWO.RESTORE}
                </Text>
                <PercentageInput />
            </ContainerFlex>
            <ContainerFlex Height="40px" Align="end" Justify="end">
                <Text Color={isSubmitDisabled ? '#A0A0A0' : '#5A5AFF'}>{FOOTER_TWO.ASSIGNED}</Text>
            </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex Justify="space-between" Height="77px" Padding="0 24px 24px 24px">
            <ContainerFlex Width="50%" onClick={onClose}>
                <Text Cursor="pointer" Color="#FF6357" FontWeight="700">
                    {AUCTION_TITLES.CANCEL}
                </Text>
            </ContainerFlex>
            <ButtonGeneral
                type="submit"
                text={AUCTION_TITLES.SAVE}
                width="50%"
                disabled={isSubmitDisabled}
            />
        </ContainerFlex>
    </ContainerFlex>
);

export const IntegratedPromotions: React.FC<FooterProps> = ({
    onClose,
    onSubmit,
    isSubmitDisabled,
    selectedItems,
}) => (
    <ContainerFlex BoxS="0px 0px 9px 0px rgba(167, 169, 172, 0.25)" Height="180px" FlexDir="column">
        <ContainerFlex Justify="space-between" Height="77px" Padding="16px 24px">
            <ContainerFlex Width="50%" onClick={onClose}>
                <Text Cursor="pointer" Color="#FF6357" FontWeight="700">
                    {AUCTION_TITLES.CANCEL}
                </Text>
            </ContainerFlex>
            <ButtonGeneral
                type="submit"
                text={AUCTION_TITLES.SAVE}
                width="50%"
                disabled={isSubmitDisabled}
            />
        </ContainerFlex>
    </ContainerFlex>
);
