import {
    PUT_EDIT_CATALOGUE,
    PUT_EDIT_CATALOGUE_SUCCESS,
    PUT_EDIT_CATALOGUE_ERROR,
} from '@components/AuctionPass/Redux/Types';
import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {IEditItem} from '@components/AuctionPass/interfaces';

export const putEditCatalogue = () => {
    return {
        type: PUT_EDIT_CATALOGUE,
    };
};
export const putEditCatalogueSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_EDIT_CATALOGUE_SUCCESS,
        payload: result,
    };
};

export const putEditCatalogueError = (error: AxiosError) => {
    return {
        type: PUT_EDIT_CATALOGUE_ERROR,
        payload: error,
    };
};

export const putEditCalaogueAction = (data: IEditItem, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(putEditCatalogue());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutEditCatalogue}`;
        try {
            const response = await axios.put(url, data, {headers});
            dispatch(putEditCatalogueSuccess(response.data.data));
        } catch (error) {
            dispatch(putEditCatalogueError(error as AxiosError));
        }
    };
};
