export const LOANSPRODUCTS = {
    LOANS: 'Préstamos personales',
    TITLE: 'Productos de préstamo',
    DESCRIPTION:
        'Crea y gestiona los productos de préstamos personales que ofrecerás a tus clientes.',
    NEW: 'Nuevo Préstamo',
};
export const LOANSACTIVE = {
    PRODUCT: 'Productos de préstamo',
    ACTIVE: 'Activos',
    INACTIVE: 'Inactivos',
    VALUE: '0',
};
export const LOANSLIST = {
    SEARCH: 'Buscar',
    FILT: 'Filtrar',
    PRODCUTS: 'Productos',
    NAME: 'Nombre',
    MIN: 'Cantidad mínima',
    MAX: 'Cantidad máxima',
    MINTIME: 'Tiempo de pago min.',
    MAXTIME: 'Tiempo de pago max.',
    ACTIVE: 'Activo',
    CREATE: 'Crea tu primer préstamo',
    FILTER: 'Filtros',
};
export const FILTERLOANS = {
    FILTER: 'Filtros',
    CLEAN: 'Limpiar filtros',
    MIN: 'Cantidad mínima',
    MAX: 'Cantidad máxima',
    MINTIME: 'Tiempo de pago mínimo',
    MAXTIME: 'Tiempo de pago máximo ',
    STATE: 'Estado del préstamo',
    ACTIVE: 'Activo',
    INACTIVE: 'Inactivo',
    ARCHIVE: 'Archivado',
    CANCEL: 'Cancelar',
    APPLY: 'Aplicar',
    ZERO: 0,
};
export const fieldLabels = {
    minimumAmount: 'Minimum Quantity',
    maximumAmount: 'Maximum Amount',
    minimumTime: 'Minimum Payment Time',
    maximumTime: 'Maximum Payment Time',
    loanStatus: 'Loan Status',
};
export const filterLoansForm = {
    companyId: 0,
    minimumAmount: 0,
    maximumAmount: 0,
    minimumTime: {label: '', value: 0},
    maximumTime: {label: '', value: 0},
};
export const CheckboxStates = {
    ACTIVE: 1,
    INACTIVE: 2,
    ARCHIVE: 3,
};

export type FieldNames =
    | 'companyId'
    | 'minimumAmount'
    | 'maximumAmount'
    | 'minimumTime'
    | 'maximumTime'
    | 'loanStatus'
    | 'minimumTime.label'
    | 'minimumTime.value'
    | 'maximumTime.label'
    | 'maximumTime.value';
export const LabelsHeaders = {
    loanStatusLabels: '',
    minimumAmountLabel: 'Cantidad mínima: ',
    maximumAmountLabel: 'Cantidad máxima: ',
    minimumTimeLabel: 'Tiempo de pago mínimo: ',
    maximumTimeLabel: 'Tiempo de pago máximo: ',
    unknownStatusLabel: 'Desconocido',
};
export const labels = {
    loanStatus: LabelsHeaders.loanStatusLabels,
    minimumAmount: LabelsHeaders.minimumAmountLabel,
    maximumAmount: LabelsHeaders.maximumAmountLabel,
    minimumTime: LabelsHeaders.minimumTimeLabel,
    maximumTime: LabelsHeaders.maximumTimeLabel,
};

export const loanStatusLabels: {[key: number]: string} = {
    1: 'Activo',
    2: 'Inactivo',
    3: 'Archivado',
};

export const monthOptions: {label: string; value: number}[] = [
    {value: 3, label: '3 meses'},
    {value: 6, label: '6 meses'},
    {value: 9, label: '9 meses'},
    {value: 12, label: '12 meses'},
    {value: 15, label: '15 meses'},
];

export const SUGGESTEDPRODUCTS = {
    TITLE: 'Sugerencias Ataskate',
    SUB: 'Selecciona los productos sugeridos por Ataskate.',
    SELECT: 'Seleccionar',
};

export const LoansTable = [
    {
        termId: 1,
        termName: 'Préstamo primario',
        minimumAmount: 500,
        maximumAmount: 10000,
        minimumTime: '1 año',
        maximumTime: '2 años',
        active: 2,
    },
    {
        termId: 2,
        termName: 'Préstamo secundario',
        minimumAmount: 400,
        maximumAmount: 12000,
        minimumTime: '6 meses',
        maximumTime: '2 años',
        active: 1,
    },
    {
        termId: 3,
        termName: 'PR PRimario',
        minimumAmount: 1000,
        maximumAmount: 15000,
        minimumTime: '1 año',
        maximumTime: '2 años',
        active: 3,
    },
    {
        termId: 4,
        termName: 'Testing',
        minimumAmount: 1500,
        maximumAmount: 20000,
        minimumTime: '1 año',
        maximumTime: '3 años',
        active: 1,
    },
];

export const TABLE_FILTERS = [
    {label: 'Activos', value: 1},
    {label: 'Inactivos', value: 2},
    {label: 'Archivados', value: 3},
];
export const MODALINACTIVE = {
    TITLE: '¿Quieres inactivar el préstamo?',
    DESCRIPTION:
        'Al hacer click en el botón Inactivar, se desactivara el el préstamo y se desactivara comercialmente, esto no afectara prestamos en activos con este producto de préstamo.',
    CANCEL: 'Cancelar',
    INACTIVE: 'Inactivar préstamo',
};
export const ACTIONILOANSCONS = {
    DETAIL: 'Ver detalle',
    ARCHIVE: 'Archivar',
};
export const ARCHIVELOANS = {
    TITLE: '¿Quieres archivar el préstamo?',
    DESCRIPTION:
        'Al hacer click en el botón archivar préstamo, se archivara comercialmente y no se podrá recuperar',
    CANCEL: 'Cancelar',
    ARCHIVE: 'Archivar préstamo',
};
export const STATUS_PRODUCTS = {
    ACTIVE: 1,
    INACTIVE: 2,
    ARCHIVE: 3,
};

export const LOAN_PRODUCT_PER_PAGE = 8;

export const NEW_PRODUCT_URL = '/NewLoanProduct';
