import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {isPrimitiveString} from '@/hooks/constants';
import {addEndorsementOption} from '@components/ShoppingCart/Redux/Actions/EndorsementsReducer';
import {DISCHARGE_ENDORSEMENT_ERRORS, ERROR_MESSAGE_MAP} from '@Shopify/ClientProfile/constants';
import {IAddDischarge} from '@Shopify/ClientProfile/interfaces';
import {ADD_DISCHARGE} from '@Shopify/ClientProfile/Redux/types';

const addDischargeStart = () => ({
    type: ADD_DISCHARGE.ADD_START,
});

const addDischargeSuccess = (result: AxiosResponse) => ({
    type: ADD_DISCHARGE.ADD_SUCCESS,
    payload: result.data,
});

const addDischargeError = () => ({
    type: ADD_DISCHARGE.ADD_ERROR,
});

export const addDischargeOrEndorsement = (
    discharge: IAddDischarge,
    token: string,
    recalculate?: () => void,
    onError?: (message: string) => void,
    addOptions: {extend?: boolean; addPay?: boolean} = {}
) => {
    return async (dispatch: AppDispatch) => {
        dispatch(addDischargeStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddDischargeEndorsement}`,
                discharge,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(addDischargeSuccess(response.data));
            if (addOptions && (addOptions.extend || addOptions.addPay)) {
                dispatch(
                    addEndorsementOption(
                        discharge.pawnId,
                        addOptions.extend || false,
                        addOptions.addPay || false
                    )
                );
            }
            if (recalculate) recalculate();
        } catch (error) {
            let errorMessage = '';
            if (axios.isAxiosError(error)) {
                const message = isPrimitiveString(error.response?.data)
                    ? String(error.response?.data)
                    : '';
                errorMessage = message || ERROR_MESSAGE_MAP[discharge.typeMovement];
            } else {
                errorMessage = DISCHARGE_ENDORSEMENT_ERRORS.GENERAL;
            }
            dispatch(addDischargeError());
            if (onError) onError(errorMessage);
        }
    };
};
