import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, ContainerImg, Image, Text} from '@Shopify/Ecommerce/styles';
import AtaskateLogo from '@images/AtaskateLogo.svg';
import ButtonGeneral from '@General/Atoms/Button';
import {IconSvg} from '@General/Atoms/NotificationsList/styles';
import {ProgressBar} from '@components/SignUp/ProgressBar';
import {CreateAccount} from '@components/SignUp/CreateAccount';
import {YourCompany} from '@components/SignUp/YourCompany';
import {TypeSale} from '@components/SignUp/TypeSale';
import {SelectPlan} from '@components/SignUp/SelectPlan';
import {LoadingScreen} from '@components/SignUp/LoadingScreen';
import {backToStep, resetAccountData} from '@components/SignUp/Redux/Actions/saveDataFormAction';
import {PROGRESSITEMS} from '@components/SignUp/constants';

export const SignUp = () => {
    const dispatch = useDispatch();

    const {actualStep, loading} = useSelector((state: RootState) => state.saveDataSingUp);

    const ViewActualStep = () => {
        const viewStep: {[key: number]: React.JSX.Element} = {
            1: <CreateAccount />,
            2: <YourCompany />,
            3: <TypeSale />,
            4: <SelectPlan />,
        };
        return viewStep[actualStep];
    };
    const backPreviousStep = () => {
        if (actualStep !== 1) {
            dispatch(backToStep());
        }
    };

    useEffect(() => {
        return () => {
            dispatch(resetAccountData());
        };
    }, []);

    return (
        <>
            {loading ? (
                <LoadingScreen />
            ) : (
                <ContainerFlex
                    backG="#E5E5FF"
                    FlexDir="column"
                    Gap="65px"
                    Padding="40px"
                    Height="100vh"
                    OverFlow="auto"
                    MinH="fit-content"
                    Justify="flex-start"
                >
                    <ContainerFlex
                        Display="grid"
                        Justify="center"
                        Align="center"
                        Height="auto"
                        Position="relative"
                    >
                        {actualStep !== 1 && (
                            <ButtonGeneral
                                FontSize="0.875rem"
                                textColor="#5A5AFF"
                                Cursor="pointer"
                                hBackG="none"
                                border="none"
                                backGround="transparent"
                                hText="#0D166B"
                                cursor="pointer"
                                padding="0"
                                height="auto"
                                clic={backPreviousStep}
                                width="4.5rem"
                                position="absolute"
                                text={
                                    <>
                                        <IconSvg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 25"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M12 20L4 12L12 4L13.425 5.4L7.825 11H20V13H7.825L13.425 18.6L12 20Z"
                                                fill="#5A5AFF"
                                            />
                                        </IconSvg>
                                        <Text FontSize="1rem" FontWeight="500" Cursor="pointer">
                                            Atrás
                                        </Text>
                                    </>
                                }
                            />
                        )}
                        <ContainerImg>
                            <Image src={AtaskateLogo} alt="ataskate-logo" Width="204px" />
                        </ContainerImg>
                    </ContainerFlex>
                    <ContainerFlex
                        Display="grid"
                        Width="auto"
                        Height="auto"
                        Radius="24px"
                        backG="#FFF"
                        BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
                        Padding="32px"
                        Gap="24px"
                        GridRows="repeat(auto-fit)"
                        JustifyItems="center"
                    >
                        <ProgressBar progressItems={PROGRESSITEMS} actualStep={actualStep} />
                        <ViewActualStep />
                    </ContainerFlex>
                    <ContainerFlex Height="auto" Width="29rem">
                        <ContainerFlex Height="auto">
                            <Text
                                Cursor="pointer"
                                FontSize="0.75rem"
                                FontWeight="500"
                                Color="#5A5AFF"
                                Height="auto"
                                onClick={() => {
                                    window.location.href = `https://www.multiapoyo.com.mx/pages/legales`;
                                }}
                            >
                                Términos y condiciones de uso
                            </Text>
                            <Text
                                FontSize="0.75rem"
                                Color="#5A5AFF"
                                Height="auto"
                                FontWeight="500"
                                Padding="0 0.25rem"
                            >
                                y el
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex
                            Height="auto"
                            onClick={() => {
                                window.location.href = `https://www.multiapoyo.com.mx/pages/aviso-de-privacidad`;
                            }}
                        >
                            <Text
                                Width="7rem"
                                Cursor="pointer"
                                Height="auto"
                                FontSize="0.75rem"
                                FontWeight="500"
                                Color="#5A5AFF"
                            >
                                Aviso de privacidad
                            </Text>
                            <Text
                                Width="10rem"
                                FontSize="0.75rem"
                                Color="#5A5AFF"
                                Height="auto"
                                FontWeight="500"
                                Padding="0 0.125rem"
                            >
                                de Multiapoyo.
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </>
    );
};
