import {
    lOGS_FILE_REQUEST,
    lOGS_FILE_USER_SOUCCES,
    lOGS_FILE_USER_ERROR,
    RESET_DOWNLOAD_DATA,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const LogsFilesUserD = (state = initialState, action) => {
    switch (action.type) {
        case lOGS_FILE_REQUEST:
            return {...state, loading: true};
        case lOGS_FILE_USER_SOUCCES:
            return {...state, loading: false, userData: action.payload};
        case lOGS_FILE_USER_ERROR:
            return {...state, loading: false};
        case RESET_DOWNLOAD_DATA:
            return {...state, userData: null};
        default:
            return state;
    }
};
