import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_UPDATE_PERIODS_START,
    GET_UPDATE_PERIODS_SUCCESS,
    GET_UPDATE_PERIODS_ERROR,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';
export const getUpdatePeriodsStart = () => {
    return {
        type: GET_UPDATE_PERIODS_START,
    };
};
export const getUpdatePeriodsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_UPDATE_PERIODS_SUCCESS,
        payload: result.data,
    };
};
export const getUpdatePeriodsError = (error: AxiosError) => {
    return {
        type: GET_UPDATE_PERIODS_ERROR,
        error,
    };
};
export const getUpdatePeriods = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getUpdatePeriodsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUpdatePeriods}`,
                {headers}
            );
            dispatch(getUpdatePeriodsSuccess(response));
        } catch (error) {
            dispatch(getUpdatePeriodsError(error as AxiosError));
        }
    };
};
