import {
    GET_ROLE_USERS,
    GET_ROLE_USERS_SUCCESS,
    GET_ROLE_USERS_ERROR,
} from '@components/RolesPermissions/Redux/Types';
import {IUsersResponse} from '@components/RolesPermissions/interfaces';
import {AxiosError, AxiosResponse} from 'axios';

export interface IGetUsers {
    loading: boolean;
    error: boolean;
    users: IUsersResponse[];
}

const initialState: IGetUsers = {
    loading: false,
    error: false,
    users: [],
};

export const GetRoleUsersReducers = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_ROLE_USERS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ROLE_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload.data,
            };
        case GET_ROLE_USERS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
