import React, {useState, useEffect} from 'react';
import {ContainerCellGrid, ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {OPTION_TABLE, TEXT_NEW_USER} from '@/components/NewUserEmployee/Operational/constants';
import Warning from '@/images/IconeWarningBlue.svg';
import arrows from '@/images/ArrowsInvert.svg';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {RootState} from '@/config/store';
import {useSelector, useDispatch} from 'react-redux';
import {newRolesUserList} from '@/components/Users/UserProfile/Redux/Actions/PutStatusRoleUser';
import {DoneSwitch} from '@components/CreateUsers/Form/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {MAX_ROLES, roleSettingsText} from '@/components/Users/UserProfile/RoleSettings/constants';
import add from '@/images/addnewIcon.svg';
import {useNavigate} from 'react-router-dom';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {Role, UpdateRolePayload} from '@/components/Users/UserProfile/RoleSettings/interfaces';
import {ModalRoles} from '@/components/Users/UserProfile/RoleSettings/ModalRoles';
import {
    allRoleOption,
    closeRoleModal,
} from '@/components/Users/UserProfile/Redux/Actions/GetAllRoleAction';
import GetAllModulesAction from '@/components/RolesPermissions/Redux/Actions/GetAllModulesAction';
import {
    NewRolesStylesTab,
    StylesRowHead,
    StylesTableRow,
    StylesTextItems,
} from '@/components/Users/UserProfile/Operational/styles';
import {profileUser} from '@/components/MyCompanyUser/Redux/Actions/UserProfileAction';
export const AssignedRole = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userId = useSelector((state: RootState) => state.UserProfileAdmin?.selectedEmployId);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const isLoading = useSelector((state: RootState) => state.getRoleCompanyUser.loading);
    const rolesData = useSelector(
        (state: RootState) => state.getRoleCompanyUser?.userData || []
    ) as Role[];
    const isModalOpen = useSelector((state: RootState) => state.getRoleAllUser.isModalOpen);
    const selectedRole = useSelector((state: RootState) => state.getRoleAllUser.selectedRole);
    const employeeId = userId;
    const [switchStates, setSwitchStates] = useState<Record<number, boolean>>({});
    const [selectedRolesCount, setSelectedRolesCount] = useState(0);
    useEffect(() => {
        const initialStates = rolesData.reduce(
            (acc, role) => ({
                ...acc,
                [role.id]: role.isActive,
            }),
            {}
        );
        setSwitchStates(initialStates);
        setSelectedRolesCount(Object.values(initialStates).filter(Boolean).length);
    }, [rolesData]);
    const handleSwitchChange = async (roleId: number) => {
        if (!userId || !token) return;
        const newState = !switchStates[roleId];
        if (newState && selectedRolesCount >= MAX_ROLES) {
            return;
        }
        setSwitchStates((prev) => ({
            ...prev,
            [roleId]: newState,
        }));
        setSelectedRolesCount((prev) => (newState ? prev + 1 : prev - 1));
        const payload: UpdateRolePayload = {
            userId,
            roleId,
            isActive: newState,
        };
        await dispatch(newRolesUserList(token, payload));
        await dispatch(profileUser(token, employeeId, navigate, false));
    };
    const handleRoleClick = async (role: Role) => {
        try {
            await dispatch(allRoleOption(token, role.id, role));
        } catch (error) {
            return error;
        }
    };
    useEffect(() => {
        if (token) dispatch(GetAllModulesAction(3, token));
    }, []);
    return (
        <>
            <ContainerFlex {...NewRolesStylesTab}>
                {isLoading ? (
                    <ContainerFlex Justify="center" Align="center" Height="400px">
                        <Loading />
                    </ContainerFlex>
                ) : (
                    <>
                        <ContainerFlex
                            Height="max-content"
                            Justify="start"
                            Align="start"
                            FlexDir="column"
                        >
                            <Text FontSize="1.25rem" FontWeight="600" Color="#2A2C2F">
                                {TEXT_NEW_USER.ASSIGNMENT_Role}
                            </Text>
                            <Text>{TEXT_NEW_USER.ROLE_USER_ASSIGNMENT}</Text>
                        </ContainerFlex>
                        <ContainerFlex backG="#DBF4FF" Padding="16px" Radius="8px">
                            <ContainerCellGrid Gap="8px" Height="max-content">
                                <Image width="24px" height="24px" src={Warning} alt="warning" />
                                <Text FontWeight="700" Color="#004461">
                                    {TEXT_NEW_USER.WARNING_ROL}
                                </Text>
                            </ContainerCellGrid>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" MinH="400px" MaxH="180px">
                            <Table>
                                <TableRowHead {...StylesRowHead}>
                                    {OPTION_TABLE.map((option, index) => (
                                        <TableItem key={index} Justify="start">
                                            <Text
                                                FontSize="0.875rem"
                                                Color="#2A2C2F"
                                                FontWeight="700"
                                            >
                                                {option.name}
                                            </Text>
                                            <Image src={arrows} alt="arrows" />
                                        </TableItem>
                                    ))}
                                </TableRowHead>
                                <TableItemsContainer Overflow="auto">
                                    {rolesData.length > 0 ? (
                                        rolesData.map((role: Role) => (
                                            <TableRowItems key={role.id} {...StylesTableRow}>
                                                <TableItem Justify="start">
                                                    <Text
                                                        onClick={() => handleRoleClick(role)}
                                                        {...StylesTextItems}
                                                        FontWeight="700"
                                                        Color="#5A5AFF"
                                                    >
                                                        {role.roleName}
                                                    </Text>
                                                </TableItem>
                                                <TableItem Justify="start">
                                                    <Text
                                                        {...StylesTextItems}
                                                        FontWeight="400"
                                                        Color="#2A2C2F"
                                                    >
                                                        {role.accessType}
                                                    </Text>
                                                </TableItem>
                                                <TableItem Justify="start" Gap="24px">
                                                    <DoneSwitch
                                                        checked={switchStates[role.id]}
                                                        onChange={() => handleSwitchChange(role.id)}
                                                        disabled={
                                                            !switchStates[role.id] &&
                                                            selectedRolesCount >= MAX_ROLES
                                                        }
                                                    />
                                                </TableItem>
                                            </TableRowItems>
                                        ))
                                    ) : (
                                        <Text Padding="24px" FontSize="0.875rem" FontWeight="400">
                                            {roleSettingsText.ERROR}
                                        </Text>
                                    )}
                                </TableItemsContainer>
                            </Table>
                        </ContainerFlex>
                        <ContainerFlex Justify="start">
                            <ButtonGenerals
                                SecondaryButton="#FFFFFF"
                                onClick={() => navigate('/RolesAndPermissions/NewRole')}
                            >
                                <Image src={add} alt="add-icon" />
                                <Text Color="#5A5AFF">{roleSettingsText.NEW_ROL}</Text>
                            </ButtonGenerals>
                        </ContainerFlex>
                    </>
                )}
            </ContainerFlex>
            {isModalOpen && (
                <ModalRoles
                    isOpen={isModalOpen}
                    closeModal={() => dispatch(closeRoleModal())}
                    selectedRole={selectedRole}
                />
            )}
        </>
    );
};
