import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Text, Input, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {
    BUTTON,
    HEADER_TITTLE,
    MODAL_BOX_FUNDING,
} from '@/components/CashFlowActions/Operational/Constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {
    Modal,
    TableCell,
    TableContainer,
    TableRow,
    Overlay,
} from '@/components/CashFlowActions/Operational/styles';
import {validationSchema} from '@/components/CashFlowActions/Operational/ValidationYup';
import {ClosedModalProps} from '@/components/CashFlowActions/Operational/interfaces';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {SendCashfFnding} from '@/components/CashFlowActions/Redux/Actions/SendCashFundingActions';

interface Employee {
    id: string;
    userEmployeeName: string;
}

interface FormData {
    amounts: string[];
}

export const ModalBranchBox = ({isOpen, closeModal}: ClosedModalProps) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const employeeData = useSelector(
        (state: RootState) => state.employeeCashFunding.userData as Employee[]
    );
    const branchId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.branchDetails?.[0]?.branchId;
    });
    const errorMesage = useSelector((state: RootState) => state.sendCashFunding.error);
    const formatCurrency = useFormatCurrency();
    const {
        control,
        handleSubmit,
        formState: {errors},
        reset,
    } = useForm<FormData>({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            amounts: employeeData?.map(() => '') || [],
        },
    });
    const [error, setError] = useState<string | null>(null);
    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
            reset();
        }
    };
    const handleClose = () => {
        reset();
        closeModal();
    };
    const onSubmit = (formData: FormData) => {
        const FundingData = {
            branchId,
            users: employeeData.map((employee, index) => {
                const amountString = formData.amounts[index];
                const amountNumber = parseFloat(amountString.replace(/[^0-9.-]+/g, ''));
                return {
                    employeeId: employee.id,
                    amount: amountNumber,
                };
            }),
        };

        try {
            dispatch(SendCashfFnding(token, FundingData));
            closeModal();
        } catch (error) {
            setError(errorMesage);
        } finally {
            reset();
        }
    };

    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex
                    FlexDir="column"
                    Width="100%"
                    MaxW="32%"
                    MinW="400px"
                    Height="100vh"
                    Radius="16px 0px 0px 16px"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Align="start"
                >
                    <Text
                        Padding="24px"
                        FontSize="24px"
                        FontWeight="700"
                        Color="#1D1E20"
                        FontFamily="Nunito"
                    >
                        {MODAL_BOX_FUNDING.TITTLE}
                    </Text>

                    <ContainerForm
                        Padding="24px"
                        FlexDir="column"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <TableContainer>
                            <TableRow>
                                <TableCell align="left">
                                    <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500">
                                        {HEADER_TITTLE.TITTLE}
                                    </Text>
                                </TableCell>
                                <TableCell align="right">
                                    <Text
                                        FontSize="0.875rem"
                                        Color="#2A2C2F"
                                        FontWeight="500"
                                        Justify="end"
                                    >
                                        {HEADER_TITTLE.SUB_TITTLE_TWO}
                                    </Text>
                                </TableCell>
                            </TableRow>

                            {employeeData?.map((employee, rowIndex) => (
                                <TableRow key={employee.id}>
                                    <TableCell align="left">
                                        <Text FontWeight="500" FontSize="0.875rem">
                                            {employee.userEmployeeName}
                                        </Text>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Controller
                                            name={`amounts.${rowIndex}`}
                                            control={control}
                                            render={({field}) => (
                                                <>
                                                    <Input
                                                        {...field}
                                                        onChange={(e) =>
                                                            field.onChange(e.target.value)
                                                        }
                                                        onBlur={(e) => {
                                                            const formattedValue = formatCurrency(
                                                                e.target.value
                                                            );
                                                            field.onChange(formattedValue);
                                                        }}
                                                        placeholder="$0.00"
                                                        value={field.value}
                                                        type="text"
                                                        TextAlign="end"
                                                    />
                                                    {errors.amounts?.[rowIndex] && (
                                                        <Text Color="#FF6357" FontSize="0.75rem">
                                                            {errors.amounts[rowIndex]?.message}
                                                        </Text>
                                                    )}
                                                </>
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableContainer>
                        {error && (
                            <ContainerFlex Justify="start" Height="max-content">
                                <Text Color="#FF6357" FontSize="0.75rem">
                                    {error}
                                </Text>
                            </ContainerFlex>
                        )}
                        <ContainerFlex Align="end" ColumnGap="16px">
                            <ButtonGenerals
                                onClick={() => handleClose()}
                                SecondaryButton="#FFFFFF"
                                Width="100%"
                                type="button"
                                Cursor="pointer"
                            >
                                {BUTTON.CLOSE}
                            </ButtonGenerals>

                            <ButtonGenerals Width="100%" type="submit" Cursor="pointer">
                                {BUTTON.FONDING}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
