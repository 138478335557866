import {
    IChargeAndPenaltyItem,
    IChargesAndPenaltiesReducer,
    IEditedCharge,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {
    HANDLE_ALL_CHARGES_CHECKBOXS,
    HANDLE_CHARGE_CHECKBOX,
    SHOW_EDIT_BUTTONS,
    ADD_NEW_CHARGE,
    SELECT_CHARGE,
    EDIT_CHARGE,
    DELETE_CHARGE,
    TOGGLE_ALL_SELECT_CHECKBOX,
    SET_DEFAULT_CHARGES_AND_PENALTIES,
    RESET_CHARGES_AND_PENALTIES,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';

const initialState: IChargesAndPenaltiesReducer = {
    chargesAndPenalties: [],
    selectedCharge: {
        id: 0,
        typeCharge: {typeChargeId: 0, label: ''},
        chargeName: '',
        chargeAmount: {typeChargeAmountId: 0, amount: 0, label: ''},
        condition: {conditionId: 0, label: ''},
        eventType: {eventTypeId: 0, label: ''},
        isChecked: false,
        isShowingEditButton: false,
    },
    allSelectCheckbox: false,
};

const setChargesAndPenaltiesTable = (
    state = initialState,
    action: {
        type: string;
        id: number;
        newCharge: IChargeAndPenaltyItem;
        selectedChargeAndPenalty: IChargeAndPenaltyItem;
        editedCharge: IEditedCharge;
        defaultCharges: IChargeAndPenaltyItem[];
    }
): IChargesAndPenaltiesReducer => {
    switch (action.type) {
        case HANDLE_ALL_CHARGES_CHECKBOXS: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => ({
                    ...item,
                    isChecked: state.allSelectCheckbox,
                })),
            };
        }
        case HANDLE_CHARGE_CHECKBOX: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => {
                    if (item.id === action.id) {
                        return {
                            ...item,
                            isChecked: !item.isChecked,
                        };
                    }
                    return item;
                }),
            };
        }
        case SHOW_EDIT_BUTTONS: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => {
                    if (item.id === action.id) {
                        return {...item, isShowingEditButton: !item.isShowingEditButton};
                    }
                    return {...item, isShowingEditButton: false};
                }),
            };
        }
        case ADD_NEW_CHARGE: {
            return {
                ...state,
                chargesAndPenalties: [...state.chargesAndPenalties, action.newCharge],
            };
        }
        case SELECT_CHARGE: {
            return {...state, selectedCharge: action.selectedChargeAndPenalty};
        }
        case EDIT_CHARGE: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.map((item) => {
                    if (item.id === state.selectedCharge.id) {
                        return {...item, ...action.editedCharge};
                    }
                    return item;
                }),
            };
        }
        case DELETE_CHARGE: {
            return {
                ...state,
                chargesAndPenalties: state.chargesAndPenalties.filter(
                    (item) => item.id !== state.selectedCharge.id
                ),
            };
        }
        case TOGGLE_ALL_SELECT_CHECKBOX: {
            return {
                ...state,
                allSelectCheckbox: !state.allSelectCheckbox,
            };
        }
        case SET_DEFAULT_CHARGES_AND_PENALTIES: {
            return {
                ...state,
                chargesAndPenalties: [...action.defaultCharges],
            };
        }
        case RESET_CHARGES_AND_PENALTIES: {
            return initialState;
        }
        default:
            return state;
    }
};

export default setChargesAndPenaltiesTable;
