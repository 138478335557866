import {
    IChargesAndPenaltiesPost,
    IClientDocuments,
    IDisbursements,
    IDisbursementsPost,
    IDocumentsPost,
    IFrecuencyTermPost,
    IGenderTerms,
    IGenderTermsPost,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {IFrequencyPaymentTable} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {IChargeAndPenaltyItem} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const getSelectedDocuments = (documents: IClientDocuments[]): IDocumentsPost[] => {
    return documents
        .filter((item) => item.isChecked)
        .map((item) => {
            return {
                documentId: item.documentId,
                docName: item.docName,
                docDescription: item.docDescription,
                seniorityJob: `${item.seniorityJob} ${item.seniorityJobTime.label}`,
                isChecked: item.isChecked,
            };
        });
};

export const getSelectedFrequencyTerms = (
    frequencyTerms: IFrequencyPaymentTable[]
): IFrecuencyTermPost[] => {
    return frequencyTerms
        .filter((item) => item.isChecked)
        .map((item) => {
            return {
                frecuancyId: item.frecuancyId,
                countFrecuancyMin: item.countPaymentMin,
                countFrecuancyMax: item.countPaymentMax,
                isChecked: item.isChecked,
            };
        });
};

export const getSelectedChargesAndPenalties = (
    chargesPenalties: IChargeAndPenaltyItem[]
): IChargesAndPenaltiesPost[] => {
    return chargesPenalties
        .filter((item) => item.isChecked)
        .map((item) => {
            return {
                typeChargeId: item.typeCharge.typeChargeId,
                chargeName: item.chargeName,
                chargeAmount: item.chargeAmount.amount,
                typeChargeAmountId: item.chargeAmount.typeChargeAmountId,
                conditionId: item.condition.conditionId,
                eventTypeId: item.eventType.eventTypeId,
            };
        });
};

export const getSelectedGenderTerms = (genderTerms: IGenderTerms[]): IGenderTermsPost[] => {
    return genderTerms
        .filter((item) => item.isChecked)
        .map((item) => {
            return {
                genderId: item.genderId,
                genderName: item.genderName,
            };
        });
};

export const getSelectedDisbursements = (disbursements: IDisbursements[]): IDisbursementsPost[] => {
    return disbursements
        .filter((item) => item.isChecked)
        .map((item) => {
            return {
                disbursementsId: item.disbursementId,
                isChecked: item.isChecked,
            };
        });
};
