import * as yup from 'yup';
import {DateTime} from 'luxon';
import {VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    isFutureYear,
    isValidDate,
    regexPatterns,
} from '@components/PersonalLoans/NewLoanProduct/schemaNewLoanProduct';

export const schemaProductDetails = yup.object().shape(
    {
        termName: yup
            .string()
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .max(100, VALIDATIONS_MSG.PRODUCT_DETAILS.TERM_NAME.MAX_LENGTH)
            .test('no-spaces', VALIDATIONS_MSG.NO_SPACES, (value) => value.trim().length > 0)
            .matches(
                regexPatterns.termName,
                VALIDATIONS_MSG.PRODUCT_DETAILS.TERM_NAME.INVALID_CHARACTERS
            ),
        description: yup
            .string()
            .optional()
            .max(500, VALIDATIONS_MSG.PRODUCT_DETAILS.DESCRIPTION.MAX_LENGTH)
            .matches(
                regexPatterns.description,
                VALIDATIONS_MSG.PRODUCT_DETAILS.DESCRIPTION.INVALID_CHARACTERS
            )
            .test('no-spaces', VALIDATIONS_MSG.NO_SPACES, (value) =>
                value ? value.trim().length > 0 : true
            ),
        minAmount: yup
            .number()
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .positive(VALIDATIONS_MSG.PRODUCT_DETAILS.MIN_AMOUNT.POSITIVE_NUMBER)
            .lessThan(
                yup.ref('maxAmount'),
                VALIDATIONS_MSG.PRODUCT_DETAILS.MIN_AMOUNT.LESS_THAN_MAX
            ),
        maxAmount: yup
            .number()
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .positive(VALIDATIONS_MSG.PRODUCT_DETAILS.MAX_AMOUNT.POSITIVE_NUMBER)
            .moreThan(
                yup.ref('minAmount'),
                VALIDATIONS_MSG.PRODUCT_DETAILS.MAX_AMOUNT.MORE_THAN_MIN
            ),
        dateDueInit: yup
            .string()
            .nullable()
            .test(
                'is-future-date',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_INIT.FUTURE_DATE,
                isFutureYear
            )
            .test(
                'is-valid-date',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_INIT.VALID_DATE,
                isValidDate
            ),
        dateDueEnd: yup
            .string()
            .nullable()
            .notRequired()
            .test(
                'is-end-date-valid',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_END.VALID_DATE,
                (value) => {
                    if (value !== null) {
                        const date = DateTime.fromISO(value!);
                        const now = DateTime.now();
                        const tenYearsFromNow = now.plus({years: 10});
                        return (
                            date.isValid &&
                            date >= now.startOf('day') &&
                            date <= tenYearsFromNow.endOf('day')
                        );
                    }
                    return true;
                }
            )
            .test(
                'is-more-than-init-date',
                VALIDATIONS_MSG.PRODUCT_DETAILS.DUE_DATE_END.MORE_THAN_INIT_DATE,
                function (value) {
                    const {dateDueInit} = this.parent;
                    if (dateDueInit !== null && value !== null && value) {
                        const initDate = DateTime.fromISO(dateDueInit);
                        const endDate = DateTime.fromISO(value);
                        return initDate < endDate;
                    }
                    return true;
                }
            ),
        porcentAnualRate: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
        periodMin: yup.object().when(['periodMax.value'], {
            is: (val: number) => val !== 0,
            then: (schema) =>
                schema
                    .shape({
                        label: yup.string(),
                        value: yup
                            .number()
                            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
                            .min(1, VALIDATIONS_MSG.MANDATORY_FIELD),
                    })
                    .test(
                        'is-less',
                        VALIDATIONS_MSG.PRODUCT_DETAILS.PERIOD_MIN.LESS_THAN_MAX_PERIOD,
                        function (value) {
                            const {periodMax} = this.parent;
                            if (periodMax && periodMax.value !== 0 && value && value.value !== 0) {
                                return value.value < periodMax.value;
                            }
                            return true;
                        }
                    ),
            otherwise: (schema) =>
                schema.shape({
                    label: yup.string(),
                    value: yup
                        .number()
                        .required(VALIDATIONS_MSG.MANDATORY_FIELD)
                        .min(1, VALIDATIONS_MSG.MANDATORY_FIELD),
                }),
        }),
        periodMax: yup.object().when(['periodMin.value'], {
            is: (val: number) => val !== 0,
            then: (schema) =>
                schema
                    .shape({
                        label: yup.string(),
                        value: yup.number(),
                    })
                    .test(
                        'is-greater',
                        VALIDATIONS_MSG.PRODUCT_DETAILS.PERIOD_MAX.MORE_THAN_MIN_PERIOD,
                        function (value) {
                            const {periodMin} = this.parent;
                            if (
                                periodMin &&
                                periodMin.value !== undefined &&
                                value &&
                                value.value !== undefined &&
                                value.value !== 0
                            ) {
                                return value.value > periodMin.value;
                            }
                            return true;
                        }
                    ),
            otherwise: (schema) =>
                schema.shape({
                    label: yup.string().notRequired(),
                    value: yup.number().notRequired(),
                }),
        }),
        requestChannel: yup
            .array()
            .of(
                yup.object().shape({
                    label: yup.string(),
                    value: yup.number().required(VALIDATIONS_MSG.MANDATORY_FIELD),
                })
            )
            .required(VALIDATIONS_MSG.MANDATORY_FIELD)
            .min(1, VALIDATIONS_MSG.PRODUCT_DETAILS.REQUEST_CHANNEL.AT_LEAST_ONE),
        countLimitLoans: yup
            .number()
            .nullable()
            .transform((value, originalValue) =>
                originalValue !== '' ? Number(originalValue) : null
            )
            .min(0, VALIDATIONS_MSG.PRODUCT_DETAILS.COUNT_LIMIT_LOANS.POSITIVE_NUMBER)
            .integer(VALIDATIONS_MSG.PRODUCT_DETAILS.COUNT_LIMIT_LOANS.INTEGER),
    },
    [
        ['minAmount', 'maxAmount'],
        ['periodMin', 'periodMax'],
    ]
);
