export const GET_FREQUENCY_PAYMENTS_START = 'GET_FREQUENCY_PAYMENTS_START';
export const GET_FREQUENCY_PAYMENTS_SUCESS = 'GET_FREQUENCY_PAYMENTS_SUCESS';
export const GET_FREQUENCY_PAYMENTS_ERROR = 'GET_FREQUENCY_PAYMENTS_ERROR';

export const SET_FREQUENCY_PAYMENTS_TABLE = 'SET_FREQUENCY_PAYMENTS_TABLE';
export const HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE = 'HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE';

export const SAVE_NEW_LOAN_PRODUCT_DATA = 'SAVE_NEW_LOAN_PRODUCT_DATA';
export const UPDATE_LOAN_PAYMENT_FREQUENCY = 'UPDATE_LOAN_PAYMENT_FREQUENCY';
export const HANDLE_CHECKBOX_PAYMENT_METHODS = 'HANDLE_CHECKBOX_PAYMENT_METHODS';
export const SET_PAYMENT_METHODS = 'SET_PAYMENT_METHODS';

export const HANDLE_ALL_CHARGES_CHECKBOXS = 'HANDLE_ALL_CHARGES_CHECKBOXS';
export const HANDLE_CHARGE_CHECKBOX = 'HANDLE_CHARGE_CHECKBOX';
export const SHOW_EDIT_BUTTONS = 'SHOW_EDIT_BUTTONS';
export const ADD_NEW_CHARGE = 'ADD_NEW_CHARGE';
export const DELETE_CHARGE = 'DELETE_CHARGE';
export const SELECT_CHARGE = 'SELECT_CHARGE';
export const EDIT_CHARGE = 'EDIT_CHARGE';
export const TOGGLE_ALL_SELECT_CHECKBOX = 'TOGGLE_ALL_SELECT_CHECKBOX';
export const SET_DEFAULT_CHARGES_AND_PENALTIES = 'SET_DEFAULT_CHARGES_AND_PENALTIES';

export const GET_DOCUMENTS_PREDIFINED_OPTIONS_START = 'GET_DOCUMENTS_PREDIFINED_OPTIONS_START';
export const GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS = 'GET_DOCUMENTS_PREDIFINED_OPTIONS_SUCCESS';
export const GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR = 'GET_DOCUMENTS_PREDIFINED_OPTIONS_ERROR';
export const ADD_DOCUMENT_NEW_OPTION = 'ADD_DOCUMENT_NEW_OPTION';
export const DELETE_DOCUMENT_NEW_OPTION = 'DELETE_DOCUMENT_NEW_OPTION';

export const GET_DISBURSEMENT_OPTIONS_START = 'GET_DISBURSEMENT_OPTIONS_START';
export const GET_DISBURSEMENT_OPTIONS_SUCCESS = 'GET_DISBURSEMENT_OPTIONS_SUCCESS';
export const GET_DISBURSEMENT_OPTIONS_ERROR = 'GET_DISBURSEMENT_OPTIONS_ERROR';

export const GET_CAT_PAYMENT_TYPES_START = 'GET_CAT_PAYMENT_TYPES_START';
export const GET_CAT_PAYMENT_TYPES_SUCCESS = 'GET_CAT_PAYMENT_TYPES_SUCCESS';
export const GET_CAT_PAYMENT_TYPES_ERROR = 'GET_CAT_PAYMENT_TYPES_ERROR';

export const GET_DEFAULT_CHARGES_AND_PENALTIES_START = 'GET_DEFAULT_CHARGES_AND_PENALTIES_START';
export const GET_DEFAULT_CHARGES_AND_PENALTIES_SUCCESS =
    'GET_DEFAULT_CHARGES_AND_PENALTIES_SUCCESS';
export const GET_DEFAULT_CHARGES_AND_PENALTIES_ERROR = 'GET_DEFAULT_CHARGES_AND_PENALTIES_ERROR';

export const GET_TYPE_CHARGE_AND_PENALTIES_START = 'GET_TYPE_CHARGE_AND_PENALTIES_START';
export const GET_TYPE_CHARGE_AND_PENALTIES_SUCCESS = 'GET_TYPE_CHARGE_AND_PENALTIES_SUCCESS';
export const GET_TYPE_CHARGE_AND_PENALTIES_ERROR = 'GET_TYPE_CHARGE_AND_PENALTIES_ERROR';

export const GET_TYPE_AMOUNT_CHARGE_PENALTIES_START = 'GET_TYPE_AMOUNT_CHARGE_PENALTIES_START';
export const GET_TYPE_AMOUNT_CHARGE_PENALTIES_SUCCESS = 'GET_TYPE_AMOUNT_CHARGE_PENALTIES_SUCCESS';
export const GET_TYPE_AMOUNT_CHARGE_PENALTIES_ERROR = 'GET_TYPE_AMOUNT_CHARGE_PENALTIES_ERROR';

export const GET_CAT_CONDITIONS_TERMS_START = 'GET_CAT_CONDITIONS_TERMS_START';
export const GET_CAT_CONDITIONS_TERMS_SUCCESS = 'GET_CAT_CONDITIONS_TERMS_SUCCESS';
export const GET_CAT_CONDITIONS_TERMS_ERROR = 'GET_CAT_CONDITIONS_TERMS_ERROR';

export const GET_TYPE_EVENT_CONDITION_START = 'GET_TYPE_EVENT_CONDITION_START';
export const GET_TYPE_EVENT_CONDITION_SUCCESS = 'GET_TYPE_EVENT_CONDITION_SUCCESS';
export const GET_TYPE_EVENT_CONDITION_ERROR = 'GET_TYPE_EVENT_CONDITION_ERROR';

export const CREATE_TERM_PERSONAL_LOAN_START = 'CREATE_TERM_PERSONAL_LOAN_START';
export const CREATE_TERM_PERSONAL_LOAN_SUCCESS = 'CREATE_TERM_PERSONAL_LOAN_SUCCESS';
export const CREATE_TERM_PERSONAL_LOAN_ERROR = 'CREATE_TERM_PERSONAL_LOAN_ERROR';

export const RESET_NEW_LOAN_PRODUCT_DATA = 'RESET_NEW_LOAN_PRODUCT_DATA';
export const RESET_FREQUENCY_TABLE = 'RESET_FREQUENCY_TABLE';
export const RESET_CHARGES_AND_PENALTIES = 'RESET_CHARGES_AND_PENALTIES';
export const RESET_DISBURSEMENT_DOCUMENTS = 'RESET_DISBURSEMENT_DOCUMENTS';
export const RESET_FREQUENCY_PAYMENTS_REDUCER = 'RESET_FREQUENCY_PAYMENTS_REDUCER';

export const CREATE_AUTOMATIC_LOAN_ACCEPTANCE_START = 'CREATE_AUTOMATIC_LOAN_ACCEPTANCE_START';
export const CREATE_AUTOMATIC_LOAN_ACCEPTANCE_SUCCESS = 'CREATE_AUTOMATIC_LOAN_ACCEPTANCE_SUCCESS';
export const CREATE_AUTOMATIC_LOAN_ACCEPTANCE_ERROR = 'CREATE_AUTOMATIC_LOAN_ACCEPTANCE_ERROR';
export const SET_IS_EDITING_LOAN_VALUE = 'SET_IS_EDITING_LOAN_VALUE';

export const VALIDATE_TERM_NAME_START = 'VALIDATE_TERM_NAME_START';
export const VALIDATE_TERM_NAME_SUCCESS = 'VALIDATE_TERM_NAME_SUCCESS';
export const VALIDATE_TERM_NAME_ERROR = 'VALIDATE_TERM_NAME_ERROR';
