import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Input, Text} from '@Shopify/Ecommerce/styles';

import {INavbarProps} from '@components/AdminNavbar/interfaces';
import ataskateClientLogo from '@images/logoClient.svg';
import {Notifications} from '@components/AdminNavbar/Notification/Notification';
import {SearchInput} from '@Customer/Styles';
import SearchImg from '@/images/search.svg';
import Users from '@/images/UsersWhite.svg';
import arrow from '@/images/Arrow_Wihite.svg';

import {HEADERS_CLIENT_HOME} from '@components/HomePublicClient/constants';
import {AccountMenuClient} from '@components/HomePublicClient/AccountMenuClient';
import {useNavigate} from 'react-router-dom';

export const ClientNavBar: React.FC<INavbarProps> = ({
    isAdmin,
    openMenuPos,
    setOpenMenuPos,
    cartToggle,
}) => {
    const [openMenu, setOpenMenu] = useState(false);
    const [openNotifications, setOpenNotifications] = useState(false);

    const handleClickAwayNotifications = () => {
        setOpenNotifications(false);
    };
    const handleClickAway = () => {
        setOpenMenu(false);
    };
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const useData = useSelector((state: RootState) => state.getUsersValidation.userData);
    const navigate = useNavigate();

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex
                Width="100%"
                Height="56px"
                backG="#0D166B"
                Padding="0.5rem 1.5rem"
                Display="flex"
                Justify="space-between"
                Gap="0.5rem"
            >
                <Image src={ataskateClientLogo} alt="ataskate-logo" />
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    Margin="0 0 4px 0"
                    BoxShadowH=""
                    BoxShadowF=""
                    Padding="0 1rem 0 0"
                    BackG="#FFFFFF"
                    Radius="32px"
                    InputPadding="0 1rem"
                    Width="40%"
                >
                    <Input type="text" placeholder="Buscar" />
                    <Image src={SearchImg} Cursor="pointer" />
                </SearchInput>
                {!useData && !token && (
                    <ContainerFlex
                        Width="8rem"
                        backG="#5A5AFF"
                        Radius="32px"
                        ColumnGap="8px"
                        Cursor="pointer"
                        onClick={() => navigate('/login/MarketUser')}
                    >
                        <Image src={Users} alt="ataskate-logo" Cursor="pointer" />{' '}
                        <Text Color="#FFFFFF" FontWeight="700" Cursor="pointer">
                            {HEADERS_CLIENT_HOME.LOG_BUTTON}
                        </Text>
                    </ContainerFlex>
                )}

                {useData && useData.names && token && (
                    <ContainerFlex
                        Width="6.5rem"
                        Align="end"
                        Cursor="pointer"
                        onClick={() => setOpenMenu(!openMenu)}
                    >
                        <ContainerFlex FlexDir="column" Align="start" Cursor="pointer">
                            <Text FontSize="0.85rem" Color="#FFFFFF" Cursor="pointer">
                                {HEADERS_CLIENT_HOME.HELLO}
                                {useData && useData.names.split(' ')[0]}
                            </Text>
                            <Text FontSize="1rem" Color="#FFFFFF" FontWeight="700" Cursor="pointer">
                                {HEADERS_CLIENT_HOME.ACCOUNT}
                            </Text>
                        </ContainerFlex>
                        <Image src={arrow} alt="arrow" />
                    </ContainerFlex>
                )}
            </ContainerFlex>
            {openMenu && <AccountMenuClient handleClickAway={handleClickAway} />}
            {openNotifications && (
                <Notifications handleClickAwayNotifications={handleClickAwayNotifications} />
            )}
        </ContainerFlex>
    );
};
