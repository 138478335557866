import {LEVEL_GROUP_TYPES as TYPE} from '@components/LocationsBranch/Redux/types';
import {Levels, SelectedLevel} from '@components/LocationsBranch/Redux/interfaces';
import {AppDispatch} from '@/config/store';

export const initGroups = (groupType: string) => ({
    type: TYPE.INIT_GROUPS,
    payload: [
        {
            group: 1,
            levels: [],
            selectedLevelId: 0,
            fetching: false,
        },
    ],
    groupType,
});

export const updateGroup = (group: number, levels: Levels[], groupType: string) => ({
    type: TYPE.UPDATE_GROUP,
    groupLevels: levels,
    group,
    groupType,
});

export const selectGroupLevel = (group: number, selectedLevelId: number, groupType: string) => ({
    type: TYPE.SELECT_GROUP_LEVEL,
    selectedLevel: {levelId: selectedLevelId},
    group,
    groupType,
});

export const setCurrentSelectedLevelId = (groupType: string, selected: SelectedLevel) => ({
    type: TYPE.CURRENT_SELECTED_LEVELID,
    groupType,
    selectedLevel: selected,
});

export const fillingGroup = (group: number, groupType: string) => ({
    type: TYPE.FILLING_GROUP,
    group,
    groupType,
});

export const addGroupLevel = (groupType: string, group: number, levels: Levels[]) => ({
    type: TYPE.ADD_LEVEL_GROUP,
    groupLevels: levels,
    groupType,
    group,
});

export const resetGroupLevels = () => ({
    type: TYPE.RESET_STATE,
});

export const resetGroupTypeSelection = (groupType: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(initGroups(groupType));
    };
};
