import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    POST_DUPLICATE_ROLE,
    POST_DUPLICATE_ROLE_ERROR,
    POST_DUPLICATE_ROLE_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const postDuplicateRole = () => {
    return {
        type: POST_DUPLICATE_ROLE,
    };
};

export const postDuplicateRoleSuccess = (result: AxiosResponse) => {
    return {
        type: POST_DUPLICATE_ROLE_SUCCESS,
        payload: result,
    };
};

export const postDuplicateRoleError = (error: AxiosError) => {
    return {
        type: POST_DUPLICATE_ROLE_ERROR,
        payload: error,
    };
};

export const postDuplicateRoleAction = (
    data: {roleId: number; roleName: string},
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(postDuplicateRole());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostDuplicateRole}`;
        try {
            const response = await axios.post(url, data, {headers});
            dispatch(postDuplicateRoleSuccess(response));
        } catch (error) {
            dispatch(postDuplicateRoleError(error as AxiosError));
        }
    };
};

export default postDuplicateRoleAction;
