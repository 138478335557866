import React, {useEffect, useState} from 'react';
import {ContainerFlex, Image, Text, TextEllipsis} from '@components/Shopify/Ecommerce/styles';
import {TableItem, TableItemsContainer} from '@components/Shopify/Endeavors/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {Table, TableRowHead} from '@components/CashFlow/FundingInquiry/styles';
import {
    NO_RESULTS_FOUND,
    TABLE_HEADERS,
    TABLE_STATUS_FILTER_VALUES,
} from '@components/CreditsAndPawns/constants';
import {ApplicationRow} from '@components/CreditsAndPawns/ApplicationRow';
import {IApplicationData} from '@components/CreditsAndPawns/interfaces';
import columnsIcon from '@components/CreditsAndPawns/icons/columnsIcon.svg';
import arrowDownIcon from '@components/CreditsAndPawns/icons/arrowDownIcon.svg';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {LoadingGeneral} from '@components/General/Atoms/Loading/LoadingGeneral';
import NoFilterResult from '@components/CreditsAndPawns/NoFilterResult';

export const ApplicationsTable = () => {
    const [areAllChecked, setAreAllChecked] = useState(false);
    const {data, loadingTable, status} = useSelector((state: RootState) => {
        return state.getListLoans;
    });
    const {filterData, isLoading, noFilterResults} = useSelector((state: RootState) => {
        return state.getFilterListLoans;
    });
    const needAdditionalDateColumn = status !== TABLE_STATUS_FILTER_VALUES.SOLICITUDES;
    const statusToHeaderMap = {
        [TABLE_STATUS_FILTER_VALUES.REJECTED]: TABLE_HEADERS.REJECTION_DATE,
        [TABLE_STATUS_FILTER_VALUES.LIQUIDATED]: TABLE_HEADERS.LIQUIDATION_DATE,
        [TABLE_STATUS_FILTER_VALUES.APPROVED]: TABLE_HEADERS.APPROVAL_DATE,
    };
    const updatedDateHeader = statusToHeaderMap[status] || TABLE_HEADERS.PAYMENT_DATE;
    const tableHeaders = [
        {title: TABLE_HEADERS.APPLICATION_ID},
        {title: TABLE_HEADERS.CLIENT},
        {title: TABLE_HEADERS.SCORE},
        {title: TABLE_HEADERS.VALUATION},
        {title: TABLE_HEADERS.TERM},
        {title: TABLE_HEADERS.AMOUNT},
        {title: TABLE_HEADERS.REQUEST_DATE},
        ...(needAdditionalDateColumn ? [{title: updatedDateHeader}] : []),
        {title: TABLE_HEADERS.STATE},
    ];

    const [applicationListCopy, setApplicationListCopy] = useState<IApplicationData[]>([]);
    const handleMainCheckbox = () => {
        if (!areAllChecked) {
            const newArray = applicationListCopy.map((application) => {
                return {
                    ...application,
                    isChecked: true,
                };
            });
            setApplicationListCopy(newArray);
            setAreAllChecked(true);
        } else {
            const newArray = applicationListCopy.map((application) => {
                return {
                    ...application,
                    isChecked: false,
                };
            });
            setApplicationListCopy(newArray);
            setAreAllChecked(false);
        }
    };
    const handleApplicationCheck = (id: number) => {
        const newArray = applicationListCopy.map((application) => {
            if (application.applicationId === id) {
                return {
                    ...application,
                    isChecked: !application.isChecked,
                };
            }
            return application;
        });
        setApplicationListCopy(newArray);
    };

    const isActiveListEmpty = filterData.length > 0 || (data.length > 0 && !noFilterResults);
    const activeList = filterData.length > 0 || noFilterResults ? filterData : data;

    const additionalColumn = needAdditionalDateColumn ? '5fr' : '';

    useEffect(() => {
        setApplicationListCopy(activeList);
    }, [activeList]);
    return (
        <>
            <ContainerFlex>
                <Table>
                    <TableRowHead
                        Height="48px"
                        GridColumn={`2fr 142px minmax(210px, 250px) minmax(70px,3fr) minmax(70px,3fr) minmax(110px,4fr) 100px minmax(130px,5fr) ${additionalColumn} minmax(133px,7fr) 136px`}
                        BackG="#fff"
                        BorderB="1px solid #E8E9EA"
                        Width="100%"
                    >
                        <TableItem Padding="1.125rem 1rem">
                            <Checkbox
                                checked={areAllChecked}
                                onChange={() => handleMainCheckbox()}
                            />
                        </TableItem>
                        {tableHeaders.map((header, index) => (
                            <TableItem
                                key={index}
                                Padding="1.125rem 1rem"
                                Justify={
                                    header.title === 'Score' || header.title === 'Tasa'
                                        ? 'center'
                                        : 'start'
                                }
                            >
                                <TextEllipsis
                                    FontFamily="Nunito"
                                    Color="#1D1E20"
                                    FontSize="0.875rem"
                                    FontWeight="700"
                                    Width="100%"
                                    TextAlign={
                                        header.title === 'Score' || header.title === 'Tasa'
                                            ? 'center'
                                            : 'start'
                                    }
                                >
                                    {header.title}
                                </TextEllipsis>
                            </TableItem>
                        ))}
                        <TableItem Padding="1.125rem 1rem" Gap="0.25rem" Justify="end">
                            <Image Width="24px" Height="24px" src={columnsIcon} Cursor="pointer" />
                            <Image
                                Width="24px"
                                Height="24px"
                                src={arrowDownIcon}
                                Cursor="pointer"
                            />
                        </TableItem>
                    </TableRowHead>
                    <TableItemsContainer Height="487px" Overflow="none" GridRow="repeat(10, 2fr)">
                        {!loadingTable || !isLoading ? (
                            isActiveListEmpty ? (
                                applicationListCopy.map((application: IApplicationData, index) => (
                                    <ApplicationRow
                                        key={index}
                                        application={application}
                                        handleApplicationCheck={handleApplicationCheck}
                                    />
                                ))
                            ) : noFilterResults ? (
                                <ContainerFlex Justify="center" Height="487px">
                                    <NoFilterResult />
                                </ContainerFlex>
                            ) : (
                                <ContainerFlex Justify="center" Height="487px">
                                    <Text
                                        FontSize="1rem"
                                        FontWeight="700"
                                        Color="#1D1E20"
                                        Justify="center"
                                    >
                                        {NO_RESULTS_FOUND.TABLE}
                                    </Text>
                                </ContainerFlex>
                            )
                        ) : (
                            <ContainerFlex Justify="center" Height="487px">
                                <LoadingGeneral />
                            </ContainerFlex>
                        )}
                    </TableItemsContainer>
                </Table>
            </ContainerFlex>
        </>
    );
};
