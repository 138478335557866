import React, {useState} from 'react';
import {DetailsPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Details/DetailsPreview';
import {INewProductDetailsProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {DetailsEditForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Details/DetailsEditForm';

export const NewProductDetails: React.FC<INewProductDetailsProps> = ({detailsRef}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    return (
        <>
            {isEditing ? (
                <DetailsEditForm
                    detailsRef={detailsRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <DetailsPreview
                    detailsRef={detailsRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </>
    );
};
