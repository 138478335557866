import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';

import {AddIdDocumentData, IDocumentsInfo} from '@Shopify/ClientProfile/interfaces';
import {ADD_ID_DOCUMENT} from '@Shopify/ClientProfile/Redux/types';
import {getClientDetails} from '@Shopify/ClientProfile/Redux/Action/GetClientDetails';

const addDocStart = () => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_START,
});

const addDocSuccess = (result: AxiosResponse) => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_SUCCESS,
    payload: result.data,
});

const addDocError = (message?: string) => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_ERROR,
    message,
});

export const addIdDocsSave = (imageInfo: IDocumentsInfo) => ({
    type: ADD_ID_DOCUMENT.ADD_DOC_SAVE,
    imageData: imageInfo,
});

export const addIdDocument = (
    data: AddIdDocumentData,
    imageInfo: IDocumentsInfo,
    token: string,
    actionOptions: {
        saveInStore?: boolean;
        fetchDetails?: boolean;
    } = {saveInStore: false, fetchDetails: false}
) => {
    return async (dispatch: AppDispatch) => {
        const {saveInStore = false, fetchDetails = false} = actionOptions;
        dispatch(addDocStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddIdentificationDocument}`,
                data,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (saveInStore) {
                dispatch(addIdDocsSave(imageInfo));
            }
            await dispatch(addDocSuccess(response.data));
            if (fetchDetails) await dispatch(getClientDetails(token, {clientId: data.clientId}));
        } catch (error) {
            const errorResp = error as AxiosError;
            dispatch(addDocError(errorResp.response?.data as string));
        }
    };
};

export const clearDocumentsError = () => ({
    type: ADD_ID_DOCUMENT.CLEAR_ERROR,
});

export const clearIdentificationImageData = () => ({
    type: ADD_ID_DOCUMENT.CLEAR_IMAGES,
});
