import {TableRowHead} from '@/components/CashFlow/FundingInquiry/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import QuestionMark from '@images/QuestionIcon.svg';
import React, {useState} from 'react';
import {modulesTable} from '@components/RolesPermissions/NewRole/constants';
import {TooltipRoles} from '@components/RolesPermissions/NewRole/Tooltip';
import {ITableHeaderProps} from '@components/RolesPermissions/NewRole/interfaces';

const TableHeader = ({totalCheckedModules, totalModules}: ITableHeaderProps) => {
    const [showTooltip, setshowTooltip] = useState(false);

    return (
        <TableRowHead
            BackG="none"
            GridColumn="50px 2fr 150px"
            Height="40px"
            Gap="8px"
            Width="100%"
            Radius="0px"
            Margin="15px 0px"
        >
            <Text>{''}</Text>
            <ContainerFlex Justify="start" Position="relative">
                <Text FontWeight="700" MarginLeft="32px">
                    {modulesTable.module}
                    {`    ${totalCheckedModules} de ${totalModules}`}
                </Text>
                <Image
                    src={QuestionMark}
                    alt="tooltip"
                    MarginLeft="15px"
                    Height="18px"
                    Width="18px"
                    Cursor="pointer"
                    onMouseEnter={() => setshowTooltip(true)}
                    onMouseLeave={() => setshowTooltip(false)}
                />
                {showTooltip && <TooltipRoles />}
            </ContainerFlex>

            <Text FontWeight="700">{modulesTable.access}</Text>
        </TableRowHead>
    );
};

export default TableHeader;
