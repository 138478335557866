import React, {useState} from 'react';
import {ContainerFlex, Text, Image, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {TITLES_USER} from '@components/Users/UserProfile/constants';
import PhoneCal from '@images/Add call.svg';
import Mail from '@images/mail_gray.svg';
import {RootState} from '@/config/store';
import {UserData} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import Dropdown from '@/components/Users/UserProfile/dropdownlist';
import Users from '@images/Users_select.svg';
import {useSelector} from 'react-redux';
import {LateralLine} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/style';
import {useNavigate} from 'react-router-dom';
import {EditableAvatar} from '@/components/Users/UserProfile/UploadAvatar';
import ImgInputUpload from '@/components/Users/UserProfile/ModalUpload';
import pencil from '@/images/pecilEdit.svg';
export const UserCard = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const profileUser = useSelector(
        (state: RootState) => state.UserProfileAdmin.userData?.data as UserData | undefined
    );
    if (!profileUser?.employeeInfo) {
        navigate('/Users');
        return null;
    }
    const {userEmployeeName, employeeInfo} = profileUser as UserData;
    const {image, email, roles, branches, accountCreationDate, phoneNumber, lastActive} =
        employeeInfo;
    const [profileImage, setProfileImage] = useState<string>(image);
    const [selectedRole, setSelectedRole] = useState<string>(roles[0]?.roleName || '');
    const [selectedBranch, setSelectedBranch] = useState<string>(branches[0]?.branchName || '');
    const handleModal = (isOpen: boolean): void => {
        setIsModalOpen(isOpen);
    };
    const handleSaveImage = (editedImage: string) => {
        setProfileImage(editedImage);
    };

    return (
        <ContainerFlex Radius="24px" Border="1px solid #E8E9EA" Padding="24px" Height="149px">
            <ContainerFlex Gap="16px">
                <ContainerFlex Width="" Justify="center" Align="center">
                    <EditableAvatar
                        src={profileImage || image}
                        onEditClick={() => handleModal(true)}
                        editIcon={pencil}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column">
                    <ContainerFlex>
                        <ContainerFlex Justify="start" Gap="4px">
                            <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                                {userEmployeeName}
                            </Text>

                            <ContainerFlex Gap="8px" Width="">
                                <Text Color="#54575C" FontWeight="400" FontSize="0.75rem">
                                    {TITLES_USER.LAST_ACTIVE}
                                </Text>
                                <Text
                                    Color="#54575C"
                                    Justify="end"
                                    FontSize="0.75rem"
                                    FontWeight="400"
                                >
                                    {lastActive}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>

                        <ContainerFlex Justify="end" Gap="8px">
                            <Text FontSize="0.75rem" FontWeight="400" Color="#54575C">
                                {TITLES_USER.DATE}
                            </Text>
                            <Text
                                Justify="start"
                                FontSize="0.75rem"
                                FontWeight="400"
                                Color="#1D1E20"
                            >
                                {accountCreationDate}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerCellGrid Gap="16px">
                        <>
                            {roles && (
                                <ContainerFlex
                                    Justify="start"
                                    Gap="4px"
                                    Width="max-content"
                                    Height="max-content"
                                >
                                    <ContainerFlex Height="32px" Width="32px">
                                        <Image alt="users" height="24px" src={Users} />
                                    </ContainerFlex>

                                    <Text FontSize="1rem" FontWeight="700">
                                        {TITLES_USER.ROL}
                                    </Text>
                                    <Dropdown
                                        items={roles.map((role) => ({
                                            id: role.roleId,
                                            name: role.roleName,
                                        }))}
                                        selectedItem={selectedRole}
                                        onSelect={setSelectedRole}
                                    />
                                </ContainerFlex>
                            )}
                            <LateralLine />
                        </>
                        <>
                            {branches && (
                                <ContainerFlex
                                    Justify="start"
                                    Gap="8px"
                                    Width="max-content"
                                    Height="max-content"
                                >
                                    <ContainerFlex Radius="18px" Height="32px" Width="32px">
                                        <Image alt="users" height="24px" src={Users} />
                                    </ContainerFlex>

                                    <Text FontSize="1rem" FontWeight="700">
                                        {TITLES_USER.PLACE}
                                    </Text>

                                    <Dropdown
                                        items={branches.map((branch) => ({
                                            id: branch.branchId,
                                            name: branch.branchName,
                                        }))}
                                        selectedItem={selectedBranch}
                                        onSelect={setSelectedBranch}
                                    />
                                </ContainerFlex>
                            )}
                        </>
                        <LateralLine />
                        <ContainerFlex Justify="start" Width="max-content" Height="max-content">
                            <ContainerFlex PaddingT="6px" Gap="8px">
                                <Image alt="phone" height="20px" src={PhoneCal} />
                                <ContainerFlex Justify="start" Gap="8px">
                                    <Text FontSize="1rem" FontWeight="700">
                                        {TITLES_USER.PHONE}
                                    </Text>

                                    <Text FontSize="0.875rem" FontWeight="400" Color="#1D1E20">
                                        {phoneNumber}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                        <LateralLine />
                        <ContainerFlex
                            Justify="start"
                            Gap="8px"
                            Width="max-content"
                            Height="max-content"
                        >
                            <ContainerFlex PaddingT="6px" Gap="8px">
                                <Image alt="email" height="20px" src={Mail} />
                                <Text FontSize="1rem" FontWeight="700">
                                    {TITLES_USER.USER_EMAIL}
                                </Text>

                                <Text FontSize="0.875rem" FontWeight="400" Color="#2A2C2F">
                                    {email}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
            {isModalOpen && (
                <ImgInputUpload
                    isOpen={isModalOpen}
                    closeModal={() => handleModal(false)}
                    onSave={handleSaveImage}
                    currentImage={profileImage || undefined}
                />
            )}
        </ContainerFlex>
    );
};
