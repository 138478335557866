import {
    GET_CAT_FAMILY,
    GET_CAT_FAMILY_ERROR,
    GET_CAT_FAMILY_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {updateFamilyChildren} from '@components/RequestNewCatalogLevel/Redux/Actions/GetCatGroupAction';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {ICatalogItems} from '@components/AuctionPass/interfaces';
import {getAuctionSubFamilyAction} from './GetSubFamilyAction';

export const getAllChildren = (
    response: ICatalogItems[],
    apiCall: (
        itemId: number,
        companyId: number,
        token: string
    ) => (dispatch: AppDispatch) => Promise<void>,
    companyId: number,
    token: string
) => {
    return (dispatch: AppDispatch) => {
        for (const item of response) {
            dispatch(apiCall(item.id, companyId, token));
        }
    };
};

export const getFamily = () => {
    return {
        type: GET_CAT_FAMILY,
    };
};

export const getFamilySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_FAMILY_SUCCESS,
        payload: result,
    };
};

export const getFamilyError = (error: AxiosError) => {
    return {
        type: GET_CAT_FAMILY_ERROR,
        payload: error,
    };
};

export const getAuctionFamilyAction = (groupId: number, companyId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getFamily());
        const params = {
            groupId: groupId?.toString(),
            companyId: companyId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAuctionFamilies}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(
                getAllChildren(response.data.data, getAuctionSubFamilyAction, companyId, token)
            );
            dispatch(getFamilySuccess(response));
            dispatch(updateFamilyChildren(groupId, response.data.data));
        } catch (error) {
            dispatch(getFamilyError(error as AxiosError));
        }
    };
};
