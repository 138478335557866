import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {BreadCrumb} from '@/components/RolesPermissions/BreadCrumb';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {ImageLogo} from '@components/LoginClient/styles';
import addImg from '@/images/addRoleWhite.svg';
import {rolesAndPermissions} from '@components/RolesPermissions/constants';
import RoleList from '@components/RolesPermissions/RoleList';
import {useNavigate} from 'react-router-dom';

export const RolesAndPermissions = () => {
    const navigate = useNavigate();
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="30px"
            backG="#FAFAFA"
            Height=""
            Gap="8px"
        >
            <BreadCrumb />
            <ContainerFlex Align="start" Justify="space-between" Height="3rem">
                <Text FontWeight="700" FontSize="1.7rem">
                    {rolesAndPermissions.Roles}
                </Text>
                <ButtonGenerals
                    Width="10rem"
                    Gap="12px"
                    onClick={() => navigate('/RolesAndPermissions/NewRole')}
                >
                    <ImageLogo Height="12px" Width="12px" src={addImg} alt="newRole" />
                    {rolesAndPermissions.NewRoles}
                </ButtonGenerals>
            </ContainerFlex>
            <RoleList />
        </ContainerFlex>
    );
};
