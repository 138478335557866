import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {AUCTION_TITLES, EXTRA_TEXT} from '@components/AuctionPass/GeneralScreen/constants';
import {CardForEdit} from '@components/AuctionPass/GeneralScreen/CardtForEdit';
import Breadcrumb from '@/components/General/Moleculs/Breadcrumb/Index';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import restored from '@images/IconeReturn.svg';
import {AuctionSettingsComponet} from '@/components/AuctionPass/AuctionSettings/AuctionComponent';
const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Creación de sucursaless'},
    {to: '#/', label: 'Ciclo comercial'},
    {to: '/AuctionPassEdit', label: 'Almoneda'},
];

export const AuctionPassTable = () => {
    return (
        <>
            <ContainerFlex
                Align="start"
                Justify="start"
                FlexDir="column"
                Gap="8px"
                Padding="30px"
                Position="relative"
            >
                <Breadcrumb items={itemsBreadcrumb} />
                <ContainerFlex Justify="space-between" Height="max-content">
                    <Text FontWeight="700" FontSize="2rem" MinH="3rem">
                        {AUCTION_TITLES.PAGE_TITLE}
                    </Text>
                    <ContainerFlex Width="max-content">
                        <Image src={restored} alt="restored-icone" />
                        <Text
                            Color="#5A5AFF"
                            FontSize="0.875rem"
                            FontWeight="700"
                            HColor="#0D166B"
                            Cursor="pointer"
                        >
                            {EXTRA_TEXT.RESTORE}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <Text FontSize="1.25rem" FontWeight="700" MinH="2rem" Color="#54575C">
                    {AUCTION_TITLES.SUBTITLE}
                </Text>
                <CardForEdit />
                <AuctionSettingsComponet />
            </ContainerFlex>
        </>
    );
};
