import React from 'react';
import {ContainerFlex, Image} from '@components/Shopify/Ecommerce/styles';
import yellowStart from '@/components/CreditQuoter/icons/yellowStarIcon.svg';
import greyStart from '@/components/CreditQuoter/icons/greyStarIcon.svg';

const RatingStars = ({rating, justify}: {rating: number; justify: string}) => {
    const starts = [...Array(5)];
    return (
        <ContainerFlex Gap="2px" Justify={justify} Height="12px">
            {starts.map((_, index) => (
                <Image
                    key={index}
                    Height="12px"
                    Width="12px"
                    src={index + 1 > rating ? greyStart : yellowStart}
                    alt="star-icon"
                />
            ))}
        </ContainerFlex>
    );
};

export default RatingStars;
