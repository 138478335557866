import React, {useEffect, useState} from 'react';
import {SearchInput} from '@/components/Customer/Styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import * as styles from '@/components/Quoter/stylesConstants';
import {useDispatch, useSelector} from 'react-redux';
import {
    getSearchClient,
    setFiltersValues,
    setSearchTerm,
    setSearchTermClear,
} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';
import {RootState} from '@/config/store';
import SearchLoanDropdown from '@components/CreditsAndPawns/SearchLoanDropdown';
import {ISearchClient} from '@components/CreditsAndPawns/interfaces';
import {NO_RESULTS_FOUND} from '@components/CreditsAndPawns/constants';

const FilterSearchClient = () => {
    const dispatch = useDispatch();
    const [menuDisplayStatus, setMenuDisplayStatus] = useState<boolean>(false);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const enterpriceId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId as number;
    });
    const {status} = useSelector((state: RootState) => {
        return state.getListLoans;
    });
    const {searchClientsData, isLoading, filtersValues, searchTerm} = useSelector(
        (state: RootState) => {
            return state.getFilterListLoans;
        }
    );

    const onClearSearch = () => {
        dispatch(setSearchTermClear());
        setMenuDisplayStatus(false);
        dispatch(setFiltersValues({...filtersValues, userClientId: null}));
    };

    const handleClickOutside = () => {
        setMenuDisplayStatus(false);
    };

    const handleClickOption = (value: ISearchClient) => {
        setMenuDisplayStatus(false);
        dispatch(setFiltersValues({...filtersValues, userClientId: value.userClientId}));
        dispatch(setSearchTerm(value.clientName));
    };

    useEffect(() => {
        if (searchTerm.length > 2 && filtersValues.userClientId === null) {
            dispatch(getSearchClient(token, enterpriceId, searchTerm, status));
            setMenuDisplayStatus(true);
        }
    }, [searchTerm, filtersValues.userClientId]);

    return (
        <ContainerFlex
            FlexDir="column"
            Gap="4px"
            Justify="flex-start"
            Align="flex-start"
            Height="68px"
        >
            <Text Color="#1D1E20" FontFamily="Nunito" FontWeight="700" LetterSpacing="0.3px">
                Cliente
            </Text>
            <SearchInput Display="flex" FontSize="1rem" Right="0">
                <input
                    type="text"
                    autoComplete="off"
                    value={searchTerm}
                    onChange={(e) => dispatch(setSearchTerm(e.target.value))}
                />

                <Icons {...styles.iconSearch} onClick={() => onClearSearch()} Cursor="pointer">
                    {searchTerm.length > 0 ? 'close' : 'search'}
                </Icons>
                <SearchLoanDropdown
                    onClearSearch={handleClickOutside}
                    menuDisplayStatus={menuDisplayStatus}
                    searchOptions={searchClientsData}
                    loading={isLoading}
                    onClickOption={(value) => handleClickOption(value)}
                    notFoundMessage={NO_RESULTS_FOUND.FILTER_SEARCH}
                />
            </SearchInput>
        </ContainerFlex>
    );
};

export default FilterSearchClient;
