import {
    HANDLE_CHECKBOX_PAYMENT_METHODS,
    SET_PAYMENT_METHODS,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IPaymentMethodState} from '@components/PersonalLoans/NewLoanProduct/interfaces';

const initialState: IPaymentMethodState[] = [];

const setPaymentMethods = (
    state = initialState,
    action: {
        type: string;
        payload: number;
        newPaymentMethodsArray: IPaymentMethodState[];
    }
): IPaymentMethodState[] => {
    switch (action.type) {
        case HANDLE_CHECKBOX_PAYMENT_METHODS:
            return state.map((item) => {
                if (item.typePaymentId === action.payload) {
                    return {
                        ...item,
                        isChecked: !item.isChecked,
                    };
                }
                return item;
            });
        case SET_PAYMENT_METHODS:
            return action.newPaymentMethodsArray;
        default:
            return state;
    }
};

export default setPaymentMethods;
