import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RESUMELOANS} from '@components/PersonalLoans/constants';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';

export const ResumeLoans = () => {
    const {numberSolicitude, amountRequested, frecuencyName, acoutnPayments} = useSelector(
        (state: RootState) => state.personalLoanRquestSummaryReducer
    );
    const formatCurrency = useFormatCurrency();
    const formatAmountRequested = formatCurrency(amountRequested?.toString());
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Border="solid 1px #0D166B"
            Radius="1rem"
            Padding="1.5rem"
            Gap="1.25rem"
            backG="#FFFFFF"
            MaxW="376px"
            MaxH="270px"
        >
            <ContainerFlex Justify="space-between" Align="start" Height="24px">
                <Text FontSize="1.25rem" Color="##1D1E20" FontWeight="700" oFlow="none">
                    {RESUMELOANS.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1rem">
                <ContainerFlex Justify="start" Gap="1rem" Height="68px">
                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="0.5rem">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESUMELOANS.MOUNT}
                        </Text>
                        <Text FontSize="1.75rem" FontWeight="700" Color="#2A2C2F">
                            {formatAmountRequested}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between" Align="start" FlexDir="column" Gap="4px">
                        <Text Color="#1D1E20" FontWeight="700">
                            {RESUMELOANS.REQUEST_NUMBER}
                        </Text>
                        <Text Padding="8px 0">{numberSolicitude}</Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="start" Gap="1rem" Height="68px">
                    <ContainerFlex Justify="space-between" Align="start" FlexDir="column" Gap="4px">
                        <Text Color="#1D1E20" FontWeight="700">
                            {RESUMELOANS.FRECUENCY}
                        </Text>
                        <Text Padding="8px 0">{frecuencyName}</Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between" Align="start" FlexDir="column" Gap="4px">
                        <Text Color="#1D1E20" FontWeight="700">
                            {RESUMELOANS.NUMBERPAY}
                        </Text>
                        <Text Padding="8px 0">{acoutnPayments}</Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
