import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {getDaysDifference} from '@/hooks/useFormatDate';
import {RenewalFormProps} from '@components/ShoppingCart/interface';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {ENDORSEMENTS} from '@components/ShoppingCart/constants';
import cancelIcon from '@images/DarkDeleteIcon.svg';
import checkIcon from '@images/DarkCheck.svg';
import {
    RenewalFormData,
    IRenewalExtension,
} from '@components/ShoppingCart/RenewalMenuOption/interface';
import {createExtension} from '@components/ShoppingCart/constants';
import {schemaExtend} from '@components/ShoppingCart/RenewalMenuOption/yupRenewalExtend';
import {addRenewalExtension} from '@components/ShoppingCart/Redux/Actions/AddRenewalExtension';

const RenewalForm: React.FC<RenewalFormProps> = ({endorsement, onFinish}) => {
    const dispatch = useDispatch();
    const [selectedPaymentDate, setSelectedPaymentDate] = useState<Date | null>(
        endorsement.extension?.nextPaymentDate
            ? new Date(endorsement.extension.nextPaymentDate)
            : new Date(endorsement.nextPaymentDate)
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const branchId = useSelector((state: RootState) => {
        const branchDetails = state.getUsersValidation.userData?.branchDetails;
        if (Array.isArray(branchDetails) && branchDetails.length > 0) {
            return branchDetails[0]?.branchId;
        }
        return undefined;
    });

    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);

    const formMethods = useForm<RenewalFormData>({
        resolver: yupResolver(schemaExtend),
        defaultValues: {paymentDate: null},
    });

    const {
        handleSubmit,
        watch,
        control,
        formState: {errors},
    } = formMethods;
    const paymentDate = watch('paymentDate');

    useEffect(() => {
        if (paymentDate) {
            setSelectedPaymentDate(paymentDate);
        }
    }, [paymentDate]);

    const currentExtensionDate = () =>
        endorsement.nextPaymentDate
            ? endorsement.nextPaymentDate
            : endorsement.extension?.nextPaymentDate!;

    const onSubmit = async (data: RenewalFormData) => {
        const totalAmount = calculateTotalAmount(true);
        const days = getDaysDifference(selectedPaymentDate!, currentExtensionDate());

        const extension: IRenewalExtension = createExtension(
            Number(cartData?.carShoppingId),
            endorsement.endorsementId,
            Number(branchId),
            endorsement.contractId,
            days,
            Number(totalAmount)
        );
        await dispatch(addRenewalExtension(token, extension));
        onFinish(true);
    };

    const handleImageClick = () => {
        if (!selectedPaymentDate || !isFutureDate(selectedPaymentDate)) {
            onFinish(false);
        } else {
            handleSubmit(onSubmit)();
        }
    };

    const isFutureDate = (date: Date) => {
        const currentDate = new Date(currentExtensionDate());
        const today = new Date();
        return date.getTime() > currentDate.getTime() && date.getTime() > today.getTime();
    };

    const calculateTotalAmount = (getNumber: boolean = false): string | number => {
        const daysDifference = getDaysDifference(selectedPaymentDate!, currentExtensionDate());
        if (daysDifference > 0) {
            const totalAmount = daysDifference * 200;
            return getNumber ? totalAmount : `$${totalAmount.toFixed(2)}`;
        }

        return getNumber ? 0 : '-';
    };

    return (
        <FlexPanel
            Padding="0.5rem 1rem"
            Align="flex-end"
            Gap="1.5rem"
            Self="stretch"
            Radius="0 0 0.25rem 0.25rem"
            Bb="1px solid #E8E9EA"
            Background="#FFF"
        >
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.25rem" Width="90%">
                <Text FontSize="1rem" FontWeight="700" Color="#1D1E20" LetterSpacing="0.019rem">
                    {ENDORSEMENTS.NEW_PAYMENT_DATE}
                </Text>
                <Controller
                    name="paymentDate"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <CalendarDateSelection
                            {...field}
                            placeholder=" /  / "
                            format="dd/MM/yyyy"
                            value={selectedPaymentDate}
                            error={!!errors.paymentDate?.message}
                            limitDate={false}
                            onSelect={(e) => {
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.25rem" Width="70%">
                <Text FontSize="1rem" FontWeight="700" Color="#1D1E20" LetterSpacing="0.019rem">
                    {ENDORSEMENTS.ADDITIONAL_COST}
                </Text>
                <Text
                    Height="2.75rem"
                    Padding="0.5rem 0"
                    FontSize="1rem"
                    FontWeight="400"
                    Color="#2A2C2F"
                    LetterSpacing="0.019rem"
                    Self="stretch"
                >
                    {calculateTotalAmount()}
                </Text>
            </ContainerFlex>
            <ContainerFlex Height="2.5rem" Align="center" Width="4%">
                <Image
                    src={
                        !selectedPaymentDate || !isFutureDate(selectedPaymentDate)
                            ? cancelIcon
                            : checkIcon
                    }
                    alt="action"
                    Cursor="pointer"
                    Width="1.5rem"
                    Height="1.5rem"
                    onClick={handleImageClick}
                />
            </ContainerFlex>
        </FlexPanel>
    );
};

export default RenewalForm;
