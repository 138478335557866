import {
    GET_CAT_FAMILY,
    GET_CAT_FAMILY_ERROR,
    GET_CAT_FAMILY_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IDATAFACE} from '@components/RequestNewCatalogLevel/interfacesTree';

export interface ICatFamily {
    loading: boolean;
    error: boolean;
    catFamily: IDATAFACE[];
}
const initialState: ICatFamily = {
    loading: false,
    catFamily: [
        {
            id: 0,
            nameGroup: '',
            nameFamily: '',
            nameBrand: '',
            nameSubFamily: '',
            name: '',
            status: false,
            markId: 0,
            children: [],
            iconUrl: '',
        },
    ],
    error: false,
};
export const getFamilyAuctionReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_CAT_FAMILY:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_CAT_FAMILY_SUCCESS:
            return {
                ...state,
                loading: false,
                catFamily: action.payload.data.data,
            };
        case GET_CAT_FAMILY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
