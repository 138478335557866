import React, {useState, useEffect} from 'react';
import {ContainerFlex, Text, Input, Image} from '@/components/Shopify/Ecommerce/styles';
import {EXPENSE_DETAILS} from '@/components/CashFlow/Expenses/ExpenseDetails/constants';
import {Controller} from 'react-hook-form';
import {IPrincipalInfo} from '@CashFlow/Expenses/ExpenseDetails/Types';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {SearchInput} from '@/components/Customer/Styles';
import greyQuestionmark from '@components/Branches/Images/GreyQuestionmark.svg';
import arrow from '@components/Branches/Images/DownArrowSelected.svg';
import {ClickAwayListener} from '@mui/material';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import add from '@/images/addPurple.svg';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';
import {
    MenuContextualDesign,
    SearchContainer,
    StyleContainer,
} from '@CashFlow/Expenses/ExpenseDetails/PreReduxConstants';
import {DateTime} from 'luxon';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getPaymentMethodsCashflow} from '@components/CashFlow/Redux/Actions/GetPaymentMethods';
import {getExpenseTypesAction} from '@components/CashFlow/Redux/Actions/getExpenseTypes';
import {getBranchProvidersAction} from '@components/CashFlow/Redux/Actions/GetBranchProviders';
import {IProviderType} from '@components/CashFlow/Expenses/ExpenseDetails/interfaces';

export const PrincipalInformation = ({
    control,
    register,
    setValue,
    errors,
    isOpen,
    setIsOpen,
    watch,
}: IPrincipalInfo) => {
    const [menu, setMenu] = useState(false);
    const today: Date = DateTime.now().toJSDate();
    const [date] = useState<Date | null>(today);

    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const [option, setOption] = useState('');

    const branchId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId ?? null,
        shallowEqual
    );
    useEffect(() => {
        dispatch(getPaymentMethodsCashflow(token));
        dispatch(getExpenseTypesAction(token));
        if (branchId) dispatch(getBranchProvidersAction(branchId, token));
    }, [branchId]);

    const expenseTypes = useSelector((state: RootState) => state.getExpenseTypes.expenseTypes).map(
        (item: {id: number; description: string}) => ({
            value: item.id,
            label: item.description,
        })
    );

    const paymentMethods = useSelector((state: RootState) =>
        state.getPaymentMethods.paymentMethods
            .filter((item: {id: number; description: string; status: boolean}) => item.status)
            .map((item: {id: number; description: string; status: boolean}) => ({
                value: item.id,
                label: item.description,
            }))
    );

    const providerTypes = useSelector(
        (state: RootState) => state.getBranchProviders.BranchProviders
    );

    const dataToDisplay = providerTypes
        ? providerTypes.map((i: {id: number; name: string}) => ({
              value: i.id,
              label: i.name,
          }))
        : [];

    return (
        <>
            <ContainerFlex Display="grid" GridColumns="repeat(3, 1fr)" Gap="10px">
                <ContainerFlex FlexDir="column" Align="start" Gap="0.6rem" Justify="start">
                    <Text FontWeight="600">{EXPENSE_DETAILS.expenseType}</Text>
                    <Controller
                        name={'expendType'}
                        control={control}
                        render={({field: {onChange, value, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                value={value}
                                placeholder="Selecciona"
                                isSearchable={false}
                                hasError={!!errors.expendType?.label?.message}
                                options={expenseTypes}
                                onChange={(selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                    <Text Color="#FF6357" FontSize="12px">
                        {errors.expendType?.label?.message as string}
                    </Text>
                </ContainerFlex>

                <ContainerFlex
                    FlexDir="column"
                    Align="start"
                    Gap="0.6rem"
                    Position="relative"
                    Height="100%"
                    Justify="start"
                >
                    <Text FontWeight="600">{EXPENSE_DETAILS.provider}</Text>
                    <Controller
                        name="supplierType"
                        control={control}
                        render={({field: {onChange, value}}) => (
                            <SearchInput
                                {...SearchContainer}
                                error={!!errors.supplierType?.message}
                                onClick={() => setMenu(true)}
                                Cursor="pointer"
                            >
                                <Text
                                    {...register('supplierType')}
                                    onChange={(selectedOption) => onChange(selectedOption)}
                                    MarginLeft="15px"
                                >
                                    {option}
                                </Text>
                                <Image
                                    src={arrow}
                                    alt="icon-warning"
                                    Cursor="pointer"
                                    Height="24px"
                                />

                                {menu && (
                                    <ClickAwayListener onClickAway={() => setMenu(false)}>
                                        <ContainerFlex {...StyleContainer}>
                                            {dataToDisplay.map((data: IProviderType) => {
                                                const handleProviderSelect = (
                                                    data: IProviderType
                                                ) => {
                                                    setOption(data.label);
                                                    setMenu(false);
                                                    setValue('supplierType', data);
                                                };

                                                return (
                                                    <ContainerFlex
                                                        {...MenuContextualDesign}
                                                        Cursor="pointer"
                                                        onClick={() => handleProviderSelect(data)}
                                                        key={data.value}
                                                    >
                                                        <Text
                                                            Color="#54575C"
                                                            FontSize="1rem"
                                                            Cursor="pointer"
                                                        >
                                                            {data.label}
                                                        </Text>
                                                    </ContainerFlex>
                                                );
                                            })}
                                            <ContainerFlex
                                                {...MenuContextualDesign}
                                                onClick={() => setIsOpen(true)}
                                                Cursor="pointer"
                                            >
                                                <ImageIcon src={add} cursor="pointer" />

                                                <Text
                                                    Color="#5A5AFF"
                                                    FontSize="1rem"
                                                    Cursor="pointer"
                                                >
                                                    Nuevo proveedor
                                                </Text>
                                            </ContainerFlex>
                                        </ContainerFlex>
                                    </ClickAwayListener>
                                )}
                            </SearchInput>
                        )}
                    />
                    <Text Color="#FF6357" FontSize="12px">
                        {errors.supplierType?.message as string}
                    </Text>
                </ContainerFlex>

                <ContainerFlex FlexDir="column" Align="start" Gap="0.6rem">
                    <Text FontWeight="600">{EXPENSE_DETAILS.paymentMethod}</Text>
                    <Controller
                        name={'paymentMethod'}
                        control={control}
                        render={({field: {onChange, value, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                value={value}
                                placeholder="Selecciona"
                                isSearchable={false}
                                hasError={!!errors.paymentMethod?.label?.message}
                                options={paymentMethods}
                                onChange={(selectedOption) => onChange(selectedOption)}
                            />
                        )}
                    />
                    <Text Color="#FF6357" FontSize="12px">
                        {errors.expendType?.label?.message as string}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                    <Text FontWeight="600">{EXPENSE_DETAILS.referenceId}</Text>
                    <Controller
                        name="transferNumber"
                        control={control}
                        render={({field}) => (
                            <SearchInput
                                Height="40px"
                                Position="none"
                                GridColumn="90% 10%"
                                Margin="0 0 4px 0"
                                BoxShadowH=""
                                BoxShadowF=""
                                Padding="0 1rem 0 0"
                                BackG="#FFFFFF"
                                Radius="32px"
                                InputPadding="0 1rem"
                                error={!!errors.transferNumber?.message}
                            >
                                <Input
                                    {...register('transferNumber')}
                                    type="number"
                                    maxLength={10}
                                />
                                <Image src={greyQuestionmark} alt="icon-warning" Cursor="pointer" />
                            </SearchInput>
                        )}
                    />
                    <Text Color="#FF6357" FontSize="12px">
                        {errors.transferNumber?.message as string}
                    </Text>
                </ContainerFlex>

                <ContainerFlex FlexDir="column" Align="start" FontSize="0.9rem" Gap="0.6rem">
                    <Text FontWeight="600">{EXPENSE_DETAILS.expenseDate}</Text>
                    <Controller
                        name="date"
                        control={control}
                        render={({field: {onChange, value, ...field}}) => (
                            <CalendarDateSelection
                                {...field}
                                placeholder=" /  /"
                                format="dd/MM/yyyy"
                                value={date}
                                error={!!errors.date?.message}
                                limitDate={true}
                                onSelect={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                    <Text Color="#FF6357" FontSize="12px">
                        {errors.date?.message as string}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
