import {
    UPDATE_DOCUMENTS_COPY_ARRAY,
    UPDATE_DOCUMENTS_COPY_ARRAY_RESET,
    SET_VISUAL_COPY_DOCUMENTS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDocumentsCopyArray} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';

export const updateDocumentsCopyArray = (payload: IDocumentsCopyArray[]) => {
    return {
        type: UPDATE_DOCUMENTS_COPY_ARRAY,
        payload,
    };
};

export const updateDocumentsCopyArrayReset = () => {
    return {
        type: UPDATE_DOCUMENTS_COPY_ARRAY_RESET,
    };
};

export const setVisualCopyDocuments = (payload: IDocumentsCopyArray[]) => {
    return {
        type: SET_VISUAL_COPY_DOCUMENTS,
        payload,
    };
};
