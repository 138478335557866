import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import * as style from '@components/CreateUsers/Styles';
import {BRANCHLOCATION} from '@components/CreateUsers/constants';
import {ITreeGroup} from '@components/CreateUsers/Redux/interface';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {treeGroups} from '@components/CreateUsers/Redux/Actions/TreeGroupsAndBranches';

export const TreeGroups = (props: ITreeGroup) => {
    const {Group, setGroup, GroupStep} = props;
    const {editUsers} = useSelector((state: RootState) => state.editUsers);
    const dispatch: AppDispatch = useDispatch();
    const {treeGroup, showTreeBranches} = useSelector(
        (state: RootState) => state.TreeGroupBranches
    );
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const company = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.enterpriceDetails
    );
    const companyId = company && company[0].enterpriceId;

    const handleShowGroups = (id: number, type: string) => {
        if (type === 'All') setGroup(Group.length === treeGroup.length ? [] : GroupStep);
        if (type === 'Group') {
            if (!Group.includes(id)) setGroup([...Group, id]);
            if (Group.includes(id)) setGroup(Group.filter((item: number) => item !== id));
        }
    };

    useEffect(() => {
        if (companyId) dispatch(treeGroups(token, {CompanyId: companyId}));
        if (treeGroup.length > 0 && showTreeBranches.copyBranches.length === 0) setGroup(GroupStep);
    }, [GroupStep.length, editUsers.name]);

    return (
        <ContainerFlex {...style.contentTree}>
            {treeGroup.length && (
                <>
                    <ContainerFlex
                        {...style.allOptions}
                        onClick={() => handleShowGroups(0, 'All')}
                        Cursor="pointer"
                    >
                        <Checkbox checked={Group.length === treeGroup.length} />
                        <Text {...style.allText}>{BRANCHLOCATION.ALLOPTIONS}</Text>
                    </ContainerFlex>
                    {treeGroup.map((item) => (
                        <ContainerFlex
                            key={item.id}
                            {...style.contentGroup}
                            onClick={() => handleShowGroups(item.id, 'Group')}
                            Cursor="pointer"
                        >
                            <Checkbox checked={Group.includes(item.id)} />
                            <TextEllipsis {...style.labelGroup}>{item.group}</TextEllipsis>
                        </ContainerFlex>
                    ))}
                </>
            )}
        </ContainerFlex>
    );
};
