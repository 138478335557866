import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import LoanQuoter from '@/components/CreditQuoter/LoanQuoter';
import {COMPANY_LOANS_QUOTER} from '@/components/CreditQuoter/constants';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {GetQuoterOthersLoansCompaniesList} from './Redux/Actions/GetQuoterOthersLoansCompanies';
import {ICompanyLoansQuoter} from '@components/CreditQuoter/interfaces';

const CompanyLoansQuoter = ({companyId}: ICompanyLoansQuoter) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const DataQuoterOthers = useSelector((state: RootState) => {
        return state.GetQuoterOthersLoansCompanies.dataOhterLoans;
    });
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        if (token) dispatch(GetQuoterOthersLoansCompaniesList(token, {companyId: companyId}));
    }, []);
    const moreInfoHandler = (frequencyTermCreditId: number) => {
        navigate(routers.CreditQuoter, {state: {frequencyTermCreditId, companyId}});
        dispatch(GetQuoterOthersLoansCompaniesList(token, {companyId: companyId}));
    };

    return (
        <ContainerFlex FlexDir="column" Gap="24px" Justify="center" Align="center">
            <Text FontWeight="600" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.5rem">
                {COMPANY_LOANS_QUOTER.TITLE}
            </Text>
            <ContainerFlex FlexDir="column" Justify="start" Gap="16px" OverFlowY="scroll">
                {DataQuoterOthers &&
                    DataQuoterOthers.map((loan, index) => (
                        <LoanQuoter
                            key={index}
                            {...loan}
                            SelectedId={() => moreInfoHandler(loan.frequencyTermCreditId)}
                        />
                    ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CompanyLoansQuoter;
