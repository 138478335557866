import React, {useState} from 'react';
import cuestion from '@images/CuestionsIcone.svg';
import {Text, Image} from '@components/Shopify/Ecommerce/styles';
import {
    Description,
    IconContainer,
    TooltipContainer,
    TooltipContent,
} from '@/components/AuctionPass/AuctionSettings/Operational/style';

interface TooltipProps {
    title?: string;
    description?: string;
}

const TooltipSpan: React.FC<TooltipProps> = ({title, description}) => {
    const [isVisible, setIsVisible] = useState(false);

    return (
        <TooltipContainer>
            <IconContainer
                onMouseEnter={() => setIsVisible(true)}
                onMouseLeave={() => setIsVisible(false)}
            >
                <Image src={cuestion} alt="help" Cursor="pointer" Width="1.5rem" Height="1.5rem" />

                {isVisible && (
                    <TooltipContent>
                        <Text FontWeight="700" Color="#ACACFF">
                            {title}
                        </Text>
                        <Description>{description}</Description>
                    </TooltipContent>
                )}
            </IconContainer>
        </TooltipContainer>
    );
};

export default TooltipSpan;
