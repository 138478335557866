import React from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, Text, Input} from '@/components/Shopify/Ecommerce/styles';
import {
    DATA_FORM,
    InputStyle,
    TextInput,
    Grids,
    TextInputEdit,
    GENERAL_TITLES,
} from '@components/Users/UserProfile/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {AddressFormProps, Colony} from './UserProfileBranchAssignment/Operational/typeInterface';
import GeneralsInput from './GeneralsInput';
import useAddressForm from './useAddressFom';

const AddressForm: React.FC<AddressFormProps> = ({
    isEdit,
    addressUser,
    control,
    errors,
    onPostalCodeChange,
    addressData,
}) => {
    const {
        selectedState,
        selectedTown,
        selectedColony,
        setSelectedState,
        setSelectedTown,
        setSelectedColony,
    } = useAddressForm(addressData, addressUser, isEdit);

    return (
        <ContainerFlex {...Grids}>
            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.POSTAL}</Text>
                {isEdit ? (
                    <Controller
                        name="postal"
                        control={control}
                        render={({field}) => (
                            <GeneralsInput
                                {...field}
                                error={!!errors.postal?.message}
                                helperText={errors.postal?.message}
                                onChange={(e) => {
                                    const newValue = e.target.value;
                                    field.onChange(newValue);
                                    if (newValue !== String(field.value)) {
                                        setSelectedColony(null);
                                    }
                                    onPostalCodeChange(newValue);
                                }}
                            />
                        )}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.postalCode?.length
                            ? addressUser.postalCode
                            : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>

            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.STREET}</Text>
                {isEdit ? (
                    <Controller
                        name="street"
                        control={control}
                        render={({field}) => (
                            <GeneralsInput
                                helperText={errors.street?.message}
                                {...field}
                                error={!!errors.street?.message}
                            />
                        )}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.street?.length ? addressUser.street : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>

            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.NO}</Text>
                {isEdit ? (
                    <Controller
                        name="outsideNumber"
                        control={control}
                        render={({field}) => (
                            <GeneralsInput
                                helperText={errors.outsideNumber?.message}
                                {...field}
                                error={!!errors.outsideNumber?.message}
                            />
                        )}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.outdoorNumber?.length
                            ? addressUser.outdoorNumber
                            : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.NO_IN}</Text>
                {isEdit ? (
                    <Controller
                        name="insideNumber"
                        control={control}
                        render={({field}) => (
                            <Input {...field} error={!!errors.insideNumber?.message} />
                        )}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.interiorNumber?.length
                            ? addressUser.interiorNumber
                            : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.COLONY}</Text>
                {isEdit ? (
                    <Controller
                        name="catColony"
                        control={control}
                        render={({field}) => {
                            if (selectedColony && addressData?.colonys) {
                                const currentColony = addressData.colonys.find(
                                    (colony) => colony.colonyName === selectedColony.label
                                );
                                if (currentColony) {
                                    const currentValue = parseInt(currentColony.colonyId);
                                    if (
                                        !field.value ||
                                        (typeof field.value === 'number' &&
                                            field.value !== currentValue)
                                    ) {
                                        field.onChange(currentValue);
                                    }
                                }
                            }
                            return (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    hasError={!!errors.catColony?.message}
                                    options={addressData?.colonys?.map((colony: Colony) => ({
                                        value: parseInt(colony.colonyId),
                                        label: colony.colonyName,
                                    }))}
                                    value={selectedColony}
                                    onChange={(selectedOption) => {
                                        setSelectedColony(selectedOption);
                                        field.onChange(selectedOption?.value);
                                    }}
                                />
                            );
                        }}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.suburbName?.length
                            ? addressUser.suburbName
                            : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.TOWN}</Text>
                {isEdit ? (
                    <Controller
                        name="town"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <GeneralsInput
                                {...field}
                                value={selectedTown}
                                onChange={(e) => {
                                    onChange(e);
                                    setSelectedTown(e.target.value);
                                }}
                                helperText={errors.town?.message}
                                error={!!errors.town?.message}
                            />
                        )}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.municipalityName?.length
                            ? addressUser.municipalityName
                            : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>

            <ContainerFlex {...InputStyle}>
                <Text {...TextInput}>{DATA_FORM.STATE}</Text>
                {isEdit ? (
                    <Controller
                        name="state"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <GeneralsInput
                                {...field}
                                value={selectedState}
                                onChange={(e) => {
                                    onChange(e);
                                    setSelectedState(e.target.value);
                                }}
                                helperText={errors.state?.message}
                                error={!!errors.state?.message}
                            />
                        )}
                    />
                ) : (
                    <Text {...TextInputEdit}>
                        {addressUser?.stateName?.length
                            ? addressUser.stateName
                            : GENERAL_TITLES.EMPITY}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AddressForm;
