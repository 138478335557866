import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';
import {styled} from '@mui/material/styles';
import {RootState} from '@config/store';
import {useForm} from 'react-hook-form';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {DeadlineResult, initialInfo} from '@CreateDeadline/interfaces';
import {Icons} from '@CreateBranch/BranchInformation/style';
import {getDeadlineSimulators} from '@/components/DeadLines/CreateDeadline/redux/actions/DeadLineSimulator';
import {TableDealines} from '@CreateDeadline/DeadlineSteps/Deadlines/TableDealines';
import {Example} from '@CreateDeadline/DeadlineSteps/Deadlines/ModalDeadlines/Example';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {setStep, tableDeadlines} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

export const DeadlinesComponent = (props: initialInfo) => {
    const {deadlineById, deadlinesGeneral, Branch} = props;
    const dispatch = useDispatch();
    const dependeceSimulator =
        deadlinesGeneral.gracePhase?.length > 0 &&
        deadlinesGeneral.initialInformation.branches?.length > 0;
    const {handleSubmit} = useForm({
        defaultValues: {},
    });
    const simulation: DeadlineResult = useSelector((state: RootState) => {
        return state.deadlineSimulator;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const [openExample, setOpenExample] = useState(false);

    const onSubmit = () => {
        dispatch(setStep(5));
        return false;
    };

    const NoMaxWidthTooltip = styled(({className, ...props}: TooltipProps) => (
        <Tooltip {...props} classes={{popper: className}} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    const handleExample = async () => {
        const data = {
            companyId: deadlinesGeneral.initialInformation.companyId,
            nameTerm: deadlinesGeneral.initialInformation.nameTerm,
            branches: Branch,
            startDate: deadlinesGeneral.initialInformation.startDate,
            endDate: deadlinesGeneral.initialInformation.endDate,
            catalogLevelType: deadlinesGeneral.initialInformation.catalogLevelType,
            catalogLevel: deadlinesGeneral.initialInformation.catalogLevel,
            storageRate: deadlinesGeneral.interest.storageRate,
            insuranceRate: deadlinesGeneral.interest.insuranceRate,
            dynamicAmount: deadlinesGeneral.amountTimes.dynamicAmount,
            quantityGarments: deadlinesGeneral.amountTimes.quantityGarments,
            rangeInterest: deadlinesGeneral.amountTimes.dynamicAmount
                ? deadlinesGeneral.rangeInterest
                : [],
            gracePhase: deadlinesGeneral.gracePhase,
        };
        dispatch(getDeadlineSimulators(data, token));
        setOpenExample(!openExample);
    };

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            deadlinesGeneral.gracePhase.length === 0
        ) {
            const vDeadlines = deadlineById.deadlineId.data;
            dispatch(tableDeadlines(vDeadlines.gracePhase));
        }

        if (deadlinesGeneral.gracePhase.length > 0 && (!deadlineById.edit || !deadlineById.copy))
            dispatch(tableDeadlines(deadlinesGeneral.gracePhase));
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
            Cursor="pointer"
            onClick={() => {
                if (
                    deadlinesGeneral.gracePhase.length > 0 ||
                    deadlinesGeneral.amountTimes.maximumLoanAmount
                ) {
                    dispatch(setStep(4));
                }
            }}
        >
            <TitleDeadline
                title={'Plazos'}
                step={deadlinesGeneral.step}
                stepCurrent={4}
                stepDependence={0}
                dependence={deadlinesGeneral.gracePhase.length > 0}
            />
            {deadlinesGeneral.step === 4 && (
                <FormContainer
                    MarginTp="0"
                    onSubmit={handleSubmit(onSubmit)}
                    autoComplete="off"
                    Padding="1.5rem"
                    Width="100%"
                    Justify="center"
                >
                    <TableDealines
                        deadlinesGeneral={deadlinesGeneral}
                        deadlineById={deadlineById}
                    />
                    <ContainerFlex Padding="1rem 0 0 0" Justify="center">
                        {!deadlineById.edit && (
                            <ButtonGeneral
                                FontSize="0.875rem"
                                hColor="#35cf44"
                                disabled={deadlinesGeneral.gracePhase.length === 0}
                                text="Continuar"
                            />
                        )}
                        <ContainerFlex Width="auto" Margin="0 0 0 1.875rem">
                            <NoMaxWidthTooltip
                                title={
                                    'Seleccione una sucursal y llene la tabla Plazos para ver la simulación.'
                                }
                            >
                                <Text
                                    FontSize="14px"
                                    FontStyle="italic"
                                    Cursor="pointer"
                                    Color={dependeceSimulator ? '#414042' : '#CBCED1'}
                                    onClick={() => {
                                        if (
                                            dependeceSimulator &&
                                            deadlinesGeneral.initialInformation.nameTerm
                                        )
                                            handleExample();
                                    }}
                                >
                                    <Icons
                                        MarginRight="0.688rem"
                                        className="material-icons"
                                        Color={dependeceSimulator ? '#414042' : '#CBCED1'}
                                    >
                                        visibility
                                    </Icons>
                                    Ver Simulación
                                </Text>
                            </NoMaxWidthTooltip>
                        </ContainerFlex>

                        {openExample && simulation.filters?.succeeded && (
                            <Example
                                openExample={openExample}
                                setOpenExample={setOpenExample}
                                deadlinesGeneral={deadlinesGeneral}
                                deadlineById={deadlineById}
                            />
                        )}
                    </ContainerFlex>
                </FormContainer>
            )}
        </ContainerFlex>
    );
};
