import {SpeechBubble, Text} from '@components/Shopify/Ecommerce/styles';
import React from 'react';
import {tooltip} from '@components/RolesPermissions/NewRole/constants';

export const TooltipRoles = () => {
    return (
        <SpeechBubble afterLeft="15%" transformX="translateX(33%);">
            <Text Color="#D8FF03" FontWeight="700">
                {tooltip.title}
            </Text>
            <Text Color="white" Width="90%" FlexWrap="wrap" MarginTop="10px">
                {tooltip.subTitle}
            </Text>
        </SpeechBubble>
    );
};
