import React from 'react';
import {ContainerFlex, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {REJECT_LOAN_MODAL} from '@components/PersonalLoans/constants';
import {
    rejectLoan,
    toggleRejectModal,
} from '@components/PersonalLoans/Redux/Actions/RejectLoanActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useParams} from 'react-router-dom';
export const RejectLoanModal = () => {
    const {creditId} = useParams<{creditId?: string}>();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const loading = useSelector((state: RootState) => {
        return state.RejectLoanReducer.loading;
    });
    const dispatch = useDispatch();
    const handleRejectLoan = () => {
        if (token && creditId !== undefined) {
            dispatch(rejectLoan(Number(creditId), token));
            dispatch(toggleRejectModal());
        }
    };

    return (
        <>
            <Modal BackC="rgba(0, 0, 0, 0.40)">
                <ContainerFlex
                    backG="#FFF"
                    Radius="1.5rem"
                    BoxShadow="0px 10px 60px 0px"
                    Padding="1.5rem"
                    Width="max-content"
                    Height="max-content"
                    FlexDir="column"
                    Align="start"
                >
                    <Text Color="#2A2C2F" FontWeight="700" FontSize="1.5rem" MarginB="0.75rem">
                        {REJECT_LOAN_MODAL.TITLE}
                    </Text>
                    <Text Color="#000" Padding="0.75rem 0px 1.5rem 0px">
                        {REJECT_LOAN_MODAL.DESCRIPTION}
                    </Text>
                    <ContainerFlex PaddingT="0.75rem" Gap="1rem">
                        <ButtonGenerals
                            SecondaryButton="#FFFFFF"
                            FontWeight="700"
                            Width="191px"
                            type="button"
                            onClick={() => dispatch(toggleRejectModal())}
                            Cursor="pointer"
                        >
                            {REJECT_LOAN_MODAL.CANCEL_BTN}
                        </ButtonGenerals>
                        <ButtonGenerals
                            BackGC="#A82424"
                            FontWeight="700"
                            type="button"
                            onClick={handleRejectLoan}
                            disabled={loading}
                            Cursor={loading ? 'not-allowed' : 'pointer'}
                        >
                            {REJECT_LOAN_MODAL.REJECT_BTN}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
