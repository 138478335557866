import {useDispatch} from 'react-redux';
import React from 'react';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import optionsIcon from '@components/PersonalLoans/NewLoanProduct/icons/optionsIcon.svg';
import {IRowChargesAndPenaltiesProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {AppDispatch} from '@/config/store';
import {
    handleChargeCheckbox,
    selectedCharge,
    showEditButtons,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {EditDeleteChargeButton} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/TableChargesAndPenalties/EditDeleteChargeButton';
import {RowTable} from '@components/PersonalLoans/NewLoanProduct/styles';

export const RowChargesAndPenalties: React.FC<IRowChargesAndPenaltiesProps> = ({
    item,
    chargeState,
    setChargeState,
    defaultChargesLength,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const amountText: string =
        item.chargeAmount.typeChargeAmountId === 1
            ? `$${item.chargeAmount.amount}`
            : `${item.chargeAmount.amount}%`;
    return (
        <>
            <RowTable
                GridColumns="40px repeat(5,1fr) 32px"
                Gap="1rem"
                Padding="0.5rem 1rem"
                Bb="1px solid #E8E9EA"
            >
                <Checkbox
                    checked={item.isChecked}
                    onChange={() => dispatch(handleChargeCheckbox(item.id))}
                />
                <Text Color="#2A2C2F" FontSize="0.875rem" Width="100%" Padding="0.25rem 0px">
                    {item.typeCharge.label}
                </Text>
                <Text
                    Color="#2A2C2F"
                    FontSize="0.875rem"
                    Width="100%"
                    Padding="0.25rem 0px"
                    wSpace="wrap"
                >
                    {item.chargeName}
                </Text>
                <Text Color="#2A2C2F" FontSize="0.875rem" Width="100%" Padding="0.25rem 0px">
                    {amountText}
                </Text>
                <Text
                    Color="#2A2C2F"
                    FontSize="0.875rem"
                    Width="100%"
                    Padding="0.25rem 0px"
                    wSpace="wrap"
                >
                    {item.condition.label}
                </Text>
                <Text
                    Color="#2A2C2F"
                    FontSize="0.875rem"
                    Width="100%"
                    Padding="0.25rem 0px"
                    wSpace="wrap"
                >
                    {item.eventType.label}
                </Text>
                <ContainerFlex
                    Width="24px"
                    Height="24px"
                    Cursor="pointer"
                    Position="relative"
                    FlexDir="column"
                    Justify="start"
                    Align="end"
                >
                    <Image
                        src={optionsIcon}
                        Width="24px"
                        Height="24px"
                        alt="options-icon"
                        Cursor="pointer"
                        onClick={() => {
                            dispatch(showEditButtons(item.id));
                            dispatch(selectedCharge(item));
                        }}
                    />
                    {item.isShowingEditButton && item.id <= defaultChargesLength && (
                        <EditDeleteChargeButton
                            id={item.id}
                            setChargeState={setChargeState}
                            chargeState={chargeState}
                            isOnlyEditing={true}
                        />
                    )}
                    {item.isShowingEditButton && item.id > defaultChargesLength && (
                        <EditDeleteChargeButton
                            id={item.id}
                            setChargeState={setChargeState}
                            chargeState={chargeState}
                            isOnlyEditing={false}
                        />
                    )}
                </ContainerFlex>
            </RowTable>
        </>
    );
};
