import {useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    IChargeTypeSelectProps,
    ISelectOption,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {RootState} from '@/config/store';

export const ChargeTypeSelect: React.FC<IChargeTypeSelectProps> = ({control, errors}) => {
    const [typeChargeOptions, setTypeChargeOptions] = useState<ISelectOption[]>([]);
    const chargesTypesReducer = useSelector(
        (state: RootState) => state.getTypeChargesAndPenaltiesReducer.data
    );
    useEffect(() => {
        const selectOptionsArray = chargesTypesReducer.map((item) => {
            return {label: item.penltiesName, value: item.penaltiesId};
        });
        setTypeChargeOptions(selectOptionsArray);
    }, [chargesTypesReducer]);
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
            <Text Color="#1D1E20" FontWeight="700">
                {CHARGES_AND_PENALTIES.FIELDS_TITLES.CHARGE_TYPE}
            </Text>
            <Controller
                name="typeCharge"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        options={typeChargeOptions}
                        noOptionsMessage={() => 'Sin opciones'}
                        isSearchable={false}
                        placeholder="Selecciona"
                        controlTop=""
                        ControlBottom="2px"
                    />
                )}
            />
            {errors.typeCharge && <ErrorMessage message={errors.typeCharge.value?.message || ''} />}
        </ContainerFlex>
    );
};
