import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_PASSWORD_RANDOM_START,
    GET_PASSWORD_RANDOM_SUCCESS,
    GET_PASSWORD_RANDOM_ERROR,
    RESET_RANDOM_PASSWORD,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';
export const resetRandomPassWord = () => {
    return {
        type: RESET_RANDOM_PASSWORD,
    };
};
export const getPasswordRandomStart = () => {
    return {
        type: GET_PASSWORD_RANDOM_START,
    };
};
export const getPasswordRandomSuccess = (result: AxiosResponse) => {
    return {
        type: GET_PASSWORD_RANDOM_SUCCESS,
        payload: result.data,
    };
};
export const getPasswordRandomError = (error: AxiosError) => {
    return {
        type: GET_PASSWORD_RANDOM_ERROR,
        error,
    };
};
export const getPasswordRandom = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getPasswordRandomStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPasswordRamdom}`,
                {headers}
            );
            dispatch(getPasswordRandomSuccess(response));
        } catch (error) {
            dispatch(getPasswordRandomError(error as AxiosError));
        }
    };
};
