import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import useToast from '@/hooks/useToast';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {ContainerFlex, Image, ContainerImg, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import moreIcon from '@images/MoreMenuBlack.svg';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {FulfillingItemProps} from '@components/ShoppingCart/interface';
import RenewealItemMenu from '@components/ShoppingCart/RenewealItemMenu';
import {ENDORSEMENTS} from '@components/ShoppingCart/constants';
import useTransferEndorseOrDischarge from '@/hooks/useTransferEndorseOrDischarge';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import {removeDischargeExtension} from '@components/ShoppingCart/Redux/Actions/deleteDischargeEndorsement';

const FulfillingItem: React.FC<FulfillingItemProps> = ({
    carShoppingId,
    discharge,
    isOpen,
    onMenuToggle,
    onSubMenuToggle,
    onSelectOption,
}) => {
    const {showToast, ToastContainer} = useToast();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token ?? '');

    const {handleTransfer} = useTransferEndorseOrDischarge({
        tranferData: {
            itemId: discharge.contractId,
            contractName: discharge.contractName,
        },
        token: token,
        carShoppingId: carShoppingId,
        onSuccess: () => {
            getCart();
        },
        onError: (message) => {
            handleError(message);
        },
    });

    const getCart = async () => {
        if (token && carShoppingId) {
            await dispatch(fetchCartById(token, carShoppingId));
        }
        onSelectOption();
        onSubMenuToggle('');
    };

    const handleError = (message: string) => {
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const handleCancel = async () => {
        await dispatch(removeDischargeExtension(token, discharge.contractId, carShoppingId));
        onSelectOption();
        onSubMenuToggle('');
    };

    return (
        <>
            <FlexPanel
                Width="24rem"
                Padding="1rem 0.5rem"
                Align="center"
                Gap="1.5rem"
                Radius="0.25rem"
                Background={isOpen ? '#FAFAFF' : '#FFFFFF'}
            >
                <ContainerFlex
                    FlexDir="column"
                    Justify="center"
                    Align="flex-start"
                    Gap="0.5rem"
                    Flex="1 0 0"
                >
                    <FlexPanel
                        Justify="center"
                        Align="center"
                        Radius="2rem"
                        Padding="0.125rem 0.5rem"
                        Height="1.125rem"
                        Border="1px solid #99E0FF"
                        Background="#DBF4FF"
                    >
                        <Text FontWeight="700" FontSize="0.75rem" Color="#004461">
                            {ENDORSEMENTS.FULFILLING}
                        </Text>
                    </FlexPanel>
                    <FlexPanel Justify="flex-start" Align="flex-start" Gap="0.5rem" Self="stretch">
                        <ContainerImg Height="3rem" Width="3rem" Justify="flex-start">
                            <Image
                                src={discharge.imgUrl}
                                alt="article"
                                Cursor="pointer"
                                Width="3rem"
                                Height="3rem"
                                Border="1px solid #5A5AFF"
                                Radius="0.5rem"
                            />
                        </ContainerImg>
                        <FlexPanel FlexDir="column" Align="flex-start" Gap="0.5rem">
                            <Text
                                Color="#1D1E20"
                                FontSize="1rem"
                                FontWeight="700"
                                LetterSpacing="0.019rem"
                            >
                                {ENDORSEMENTS.ARTICLES_COUNT}
                                {discharge.pledgeNumber}
                            </Text>
                            <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                {ENDORSEMENTS.CONTRACT_NUM}
                                {discharge.contractName}
                            </Text>
                        </FlexPanel>
                    </FlexPanel>
                </ContainerFlex>
                <Text
                    Color="#1D1E20"
                    TextAlign="right"
                    FontSize="1rem"
                    FontWeight="700"
                    LetterSpacing="0.019rem"
                >
                    {numberToCurrency(discharge.subDischarge)}
                </Text>
                <Image
                    src={moreIcon}
                    alt="options"
                    Cursor="pointer"
                    Width="1.5rem"
                    Height="1.5rem"
                    onClick={() => {
                        onMenuToggle();
                    }}
                />
            </FlexPanel>
            {isOpen && (
                <RenewealItemMenu
                    discharge={discharge}
                    setViewingMenu={() => {
                        onMenuToggle();
                    }}
                    onEndorseClick={() => handleTransfer(TYPE_MOVEMENT_ADD_CART.RENEWAL)}
                    onCancelClick={handleCancel}
                />
            )}
            <ToastContainer />
        </>
    );
};
export default FulfillingItem;
