import React from 'react';
import {ContainerFlex, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {LOADER_MESSAGE} from '@components/General/Atoms/TransversalLoader/Constants';

export const TransversalLoader = () => {
    return (
        <>
            <Modal BackC="rgba(250, 250, 255, 0.88)">
                <ContainerFlex Width="250px" Height="max-content" Gap="1rem" FlexDir="column">
                    <ContainerFlex Width="120px" Height="120px">
                        <Loading width="100%" padding="23px" />
                    </ContainerFlex>
                    <Text
                        Color="#2A2C2F"
                        FontWeight="700"
                        TextAlign="center"
                        LetterSpacing="0.019rem"
                    >
                        {LOADER_MESSAGE}
                    </Text>
                </ContainerFlex>
            </Modal>
        </>
    );
};
