import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';

import {catCompany} from '@components/SignUp/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {NEWGROUP} from '@components/LocationsBranch/constants';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import * as style from '@components/LocationsBranch/stylesLocations';
import {IBusinessLines} from '@components/LocationsBranch/interfaces';

export const BusinessLines = ({setValue, nameBusiness, errors, register}: IBusinessLines) => {
    const {business} = useSelector((state: RootState) => ({
        business: state.catPlaceAndBusiness,
    }));

    useEffect(() => {
        const catBusiness = business.catBusiness?.map((data: catCompany) => ({
            ...data,
            status: false,
        }));
        setValue(nameBusiness, catBusiness);
    }, [business.catBusiness]);

    return (
        <ContainerFlex {...style.contentGral} Gap="none">
            <Text {...style.contentGralTitle} MarginB="1rem">
                {NEWGROUP.LINEBUSINESS}
            </Text>
            {business.catBusiness &&
                business.catBusiness.map((data: catCompany, index: number) => (
                    <ContainerFlex
                        key={data.id}
                        Justify="space-between"
                        Padding="0.5rem"
                        Cursor="pointer"
                    >
                        <Text Color="#2A2C2F">{data.bussiness}</Text>
                        <GreenSwitch
                            {...register(`${nameBusiness}.${index}.status`)}
                            defaultChecked={false}
                        />
                    </ContainerFlex>
                ))}

            {errors[nameBusiness] && (
                <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                    {errors[nameBusiness]?.message as string}
                </Text>
            )}
        </ContainerFlex>
    );
};
