import React from 'react';
import ButtonGeneral from '@components/General/Atoms/Button/index';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {deleteRoleMenu} from './constants';
import {IRoleItems} from './interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import putUpdateRoleStatusAction from './Redux/Actions/PutUpdateRoleStatusAction';
import deleteRoleAction from './Redux/Actions/DeleteRoleAction';
import {useNavigate} from 'react-router-dom';

type deleteMenuProps = {
    Title: string;
    Texts: Array<string>;
    setShowDeleteMenu: (showDeleteMenu: boolean) => void;
    isDelete: boolean;
    buttonText: string;
    roles: IRoleItems[];
    setRoles: React.Dispatch<React.SetStateAction<IRoleItems[]>>;
    pendingToggleRoleId: IRoleItems | null;
    setPendingToggleRoleId: (id: IRoleItems | null) => void;
    roleToDelete: number | null;
    setRoleToDelete: (id: number | null) => void;
};

const DeleteRoleMenu = ({
    Title,
    Texts,
    setShowDeleteMenu,
    isDelete,
    buttonText,
    roles,
    setRoles,
    pendingToggleRoleId,
    setPendingToggleRoleId,
    roleToDelete,
    setRoleToDelete,
}: deleteMenuProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    const confirmToggle = () => {
        if (pendingToggleRoleId) {
            setRoles(
                roles.map((role) =>
                    role.id === pendingToggleRoleId.id ? {...role, status: !role.status} : role
                )
            );
            const data = {
                id: pendingToggleRoleId.id,
                status: !pendingToggleRoleId.status,
            };
            dispatch(putUpdateRoleStatusAction(data, token));
            setPendingToggleRoleId(null);
        }
        setShowDeleteMenu(false);
    };

    const handleDeleteRole = () => {
        if (roleToDelete !== null) {
            setRoles((prevRoles: IRoleItems[]) =>
                prevRoles.filter((role) => role.id !== roleToDelete)
            );
            dispatch(deleteRoleAction(roleToDelete, navigate, token));
            setRoleToDelete(null);
        }
        setShowDeleteMenu(false);
    };
    return (
        <ContainerFlex
            Position="fixed"
            Gap="30px"
            ZIndex="2"
            FlexDir="column"
            backG="white"
            Width="40rem"
            Height="20rem"
            PositionTop="40%"
            PositionRight="28.8%"
            Justify="start"
            Align="start"
            Padding="25px"
            Radius="24px"
            BoxS="rgba(223, 221, 221, 0.2) 0px 2px 8px 0px"
        >
            <Text FontSize="1.5rem" FontWeight="700" MinH="1.5rem" MarginTop=".5rem">
                {Title}
            </Text>
            {Texts.map((text: string, index: number) => (
                <div key={index} dangerouslySetInnerHTML={{__html: text}} />
            ))}
            <ContainerFlex Gap="15px">
                <ButtonGeneral
                    text={deleteRoleMenu.Cancel}
                    border="1px solid #5A5AFF"
                    color="#5A5AFF"
                    backGround="white"
                    width="10rem"
                    hBackG="#ddddddbb"
                    clic={() => setShowDeleteMenu(false)}
                    mt="1rem"
                />
                <ButtonGeneral
                    text={buttonText}
                    backGround="#A82424"
                    width="10rem"
                    clic={() => (isDelete ? handleDeleteRole() : confirmToggle())}
                    mt="1rem"
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default DeleteRoleMenu;
