import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_FREQUENCY_OPTIONS_ERROR,
    GET_FREQUENCY_OPTIONS_SUCCESS,
    GET_FREQUENCY_OPTIONS_START,
} from '@/components/GlobalQuoter/Redux/Types/Types';

const initialState = {
    frequencyOptions: [],
    isLoading: false,
    error: false,
};

const globalQuoterFrequencyReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    switch (action.type) {
        case GET_FREQUENCY_OPTIONS_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_FREQUENCY_OPTIONS_SUCCESS:
            return {
                ...state,
                frequencyOptions: action.payload.data.data.map(
                    (item: {frequencyId: number; frequencyName: string}) => ({
                        value: item.frequencyId,
                        label: item.frequencyName,
                    })
                ),
                isLoading: false,
            };
        case GET_FREQUENCY_OPTIONS_ERROR:
            return {
                ...state,
                frequencyOptions: [],
                isLoading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default globalQuoterFrequencyReducer;
