import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CREDIT_DETAIL_PAYMENT_ERROR,
    GET_CREDIT_DETAIL_PAYMENT_START,
    GET_CREDIT_DETAIL_PAYMENT_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {ICreditDetailPaymentAction} from '@components/CreditsAndPawns/interfaces';

const getCreditDetailPaymentStart = () => {
    return {
        type: GET_CREDIT_DETAIL_PAYMENT_START,
    };
};

const getCreditDetailPaymentSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CREDIT_DETAIL_PAYMENT_SUCCESS,
        payload: result,
    };
};

const getCreditDetailPaymentError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_DETAIL_PAYMENT_ERROR,
        error: error,
    };
};

export const getCreditDetailPaymentAction = ({
    token,
    creditId,
    statusId,
}: ICreditDetailPaymentAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditDetailPayment}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const params = {
        personalCreditApplicationId: creditId,
        paymentStatusCPId: statusId,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditDetailPaymentStart());
        try {
            const response = await axios.get(url, {headers, params});
            dispatch(getCreditDetailPaymentSuccess(response.data));
        } catch (e) {
            dispatch(getCreditDetailPaymentError(e as AxiosError));
        }
    };
};
