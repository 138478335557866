import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';

import addIcon from '@images/addIcon.svg';
import arrowUpIcon from '@images/arrowUpIcon.svg';
import arrowDownIcon from '@images/arrowDownIcon.svg';
import {formatHours} from '@/hooks/currentFormatUtils';
import {ScheduleTable} from '@components/ScheduleTable';
import * as style from '@components/LocationsBranch/stylesLocations';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {PAYMENT_AND_BILLING} from '@components/AccountSettings/constants';
import {IHolidaysGroupBranch} from '@components/LocationsBranch/interfaces';
import {getDayHoursInterval} from '@components/LocationsBranch/utils/GetIntervals';
import {HOLIDAYS_DEFAULT_VALUES, SCHEDULE} from '@components/LocationsBranch/constants';
import {IScheduleSlot} from '@components/ScheduleTable/interfaces/interfaces';

export const Holidays = ({
    register,
    control,
    errors,
    watch,
    setShowHolidaysModal,
    setValue,
}: IHolidaysGroupBranch) => {
    const [showDays, setShowDays] = useState(false);
    const [holidaySlots, setHolidaySlots] = React.useState<IScheduleSlot[]>([]);
    const headers = [SCHEDULE.DAY, SCHEDULE.STATE, SCHEDULE.OPEN, SCHEDULE.CLOSE];
    const defaultValues = {...HOLIDAYS_DEFAULT_VALUES};
    const intervals = getDayHoursInterval(0, 23, 30);
    const intervalsOptions = intervals.map((item: {time: string; id: number}) => ({
        label: item.time,
        value: String(item.id),
    }));
    const {group} = useSelector((state: RootState) => ({
        group: state.getCreateGroupBranch,
    }));

    const getHolidaySlots = () => {
        return (
            group.holidays &&
            group.holidays.map((item, index) => {
                const dayName = item.reason ? item.reason : PAYMENT_AND_BILLING.NO_INFO;
                setValue(`holidays.${[item.id]}.${defaultValues.reason}`, dayName);

                return {
                    id: item.id,
                    opening: {value: formatHours(item.date), label: formatHours(item.date)},
                    closing: {value: formatHours(item.endTime), label: formatHours(item.endTime)},
                    dayName,
                    levelId: item.levelId,
                    status: item.status,
                    toggleName: `holidays.${[item.id]}.${defaultValues.switch}`,
                    openingSelectName: `holidays.${[item.id]}.${defaultValues.opening}`,
                    closingSelectName: `holidays.${[item.id]}.${defaultValues.closing}`,
                    intervals: intervalsOptions,
                };
            })
        );
    };

    useEffect(() => {
        setShowDays(true);
    }, [errors.holidays]);

    useEffect(() => {
        setHolidaySlots(getHolidaySlots());
    }, [group.holidays, watch('holidays')]);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Bt="solid 1px #F4F5F5"
            Padding="1rem 0 0 0"
            Gap="1rem"
        >
            <Text {...style.contentGralTitle}>{SCHEDULE.UNABLED}</Text>
            <ContainerFlex
                onClick={() => setShowDays(!showDays)}
                Justify="start"
                Height="1.5rem"
                Gap="0.5rem"
                Width="7rem"
                Cursor="pointer"
            >
                <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer">
                    {showDays ? SCHEDULE.HIDE : SCHEDULE.SHOW}
                </Text>
                {showDays ? <Image src={arrowUpIcon} /> : <Image src={arrowDownIcon} />}
            </ContainerFlex>

            {showDays && (
                <>
                    {holidaySlots && holidaySlots.length > 0 && (
                        <ScheduleTable
                            headers={headers}
                            scheduleSlots={holidaySlots}
                            control={control}
                            errors={errors}
                            watch={watch}
                            setValue={setValue}
                            isHolidays={true}
                        />
                    )}
                    <ContainerFlex
                        onClick={() => setShowHolidaysModal(true)}
                        Justify="start"
                        Width="8rem"
                        Height="1rem"
                        Cursor="pointer"
                        Gap="0.5rem"
                    >
                        <Image src={addIcon} />
                        <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700">
                            {SCHEDULE.ADD_DAY}
                        </Text>
                    </ContainerFlex>
                    {errors && errors.holidays && (
                        <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                            {String(errors.holidays?.message)}
                        </Text>
                    )}
                </>
            )}
        </ContainerFlex>
    );
};
