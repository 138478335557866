import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {GetTreeUser} from './Redux/Actions/GetBranchTreeAction';
import {GetTreeLevelBranchUser} from './Redux/Actions/LevelBranchAction';
import {putTreeNewLevel} from './Redux/Actions/PutLevelTreeNewAction';
import {SearchFilterAssignment} from '@/components/Users/UserProfile/UserProfileBranchAssignment/FilterOfUser';
import {BranchAssignmentTree} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchAssignmentTree';
import {SearchItem} from './Operational/typeInterface';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {BRANCH_ASSIGNMENT} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import {RESET_PASSWORD} from '@/components/UserProfileResetPassword/Operational/Constants';
import {useNavigate} from 'react-router-dom';
import {profileUser} from '@/components/MyCompanyUser/Redux/Actions/UserProfileAction';

export const UserBranchAssignment = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.UserProfileAdmin.selectedEmployId);
    const Loadings = useSelector((state: RootState) => state.UserProfileAdmin.loading);
    const companyId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.companyId
    );
    const isloading = useSelector((state: RootState) => state.GetLevelBranchUser.loading);
    const [checkedOptions, setCheckedOptions] = useState<Set<number>>(new Set());
    const [searchResults, setSearchResults] = useState<SearchItem[]>([]);

    useEffect(() => {
        if (token && employeeId && companyId) {
            dispatch(GetTreeUser(token, employeeId));
            dispatch(GetTreeLevelBranchUser(token, companyId));
        }
    }, [dispatch, token, employeeId, companyId]);

    const handleSearchResult = useCallback((results: SearchItem[]) => {
        setSearchResults(results);
    }, []);

    const handleBranchSelect = async (selectedBranches: number[]) => {
        if (!token || !employeeId) return;

        try {
            const branchData = {
                employeeId,
                branches: selectedBranches,
            };

            await dispatch(putTreeNewLevel(token, branchData));
            await dispatch(GetTreeUser(token, employeeId));
            await dispatch(profileUser(token, employeeId, navigate, false));
        } catch (error) {
            return error;
        }
    };
    const handleReset = () => {
        setCheckedOptions(new Set());
        setSearchResults([]);
        dispatch(GetTreeLevelBranchUser(token, companyId));
    };

    return (
        <ContainerFlex FlexDir="column" Height="max-content" Gap="16px">
            <ContainerFlex Justify="start">
                <Text FontSize="1.5rem" FontWeight="700">
                    {BRANCH_ASSIGNMENT.TITLE}
                </Text>
            </ContainerFlex>

            {isloading || Loadings ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex
                        FlexDir="column"
                        Padding="16px"
                        backG="#FFF"
                        Border="1px solid #E8E9EA"
                        Gap="16px"
                        Radius="16px"
                    >
                        <ContainerFlex FlexDir="column" Align="start">
                            <Text FontWeight="700" FontSize="1.25rem" Color="#2A2C2F">
                                {BRANCH_ASSIGNMENT.TITLE_TWO}
                            </Text>
                            <Text FontWeight="400" Color="#2A2C2F">
                                {BRANCH_ASSIGNMENT.INDICATION}
                            </Text>
                        </ContainerFlex>

                        <SearchFilterAssignment onSearchResult={handleSearchResult} />

                        <BranchAssignmentTree
                            checkedOptions={checkedOptions}
                            setCheckedOptions={setCheckedOptions}
                            onBranchSelect={handleBranchSelect}
                            searchResults={searchResults}
                        />
                    </ContainerFlex>
                    <ContainerFlex
                        Padding="16px"
                        backG="#FFF"
                        Border="1px solid #E8E9EA"
                        Gap="16px"
                        Radius="16px"
                    >
                        <ButtonGenerals
                            onClick={handleReset}
                            Width="147px"
                            SecondaryButton="#FFFFFF"
                            type="button"
                        >
                            {RESET_PASSWORD.BTN}
                        </ButtonGenerals>
                        <ButtonGenerals
                            Width="147px"
                            onClick={() => handleBranchSelect(Array.from(checkedOptions))}
                        >
                            {RESET_PASSWORD.BTN_KEEP}
                        </ButtonGenerals>
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
};
