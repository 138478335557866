import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {PersonalLoansStepOne} from '@components/PersonalLoans/PersonalLoansStepOne';
import {PersonalLoansStepTwo} from '@components/PersonalLoans/PersonalLoansStepTwo';
import {PersonalLoansStepThree} from '@components/PersonalLoans/PersonalLoansStepThree';
import {PersonalLoansStepFour} from '@components/PersonalLoans/PersonalLoansStepFour';
import {ProgressBarStep} from '@components/PersonalLoans/ProgressBarStep';
import {PERSONALLOANS} from '@components/PersonalLoans/constants';
import IconMenu from '@images/ICON_MENUPP.png';
import {ResumeLoans} from '@components/PersonalLoans/ResumeLoans';
import {PersonalLoansStepFourPayment} from '@components/PersonalLoans/PersonalLoansStepFourPayment';
import {getAdditionalDocuments} from '@components/PersonalLoans/Redux/Actions/GetAdditionalCreditDocumentsActions';

export const PersonalLoans = () => {
    const dispatch = useDispatch();
    const DataStepLoans = useSelector((state: RootState) => {
        return state.DataStepLoans.dataStep;
    });
    const IPersonalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });

    useEffect(() => {
        if (IPersonalInformationId && token) {
            dispatch(getAdditionalDocuments(token, IPersonalInformationId));
        }
    }, [IPersonalInformationId, token]);
    return (
        <ContainerFlex
            Align={DataStepLoans === PERSONALLOANS.FIVE ? 'center' : 'start'}
            Justify="center"
            backG="#E5E5FF"
            Padding="1.5rem 3.75rem"
            Gap="1rem"
            Height="calc(100vh - 56px)"
            Overflow="auto"
        >
            <ContainerFlex
                Justify="start"
                FlexDir="column"
                Border="solid 1px #0D166B"
                Radius="1rem"
                Padding="1.5rem"
                Gap="1rem"
                backG="#FFFFFF"
                MaxW="900px"
                Height="max-content"
            >
                <ContainerFlex Justify="space-between" Height="40px">
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700" oFlow="none">
                        {PERSONALLOANS.TITLE}
                    </Text>
                    <Image src={IconMenu} />
                </ContainerFlex>
                <ContainerFlex Bb="solid 1px #E8E9EA" Height="1px" />
                <ContainerFlex Align="start" FlexDir="column" Justify="start" Gap="1.5rem">
                    <ProgressBarStep dataStep={DataStepLoans} />
                    {DataStepLoans === PERSONALLOANS.ONE && <PersonalLoansStepOne />}
                    {DataStepLoans === PERSONALLOANS.TWO && <PersonalLoansStepTwo />}
                    {DataStepLoans === PERSONALLOANS.THREE && <PersonalLoansStepThree />}
                    {DataStepLoans === PERSONALLOANS.FOUR && <PersonalLoansStepFourPayment />}
                    {DataStepLoans === PERSONALLOANS.FIVE && <PersonalLoansStepFour />}
                </ContainerFlex>
            </ContainerFlex>
            {DataStepLoans !== PERSONALLOANS.FIVE && <ResumeLoans />}
        </ContainerFlex>
    );
};
