import React from 'react';

import {AvatarContainer, AvatarImage, EditButton, EditIcon} from '@/components/Users/stylesAvatar';
import {EditableAvatarProps} from '@/components/Users/UserProfile/interfaces';

export const EditableAvatar: React.FC<EditableAvatarProps> = ({src, onEditClick, editIcon}) => {
    return (
        <AvatarContainer>
            <AvatarImage src={src} alt="user-avatar" />
            <EditButton onClick={onEditClick}>
                <EditIcon src={editIcon} alt="edit" />
            </EditButton>
        </AvatarContainer>
    );
};
