import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    POST_NEW_ROLE,
    POST_NEW_ROLE_ERROR,
    POST_NEW_ROLE_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError} from 'axios';

export const PostNewRole = () => {
    return {
        type: POST_NEW_ROLE,
    };
};

export const PostNewRoleSuccess = () => {
    return {
        type: POST_NEW_ROLE_SUCCESS,
    };
};

export const PostNewRoleError = (error: AxiosError) => {
    return {
        type: POST_NEW_ROLE_ERROR,
        payload: error,
    };
};

export type IPostNewRoleProps = {
    id?: number;
    companyId: number;
    name: string;
    adminModules: number[];
    operationalModules: number[];
    users: number[];
};

export const PostNewRoleAction = (
    data: IPostNewRoleProps,
    navigate: (url: string) => void,
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(PostNewRole());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostNewRole}`;
        try {
            await axios.post(url, data, {headers});
            dispatch(PostNewRoleSuccess());
            navigate('/RolesAndPermissions');
        } catch (error) {
            dispatch(PostNewRoleError(error as AxiosError));
        }
    };
};
export default PostNewRoleAction;
