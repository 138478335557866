import React, {useRef, useState} from 'react';
import {Box} from '@mui/material';
import 'swiper/swiper-bundle.css';
import {useSelector} from 'react-redux';
import {SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';
import {Swiper as SwiperType} from 'swiper';
import {useDispatch} from 'react-redux';

import {RootState} from '@/config/store';
import useToast from '@/hooks/useToast';
import useCheckPawnsOnCart from '@/hooks/useCheckPawnsOnCart';
import {setIsCartOpen} from '@/components/ShoppingCart/Redux/Actions/CartState';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {IActions, IAddDischarge} from '@Shopify/ClientProfile/interfaces';
import {SwiperSlider} from '@Shopify/ClientProfile/Styles/styles';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {
    QUICK_ACTIONS,
    SLIDER_BREAKPOINT,
    TYPE_MOVEMENT_ADD_CART,
} from '@Shopify/ClientProfile/constants';
import {addDischargeOrEndorsement} from '@Shopify/ClientProfile/Redux/Action/AddDischargeEndorsement';
import {clientQuickActions} from '@Shopify/ClientProfile/Redux/Action/QuickAction';
import QuickActionsHeader from '@Shopify/ClientProfile/QuickActionsHeader';
import QuickActionCard from '@Shopify/ClientProfile/QuickActionCard';
import QuickActionNavigationButtons from '@Shopify/ClientProfile/QuickActionNavigationButtons';

export const QuickActions = () => {
    const dispatch = useDispatch();
    const {showToast, ToastContainer} = useToast();
    const {checkCartAndPawns} = useCheckPawnsOnCart();

    const {clientProfile, carShoppingId, token} = useSelector((state: RootState) => ({
        clientProfile: state.cardClientProfile,
        carShoppingId: state.getUsersValidation.userData?.carShoopingId,
        token: state.getUsersValidation.userData?.token ?? '',
    }));
    const branchId = useSelector((state: RootState) => {
        const branchDetails = state.getUsersValidation.userData?.branchDetails;
        if (Array.isArray(branchDetails) && branchDetails.length > 0) {
            return branchDetails[0]?.branchId;
        }
        return undefined;
    });
    const swiperRef = useRef<SwiperType | null>(null);
    const [quickActions, setQuickActions] = useState(true);
    const {actions} = useSelector((state: RootState) => state.quickAction);

    const handleSuccess = async () => {
        dispatch(setIsCartOpen(true));
        await dispatch(clientQuickActions(token, {clientId: clientProfile.idClient}, true));
    };

    const handleError = (message: string) => {
        dispatch(setIsCartOpen(true));
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const handleEndorsementDischarge = async (card: IActions, typeMovement: number) => {
        const {hasPawns, message} = await checkCartAndPawns();
        if (hasPawns && message) {
            handleError(message);
            return;
        }
        const shopValuator = card.importCarShopValuator;
        const payment =
            typeMovement === TYPE_MOVEMENT_ADD_CART.RENEWAL ? card.payment : card.liquidation;
        const discharge: IAddDischarge = {
            carShoppingId: Number(carShoppingId),
            typeMovement,
            branchId: Number(branchId),
            pawnId: card.pawnId,
            paymentAmount: payment,
            iva: shopValuator.iva,
            moratoriumAmount: shopValuator.moratoriumAmount,
            amountStorage: shopValuator.amountStorage,
            amountInterest: shopValuator.maximumInterest,
            insuranceAmount: shopValuator.insuranceAmount,
            amountReposition: shopValuator.amountReposition,
            amountRepositionIVA: shopValuator.amountRepositionIVA,
            amountDiscountInterest: shopValuator.amountDiscountInterest,
            amountDiscountInterestIVA: shopValuator.amountDiscountInterestIVA,
            maximumInterest: shopValuator.maximumInterest,
        };
        await dispatch(addDischargeOrEndorsement(discharge, token, handleSuccess, handleError));
    };

    const handleNext = () => swiperRef.current?.slideNext();
    const handlePrev = () => swiperRef.current?.slidePrev();

    const generateBreakpoints = (
        config: {width: number; slides: number}[],
        startWidth: number,
        step: number,
        maxSlides: number
    ) => {
        const breakpoints: Record<number, {slidesPerView: number}> = {};
        config.forEach(({width, slides}) => {
            breakpoints[width] = {slidesPerView: slides};
        });
        for (
            let width = startWidth, slides = config[config.length - 1].slides + 1;
            slides <= maxSlides;
            width += step, slides++
        ) {
            breakpoints[width] = {slidesPerView: slides};
        }

        return breakpoints;
    };

    const breakpoints = generateBreakpoints(SLIDER_BREAKPOINT, 2550, 400, 10);

    return (
        <>
            <ContainerFlex {...style.contentGral}>
                <QuickActionsHeader
                    quickActions={quickActions}
                    onToggle={() => setQuickActions(!quickActions)}
                />
                {quickActions && (
                    <Box sx={{...style.boxSlider}}>
                        <SwiperSlider
                            onSwiper={(swiper: SwiperType) => (swiperRef.current = swiper)}
                            modules={[Navigation]}
                            navigation={false}
                            loop={actions && actions.length > 3}
                            breakpoints={breakpoints}
                        >
                            {actions && actions.length > 0 ? (
                                actions.map((card, index) => (
                                    <SwiperSlide key={index}>
                                        <ContainerFlex
                                            Padding={style.gapSlide(index, actions.length - 1)}
                                        >
                                            <QuickActionCard
                                                card={card}
                                                handleEndorsementDischarge={
                                                    handleEndorsementDischarge
                                                }
                                            />
                                        </ContainerFlex>
                                    </SwiperSlide>
                                ))
                            ) : (
                                <ContainerFlex Color="#000">
                                    {QUICK_ACTIONS.NO_CONTRACTS}
                                </ContainerFlex>
                            )}

                            <ContainerFlex Justify="end" Gap="1rem" Padding="1rem 1.5rem 0 1.5rem">
                                {actions && actions.length > 3 && (
                                    <QuickActionNavigationButtons
                                        onNext={handleNext}
                                        onPrev={handlePrev}
                                    />
                                )}
                            </ContainerFlex>
                        </SwiperSlider>
                    </Box>
                )}
            </ContainerFlex>
            <ToastContainer />
        </>
    );
};
