import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {AxiosError} from 'axios';

import {ContainerFlex, ContainerGrid, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';
import {ICreditPaymentDetailsProps} from '@/components/CreditQuoter/interfaces';
import tableIcon from '@/components/CreditQuoter/icons/tableIcon.svg';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {getQuoteCreditPersonalLoans} from '@/components/CreditQuoter/Redux/Actions/GetQuoteCreditPersonalLoansActions';
import {AmortizationTableQuoter} from '@/components/CreditQuoter/AmortizationTable/AmortizationTableQuoter';
import {
    createRequestError,
    createRequestLoan,
} from '@/components/PersonalLoans/Redux/Actions/PersonalLoanRquestSummaryActions';

const CreditCalculator: React.FC<ICreditPaymentDetailsProps> = ({
    amount,
    countPayments,
    frequencyTermCreditId,
    companyId,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showTable, setShowTable] = useState<boolean>(false);
    const token: string = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {
        data: {quantityPeriods, valuation, annualInterest, frequencyName, payments},
    } = useSelector((state: RootState) => state.getQuoteCreditPersonalLoans);
    const {parsedAmount, accountPayments} = useSelector(
        (state: RootState) => state.globalQuoterFormReducer
    );

    useEffect(() => {
        const filters = {
            amount: Number(amount),
            frequencyTermCreditId: Number(frequencyTermCreditId),
            quantityPeriods: Number(countPayments),
        };
        if (Number(countPayments) !== 0) dispatch(getQuoteCreditPersonalLoans(token, filters));
    }, [token, amount, countPayments, frequencyTermCreditId]);

    const handleApply = async () => {
        try {
            if (token && accountPayments && frequencyTermCreditId && parsedAmount && companyId) {
                const data = {
                    amountRequested: parsedAmount,
                    frequencyTermId: frequencyTermCreditId,
                    periodAcount: accountPayments,
                    companyId,
                };
                dispatch(createRequestLoan(token, data, navigate));
            }
        } catch (error) {
            dispatch(createRequestError(error as AxiosError));
        }
    };
    return (
        <ContainerFlex Padding="16px 48px" backG="#009ABE">
            <ContainerFlex Radius="16px" backG="#FAFAFA" Padding="24px" Gap="1rem" FlexDir="column">
                <ContainerFlex Padding="16px" Gap="16px" Radius="8px" backG="#fff" FlexDir="column">
                    <ContainerFlex>
                        <Text
                            FontWeight="700"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {CREDIT_QUOTER.PAYMENTS_GENERATOR_TEXT(
                                String(Math.floor(quantityPeriods))
                            )}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex>
                        <Text
                            FontWeight="700"
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontSize="3.0rem"
                        >
                            {numberToCurrencyWithoutCents(payments)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex backG="#E8E9EA" Width="50%" Height="1px" />
                    <ContainerGrid GridColumns="repeat(3,90px)" Gap="16px" Justify="center">
                        <ContainerFlex FlexDir="column" Gap="4px">
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="0.88rem"
                            >
                                {CREDIT_QUOTER.ANUAL_FEE}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {`${annualInterest}%`}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Gap="4px">
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="0.88rem"
                            >
                                {CREDIT_QUOTER.RATE}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {valuation}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Gap="4px">
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="0.88rem"
                            >
                                {CREDIT_QUOTER.FREQUENCY}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {frequencyName}
                            </Text>
                        </ContainerFlex>
                    </ContainerGrid>
                </ContainerFlex>
                <ButtonGenerals
                    Width="100%"
                    FontSize="1rem"
                    FontWeight="700"
                    Cursor="pointer"
                    property="8px 16px"
                    MHeight="48px"
                    onClick={handleApply}
                >
                    {CREDIT_QUOTER.BTN_REQUEST_NOW}
                </ButtonGenerals>
                <ContainerFlex Justify="center" Align="center" Gap="8px" Flex="0">
                    <Image
                        src={tableIcon}
                        alt="table-icon"
                        Width="24px"
                        Height="24px"
                        Cursor="Pointer"
                    />
                    <Text
                        FontWeight="700"
                        Color="#5A5AFF"
                        FontFamily="Nunito"
                        FontSize="1.0rem"
                        Cursor="Pointer"
                        onClick={() => setShowTable(!showTable)}
                    >
                        {CREDIT_QUOTER.AMORTIZATION_TABLE_CTA}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            {showTable && (
                <AmortizationTableQuoter
                    showTable={showTable}
                    setShowTable={setShowTable}
                    periodsNumber={countPayments}
                    ammountRequested={amount}
                    frequencyTermCreditId={Number(frequencyTermCreditId)}
                />
            )}
        </ContainerFlex>
    );
};

export default CreditCalculator;
