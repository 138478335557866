import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';

import {catPlaces} from '@components/SignUp/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {SELLCHANNEL} from '@components/LocationsBranch/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import * as style from '@components/LocationsBranch/stylesLocations';
import {IBusinessLines} from '@components/LocationsBranch/interfaces';

export const SellChannels = ({setValue, errors, nameBusiness, register}: IBusinessLines) => {
    const {business} = useSelector((state: RootState) => ({
        business: state.catPlaceAndBusiness,
    }));

    useEffect(() => {
        const catPlaces = business.catPlaces?.map((data: catPlaces) => ({
            ...data,
            status: false,
        }));
        setValue(nameBusiness, catPlaces);
    }, [business.catPlaces]);

    return (
        <ContainerFlex {...style.contentGral}>
            <Text {...style.contentGralTitle}>{SELLCHANNEL.TITLE}</Text>
            {business.catPlaces &&
                business.catPlaces.map((item: catPlaces, index: number) => (
                    <ContainerFlex Cursor="pointer" key={item.id} Justify="start" Gap="0.5rem">
                        <Checkbox {...register(`${nameBusiness}.${index}.status`)} />
                        <Text Color="#2A2C2F">{item.description}</Text>
                    </ContainerFlex>
                ))}
            {errors[nameBusiness] && (
                <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                    {errors[nameBusiness]?.message as string}
                </Text>
            )}
        </ContainerFlex>
    );
};
