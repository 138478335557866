import React from 'react';
import {LOAN_PRODUCT_PREVIEW} from '@components/PersonalLoans/NewLoanProduct/constants';
import pencilBlueIcon from '@components/PersonalLoans/NewLoanProduct/icons/pencilBlueIcon.svg';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IHeaderPreviewProps} from '@components/PersonalLoans/NewLoanProduct/ui/interfaces';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const HeaderPreview: React.FC<IHeaderPreviewProps> = ({title, isEditing, setIsEditing}) => {
    const DataLoans = useSelector((state: RootState) => state.GetTermPersonalLoan.dataLoans);
    const isEditingLoan: boolean = useSelector((state: RootState) => state.setIsEditingLoanReducer);
    return (
        <ContainerFlex Gap="0.5rem" Align="start" Justify="space-between">
            <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                {title}
            </Text>
            {((DataLoans && DataLoans?.isEdit === true) || !isEditingLoan) && (
                <ContainerFlex
                    Gap="0.5rem"
                    Width="max-content"
                    Height="max-content"
                    Cursor="pointer"
                    onClick={() => setIsEditing(!isEditing)}
                >
                    <Image src={pencilBlueIcon} Width="24px" Height="24px" alt="pencil-icon" />

                    <Text FontWeight="700" Color="#5A5AFF" FontSize="0.875rem" Cursor="pointer">
                        {LOAN_PRODUCT_PREVIEW.BTN_EDIT}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
