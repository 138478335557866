import React, {useRef, useState} from 'react';
import {Controller} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {
    ContainerFlex,
    Text,
    Image,
    ContainerCellGrid,
    Input,
} from '@/components/Shopify/Ecommerce/styles';
import {
    fileErrorsInitialState,
    UPLOADDROPDATA,
    UPLOADFRONTDATA,
    UPLOADIMAGESLOANS,
} from '@components/PersonalLoans/constants';
import {FrontLoansDataProp, IDateLoan, IFileErrors} from '@components/PersonalLoans/interfaces';
import {HiddenFileInput} from '@components/PersonalLoans/HiddenFileInput';
import checkIcon from '@components/PersonalLoans/icons/checkIcon.svg';
import credentialIcon from '@components/PersonalLoans/icons/credentialIcon.svg';
import errorCrossIcon from '@components/PersonalLoans/icons/errorCrossIcon.svg';
import UploadIcon from '@images/Uploadtocloud.svg';
import {
    CalendarFieldComponent,
    CalendarIconContainer,
    DatePickerCustom,
    InputCalendarField,
} from '@components/CreateUsers/DatePiker/datePickerStyles';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import * as content from '@components/PersonalLoans/styles';
import ArrowUpMenu from '@images/arrowUpIcon.svg';
import ArrowDownMenu from '@images/arrowDownIcon.svg';
import RemplaceIcon from '@images/RefreshIconBlue.svg';
import PhotosIcon from '@images/PhotosIconBlue.svg';
import {ErrorMessage} from '@components/PersonalLoans/ui/ErrorMessage';
import pdfIcon from '@images/pdfIcon.svg';

export const PersonalLoansFrontDocument = ({
    register,
    errors,
    documentsInfo,
    setDocumentsInfo,
    control,
    setValue,
}: FrontLoansDataProp) => {
    const [showFrontDocument, setShowFrontDocument] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileErrors, setFileErrors] = useState<IFileErrors>(fileErrorsInitialState);

    const convertToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                callback(reader.result as string);
            }
        };
    };

    const isSupportedFileType = (file: File): boolean => {
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf', 'image/jpg'];
        return allowedTypes.some((type) => file.type.startsWith(type));
    };
    const handleFrontFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        const fileSizeMB = file.size / 1024 / 1024;
        if (fileSizeMB > 4) {
            setFileErrors({...fileErrors, imageSize: true});
            return;
        }
        if (!isSupportedFileType(file)) {
            setFileErrors({...fileErrors, validImageFormat: true});
            return;
        }

        convertToBase64(file, (base64) => {
            setDocumentsInfo((prevState) => ({
                ...prevState,
                frontImage: {fileName: file.name, formFile: base64},
            }));
        });
        setFileErrors(fileErrorsInitialState);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const fileSizeMB = file.size / 1024 / 1024;

        if (fileSizeMB > 4) {
            setFileErrors({...fileErrors, imageSize: true});
            return;
        }
        if (!isSupportedFileType(file)) {
            setFileErrors({...fileErrors, validImageFormat: true});
            return;
        }
        convertToBase64(file, (base64) => {
            setDocumentsInfo((prevState) => ({
                ...prevState,
                frontImage: {fileName: file.name, formFile: base64},
            }));
        });
        setFileErrors(fileErrorsInitialState);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    const convertDate = (e: IDateLoan) => {
        if (e && e.c) {
            const year = String(e.c.year + 1);
            setValue('date', year);
        }
    };
    return (
        <ContainerFlex
            {...content.ContainerFlexUpload}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <ContainerFlex Justify="space-between" Gap="0.5rem">
                <ContainerFlex Justify="start" Gap="0.5rem" Width="35rem">
                    <Image
                        alt="icon-check"
                        Height="1.5rem"
                        Width="1.5rem"
                        src={documentsInfo.frontImage ? checkIcon : credentialIcon}
                    />
                    <ContainerFlex FlexDir="column" Width="max-content" Align="start">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400" oFlow="none">
                            {UPLOADFRONTDATA.TITLE}
                        </Text>
                        {showFrontDocument === true && !documentsInfo.frontImage && (
                            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400" oFlow="none">
                                {UPLOADFRONTDATA.SUBTITLE}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
                <Image
                    alt="icon-arrow"
                    src={showFrontDocument ? ArrowUpMenu : ArrowDownMenu}
                    Cursor="pointer"
                    onClick={() => setShowFrontDocument(!showFrontDocument)}
                />
            </ContainerFlex>
            {showFrontDocument === true &&
                (documentsInfo.frontImage ? (
                    <>
                        <ContainerFlex Justify="start" Align="start" Gap="2rem">
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Width="fit-content"
                                Gap="0.75rem"
                            >
                                <Image
                                    alt="icon-image"
                                    src={
                                        documentsInfo.frontImage.fileName.includes('.pdf')
                                            ? pdfIcon
                                            : documentsInfo.frontImage.formFile
                                    }
                                    {...content.ImageUpload}
                                    ObjectFit={
                                        documentsInfo.frontImage.fileName.includes('.pdf')
                                            ? 'contain'
                                            : 'cover'
                                    }
                                />
                                <ContainerFlex
                                    {...content.ContainerUploadRef}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <HiddenFileInput
                                        onChange={handleFrontFileChange}
                                        ref={fileInputRef}
                                    />
                                    <Image alt="icon-remplace" src={RemplaceIcon} />
                                    <Text {...content.UploadRemplace}>
                                        {UPLOADDROPDATA.REMPLACE}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column">
                                <ContainerCellGrid {...content.TextUploadName}>
                                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                                        {UPLOADIMAGESLOANS.NAME}
                                    </Text>
                                    <Input
                                        maxLength={100}
                                        Width="100%"
                                        Margin="0 0 8px 0"
                                        {...register('name')}
                                        error={errors.name?.message}
                                    />
                                </ContainerCellGrid>
                                {errors?.name?.message && (
                                    <ContainerFlex Justify="start" Gap="0.25rem">
                                        <Image
                                            alt="icon-close"
                                            Height="1rem"
                                            Width="1rem"
                                            src={errorCrossIcon}
                                        />
                                        <Text {...content.TextError}>{errors.name?.message}</Text>
                                    </ContainerFlex>
                                )}
                                <ContainerCellGrid {...content.TextUploadRequire}>
                                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                                        {UPLOADIMAGESLOANS.VALID}
                                    </Text>
                                    <Controller
                                        name="date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: UPLOADIMAGESLOANS.REQUIRED,
                                            },
                                        }}
                                        render={({field: {onChange, ref, value, ...field}}) => (
                                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                                <DatePickerCustom
                                                    value={value}
                                                    mask="____"
                                                    views={['year']}
                                                    {...field}
                                                    inputFormat="yyyy"
                                                    onChange={(e) => {
                                                        onChange(e);
                                                        convertDate(e as IDateLoan);
                                                    }}
                                                    minDate={
                                                        new Date(
                                                            new Date().getFullYear(),
                                                            new Date().getMonth(),
                                                            new Date().getDate() + 0
                                                        )
                                                    }
                                                    maxDate={
                                                        new Date(
                                                            new Date().getFullYear() + 20,
                                                            new Date().getMonth(),
                                                            new Date().getDate() + 0
                                                        )
                                                    }
                                                    components={{OpenPickerIcon: DateIcon}}
                                                    renderInput={({
                                                        inputRef,
                                                        inputProps,
                                                        InputProps,
                                                    }) => (
                                                        <CalendarFieldComponent
                                                            error={
                                                                errors.date?.message ? true : false
                                                            }
                                                        >
                                                            <InputCalendarField
                                                                ref={inputRef}
                                                                {...inputProps}
                                                                FontSize="1rem"
                                                                Width="100px"
                                                            />
                                                            <CalendarIconContainer>
                                                                {InputProps?.endAdornment}
                                                            </CalendarIconContainer>
                                                        </CalendarFieldComponent>
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        )}
                                    />
                                </ContainerCellGrid>
                                {errors?.date?.message && (
                                    <ContainerFlex Justify="start" Gap="0.25rem">
                                        <Image
                                            alt="icon-close"
                                            Height="1rem"
                                            Width="1rem"
                                            src={errorCrossIcon}
                                        />
                                        <Text {...content.TextError}>{errors.date?.message}</Text>
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                        </ContainerFlex>
                        {fileErrors.imageSize && <ErrorMessage message={UPLOADDROPDATA.ERROR} />}
                        {fileErrors.validImageFormat && (
                            <ErrorMessage message={UPLOADDROPDATA.FORMAT_ERROR} />
                        )}
                    </>
                ) : (
                    <>
                        <ContainerFlex
                            {...content.UploadDrop}
                            Border={
                                fileErrors.imageSize || fileErrors.validImageFormat
                                    ? '1px dashed #A82424'
                                    : '1px dashed #ACACFF'
                            }
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <HiddenFileInput onChange={handleFrontFileChange} ref={fileInputRef} />
                            <Image src={UploadIcon} alt="icon-upload" />
                            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                                {UPLOADDROPDATA.DROP}
                            </Text>
                            <Text Color="#A1A5AA" FontSize="0.75rem">
                                {UPLOADDROPDATA.TYPE}
                            </Text>
                        </ContainerFlex>

                        {fileErrors.imageSize && <ErrorMessage message={UPLOADDROPDATA.ERROR} />}
                        {fileErrors.validImageFormat && (
                            <ErrorMessage message={UPLOADDROPDATA.FORMAT_ERROR} />
                        )}

                        <ContainerFlex
                            Width="fit-content"
                            Gap="0.25rem"
                            Margin="0 1rem"
                            Cursor="pointer"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <HiddenFileInput onChange={handleFrontFileChange} ref={fileInputRef} />

                            <Image alt="icon-upload" src={PhotosIcon} />
                            <Text {...content.UploadSelect}>{UPLOADDROPDATA.SELECT}</Text>
                        </ContainerFlex>
                    </>
                ))}
        </ContainerFlex>
    );
};
export const DateIcon = () => {
    return (
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            calendar_today
        </Icons>
    );
};
