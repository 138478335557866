import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {formatDate, formatNum} from '@/hooks/currentFormatUtils';
import {DATE_FORMATS} from '@Shopify/ListClient/constants';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerFlex, ContainerGrid, Text} from '@Shopify/Ecommerce/styles';
import {CONTRACT_DETAIL, convertDate} from '@Shopify/ClientProfile/constants';
import {MY_COMPANY} from '@/components/MyCompany/constants';

export const ContractGarmentsAccount = () => {
    const {details} = useSelector((state: RootState) => state.detailContract);

    return (
        <ContainerGrid Gap="1.5rem" GridColumns="repeat(4,1fr)">
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.NO_CONTRACT}</Text>
                <Text {...style.garments.titles}>
                    {details && details.contractDetail.contractNumber}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.BRANCH}</Text>
                <Text Color="#5A5AFF" FontWeight="600">
                    {details && details.contractDetail.houseAndBranch}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.PAY}</Text>
                <Text {...style.garments.titles}>
                    {details &&
                        formatNum({
                            number: details.contractDetail.endorsement,
                            fractionDigits: 2,
                            money: true,
                        })}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.DISCHARGE}</Text>
                <Text {...style.garments.titles}>
                    {details &&
                        formatNum({
                            number: details.contractDetail.discharge,
                            fractionDigits: 2,
                            money: true,
                        })}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.NO_PAY}</Text>
                <Text {...style.garments.titles}>
                    {details && details.contractDetail.numberOfEndorsement}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.END_DATE}</Text>
                <Text {...style.garments.titles}>
                    {details &&
                        formatDate(
                            convertDate(details.contractDetail.expiration),
                            DATE_FORMATS.FULL_DATE
                        )}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.STATE}</Text>
                {details && (
                    <Text {...style.colorStatusContract(details.contractDetail.status)}>
                        {details.contractDetail.status}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex {...style.contentGarments}>
                <Text Color="#2A2C2F">{CONTRACT_DETAIL.LOCATION}</Text>
                <Text {...style.garments.titles}>{MY_COMPANY.WITHOUTINFO}</Text>
            </ContainerFlex>
        </ContainerGrid>
    );
};
