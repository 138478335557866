import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import * as style from '@Articles/style';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import TooltipGeneral from '@/components/General/Atoms/Tooltip/TooltipGeneral';
import {HEADERCATALOG, HEREDITARY, NEWCATALOG} from '@Articles/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {FeatureHereditaryData} from '@/components/Articles/interface';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {GetInheritedFeaturesData} from '@components/Articles/Redux/Actions/GetInheritedFeatures';
import {
    DataCharacteristicsInheriteds,
    DataNodeProps,
    IArticleDetail,
    ICharacteristics,
} from '@components/Articles/Redux/interfaces';
import {AddHereditaryItems} from '@components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {updateHereditaryFeature} from '@/components/Catalogue/Redux/Actions/SaveNewInheritedFeatureActions';

const CaracteristicsHereditary = () => {
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const LevelsDataRoute = useSelector(
        (state: RootState) => state.GetNewCatalogId.targetLevel as number
    );
    const FeaturesDinamic = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.dataDetails
    );
    const CatLevelIdDinamics = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.catLevelId
    );
    const FeaturesHereditary = useSelector(
        (state: RootState) => state.GetInheritedFeatures.getHereditary?.data
    );
    const DataUpdate = useSelector(
        (state: RootState) => state.GetNewCatalogId.dataCatalog?.inheritedCharacteristics
    );
    const DataNodeSelected = useSelector((state: RootState) => state.SelectedDataNode.dataNode);
    const ActionTypeForm = useSelector(
        (state: RootState) => state.GetDetailsCharacteristics.actionType as number
    );
    const [localFeatures, setLocalFeatures] = useState<FeatureHereditaryData[]>([]);
    const [changedFeatures, setChangedFeatures] = useState<FeatureHereditaryData[]>([]);
    const LevelsRouteMinus = 1;
    const filteredData = DataNodeSelected.find(
        (item: DataNodeProps) => item.level === LevelsDataRoute - LevelsRouteMinus
    );
    useEffect(() => {
        if (DataNodeSelected && ActionTypeForm === NEWCATALOG.CREATE && filteredData) {
            const LevelReducerRoute = 2;
            dispatch(
                GetInheritedFeaturesData(
                    {
                        levelID: filteredData.catLevel,
                        articleLevels: LevelsDataRoute - LevelReducerRoute,
                    },
                    token
                )
            );
        }
    }, [DataNodeSelected, ActionTypeForm, filteredData, LevelsDataRoute, dispatch, token]);
    const mapArticleDetailsToFeatures = (
        articleDetails: IArticleDetail[]
    ): FeatureHereditaryData[] => {
        if (!articleDetails || articleDetails.length === 0) {
            return [];
        }

        return articleDetails.map((detail) => {
            const isMandatory = detail.isRequerid === 1;
            return {
                itemControlId: detail.articleDetailId,
                itemControlName: detail.propertyName,
                itemControlMandatory: isMandatory,
                itemControlIncludeTag: detail.includeLabel,
                typeItemDetailId: detail.typeArticleDetailId,
                typeItemDetailName: detail.typeArticleDetailName,
                typeCat: 0,
                typeCatName: '',
                catId: CatLevelIdDinamics,
                values: detail.articlePropertyLists.map((item) => item.descriptionItem).join(', '),
                propertyStatus: detail.propertyStatus,
                isInherited: detail.isInherited,
                status: detail.propertyStatus,
            };
        });
    };
    function mapCharacteristicsToHereditaryFeatures(
        characteristics: ICharacteristics[]
    ): FeatureHereditaryData[] {
        return characteristics.map((charItem) => {
            const valuesArray = charItem.values.split('|');
            const values = valuesArray.length > 1 ? valuesArray[1].trim() : '';
            return {
                itemControlId: charItem.articleDetailId,
                itemControlName: charItem.propertyName,
                itemControlMandatory: charItem.isRequerid === 1,
                itemControlIncludeTag: charItem.includeLabel,
                typeItemDetailId:
                    Array.isArray(charItem.articlesPropertyLists) &&
                    charItem.articlesPropertyLists.length > 0
                        ? charItem.articlesPropertyLists[0].articleDetailsId
                        : 0,
                typeItemDetailName: charItem.typeArticleDetailName,
                typeCat: 0,
                typeCatName: '',
                catId: 0,
                values: values,
                isInherited: true,
                status: charItem.propertyStatus,
            };
        });
    }

    useEffect(() => {
        if (ActionTypeForm === NEWCATALOG.CREATE) {
            const mappedDynamicFeatures = mapArticleDetailsToFeatures(FeaturesDinamic || []);
            const combinedFeatures = [...(FeaturesHereditary || []), ...mappedDynamicFeatures];
            const uniqueFeatures = combinedFeatures.filter(
                (item, index, self) =>
                    index ===
                    self.findIndex((feature) => feature.itemControlId === item.itemControlId)
            );
            setLocalFeatures(uniqueFeatures);
            setChangedFeatures(uniqueFeatures);
        }
        if (ActionTypeForm === NEWCATALOG.EDIT) {
            const mappedUpdatedFeatures = mapCharacteristicsToHereditaryFeatures(DataUpdate || []);
            setLocalFeatures(mappedUpdatedFeatures);
            setChangedFeatures(mappedUpdatedFeatures);
        }
    }, [ActionTypeForm, FeaturesHereditary, FeaturesDinamic, DataUpdate]);

    const handleCheckboxChange = (
        itemControlId: number,
        field: 'itemControlMandatory' | 'itemControlIncludeTag' | 'status'
    ) => {
        setLocalFeatures((prevFeatures) =>
            prevFeatures.map((item: FeatureHereditaryData) =>
                item.itemControlId === itemControlId ? {...item, [field]: !item[field]} : item
            )
        );

        setChangedFeatures((prevChangedFeatures) => {
            const updatedItem = localFeatures.find((item) => item.itemControlId === itemControlId);

            if (updatedItem) {
                const newItem = {...updatedItem, [field]: !updatedItem[field]};
                const updatedFeatures = prevChangedFeatures.map((item) =>
                    item.itemControlId === itemControlId ? newItem : item
                );
                const allFeaturesMap = new Map(
                    updatedFeatures.map((item) => [item.itemControlId, item])
                );

                localFeatures.forEach((item) => {
                    if (!allFeaturesMap.has(item.itemControlId)) {
                        allFeaturesMap.set(item.itemControlId, item);
                    }
                });

                return Array.from(allFeaturesMap.values());
            }

            return prevChangedFeatures;
        });

        const updatedFeature = localFeatures.find(
            (feature) => feature.itemControlId === itemControlId
        );
        if (updatedFeature) {
            const updatedFeatureData = {
                ...updatedFeature,
                [field]: !updatedFeature[field],
            };
            dispatch(updateHereditaryFeature(updatedFeatureData));
        }
    };
    useEffect(() => {
        const formattedFeatures: DataCharacteristicsInheriteds[] = changedFeatures.map((item) => ({
            characteristicsInheritedId: item.itemControlId,
            characteristicName: item.itemControlName,
            mandatory: item.itemControlMandatory ? 1 : 0,
            includesLabel: item.itemControlIncludeTag,
            enabled: true,
            valueInherited: [
                {
                    valueDetailInheritedId: item.typeItemDetailId,
                    description: item.values,
                    statusValue: true,
                },
            ],
        }));
        dispatch(AddHereditaryItems(formattedFeatures));
    }, [changedFeatures, dispatch]);

    return (
        <ContainerFlex {...style.Card}>
            <ContainerFlex Height="" Justify="start" Gap="0.5rem">
                <Text {...style.Card.Title}>{HEREDITARY.CARACTERISTICS}</Text>
                <TooltipGeneral
                    icon={'help_outline'}
                    titleTooltip={HEREDITARY.CARACTERISTICS}
                    descriptionTooltip={HEREDITARY.DESCRIPTION_CARACTERISTICS}
                />
            </ContainerFlex>
            {LevelsDataRoute <= 1 ? (
                <Text {...style.Card.Subtitle} MarginB="0.5rem">
                    {HEREDITARY.NOT_APPLY}
                </Text>
            ) : (
                <ContainerFlex FlexDir="column" Align="start">
                    <Text {...style.Card.Subtitle} MarginB="0.5rem">
                        {HEREDITARY.SECTION_DESCRIPTION}
                    </Text>
                    <ContainerFlex
                        Display="grid"
                        GridColumns="0.5fr 0.5fr 0.5fr 0.2fr 0.2fr 0.2fr"
                        Justify="start"
                        Height="2.75rem"
                        Padding="0.5rem"
                        Bb="solid 1px #E8E9EA"
                    >
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {HEADERCATALOG.NAME}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {HEADERCATALOG.TYPE}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {HEADERCATALOG.VALUES}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {HEADERCATALOG.MANDATORY}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {HEADERCATALOG.ADD}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                            {HEADERCATALOG.AVIABLE}
                        </Text>
                    </ContainerFlex>
                    {localFeatures &&
                        localFeatures.map((item: FeatureHereditaryData) => (
                            <ContainerFlex
                                Display="grid"
                                GridColumns="0.5fr 0.5fr 0.5fr 0.2fr 0.2fr 0.2fr"
                                Justify="start"
                                Height="3.5rem"
                                Bb="solid 1px #E8E9EA"
                                Gap="1.5rem"
                                Padding="0.5rem"
                                key={item.itemControlId}
                            >
                                <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                    {item.itemControlName}
                                </TextEllipsis>
                                <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                    {item.typeItemDetailName}
                                </TextEllipsis>
                                <TextEllipsis FontWeight="400" Color="#2A2C2F">
                                    {item.values}
                                </TextEllipsis>
                                <Checkbox
                                    checked={item.itemControlMandatory}
                                    onChange={() =>
                                        handleCheckboxChange(
                                            item.itemControlId,
                                            'itemControlMandatory'
                                        )
                                    }
                                />
                                <Checkbox
                                    checked={item.itemControlIncludeTag}
                                    onChange={() =>
                                        handleCheckboxChange(
                                            item.itemControlId,
                                            'itemControlIncludeTag'
                                        )
                                    }
                                />
                                <GreenSwitch
                                    checked={item.status}
                                    onChange={() =>
                                        handleCheckboxChange(item.itemControlId, 'status')
                                    }
                                />
                            </ContainerFlex>
                        ))}
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default CaracteristicsHereditary;
