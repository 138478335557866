import {AppDispatch} from '@/config/store';
import {refreshTokenAction} from '@/components/Login/Redux/Actions/GetValidateUsers';

export const refreshToken = (newToken: string | null, dispatch: AppDispatch) => {
    if (newToken && validateExpirationTime()) {
        dispatch(refreshTokenAction(newToken));
    }
};

const validateExpirationTime = (): boolean => {
    const tokeExpirationTime = new Date().getTime();
    const expirationTime = 12 * 60 * 1000;
    const currentTime = new Date().getTime();
    const isTokenExpired = currentTime - tokeExpirationTime > expirationTime;
    return isTokenExpired;
};
