export const BRANCH_ASSIGNMENT = {
    TITLE: 'Asignación de sucursales',
    TITLE_TWO: 'Selecciona la sucursal del usuario',
    INDICATION: 'Asigna las ubicaciones en tu empresa a las que este usuario tendrá acceso.',
    ASSIGNMENT: '[Nombre Empresa]',
    ASSIGNMENT_ONE: 'México',
    ASSIGNMENT_TWO: 'Centro',
    ASSIGNMENT_THREE: 'CDMX',
    TYPE_ASSIGNMENT: 'Grupo, sucursal o bodega',
    ELEMENT: 'elementos',
    BTN: 'Descartar',
    BTN_KEEP: 'Guardar',
    SEARCH: 'Busca un grupo o sucursal',
    NOT_DATA: 'No hay datos disponibles.',
    IS_BRANCH: 'Sucursal',
};

export const DATA_TYPE_CASH = [
    {label: 'Ingreso', value: 'Ingreso'},
    {label: 'Egreso', value: 'Egreso'},
];

export const userProfile = 3;
