import {AxiosResponse} from 'axios';
import {
    QUOTER_OTHER_LOANS_COMPANIES_START,
    QUOTER_OTHER_LOANS_COMPANIES_ERROR,
    QUOTER_OTHER_LOANS_COMPANIES_SUCCESS,
} from '@components/CreditQuoter/Redux/Types/Types';
import {IQuoterOthersLoansCompanies} from '@components/CreditQuoter/interfaces';

const initialState: IQuoterOthersLoansCompanies = {
    dataOhterLoans: null,
    loading: false,
    error: false,
    errorData: null,
};

const GetQuoterOthersLoansCompanies = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IQuoterOthersLoansCompanies => {
    switch (action.type) {
        case QUOTER_OTHER_LOANS_COMPANIES_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case QUOTER_OTHER_LOANS_COMPANIES_SUCCESS:
            return {...state, dataOhterLoans: action.payload.data, loading: false, error: false};
        case QUOTER_OTHER_LOANS_COMPANIES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default GetQuoterOthersLoansCompanies;
