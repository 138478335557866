import {AppDispatch} from '@/config/store';
import {
    ADD_CART_PAYMENT,
    ADD_CART_PAYMENT_SUCCESS,
    ADD_CART_PAYMENT_ERROR,
    CART_PAYMENT_ENTERED,
    ADD_PAYMENT_RESET,
} from '@TypesLoan/types';
import {URL} from '@config/constants/index';
import {toggleLoading} from '@/components/General/Atoms/LoadingAtaskate/Redux/Actions/loadingActions';

import axios from 'axios';
import {IAddCartPayment, ISummaryCard} from '@components/Loan/Redux/interfaces';

export const addCartPay = () => {
    return {
        type: ADD_CART_PAYMENT,
    };
};
export const addCartPaySuccess = (result: ISummaryCard) => {
    return {
        type: ADD_CART_PAYMENT_SUCCESS,
        paymentResult: result,
    };
};
export const addCartPayError = () => {
    return {
        type: ADD_CART_PAYMENT_ERROR,
    };
};

export default function addCartPayment(token: string, data: IAddCartPayment) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(addCartPay());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddCartPayment}`,
                data,
                {headers}
            );
            await dispatch(addCartPaySuccess(response.data));
        } catch (error: unknown) {
            dispatch(toggleLoading(false));
            dispatch(addCartPayError());
            throw error;
        }
    };
}

export const setPaymentPosted = (paid: boolean) => ({
    type: CART_PAYMENT_ENTERED,
    paid,
});

export const resetPaymentPosted = () => ({
    type: ADD_PAYMENT_RESET,
});
