import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CAT_PAYMENT_TYPES_START,
    GET_CAT_PAYMENT_TYPES_SUCCESS,
    GET_CAT_PAYMENT_TYPES_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';

export const getCatPaymentTypesStart = () => {
    return {
        type: GET_CAT_PAYMENT_TYPES_START,
    };
};

export const getCatPaymentTypesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CAT_PAYMENT_TYPES_SUCCESS,
        payload: result.data,
    };
};

export const getCatPaymentTypesError = (error: AxiosError) => {
    return {
        type: GET_CAT_PAYMENT_TYPES_ERROR,
        error,
    };
};

export const getCatPaymentTypes = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCatPaymentTypesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCatPaymentTypes}`,
                {headers}
            );
            dispatch(getCatPaymentTypesSuccess(response));
        } catch (error) {
            dispatch(getCatPaymentTypesError(error as AxiosError));
        }
    };
};
