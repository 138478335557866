import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Controller} from 'react-hook-form';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import CalendarDateSelection from '@General/Atoms/CalendarDateSelection/CalendarDateSelection';
import {IDateCash} from '@CashFlow/interfaces';
import {hoursOfDay} from '@CashFlow/constants';
import {TITLES_FILTER} from '@CashFlow/constantsAtaskateFlow';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';

export const DateFilter = ({
    control,
    errors,
    dateFilter,
    setDateFilter,
    hourFilter,
    setHourFilter,
    isReset,
    handleClick,
}: IDateCash) => {
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="" Gap="24px">
            <ContainerFlex Justify="space-between">
                <Text Color="#54575C" FontWeight="700">
                    {TITLES_FILTER.FILTER_DATE}
                </Text>
                <GreenSwitch
                    onChange={() => setDateFilter(!dateFilter)}
                    value={dateFilter}
                    checked={dateFilter}
                />
            </ContainerFlex>
            {dateFilter && (
                <ContainerFlex Align="start" FlexDir="column">
                    <Text Color="#54575C" FontWeight="700">
                        {TITLES_FILTER.DATE}
                    </Text>
                    <Controller
                        name="startTime"
                        control={control}
                        render={({field: {onChange, value, ...field}}) => (
                            <CalendarDateSelection
                                {...field}
                                placeholder="dd/mm/yyyy"
                                format="dd/MM/yyyy"
                                error={!!errors.startTime?.message}
                                isReset={isReset}
                                click={handleClick}
                                limitDate={false}
                                value={value.value ? new Date(value.value) : null}
                                onSelect={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                    <Text Color="#54575C" FontSize="0.75rem">
                        {TITLES_FILTER.FOMAT_DATE}
                    </Text>
                </ContainerFlex>
            )}

            <ContainerFlex Justify="space-between">
                <Text Color="#54575C" FontWeight="700">
                    {TITLES_FILTER.FILTER_HOUR}
                </Text>
                <GreenSwitch
                    value={hourFilter}
                    onChange={() => setHourFilter(!hourFilter)}
                    checked={hourFilter}
                />
            </ContainerFlex>
            {hourFilter && (
                <ContainerFlex Justify="space-between" ColumnGap="16px">
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text Color="#54575C" FontWeight="700">
                            {TITLES_FILTER.FROM}
                        </Text>
                        <Controller
                            name="startTime"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    isDisabled={!hourFilter}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    hasError={!!errors.startTime?.label?.message}
                                    options={hoursOfDay}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                        {errors.startTime?.label?.message && (
                            <Text FontSize="0.75rem" Color="#ef4f55">
                                {errors.endTime?.label?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text Color="#54575C" FontWeight="700">
                            {TITLES_FILTER.TO}
                        </Text>
                        <Controller
                            name="endTime"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isDisabled={!hourFilter}
                                    isSearchable={false}
                                    hasError={!!errors.endTime?.label?.message}
                                    options={hoursOfDay}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                        {errors.endTime?.label?.message && (
                            <Text FontSize="0.75rem" Color="#ef4f55">
                                {errors.endTime?.label?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
