import {BRANCH_ID} from '@components/AdminNavbar/types';
import {IIdBranch} from '@components/AdminNavbar/interfaces';

const initialState: IIdBranch = {
    branchId: 0,
};

const getBranchId = (state = initialState, action: {type: string; branch: number}) => {
    switch (action.type) {
        case BRANCH_ID:
            return {...state, branchId: action.branch};

        default:
            return state;
    }
};
export default getBranchId;
