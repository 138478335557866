import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContactInfoProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import IdentityDocsModal from '@Shopify/ClientProfile/IdentityDocsModal';
import InfoBlock from '@/components/Shopify/ClientProfile/Information/InfoBlock';
import {PERSONAL_INFORMATION} from '@Shopify/ClientProfile/constants';
import {getClientDetails} from '@Shopify/ClientProfile/Redux/Action/GetClientDetails';

const PersonalInfo: React.FC<ContactInfoProps> = ({clientProfile}) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const {token} = useSelector((state: RootState) => ({
        token: String(state.getUsersValidation.userData?.token),
    }));
    const clientDetails = useSelector((state: RootState) => state.clientDetails.clientDetails);
    const clientId = useSelector((state: RootState) => state.cardClientProfile.idClient);
    const fetchClientDetails = () => {
        dispatch(getClientDetails(token, {clientId: clientProfile.clientId}));
    };
    return (
        <>
            <ContainerFlex
                Padding="1rem 1.5rem"
                FlexDir="column"
                Align="start"
                Gap="1rem"
                Radius="1.5rem"
                Border="1px solid #E8E9EA"
                Self="stretch"
            >
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                    {PERSONAL_INFORMATION.TITLE}
                </Text>
                <ContainerFlex Align="flex-start" Gap="1.5rem" Self="stretch">
                    <Image
                        src={clientProfile.image}
                        Width="7.625rem"
                        Height="7.625rem"
                        Radius="41%"
                        ObjectFit="cover"
                    />
                    <ContainerFlex
                        Align="flex-start"
                        Justify="start"
                        FlexDir="column"
                        AlignContent="flex-start"
                        Gap="1rem"
                        FlexWrap="nowrap"
                    >
                        <ContainerFlex Gap="1rem" Justify="flex-start">
                            <InfoBlock
                                label={PERSONAL_INFORMATION.NAMES}
                                transformText="capitalize"
                                value={clientDetails?.name}
                            />
                            <InfoBlock
                                label={PERSONAL_INFORMATION.PATERNAL_SURNAME}
                                transformText="capitalize"
                                value={clientDetails?.paternalSurname}
                            />
                        </ContainerFlex>
                        <ContainerFlex Gap="1rem" Justify="flex-start">
                            <InfoBlock
                                label={PERSONAL_INFORMATION.MATERNAL_SURNAME}
                                transformText="capitalize"
                                value={clientDetails?.maternalSurname}
                            />
                            <InfoBlock
                                label={PERSONAL_INFORMATION.EMAIL}
                                value={clientDetails?.email}
                            />
                        </ContainerFlex>
                        <ContainerFlex Gap="1rem" Justify="flex-start">
                            <InfoBlock
                                label={PERSONAL_INFORMATION.CELLULAR_PHONE}
                                value={clientDetails?.phone}
                            />
                            <InfoBlock
                                label={PERSONAL_INFORMATION.BIRTH_DATE}
                                value={clientDetails?.birthDate}
                            />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                    {PERSONAL_INFORMATION.ADD_FINGERPRINTS}
                </Text>
                <ButtonGenerals
                    Width="9.344rem"
                    Radius="2rem"
                    Height="2rem"
                    Padding="0.25rem 1rem"
                    BackGC="transparent"
                    Justify="center"
                    Color="#5A5AFF"
                    HBackG="transparent"
                    FontWeight="500"
                    FontSize="1rem"
                    type="button"
                    Cursor="pointer"
                    Border="1px solid #5A5AFF"
                    onClick={() => {
                        setShowModal(true);
                    }}
                >
                    {PERSONAL_INFORMATION.MODIFY}
                </ButtonGenerals>
            </ContainerFlex>
            {showModal && (
                <IdentityDocsModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    fingerPrints={true}
                    clientId={clientId}
                    onExit={fetchClientDetails}
                />
            )}
        </>
    );
};

export default PersonalInfo;
