export const NEW_LEVEL = {
    LEVEL: 'Nuevo nivel ',
    EDIT: 'Editar nivel',
    CANCEL: 'Cancelar',
};

export const DINAMICS = {
    CARACTERISTICS: 'Características dinámicas',
    SECTION_DESCRIPTION: 'Basado en las características creadas en artículos similares',
    DESCRIPTION_CARACTERISTICS:
        'Las características dinámicas son elementos que pueden cambiar dependiendo del estado de un producto. Por ejemplo, opciones de tamaño como chico, mediano o grande, de los que el cliente puede seleccionar.',
    NEW_CARACTERISTIC: 'Nueva característica',
};
export const HEREDITARY = {
    CARACTERISTICS: 'Características heredadas',
    SECTION_DESCRIPTION: 'Estas características se heredan de los niveles superiores a este nivel.',
    NOT_APPLY: 'En este nivel, no se presentan características heredadas.',
    DESCRIPTION_CARACTERISTICS:
        'Las características dinámicas son elementos que pueden cambiar dependiendo del estado de un producto. Por ejemplo, opciones de tamaño como chico, mediano o grande, de los que el cliente puede seleccionar.',
    NEW_CARACTERISTIC: 'Nueva característica',
};
export const HEADERCATALOG = {
    NAME: 'Nombre',
    TYPE: 'Tipo',
    VALUES: 'Valores',
    MANDATORY: 'Obligatoria',
    ADD: 'Incluir etiqueta',
    AVIABLE: '¿Habilitada?',
};

export const CATEGORIZATION = {
    TITLE_SECTION: 'Categorización',
    LEVEL: {
        GROUP: 'Nuevo nivel 1 (Grupo)',
        FAMILIY: 'Nuevo nivel 2 (Familia)',
        SUBFAMILY: 'Nuevo nivel 3 (Sub-Familia)',
        BRAND: 'Nuevo nivel 4 (Marca)',
        ARTICLE: 'Nuevo nivel 5 (Artículo)',
    },
};

export const INFORMATION = {
    TITLE_SECTION: 'Información',
    INPUT_NAME: 'Nombre',
    INPUT_DESCRIPTION: 'Descripción',
    FIVE: 5,
};

export const ARTICLE_SEARCH_TITLES = {
    TITLE: 'Gestión de catálogo',
    PLACE_HOLDER: 'Busca una nivel, marca o artículo',
    ERROR: ' No se encontraron coincidencias. Por favor, ingrese un texto diferente.',
};
export const DEFAULT_TITLES = {
    GROUP: 'Nuevo nivel 1 (Grupo)',
    FAMILIY: 'Nuevo nivel 2 (Familia)',
    SUBFAMILY: 'Nuevo nivel 3 (Sub-familia)',
    BRAND: 'Nuevo nivel 4 (Marca)',
    ARTICLE: 'Nuevo nivel 5 (Artículo)',
};
export const ACTIONS = {
    TITLE: 'Otras acciones',
    ACTIVATE: 'Activa este nivel para añadir subniveles dentro de él.',
    SUB: 'Si publicas este artículo, notificará a los clientes que estará disponible para empeñar.',
};

export const CatalogValues = {
    level: 0,
    name: '',
    description: '',
    catLevelId: 0,
    fileExtension: '',
    fileContent: '',
    sizeLabelId: 0,
    dynamicCharacteristic: [
        {
            idTypeCharacteristics: 0,
            nameCharacteristics: '',
            isRequerid: 0,
            includeLabelPrinter: true,
            details: [
                {
                    valueDescription: '',
                },
            ],
            isInherited: true,
        },
    ],
    status: true,
};
export const EMPTY_IMAGE_URL = '';

export const NEWCATALOG = {NEW: 'Nuevo', CREATE: 1, EDIT: 2};
export const LEVELING = {
    ZERO: 0,
    ONE: 1,
};
export const LEVELNAMES = {
    ZERO: 0,
    GROUP: 'Grupo',
    ONE: 'Nivel 1',
    FAMILY: 'Familia',
    TWO: 'Nivel 2',
    SUBFAMILY: 'Sub-familia',
    THREE: 'Nivel 3',
    BRAND: 'Marca',
    FOUR: 'Nivel 4',
    ARTICLE: 'Articulo',
    FIVE: 'Nivel 5',
};
