import React from 'react';

import {NEWGROUP} from '@components/LocationsBranch/constants';
import {INameLocation} from '@components/LocationsBranch/interfaces';
import * as style from '@components/LocationsBranch/stylesLocations';
import {ContainerFlex, Text, ContainerCellGrid, Input} from '@Shopify/Ecommerce/styles';

export const NameLocation = ({register, errors, nameLocation, newName}: INameLocation) => {
    return (
        <ContainerFlex {...style.contentGral} Gap="1.5rem">
            <ContainerCellGrid Justify="start" FlexDir="column" Gap="0.25rem">
                <Text Color="#6D6E71" FontWeight="700">
                    {NEWGROUP.NAME}
                </Text>
                <Input
                    Width="100%"
                    Cursor="text"
                    {...register(`${nameLocation}`)}
                    error={errors[nameLocation]?.message as string}
                />
                <Text Color="#FF6357" FontSize="0.75rem">
                    {(errors[nameLocation]?.message as string) ||
                        (newName.error && NEWGROUP.NEW_NAME)}
                </Text>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
