import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ContainerFlex} from '@components/Shopify/Ecommerce/styles';
import CreditRequestProfile from '@/components/CreditsAndPawns/CreditRequest/CreditRequestProfile';
import CreditRequestHeader from '@/components/CreditsAndPawns/CreditRequest/CreditRequestHeader';
import CreditRequestInformation from '@/components/CreditsAndPawns/CreditRequest/CreditRequestInformation';
import CreditRequestNotes from '@/components/CreditsAndPawns/CreditRequest/CreditRequestNotes';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {getCreditRequestDetailsAction} from '@components/CreditsAndPawns/Redux/Actions/GetDetailsCreditActions';
import CustomerInfoModal from '@/components/CreditsAndPawns/CreditRequest/CustomerInfoModal/CustomerInfoModal';
import {AmortizationTableCreditRequest} from '@/components/CreditsAndPawns/CreditRequest/AmortizationTableCreditRequest';
import {initialApprovedCreditRequest} from '@/components/CreditsAndPawns/Redux/Actions/GetApprovedCreditSolicitudeAction';

const CreditRequest = () => {
    const {id} = useParams<{id?: string}>();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (token && id) {
            dispatch(getCreditRequestDetailsAction({token, creditId: id}));
            dispatch(initialApprovedCreditRequest());
        }
    }, [id]);
    const {creditRequestDetail} = useSelector((state: RootState) => {
        return state.getDetailsCredit;
    });
    const {openModal} = useSelector((state: RootState) => {
        return state.getCustomerInfo;
    });
    const [showTable, setShowTable] = useState<boolean>(false);
    return (
        <ContainerFlex FlexDir="column" Align="start" Justify="space-between" Height="max-content">
            <ContainerFlex
                FlexDir="column"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1rem"
                Height="100%"
            >
                <ContainerFlex FlexDir="row" Align="start" Justify="start" Height="78px">
                    <CreditRequestHeader
                        solicitudeNumber={creditRequestDetail?.solicitudeNumber ?? ''}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="row" Align="start" Justify="start" Height="100%" Gap="16px">
                    {creditRequestDetail && (
                        <>
                            <ContainerFlex Padding="0px" Justify="start" Align="start">
                                <CreditRequestProfile {...creditRequestDetail} />
                            </ContainerFlex>
                            <ContainerFlex
                                FlexDir="column"
                                Padding="0px"
                                Justify="start"
                                Align="start"
                                Gap="24px"
                            >
                                <CreditRequestInformation
                                    creditRequestData={creditRequestDetail}
                                    solicitudeId={parseInt(id as string, 10)}
                                    showTable={showTable}
                                    setShowTable={setShowTable}
                                />
                                <CreditRequestNotes />
                            </ContainerFlex>
                        </>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            {openModal && <CustomerInfoModal />}
            {showTable && (
                <AmortizationTableCreditRequest showTable={showTable} setShowTable={setShowTable} />
            )}
        </ContainerFlex>
    );
};

export default CreditRequest;
