import * as yup from 'yup';
import {
    ERROR_GRAL_MESSAGE,
    holidaysHours,
    scheduleHours,
} from '@components/LocationsBranch/constants';
import {catCompany, catPlaces} from '@components/SignUp/interfaces';

const NAME = /^[1-9A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ.\s]+$/;
const ADDRESS = {
    colony: yup.number().required(ERROR_GRAL_MESSAGE.ADDRESS),
    street: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
    outerNumber: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
    interiorNumber: yup.string(),
    municipal: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
    state: yup.string().required(ERROR_GRAL_MESSAGE.ADDRESS),
};

export const newGroupShema = (isEdit: boolean) =>
    yup.object().shape({
        nameGroup: yup
            .string()
            .required(ERROR_GRAL_MESSAGE.NAME_GROUP)
            .matches(NAME, ERROR_GRAL_MESSAGE.NAME_MATCHES),
        businessLines: isEdit
            ? yup.array().optional()
            : yup
                  .array()
                  .min(1, ERROR_GRAL_MESSAGE.BUSINESS_LINE)
                  .test('OneActive', ERROR_GRAL_MESSAGE.BUSINESS_LINE, (value) => {
                      return value ? value.some((item: catCompany) => item.status) : false;
                  }),
        storages: yup.array().nullable(),
        openingHours: yup.array().nullable(),
        holidays: yup
            .array()
            .nullable()
            .test('OneActive', ERROR_GRAL_MESSAGE.HOLIDAYS, (value) => {
                if (!value || value.length === 0) return true;
                return holidaysHours(value).length > 0;
            }),
    });
export const newBranchesShema = (isEdit: boolean) =>
    yup.object().shape({
        nameBranch: yup
            .string()
            .required(ERROR_GRAL_MESSAGE.NAME_BRANCH)
            .matches(NAME, ERROR_GRAL_MESSAGE.NAME_MATCHES),
        branchDirection: yup.object().shape(ADDRESS),
        branchBusinessLine: isEdit
            ? yup.array().optional()
            : yup
                  .array()
                  .min(1, ERROR_GRAL_MESSAGE.BUSINESS_LINE)
                  .test('OneActive', ERROR_GRAL_MESSAGE.BUSINESS_LINE, (value) => {
                      return value ? value.some((item: catCompany) => item.status) : false;
                  }),
        branchSalesChannel: isEdit
            ? yup.array().optional()
            : yup
                  .array()
                  .min(1, ERROR_GRAL_MESSAGE.SALES_CHANNEL)
                  .test('OneActive', ERROR_GRAL_MESSAGE.SALES_CHANNEL, (value) => {
                      return value ? value.some((item: catPlaces) => item.status) : false;
                  }),
        storages: yup.array().min(1, ERROR_GRAL_MESSAGE.STORAGE),
        openingHours: yup
            .array()
            .min(1, ERROR_GRAL_MESSAGE.SCHEDULE)
            .test('OneActive', ERROR_GRAL_MESSAGE.SCHEDULE, (value) => {
                return value ? scheduleHours(value).length > 0 : false;
            }),
        holidays: yup
            .array()
            .nullable()
            .test('OneActive', ERROR_GRAL_MESSAGE.HOLIDAYS, (value) => {
                if (!value || value.length === 0) return true;
                return holidaysHours(value).length > 0;
            }),
    });

export const newStorageShema = yup.object().shape({
    nameStorage: yup
        .string()
        .required(ERROR_GRAL_MESSAGE.NAME_STORAGE)
        .matches(NAME, ERROR_GRAL_MESSAGE.NAME_MATCHES),
    storageDirection: yup.object().shape(ADDRESS),
    storages: yup.array().nullable(),
});
