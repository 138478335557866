import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {ClickAwayListener} from '@mui/material';
import * as style from '@/components/Quoter/stylesConstants';
import * as styles from '@components/CreateUsers/Styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {BRANCHLOCATION} from '@components/CreateUsers/constants';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    GroupManagementGroup,
    getArticleLevel,
    getBrandLevel,
    getFamilyLevel,
    getSubfamilyLevel,
} from '@/components/Articles/Redux/Actions/SearchManagement';
import {ISearchAllCatalog, ISearchCatalog, LevelIds} from '@/components/Articles/Redux/interfaces';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {IconSvg} from '@components/Quoter/Icons/styles';
import {ARTICLE_SEARCH_TITLES} from '@Articles/constants';
import {Highlight} from '@components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';
import {useInfiniteScroll} from '@OrganizationCreation/SubContainer/TreeTableUtils/ReutilizableInfiniteScroll';
import {
    saveArticle,
    saveBrand,
    saveFamily,
    saveGroup,
    saveSubFamily,
} from '@/components/Articles/Redux/Actions/SavedLevels';

export const SearchArticle = () => {
    const [viewList, setView] = useState(false);
    const [search, setSearch] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const handleIntersect = () => {
        if (pageNumber <= SearchDataset.PageData.totalPages)
            setPageNumber((prev: number) => prev + 1);
    };

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const SearchDataset: ISearchAllCatalog = useSelector(
        (state: RootState) => state.ArticleManagementSearch
    );
    const dispatch = useDispatch();
    const Request = {
        searchName: search,
        pageNumber: 1,
        pageSize: 5 * pageNumber,
    };
    const targetRef = useInfiniteScroll({
        onIntersect: handleIntersect,
    });

    const HandleSearch = (Value: string) => {
        if (Value.length > 2 && token) {
            setView(true);
            setSearch(Value);
            setPageNumber(1);
            dispatch(GroupManagementGroup(token, Request));
        }
    };
    useEffect(() => {
        if (search.length <= 2) setView(false);
    }, [search]);

    useEffect(() => {
        if (token) {
            dispatch(GroupManagementGroup(token, Request));
        }
    }, [pageNumber]);

    const SaveLevels = (data: LevelIds) => {
        dispatch(saveGroup(data.groupId));
        dispatch(saveFamily(data.familyId));
        dispatch(saveSubFamily(data.subFamilyId));
        dispatch(saveBrand(data.brandId));
        dispatch(saveArticle(data.itemId));
    };
    const HandleGet = (data: LevelIds) => {
        if (token && data.groupId !== 0) {
            dispatch(getFamilyLevel(token, String(data.groupId)));
        }
        if (token && data.familyId !== 0) {
            dispatch(getSubfamilyLevel(token, String(data.familyId)));
        }
        if (token && data.subFamilyId !== 0) {
            dispatch(getBrandLevel(token, String(data.subFamilyId)));
        }
        if (token && data.brandId !== 0) {
            dispatch(getArticleLevel(token, String(data.brandId)));
        }
        SaveLevels(data);
    };
    const formatBreadComes = (breadComes: string) => {
        return breadComes.split(' -> ').map((part, index) => (
            <ContainerFlex
                Justify="center"
                Align="center"
                Width="auto"
                key={index}
                Cursor="pointer"
            >
                <Highlight text={part} highlight={search} Cursor="pointer" />
                {index < breadComes.split(' -> ').length - 1 && (
                    <IconSvg Height="18px" Width="18px" cursor="pointer">
                        <path
                            d="M7.05 13.5L6 12.45L9.45 9L6 5.55L7.05 4.5L11.55 9L7.05 13.5Z"
                            fill="#5A5AFF"
                        />
                    </IconSvg>
                )}
            </ContainerFlex>
        ));
    };
    const ProductComponent = ({breadComes, levelsIds}: ISearchCatalog) => {
        return (
            <ContainerFlex
                {...style.contentList}
                HBackground="#f0f0ff"
                HBorderLeft="2px solid #5a5aff"
                Height="40px"
                Justify="start"
                Cursor="pointer"
                onClick={() => {
                    if (token) HandleGet(levelsIds);
                }}
            >
                {formatBreadComes(breadComes)}
            </ContainerFlex>
        );
    };
    return (
        <ContainerFlex Justify="start" Align="start" Height="auto">
            <ClickAwayListener onClickAway={() => setView(false)}>
                <SearchInput
                    Position="relative"
                    GridColumn="98% 2%"
                    FontSize="1rem"
                    PlaceholderColor="#A1A5AA"
                    WBoxShadow=""
                    Width="100%"
                    Margin="0 0 0.5rem 0"
                    Right=""
                >
                    <input
                        onChange={(e: {target: {value: string}}) => {
                            HandleSearch(e.target.value);
                            setSearch(e.target.value);
                        }}
                        placeholder={ARTICLE_SEARCH_TITLES.PLACE_HOLDER}
                        value={search}
                    />
                    <Icons
                        {...styles.iconSearch}
                        Cursor="pointer"
                        onClick={() => {
                            if (search.length > 0) {
                                setSearch('');
                                setPageNumber(1);
                            }
                        }}
                    >
                        {search.length > 0 ? BRANCHLOCATION.ICONCLOSE : BRANCHLOCATION.ICONSEARCH}
                    </Icons>
                    <ContainerFlex
                        Position="absolute"
                        Height=""
                        PositionTop="40px"
                        Width="100%"
                        BoxShadow=""
                        BoxS="0px 0px 8px 0px rgba(0, 0, 0, 0.08)"
                        backG="#fff"
                        FlexDir="column"
                        OverFlow="auto"
                        Justify="start"
                        MaxH={SearchDataset.Group.length > 0 ? '120px' : ''}
                        Radius="0.5rem"
                        ZIndex="2"
                        Border={'1px solid #F3F3F3'}
                    >
                        {viewList && (
                            <>
                                {SearchDataset.Group ? (
                                    SearchDataset.Group.map(
                                        (data: ISearchCatalog, index: number) => {
                                            return (
                                                <ProductComponent
                                                    key={index}
                                                    breadComes={data.breadComes}
                                                    levelsIds={data.levelsIds}
                                                />
                                            );
                                        }
                                    )
                                ) : (
                                    <LoadingAtaskate />
                                )}
                                {SearchDataset.errors && (
                                    <ContainerFlex Justify="start" Height="40px" Padding="8px">
                                        <Text FontSize="1rem" Color="#2A2C2F">
                                            {ARTICLE_SEARCH_TITLES.ERROR}
                                        </Text>
                                    </ContainerFlex>
                                )}
                                <ContainerFlex ref={targetRef} />
                            </>
                        )}
                    </ContainerFlex>
                </SearchInput>
            </ClickAwayListener>
        </ContainerFlex>
    );
};
