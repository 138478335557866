import React, {SyntheticEvent, useRef, useState} from 'react';

import {ClickAwayListener, Grow, Paper, Popper} from '@mui/material';
import styled from '@emotion/styled';
import {Image} from '@Shopify/Ecommerce/styles';
import {IActionMenuProps} from '@components/LocationsBranch/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import MoreMenuIcon from '@images/moreMenu.svg';
import {STORAGE_MENU_OPTIONS} from '@components/LocationsBranch/constants';

const GrowContainer = styled(Grow)`
    transform-origin: left top;
    border-radius: 0.5rem;
    border: 1px solid #f3f3f3;
`;

export const ActionMenu = ({warehouse, onEdit, onDuplicate, onDelete}: IActionMenuProps) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: Event | SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpen(false);
    };

    const buttonId = `basic-button-${warehouse.id}`;
    const menuId = `storage-menu${warehouse.id}`;

    const menuOptions = STORAGE_MENU_OPTIONS({
        onEdit: onEdit,
        onDuplicate: onDuplicate,
        onDelete: onDelete,
    });

    return (
        <>
            <div
                ref={anchorRef}
                id={buttonId}
                aria-controls={open ? menuId : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Image
                    src={MoreMenuIcon}
                    alt="add"
                    Width="0.25rem"
                    Height="1rem"
                    Margin="0.125rem 0 0 1.125rem"
                />
            </div>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({TransitionProps}) => (
                    <GrowContainer {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <ContainerFlex
                                    Justify="start"
                                    Align="start"
                                    Padding="0.625rem 0.5rem 0.625rem 0.5rem"
                                    Width="12.5rem"
                                    FlexDir="column"
                                >
                                    {menuOptions.map((option, index) => (
                                        <ContainerFlex
                                            Justify="start"
                                            Align="start"
                                            Gap="0.5rem"
                                            Padding="0.5rem"
                                            key={index}
                                        >
                                            <Image
                                                src={option.icon}
                                                alt="edit-storage"
                                                Width="1.125rem"
                                                Height="1.125rem"
                                                Margin="0 0 0.313rem"
                                            />
                                            <Text
                                                Color={option.textColor}
                                                Cursor="pointer"
                                                FontSize="1rem"
                                                onClick={(event) => {
                                                    handleClose(event);
                                                    option.onClick(warehouse);
                                                }}
                                            >
                                                {option.name}
                                            </Text>
                                        </ContainerFlex>
                                    ))}
                                </ContainerFlex>
                            </ClickAwayListener>
                        </Paper>
                    </GrowContainer>
                )}
            </Popper>
        </>
    );
};
