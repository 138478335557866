import axios from 'axios';
import {
    GET_ALL_ROLES_USER_REQUEST,
    GET_ALL_ROLES_USER_ROLES_SOUCCES,
    GET_ALL_ROLES_ROLES_USER_RROR,
    OPEN_ROLE_MODAL,
    CLOSE_ROLE_MODAL,
} from '@components/Users/UserProfile/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';
export const RoleResquest = () => {
    return {
        type: GET_ALL_ROLES_USER_REQUEST,
    };
};
export const RoleSuccess = (userData) => {
    return {
        type: GET_ALL_ROLES_USER_ROLES_SOUCCES,
        payload: userData,
    };
};
export const RoleFailure = (err) => {
    return {
        type: GET_ALL_ROLES_ROLES_USER_RROR,
        payload: err,
    };
};
export const openRoleModal = (role) => ({
    type: OPEN_ROLE_MODAL,
    payload: role,
});
export const closeRoleModal = () => ({
    type: CLOSE_ROLE_MODAL,
});
export const allRoleOption = (token, roleId, roleData) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(RoleResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetModulesByRol}`,
                {
                    headers,
                    params: {roleId},
                }
            );
            dispatch(RoleSuccess(response.data));
            dispatch(openRoleModal(roleData));
        } catch (error) {
            dispatch(RoleFailure(error));
        }
    };
};
