import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image, ContainerCellGrid} from '@/components/Shopify/Ecommerce/styles';
import {FOCUSED_COLORS, GENERAL_TITLES} from '@components/Users/UserProfile/constants';
import {ResentAccess} from '@components/Users/UserProfile/RecentAccess';
import {TaskTable} from '@components/Users/UserProfile/TaskTable';
import Delete from '@/images/delete _trash_red.svg';
import {UserData} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {PurpleSwitchBlue} from '@/components/Users/UserProfile/SwichPurplue';
import {OnStatusActive} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/OnStatusUserAction';
import {OfStatus} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/OfStatusUserAction';
import {DeleteStatuUser} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/DeleteUserAction';
import {EnhancedText} from '@/components/Users/UserProfile/UserProfileBranchAssignment/TextEdit';
import {profileUser} from '@/components/MyCompanyUser/Redux/Actions/UserProfileAction';
import {useNavigate} from 'react-router-dom';

export const GeneralInformation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const profileUserdata = useSelector(
        (state: RootState) => state.UserProfileAdmin.userData?.data as UserData | undefined
    );
    useEffect(() => {
        if (!profileUserdata?.employeeInfo) {
            navigate('/Users');
        }
    }, [profileUserdata, navigate]);

    if (!profileUserdata?.employeeInfo) return null;
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.UserProfileAdmin.selectedEmployId);
    const {employeeInfo} = profileUserdata as UserData;
    const {generatedRevenue, generatedPawnRevenue, isActive} = employeeInfo;
    const [isSwitchOn, setIsSwitchOn] = useState(isActive);

    const handleStatusChange = async () => {
        if (!token || !employeeId) return;
        const newStatus = !isSwitchOn;
        try {
            const statusAction = newStatus
                ? OnStatusActive(token, employeeId)
                : OfStatus(token, employeeId);
            await dispatch(statusAction);
            await dispatch(profileUser(token, employeeId, navigate, false));
            setIsSwitchOn(newStatus);
        } catch (error) {
            return error;
        }
    };

    const handleDeleteClick = () => {
        dispatch(DeleteStatuUser(token, employeeId));
    };

    return (
        <ContainerFlex FlexDir="column" Gap="16px" Justify="start" Align="start">
            <ContainerFlex
                FlexDir="column"
                Padding="24px"
                Radius="24px"
                Border={FOCUSED_COLORS.BORDER_LEFT}
            >
                <ContainerFlex Justify="start" Height="" PaddingB="16px" Bb="1px solid  #E8E9EA">
                    <Text Color="#2A2C2F" FontSize="1.25rem" FontWeight="600">
                        {GENERAL_TITLES.SUMMARY}
                    </Text>
                </ContainerFlex>
                <ContainerFlex>
                    <ContainerFlex Width="50%" FlexDir="column" Gap="8px">
                        <Text FontSize="1.25rem" FontWeight="700">
                            {generatedPawnRevenue}
                        </Text>
                        <Text>{GENERAL_TITLES.PAWN}</Text>
                    </ContainerFlex>

                    <ContainerFlex
                        Width="50%"
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                        Bl={FOCUSED_COLORS.BORDER_LEFT}
                    >
                        <Text FontSize="1.25rem" FontWeight="700">
                            {GENERAL_TITLES.VALUE_TAR}
                        </Text>
                        <Text>{GENERAL_TITLES.PENDING}</Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Width="50%"
                        Padding="16px"
                        FlexDir="column"
                        Gap="8px"
                        Bl={FOCUSED_COLORS.BORDER_LEFT}
                    >
                        <Text FontSize="1.25rem" FontWeight="700">
                            {GENERAL_TITLES.CASH_SYMBOL} {generatedRevenue}
                        </Text>
                        <Text>{GENERAL_TITLES.IN}</Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <TaskTable />
            <ResentAccess />

            <ContainerFlex
                Radius="24px"
                Border={FOCUSED_COLORS.BORDER_LEFT}
                Justify="start"
                Align="startt"
                Padding="24px"
                FlexDir="column"
                Gap="16px"
                ColumnGap="8px"
            >
                <ContainerFlex Justify="space-between">
                    <Text FontSize="1.25rem" FontWeight="700">
                        {GENERAL_TITLES.USER}
                    </Text>
                    <ContainerFlex Justify="end" Width="30%" Gap="8px">
                        <Text FontSize="1rem" FontWeight="700">
                            {GENERAL_TITLES.USER_ACTIVE}
                        </Text>
                        <PurpleSwitchBlue checked={isSwitchOn} onChange={handleStatusChange} />
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex Justify="start" Align="start" ColumnGap="8px">
                    <Text Width="70%" FontWeight="400" wSpace="">
                        {GENERAL_TITLES.DESCATIVATE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Radius="24px"
                Border={FOCUSED_COLORS.BORDER_LEFT}
                Justify="start"
                Align="startt"
                Padding="24px"
                FlexDir="column"
                Gap="16px"
            >
                <ContainerFlex Width="30%" Gap="8px" Justify="start">
                    <Image
                        onClick={handleDeleteClick}
                        Cursor="pointer"
                        alt="delete"
                        src={Delete}
                        Height="24px"
                    />
                    <Text
                        onClick={handleDeleteClick}
                        Cursor="pointer"
                        FontSize="1rem"
                        FontWeight="700"
                        Color="#A82424"
                    >
                        {GENERAL_TITLES.DELETE}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Justify="space-between" Gap="4px">
                    <ContainerCellGrid Gap="4px">
                        <EnhancedText
                            text={GENERAL_TITLES.ACCOUNT_DESCRIPTION}
                            boldParts={['plazo de 30 días para revertir la acción']}
                            Width="90%"
                            FontWeight="400"
                            wSpace=""
                            FontFamily="Nunito"
                        />
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
