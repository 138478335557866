import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_PRODUCT_PREVIEW} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IEditingButtonsFormProps} from '@components/PersonalLoans/NewLoanProduct/ui/interfaces';

export const EditingButtonsForm: React.FC<IEditingButtonsFormProps> = ({onCancel, onContinue}) => {
    return (
        <ContainerFlex Gap="1rem" Justify="end">
            <ButtonGenerals
                SecondaryButton="#FFFFFF"
                Width="101px"
                onClick={onCancel}
                type="button"
            >
                {LOAN_PRODUCT_PREVIEW.BTN_CANCEL}
            </ButtonGenerals>
            <ButtonGenerals onClick={onContinue} type="submit">
                {LOAN_PRODUCT_PREVIEW.BTN_CONTINUE}
            </ButtonGenerals>
        </ContainerFlex>
    );
};
