import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {RootState} from '@/config/store';
import {header, newRole} from '@components/RolesPermissions/NewRole/constants';
import {IModulesProps, IRoleModules} from '@components/RolesPermissions/NewRole/interfaces';
import Modules from '@components/RolesPermissions/NewRole/Modules';
import GetAllModulesAction from '@components/RolesPermissions/Redux/Actions/GetAllModulesAction';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import adminIcon from '@images/Ellipse 975.svg';
import posIcon from '@images/Ellipse 976.svg';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

const ModuleSwitch = ({isEdit, setListData, listData, selectedRole}: IModulesProps) => {
    const dispatch = useDispatch();
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [isOperative, setisOperative] = useState<boolean>(false);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const modules: {permissionName: string; modules: IRoleModules[]}[] = useSelector(
        (state: RootState) => state.GetAllModules.modules
    );

    useEffect(() => {
        if (token) dispatch(GetAllModulesAction(3, token));
    }, []);

    useEffect(() => {
        if (modules) {
            if (isEdit) {
                if (selectedRole.modules.adminModules.length > 0) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
                if (selectedRole.modules.operationalModules.length > 0) {
                    setisOperative(true);
                } else {
                    setisOperative(false);
                }
            }
        }
    }, [selectedRole]);

    useEffect(() => {
        if (!isAdmin) {
            setListData((prev) => ({...prev, adminModules: []}));
        }

        if (!isOperative) {
            setListData((prev) => ({...prev, operationalModules: []}));
        }
    }, [isAdmin, isOperative]);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="white"
            Border="1px solid #E8E9EA"
            Radius="16px"
            Padding="20px"
            Gap="10px"
            Height=""
            id={header.permissions}
        >
            <Text FontWeight="700" FontSize="1.2rem" MinH="2rem">
                {newRole.title}
            </Text>
            <Text MinH="2rem">{newRole.subTitle}</Text>
            <ContainerFlex Justify="space-between" Height="5rem">
                <ContainerFlex Gap="10px" Justify="start">
                    <Image src={adminIcon} alt="admin" />
                    <Text FontWeight="700">{newRole.admin}</Text>
                </ContainerFlex>
                <GreenSwitch onChange={() => setIsAdmin(!isAdmin)} checked={isAdmin} />
            </ContainerFlex>
            {modules.length > 0 && isAdmin && (
                <Modules
                    data={modules[0].modules}
                    isAdmin={true}
                    isEdit={isEdit}
                    listData={listData}
                    setListData={setListData}
                    setIsAdmin={setIsAdmin}
                    selectedRole={selectedRole}
                />
            )}
            <ContainerFlex Justify="space-between" Height="5rem">
                <ContainerFlex Gap="10px" Justify="start">
                    <Image src={posIcon} alt="operation" />
                    <Text FontWeight="700">{newRole.operative}</Text>
                </ContainerFlex>
                <GreenSwitch onChange={() => setisOperative(!isOperative)} checked={isOperative} />
            </ContainerFlex>
            {modules.length > 0 && isOperative && (
                <Modules
                    data={modules[1].modules}
                    isAdmin={false}
                    isEdit={isEdit}
                    listData={listData}
                    setListData={setListData}
                    setIsAdmin={setIsAdmin}
                    selectedRole={selectedRole}
                />
            )}
        </ContainerFlex>
    );
};

export default ModuleSwitch;
