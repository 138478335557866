import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import errorIcon from '@images/CloseIconRed.svg';
import * as content from '@components/PersonalLoans/styles';
import {AdditionalDocumentErrorMessageProps} from '@components/PersonalLoans/interfaces';

const AdditionalDocumentErrorMessage: React.FC<AdditionalDocumentErrorMessageProps> = ({
    message,
}) => (
    <ContainerFlex Justify="start" Align="start" Gap="0.25rem">
        <Image alt="icon-error" Height="1rem" Width="1rem" src={errorIcon} />
        <Text {...content.ImageError}>{message}</Text>
    </ContainerFlex>
);

export default AdditionalDocumentErrorMessage;
