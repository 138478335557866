import {GET_SCREEN, GET_ALL_FORM, GET_ALL_FORM_ADDRESS} from '@components/Users/Redux/types';

import {IContactValues} from '@components/Users/UserProfile/yupSchema';
import {IPersonalInfo} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';

export const getScreenUsers = (data: number) => {
    return {
        type: GET_SCREEN,
        payload: data,
    };
};

export const getAllFormData = (data: IPersonalInfo) => {
    return {
        type: GET_ALL_FORM,
        form: data,
    };
};

export const getAllFormDataAddress = (data: IContactValues) => {
    return {
        type: GET_ALL_FORM_ADDRESS,
        formAddress: data,
    };
};
