import {IRoleItems} from '@components/RolesPermissions/interfaces';
import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_ROLES,
    GET_ROLES_ERROR,
    GET_ROLES_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';

export interface IGetRoles {
    loading: boolean;
    error: boolean;
    roles: IRoleItems[];
}

const initialState: IGetRoles = {
    loading: false,
    error: false,
    roles: [
        {
            id: 0,
            name: '',
            status: false,
            description: '',
            companyId: 0,
            quantityUsers: 0,
            isSuggested: false,
            typeRoleId: 0,
            typeRoleName: '',
            users: '',
            typeRol: {
                id: 0,
                type: '',
                status: false,
            },
            canBeDeleted: false,
        },
    ],
};

const GetRolesReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
): IGetRoles => {
    switch (action.type) {
        case GET_ROLES:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                roles: action.payload.data.data,
            };
        case GET_ROLES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default GetRolesReducer;
