import styled from '@emotion/styled';
import {IPropsBaseBox, IPropsImageLogo, IPropsStepContainer} from '@LoginClient/interfacesStyles';

export const ImageLogo = styled.img<IPropsImageLogo>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    margin: ${(props) => props.Margin};
    transform: ${(props) => props.Transform};
    display: ${(props) => props.Display};
    position: ${(props) => props.Position};
    right: ${(props) => props.Right};
    z-index: ${(props) => props.ZIndex};
    clip-path: ${(props) => props.ClipPath};
    position: ${(props) => props.Position};
    right: ${(props) => props.Right};
    top: ${(props) => props.Top};
    bottom: ${(props) => props.Bottom};
    left: ${(props) => props.Left};
    border-radius: ${(props) => props.Radius};
    cursor: ${(props) => props.Cursor};
`;
ImageLogo.defaultProps = {
    Height: '132px',
    Width: '300px',
};
export const ContainerProgress = styled.div`
    height: 2px;
    width: 50%;
    position: relative;
    z-index: -1;
`;

export const ContainerCondiciones = styled.div`
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 0 19px 0;
    width: 100%;
    height: 100%;
`;
export const BaseBox = styled.div`
    height: 50%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 3px;
    transition: width 10s ease-in-out;
    z-index: -1;
`;

export const BackgroundBar = styled(BaseBox)`
    background: #cbced1;
    z-index: -1;
    width: 100%;
`;

export const ProgressPercent = styled(BaseBox)<IPropsBaseBox>`
    background: #7ac143;
    z-index: -1;
    width: ${({percent}) => percent}%;
`;
export const StepContainer = styled.div<IPropsStepContainer>`
    width: 24px;
    height: 24px;
    background-color: ${(props) => props.BackG};
    box-shadow: ${(props) => props.BS};
    border: ${(props) => props.Border};
    border-radius: 100px;
    margin: ${(props) => props.Margin};
    text-align: center;
    justify-content: center;
    align-items: center;
    span {
        color: ${(props) => props.color};
    }
`;
StepContainer.defaultProps = {
    BackG: '#FFFFFF',
    BS: '0px 3px 6px #CBCED180',
    Border: '1px solid #CBCED1',
};
