import React from 'react';
import {LoadingContainer, Loading} from '@Atoms/LoadingAtaskate/styles';
import {Text} from '@Shopify/Ecommerce/styles';
import {IPropsLoading} from '@Atoms/LoadingAtaskate/interfaces';

export const LoadingAtaskate = ({width, padding, promptText, textWidth}: IPropsLoading) => {
    return (
        <LoadingContainer>
            <Loading width={width} padding={padding} />
            {promptText && (
                <Text
                    FontSize="1.5rem"
                    Color="#2A2C2F"
                    FontWeight="500"
                    MarginTop="10px"
                    wSpace="normal"
                    TextAlign="center"
                    MaxW={textWidth}
                >
                    {promptText}
                </Text>
            )}
        </LoadingContainer>
    );
};
