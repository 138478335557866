import {useCallback, useState, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {resetGroupTypeSelection} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';
import {GetCompanyLevelsByCompanyId} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import {setSelectedOptionList} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';

const useLoadLevels = (type: string, companyId: number, token: string) => {
    const dispatch = useDispatch();
    const [groupsFilling, setGroupsFilling] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const groupTypes = useSelector((state: RootState) => state.levelGroups.groupTypes);
    const currentSelection = useSelector((state: RootState) => state.levelGroups.groupTypes).find(
        (groups) => groups.groupType === type
    )?.selectedLevel.breadcrum;
    const {selectedLevelId} = useSelector((state: RootState) => ({
        selectedLevelId: state.getLevels.selectedLevelId,
    }));

    const levelGroups = groupTypes.find((groups) => groups.groupType === type)?.levelGroups || [];
    const levels = useSelector((state: RootState) => state.getLevels.level);

    useEffect(() => {
        if (companyId && token && !currentSelection && levels) {
            loadLevelGroup(1, 0);
        }
    }, [companyId, token, selectedLevelId]);

    const loadLevelGroup = useCallback(
        async (group: number, levelId: number) => {
            if (token && companyId) {
                const filters = {
                    inheritType: type,
                    groupNumber: 1,
                    fillLevelsOnly: true,
                };
                await dispatch(GetCompanyLevelsByCompanyId(companyId, token, levelId, filters));
            }
        },
        [dispatch, companyId, token]
    );

    const handleGroupSelect = useCallback(
        async (group: number, selectedLevelId: number) => {
            if (selectedLevelId !== 0 || group === 1) {
                const internalRowId = levelGroups.find((level) => level.group === group)
                    ?.selectedLevelId;
                await dispatch(
                    GetCompanyLevelsByCompanyId(
                        companyId,
                        token,
                        selectedLevelId,
                        {inheritType: type, groupNumber: group, reloadingSelection: true},
                        group,
                        internalRowId
                    )
                );
            }
        },
        [dispatch, loadLevelGroup, levelGroups.length]
    );

    const handleGroupsSelection = useCallback(
        async (item: LevelSelection) => {
            try {
                setGroupsFilling(true);
                item.ids.reduce((promise, id, index) => {
                    const groupNumber = levelGroups[index]?.group || index + 1;
                    return promise.then(() => handleGroupSelect(groupNumber, id));
                }, Promise.resolve());
                if (currentSelection?.branch) {
                    dispatch(setSelectedOptionList(currentSelection.branch));
                }
            } catch (error) {
                setErrorMessage('Ocurrió un error al seleccionar niveles.');
            }
            setGroupsFilling(false);
        },
        [handleGroupSelect]
    );

    const getGroupOptions = useCallback(
        (group: number) => {
            const currentGroupOptions = levelGroups.find(
                (levelGroup) => levelGroup.group === group
            );
            return currentGroupOptions ? currentGroupOptions.levels : [];
        },
        [levelGroups]
    );

    const getSelectedValue = useCallback(
        (group: number) => {
            const currentGroup = levelGroups.find((levelGroup) => levelGroup.group === group);
            return currentGroup ? currentGroup.selectedLevelId : 0;
        },
        [levelGroups]
    );

    const handleResetGroup = useCallback(async () => {
        dispatch(resetGroupTypeSelection(type));
    }, [dispatch, type]);

    return useMemo(
        () => ({
            groupsFilling,
            levelGroups,
            currentSelection,
            handleGroupSelect,
            getGroupOptions,
            getSelectedValue,
            handleGroupsSelection,
            handleResetGroup,
            errorMessage,
        }),
        [
            groupsFilling,
            levelGroups,
            currentSelection,
            handleGroupSelect,
            getGroupOptions,
            getSelectedValue,
            handleGroupsSelection,
            handleResetGroup,
            errorMessage,
        ]
    );
};

export default useLoadLevels;
