import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_MUNICIPALITY,
    GET_MUNICIPALITY_ERROR,
    GET_MUNICIPALITY_SUCCEED,
} from '@components/NewUser/Redux/Types';

const initialState = {
    municipalities: null,
    loading: false,
    error: false,
};

const getMunicipalityReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_MUNICIPALITY:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_MUNICIPALITY_SUCCEED:
            return {
                ...state,
                municipalities: action.payload.data.data,
                loading: false,
            };
        case GET_MUNICIPALITY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
                errorData: action.error,
            };
        default:
            return state;
    }
};

export default getMunicipalityReducer;
