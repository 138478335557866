import React, {useState} from 'react';

import LocationIcon from '@images/LocationIcon.svg';
import SelectGeneral from '@General/Atoms/Select/Select';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';

export const LocationQuoter = () => {
    const [showLocation, setShowLocation] = useState(false);

    return (
        <ContainerFlex
            Gap="1.5rem"
            FlexDir="column"
            Justify="start"
            Align={showLocation ? 'start' : 'center'}
        >
            {!showLocation && (
                <FlexPanel
                    Justify="center"
                    backG="#5A5AFF"
                    Radius="2rem"
                    Padding="0.5rem 1rem"
                    onClick={() => setShowLocation(true)}
                >
                    <Image src={LocationIcon} Filter="brightness(0) invert(1)" />
                    <Text Padding="0 0 0 0.5rem" FontWeight="700" Color="#FFFFFF" Cursor="pointer">
                        {SHOPPING_CART.LOCATION}
                    </Text>
                </FlexPanel>
            )}
            {showLocation && (
                <>
                    <Text Cursor="pointer" FontWeight="700">
                        {SHOPPING_CART.SELECT_LOCATION}
                    </Text>
                    <FlexPanel Justify="space-between" Width="100%" Gap="0.5rem">
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Cursor="pointer">{SHOPPING_CART.SELECT_LOCATION}</Text>
                            <SelectGeneral
                                placeholder={SHOPPING_CART.PLACEHOLDER_SELECT}
                                isSearchable={false}
                                options={[{value: 1, label: 'Todas'}]}
                                noOptionsMessage={() => 'Sin opciones'}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Cursor="pointer">{SHOPPING_CART.ROW}</Text>
                            <SelectGeneral
                                placeholder={SHOPPING_CART.PLACEHOLDER_SELECT}
                                isSearchable={false}
                                options={[{value: 1, label: 'Todas'}]}
                                noOptionsMessage={() => 'Sin opciones'}
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                            <Text Cursor="pointer">{SHOPPING_CART.COLUMN}</Text>
                            <SelectGeneral
                                placeholder={SHOPPING_CART.PLACEHOLDER_SELECT}
                                isSearchable={false}
                                options={[{value: 1, label: 'Todas'}]}
                                noOptionsMessage={() => 'Sin opciones'}
                            />
                        </ContainerFlex>
                    </FlexPanel>
                </>
            )}
        </ContainerFlex>
    );
};
