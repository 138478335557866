import {
    GET_ROL_REQUEST,
    GET_ROL_SOUCCES,
    GET_ROL_ERROR,
} from '@components/Users/UserProfile/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const getRoleUser = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROL_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_ROL_SOUCCES:
            return {
                ...state,
                loading: false,
                userData: action.payload?.data || null,
                error: null,
            };
        case GET_ROL_ERROR:
            return {
                ...state,
                loading: false,
                userData: null,
                error: action.payload?.data?.data || 'error',
            };
        default:
            return state;
    }
};
