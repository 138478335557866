import axios, {AxiosResponse, AxiosError} from 'axios';
import {
    GET_FREQUENCY_OPTIONS_ERROR,
    GET_FREQUENCY_OPTIONS_SUCCESS,
    GET_FREQUENCY_OPTIONS_START,
} from '@/components/GlobalQuoter/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@/config/constants';

const getFrequencyStart = () => {
    return {
        type: GET_FREQUENCY_OPTIONS_START,
    };
};

const getFrequencySuccess = (payload: AxiosResponse) => {
    return {
        type: GET_FREQUENCY_OPTIONS_SUCCESS,
        payload,
    };
};

const getFrequencyError = (error: AxiosError) => {
    return {
        type: GET_FREQUENCY_OPTIONS_ERROR,
        error,
    };
};

export const getFrequencyOptions = (token: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(getFrequencyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllCatFrequency}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(getFrequencySuccess(response));
        } catch (error) {
            dispatch(getFrequencyError(error as AxiosError));
        }
    };
};
