import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@config/store';

import {SearchQuoter} from '@Quoter/Search';
import {ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {ProgressBar} from '@components/SignUp/ProgressBar';
import {PROGRESSITEMS, QUOTER, SEARCHVALUES} from '@Quoter/constants';
import {StepOne} from '@Quoter/Steps/StepOne/StepOne';
import {StadisticsProduct} from '@Quoter/Steps/StepOne/StadisticsProduct';
import {ArticleRequest} from '@Quoter/Search/ArticleRequest';
import {SuccessMessageRequestArticle} from '@Quoter/Search/ArticleRequestInputs/SuccessMessage';
import {FieldValues, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DinamicInputSchema} from '@Quoter/Redux/yupValidations';
import {postTemporalPawn} from '@ActionsQuoter/CreateTemporalyPawnStep1';
import {PromotionsStep} from '@Quoter/PromotionsStep';
import {getShoppingCartContracts} from '@components/ShoppingCart/Redux/Actions/getCartContractsActions';
import {IResetData} from '@components/Quoter/inferfaces';
import {CoownerBeneficiary} from '@/components/Quoter/CoownerBeneficiary/CoownerBeneficiary';
import {EditCoOwnerBenefist} from '@Quoter/CoownerBeneficiary/EditCoOwnerBenefist';
import {AddCoOwnerBenefist} from '@Quoter/CoownerBeneficiary/AddCoOwnerBenefist';
import {resetInputDinamics} from '@Quoter/Redux/Actions/PropertiesArticle';
import {OverResetAll, restarQuoter} from '@Quoter/Redux/Actions/saveEndeavors';
import {ResetStadistics, States} from '@Quoter/Redux/Actions/StatusStepOne';
import {resetEditQuoter} from '@components/ShoppingCart/Redux/Actions/getInfoContractActions';
import useToast from '@/hooks/useToast';
import {setIsCartOpen} from '@/components/ShoppingCart/Redux/Actions/CartState';

export const Quoter = () => {
    const {showToast, ToastContainer} = useToast();
    const [salePriceValue, setSalePriceValue] = useState<string>('');
    const [onSubmitQuoter, setOnSubmitQuoter] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showAdd, setShowAdd] = useState(false);
    const saveEndeavor = useSelector((state: RootState) => state.saveEndeavor);
    const {inputDinamics} = useSelector((state: RootState) => state.saveEndeavor);
    const [stadistic, setStadistic] = useState<FieldValues>({loan: 0, value: 0, state: {}});

    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    const blockQuote =
        (cartData?.endorsements && cartData?.endorsements.length > 0) ||
        (cartData?.discharges && cartData?.discharges.length > 0);
    const [view, setView] = useState(false);
    const [successMessage, setSuccesMesssage] = useState(false);

    const branch = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails
    );
    const {propertiesarticles} = useSelector((state: RootState) => state.propertiesArticle);

    const dinamic: FieldValues = {};
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );

    const handleSalePrice = (salePriceValue: string) => {
        setSalePriceValue(salePriceValue);
    };

    const branchId = branch ? branch[0].branchId : 2;
    useEffect(() => {
        if (successMessage)
            setTimeout(() => {
                setSuccesMesssage(false);
            }, 3000);
    }, [successMessage]);
    const {
        register,
        reset,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: {errors, isValid},
    } = useForm<FieldValues>({
        defaultValues: {
            Group: '',
            Family: '',
            Subfamily: '',
            Brand: '',
            Article: '',
            Note: '',
            value: '',
            loan: '',
            Status: '',
        },
        resolver: yupResolver(DinamicInputSchema(dinamic, propertiesarticles)),
    });
    const dispatch = useDispatch();
    const priceRegex = /\d+(\.\d+)?/;
    const match = salePriceValue.match(priceRegex);
    const numberString = match ? match[0] : '';
    const resetData = propertiesarticles.reduce<IResetData>((acc, item) => {
        acc[item.articleDetailId] = '';
        return acc;
    }, {});

    const selectsDinamics = propertiesarticles.filter((obj) => obj.propertyValueList !== null);
    const articleIds = selectsDinamics.map((obj) => obj.articleDetailId);

    const setValueEmpty = () => {
        propertiesarticles.forEach((obj) => {
            const articleIdAsString = String(obj.articleDetailId);
            setValue(articleIdAsString, '');
        });
    };

    useEffect(() => {
        articleIds.forEach((articleId) => {
            setValue(String(articleId), {label: ''});
        });
        setValueEmpty();
    }, [onSubmitQuoter]);

    const onSubmit = async (values: FieldValues) => {
        setOnSubmitQuoter(true);
        const dinamicsToSend = propertiesarticles.map((item) => {
            const detailValue = values[item.articleDetailId]?.label || values[item.articleDetailId];
            return {
                articleDetailId: item.articleDetailId,
                detailValue: String(detailValue),
            };
        });

        const loanValue = parseInt(values.loan.replace(/\D/g, ''), 10);
        const valueNumber = parseInt(values.value.replace(/\D/g, ''), 10);

        const SendCart = {
            carShoppingId: carShoppingId,
            branchId: branchId,
            artticleId: Number(values.Article.value),
            notes: values.Note,
            stateConservationId: Number(values.Status.value),
            salePrice: Number(numberString),
            loan: loanValue,
            value: valueNumber,
            dinamics: dinamicsToSend,
        };

        const cartContracts = async () => {
            await dispatch(postTemporalPawn(token, SendCart));
            await dispatch(getShoppingCartContracts(token, {carshopingId: carShoppingId}));
        };

        await cartContracts();
        reset();
        reset(resetData);
        reset(inputDinamics);
        setValueEmpty();
        dispatch(OverResetAll());
        dispatch(ResetStadistics());
        dispatch(resetInputDinamics());
        restarQuoter();
        reset(SEARCHVALUES);
        dispatch(resetEditQuoter());
        dispatch(States(token));
        setStadistic({loan: 0, value: 0, state: {}});
        dispatch(setIsCartOpen(true));
    };

    useEffect(() => {
        if (blockQuote) {
            showToast({
                title: QUOTER.NOTICE,
                message: QUOTER.QUOTER_NOT_AVAILABLE,
                type: 'warning',
                duration: 9000,
            });
        }
    }, [blockQuote]);

    return (
        <>
            {view && <ArticleRequest setView={setView} setSuccesMesssage={setSuccesMesssage} />}
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                FlexDir="column"
                Padding="1rem 1.5rem"
                Gap="1rem"
                Align="start"
                Height="auto"
                Justify="start"
            >
                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500" LHeight="auto">
                    {QUOTER.TITLE}
                </Text>
                {successMessage && (
                    <SuccessMessageRequestArticle setSuccesMesssage={setSuccesMesssage} />
                )}
                <ProgressBar progressItems={PROGRESSITEMS} actualStep={saveEndeavor.actualStep} />
                {saveEndeavor.actualStep === 1 && (
                    <>
                        <SearchQuoter
                            branchId={branchId}
                            token={token}
                            register={register}
                            errors={errors}
                            control={control}
                            reset={reset}
                            watch={watch}
                            setView={setView}
                            onSubmitQuoter={onSubmitQuoter}
                        />
                        <StepOne
                            branchId={branchId}
                            token={token}
                            register={register}
                            errors={errors}
                            control={control}
                            reset={reset}
                            watch={watch}
                            setValue={setValue}
                        />
                        <StadisticsProduct
                            branchId={branchId}
                            token={token}
                            register={register}
                            errors={errors}
                            control={control}
                            reset={reset}
                            watch={watch}
                            salePrice={handleSalePrice}
                            setValue={setValue}
                            isValid={isValid}
                            setValueEmpty={setValueEmpty}
                            ResetData={resetData}
                            inputDinamics={inputDinamics}
                            stadistic={stadistic}
                            setStadistic={setStadistic}
                        />
                    </>
                )}
                {saveEndeavor.actualStep === 2 && <PromotionsStep />}
                {saveEndeavor.actualStep === 3 && (
                    <CoownerBeneficiary setShowEdit={setShowEdit} setShowAdd={setShowAdd} />
                )}
            </ContainerForm>
            {showEdit && <EditCoOwnerBenefist showEdit={showEdit} setShowEdit={setShowEdit} />}
            {showAdd && <AddCoOwnerBenefist showAdd={showAdd} setShowAdd={setShowAdd} />}
            <ToastContainer />
        </>
    );
};
