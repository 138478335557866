import {FETCH_CART_BY_ID} from '@components/ShoppingCart/Redux/types';
import {IShoppingCartData, IGetCartByCartIdRedux} from '@components/ShoppingCart/Redux/interfaces';

const initialState: IShoppingCartData = {
    loading: false,
    error: false,
    refresh: false,
    cartData: null,
    fetched: false,
};

const getCartByCartId = (
    state = initialState,
    action: IGetCartByCartIdRedux
): IShoppingCartData => {
    switch (action.type) {
        case FETCH_CART_BY_ID.FETCH_CART_START:
            return {
                ...state,
                loading: true,
            };
        case FETCH_CART_BY_ID.FETCH_CART_SUCCESS:
            return {
                ...state,
                loading: false,
                cartData: action.payload,
                refresh: false,
                fetched: true,
            };
        case FETCH_CART_BY_ID.FETCH_CART_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                refresh: false,
            };
        case FETCH_CART_BY_ID.SET_REFRESH:
            return {
                ...state,
                refresh: true,
            };
        default:
            return state;
    }
};
export default getCartByCartId;
