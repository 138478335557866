import {ILevel} from '@Roles/AddRoles/interfaces';

export const TableList = {
    Roles: 'Roles',
    NewRoles: 'Nuevo rol',
    NoRoles: 'No se encontraron usuarios',
};
export const FilterRoles = {
    All: 'Todos',
    Administrative: 'Administrativos',
    POS: 'P.O.S',
    Admin: 'Admin',
    Pos: 'POS',
};

export const TableHeaderText = {
    Role: 'Rol',
    Type: 'Tipo',
    Users: 'Usuarios',
};

export const POPUPTEXTROLES = {
    TITLE: 'Asigna usuarios a este rol',
    HINT: 'Sugeridos',
    USERNAME: 'Nombre de usuario',
    ROL: 'Rol',
    E_MAIL: 'Email',
    ASSIGN: 'Asignar',
    REMOVE: 'Remover accesos',
    REMOVE_ACCESS_ROL: 'Remover accesos al rol',
    CANCEL: 'Cancelar',
    ACCESS: 'Accessos',
    SELECTED: 'Seleccionar uno o varios usuario para continuar',
    NO_USERS: 'No existen usuarios,',
    CREATE_USER: ' crea usuarios ',
    AND_ASSING_ROLE: 'primero y asigna su rol.',
    NO_ROLES: 'No hay roles disponibles en este momento.',
};

export const ASSINGROLE_TITLE = ['Nombre de usuario', 'Rol', 'Email'];

export const ROLES = {
    NEW: 'Nuevo',
    UPDATE: 'Editar',
    CREATE_ROLE: ' rol',
    TYPE_ROLE: {
        TYPE: 'Tipo de rol',
        ADMINISTRATIVE: 'Administrativo',
        POS: 'POS',
        NAME_ROL: 'Nombre de rol',
        ADMIN_ID: 1,
        POS_ID: 2,
        ADMINPOS: 3,
        BOTH: 0,
    },
    PERMISIONS_ROLE: {
        PERMISIONS: 'Permisos',
        MODULE: 'Módulo',
        ACCESS: 'Acceso al módulo',
        NOTIFY: 'Recibir alertas',
    },
    USER_ROL: {
        ASSIGMENT: 'Asigna usuarios a este rol',
        ASSIGMENTED_USERS: 'Usuarios asignados al rol',
        ASSIGNATED_EDIT: 'Descubre aquí a todos los usuarios con este rol asignado',
        SUGESTION: 'Sugeridos',
        SUGGESTION_ROLES: 'Usuarios sugeridos para asignar este rol',
        NAME_USER: 'Nombre de usuario',
        ROL: 'Rol',
        ACCESS: 'Accesos',
    },
};

export const createFormRol = {
    roleName: '',
    roleType: 0,
    modules: [
        {
            moduleId: 0,
            receiveAlerts: true,
        },
    ],
    userId: [],
};

export const ROLE_USER_ASSIGNMENT = {
    TITLE_ASSINGMENT: 'Cambios al rol',
    DESCRIPTION_ASSINGMENT:
        ' Al hacer esta acción, se realizarán los cambio al rol y esto afectará los siguientes usuarios',
    CANCEL_BUTTON: 'Cancelar',
    WITHOUT_USERS: 'No hay usuarios asignados a este rol',
};

export const USER_ASSIGNMENT_TITLE = ['Nombre de usuario', 'Email'];

export const styleValidate = (module: ILevel, operator: string) => {
    switch (operator) {
        case 'moreLevels':
            if (module.step > 0) {
                if (module.nextLevel.length > 0) return `0.3rem`;
                else return `1.8rem`;
            }
            if (module.step === 0 && module.nextLevel.length === 0) return `1.5rem`;
            break;
        case 'modules':
            if (module.step > 1) {
                if (module.nextLevel.length > 0) return `-1rem`;
                else return `-1.${module.step > 2 ? 5 : 0}rem`;
            }
            if (module.step === 1) {
                if (module.nextLevel.length > 0) return `-0.${module.step + 4}rem`;
                else return `-0.5rem`;
            }
            break;
        case 'alert':
            if (module.step > 1) {
                if (module.nextLevel.length > 0) return `-0.4rem`;
                else return `-0.${module.step > 2 ? 6 : 5}rem`;
            }
            if (module.step === 1) {
                if (module.nextLevel.length > 0) return `-0.${module.step + 2}rem`;
                return `-0.2rem`;
            }
            break;
        case 'header':
            if (module.step > 1) {
                if (module.nextLevel.length > 0) return `-${module.step + 3}rem`;
                else return `-${module.step + 2}rem`;
            }
            if (module.step === 1) {
                if (module.nextLevel.length > 0) return `-${module.step + 1}rem`;
                else return `-0.5rem`;
            }

            break;
        default:
            break;
    }
};
