import React from 'react';

import {formatterDolar} from '@/hooks/currentFormatUtils';
import {CART_MESSAGES} from '@Loan/PaymentQuoter/constants';
import {IArticlesExpand} from '@/components/Shopify/Products/redux/interface';
import {ContainerFlex, Text, Image, ContainerImg} from '@Shopify/Ecommerce/styles';

export const ArticlesExpand = ({
    allowDetailExpand,
    lastItem,
    isLastItem,
    articles,
}: IArticlesExpand) => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            ColumnGap="0.5rem"
            Gap="0.5rem"
            Padding="0 1rem"
            backG={!allowDetailExpand ? '#FAFAFA' : '#f5f4f4'}
            Bb={allowDetailExpand ? '1px solid #e3e3e3' : 'none'}
            Bl={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
            Br={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
            PaddingB="0.438rem"
            Radius={!allowDetailExpand && lastItem && isLastItem ? ' 0 0 0.5rem 0.5rem' : 'none'}
            Border={!allowDetailExpand ? '1px solid #e8e8e8' : 'none'}
        >
            <ContainerImg Radius="0.5rem" Border="1px solid #E8E9EA">
                <Image
                    src={articles.articlec}
                    alt="cart-item"
                    Height="2.625rem"
                    Width="2.625rem"
                    AspectRatio="1"
                    ObjectFit="contain"
                />
            </ContainerImg>
            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="0.5rem">
                <Text FontWeight="500" Color="#2A2C2F">
                    {articles.articleName}
                </Text>
                <Text FontWeight="400" Color="#54575C">
                    {CART_MESSAGES.VALUE}
                    <Text FontWeight="500" Color="#2A2C2F" Padding="0 0.5rem">
                        {formatterDolar.format(articles.value)}
                    </Text>
                </Text>
                <Text FontWeight="400" Color="#54575C">
                    {CART_MESSAGES.LOAN}
                    <Text FontWeight="500" Color="#2A2C2F" Padding="0 0.5rem">
                        {formatterDolar.format(articles.loan)}
                    </Text>
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
