import React, {useEffect} from 'react';
import {useForm, FieldValues} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {RootState} from '@/config/store';
import {routers} from '@/appRoute';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import PersonalLoansToAccept from '@/components/PersonalLoans/PersonalLoansToAccept';
import {ICreditLoanSummary} from '@/components/PersonalLoans/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import addWireTransferAction, {
    clearWireTransferState,
} from '@/components/PersonalLoans/Redux/Actions/AddWireTransferPersonalLoanActions';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaTransactionLoans} from '@/components/PersonalLoans/schemaPersonalLoans';
import {
    ContainerFlex,
    ContainerForm,
    ContainerGrid,
    Text,
} from '@/components/Shopify/Ecommerce/styles';

const PersonalLoanApprovedSummary: React.FC<ICreditLoanSummary> = ({
    requestedLoan,
    loanGranted,
    dues,
    annualInterest,
    monthlyPayments,
    totalAmount,
    token,
    creditId,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {data: wasCreditAccepted} = useSelector((state: RootState) => {
        return state.addWireTransfer;
    });
    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            terms: false,
        },
        resolver: yupResolver(schemaTransactionLoans),
    });

    useEffect(() => {
        if (wasCreditAccepted) {
            dispatch(clearWireTransferState());
            navigate(routers.MyAccount);
        }
    }, [wasCreditAccepted]);

    const onSubmit = () => {
        const addWireTransferData = {
            token,
            personalCreditId: Number(creditId),
        };
        dispatch(addWireTransferAction(addWireTransferData));
    };
    return (
        <ContainerForm
            Width="100%"
            Height="100%"
            Justify="space-between"
            Align="start"
            Padding="24px"
            Gap="24px"
            Radius="24px"
            Border="1px solid #0D166B"
            FlexDir="column"
            onSubmit={handleSubmit(onSubmit)}
        >
            <ContainerFlex Width="100%" Height="100%" Align="start" Justify="start">
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#1D1E20">
                    {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.SUBTITLE}
                </Text>
            </ContainerFlex>
            <ContainerGrid
                Width="100%"
                Height="100%"
                Align="start"
                Justify="start"
                Gap="16px"
                FlexDir="column"
                Padding="8px"
                GridColumns="repeat(2,1fr)"
            >
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.LOAN_REQUESTED}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#2A2C2F">
                        {numberToCurrencyWithoutCents(requestedLoan)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.LOAN_GRANTED}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#309C60">
                        {numberToCurrencyWithoutCents(loanGranted)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.FEES}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#2A2C2F">
                        {dues}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="start"
                    Align="start"
                    Gap="4px"
                    FlexDir="column"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {PERSONAL_LOAN_APPROVED.BODY.SUMMARY.ANUAL_INTEREST_RATE}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.rem" Color="#2A2C2F">
                        {`${annualInterest}%`}
                    </Text>
                </ContainerFlex>
            </ContainerGrid>
            <ContainerFlex Height="100%" Width="100%" FlexDir="column" Gap="24px">
                <PersonalLoansToAccept
                    dues={dues}
                    monthlyPayments={monthlyPayments}
                    totalAmount={totalAmount}
                    control={control}
                    errors={errors}
                />
            </ContainerFlex>
        </ContainerForm>
    );
};

export default PersonalLoanApprovedSummary;
