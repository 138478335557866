import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    OptionsMenu,
    SelectContainer,
    SelectField,
    Option,
} from '@/components/MyCompanyUser/Operational/style';
import {CustomSelectProps} from '@/components/CashFlowActions/Operational/interfaces';
import {BTN} from '@/components/CashFlowActions/Operational/Constants';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import arrow from '@images/arrowBottom.svg';
import {Text} from '@/components/Shopify/Ecommerce/styles';

export const CustomSelectOptions = ({options, value, onChange, disabled}: CustomSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null);
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    const ALL_OPTION = 'ALL';

    useEffect(() => {
        if (value === ALL_OPTION) {
            setSelectedOptions(
                options
                    .filter((option) => option.value !== ALL_OPTION)
                    .map((option) => option.value)
            );
        } else if (value) {
            setSelectedOptions([value]);
        } else {
            setSelectedOptions([]);
        }
    }, [value, options]);

    const handleSelect = useCallback(
        (optionValue: string) => {
            const isAllOption = optionValue === ALL_OPTION;

            if (isAllOption) {
                const isAllSelected = selectedOptions.length === options.length - 1;
                onChange(isAllSelected ? '' : ALL_OPTION);
                setSelectedOptions(
                    isAllSelected
                        ? []
                        : options
                              .filter((option) => option.value !== ALL_OPTION)
                              .map((option) => option.value)
                );
                setIsOpen(false);
                return;
            }

            const isSelected = selectedOptions.includes(optionValue);

            if (isSelected) {
                setSelectedOptions((prev) => prev.filter((val) => val !== optionValue));
                onChange('');
            } else {
                setSelectedOptions([optionValue]);
                onChange(optionValue);
            }
        },
        [onChange, options, selectedOptions]
    );

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const displayText =
        selectedOptions.length === options.length - 1
            ? options
                  .filter((option) => option.value !== ALL_OPTION)
                  .map((option) => option.label)
                  .join(', ')
            : selectedOptions
                  .map(
                      (optionValue) =>
                          options.find((option) => option.value === optionValue)?.label || ''
                  )
                  .join(', ') || <Text>{BTN.SELECT}</Text>;

    return (
        <SelectContainer ref={selectRef}>
            <SelectField onClick={() => !disabled && setIsOpen(!isOpen)} isOpen={isOpen}>
                <Text Color={selectedOptions.length > 0 ? '#000' : '#AAA'}>{displayText}</Text>
                <ImageLogo width="24px" Height="24px" src={arrow} alt="icone-arrow" />
            </SelectField>
            <OptionsMenu isOpen={isOpen}>
                {options.map((option) => (
                    <Option
                        key={option.value}
                        isSelected={selectedOptions.includes(option.value)}
                        onClick={() => handleSelect(option.value)}
                    >
                        <Text Cursor="pointer" PaddingL="4px">
                            {option.label}
                        </Text>
                    </Option>
                ))}
            </OptionsMenu>
        </SelectContainer>
    );
};
