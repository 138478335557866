import {useFormContext} from 'react-hook-form';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import informationIcon from '@components/PersonalLoans/NewLoanProduct/icons/informationIcon.svg';
import {
    frequencyTableHeaders,
    PAYMENT_FREQUENCY,
    VALIDATIONS_MSG,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Table, TableRowHead} from '@components/CashFlow/FundingInquiry/styles';
import {TableItemsContainer} from '@/components/Shopify/Endeavors/styles';
import React, {useEffect, useState} from 'react';
import {
    IGetFrequencyPaymentsParms,
    IPaymentFrequencyProps,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {getFrequencyPayments} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetFrequencyPaymentsActions';
import {HeaderRow} from '@components/PersonalLoans/NewLoanProduct/PaymentFrequency/HeaderRow';
import {FrequencyTermRow} from '@components/PersonalLoans/NewLoanProduct/PaymentFrequency/FrequencyTermRow';
import {setFrequencyPaymentsTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {WarningError} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/WarningError';

export const PaymentFrequency: React.FC<IPaymentFrequencyProps> = ({frequencyRef, isSubmitted}) => {
    const {
        control,
        watch,
        formState: {errors},
        trigger,
        resetField,
    } = useFormContext();
    const [headers, setHeaders] = useState(frequencyTableHeaders);
    const dispatch: AppDispatch = useDispatch();
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const handleToolTipVisibility = (id: number, isShowing: boolean) => {
        const newArray = headers.map((header) => {
            if (header.id === id) {
                return {
                    ...header,
                    isShowingToolTip: isShowing,
                };
            }
            return header;
        });
        setHeaders(newArray);
    };
    const formState = watch();
    useEffect(() => {
        const noErrors = errors.periodMax === undefined && errors.periodMin === undefined;
        const params: IGetFrequencyPaymentsParms = {
            frequencyCountMin: formState.periodMin.value,
            frequencyCountMax: formState.periodMax.value,
        };
        const readyToGet =
            token &&
            noErrors &&
            params.frequencyCountMin !== 0 &&
            (params.frequencyCountMax === 0 || params.frequencyCountMin < params.frequencyCountMax);
        if (readyToGet) {
            dispatch(getFrequencyPayments(token, params));
        }
    }, [errors.periodMin, errors.periodMax, formState.periodMin, formState.periodMax]);
    const frequencyPaymentReducer = useSelector((state: RootState) => state.getFrequencyPayments);
    useEffect(() => {
        const noWeeklyItemArray = frequencyPaymentReducer.data.filter(
            (item) => item.frecuancyId !== 3
        );
        const weeklyItemArray = frequencyPaymentReducer.data.filter(
            (item) => item.frecuancyId === 3
        );
        const termOrderArray = [...weeklyItemArray, ...noWeeklyItemArray];
        if (frequencyPaymentReducer.data.length > 0) {
            dispatch(setFrequencyPaymentsTable(termOrderArray));
        }
    }, [frequencyPaymentReducer.data]);
    const {frequencyPaymentsTable} = useSelector(
        (state: RootState) => state.setFrequencyPaymentsTable
    );
    const errorMessage = errors.frequencyTerm?.message?.toString() || '';
    useEffect(() => {
        resetField('frequencyTerm', {defaultValue: frequencyPaymentsTable});
    }, [frequencyPaymentsTable]);
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1.5rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={frequencyRef}
            >
                <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start" Justify="start">
                    <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {PAYMENT_FREQUENCY.TITLE}
                    </Text>
                    <Text Color="#2A2C2F">{PAYMENT_FREQUENCY.DESCRIPTION}</Text>
                </ContainerFlex>
                {errorMessage && (
                    <WarningError
                        errorMessage={errorMessage || VALIDATIONS_MSG.PAYMENT_METHODS.AT_LEAST_ONE}
                    />
                )}

                {frequencyPaymentReducer.data.length === 0 && (
                    <ContainerFlex Padding="1rem" backG="#DBF4FF">
                        <ContainerFlex Gap="0.5rem" Justify="start" Radius="0.5rem">
                            <Image
                                src={informationIcon}
                                Width="24px"
                                Height="24px"
                                alt="information-icon"
                            />
                            <Text Color="#2A2C2F">{PAYMENT_FREQUENCY.ALERT_DESCRIPTION}</Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}

                <Table>
                    <TableRowHead
                        Height="48px"
                        GridColumn="200px 203px 203px 1fr"
                        Radius="0px"
                        Width="100%"
                        BackG="#fff"
                        BorderB="1px solid #E8E9EA"
                    >
                        {headers.map((item, index) => (
                            <HeaderRow
                                key={index}
                                index={index}
                                {...item}
                                handleToolTipVisibility={handleToolTipVisibility}
                            />
                        ))}
                    </TableRowHead>
                    <TableItemsContainer>
                        {frequencyPaymentsTable.map((item, index) => (
                            <FrequencyTermRow
                                key={index}
                                index={index}
                                control={control}
                                tableLength={frequencyPaymentsTable.length}
                                {...item}
                                frequencyPaymentReducer={frequencyPaymentReducer.data}
                                isSubmitted={isSubmitted}
                                trigger={trigger}
                            />
                        ))}
                    </TableItemsContainer>
                </Table>
            </ContainerFlex>
        </>
    );
};
