import {AppDispatch} from '@/config/store';
import {
    GET_ROLE_BY_ID,
    GET_ROLE_BY_ID_SUCCESS,
    GET_ROLE_BY_ID_ERROR,
    GET_ROLE_BY_ID_RESET,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@/config/constants/index';

const GetRoleById = () => {
    return {
        type: GET_ROLE_BY_ID,
    };
};

const GetRoleByIdSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ROLE_BY_ID_SUCCESS,
        payload: result,
    };
};

const GetRoleByIdError = (error: AxiosError) => {
    return {
        type: GET_ROLE_BY_ID_ERROR,
        payload: error,
    };
};

export const GetRoleByIdReset = () => {
    return {
        type: GET_ROLE_BY_ID_RESET,
    };
};

const GetRoleByIdAction = (id: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        const params = {
            id: id.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        dispatch(GetRoleById());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRoleById}?${queryString}`;

        try {
            const response = await axios.get(url, {headers});
            dispatch(GetRoleByIdSuccess(response));
        } catch (error) {
            dispatch(GetRoleByIdError(error as AxiosError));
        }
    };
};

export default GetRoleByIdAction;
