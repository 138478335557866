import React from 'react';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import {IInputAgeProps} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';

export const InputAge: React.FC<IInputAgeProps> = ({register, name}) => {
    return (
        <>
            <Input
                placeholder="N°"
                {...register(name)}
                type="number"
                onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === '+' || e.key === '-' || e.key === '.') {
                        e.preventDefault();
                    }
                }}
            />
        </>
    );
};
