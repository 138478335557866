export const COOWNER_BENEFICIARY = {
    Title: ' Cotitular y/o beneficiario',
    Ask_Add_Coowner: '¿Quiere añadir cotitular y/o beneficiario?',
    Add_New: 'Agregar nuevo ',
    Cancel: 'Cancelar',
    Finish: 'Finalizar',
    Form_Add_New: 'Agrega cotitular/beneficiario',
};

export const COOWNER_CONTRACT = {
    CoOwner: 'Cotitular',
    Beneficiary: 'Beneficiario',
    Phone: 'Tel',
    Email: 'Correo electrónico',
    Show_More: 'Ver más',
    Show_Less: 'Ver menos',
};

export const ALERT_COOWNER = {
    Add_Coowner: 'Vincula al un beneficiario y/o cotitular',
    No_Coowner: 'El cliente aun no cuenta con un cotitular o beneficiaro',
};

export const ERROR_MESSAGES = {
    TITLE_PERSON:
        'Has seleccionado un máximo de cuatro personas como cotitulares y/o beneficiarios.',
    SUB_TITLE_PERSON:
        'Ten en cuenta que si has seleccionado a una persona con ambos roles, cotitular y beneficiario, será contada como un cotitular y un beneficiario independiente.',
};

export const COOWNER_VALIDATION_MSG = {
    NOT_VALID: 'Carácter inválido, ingrese solo letras.',
    EMAIL_NOTVALID: 'Correo no válido',
    ENTER_FIRSTNAME: 'Ingrese nombre',
    ENTER_LASTNAME: 'Ingrese apellido',
    ENTER_EMAIL: 'Ingrese correo',
    ENTER_PHONE: 'Ingrese teléfono',
    PHONE_MIN: 'El número de teléfono debe tener al menos 10 dígitos.',
};
