import {
    GET_INFO_USER,
    GET_INFO_USER_SUCCESS,
    GET_INFO_USERS_ERROR,
} from '@components/Users/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};
export const getInfoUserData = (state = initialState, action) => {
    switch (action.type) {
        case GET_INFO_USER:
            return {...state, loading: true};
        case GET_INFO_USER_SUCCESS:
            return {...state, loading: false, userData: action.payload.data};
        case GET_INFO_USERS_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
