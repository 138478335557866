import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {BANNER_QUOTER} from '@/components/CreditQuoter/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import makePayment from '@/components/CreditQuoter/images/Payment.png';
import needMoney from '@/components/CreditQuoter/images/Money.png';
import arrowRightIcon from '@/components/CreditQuoter/icons/arrowRightIcon.svg';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';

const BannerQuoter = () => {
    const navigate = useNavigate();
    return (
        <ContainerFlex Padding="40px 60px" backG="#fff" Gap="24px" Height="283px" Width="100%">
            <ContainerFlex
                backG="#F9FFD6"
                Radius="16px"
                Height="203px"
                Padding="24px 0px 24px 24px"
            >
                <ContainerFlex FlexDir="column" Gap="16px" Justify="center" Align="start">
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Justify="start"
                        Align="start"
                        Height="62px"
                    >
                        <Text
                            FontWeight="700"
                            Color="#0D166B"
                            FontFamily="Nunito"
                            FontSize="1.75rem"
                        >
                            {BANNER_QUOTER.PAYMENT}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#0D166B"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {BANNER_QUOTER.PAYMENT_TEXT}
                        </Text>
                    </ContainerFlex>
                    <ButtonGenerals
                        SecondaryButton="true"
                        Width="151px"
                        FontSize="1rem"
                        FontWeight="700"
                        Cursor="pointer"
                        Padding="8px 16px"
                        MHeight="40px"
                        BackGC="#F0F0FF"
                        Color="#5A5AFF"
                        HBackG="#F0F0FF"
                        Gap="8px"
                        onClick={() => navigate(routers.QuickPayment)}
                    >
                        {BANNER_QUOTER.PAYMENT_CTA}
                        <Image
                            src={arrowRightIcon}
                            Width="24px"
                            Height="24px"
                            alt="arrow-right-icon"
                        />
                    </ButtonGenerals>
                </ContainerFlex>
                <ContainerFlex Justify="end">
                    <Image src={makePayment} alt="happy-woman" />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                backG="#D0F6FF"
                Radius="16px"
                Height="203px"
                Padding="24px 0px 24px 24px"
            >
                <ContainerFlex FlexDir="column" Gap="16px" Justify="center" Align="start">
                    <ContainerFlex
                        FlexDir="column"
                        Gap="4px"
                        Justify="start"
                        Align="start"
                        Height="62px"
                    >
                        <Text
                            FontWeight="700"
                            Color="#0D166B"
                            FontFamily="Nunito"
                            FontSize="1.75rem"
                        >
                            {BANNER_QUOTER.NEED_MONEY}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#0D166B"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {BANNER_QUOTER.NEED_MONEY_TEXT}
                        </Text>
                    </ContainerFlex>
                    <ButtonGenerals
                        SecondaryButton="true"
                        Width="224px"
                        FontSize="1rem"
                        FontWeight="700"
                        Cursor="pointer"
                        Padding="8px 16px"
                        MHeight="40px"
                        BackGC="#F0F0FF"
                        Color="#5A5AFF"
                        HBackG="#F0F0FF"
                        Gap="8px"
                    >
                        {BANNER_QUOTER.NEED_MONEY_CTA}
                        <Image
                            src={arrowRightIcon}
                            Width="24px"
                            Height="24px"
                            alt="arrow-right-icon"
                        />
                    </ButtonGenerals>
                </ContainerFlex>
                <ContainerFlex Justify="end">
                    <Image src={needMoney} alt="thoughtful-man" />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default BannerQuoter;
