import React, {useRef, useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    fileErrorsInitialState,
    UPLOADBACKDATA,
    UPLOADDROPDATA,
} from '@components/PersonalLoans/constants';
import {BackLoansDataProp, IFileErrors} from '@components/PersonalLoans/interfaces';
import {HiddenFileInput} from '@components/PersonalLoans/HiddenFileInput';
import checkIcon from '@components/PersonalLoans/icons/checkIcon.svg';
import credentialIcon from '@components/PersonalLoans/icons/credentialIcon.svg';
import UploadIcon from '@images/Uploadtocloud.svg';
import ArrowUpMenu from '@images/arrowUpIcon.svg';
import ArrowDownMenu from '@images/arrowDownIcon.svg';
import RemplaceIcon from '@images/RefreshIconBlue.svg';
import PhotosIcon from '@images/PhotosIconBlue.svg';
import * as content from '@components/PersonalLoans/styles';
import {ErrorMessage} from '@components/PersonalLoans/ui/ErrorMessage';
import pdfIcon from '@images/pdfIcon.svg';

export const PersonalLoansBackDocument = ({
    register,
    errors,
    documentsInfo,
    setDocumentsInfo,
}: BackLoansDataProp) => {
    const [showBackDocument, setShowBackDocument] = useState(false);
    const [fileErrors, setFileErrors] = useState<IFileErrors>(fileErrorsInitialState);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const convertToBase64 = (file: File, callback: (base64: string) => void) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            if (reader.result) {
                callback(reader.result as string);
            }
        };
    };

    const isSupportedFileType = (file: File): boolean => {
        const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf'];
        return allowedTypes.some((type) => file.type.startsWith(type));
    };

    const handleBackFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return;
        const file = event.target.files[0];
        const fileSizeMB = file.size / 1024 / 1024;
        if (fileSizeMB > 4) {
            setFileErrors({...fileErrors, imageSize: true});
            return;
        }
        if (!isSupportedFileType(file)) {
            setFileErrors({...fileErrors, validImageFormat: true});
            return;
        }

        convertToBase64(file, (base64) => {
            setDocumentsInfo((prevState) => ({
                ...prevState,
                backImage: {fileName: file.name, formFile: base64},
            }));
        });
        setFileErrors(fileErrorsInitialState);
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const fileSizeMB = file.size / 1024 / 1024;

        if (fileSizeMB > 4) {
            setFileErrors({...fileErrors, imageSize: true});
            return;
        }
        if (!isSupportedFileType(file)) {
            setFileErrors({...fileErrors, validImageFormat: true});
            return;
        }

        convertToBase64(file, (base64) => {
            setDocumentsInfo((prevState) => ({
                ...prevState,
                backImage: {fileName: file.name, formFile: base64},
            }));
        });
        setFileErrors(fileErrorsInitialState);
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    };

    return (
        <ContainerFlex
            {...content.ContainerFlexUpload}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <ContainerFlex Justify="space-between" Gap="0.5rem">
                <ContainerFlex Justify="start" Gap="0.5rem" Width="35rem">
                    <Image
                        alt="icon-check"
                        Height="1.5rem"
                        Width="1.5rem"
                        src={documentsInfo.backImage ? checkIcon : credentialIcon}
                    />
                    <ContainerFlex FlexDir="column" Width="max-content" Align="start">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400" oFlow="none">
                            {UPLOADBACKDATA.TITLE}
                        </Text>
                        {showBackDocument === true && !documentsInfo.backImage && (
                            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400" oFlow="none">
                                {UPLOADBACKDATA.SUBTITLE}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>

                <Image
                    alt="icon-arrow"
                    src={showBackDocument ? ArrowUpMenu : ArrowDownMenu}
                    Cursor="pointer"
                    onClick={() => setShowBackDocument(!showBackDocument)}
                />
            </ContainerFlex>
            {showBackDocument === true &&
                (documentsInfo.backImage ? (
                    <>
                        <ContainerFlex Justify="start" Align="start" Gap="2rem">
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Width="fit-content"
                                Gap="0.75rem"
                            >
                                <Image
                                    alt="icon-image"
                                    src={
                                        documentsInfo.backImage.fileName.includes('.pdf')
                                            ? pdfIcon
                                            : documentsInfo.backImage.formFile
                                    }
                                    {...content.ImageUpload}
                                    ObjectFit={
                                        documentsInfo.backImage.fileName.includes('.pdf')
                                            ? 'contain'
                                            : 'cover'
                                    }
                                />
                                <ContainerFlex
                                    {...content.ContainerUploadRef}
                                    onClick={() => fileInputRef.current?.click()}
                                >
                                    <HiddenFileInput
                                        onChange={handleBackFileChange}
                                        ref={fileInputRef}
                                    />
                                    <Image alt="icon-remplace" src={RemplaceIcon} />
                                    <Text {...content.UploadRemplace}>
                                        {UPLOADDROPDATA.REMPLACE}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                        {fileErrors.imageSize && <ErrorMessage message={UPLOADDROPDATA.ERROR} />}
                        {fileErrors.validImageFormat && (
                            <ErrorMessage message={UPLOADDROPDATA.FORMAT_ERROR} />
                        )}
                    </>
                ) : (
                    <>
                        <ContainerFlex
                            {...content.UploadDrop}
                            Border={
                                fileErrors.imageSize || fileErrors.validImageFormat === true
                                    ? '1px dashed #A82424'
                                    : '1px dashed #ACACFF'
                            }
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <HiddenFileInput onChange={handleBackFileChange} ref={fileInputRef} />
                            <Image src={UploadIcon} alt="icon-upload" />
                            <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="500">
                                {UPLOADDROPDATA.DROP}
                            </Text>
                            <Text Color="#A1A5AA" FontSize="0.75rem">
                                {UPLOADDROPDATA.TYPE}
                            </Text>
                        </ContainerFlex>
                        {fileErrors.imageSize && <ErrorMessage message={UPLOADDROPDATA.ERROR} />}
                        {fileErrors.validImageFormat && (
                            <ErrorMessage message={UPLOADDROPDATA.FORMAT_ERROR} />
                        )}
                        <ContainerFlex
                            Width="fit-content"
                            Gap="0.25rem"
                            Margin="0 1rem"
                            Cursor="pointer"
                            onClick={() => fileInputRef.current?.click()}
                        >
                            <HiddenFileInput onChange={handleBackFileChange} ref={fileInputRef} />
                            <Image alt="icon-upload" src={PhotosIcon} />
                            <Text {...content.UploadSelect}>{UPLOADDROPDATA.SELECT}</Text>
                        </ContainerFlex>
                    </>
                ))}
        </ContainerFlex>
    );
};
