import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_FREQUENCY_PAYMENTS_START,
    GET_FREQUENCY_PAYMENTS_SUCESS,
    GET_FREQUENCY_PAYMENTS_ERROR,
    RESET_FREQUENCY_PAYMENTS_REDUCER,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IGetFrequencyPaymentsReducer} from '@components/PersonalLoans/NewLoanProduct/interfaces';

const initialState: IGetFrequencyPaymentsReducer = {
    data: [],
    loading: false,
    error: false,
};

const getFrequencyPayments = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IGetFrequencyPaymentsReducer => {
    switch (action.type) {
        case GET_FREQUENCY_PAYMENTS_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case GET_FREQUENCY_PAYMENTS_SUCESS: {
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        }
        case GET_FREQUENCY_PAYMENTS_ERROR: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case RESET_FREQUENCY_PAYMENTS_REDUCER: {
            return initialState;
        }
        default: {
            return state;
        }
    }
};

export default getFrequencyPayments;
