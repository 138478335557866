import React, {SyntheticEvent, useEffect, useRef, useState} from 'react';
import {Image} from '@Shopify/Ecommerce/styles';
import {IActionMenu} from '@components/LocationsBranch/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import MoreMenuIcon from '@images/moreMenu.svg';

export const ActionMenuOption = ({id, menuOptions}: IActionMenu) => {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef<HTMLDivElement>(null);

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: MouseEvent | SyntheticEvent) => {
        if (
            anchorRef.current &&
            event.target instanceof Node &&
            anchorRef.current.contains(event.target)
        ) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        document.addEventListener('click', handleClose);
        return () => {
            document.removeEventListener('click', handleClose);
        };
    }, []);

    const buttonId = `basic-button-${id}`;
    const menuId = `action-menu-${id}`;

    return (
        <div style={{position: 'relative'}}>
            <div
                ref={anchorRef}
                id={buttonId}
                aria-controls={open ? menuId : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={(e) => {
                    stopPropagation(e);
                    handleToggle();
                }}
            >
                <Image
                    src={MoreMenuIcon}
                    alt="add"
                    Width="0.25rem"
                    Height="1rem"
                    Margin="0.125rem 0 0 1.125rem"
                />
            </div>
            {menuOptions.length > 0 && (
                <ContainerFlex
                    Display={open ? 'flex' : 'none'}
                    Position="absolute"
                    PositionTop="100%"
                    PositionRight="0"
                    backG="#FFFFFF"
                    MinH="2.5rem"
                    MinW="6.5rem"
                    BoxShadow="0 0.25rem 0.375rem rgba(0, 0, 0, 0.1)"
                    Border="1px solid #f3f3f3"
                    ZIndex="1000"
                    Radius="0.5rem"
                >
                    {menuOptions.map((option, index) => (
                        <ContainerFlex
                            Align="center"
                            Justify="start"
                            Gap="0.5rem"
                            Cursor="pointer"
                            Padding="0.5rem"
                            key={index}
                            onClick={(event) => {
                                option.action();
                                handleClose(event);
                            }}
                        >
                            <Image
                                src={option.icon}
                                alt="menu-icon"
                                Width="1.125rem"
                                Height="1.125rem"
                                Margin="0 0 0.313rem"
                            />
                            <Text
                                Color={option.textColor}
                                Cursor="pointer"
                                FontSize="1rem"
                                FontWeight="400"
                            >
                                {option.name}
                            </Text>
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
            )}
        </div>
    );
};
