import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {IContract} from '@Quoter/inferfaces';
import mergeArrrow from '@images/mergeArrow.svg';
import JoinDeadlines from '@Quoter/Steps/JoinDeadlines';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {DataContract, IContracts} from '@Quoter/Steps/interface';
import {IClientData} from '@components/ShoppingCart/Redux/interfaces';
import {saveIdContract} from '@Quoter/Redux/Actions/saveEndeavors';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {
    getShoppingCartContracts,
    updateContract,
} from '@/components/ShoppingCart/Redux/Actions/getCartContractsActions';

export const ContractContainer = ({
    setSelectContract,
    selectContract,
    setSelectArticle,
    setLoan,
    setValue,
}: IContract) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const {idContract} = useSelector((state: RootState) => state.saveEndeavor);
    const {loadingUpdate} = useSelector((state: RootState) => state.getCartContracts);
    const ContractsCarShopping: DataContract[] = useSelector(
        (state: RootState) => state.getCartContracts.data
    );
    const carshopingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const {client: selectedClient}: IClientData = useSelector(
        (state: RootState) => state.clientList
    );

    const joinContract = () => {
        let join: DataContract[] = [];
        const contracts = ContractsCarShopping;
        if (contracts) {
            if (contracts.length <= 2 && contracts.map((i) => i.pledges).flat().length === 2)
                join = contracts;
            if (contracts.length > 0 && contracts.map((i) => i.pledges).flat().length > 2) {
                const lastOriginalContract = contracts[contracts.length - 1];
                join = [
                    ...contracts,
                    {
                        carShoppingId: lastOriginalContract.carShoppingId,
                        contractId: lastOriginalContract.contractId + 1,
                        contractName: `Contrato ${contracts.length + 1}`,
                        promotionId: lastOriginalContract.promotionId,
                        pledges: [],
                    },
                ];
            }
        }
        return join;
    };

    const [contract, setContracts] = useState<IContracts>({
        typeAction: 'Combinar',
        initialPledges: joinContract(),
        draggedItem: null,
    });

    const mergeContracts = async () => {
        if (contract.initialPledges) {
            if (contract.typeAction === 'Combinar') {
                if (
                    contract.initialPledges.length <= 2 &&
                    contract.initialPledges[0]?.pledges.length === 1 &&
                    contract.initialPledges[1]?.pledges.length === 1 &&
                    contract.initialPledges.map((i) => i.pledges).flat().length === 2
                ) {
                    const principalContract = 0;
                    const updateContracts = [
                        {
                            carShoppingId: contract.initialPledges[principalContract].carShoppingId,
                            contractId: 0,
                            contractName: contract.initialPledges[principalContract].contractName,
                            promotionId: contract.initialPledges[principalContract].promotionId,
                            pledges: contract.initialPledges[principalContract].pledges.concat(
                                contract.initialPledges[1].pledges
                            ),
                        },
                    ];
                    if (token && carshopingId) {
                        dispatch(
                            updateContract(
                                token,
                                {pawns: updateContracts},
                                carshopingId,
                                selectedClient?.id,
                                setShowModal
                            )
                        );
                    }
                }
                if (contract.initialPledges.some((i) => i.pledges.length === 0)) setShowModal(true);
            }

            if (contract.typeAction === 'Dividir') {
                if (
                    contract.initialPledges.length === 1 &&
                    contract.initialPledges.map((i) => i.pledges).flat().length === 2
                ) {
                    const updateContracts = [
                        ...joinContract().map((items, index = 0) => ({
                            carShoppingId: items.carShoppingId,
                            contractId: items.contractId,
                            contractName: items.contractName,
                            promotionId: items.promotionId,
                            pledges: [items.pledges[index]],
                        })),
                        ...joinContract().map((items) => ({
                            carShoppingId: items.carShoppingId,
                            contractId: 0,
                            contractName: 'Contrato 2',
                            promotionId: items.promotionId,
                            pledges: [items.pledges[1]],
                        })),
                    ];
                    if (token && carshopingId) {
                        dispatch(
                            updateContract(
                                token,
                                {pawns: updateContracts},
                                carshopingId,
                                selectedClient?.id,
                                setShowModal
                            )
                        );
                    }
                }
                if (
                    contract.initialPledges.length > 0 &&
                    contract.initialPledges.map((i) => i.pledges).flat().length > 2
                ) {
                    setShowModal(true);
                }
            }
        }
    };

    const findArticles = (contractId: number) => {
        setSelectContract(contractId);
        const selectedContract = ContractsCarShopping.find(
            (data) => data.contractId === contractId
        );
        dispatch(saveIdContract(contractId));
        const selectedPledges =
            ContractsCarShopping.find((data) => data.contractId === contractId)?.pledges.map(
                (pledge) => pledge.articleId
            ) || [];
        setSelectArticle(selectedPledges);
        const totalLoan =
            selectedContract?.pledges.reduce((acc, pledge) => acc + pledge.articleLoan, 0) || 0;
        const totalValue =
            selectedContract?.pledges.reduce((acc, pledge) => acc + pledge.articleValue, 0) || 0;
        setLoan(totalLoan);
        setValue(totalValue);
    };

    useEffect(() => {
        if (token) dispatch(getShoppingCartContracts(token, {carshopingId: carshopingId}));
    }, []);

    useEffect(() => {
        if (ContractsCarShopping && ContractsCarShopping.length > 0) {
            findArticles(ContractsCarShopping[0].contractId);
            setContracts({
                ...contract,
                initialPledges: joinContract(),
                typeAction: ContractsCarShopping.some((i) => i.pledges.length > 1)
                    ? 'Dividir'
                    : 'Combinar',
            });
            dispatch(saveIdContract(ContractsCarShopping[0].contractId));
        }
    }, [ContractsCarShopping]);

    useEffect(() => {
        findArticles(idContract);
    }, [idContract]);

    return (
        <ContainerFlex Height="auto" Justify="space-between">
            <ContainerFlex Height="auto" Justify="start" OverFlowY="auto" HeightScroll="8px">
                {ContractsCarShopping &&
                    ContractsCarShopping.length > 0 &&
                    ContractsCarShopping.map((data) => (
                        <ContainerFlex
                            Bb={
                                selectContract === data.contractId
                                    ? '1.2px solid #5A5AFF'
                                    : '1.2px solid #D4D6D8'
                            }
                            Height="40px"
                            key={data.contractId}
                            Padding="8px"
                            onClick={() => {
                                if (ContractsCarShopping && ContractsCarShopping.length > 0)
                                    findArticles(data.contractId);
                            }}
                            Width="7.063rem"
                            Cursor="pointer"
                        >
                            <Text
                                FontSize="1rem"
                                Color={selectContract === data.contractId ? '#5A5AFF' : '#54575C'}
                                FontWeight="500"
                            >
                                {data.contractName}
                            </Text>
                        </ContainerFlex>
                    ))}
            </ContainerFlex>

            {loadingUpdate && !showModal ? (
                <ContainerFlex Width="11.75rem">
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <ContainerFlex
                    Cursor="pointer"
                    Width="11.75rem"
                    Gap="0.5rem"
                    onClick={() => mergeContracts()}
                >
                    <Image src={mergeArrrow} />
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="500">
                        {`${contract.typeAction} contratos`}
                    </Text>
                </ContainerFlex>
            )}

            <JoinDeadlines
                showModal={showModal}
                setShowModal={setShowModal}
                contract={contract}
                setContracts={setContracts}
            />
        </ContainerFlex>
    );
};
