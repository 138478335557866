export const GET_PASSWORD_RANDOM_START = 'GET_PASSWORD_RANDOM_START';
export const GET_PASSWORD_RANDOM_SUCCESS = 'GET_PASSWORD_RANDOM_SUCCESS';
export const GET_PASSWORD_RANDOM_ERROR = 'GET_PASSWORD_RANDOM_ERROR';
export const RESET_RANDOM_PASSWORD = 'RESET_RANDOM_PASSWORD';
export const GET_UPDATE_PERIODS_START = 'GET_UPDATE_PERIODS_START';
export const GET_UPDATE_PERIODS_SUCCESS = 'GET_UPDATE_PERIODS_SUCCESS';
export const GET_UPDATE_PERIODS_ERROR = 'GET_UPDATE_PERIODS_ERROR';
export const GET_ROLE_BY_COMPANY_START = 'GET_ROLE_BY_COMPANY_START';
export const GET_ROLE_BY_COMPANY_SUCCESS = 'GET_ROLE_BY_COMPANY_SUCCESS';
export const GET_ROLE_BY_COMPANY_ERROR = 'GET_ROLE_BY_COMPANY_ERROR';
export const GET_LEVEL_TREE_BRANCH_START = 'GET_LEVEL_TREE_BRANCH_START';
export const GET_LEVEL_TREE_BRANCH_SUCCESS = 'GET_LEVEL_TREE_BRANCH_SUCCESS';
export const GET_LEVEL_TREE_BRANCH_ERROR = 'GET_LEVEL_TREE_BRANCH_ERROR';
export const CREATE_USER_COMMAND_START = 'CREATE_USER_COMMAND_START';
export const CREATE_USER_COMMAND_SUCCESS = 'CREATE_USER_COMMAND_SUCCESS';
export const CREATE_USER_COMMAND_ERROR = 'CREATE_USER_COMMAND_ERROR';
