import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import frequencyIcon from '@/components/CreditQuoter/icons/frequencyIcon.svg';
import paymentsIcon from '@/components/CreditQuoter/icons/paymentsIcon.svg';
import {ICreditPaymentDetailsProps} from '@/components/CreditQuoter/interfaces';
const CreditPaymentDetails: React.FC<ICreditPaymentDetailsProps> = ({
    amount,
    frequencyName,
    countPayments,
}) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Width="100%"
            Height="100%"
            Gap="32px"
            Justify="start"
            Align="start"
        >
            <ContainerFlex Justify="start" Align="start" Width="520px" FlexDir="column" Gap="8px">
                <Text FontWeight="400" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.0rem">
                    {CREDIT_QUOTER.FIELDS.AMOUNT}
                </Text>
                <ContainerFlex
                    Width="100%"
                    Height="58px"
                    Padding="12px 16px"
                    Justify="start"
                    Align="start"
                >
                    <Text FontWeight="700" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.75rem">
                        {numberToCurrencyWithoutCents(amount)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Align="start" Width="520px" Gap="32px">
                <ContainerFlex Radius="16px" Height="58px">
                    <Image src={frequencyIcon} Width="24px" Height="24px" alt="calender-icon" />
                    <ContainerFlex
                        Padding="8px"
                        FlexDir="column"
                        Justify="start"
                        Align="start"
                        Gap="4px"
                    >
                        <Text
                            FontWeight="400"
                            Color="#54575C"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                        >
                            {CREDIT_QUOTER.FIELDS.FREQUENCY}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {frequencyName}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Radius="16px" Height="58px">
                    <ContainerFlex
                        Padding="8px"
                        Justify="center"
                        Align="center"
                        Width="40px"
                        Radius="16px"
                    >
                        <Image src={paymentsIcon} Width="24px" Height="24px" alt="calender-icon" />
                    </ContainerFlex>
                    <ContainerFlex
                        Padding="8px"
                        FlexDir="column"
                        Justify="start"
                        Align="start"
                        Gap="4px"
                    >
                        <Text
                            FontWeight="400"
                            Color="#54575C"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                        >
                            {CREDIT_QUOTER.FIELDS.PERIODS}
                        </Text>
                        <Text
                            FontWeight="700"
                            Color="#2A2C2F"
                            FontFamily="Nunito"
                            FontSize="1.0rem"
                        >
                            {countPayments}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditPaymentDetails;
