import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import CloseIcon from '@images/close.svg';
import {FieldNames, loanStatusLabels, LabelsHeaders} from '@components/LoansProducts/constants';

interface RenderDisplayValueProps {
    keyName: string;
    value: string | number | {label: string; value: number};
    onResetField: (fieldName: FieldNames) => void;
}

const RenderDisplayValue: React.FC<RenderDisplayValueProps> = ({keyName, value, onResetField}) => {
    const displayValue = typeof value === 'object' && value !== null ? value.label : value;

    if (!displayValue) return null;

    const textMap: Record<string, string> = {
        loanStatus:
            typeof displayValue === 'number'
                ? loanStatusLabels[displayValue] || LabelsHeaders.unknownStatusLabel
                : '',
        minimumAmount: `${LabelsHeaders.minimumAmountLabel}${displayValue}`,
        maximumAmount: `${LabelsHeaders.maximumAmountLabel}${displayValue}`,
        minimumTime: `${LabelsHeaders.minimumTimeLabel}${displayValue}`,
        maximumTime: `${LabelsHeaders.maximumTimeLabel}${displayValue}`,
    };

    const textValue = textMap[keyName] || `${keyName}: ${displayValue}`;

    return (
        <ContainerFlex
            key={keyName}
            Justify="start"
            backG="#F0F0FF"
            Padding="4px 8px"
            Height="32px"
            Gap="8px"
            Radius="32px"
            Width="fil-content"
            FlexWrap="wrap"
        >
            <Text Color="#5A5AFF">{textValue}</Text>
            <Image
                src={CloseIcon}
                onClick={() => onResetField(keyName as FieldNames)}
                Cursor="pointer"
                alt="icon-close"
            />
        </ContainerFlex>
    );
};

export default RenderDisplayValue;
