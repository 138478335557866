import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_PRODUCT_PER_PAGE, LOANSPRODUCTS} from '@components/LoansProducts/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import Add from '@images/add - plus white.svg';
import {ProductsLoans} from '@components/LoansProducts/ProductsLoans';
import {LoansActive} from '@components/LoansProducts/LoansActive';
import {SuggestedProducts} from '@components/LoansProducts/SuggestedProducts';
import {useDispatch, useSelector} from 'react-redux';
import {getAllTotalAvailables} from '@components/LoansProducts/Redux/Actions/getPersonalCreditProductsActions';
import {getProductsCustomerUser} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';
import {RootState} from '@/config/store';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {NEW_LOAN_PRODUCT} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ModalActionLoans} from '@components/LoansProducts/ModalActionLoans';
import {ModalArchiveLoans} from '@components/LoansProducts/ModalArchiveLoans';
import {ModalSearchProducts} from '@components/LoansProducts/ModalSearchProducts';
import {useNavigate} from 'react-router-dom';
import {resetTermPersonalLoan} from '@components/LoansProducts/Redux/Actions/GetTermPersonalLoan';
import {setIsEditingLoan} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/isEditingLoanStateActions';

export const LoansProducts = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const enterpriceId = useSelector((state: RootState) => {
        return Number(state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId);
    });
    const {activeStatus, page} = useSelector(
        (state: RootState) => state.getProductsCustomerUserReducer
    );
    const [showActionModal, setShowActionModal] = useState(false);
    const [showArchiveModal, setShowArchiveModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    useEffect(() => {
        dispatch(getAllTotalAvailables(token, enterpriceId));
    }, [dispatch, token, enterpriceId]);

    useEffect(() => {
        dispatch(
            getProductsCustomerUser(token, enterpriceId, activeStatus, LOAN_PRODUCT_PER_PAGE, page)
        );
    }, [dispatch, token, enterpriceId, page]);
    useEffect(() => {
        if (page === 1) {
            dispatch(
                getProductsCustomerUser(
                    token,
                    enterpriceId,
                    activeStatus,
                    LOAN_PRODUCT_PER_PAGE,
                    page
                )
            );
        }
    }, [dispatch, token, enterpriceId, activeStatus]);

    const itemsBreadcrumb = [
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_1, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_1},
        {to: NEW_LOAN_PRODUCT.URL.LEVEL_2, label: NEW_LOAN_PRODUCT.ROOT.LEVEL_2},
    ];
    const navigate = useNavigate();
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#FAFAFA"
            Padding="1.5rem"
            Gap="1.5rem"
        >
            <Breadcrumb items={itemsBreadcrumb} />
            <ContainerFlex Justify="space-between" Height="4.125rem">
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="8px"
                    Width="37.5rem"
                >
                    <Text Color="#1D1E20" FontSize="1.75rem" FontWeight="700">
                        {LOANSPRODUCTS.TITLE}
                    </Text>
                    <Text Color="#2A2C2F">{LOANSPRODUCTS.DESCRIPTION}</Text>
                </ContainerFlex>
                <ButtonGenerals
                    Width="191px"
                    onClick={() => {
                        dispatch(resetTermPersonalLoan());
                        dispatch(setIsEditingLoan(false));
                        navigate('/NewLoanProduct');
                    }}
                >
                    <Image src={Add} alt="add" />
                    {LOANSPRODUCTS.NEW}
                </ButtonGenerals>
            </ContainerFlex>
            <LoansActive />
            <ProductsLoans
                setShowModal={setShowModal}
                setShowActionModal={setShowActionModal}
                setShowArchiveModal={setShowArchiveModal}
            />
            {showModal && <ModalSearchProducts setShowModal={setShowModal} />}
            <SuggestedProducts />
            {showActionModal && <ModalActionLoans setShowActionModal={setShowActionModal} />}
            {showArchiveModal && <ModalArchiveLoans setShowArchiveModal={setShowArchiveModal} />}
        </ContainerFlex>
    );
};
