import {TableRowItems} from '@/components/CashFlow/FundingInquiry/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import roleArrowImg from '@/images/roleIcon_Arrow.svg';
import {tableRowItemProps} from '@components/RolesPermissions/NewRole/constants';
import React from 'react';
import {IModuleTree, IRoleModules} from '@/components/Users/UserProfile/RoleSettings/interfaces';
import check from '@/images/CheckListRoles.svg';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';

const ModuleTree = ({
    modules,
    level,
    isExpand,
    totalModules,
    setModules,
    listData,
    setListData,
    isAdmin,
}: IModuleTree) => {
    const areAllChildrenSelected = (module: IRoleModules): boolean => {
        if (!module.nextLevel || module.nextLevel.length === 0) return true;

        return module.nextLevel.every((child) => {
            const isChildSelected = !isAdmin
                ? listData.operationalModules.includes(child.moduleId)
                : listData.adminModules.includes(child.moduleId);
            return isChildSelected && areAllChildrenSelected(child);
        });
    };

    const isModuleSelected = (module: IRoleModules): boolean => {
        const directlySelected = !isAdmin
            ? listData.operationalModules.includes(module.moduleId)
            : listData.adminModules.includes(module.moduleId);

        if (module.nextLevel && module.nextLevel.length > 0) {
            return directlySelected || areAllChildrenSelected(module);
        }

        return directlySelected;
    };

    const toggleModuleOpen = (id: number) => {
        const toggleRecursively = (
            modules: IRoleModules[],
            targetId: number,
            toggleState?: boolean
        ): IRoleModules[] => {
            return modules.map((module) => {
                if (module.moduleId === targetId) {
                    const newToggleState = toggleState ?? !module.isOpen;
                    return {
                        ...module,
                        isOpen: newToggleState,
                        nextLevel: toggleRecursively(module.nextLevel, targetId, newToggleState),
                    };
                }
                return {
                    ...module,
                    nextLevel: module.nextLevel
                        ? toggleRecursively(module.nextLevel, targetId, toggleState)
                        : [],
                };
            });
        };

        setModules((prevModules) => toggleRecursively(prevModules, id));
    };

    const calculateMargin = (level: number) => {
        return level * 25;
    };

    return (
        <>
            {modules.map((i: IRoleModules) => {
                const isModuleChecked = isModuleSelected(i);

                return (
                    <React.Fragment key={i.moduleId}>
                        <TableRowItems
                            {...tableRowItemProps}
                            BackGround={level !== 0 ? '#FAFAFA' : 'none'}
                        >
                            <ContainerFlex
                                Justify="start"
                                Width="80%"
                                MarginL={`${calculateMargin(level)}px`}
                            >
                                <ContainerFlex Width="8%">
                                    {i.nextLevel.length > 0 && (
                                        <Image
                                            src={roleArrowImg}
                                            Cursor="pointer"
                                            onClick={() => toggleModuleOpen(i.moduleId)}
                                            Transform={i.isOpen ? 'rotate(0.5turn)' : ''}
                                            alt="arrow"
                                        />
                                    )}
                                </ContainerFlex>
                                <ContainerFlex Justify="start" Gap="10px">
                                    {i.icon && <Image src={i.icon} alt="logo" width="20px" />}
                                    <Text FontWeight={i.nextLevel.length > 0 ? '700' : '400'}>
                                        {i.moduleName}
                                    </Text>
                                    {i.nextLevel.length > 0 && (
                                        <Text FontWeight={'700'}>
                                            {`${
                                                isAdmin
                                                    ? i.nextLevel?.filter((child) =>
                                                          listData.adminModules.includes(
                                                              child.moduleId
                                                          )
                                                      ).length
                                                    : i.nextLevel?.filter((child) =>
                                                          listData.operationalModules.includes(
                                                              child.moduleId
                                                          )
                                                      ).length
                                            } de ${i.nextLevel?.length}`}
                                        </Text>
                                    )}
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex>
                                {isModuleChecked ? (
                                    <Image src={check} alt="check" width="20px" />
                                ) : (
                                    <Checkbox disabled />
                                )}
                            </ContainerFlex>
                        </TableRowItems>
                        {i.isOpen && i.nextLevel && (
                            <ModuleTree
                                level={level + 1}
                                modules={i.nextLevel}
                                isExpand={isExpand}
                                totalModules={totalModules}
                                setModules={setModules}
                                listData={listData}
                                setListData={setListData}
                                isAdmin={isAdmin}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ModuleTree;
