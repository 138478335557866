export const GET_PERSONAL_CREDIT_PRODUCTS_START = 'GET_PERSONAL_CREDIT_PRODUCTS_START';
export const GET_PERSONAL_CREDIT_PRODUCTS_SUCCESS = 'GET_PERSONAL_CREDIT_PRODUCTS_SUCCESS';
export const GET_PERSONAL_CREDIT_PRODUCTS_ERROR = 'GET_PERSONAL_CREDIT_PRODUCTS_ERROR';

export const GET_PRODUCTS_CUSTOMER_USER_START = 'GET_PRODUCTS_CUSTOMER_USER_START';
export const GET_PRODUCTS_CUSTOMER_USER_SUCCESS = 'GET_PRODUCTS_CUSTOMER_USER_SUCCESS';
export const GET_PRODUCTS_CUSTOMER_USER_ERROR = 'GET_PRODUCTS_CUSTOMER_USER_ERROR';
export const SET_ACTIVE_STATUS = 'SET_ACTIVE_STATUS';

export const ACTIVE_DESACTIVE_CREDIT_START = 'ACTIVE_DESACTIVE_CREDIT_START';
export const ACTIVE_DESACTIVE_CREDIT_SUCCESS = 'ACTIVE_DESACTIVE_CREDIT_SUCCESS';
export const ACTIVE_DESACTIVE_CREDIT_ERROR = 'ACTIVE_DESACTIVE_CREDIT_ERROR';
export const SELECTED_ACTIVE_DESACTIVE_CREDIT = 'SELECTED_ACTIVE_DESACTIVE_CREDIT';
export const RESET_ACTIVE_DESACTIVE_CREDIT = 'RESET_ACTIVE_DESACTIVE_CREDIT';

export const GET_TERM_PERSONAL_LOAN_START = 'GET_TERM_PERSONAL_LOAN_START';
export const GET_TERM_PERSONAL_LOAN_SUCCESS = 'GET_TERM_PERSONAL_LOAN_SUCCESS';
export const GET_TERM_PERSONAL_LOAN_ERROR = 'GET_TERM_PERSONAL_LOAN_ERROR';
export const RESET_GET_TERM_PERSONAL_LOAN = 'RESET_GET_TERM_PERSONAL_LOAN';

export const UPDATE_TERM_PERSONAL_LOANS_START = 'UPDATE_TERM_PERSONAL_LOANS_START';
export const UPDATE_TERM_PERSONAL_LOANS_SUCCESS = 'UPDATE_TERM_PERSONAL_LOANS_SUCCESS';
export const UPDATE_TERM_PERSONAL_LOANS_ERROR = 'UPDATE_TERM_PERSONAL_LOANS_ERROR';
export const GET_FILTER_LOAN_PRODUCTS_START = 'GET_FILTER_LOAN_PRODUCTS_START';
export const GET_FILTER_LOAN_PRODUCTS_SUCCESS = 'GET_FILTER_LOAN_PRODUCTS_SUCCESS';
export const GET_FILTER_LOAN_PRODUCTS_ERROR = 'GET_FILTER_LOAN_PRODUCTS_ERROR';

export const GET_SEARCH_LOAN_PRODUCTS_START = 'GET_SEARCH_LOAN_PRODUCTS_START';
export const GET_SEARCH_LOAN_PRODUCTS_SUCCESS = 'GET_SEARCH_LOAN_PRODUCTS_SUCCESS';
export const GET_SEARCH_LOAN_PRODUCTS_ERROR = 'GET_SEARCH_LOAN_PRODUCTS_ERROR';

export const RESET_SEARCH_RESULTS = 'RESET_SEARCH_RESULTS';
export const SAVE_FILTER_LOANS_USER = 'SAVE_FILTER_LOANS_USER';
export const SAVE_FILTER_LOANS_RESET = 'SAVE_FILTER_LOANS_RESET';

export const SET_PAGE_NUMBER = 'SET_PAGE_NUMBER';
export const RESET_PAGE_NUMBER = 'RESET_PAGE_NUMBER';
