import React, {useState} from 'react';
import {ContainerFlex, Text, TextEllipsis, Image} from '@/components/Shopify/Ecommerce/styles';
import {priorityStyles, UserUbication} from '@components/Users/UserProfile/constants';
import {TableRowItems} from '@/components/CashFlow/FundingInquiry/styles';
import {TableItem} from '@/components/DeadLines/styles';
import {ClickAwayListener} from '@mui/material';
import Arrow from '@images/Down_aero.svg';
import {IScreenTableData} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';

const getPriorityStyle = (priority: string) => {
    const key = priority.toLowerCase() as keyof typeof priorityStyles;
    return priorityStyles[key] || priorityStyles.default;
};

export const TaskTableMap = ({i}: IScreenTableData) => {
    const [show, setShow] = useState(false);
    const priorityStyle = getPriorityStyle(i.PRIORITY);

    return (
        <TableRowItems
            Height="40px"
            GridColumn="1fr 1fr 1fr 1fr 1fr"
            BackGround="none"
            BorderBottom="1px solid #E8E9EA"
            Border="none"
            BorderRadius="0px"
            Position="relative"
        >
            <TableItem Justify="start" Height="40px">
                <Text FontSize="0.875rem" FontWeight="400" Padding="4px 0px">
                    {i.DATE}
                </Text>
            </TableItem>

            <TableItem Justify="start" Height="40px">
                <TextEllipsis FontSize="0.875rem" FontWeight="300" Padding="4px 0px">
                    {i.TASK}
                </TextEllipsis>
            </TableItem>

            <TableItem Justify="start" Height="40px">
                <TextEllipsis FontSize="0.875rem" FontWeight="400" Padding="4px 0px">
                    {i.BRANCH}
                </TextEllipsis>
            </TableItem>

            <TableItem Justify="start" Height="40px">
                <ContainerFlex
                    Radius="32px"
                    Border={priorityStyle.border}
                    backG={priorityStyle.background}
                    Padding="2px 8px"
                    Width=""
                    Height="18px"
                >
                    <Text
                        FontSize="0.875rem"
                        FontWeight="700"
                        Padding="4px 0px"
                        Color={priorityStyle.color}
                    >
                        {i.PRIORITY}
                    </Text>
                </ContainerFlex>
            </TableItem>

            <TableItem Justify="start" onClick={() => setShow(true)} Cursor="pointer">
                <ContainerFlex
                    Justify="space-between"
                    Height="32px"
                    Radius="24px"
                    Border="1px solid #E8E9EA"
                    Padding="8px"
                    Gap="8px"
                >
                    <TextEllipsis
                        FontSize="0.875rem"
                        Width="max-content"
                        FontWeight="300"
                        Padding="4px 0px"
                    >
                        {i.USERS}
                    </TextEllipsis>
                    <Image src={Arrow} Cursor="pointer" />
                </ContainerFlex>
                {show && (
                    <ClickAwayListener
                        onClickAway={() => {
                            setShow(false);
                        }}
                    >
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            Position="absolute"
                            ZIndex="3"
                            PositionTop="1rem"
                            backG="#FFFFFF"
                            Radius="8px"
                            Border="1px solid #F3F3F3"
                            Height="168px"
                            BoxS="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
                            FlexDir="column"
                            Width="15%"
                        >
                            {UserUbication.map((data, index) => {
                                return (
                                    <ContainerFlex
                                        Padding="8px"
                                        Justify="start"
                                        Gap="8px"
                                        Align="start"
                                        FlexDir="column"
                                        Cursor="pointer"
                                        BackdropF="#FFFFFF"
                                        HBackground="#F0F0FF"
                                        HColor="#5A5AFF"
                                        Height="54px"
                                        HBorderLeft="1px solid #5A5AFF"
                                        key={index}
                                    >
                                        <Text Color="#2A2C2F" Cursor="pointer" HColor="#5A5AFF">
                                            {data.name}
                                        </Text>
                                        <Text
                                            FontSize="1rem"
                                            Color="#2A2C2F"
                                            Cursor="pointer"
                                            HColor="#5A5AFF"
                                        >
                                            {data.Ubication}
                                        </Text>
                                    </ContainerFlex>
                                );
                            })}
                        </ContainerFlex>
                    </ClickAwayListener>
                )}
            </TableItem>
        </TableRowItems>
    );
};
