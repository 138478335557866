import {
    USER_TABLE_REQUEST,
    USER_TABLE_SOUCCES,
    USER_TABLE_ERROR,
    USER_TABLE_RESET,
} from '@components/MyCompanyUser/Redux/types';
import {AxiosError, AxiosResponse} from 'axios';
import {IDAlluser} from '@/components/MyCompanyUser/Operational/interfaceType';

const initialState: IDAlluser = {
    loading: false,
    userData: null,
    message: '',
    error: false,
};

export const TableAllUserAdmin = (
    state: IDAlluser = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
): IDAlluser => {
    switch (action.type) {
        case USER_TABLE_REQUEST:
            return {
                ...state,
                loading: true,
                error: false,
                message: '',
                userData: null,
            };
        case USER_TABLE_SOUCCES:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: false,
                message: '',
            };
        case USER_TABLE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                userData: null,
            };
        case USER_TABLE_RESET:
            return {
                loading: false,
                error: false,
                message: '',
                userData: null,
            };
        default:
            return state;
    }
};
