import {IUpdateCompanyData, IUpdateCompanyDataRedux} from '@MyCompany/Interface';
import {UPDATE_COMPANY_DATA, GET_COMPANY_DATA} from '@MyCompany/Redux/Types/types';

const initialState: IUpdateCompanyData = {
    updateCompany: null,
    loading: false,
    error: false,
    companyData: null,
    loadingInfo: false,
    errorInfo: false,
    wasDataCompanyUpdated: false,
};

export const updateCompanyData = (
    state = initialState,
    action: IUpdateCompanyDataRedux
): IUpdateCompanyData => {
    switch (action.type) {
        case UPDATE_COMPANY_DATA.START_DATA:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case UPDATE_COMPANY_DATA.SUCCESS_DATA:
            return {
                ...state,
                updateCompany: action.updateCompany,
                loading: false,
                error: false,
                wasDataCompanyUpdated: true,
            };
        case UPDATE_COMPANY_DATA.ERROR_DATA:
            return {
                ...state,
                error: true,
                loading: false,
            };
        case UPDATE_COMPANY_DATA.COMPANY_NOT_UPDATED:
            return {
                ...state,
                wasDataCompanyUpdated: false,
            };
        case GET_COMPANY_DATA.START_DATA_EXIST:
            return {
                ...state,
                loadingInfo: true,
                errorInfo: false,
            };
        case GET_COMPANY_DATA.SUCCESS_DATA_EXIST:
            return {
                ...state,
                companyData: action.companyData,
                loadingInfo: false,
                errorInfo: false,
            };
        case GET_COMPANY_DATA.ERROR_DATA_EXIST:
            return {
                ...state,
                errorInfo: true,
                loadingInfo: false,
            };
        default:
            return state;
    }
};
