import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';

const DetailsQuoterConditions = () => {
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    return (
        <ContainerFlex
            FlexDir="column"
            Padding="24px"
            Radius="16px"
            backG="#fff"
            Border="1px solid #E8E9EA"
        >
            <ContainerFlex
                Justify="space-between"
                Align="start"
                PaddingB={isExpanded ? '24px' : '0px'}
            >
                <Text FontWeight="500" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.25rem">
                    {CREDIT_QUOTER.CONDITIONS_DETAILS.TITLE}
                </Text>
                <ButtonGenerals
                    SecondaryButton="true"
                    Width="40px"
                    Cursor="pointer"
                    Padding="16px"
                    MHeight="40px"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    <Image
                        src={
                            isExpanded
                                ? CREDIT_QUOTER.CONDITIONS_DETAILS.COLLAPSE_ICON
                                : CREDIT_QUOTER.CONDITIONS_DETAILS.EXPAND_ICON
                        }
                        Width="24px"
                        Height="24px"
                        alt="arrow-icon"
                    />
                </ButtonGenerals>
            </ContainerFlex>
            {isExpanded && (
                <ContainerFlex
                    PaddingT="24px"
                    Bt="1px solid #E8E9EA"
                    FlexDir="column"
                    Height="fit-content"
                    Justify="start"
                    Align="start"
                >
                    <Text oFlow="normal" wSpace="normal">
                        {CREDIT_QUOTER.CONDITIONS_DETAILS.TEXT}
                    </Text>
                    <Text oFlow="normal" wSpace="normal">
                        {CREDIT_QUOTER.CONDITIONS_DETAILS.TEXT2}
                    </Text>
                    <Text oFlow="normal" wSpace="normal">
                        {CREDIT_QUOTER.CONDITIONS_DETAILS.TEXT3}
                    </Text>
                    <Text oFlow="normal" wSpace="normal">
                        {CREDIT_QUOTER.CONDITIONS_DETAILS.TEXT4}
                    </Text>
                    <Text oFlow="normal" wSpace="normal">
                        {CREDIT_QUOTER.CONDITIONS_DETAILS.TEXT5}
                    </Text>
                    <Text oFlow="normal" wSpace="normal">
                        {CREDIT_QUOTER.CONDITIONS_DETAILS.TEXT6}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default DetailsQuoterConditions;
