import {
    TOGGLE_FILTER_MODAL,
    SET_FILTER_VALUES,
    SET_VISUAL_FILTER_VALUES,
    CLEAR_FILTERS_VALUES,
    GET_LOAN_LIST_START,
    GET_LOAN_LIST_SUCCESS,
    GET_LOAN_LIST_ERROR,
    RESET_MEDIA_LOAN_INFO,
    SET_FILTER_LIST,
} from '@/components/GlobalQuoter/Redux/Types/Types';
import {IGlobaQuoterFilterValues} from '@/components/GlobalQuoter/interface';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const toggleFilterModal = () => {
    return {
        type: TOGGLE_FILTER_MODAL,
    };
};

export const setFiltersValues = (filterValues: IGlobaQuoterFilterValues) => {
    return {
        type: SET_FILTER_VALUES,
        filterValues,
    };
};

export const setVisualFiltersValues = (filterValues: IGlobaQuoterFilterValues) => {
    return {
        type: SET_VISUAL_FILTER_VALUES,
        filterValues,
    };
};

export const clearFiltersValues = () => {
    return {
        type: CLEAR_FILTERS_VALUES,
    };
};

export const getLoanListStart = () => {
    return {
        type: GET_LOAN_LIST_START,
    };
};

export const getLoanListSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_LOAN_LIST_SUCCESS,
        payload,
    };
};

export const getLoanListError = (error: AxiosError) => {
    return {
        type: GET_LOAN_LIST_ERROR,
        error,
    };
};

export const resetMediaLoanInfo = () => {
    return {
        type: RESET_MEDIA_LOAN_INFO,
    };
};

export const setFilterList = (filterValues: IGlobaQuoterFilterValues) => {
    return {
        type: SET_FILTER_LIST,
        filterValues,
    };
};

export const getQuoterCompaniesLoans = (
    token: string,
    amountRequest: number,
    frequencyId: number,
    acountPayments: number
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getLoanListStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetQuoterCompaniesLoans}`,
                {headers, params: {amountRequest, frequencyId, acountPayments}}
            );
            dispatch(getLoanListSuccess(response));
        } catch (error) {
            dispatch(getLoanListError(error as AxiosError));
        }
    };
};
