import {
    UPDATE_DOCUMENTS_COPY_ARRAY,
    UPDATE_DOCUMENTS_COPY_ARRAY_RESET,
    SET_VISUAL_COPY_DOCUMENTS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IDocumentsCopyArray} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';

interface IUpdatePersonalDocumentsReducerInitialState {
    updatedDocuments: IDocumentsCopyArray[];
    visualCopyDocuments: IDocumentsCopyArray[];
}

const initialState: IUpdatePersonalDocumentsReducerInitialState = {
    updatedDocuments: [],
    visualCopyDocuments: [],
};

const UpdatePersonalDocumentsReducer = (
    state = initialState,
    action: {type: string; payload: IDocumentsCopyArray[]}
) => {
    switch (action.type) {
        case UPDATE_DOCUMENTS_COPY_ARRAY:
            return {
                ...state,
                updatedDocuments: action.payload,
            };
        case UPDATE_DOCUMENTS_COPY_ARRAY_RESET:
            return {
                ...state,
                updatedDocuments: [],
                visualCopyDocuments: [],
            };
        case SET_VISUAL_COPY_DOCUMENTS:
            return {
                ...state,
                visualCopyDocuments: action.payload,
            };
        default:
            return state;
    }
};

export default UpdatePersonalDocumentsReducer;
