import axios from 'axios';
import {
    GET_INFO_USER,
    GET_INFO_USER_SUCCESS,
    GET_INFO_USERS_ERROR,
} from '@components/Users/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';
export const getUserInfoResquest = () => {
    return {
        type: GET_INFO_USER,
    };
};
export const getUserInfoSuccess = (userData) => {
    return {
        type: GET_INFO_USER_SUCCESS,
        payload: userData,
    };
};
export const getUserInfoFailure = (err) => {
    return {
        type: GET_INFO_USERS_ERROR,
        payload: err,
    };
};

export const InfoUserdata = (token, userId, userProfile) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getUserInfoResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInformationUser}`,
                {
                    headers,
                    params: userId,
                    userProfile,
                }
            );
            dispatch(getUserInfoSuccess(response.data));
        } catch (error) {
            dispatch(getUserInfoFailure(error));
        }
    };
};
