import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image, Line} from '@Shopify/Ecommerce/styles';
import {PAYMENT_GATEWAY} from '@Loan/PaymentQuoter/constants';
import arrowIcon from '@images/arrowDark.svg';

const FulfillingSummary = () => {
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    return (
        <>
            {cartData?.discharges &&
                cartData.discharges.map((item) => (
                    <ContainerFlex
                        key={item.contractId}
                        FlexDir="column"
                        Gap="1rem"
                        Align="self-start"
                    >
                        <ContainerFlex Justify="space-between">
                            <ContainerFlex Gap="1rem" Justify="flex-start">
                                <Text
                                    Color="#2A2C2F"
                                    FontSize="1rem"
                                    FontWeight="700"
                                    LetterSpacing="0.019rem"
                                >
                                    {PAYMENT_GATEWAY.PURCHASE_SUMMARY}
                                </Text>
                                <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                    {PAYMENT_GATEWAY.ARTICLE_COUNT} {item.pledgeNumber}
                                </Text>
                            </ContainerFlex>
                            <Image
                                alt="arrow"
                                src={arrowIcon}
                                Width="1.5rem"
                                Height="1.5rem"
                                Transform="rotate(90deg)"
                            />
                        </ContainerFlex>
                        <ContainerFlex Gap="1rem">
                            <Image
                                src={item.imgUrl}
                                alt="article"
                                Cursor="pointer"
                                Width="3rem"
                                Height="3rem"
                            />
                            <ContainerFlex Gap="1rem" Justify="flex-start">
                                <ContainerFlex
                                    Radius="2rem"
                                    Padding="0.125rem 0.5rem"
                                    Height="1.25rem"
                                    Width="5.313rem"
                                    Border="1px solid #99E0FF"
                                    backG="#DBF4FF"
                                >
                                    <Text FontWeight="700" FontSize="0.75rem" Color="#004461">
                                        {PAYMENT_GATEWAY.FULFILLING}
                                    </Text>
                                </ContainerFlex>
                                <Text FontSize="0.875rem" FontWeight="400" Color="#54575C">
                                    {PAYMENT_GATEWAY.CONTRACT} {item.contractName}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <Line />
                    </ContainerFlex>
                ))}
        </>
    );
};

export default FulfillingSummary;
