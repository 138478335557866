import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import {NewGroup} from '@components/LocationsBranch/NewGroup';
import {NewBranchGroup} from '@components/LocationsBranch/NewBranchGroup';
import {HolidaysAdd} from '@components/LocationsBranch/Holidays/HolidaysAdd';
import {AddressAdd} from '@components/LocationsBranch/AddAddress/AddressAdd';
import {getSchedule} from '@components/LocationsBranch/Redux/Action/GetSchedule';
import {getHolidays} from '@components/LocationsBranch/Redux/Action/GetHolidays';
import {
    catTypeBusiness,
    catTypePlaces,
} from '@components/SignUp/Redux/Actions/catPlaceAndBusinessAction';
import {NewStorage} from '@components/LocationsBranch/NewStorage';
import {getLevelBreadcrumb} from '@components/LocationsBranch/Redux/Action/GelLevels';
import {GetUserByLevel} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import useToast from '@/hooks/useToast';
import {LOCATION_TYPE, TOAST} from '@components/LocationsBranch/constants';
import {useParams} from 'react-router-dom';

export const CreateNewGroup = () => {
    const {id} = useParams<{id?: string}>();
    const editId = id ? parseInt(id) : undefined;
    const dispatch: AppDispatch = useDispatch();
    const {showToast, ToastContainer} = useToast();
    const [modalAddress, setModalAddress] = useState(false);
    const [showHolidaysModal, setShowHolidaysModal] = useState(false);
    const {typeCreate, token, company, levels} = useSelector((state: RootState) => ({
        levels: state.getLevels.level,
        typeCreate: state.CreateNewGroup.typeSelected,
        token: String(state.getUsersValidation.userData?.token),
        company: Number(state.getUsersValidation.userData?.enterpriceDetails?.[0]?.enterpriceId),
    }));

    const handleClickAction = async () => {
        showToast({
            title: TOAST.TITLE_SUCCESS,
            message: TOAST.MESSAGE_SUCCESS,
            type: 'success',
            duration: 3000,
        });
    };

    const loadCataloges = () => {
        if (!editId && levels) {
            getDispatchesToLoad();
        }
    };

    const dispatchActions = () => ({
        [LOCATION_TYPE.NEWGROUP]: () => {
            dispatch(
                getLevelBreadcrumb(token, {
                    companyId: company,
                    typeCreation: typeCreate.locationId,
                    levelId: levels.levelId,
                })
            );
            dispatch(catTypeBusiness());
            dispatch(getHolidays(token, levels.levelId));
            dispatch(getSchedule(token, levels.levelId));
            dispatch(GetUserByLevel(token, levels.levelId));
        },
        [LOCATION_TYPE.NEWBRANCHGROUP]: () => {
            dispatch(
                getLevelBreadcrumb(token, {
                    companyId: company,
                    typeCreation: typeCreate.locationId,
                    levelId: levels.levelId,
                })
            );
            dispatch(catTypeBusiness());
            dispatch(catTypePlaces());
            dispatch(getHolidays(token, levels.levelId));
            dispatch(getSchedule(token, levels.levelId));
            dispatch(GetUserByLevel(token, levels.levelId));
        },
        [LOCATION_TYPE.NEWSTORAGE]: () => {
            dispatch(
                getLevelBreadcrumb(token, {
                    companyId: company,
                    typeCreation: typeCreate.locationId,
                    levelId: levels.levelId,
                })
            );
            dispatch(GetUserByLevel(token, levels.levelId));
        },
    });

    const getDispatchesToLoad = () => {
        const action = dispatchActions();
        if (action) {
            action[typeCreate.locationId as keyof typeof action]();
        }
    };

    useEffect(() => loadCataloges(), []);

    return (
        <>
            {typeCreate.locationId === 2 ? (
                <NewBranchGroup
                    setShowHolidaysModal={setShowHolidaysModal}
                    setModalAddress={setModalAddress}
                    branchId={editId}
                />
            ) : typeCreate.locationId === 1 ? (
                <NewGroup setShowHolidaysModal={setShowHolidaysModal} selectionId={editId} />
            ) : (
                <NewStorage setModalAddress={setModalAddress} storageId={editId} />
            )}

            {showHolidaysModal && (
                <HolidaysAdd
                    handleClickAction={handleClickAction}
                    showHolidaysModal={showHolidaysModal}
                    setShowHolidaysModal={setShowHolidaysModal}
                />
            )}

            {modalAddress && (
                <AddressAdd modalAddress={modalAddress} setModalAddress={setModalAddress} />
            )}

            <ToastContainer />
        </>
    );
};
