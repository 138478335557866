import {
    GET_ALL_ROLES_USER_REQUEST,
    GET_ALL_ROLES_USER_ROLES_SOUCCES,
    GET_ALL_ROLES_ROLES_USER_RROR,
    OPEN_ROLE_MODAL,
    CLOSE_ROLE_MODAL,
} from '@components/Users/UserProfile/Redux/types';
const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
    isModalOpen: false,
    selectedRole: null,
};
export const getRoleAllUser = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_ROLES_USER_REQUEST:
            return {...state, loading: true};
        case GET_ALL_ROLES_USER_ROLES_SOUCCES:
            return {...state, loading: false, userData: action.payload?.data || []};
        case GET_ALL_ROLES_ROLES_USER_RROR:
            return {...state, loading: false, error: action.payload.data.data};
        case OPEN_ROLE_MODAL:
            return {
                ...state,
                isModalOpen: true,
                selectedRole: action.payload,
            };
        case CLOSE_ROLE_MODAL:
            return {
                ...state,
                isModalOpen: false,
                selectedRole: null,
            };
        default:
            return state;
    }
};
