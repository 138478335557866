import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {LOAN_PAYMENT_INFO} from '@/components/GlobalQuoter/TempConstants';

const LoanPaymentInfo = () => {
    const {companiesCount, offertLoansCount, mediaAnualRate, monthtlyPayments} = useSelector(
        (state: RootState) => state.globalQuoterFilterRedcuer
    );
    return (
        <ContainerFlex
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
            Padding="16px"
            Radius="16px"
            Gap="16px"
            backG="#FFFFFF"
            Height="232px"
        >
            <ContainerFlex
                FlexDir="column"
                Justify="flex-start"
                Align="center"
                Radius="8px"
                Height="90px"
            >
                <Text
                    FontSize="1rem"
                    Color="#2A2C2F"
                    FontWeight="700"
                    FontFamily="Nunito"
                    LetterSpacing="0.3px"
                >
                    {LOAN_PAYMENT_INFO.ESTIAMTED_PAYMENT}
                </Text>
                <Text FontSize="3rem" Color="#1D1E20" FontWeight="700">
                    {numberToCurrencyWithoutCents(monthtlyPayments)}
                </Text>
            </ContainerFlex>
            <Text
                FontSize="1rem"
                Color="#2A2C2F"
                FontFamily="Nunito"
                LetterSpacing="0.3px"
                Self="center"
            >
                {`${LOAN_PAYMENT_INFO.ANUAL_RATE}${mediaAnualRate}%`}
            </Text>
            <ContainerFlex
                Justify="space-between"
                Padding="8px 16px"
                backG="#FAFAFA"
                Radius="16px"
                Height="54px"
            >
                <ContainerFlex Justify="flex-start" Align="center" FlexDir="column">
                    <Text
                        Color="#2A2C2F"
                        FontSize="0.875rem"
                        FontFamily="Nunito"
                        LetterSpacing="0.3px"
                        FontWeight="500"
                    >
                        {companiesCount}
                    </Text>
                    <Text FontSize="0.875rem" FontFamily="Nunito" Color="#54575C">
                        {LOAN_PAYMENT_INFO.COMPANY.LABEL}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="flex-start" Align="center" FlexDir="column">
                    <Text
                        FontSize="0.875rem"
                        Color="#2A2C2F"
                        FontFamily="Nunito"
                        LetterSpacing="0.3px"
                        FontWeight="500"
                    >
                        {offertLoansCount}
                    </Text>
                    <Text FontSize="0.875rem" FontFamily="Nunito" Color="#54575C">
                        {LOAN_PAYMENT_INFO.OFFERS.LABEL}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default LoanPaymentInfo;
