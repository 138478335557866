import {
    GET_ROLE_BY_ID,
    GET_ROLE_BY_ID_SUCCESS,
    GET_ROLE_BY_ID_ERROR,
    GET_ROLE_BY_ID_RESET,
} from '@components/RolesPermissions/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IRoleToEdit} from '@components/RolesPermissions/NewRole/interfaces';

type IinitialState = {
    loading: boolean;
    error: boolean;
    role: IRoleToEdit;
};

const initialState: IinitialState = {
    loading: false,
    error: false,
    role: {
        id: 0,
        name: '',
        estatus: false,
        typeRolId: 0,
        typeRole: '',
        quantityUsers: 0,
        description: '',
        users: [],
        modules: {
            adminModules: [
                {
                    moduleId: 0,
                    moduleName: '',
                    isEnabled: false,
                },
            ],
            operationalModules: [
                {
                    moduleId: 0,
                    moduleName: '',
                    isEnabled: false,
                },
            ],
        },
    },
};

const GetRoleByIdReducer = (
    state = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse}
) => {
    switch (action.type) {
        case GET_ROLE_BY_ID:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ROLE_BY_ID_SUCCESS:
            return {
                ...state,
                loading: false,
                role: action.payload.data.data,
            };
        case GET_ROLE_BY_ID_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case GET_ROLE_BY_ID_RESET:
            return {
                loading: false,
                role: initialState.role,
                error: false,
            };
        default:
            return state;
    }
};

export default GetRoleByIdReducer;
