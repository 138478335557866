import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ICreditDetailProps} from '@/components/CreditsAndPawns/interfaces';
import {CREDIT_DETAIL_PROFILE, FORMAT_DATE} from '@/components/CreditsAndPawns/constants';
import {statusColorHandler} from '@/components/CreditsAndPawns/ApplicationRow';
import {formatDate} from '@/hooks/currentFormatUtils';
import {DateTime} from 'luxon';

const CreditDetailContract: React.FC<ICreditDetailProps> = ({
    contractNumber,
    status,
    paymentFrequency,
    contractType,
    statusId,
    applicationDate,
    lastUpdatedDate,
    totalPaidQuotas,
    totalQuotas,
    applicationForm,
}) => {
    const {color, backgroundColor, borderColor} = statusColorHandler(Number(statusId));
    return (
        <ContainerFlex FlexDir="column" Gap="8px">
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.CONTRACT}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {contractNumber}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.REQUEST_DATE}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {formatDate(DateTime.fromISO(String(applicationDate)), FORMAT_DATE)}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.APPROVAL_DATE}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {formatDate(DateTime.fromISO(String(lastUpdatedDate)), FORMAT_DATE)}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.STATUS}
                </Text>
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="0.88rem"
                    Color={color}
                    bGround={backgroundColor}
                    Border={`solid 1px ${borderColor}`}
                    Padding="2px 8px"
                    BorderRadius="32px"
                >
                    {status}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.REQUEST_FORM}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {applicationForm}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.FEES}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {`${totalPaidQuotas}/${totalQuotas}`}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.PAYMENT_FREQUENCY}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {paymentFrequency}
                </Text>
            </ContainerFlex>
            <ContainerFlex Gap="4px" Justify="start" Align="center">
                <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#54575C">
                    {CREDIT_DETAIL_PROFILE.CONTRACT_TYPE}
                </Text>
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                    {contractType}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailContract;
