import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import {ContainerFlex, Modal, Text} from '@components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {CANCEL_PRODUCT} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ICancelProductModalProps} from '@components/PersonalLoans/NewLoanProduct/ui/interfaces';
import {AppDispatch} from '@/config/store';
import {resetLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {resetFrequencyPaymentTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {resetChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {resetDisbursementDocuments} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/DocumentDisbursementActions';
import {resetFrequencyPaymentsReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetFrequencyPaymentsActions';

export const CancelProductModal: React.FC<ICancelProductModalProps> = ({
    showCancelProductModal,
    setShowCancelProductModal,
}) => {
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const handleCancel = () => {
        navigate('/LoansProducts');
        dispatch(resetLoanSubmittedData());
        dispatch(resetFrequencyPaymentTable());
        dispatch(resetChargesAndPenalties());
        dispatch(resetDisbursementDocuments());
        dispatch(resetFrequencyPaymentsReducer());
    };
    return (
        <Modal BackC="rgba(0, 0, 0, 0.40)">
            <ContainerFlex
                backG="#FFF"
                Radius="1.5rem"
                BoxShadow="0px 10px 60px 0px"
                Padding="1.5rem"
                Width="600px"
                Height="max-content"
                FlexDir="column"
                Align="start"
            >
                <Text Color="#2A2C2F" FontWeight="700" FontSize="1.5rem" MarginB="0.75rem">
                    {CANCEL_PRODUCT.TITLE}
                </Text>
                <Text Color="#000" Padding="0.75rem 0px 1.5rem 0px" wSpace="wrap">
                    {CANCEL_PRODUCT.DESCRIPTION}
                </Text>
                <ContainerFlex PaddingT="0.75rem" Gap="1rem">
                    <ButtonGenerals
                        SecondaryButton="#FFFFFF"
                        FontWeight="700"
                        Width="191px"
                        type="button"
                        onClick={() => setShowCancelProductModal(!showCancelProductModal)}
                    >
                        {CANCEL_PRODUCT.BTN_CANCEL}
                    </ButtonGenerals>
                    <ButtonGenerals
                        BackGC="#A82424"
                        FontWeight="700"
                        type="button"
                        onClick={() => handleCancel()}
                    >
                        {CANCEL_PRODUCT.BTN_CONTINUE_CANCEL}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
