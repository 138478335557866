import {
    GET_CAT_SUBFAMILY,
    GET_CAT_SUBFAMILY_ERROR,
    GET_CAT_SUBFAMILY_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {AxiosError, AxiosResponse} from 'axios';
import {IDATAFACE} from '@components/RequestNewCatalogLevel/interfacesTree';
export interface ICatSubFamily {
    loading: boolean;
    error: boolean;
    catSubfamily: IDATAFACE[];
}
const initialState: ICatSubFamily = {
    loading: false,
    catSubfamily: [
        {
            id: 0,
            nameGroup: '',
            nameFamily: '',
            nameBrand: '',
            nameSubFamily: '',
            name: '',
            status: false,
            markId: 0,
            children: [],
            iconUrl: '',
        },
    ],
    error: false,
};

export const getSubfamilyAuctionReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_CAT_SUBFAMILY:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_CAT_SUBFAMILY_SUCCESS:
            return {
                ...state,
                loading: false,
                catSubfamily: action.payload.data.data,
            };
        case GET_CAT_SUBFAMILY_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
