import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {CREDIT_DETAIL_HEADER, STYLE_MESSAGE_SETUP_MAP} from '@components/CreditsAndPawns/constants';
import {useNavigate} from 'react-router-dom';
import arrowLeft from '@components/CreditsAndPawns/icons/largeLeftArrowIcon.svg';
import {routers} from '@/appRoute';
import CreditDetailMessage from '@components/CreditsAndPawns/CreditDetail/CreditDetailMessage';
import {ICreditStatusID} from '@components/CreditsAndPawns/interfaces';

const CreditDetailHeader: React.FC<ICreditStatusID> = ({statusId}) => {
    const navigate = useNavigate();
    const msgSetup = STYLE_MESSAGE_SETUP_MAP.get(Number(statusId));
    return (
        <ContainerFlex FlexDir="column" Align="start" Gap="1.0rem">
            <ContainerFlex FlexDir="row" Align="start" Justify="start" Gap="0.5rem">
                <ContainerFlex
                    FlexDir="row"
                    Justify="start"
                    Gap="8px"
                    Cursor="pointer"
                    onClick={() => navigate(routers.CreditsAndPawns)}
                >
                    <Image
                        Width="24px"
                        Height="24px"
                        src={arrowLeft}
                        alt={CREDIT_DETAIL_HEADER.ALT}
                    />
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color="#5A5AFF"
                        Cursor="pointer"
                    >
                        {CREDIT_DETAIL_HEADER.CTA_BACK_TO}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="row" Align="start" Justify="start">
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.75rem"
                    Color="#2A2C2F"
                    TextAlign="center"
                >
                    {CREDIT_DETAIL_HEADER.TITLE}
                </Text>
            </ContainerFlex>
            {msgSetup && <CreditDetailMessage {...msgSetup} />}
        </ContainerFlex>
    );
};

export default CreditDetailHeader;
