import {AxiosResponse} from 'axios';
import {
    GET_PASSWORD_RANDOM_START,
    GET_PASSWORD_RANDOM_SUCCESS,
    GET_PASSWORD_RANDOM_ERROR,
    RESET_RANDOM_PASSWORD,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {IGetPasswordRandomReducer} from '@components/NewUserEmployee/Redux/interfaces';
const initialState: IGetPasswordRandomReducer = {
    data: '',
    loading: false,
    error: false,
};
const getRandomPasswordReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetPasswordRandomReducer => {
    switch (action.type) {
        case GET_PASSWORD_RANDOM_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_PASSWORD_RANDOM_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_PASSWORD_RANDOM_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case RESET_RANDOM_PASSWORD:
            return initialState;
        default:
            return state;
    }
};
export default getRandomPasswordReducer;
