import {useDispatch} from 'react-redux';
import {Controller} from 'react-hook-form';
import React from 'react';
import {TableItem, TableRowItems} from '@/components/Shopify/Endeavors/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import informationIcon from '@components/PersonalLoans/NewLoanProduct/icons/informationIcon.svg';
import {IFrequencyTermRowProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {AppDispatch} from '@/config/store';
import {handleCheckboxFrequencyTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {PAYMENT_FREQUENCY} from '@components/PersonalLoans/NewLoanProduct/constants';
import {getPaymentCount} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/helpers/frequencyHelpers';

export const FrequencyTermRow: React.FC<IFrequencyTermRowProps> = ({
    index,
    control,
    tableLength,
    frecuancyId,
    frecuncyName,
    countPaymentMin,
    countPaymentMax,
    frequencyPaymentReducer,
    isSubmitted,
    trigger,
    isChecked,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const isDataEmpty = frequencyPaymentReducer.length === 0;
    const isIntegerAndNotZero =
        countPaymentMin % 1 === 0 &&
        countPaymentMax % 1 === 0 &&
        countPaymentMax !== 0 &&
        countPaymentMin !== 0;
    const isValidData = isDataEmpty || isIntegerAndNotZero;
    const minPaymentCount = getPaymentCount(isDataEmpty, isValidData, countPaymentMin);
    const maxPaymentCount = getPaymentCount(isDataEmpty, isValidData, countPaymentMax);
    return (
        <TableRowItems
            key={index}
            GridColumn="200px 203px 203px 1fr"
            Margin="0px"
            BorderL="none"
            BorderT="none"
            Border=""
            Radius=""
            BorderB={index === tableLength - 1 ? '' : '1px solid #E8E9EA'}
            Padding={index === tableLength - 1 ? '1rem 0px 0px 0px' : '1rem 0px'}
        >
            <TableItem Gap="0.5rem" Justify="start">
                {isValidData ? (
                    <Controller
                        name={`frequencyTerm[${index}].isChecked`}
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <Checkbox
                                {...field}
                                checked={isChecked}
                                onChange={(e) => {
                                    onChange(e);
                                    dispatch(handleCheckboxFrequencyTable(frecuancyId));
                                    if (isSubmitted) trigger('frequencyTerm');
                                }}
                            />
                        )}
                    />
                ) : (
                    <ContainerFlex
                        Width="24px"
                        Height="24px"
                        Padding="0.25rem"
                        Radius="0.5rem"
                        Border="1px solid #E8E9EA"
                        backG="#E8E9EA"
                    />
                )}
                <Text Color="#2A2C2F">{frecuncyName}</Text>
            </TableItem>
            <TableItem Justify="start">
                <Text Color="#2A2C2F">{minPaymentCount}</Text>
            </TableItem>
            <TableItem Justify="start">
                <Text Color="#2A2C2F">{maxPaymentCount}</Text>
            </TableItem>
            {!isValidData && (
                <TableItem Justify="start" Gap="0.5rem">
                    <Image
                        src={informationIcon}
                        Width="24px"
                        Height="24px"
                        alt="information-icon"
                    />
                    <Text Color="#2A2C2F">{PAYMENT_FREQUENCY.CHANGE_TERM_TIME}</Text>
                </TableItem>
            )}
        </TableRowItems>
    );
};
