import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useLocation} from 'react-router-dom';

import close from '@images/clousedGIcone.svg';
import success from '@images/SuccessIconGreen.svg';
import {TotalCount} from '@components/ShoppingCart/TotalCount';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {PrintTicket} from '@components/ShoppingCart/PrintTicket';
import {LocationQuoter} from '@components/ShoppingCart/Locations';
import CardsPayResume from '@components/ShoppingCart/CardsPayResume';
import {ContainerFlex, Image, Text, Line} from '@Shopify/Ecommerce/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import ContractCart from '@components/Loan/PaymentQuoter/ContractCart';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {IClienctContactPay} from '@/components/Shopify/Products/redux/interface';
import {FinishTransactionModal} from '@components/ShoppingCart/FinishTransactionModal';
import {useCloseTransaction} from '@/hooks/useCloseTransaction';

export const AlmostPayment = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const onCloseTransactionHandler = useCloseTransaction();
    const [showCloseTransactionWindow, setShowCloseTransactionWindow] = useState(false);
    const [contractSelected, setContractSelected] = useState<{label: string; value: string}>();
    const [contractsOptions, setContractsOptions] =
        useState<Array<{label: string; value: string}>>();

    const {summary, Contracts} = useSelector((state: RootState) => ({
        summary: state.cartPaymentReducer,
        Contracts: state.getCarContracts as IClienctContactPay,
    }));
    const summaryContracts = summary.paymentResult && summary.paymentResult.data;

    const cartPayData = summary.paymentResult!;
    const contractOptions =
        (cartPayData &&
            cartPayData.data.pawnsPay.map(({contractName, contractURL}) => ({
                label: contractName || '',
                value: contractURL || '',
            }))) ||
        [];

    useEffect(() => {
        const previousPath = localStorage.getItem('currentPath');

        if (previousPath === location.pathname) {
            onCloseTransactionHandler();
        }
        localStorage.setItem('currentPath', location.pathname);

        return () => {
            localStorage.removeItem('currentPath');
        };
    }, [location, dispatch]);

    const prepareContractsOptions = () => {
        const newContractOptions = [...contractOptions];
        const allContractsOption = newContractOptions.pop();
        if (newContractOptions.length > 1 && allContractsOption) {
            newContractOptions.unshift(allContractsOption);
            setContractSelected(allContractsOption);
        }
        setContractsOptions(newContractOptions);
    };

    const handlePrintReceipt = () => {
        if (!contractSelected) {
            return;
        }
        const {value} = contractSelected;
        window.open(value, '_blank');
    };

    const closeSummary = () => {
        if (
            summaryContracts &&
            ((summaryContracts.endorsementsPay && summaryContracts.endorsementsPay.length > 0) ||
                (summaryContracts.dischargesPay && summaryContracts.dischargesPay.length > 0))
        ) {
            onCloseTransactionHandler();
        } else setShowCloseTransactionWindow(true);
    };

    useEffect(() => {
        prepareContractsOptions();
    }, [cartPayData]);

    return (
        <>
            {!summary.loading && (
                <ContainerFlex Gap="1.5rem" FlexDir="column" Padding="1rem 0" Justify="start">
                    <FlexPanel
                        Justify="end"
                        Width="100%"
                        Gap="0.5rem"
                        onClick={() => closeSummary()}
                        Cursor="pointer"
                    >
                        <Image src={close} Width="1.5rem" Height="1.5rem" alt="close-icon" />
                        <Text FontSize="1rem" FontWeight="700" Color="#A82424" Cursor="pointer">
                            {SHOPPING_CART.CLOSE}
                        </Text>
                    </FlexPanel>
                    <FlexPanel Gap="1rem" Width="100%">
                        <Image
                            src={success}
                            Width="2.375rem"
                            Height="2.375rem"
                            alt="success-icon"
                        />
                        <Text FontSize="1.75rem" FontWeight="700">
                            {SHOPPING_CART.TRANSFER_SUCCESS}
                        </Text>
                    </FlexPanel>

                    <FlexPanel
                        Width="70%"
                        FlexDir="column"
                        Justify="start"
                        Padding="1rem"
                        Radius="1.5rem"
                        Border="1px solid #E8E9EA"
                        Gap="1rem"
                        backG="#FFF"
                    >
                        {Contracts &&
                        Contracts.data &&
                        Contracts.data.contractsList &&
                        Contracts.data.contractsList.length > 0 ? (
                            <ContainerFlex Justify="space-between">
                                <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                                    {SHOPPING_CART.REICIEVE}
                                </Text>
                                <ContainerFlex Width="20rem" Height="2.5rem" Gap="0.5rem">
                                    <SelectGeneral
                                        placeholder="Selecciona"
                                        isSearchable={false}
                                        options={contractsOptions}
                                        noOptionsMessage={() => 'Sin opciones'}
                                        value={contractSelected}
                                        onChange={(e) => {
                                            setContractSelected(
                                                e as {label: string; value: string}
                                            );
                                        }}
                                    />
                                    <ButtonGenerals
                                        Height="2rem"
                                        Width="6.25rem"
                                        Padding="0.25rem 1rem 0.25rem 1rem"
                                        Radius="1.5rem"
                                        Border="1px solid #5A5AFF"
                                        Cursor="pointer"
                                        FontSize="0.85rem"
                                        FontWeight="500"
                                        onClick={handlePrintReceipt}
                                    >
                                        {SHOPPING_CART.DOWNLOAD_RECIEVE}
                                    </ButtonGenerals>
                                </ContainerFlex>
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex Justify="space-between">
                                <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                                    {SHOPPING_CART.REICIEVE}
                                </Text>
                                <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                                    {SHOPPING_CART.REICIEVE_NUM}
                                </Text>
                            </ContainerFlex>
                        )}
                        <Line />
                        <CardsPayResume />
                        <Line />
                        {summaryContracts && (
                            <>
                                {Contracts &&
                                    Contracts.data &&
                                    Contracts.data.contractsList &&
                                    Contracts.data.contractsList.length > 0 && (
                                        <ContainerFlex
                                            Justify="start"
                                            Align="start"
                                            FlexDir="column"
                                            Radius="1.5rem"
                                        >
                                            {Contracts.data.contractsList.map((item, index) => {
                                                const contractName = `Contrato ${item.contractNumber}`;
                                                const matchedContract =
                                                    cartPayData.data.pawnsPay.find(
                                                        (contract) =>
                                                            contract.contractName === contractName
                                                    );
                                                const folio = matchedContract?.invoice || '';
                                                const isLastItem =
                                                    index ===
                                                    Contracts.data.contractsList.length - 1;
                                                return (
                                                    <ContractCart
                                                        contractsList={item}
                                                        key={index}
                                                        allowDetailExpand={false}
                                                        folio={folio}
                                                        contractKey={index}
                                                        lastItem={isLastItem}
                                                    />
                                                );
                                            })}
                                        </ContainerFlex>
                                    )}

                                {summaryContracts.endorsementsPay &&
                                    summaryContracts.endorsementsPay.length > 0 &&
                                    summaryContracts.endorsementsPay.map((endorment, index) => (
                                        <ContractCart endorsementsPay={endorment} key={index} />
                                    ))}
                                {summaryContracts.dischargesPay &&
                                    summaryContracts.dischargesPay.length > 0 &&
                                    summaryContracts.dischargesPay.map((dischar, index) => (
                                        <ContractCart dischargesPay={dischar} key={index} />
                                    ))}
                            </>
                        )}
                        <Line />
                        <TotalCount />
                        <Line />
                        {Contracts &&
                        Contracts.data &&
                        Contracts.data.contractsList &&
                        Contracts.data.contractsList.length > 0 ? (
                            <LocationQuoter />
                        ) : (
                            <PrintTicket summaryContracts={summaryContracts} />
                        )}
                    </FlexPanel>
                </ContainerFlex>
            )}
            {summary.loading && (
                <ContainerFlex>
                    <LoadingAtaskate width="15.625rem" padding="3.438rem" />
                </ContainerFlex>
            )}
            {showCloseTransactionWindow && (
                <FinishTransactionModal
                    showModal={showCloseTransactionWindow}
                    setShowModal={setShowCloseTransactionWindow}
                />
            )}
        </>
    );
};
