import {
    IFrequencyItem,
    IFrequencyPaymentTableReducer,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {
    SET_FREQUENCY_PAYMENTS_TABLE,
    HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE,
    RESET_FREQUENCY_TABLE,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';

const initialState: IFrequencyPaymentTableReducer = {
    frequencyPaymentsTable: [
        {
            frecuancyId: 3,
            frecuncyName: 'Semanal',
            countPaymentMin: 0,
            countPaymentMax: 0,
            isChecked: false,
        },
        {
            frecuancyId: 1,
            frecuncyName: 'Quincenal',
            countPaymentMin: 0,
            countPaymentMax: 0,
            isChecked: false,
        },
        {
            frecuancyId: 2,
            frecuncyName: 'Mensual',
            countPaymentMin: 0,
            countPaymentMax: 0,
            isChecked: false,
        },
        {
            frecuancyId: 4,
            frecuncyName: 'Bimestral',
            countPaymentMin: 0,
            countPaymentMax: 0,
            isChecked: false,
        },
        {
            frecuancyId: 5,
            frecuncyName: 'Trimestral',
            countPaymentMin: 0,
            countPaymentMax: 0,
            isChecked: false,
        },
        {
            frecuancyId: 6,
            frecuncyName: 'Semestral',
            countPaymentMin: 0,
            countPaymentMax: 0,
            isChecked: false,
        },
    ],
};

export const setFrequencyPaymentsTable = (
    state = initialState,
    action: {type: string; payload: IFrequencyItem[]; id: number}
): IFrequencyPaymentTableReducer => {
    switch (action.type) {
        case SET_FREQUENCY_PAYMENTS_TABLE: {
            return {
                ...state,
                frequencyPaymentsTable: action.payload.map((newItem) => {
                    const existingItem = state.frequencyPaymentsTable.find(
                        (item) => item.frecuancyId === newItem.frecuancyId
                    );
                    const isIntegerAndNotZero =
                        newItem.countPaymentMin % 1 === 0 &&
                        newItem.countPaymentMax % 1 === 0 &&
                        newItem.countPaymentMax !== 0 &&
                        newItem.countPaymentMin !== 0;
                    return {
                        ...newItem,
                        isChecked: existingItem
                            ? isIntegerAndNotZero
                                ? existingItem.isChecked
                                : false
                            : false,
                    };
                }),
            };
        }
        case HANDLE_CHECKBOX_FREQUENCY_PAYMENTS_TABLE: {
            return {
                ...state,
                frequencyPaymentsTable: state.frequencyPaymentsTable.map((item) => {
                    if (item.frecuancyId === action.id) {
                        return {
                            ...item,
                            isChecked: !item.isChecked,
                        };
                    }
                    return item;
                }),
            };
        }
        case RESET_FREQUENCY_TABLE: {
            return initialState;
        }
        default:
            return state;
    }
};

export default setFrequencyPaymentsTable;
