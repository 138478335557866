export const breadCrumb = {
    myCompany: 'Mi empresa',
    roles: 'Roles y permisos',
    newRole: 'Nuevo rol',
};

export const newRole = {
    name: 'Nombre',
    inputWarning: 'El nombre tiene que contener un mínimo de  2 caracteres.',
    permissions: 'Permisos',
    title: 'Permisos',
    subTitle:
        'Selecciona los sistemas operativos y los permisos de los módulos a los que este rol tendrá acceso.',
    admin: 'Administrador',
    operative: 'Operativo',
    existingRole: 'Gerente',
    roleNameError: 'El nombre de este rol ya existe. Por favor, elige un nombre diferente.',
    deleteRole: 'Eliminar Rol',
};

export const header = {
    name: 'Nombre',
    permissions: 'Permisos',
    assigned: 'Usuarios Asignados',
};

export const tooltip = {
    title: 'Selección de Accesos',
    subTitle:
        'Indica la cantidad total de accesos disponibles en cada menú y cuántos has seleccionado hasta ahora',
};

export const modulesTable = {
    placeHolder: 'Busque módulo o sub-módulo',
    expand: 'Expandir todo',
    module: 'Módulo',
    access: 'Acceso al módulo',
};

export const userList = {
    title: 'Asigna usuarios (Opcional)',
    subTitle: 'Selecciona y asigna los usuarios que deseas vincular a este rol.',
    placeHolder: 'Busque nombre de usuario',
    assign: 'Asignar',
    name: 'Nombre',
    role: 'Rol',
    lastActive: 'Activo hace',
};

export const buttonsCreateRole = {
    cancel: 'Cancelar',
    save: 'Crear Rol',
    edit: 'Guardar Cambios',
};

export const FIRST_PAGE = 1;
export const ITEM_PER_PAGE = 5;
export const MIN_SEARCH_LENGTH = 3;

export const searchInputProps = {
    Height: '40px',
    Position: 'none',
    GridColumn: '98% 2%',
    Margin: '0 0 4px 0',
    Padding: '0 1rem 0 0',
    BackG: '#FFFFFF',
    Radius: '32px',
    InputPadding: '0 1rem',
    Width: '90%',
};

export const containerProps = {
    FlexDir: 'column',
    Justify: 'start',
    Align: 'start',
    Border: '1px solid #E8E9EA',
    Radius: '16px',
};

export const tableRowItemProps = {
    GridColumn: '1fr .15fr',
    Border: 'none',
    Width: '100%',
    BorderTop: '1px solid #E8E9EA',
    Height: '4rem',
    BorderRadius: '0px',
};

export const sortByOptions: [number, string][] = [
    [1, 'name'],
    [2, 'roles'],
    [3, 'lastActive'],
];
export const sortNumbers = {
    name: 1,
    roles: 3,
    lastActive: 4,
};
