import styled from '@emotion/styled';

import {LoaderOverlayProps} from '@General/Atoms/LoadingOverlay/interfaces';

export const LoaderOverlay = styled.div<LoaderOverlayProps>`
    display: ${({isVisible}) => (isVisible ? 'grid' : 'none')};
    border-radius: ${({borderRadius}) => borderRadius};
    place-items: center;
    background-color: rgba(255, 255, 255, 0.53);
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 1;
`;

export const LoadingBox = styled.div`
    padding: 1rem;
    font-size: 1.25rem;
    color: #000;
`;

export const OverlayContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
    cursor: default;
`;

export const Content = styled.div`
    grid-column: 1;
    grid-row: 1;
`;
