import axios from 'axios';

import {ADRESS_USER, ADRESS_USER_SUCCESS, ADRESS_USERERROR} from '@components/Users/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';
export const getUserAdressResquest = () => {
    return {
        type: ADRESS_USER,
    };
};
export const getUserAdressSuccess = (userData) => {
    return {
        type: ADRESS_USER_SUCCESS,
        payload: userData,
    };
};
export const getUserAdressFailure = (err) => {
    return {
        type: ADRESS_USERERROR,
        payload: err,
    };
};

export const GetAdressUserInfo = (token, PostalCode) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(getUserAdressResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPostalInfo}`,
                {
                    headers,
                    params: {PostalCode},
                }
            );
            dispatch(getUserAdressSuccess(response.data));
        } catch (error) {
            dispatch(getUserAdressFailure(error));
        }
    };
};
