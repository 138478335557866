import React, {useState, useEffect, useRef, useCallback} from 'react';
import {
    OptionsMenu,
    SelectContainer,
    SelectField,
    Option,
} from '@/components/MyCompanyUser/Operational/style';
import {CustomSelectProps} from '@/components/CashFlowActions/Operational/interfaces';
import {BTN} from '@/components/CashFlowActions/Operational/Constants';
import {ImageLogo} from '@/components/MarketLogin/Styles';
import arrow from '@images/arrowBottom.svg';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';

export const CustomSelect = ({options, value, onChange, disabled}: CustomSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<string>(value);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setSelectedOption(value);
    }, [value]);

    const handleSelect = useCallback(
        (optionValue: string) => {
            setSelectedOption(optionValue);
            onChange(optionValue);
            setIsOpen(false);
        },
        [onChange]
    );

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    const displayText = options.find((option) => option.value === selectedOption)?.label || (
        <Text>{BTN.SELECT}</Text>
    );

    return (
        <SelectContainer ref={selectRef}>
            <SelectField onClick={() => !disabled && setIsOpen(!isOpen)} isOpen={isOpen}>
                <Text Color={selectedOption ? '#000' : '#AAA'}>{displayText}</Text>
                <ImageLogo width="24px" Height="24px" src={arrow} alt="icone-arrow" />
            </SelectField>
            <OptionsMenu isOpen={isOpen}>
                {options.map((option) => (
                    <Option
                        key={option.value}
                        isSelected={selectedOption === option.value}
                        onClick={() => handleSelect(option.value)}
                    >
                        <Checkbox
                            checked={selectedOption === option.value}
                            onChange={(e) => {
                                e.stopPropagation();
                                handleSelect(option.value);
                            }}
                        />
                        <Text PaddingL="4px">{option.label}</Text>
                    </Option>
                ))}
            </OptionsMenu>
        </SelectContainer>
    );
};
