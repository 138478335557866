import styled from '@emotion/styled';
import {
    IPropsIconContainer,
    IPropsIconPrincipalContainer,
    IPropsMenuItem,
    IPropsMenuPrincipalItem,
} from '@MenuAmazon/interfacesStyles';

export const MenuItem = styled.div<IPropsMenuItem>`
    display: flex;
    justify-content: ${(props) => props.Justify};
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    align-items: ${(props) => props.Align};
    flex-direction: ${(props) => props.FlexDir};
    box-sizing: border-box;
    margin: ${(props) => props.Margin};
    cursor: ${(props) => props.Cursor};
    &:hover {
        background-color: ${(props) => props.HBackColor};
        cursor: pointer;
    }
    span {
        font-weight: normal;
        color: #414042;
        font-size: 0.875rem;
        padding: 0 16px 0 10px;
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        font-weight: normal;
        color: #414042;
        font-size: 0.875rem;
        padding: 0 16px 0 10px;
    }
`;
MenuItem.defaultProps = {
    Height: '40px',
    Padding: '0 0 0 8px',
    Align: 'center',
    Justify: 'center',
    Width: 'auto',
    HBackColor: '#ebfbed',
};
export const MenuContainer = styled.div`
    flex-direction: Column;
    justify-content: flex-start;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #e5e7e9;
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
`;
export const IconContainer = styled.img<IPropsIconContainer>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    cursor: pointer;
    box-sizing: border-box;
`;
IconContainer.defaultProps = {
    width: '24px',
    height: '24px',
};
export const IconContainerMenu = styled.img`
    width: 16px;
    height: 16px;
`;

export const MenuPrincipalItem = styled.div<IPropsMenuPrincipalItem>`
    display: flex;
    justify-content: flex-start;
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};
    width: 100%;
    align-items: ${(props) => props.Align};
    flex-direction: ${(props) => props.FlexDir};
    &:hover {
        background-color: #ebfbed;
        cursor: pointer;
    }
    span {
        font-weight: normal;
        color: #414042;
        font-size: 0.875rem;
        padding: 0 16px 0 10px;
    }
    a {
        text-decoration: none;
    }
`;
MenuItem.defaultProps = {
    Height: '40px',
    Padding: '0 8px',
    Align: 'center',
};
export const MenuPrincipalContainer = styled.div`
    flex-direction: Column;
    justify-content: flex-start;
    height: 100%;
    border-radius: 4px;
    border: 1px solid #e5e7e9;
    position: fixed;
    background-color: #ffffff;
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.5);
`;
export const IconPrincipalContainer = styled.img<IPropsIconPrincipalContainer>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    cursor: pointer;
`;
IconContainer.defaultProps = {
    width: '24px',
    height: '24px',
};
export const IconContainerMenuPrincipal = styled.img`
    width: 16px;
    height: 16px;
`;
