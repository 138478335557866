import styled from '@emotion/styled';
import Accordion from '@/components/SideBarMenu/Accordion/Accordion';
import AccordionSummary from '@/components/SideBarMenu/Accordion/AccordionSummary';
import AccordionDetails from '@/components/SideBarMenu/Accordion/AccordionDetails';
import {IAccordionSideBarProps} from '@/components/SideBarMenu/Accordion/interfaces';

export const AccordionSideBarStyled = styled(Accordion)<IAccordionSideBarProps>`
    width: ${(props) => props.Width || '100%'};
    height: ${(props) => props.Height || 'auto'};
    flex-direction: ${(props) => props.FlexDir || 'column'};
`;

export const AccordionSummarySideBarStyled = styled(AccordionSummary)<IAccordionSideBarProps>`
    width: ${(props) => props.Width || '100%'};
    height: ${(props) => props.Height || '40px'};
    flex-direction: ${(props) => props.FlexDir || 'row'};
    gap:${(props) => props.Gap || '8px'};
    padding:${(props) => props.Padding || '8px'};
    border-left:${(props) => props.Bl}; 
    background-color:${(props) => props.backG};

    &:hover {
    border-left: 3px solid #5A5AFF;
    background-color: #F0F0FF};
    }
`;

export const AccordionDetailsSideBarStyled = styled(AccordionDetails)<IAccordionSideBarProps>`
    width: ${(props) => props.Width || '100%'};
    flex-direction: ${(props) => props.FlexDir || 'column'};
    padding: ${(props) => props.Padding || '0px 8px'};
`;
