import React from 'react';
import {Text, ContainerFlex} from '@Shopify/Ecommerce/styles';
import {Controller} from 'react-hook-form';
import {FormFieldDatePickerProps} from '@components/AccountSettings/interfaces';
import {monthsString as months} from '@General/Atoms/CalendarDateSelection/constants';
import {DATE_FORMATS} from '@components/Shopify/ListClient/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

const FormDatePickerField: React.FC<FormFieldDatePickerProps> = ({
    name,
    placeholder,
    format,
    isDate,
    fontSize,
    label,
    error,
    control,
    outputType = 'string',
}) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: 11}, (_, i) => ({
        value: currentYear + i,
        label: (currentYear + i).toString(),
    }));

    const getOption = (
        options: {value: number | string; label: string}[],
        value: string | number
    ) => {
        return (
            options.find((option) => {
                if (outputType === 'number') {
                    return Number(option.value) === Number(value);
                }
                return option.value.toString() === value.toString();
            }) || null
        );
    };

    return (
        <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px" Height="auto" Flex="1 0 0">
            <Text FontSize={fontSize ? fontSize : '0.75rem'} FontWeight="700" Color="#6D6E71">
                {label}
            </Text>
            <ContainerFlex Justify="center" Align="center">
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => (
                        <>
                            {format === DATE_FORMATS.YEAR && (
                                <SelectGeneral
                                    {...field}
                                    value={getOption(years, field.value) || null}
                                    options={years}
                                    onChange={(selectedOption) => {
                                        const newValue =
                                            outputType === 'number'
                                                ? selectedOption?.value
                                                : selectedOption?.value.toString();
                                        field.onChange(newValue);
                                    }}
                                    isDate={isDate}
                                    placeholder={placeholder}
                                    hasError={!!error}
                                />
                            )}
                            {format === DATE_FORMATS.MONTH && (
                                <SelectGeneral
                                    {...field}
                                    value={getOption(months, field.value) || null}
                                    options={months}
                                    isDate={isDate}
                                    onChange={(selectedOption) => {
                                        const newValue =
                                            outputType === 'number'
                                                ? selectedOption?.value
                                                : selectedOption?.value.toString();
                                        field.onChange(newValue);
                                    }}
                                    placeholder={placeholder}
                                    hasError={!!error}
                                />
                            )}
                        </>
                    )}
                />
            </ContainerFlex>
            {error && (
                <Text Color="#FF6357" FontSize="12px" wSpace="normal">
                    {error}
                </Text>
            )}
        </ContainerFlex>
    );
};

export default FormDatePickerField;
