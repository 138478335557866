import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import PaymentAndBillingModal from '@/components/AccountSettings/PaymentAndBillingModal';
import PaymentRecordOptions from '@/components/AccountSettings/PaymentRecordOptions';
import {GetPaymentMethod} from '@/components/AccountSettings/Redux/Actions/GetCompanyPaymentMethod';
import {IPaymentMethod} from '@/components/AccountSettings/Redux/interfaces';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@EcommerceShopify/styles';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {
    MAX_PAYMENT_METHODS_QUANTITY,
    PAYMENT_AND_BILLING,
} from '@components/AccountSettings/constants';
import infoIcon from '@components/Branches/Images/InfoIcon.svg';
import plusIcon from '@components/Branches/Images/PlusIcon.svg';
import MoreOptions from '@components/General/Atoms/RecordMoreOptions/MoreOptions';
import {deletePaymentMethodAction} from '@components/AccountSettings/Redux/Actions/DeletePaymentMethodAction';
import {PutPaymentDefaultAction} from '@components/AccountSettings/Redux/Actions/PutPaymentDefaultAction';

const MyPaymentMethods = () => {
    const dispatch = useDispatch();
    const [showNewPaymentWindow, setShowNewPaymentWindow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState<IPaymentMethod | null>(null);

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const paymentMethods = useSelector(
        (state: RootState) => state.GetCompanyPaymentMethod?.paymentMethods
    );

    const addPaymentErr = useSelector((state: RootState) => state.AddCompanyPaymentMethod.error);

    const handleSuccess = () => {
        if (!addPaymentErr) {
            setShowNewPaymentWindow(false);
        }
    };

    const handleDelete = async (id: number) => {
        if (token && companyId) {
            setLoading(true);
            try {
                await dispatch(deletePaymentMethodAction(id, token));
                dispatch(GetPaymentMethod(companyId, token));
            } catch (error) {
                return error;
            } finally {
                setLoading(false);
            }
        }
    };

    const setPaymentDefault = async (id: number) => {
        if (token) {
            setLoading(true);
            try {
                await dispatch(PutPaymentDefaultAction(id, token));
                dispatch(GetPaymentMethod(companyId, token));
            } catch (error) {
                return error;
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (token && companyId) {
            dispatch(GetPaymentMethod(companyId, token));
        }
    }, [dispatch, token, companyId]);

    return (
        <>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                Padding="16px"
                FlexDir="column"
                Gap="16px"
                Radius="16px"
                backG="#FFFF"
                Height="fit-content"
            >
                <Text FontSize="1.25rem" oFlow="visible" FontWeight="700" Color="#2A2C2F">
                    {PAYMENT_AND_BILLING.MY_METHODS}
                </Text>

                {!paymentMethods || paymentMethods.length === 0 ? (
                    <ContainerFlex backG="#DBF4FF" Padding="8px" Radius="8px" Align="center">
                        <ContainerFlex Justify="start" Align="center" Gap="8px" Flex="1 0 0">
                            <Image src={infoIcon} alt="add" Width="20px" Height="20px" />
                            <Text FontSize="1rem" FontWeight="500" Color="#004461">
                                {PAYMENT_AND_BILLING.MISSING_PAYMENT_PROMPT}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                ) : loading ? (
                    <ContainerFlex>
                        <Loading />
                    </ContainerFlex>
                ) : (
                    paymentMethods.map((item) => (
                        <ContainerFlex key={item.paymentId}>
                            <ContainerFlex Gap="8px" Align="center" Justify="start">
                                <Image
                                    src={item.cardTypeUrl}
                                    alt="type"
                                    Width="32px"
                                    Height="32px"
                                    Border="1px solid #F4F5F5"
                                    Radius="1.5rem"
                                />
                                <Text FontSize="1rem" FontWeight="500" Color="#2A2C2F">
                                    {item.cardNumber.slice(1)}
                                </Text>
                                {item.default && (
                                    <ContainerFlex
                                        Justify="center"
                                        Align="center"
                                        Radius="32px"
                                        Padding="2px 8px"
                                        Height="18px"
                                        Width="85px"
                                        Border="1px solid #B1E7C9"
                                        backG="#E4F7EC"
                                    >
                                        <Text FontWeight="500" FontSize="0.75rem" Color="#174A2E">
                                            {PAYMENT_AND_BILLING.PRINCIPAL}
                                        </Text>
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                            {!item.default && (
                                <ContainerFlex
                                    Justify="center"
                                    Align="center"
                                    Width="24px"
                                    Cursor="pointer"
                                    onClick={() => setSelectedPayment(item)}
                                >
                                    <MoreOptions
                                        isOpen={selectedPayment === item}
                                        onOptionClick={() => {
                                            setSelectedPayment(null);
                                        }}
                                    >
                                        <PaymentRecordOptions
                                            id={item.paymentId}
                                            handleDelete={handleDelete}
                                            setPaymentDefault={setPaymentDefault}
                                        />
                                    </MoreOptions>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    ))
                )}

                {paymentMethods.length < MAX_PAYMENT_METHODS_QUANTITY && (
                    <ContainerFlex
                        Cursor="pointer"
                        Justify="start"
                        Align="center"
                        Width="fit-content"
                        Gap="8px"
                        onClick={() => setShowNewPaymentWindow(true)}
                    >
                        <Image src={plusIcon} alt="down-arrow" Width="20px" Height="20px" />
                        <Text FontSize="1rem" FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                            {PAYMENT_AND_BILLING.ADD_PAYMENT_METHOD}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>

            <PaymentAndBillingModal
                modalFormType="paymentMethod"
                showModal={showNewPaymentWindow}
                setShowModal={setShowNewPaymentWindow}
                onSuccess={handleSuccess}
            />
        </>
    );
};

export default MyPaymentMethods;
