import React from 'react';

import * as style from '@/components/LocationsBranch/style';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {IInheritModalProps} from '@components/LocationsBranch/interfaces';
import {InheritAttributes} from '@components/LocationsBranch/InheritAttributes';

export const InheritFromModal: React.FC<IInheritModalProps> = ({
    type,
    showModal,
    setShowModal,
    onClickTransfer,
}) => {
    return (
        <Modal
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            {...style.modalContent}
            background="rgb(0 0 0 / 60%)"
            closeOnOutsideClick={false}
        >
            <InheritAttributes
                setShowModal={setShowModal}
                type={type}
                onClickTransfer={onClickTransfer}
            />
        </Modal>
    );
};
