import {ImageLogo} from '@components/LoginClient/styles';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import deleteRole from '@images/deleteRole.svg';
import duplicateRole from '@images/duplicateRole.svg';
import editRole from '@images/editRole.svg';
import editRoleName from '@images/editRoleName.svg';
import {ClickAwayListener} from '@mui/material';
import React from 'react';
import {sideMenu} from '@components/RolesPermissions/constants';
import {useNavigate} from 'react-router-dom';

type SideMenuProps = {
    setShowSideMenu: (showSideMenu: boolean) => void;
    setShowDeleteMenu: (showDeleteMenu: boolean) => void;
    handleEditClick: (id: number) => void;
    handleDuplicateClick: (id: number) => void;
    id: number;
};

const SideMenu = ({
    setShowSideMenu,
    setShowDeleteMenu,
    handleEditClick,
    id,
    handleDuplicateClick,
}: SideMenuProps) => {
    const navigate = useNavigate();

    const handleEditRole = (id: number) => {
        navigate('/RolesAndPermissions/EditRole');
    };

    return (
        <ClickAwayListener onClickAway={() => setShowSideMenu(false)}>
            <ContainerFlex
                FlexDir="column"
                Position="absolute"
                PositionTop="2.6rem"
                PositionRight="5rem"
                backG="white"
                Height="10rem"
                Width="12rem"
                Align="start"
                BoxS="rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;"
                Padding="5px"
                Radius="8px"
                ZIndex="1"
            >
                <ContainerFlex
                    Gap="10px"
                    Justify="start"
                    Padding="5px"
                    Cursor="pointer"
                    onClick={() => {
                        handleDuplicateClick(id);
                        setShowSideMenu(false);
                    }}
                >
                    <ImageLogo src={duplicateRole} Height="20px" Width="20px" alt="duplicateRole" />
                    <Text Cursor="pointer">{sideMenu.Duplicate}</Text>
                </ContainerFlex>
                <ContainerFlex
                    Gap="10px"
                    Justify="start"
                    Padding="5px"
                    onClick={() => handleEditRole(id)}
                >
                    <ImageLogo src={editRole} Height="20px" Width="20px" alt="editRole" />
                    <Text Cursor="pointer">{sideMenu.Edit}</Text>
                </ContainerFlex>
                <ContainerFlex
                    Gap="10px"
                    Justify="start"
                    Padding="5px"
                    onClick={() => {
                        handleEditClick(id);
                        setShowSideMenu(false);
                    }}
                >
                    <ImageLogo src={editRoleName} Height="20px" Width="20px" alt="editRoleName" />
                    <Text Cursor="pointer">{sideMenu.ChangeName}</Text>
                </ContainerFlex>
                <ContainerFlex
                    Gap="10px"
                    Justify="start"
                    Padding="5px"
                    onClick={() => {
                        setShowDeleteMenu(true);
                        setShowSideMenu(false);
                    }}
                >
                    <ImageLogo src={deleteRole} Height="20px" Width="20px" alt="deleteRole" />
                    <Text Cursor="pointer">{sideMenu.Delete}</Text>
                </ContainerFlex>
            </ContainerFlex>
        </ClickAwayListener>
    );
};

export default SideMenu;
