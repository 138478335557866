import {AxiosError, AxiosResponse} from 'axios';
import {
    PUT_UPDATE_ROLE_STATUS,
    PUT_UPDATE_ROLE_STATUS_ERROR,
    PUT_UPDATE_ROLE_STATUS_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';

const initialState = {
    loading: false,
    error: false,
};

const PutUpdateRoleStatusReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case PUT_UPDATE_ROLE_STATUS:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case PUT_UPDATE_ROLE_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case PUT_UPDATE_ROLE_STATUS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default PutUpdateRoleStatusReducer;
