import {
    AccSchedule,
    Accumulator,
    IHoursSchedule,
    IHoursHolidays,
} from '@components/ScheduleTable/interfaces/interfaces';
import {IHolidays, ISchedule, IStorageActionOption} from '@components/LocationsBranch/interfaces';

import {IUsersPaginated} from '@components/LocationsBranch/interfaces';
import {FieldValues} from 'react-hook-form';

import TrashIcon from '@images/trashDanger.svg';
import DuplicateIcon from '@images/transfer.svg';
import EditIcon from '@images/editIcon.svg';
export const LOCATIONS_SELECT = {
    NEW_GROUP_BRANCH: 'Nuevo grupo, sucursal o bodega',
    TYPE_INSTRUCTION: 'Selecciona el tipo de elemento que quieres configurar',
    PLACEHOLDER_SEARCH: 'Busca un grupo o sucursal',
    TYPE: 'Tipo',
    NO_RECORDS_FOUND: 'No se encontraron coincidencias. Por favor, ingrese un texto diferente.',
};

export const SEARCH_LOCATIONS = {
    location: '',
    code: '',
    filter: {value: 1, label: 'Todas'},
};
export const ID_MEXICO = 151;

export const CAT_ADDRESS = [{value: 1, label: 'Tecámac'}];

export const LOCATIONGROUP = {
    TITLE: 'Locaciones',
    ADDGROUP: 'Grupos, sucursal o bodega',
    HIDDENGROUP: 'Ocultar estructura de grupos',
    SHOWGROUP: 'Mostrar estructura de grupos',
    SELECTGROUP:
        'Elige un grupo que incluya sucursales o bodegas, o crea uno para visualizarlas aquí.',
    EMPTY_SELECTGROUP: 'No hay grupos, comienza a crear tu estructura organizacional.',
    ELEMENTS_LABEL: 'Elmentos',
};

export const TYPE_LOCATION = {
    GROUP: {NAME: 'Group', TYPE: 1, STATUS: true},
    BRANCH: {NAME: 'Branch', TYPE: 2, STATUS: true},
    STORAGE: {NAME: 'Storage', TYPE: 3, STATUS: true},
};

export const INHERIT_TYPE = {
    USER: 'user',
    LOCATION: 'location',
    SCHEDULE: 'schedule',
};
export const NEWGROUP = {
    TITLE: 'Nuevo grupo',
    NAMEGROUP: 'Nombre de grupo',
    NAME: 'Nombre',
    ORGANIZATION: 'Estructura organizacional',
    LINEBUSINESS: 'Líneas de negocio y servicios',
    USERASSIGNMENT: 'Usuarios asignados ',
    OPTIONAL: '(opcional)',
    USERNAME: 'Nombre de usuario',
    ROL: 'Rol',
    LASTSESSION: 'Ultimo inicio de sesión',
    ADDUSER: 'Agregar usuario',
    NAME_FORM: 'nameGroup',
    NEW_NAME: 'Este nombre ya existe, por favor utilizar otro nombre',
    LINES: 'businessLines',
};

export const SCHEDULE = {
    TITLE: 'Horarios y días festivos ',
    SUBTITLE: 'Mismo horario para todos los servicios de la tienda',
    DAY: 'Día',
    STATE: 'Abierto',
    OPEN: 'Apertura',
    CLOSE: 'Cierre',
    UNABLED: 'Días festivos y/o inhabiles',
    SHOW: 'Mostrar',
    HIDE: 'Ocultar',
    ADD_DAY: 'Agregar día',
    OPTIONAL: '(opcional)',
};

export const STORAGE = {
    TITLE: 'Almacenamiento de inventario ',
    OPTIONAL: '(opcional)',
    STORAGE: 'Almacén',
    LEVEL: 'Nivel ',
    TYPE: 'Tipos de almacenamiento',
    ADD: 'Nuevo nivel',
    NEW_WAREHOUSE: 'Nuevo almacén',
    NEW: 'Nuevo',
    SEARCH: 'Busca ubicación de almacenamiento',
    HOME: 'Inicio',
    PRODUCTS: 'Artículos',
    NEW_FLOOR: 'Nuevo piso',
    NO_RECORDS_FOUND: 'No se encontraron ubicaciones',
};

export const NEWBRANCHGROUP = {
    TITLE: 'Nueva sucursal',
    NAMEGROUP: 'Nombre de sucursal',
    NAME: 'Nombre',
    ORGANIZATION: 'Estructura organizacional',
    LINEBUSINESS: 'Líneas de negocio y servicios',
    USERASSIGNMENT: 'Usuarios asignados ',
    OPTIONAL: '(opcional)',
    USERNAME: 'Nombre de usuario',
    ROL: 'Rol',
    LASTSESSION: 'Ultimo inicio de sesión',
    ADDUSER: 'Agregar usuario',
    NAME_FORM: 'nameBranch',
    ADDRESS_FORM: 'branchDirection',
    LINES: 'branchBusinessLine',
    CHANNELS: 'branchSalesChannel',
    ERROR_CHANNELS: 'Indique un canal de venta',
};

export const ADDRESS = {
    TITLE: 'Dirección',
    ADD: 'Agregar dirección',
    MODIFY: 'Modificar',
    ERR: 'Indique una dirección',
};
export const SELLCHANNEL = {
    TITLE: 'Canales de venta',
};

export const DayWeek = [
    {day: 'Lunes', id: 1, name: 'monday'},
    {day: 'Martes', id: 2, name: 'tuesday'},
    {day: 'Miércoles', id: 3, name: 'wednesday'},
    {day: 'Jueves', id: 4, name: 'thursday'},
    {day: 'Viernes', id: 5, name: 'friday'},
    {day: 'Sábado', id: 6, name: 'saturday'},
    {day: 'Domingo', id: 7, name: 'sunday'},
];

export const HOLIDAYS = {
    TITLE: 'Día festivo',
    EACH_YEAR: 'Cada año',
    OPENING: 'Apertura',
    ENDING: 'Cierre',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    REMOVE: 'Remover',
};
export const getNamesforHoliday = (somedays: IHolidays[]) => {
    return (
        somedays &&
        somedays.reduce<Accumulator>((acc, item) => {
            acc[`${item.id}`] = {
                date: item.date,
                endActivities: item.endTime,
                reason: item.reason,
            };
            return acc;
        }, {})
    );
};

export const getNamesforSchedule = (schedule: ISchedule[]) => {
    return (
        schedule &&
        schedule.reduce<AccSchedule>((acc, item, index) => {
            acc[`${item.dayOfWeek}`] = {
                openingAttentionSchedule: {value: index + 1, label: item.openingTime},
                closingAttentionSchedule: {value: index + 1, label: item.closingTime},
                weekDay: item.dayOfWeek,
                dayName: item.dayName,
            };

            return acc;
        }, {})
    );
};

export const UserListPageSize = 5;

export const defaultCompanyUsersData: IUsersPaginated = {
    actualPage: 1,
    allUserData: [],
    error: false,
    loading: false,
    companyUsers: {
        data: [],
        pageNumber: 1,
        pageSize: UserListPageSize,
        totalPages: 1,
        totalItems: 0,
    },
};

export const DELUSER = {
    WQ_REMOVE: '¿Estás seguro remover este usuario?',
    CHANGE_ACCESS:
        'Cambiar estos valores puede alterar el acceso de tus usuarios a la ubicación, las operaciones, al inventario y a otros elementos críticos.',
};

export const USER_LIST = {
    DESC: 'desc',
    ASC: 'asc',
    EMPTY: '',
    EMPTY_USERS: 'No se encontraron usuarios.',
};

export const ADDRESS_MODAL = {
    TITLE: 'Dirección',
    POSTAL_CODE: 'Código postal',
    STREET: 'Calle',
    OUTERNUM: 'No. exterior',
    INETERNALNUM: 'No. interior',
    OPTIONAL: '(opcional)',
    COLONY: 'Colonia',
    MUNICIPALY: 'Alcaldía',
    STATE: 'Estado',
};
export const SCHEDULE_DEFAULT_VALUES = {
    switch: 'scheduleSwitch',
    opening: 'openingAttentionSchedule',
    closing: 'closingAttentionSchedule',
    weekDay: 'weekDay',
    dayName: 'dayName',
};

export const HOLIDAYS_DEFAULT_VALUES = {
    switch: 'holidaySwitch',
    opening: 'holidayOpening',
    closing: 'holidayEnding',
    reason: 'holidayReason',
};

export const INHERIT_FROM = {
    TITLE: 'Heredar',
    PROMPT: 'Selecciona un grupo, sucursal o bodega donde quieras heredar los atributos',
    CANCEL: 'Cancelar',
    TRANSFER: 'Transferir',
    SEARCH_PLACEHOLDER: 'Busca un grupo o sucursal',
    TYPE_USERS: 'Transferir usuarios',
    TYPE_INVENTORY: 'Transferir inventario',
    FROM: 'De',
    TO: 'A',
    NO_RESULTS: 'Parece que este nivel no existe',
};

export const ADD_USER_MODAL = {
    TITLE: 'Nuevo usuario',
    PLACEHOLDER: 'Busca a un usuario',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    NO_RESULTS: 'No se ha encontrado ningún usuario.',
};

export const SortDirection = {
    Asc: 'asc',
    Desc: 'desc',
};

export const ACCORDION_STYLES = {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:before': {
        display: 'none',
    },
};

export const INITIAL_STORAGE = {
    id: 1,
    warehouseId: 0,
    name: '',
    productQuantity: 0,
    levelId: 1,
    parentLevel: 0,
};
export const INITIAL_WAREHOUSE = {
    id: 1,
    name: 'Almacén',
};

export const MAX_LEVELS = 6;
export const FIXED_LEVEL = 2;
export const START_LEVEL = 2;
export const DEFAULT_LEVEL_ID = 1;
export const DEFAULT_PARENT_LEVEL_ID = 1;

export const datAssignment = (day: number) => {
    const WEEKDAY: {[key: number]: string} = {
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado',
        7: 'Domingo',
    };
    return WEEKDAY[day];
};

export const scheduleHours = (openingHours: IHoursSchedule[]) => {
    return (
        openingHours &&
        openingHours
            .filter((i: FieldValues) => i !== null)
            .map((hours: IHoursSchedule, index: number) => ({
                openingAttentionSchedule: hours.openingAttentionSchedule?.label,
                closingAttentionSchedule: hours.closingAttentionSchedule?.label,
                weekDay: index + 1,
                dayName: datAssignment(index + 1),
                status: hours.scheduleSwitch,
            }))
            .map((hours: FieldValues) => ({
                openingAttentionSchedule: hours.openingAttentionSchedule,
                closingAttentionSchedule: hours.closingAttentionSchedule,
                weekDay: hours.weekDay,
                dayName: hours.dayName,
                status: hours.status,
            }))
            .filter((i: FieldValues) => i.closingAttentionSchedule !== undefined)
    );
};

export const holidaysHours = (hoursHolisdays: IHoursHolidays[]) => {
    return (
        hoursHolisdays &&
        hoursHolisdays
            .filter((i: FieldValues) => i !== null)
            .map((days: IHoursHolidays) => ({
                date: days.holidayOpening.label,
                reason: days.holidayReason,
                endActivities: days.holidayEnding.label,
                status: days.holidaySwitch,
            }))
    );
};

export const routesType = (type: number) => {
    const ROUTE: {[key: number]: string} = {
        1: 'Nuevo grupo (actual)',
        2: 'Nuevo sucursal (actual)',
    };
    return ROUTE[type];
};

export const isOptional = (idKey: string, lenghtSection: unknown[] | FieldValues) => {
    if (lenghtSection && lenghtSection.length > 0) return {[idKey]: lenghtSection};
};

export const LOCATIONS_LIST_HEADERS = {
    NAME: 'Nombre',
    TYPE: 'Tipo',
    ADDRESS: 'Dirección',
    MANAGER: 'Gerente',
    USERS: 'Usuarios',
};

export const getStyleByType = (type: string) => {
    switch (type) {
        case 'En línea':
            return {
                color: '#004461',
                borderColor: '#99E0FF',
                backgroundColor: '#DBF4FF',
            };
        case 'POS':
            return {
                color: '#174A2E',
                borderColor: '#B1E7C9',
                backgroundColor: '#E4F7EC',
            };
        default:
            return {
                color: '#000000',
                borderColor: '#CCCCCC',
                backgroundColor: '#FFFFFF',
            };
    }
};

export const locationTypeFilter = [
    {value: 1, label: 'Todas'},
    {value: 2, label: 'En línea'},
    {value: 3, label: 'POS'},
];

export const ALLTYPEFILTER = 'Todas';

export const branchTypeMapping = locationTypeFilter.reduce(
    (acc, {value, label}) => ({...acc, [value]: label === ALLTYPEFILTER ? null : label}),
    {} as Record<number, string | null>
);

export const errorExist = (error?: string) => {
    let color = false;
    if (error) color = true;
    return color;
};

export const NEWSTORAGE = {
    TITLE: 'Nueva bodega',
    NAMEGROUP: 'Nombre de bodega',
    NAME: 'Bodega',
    ORGANIZATION: 'Ubicación',
    NAME_FORM: 'nameStorage',
    ADDRESS_FORM: 'storageDirection',
};

export const STORAGE_MENU_OPTIONS = ({onEdit, onDuplicate, onDelete}: IStorageActionOption) => {
    const options = [
        {
            name: 'Editar',
            icon: EditIcon,
            textColor: '#2A2C2F',
            onClick: onEdit,
        },
        {
            name: 'Duplicar',
            icon: DuplicateIcon,
            textColor: '#2A2C2F',
            onClick: onDuplicate,
        },
        {name: 'Eliminar', icon: TrashIcon, textColor: '#FF6357', onClick: onDelete},
    ];

    return options;
};

export const EDIT_WAREHOUSE_MODAL = {
    TITLE: 'Editar almacenamiento',
    INPUT_NAME: 'Nombre',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
};

export const LAST_LOCATION = {
    NEW_GROUP: 'Nuevo grupo (actual)',
    NEW_BRANCH: 'Nueva sucursal (actual)',
    NEW_STORAGE: 'Nueva bodega (actual)',
};

export const DAY_OF_WEEK = [
    {value: 1, label: 'Lunes'},
    {value: 2, label: 'Martes'},
    {value: 3, label: 'Miércoles'},
    {value: 4, label: 'Jueves'},
    {value: 5, label: 'Viernes'},
    {value: 6, label: 'Sábado'},
    {value: 7, label: 'Domingo'},
];

export const INITIAL_OPENING_HOURS = {
    OPENING: {label: '08:00', value: '17'},
    CLOSING: {label: '18:30', value: '38'},
};

export const ERROR_GRAL_MESSAGE = {
    NAME_BRANCH: 'Indique el nombre de la sucursal',
    NAME_GROUP: 'Indique el nombre del grupo',
    NAME_STORAGE: 'Indique el nombre de la bodega',
    BUSINESS_LINE: 'Indique una línea de negocio',
    ADDRESS: 'Indique una dirección',
    NAME_MATCHES: 'Indique solo letras y números',
    SALES_CHANNEL: 'Indique un canal de venta',
    STORAGE: 'Indique un almacenamiento',
    SCHEDULE: 'Indique un horario',
    HOLIDAYS: 'Indique un festivo',
};

export const INIT_LEVEL = 0;
export const MAX_GROUP_ROW = 4;

export const FROM_SECTION = {
    WAREHOUSE: 'warehouse',
    STORAGE_TYPE_NAME: 'STORAGE_TYPE_NAME',
    FLOOR_NAME: 'FLOOR_NAME',
    LEVEL_NAME: 'LEVEL_NAME',
    ROW_NAME: 'ROW_NAME',
};

export const TOOLTIP_STORAGE = 'Ingresa un nombre a tu compartimiento de inventario';

export const STORAGE_TYPE_LEVELS = {
    LEVEL_ZERO: 'Nivel 0',
    LEVEL_ONE: 'Nivel 1',
    LEVEL_TWO: 'Nivel 2',
};

export const YUP_EDIT_NAME_SCHEMA = {
    REQUIRED: 'Indique el campo',
    MIN: {
        VALUE: 2,
        MESSAGE: 'Ingresar por lo menos 2 caracteres',
    },
    MAX: {
        VALUE: 16,
        MESSAGE: 'Solo se permiten 16 caracteres',
    },
    MATCHES: {
        REGEX: /^[A-Za-z0-9 &\-_\[\]\n\táéíóúÁÉÍÓÚñÑ]*$/,
        MESSAGE: 'Caracteres no permitidos',
    },
    TEST: {
        NAME: 'is-valid-name',
        MESSAGE: 'Ya se utilizó este nombre',
    },
};
export const COPY_NAME = '- copia';
export const defaultHourHoliday = '00:00';

export const TOAST = {
    TITLE_SUCCESS: 'Éxito',
    MESSAGE_SUCCESS: 'Día festivo agregado en la lista',
};

export const YUP_NEW_NAME = {
    REQUIRED: 'Indique el campo',
    MIN: {
        VALUE: 2,
        MESSAGE: 'Ingresar más de dos caracteres',
    },
    MAX: {
        VALUE: 16,
        MESSAGE: 'Max 16 caracteres',
    },
    MATCHES: {
        REGEX: /^[A-Za-z0-9 &\-_\[\]\n\táéíóúÁÉÍÓÚñÑ]*$/,
        MESSAGE: 'Caracteres no permitidos',
    },
};

export const STORAGE_NAME_INPUT = 'storageName';

export const LOCATION_TYPE = {
    NEWGROUP: 1,
    NEWBRANCHGROUP: 2,
    NEWSTORAGE: 3,
};

export const DEFAULT_COUNTRY = 151;

export const STORAGE_STATUS = {
    OLD: 'OLD',
    NEW: 'NEW',
};
