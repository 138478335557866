import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';
import {LOAN_CARD_ACTN_BTN} from '@/components/GlobalQuoter/TempConstants';
import {useDispatch, useSelector} from 'react-redux';
import {
    createRequestError,
    createRequestLoan,
} from '@/components/PersonalLoans/Redux/Actions/PersonalLoanRquestSummaryActions';
import {RootState} from '@/config/store';
import {ILoanListCardButtonsProps} from '@/components/GlobalQuoter/interface';
import {AxiosError} from 'axios';

const LoanListCardButtons = ({companyId, frequencyTermCreditId}: ILoanListCardButtonsProps) => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {parsedAmount, accountPayments} = useSelector(
        (state: RootState) => state.globalQuoterFormReducer
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleApply = () => {
        try {
            if (token && accountPayments && frequencyTermCreditId && parsedAmount && companyId) {
                const data = {
                    amountRequested: parsedAmount,
                    frequencyTermId: frequencyTermCreditId,
                    periodAcount: accountPayments,
                    companyId,
                };
                dispatch(createRequestLoan(token, data, navigate));
            }
        } catch (error) {
            dispatch(createRequestError(error as AxiosError));
        }
    };

    const moreInfoHandler = () => {
        navigate(routers.CreditQuoter, {state: {frequencyTermCreditId, companyId}});
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Justify="center"
            Align="flex-start"
            Padding="16px 16px 16px 0"
            Gap="16px"
        >
            <ButtonGenerals
                BackGC="#F0F0FF"
                FontSize="1rem"
                FontWeight="500"
                Color="#fff"
                Border="none"
                HBackG="none"
                Width="100%"
                Cursor="pointer"
                onClick={handleApply}
            >
                <Text
                    Color="#5A5AFF"
                    FontWeight="700"
                    FontFamily="Nunito"
                    LetterSpacing="0.3px"
                    Cursor="pointer"
                >
                    {LOAN_CARD_ACTN_BTN.APPLY}
                </Text>
            </ButtonGenerals>
            <ButtonGenerals
                BackGC="transparent"
                FontSize="1rem"
                FontWeight="500"
                Color="#fff"
                Border="none"
                HBackG="none"
                Width="100%"
                Height="17px"
                Padding="0"
                Cursor="pointer"
                onClick={moreInfoHandler}
            >
                <Text
                    Color="#5A5AFF"
                    FontWeight="700"
                    FontFamily="Nunito"
                    LetterSpacing="0.3px"
                    Cursor="pointer"
                >
                    {LOAN_CARD_ACTN_BTN.DETAILS}
                </Text>
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default LoanListCardButtons;
