import * as yup from 'yup';
import {LOANSMESSAGEYUP, VALIDATION_MESSAGE} from '@/components/PersonalLoans/constants';

export const schemaUserLoans = yup.object().shape({
    name: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
    lastName: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
    maternalSurname: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
    catGenderId: yup.number(),
    curp: yup.string().required(LOANSMESSAGEYUP.REQUIRED).length(18, LOANSMESSAGEYUP.BECURP),
    birthdate: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
    phone: yup.string().required(LOANSMESSAGEYUP.REQUIRED).length(10, LOANSMESSAGEYUP.BETEN),
    catLadaId: yup.number(),
    addressPersonalLoans: yup.object().shape({
        street: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
        interiorNumber: yup.string(),
        externalNumber: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
        postalCode: yup.string().required(LOANSMESSAGEYUP.REQUIRED).length(5, LOANSMESSAGEYUP.BECP),
        catColonyId: yup.object().shape({
            value: yup
                .number()
                .required(LOANSMESSAGEYUP.REQUIRED)
                .test('is-non-zero', LOANSMESSAGEYUP.REQUIRED, function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        }),
        catMunicipaliyId: yup.object().shape({
            value: yup
                .number()
                .required(LOANSMESSAGEYUP.REQUIRED)
                .test('is-non-zero', LOANSMESSAGEYUP.REQUIRED, function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        }),
        catStateId: yup.object().shape({
            value: yup
                .number()
                .required(LOANSMESSAGEYUP.REQUIRED)
                .test('is-non-zero', LOANSMESSAGEYUP.REQUIRED, function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
        }),
    }),
});

export const schemaJobLoans = yup.object().shape({
    catEmploymentSituationId: yup
        .object()
        .shape({
            value: yup
                .number()
                .required(LOANSMESSAGEYUP.REQUIRED)
                .test('is-non-zero', LOANSMESSAGEYUP.REQUIRED, function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        })
        .required()
        .typeError(LOANSMESSAGEYUP.REQUIRED),
    nameCompany: yup.string(),
    addresCompany: yup.string(),
    phoneCompany: yup
        .string()
        .nullable()
        .test('is-valid-phone', LOANSMESSAGEYUP.BEDIGITAL, function (value) {
            if (!value) return true;
            const isValidLength = value.length >= 10 && value.length <= 15;
            const isDigitsOnly = /^\d+$/.test(value);
            return isValidLength && isDigitsOnly;
        }),
    job: yup.string(),
    catLadaId: yup.number(),
    catLaborOldId: yup
        .object()
        .shape({
            value: yup
                .number()
                .required(LOANSMESSAGEYUP.REQUIRED)
                .test('is-non-zero', LOANSMESSAGEYUP.REQUIRED, function (value) {
                    const {label} = this.parent;
                    return !(value === 0 && label === '');
                }),
            label: yup.string(),
        })
        .required()
        .typeError(LOANSMESSAGEYUP.REQUIRED),
    monthlyIncome: yup
        .number()
        .transform((value, originalValue) => {
            return originalValue === '' ? null : value;
        })
        .nullable()
        .required(LOANSMESSAGEYUP.REQUIRED)
        .typeError(LOANSMESSAGEYUP.BENUMBER)
        .test('is-not-zero', LOANSMESSAGEYUP.BEMONTH, (value) => value !== 0),
});

export const schemaUploadLoans = yup.object().shape({
    name: yup.string().required(LOANSMESSAGEYUP.REQUIRED),
    date: yup
        .string()
        .required(LOANSMESSAGEYUP.REQUIRED)
        .min(4, LOANSMESSAGEYUP.FOUR)
        .max(4, LOANSMESSAGEYUP.FOUR)
        .matches(/\d/g, {message: LOANSMESSAGEYUP.DATE}),
    signature: yup
        .boolean()
        .oneOf([true], LOANSMESSAGEYUP.CLICK)
        .required(LOANSMESSAGEYUP.REQUIRED),
    authorized: yup
        .boolean()
        .oneOf([true], LOANSMESSAGEYUP.CLICK)
        .required(LOANSMESSAGEYUP.REQUIRED),
    acceptLoan: yup.boolean(),
});

export const schemaTransactionLoans = yup.object().shape({
    terms: yup.boolean().oneOf([true], VALIDATION_MESSAGE.TERMS_CONDITIONS),
});

export const schemaUploadAutomaticLoan = yup.object().shape({
    catBankId: yup
        .number()
        .typeError(LOANSMESSAGEYUP.AVIABLEBANK)
        .required(LOANSMESSAGEYUP.BANKREQUIRED)
        .min(1, LOANSMESSAGEYUP.AVIABLEBANK),
    accountClabe: yup
        .string()
        .required(LOANSMESSAGEYUP.CLABEREQUIRED)
        .length(18, LOANSMESSAGEYUP.CLABEFOR),
});
