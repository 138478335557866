import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from '@/config/store';
import {getIdentificationTypeList} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {addIdDocsSave} from '@components/Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {yupClientDocuments} from '@components/Shopify/ClientProfile/yupClientDocuments';
import {IDocumentsInfo, DateFormField} from '@components/Shopify/ClientProfile/interfaces';
import {IdentificationType, SelectOption} from '@components/Shopify/ListClient/interfaces';
import {
    UPLOAD_DOCUMENTS,
    ONE_IMAGE_DOCS,
    formatFileSize,
} from '@components/Shopify/ClientProfile/constants';

export const useIdentityDocs = (token: string, onComplete: (document: IDocumentsInfo) => void) => {
    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.addIdentificationDoc.loading);
    const identificationTypes = useSelector(
        (state: RootState) => state.newClientProcess.identificationTypes
    );
    const existingClientIdDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.identityDocuments
    );
    const addedImageDocs = useSelector((state: RootState) => state.addIdentificationDoc.imageData);

    useEffect(() => {
        dispatch(getIdentificationTypeList(token));
    }, []);

    const [documentsInfo, setDocumentsInfo] = useState<IDocumentsInfo>({
        catIdentificationTypeId: '',
        identificationNumber: '',
        idDocument: 1,
        frontImage: null,
        backImage: null,
        validity: null,
        expiryDate: null,
    });

    const {
        setValue,
        control,
        formState: {errors},
        trigger,
        reset,
    } = useForm({
        resolver: yupResolver(yupClientDocuments),
        defaultValues: {
            catIdentificationTypeId: '',
            identificationNumber: '',
            idDocument: documentsInfo.idDocument,
            imageDocFront: '',
            imageDocBack: '',
            validity: '',
            expiryDate: '',
        },
    });

    const requireReverseImg = !ONE_IMAGE_DOCS.includes(documentsInfo.catIdentificationTypeId);

    const formattedIdentificationType: SelectOption[] =
        identificationTypes?.map((type: IdentificationType) => ({
            value: type.id,
            label: type.identificationName,
        })) || [];

    const getFileExtension = (file: string) => {
        return file?.split('.').pop()?.split('?')[0] || 'jpg';
    };

    const handleSave = async () => {
        const isValid = await trigger();
        if (isValid) {
            const document = {
                ...documentsInfo,
                backImage: requireReverseImg
                    ? addedImageDocs.backImage || documentsInfo.backImage
                    : null,
            };
            await dispatch(addIdDocsSave(document));
            onComplete(document);
        }
    };

    const handleDateChange = (name: DateFormField, value: string) => {
        setDocumentsInfo((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setValue(name, value);
    };

    const handleTypeChange = (value: string) => {
        setDocumentsInfo((prevState) => ({
            ...prevState,
            catIdentificationTypeId: value,
        }));
    };

    const handleIdNumberChange = (value: string) => {
        setDocumentsInfo((prevState) => ({
            ...prevState,
            identificationNumber: value,
        }));
    };

    useEffect(() => {
        if (existingClientIdDocs) {
            const front = existingClientIdDocs.find((doc) => !doc.isReverse && doc.nameInDocuments);
            if (!front) return;

            const back = existingClientIdDocs.find((doc) => doc.isReverse && doc.nameInDocuments);
            const frontImg = front
                ? {
                      fileName: `${front.typeOfDocuments}_${
                          UPLOAD_DOCUMENTS.FRONT
                      }.${getFileExtension(front.nameInDocuments)}`,
                      formFile: front.nameInDocuments,
                      image: front.nameInDocuments,
                      fileExtension: getFileExtension(front.nameInDocuments),
                      fileSize: formatFileSize(front.size),
                  }
                : null;
            const backImg = back
                ? {
                      fileName: `${back.typeOfDocuments}_${
                          UPLOAD_DOCUMENTS.FRONT
                      }.${getFileExtension(back.nameInDocuments)}`,
                      formFile: back.nameInDocuments,
                      image: back.nameInDocuments,
                      fileExtension: getFileExtension(back.nameInDocuments),
                      fileSize: formatFileSize(back.size),
                  }
                : null;
            const identificationType =
                formattedIdentificationType
                    .find((option) => option.label === front.typeOfDocuments)
                    ?.value.toString() || '';
            const reverseReq = !ONE_IMAGE_DOCS.includes(identificationType);
            setDocumentsInfo((prevInfo) => ({
                ...prevInfo,
                catIdentificationTypeId: identificationType || prevInfo.catIdentificationTypeId,
                identificationNumber: front?.number || prevInfo.identificationNumber,
                frontImage: frontImg || prevInfo.frontImage,
                backImage: reverseReq ? backImg || prevInfo.backImage : null,
                validity: front?.effectiveDate.split('-')[0] || prevInfo.validity,
                expiryDate: front?.expirationDate.split('-')[0] || prevInfo.expiryDate,
            }));
            reset({
                catIdentificationTypeId: identificationType || '',
                identificationNumber: front?.number || '',
                validity: front?.effectiveDate.split('-')[0] || '',
                expiryDate: front?.expirationDate.split('-')[0] || '',
                imageDocFront: frontImg?.fileName || '',
                imageDocBack: reverseReq ? backImg?.fileName || '' : '',
            });
            return;
        }
        if (addedImageDocs) {
            setDocumentsInfo((prevInfo) => ({
                ...prevInfo,
                catIdentificationTypeId:
                    addedImageDocs.catIdentificationTypeId || prevInfo.catIdentificationTypeId,
                identificationNumber:
                    addedImageDocs.identificationNumber || prevInfo.identificationNumber,
                frontImage: addedImageDocs.frontImage || prevInfo.frontImage,
                backImage: requireReverseImg
                    ? addedImageDocs.backImage || documentsInfo.backImage
                    : null,
                validity: addedImageDocs.validity || prevInfo.validity,
                expiryDate: addedImageDocs.expiryDate || prevInfo.expiryDate,
            }));

            reset({
                catIdentificationTypeId: addedImageDocs.catIdentificationTypeId || '',
                identificationNumber: addedImageDocs.identificationNumber || '',
                validity: addedImageDocs.validity || '',
                expiryDate: addedImageDocs.expiryDate || '',
                imageDocFront: addedImageDocs.frontImage?.fileName || '',
                imageDocBack: requireReverseImg ? addedImageDocs.backImage?.fileName || '' : '',
            });
            return;
        }
    }, [existingClientIdDocs, addedImageDocs, reset]);

    return {
        loading,
        documentsInfo,
        setDocumentsInfo,
        setValue,
        control,
        errors,
        handleSave,
        handleDateChange,
        handleTypeChange,
        handleIdNumberChange,
        formattedIdentificationType,
        requireReverseImg,
    };
};
