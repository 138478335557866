import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIcon from '@images/clousedGIcone.svg';
import doneIcon from '@images/DoneGreen.svg';
import {Icon} from '@/components/MarketForgottenLogin/Operational/styles';
import {RESTORE_NEW_PASSWORD, VALIDA_FORM} from './Operational/constants';
import {IErrors} from '@/components/NewUserEmployee/Operational/interface';
import {MesageRepeat} from '@/components/NewUserEmployee/Operational/styles';

export const ErrorPasswordRepeat = ({error, password, repeatPassword, errorMessage}: IErrors) => {
    if (!repeatPassword && error && errorMessage) {
        return (
            <Text Color="#A82424" FontSize="0.85rem" FontFamily="Nunito">
                {errorMessage}
            </Text>
        );
    }
    const isLengthValid = repeatPassword.length >= 8;
    const doPasswordsMatch = password === repeatPassword;
    const passwordIcon = isLengthValid ? doneIcon : closeIcon;
    const passwordIconColor = isLengthValid ? '#309C60' : '#A82424';
    const matchIcon = doPasswordsMatch ? doneIcon : closeIcon;

    const getMatchColor = () => {
        if (!repeatPassword) return '#2A2C2F';
        if (error) return '#A82424';
        if (doPasswordsMatch) return '#309C60';
        return '#A82424';
    };

    const matchIconColor = getMatchColor();
    const shouldShowIcons = repeatPassword.length > 0;

    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <Icon
                    src={passwordIcon}
                    alt={isLengthValid ? 'Done icon' : 'Close icon'}
                    color={passwordIconColor}
                />
                {shouldShowIcons && (
                    <Icon
                        src={matchIcon}
                        alt={doPasswordsMatch ? 'Done icon' : 'Close icon'}
                        color={matchIconColor}
                    />
                )}
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                <Text Color={isLengthValid ? '#309C60' : '#A82424'} {...MesageRepeat}>
                    {RESTORE_NEW_PASSWORD.MESSAGE}
                </Text>

                {shouldShowIcons && (
                    <Text Color={getMatchColor()} {...MesageRepeat}>
                        {VALIDA_FORM.ONE_OF}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
