import * as yup from 'yup';
import {YUP_NEW_NAME} from '@components/LocationsBranch/constants';
export const newNameSchema = yup.object().shape({
    storageName: yup
        .string()
        .required(YUP_NEW_NAME.REQUIRED)
        .min(YUP_NEW_NAME.MIN.VALUE, YUP_NEW_NAME.MIN.MESSAGE)
        .max(YUP_NEW_NAME.MAX.VALUE, YUP_NEW_NAME.MAX.MESSAGE)
        .matches(YUP_NEW_NAME.MATCHES.REGEX, YUP_NEW_NAME.MATCHES.MESSAGE),
});
