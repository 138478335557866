import {DELETE_EXTENSION} from '@components/ShoppingCart/Redux/types';
const initialState = {
    error: false,
    loading: false,
};
const deleteExtension = (state = initialState, action: {type: string}) => {
    switch (action.type) {
        case DELETE_EXTENSION.START:
            return {
                ...state,
                loading: true,
            };
        case DELETE_EXTENSION.SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case DELETE_EXTENSION.ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};
export default deleteExtension;
