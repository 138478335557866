import {AppDispatch} from '@/config/store';

import {URL} from '@config/constants/index';

import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    CREATE_USER_MARKET,
    CREATE_USER_MARKET_ERROR,
    CREATE_USER_MARKET_SUCCESS,
} from '@components/Login/MarketPlaceLogin/Redux/Types';
import {NavigateFunction} from 'react-router-dom';
import {
    addCodeMarketLogin,
    addCountMarketLogin,
} from '@/components/MarketLogin/redux/actions/codevalideAction';

export const createUserMarket = () => {
    return {
        type: CREATE_USER_MARKET,
    };
};
export const createUserMarketSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_USER_MARKET_SUCCESS,
        payload: result,
    };
};
export const createUserMarketError = (error: AxiosError) => {
    return {
        type: CREATE_USER_MARKET_ERROR,
        error: error,
    };
};

export function createUserMarketPlace(
    data: {
        userClientName: string;
        email: string;
        password: string;
        firstName: string;
        paternalLastName: string;
        maternalLastName: string;
        phoneNumber: string;
        countryId: number;
    },
    navigate: NavigateFunction
) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(createUserMarket());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateClientMarket}`,
                data,
                {
                    headers,
                }
            );
            dispatch(createUserMarketSuccess(response));
            dispatch(addCountMarketLogin(''));
            dispatch(addCodeMarketLogin([]));
            navigate('/Login/MarketValide');
        } catch (error) {
            dispatch(createUserMarketError(error as AxiosError));
        }
    };
}
