import {FieldValues, FormProvider, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {IClientsAndDocumentsEditingFormProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/clientInterfaces';
import {EditingButtonsForm} from '@components/PersonalLoans/NewLoanProduct/ui/EditingButtonsForm';
import {DOCUMENTCLIENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ClientsDocumentsForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/EditingForm/ClientsDocumentsForm';
import {ICheckBoxsState} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';
import {schemaClientsAndDocuments} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/schemaClientsAndDocuments';
import {saveNewLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {
    setVisualCopyDocuments,
    updateDocumentsCopyArray,
} from '@/components/PersonalLoans/Redux/Actions/UpdatePersonalDocumentsActions';

export const ClientsAndDocumentsEditingForm: React.FC<IClientsAndDocumentsEditingFormProps> = ({
    clientAndDocumentsRef,
    setIsEditing,
    isEditing,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const {updatedDocuments, visualCopyDocuments} = useSelector((state: RootState) => {
        return state.UpdatePersonalDocumentsReducer;
    });
    const formMethods = useForm({resolver: yupResolver(schemaClientsAndDocuments)});
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData);
    const documentsClientsData = newLoanData.details;
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const onSubmit = (data: FieldValues) => {
        dispatch(setVisualCopyDocuments(updatedDocuments));
        dispatch(
            saveNewLoanSubmittedData({
                ...newLoanData,
                details: {
                    ...documentsClientsData,
                    genderTerms: data.genderTerms,
                    ageMin: data.ageMin,
                    ageMax: data.ageMax,
                    reviewCreditVoul: data.reviewCreditVoul,
                    creditScore: data.creditScore,
                    documents: updatedDocuments.length > 0 ? updatedDocuments : data.documents,
                },
            })
        );
        setIsEditing(!isEditing);
    };
    const onCancel = () => {
        dispatch(updateDocumentsCopyArray(visualCopyDocuments));
        setIsEditing(!isEditing);
    };
    const {handleSubmit, reset} = formMethods;
    const [checkboxsState, setCheckBoxsState] = useState<ICheckBoxsState>({
        genderMan: documentsClientsData.genderTerms[0].isChecked,
        genderWoman: documentsClientsData.genderTerms[1].isChecked,
        reviewCreditVoul: documentsClientsData.reviewCreditVoul,
    });
    useEffect(() => {
        reset({
            genderTerms: documentsClientsData.genderTerms,
            ageMin: documentsClientsData.ageMin,
            ageMax: documentsClientsData.ageMax,
            reviewCreditVoul: documentsClientsData.reviewCreditVoul,
            creditScore: documentsClientsData.creditScore,
            documents: documentsClientsData.documents,
        });
    }, []);
    return (
        <>
            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                Gap="1.5rem"
                Radius="1.5rem"
                Padding="1.25rem"
                Border="1px solid #E8E9EA"
                Height="max-content"
                ref={clientAndDocumentsRef}
            >
                <ContainerFlex FlexDir="column" Gap="0.5rem" Height="max-content" Align="start">
                    <Text oFlow="normal" Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                        {DOCUMENTCLIENTS.TITLE}
                    </Text>
                    <Text oFlow="normal" Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                        {DOCUMENTCLIENTS.SELECTTYPE}
                    </Text>
                </ContainerFlex>
                <FormProvider {...formMethods}>
                    <ContainerForm
                        onSubmit={handleSubmit(onSubmit)}
                        Justify="start"
                        Align="start"
                        FlexDir="column"
                        Gap="1.5rem"
                    >
                        <ClientsDocumentsForm
                            isSubmitted={isSubmitted}
                            checkboxsState={checkboxsState}
                            setCheckBoxsState={setCheckBoxsState}
                        />
                        <EditingButtonsForm
                            onCancel={onCancel}
                            onContinue={() => setIsSubmitted(!isSubmitted)}
                        />
                    </ContainerForm>
                </FormProvider>
            </ContainerFlex>
        </>
    );
};
