import React, {useEffect} from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import CreditDetailHeader from '@/components/CreditsAndPawns/CreditDetail/CreditDetailHeader';
import CreditDetailProfile from '@/components/CreditsAndPawns/CreditDetail/CreditDetailProfile';
import CreditDetailTables from '@/components/CreditsAndPawns/CreditDetail/CreditDetailTables';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getCreditDetailAction} from '@/components/CreditsAndPawns/Redux/Actions/GetDetailsCreditActions';

const CreditDetail = () => {
    const dispatch = useDispatch();
    const {id} = useParams<{id?: string}>();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {creditDetail} = useSelector((state: RootState) => {
        return state.getDetailsCredit;
    });

    useEffect(() => {
        if (token && id) {
            dispatch(getCreditDetailAction({token, creditId: id}));
        }
    }, [id, token]);

    return (
        <ContainerFlex FlexDir="column" Align="start" Justify="start" MinH="calc(100vh - 56px)">
            <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="1rem" Height="100%">
                <ContainerFlex
                    FlexDir="row"
                    Align="start"
                    Justify="start"
                    Height="100%"
                    Flex="0"
                    Padding="24px 24px 0 24px"
                >
                    {creditDetail && (
                        <CreditDetailHeader statusId={Number(creditDetail?.statusId)} />
                    )}
                </ContainerFlex>
                <ContainerFlex
                    Align="start"
                    Justify="space-between"
                    Width="100%"
                    Height="100%"
                    Gap="16px"
                    Padding="0 24px"
                >
                    <ContainerFlex
                        Justify="start"
                        Align="start"
                        Width="100%"
                        Height="100%"
                        Flex="0"
                    >
                        {creditDetail && <CreditDetailProfile {...creditDetail} />}
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="start" Width="100%" Height="100%">
                        {creditDetail && (
                            <CreditDetailTables statusId={Number(creditDetail?.statusId)} />
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetail;
