import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {LOANSLIST, LoansTable, SUGGESTEDPRODUCTS} from '@components/LoansProducts//constants';
import Spark from '@images/sparkles.svg';
import {SuggestedList} from '@components/LoansProducts/interfaces';

export const SuggestedProducts = () => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="1rem 1.5rem"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Gap="1.5rem"
        >
            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="0.5rem">
                <ContainerFlex Justify="start" Gap="0.5rem">
                    <Image src={Spark} alt="spark" />{' '}
                    <Text oFlow="normal" Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                        {SUGGESTEDPRODUCTS.TITLE}
                    </Text>
                </ContainerFlex>
                <Text oFlow="normal">{SUGGESTEDPRODUCTS.SUB}</Text>
            </ContainerFlex>

            <ContainerFlex Justify="start" Align="start" FlexDir="column">
                <ContainerFlex
                    Display="grid"
                    GridColumns="2fr 2fr 2fr 2fr 2fr 2fr"
                    Height="2.75rem"
                    Bb="1px solid #E8E9EA"
                    Padding="0.5rem 1rem"
                    Gap="1rem"
                >
                    <Text FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.NAME}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MIN}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MAX}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MINTIME}
                    </Text>
                    <Text FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MAXTIME}
                    </Text>
                </ContainerFlex>
                {LoansTable.map((item: SuggestedList) => (
                    <ContainerFlex
                        key={item.termId}
                        Display="grid"
                        GridColumns="2fr 2fr 2fr 2fr 2fr 2fr"
                        Height="2.75rem"
                        Bb="1px solid #E8E9EA"
                        Padding="0.5rem 1rem"
                        Gap="1rem"
                    >
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {item.termName}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {`$${item.minimumAmount}`}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {`$${item.maximumAmount}`}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {item.minimumTime}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {item.maximumTime}
                        </Text>
                        <Text Color="#5A5AFF" FontSize="0.875rem" FontWeight="700" Cursor="pointer">
                            {SUGGESTEDPRODUCTS.SELECT}
                        </Text>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};
