import React from 'react';

import {Controller} from 'react-hook-form';
import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input, Image} from '@Shopify/Ecommerce/styles';
import Close from '@images/close.svg';
import * as content from '@components/CreateUsers/Styles';

const CotitularBeneficiarioRow = (
    props: IClientProofStep & {index: number; handleRemoveCotitular: (position: number) => void}
) => {
    const {register, errors, control, handleRemoveCotitular, index} = props;

    return (
        <ContainerFlex Justify="space-between" Align="flex-start" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.COTITULAR_NOMBRES}</Text>
                <Controller
                    name={`cotitularNombres${index}`}
                    control={control}
                    render={() => (
                        <Input
                            {...register(`cotitularNombres${index}`)}
                            error={!!errors[`cotitularNombres${index}`]?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors[`cotitularNombres${index}`]?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.COTITULAR_PATERNAL}</Text>
                <Controller
                    name={`coownerPaternalLastname${index}`}
                    control={control}
                    render={() => (
                        <Input
                            {...register(`coownerPaternalLastname${index}`)}
                            error={!!errors[`coownerPaternalLastname${index}`]?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors[`coownerPaternalLastname${index}`]?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.COTITULAR_MATERNAL}</Text>
                <Controller
                    name={`coownerMaternalLastname${index}`}
                    control={control}
                    render={() => (
                        <Input
                            {...register(`coownerMaternalLastname${index}`)}
                            error={!!errors[`coownerMaternalLastname${index}`]?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors[`coownerMaternalLastname${index}`]?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.COTITULAR_TELEFONO}</Text>
                <Controller
                    name={`cotitularTelefono${index}`}
                    control={control}
                    render={() => (
                        <Input
                            type="number"
                            {...register(`cotitularTelefono${index}`)}
                            error={!!errors[`cotitularTelefono${index}`]?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors[`cotitularTelefono${index}`]?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.COTITULAR_CORREO_ELECTRONICO}</Text>
                <Controller
                    name={`cotitularCorreoElectronico${index}`}
                    control={control}
                    render={() => (
                        <Input
                            {...register(`cotitularCorreoElectronico${index}`)}
                            error={!!errors[`cotitularCorreoElectronico${index}`]?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors[`cotitularCorreoElectronico${index}`]?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex MarginTop="1.5rem" Width="1%">
                <Image
                    src={Close}
                    {...content.contentImg.imgStart}
                    Height="24px"
                    ObjectFit="contain"
                    onClick={() => handleRemoveCotitular(index)}
                    Cursor="pointer"
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CotitularBeneficiarioRow;
