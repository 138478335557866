import {AxiosError, AxiosResponse} from 'axios';
import {
    ACTIVE_DESACTIVE_CREDIT_ERROR,
    ACTIVE_DESACTIVE_CREDIT_START,
    ACTIVE_DESACTIVE_CREDIT_SUCCESS,
    RESET_ACTIVE_DESACTIVE_CREDIT,
    SELECTED_ACTIVE_DESACTIVE_CREDIT,
} from '@components/LoansProducts/Redux/Types/Types';
import {ActiveDesactiveReducer, SuggestedList} from '@components/LoansProducts/interfaces';

const initialState: ActiveDesactiveReducer = {
    data: false,
    loading: false,
    error: false,
    errorData: null,
    selectedCredit: null,
};

export const ActiveDesactiveTermCredit = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError; data: SuggestedList}
): ActiveDesactiveReducer => {
    switch (action.type) {
        case ACTIVE_DESACTIVE_CREDIT_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ACTIVE_DESACTIVE_CREDIT_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case ACTIVE_DESACTIVE_CREDIT_ERROR:
            return {
                ...state,
                errorData: action.error,
                loading: false,
                error: true,
            };
        case SELECTED_ACTIVE_DESACTIVE_CREDIT:
            return {
                ...state,
                selectedCredit: action.data,
                loading: false,
                error: false,
            };
        case RESET_ACTIVE_DESACTIVE_CREDIT:
            return initialState;
        default:
            return state;
    }
};
