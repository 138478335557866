import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IconContainer} from '@/components/CashFlow/styles';
import {Highlight} from '@/components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ICoincidence} from '@Login/MarketPlaceLogin/interface';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';
import {EMAIL_ERROR} from '@/components/Login/constants';

export const ErrorMail = () => {
    const navigate = useNavigate();
    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <IconContainer
                    FontSize="0.875rem"
                    className="material-icons"
                    Color="#FF6357"
                    Width="auto"
                    Height="auto"
                >
                    {EMAIL_ERROR.CLOSE}
                </IconContainer>
            </ContainerFlex>
            <ContainerFlex Justify="start" Align="start" Gap="4px" ColumnGap="4px">
                <Text
                    Color="#FF6357"
                    FontSize="0.85rem"
                    FontFamily="Nunito"
                    wSpace="normal"
                    TextAlign="justify"
                    Display="inline-block"
                >
                    {EMAIL_ERROR.MESSAGE_TEXT}
                    <Text
                        Color="#5A5AFF"
                        FontSize="0.85rem"
                        FontFamily="Nunito"
                        wSpace="normal"
                        TextAlign="justify"
                        Display="inline-block"
                        FontWeight="700"
                        Cursor="pointer"
                        onClick={() => navigate(`/Login${routers.AccountContainerLogin}`)}
                    >
                        {EMAIL_ERROR.MESSAGE_TEXT_CTA}
                    </Text>
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export const CoincidenceUser = ({setValue}: ICoincidence) => {
    const EmailVerify: string | undefined = useSelector(
        (state: RootState) => state.getUsersMarketValidation.userData?.clientName
    );
    const Message = EMAIL_ERROR.USER_VALIDATION + EmailVerify + '?';
    return (
        <>
            {EmailVerify && (
                <Highlight
                    text={Message}
                    FontSize="0.875rem"
                    FontFamily="Nunito"
                    color="#5A5AFF"
                    ColorH="#54575C"
                    Cursor="pointer"
                    highlight={EmailVerify}
                    clic={() => {
                        if (EmailVerify) {
                            setValue('firstName', EmailVerify);
                        }
                    }}
                />
            )}
        </>
    );
};
