import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    GET_ALL_MODULES,
    GET_ALL_MODULES_ERROR,
    GET_ALL_MODULES_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const GetAllModules = () => {
    return {
        type: GET_ALL_MODULES,
    };
};

export const GetAllModulesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_MODULES_SUCCESS,
        payload: result,
    };
};

export const GetAllModulesError = (error: AxiosError) => {
    return {
        type: GET_ALL_MODULES_ERROR,
        payload: error,
    };
};

export const GetAllModulesAction = (typeRole: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        const params = {
            typeRol: typeRole.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        dispatch(GetAllModules());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAllModules}?${queryString}`;

        try {
            const response = await axios.get(url, {headers});
            dispatch(GetAllModulesSuccess(response));
        } catch (error) {
            dispatch(GetAllModulesError(error as AxiosError));
        }
    };
};

export default GetAllModulesAction;
