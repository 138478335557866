import {
    POST_DUPLICATE_ROLE,
    POST_DUPLICATE_ROLE_ERROR,
    POST_DUPLICATE_ROLE_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
};

const PostDuplicateRoleReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case POST_DUPLICATE_ROLE:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case POST_DUPLICATE_ROLE_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case POST_DUPLICATE_ROLE_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default PostDuplicateRoleReducer;
