import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IProductDetailProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';

export const ProductDetail: React.FC<IProductDetailProps> = ({label, value}) => {
    return (
        <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
            <Text Color="#2A2C2F" FontWeight="700">
                {label}
            </Text>
            <Text Color="#2A2C2F" Height="40px" Padding="0.5rem 0px">
                {value}
            </Text>
        </ContainerFlex>
    );
};
