import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '@/config/store';
import {ContainerFlex, ContainerGrid, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_DETAIL_TABLES} from '@/components/CreditsAndPawns/constants';
import {Select} from '@components/Quoter/Steps/styles';
import {IOptionType} from '@/components/CreditsAndPawns/interfaces';
import CreditDetailTableRow from '@/components/CreditsAndPawns/CreditDetail/CreditDetailTableRow';
import downloadFocused from '@/images/download_focused.svg';
import {getCreditDetailHistoryAction} from '@/components/CreditsAndPawns/Redux/Actions/GetCreditDetailHistoryActions';

const CreditDetailHistoryTable = () => {
    const dispatch = useDispatch();
    const {id} = useParams<{id?: string}>();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {creditDetailHistory} = useSelector((state: RootState) => state.getCreditDetailHistory);

    const filterOptions: IOptionType[] = [
        {label: CREDIT_DETAIL_TABLES.FILTER.ALL, value: CREDIT_DETAIL_TABLES.FILTER_VALUES.ALL},
        {
            label: CREDIT_DETAIL_TABLES.FILTER.FEE_PAYMENT,
            value: CREDIT_DETAIL_TABLES.FILTER_VALUES.FEE_PAYMENT,
        },
        {
            label: CREDIT_DETAIL_TABLES.FILTER.PAYMENT,
            value: CREDIT_DETAIL_TABLES.FILTER_VALUES.PAYMENT,
        },
    ];
    useEffect(() => {
        if (token && id) {
            const creditDetailPaymentProps = {
                token,
                creditId: String(id),
                PaidTypeId: null,
            };
            dispatch(getCreditDetailHistoryAction(creditDetailPaymentProps));
        }
    }, [token, id]);

    const handleChangeFilter = (selectedOption: IOptionType) => {
        const creditDetailPaymentProps = {
            token,
            creditId: String(id),
            PaidTypeId: Number(selectedOption.value) || null,
        };
        dispatch(getCreditDetailHistoryAction(creditDetailPaymentProps));
    };
    return (
        <>
            <ContainerFlex Height="40px" Width="100%" Justify="space-between" Align="start">
                <ContainerFlex Flex="1">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.25rem"
                        Color="#2A2C2F"
                        Width="100%"
                    >
                        {CREDIT_DETAIL_TABLES.HISTORY_TITLE}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Justify="end" Align="center" Gap="16px" Flex="2">
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#5A5AFF"
                        Gap="8px"
                        Cursor="pointer"
                    >
                        <Image
                            Width="16px"
                            Height="16px"
                            src={downloadFocused}
                            alt={CREDIT_DETAIL_TABLES.ALTS.DOWNLOAD_ICON}
                            Cursor="pointer"
                        />
                        {CREDIT_DETAIL_TABLES.HISTORY_TABLE.DOWNLOAD_BUTTON}
                    </Text>
                    <Select
                        noOptionsMessage={() => CREDIT_DETAIL_TABLES.FILTER.NO_OPTION}
                        options={filterOptions}
                        width="223px"
                        padding="0px"
                        isSearchable={false}
                        defaultValue={{
                            label: CREDIT_DETAIL_TABLES.FILTER.ALL,
                            value: CREDIT_DETAIL_TABLES.FILTER_VALUES.ALL,
                        }}
                        BorderBOptions="0px"
                        onChange={(e) => handleChangeFilter(e as IOptionType)}
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Width="100%"
                Height="100%"
                Radius="8px"
                Border="1px solid #E8E9EA"
            >
                <ContainerGrid
                    BorderR="8px 8px 0 0"
                    bGround="#FAFAFA"
                    BorderBt="1px solid #E8E9EA"
                    Padding="8px 16px"
                    Height="40px"
                    MinH="40px"
                    Width="100%"
                    GridColumns="repeat(4, 1fr)"
                    Justify="space-between"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.HISTORY_TABLE.TRANSACTION_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.HISTORY_TABLE.PAYMENT_METHOD_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.HISTORY_TABLE.PAY_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.HISTORY_TABLE.PAYMENT_DATE_FIELD}
                    </Text>
                </ContainerGrid>
                <ContainerFlex
                    FlexDir="column"
                    Height="100%"
                    MaxH="500px"
                    Width="100%"
                    OverFlowY="auto"
                    Justify="start"
                    Align="start"
                >
                    {creditDetailHistory?.map((data, index) => {
                        const {
                            transactionType,
                            cardTypeUrl,
                            cardNumber,
                            paymentAmount,
                            paymentDate,
                        } = data;
                        const organizedData = {
                            transactionType,
                            cardNumber,
                            paymentAmount,
                            paymentDate,
                            cardTypeUrl,
                        };
                        return (
                            <CreditDetailTableRow
                                key={index}
                                data={organizedData}
                                isFeesTable={false}
                            />
                        );
                    })}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};

export default CreditDetailHistoryTable;
