import {AxiosResponse} from 'axios';
import {IGetRolByCompanyReducer} from '@components/NewUserEmployee/Redux/interfaces';
import {
    GET_ROLE_BY_COMPANY_ERROR,
    GET_ROLE_BY_COMPANY_START,
    GET_ROLE_BY_COMPANY_SUCCESS,
} from '@components/NewUserEmployee/Redux/Types/Types';
const initialState: IGetRolByCompanyReducer = {
    data: [],
    loading: false,
    error: false,
};
const getRolByCompanyReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetRolByCompanyReducer => {
    switch (action.type) {
        case GET_ROLE_BY_COMPANY_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ROLE_BY_COMPANY_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_ROLE_BY_COMPANY_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default getRolByCompanyReducer;
