import React from 'react';
import {DropdownIndicatorProps, components} from 'react-select';
import questionImg from '@images/searchAtaskate.svg';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import arrowDown from '@components/PersonalLoans/NewLoanProduct/icons/arrowDown.svg';
export const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ContainerFlex Gap="0.25rem" Width="max-content" Height="max-content">
                <ContainerFlex
                    Width="24px"
                    Height="24px"
                    FlexDir="column"
                    Position="relative"
                    Justify="start"
                >
                    <Image
                        src={questionImg}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        alt="question-icon"
                    />
                </ContainerFlex>
                <Image src={arrowDown} Width="24px" Height="24px" alt="arrow-down-icon" />
            </ContainerFlex>
        </components.DropdownIndicator>
    );
};
