import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    CREATE_REQUEST_LOAN_START,
    CREATE_REQUEST_LOAN_SUCCESS,
    CREATE_REQUEST_LOAN_ERROR,
    GET_CREDIT_LOAN_SUMMARY_START,
    GET_CREDIT_LOAN_SUMMARY_SUCCESS,
    GET_CREDIT_LOAN_SUMMARY_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import axios, {AxiosResponse, AxiosError} from 'axios';
import {ICreateRequestLoan} from '@components/PersonalLoans/interfaces';
import {NavigateFunction} from 'react-router-dom';
import {routers} from '@/appRoute';

export const createRequestStart = () => {
    return {
        type: CREATE_REQUEST_LOAN_START,
    };
};

export const createRequestSuccess = (payload: AxiosResponse) => {
    return {
        type: CREATE_REQUEST_LOAN_SUCCESS,
        payload,
    };
};

export const createRequestError = (error: AxiosError) => {
    return {
        type: CREATE_REQUEST_LOAN_ERROR,
        error,
    };
};

export const getCreditLoanSummaryStart = () => {
    return {
        type: GET_CREDIT_LOAN_SUMMARY_START,
    };
};

export const getCreditLoanSummarySuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CREDIT_LOAN_SUMMARY_SUCCESS,
        payload,
    };
};

export const getCreditLoanSummaryError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_LOAN_SUMMARY_ERROR,
        error,
    };
};

export const createRequestLoan = (
    token: string,
    dataCreateRequest: ICreateRequestLoan,
    navigate: NavigateFunction
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(createRequestStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateRequestedCredit}`,
                dataCreateRequest,
                {headers: headers}
            );

            dispatch(createRequestSuccess(response));
            dispatch(getCreditLoanSummary(token, response.data.data, navigate));
        } catch (error) {
            dispatch(createRequestError(error as AxiosError));
        }
    };
};

export const getCreditLoanSummary = (
    token: string,
    solicitudeId: number,
    navigate: NavigateFunction
) => {
    const headers = {
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditLoanSummaryStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRequestedSummary}`,
                {
                    headers,
                    params: {solicitudeId},
                }
            );
            dispatch(getCreditLoanSummarySuccess(response));
            navigate(routers.PersonalLoans);
            return response;
        } catch (error) {
            dispatch(getCreditLoanSummaryError(error as AxiosError));
        }
    };
};
