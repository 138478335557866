import React from 'react';

import addIcon from '@images/addIcon.svg';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {CONTRACT_DETAIL} from '@Shopify/ClientProfile/constants';

export const PayMethod = () => {
    return (
        <ContainerFlex {...style.contenSectionDeatil}>
            <ContainerFlex FlexDir="column" Align="start">
                <Text Color="#000000" FontSize="1.25rem" FontWeight="700">
                    {CONTRACT_DETAIL.PAY_METHOD}
                </Text>
                <Text Color="#000000" wSpace="normal">
                    {CONTRACT_DETAIL.PAY_DECRIPTION}
                </Text>
            </ContainerFlex>
            <Text Width="18.1rem" Color="#5A5AFF" FontWeight="700" Gap="0.25rem">
                <Image src={addIcon} alt="icon-add" />
                {CONTRACT_DETAIL.ADD_PAY_METHOD}
            </Text>
        </ContainerFlex>
    );
};
