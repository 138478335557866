import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Divisor} from '@/components/QuickPayment/SuccessPaymentSummary/ResumenPagoExitosoStyles';
import {PAYMENT_SUMMARY, STATE_ACTIONS} from '@/components/QuickPayment/Constants';
import {useDispatch, useSelector} from 'react-redux';
import {sendConfirmationVoucherReset} from '@/components/QuickPayment/Redux/Actions/SendConfirmationVoucher';
import {RootState} from '@/config/store';
import {selectActionState} from '@/components/QuickPayment/Redux/Actions/GetActionPayment';
import {resetAddFastPayment} from '@/components/QuickPayment/Redux/Actions/AddFastPaymentActions';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';

const SuccessSummary = () => {
    const dispatch = useDispatch();
    const {totalLateLoan, totalLoan, totalPayment} = useSelector((state: RootState) => {
        return state.addFastPayment;
    });
    const navigateAndClear = () => {
        dispatch(sendConfirmationVoucherReset());
        dispatch(resetAddFastPayment());
        dispatch(selectActionState(STATE_ACTIONS.ACCESS_PAYMENT));
        window.location.href = PAYMENT_SUMMARY.GO_TO_MY_ACCOUNT_URL;
    };
    const formatCurrency = useFormatCurrency();
    return (
        <ContainerFlex
            Radius="24px"
            backG="#FAFAFA"
            Padding="24px"
            Justify="flex-start"
            Gap="24px"
            FlexDir="column"
        >
            <ContainerFlex Justify="space-between" Height="24px">
                <Text FontFamily="Nunito" FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                    {PAYMENT_SUMMARY.TITLE}
                </Text>
                <ButtonGenerals
                    BackGC="transparent"
                    HBackG="transparent"
                    Radius="32px"
                    Border="none"
                    Padding="0"
                    onClick={navigateAndClear}
                    Cursor="pointer"
                >
                    <Text FontFamily="Nunito" FontSize="1rem" FontWeight="700" Color="#5A5AFF">
                        {PAYMENT_SUMMARY.GO_TO_MY_ACCOUNT_BTN_TEXT}
                    </Text>
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerFlex FlexDir="column">
                <ContainerFlex Justify="space-between" Padding="8px 0">
                    <Text FontFamily="Nunito" FontSize="1rem" Color="#2A2C2F">
                        {PAYMENT_SUMMARY.PAYMENT_ITEMS[0].TITLE}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1rem" Color="#2A2C2F">
                        {formatCurrency(totalLoan.toString())}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="space-between" Padding="8px 0">
                    <Text FontFamily="Nunito" FontSize="1rem" Color="#2A2C2F">
                        {PAYMENT_SUMMARY.PAYMENT_ITEMS[1].TITLE}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1rem" Color="#2A2C2F">
                        {formatCurrency(totalLateLoan.toString())}
                    </Text>
                </ContainerFlex>
                <Divisor margin="16px 0" />
                <ContainerFlex Justify="space-between" Padding="8px 0">
                    <Text FontFamily="Nunito" FontSize="1rem" Color="#2A2C2F" FontWeight="700">
                        {PAYMENT_SUMMARY.PAYMENT_ITEMS[2].TITLE}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="1rem" Color="#2A2C2F" FontWeight="700">
                        {formatCurrency(totalPayment.toString())}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default SuccessSummary;
