import {
    HANDLE_CHECKBOX_PAYMENT_METHODS,
    SET_PAYMENT_METHODS,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IPaymentMethodState} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const handleCheckBoxPaymentMethods = (id: number) => {
    return {
        type: HANDLE_CHECKBOX_PAYMENT_METHODS,
        payload: id,
    };
};

export const setPaymentMethods = (newPaymentMethodsArray: IPaymentMethodState[]) => {
    return {
        type: SET_PAYMENT_METHODS,
        newPaymentMethodsArray,
    };
};
