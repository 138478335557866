import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import arrowBack from '@images/arrow_back.svg';
import logo from '@images/logo-multiapoyo.svg';
import {PayMethod} from '@Shopify/ClientProfile/PayMethod';
import downloadContract from '@images/download_focused.svg';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {CONTRACT_DETAIL} from '@Shopify/ClientProfile/constants';
import {ImagesDetails} from '@Shopify/ClientProfile/ImagesDetails';
import {ContractResume} from '@Shopify/ClientProfile/ContractResume';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ContainerTabs} from '@/components/General/ModuleTabs/styles/styles';
import {ContractTabArticles} from '@Shopify/ClientProfile/ContractTabArticles';
import {ContainerFlex, Text, Image, ContainerImg, ContainerGrid} from '@Shopify/Ecommerce/styles';
import {
    detailsContract,
    downloadContracts,
} from '@Shopify/ClientProfile/Redux/Action/DetailsContract';
import {ContractGarmentsAccount} from '@Shopify/ClientProfile/ContractGarmentsAccount';
import {StyledLinkQuoter} from '@/components/Articles/style';

export const ContractDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [modalImg, setModalImg] = useState(false);

    const {clientId, token, branch, details, loading, pawnId, downloadUrl} = useSelector(
        (state: RootState) => ({
            details: state.detailContract.details,
            loading: state.detailContract.loading,
            pawnId: state.detailContract.pawnId,
            downloadUrl: state.detailContract.downloadUrl,
            clientId: state.cardClientProfile.clientProfile.clientId,
            token: state.getUsersValidation.userData?.token as string,
            branch: state.getUsersValidation.userData?.branchDetails?.[0]?.branchId as number,
        })
    );

    useEffect(() => {
        dispatch(detailsContract(token, {branchId: branch, clientId: clientId, pawnId: pawnId}));
        dispatch(downloadContracts(token, pawnId));
    }, []);

    return loading ? (
        <ContainerFlex>
            <LoadingAtaskate />
        </ContainerFlex>
    ) : (
        <ContainerFlex {...style.datailContent}>
            <ContainerFlex
                onClick={() => navigate('/Clients/ClientProfile')}
                Justify="start"
                Gap="0.5rem"
                Height="1.5rem"
                Cursor="pointer"
            >
                <Image src={arrowBack} alt="icon-back" Width="1.5rem" Height="1.5rem" />
                <Text Color="#5A5AFF" Cursor="pointer">
                    {CONTRACT_DETAIL.RETURN}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.contenSectionDeatil} FlexDir="Column">
                <ContainerGrid Justify="space-between" GridColumns="repeat(2, 1fr)">
                    <Text Color="#000000" FontSize="1.5rem" FontWeight="700">
                        {CONTRACT_DETAIL.PAWN_DETAIL}
                    </Text>
                    <ContainerImg Justify="end">
                        <Image src={logo} alt="logo" width="147rem" height="29.7rem" />
                    </ContainerImg>
                    <Text Color="#000" FontSize="1.25rem" FontWeight="600">{`${
                        CONTRACT_DETAIL.ENDEAVOR
                    } #${details && details.pawnNumber}`}</Text>
                    <Text
                        JustSelf="end"
                        Cursor="pointer"
                        Color="#5A5AFF"
                        FontSize="0.875rem"
                        FontWeight="700"
                    >
                        {CONTRACT_DETAIL.ONLINE_STORE}
                    </Text>
                </ContainerGrid>
                <ContractResume />
                <ContainerFlex {...style.garments}>
                    <ContainerFlex Justify="space-between">
                        <Text Color="#000000" FontSize="1.25rem" FontWeight="700">
                            {CONTRACT_DETAIL.PAWN_DETAIL}
                        </Text>
                        {downloadUrl && (
                            <StyledLinkQuoter href={downloadUrl} target="_self">
                                <Text
                                    Color="#5A5AFF"
                                    FontSize="0.875rem"
                                    FontWeight="700"
                                    Gap="0.25rem"
                                    Cursor="pointer"
                                >
                                    <Image
                                        Cursor="pointer"
                                        src={downloadContract}
                                        alt="icon-download"
                                    />
                                    {CONTRACT_DETAIL.DOWNLOAD_CONTRACT}
                                </Text>
                            </StyledLinkQuoter>
                        )}
                    </ContainerFlex>
                    <ContractGarmentsAccount />
                    <ContainerTabs
                        tabs={CONTRACT_DETAIL.TAB_CONTRACT}
                        fontWeight="700"
                        {...style.tabsGarments}
                    >
                        <ContractTabArticles setModalImg={setModalImg} />
                        <ContainerFlex>{CONTRACT_DETAIL.TAB_CONTRACT[1]}</ContainerFlex>
                    </ContainerTabs>
                </ContainerFlex>
            </ContainerFlex>
            <PayMethod />
            {modalImg && <ImagesDetails modalImg={modalImg} setModalImg={setModalImg} />}
        </ContainerFlex>
    );
};
