import React from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {CreateAccount} from '@components/Login/MarketPlaceLogin/CreateAccount';
import {CreateUserWithEmail} from '@Login/MarketPlaceLogin/CreateUserWithEmail';
import {TermAndConditions} from '@Login/MarketPlaceLogin/TermsAndConditions';
import {AtaskateHeader} from '@components/Login/MarketPlaceLogin/Ataskateheader';
import {TYPE_OF_SCREEN} from '@components/Login/constants';

export const LoginClient = () => {
    const Screen: {data: number} = useSelector((state: RootState) => state.getScreenSelect);

    return (
        <ContainerFlex Height="100vh" backG="#ACACFF">
            <AtaskateHeader />
            <ContainerFlex
                backG="#E5E5FF"
                FlexDir="column"
                Width="50%"
                Height="100vh"
                Gap="32px"
                OverFlowY="hidden"
                Padding="20px"
                Justify="space-between"
                Align="center"
            >
                <ContainerFlex
                    Justify={Screen.data === TYPE_OF_SCREEN.CREATE_ACCOUNT ? 'center' : 'start'}
                >
                    {Screen.data === TYPE_OF_SCREEN.CREATE_ACCOUNT && <CreateAccount />}
                    {Screen.data === TYPE_OF_SCREEN.CREATE_USER_EMAIL && <CreateUserWithEmail />}
                </ContainerFlex>
                <TermAndConditions />
            </ContainerFlex>
        </ContainerFlex>
    );
};
