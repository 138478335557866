import {AppDispatch} from '@/config/store';
import {NavigateFunction} from 'react-router-dom';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {IAddStorage} from '@components/LocationsBranch/interfaces';
import {NEW_STORAGE} from '@components/LocationsBranch/Redux/types';

export const newStorageStart = () => ({type: NEW_STORAGE.START});
export const newStorageSuccess = (result: {data: AxiosResponse}) => ({
    type: NEW_STORAGE.SUCCESS,
    newStorage: result.data,
});
export const newStorageError = (error: AxiosError) => ({
    type: NEW_STORAGE.ERROR,
    error: error,
});
export const newStorageGetStart = () => ({type: NEW_STORAGE.GET_STORAG_START});
export const getEditStorage = (result: {data: AxiosResponse}) => ({
    type: NEW_STORAGE.GET_STORAGE_SUCCESS,
    newStorage: result.data,
});
export const getEditStorageError = (error: AxiosError) => ({
    type: NEW_STORAGE.GET_STORAGE_ERROR,
    error: error,
});
export const resetNewStorage = () => ({
    type: NEW_STORAGE.GET_RESET_STORAGE,
});

export const newStorage = (token: string, navigate: NavigateFunction, storage: IAddStorage) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(newStorageStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateStorageByLevelId}`,
                storage,
                {headers}
            );
            dispatch(newStorageSuccess(response.data));
            navigate('/LocationBranch');
        } catch (error) {
            dispatch(newStorageError(error as AxiosError));
        }
    };
};

export const getStorage = (token: string, body: {LevelId: number; StorageId: number}) => {
    const {LevelId, StorageId} = body;

    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(newStorageGetStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetStorage.replace(
                    '{LevelId}',
                    LevelId.toString()
                ).replace('{StorageId}', StorageId.toString())}`,
                {
                    headers,
                }
            );
            dispatch(getEditStorage(response.data));
        } catch (error) {
            dispatch(getEditStorageError(error as AxiosError));
        }
    };
};

export const updateStorage = (token: string, navigate: NavigateFunction, body: IAddStorage) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(newStorageGetStart());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateStorage}`,
                body,
                {
                    headers,
                }
            );
            navigate('/LocationBranch');
        } catch (error) {
            dispatch(newStorageError(error as AxiosError));
        }
    };
};
