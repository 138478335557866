import React, {useState} from 'react';

import {ToastsWrapper} from '@General/Atoms/AppToast/styles';
import {ToastConfig, ToastMessage} from '@General/Atoms/AppToast/interfaces';
import Toast from '@General/Atoms/AppToast/Toast';

let toastIdCounter = 0;

const useToast = () => {
    const [toasts, setToasts] = useState<ToastMessage[]>([]);

    const showToast = (config: ToastConfig) => {
        const {title, message, type, size, duration = 3000, button, onTimeoutCloseAction} = config;

        setToasts((prev) => {
            const existingToast = prev.find(
                (toast) => toast.title === title && toast.message === message && toast.type === type
            );

            if (existingToast) {
                return prev.filter((toast) => toast.id !== existingToast.id);
            }

            return prev;
        });

        toastIdCounter += 1;
        const id = toastIdCounter;
        setToasts((prev) => [
            ...prev,
            {id, title, message, type, size, duration, button, onTimeoutCloseAction},
        ]);

        setTimeout(() => {
            setToasts((prev) => prev.filter((t) => t.id !== id));
            if (onTimeoutCloseAction) {
                onTimeoutCloseAction();
            }
        }, duration);
    };

    const ToastContainer = () => (
        <ToastsWrapper>
            {toasts.map((toast) => (
                <Toast
                    key={toast.id}
                    id={toast.id}
                    title={toast.title}
                    message={toast.message}
                    type={toast.type}
                    duration={toast.duration}
                    size={toast.size}
                    onClose={() => setToasts((prev) => prev.filter((t) => t.id !== toast.id))}
                    button={toast.button}
                />
            ))}
        </ToastsWrapper>
    );

    return {showToast, ToastContainer};
};

export default useToast;
