import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {FOCUSED_COLORS, GENERAL_TITLES} from '@components/Users/UserProfile/constants';
import {PersonalInfo} from '@components/Users/UserProfile/PersonalInfo';
import {AddressInfo} from '@components/Users/UserProfile/AddressInfo';

export const ContactData = () => {
    return (
        <ContainerFlex FlexDir="column" Gap="16px" Justify="start" Align="start">
            <Text FontSize="1.5rem" FontWeight="700">
                {GENERAL_TITLES.TITLE_CONTACT}
            </Text>
            <ContainerFlex
                Radius="16px"
                Border={FOCUSED_COLORS.BORDER_LEFT}
                Justify="start"
                Align="start"
                FlexDir="column"
                Padding="16px"
                Gap="16px"
            >
                <PersonalInfo />
                <ContainerFlex Height="1px" backG=" #E8E9EA" />
                <AddressInfo />
            </ContainerFlex>
        </ContainerFlex>
    );
};
