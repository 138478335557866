import React, {useEffect} from 'react';
import {DateTime} from 'luxon';
import {useForm, Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Table} from '@DeadLines/styles';

import {
    getSecurityBagWeightEdit,
    postSecurityBagWeight,
} from '@ActionsSecurityBagWeight/SecurityBagWeightActions';

export function SecurityBagWeightEdit() {
    const {handleSubmit, register, control} = useForm({
        defaultValues: {
            sucursal: '',
            costo: '',
            fecha: '',
            region: '',
            fundidora: '',
            prendas: '',
            asignado: '',
        },
    });
    const token = useSelector((state) => {
        return state.getUsersValidation.userData?.token;
    });
    const {id} = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            const getSecurityBagWeightData = () => {
                dispatch(getSecurityBagWeightEdit(id, token));
            };
            getSecurityBagWeightData();
        }
    }, []);

    const identify = useSelector((state) => {
        return state.securitybagweight.identifyfoundry;
    });

    const securitybagweightedit = useSelector((state) => {
        return state.securitybagweight.securitybagweightedit;
    });

    const formatterDolar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const onSubmitAudit = async (values) => {
        let objetos = values.kilatajes.map((item) => {
            let json = {
                kilataje: Number(item.kilataje),
                pesoAuditoria: Number(item.pesoAuditoria),
                pesoTestigo: Number(item.pesoTestigo),
            };
            return json;
        });
        let json = {
            idFundicion: identify,
            seguribolsa: id,
            observaciones: values.observaciones,
            kilatajes: objetos,
        };
        await dispatch(postSecurityBagWeight(json, token));
        window.location.href = `/Fundiciones/Fundicion`;
    };
    return (
        <>
            {Object.keys(securitybagweightedit).length !== 0 ? (
                <>
                    <div className="pt-2 pb-2 flex align-items-center w-90 mx-auto">
                        <span className="child-breadcrumb">Fundiciones</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="child-breadcrumb">Fundición</span>
                        <span className="pt-2">
                            <i className="material-icons arrow-breadcrumb">keyboard_arrow_right</i>{' '}
                        </span>
                        <span className="principal-breadcrumb">Peso de seguribolsa</span>
                    </div>
                    <div className="pt-2 pb-2 flex align-items-center w-50 mx-auto">
                        <form
                            className="flex container-form pl-4 mt-5"
                            onSubmit={handleSubmit(onSubmitAudit)}
                        >
                            <div className="flex justify-content-between w-100">
                                <span>
                                    Pesa el oro de las seguribolsas por kilataje y captura los
                                    resultados.
                                </span>
                                <button className="w-30 pl-5 pr-5 btn-add-incidents h-40">
                                    GUARDAR
                                </button>
                            </div>
                            <div className=" flex flex-wrap pt-4 w-90 mx-auto">
                                <span className="w-50 pl-4">
                                    Auditor:{' '}
                                    {securitybagweightedit && securitybagweightedit.auditor}
                                </span>
                                <span className="w-50 ">
                                    Región: {securitybagweightedit && securitybagweightedit.region}
                                </span>
                                <span className="w-50 pl-4">
                                    Clave: {securitybagweightedit && securitybagweightedit.clave}
                                </span>
                                <span className="w-50 ">
                                    Sucursal:{' '}
                                    {securitybagweightedit && securitybagweightedit.sucursal}
                                </span>
                                <span className="w-50 pl-4">
                                    Fecha de elaboración:{' '}
                                    <span>
                                        {securitybagweightedit &&
                                            DateTime.fromISO(securitybagweightedit.fecha).toFormat(
                                                'dd/MM/yyyy'
                                            )}
                                    </span>
                                </span>
                                <span className="w-50 ">
                                    Valor:{' '}
                                    {securitybagweightedit &&
                                        formatterDolar.format(securitybagweightedit.costo)}
                                </span>
                            </div>
                            <Table className="w-92 mx-auto mt-4">
                                <thead className="title-head-foundry">
                                    <tr>
                                        <th className="text-center">Kilataje</th>
                                        <th className="text-center">Peso sistema</th>
                                        <th className="text-center">Peso total</th>
                                    </tr>
                                </thead>

                                {securitybagweightedit &&
                                    securitybagweightedit.kilatajes.map(
                                        (recolectionitems, index) => (
                                            <tr className="row-sucursal-tr pt-4" key={index}>
                                                <td className="">
                                                    <Controller
                                                        control={control}
                                                        className="input-search"
                                                        name={`kilatajes[${index}].kilataje`}
                                                        defaultValue={recolectionitems.kilataje}
                                                        disabled={true}
                                                        as={<input />}
                                                    />
                                                </td>
                                                <td>
                                                    <Controller
                                                        control={control}
                                                        className="input-search"
                                                        name={`kilatajes[${index}].pesoAuditoria`}
                                                        defaultValue={
                                                            recolectionitems.pesoAuditoria
                                                        }
                                                        disabled={true}
                                                        as={<input />}
                                                    />
                                                </td>
                                                <td>
                                                    <Controller
                                                        control={control}
                                                        className="input-search"
                                                        name={`kilatajes[${index}].pesoTestigo`}
                                                        defaultValue={recolectionitems.pesoTestigo}
                                                        disabled={
                                                            recolectionitems.pesoAuditoria === 0
                                                                ? true
                                                                : false
                                                        }
                                                        as={<input />}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    )}
                            </Table>
                            <div className="w-100 flex pr-5 pt-5">
                                <label className="w-25">Observaciones</label>

                                <input
                                    className="input-react-hook-form"
                                    {...register('observaciones', {})}
                                />
                            </div>
                        </form>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
