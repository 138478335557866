import React, {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {AppDispatch} from '@config/store';

import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {Table, TableItem, TableItems, TableItemsContainer} from '@DeadLines/styles';
import {StyleNumericFormat} from '@Loan/styles';
import {PercentageFormat, QuitNumberFormat, limitNumberFormat} from '@/hooks/currentFormatUtils';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {discounts, orderDiscounts, tableDiscount} from '@CreateDeadline/interfaces';
import edit from '@images/edit.svg';
import {MenuIcons} from '@/components/Roles/styles';
import {discountsTable, haveDiscount} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import {interested} from '@/components/DeadLines/CreateDeadline/redux/Constants';
import {
    TableRowItemsContract,
    TableRowHeadContract,
} from '@DeadLines/CreateDeadline/DeadlineSteps/AmountAndTimes/styles';

export const TableDiscounts = (props: discounts) => {
    const {deadlineById, interest, deadlinesGeneral} = props;
    const dispatch: AppDispatch = useDispatch();
    const [endorsement, setEndorsement] = useState(false);
    const [discharge, setDischarge] = useState(false);
    const {handleSubmit, reset, getValues, control, watch} = useForm({
        defaultValues: {
            haveDiscount: interest,

            order: '',
            discountPercentage: '',
            discharge: false,
            endorsement: false,
            quantityDiscounts: '',
        },
    });
    const dataReset = watch();

    const discountTable = (values: tableDiscount) => {
        if (values.discountPercentage !== '') {
            const tableDiscount = {
                endorsement: endorsement,
                discharge: discharge,
                discountPercentage: QuitNumberFormat(values.discountPercentage),
                quantityDiscounts: QuitNumberFormat(values.quantityDiscounts),
            };
            dispatch(haveDiscount(interest));
            if (interest)
                dispatch(discountsTable([...deadlinesGeneral.promotionDiscount, tableDiscount]));

            reset({
                order: '',
                endorsement: false,
                discharge: false,
                discountPercentage: '',
                quantityDiscounts: '',
            });
        }
        setEndorsement(false);
        setDischarge(false);
    };

    const handleEdit = (id: number) => {
        const editDiscount = deadlinesGeneral.promotionDiscount.filter(
            (item: {order: number}) => item.order === id
        )[0];
        if (
            deadlinesGeneral.promotionDiscount.filter((item: {order: number}) => item.order === id)
        ) {
            reset({
                endorsement: editDiscount.endorsement,
                discharge: editDiscount.discharge,
                discountPercentage: PercentageFormat(editDiscount.discountPercentage),
                quantityDiscounts: String(editDiscount.quantityDiscounts),
            });
            setEndorsement(editDiscount.endorsement);
            setDischarge(editDiscount.discharge);
        }

        dispatch(
            discountsTable(
                deadlinesGeneral.promotionDiscount.filter(
                    (item: {order: number}) => item.order !== id
                )
            )
        );
    };

    const onSubmit = () => {
        dispatch(haveDiscount(interest));
        if (interest) dispatch(discountsTable(deadlinesGeneral.promotionDiscount));
    };

    useEffect(() => {
        if (deadlinesGeneral.promotionDiscount.length > 0) {
            const newData = deadlinesGeneral.promotionDiscount.reduce(
                (acc: interested<orderDiscounts>, item, index) => {
                    acc[index] = {
                        order: index + 1,
                        endorsement: item.endorsement,
                        discharge: item.discharge,
                        discountPercentage: item.discountPercentage,
                        quantityDiscounts: item.quantityDiscounts,
                    };
                    return acc;
                },
                {}
            );
            dispatch(discountsTable(Object.values(newData)));
        }
    }, [deadlinesGeneral.promotionDiscount.length]);

    return (
        <FormContainer
            onSubmit={handleSubmit(onSubmit)}
            Justify="center"
            autoComplete="off"
            Width="100%"
        >
            <Table>
                <TableRowHeadContract
                    Height="48px"
                    Grid={
                        deadlineById.edit
                            ? '0.5fr 0.5fr 0.5fr 0.5fr'
                            : '0.5fr 0.5fr 0.5fr 0.5fr 0.1fr'
                    }
                >
                    <TableItem Justify="center" FontSize="0.875rem">
                        Refrendo
                    </TableItem>
                    <TableItem Justify="center" FontSize="0.875rem">
                        Desempeña
                    </TableItem>
                    <TableItem Justify="center" FontSize="0.875rem">
                        Cantidad
                    </TableItem>
                    <TableItem Justify="center" FontSize="0.875rem">
                        % Descuento
                    </TableItem>
                </TableRowHeadContract>

                {!deadlineById.edit && (
                    <TableItemsContainer>
                        <TableRowItemsContract
                            Height="60px"
                            Grid={
                                deadlineById.edit
                                    ? '0.5fr 0.5fr 0.5fr 0.5fr'
                                    : '0.5fr 0.5fr 0.5fr 0.5fr 0.1fr'
                            }
                        >
                            <TableItems InputHeight="1rem" Justify="center">
                                <Checkbox
                                    checked={endorsement}
                                    disabled={deadlineById.edit}
                                    onChange={() => {
                                        setEndorsement(!endorsement);
                                        reset({
                                            discountPercentage: dataReset.discountPercentage,
                                            discharge: false,
                                            endorsement: false,
                                        });
                                    }}
                                />
                            </TableItems>
                            <TableItems InputHeight="1rem" Justify="center">
                                <Checkbox
                                    name="discharge"
                                    checked={discharge}
                                    disabled={deadlineById.edit}
                                    onChange={() => {
                                        setDischarge(!discharge);
                                        reset({
                                            discountPercentage: dataReset.discountPercentage,
                                            discharge: false,
                                            endorsement: false,
                                        });
                                    }}
                                />
                            </TableItems>
                            <TableItems Justify="center" InputHeight="40px" Width="70%">
                                <Controller
                                    name="quantityDiscounts"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            textAlign="center"
                                            type="text"
                                            height="25px"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    discharge ? 2 : 11
                                                )
                                            }
                                        />
                                    )}
                                />
                            </TableItems>
                            <TableItems Justify="center" InputHeight="40px" Width="70%">
                                <Controller
                                    name="discountPercentage"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            textAlign="center"
                                            type="text"
                                            height="25px"
                                            suffix="%"
                                            defaultValue=""
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            isAllowed={(values: {value: string}) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </TableItems>
                            <TableItems Justify="center">
                                <span
                                    className="material-icons"
                                    onClick={() => {
                                        getValues([
                                            'discountPercentage',
                                            'discharge',
                                            'endorsement',
                                            'quantityDiscounts',
                                        ]);

                                        const getData = getValues();
                                        discountTable(getData);
                                    }}
                                >
                                    save
                                </span>
                            </TableItems>
                        </TableRowItemsContract>
                    </TableItemsContainer>
                )}

                {deadlinesGeneral.promotionDiscount.map((item, index: number) => (
                    <TableItemsContainer key={index}>
                        <TableRowItemsContract
                            Height="60px"
                            Grid={
                                deadlineById.edit
                                    ? '0.5fr 0.5fr 0.5fr 0.5fr'
                                    : '0.5fr 0.5fr 0.5fr 0.5fr 0.1fr'
                            }
                        >
                            <TableItems InputHeight="1rem" Justify="center">
                                <Checkbox checked={item.endorsement} />
                            </TableItems>
                            <TableItems InputHeight="1rem" Justify="center">
                                <Checkbox checked={item.discharge} />
                            </TableItems>
                            <TableItems Justify="center">{item.quantityDiscounts}</TableItems>
                            <TableItems Justify="center">
                                {PercentageFormat(item.discountPercentage)}
                            </TableItems>

                            {!deadlineById.edit && (
                                <TableItems Justify="center">
                                    <MenuIcons
                                        Width="1.25rem"
                                        Height="1.25rem"
                                        onClick={() => handleEdit(item.order)}
                                        src={edit}
                                        Cursor="pointer"
                                    />
                                </TableItems>
                            )}
                        </TableRowItemsContract>
                    </TableItemsContainer>
                ))}
            </Table>
        </FormContainer>
    );
};
