import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {
    ContainerFlex,
    Text,
    Input,
    Image,
    ContainerGrid,
} from '@/components/Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    EDITING_TYPE_ACTION,
    TYPEDOCUMENTS,
    timeOptions,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import AddIcon from '@images/add - plus.svg';
import {RootState} from '@/config/store';
import {AddNewDocumentForm} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/AddNewDocumentForm';
import {IDocumentsCopyArray} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';
import checkDisableCheckboxIcon from '@components/PersonalLoans/NewLoanProduct/icons/checkDisableCheckboxIcon.svg';
import {IDocumentsTypesFormProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/clientInterfaces';
import {updateDocumentsCopyArray} from '@/components/PersonalLoans/Redux/Actions/UpdatePersonalDocumentsActions';

export const DocumentsTypesForm: React.FC<IDocumentsTypesFormProps> = ({control, register}) => {
    const dispatch = useDispatch();
    const {updatedDocuments} = useSelector((state: RootState) => {
        return state.UpdatePersonalDocumentsReducer;
    });
    const documentOptions = useSelector((state: RootState) => {
        return state.newLoanSubmittedData.details.documents;
    });
    const [documentsCopy, setDocumentsCopy] = useState<IDocumentsCopyArray[]>([]);
    const [addDocuments, setAddDocuments] = useState<boolean>(false);
    const [mainCheckbox, setMainCheckbox] = useState<boolean>(false);
    const handleCheckboxChange = (id: number) => {
        const newArrayIsChecked = updatedDocuments.map((item) => {
            if (item.id === id) {
                return {...item, isChecked: !item.isChecked};
            }
            return item;
        });
        dispatch(updateDocumentsCopyArray(newArrayIsChecked));
        if (newArrayIsChecked.some((item) => item.isChecked === false)) {
            setMainCheckbox(false);
        } else {
            setMainCheckbox(true);
        }
    };
    const handleSelectAllCheckbox = () => {
        if (mainCheckbox) {
            dispatch(
                updateDocumentsCopyArray(
                    updatedDocuments.map((item) => {
                        return {...item, isChecked: false};
                    })
                )
            );
        } else {
            dispatch(
                updateDocumentsCopyArray(
                    updatedDocuments.map((item) => {
                        return {...item, isChecked: true};
                    })
                )
            );
        }

        setMainCheckbox(!mainCheckbox);
    };
    useEffect(() => {
        const newDocumentsArray = documentOptions.map((item) => {
            return {
                id: item.documentId,
                documentId: item.documentId,
                docName: item.docName,
                docDescription: item.docDescription,
                seniorityJob: item.seniorityJob,
                seniorityJobTime: item.seniorityJobTime,
                isChecked: item.isChecked,
            };
        });
        if (updatedDocuments.length === 0) {
            dispatch(updateDocumentsCopyArray(newDocumentsArray));
            setMainCheckbox(documentOptions.every((item) => item.isChecked));
        }
    }, [documentOptions]);

    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column">
            <ContainerGrid
                GridColumns="0.05fr 0.5fr 0.5fr"
                Align="center"
                Height="48px"
                Padding="0.5rem 1rem"
                BorderBt="solid 1px #E8E9EA"
            >
                <ContainerFlex Justify="start" Padding="0 8px">
                    <Checkbox checked={mainCheckbox} onChange={handleSelectAllCheckbox} />
                </ContainerFlex>
                <Text oFlow="normal" wSpace="pre" Color="#2A2C2F" FontWeight="700">
                    {TYPEDOCUMENTS.DOCUMENT}{' '}
                </Text>
                <Text oFlow="normal" wSpace="pre" Color="#2A2C2F" FontWeight="700">
                    {TYPEDOCUMENTS.REQUIRED}
                    <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                        {TYPEDOCUMENTS.OPTIONAL}
                    </Text>
                </Text>
                <ContainerFlex />
            </ContainerGrid>
            {updatedDocuments.map((item, index: number) => (
                <ContainerGrid
                    key={index}
                    GridColumns="0.02fr 0.5fr 0.5fr"
                    Justify="start"
                    Height="70px"
                    Padding="0.5rem 1rem"
                    BorderBt="solid 1px #E8E9EA"
                    Gap="1rem"
                >
                    <ContainerFlex Justify="start" Padding="0 8px">
                        {item.id === 1 ? (
                            <ContainerFlex backG="#E5E5FF" Width="18px" Height="18px" Radius="6px">
                                <Image src={checkDisableCheckboxIcon} Width="16px" Height="16px" />
                            </ContainerFlex>
                        ) : (
                            <Controller
                                name={`documents[${index}].isChecked`}
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <Checkbox
                                        {...field}
                                        checked={item.isChecked}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleCheckboxChange(item.id);
                                        }}
                                    />
                                )}
                            />
                        )}
                    </ContainerFlex>
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text Color="#2A2C2F">{item.docName}</Text>
                        <Text Color="#54575C" FontSize="0.875rem">
                            {item.docDescription}
                        </Text>
                    </ContainerFlex>
                    {item.docName === TYPEDOCUMENTS.JOB_DOC && (
                        <ContainerFlex>
                            <ContainerFlex Justify="center">
                                <Text FontSize="0.875rem" Color="#2A2C2F" MarginRight="1rem">
                                    {TYPEDOCUMENTS.TIME}
                                </Text>
                                <Input
                                    Radius="32px 0 0 32px"
                                    type="number"
                                    Width="98px"
                                    placeholder="N.º"
                                    {...register(`documents[${index}].seniorityJob`)}
                                />
                                <Controller
                                    name={`documents[${index}].seniorityJobTime`}
                                    control={control}
                                    render={({field: {value, onChange, ...field}}) => (
                                        <SelectGeneral
                                            {...field}
                                            options={timeOptions}
                                            value={value}
                                            borderRadius="0px 32px 32px 0px"
                                            border="1px solid #E8E9EA"
                                            background="#FFFFFF"
                                            width="150px"
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                </ContainerGrid>
            ))}
            {addDocuments ? (
                <AddNewDocumentForm
                    addDocuments={addDocuments}
                    setAddDocuments={setAddDocuments}
                    documentsCopy={documentsCopy}
                    setDocumentsCopy={setDocumentsCopy}
                    typeAction={EDITING_TYPE_ACTION}
                />
            ) : (
                <ContainerFlex Justify="start" MarginTop="1rem" Gap="0.5rem" Height="1.5rem">
                    <Image
                        src={AddIcon}
                        alt="add-icon"
                        Cursor="pointer"
                        onClick={() => setAddDocuments(true)}
                    />
                    <Text
                        FontWeight="700"
                        Color="#5A5AFF"
                        Cursor="pointer"
                        onClick={() => setAddDocuments(true)}
                    >
                        {TYPEDOCUMENTS.ANOTHERDOC}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
