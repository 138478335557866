import {components, MultiValueRemoveProps} from 'react-select';
import React from 'react';
import {Image} from '@/components/Shopify/Ecommerce/styles';
import closeMultiValueIcon from '@components/PersonalLoans/NewLoanProduct/icons/closeMultiValueIcon.svg';

export const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
        <components.MultiValueRemove {...props}>
            <Image
                Width="20px"
                Height="20px"
                src={closeMultiValueIcon}
                Cursor="pointer"
                alt="close-icon"
            />
        </components.MultiValueRemove>
    );
};
