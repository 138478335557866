import React from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import CompanyInfo from '@components/GlobalQuoter/CompanyInfo';
import LoanInfo from '@components/GlobalQuoter/LoanInfo';
import LoanListCardButtons from './LoanListCardButtons';
import {DivisorLine} from '@components/MyAccount/styles';
import {ILoanLisCard} from '@components/GlobalQuoter/interface';

const LoanLisCard = ({loan}: ILoanLisCard) => {
    return (
        <>
            <ContainerFlex Height="129px" Justify="flex-start">
                <CompanyInfo
                    needTag={loan.isSponsored}
                    companyName={loan.companyName}
                    rating={loan.startsCount}
                    logo={loan.companyLogo}
                    companyAvatarColor={loan.companyAvatarColor}
                />
                <LoanInfo
                    maxAmount={loan.amountMax}
                    countPayments={loan.countPayments}
                    frecuencyName={loan.frecuencyName}
                    anualRate={loan.anualRate}
                />
                <LoanListCardButtons
                    companyId={loan.companyId}
                    frequencyTermCreditId={loan.frequencyTermCreditId}
                />
            </ContainerFlex>
            <DivisorLine width="100%" height="1px" />
        </>
    );
};

export default LoanLisCard;
