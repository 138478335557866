import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import useFormatDate from '@/hooks/useFormatDate';
import {formatToDBDateString, formatYearDate} from '@/hooks/useFormatDate';
import IdentityDocsModal from '@Shopify/ClientProfile/IdentityDocsModal';
import IdentityDocInputBox from '@Shopify/ClientProfile/IdentityDocInputBox';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import InfoBlock from '@/components/Shopify/ClientProfile/Information/InfoBlock';
import {IDENTITY_DOCUMENT, ONE_IMAGE_DOCS} from '@Shopify/ClientProfile/constants';
import {AddIdDocumentData} from '@components/Shopify/ClientProfile/interfaces';
import {addIdDocument} from '@components/Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {getIdentificationTypeList} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {IDocumentsInfo} from '@components/Shopify/ClientProfile/interfaces';

const IdentityDocumentInfo = () => {
    const dispatch = useDispatch();
    const [showIdDocumentModal, setShowIdDocumentModal] = useState(false);
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const clientIdDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.identityDocuments
    );
    const addedIdImageDocs = useSelector(
        (state: RootState) => state.addIdentificationDoc.imageData
    );

    const idDocument = clientIdDocs && clientIdDocs[0];

    const {clientProfile} = useSelector((state: RootState) => state.cardClientProfile);
    useEffect(() => {
        dispatch(getIdentificationTypeList(token));
    }, []);

    const addDocument = async (document: IDocumentsInfo) => {
        const formattedDate = formatYearDate(Number(document.expiryDate!));
        const formattedDateEffective = formatYearDate(Number(document.validity));
        const requireReverseImg = !ONE_IMAGE_DOCS.includes(document.catIdentificationTypeId);
        const imageData: AddIdDocumentData = {
            clientId: clientProfile.clientId,
            identificationTypeId: Number(document.catIdentificationTypeId),
            effectiveDate: formatToDBDateString(formattedDateEffective),
            expirationDate: formatToDBDateString(formattedDate),
            havingReverse: requireReverseImg,
            identificationNumber: document.identificationNumber,
            listImage: [
                {
                    fileExtension: String(document.frontImage?.fileExtension),
                    fileContent: String(document.frontImage?.formFile),
                    havingReverse: false,
                },
                ...(requireReverseImg
                    ? [
                          {
                              fileExtension: String(document.backImage?.fileExtension),
                              fileContent: String(document.backImage?.formFile),
                              havingReverse: true,
                          },
                      ]
                    : []),
            ],
        };
        await dispatch(addIdDocument(imageData, addedIdImageDocs, token, {fetchDetails: true}));
        setShowIdDocumentModal(false);
    };

    return (
        <>
            <ContainerFlex
                Padding="1rem 1.5rem"
                FlexDir="column"
                Align="start"
                Gap="1rem"
                Radius="1.5rem"
                Border="1px solid #E8E9EA"
                Self="stretch"
            >
                <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                    {IDENTITY_DOCUMENT.TITLE}
                </Text>
                <ContainerFlex
                    Align="flex-start"
                    Justify="start"
                    FlexDir="column"
                    AlignContent="flex-start"
                    Gap="1rem"
                    FlexWrap="nowrap"
                >
                    <ContainerFlex Gap="1rem" Justify="flex-start">
                        <InfoBlock
                            label={IDENTITY_DOCUMENT.DOCUMENT_TYPE}
                            value={idDocument?.typeOfDocuments}
                        />
                        <InfoBlock label={IDENTITY_DOCUMENT.NUMBER} value={idDocument?.number} />
                    </ContainerFlex>
                    <ContainerFlex Gap="1rem" Justify="flex-start">
                        <InfoBlock
                            label={IDENTITY_DOCUMENT.NAME_IN_DOCUMENT}
                            value={idDocument?.nameOnTheDocument}
                        />
                        <InfoBlock
                            label={IDENTITY_DOCUMENT.EXPIRATION_DATE}
                            value={useFormatDate(String(idDocument?.expirationDate))}
                        />
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" Width="50vw">
                        <IdentityDocInputBox
                            onOpenSelection={setShowIdDocumentModal}
                            error={false}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {showIdDocumentModal && (
                <IdentityDocsModal
                    showModal={showIdDocumentModal}
                    setShowModal={setShowIdDocumentModal}
                    onDocumentProvided={addDocument}
                    closeOnSubmit={false}
                />
            )}
        </>
    );
};

export default IdentityDocumentInfo;
