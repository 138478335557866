import styled from '@emotion/styled';
import {Image} from '@components/Shopify/Ecommerce/styles';

interface DropdownContainerProps {
    isOpen: boolean;
    itemHeight?: number;
}

export const Images = styled.img`
    height: 16px;
    width: 16px;
`;

export const Icon = styled.img<{color: string}>`
    font-size: 0.875rem;
    color: ${(props) => props.color};
    width: 16px;
    height: 16px;
`;

export const InputContainer = styled.div<{hasError?: boolean}>`
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => (props.hasError ? '#ef4f55' : '#e1e1e1')};
    border-radius: 32px;
    transition: border-color 0.2s;
    height: 40px;

    &:focus-within {
        border-color: ${(props) => (props.hasError ? ' #ef4f55' : '#5a5aff')};
    }
`;

export const DayInput = styled.input`
    width: 50%;
    font-size: 16px;
    border: none;
    outline: none;
    height: 40px;
    padding: 0 16px;
    border-radius: 32px 0 0 32px;
    background: white;
    text-align: start;
`;

export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: #fafafa;
    width: 50%;
    position: relative;
    border-radius: 0 32px 32px 0;
    cursor: pointer;
`;

export const DropdownButton = styled.div`
    width: calc(100% - 24px);
    font-size: 16px;
    font-family: nunito;
    border: none;
    outline: none;
    background: transparent;
    padding: 16px;
    color: #333;
    height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
`;

export const DropdownOptions = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    height: 280px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
        margin: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background: #a1a1a1;
        }
    }
`;

export const Option = styled.div`
    padding: 8px;
    cursor: pointer;
    height: 24px;
    display: flex;
    align-items: center;
    font-family: nunito;

    &:hover {
        background-color: #f5f5f5;
        color: #5a5aff;
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;

export const ArrowIcon = styled(Image)<{isOpen: boolean}>`
    right: 12px;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.2s ease;
`;

export const PhoneInputContainer = styled.div<{hasError?: boolean}>`
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => (props.hasError ? '#ef4f55' : '#e1e1e1')};
    border-radius: 32px;
    transition: border-color 0.2s;
    height: 40px;
    position: relative;

    &:focus-within {
        border-color: ${(props) => (props.hasError ? '#ef4f55' : '#5a5aff')};
    }
`;

export const DialCodeWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 30%;
    background-color: #fafafa;
    padding: 0 8px;
    border-radius: 32px 0 0 32px;
    position: relative;
`;

export const DialCodeButton = styled.button`
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    cursor: pointer;
    padding: 4px;
    font-size: 16px;
    color: #333;
    width: 100%;
    justify-content: flex-start;
    height: 40px;
`;

export const StyledInput = styled.input<{error?: boolean}>`
    width: 70%;
    font-size: 16px;
    border: none;
    outline: none;
    height: 38px;
    text-align: start;
    border-radius: 0 32px 32px 0;
    padding: 0 16px;
    background: white;

    &::placeholder {
        color: #666;
    }
`;

export const FlagImg = styled.img`
    width: 28px;
    height: 28px;
    margin-right: 4px;
    border-radius: 28px;
`;

export const DropdownContainer = styled.div<DropdownContainerProps>`
    position: absolute;
    top: 45px;
    left: 0;
    width: 120px;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    z-index: 1000;
    display: ${(props) => (props.isOpen ? 'block' : 'none')};
    max-height: ${(props) => `${(props.itemHeight || 40) * 2}px`};
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
`;

export const DropdownOption = styled.div`
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
    height: 40px;

    &:hover {
        background-color: #f5f5f5;
    }
`;

export const AvatarContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const AvatarImage = styled.img`
    width: 62px;
    height: 62px;
    border-radius: 32px;
    object-fit: cover;
`;

export const EditButton = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(25%, -15%);
    width: 24px;
    height: 24px;
    border-radius: 32px;
    border: 1px solid #5a5aff;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &:hover {
        border: 1px solid #000061;
        background-color: rgba(240, 242, 245, 0.5);
    }
`;

export const EditIcon = styled.img`
    width: 16px;
    height: 16px;
`;

interface CanvasProps {
    isDragging: boolean;
}

export const StyledCanvas = styled.canvas<CanvasProps>`
    width: 100%;
    height: 100%;
    cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
`;

export const ErrorContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.25rem;
`;

export const ErrorImage = styled.img`
    width: 1rem;
    height: 1rem;
`;
export const personalStyles = {
    Height: 'max-content',
    Radius: '16px',
    FlexDir: 'column',
    Border: ' 1px solid #E8E9EA',
};

export const hastStyles = {
    Justify: 'start',
    Radius: '8px',
    Padding: '16px',
    backG: '#FFE6E6',
    Gap: '8px',
};

export const searchStyles = {
    Height: '40px',
    Position: 'none',
    GridColumn: '90% 10%',
    Margin: '0 0 4px 0',
    BoxShadowH: '',
    BoxShadowF: '',
    Padding: '0 1rem',
    BackG: '#FFFFFF',
    Radius: '32px',
};
export const PasswordAcces = {
    FlexDir: 'column',
    Height: 'max-content',
    Radius: '16px',
    Border: ' 1px solid #E8E9EA',
    Padding: '24px',
};

export const repeatPassword = {
    Justify: 'start',
    Align: 'start',
    FlexDir: 'column',
    Gap: '4px',
    Height: 'max-content',
};

export const MesageRepeat = {
    FontSize: '0.85rem',
    FontFamily: 'Nunito',
    wSpace: 'normal',
    TextAlign: 'justify',
};

export const seachNewEmploye = {
    Height: '40px',
    Position: 'none',
    GridColumn: 'calc(100% - 24px) 24px',
    Margin: '0 0 4px 0',
    BoxShadowH: '',
    BoxShadowF: '',
    Padding: '0 1rem 0 0',
    BackG: '#FFFFFF',
    Radius: '32px',
    InputPadding: '0 1rem',
};
