import React from 'react';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@/components/TransactionsDetails/Operational/styles';
import {hideDigits, OPERATIONS} from '@Shopify/ClientProfile/constants';
import Printer from '@images/print.svg';
import cards from '@images/mastercardIcon.svg';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ITransactionDetailProps} from '@Shopify/ClientProfile/interfaces';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import useFormatDate from '@/hooks/useFormatDate';
import Swal from 'sweetalert2';

export const TransactionClientDetail = ({
    setShowTransactionDetail,
    showTransactionDetail,
}: ITransactionDetailProps) => {
    const handleCopy = (value: string) => {
        navigator.clipboard.writeText(value).then(() => {
            Swal.fire({
                icon: 'success',
                title: OPERATIONS.TRANSACTIONS.CLIPBOARD_COPY,
                toast: true,
                position: 'top',
                showConfirmButton: false,
                timer: 1000,
                timerProgressBar: true,
            });
        });
    };

    const {
        TITLE,
        REFERENCE_NUMBER,
        TYPE,
        PAWN_SHOP,
        RENEWAL_VALUE,
        LOAN_REDEMPTION_VALUE,
        PAYMENT_DATE,
        PAYMENT_METHOD,
        CLOSE,
    } = OPERATIONS.TRANSACTIONS.DETAIL_TRANSACTION_MODAL.LABELS;

    const {
        REFERENCE_NUMBER: REFERENCE_NUMBER_VALUE,
        TOTAL: TOTAL_VALUE,
        TYPE: TYPE_VALUE,
        PAWN_SHOP: PAWN_SHOP_VALUE,
        RENEWAL: RENEWAL_AMOUNT,
        LOAN_REDEMPTION: LOAN_REDEMPTION_AMOUNT,
        PAYMENT_DATE: PAYMENT_DATE_VALUE,
        PAYMENT_METHOD: PAYMENT_METHOD_VALUE,
    } = OPERATIONS.TRANSACTIONS.DETAIL_TRANSACTION_MODAL.VALUES;

    return (
        <Modal
            modalState={showTransactionDetail}
            titleModalState={false}
            closeOnOutsideClick={false}
            changeModalState={() => setShowTransactionDetail(false)}
            Width="38.375rem"
            Top="0"
            BorderRadius="1.5rem"
            background="none"
            FlexDirMod="Column"
            Padding="1.375rem 1.5rem 1.5rem 1.5rem"
        >
            <ContainerFlex Height={'3.5rem'}>
                <ContainerFlex Justify="flex-start">
                    <Text Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                        {TITLE}
                    </Text>
                    <ContainerFlex
                        Cursor="pointer"
                        Width="2.5rem"
                        Height="2.5rem"
                        Radius="2rem"
                        Border="1px solid #5A5AFF"
                        FlexDir="column"
                        MarginL="1rem"
                    >
                        <ImageLogo
                            Width="1.5rem"
                            Height="1.5rem"
                            src={Printer}
                            alt={OPERATIONS.TRANSACTIONS.PRINT}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="flex-start" Align="start" FlexDir="Column">
                <ContainerFlex
                    Justify="flex-start"
                    Bb="1px solid #e8e9ea"
                    PaddingB="1rem"
                    PaddingT="0.75rem"
                >
                    <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700" Width="6rem">
                        {REFERENCE_NUMBER}
                    </Text>
                    <Text
                        Color="#5A5AFF"
                        FontSize="1rem"
                        FontWeight="700"
                        Width="6rem"
                        Cursor="pointer"
                        onClick={() => {
                            handleCopy(REFERENCE_NUMBER_VALUE);
                        }}
                    >
                        {REFERENCE_NUMBER_VALUE}
                    </Text>
                    <ContainerFlex Justify="flex-end" Height={'2.5rem'}>
                        <Text Color="#2A2C2F" FontSize="1.25rem" FontWeight="700">
                            {numberToCurrency(TOTAL_VALUE)}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    Justify="flex-start"
                    Align="start"
                    Height={'18.625rem'}
                    FlexDir="Column"
                >
                    <ContainerFlex Justify="flex-start" MarginTop="1rem">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                            {TYPE}:
                        </Text>
                        <ContainerFlex
                            Justify="flex-start"
                            Radius="0.875rem"
                            Height="1.25rem"
                            Width="10rem"
                            Border="1px solid #D4D6D8"
                            backG="#D4D6D8"
                            MarginL="0.5rem"
                        >
                            <Text
                                Color="#2A2C2F"
                                FontSize="0.875rem"
                                FontWeight="400"
                                PaddingL="0.5rem"
                            >
                                {TYPE_VALUE}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" MarginTop="1rem">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                            {PAWN_SHOP}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700" PaddingL="0.5rem">
                            {PAWN_SHOP_VALUE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" MarginTop="1rem">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                            {RENEWAL_VALUE}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700" PaddingL="0.5rem">
                            {numberToCurrency(RENEWAL_AMOUNT)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" MarginTop="1rem">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                            {LOAN_REDEMPTION_VALUE}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700" PaddingL="0.5rem">
                            {numberToCurrency(LOAN_REDEMPTION_AMOUNT)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" MarginTop="1rem">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                            {PAYMENT_DATE}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700" PaddingL="0.5rem">
                            {useFormatDate(PAYMENT_DATE_VALUE)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" MarginTop="1rem">
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                            {PAYMENT_METHOD}:
                        </Text>
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700" PaddingL="0.5rem">
                            <Image src={cards} alt="tipo-metodo-pago" Margin="0 0.5rem 0 0" />
                            {hideDigits(PAYMENT_METHOD_VALUE)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="center" MarginTop="2.25rem" PaddingB="1.5rem">
                        <ButtonGeneral
                            width="9.375rem"
                            text={CLOSE}
                            type="submit"
                            cursor="pointer"
                            clic={() => {
                                setShowTransactionDetail(false);
                            }}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
