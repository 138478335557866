import {AxiosResponse} from 'axios';
import {
    GET_TYPE_EVENT_CONDITION_START,
    GET_TYPE_EVENT_CONDITION_SUCCESS,
    GET_TYPE_EVENT_CONDITION_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IGetTypeEventConditionReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/reducerInterfaces';

const initialState: IGetTypeEventConditionReducer = {
    data: [],
    loading: false,
    error: false,
};

const getTypeEventConditionReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetTypeEventConditionReducer => {
    switch (action.type) {
        case GET_TYPE_EVENT_CONDITION_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_TYPE_EVENT_CONDITION_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_TYPE_EVENT_CONDITION_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getTypeEventConditionReducer;
