import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    CREDIT_DETAILS_INFORMATION,
    CREDIT_STATUS,
} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import CreditDetailsDownloadButton from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsDownloadButton';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    ICreditStatus,
    IPersonalCreditDetailsReducer,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {DateTime} from 'luxon';
import {statusColorHandler} from '@/components/CreditsAndPawns/ApplicationRow';

const CreditDetailsInformationLoan: React.FC<ICreditStatus> = ({creditStatus}) => {
    const formatCurrency = useFormatCurrency();
    const {creditLoanDetailData} = useSelector((state: RootState) => {
        return state.GetPersonalCreditAmountReducer as IPersonalCreditDetailsReducer;
    });
    const requestedDate = creditLoanDetailData?.applicationDate
        ? DateTime.fromISO(creditLoanDetailData?.applicationDate).toLocaleString(
              DateTime.DATE_SHORT
          )
        : 'Invalid Date';
    const {color, backgroundColor, borderColor} = statusColorHandler(
        creditLoanDetailData?.stateId || 0
    );
    return (
        <ContainerFlex Width="100%" Gap="24px" FlexDir="column">
            <ContainerFlex Width="100%" Justify="space-between" Align="center">
                <ContainerFlex Align="start" Justify="start">
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#000000">
                        {CREDIT_DETAILS_INFORMATION.LOAN.SUB_TITLE}
                    </Text>
                </ContainerFlex>
                {creditStatus !== CREDIT_STATUS.APPROVED &&
                    creditStatus !== CREDIT_STATUS.UNDER_REVIEW &&
                    creditStatus !== CREDIT_STATUS.REJECTED && (
                        <CreditDetailsDownloadButton
                            ctaText={CREDIT_DETAILS_INFORMATION.LOAN.CTA_TEXT}
                        />
                    )}
            </ContainerFlex>
            <ContainerFlex
                Gap="24px"
                Justify="start"
                Align="start"
                Display="grid"
                GridColumns="repeat(4, 1fr)"
                Height="100%"
                Width="100%"
            >
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.REQUESTED_QUANTITY}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                        {formatCurrency(creditLoanDetailData?.requestedQuantity.toString())}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.APPLICATION_DATE}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                        {requestedDate}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.STATE}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="1.0rem"
                        Color={color}
                        bGround={backgroundColor}
                        Border={`1px solid ${borderColor}`}
                        BorderRadius="32px"
                        Padding="2px 8px"
                    >
                        {creditLoanDetailData?.stateDescription}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.REQUESTED_IN}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                        {creditLoanDetailData?.companyName}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.APPLICATION_FORM}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                        {creditLoanDetailData?.applicationFormDescription}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.FEES}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                        {creditLoanDetailData?.cuotas}
                    </Text>
                </ContainerFlex>
                {creditStatus === CREDIT_STATUS.LIQUIDATED && (
                    <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {CREDIT_DETAILS_INFORMATION.LOAN.FEES_AMOUNT}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {creditLoanDetailData?.valueQuota}
                        </Text>
                    </ContainerFlex>
                )}
                <ContainerFlex Gap="4px" Justify="start" Align="start" FlexDir="column">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAILS_INFORMATION.LOAN.PAYMENT_FREQUENCY}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#2A2C2F">
                        {creditLoanDetailData?.frequencyDescription}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailsInformationLoan;
