import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {OPTIONS} from '@components/ShoppingCart/constants';
import RenewalItem from '@components/ShoppingCart/RenewalItem';
import FulfillingItem from '@components/ShoppingCart/FulfillingItem';

const Renewals = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const carShoppingId = useSelector((state: RootState) =>
        Number(state.getUsersValidation.userData?.carShoopingId)
    );
    const {addOptions} = useSelector((state: RootState) => state.endorsementsReducer);
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    const refreshCart = useSelector((state: RootState) => state.getCartByCartId.refresh);
    const [openMenuId, setOpenMenuId] = useState<string | null>(null);
    const [openMenu, setOpenMenu] = useState<string | null>(null);
    const [openSubMenuId, setOpenSubMenuId] = useState<number | null>(null);
    const [selectedMenuOptionId, setSelectedMenuOptionId] = useState<string | null>(null);
    const [selectedSubMenuOption, setSelectedSubMenuOption] = useState<string | null>(null);
    const handleMenuToggle = (menuId: string) => {
        setOpenMenuId((prevId) => (prevId === menuId ? null : menuId));
        setOpenSubMenuId(null);
        setSelectedMenuOptionId(null);
    };

    useEffect(() => {
        if (addOptions && addOptions.id && (addOptions.extend || addOptions.addPay)) {
            const menuId = addOptions.extend ? `e${addOptions.id}` : `e${addOptions.id}`;
            const menu = addOptions.extend ? OPTIONS.EXTENSION : OPTIONS.PAY_TOWARDS_PRINCIPAL;
            setOpenMenu(menu);
            handleOptionSelect(menuId);
        }
    }, [addOptions]);

    const handleSubMenuToggle = (menuId: number, value: string) => {
        setOpenSubMenuId((prevId) => {
            if (prevId === menuId) {
                return null;
            }
            setOpenMenuId(null);
            return menuId;
        });
        setSelectedMenuOptionId(null);
        setSelectedSubMenuOption(value);
    };

    const handleOptionSelect = (menuId: string) => {
        setSelectedMenuOptionId(menuId);
    };

    useEffect(() => {
        if (token && carShoppingId && (!cartData || refreshCart)) {
            dispatch(fetchCartById(token, carShoppingId));
        }
    }, [cartData, refreshCart]);

    return (
        <FlexPanel
            Width="24rem"
            FlexDir="column"
            Align="flex-start"
            Justify="space-between"
            OverFlowY="auto"
            OverFlowX="hidden"
        >
            {cartData &&
                cartData.endorsements &&
                cartData.endorsements.map((endorsement) => (
                    <RenewalItem
                        key={endorsement.contractId}
                        carShoppingId={cartData.carShoppingId}
                        endorsement={endorsement}
                        isOpen={openMenuId === `e${endorsement.contractId}`}
                        onMenuToggle={() => handleMenuToggle(`e${endorsement.contractId}`)}
                        isSubMenuOpen={openSubMenuId === endorsement.contractId}
                        onSubMenuToggle={(value: string) =>
                            handleSubMenuToggle(endorsement.contractId, value)
                        }
                        isSelected={selectedMenuOptionId === `e${endorsement.contractId}`}
                        onSelectOption={() => handleOptionSelect(`e${endorsement.contractId}`)}
                        selectedSubMenuOption={selectedSubMenuOption}
                        openedMenu={openMenu}
                    />
                ))}
            {cartData &&
                cartData.discharges &&
                cartData.discharges.map((discharge) => (
                    <FulfillingItem
                        key={discharge.contractId}
                        carShoppingId={cartData.carShoppingId}
                        discharge={discharge}
                        isOpen={openMenuId === `d${discharge.contractId}`}
                        onMenuToggle={() => handleMenuToggle(`d${discharge.contractId}`)}
                        onSubMenuToggle={(value: string) =>
                            handleSubMenuToggle(discharge.contractId, value)
                        }
                        onSelectOption={() => handleOptionSelect(`d${discharge.contractId}`)}
                    />
                ))}
        </FlexPanel>
    );
};
export default Renewals;
