import {AxiosResponse, AxiosError} from 'axios';
import {
    EMAIL_PAYMENT_MADE_ERROR,
    EMAIL_PAYMENT_MADE_START,
    EMAIL_PAYMENT_MADE_SUCCESS,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {IEmailPaymentMadeReducer} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

const initialState: IEmailPaymentMadeReducer = {
    loading: false,
    error: false,
    was_email_sent: false,
    emailPaymentMadeData: null,
};

const emailPaymentMade = (
    state: IEmailPaymentMadeReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IEmailPaymentMadeReducer => {
    switch (action.type) {
        case EMAIL_PAYMENT_MADE_START:
            return {
                ...state,
                loading: true,
            };
        case EMAIL_PAYMENT_MADE_SUCCESS:
            return {
                ...state,
                was_email_sent: true,
                loading: false,
            };
        case EMAIL_PAYMENT_MADE_ERROR:
            return {
                ...initialState,
                error: true,
            };
        default:
            return state;
    }
};

export default emailPaymentMade;
