import {useSelector} from 'react-redux';
import React from 'react';
import {IPaymentMethodsPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/paymentMethodsInterfaces';
import {HeaderPreview} from '@components/PersonalLoans/NewLoanProduct/ui/HeaderPreview';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {PAYMENT_METHODS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {RootState} from '@/config/store';
import checkDisbursementIcon from '@components/PersonalLoans/NewLoanProduct/icons/checkDisbursementIcon.svg';

export const PaymentMethodsPreview: React.FC<IPaymentMethodsPreviewProps> = ({
    paymentMethodRef,
    setIsEditing,
    isEditing,
}) => {
    const paymentMethodsData = useSelector(
        (state: RootState) => state.newLoanSubmittedData.details.typePaymentsIns
    );
    const paymentSelectedData = paymentMethodsData.filter((item) => item.isChecked);
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={paymentMethodRef}
            >
                <HeaderPreview
                    title={PAYMENT_METHODS.TITLE}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
                {paymentSelectedData.map((item, index) => (
                    <ContainerFlex
                        Gap="0.5rem"
                        Align="start"
                        Justify="start"
                        Height="max-content"
                        key={index}
                    >
                        <ContainerFlex Padding="0.25rem" Width="max-content" Height="max-content">
                            <Image
                                src={checkDisbursementIcon}
                                Width="16px"
                                Height="16px"
                                alt="check-icon"
                            />
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="0.25rem">
                            <Text Color="#2A2C2F">{item.typePaymentName}</Text>
                            <Text Color="#54575C" FontSize="0.875rem">
                                {item.typePaymentDescription}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
        </>
    );
};
