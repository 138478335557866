import ButtonGeneral from '@/components/General/Atoms/Button';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {SelectLada} from '@/components/Quoter/CoownerBeneficiary/SelectLada';
import {ContainerFlex, Text, Input, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import React, {useState} from 'react';
import {Controller, SubmitHandler, useForm} from 'react-hook-form';
import {
    CREATE_PROVIDER,
    MAX_ACCOUNT_LENGTH,
    MAX_PHONE_LENGTH,
    MAX_PROVIDER_LENGTH,
    MAX_RFC_LENGTH,
} from '@/components/CashFlow/CreateProvider/constants';
import {ICreateProvider} from '@/components/CashFlow/CreateProvider/interfaces';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from '@/components/CashFlow/CreateProvider/validationYup';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {PostCreateProviderAction} from '@components/CashFlow/Expenses/Redux/Actions/PostCreateProviderAction';
import {getBranchProvidersAction} from '@components/CashFlow/Redux/Actions/GetBranchProviders';

interface CreateProviderProps {
    onClose: () => void;
}

const CreateProvider: React.FC<CreateProviderProps> = ({onClose}) => {
    const dispatch = useDispatch();
    const options = [
        {value: 1, label: 'Fundicion'},
        {value: 2, label: 'Servicios'},
    ];

    const [nationSelected, setNationSelected] = useState<IGetAllNations | null>({
        countryId: 151,
        lada: '+52',
        name: 'México',
        urlFlag:
            'https://storage.googleapis.com/goldenstarweb/media/Assests/Banderas/America/Mexico.svg',
    });

    const branchIdUser = useSelector(
        (state: RootState) => state.getUsersValidation?.userData?.branchDetails?.[0]?.branchId
    );

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    const {
        control,
        formState: {errors},
        handleSubmit,
    } = useForm<ICreateProvider>({
        resolver: yupResolver(schema),
        defaultValues: {
            lada: nationSelected ? nationSelected.lada : 'mexicano',
            flag: nationSelected
                ? nationSelected.urlFlag
                : 'https://storage.googleapis.com/goldenstarweb/media/Assests/Pais/Bandera/mexicano.jpg',
        },
    });

    const onSubmit: SubmitHandler<ICreateProvider> = (data) => {
        if (branchIdUser) {
            const submitData = {
                branchId: branchIdUser,
                expenseProviderDetails: {
                    name: data.providerName,
                    rfc: String(data.rfc),
                    accountNumber: String(data.accountNumber),
                    phoneNumber: String(data.phone),
                    email: data.email,
                    economicActivityId: Number(data.activity.value),
                    expenseTypeId: Number(data.type.value),
                    countryId: nationSelected?.countryId,
                },
            };
            dispatch(PostCreateProviderAction(submitData, token, onClose));
            dispatch(getBranchProvidersAction(branchIdUser, token));
        }
    };

    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            Position="absolute"
            Justify="end"
            Align="start"
            ZIndex="5"
            Width="100%"
            Height="100%"
            BackG="none"
            Margin="0 0 42px 0"
        >
            <ContainerFlex
                FlexDir="column"
                Align="start"
                Height="auto"
                Width="25vw"
                Padding="20px"
                Gap="8px"
                Radius="20px 0 0 20px"
                BoxS="rgba(0, 0, 0, 0.35) 0px 5px 15px;"
                backG="white"
            >
                <Text FontSize="1.3rem" FontWeight="700" Padding="8px 0">
                    {CREATE_PROVIDER.newProvider}
                </Text>
                <Text FontSize="1.1rem" FontWeight="600" Padding="8px 0">
                    {CREATE_PROVIDER.basicInformation}
                </Text>
                <Text FontWeight="500">{CREATE_PROVIDER.type}</Text>
                <Controller
                    name="type"
                    control={control}
                    render={({field: {value, onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            options={options}
                            placeholder="Selecciona"
                            onChange={(selectedOption) => onChange(selectedOption)}
                            hasError={!!errors?.type?.label?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.type?.label?.message}
                </Text>
                <Text FontSize="1.1rem" FontWeight="600" Padding="8px 0">
                    {CREATE_PROVIDER.name}
                </Text>
                <Controller
                    name="providerName"
                    control={control}
                    render={({field}) => (
                        <Input
                            {...field}
                            error={!!errors?.providerName?.message}
                            maxLength={MAX_PROVIDER_LENGTH}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.providerName?.message}
                </Text>
                <Text>{CREATE_PROVIDER.fiscalInformation}</Text>
                <Controller
                    name="activity"
                    control={control}
                    render={({field: {value, onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            onChange={(selectedOption) => onChange(selectedOption)}
                            options={options}
                            hasError={!!errors.activity?.label?.message}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.activity?.label?.message}
                </Text>
                <Text>{CREATE_PROVIDER.rfc}</Text>
                <Controller
                    name="rfc"
                    control={control}
                    render={({field}) => (
                        <Input
                            {...field}
                            error={!!errors.rfc?.message}
                            maxLength={MAX_RFC_LENGTH}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.rfc?.message}
                </Text>
                <Text>
                    {CREATE_PROVIDER.accountNumber}
                    <Text Color="#A1A5AA">{CREATE_PROVIDER.optional}</Text>
                </Text>
                <Controller
                    name="accountNumber"
                    control={control}
                    render={({field}) => (
                        <Input
                            {...field}
                            type="number"
                            error={!!errors.accountNumber?.message}
                            onInput={(e) => {
                                const input = e.target as HTMLInputElement;
                                let value = input.value.replace(/\D/g, '');
                                if (value.length > MAX_ACCOUNT_LENGTH) {
                                    value = value.slice(0, MAX_ACCOUNT_LENGTH);
                                }
                                input.value = value;
                                field.onChange(value);
                            }}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.accountNumber?.message}
                </Text>
                <Text FontSize="1.1rem" FontWeight="600" Padding="8px 0">
                    {CREATE_PROVIDER.contact}
                </Text>
                <Text>{CREATE_PROVIDER.Phone}</Text>
                <ContainerFlex>
                    <Controller
                        name="lada"
                        control={control}
                        render={({field}) => (
                            <SelectLada handleNationSelected={setNationSelected} {...field} />
                        )}
                    />
                    <Controller
                        name="phone"
                        control={control}
                        render={({field}) => (
                            <Input
                                {...field}
                                type="tel"
                                maxLength={MAX_PHONE_LENGTH}
                                error={!!errors.phone?.message}
                                onInput={(e) => {
                                    const input = e.target as HTMLInputElement;
                                    input.value = input.value.replace(/\D/g, '');
                                    field.onChange(e);
                                }}
                            />
                        )}
                    />
                </ContainerFlex>
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.phone?.message}
                </Text>
                <Text>{CREATE_PROVIDER.email}</Text>
                <Controller
                    name="email"
                    control={control}
                    render={({field}) => <Input {...field} error={!!errors.email?.message} />}
                />
                <Text Color="#FF6357" FontSize="12px">
                    {errors?.email?.message}
                </Text>
                <ContainerFlex Justify="space-between">
                    <ButtonGeneral
                        text="Cancelar"
                        width="45%"
                        clic={onClose}
                        backGround="white"
                        color="#5A5AFF"
                    />
                    <ButtonGeneral text="Guardar" width="45%" Type="submit" />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};

export default CreateProvider;
