export const CardSelectionProps = {
    Radius: '1rem',
    BoxS: '0px 10px 60px 0px rgba(0, 0, 0, 0.25)',
    Height: '250px',
    Padding: '16px',
    FlexDir: 'column',
    Width: '18.956rem',
    Gap: '1rem',
};

export const ButtonContainer = {
    backG: '#5A5AFF',
    Height: '40px',
    Radius: '32px',
    Cursor: 'pointer',
};

export const WhiteText = {
    FontSize: '1rem',
    Color: '#FFFFFF',
    FontFamily: 'Nunito',
    wSpace: '',
    TextAlign: 'center',
    FontWeight: '700',
    Cursor: 'pointer',
};

export const TITLES = {
    PLAT_FORM_SELECTION: 'Selección de plataforma',
    WICH_PLAT: '¿A Cuál plataforma deseas ingresar?',
    ADMIN: 'Administrativa',
    INTEGER: 'Gestión Integral: Empresa, Usuarios y Herramientas',
    LOGIN: 'Ingresar',
    OPERATIVE: 'Operativa',
    CONTROL: 'Control de Sucursales: Punto de Venta y Operaciones',
    TERMS: 'Términos y condiciones de uso y el Aviso de privacidad de Multiapoyo.',
};
