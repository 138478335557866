import ReactSelect from 'react-select';
import styled from '@emotion/styled';
import Trash from '@images/trash.svg';
import pinDropHover from '@images/pinDropHover.svg';
import locationHover from '@images/locationHover.svg';
import {
    IPropsFileUpLoad,
    IPropsFileUpLoadContent,
    IPropsAddImgs,
    IPropsImgsContent,
    IPropsImgs,
    IPropsIconImg,
    IPropsIconContainer,
    IPropsIcons,
    IPropsImageSVG,
    IPropsFigureContainer,
    IPropsStyleReactSelect,
    IPropsFileInputContainer,
} from '@Steps/interfacesStyles';

export const FileUpLoad = styled.input<IPropsFileUpLoad>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    opacity: ${(props) => props.Opacity};
    overflow: ${(props) => props.Overflow};
    position: ${(props) => props.Position};
    z-index: ${(props) => props.ZIndex};
    cursor: ${(props) => props.Cursor};
    border: ${(props) => (props.error ? '1px solid #ef4f55' : '1px solid #E8E9EA')};

    ${(props) =>
        props.disabled
            ? `&:disabled{
            background-color: #f3f3f3
        }`
            : `&:focus {
                border-color: ${props.error ? '#ef4f55' : '#5A5AFF'};
        }
        &:hover {
            border-color: ${props.error ? '#ef4f55' : '#5A5AFF'};
        }`}
`;

FileUpLoad.defaultProps = {
    Width: '0.1px',
    Height: '0.1px',
    Opacity: '0',
    Overflow: 'hidden',
    Position: 'absolute',
    Cursor: 'Pointer',
};

export const FileUpLoadContent = styled.label<IPropsFileUpLoadContent>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border-radius: ${(props) => props.RABorder};
    display: ${(props) => props.Display};
    flex-direction: ${(props) => props.FDirection};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    background-color: ${(props) => props.BackG};
    cursor: ${(props) => props.Cursor};
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    padding: ${(props) => props.Padding};
`;

FileUpLoadContent.defaultProps = {
    Width: '117px',
    Height: '73px',
    RABorder: '4px',
    Display: 'flex',
    Justify: 'center',
    Align: 'center',
    BackG: '#E5E7E9',
    Cursor: 'pointer',
    Margin: '0',
};

export const AddImgs = styled.div<IPropsAddImgs>`
    justify-content: ${(props) => props.Justify};
    z-index: ${(props) => props.ZIndex};
    position: ${(props) => props.Position};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.BRadius};
    height: ${(props) => props.Height};
    opacity: ${(props) => props.Opacity};

    &:hover {
        img:first-child {
            opacity: ${(props) => (props.HOpacity === '1' ? '0.3' : '9')};
        }

        img:nth-child(2) {
            opacity: ${(props) => (props.HOpacity === '1' ? '0.3' : '9')};
        }
    }
`;

AddImgs.defaultProps = {
    Justify: 'center',
    ZIndex: '0',
    Position: 'relative',
    Width: '117px',
    Height: '73px',
};

export const ImgsContent = styled.div<IPropsImgsContent>`
    display: flex;
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    flex-direction: ${(props) => props.FlexDir};
    box-sizing: border-box;
    align-items: ${(props) => props.Align};
    flex-wrap: ${(props) => props.FlexWrap};
    margin: ${(props) => props.Margin};
    margin-bottom: ${(props) => props.MarginB};
    gap: 24px;

    div:first-child {
        span {
            background-color: rgba(229, 231, 233, 0.8);
            text-align: center;
            top: 80%;
            left: 0;
            z-index: 3;
            position: absolute;
            width: 73px;
            color: #6d6e71;
            font-size: 14px;
            font-weight: 500;
            border: 1.2013px solid #e5e7e9;
            border-radius: 0px 0px 4px 4px;
        }
    }

    div:last-child {
        span {
            text-align: center;
            top: 0;
            padding: 0.9rem 0;
            left: 0;
            z-index: 3;
            position: absolute;
            width: 90px;
            color: #6d6e71;
            font-size: 1.2rem;
            font-weight: 400;
            background: linear-gradient(0deg, rgba(243, 243, 243, 0.8), rgba(243, 243, 243, 0.8));
        }
    }
`;

ImgsContent.defaultProps = {
    Align: 'flex-start',
    FlexWrap: 'wrap',
};

export const Imgs = styled.img<IPropsImgs>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    object-fit: ${(props) => props.ObjectF};
    margin: ${(props) => props.Margin};
`;

Imgs.defaultProps = {
    Width: '100%',
    Height: '100%',
    ObjectF: 'contain',
};

export const IconImg = styled.img<IPropsIconImg>`
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    position: ${(props) => props.Position};
    top: ${(props) => props.Top};
    left: ${(props) => props.Left};
    z-index: ${(props) => props.ZIndex};
    padding: ${(props) => props.Padding};
    opacity: ${(props) => props.Opacity};
    cursor: ${(props) => props.Cursor};
    &:hover {
        content: url(${Trash});
        background-color: ${(props) => props.HBColor};
    }
`;

IconImg.defaultProps = {
    FontSize: '2rem',
    Position: 'absolute',
    Top: '40%',
    Left: '40%',
    Opacity: '0',
    Cursor: 'pointer',
};

export const ImgsDrop = styled.img`
    &:hover {
        content: url(${pinDropHover});
    }
`;

export const ImgsLocation = styled.img`
    &:hover {
        content: url(${locationHover});
    }
`;
export const IconContainer = styled.div<IPropsIconContainer>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    padding: ${(props) => props.Padding};
    transform: ${(props) => props.Transform};
    border-radius: ${(props) => props.Radius};
    background-color: ${(props) => props.backG};
    cursor: ${(props) => props.Cursor};
    position: ${(props) => props.Position};
    top: ${(props) => props.PositionTop};
    bottom: ${(props) => props.PositionBottom};
    z-index: ${(props) => props.ZIndex};
    position: ${(props) => props.Position};

    &:hover span {
        visibility: visible;
    }
    &:hover svg {
        color: ${(props) => props.HBColor};
    }
`;
IconContainer.defaultProps = {
    Color: ' ',
    FontSize: ' ',
    Padding: '0',
    Width: '22px',
    Height: '22px',
};
export const ArchiveInputContainer = styled.div<IPropsFileInputContainer>`
    display: flex;
    justify-content: ${(props) => props.Justify};
    color: ${(props) => props.Align};
    cursor: pointer;
    box-sizing: border-box;
    gap: 24px;
    margin: 0;
    border-radius: 16px;
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border: ${(props) => (props.error ? ' 1px dashed #FF6357' : ' 1px dashed #5a5aff')};
    span {
        cursor: pointer;
    }
`;
ArchiveInputContainer.defaultProps = {
    Width: '100%',
    Height: '100%',
};

export const Icons = styled.i<IPropsIcons>`
    font-size: ${(props) => props.FontSize};
    color: ${(props) => props.Color};
    font-weight: ${(props) => props.FontWeight};
    cursor: ${(props) => props.Cursor};
    margin-left: ${(props) => props.MarginLeft};
    margin-right: ${(props) => props.MarginRight};
    margin-bottom: ${(props) => props.MarginBottom};
    margin-top: ${(props) => props.MarginTop};
    margin: ${(props) => props.Margin};
    opacity: ${(props) => props.Opacity};
    display: ${(props) => props.Display && props.Display};
    justify-content: ${(props) => props.Justify && props.Justify};
    align-items: ${(props) => props.Align && props.Align};
    width: ${(props) => props.Width};

    &:hover {
        color: ${(props) => props.HColor};
    }
`;

export const ImageSVG = styled.img<IPropsImageSVG>`
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    border-radius: ${(props) => props.Radius};
    margin: ${(props) => props.Margin};
`;

ImageSVG.defaultProps = {
    Width: '100%',
    Height: '100%',
};
export const FigureContainer = styled.figure<IPropsFigureContainer>`
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    margin: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    background-color: ${(props) => props.backG};
    border-radius: ${(props) => props.Radius};
    cursor: ${(props) => props.Cursor};
    position: ${(props) => props.Position};
    box-shadow: ${(props) => props.BoxS};
`;

export const Select = styled(ReactSelect)<IPropsStyleReactSelect>`
    text-align: ${(props) => props.textAlign};
    border: ${(props) => props.border};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    font-size: ${(props) => props.fontSize};
    outline: ${(props) => props.outline};
    margin: ${(props) => props.margin};
    padding: ${(props) => props.padding};
    border-radius: ${(props) => props.radius};
    border-color: ${(props) => (props.hasError ? 'red' : '#E8E9EA')};
    background: ${(props) => (props.isDisabled ? '#F4F5F5' : 'white')};
    border-left: ${(props) => props.BorderLeft};

    border-right: ${(props) => props.BorderRight};
    &::placeholder {
        color: ${(props) => props.colorplace};
    }

    &:hover {
        border: ${(props) => props.hborder};
        box-shadow: ${(props) => props.hshadow};
    }
    &:focus {
        border: ${(props) => props.borderf};
        box-shadow: ${(props) => props.boxshadowf};
        outline: ${(props) => props.outlinef};
    }

    input {
        margin: 0;
        padding: 0;
    }

    [class$='-control'] {
        border: none;
        padding: 0;
        margin: 0;
        border-color: unset;
        border-radius: 0;
        background-color: unset;
        height: 40px;
        box-shadow: none;
        padding: 0px 16px;
        top: ${(props) => props.controlTop};
        bottom: ${(props) => props.ControlBottom};
    }

    .css-hlgwow {
        padding: ${(props) => props.PaddingSelection};
    }
    .css-894a34-indicatorSeparator {
        width: 0;
    }
    .css-qbdosj-Input {
        padding: 0;
        margin: 0;
    }

    .css-1fdsijx-ValueContainer {
        border: none;
        margin: 0;
        border-color: none;
        border-radius: 0;
        background-color: unset;
        height: 40px;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        align-content: center;
        padding: 0;
    }
    .css-1hb7zxy-IndicatorsContainer {
        position: relative;
        padding: 0;
    }
    .css-894a34-indicatorSeparator {
        width: 0;
    }
    .css-1u9des2-indicatorSeparator {
        display: none;
        width: 0;
    }
    .css-1jqq78o-placeholder {
        border: none;
        padding: 0;
        margin: 0;
        color: #a1a5aa;
    }
    .css-1xc3v61-indicatorContainer,
    .css-15lsz6c-indicatorContainer {
        padding: 0;
    }
    .css-1dimb5e-singleValue {
        margin: 0;
    }
    .css-1nmdiq5-menu {
        margin: 1px 0;
        box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
        padding: 0;
        border: 1px solid #f3f3f3;
        max-height: 200px;
        border-radius: 0.5rem;
        background-color: #fff;
        justify-content: start;
    }

    .css-1nmdiq5-menu div {
        overflow-y: auto;
        max-height: 200px;
        font-weight: 500;
        margin: 0;
        padding: 0;
        color: #2a2c2f;
        border-radius: 0.5rem;
        background-color: #fff;
        width: auto;

        ::-webkit-scrollbar-track {
            background-color: #a7a9ac00;
        }
        ::-webkit-scrollbar-thumb {
            border-radius: 0.15rem 0.15rem;
            background-color: #cbced1;
        }
        ::-webkit-scrollbar {
            width: 5px;
        }
    }

    .css-1nmdiq5-menu div div {
        height: ${(props) => props.HeigthOptions};
        cursor: pointer;
        padding: 0.75rem 0.5rem;
        overflow: hidden;
        border-bottom: ${(props) => props.BorderBOptions ?? '1px solid #e4e7e9'};
        border-radius: 0;
        width: auto;

        &:hover {
            background-color: #f0f0ff;
            border-left: 2px solid #5a5aff;
        }
    }

    .css-1nmdiq5-menu div div:last-child {
        border-bottom: none;
        width: auto;
    }

    svg {
        color: #0d166b;
    }

    .css-1p3m7a8-multiValue {
        border-radius: ${(props) => props.MultiRadius};
    }

    .css-1p3m7a8-multiValue .css-v7duua:hover {
        background-color: ${(props) => props.MultiIconHover};
    }
`;

Select.defaultProps = {
    height: '40px',
    hborder: '1px solid #5A5AFF',
    fontSize: '1rem',
    outline: 'none',
    border: '1px solid #E8E9EA',
    radius: '2rem',
    width: '100%',
    controlTop: '-1px',
    HeigthOptions: '40px',
};
