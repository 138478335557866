import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import arrowSmall from '@/images/arrow small.svg';
import {CARD_DATA, CREDIT_TYPES} from '@/components/MyAccount/constants';
import {IDataCardProps} from '@/components/MyAccount/interfaces';
import StatusInfo from '@/components/MyAccount/MyCredit/StatusInfo';
import CreditQuotesOrDate from '@/components/MyAccount/MyCredit/CreditQuotesOrDate';
import {DivisorLine} from '@/components/MyAccount/styles';
import {useFormatCurrency} from '@/components/CashFlowActions/FormatCurrency';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';
import {
    CREDIT_STATUS_MAP,
    CREDIT_STATUS_DEFAULT,
} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {addCreditToShoppingCarAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';

const DataCard = ({type, data}: IDataCardProps) => {
    const formatCurrency = useFormatCurrency();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {token, carShoopingId} = useSelector((state: RootState) => {
        const userData = state.getUsersValidation.userData;
        return {
            token: String(userData?.token),
            carShoopingId: Number(userData?.carShoopingId),
        };
    });

    const detailBtn =
        type === CREDIT_TYPES.PERSONAL ? CARD_DATA.CREDIT.DETAIL_BTN : CARD_DATA.PAWNS.DETAIL_BTN;
    const rightSideBtn =
        type === CREDIT_TYPES.PERSONAL
            ? CARD_DATA.CREDIT.RIGHT_ACTION_BTN
            : CARD_DATA.PAWNS.RIGHT_ACTION_BTN;
    const businessName = data?.companyName || CARD_DATA.SHARE.BUSINESS_NAME;

    const isStatusUptoDateOrOverdue =
        data?.statusId === CARD_DATA.CREDIT.ONTIME || data?.statusId === CARD_DATA.CREDIT.OVERDUE;
    const isStatusAccepted = data?.statusId === CARD_DATA.CREDIT.APPROVED;

    const amount =
        data?.statusId === CARD_DATA.CREDIT.REJECTED ||
        data?.statusId === CARD_DATA.CREDIT.APPLICATION
            ? data?.amountRequired
            : data?.amountApproved;

    const handleAddShoppingCart = () => {
        const personalCreditId = Number(data?.solicitudCreditoPersonalId);
        if (token && carShoopingId && personalCreditId) {
            dispatch(addCreditToShoppingCarAction(personalCreditId, token, String(carShoopingId)));
        }
    };

    const handleDetailClick = () => {
        const creditStatusMap = CREDIT_STATUS_MAP.get(
            Number(data?.statusId) ?? CREDIT_STATUS_DEFAULT
        );
        const creditId = data?.solicitudCreditoPersonalId;
        navigate(
            String(creditStatusMap?.creditUrlNavigation).replace(':creditId', String(creditId))
        );
    };
    const handleAcceptClick = () => {
        const creditId = data?.solicitudCreditoPersonalId;
        if (creditId !== undefined) {
            navigate(routers.PersonalLoansApproved.replace(':creditId', String(creditId)), {
                state: {personalCreditId: creditId},
            });
        }
    };

    return (
        <>
            <ContainerFlex
                FlexDir="column"
                Gap="16px"
                Justify="flex-start"
                Align="flex-start"
                backG="#FFFFFF"
                Radius="16px"
                Padding="16px"
                Border="1px solid #E8E9EA"
            >
                <ContainerFlex FlexDir="column" Gap="4px" Justify="flex-start" Align="flex-start">
                    <ContainerFlex Justify="space-between">
                        <Text oFlow="normal" Color="#2A2C2F">
                            {CARD_DATA.SHARE.TITLE}
                        </Text>
                        <StatusInfo statusId={data?.statusId} statusLabel={data?.creditStatus} />
                    </ContainerFlex>
                    <Text oFlow="normal" Color="#5A5AFF" FontWeight="700">
                        {businessName}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Justify="space-between">
                    <ContainerFlex
                        Justify="flex-start"
                        Align="flex-start"
                        FlexDir="column"
                        Gap="4px"
                        Width="fit-content"
                    >
                        <Text oFlow="normal" Color="#2A2C2F">
                            {CARD_DATA.CREDIT.TITLE}
                        </Text>
                        <Text oFlow="normal" Color="#2A2C2F" FontWeight="700">
                            {amount && formatCurrency(amount.toString())}
                        </Text>
                    </ContainerFlex>
                    <CreditQuotesOrDate
                        statusId={data?.statusId || CARD_DATA.CREDIT.APPLICATION}
                        date={data?.applicationDate}
                        paidQuotas={data?.totalQuotasPaid}
                        totalQuotas={data?.totalQuotas}
                    />
                </ContainerFlex>
                <ContainerFlex
                    Justify={isStatusUptoDateOrOverdue ? 'space-between' : 'center'}
                    Gap="16px"
                >
                    {isStatusUptoDateOrOverdue && (
                        <>
                            <ButtonGenerals
                                Width="164px"
                                BackGC="#FFFFFF"
                                Border="1px solid #5A5AFF"
                                Color="#5A5AFF"
                                Padding="4px 0"
                                HBackG="#E8E9EA"
                                FontSize="0.875rem"
                                FontWeight="700"
                                Height="32px"
                                onClick={handleAddShoppingCart}
                            >
                                {CARD_DATA.SHARE.LEFT_ACTION_BTN}
                            </ButtonGenerals>
                            <ButtonGenerals
                                Width="164px"
                                BackGC="#5A5AFF"
                                Color="#FFFFFF"
                                Padding="4px 0"
                                FontSize="0.875rem"
                                FontWeight="700"
                                Height="32px"
                                onClick={() => navigate(routers.CreditsCartShopping)}
                                Cursor="pointer"
                            >
                                {rightSideBtn}
                            </ButtonGenerals>
                        </>
                    )}
                    {isStatusAccepted && (
                        <ButtonGenerals
                            Width="100%"
                            BackGC="#5A5AFF"
                            Color="#FFFFFF"
                            Padding="4px 0"
                            FontSize="0.875rem"
                            FontWeight="700"
                            Height="32px"
                            onClick={handleAcceptClick}
                            Cursor="pointer"
                        >
                            {CARD_DATA.CREDIT.ACCEPT}
                        </ButtonGenerals>
                    )}
                </ContainerFlex>

                <DivisorLine width="100%" height="1px" />
                <ButtonGenerals
                    Width="100%"
                    BackGC="#FFFFFF"
                    Height="24px"
                    Padding="0"
                    HBackG="#FFFFFF"
                    onClick={handleDetailClick}
                    Cursor="pointer"
                >
                    <Text Color="#5A5AFF" FontSize="0.875rem" FontWeight="700" Cursor="pointer">
                        {detailBtn}
                    </Text>
                    <img src={arrowSmall} alt="next icon arrow" />
                </ButtonGenerals>
            </ContainerFlex>
        </>
    );
};

export default DataCard;
