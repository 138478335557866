import styled from '@emotion/styled';
import {IPropsSearchInput} from '@/components/Customer/interfacesStyles';

export const SearchInputCherry = styled.div<IPropsSearchInput>`
    background: #ffffff 0% 0% no-repeat padding-box;
    background-color: ${(props) => props.BackG};
    justify-content: ${(props) => props.Justify};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    position: ${(props) => props.Position};
    margin: ${(props) => props.Margin};
    margin-top: ${(props) => props.MarginTop};
    right: ${(props) => props.Right};
    top: 0;
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    display: ${(props) => (props.Display ? props.Display : 'grid')};
    grid-template-columns: ${(props) => props.GridColumn};
    flex: ${(props) => props.Flex};
    align-items: center;
    padding: ${(props) => props.Padding};
    min-height: ${(props) => props.MinH};
    cursor: ${(props) => props.Cursor};
    border-color: ${(props) => (props.error ? '#A82424' : '#E8E9EA')};
    box-sizing: border-box;
    overflow: ${(props) => props.OverFlow};
    & > input {
        border: none;
        outline: none;
        font-size: ${(props) => props.FontSize};
        color: #050505;
        line-height: 16px;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: ${(props) => (props.InputPadding ? props.InputPadding : '0')};
        width: ${(props) => props.widthInput};
        ::placeholder {
            color: ${(props) => props.PlaceholderColor};
            line-height: ${(props) => props.PlaceholderLHeight};
            font-size: ${(props) => props.PlaceholderFontSize};
            font-weight: ${(props) => props.PlaceholderWeight};
        }
        :disabled {
            background: #ffffff;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus {
            -webkit-box-shadow: ${(props) => props.WBoxShadow};
        }
    }
    &:hover {
        border-color: ${(props) => (props.error ? '#A82424' : '#5A5AFF')};
        box-shadow: ${(props) => props.BoxShadowH};
    }
    &:focus-within {
        border-color: ${(props) => (props.error ? '#A82424' : '#5A5AFF')};
        box-shadow: ${(props) => props.BoxShadowF};
    }

    figure > svg > path {
        fill: ${(props) => (props.error ? '#A82424' : '#5A5AFF')};
    }
`;
SearchInputCherry.defaultProps = {
    Height: '40px',
    Position: 'relative',
    GridColumn: '85% 15%',
    Width: '100%',
    Padding: '0 1rem',
    PlaceholderColor: '#A7A9AC',
    PlaceholderLHeight: '1rem',
    PlaceholderFontSize: '0.875rem',
    PlaceholderWeight: '400',
    BorderH: '1px solid #5A5AFF',
    BorderF: '1px solid #5A5AFF',
    Border: '1px solid #E8E9EA',
    Right: '1rem',
    MinH: '40px',
    Radius: '32px',
    WBoxShadow: '0 0 0px 1000px #fff inset',
    FontSize: '1rem',
    widthInput: 'inherit',
};
