import React, {useEffect, useState} from 'react';
import {
    TableItemsContainer,
    TableRowHead,
    TableRowItems,
} from '@/components/CashFlow/FundingInquiry/styles';
import {InputCheckbox} from '@/components/Loan/styles';
import {
    ContainerFlex,
    Image,
    Input,
    Text,
    TextEllipsis,
} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import SearchImg from '@/images/search.svg';
import {SearchInput} from '@Customer/Styles';
import {
    ITEM_PER_PAGE,
    MIN_SEARCH_LENGTH,
    userList,
} from '@components/RolesPermissions/NewRole/constants';
import {GetRoleUsersAction} from '@components/RolesPermissions/Redux/Actions/GetRoleUsersAction';
import {IRoleUsers} from '@components/RolesPermissions/interfaces';
import {useDispatch, useSelector} from 'react-redux';
import {UserListProps} from '@components/RolesPermissions/NewRole/interfaces';

const UserList: React.FC<UserListProps> = ({
    pageNumber,
    isEdit,
    listData,
    setListData,
    selectedRole,
}) => {
    const dispatch = useDispatch();
    const userData = useSelector((state: RootState) => state.GetRoleUsers.users);
    const [users, setUsers] = useState<IRoleUsers[]>(userData.userByCompany || []);
    const [searchUser, setSearchUser] = useState<string>('');

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );
    const debounceSearch = (e: string) => {
        setTimeout(() => {
            if (e.length >= MIN_SEARCH_LENGTH) {
                setSearchUser(e);
            } else {
                setSearchUser('');
            }
        }, 500);
    };

    const handleUserCheck = (item: IRoleUsers) => {
        setUsers((prevUser) =>
            prevUser.map((user) =>
                user.userId === item.userId ? {...user, isChecked: !user.isChecked} : user
            )
        );
        setListData((prev) => ({
            ...prev,
            users: prev.users.includes(item.userId)
                ? prev.users.filter((id) => id !== item.userId)
                : [...prev.users, item.userId],
        }));
    };

    useEffect(() => {
        if (companyId) {
            const data = {
                companyId: companyId,
                searchName: searchUser,
                pageSize: ITEM_PER_PAGE,
                pageNumber: pageNumber,
            };
            dispatch(GetRoleUsersAction(data, token));
        }
    }, [searchUser, pageNumber, companyId, dispatch, token]);

    useEffect(() => {
        setUsers(userData.data);
    }, [userData, pageNumber, selectedRole]);

    useEffect(() => {
        if (isEdit) {
            setListData((prev) => ({...prev, users: selectedRole.users}));
        }
    }, [selectedRole]);

    return (
        <ContainerFlex
            FlexDir="column"
            Justify="start"
            Align="start"
            Border="1px solid #E8E9EA"
            Radius="16px"
        >
            <ContainerFlex
                Gap="15px"
                backG="#FAFAFA"
                Bb="1px solid #E8E9EA"
                Height="4rem"
                MinH="4rem"
            >
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="98% 2%"
                    Margin="0 0 4px 0"
                    Padding="0 1rem 0 0"
                    BackG="#FFFFFF"
                    Radius="32px"
                    InputPadding="0 1rem"
                    Width="97%"
                >
                    <Input
                        type="text"
                        placeholder={userList.placeHolder}
                        onChange={(e) => debounceSearch(e.target.value)}
                    />
                    <Image src={SearchImg} Cursor="pointer" alt="search" />
                </SearchInput>
            </ContainerFlex>
            <TableRowHead
                BackG="none"
                GridColumn=".5fr 1fr 1fr 1fr"
                Height="40px"
                Gap="8px"
                Width="100%"
                Radius="0px"
                Margin="15px 0px 15px 0"
            >
                <Text FontWeight="700" MarginLeft="20px">
                    {userList.assign}
                </Text>
                <Text FontWeight="700">{userList.name} </Text>
                <Text FontWeight="700">{userList.role} </Text>
                <Text FontWeight="700">{userList.lastActive} </Text>
            </TableRowHead>

            <TableItemsContainer Background="none" Width="100%">
                {users?.map((i: IRoleUsers) => {
                    const userRoleText = i.roles.map((role) => role.roleName).join(', ');

                    return (
                        <TableRowItems
                            GridColumn=".5fr 1fr 1fr 1fr"
                            key={i.userId}
                            Border="none"
                            Width="100%"
                            BorderTop="1px solid #E8E9EA"
                            Height="4rem"
                        >
                            <ContainerFlex Justify="start" MarginL="30px">
                                <InputCheckbox
                                    type="checkbox"
                                    checked={listData.users.includes(i.userId)}
                                    BackG="white"
                                    Border="1px solid #5A5AFF"
                                    onChange={() => handleUserCheck(i)}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Gap="10px">
                                <Image
                                    src={i.image}
                                    Cursor="pointer"
                                    Height="40px"
                                    Width="40px"
                                    Radius="50%"
                                    alt="profilePicture"
                                />
                                <Text>{i.name}</Text>
                            </ContainerFlex>
                            <TextEllipsis FontWeight="4000" Width="90%" MarginTop="23px">
                                {userRoleText}
                            </TextEllipsis>
                            <Text>{i.lastActive}</Text>
                        </TableRowItems>
                    );
                })}
            </TableItemsContainer>
        </ContainerFlex>
    );
};

export default UserList;
