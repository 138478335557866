import {
    GET_ROL_COMPANY_REQUEST,
    GET_ROL_COMPANY_SOUCCES,
    GET_ROL_COMPANY_RROR,
} from '@components/Users/UserProfile/Redux/types';

const initialState = {
    loading: false,
    userData: [],
    message: '',
    error: false,
};

export const getRoleCompany = (state = initialState, action) => {
    switch (action.type) {
        case GET_ROL_COMPANY_REQUEST:
            return {...state, loading: true};
        case GET_ROL_COMPANY_SOUCCES:
            return {...state, loading: false, userData: action.payload.data};
        case GET_ROL_COMPANY_RROR:
            return {...state, loading: false, error: action.payload};
        default:
            return state;
    }
};
