export const NewRolesStylesTab = {
    FlexDir: 'column',
    Height: '650px',
    Gap: '16px',
    Radius: '16px',
    Border: '1px solid #E8E9EA',
    Padding: '24px',
};
export const StylesTableRow = {
    GridColumn: '1fr 1fr 76px',
    BorderB: '0.5px solid #e4e7e9',
    Border: '',
    Height: '56px',
    Padding: '4px 16px 4px 16px',
};
export const StylesRowHead = {
    BackG: 'none',
    GridColumn: '1fr 1fr 76px',
    BorderB: '0.5px solid #e4e7e9',
    Padding: '16px',
};
export const StylesTextItems = {
    Cursor: 'pointer',
    FontFamily: 'Nunito',
    FontSize: '0.875rem',
};
export const StylesModal = {
    FlexDir: 'column',
    Width: '100%',
    Height: '45.75rem',
    Radius: '24px',
    backG: '#fff',
    BoxS: '0px 10px 60px 0px rgba(0, 0, 0, 0.25)',
    Padding: '24px',
    Gap: '24px',
};
export const StylesModalContainer = {
    Border: ' 1px solid #E8E9EA',
    Radius: ' 24px 24px 0 0',
    Align: 'start',
    FlexDir: 'column',
    Height: '550px',
};
export const StylesModalContainerTitle = {
    Justify: 'start',
    Height: '56px',
    backG: '#F0F0FF',
    Radius: '24px 24px 0 0',
    Padding: '8px',
};
export const StylesContainerTitle = {
    Justify: 'start',
    Height: '56px',
    backG: '#D0F6FF',
    Radius: '24px 24px 0 0',
    Padding: '8px',
};
