import {IconContainer} from '@/components/Quoter/styles';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {DinamicPadding} from '@/components/AuctionPass/AuctionConstants';
import {idSelected} from '@components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {Text} from '@components/Shopify/Ecommerce/styles';
import {TableItem} from '@DeadLines/styles';
import {TextEllipsis} from '@Shopify/Ecommerce/styles';
import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {ILocationTree, Level} from '@components/NewUserEmployee/interface';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';

export const TreeComponent = (data: ILocationTree) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: Level, index: number) => {
                    return (
                        <TreeNode
                            node={tree}
                            level={data.level + 1}
                            branchesId={data.branchesId}
                            setBranchesId={data.setBranchesId}
                            idLevel={data.idLevel}
                            setIdLevel={data.setIdLevel}
                            key={index}
                        />
                    );
                })}
        </>
    );
};

const TreeNode = ({
    node,
    level,
    branchesId,
    setBranchesId,
    idLevel,
    setIdLevel,
}: {
    node: Level;
    level: number;
    branchesId: number[];
    setBranchesId: React.Dispatch<React.SetStateAction<number[]>>;
    idLevel: number[];
    setIdLevel: React.Dispatch<React.SetStateAction<number[]>>;
}) => {
    const dispatch = useDispatch();

    const toggleBranchId = (id: number) => {
        setBranchesId((prevBranchesId) =>
            prevBranchesId.includes(id)
                ? prevBranchesId.filter((branchId) => branchId !== id)
                : [...prevBranchesId, id]
        );
    };
    const handleCheckboxChange = (node: Level) => {
        if (branchesId.includes(node.idBranch) || idLevel.includes(node.idLevel)) {
            handleDeselectAllDescendants(node);
        } else {
            handleSelectAllDescendants(node);
        }
    };
    const selectAllDescendants = (
        node: Level,
        branchesId: number[],
        idLevel: number[]
    ): [number[], number[]] => {
        if (node.idBranch) {
            branchesId.push(node.idBranch);
        }

        idLevel.push(node.idLevel);

        node.nextLevel.forEach((childNode) => {
            const result = selectAllDescendants(childNode, branchesId, idLevel);
            branchesId = result[0];
            idLevel = result[1];
        });

        node.branches?.forEach((branchNode) => {
            if (branchNode.idBranch) {
                branchesId.push(branchNode.idBranch);
            }
            idLevel.push(branchNode.idLevel);
        });

        return [branchesId, idLevel];
    };
    const handleSelectAllDescendants = (node: Level) => {
        const [updatedBranchesId, updatedIdLevel] = selectAllDescendants(
            node,
            [...branchesId],
            [...idLevel]
        );
        setBranchesId(updatedBranchesId);
        setIdLevel(updatedIdLevel);
    };
    const deselectAllDescendants = (
        node: Level,
        branchesId: number[],
        idLevel: number[]
    ): [number[], number[]] => {
        if (node.idBranch) {
            branchesId = branchesId.filter((id) => id !== node.idBranch);
        }

        idLevel = idLevel.filter((id) => id !== node.idLevel);

        node.nextLevel.forEach((childNode) => {
            const result = deselectAllDescendants(childNode, branchesId, idLevel);
            branchesId = result[0];
            idLevel = result[1];
        });

        node.branches?.forEach((branchNode) => {
            if (branchNode.idBranch) {
                branchesId = branchesId.filter((id) => id !== branchNode.idBranch);
            }
            idLevel = idLevel.filter((id) => id !== branchNode.idLevel);
        });

        return [branchesId, idLevel];
    };
    const handleDeselectAllDescendants = (node: Level) => {
        const [updatedBranchesId, updatedIdLevel] = deselectAllDescendants(
            node,
            [...branchesId],
            [...idLevel]
        );
        setBranchesId(updatedBranchesId);
        setIdLevel(updatedIdLevel);
    };
    const countIdsInLevel = (
        node: Level,
        branchesId: number[],
        idLevel: number[]
    ): {countLevel: number; countBranch: number} => {
        let countLevel = 0;
        let countBranch = 0;

        if (node.nextLevel)
            node.nextLevel.forEach((childNode) => {
                if (idLevel.includes(childNode.idLevel)) {
                    countLevel++;
                }

                if (childNode.idBranch && branchesId.includes(childNode.idBranch)) {
                    countBranch++;
                }
            });

        node.branches?.forEach((branchNode) => {
            if (branchNode.idBranch && branchesId.includes(branchNode.idBranch)) {
                countBranch++;
            }
            if (idLevel.includes(branchNode.idLevel)) {
                countLevel++;
            }
        });

        return {countLevel, countBranch};
    };
    const {countLevel, countBranch} = countIdsInLevel(node, branchesId, idLevel);

    const [nextChild, setNextChild] = useState(false);

    return (
        <>
            <TableRowItems
                GridColumn="3fr .5fr .5fr"
                Cursor="auto"
                Width="100%"
                Padding="0 16px"
                Border=""
                Height="56px"
            >
                <TableItem
                    Justify="start"
                    PaddingLeft={DinamicPadding(level)}
                    Gap="15px"
                    onClick={() => {
                        dispatch(idSelected(node.idLevel));
                        setNextChild(!nextChild);
                    }}
                    CursorTable="pointer"
                    BorderTop="1px solid #E8E9EA"
                >
                    <IconContainer
                        className="material-icons"
                        FontSize="1.5rem"
                        Color={'#5A5AFF'}
                        Justify="start"
                    >
                        {nextChild ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
                    </IconContainer>

                    <TextEllipsis FontWeight="700" FontSize="0.875rem" title={node.levelName}>
                        {node.levelName || node.branchName}
                    </TextEllipsis>
                </TableItem>
                <TableItem Justify="end" BorderTop="1px solid #E8E9EA">
                    <Text FontSize="0.875rem" FontWeight="700">
                        {countBranch + countLevel}/{node.nextLevelCount}
                    </Text>
                </TableItem>
                <TableItem Justify="end" BorderTop="1px solid #E8E9EA">
                    <Checkbox
                        checked={
                            branchesId.includes(node.idBranch) || idLevel.includes(node.idLevel)
                        }
                        onChange={() => {
                            if (node.idBranch) {
                                toggleBranchId(node.idBranch);
                            }
                            if (!node.idBranch) {
                                handleCheckboxChange(node);
                            }
                        }}
                    />
                </TableItem>
            </TableRowItems>

            {nextChild && node.nextLevel && (
                <TreeComponent
                    data={node.nextLevel}
                    level={level}
                    branchesId={branchesId}
                    setBranchesId={setBranchesId}
                    idLevel={idLevel}
                    setIdLevel={setIdLevel}
                />
            )}
            {nextChild && node.branches && (
                <TreeComponent
                    data={node.branches}
                    level={level}
                    branchesId={branchesId}
                    setBranchesId={setBranchesId}
                    idLevel={idLevel}
                    setIdLevel={setIdLevel}
                />
            )}
        </>
    );
};
