import {AxiosResponse} from 'axios';
import {
    GET_ADDITIONAL_DOCUMENTS_START,
    GET_ADDITIONAL_DOCUMENTS_SUCCESS,
    GET_ADDITIONAL_DOCUMENTS_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';

const initialState = {
    error: false,
    loading: false,
    data: [],
};

const getAdditionalCreditDocumentsReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_ADDITIONAL_DOCUMENTS_START:
            return {...state, loading: true};
        case GET_ADDITIONAL_DOCUMENTS_SUCCESS:
            return {...state, loading: false, data: action.payload.data};
        case GET_ADDITIONAL_DOCUMENTS_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default getAdditionalCreditDocumentsReducer;
