import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {useParams} from 'react-router-dom';

import {ContainerGrid} from '@/components/Shopify/Ecommerce/styles';
import PersonalLoanApprovedDeposit from '@/components/PersonalLoans/PersonalLoanApproved/PersonalLoanApprovedDeposit';
import PersonalLoanApprovedSummary from '@/components/PersonalLoans/PersonalLoanApproved/PersonalLoanApprovedSummary';
import getCreditLoanSummaryAction from '@/components/PersonalLoans/Redux/Actions/GetCreditLoanSummaryAction';
import {TransversalLoader} from '@/components/General/Atoms/TransversalLoader/TransversalLoader';

const PersonalLoanApprovedBody = () => {
    const dispatch: AppDispatch = useDispatch();
    const {creditId} = useParams<{creditId?: string}>();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    useEffect(() => {
        if (token && creditId) {
            const creditLoanSummary = {
                token,
                personalCredit: parseInt(creditId, 10),
                termsConditions: true,
            };
            dispatch(getCreditLoanSummaryAction(creditLoanSummary));
        }
    }, [creditId]);
    const {data} = useSelector((state: RootState) => {
        return state.getCreditLoanSummary;
    });
    const addWireTransferLoading = useSelector((state: RootState) => state.addWireTransfer.loading);
    return (
        <ContainerGrid
            GridColumns="1fr 405px"
            Height="100%"
            Width="100%"
            Justify="start"
            Align="start"
            Gap="24px"
        >
            {data && <PersonalLoanApprovedDeposit {...data} />}
            {data && (
                <PersonalLoanApprovedSummary {...data} token={token} creditId={String(creditId)} />
            )}
            {addWireTransferLoading && <TransversalLoader />}
        </ContainerGrid>
    );
};

export default PersonalLoanApprovedBody;
