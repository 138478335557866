import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {RootState} from '@config/store';
import {
    ContainerFlex,
    Image,
    Input,
    Text,
    TextEllipsis,
} from '@components/Shopify/Ecommerce/styles';
import {SearchInput} from '@components/Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import editOrganization from '@components/Branches/Images/EditOrg.svg';
import ButtonGeneral from '@components/General/Atoms/Button';
import {
    ICountryItem,
    IFormCreateBranch,
    IMainForm,
    IUpdateMainInfo,
} from '@components/Branches/interfaces';
import {OrganizationTreeModal} from '@components/Branches/CreateBranchModal/OrganizationTreeModal';
import {MainInfoSelect} from '@components/Branches/ManageBranch/BranchInfo/MainInfoSelect';
import {GetTieredLevel} from '@components/Branches/Redux/Actions/GetTieredLevel';
import {PutMainInfo} from '@components/Branches/ManageBranch/Redux/Actions/PutMainInfo';
import {schemaFormModal} from '@components/Branches/schemaValidations';
import {LoadingGeneralButtons} from '@/components/Electronics/loading-buttons';

export const MainForm = ({showMainForm, setShowMainForm, setShowItems, showItems}: IMainForm) => {
    const dispatch = useDispatch();
    const {idBranch} = useParams();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const tieredLevel = useSelector((state: RootState) => {
        return state.GetTieredLevel.tieredLevel.data;
    });
    const infoBranch = useSelector((state: RootState) => {
        return state.GetInformationBranch?.branchInfo.principalInformation;
    });
    const catCountry = useSelector((state: RootState) => {
        return state.GetCatCountry?.catCountry;
    });
    const mainLoad = useSelector((state: RootState) => {
        return state.PutMainInfo?.loading;
    });
    const [openTree, setOpenTree] = useState(false);
    useEffect(() => {
        catCountry.map((item: ICountryItem) => setValue('countryId', item.countryId));
    }, [catCountry]);
    const {
        handleSubmit,
        register,
        watch,
        trigger,
        setValue,
        getValues,
        formState: {errors},
    } = useForm<IFormCreateBranch>({
        resolver: yupResolver(schemaFormModal),
        defaultValues: {
            countryId: undefined,
            countryName: infoBranch.country,
            nameBranch: infoBranch.branchName,
            description: infoBranch.description,
            levelId: infoBranch.idLevel,
        },
    });
    const watchCountry = watch('countryName');
    const watchLevel = watch('levelId');
    const levelSelected = (id: number | undefined) => {
        if (getValues('levelId') === id) {
            return setValue('levelId', undefined);
        }
        setValue('levelId', id);
    };
    useEffect(() => {
        dispatch(GetTieredLevel({companyId, levelId: watchLevel}, token));
    }, [watchLevel]);

    const onSubmit = (data: IFormCreateBranch) => {
        const postData: IUpdateMainInfo = {
            branchId: Number(idBranch),
            countryId: data.countryId,
            nameBranch: data.nameBranch,
            description: data.description,
            nivelId: data.levelId,
        };
        dispatch(
            PutMainInfo(postData, token, setShowMainForm, setShowItems, showItems, Number(idBranch))
        );
    };

    return (
        <ContainerFlex Height="auto" FlexDir="column" Bb="1px solid #e5e7e9">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Información principal
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Height="200px"
                Align="flex-start"
                FlexDir="column"
                FlexWrap="wrap"
                AlignContent="center"
                ColumnGap="24px"
            >
                <Text FontSize="0.75rem" required Color="#6d6e71">
                    País
                </Text>
                <MainInfoSelect
                    data={catCountry}
                    optionSelect={watchCountry}
                    setValue={setValue}
                    errors={errors}
                    trigger={trigger}
                />
                {errors.countryName?.message && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.countryName.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" Color="#6d6e71">
                    Descripción
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Margin={errors.description ? '0 0 1px 0' : '0 0 25px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Descripción"
                    Width="300px"
                    {...register('description')}
                    type="text"
                    maxLength={160}
                    error={errors.description?.message}
                />
                {errors.description && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.description.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71">
                    Nombre de la sucursal
                </Text>
                <Input
                    Cursor="text"
                    Height="2.5rem"
                    Width="300px"
                    Margin={errors.nameBranch ? '' : '0 0 24px 0'}
                    FontSize="0.875rem"
                    HolderFontSize="0.875rem"
                    Padding="13px 13px 11px 13px"
                    Radius="4px"
                    placeholder="Nombre"
                    {...register('nameBranch')}
                    type="text"
                    maxLength={160}
                    error={errors.nameBranch?.message}
                />
                {errors.nameBranch && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.nameBranch.message}
                    </Text>
                )}
                <Text FontSize="0.75rem" required Color="#6d6e71">
                    Estructura organizacional
                </Text>
                <SearchInput
                    Justify="start"
                    Border={errors.levelId ? '1px solid #ef4f55' : '1px solid #e5e7e9'}
                    BorderH={errors.levelId ? '1px solid #ef4f55' : '1px solid #35cf44'}
                    BorderF={errors.levelId ? '1px solid #ef4f55' : '1px solid #35cf44'}
                    Width="300px"
                    Margin={errors.levelId ? '' : '0 0 24px 0'}
                    Padding="0 8px"
                    BoxShadowH={errors.levelId ? '' : '0px 4px 4px rgba(53, 207, 68, 0.2)'}
                    Height="40px"
                    Position="none"
                    BackG="#ffffff"
                    onClick={() => setOpenTree(!openTree)}
                    Cursor="pointer"
                >
                    <ContainerFlex Justify="flex-start">
                        <TextEllipsis FontSize="0.875rem" FontWeight="400">
                            {tieredLevel}
                        </TextEllipsis>
                    </ContainerFlex>
                    <IconContainer Width="100%" Justify="flex-end" Color="#6d6e71" FontSize="1rem">
                        <Image src={editOrganization} alt="edit-org" />
                    </IconContainer>
                </SearchInput>
                {errors.levelId && (
                    <Text Padding="0px 12px" FontSize="0.75rem" Color="#ef4f55">
                        {errors.levelId.message}
                    </Text>
                )}
                <OrganizationTreeModal
                    createModal={openTree}
                    setCreateModal={setOpenTree}
                    levelSelected={levelSelected}
                    watchLevel={watchLevel}
                    edit={true}
                    trigger={trigger}
                />
            </ContainerFlex>
            <ContainerFlex Padding="18px" Height="16%" Gap="20px">
                <ButtonGeneral
                    width="93px"
                    text="Cancelar"
                    type="submit"
                    border="transparent"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    hColor="#35cf44"
                    transform=""
                    hBorder=""
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={() => {
                        setShowMainForm(false);
                    }}
                    disabled={mainLoad}
                    cursor={mainLoad ? '' : 'pointer'}
                />
                <ButtonGeneral
                    width="87px"
                    text={mainLoad ? <LoadingGeneralButtons /> : 'Guardar'}
                    type="submit"
                    hColor="#35cf44"
                    bs="0 0 8px 0 rgba(167, 169, 172, 0.25)"
                    transform="capitalize"
                    hText="#35cf44"
                    hShadow="0px 2px 4px rgba(53, 207, 68, 0.5);"
                    clic={handleSubmit(onSubmit)}
                    disabled={mainLoad}
                    cursor={mainLoad ? '' : 'pointer'}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
