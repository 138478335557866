import React from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import {ContainerForm, Text} from '@/components/Shopify/Ecommerce/styles';
import {MethodPaymentCredits} from '@/components/MyAccount/MyCredit/CreditDetails/MethodPaymentCredits';
import {ResumeTypePay} from '@/components/MyAccount/MyCredit/CreditDetails/ResumeTypePay';
import {CREDITSROUTE} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import {formatDate} from '@/hooks/currentFormatUtils';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaPaymentMethod} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/schemaPaymentMethods';
import {
    ICardData,
    ICardDataSubmit,
} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import {ICreditPaymentProcessProps} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {RootState} from '@/config/store';
import {creditPaymentProcessAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';

export const ActionPaymentCredits = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {loansCarCreditsSelected} = useSelector((state: RootState) => state.shoppingCarClient);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<ICardDataSubmit>({
        resolver: yupResolver(schemaPaymentMethod),
        defaultValues: {
            cardNumber: '',
            cardOwner: '',
            vigency: '',
            cvv: '',
            defaultCard: '',
        },
    });
    const onSubmit = (data: ICardDataSubmit) => {
        const {cardNumber, cardOwner, cvv, vigency}: ICardData = {
            ...data,
            vigency: formatDate(DateTime.fromISO(String(data.vigency)), PAYMENT_METHOD.DATE_FORMAT),
            cardNumber: data.cardNumber.replace(/\s/g, ''),
        };
        const creditPaymentProps: ICreditPaymentProcessProps = {
            token,
            loansCarCreditsSelected,
            cardNumber,
            cardOwner,
            cvv,
            vigency,
        };
        dispatch(creditPaymentProcessAction(creditPaymentProps));
    };
    return (
        <>
            <Text oFlow="normal" Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                {CREDITSROUTE.TITLE}
            </Text>
            <ContainerForm
                BackG="#FAFAFF"
                Justify="start"
                Align="start"
                Gap="1.0rem"
                onSubmit={handleSubmit(onSubmit)}
            >
                <MethodPaymentCredits
                    register={register}
                    setValue={setValue}
                    control={control}
                    errors={errors}
                />

                <ResumeTypePay />
            </ContainerForm>
        </>
    );
};
