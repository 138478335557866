export const CLIENT_PROFILE = {
    INITIAL_DATA: 'INITIAL_DATA',
    PROFILE_SUCCESS: 'PROFILE_SUCCESS',
    PROFILE_ERROR: 'PROFILE_ERROR',
    ID_CLIENT: 'ID_CLIENT',
};

export const GET_CLIENT_DETAILS = {
    GET_START: 'GET_CLIENT_DETAILS_START',
    GET_SUCCESS: 'GET_CLIENT_DETAILS_SUCCESS',
    GET_ERROR: 'GET_CLIENT_DETAILS_ERROR',
    CLEAR_DATA: 'GET_CLIENT_DETAILS_CLEAR_DATA',
};

export const QUICK_ACTIONS_SUCCESS = 'QUICK_ACTIONS_SUCCESS';
export const QUICK_ACTIONS_ERROR = 'QUICK_ACTIONS_ERROR';

export const VALIDATE_ID_DOC_IMAGE = {
    SET_START: 'SET_START',
    SET_SUCCESS: 'SET_SUCCESS',
    SET_ERROR: 'SET_SUCCESS',
};

export const ADD_ID_DOCUMENT = {
    ADD_DOC_START: 'ADD_DOC_START',
    ADD_DOC_SUCCESS: 'ADD_DOC_SUCCESS',
    ADD_DOC_ERROR: 'ADD_DOC_ERROR',
    CLEAR_ERROR: 'CLEAR_ERROR',
    ADD_DOC_SAVE: 'ADD_DOC_SAVE_IMAGES',
    CLEAR_IMAGES: 'CLEAR_IAMGES',
};

export const TRANSACTIONS = {
    INITIAL_TRANSACTIONS_DATA: 'INITIAL_TRANSACTIONS_DATA',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
    RESET_TRANSACTIONS: 'RESET_TRANSACTIONS',
};

export const PERSONAL_CREDIT = {
    GET_START: 'GET_PERSONAL_CREDIT_START',
    GET_SUCCESS: 'GET_PERSONAL_CREDIT_SUCCESS',
    GET_ERROR: 'GET_PERSONAL_CREDIT_ERROR',
};

export const ENDEAVORS_CONTRACTS = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const CAT_STATUS_CONTRACT = {
    START_PAWN: 'START_PAWN',
    SUCCESS_PAWN: 'SUCCESS_PAWN',
    ERROR_PAWN: 'ERROR_PAWN',
};

export const DETAIL_CONTRACTS = {
    START: 'START',
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR',
};

export const SAVE_ID_PAWN = 'SAVE_ID_PAWN';
export const SAVE_CONTRACT_IMG = 'SAVE_CONTRACT_IMG';

export const DOWNLOAD_CONTRACT = {
    START_DATA: 'START_DATA',
    GET_URL: 'GET_URL',
    ERROR_LOAD: 'ERROR_LOAD',
};
export const SEARCHING_OPERATIONS = {
    OPERATION_SEARCHING: 'OPERATION_SEARCHING',
};

export const ADD_DISCHARGE = {
    ADD_START: 'ADD_DISCHARGE_ENDORSEMENT_START',
    ADD_SUCCESS: 'ADD_DISCHARGE_ENDORSEMENT_SUCCESS',
    ADD_ERROR: 'ADD_DISCHARGE_ENDORSEMENT_ERROR',
};
