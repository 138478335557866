import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {formatNum} from '@/hooks/currentFormatUtils';
import useToast from '@/hooks/useToast';
import useCheckPawnsOnCart from '@/hooks/useCheckPawnsOnCart';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {
    CONTRACT_DETAIL,
    dellWords,
    STATUS_CONTRACT,
    TYPE_MOVEMENT_ADD_CART,
} from '@Shopify/ClientProfile/constants';
import {addDischargeOrEndorsement} from '@Shopify/ClientProfile/Redux/Action/AddDischargeEndorsement';
import {setIsCartOpen} from '@/components/ShoppingCart/Redux/Actions/CartState';
import {ICarShopData} from '@Shopify/ClientProfile/interfaces';

export const ContractResume = () => {
    const dispatch = useDispatch();
    const {showToast, ToastContainer} = useToast();
    const {checkCartAndPawns} = useCheckPawnsOnCart();
    const {carShoppingId, details, branch, token, pawnId} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        carShoppingId: state.getUsersValidation.userData?.carShoopingId as number,
        details: state.detailContract.details,
        pawnId: state.detailContract.pawnId,
        branch: state.getUsersValidation.userData?.branchDetails?.[0]?.branchId as number,
    }));

    const handleError = (message: string) => {
        dispatch(setIsCartOpen(true));
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const endorsementDischarge = async (typeMovement: number, payment: number) => {
        const {hasPawns, message} = await checkCartAndPawns();
        if (hasPawns && message) {
            handleError(message);
            return;
        }
        const shopValuator: ICarShopData = details.carShopDat!;
        const json = {
            carShoppingId: carShoppingId,
            typeMovement: typeMovement,
            branchId: branch,
            pawnId: pawnId,
            paymentAmount: payment,
            iva: shopValuator.iva,
            moratoriumAmount: shopValuator.moratoriumAmount,
            amountStorage: shopValuator.amountStorage,
            amountInterest: shopValuator.maximumInterest,
            insuranceAmount: shopValuator.insuranceAmount,
            amountReposition: shopValuator.amountReposition,
            amountRepositionIVA: shopValuator.amountRepositionIVA,
            amountDiscountInterest: shopValuator.amountDiscountInterest,
            amountDiscountInterestIVA: shopValuator.amountDiscountInterestIVA,
            maximumInterest: shopValuator.maximumInterest,
        };
        await dispatch(
            addDischargeOrEndorsement(json, token, () => dispatch(setIsCartOpen(true)), handleError)
        );
    };

    return (
        <>
            <ContainerFlex {...style.resumeContent}>
                <ContainerFlex FlexDir="column" Align="start" Padding="0 0.5rem 0 0">
                    <Text {...style.resumeContent.detail}>
                        {details &&
                            details.borrowedMoney &&
                            formatNum({
                                number: Number(
                                    dellWords(details.borrowedMoney, CONTRACT_DETAIL.LENDED)
                                ),
                                fractionDigits: 0,
                                money: true,
                            })}
                    </Text>
                    <Text Color="#2A2C2F">{CONTRACT_DETAIL.LENDED}</Text>
                </ContainerFlex>
                <ContainerFlex {...style.resumeContent.sections} Padding="0 0.5rem">
                    <Text {...style.resumeContent.detail}>
                        {details && dellWords(details.garmentsNumber, CONTRACT_DETAIL.ARTICLES)}
                    </Text>
                    <Text Color="#2A2C2F">{CONTRACT_DETAIL.ARTICLES}</Text>
                </ContainerFlex>
                <ContainerFlex {...style.resumeContent.sections} Padding="0 0 0 0.5rem">
                    <Text {...style.resumeContent.detail}>
                        {details && details.nextEndorsement}
                    </Text>
                    <Text Color="#2A2C2F">{CONTRACT_DETAIL.DATE_ENDEAVOR}</Text>
                </ContainerFlex>
                {details && (
                    <>
                        {details.contractDetail.status === STATUS_CONTRACT.TO_WIN && (
                            <ButtonGeneral
                                clic={() =>
                                    endorsementDischarge(
                                        TYPE_MOVEMENT_ADD_CART.DISCHARGE,
                                        details.contractDetail.discharge
                                    )
                                }
                                height="2rem"
                                text={CONTRACT_DETAIL.NOW_DISCHARGE}
                                secondaryButton
                            />
                        )}
                        <ButtonGeneral
                            clic={() =>
                                endorsementDischarge(
                                    TYPE_MOVEMENT_ADD_CART.RENEWAL,
                                    details.contractDetail.endorsement
                                )
                            }
                            height="2rem"
                            margin="0 0 0 1rem"
                            text={CONTRACT_DETAIL.NOW_PAY}
                        />
                    </>
                )}
            </ContainerFlex>

            <ToastContainer />
        </>
    );
};
