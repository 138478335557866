import React from 'react';
import {CART_MESSAGES} from '@Loan/PaymentQuoter/constants';
import {SHOPPING_CART} from '@/components/ShoppingCart/constants';
import {DATE_FORMATS} from '@/components/Shopify/ListClient/constants';
import {convertDate} from '@/components/Shopify/ClientProfile/constants';
import {formatDate, numberToCurrency} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Text, Image, ContainerImg} from '@Shopify/Ecommerce/styles';
import {IClienCarDataMapping} from '@/components/Shopify/Products/redux/interface';
import useFormatDate from '@/hooks/useFormatDate';

export const ContractDetail = ({
    endorsementsPay,
    pawnsPay,
    dischargesPay,
}: IClienCarDataMapping) => {
    const contractData = () => {
        return {
            img: endorsementsPay?.imgURL || pawnsPay?.pledges[0].imgurl || dischargesPay?.imgURL,
            folio:
                endorsementsPay?.contractName ||
                pawnsPay?.contractName ||
                dischargesPay?.contractName,
            articleNumber:
                endorsementsPay?.pledgeNumber ||
                pawnsPay?.pledges.length ||
                dischargesPay?.pledgeNumber,
            nextDatePay:
                (endorsementsPay &&
                    useFormatDate(
                        endorsementsPay.nextPaymentDate || endorsementsPay.extension.nextPaymentDate
                    )) ||
                (pawnsPay && useFormatDate(pawnsPay.dueDate)),
            subtotal:
                (endorsementsPay && numberToCurrency(endorsementsPay.subEndorsement)) ||
                (pawnsPay && numberToCurrency(pawnsPay.loan)) ||
                (dischargesPay && numberToCurrency(dischargesPay.subDischarge)),
        };
    };

    return (
        <ContainerFlex FlexDir="Column" Align="start">
            <ContainerFlex Justify="start" Align="start" Padding="0.5rem" Gap="0.5rem">
                <ContainerImg Width="2.113rem" Radius="0.25rem" Border="1px solid #E8E9EA">
                    <Image
                        src={contractData().img}
                        alt="cart-item"
                        height="37rem"
                        Width="33rem"
                        AspectRatio="1"
                        ObjectFit="contain"
                    />
                </ContainerImg>
                <ContainerFlex Align="start" FlexDir="column" Gap="0.5rem">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {`${CART_MESSAGES.CONTRACT}: ${contractData().folio}`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>

            {endorsementsPay && (
                <ContainerFlex Padding="0.5rem" Justify="space-between">
                    <Text Color="#1D1E20">{SHOPPING_CART.ENDORSEMENTS_PAYMENT}</Text>
                    <Text Color="#1D1E20">{numberToCurrency(endorsementsPay.subEndorsement)}</Text>
                </ContainerFlex>
            )}
            {dischargesPay && (
                <ContainerFlex Padding="0.5rem" Justify="space-between">
                    <Text Color="#1D1E20">{SHOPPING_CART.DISCHARGE}</Text>
                    <Text Color="#1D1E20">{numberToCurrency(dischargesPay.subDischarge)}</Text>
                </ContainerFlex>
            )}
            {endorsementsPay && endorsementsPay.extension && (
                <ContainerFlex Padding="0.5rem 0.5rem 0 0.5rem" Justify="space-between">
                    <Text Color="#1D1E20">
                        {`${SHOPPING_CART.EXTENSION} ${endorsementsPay.extension.numberDays} ${
                            SHOPPING_CART.EXTENSION_DAYS
                        } ${formatDate(
                            convertDate(endorsementsPay.extension.nextPaymentDate),
                            DATE_FORMATS.FULL_DATE
                        )}`}
                    </Text>
                    <Text Color="#1D1E20">
                        {numberToCurrency(endorsementsPay.extension.subExtension)}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
