import {LAST_LEVEL_BY_COMPANY} from '@components/LocationsBranch/Redux/types';
import {ILevels, ILevelsRedux} from '@components/LocationsBranch/interfaces';

const initialState: ILevels = {
    error: false,
    loading: false,
    level: {breadcrumbId: '', breadcrumbName: '', levelId: 0},
    selectedLevelId: 0,
};

export const getLevels = (state = initialState, action: ILevelsRedux): ILevels => {
    switch (action.type) {
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_SUCCESS:
            return {
                ...state,
                loading: false,
                level: {...action.level, levelId: state.selectedLevelId},
            };
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case LAST_LEVEL_BY_COMPANY.ID_LEVEL_RESET:
            return initialState;
        case LAST_LEVEL_BY_COMPANY.ID_SET_LEVEL:
            return {
                ...state,
                selectedLevelId: action.selectedLevelId,
                level: {...state.level, levelId: action.selectedLevelId},
            };
        default:
            return state;
    }
};
