import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {
    GET_TERM_PERSONAL_LOAN_ERROR,
    GET_TERM_PERSONAL_LOAN_START,
    GET_TERM_PERSONAL_LOAN_SUCCESS,
    RESET_GET_TERM_PERSONAL_LOAN,
} from '@components/LoansProducts/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {personalCreditTermIdAction} from '@components/LoansProducts/interfaces';

export const fetchGetTermPersonalLoanStart = () => {
    return {
        type: GET_TERM_PERSONAL_LOAN_START,
    };
};

export const fetchGetTermPersonalLoanSuccess = (data: AxiosResponse) => {
    return {
        type: GET_TERM_PERSONAL_LOAN_SUCCESS,
        payload: data,
    };
};

export const fetchGetTermPersonalLoanError = (error: AxiosError) => {
    return {
        type: GET_TERM_PERSONAL_LOAN_ERROR,
        payload: error,
    };
};

export const resetTermPersonalLoan = () => {
    return {
        type: RESET_GET_TERM_PERSONAL_LOAN,
    };
};

export const getTermPersonalLoansInformation = (
    token: string,
    filters: personalCreditTermIdAction
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetTermPersonalLoanStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTermPersonalLoan}${queryString}`,
                {headers}
            );
            dispatch(fetchGetTermPersonalLoanSuccess(response));
        } catch (error) {
            dispatch(fetchGetTermPersonalLoanError(error as AxiosError));
        }
    };
};
