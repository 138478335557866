import React, {useState} from 'react';
import {SearchInput} from '@Customer/Styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import IconSearch from '@images/search.svg';
import IconClose from '@images/closeBlueIcone.svg';
import IconAlert from '@images/Alert.svg';
import {OptionsSearchList, SearchContainer} from '@components/LoansProducts/Redux/styles';
import {useDispatch, useSelector} from 'react-redux';
import {
    fetchSearchLoanProducts,
    SelectedSearchLoanProductsList,
    SelectedSearchLoanProductsSuccess,
} from '@components/LoansProducts/Redux/Actions/GetLoanProductsSearchEngine';
import {getProductsCustomerUser} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';
import {SearchInputComponentProps, SuggestedList} from '@components/LoansProducts/interfaces';
import {LOAN_PRODUCT_PER_PAGE, LOANSLIST} from '@components/LoansProducts/constants';
import {RootState} from '@/config/store';

const SearchProductsLoans = ({
    enterpriceId,
    token,
    searchResults,
    message,
    loading,
    setIsMessage,
}: SearchInputComponentProps) => {
    const dispatch = useDispatch();
    const [inputValue, setInputValue] = useState('');
    const [isOptionsVisible, setOptionsVisible] = useState(false);
    const {activeStatus} = useSelector((state: RootState) => state.getProductsCustomerUserReducer);
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setInputValue(value);
        if (value.length >= 3) {
            dispatch(
                fetchSearchLoanProducts(
                    {
                        companyId: enterpriceId,
                        search: value,
                        status: activeStatus,
                        pageSize: 3,
                        pageNumber: 1,
                    },
                    token
                )
            );
            setOptionsVisible(true);
            setIsMessage(false);
        }
        if (value.length === 0) {
            dispatch(
                getProductsCustomerUser(token, enterpriceId, activeStatus, LOAN_PRODUCT_PER_PAGE, 1)
            );
            setOptionsVisible(false);
            setIsMessage(false);
        }
    };

    const handleCancelSearch = () => {
        setInputValue('');
        dispatch(
            getProductsCustomerUser(token, enterpriceId, activeStatus, LOAN_PRODUCT_PER_PAGE, 1)
        );
        setOptionsVisible(false);
        setIsMessage(false);
    };

    const handleSelectTerm = (item: SuggestedList) => {
        setInputValue(item.termName);
        dispatch(SelectedSearchLoanProductsSuccess(item));
        setOptionsVisible(false);
        setIsMessage(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (searchResults.length > 0) {
                dispatch(SelectedSearchLoanProductsList(searchResults));
                setIsMessage(false);
            } else {
                setIsMessage(true);
            }
            setOptionsVisible(false);
        }
    };

    return (
        <SearchContainer>
            <SearchInput
                Height="40px"
                Width="270px"
                Position="none"
                GridColumn="90% 10%"
                widthInput="200px"
            >
                <input
                    type="text"
                    placeholder={LOANSLIST.SEARCH}
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                />
                {inputValue.length > 0 ? (
                    <Image
                        alt="icon-close"
                        src={IconClose}
                        onClick={handleCancelSearch}
                        Cursor="pointer"
                    />
                ) : (
                    <Image alt="icon-search" src={IconSearch} Cursor="pointer" />
                )}
            </SearchInput>
            {isOptionsVisible && inputValue.length >= 3 && (
                <OptionsSearchList>
                    {searchResults.length > 0
                        ? searchResults.map((item) => (
                              <ContainerFlex
                                  key={item.termId}
                                  Justify="start"
                                  Padding="8px"
                                  Cursor="pointer"
                                  HBackground="#F0F0FF"
                                  Bl="2px solid transparent"
                                  HBorderLeft="2px solid #5A5AFF"
                                  onClick={() => handleSelectTerm(item)}
                                  HColor="#5A5AFF"
                              >
                                  <Text Color="#2A2C2F" Cursor="pointer">
                                      {item.termName}
                                  </Text>
                              </ContainerFlex>
                          ))
                        : message && (
                              <ContainerFlex Justify="start" Height="40px" Padding="8px" Gap="8px">
                                  <Image alt="icon-search" src={IconAlert} />
                                  <Text FontSize="1rem" Color="#2A2C2F">
                                      {message}
                                  </Text>
                              </ContainerFlex>
                          )}
                </OptionsSearchList>
            )}
        </SearchContainer>
    );
};

export default SearchProductsLoans;
