import {
    START_SHARE_TICKETS_RESUME,
    SUCCESS_SHARE_TICKETS_RESUME,
    ERROR_SHARE_TICKETS_RESUME,
} from '@components/ShoppingCart/Redux/types';
import {IShareTicket, IShareTicketRedux} from '@components/ShoppingCart/Redux/interfaces';

const initialState: IShareTicket = {
    shareTicket: null,
    loading: false,
    error: false,
};

export const shareTicketsResume = (
    state = initialState,
    action: IShareTicketRedux
): IShareTicket => {
    switch (action.type) {
        case START_SHARE_TICKETS_RESUME:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case SUCCESS_SHARE_TICKETS_RESUME:
            return {
                ...state,
                shareTicket: action.shareTicket,
                loading: false,
            };
        case ERROR_SHARE_TICKETS_RESUME:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
