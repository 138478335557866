import {useFormContext} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ITermNameFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {validateTermNameAction} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/CreateTermPersonalLoanActions';

export const TermNameField: React.FC<ITermNameFieldProps> = ({name}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const companyId = useSelector((state: RootState) =>
        Number(state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId)
    );
    const {isTermNameUnique} = useSelector(
        (state: RootState) => state.createTermPersonalLoanReducer
    );
    const {
        register,
        formState: {errors},
    } = useFormContext();

    const errorMessage = errors[name]?.message?.toString() || '';
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        dispatch(validateTermNameAction(token, companyId, value));
    };
    return (
        <>
            <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                <Text Color="#2A2C2F" FontWeight="700">
                    {LOAN_DETAILS.FIELDS.PRODUCT_NAME}
                </Text>
                <Input
                    error={!isTermNameUnique}
                    {...register(name)}
                    maxLength={100}
                    onBlur={handleChange}
                />
                {!isTermNameUnique && <ErrorMessage message={LOAN_DETAILS.ERROR_MESSAGE} />}
                {errorMessage && isTermNameUnique && <ErrorMessage message={errorMessage} />}
            </ContainerFlex>
        </>
    );
};
