import React from 'react';
import {EnhancedTextProps} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/interface';
import {
    BoldText,
    StyledText,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/style';

export const EnhancedText: React.FC<EnhancedTextProps> = ({text, boldParts = [], ...props}) => {
    const parts = text.split(new RegExp(`(${boldParts.join('|')})`, 'gi'));

    return (
        <StyledText {...props}>
            {parts.map((part, index) =>
                boldParts.includes(part) ? (
                    <BoldText key={index}>{part}</BoldText>
                ) : (
                    <React.Fragment key={index}>{part}</React.Fragment>
                )
            )}
        </StyledText>
    );
};
