export const routers = {
    login: '/',
    ClientList: '/Clients/ClientList',
    ClientAddNew: '/Clients/NewClient',
    LoginAdmin: '/LoginAdmin',
    ForgotPasswordAdmin: '/ForgotPasswordAdmin',
    NewPassword: '/AdminLogin/ResetPassword/:user',
    ConfirmMailSuccess: '/AdminLogin/ConfirmMailSuccess/:user',
    NewAdminAccount: '/NewAdminAccount',
    CreateOrganization: '/CreateOrganization',
    WithHoldingsScreen: '/Fundiciones/AsignacionAuditoria',
    dashboardNew: '/DashboardNew/Index',
    CloseBranch: '/CloseBranch',
    ConsultAssignmentScreen: '/Fundiciones/ConsultaAuditoria',
    EditAssignmentScreen: '/Fundiciones/Edicion/:id',
    SucursalVerificationDataScreen: '/Fundiciones/Auditoria',
    RecolectionGoldScreen: '/Fundiciones/Recoleccion/:idsucursal/:idaudit',
    AuctionScreen: '/Recoleccion/Almoneda/:sku',
    WebcamCapture: '/Fundiciones/Recoleccion/Captura',
    IncidentsFoundryScreen: '/Recoleccion/Almoneda/Incidencia/:sku',
    DocumentsFoundryScreen: '/Fundiciones/Documento/:id',
    DeliveryScreen: '/Fundiciones/Entrega',
    DeliverySecuritybagScreen: '/Fundiciones/Entrega/Seguribolsa',
    AssignmentFoundryScreen: '/Fundiciones/AsignacionFundicion',
    SecurityBagWeightScreen: '/Fundiciones/Fundicion',
    WeightSecurityBagScreen: '/PesoSeguribolsa/:id',
    SecurityBagWeightJiracasScreen: '/PesoSeguribolsaJiracas',
    SecurityBagWeightEscoriaScreen: '/PesoEscoria',
    SecurityBagWeightGoldScreen: '/Fundiciones/PesoEscoriaOro/:id',
    PurityScreen: '/Fundiciones/Pureza',
    PurityLabScreen: '/Pureza/:id',
    PurityFile: '/PurezaArchivo/:lingote',
    PurityPay: '/Fundiciones/PurezaPago/:id',
    UserProfile: 'User/UserProfile',
    Shopify: '/Administracion/Shopify',
    ExpenseDetails: '/ExpenseDetails',
    ExpenseDetailsCreate: '/ExpenseDetailsCreate',
    ProductsDetailShopify: '/Administracion/Shopify/:sku',
    Ecommerce: '/Home/Ecommerce',
    Categories: '/Ecommerce/:category',
    Empe: '/Empenos/Cotizador/:isEditable?/:IDArticle?/:IDContract?',
    SearchLoan: '/Empenos/Cotizador/Buscador',
    LevelLoan: '/Empenos/Cotizador/Niveles',
    FeaturesLoan: '/Empenos/Cotizador/Caracteristicas',
    ContractsLoan: '/Empenos/Cotizador/Contratos&Plazos',
    PictuesLoan: '/Empenos/Cotizador/Imagenes',
    AdministrationArticle: '/Management/Articles',
    FieldDinamics: '/Management/Articles/FieldsDinamics',
    ManageArticle: '/Administracion/Articulos/Manage',
    AllArticle: '/Administracion/Articulos/All',
    AdministrationEcommerce: '/Administracion/Ecommerce',
    AdministrationBanner: '/Administracion/Banner',
    BannerTable: '/Administracion/Ecommerce/Banner',
    Reactivations: '/Reactivations',
    DetailsReactivations: '/DetailsReactivations',
    ReactivationScreen: '/ReactivationScreen',
    Support: '/Administracion/Soporte',
    Endeavors: '/Endeavors',
    DetailClient: '/DetailClient',
    Orders: '/Orders',
    Payment: '/Payment',
    PaymentSuccess: '/PaymentSuccess',
    Quoter: '/Quoter',
    DeadLines: '/DeadLines',
    NewDeadline: '/NewDeadline',
    RouteDeadline: '/NewDeadline/RouteDeadline',
    CreateService: '/NewDeadLine/CreateService',
    CreateDeadline: '/NewDeadLine/CreateService/CreateDeadLine',
    CreateLocation: '/Locations/CreateLocation',
    NavLocations: '/NavLocations',
    Vault: '/Locations/CreateLocation/Vault',
    Rack: '/Locations/CreateLocation/Rack',
    Floor: '/Locations/CreateLocation/Floor',
    Pension: '/Locations/CreateLocation/Pension',
    LocationSuccess: '/Locations/CreateLocation/LocationSuccess',
    ManageLocations: '/Locations/ManageLocations',
    AssigmentLocations: '/Locations/AssigmentLocations',
    FundingInquiry: '/CashFlow/FundingInquiry',
    FundingRequest: '/CashFlow/FundingRequest',
    CashFlow: '/CashFlow',
    FundingExcedents: '/FlujoEfectivo/ConsultaExcedentes',
    CashFlowBasic: '/CashFlow/Basic',
    AddCardsPayment: '/AddCardsPayment',
    RequestManagment: '/FlujoEfectivo/ConsultaFondeo/GestionSolicitud/:idSucursal/:idSolicitud',
    InProcess: '/InProcess',
    POSClosing: '/Cierre/CierreCaja',
    StoreClosing: '/Cierre/CierreSucursal',
    SearchQuoter: '/Quoter',
    PhasesContract: '/PhasesContract',
    PaymentGateway: '/PaymentGateway',
    PaymentQuoter: '/PaymentQuoter',
    PaymentQuoterConfirmation: '/PaymentQuoterConfirmation',
    PaymentContracts: '/PaymentQuoter/PaymentContract',
    AuctionPass: '/AuctionPass',
    Headland: '/Headland',
    MoreInfoContainer: '/MoreInfoAuction',
    DetailsPublication: '/Auction/DetailsPublication',
    CloseBox: '/CloseBox',
    MyCompany: '/MyCompany',
    AplicationContact: '/MyCompany/AplicationContact',
    Branches: '/CreateBranch',
    ManageBranch: '/CreateBranch/ManageBranch/:idBranch',
    ManageUser: '/Users/ManageUser/:idUser',
    Users: '/Users',
    CreateUsers: 'Users/CreateUsers',
    CreateUser: 'Users/CreateUser',
    Roles: '/Roles',
    CreateRole: '/Roles/CreateRole',
    UpdateRole: '/Roles/UpdateRole',
    LocationBranch: '/LocationBranch',
    CreateNewGroup: '/LocationBranch/CreateLocation',
    EditLocation: '/LocationBranch/EditLocation/:locationName/:id',
    PersonalLoans: '/PersonalLoans',
    ClientProfile: '/Clients/ClientProfile',
    UpdateAddress: '/UpdateAddress',
    Catalogue: '/Catalogue',
    AppSettings: '/AppSettings',
    SettingsPaymentAndBilling: '/Settings/PaymentAndBilling',
    MyPlan: 'Administrator/MyPlan',
    OpenFundingsModals: 'OpenFundingsModals',
    ProductDetailsContainer: '/ProductDetails',
    PendingTransaction: '/PendingTransaction',
    CreditsAndPawns: '/CreditsAndPawns',
    CreditRequest: '/CreditsAndPawns/:id/CreditRequest',
    AdminGeneralModule: '/Administrator/GeneralModule',
    CreditQuoter: '/CreditQuoter',
    RequestNewCatalogLevel: '/RequestNewCatalogLevel',
    PersonalLoansValidating: '/PersonalLoans/:creditId/Validating',
    PersonalLoanSemiApproved: '/PersonalLoans/:creditId/SemiApproved',
    PersonalLoansApproved: '/PersonalLoans/:creditId/Approved',
    CatalogManagement: '/InventoryManagement/CatalogManagement',
    DetailsExpense: '/CashFlow/ExpenseDetails',
    CreditsPayment: '/CreditsPayment',
    CreditsCartShopping: '/CreditsCartShopping',
    MyAccount: '/MyAccount',
    CreditDetails: '/CreditDetails/:creditId',
    AdminCashflow: 'Administrator/Cashflow',
    NewLoanProduct: '/NewLoanProduct',
    ContractDetail: '/ClientProfile/ContractDetail',
    LoansProducts: '/LoansProducts',
    CreditDetailsRejected: '/CreditDetails/:creditId/Rejected',
    CreditDetailsUnderReview: '/CreditDetails/:creditId/UnderReview',
    CreditDetailsApproved: '/CreditDetails/:creditId/Approved',
    NewUser: '/NewUser',
    DocumentClients: '/DocumentClients',
    AuctionPassDetails: '/BranchCreation/ComercialCicle/AuctionDetails',
    GlobalQuoter: '/GlobalQuoter',
    AuctionPassEdit: '/AuctionPassEdit',
    CreditDetail: '/CreditsAndPawns/:id/CreditDetail',
    RolesAndPermissions: '/RolesAndPermissions',
    TransversalLoader: '/TransversalLoader',
    NewEmployeeUser: '/NewEmployeeUser',
    NewRole: '/RolesAndPermissions/NewRole',
    EditRole: '/RolesAndPermissions/EditRole',
    QuickPayment: '/QuickPayment',
    AccountContainerLogin: '/MarketUser',
};
