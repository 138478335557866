import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {
    GET_QUOTER_COMPANIES_LOAN_ERROR,
    GET_QUOTER_COMPANIES_LOAN_START,
    GET_QUOTER_COMPANIES_LOAN_SUCCESS,
    GET_SELECTED_QUOTER_CREDIT,
} from '@components/CreditQuoter/Redux/Types/Types';

export const fetchGetQuoterCompaniesLoansStart = () => {
    return {
        type: GET_QUOTER_COMPANIES_LOAN_START,
    };
};

export const fetchGetQuoterCompaniesLoansSuccess = (result: AxiosResponse) => {
    return {
        type: GET_QUOTER_COMPANIES_LOAN_SUCCESS,
        payload: result.data,
    };
};

export const fetchGetQuoterCompaniesLoansError = (error: AxiosError) => {
    return {
        type: GET_QUOTER_COMPANIES_LOAN_ERROR,
        error,
    };
};

export const GetQuoterCompaniesLoansAditionalService = (
    filters: {termPersonalLoanId: number},
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchGetQuoterCompaniesLoansStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetQuoterCompaniesLoansAditionalInf}${queryString}`,
                {headers}
            );
            dispatch(fetchGetQuoterCompaniesLoansSuccess(response));
        } catch (error) {
            dispatch(fetchGetQuoterCompaniesLoansError(error as AxiosError));
        }
    };
};

export const fecthSelectedQuoterPersonalList = (result: number) => {
    return {
        type: GET_SELECTED_QUOTER_CREDIT,
        item: result,
    };
};
