export const JOINTEXTDEADLINE = {
    TITLE: 'Combinar contrato',
    SUBTITLE: 'Arrastra los artículos para agregarlos al contrato que desees.',
    VALUATION: 'Avalúo:',
    LOAN: 'Préstamo:',
    ADD: 'Arrastra artículos para agregarlos a este contrato',
};

export const COOWNEREDIT = {
    TITLE: 'Editar cotitular/beneficiario',
    TITLE_ADD: 'Agrega cotitular/beneficiario',
    FIRSTNAME: 'Nombre',
    LASTNAME: 'Apellido',
    LASTNAME_PATERNAL: 'Apellido Paterno',
    LASTNAME_MATERNAL: 'Apellido Materno',
    EMAIL: 'Correo',
    PHONE: 'Numero de teléfono',
    ADD: '¿Cómo lo quieres agregar?',
    COOWNER: 'Cotitular',
    BENEFIST: 'Beneficiario',
    DELETE: 'Eliminar contacto',
    SAVE_CHANGES: 'Guardar cambios',
    CANCEL: 'Cancelar',
    ADDING_ERROR: 'Error al agregar cotitular/beneficiario',
};

export const DEFAULT_LADA = {
    countryId: 151,
    lada: '+52',
    name: 'México',
    urlFlag:
        'https://storage.googleapis.com/goldenstarweb/media/Assests/Banderas/America/Mexico.svg',
};
