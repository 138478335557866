import React, {useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';

import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

import {
    FieldNames,
    FILTERLOANS,
    filterLoansForm,
    LOAN_PRODUCT_PER_PAGE,
    monthOptions,
} from '@components/LoansProducts/constants';
import {LoansModalProps, SubmitFilterLoans} from '@components/LoansProducts/interfaces';
import {RootState} from '@/config/store';
import {SearchLoansProductsInput} from '@components/LoansProducts/Redux/Actions/GetFilterLoanProducts';
import {ContainerFlex, ContainerCellGrid, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {schemaFilterLoans} from '@components/LoansProducts/schemaFilterLoans';
import {
    AddFilterLoansForm,
    resetFilterLoans,
} from '@components/LoansProducts/Redux/Actions/SaveFilterLoans';
import {getProductsCustomerUser} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';
import RenderDisplayValue from '@components/LoansProducts/DisplayValue';
import {StyleNumericFormat} from '@/components/Loan/styles';
import * as Styles from '@components/LoansProducts/Styles';

export const ModalSearchProducts = ({setShowModal}: LoansModalProps) => {
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const enterpriceId = useSelector((state: RootState) => {
        return Number(state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId);
    });

    const {activeStatus, data} = useSelector(
        (state: RootState) => state.getProductsCustomerUserReducer
    );
    const dispatch = useDispatch();
    const SavesFilteredLoans = useSelector((state: RootState) => {
        return state.SaveFilterLoans.saveFiltered;
    });
    const {
        control,
        handleSubmit,
        setValue,
        reset,
        resetField,
        watch,
        formState: {errors},
    } = useForm<SubmitFilterLoans>({
        resolver: yupResolver(schemaFilterLoans),
        defaultValues: filterLoansForm,
    });

    const formData = watch();
    useEffect(() => {
        if (SavesFilteredLoans) {
            reset({
                minimumAmount: SavesFilteredLoans.minimumAmount
                    ? SavesFilteredLoans.minimumAmount
                    : 0,
                maximumAmount: SavesFilteredLoans.maximumAmount
                    ? SavesFilteredLoans.maximumAmount
                    : 0,
                minimumTime: SavesFilteredLoans.minimumTime || {label: '', value: 0},
                maximumTime: SavesFilteredLoans.maximumTime || {label: '', value: 0},
            });
        }
    }, [SavesFilteredLoans, reset]);
    const onSubmit = (data: SubmitFilterLoans) => {
        const finalData = {
            ...data,
            companyId: enterpriceId,
            minimumAmount: Number(
                parseFloat(String(data.minimumAmount).replace(/[$\s]/g, '').replace(/,/g, ''))
            ),
            maximumAmount: Number(
                parseFloat(String(data.maximumAmount).replace(/[$\s]/g, '').replace(/,/g, ''))
            ),
            minimumTime: data.minimumTime.value,
            maximumTime: data.maximumTime.value,
        };
        const allValuesZero =
            finalData.minimumAmount === FILTERLOANS.ZERO &&
            finalData.maximumAmount === FILTERLOANS.ZERO &&
            finalData.minimumTime === FILTERLOANS.ZERO &&
            finalData.maximumTime === FILTERLOANS.ZERO;
        if (!allValuesZero) {
            dispatch(AddFilterLoansForm({...data, companyId: enterpriceId}));
        }
        dispatch(
            SearchLoansProductsInput(finalData, token, LOAN_PRODUCT_PER_PAGE, 1, activeStatus)
        );
        setShowModal(false);
    };

    const handleClean = async () => {
        await dispatch(
            getProductsCustomerUser(token, enterpriceId, activeStatus, LOAN_PRODUCT_PER_PAGE, 1)
        );
        if (data.length > 0) {
            reset(filterLoansForm);
            dispatch(resetFilterLoans());
        }
    };

    const handleFieldReset = (fieldName: FieldNames) => {
        resetField(fieldName);
        if (fieldName === 'minimumAmount' || fieldName === 'maximumAmount') {
            setValue(fieldName, 0);
        }
    };
    return (
        <Modal Justify="end" Align="start">
            <ContainerFlex {...Styles.containerFlexProps} onSubmit={handleSubmit(onSubmit)}>
                <ContainerFlex Justify="space-between" Height="30px" Width="352px">
                    <Text {...Styles.textHeaderProps}>{FILTERLOANS.FILTER}</Text>
                    <Text {...Styles.cleanButtonTextProps} onClick={handleClean}>
                        {FILTERLOANS.CLEAN}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    Gap="0.5rem"
                    FlexDir="column"
                    FlexWrap="wrap"
                    Height="fit-content"
                >
                    {Object.entries(formData).map(([key, value]) => (
                        <RenderDisplayValue
                            key={key}
                            keyName={key}
                            value={value}
                            onResetField={handleFieldReset}
                        />
                    ))}
                </ContainerFlex>
                <ContainerCellGrid FlexDir="column" Align="start" Height="4.25rem">
                    <Text {...Styles.FilterTextInputs}>{FILTERLOANS.MIN}</Text>
                    <Controller
                        control={control}
                        name="minimumAmount"
                        render={({field: {ref, ...field}}) => (
                            <StyleNumericFormat
                                {...Styles.inputNumericProps}
                                {...field}
                                getInputRef={ref}
                            />
                        )}
                    />
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Height="4.25rem">
                    <Text {...Styles.FilterTextInputs}>{FILTERLOANS.MAX}</Text>
                    <Controller
                        control={control}
                        name="maximumAmount"
                        render={({field: {ref, ...field}}) => (
                            <StyleNumericFormat
                                {...Styles.inputNumericProps}
                                {...field}
                                getInputRef={ref}
                            />
                        )}
                    />
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Height="4.25rem">
                    <Text {...Styles.FilterTextInputs}>{FILTERLOANS.MINTIME}</Text>
                    <Controller
                        control={control}
                        name="minimumTime"
                        render={({field}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                hasError={!!errors.minimumTime}
                                options={monthOptions}
                                isSearchable={false}
                            />
                        )}
                    />
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Height="4.25rem">
                    <Text {...Styles.FilterTextInputs}>{FILTERLOANS.MAXTIME}</Text>
                    <Controller
                        control={control}
                        name="maximumTime"
                        render={({field}) => (
                            <SelectGeneral
                                {...field}
                                placeholder="Selecciona"
                                hasError={!!errors.maximumTime}
                                options={monthOptions}
                                isSearchable={false}
                            />
                        )}
                    />
                </ContainerCellGrid>
                {errors?.maximumAmount && (
                    <ContainerFlex Justify="start" Gap="0.25rem">
                        <Text {...Styles.errorTextProps}>{errors?.maximumAmount.message}</Text>
                    </ContainerFlex>
                )}
                <ContainerFlex Justify="space-between" Gap="1rem" Height="2.5rem" Align="end">
                    <ButtonGenerals
                        {...Styles.cancelButtonProps}
                        type="button"
                        onClick={() => {
                            handleClean();
                            setShowModal(false);
                        }}
                    >
                        {FILTERLOANS.CANCEL}
                    </ButtonGenerals>
                    <ButtonGenerals {...Styles.buttonGeneralsProps} type="submit">
                        {FILTERLOANS.APPLY}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
