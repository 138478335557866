import React, {useState} from 'react';

import Search from '@images/search.svg';
import {useDispatch, useSelector} from 'react-redux';
import {SearchInput} from '@/components/Customer/Styles';
import {OPERATIONS} from '@Shopify/ClientProfile/constants';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {ContainerTabs} from '@General/ModuleTabs/styles/styles';
import {Transactions} from '@Shopify/ClientProfile/Transactions';
import {EndeavorContracts} from '@Shopify/ClientProfile/EndeavorContracts';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {
    getClientTransactions,
    resetTrasactions,
} from '@Shopify/ClientProfile/Redux/Action/GetTransactions';
import PersonalCredits from '@/components/Shopify/ClientProfile/PersonalCredits';
import {getClientPersonalCredit} from '@Shopify/ClientProfile/Redux/Action/GetPersonalCredit';
import {searchingOperations} from '@Shopify/ClientProfile/Redux/Action/SearchOperations';
import {getEndeavours} from '@Shopify/ClientProfile/Redux/Action/EndeavorsContracts';
import Close from '@images/DashboardClose.svg';

export const Operations = () => {
    const dispatch = useDispatch();
    const [placeHolder, setPlaceHolder] = useState(0);
    const [query, setQuery] = useState('');
    const [searchTriggered, setSearchTriggered] = useState<boolean>(false);
    const loading = useSelector((state: RootState) => state.searchOperations).loading;

    const {clientProfile, token} = useSelector((state: RootState) => ({
        clientProfile: state.cardClientProfile,
        token: state.getUsersValidation.userData?.token as string,
    }));

    const handleInputChange = ({target: {value}}: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(value);
    };

    const onTypeInputTransaction = ({key}: React.KeyboardEvent<HTMLInputElement>) => {
        if (!query.trim()) return resetSearch();
        if (key === 'Enter') handleTriggerSearch();
    };

    const searchHandlers: Record<number, () => Promise<void>> = {
        0: async () => {
            await dispatch(getClientTransactions());
        },
        2: async () => {
            const body = {
                clientId: clientProfile.clientProfile.clientId,
                searchContract: query.trim(),
            };
            dispatch(getEndeavours(token, body));
        },
        3: async () => {
            await dispatch(
                getClientPersonalCredit(token, {
                    clientId: clientProfile.idClient,
                    searchCredit: query.trim(),
                })
            );
        },
    };
    const resetHandlers: Record<number, () => Promise<void>> = {
        0: async () => {
            await dispatch(resetTrasactions());
        },
        2: async () => {
            await dispatch(getEndeavours(token, {clientId: clientProfile.clientProfile.clientId}));
        },
        3: async () => {
            await dispatch(getClientPersonalCredit(token, {clientId: clientProfile.idClient}));
        },
    };

    const handleTriggerSearch = async () => {
        if (query.trim().length < 3) return;
        dispatch(searchingOperations(true));

        const searchHandler = searchHandlers[placeHolder];
        if (searchHandler) {
            await searchHandler();
        }

        dispatch(searchingOperations(false));
        setSearchTriggered(true);
    };

    const resetSearch = async () => {
        setQuery('');
        if (searchTriggered) {
            dispatch(searchingOperations(true));

            const resetHandler = resetHandlers[placeHolder];
            if (resetHandler) {
                await resetHandler();
            }

            dispatch(searchingOperations(false));
            setSearchTriggered(false);
        }
    };

    const handleTabChange = (tab: string) => {
        const tabIndex = OPERATIONS.TABS_TITLE.indexOf(tab);
        setPlaceHolder(tabIndex);
        resetSearch();
    };

    return (
        <ContainerFlex {...style.contentGral}>
            <ContainerFlex Justify="space-between">
                <Text {...style.titles}>{OPERATIONS.TITLE}</Text>
                <SearchInput {...style.search}>
                    <input
                        type="text"
                        autoComplete="off"
                        placeholder={OPERATIONS.TABS_PLACEHOLDER[placeHolder]}
                        value={query}
                        onChange={handleInputChange}
                        onKeyUp={(e) => {
                            onTypeInputTransaction(e);
                        }}
                    />
                    {query && searchTriggered && (
                        <Image
                            Cursor="pointer"
                            src={Close}
                            onClick={() => {
                                resetSearch();
                            }}
                        />
                    )}
                    {!loading && !searchTriggered && (
                        <Image
                            Cursor="pointer"
                            src={Search}
                            onClick={() => {
                                handleTriggerSearch();
                            }}
                        />
                    )}
                </SearchInput>
            </ContainerFlex>
            <ContainerTabs
                tabs={OPERATIONS.TABS_TITLE}
                Gap="1rem"
                FlexDir="column"
                Align="center"
                Justify="start"
                onTabChange={handleTabChange}
            >
                <Transactions />
                {OPERATIONS.TABS_TITLE[2]}
                <EndeavorContracts />
                <PersonalCredits />
            </ContainerTabs>
        </ContainerFlex>
    );
};
