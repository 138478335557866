import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, ContainerForm, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    frequencyTableHeaders,
    PAYMENT_FREQUENCY,
    VALIDATIONS_MSG,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Table, TableRowHead} from '@components/CashFlow/FundingInquiry/styles';
import {TableItemsContainer} from '@/components/Shopify/Endeavors/styles';
import {IFrequencyPaymentTable} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {AppDispatch, RootState} from '@/config/store';
import {HeaderRow} from '@components/PersonalLoans/NewLoanProduct/PaymentFrequency/HeaderRow';
import {EditingButtonsForm} from '@components/PersonalLoans/NewLoanProduct/ui/EditingButtonsForm';
import {FrequencyTermEditingRow} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/EditingForm/FrequencyTermEditingRow';
import {saveNewLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaPaymentFrequency} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/schemaPaymentFrequency';
import {
    IPaymentFrequencyEditFormProps,
    IPaymentFrequencySubmit,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/paymentFrequencyInterfaces';
import {WarningError} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/WarningError';

export const PaymentFrequencyEditForm: React.FC<IPaymentFrequencyEditFormProps> = ({
    isEditing,
    setIsEditing,
    frequencyRef,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData);
    const frequencyData = newLoanData.details.frequencyTerm.sort(
        (a, b) => b.countPaymentMin - a.countPaymentMin
    );
    const [tableCopy, setTableCopy] = useState<IFrequencyPaymentTable[]>(frequencyData);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const {
        reset,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IPaymentFrequencySubmit>({
        resolver: yupResolver(schemaPaymentFrequency),
    });
    const [headers, setHeaders] = useState(frequencyTableHeaders);
    const errorMessage = errors.frequencyTerm?.message?.toString() || '';
    const handleToolTipVisibility = (id: number, isShowing: boolean) => {
        const newArray = headers.map((header) => {
            if (header.id === id) {
                return {
                    ...header,
                    isShowingToolTip: isShowing,
                };
            }
            return header;
        });
        setHeaders(newArray);
    };
    const onSubmit = (data: IPaymentFrequencySubmit) => {
        dispatch(
            saveNewLoanSubmittedData({
                ...newLoanData,
                details: {...newLoanData.details, frequencyTerm: data.frequencyTerm},
            })
        );
        setIsEditing(!isEditing);
    };
    const handleCheckboxTable = (id: number) => {
        const newArray = tableCopy.map((item) => {
            if (item.frecuancyId === id) {
                return {
                    ...item,
                    isChecked: !item.isChecked,
                };
            }
            return item;
        });
        setTableCopy(newArray);
    };
    useEffect(() => {
        reset({frequencyTerm: frequencyData});
    }, []);
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1.5rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={frequencyRef}
            >
                <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start" Justify="start">
                    <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {PAYMENT_FREQUENCY.TITLE}
                    </Text>
                    <Text Color="#2A2C2F">{PAYMENT_FREQUENCY.DESCRIPTION}</Text>
                </ContainerFlex>
                {errorMessage && (
                    <WarningError
                        errorMessage={
                            errorMessage.toString() || VALIDATIONS_MSG.PAYMENT_METHODS.AT_LEAST_ONE
                        }
                    />
                )}
                <ContainerForm
                    Gap="1.5rem"
                    FlexDir="column"
                    Align="start"
                    Justify="start"
                    Height="max-content"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <Table>
                        <TableRowHead
                            Height="48px"
                            GridColumn="200px 203px 203px 1fr"
                            Radius="0px"
                            Width="100%"
                            BackG="#fff"
                            BorderB="1px solid #E8E9EA"
                        >
                            {headers.map((item, index) => (
                                <HeaderRow
                                    key={index}
                                    index={index}
                                    {...item}
                                    handleToolTipVisibility={handleToolTipVisibility}
                                />
                            ))}
                        </TableRowHead>
                        <TableItemsContainer>
                            {tableCopy.map((item, index) => (
                                <FrequencyTermEditingRow
                                    key={index}
                                    index={index}
                                    control={control}
                                    tableLength={tableCopy.length}
                                    {...item}
                                    frequencyPaymentReducer={tableCopy}
                                    handleCheckboxTable={handleCheckboxTable}
                                />
                            ))}
                        </TableItemsContainer>
                    </Table>
                    <EditingButtonsForm
                        onCancel={() => setIsEditing(!isEditing)}
                        onContinue={() => setIsSubmitted(!isSubmitted)}
                    />
                </ContainerForm>
            </ContainerFlex>
        </>
    );
};
