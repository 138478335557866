import React from 'react';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';
import {IAccordionSummaryProps} from '@/components/SideBarMenu/Accordion/interfaces';

const AccordionSummary: React.FC<IAccordionSummaryProps> = ({
    expandedIcon,
    collapsedIcon,
    expanded,
    children,
    handlerExpanded,
    ...rest
}) => {
    const handleClick = () => {
        if (handlerExpanded) {
            handlerExpanded(!expanded);
        }
    };
    return (
        <ContainerFlex
            {...rest}
            Bl={expanded ? '3px solid #5A5AFF' : ''}
            backG={expanded ? '#F0F0FF' : ''}
        >
            {children}
            <Image
                Width="24px"
                Height="24px"
                alt="expand-collapse-icon"
                src={expanded ? expandedIcon : collapsedIcon}
                onClick={handleClick}
                Cursor="pointer"
            />
        </ContainerFlex>
    );
};

export default AccordionSummary;
