import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import ProgressBarComponent from '@components/Home/ProgressBarComponent';
import {ProgressBarProp} from '@components/PersonalLoans/interfaces';
import {PERSONALLOANS} from '@components/PersonalLoans/constants';

export const ProgressBarStep = ({dataStep}: ProgressBarProp) => {
    const currentStep = dataStep > PERSONALLOANS.FOUR ? PERSONALLOANS.FOUR : dataStep;
    const calculateProgress = (step: number) => {
        if (step === PERSONALLOANS.ONE) {
            return 0;
        }
        return ((step - 1) / 3) * 100;
    };
    return (
        <ContainerFlex Justify="start" Align="start" Gap="0.5rem" FlexDir="column">
            <Text Color="#2A2C2F" FontSize="1rem" FontWeight="700" oFlow="normal" wSpace="normal">
                {PERSONALLOANS.STEP} {currentStep} {PERSONALLOANS.FORSTEP}
            </Text>
            <ProgressBarComponent progress={calculateProgress(currentStep)} />
        </ContainerFlex>
    );
};
