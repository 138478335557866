import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import styled from '@emotion/styled';
import {IAccordionStyledProps} from '@/components/PersonalLoans/interfaces';

export const AccordionStyled = styled(Accordion)<IAccordionStyledProps>`
    width: ${(props) => props.Width || 'auto'};
    height: ${(props) => props.Height || 'auto'};
    background-color: ${(props) => props.backgroundColor || 'transparent'};
    border-radius: ${(props) => props.BorderRadius || '8px'};
    border: ${(props) =>
        props.BorderWidth
            ? `${props.BorderWidth} solid ${props.BorderColor || '#E8E9EA'}`
            : 'none'};
    box-shadow: none;
`;

export const AccordionSummaryStyled = styled(AccordionSummary)`
    padding: '8px 16px 16px 16px';
    margin: '0px';
    '& .muiaccordionsummary-content': {
        margin: '0px';
    }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
    padding: '8px 16px 16px 16px';
    margin: '0px';
    '& .muiaccordionsummary-content': {
        margin: '0px';
    }
`;
export const HiddenFileInputStyled = styled.input`
    display: none;
`;

export const ContainerFlexUpload = {
    Justify: 'center',
    Align: 'end',
    backG: '#FFFFFF',
    Padding: '0.5rem 0.625rem',
    Radius: '0.5rem',
    Gap: '1rem',
    FlexDir: 'column',
    MinH: '3rem',
};
export const ImageUpload = {
    Width: '12.75rem',
    Height: '7.875rem',
    Radius: '0.5rem',
    ObjectFit: 'cover',
    Cursor: 'pointer',
};
export const ImageError = {
    Color: '#A82424',
    FontSize: '0.75rem',
    Align: 'start',
    wSpace: 'normal',
    Height: 'fit-content',
    FontWeight: '400',
};
export const UploadRemplace = {
    Color: '#5A5AFF',
    FontSize: '0.875rem',
    FontWeight: '700',
    Cursor: 'pointer',
};

export const TextError = {
    Color: '#ef4f55',
    FontSize: '0.75rem',
    Align: 'start',
    wSpace: 'normal',
    Height: 'fit-content',
    FontWeight: '400',
};
export const TextUploadName = {
    FlexDir: 'column',
    Align: 'start',
    Width: '22rem',
    Gap: '0.25rem',
};
export const TextUploadRequire = {
    FlexDir: 'column',
    Align: 'start',
    Height: 'fit-content',
    Width: '7.375rem',
    Gap: '0.25rem',
};
export const UploadDrop = {
    Justify: 'start',
    FlexDir: 'column',
    backG: '#FFFFFF',
    Padding: '1rem 0.5rem',
    Gap: '5px',
    Cursor: 'pointer',
};
export const UploadSelect = {
    Color: '#5A5AFF',
    FontSize: '0.875rem',
    FontWeight: '700',
    Cursor: 'pointer',
};

export const ContainerUploadRef = {
    Width: 'fit-content',
    Heigh: 'fit-content',
    Gap: '0.25rem',
    Cursor: 'pointer',
};

export const ContainerBorderPayment = {
    Bb: 'solid 1px #E8E9EA',
    Height: '1px',
};
export const ContainerPayment = {
    BackG: 'none',
    Align: 'start',
    FlexDir: 'column',
    Justify: 'start',
    Height: 'auto',
    Gap: '1.5rem',
};
export const ContainerWallet = {
    Justify: 'start',
    Padding: '0.5rem 1rem',
    Gap: '0.5rem',
    Border: '1px solid #E8E9EA',
    Radius: '1rem',
};
export const ContainerTypePayment = {
    Justify: 'start',
    Padding: '0.5rem 1rem',
    Gap: '1rem',
    Border: '1px solid #E8E9EA',
    Radius: '1rem',
    FlexDir: 'column',
};
export const ContainerAdvise = {
    Justify: 'start',
    Align: 'start',
    TextAlign: 'start',
    FlexDir: 'column',
    Gap: '0.25rem',
};
export const ContainerPaymentForm = {
    Justify: 'start',
    Align: 'start',
    Padding: '1rem',
    Gap: '1rem',
    Border: '1px solid #E8E9EA',
    Radius: '1rem',
};
export const TextAdvise = {
    wSpace: 'normal',
    oFlow: 'normal',
    Color: '##2A2C2F',
    FontWeight: '700',
    FontSize: '0.875rem',
    Height: '3rem',
    Align: 'start',
};
export const TextAdviseDescription = {
    wSpace: 'normal',
    oFlow: 'normal',
    Color: '#54575C',
    FontSize: '0.875rem',
    Height: '3rem',
    Align: 'start',
};
export const ButtonBefore = {
    Height: '2.5rem',
    Padding: '0.5rem 1rem',
    Gap: '0.5rem',
    FontSize: '1rem',
    FontWeight: '700',
    Width: '12.313rem',
    SecondaryButton: '#FFFFFF',
    FillIcon: '#5A5AFF',
    Cursor: 'pointer',
};
export const ButtonValidate = {
    Height: '2.5rem',
    Padding: '0.5rem 1rem',
    Gap: '0.5rem',
    FontSize: '1rem',
    FontWeight: '700',
    Cursor: 'pointer',
};
export const IconWallet = {
    backG: '#F4F5F5',
    Radius: '3.75rem',
    Width: '2.75rem',
    Height: '2.75rem',
};
export const StepPaymentTypes = {
    Align: 'start',
    FlexDir: 'column',
    Justify: 'start',
    Gap: '0.25rem',
    Height: '2.75rem',
};
