import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ENDORSEMENT, PAYMENT} from '@components/HomePublicClient/constants';
import {useNavigate} from 'react-router-dom';

export const GlobalHeader = () => {
    const navigate = useNavigate();
    return (
        <ContainerFlex Justify="space-between" Height="40px" Padding="8px 16px">
            <Text
                Color="#0D166B"
                FontWeight="700"
                Cursor="pointer"
                onClick={() => navigate('/MyAccount')}
            >
                {ENDORSEMENT}
            </Text>
            <Text
                Color="#0D166B"
                FontWeight="700"
                Cursor="pointer"
                onClick={() => navigate('/QuickPayment')}
            >
                {PAYMENT}
            </Text>
        </ContainerFlex>
    );
};
