import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import editImg from '@images/editIcon.svg';
import deleteImg from '@images/delete _trash_red.svg';
import {SIDE_MENU} from '@components/MyCompanyUser/Operational/Constants';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {DeleteStatuUser} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/DeleteUserAction';
import {ISideMenu} from '@components/MyCompanyUser/interface';
import {
    SetSelectedEmployeeId,
    profileUser,
} from '@/components/MyCompanyUser/Redux/Actions/UserProfileAction';

const SideMenu = ({token, userId, fetchUsers}: ISideMenu) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleDelete = async () => {
        await dispatch(DeleteStatuUser(token, userId));
        fetchUsers();
    };

    const handleClick = async (userId: number) => {
        if (token) {
            await dispatch(SetSelectedEmployeeId(userId));
            await dispatch(profileUser(token, userId, navigate, true));
        }
    };
    return (
        <ContainerFlex
            Height="80px"
            Width="170px"
            //TODO: fix absolute
            Position="absolute"
            PositionTop="3rem"
            PositionRight="1rem"
            FlexDir="column"
            Border="1px solid #F3F3F3"
            Radius="8px"
            backG="#FFFF"
            Padding="10px"
            Align="start"
            ZIndex="2"
        >
            <ContainerFlex
                Gap="10px"
                Justify="start"
                onClick={() => handleClick(userId)}
                Cursor="pointer"
            >
                <Image src={editImg} Height="18px" Width="18px" alt="edit" />
                <Text Cursor="pointer">{SIDE_MENU.EDIT}</Text>
            </ContainerFlex>
            <ContainerFlex Gap="10px" Justify="start" onClick={handleDelete} Cursor="pointer">
                <Image src={deleteImg} Height="18px" Width="18px" alt="delete" />
                <Text Cursor="pointer">{SIDE_MENU.DELETE}</Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default SideMenu;
