import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {IconContainer} from '@/components/Quoter/styles';
import {Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {TableItem} from '@DeadLines/styles';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {SubLevels} from '@SubContainerOrgCreation/interface';
import {ContextualMenu} from '@TreeTableUtils/ContextualMenu';
import DisabledOrganization from '@SubContainerOrgCreation/DisabledOrganization';
import {saveNode} from '@ActionsOrgCreation/LevelTree';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {ITreeComponent, ITreeNode} from '@OrganizationCreation/interface';
import {upStatusOrg} from '@OrganizationCreation/Redux/Actions/UpdateStatusLevel';

export const TreeComponent = (data: ITreeComponent) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: SubLevels, index: number) => {
                    return (
                        <TreeNode
                            node={tree}
                            key={index}
                            setCurrentPage={data.setCurrentPage}
                            setLevelId={data.setLevelId}
                            levelId={data.levelId}
                            paginate={index}
                        />
                    );
                })}
        </>
    );
};
const TreeNode = (node: ITreeNode) => {
    const token = useSelector((state: RootState) =>
        useSelector((state: RootState) => state.getUsersValidation?.userData)
    );
    const LevelData = useSelector((state: RootState) => {
        return state.getAllLevels;
    });
    const [childVisible, setChildVisible] = useState(false);
    const [showModalDisabled, setShowModalDisabled] = useState(false);
    const [contextMenuVisibility, setContextMenuVisibility] = useState(false);

    const dispatch: AppDispatch = useDispatch();

    const DinamicPadding = (PaddingSize: number) => {
        switch (PaddingSize) {
            case 1:
                return '40px';
            case 2:
                return '64px';
            case 3:
                return '88px';
            case 4:
                return '112px';
            case 5:
                return '136px';
            case 6:
                return '136px';
        }
    };
    const hasChild = node.node.nextLevel ? true : false;

    const ChangesStatus = async () => {
        dispatch(
            upStatusOrg(String(token?.token), {
                levelPatherId: node.node.idFather,
                status: !node.node.status,
                companyId: Number(token?.enterpriceDetails?.[0]?.enterpriceId),
                levelId: node.node.idLevel,
            })
        );
        node.setCurrentPage(1);
        if (node.node.status) setShowModalDisabled(false);
    };

    const HandleStatus = () => {
        node.setLevelId(node.node.idLevel);
        switch (node.node.status) {
            case true:
                return setShowModalDisabled(true);
            case false:
                return ChangesStatus();
        }
    };
    const [levelId, setLevelId] = useState(0);

    const disabledLevels = () => {
        if (node.node.idLevel === node.levelId) {
            return LevelData.loading;
        }
    };
    useEffect(() => {
        if (node.node.nextLevel.length > 0 && node.node.level === 0) {
            setChildVisible(!childVisible);
        }
    }, []);
    return (
        <>
            <TableRowItems
                GridColumn="1.5fr 3.3fr 1.2fr .2fr"
                Cursor="auto"
                Height="48px"
                Width="100%"
            >
                <TableItem
                    Width={'16rem'}
                    PaddingLeft={node.node.level ? DinamicPadding(node.node.level) : '16px'}
                >
                    {hasChild && node.node.nextLevel?.length > 0 && (
                        <IconContainer
                            className="material-icons"
                            FontSize="1rem"
                            Color="#414042"
                            Justify="start"
                            Cursor="pointer"
                            onClick={() => {
                                setChildVisible(!childVisible);
                            }}
                        >
                            {hasChild && childVisible
                                ? 'keyboard_arrow_down'
                                : 'keyboard_arrow_right'}
                        </IconContainer>
                    )}
                    <TextEllipsis
                        FontWeight="500"
                        Width="auto"
                        FontSize="0.875rem"
                        Padding={node.node.nextLevel?.length > 0 ? '' : '0 0 0 24px'}
                        Color="#414042"
                        title={node.node.levelName}
                    >
                        {node.node.levelName}
                    </TextEllipsis>
                </TableItem>
                <TableItem
                    onClick={() => {
                        setChildVisible(!childVisible);
                    }}
                    Padding={node.node.level ? '0 0 0 16px' : '0 0 0 16px'}
                >
                    <Text FontWeight="400" FontSize="0.875rem" Color="#414042">
                        {node.node.levelDescription}
                    </Text>
                </TableItem>
                <TableItem Justify="center">
                    <GreenSwitch
                        checked={node.node.status}
                        size="small"
                        onChange={() => HandleStatus()}
                        disabled={disabledLevels()}
                    />
                </TableItem>
                <TableItem>
                    <IconContainer
                        className="material-icons"
                        FontSize="1.5rem"
                        Cursor="pointer"
                        onClick={() => {
                            setContextMenuVisibility(true);
                            setLevelId(node.node.idLevel);
                            dispatch(saveNode(node.node));
                        }}
                    >
                        more_vert
                    </IconContainer>
                    {contextMenuVisibility && node.node.idLevel === levelId && (
                        <ContextualMenu
                            contextMenuVisibility={contextMenuVisibility}
                            node={node.node}
                            levelId={levelId}
                            level={node.node.level}
                            setContextMenuVisibility={setContextMenuVisibility}
                            paginate={node.paginate}
                        />
                    )}
                </TableItem>
            </TableRowItems>
            {hasChild && childVisible && (
                <TreeComponent
                    data={node.node.nextLevel}
                    setCurrentPage={node.setCurrentPage}
                    setLevelId={node.setLevelId}
                    levelId={node.levelId}
                />
            )}
            <DisabledOrganization
                showModalDisabled={showModalDisabled}
                setShowModalDisabled={setShowModalDisabled}
                ChangesStatus={ChangesStatus}
            />
        </>
    );
};
