import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {
    DETAIL_CONTRACTS,
    DOWNLOAD_CONTRACT,
    SAVE_CONTRACT_IMG,
    SAVE_ID_PAWN,
} from '@Shopify/ClientProfile/Redux/types';
import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {IParamsDetailContract, IContracImg} from '@Shopify/ClientProfile/interfaces';

export const detailsContractStart = () => ({type: DETAIL_CONTRACTS.START});
export const detailsContractSuccess = (result: AxiosResponse) => ({
    type: DETAIL_CONTRACTS.SUCCESS,
    details: result.data,
});
export const detailsContractError = (error: AxiosError) => ({
    type: DETAIL_CONTRACTS.ERROR,
    error: error,
});
export const detailsContract = (token: string, params: IParamsDetailContract) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    const queryString = StringUtils.jsonToQueryString({...params});

    return async (dispatch: AppDispatch) => {
        dispatch(detailsContractStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetContractDetailsForProfile}${queryString}`,
                {headers}
            );
            dispatch(detailsContractSuccess(response.data));
        } catch (error) {
            dispatch(detailsContractError(error as AxiosError));
        }
    };
};

export const savePawnId = (id: number) => ({type: SAVE_ID_PAWN, pawnId: id});
export const saveContractsImg = (imagesContract: IContracImg[]) => ({
    type: SAVE_CONTRACT_IMG,
    imagesContract: imagesContract,
});

export const downloadContractStart = () => ({type: DOWNLOAD_CONTRACT.START_DATA});
export const downloadContractSuccess = (result: {message: AxiosResponse}) => ({
    type: DOWNLOAD_CONTRACT.GET_URL,
    downloadUrl: result.message,
});
export const downloadContractError = (error: AxiosError) => ({
    type: DOWNLOAD_CONTRACT.ERROR_LOAD,
    urlError: error,
});
export const downloadContracts = (token: string, pawnId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(downloadContractStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDownLoadPawnContract}?pawnId=${pawnId}`,
                {headers}
            );
            dispatch(downloadContractSuccess(response.data));
        } catch (error) {
            dispatch(downloadContractError(error as AxiosError));
        }
    };
};
