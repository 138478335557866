import React from 'react';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import Header from '@components/MyAccount/MyCredit/Header';
import CreditFilters from '@components/MyAccount/MyCredit/CreditFilters';
import CardsContainer from '@components/MyAccount/MyCredit/CardsContainer';
import {CARDS_CONTAINER_TITLE, CREDIT_TYPES} from '@components/MyAccount/constants';
import {ModalCartShopping} from '@/components/MyAccount/MyCredit/CreditDetails/ModalCartShopping';

const MyCredit = () => {
    const {creditType, personalCredits} = useSelector((state: RootState) => {
        return state.MyCreditReducer;
    });
    const {showModal} = useSelector((state: RootState) => state?.shoppingCarClient);

    const showJustPersonal =
        creditType === CREDIT_TYPES.PERSONAL || creditType === CREDIT_TYPES.ALL;
    const showJustPawns = creditType === CREDIT_TYPES.PAWNS;

    return (
        <>
            <Header />
            <CreditFilters />
            {showJustPersonal && (
                <CardsContainer
                    title={CARDS_CONTAINER_TITLE.PERSONAL}
                    type={CREDIT_TYPES.PERSONAL}
                    data={personalCredits}
                />
            )}
            {showJustPawns && (
                <CardsContainer title={CARDS_CONTAINER_TITLE.PAWNS} type={CREDIT_TYPES.PAWNS} />
            )}
            {showModal && <ModalCartShopping />}
        </>
    );
};

export default MyCredit;
