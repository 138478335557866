import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';

import {setCurrentSelectedLevelId} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import useLoadLevels from '@/hooks/useLoadLevels';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';

import {INHERIT_FROM} from '@components/LocationsBranch/constants';
import {IInheritAttributesProps} from '@components/LocationsBranch/interfaces';
import {InheritActionButtons} from '@components/LocationsBranch/InheritActionButtons';
import {LevelSelection} from '@components/LocationsBranch/Redux/interfaces';

import {
    GetCompanyLevelsByCompanyId,
    resetGroups,
} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';
import Locations from '@components/LocationsBranch/Locations';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

export const InheritAttributes: React.FC<IInheritAttributesProps> = ({
    type,
    setShowModal,
    onClickTransfer,
}) => {
    const dispatch = useDispatch();

    const {currentBranch, selectedLevelId, selectedOptionList, companyId, token} = useSelector(
        (state: RootState) => ({
            currentBranch: state.getCompanyLevels.data,
            selectedLevelId: state.getLevels.selectedLevelId,
            selectedOptionList: state.getCompanyLevels.selectedOptionList,
            token: String(state.getUsersValidation.userData?.token),
            companyId: Number(
                state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
            ),
        })
    );

    const {groupsFilling, levelGroups, currentSelection, handleGroupsSelection, handleResetGroup} =
        useLoadLevels(type, companyId, token);

    useEffect(() => {
        if (!currentSelection) {
            handleResetGroup();
            dispatch(resetGroups());
            dispatch(
                GetCompanyLevelsByCompanyId(companyId, token, 0, {
                    inheritType: type,
                    groupNumber: 1,
                })
            );
        } else {
            dispatch(resetGroups());
            handleGroupsSelection(currentSelection);
        }
    }, [companyId, token, selectedLevelId]);

    const handleOnSave = () => {
        const selectionBreadcrum: LevelSelection = {
            title: currentBranch.branchName,
            ids: [0],
            branch: selectedOptionList || null,
        };
        levelGroups.forEach((group) => {
            if (group.selectedLevelId !== 0) {
                const selectedLevel = group.levels?.find(
                    (level) => level.levelId === group.selectedLevelId
                );
                if (selectedLevel) {
                    selectionBreadcrum.title = selectionBreadcrum.title
                        ? `${selectionBreadcrum.title} > ${selectedLevel.levelName}`
                        : selectedLevel.levelName;
                    selectionBreadcrum.ids = [...selectionBreadcrum.ids, group.selectedLevelId];
                }
            }
        });
        const topselectedLevelId = levelGroups.reduce((highest, group) => {
            if (group.selectedLevelId !== 0) {
                return group.selectedLevelId;
            }
            return highest;
        }, 0);

        if (topselectedLevelId || selectedOptionList) {
            const groupName =
                topselectedLevelId &&
                levelGroups
                    .find((levels) => levels.selectedLevelId === topselectedLevelId)
                    ?.levels?.find((level) => level.levelId === topselectedLevelId)?.levelName;

            const transferSelection = {
                levelId: !topselectedLevelId ? currentBranch.levelId : topselectedLevelId,
                levelName: String(!groupName ? currentBranch.branchName : groupName),
                breadcrum: selectionBreadcrum,
            };
            dispatch(setCurrentSelectedLevelId(type, transferSelection));
            if (onClickTransfer) onClickTransfer(transferSelection);
        }
        setShowModal(false);
    };

    const handleOnCancel = () => {
        setShowModal(false);
        if (!currentSelection) {
            handleResetGroup();
            dispatch(resetGroups());
            dispatch(
                GetCompanyLevelsByCompanyId(companyId, token, 0, {
                    inheritType: type,
                    groupNumber: 1,
                })
            );
        }
    };

    return (
        <ContainerFlex Padding="1.5rem" FlexDir="column" Align="center" Gap="1.5rem" MaxW="62.5rem">
            <ContainerFlex
                FlexDir="column"
                Align="flex-start"
                Justify="flex-start"
                Gap="1.5rem"
                MaxH="900px"
                OverFlowY="scroll"
            >
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500" oFlow="visible">
                    {INHERIT_FROM.TITLE}
                </Text>
                <Text Color="#6D6E71" FontSize="0.75rem" FontWeight="500" oFlow="visible">
                    {INHERIT_FROM.PROMPT}
                </Text>
                {groupsFilling ? (
                    <ContainerFlex Justify="center">
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    <Locations inheritType={type} />
                )}
            </ContainerFlex>
            <InheritActionButtons onCancel={() => handleOnCancel()} onSave={() => handleOnSave()} />
        </ContainerFlex>
    );
};
