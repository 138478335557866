import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    PASSWORD_CONFIG,
    RESET_PASSWORD,
} from '@/components/UserProfileResetPassword/Operational/Constants';
import {RootState} from '@/config/store';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {useSelector} from 'react-redux';
import {PinUser} from './PinFormUser';
import {ResetPasswordForm} from './ResetPasswordForm';
export const ProfileUserResetPassword = () => {
    const [isEdit, setIsEdit] = useState(false);
    const isloading = useSelector((state: RootState) => state.AutoPassUserofAdmin.loading);

    const maskPassword = (password: string) => {
        return '*'.repeat(password.length);
    };

    if (isloading) {
        return (
            <ContainerFlex>
                <Loading />
            </ContainerFlex>
        );
    }

    return (
        <ContainerFlex FlexDir="column" Justify="start" Gap="16px" Height="max-content">
            <ContainerFlex
                FlexDir="column"
                Padding="16px"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Gap="16px"
                Radius="16px"
                Height=""
            >
                {!isEdit ? (
                    <>
                        <ContainerFlex Justify="space-between">
                            <Text FontWeight="600" FontSize="1.25rem" Color="#2A2C2F">
                                {RESET_PASSWORD.TITLE_TWO}
                            </Text>
                        </ContainerFlex>

                        <ContainerFlex Gap="16px">
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Gap="16px"
                            >
                                <Text FontWeight="700" Color="#2A2C2F">
                                    {RESET_PASSWORD.PASS}
                                </Text>
                                <Text FontWeight="700" Color="#2A2C2F">
                                    {maskPassword(PASSWORD_CONFIG.currentPassword)}
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Gap="16px"
                            >
                                <Text FontWeight="700" Color="#2A2C2F">
                                    {RESET_PASSWORD.EDIT_PASSWORD}
                                </Text>
                                <Text>{PASSWORD_CONFIG.lastChangeDate}</Text>
                            </ContainerFlex>
                            <ContainerFlex />
                        </ContainerFlex>
                    </>
                ) : (
                    <ResetPasswordForm setIsEdit={setIsEdit} />
                )}
            </ContainerFlex>
            <PinUser />
        </ContainerFlex>
    );
};
