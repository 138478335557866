import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AppDispatch, RootState} from '@/config/store';
import {createTermPersonalLoan} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/CreateTermPersonalLoanActions';
import {
    IChargesAndPenaltiesPost,
    IClientDocuments,
    IDisbursementsPost,
    IDocumentsPost,
    IFrecuencyTermPost,
    IGenderTermsPost,
    INewLoanSubmittedItem,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {IPaymentMethodState} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {
    getSelectedChargesAndPenalties,
    getSelectedDisbursements,
    getSelectedDocuments,
    getSelectedFrequencyTerms,
    getSelectedGenderTerms,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/helpers/getSelectedItems';
import {
    UpdateChargePenalty,
    UpdateDocument,
    UpdateTermPersonalLoanAction,
} from '@/components/LoansProducts/interfaces';
import {IChargeAndPenaltyItem} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {UpdateTermPersonalLoansForm} from '@/components/LoansProducts/Redux/Actions/UpdateTermPersonalLoan';

export const useSubmittedLoanData = () => {
    const navigate = useNavigate();
    const {details, companyId} = useSelector((state: RootState) => state.newLoanSubmittedData);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const dispatch: AppDispatch = useDispatch();
    const getDueDate = (): string => {
        if (details.dateDueEnd) {
            return typeof details.dateDueEnd === 'string' ? details.dateDueEnd : '';
        } else {
            return typeof details.dateDueInit === 'string' ? details.dateDueInit : '';
        }
    };
    const getAge = (age: string | null): number => (age ? 0 : Number(age));
    const isEditingLoan: boolean = useSelector((state: RootState) => state.setIsEditingLoanReducer);
    const onSubmit = () => {
        const dateDue: string = getDueDate();
        const frequencyTermsSelected: IFrecuencyTermPost[] = getSelectedFrequencyTerms(
            details.frequencyTerm
        );
        const selectedDocuments: IDocumentsPost[] = getSelectedDocuments(details.documents);

        const selectedTypePaymentsIns: IPaymentMethodState[] = details.typePaymentsIns.filter(
            (item) => item.isChecked
        );
        const selectedChargesAndPenalties: IChargesAndPenaltiesPost[] =
            getSelectedChargesAndPenalties(details.chargesPenalties);
        const selectedGenderTerms: IGenderTermsPost[] = getSelectedGenderTerms(details.genderTerms);
        const selectedDisbursements: IDisbursementsPost[] = getSelectedDisbursements(
            details.disbursements
        );
        const ageMin = getAge(details.ageMin);
        const ageMax = getAge(details.ageMax);
        const json: INewLoanSubmittedItem = {
            detail: {
                termName: details.termName,
                description: details.description,
                minAmount: details.minAmount,
                maxAmount: details.maxAmount,
                dateDue: dateDue,
                porcentAnualRate: details.porcentAnualRate,
                periodMin: details.periodMin.value,
                periodMax: details.periodMax.value,
                requestChannel: 1,
                countLimitLoans: details.countLimitLoans,
                frequencyTerm: frequencyTermsSelected,
                documents: selectedDocuments,
                typePaymentsIns: selectedTypePaymentsIns,
                chargesPenalties: selectedChargesAndPenalties,
                genderTerms: selectedGenderTerms,
                disbursements: selectedDisbursements,
                ageMin: ageMin,
                ageMax: ageMax,
                reviewCreditVoul: details.reviewCreditVoul,
                arrScoreTerm: JSON.stringify(details.creditScore),
            },
            companyId: companyId,
        };

        const chargesPenaltiesTransformed: UpdateChargePenalty[] = details.chargesPenalties.map(
            (item: IChargeAndPenaltyItem) => ({
                chargeName: item.chargeName,
                chargeAmount: item.chargeAmount.amount,
                typeChargeId: item.typeCharge.typeChargeId,
                typeChargeAmountId: item.chargeAmount.typeChargeAmountId,
                conditionId: item.condition.conditionId,
                eventTypeId: item.eventType.eventTypeId,
                isChecked: item.isChecked,
            })
        );
        const documentsTransformed: UpdateDocument[] = details.documents.map(
            (item: IClientDocuments) => ({
                documentId: item.documentId,
                seniorityJob: item.seniorityJob,
                isRequired: item.isRequired ?? false,
                isChecked: item.isChecked,
                docName: item.docName,
                docDescription: item.docDescription,
            })
        );
        const EditJson: UpdateTermPersonalLoanAction = {
            detail: {
                termId: Number(details.termId),
                termName: details.termName,
                description: details.description,
                minAmount: details.minAmount,
                maxAmount: details.maxAmount,
                dateDue: dateDue,
                porcentAnualRate: details.porcentAnualRate,
                periodMin: details.periodMin.value,
                periodMax: details.periodMax.value,
                ageMin: Number(details.ageMin),
                ageMax: Number(details.ageMax),
                reviewCreditVoul: details.reviewCreditVoul,
                countLimitLoans: details.countLimitLoans,
                requestChannel: 1,
                frequencyTerm: frequencyTermsSelected,
                documents: documentsTransformed,
                typePaymentsIns: details.typePaymentsIns,
                chargesPenalties: chargesPenaltiesTransformed,
                genderTerms: details.genderTerms,
                disbursements: selectedDisbursements,
                arrScoreTerm: JSON.stringify(details.creditScore),
            },
        };
        if (!token) return;
        if (isEditingLoan) {
            dispatch(UpdateTermPersonalLoansForm(EditJson, token, navigate));
        } else {
            dispatch(createTermPersonalLoan(token, json, navigate));
        }
    };
    return {
        onSubmit,
    };
};
