import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {FieldValues, useForm} from 'react-hook-form';
import {useSelector, useDispatch} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';

import {
    HOLIDAYS,
    INHERIT_TYPE,
    LAST_LOCATION,
    NEWBRANCHGROUP,
    holidaysHours,
    isOptional,
    scheduleHours,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UserGroup} from '@components/LocationsBranch/UserGroup';
import {getBranchByLevel} from '@components/LocationsBranch/Redux/Action/GetBranchByLevel';
import * as style from '@components/LocationsBranch/stylesLocations';
import {NameLocation} from '@components/LocationsBranch/NameLocation';
import {AddUserGroupModal} from '@components/LocationsBranch/AddUser';
import {SellChannels} from '@components/LocationsBranch/SellChannels';
import {BusinessLines} from '@components/LocationsBranch/BusinessLines';
import {RoutesLocations} from '@components/LocationsBranch/RoutesLocations';
import {LocationStorage} from '@components/LocationsBranch/LocationStorage';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {newBranch} from '@components/LocationsBranch/Redux/Action/NewBranch';
import {HolidaysSchedule} from '@components/LocationsBranch/HolidaysSchedule';
import {AddressLocations} from '@/components/LocationsBranch/AddressLocations';
import {updateBranch} from '@components/LocationsBranch/Redux/Action/UpdateBranch';
import {newBranchesShema} from '@components/LocationsBranch/createLocationsErrors';
import {
    IBranchHolidays,
    IBranchUsers,
    ICreateBranches,
    IFormBranch,
} from '@components/LocationsBranch/interfaces';
import {DelUserLocations} from '@components/LocationsBranch/DelUser/DelUsersLocations';
import {useInitializeForm} from '@components/LocationsBranch/utils/useFormInitialization';
import {
    IHolidaysHours,
    IOpeningHours,
    SelectedLevel,
    IInventories,
    UpdateBranchStorage,
} from '@components/LocationsBranch/Redux/interfaces';
import {getUsersByLocation} from './Redux/Action/GetUsersByLocationId';
import {getHolidaysLocation} from '@components/LocationsBranch/Redux/Action/GetHolidaysByLocationId';
import {getScheduleLocation} from '@components/LocationsBranch/Redux/Action/GetScheduleByLocationId';
import {getLocationValues} from '@components/LocationsBranch/utils/GetLocationValues';
import {fetchStorageByLocationId} from '@components/LocationsBranch/Redux/Action/GetStorageByLocationId';
import {routers} from '@/appRoute';
import {reverseTransformData} from '@components/LocationsBranch/utils/TransformStorageData';
import {catCompany, catPlaces} from '@components/SignUp/interfaces';
import {getUserIds} from '@components/LocationsBranch/utils/GetUserIds';
import {resetSchedule} from '@components/LocationsBranch/Redux/Action/GetSchedule';

export const NewBranchGroup = ({
    setShowHolidaysModal,
    setModalAddress,
    branchId,
}: ICreateBranches) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {initialize} = useInitializeForm();
    const [modal, setModal] = useState({addUser: false, locations: false});
    const isEditing = !!branchId;
    const {
        branch,
        token,
        addBranch,
        inventory,
        levels,
        editBranch,
        companyId,
        selectedLevelId,
        userList,
    } = useSelector((state: RootState) => ({
        branch: state.getCreateGroupBranch,
        token: String(state.getUsersValidation.userData?.token),
        companyId: Number(state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId),
        addBranch: state.newBranch,
        inventory: state.Warehouse.warehouses,
        levels: state.getLevels.level,
        selectedLevelId: state.getLevels.selectedLevelId,
        editBranch: state.getBranchByLevel,
        userList: state.GetUserByLevelId.allUserData,
    }));

    const {
        register,
        control,
        watch,
        setValue,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            nameBranch: '',
            branchDirection: {},
            branchBusinessLine: [],
            branchSalesChannel: [],
            users: [],
            openingHours: [],
            holidays: [],
            storages: [],
        },
        resolver: yupResolver(newBranchesShema(!!branchId)),
    });

    const parseToBranchStorage = (inventory: IInventories[]): UpdateBranchStorage[] => {
        return inventory.map((item) => ({
            id: item.storageId,
            nameStorage: item.storageName,
            description: item.storageDescrption,
            branchStorage: parseToBranchStorage(item.inventories),
        }));
    };

    const createBranch = (
        values: FieldValues,
        openingHours: IOpeningHours[],
        holidayHours: IHolidaysHours[]
    ) => {
        const branchUsers = getUserIds(userList);
        const json = {
            branchLevelId: levels && levels.levelId,
            nameBranch: values.nameBranch,
            branchDirection: values.branchDirection,
            branchBusinessLine: values.branchBusinessLine.reduce(
                (acc: number[], {status, id}: catCompany) => (status ? [...acc, id] : acc),
                []
            ),
            branchSalesChannel: values.branchSalesChannel.reduce(
                (acc: number[], {status, id}: catPlaces) => (status ? [...acc, id] : acc),
                []
            ),
            branchUsers,
            ...isOptional('branchOpeningHour', openingHours),
            ...isOptional('branchHoliday', holidayHours),
            ...isOptional('branchStorage', parseToBranchStorage(reverseTransformData(inventory))),
        };
        dispatch(newBranch(token, navigate, json));
    };

    const updateBranches = (
        values: FieldValues,
        openingHours: IOpeningHours[],
        holidayHours: IHolidaysHours[]
    ) => {
        if (!branchId) return;
        const branchUsersP = getUserIds(userList);
        const json = {
            branchId,
            nameBranch: values.nameBranch,
            branchDirectionP: values.branchDirection,
            branchUsersP,
            ...isOptional('branchOpeningHourP', openingHours),
            ...isOptional('branchHolidayP', holidayHours),
            ...isOptional('branchStorage', parseToBranchStorage(reverseTransformData(inventory))),
        };
        dispatch(updateBranch(token, cancelOperation, json));
    };

    const onsubmit = (values: FieldValues) => {
        const openingHours =
            values.openingHours && scheduleHours(Object.values(values.openingHours));
        const holidayHours = values.holidays && holidaysHours(Object.values(values.holidays));

        if (branchId) updateBranches(values, openingHours, holidayHours);
        else createBranch(values, openingHours, holidayHours);
    };

    const cancelOperation = () => {
        navigate(routers.LocationBranch);
    };

    const initializeBranch = () => {
        const {
            branchName = '',
            levelId = 0,
            description = '',
            branchDirection = null,
            userBranch = [],
            openingHourBranchs = [],
            holidaysBranchs = [],
            storagesBranchs = null,
        } = editBranch.branchByLevel;

        const source: IFormBranch = {
            name: branchName,
            levelId: levels && levels.levelId,
            description,
            address: branchDirection,
            openingHours: openingHourBranchs,
            holidays: holidaysBranchs,
            storagesDetail: storagesBranchs,
            users: userBranch,
            companyId: companyId,
            fatherId: levelId,
        };
        initialize(source, NEWBRANCHGROUP.NAME_FORM, setValue);
    };

    const onSetHolidaysSchedule = (
        holidays?: IBranchHolidays[],
        openingHours?: IOpeningHours[]
    ) => {
        const source: IFormBranch = {
            ...(holidays && {holidays}),
            ...(openingHours && {openingHours}),
        };
        initialize(source, '', setValue);
    };

    const onSetHolidays = (holidays: IBranchHolidays[]) => {
        if (!holidays) return;
        reset({...watch(), holidays: []});
        return onSetHolidaysSchedule(holidays);
    };

    const onSetSchedule = (schedule: IOpeningHours[]) => {
        if (!schedule) return;
        reset({...watch(), openingHours: []});
        return onSetHolidaysSchedule(undefined, schedule);
    };

    const onSetUsers = (users: IBranchUsers[]) => {
        const source: IFormBranch = {
            users,
        };
        initialize(source, '', setValue);
    };

    const onSetStorage = (storagesDetail: IInventories[]) => {
        if (storagesDetail) {
            const source: IFormBranch = {
                storagesDetail,
            };
            initialize(source, '', setValue);
        }
    };

    const onClickTransfer = async (transferSelection: SelectedLevel, type: string) => {
        const data = getLocationValues(transferSelection);
        const transferActions: Record<string, () => Promise<void>> = {
            [INHERIT_TYPE.SCHEDULE]: async () => {
                dispatch(resetSchedule());
                dispatch(getHolidaysLocation(data, token, onSetHolidays));
                dispatch(getScheduleLocation(data, token, onSetSchedule));
            },
            [INHERIT_TYPE.USER]: async () => {
                dispatch(getUsersByLocation(data, token, onSetUsers));
            },
            [INHERIT_TYPE.LOCATION]: async () => {
                await dispatch(fetchStorageByLocationId(data, token, onSetStorage));
            },
        };

        const action = transferActions[type];
        if (action) {
            await action();
        }
    };

    useEffect(() => {
        if (branchId)
            dispatch(
                getBranchByLevel(token, {
                    BranchId: branchId,
                    LevelId: selectedLevelId || levels.levelId,
                })
            );
    }, [branchId]);

    useEffect(() => {
        if (branchId && editBranch.branchByLevel) initializeBranch();
    }, [editBranch.branchByLevel]);

    return (
        <ContainerForm {...style.contentForm} onSubmit={handleSubmit(onsubmit)}>
            {branch.loading || editBranch.loading ? (
                <ContainerFlex>
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <>
                    <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700" oFlow="none">
                        {branchId ? editBranch.branchByLevel.branchName : NEWBRANCHGROUP.TITLE}
                    </Text>
                    <NameLocation
                        newName={addBranch}
                        nameLocation={NEWBRANCHGROUP.NAME_FORM}
                        register={register}
                        errors={errors}
                        watch={watch}
                        reset={reset}
                    />
                    <RoutesLocations lastLevel={LAST_LOCATION.NEW_BRANCH} />
                    <AddressLocations
                        errors={errors}
                        setValue={setValue}
                        nameAddress={NEWBRANCHGROUP.ADDRESS_FORM}
                        setModalAddress={setModalAddress}
                    />
                    {!branchId && (
                        <>
                            <BusinessLines
                                setValue={setValue}
                                errors={errors}
                                nameBusiness={NEWBRANCHGROUP.LINES}
                                register={register}
                            />
                            <SellChannels
                                setValue={setValue}
                                errors={errors}
                                nameBusiness={NEWBRANCHGROUP.CHANNELS}
                                register={register}
                            />
                        </>
                    )}
                    <UserGroup
                        modal={modal}
                        setModal={setModal}
                        setValue={setValue}
                        onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.USER)}
                    />
                    <HolidaysSchedule
                        register={register}
                        control={control}
                        errors={errors}
                        setShowHolidaysModal={setShowHolidaysModal}
                        watch={watch}
                        reset={reset}
                        setValue={setValue}
                        isOptional={false}
                        onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.SCHEDULE)}
                        isEdit={isEditing}
                    />
                    <LocationStorage
                        isOptional={false}
                        onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.LOCATION)}
                        isEdit={isEditing}
                        setValue={setValue}
                        errors={errors}
                    />
                    {addBranch.loading ? (
                        <ContainerFlex Height="2.5rem">
                            <LoadingAtaskate />
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Gap="1.5rem" Height="2.5rem">
                            <ButtonGeneral
                                width="6.25rem"
                                text={HOLIDAYS.CANCEL}
                                clic={() => cancelOperation()}
                                color="#5A5AFF"
                                backGround="#FFFFFF"
                                hText="#5A5AFF"
                                hShadow=""
                                height="2.5rem"
                                cursor="pointer"
                                border="1px solid #5A5AFF"
                                hBackG="#E5E5FF"
                            />
                            <ButtonGeneral
                                width="6.25rem"
                                text={HOLIDAYS.SAVE}
                                type="submit"
                                height="2.5rem"
                                cursor="pointer"
                            />
                        </ContainerFlex>
                    )}
                </>
            )}
            {modal.addUser && <AddUserGroupModal modal={modal} setModal={setModal} />}
            {modal.locations && <DelUserLocations modal={modal} setModal={setModal} />}
        </ContainerForm>
    );
};
