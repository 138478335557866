import {Image, SpeechBubble, Text} from '@components/Shopify/Ecommerce/styles';
import warning from '@images/roleTooltipWarning.svg';
import React from 'react';
import {rolTooltip} from '@components/RolesPermissions/constants';

const Tooltip = () => {
    return (
        <SpeechBubble afterLeft="20%" transformX="translateX(-16.5%);">
            <Text Color="#D8FF03" FontWeight="700">
                <Image src={warning} MarginRight="10px" alt="warning" />
                {rolTooltip.Title}
            </Text>
            <Text Color="white" FlexWrap="wrap" MarginTop="10px" FontSize="1.03rem">
                {rolTooltip.subTitle}
            </Text>
        </SpeechBubble>
    );
};

export default Tooltip;
