import {
    USER_PROFILE_REQUEST,
    USER_PROFILE_SOUCCES,
    USER_PROFILE_ERROR,
    SET_SELECTED_EMPLOYEE_ID,
} from '@components/MyCompanyUser/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {NavigateFunction} from 'react-router-dom';

export const GetUserProfileRequest = () => {
    return {
        type: USER_PROFILE_REQUEST,
    };
};
export const GetUserProfileSuccess = (userData: AxiosResponse) => {
    return {
        type: USER_PROFILE_SOUCCES,
        payload: userData,
    };
};
export const GetUserProfileFailure = (error: AxiosError) => {
    return {
        type: USER_PROFILE_ERROR,
        payload: error,
    };
};
export const SetSelectedEmployeeId = (employId: number) => {
    return {
        type: SET_SELECTED_EMPLOYEE_ID,
        payload: employId,
    };
};
export const profileUser = (
    token: string,
    employId: number,
    navigate: NavigateFunction,
    isNew: boolean
) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(GetUserProfileRequest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetEmployeeById.replace(
                    '{employeeId}',
                    String(employId)
                )}`,
                {headers}
            );
            dispatch(GetUserProfileSuccess(response));
            if (isNew) {
                navigate('/User/UserProfile');
            }
        } catch (error) {
            dispatch(GetUserProfileFailure(error as AxiosError));
        }
    };
};
