import {TableItemsContainer, TableRowItems} from '@/components/CashFlow/FundingInquiry/styles';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {
    deleteRoleMenu,
    disableMenu,
    MAX_NAME_LENGTH,
    roleTable,
} from '@/components/RolesPermissions/constants';
import DeleteRoleMenu from '@/components/RolesPermissions/DeleteRoleMenu';
import {IRoleItems, ITableProps} from '@/components/RolesPermissions/interfaces';
import SideMenu from '@/components/RolesPermissions/SideMenu';
import moreVertIcon from '@/images/more_vert.svg';
import Tooltip from '@components/RolesPermissions/Tooltip';
import {ContainerFlex, Image, Input, Text} from '@components/Shopify/Ecommerce/styles';
import ConfirmButton from '@images/CheckAuction.svg';
import warning from '@images/duplicateRoleWarning.svg';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import postDuplicateRoleAction from '@components/RolesPermissions/Redux/Actions/PostDuplicateRoleAction';
import putUpdateRoleStatusAction from '@components/RolesPermissions/Redux/Actions/PutUpdateRoleStatusAction';
import PutUpdateRoleAction from '@components/RolesPermissions/Redux/Actions/PutUpdateRoleAction';
import {useNavigate} from 'react-router-dom';
import {RoleToEditAction} from '@components/RolesPermissions/Redux/Actions/RoleToEditAction';
import {RootState} from '@/config/store';
import {IRoleModules} from './NewRole/interfaces';
import GetRolesAction from './Redux/Actions/GetRolesAction';
import {GetRoleByIdReset} from '@components/RolesPermissions/Redux/Actions/GetRoleByIdAction';

const RoleTable = ({
    roles,
    setRoles,
    token,
    searchRoleName,
    companyId,
    selectedFilter,
}: ITableProps) => {
    const dispatch = useDispatch();
    const [editableRoleId, setEditableRoleId] = useState<number | null>(null);
    const [sideMenuId, setSideMenuId] = useState<number>();
    const [showSideMenu, setShowMenu] = useState<boolean>(false);
    const [showDeleteMenu, setShowDeleteMenu] = useState<boolean>(false);
    const [showDisableMenu, setShowDisableMenu] = useState<boolean>(false);
    const [pendingToggleRoleId, setPendingToggleRoleId] = useState<IRoleItems | null>(null);
    const [editRoleName, setEditRoleName] = useState<string>('');
    const [roleToDelete, setRoleToDelete] = useState<number | null>(null);
    const [roleNameExists, setRoleNameExists] = useState<boolean>(false);
    const [hoveredId, setHoveredId] = useState<number | null>(null);

    const repeatedProps = {
        roles,
        setRoles,
        pendingToggleRoleId,
        setPendingToggleRoleId,
        roleToDelete,
        setRoleToDelete,
    };
    const handleSideMenu = (id: number) => {
        setSideMenuId(id);
        setShowMenu(!showSideMenu);
    };
    const navigate = useNavigate();
    const selectedRole = useSelector((state: RootState) => state.GetRoleById.role);

    const hasDuplicateAccessAndUserQuantity = (access: string, userQuantity: number) => {
        return (
            roles.filter(
                (role) => role.typeRoleName === access && role.quantityUsers === userQuantity
            ).length > 1
        );
    };

    const doesRoleNameExist = (name: string, excludeRoleId: number | null = null) => {
        return roles.some(
            (role) => role.name.toLowerCase() === name.toLowerCase() && role.id !== excludeRoleId
        );
    };

    const handleRoleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newName = e.target.value;
        setEditRoleName(newName);
        setRoleNameExists(doesRoleNameExist(newName, editableRoleId));
    };

    const handleSaveClick = async (id: number) => {
        if (!roleNameExists) {
            setEditableRoleId(null);

            setRoles((prevRoles) =>
                prevRoles.map((role) => (role.id === id ? {...role, role: editRoleName} : role))
            );

            await dispatch(
                PutUpdateRoleAction(
                    {
                        adminModules: selectedRole.modules.adminModules.map(
                            (module: IRoleModules) => module.moduleId
                        ),
                        operationalModules: selectedRole.modules.operationalModules.map(
                            (module: IRoleModules) => module.moduleId
                        ),
                        id,
                        name: editRoleName,
                        users: selectedRole.users,
                    },
                    navigate,
                    token
                )
            );
            if (companyId) {
                const data = {
                    searchRoleName: searchRoleName,
                    enterpriseId: companyId,
                    filterRoles: selectedFilter,
                };
                dispatch(GetRolesAction(data, token));
            }
        }
    };

    const handleDuplicateClick = (id: number) => {
        const roleToDuplicate = roles.find((role) => role.id === id);
        const newRoleName = roleToDuplicate ? `${roleToDuplicate.name} (copia)` : '';
        const roleNameExists = roleToDuplicate
            ? doesRoleNameExist(newRoleName, roleToDuplicate.id)
            : null;

        if (roleToDuplicate && !roleNameExists && newRoleName.length < MAX_NAME_LENGTH) {
            const newRole = {
                ...roleToDuplicate,
                id: Math.max(...roles.map((role) => role.id)) + 1,
                name: `${roleToDuplicate.name} (copia)`,
            };
            const roleIndex = roles.findIndex((role) => role.id === id);
            const updatedRoles = [...roles];
            updatedRoles.splice(roleIndex + 1, 0, newRole);
            setRoles(updatedRoles);
            const data = {
                roleName: `${roleToDuplicate.name} (copia)`,
                roleId: id,
            };
            dispatch(postDuplicateRoleAction(data, token));
        }
    };

    const handleSwitchClick = (item: IRoleItems) => {
        if (item.status) {
            setPendingToggleRoleId(item);
            setShowDisableMenu(true);
        } else {
            setRoles(
                roles.map((role) => (role.id === item.id ? {...role, status: !role.status} : role))
            );
            const data = {
                id: item.id,
                status: !item.status,
            };
            dispatch(putUpdateRoleStatusAction(data, token));
        }
    };

    const handleEditClick = (id: number, roleName: string) => {
        setEditableRoleId(id);
        setEditRoleName(roleName);
    };

    useEffect(() => {
        dispatch(RoleToEditAction(0));
        dispatch(GetRoleByIdReset());
    }, []);

    return (
        <TableItemsContainer Background="none" Width="100%" Height="auto">
            {roles.map((i: IRoleItems) => (
                <TableRowItems
                    key={i.id}
                    GridColumn="1fr 1fr 1fr .5fr"
                    Width="100%"
                    Border="none"
                    BorderTop="1px solid #E8E9EA"
                    Height="4.3rem"
                >
                    {editableRoleId === i.id ? (
                        <ContainerFlex FlexDir="column" Align="start" Gap="5px">
                            <Input
                                type="text"
                                value={editRoleName}
                                onChange={handleRoleNameChange}
                                Width="90%"
                                error={roleNameExists}
                                MarginLeft="1.3rem"
                            />
                            {roleNameExists && (
                                <Text Color="#A82424" FontSize="12px" MarginLeft="1.6rem">
                                    {roleTable.Error}{' '}
                                </Text>
                            )}
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Justify="start" Gap="15px">
                            <Text MarginLeft="25px">{i.name}</Text>
                            {hasDuplicateAccessAndUserQuantity(i.typeRoleName, i.quantityUsers) && (
                                <ContainerFlex Justify="start" Width="20px" Position="relative">
                                    <Image
                                        src={warning}
                                        Width="24px"
                                        onMouseEnter={() => setHoveredId(i.id)}
                                        onMouseLeave={() => setHoveredId(null)}
                                        Cursor="pointer"
                                        alt="warning"
                                    />
                                    {hoveredId === i.id && <Tooltip />}
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    )}
                    <Text>{i.typeRoleName}</Text>
                    <Text>{`${i.quantityUsers}\xa0 ${roleTable.UserQuantity}`}</Text>
                    {editableRoleId === i.id ? (
                        <ContainerFlex>
                            <Image
                                src={ConfirmButton}
                                onClick={() => handleSaveClick(i.id)}
                                Cursor="pointer"
                                alt="confirm"
                            />
                        </ContainerFlex>
                    ) : (
                        <ContainerFlex Justify="space-between" Position="relative" MarginL="1rem">
                            <GreenSwitch
                                checked={i.status}
                                onChange={() => handleSwitchClick(i)}
                                disabled={!i.canBeDeleted}
                            />

                            {i.canBeDeleted && (
                                <Image
                                    src={moreVertIcon}
                                    MarginRight="65px"
                                    Cursor="pointer"
                                    onClick={() => {
                                        handleSideMenu(i.id);
                                        setRoleToDelete(i.id);
                                        dispatch(RoleToEditAction(i.id));
                                    }}
                                    alt="sideMenu"
                                />
                            )}
                            {sideMenuId === i.id && showSideMenu && (
                                <SideMenu
                                    setShowSideMenu={() => setShowMenu(false)}
                                    setShowDeleteMenu={setShowDeleteMenu}
                                    handleEditClick={() => handleEditClick(i.id, i.name)}
                                    handleDuplicateClick={() => handleDuplicateClick(i.id)}
                                    id={i.id}
                                />
                            )}
                        </ContainerFlex>
                    )}
                    {showDeleteMenu && (
                        <DeleteRoleMenu
                            Title={deleteRoleMenu.title}
                            Texts={deleteRoleMenu.text}
                            setShowDeleteMenu={setShowDeleteMenu}
                            isDelete={true}
                            buttonText={deleteRoleMenu.buttonText}
                            {...repeatedProps}
                        />
                    )}
                    {showDisableMenu && (
                        <DeleteRoleMenu
                            Title={disableMenu(i.quantityUsers).title}
                            Texts={disableMenu(i.quantityUsers).text}
                            setShowDeleteMenu={setShowDisableMenu}
                            isDelete={false}
                            buttonText={disableMenu(i.quantityUsers).buttonText}
                            {...repeatedProps}
                        />
                    )}
                </TableRowItems>
            ))}
        </TableItemsContainer>
    );
};
export default RoleTable;
