import React from 'react';

import {MenuIcons} from '@Roles/styles';
import {Text} from '@Shopify/Ecommerce/styles';
import monetization from '@images/monetization.svg';
import {MenuItem} from '@General/MenuAmazon/styles';
import tableView from '@images/table_view_endeavors.svg';
import {homeClient, menuEndeavors} from '@/components/Shopify/Endeavors/redux/interfaces';
import {DropDownMenu} from '@General/MenuAmazon/ReutilizableComponent/FloatMenu';
import {endeavorsByCar} from '@Shopify/Endeavors/redux/actions/EndeavorsClientActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const ContextualMenuEndeavors = (props: menuEndeavors) => {
    const {handleClickAway, contextualMenu, endeavors, setModal} = props;
    const dispatch = useDispatch();

    const cardShooping = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.carShoopingId as number;
    });
    const homeclient: homeClient = useSelector((state: RootState) => {
        return state.homeclient;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const endorsmentEndeavor = (typeOperation: number) => {
        handleClickAway();
        setModal({
            type: typeOperation === 1 ? 'endorsment' : 'endeavor',
            item: endeavors,
            flag: true,
        });
        dispatch(
            endeavorsByCar(token, {
                carShoppingId: cardShooping,
                endorementDischarge: [
                    {
                        typeMovement: typeOperation,
                        clientId: homeclient.infoclient?.clientId,
                        branchId: endeavors.branchId,
                        branchName: endeavors.branchName,
                        companyId: endeavors.companyId,
                        pawnId: endeavors.pawnId,
                        companyName: endeavors.companyName,
                        folioContract: endeavors.folioContract,
                        nameStatusContract: endeavors.nameStatusContract,
                        endorsementValue: endeavors.endorsementValue,
                        dischargeValue: endeavors.dischargeValue,
                        moratoriumValue: endeavors.moratorium,
                        amountInterest: endeavors.interest,
                        paymentAmount:
                            typeOperation === 1
                                ? endeavors.endorsementValue
                                : endeavors.dischargeValue,
                        iva: endeavors.iva,
                        ivaDiscount: endeavors.ivaDiscount,
                        maximumInterest: endeavors.maximumInterest,
                        hiddenTerm: endeavors.hiddenTerm,
                        netInterest: endeavors.netInterest,
                        mutualBalance: endeavors.mutualBalance,
                        deadlineDate: endeavors.deadlineDate,
                        grossPay:
                            typeOperation === 1
                                ? endeavors.endorsementValue
                                : endeavors.dischargeValue,
                        moratoriumAmount: endeavors.moratorium,
                        amountStorage: endeavors.storage,
                        endorsementNumber: endeavors.endorsementNumber,
                        pledgeNumber: endeavors.pledgeNumber,
                        insuranceAmount: endeavors.insurance,
                        amountDiscountInterest: endeavors.interestDiscount,
                        expirationDate: endeavors.expirationDate,
                        urlContract: endeavors.urlContract,
                        urlFlag: endeavors.urlFlag,
                        urlQR: endeavors.urlQR,
                        amountDeductible: 0,
                        amountReposition: 0,
                        amountRepositionIVA: 0,
                        notes: '',
                        isCanceled: false,
                    },
                ],
            })
        );
    };

    return (
        <DropDownMenu
            OutSideClick={handleClickAway}
            MenuDisplayStatus={contextualMenu}
            Position="absolute"
            Height="80px"
            Top={endeavors.pawnId <= 3 ? '0' : '3rem'}
            Bottom="0"
            Width="11.563rem"
            Values={[]}
            Right="0.5rem"
            Valued={
                <>
                    <MenuItem
                        HBackColor="#efefefe0"
                        onClick={() => endorsmentEndeavor(1)}
                        Cursor="pointer"
                    >
                        <MenuIcons
                            className="material-icons"
                            Width="1.344rem"
                            Height="0.938rem"
                            src={tableView}
                        />
                        <Text FontSize="0.875rem" Color="#414042" Cursor="span">
                            Refrendo
                        </Text>
                    </MenuItem>
                    <MenuItem
                        HBackColor="#efefefe0"
                        onClick={() => endorsmentEndeavor(2)}
                        Cursor="pointer"
                    >
                        <MenuIcons
                            className="material-icons"
                            Width="1.344rem"
                            Height="0.938rem"
                            src={monetization}
                        />
                        <Text FontSize="0.875rem" Color="#414042" Cursor="span">
                            Desempeño
                        </Text>
                    </MenuItem>
                </>
            }
        />
    );
};
