import React from 'react';

import {IFormFieldDatePickerProps} from '@General/Atoms/FormDatePickSelect/interfaces';
import {monthsString as months} from '@General/Atoms/CalendarDateSelection/constants';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {DATE_FORMATS} from '@components/Shopify/ListClient/constants';
import SelectGeneral from '@/components/General/Atoms/Select/Select';

const FormDatePickeSelect: React.FC<IFormFieldDatePickerProps> = ({
    name,
    placeholder,
    format,
    isDate,
    error,
    outputType = 'string',
    value,
    onChange,
    yearsRange = 11,
    pastYears = 0,
}) => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({length: yearsRange}, (_, i) => ({
        value: currentYear - pastYears + i,
        label: (currentYear - pastYears + i).toString(),
    }));

    const getOption = (
        options: {value: number | string; label: string}[],
        value: string | number
    ) => {
        return (
            options.find((option) => {
                if (outputType === 'number') {
                    return Number(option.value) === Number(value);
                }
                return option.value.toString() === value.toString();
            }) || null
        );
    };

    const safeValue = value ?? '';

    return (
        <ContainerFlex FlexDir="column" Align="flex-start" Gap="0.25rem" Flex="1 0 0">
            <ContainerFlex Justify="center" Align="center">
                <>
                    {format === DATE_FORMATS.YEAR && (
                        <SelectGeneral
                            value={getOption(years, safeValue) || null}
                            options={years}
                            onChange={(selectedOption) => {
                                const newValue =
                                    outputType === 'number'
                                        ? selectedOption?.value
                                        : selectedOption?.value.toString();
                                if (newValue) onChange(newValue);
                            }}
                            isDate={isDate}
                            placeholder={placeholder}
                            hasError={!!error}
                        />
                    )}
                    {format === DATE_FORMATS.MONTH && (
                        <SelectGeneral
                            value={getOption(months, safeValue) || null}
                            options={months}
                            onChange={(selectedOption) => {
                                const newValue =
                                    outputType === 'number'
                                        ? selectedOption?.value
                                        : selectedOption?.value.toString();
                                if (newValue) onChange(newValue);
                            }}
                            placeholder={placeholder}
                            hasError={!!error}
                        />
                    )}
                </>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default FormDatePickeSelect;
