import React, {ChangeEvent, useState, useEffect, useRef} from 'react';
import {Controller, FieldErrors} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {PROOF_LABELS, FORM_ERRORS} from '@components/Shopify/ListClient/constants';
import {getIdentificationTypesList} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {ProofType} from '@components/Shopify/ListClient/interfaces';

import {AppDispatch, RootState} from '@config/store';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import * as content from '@components/CreateUsers/Styles';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import Refresh from '@images/icon-refresh.svg';
import uploadIcon from '@images/UploadFile.svg';
import warningIcon from '@images/warning-red.svg';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {validateProofAddressClient} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import {IValidateProofAddress} from '@components/Shopify/ListClient/redux/interfaces';
import {ContainerFlex, Text, ContainerCellGrid, Image} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';

const getImageError = (
    errorMessage: string,
    errors: FieldErrors,
    imageSrc: string | null,
    hasSelected: boolean
): string => {
    if (errorMessage && hasSelected) {
        return errorMessage;
    }
    if (errors.imageProof) {
        return errors.imageProof.message as string;
    }
    return '';
};

const ClientProof = (props: IClientProofStep) => {
    const dispatch: AppDispatch = useDispatch();
    const {token, errors, control, setValue, isPopup} = props;
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [selectedImage, setSelectedImage] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [imageFileName, setImageFileName] = useState('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const proofTypes: ProofType[] | null = useSelector(
        (state: RootState) => state.newClientProcess.proofTypes
    );
    const domiciliaryDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.domiciliaryProof
    );
    const loading: boolean = useSelector((state: RootState) => state.newClientProcess.loading);
    let formattedProofTypes: {value: number; label: string}[] = [];

    if (proofTypes) {
        formattedProofTypes = proofTypes.map((proofType: ProofType) => ({
            value: proofType.id,
            label: proofType.description,
        }));
    }

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };

    useEffect(() => {
        dispatch(getIdentificationTypesList(token));
    }, [dispatch, token]);

    const clearSelection = () => {
        setImageSrc(null);
        setImageFileName('');
        setValue('imageProof', '');
        setValue('imageFileExtension', '');
    };

    const imgAdd = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(true);
            const file = e.target.files[0];
            const maxSizeMB = 25;
            const maxSizeBytes = maxSizeMB * 1024 * 1024;

            if (file.size > maxSizeBytes) {
                setErrorMessage(`Archivo no debe de exeder ${maxSizeMB} MB`);
                setSelectedImage(true);
                clearSelection();
                return;
            }

            setSelectedImage(true);
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            if (!fileExtension || !['jpg', 'png'].includes(fileExtension)) {
                setErrorMessage(FORM_ERRORS.MEDIA_TYPE_ERROR);
                clearSelection();
                return;
            }
            setErrorMessage('');
            setImageFileName(file.name);

            const readFileAsDataURL = (file: File): Promise<string> => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = (error) => reject(error);
                });
            };

            try {
                const fileContent = await readFileAsDataURL(file);
                const base64Content = fileContent.split(',')[1];

                const imageData: IValidateProofAddress = {
                    fileExtension: fileExtension,
                    fileContent: base64Content,
                };

                await dispatch(validateProofAddressClient(imageData, token));
                setImageSrc(fileContent);
                setValue('imageProof', base64Content);
                setValue('imageFileExtension', fileExtension);
            } catch (error) {
                setErrorMessage(FORM_ERRORS.MEDIA_TYPE_ERROR);
                clearSelection();
            }
        }
    };

    useEffect(() => {
        if (isPopup && domiciliaryDocs && domiciliaryDocs?.length > 0) {
            const proofDoc = domiciliaryDocs[domiciliaryDocs.length - 1];
            const matchingProofType = formattedProofTypes.find(
                (proofType) => proofType.value === proofDoc.typeOfReceiptId
            );
            const fileExtension = proofDoc.urlImagen.split('.').pop()?.toLowerCase();
            setValue('typeOfReceiptId', matchingProofType);
            setValue('effectiveDate', proofDoc.validityDate);
            setValue('imageFileExtension', fileExtension);

            setImageSrc(proofDoc.urlImagen);
            setImageFileName(proofDoc.name);
        }
    }, [isPopup, domiciliaryDocs, formattedProofTypes]);

    const currentError = getImageError(errorMessage, errors, imageSrc, selectedImage);

    return (
        <ContainerFlex
            FlexDir="column"
            Bb={!isPopup ? '1px solid #E8E9EA' : 'transparent'}
            Align="start"
        >
            {!isPopup && (
                <>
                    <Text FontWeight="500" FontSize="1.2rem" Color="#2a2c2f">
                        {PROOF_LABELS.ADD_PROOF}
                    </Text>
                    {loading && (
                        <ContainerFlex Position="fixed" backG="#ffffff87" ZIndex="1">
                            <LoadingAtaskate width="3.75rem" padding="0.938rem" />
                        </ContainerFlex>
                    )}
                </>
            )}
            <ContainerFlex
                Padding="0.75rem 1rem 1rem"
                Gap="1.5rem"
                Radius="1rem"
                Align="flex-start"
                Justify="flex-start"
            >
                <ContainerFlex
                    Justify="flex-start"
                    Align="start"
                    FlexDir="column"
                    Gap="0.8rem"
                    Width="19.2rem"
                >
                    <FileUpLoad
                        accept=".jpg,.png"
                        type="file"
                        onChange={imgAdd}
                        id="file"
                        ref={fileInputRef}
                    />
                    <FileUpLoadContent
                        {...content.contentImg}
                        Width="17.875rem"
                        Height="9.063rem"
                        RABorder="0.875rem"
                        Display="flex"
                        Align="center"
                        FDirection="column"
                        Border={!imageSrc ? '1px dashed #5A5AFF' : '1px dashed #d4d6d8;'}
                        Padding="0.625rem"
                    >
                        {imageSrc ? (
                            <>
                                <Image
                                    alt="image-proof"
                                    src={imageSrc}
                                    {...content.contentImg.imgStart}
                                    Height="100%"
                                    Width="100%"
                                    ObjectFit="contain"
                                    Radius="0"
                                    Margin="0"
                                    Cursor={imageSrc ? 'pointer' : 'default'}
                                />
                            </>
                        ) : (
                            <ContainerFlex
                                Justify="center"
                                Align="center"
                                FlexDir="column"
                                Gap="0.938rem"
                                Width="9.375rem"
                            >
                                <Text
                                    Color="#5A5AFF"
                                    FontWeight="700"
                                    FontSize="1rem"
                                    Cursor="pointer"
                                    Gap="0.5rem"
                                    LetterSpacing="0.019rem"
                                >
                                    <Image
                                        alt="upload"
                                        src={uploadIcon}
                                        Width="1.5rem"
                                        Height="1.5rem"
                                        ObjectFit="cover"
                                        Cursor="inherit"
                                    />
                                    {PROOF_LABELS.SELECT_IMAGE}
                                </Text>
                                <Text
                                    Color="#5A5AFF"
                                    FontWeight="700"
                                    FontSize="1rem"
                                    Cursor="pointer"
                                    LetterSpacing="0.019rem"
                                >
                                    {PROOF_LABELS.IMAGE_SIZE}
                                </Text>
                            </ContainerFlex>
                        )}
                    </FileUpLoadContent>
                    {imageSrc && (
                        <ButtonGenerals
                            type="button"
                            Height="1.875rem"
                            Self="center"
                            Padding="0.125rem 0.5rem"
                            Gap="0.25rem"
                            BackGC="#e8e9ea"
                            Cursor="pointer"
                            Width="17rem"
                            HBackG="#999a9c"
                            onClick={handleButtonClick}
                        >
                            <Text
                                FontWeight="500"
                                FontSize="1rem"
                                Color="#2a2c2f"
                                oFlow="hidden"
                                tOver="ellipsis"
                                wSpace="nowrap"
                                Cursor="pointer"
                            >
                                {imageFileName}
                            </Text>
                            <Image src={Refresh} alt="add-client" Width="24px" Height="24px" />
                        </ButtonGenerals>
                    )}
                    {currentError && (
                        <Text Color="#FF6357" FontWeight="400" FontSize="0.875rem" wSpace="normal">
                            {currentError}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex
                    Justify="flex-start"
                    Align="start"
                    FlexDir="column"
                    Gap="1.5rem"
                    Width={isPopup ? '100%' : '65%'}
                >
                    <ContainerCellGrid
                        AlignContent="start"
                        FlexDir="column"
                        Height="80px"
                        Gap="0.25rem"
                        Width={isPopup ? '100%' : '70%'}
                    >
                        {errorMessage === FORM_ERRORS.PROOF_TYPE && (
                            <Text Color="#FF6357" FontSize=".875rem" wSpace="normal">
                                <Image
                                    alt="warning"
                                    src={warningIcon}
                                    Padding="0 0.25rem"
                                    Width="1rem"
                                    Height="1rem"
                                    ObjectFit="cover"
                                />
                                {errorMessage}
                            </Text>
                        )}
                        <Text
                            Color="#1D1E20"
                            FontSize="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                            oFlow="visible"
                        >
                            {PROOF_LABELS.TYPE_OF_PROOF_OF_ADDRESS}
                        </Text>

                        <Controller
                            name="typeOfReceiptId"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    background="#FFF"
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    hasError={!!errors.typeOfReceiptId?.message}
                                    options={formattedProofTypes}
                                    isLoading={!formattedProofTypes}
                                    onChange={(selectedOption) => {
                                        setValue('typeOfReceiptId', selectedOption);
                                        onChange(selectedOption);
                                    }}
                                />
                            )}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors.typeOfReceiptId?.message &&
                                String(errors.typeOfReceiptId?.message)}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid
                        AlignContent="start"
                        FlexDir="column"
                        Height="5rem"
                        Gap="0.25rem"
                        Width={isPopup ? '100%' : '70%'}
                    >
                        <Text
                            Color="#1D1E20"
                            FontSize="1rem"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                            oFlow="visible"
                        >
                            {PROOF_LABELS.EFFECTIVE_DATE}
                        </Text>
                        <Controller
                            name="effectiveDate"
                            control={control}
                            render={({field: {onChange, value, ...field}}) => (
                                <CalendarDateSelection
                                    {...field}
                                    placeholder="dd/mm/yyyy"
                                    format="dd/MM/yyyy"
                                    error={!!errors.effectiveDate?.message}
                                    limitDate={false}
                                    value={value ? new Date(value) : null}
                                    inputGridCols="90% 10%"
                                    onSelect={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors.effectiveDate?.message && String(errors.effectiveDate?.message)}
                        </Text>
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default ClientProof;
