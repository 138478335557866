import React, {useState} from 'react';
import {RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, FormProvider} from 'react-hook-form';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_ADDRESS} from '@MyCompany/constants';
import {formatDate} from '@/hooks/currentFormatUtils';
import {ISelectReact} from '@/components/Quoter/inferfaces';
import {convertDate} from '@Shopify/ClientProfile/constants';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {HolidaysForm} from '@components/LocationsBranch/Holidays/HolidayForm';
import {addHoliday} from '@components/LocationsBranch/Redux/Action/GetHolidays';
import {holidaysShema} from '@components/LocationsBranch/Holidays/holidayErrors';
import {defaultHourHoliday, HOLIDAYS} from '@components/LocationsBranch/constants';
import {IHolidayFields, IHolidaysAdd} from '@components/LocationsBranch/interfaces';

export const HolidaysAdd = ({
    showHolidaysModal,
    setShowHolidaysModal,
    handleClickAction,
}: IHolidaysAdd) => {
    const methods = useForm();
    const dispatch = useDispatch();
    const [openingClose, setOpeningClose] = useState(false);
    const {holidays, level} = useSelector((state: RootState) => ({
        level: state.getLevels.level,
        holidays: state.getCreateGroupBranch.holidays,
    }));

    const {
        handleSubmit,
        register,
        control,
        reset,
        watch,
        formState: {errors},
    } = useForm<IHolidayFields>({
        defaultValues: {
            holiday: '',
            eachYears: true,
            openingClose: openingClose,
            opening: {},
            ending: {},
        },
        resolver: yupResolver(holidaysShema(openingClose)),
    });

    const cancelOperation = () => {
        setShowHolidaysModal(false);
        reset();
    };

    const dateOpening = (opening: ISelectReact) => {
        const openingTime = opening.label;
        const dateHoliday = new Date();
        const [hours, minutes] = openingTime.split(':');
        dateHoliday.setHours(Number(hours), Number(minutes), 0, 0);
        return dateHoliday.toISOString();
    };

    const onSubmit = async (values: IHolidayFields) => {
        const lastHoliday = holidays && holidays[holidays.length - 1];

        const newHoliday = {
            id: lastHoliday ? lastHoliday.id + 1 : 1,
            date: values.opening.label ? dateOpening(values.opening) : defaultHourHoliday,
            endTime: values.ending.label ? values.ending.label : defaultHourHoliday,
            halfDay: true,
            reason: formatDate(convertDate(values.holiday)),
            levelId: level.levelId,
            status: true,
        };

        await dispatch(addHoliday([...holidays, newHoliday]));
        await handleClickAction();
        cancelOperation();
    };

    return (
        <Modal
            Top="0"
            modalState={showHolidaysModal}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <FormProvider {...methods}>
                <ContainerForm
                    onSubmit={handleSubmit(onSubmit)}
                    FlexDir="column"
                    Width="25rem"
                    Height="100vh"
                    Align="start"
                    Justify="start"
                    Padding="1.5rem"
                    Gap="1.5rem"
                >
                    <Text FontSize="1.5rem" FontWeight="700" Color="#2A2C2F">
                        {HOLIDAYS.TITLE}
                    </Text>
                    <HolidaysForm
                        register={register}
                        reset={reset}
                        watch={watch}
                        control={control}
                        errors={errors}
                        openingClose={openingClose}
                        setOpeningClose={setOpeningClose}
                    />
                    <ContainerFlex Height="auto" Gap="1.5rem">
                        <ButtonGeneral
                            width="100%"
                            text={UPDATE_ADDRESS.CANCEL}
                            clic={() => cancelOperation()}
                            color="#5A5AFF"
                            backGround="#FFFFFF"
                            hText="#5A5AFF"
                            hShadow=""
                            height="40px"
                            cursor="pointer"
                            border="1px solid #5A5AFF"
                            hBackG="#E5E5FF"
                        />
                        <ButtonGeneral width="100%" text={UPDATE_ADDRESS.SAVE} />
                    </ContainerFlex>
                </ContainerForm>
            </FormProvider>
        </Modal>
    );
};
