import {Controller} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime} from 'luxon';
import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import {
    CalendarFieldComponent,
    CalendarIconContainer,
    DatePickerCustom,
    InputCalendarField,
} from '@components/CreateUsers/DatePiker/datePickerStyles';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';
import errorIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/errorIcon.svg';
import {IDatePickerVigencyProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';

export const DatePickerVigency: React.FC<IDatePickerVigencyProps> = ({
    errors,
    control,
    setValue,
}) => {
    return (
        <>
            <ContainerFlex FlexDir="column" Gap="0.5rem" Align="start" Justify="start" Width="100%">
                <Text Color="#1D1E20" FontWeight="700" Width="100%">
                    {PAYMENT_METHOD.FIELDS.CARD_VIGENCY}
                </Text>
                <Controller
                    name={'vigency'}
                    control={control}
                    render={({field: {onChange, ref, value, ...field}}) => (
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePickerCustom
                                views={['month', 'year']}
                                mask="__/____"
                                {...field}
                                inputFormat="MM/yyyy"
                                value={value}
                                openTo="month"
                                onChange={(e) => {
                                    onChange(e);
                                    setValue('vigency', e as DateTime);
                                }}
                                minDate={
                                    new Date(
                                        new Date().getFullYear(),
                                        new Date().getMonth(),
                                        new Date().getDate() + 0
                                    )
                                }
                                disableMaskedInput={false}
                                components={{OpenPickerIcon: DateIcon}}
                                renderInput={({inputRef, inputProps, InputProps}) => (
                                    <CalendarFieldComponent
                                        error={errors.vigency?.message ? true : false}
                                    >
                                        <InputCalendarField
                                            Width="100%"
                                            ref={inputRef}
                                            {...inputProps}
                                            FontSize="1rem"
                                            placeholder="   /"
                                        />
                                        <CalendarIconContainer>
                                            {InputProps?.endAdornment}
                                        </CalendarIconContainer>
                                    </CalendarFieldComponent>
                                )}
                            />
                        </LocalizationProvider>
                    )}
                />
                {errors.vigency && (
                    <Text FontSize="0.875rem" Color="#FF6357" Gap="0.25rem">
                        <Image src={errorIcon} Width="16px" Height="16px" />
                        {errors.vigency?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};

export const DateIcon = () => {
    return (
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            calendar_today
        </Icons>
    );
};
