import {
    SEARCH_LEVEL_TREE_REQUEST,
    SEARCH_LEVEL_TREE_SOUCCES,
    SEARCH_LEVEL_TREE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};
export const SearchLevelBranchUser = (state = initialState, action) => {
    switch (action.type) {
        case SEARCH_LEVEL_TREE_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                message: '',
                userData: null,
            };
        case SEARCH_LEVEL_TREE_SOUCCES:
            return {
                ...state,
                loading: false,
                userData: action.payload.data,
                error: null,
                message: '',
            };
        case SEARCH_LEVEL_TREE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload?.response?.data,
                userData: null,
            };
        default:
            return state;
    }
};
