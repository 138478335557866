import {
    IStyleMessageSetup,
    IStyleRowSetup,
    ITableFilterTabs,
} from '@/components/CreditsAndPawns/interfaces';
import alertIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/alertIcon.svg';
import infoIconBlue from '@/components/CreditsAndPawns/icons/infoIconBlue.svg';

export const CREDITSANDPAWNS = {
    TITLE: 'Créditos y empeños',
    DESCRIPTION: 'Revisa las solicitudes, los créditos y los empeños.',
    INPUT_PLACEHOLDER: 'Busca un cliente o No. solicitud',
};

export const TAB_HEADER_TEXT = {
    CREDITS: 'Créditos',
    PAWNS: 'Empeños',
};

export const TAB_TITLES = {
    TOTAL: 'Disponible total',
    BRANCH: 'Disponible en sucursal',
    CREDITS: 'Invertido en créditos',
    APPROVED_CREDITS: 'Créditos aprobados',
};

export const TABLE_FILTERS = {
    APPLICATION: 'Solicitudes',
    UP_TO_DATE: 'Al día',
    OVERDUE: 'Atrasados',
    LIQUIDATED: 'Liquidados',
    REJECTED: 'Rechazados',
    APPROVED: 'Aprobados',
};
export const TABLE_STATUS_FILTER_VALUES = {
    UP_TO_DATE: 1,
    OVERDUE: 2,
    LIQUIDATED: 3,
    REJECTED: 4,
    SOLICITUDES: 5,
    APPROVED: 6,
};

export const APPLICATION_LIST = {
    APPLICATION: 'Lista de solicitudes',
    UP_TO_DATE: 'Lista de crédito al día',
    OVERDUE: 'Lista de crédito atrasados',
    LIQUIDATED: 'Lista de crédito liquidado',
    REJECTED: 'Lista de crédito rechazados',
    APPROVED: 'Lista de crédito aprobados',
    SWITCH_TITLE: 'Activar revisión de solicitudes en cola',
};
export const messageMap: {[key: string]: string} = {
    [TABLE_FILTERS.APPLICATION]: APPLICATION_LIST.APPLICATION,
    [TABLE_FILTERS.UP_TO_DATE]: APPLICATION_LIST.UP_TO_DATE,
    [TABLE_FILTERS.OVERDUE]: APPLICATION_LIST.OVERDUE,
    [TABLE_FILTERS.LIQUIDATED]: APPLICATION_LIST.LIQUIDATED,
    [TABLE_FILTERS.REJECTED]: APPLICATION_LIST.REJECTED,
    [TABLE_FILTERS.APPROVED]: APPLICATION_LIST.APPROVED,
};
export const TABLE_HEADERS = {
    APPLICATION_ID: 'No. de solicitud',
    CLIENT: 'Cliente',
    SCORE: 'Score',
    VALUATION: 'Tasa',
    TERM: 'Plazo',
    AMOUNT: 'Monto',
    REQUEST_DATE: 'Fecha solicitud',
    PAYMENT_DATE: 'Fecha pago',
    APPROVAL_DATE: 'Fecha aprobación',
    LIQUIDATION_DATE: 'Fecha liquidado',
    REJECTION_DATE: 'Fecha de rechazo',
    STATE: 'Estado',
};

//Credit Request

export const CREDIT_REQUEST_FOOTER = {
    TAB_TITLE: 'Ventana principal',
};

export const CREDIT_REQUEST_HEADER = {
    CTA_BACK_TO: 'Volver a Solicitudes de Créditos',
    TITLE: 'Solicitud #',
};

export const CREDIT_REQUEST_KPI = {
    CONTRACTS: 'Contratos activos',
    MUTUAL_AMOUNT: 'Monto mutuo',
    TO_PAY: 'Por pagar',
    EARNINGS: 'Ganancias intereses',
    LOSSES: 'Perdidas',
    PAYMENTS_MADE: 'Pagos realizados',
};

export const CREDIT_REQUEST_USER = {
    NUC: 'NUC',
    CTA_INFORMATION: 'Más información',
    CTA_EVALUATION: 'Ver evaluación',
};

export const CREDIT_REQUEST_HISTORIES = {
    CREDIT_AND_PAWNS_TITLE: 'Historial de créditos y empeños',
    CREDIT_AND_PAWNS_TYPE: 'Tipo',
    CREDIT_AND_PAWNS_STATUS: 'Estado',
    CREDIT_AND_PAWNS_AMOUNT: 'Monto Mutuo',
    CREDIT_AND_PAWNS_PAYMENTS: 'Pagos',
    CREDIT_AND_PAWNS_DEBT: 'Deuda',
};

export const CREDIT_REQUEST_INFORMATION = {
    SUB_TITLE: 'Solicitud de crédito',
    AMOUNT: 'Monto solicitado',
    TERM: 'Plazo',
    ANNUAL_INTEREST_RATE: 'Tasa de interés anual',
    REQUEST_DATE: 'Fecha de solicitud',
    CTA_REJECT: 'Rechazar solicitud',
    CTA_APPROVE: 'Aprobar',
    PERCENTAGE_MESSAGE: 'Elige el un porcentaje a prestar o escribe una cantidad',
    LEND: 'Préstaras',
    RECEIVE: 'Recibirás',
};

export const CREDIT_REQUEST_NOTES = {
    SUB_TITLE: 'Notas para el cliente',
};

export const ACCORDION_STYLES = {
    width: '100%',
    boxShadow: 'none',
    backgroundColor: 'transparent',
    '&:before': {
        display: 'none',
    },
};

export const ACCORDION_STYLES_NO_PADDING = {
    padding: '0px',
};

export const NUL_SEARCH = 'NUL';

export const CREDITSANDPAWNS_FOOTER_TABS = {
    VENTANA_PRINCIPAL: 'Ventana principal',
};

export const NUEVA_SOLICITUD = 'Nueva solicitud';

export const NO_RESULTS_FOUND = {
    SEARCH: 'Parece que este contrato o nombre no existe en el listado',
    TABLE: 'No hay solicitudes',
    FILTER_SEARCH: 'Nombre no existe en el listado.',
};

export const FILTER_TITLES = {
    FREQUENCY: 'Frecuencia del pago',
    AMOUNT: 'Monto',
    REQUEST_DATE: 'Fecha de solicitud',
    PAYMENT_DATE: 'Fecha de pago',
    SCORE: 'Score',
    INTERES_RATE: 'Tasa de interés',
    CLIENT: 'Cliente',
};

export const FILTER_NAMES = {
    SCORE_START: 'scoreStart',
    SCORE_END: 'scoreEnd',
    ANUAL_INTEREST_START: 'interestAnualStart',
    ANUAL_INTEREST_END: 'interestAnualEnd',
    AMOUNT_MAX_CREDIT_START: 'amountMaxCreditStart',
    AMOUNT_MAX_CREDIT_END: 'amontMaxCreditEnd',
    REQUESTED_DATE_START: 'requestedDateStart',
    REQUESTED_DATE_END: 'requestedDateEnd',
    PAYMENT_DATE_START: 'paymentDateStart',
    PAYMENT_DATE_END: 'paymentDateEnd',
};

export const SLIDER_TYPE = {
    CURRENCY: 'money',
    PERCENTAGE: 'percentage',
};

export const DEFAULT_PAGE = 1;

export const NO_FILTER_RESULT = {
    TITLE: 'No se han encontrado resultados',
    BUTTON_TEXT: 'Ver Filtros',
};

export const LIMITS_RANGES = {
    BAD: {
        scoreColor: '#A82424',
        scoreColorBg: '#F7D3D3',
        infoIconColor: '',
        min: 300,
        max: 549,
        startAngle: 220,
        endAngle: 259,
    },
    REGULAR: {
        scoreColor: '#CC9200',
        scoreColorBg: '#F8E2A6',
        infoIconColor: '',
        min: 550,
        max: 632,
        startAngle: 260,
        endAngle: 315,
    },
    GOOD: {
        scoreColor: '#008FCC',
        scoreColorBg: '#DBF4FF',
        infoIconColor: '',
        min: 633,
        max: 670,
        startAngle: 316,
        endAngle: 365,
    },
    EXCELLENT: {
        scoreColor: '#309C60',
        scoreColorBg: '#8AD0A8',
        infoIconColor: '',
        min: 671,
        max: 850,
        startAngle: 366,
        endAngle: 400,
    },
};

export const MODAL_INFO_HEADER = {
    TITLE: 'Información del cliente',
};

export const MODAL_INFO_BODY = {
    SUBTITLE_EMPLOYMENT: 'Información laboral',
    EMPLOYMENT_SITUATION: 'Situación laboral',
    COMPANY: 'Empresa',
    ADDRESS: 'Dirección',
    JOB: 'Puesto',
    MONTHLY_INCOME: 'Ingresos mensuales',
    PHONE: 'Teléfono',
    LABOR_OLD: 'Antiguedad',
    SUBTITLE_VOUCHERS: 'Comprobantes',
};

export const MODAL_INFO_FOOTER = {
    CTA_CLOSE: 'Cerrar',
};

export const TAG_KEYS = {
    START: 'Start',
    END: 'End',
    AMOUNT: 'montoMaximoCredito',
    REQUEST_DATE: 'fechaSolicitud',
    PAYMENT_DATE: 'fechaPago',
    SCORE: 'score',
    INTERES_RATE: 'interesAnual',
};

export const statusColorsMap = {
    [TABLE_STATUS_FILTER_VALUES.SOLICITUDES]: {
        color: '#004461',
        backgroundColor: '#DBF4FF',
        borderColor: '#99E0FF',
    },
    [TABLE_STATUS_FILTER_VALUES.UP_TO_DATE]: {
        color: '#174A2E',
        backgroundColor: '#E4F7EC',
        borderColor: '#B1E7C9',
    },
    [TABLE_STATUS_FILTER_VALUES.OVERDUE]: {
        color: '#610052',
        backgroundColor: '#FFE5FB',
        borderColor: '#FFC6F6',
    },
    [TABLE_STATUS_FILTER_VALUES.LIQUIDATED]: {
        color: '#54575C',
        backgroundColor: '#F4F5F5',
        borderColor: '#D4D6D8',
    },
    [TABLE_STATUS_FILTER_VALUES.REJECTED]: {
        color: '#501111',
        backgroundColor: '#FFE6E6',
        borderColor: '#FE9A9A',
    },
    [TABLE_STATUS_FILTER_VALUES.APPROVED]: {
        color: '#000061',
        backgroundColor: '#E5E5FF',
        borderColor: '#0D166B',
    },
    default: {
        color: '#004461',
        backgroundColor: '#DBF4FF',
        borderColor: '#99E0FF',
    },
};

export const CREDIT_REQUEST_STATUS_ID = 5;

export const CREDIT_DETAIL_HEADER = {
    TITLE: 'Detalle del crédito ',
    CTA_BACK_TO: 'Regresar a lista de créditos',
    ALT: 'Icono de flecha larga a la izquierda',
};
export const CREDIT_DETAIL_PROFILE = {
    CLIENT: 'Cliente',
    CONTRACT: 'Contrato:',
    REQUEST_DATE: 'Fecha de solicitud:',
    APPROVAL_DATE: 'Fecha de aprobación:',
    STATUS: 'Estado:',
    REQUEST_FORM: 'Forma de solicitud:',
    FEES: 'Cuotas:',
    PAYMENT_FREQUENCY: 'Frecuencia de pago:',
    CONTRACT_TYPE: 'Tipo de contrato:',
    CREDIT_VALUE: 'Valor del crédito',
    PAID: 'Pagado ',
    OWES: 'Debe',
    PRINTER_CTA: 'Imprimir contrato',
    ALT: {
        USER: 'Icono de avantar de usuario',
        PRINTER: 'Icono de impresora',
    },
};

export const STYLE_MESSAGE_SETUP_MAP: Map<number, IStyleMessageSetup> = new Map([
    [
        4,
        {
            backgroundMessage: '#DBF4FF',
            colorTitle: '#004461 ',
            colorParagraph: '#2A2C2F',
            icon: infoIconBlue,
            title: 'Préstamo rechazado',
            alt: 'icono de informacion azul',
        },
    ],
    [
        2,
        {
            backgroundMessage: '#FFF5DB',
            colorTitle: '#614500',
            colorParagraph: '#2A2C2F',
            icon: alertIcon,
            title: 'Pago atrasado',
            message:
                'El pago de la cuota está atrasado, por lo que se cobrarán intereses por mora.',
            alt: 'icono de aprobacion',
        },
    ],
    [
        6,
        {
            backgroundMessage: '#FFF5DB',
            colorTitle: '#614500',
            colorParagraph: '#2A2C2F',
            icon: alertIcon,
            title: 'Préstamo a la espera de aceptación',
            message:
                'Se le notificó al cliente que su préstamo fue aprobado. Una vez que el cliente lo acepte, aparecerá el detalle del pago de cuotas en la parte inferior.',
            alt: 'icono de aprobacion',
        },
    ],
]);

export const CREDIT_DETAIL_TABLES = {
    FEE_TITLE: 'Pago a cuotas',
    HISTORY_TITLE: 'Historial de movimientos',
    ALTS: {
        CREDIT_CARD: 'Icono de tarjeta de credito visa',
        DOWNLOAD_ICON: 'Icono de descarga morado',
    },
    FILTER: {
        ALL: 'Todos',
        PAID: 'Pagado',
        UNPAID: 'No Pagado',
        OVERDUE: 'Atrasado',
        FEE_PAYMENT: 'Pago a cuota',
        PAYMENT: 'Abono',
        NO_OPTION: 'No hay opciones',
    },
    FILTER_VALUES: {
        ALL: null,
        PAID: 1,
        OVERDUE: 2,
        UNPAID: 3,
        FEE_PAYMENT: 1,
        PAYMENT: 2,
        NO_OPTION: 'No hay opciones',
    },
    FEE_TABLE: {
        FEE_FIELD: 'Cuota',
        EXPIRATION_FIELD: 'Vencimiento',
        STATUS_FIELD: 'Estado',
        PAYMENT_METHOD_FIELD: 'Método de pago',
        PAID_FIELD: 'Pagado',
        VOUCHER_CTA: 'Comprobante',
    },
    HISTORY_TABLE: {
        TRANSACTION_FIELD: 'Transacción',
        PAYMENT_METHOD_FIELD: 'Método de pago',
        PAY_FIELD: 'Pago',
        PAYMENT_DATE_FIELD: 'Fecha de pago',
        DOWNLOAD_BUTTON: 'Descargar historial',
    },
    CREDIT_DETAIL_ROW_SETUP: {
        PAY: 'paymentAmount',
        PAYMENT_METHOD: 'cardNumber',
        PAYMENT_DATE: 'paymentDate',
        VOUCHER_CTA: 'cta',
        DEFAULT: 'default',
        STATUS: 'quotaStatus',
    },
};

export const CREDIT_DETAIL_FIELD_STYLE_MAP: Map<string, IStyleRowSetup> = new Map([
    [
        CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.PAY,
        {
            color: '#5A5AFF',
            borderColor: '',
            background: '',
            fontWeight: '700',
            fontSize: '0.88rem',
            padding: '',
            radius: '',
            cursor: '',
            width: '100%',
            height: '18px',
            gap: '4px',
        },
    ],
    [
        CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.VOUCHER_CTA,
        {
            color: '#5A5AFF',
            borderColor: '',
            background: '',
            fontWeight: '700',
            fontSize: '0.88rem',
            padding: '',
            radius: '',
            cursor: 'pointer',
            width: '100%',
            height: '18px',
            gap: '4px',
        },
    ],
    [
        CREDIT_DETAIL_TABLES.FILTER.PAID.toLocaleLowerCase(),
        {
            color: '#174A2E',
            borderColor: '1px solid #B1E7C9',
            background: '#E4F7EC',
            padding: '',
            fontWeight: '700',
            fontSize: '0.75rem',
            radius: '32px',
            cursor: '',
            width: '57px',
            height: '18px',
            gap: '',
        },
    ],
    [
        CREDIT_DETAIL_TABLES.FILTER.OVERDUE.toLocaleLowerCase(),
        {
            color: '#610052',
            borderColor: '1px solid #FFC6F6',
            background: '#FFE5FB',
            fontWeight: '700',
            fontSize: '0.75rem',
            padding: '',
            radius: '32px',
            cursor: '',
            width: '73px',
            height: '18px',
            gap: '',
        },
    ],
    [
        CREDIT_DETAIL_TABLES.FILTER.UNPAID.toLocaleLowerCase(),
        {
            color: '#54575C',
            borderColor: '1px solid #D4D6D8',
            background: '#F4F5F5',
            fontWeight: '700',
            fontSize: '0.75rem',
            padding: '',
            radius: '32px',
            cursor: '',
            width: '84px',
            height: '18px',
            gap: '',
        },
    ],
    [
        CREDIT_DETAIL_TABLES.CREDIT_DETAIL_ROW_SETUP.DEFAULT,
        {
            color: '#2A2C2F',
            borderColor: '',
            background: '',
            fontWeight: '400',
            fontSize: '0.88rem',
            padding: '',
            radius: '',
            cursor: '',
            width: '100%',
            height: '18px',
            gap: '4px',
        },
    ],
]);

export const FORMAT_DATE = 'dd/MM/yyyy';

export const TABLE_FILTER_TABS: ITableFilterTabs[] = [
    {title: TABLE_FILTERS.APPLICATION, value: TABLE_STATUS_FILTER_VALUES.SOLICITUDES},
    {title: TABLE_FILTERS.APPROVED, value: TABLE_STATUS_FILTER_VALUES.APPROVED},
    {title: TABLE_FILTERS.UP_TO_DATE, value: TABLE_STATUS_FILTER_VALUES.UP_TO_DATE},
    {title: TABLE_FILTERS.OVERDUE, value: TABLE_STATUS_FILTER_VALUES.OVERDUE},
    {title: TABLE_FILTERS.LIQUIDATED, value: TABLE_STATUS_FILTER_VALUES.LIQUIDATED},
    {title: TABLE_FILTERS.REJECTED, value: TABLE_STATUS_FILTER_VALUES.REJECTED},
];
export const USER_TYPE_ADMINISTRATOR = 'Administrator';
