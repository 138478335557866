import React, {useEffect, useState} from 'react';
import {routers} from '@/appRoute';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {FieldValues, useForm, FormProvider} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import * as style from '@Quoter/stylesConstants';
import {RootState} from '@config/store';
import {
    shemaCreateClientLevelOne,
    shemaCreateClientLevelTwo,
    schemaCreateClientLevelThree,
} from '@Shopify/ListClient/yupAddClientProof';

import {
    changeNewClientStep,
    addProofAddressClient,
    addNewClientInfo,
    resetNewClientProcess,
} from '@ListClient/redux/Actions/newClientProcessAction';
import {
    IAddProofAddressClient,
    INewClient,
    ICoOwner,
} from '@components/Shopify/ListClient/redux/interfaces';

import {formNewClient} from '@components/Shopify/ListClient/constants';
import {getClientDetailClearData} from '@Shopify/ClientProfile/Redux/Action/GetClientDetails';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {ProgressBar} from '@components/SignUp/ProgressBar';
import ButtonGeneral from '@components/General/Atoms/Button';
import {NEW_CLIENT, PROGRESSITEMS} from '@components/Shopify/ListClient/constants';
import {Icons} from '@Articles/style';
import CreateClient from '@components/Shopify/ListClient/CreateClient';
import ClientProof from '@components/Shopify/ListClient/ClientProof';
import {FORM_ERRORS} from '@components/Shopify/ListClient/constants';
import {ONE_IMAGE_DOCS} from '@components/Shopify/ClientProfile/constants';
import FingerprintRegistration from '@/components/Shopify/ListClient/FingerprintRegistration/ClientFingerprintRegistration';
import {clearIdentificationImageData} from '@components/Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {formatToDBDateString, formatYearDate} from '@/hooks/useFormatDate';
import {addIdDocument} from '@components/Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {AddIdDocumentData} from '@components/Shopify/ClientProfile/interfaces';
import AddNewClientWarning from '@components/Shopify/ListClient/AddNewClientWarning';
import {resetCatAddress} from '@components/LocationsBranch/Redux/Action/CatAddress';
import {resetAddress} from '@/components/LocationsBranch/Redux/Action/AddAddressLocation';

export const NewClient = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const addedIdImageDocs = useSelector(
        (state: RootState) => state.addIdentificationDoc.imageData
    );
    const {address} = useSelector((state: RootState) => state.getAddress);

    const newClientProcess = useSelector((state: RootState) => state.newClientProcess);
    const [processErrorMessage, setProcessErrorMessage] = useState<string | null>(null);
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });

    const [resolver, setResolver] = useState(() => yupResolver(shemaCreateClientLevelOne));

    const formMethods = useForm<FieldValues>({
        mode: 'onChange',
        resolver: resolver,
        defaultValues: formNewClient,
    });

    const {
        register,
        reset,
        resetField,
        handleSubmit,
        watch,
        setValue,
        getValues,
        control,
        formState: {errors, isSubmitSuccessful},
    } = formMethods;

    useEffect(() => {
        if (newClientProcess.actualStep === 1) {
            dispatch(getClientDetailClearData());
            reset({
                imageProof: '',
                effectiveDate: '',
                typeOfReceiptId: '',
                imageFileExtension: '',
            });
            setResolver(() => yupResolver(shemaCreateClientLevelOne));
        } else if (newClientProcess.actualStep === 2) {
            if (isSubmitSuccessful) {
                reset();
            }
            setResolver(() => yupResolver(shemaCreateClientLevelTwo));
        } else if (newClientProcess.actualStep === 3) {
            setResolver(() => yupResolver(schemaCreateClientLevelThree));
        }
    }, [newClientProcess.actualStep, reset]);

    useEffect(() => {
        dispatch(clearIdentificationImageData());
        dispatch(resetCatAddress());
        dispatch(resetAddress());
    }, []);

    const handleBack = () => {
        if (newClientProcess.actualStep > 1) {
            dispatch(changeNewClientStep(newClientProcess.actualStep - 1));
        }
    };

    const uploadIdDocumentData = async (clientId: number, clientData: INewClient) => {
        const formattedDate = formatYearDate(Number(addedIdImageDocs.expiryDate!));
        const formattedDateEffective = formatYearDate(Number(addedIdImageDocs.validity));
        const requireReverseImg = !ONE_IMAGE_DOCS.includes(
            String(clientData.catIdentificationTypeId)
        );
        const imageData: AddIdDocumentData = {
            clientId: clientId,
            identificationTypeId: clientData.catIdentificationTypeId,
            effectiveDate: formatToDBDateString(formattedDateEffective),
            expirationDate: formatToDBDateString(formattedDate),
            havingReverse: requireReverseImg,
            identificationNumber: clientData.identificationNumber,
            listImage: [
                {
                    fileExtension: String(addedIdImageDocs.frontImage?.fileExtension),
                    fileContent: String(addedIdImageDocs.frontImage?.formFile),
                    havingReverse: false,
                },
                ...(requireReverseImg
                    ? [
                          {
                              fileExtension: String(addedIdImageDocs.backImage?.fileExtension),
                              fileContent: String(addedIdImageDocs.backImage?.formFile),
                              havingReverse: true,
                          },
                      ]
                    : []),
            ],
        };
        await dispatch(addIdDocument(imageData, addedIdImageDocs, token));
    };

    const onSubmit = async () => {
        if (newClientProcess.actualStep === 1) {
            setShowWarningModal(true);
            return;
        }
        processStep();
    };

    const processStep = async () => {
        const values = getValues();
        setProcessErrorMessage('');
        const arrCotitulares: ICoOwner[] = [];
        for (let i = 0; i < 4; i++) {
            const coownerNames = values[`cotitularNombres${i}`];
            const coownerPhone = values[`cotitularTelefono${i}`];
            const coownerEmail = values[`cotitularCorreoElectronico${i}`];

            if (values[`cotitularNombres${i}`] !== undefined) {
                arrCotitulares.push({
                    names: coownerNames,
                    lastNames: values[`coownerPaternalLastname${i}`],
                    secondLastName: values[`coownerMaternalLastname${i}`],
                    phone: coownerPhone,
                    email: coownerEmail,
                });
            }
        }
        try {
            if (newClientProcess.actualStep === 1) {
                const formattedDate = formatYearDate(Number(values.validity));
                const clientInfo: INewClient = {
                    names: values.names,
                    lastName: values.lastName,
                    secondLastName: values.secondLastName,
                    mainPhone: values.mainPhone,
                    mainEmail: values.mainEmail,
                    civilStatusId: values.civilStatusId.value,
                    economicActivityId: values.economicActivityId.value,
                    genderId: values.genderId.value,
                    countryOfBirthId: values.countryOfBirthId.value,
                    rfc: values.rfc,
                    curp: values.curp,
                    nationality: values.nationality,
                    catIdentificationTypeId: Number(values.catIdentificationTypeId),
                    identificationNumber: values.identificationNumber,
                    validity: formattedDate,
                    clientAddres: {
                        secondEmail: values.secondEmail,
                        landline: values.landline,
                        cellPhone: values.cellPhone,
                        anotherPhone: values.anotherPhone,
                        street: address?.street || '',
                        externalNumber: address?.outerNum || '',
                        internalNumber: address?.interNum || '',
                        cp: Number(address?.postalCode) || 0,
                        colonyId: Number(address?.colony?.value) || 0,
                        municipalityId: Number(address?.municipaly?.value) || 0,
                        stateId: Number(address?.state?.value) || 0,
                        notes: values.notes,
                    },
                    customerMarketings: {
                        catCustomerMarketingId: values.catCustomerMarketingId.value,
                        acceptMarketing: true,
                        other: values.other,
                    },
                    addCoOwner: values.cotitularId === '1',
                    coOwnerClients: arrCotitulares,
                    imageClients: {
                        fileExtension: values.imageFileExtension,
                        fileContent: values.imageUser,
                    },
                };
                await dispatch(addNewClientInfo(clientInfo, token, uploadIdDocumentData));
                dispatch(changeNewClientStep(2));
            } else if (newClientProcess.actualStep === 2) {
                const proofClientData: IAddProofAddressClient = {
                    clientId: newClientProcess.newClientId,
                    typeOfReceiptId: values.typeOfReceiptId.value,
                    fileExtension: values.imageFileExtension,
                    fileContent: values.imageProof,
                    vigency: values.effectiveDate.toISOString(),
                };
                await dispatch(addProofAddressClient(proofClientData, token));
                dispatch(changeNewClientStep(3));
            } else if (newClientProcess.actualStep === 3) {
                dispatch(resetNewClientProcess());
                navigate('/Clients/ClientList');
            }
        } catch (error) {
            if (newClientProcess.actualStep === 2) {
                setProcessErrorMessage(FORM_ERRORS.COMPLETE_PROOF_DOCUMENT);
            } else {
                setProcessErrorMessage(FORM_ERRORS.GENERAL_ERROR);
            }
        }
    };
    const marketId = watch('catCustomerMarketingId.value');

    const validate = () => {
        let flag = false;
        if (marketId === 7 && watch('other').length === 0) {
            flag = true;
        }
        return flag;
    };
    return (
        <FormProvider {...formMethods}>
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                FlexDir="column"
                Padding="1rem 1.5rem"
                Gap="1rem"
                Align="start"
                Height="auto"
                Justify="start"
                BColor="#FAFAFA"
            >
                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500" LHeight="auto">
                    {NEW_CLIENT.TITLE}
                </Text>
                <ContainerFlex
                    Align="center"
                    Justify="start"
                    Padding="4px 0px 8px 0px"
                    Bb="1px solid #E8E9EA"
                >
                    <ProgressBar
                        progressItems={PROGRESSITEMS}
                        actualStep={newClientProcess.actualStep}
                    />
                </ContainerFlex>
                {newClientProcess.actualStep === 1 && (
                    <CreateClient
                        token={token}
                        register={register}
                        errors={errors}
                        control={control}
                        reset={reset}
                        watch={watch}
                        setValue={setValue}
                        getValues={getValues}
                        resetField={resetField}
                    />
                )}
                {newClientProcess.actualStep === 2 && (
                    <ClientProof
                        token={token}
                        register={register}
                        errors={errors}
                        control={control}
                        reset={reset}
                        watch={watch}
                        setValue={setValue}
                        resetField={resetField}
                    />
                )}
                {newClientProcess.actualStep === 3 && (
                    <>
                        <FingerprintRegistration
                            token={token}
                            register={register}
                            errors={errors}
                            control={control}
                            reset={reset}
                            watch={watch}
                            setValue={setValue}
                        />
                    </>
                )}
                {processErrorMessage && (
                    <ContainerFlex Justify="center" Align="center" Height="auto">
                        <Text Color="#FF6357" FontSize=".875rem" wSpace="normal">
                            {processErrorMessage}
                        </Text>
                    </ContainerFlex>
                )}
                <ContainerFlex Gap="1rem">
                    {newClientProcess.actualStep === 1 && (
                        <>
                            <Text
                                Color="#FF6357"
                                FontWeight="400"
                                FontSize="1rem"
                                Cursor="pointer"
                                MarginRight="1rem"
                                onClick={() => navigate(routers.ClientList)}
                            >
                                <Icons
                                    className="material-icons"
                                    Color="#FF6357"
                                    FontSize="1rem"
                                    MarginRight="0.25rem"
                                    MarginTop="0.15rem"
                                >
                                    close
                                </Icons>
                                Cancelar
                            </Text>
                        </>
                    )}
                    {newClientProcess.actualStep === 3 && (
                        <Text
                            Color="#5A5AFF"
                            bGround=" #FFFFFF"
                            Border="1px solid #5A5AFF"
                            {...style.Btn}
                            onClick={handleBack}
                        >
                            <>
                                <Icons className="material-icons" Color="#5a5aff" FontSize="1rem">
                                    keyboard_arrow_left
                                </Icons>
                                Atrás
                            </>
                        </Text>
                    )}
                    <ButtonGeneral
                        text={
                            <>
                                <Text Padding="0 8px" Cursor="pointer" Color="#FFFFFF">
                                    {newClientProcess.actualStep === 3
                                        ? 'Guardar Cliente'
                                        : 'Siguiente'}
                                </Text>
                                {newClientProcess.actualStep !== 3 && (
                                    <Icons
                                        className="material-icons"
                                        Color="#FFFFFF"
                                        FontSize="1rem"
                                    >
                                        keyboard_arrow_right
                                    </Icons>
                                )}
                            </>
                        }
                        disabled={validate()}
                        onClick={handleSubmit(onSubmit)}
                        Cursor="pointer"
                    />
                </ContainerFlex>
                {showWarningModal && (
                    <AddNewClientWarning
                        setShowModal={setShowWarningModal}
                        showModal={showWarningModal}
                        onProceed={() => processStep()}
                    />
                )}
            </ContainerForm>
        </FormProvider>
    );
};
