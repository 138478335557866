import {ICatalogItems} from '@/components/AuctionPass/interfaces';
import {
    GET_GROUP_NODE,
    GET_GROUP_NODE_NAME,
    RESET_SELECTED,
    SELECTED_LEVEL_NUMBER,
} from '@components/RequestNewCatalogLevel/Types/Types';
export const getSelectedGroupNodeLevel = (result: number) => {
    return {
        type: SELECTED_LEVEL_NUMBER,
        level: result,
    };
};
export const getSelectedGroupNode = (result?: ICatalogItems[]) => {
    return {
        type: GET_GROUP_NODE,
        payload: result,
    };
};
export const getSelectedGroupNodeName = (result: string) => {
    return {
        type: GET_GROUP_NODE_NAME,
        names: result,
    };
};

export const ResetSelectedNodeLevel = () => {
    return {
        type: RESET_SELECTED,
    };
};
