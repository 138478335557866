import {
    DETAIL_CONTRACTS,
    SAVE_CONTRACT_IMG,
    SAVE_ID_PAWN,
    DOWNLOAD_CONTRACT,
} from '@Shopify/ClientProfile/Redux/types';
import {IDetailsContract, IDetailsContractRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IDetailsContract = {
    error: false,
    loading: false,
    details: {
        title: '',
        clientId: 0,
        clientName: '',
        pawnNumber: 0,
        status: '',
        borrowedMoney: '',
        garmentsNumber: '',
        nextEndorsement: '',
        contractDetail: {
            contractNumber: 0,
            houseAndBranch: '',
            endorsement: 0,
            discharge: 0,
            numberOfEndorsement: 0,
            expiration: '',
            status: '',
            garmentsInTheContract: [],
        },
        carShopDat: null,
    },
    loadingAction: false,
    errorAction: false,
    pawnId: 0,
    imagesContract: [],
    downloadUrl: '',
    urlLoading: false,
    urlError: false,
};

export const detailContract = (
    state = initialState,
    action: IDetailsContractRedux
): IDetailsContract => {
    switch (action.type) {
        case DETAIL_CONTRACTS.START:
            return {...state, loading: true, error: false};
        case DETAIL_CONTRACTS.SUCCESS:
            return {
                ...state,
                details: action.details,
                loading: false,
            };
        case DETAIL_CONTRACTS.ERROR:
            return {...state, loading: false, error: true};
        case SAVE_ID_PAWN:
            return {...state, pawnId: action.pawnId};
        case SAVE_CONTRACT_IMG:
            return {...state, imagesContract: action.imagesContract};
        case DOWNLOAD_CONTRACT.START_DATA:
            return {...state, urlLoading: false, urlError: false};
        case DOWNLOAD_CONTRACT.GET_URL:
            return {
                ...state,
                downloadUrl: action.downloadUrl,
                urlLoading: false,
            };
        case DOWNLOAD_CONTRACT.ERROR_LOAD:
            return {...state, urlLoading: false, urlError: true};
        default:
            return state;
    }
};
