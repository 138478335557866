import {
    RECENT_ACCESS_REQUEST,
    RECENT_ACCESS_SOUCCES,
    RECENT_ACCESS_ERROR,
} from '@components/Users/UserProfile/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const RecentAccessUserData = (state = initialState, action) => {
    switch (action.type) {
        case RECENT_ACCESS_REQUEST:
            return {...state, loading: true};
        case RECENT_ACCESS_SOUCCES:
            return {...state, loading: false, userData: action.payload};
        case RECENT_ACCESS_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
