import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {FieldValues, useForm} from 'react-hook-form';

import {ImageSVG} from '@/components/Quoter/Steps/styles';
import {
    ContainerCellGrid,
    ContainerForm,
    ContainerImg,
    Image,
    Input,
    Text,
} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {CONFIRM_PASSWORD} from '@Login/constants';
import AtaskateFox from '@images/AtaskateFox1.svg';
import * as style from '@/components/Login/style';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {PrivacityContainer} from '@Login/Administrative/Privacity';
import {RootState} from '@/config/store';
import {yupResolver} from '@hookform/resolvers/yup';
import {schema} from '@/components/Login/PasswordForgotAdmin/ConfirmPass/validationYup';
import {ResetAdminPassword} from '@/components/Login/Redux/Actions/PutResetPassWordAdmin';

export const FormNewPassword = ({rowId}: {rowId: string}) => {
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const loading = useSelector((state: RootState) => state.getUsersValidation.loading);
    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm<{
        Password: string;
        ConfirmPassword: string;
        Version: number;
    }>({
        defaultValues: {
            Password: '',
            ConfirmPassword: '',
            Version: 1,
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (value: FieldValues) => {
        const Json = {
            rowId: rowId,
            NewPassword: value.Password,
            verificationNewPassword: value.ConfirmPassword,
        };
        dispatch(ResetAdminPassword(Json));
    };

    return (
        <ContainerForm Gap="16px" FlexDir="column" onSubmit={handleSubmit(onSubmit)}>
            <ImageSVG Width="7.313rem" Height="8rem" src={AtaskateFox} alt="Fox-ataskate" />
            <Text FontWeight="500" Color="#54575C" Transform="capitalize" Height="16px">
                {CONFIRM_PASSWORD.TITLE}
            </Text>
            <Text FontSize="0.875rem" Color="#54575C" Height="14px">
                {CONFIRM_PASSWORD.SUBTITLE}
            </Text>
            <ContainerCellGrid
                FlexDir="column"
                Align="start"
                Height="auto"
                Padding="0 16px"
                Gap="4px"
            >
                <Text {...style.textDescription}>{CONFIRM_PASSWORD.TITLE}</Text>
                <SearchInput {...style.searchInput} Width="100%">
                    <input type={viewPassword ? 'Text' : 'Password'} {...register('Password')} />
                    <ContainerImg onClick={() => setViewPassword(!viewPassword)} Cursor="pointer">
                        <Image
                            src={viewPassword ? VisibilityOn : VisibilityOff}
                            alt="icon-warning"
                            Cursor="pointer"
                        />
                    </ContainerImg>
                </SearchInput>
                {errors.Password?.message && (
                    <Text {...style.errorsStyle}>{errors.Password.message}</Text>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid
                FlexDir="column"
                Align="start"
                Height="auto"
                Padding="0 16px"
                Gap="4px"
            >
                <Text {...style.textDescription}>{CONFIRM_PASSWORD.CONFIRM}</Text>
                <Input
                    Cursor="text"
                    FontSize="0.875rem"
                    type={viewPassword ? 'Text' : 'Password'}
                    {...register('ConfirmPassword')}
                />
                {errors.ConfirmPassword?.message && (
                    <Text {...style.errorsStyle}>{errors.ConfirmPassword.message}</Text>
                )}
            </ContainerCellGrid>
            {loading ? <LoadingAtaskate /> : <ButtonGeneral {...style.button} />}
            <PrivacityContainer />
        </ContainerForm>
    );
};
