import * as yup from 'yup';

export const schemaClientsAndDocuments = yup.object().shape(
    {
        genderTerms: yup
            .array()
            .of(
                yup.object().shape({
                    genderId: yup.number(),
                    genderName: yup.string(),
                    isChecked: yup.boolean(),
                })
            )
            .notRequired(),
        ageMin: yup.string(),
        ageMax: yup
            .string()
            .test(
                'ageMin-less-than-ageMax',
                'La edad máxima debe de ser mayor o igual a la edad mínima',
                function (value) {
                    const {ageMin} = this.parent;
                    if (!ageMin && !value) return true;
                    if (!ageMin || !value) return false;
                    return Number(ageMin) <= Number(value);
                }
            ),
        reviewCreditVoul: yup.boolean().notRequired(),
    },
    [['ageMin', 'ageMax']]
);
