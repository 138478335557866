import React from 'react';
import {Input, Image} from '@EcommerceShopify/styles';
import {SearchInput} from '@/components/Customer/Styles';
import calendarIcon from '@components/Branches/Images/CalendarIcon.svg';
import {DatePickerInputProps} from '@General/Atoms/CalendarDateSelection/interfaces';
import {DEFAULT_DESIGN} from '@components/General/Atoms/CalendarDateSelection/constants';

const DatePickerInput: React.FC<DatePickerInputProps> = ({
    placeholder,
    value,
    gap,
    inputGridCols,
    onClick,
    error,
    type,
    disabled,
    onChange,
}) => {
    return (
        <>
            <SearchInput
                error={error}
                Height="2.5rem"
                Position="none"
                GridColumn={
                    type === DEFAULT_DESIGN ? '10% 90%' : inputGridCols ? inputGridCols : '90% 10%'
                }
                BoxShadowH=""
                BoxShadowF=""
                Padding="0.5rem 1rem"
                BackG={disabled ? '#F4F5F5' : '#FFFFFF'}
                Radius="2rem"
                ColumnGap={gap}
                onClick={onClick}
            >
                {type && type === DEFAULT_DESIGN && (
                    <Image src={calendarIcon} alt="icon-calendar" Cursor="pointer" />
                )}
                <Input
                    placeholder={placeholder}
                    value={value}
                    error={!!error}
                    BackGroundColor={disabled ? '#F4F5F5' : '#FFFFFF'}
                    readOnly
                    onClick={onClick}
                    disabled={disabled}
                    disabledBackG={disabled ? 'disabled' : ''}
                    onChange={(e) => onChange && onChange(e.target.value)}
                />
                {!type && type !== DEFAULT_DESIGN && (
                    <Image src={calendarIcon} alt="icon-calendar" Cursor="pointer" />
                )}
            </SearchInput>
        </>
    );
};
export default DatePickerInput;
