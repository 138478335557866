import React, {useEffect} from 'react';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ICustomSelect, IUse, IRouteTemporal} from '@/components/Articles/Redux/interfaces';
import * as style from '@/components/Quoter/stylesConstants';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {addLevelCatalog} from '@/components/Articles/Redux/Actions/SearchManagement';
import EditIcon from '@images/edit.svg';
import {
    fetchRouteSelect,
    fetchUpdateLabelTarget,
} from '@components/Articles/Redux/Actions/UpdateNewCatalogId';
import {fetchActionTypeSelect} from '@components/Articles/Redux/Actions/GetDetailsCharacteristics';
import {fetchTargetLevel} from '@components/Articles/Redux/Actions/GetNewCatalogId';
import {NEWCATALOG} from '@components/Articles/constants';
import AddIcon from '@images/add - plus.svg';

export const CustomSelect = ({
    data = [],
    levelName,
    id,
    title,
    handleClick,
    isSelected,
    level,
}: ICustomSelect) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const LevelRoute = useSelector((state: RootState) => state.UpdateNewCatalogId.labelRoute);

    const handleNewLevel = (clickedLevel: number, clickedLevelName: string, id: number) => {
        dispatch(fetchTargetLevel(clickedLevel));
        const newLevel: IRouteTemporal = {
            id: id,
            description: `Nuevo nivel ${clickedLevel} (${clickedLevelName})`,
            levelType: clickedLevelName,
        };
        if (clickedLevel !== 1) {
            dispatch(
                fetchRouteSelect({
                    ...LevelRoute,
                    route: LevelRoute.temporalRoute.slice(0, clickedLevel - 1).concat([newLevel]),
                })
            );
        } else {
            dispatch(
                fetchRouteSelect({
                    temporalRoute: [newLevel],
                    route: [],
                })
            );
        }
    };

    const dispatchNewLevel = () => {
        if (LevelRoute.route.length || LevelRoute.temporalRoute.length > 0) {
            dispatch(addLevelCatalog({level: level, breadcrumb: LevelRoute.route}));
            navigate('/Management/Articles/FieldsDinamics');
        }
    };

    useEffect(() => {
        if (LevelRoute.route.length || LevelRoute.temporalRoute.length > 0) {
            dispatchNewLevel();
        }
    }, [LevelRoute, dispatch, navigate, level]);

    return (
        <ContainerFlex
            FlexDir="Column"
            Padding="8px"
            Height="312px"
            Justify="start"
            Align="start"
            Width="100%"
            backG="#FFFFFF"
            Radius="16px"
            OverFlow="auto"
        >
            <ContainerFlex Justify="space-between" Height="auto">
                <Text FontSize="1rem" FontWeight="500">
                    {levelName}
                </Text>
                <Text FontSize="0.75rem">{title}</Text>
            </ContainerFlex>
            <>
                <ContainerFlex
                    Justify="start"
                    Align="center"
                    Height="40px"
                    ColumnGap="8px"
                    Padding="8px 0 0 0"
                    Cursor="pointer"
                    onClick={() => {
                        handleNewLevel(level, levelName, id);
                        dispatch(fetchActionTypeSelect(1));
                    }}
                >
                    <Image src={AddIcon} alt="add-icon" />
                    <Text Cursor="pointer" FontSize="1rem" Color="#5A5AFF">
                        {NEWCATALOG.NEW}
                    </Text>
                </ContainerFlex>
                {data ? (
                    data.map((item: IUse, index: number) => (
                        <ContainerFlex
                            key={index}
                            {...style.newContentList}
                            HBackground="#f0f0ff"
                            HBorderLeft="2px solid #5a5aff"
                            Bl={isSelected === item.id ? '2px solid #5a5aff' : ''}
                            backG={isSelected === item.id ? '#f0f0ff' : ''}
                        >
                            <Text
                                onClick={() => {
                                    handleClick(item);
                                }}
                                {...style.wordSearch}
                                Cursor="pointer"
                            >
                                {item.name}
                            </Text>
                            <Image
                                src={EditIcon}
                                width="18px"
                                Height="18px"
                                Cursor="pointer"
                                onClick={() => {
                                    handleNewLevel(level, levelName, id);
                                    dispatch(fetchUpdateLabelTarget(item));
                                    dispatch(fetchActionTypeSelect(2));
                                }}
                            />
                        </ContainerFlex>
                    ))
                ) : (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                )}
            </>
        </ContainerFlex>
    );
};
