export const STATUS_USER_ADMIN_REQUEST = 'STATUS_USER_ADMIN_REQUEST';
export const STATUS_USER_USER_SOUCCES = 'STATUS_USER_USER_SOUCCES';
export const STATUS_USER_USER_ERROR = 'STATUS_USER_USER_ERROR';

export const RECENT_ACCESS_REQUEST = 'RECENT_ACCESS_REQUEST';
export const RECENT_ACCESS_SOUCCES = 'RECENT_ACCESS_SOUCCES';
export const RECENT_ACCESS_ERROR = 'RECENT_ACCESS_ERROR ';

export const GET_ROL_REQUEST = 'GET_ROL_REQUEST';
export const GET_ROL_SOUCCES = 'GET_ROL_SOUCCES';
export const GET_ROL_ERROR = 'GET_ROL_ERROR';

export const GET_ROL_COMPANY_REQUEST = 'GET_ROL_COMPANY_REQUEST';
export const GET_ROL_COMPANY_SOUCCES = 'GET_ROL_COMPANY_SOUCCES';
export const GET_ROL_COMPANY_RROR = 'GET_ROL_COMPANY_RROR';

export const PUT_ROL_USER_REQUEST = 'PUT_ROL_USER_REQUEST';
export const PUT_ROL_USER_SOUCCES = 'PUT_ROL_USER_SOUCCES';
export const PUT_ROL_USER_RROR = 'PUT_ROL_USER_RROR';

export const PUT_NEW_ROL_USER_REQUEST = 'PUT_NEW_ROL_USER_REQUEST';
export const PUT_NEW_ROL_USER_SOUCCES = 'PUT_NEW_ROL_USER_SOUCCES';
export const PUT_NEW_ROL_USER_RROR = 'PUT_NEW_ROL_USER_RROR';
export const GET_NEW_ROL_USER_REQUEST = 'GET_NEW_ROL_USER_REQUEST';
export const GET_NEW_ROL_USER_SOUCCES = 'GET_NEW_ROL_USER_SOUCCES';
export const GET_NEW_ROL_USER_RROR = 'GET_NEW_ROL_USER_RROR';
export const GET_ALL_ROLES_USER_REQUEST = 'GET_ALL_ROLES_USER_REQUEST';
export const GET_ALL_ROLES_USER_ROLES_SOUCCES = 'GET_ALL_ROLES_USER_ROLES_SOUCCES';
export const GET_ALL_ROLES_ROLES_USER_RROR = 'GET_ALL_ROLES_ROLES_USER_RROR';
export const OPEN_ROLE_MODAL = 'OPEN_ROLE_MODAL';
export const CLOSE_ROLE_MODAL = 'CLOSE_ROLE_MODAL';
