import {Controller} from 'react-hook-form';
import React, {useState} from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import debitCardIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/debitCardIcon.svg';
import {ICardNumberFieldProps} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/interfaces';
import errorIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/errorIcon.svg';
import warningIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/warningIcon.svg';
import visaIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/visaIcon.svg';
import mastercardIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/mastercardIcon.svg';
import amexIcon from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/icons/amexIcon.svg';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';

export const CardNumberField: React.FC<ICardNumberFieldProps> = ({control, errors}) => {
    const [cardIconDefault, setCardIconDefault] = useState<string>(debitCardIcon);

    const formatCardNumber = (value: string) => {
        const cleanValue = value.replace(/\D/g, '');

        const cardIcons = new Map<string, string>([
            ['4', visaIcon],
            ['51', mastercardIcon],
            ['52', mastercardIcon],
            ['53', mastercardIcon],
            ['54', mastercardIcon],
            ['55', mastercardIcon],
            ['2221', mastercardIcon],
            ['2720', mastercardIcon],
            ['34', amexIcon],
            ['37', amexIcon],
        ]);

        const cardType = Array.from(cardIcons.keys()).find((prefix) =>
            cleanValue.startsWith(prefix)
        );

        setCardIconDefault((cardType && cardIcons.get(cardType)) || debitCardIcon);

        return cleanValue.replace(/(\d{4})(?=\d)/g, '$1 ').trim();
    };
    return (
        <>
            <ContainerFlex FlexDir="column" Gap="0.5rem" Align="start" Justify="start">
                <Text Color="#1D1E20" FontWeight="700" Width="max-content">
                    {PAYMENT_METHOD.FIELDS.CARD_NUMBER}
                </Text>
                <Controller
                    name="cardNumber"
                    control={control}
                    render={({field}) => (
                        <SearchInput
                            GridColumn="48px 1fr 24px"
                            Height="40px"
                            Padding="0px 1rem 0px 0px"
                            Width="100%"
                            Position="static"
                            OverFlow="hidden"
                            InputPadding="0px 1rem"
                            Justify="space-between"
                        >
                            <ContainerFlex backG="#F4F5F5" Height="40px" Width="48px">
                                <ContainerFlex
                                    backG="#FFF"
                                    Radius="2.375rem"
                                    Width="32px"
                                    Height="32px"
                                >
                                    <Image
                                        src={cardIconDefault}
                                        Width="24px"
                                        Height="24px"
                                        alt="Credit-Card-Icon"
                                    />
                                </ContainerFlex>
                            </ContainerFlex>

                            <Input
                                {...field}
                                maxLength={19}
                                onChange={(e) => field.onChange(formatCardNumber(e.target.value))}
                            />
                            <Image
                                src={warningIcon}
                                Width="24px"
                                Height="24px"
                                alt="warning-icon"
                            />
                        </SearchInput>
                    )}
                />
                {errors.cardNumber && (
                    <Text FontSize="0.875rem" Color="#FF6357" Gap="0.25rem">
                        <Image src={errorIcon} Width="16px" Height="16px" />
                        {errors.cardNumber?.message}
                    </Text>
                )}
            </ContainerFlex>
        </>
    );
};
