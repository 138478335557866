import React, {useState, useEffect, useRef} from 'react';
import arrow from '@/images/arrow_down_small.svg';

import {
    ArrowIcon,
    DayInput,
    DropdownButton,
    DropdownOptions,
    InputContainer,
    SelectWrapper,
    Option,
} from './Operational/styles';
import {DAYS_IN_MONTH, MONTHS, TEXTS} from './Operational/Constants';
import {SplitInputProps} from './Operational/interface';

type Month = (typeof MONTHS)[number];

const SplitInput: React.FC<SplitInputProps> = ({onChange, onBlur, value = '', error = false}) => {
    const [day, setDay] = useState<string>('');
    const [selectedMonth, setSelectedMonth] = useState<Month | ''>('');
    const [inputValue, setInputValue] = useState<string>('');
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (value) {
            const [dayValue, monthValue] = value.split('-');
            setDay(dayValue || '');
            setSelectedMonth((monthValue || '') as Month);
            setInputValue(dayValue || '');
        }
    }, [value]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
                onBlur?.();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, [onBlur]);

    const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;

        if (newValue === '') {
            setInputValue('');
            setDay('');
            onChange?.(`-${selectedMonth}`);
            return;
        }

        if (/^\d{0,2}$/.test(newValue)) {
            setInputValue(newValue);

            const dayNum = parseInt(newValue);

            if (!isNaN(dayNum) && dayNum > 0) {
                setDay(newValue);
                onChange?.(`${newValue}-${selectedMonth}`);
            }
        }
    };

    const handleDayBlur = () => {
        const dayNum = parseInt(inputValue);
        const maxDays = selectedMonth ? DAYS_IN_MONTH[selectedMonth] : 31;
        if (!isNaN(dayNum)) {
            if (dayNum < 1) {
                setDay('1');
                setInputValue('1');
                onChange?.(`1-${selectedMonth}`);
            } else if (dayNum > maxDays) {
                setDay(maxDays.toString());
                setInputValue(maxDays.toString());
                onChange?.(`${maxDays}-${selectedMonth}`);
            }
        }
    };

    const handleMonthSelect = (month: Month) => {
        setSelectedMonth(month);
        setIsOpen(false);

        if (day) {
            const maxDays = DAYS_IN_MONTH[month];
            const dayNum = parseInt(day);

            if (dayNum > maxDays) {
                setDay(maxDays.toString());
                setInputValue(maxDays.toString());
                onChange?.(`${maxDays}-${month}`);
            } else {
                onChange?.(`${day}-${month}`);
            }
        } else {
            onChange?.(`${day}-${month}`);
        }
    };

    return (
        <InputContainer hasError={error}>
            <DayInput
                type="text"
                value={inputValue}
                onChange={handleDayChange}
                onBlur={handleDayBlur}
                placeholder={TEXTS.PLACEHOLDER_DAY}
                maxLength={2}
            />
            <SelectWrapper ref={dropdownRef}>
                <DropdownButton onClick={() => setIsOpen(!isOpen)}>
                    {selectedMonth || TEXTS.PLACEHOLDER_MONTH}
                </DropdownButton>
                <ArrowIcon src={arrow} isOpen={isOpen} alt="arrow" />
                {isOpen && (
                    <DropdownOptions>
                        {MONTHS.map((month) => (
                            <Option key={month} onClick={() => handleMonthSelect(month)}>
                                {month}
                            </Option>
                        ))}
                    </DropdownOptions>
                )}
            </SelectWrapper>
        </InputContainer>
    );
};

export default SplitInput;
