import {OPERATIONS, PRODUCTS, STATUS_CONTRACT} from '@Shopify/ClientProfile/constants';
import {ICustome, ISylesTransactions} from '@Shopify/ClientProfile/interfaces';

export const contentGral = {
    Height: 'min-content',
    Padding: '1.5rem',
    Gap: '1rem',
    Radius: '2rem',
    Border: '1px solid #E8E9EA',
    backG: '#FFF',
    FlexDir: 'Column',
};

export const titles = {
    FontSize: '1.25rem',
    FontWeight: '700',
};

export const cards = {
    Padding: '1rem',
    Gap: '1rem',
    Border: '1px solid #5A5AFF',
    Radius: '1.5rem',
    FlexDir: 'column',
    MinH: '19.375rem',
};

export const cardsTitle = {
    Color: '#2A2C2F',
    FontWeight: '700',
    FontSize: '0.875rem',
};

export const boxSlider = {width: '100%', gap: '1.5rem'};

export const gapSlide = (firstPosition: number, lastPosition: number) =>
    firstPosition === 0 ? '0 1.5rem 0 0' : lastPosition ? '0 1.5rem 0 0' : '0 0.75rem';

export const colorProduct = (product: string) => {
    const styles = {
        BorderRadius: '2rem',
        Padding: '0.125rem 0.5rem',
        FontWeight: '700',
        FontSize: '0.875rem',
    };

    const CUSTOME: {[key: string]: ICustome} = {
        [PRODUCTS.DEFEATED]: {
            ...styles,
            bGround: '#FFE6E6',
            Border: '1px solid #FE9A9A',
            Color: '#501111',
        },
        [PRODUCTS.TO_WIN]: {
            ...styles,
            bGround: '#FFF5DB',
            Border: '1px solid #FFE299',
            Color: '#614500',
        },
        [PRODUCTS.NEXT_PAYMENT]: {
            ...styles,
            bGround: '#DBF4FF',
            Border: '1px solid #99E0FF',
            Color: '#004461',
        },
    };
    return CUSTOME[product];
};

export const search = {
    Width: '40%',
    Height: '2.5rem',
    Position: 'none',
    InputPadding: '.5rem 0',
    GridColumn: '95% 5%',
    FontSize: '1rem',
    PlaceholderColor: '#A1A5AA',
    WBoxShadow: '',
    widthInput: '100%',
};

export const filter = {
    Padding: '0.25rem 1rem',
    Gap: '0.25rem',
    Height: '2rem',
    Cursor: 'pointer',

    text: {
        FontSize: '0.875rem',
        FontWeight: '700',
        Color: '#5A5AFF',
        Cursor: 'pointer',
    },
};

export const table = {
    content: {
        Display: 'grid',
        GridColumns: 'repeat(3, 1fr) 1.5fr repeat(2, 1fr) 0.4fr',
        Padding: '1rem 0.5rem',
        Gap: '1rem',
        Bb: '1px solid #E8E9EA',
    },
    header: {FontSize: '0.875rem', Color: '#2A2C2F', FontWeight: '700', wSpace: 'Normal'},
    details: {FontSize: '0.875rem', Color: '#54575C', wSpace: 'normal'},
};

export const sectionImg = {
    Gap: '0.5rem',
    FlexDir: 'Column',
    Align: 'start',
};

export const tabsImg = {
    Height: '3rem',
    Justify: 'start',
    Gap: '0.5rem',
};

export const contentImg = {
    Width: '3rem',
    Radius: '0.5rem',
    Border: '0.031rem solid #E8E9EA',
};

export const img = {
    height: '47rem',
    width: '47rem',
    alt: 'product',
    ObjectFit: 'contain',
    Radius: '0.5rem',
};

export const contractsResume = {
    tab: {
        Display: 'grid',
        Padding: '1rem 3rem',
        JustifyItems: 'center',
        GridColumns: 'repeat(3, 1fr)',
    },
    content: {Color: '#2A2C2F', FontSize: '1.25rem', FontWeight: '700'},
};

export const colorStatusContract = (product: string) => {
    const styles = {
        BorderRadius: '2rem',
        Padding: '0.125rem 0.5rem',
        FontWeight: '700',
        FontSize: '0.75rem',
        Width: 'min-content',
    };

    const CUSTOME: {[key: string]: ICustome} = {
        [STATUS_CONTRACT.TO_WIN]: {
            ...styles,
            Border: '1px solid #B1E7C9',
            Color: '#174A2E',
            bGround: '#E4F7EC',
        },
        [STATUS_CONTRACT.DEFEATED]: {
            ...styles,
            Border: '1px solid #FFE299',
            Color: '#614500',
            bGround: '#FFF5DB',
        },
        [STATUS_CONTRACT.PAYMENTED]: {
            ...styles,
            Border: '1px solid #D4D6D8',
            Color: '#54575C',
            bGround: '#F4F5F5',
        },
        [STATUS_CONTRACT.CANCELED]: {
            ...styles,
            Border: '1px solid #D4D6D8',
            Color: '#54575C',
            bGround: '#F4F5F5',
        },
        [STATUS_CONTRACT.SALE]: {
            ...styles,
            Border: '1px solid #ACACFF',
            Color: '#000061',
            bGround: '#E5E5FF',
        },
    };
    return CUSTOME[product];
};

export const contentForm = {
    Radius: '16px 0px 0px 16px',
    FlexDir: 'column',
    Width: '25rem',
    Height: '100vh',
    Align: 'start',
    Justify: 'start',
    Padding: '1.5rem',
    Gap: '1.5rem',
};

export const conceptFilter = {
    FlexDir: 'Column',
    Align: 'start',
    Height: 'min-content',
    Gap: '0.25rem',
    text: {FontWeight: '700', Color: '#1D1E20'},
};

export const contenSectionDeatil = {
    Gap: '1.5rem',
    Radius: '1.5rem',
    Border: '1px solid #E8E9EA',
    backG: '#FFF',
    Height: 'min-content',
    Padding: '1.5rem',
};

export const datailContent = {
    Padding: '1.5rem 3.75rem',
    FlexDir: 'Column',
    backG: '#FAFAFA',
    Align: 'start',
    Justify: 'start',
    Gap: '1.5rem',
};

export const resumeContent = {
    Border: '1px solid #0D166B',
    Radius: '1rem',
    Padding: '1rem',
    Display: 'grid',
    GridColumns: 'repeat(2,1fr) 2fr repeat(2,auto)',
    detail: {Color: '#1D1E20', FontSize: '1.25rem', FontWeight: '700'},
    sections: {
        FlexDir: 'column',
        Align: 'start',
        Bl: '1px solid #E8E9EA',
    },
};

export const garments = {
    Gap: '1.5rem',
    Padding: '1.5rem 0 0 0',
    Bt: '1px solid #E8E9EA',
    Align: 'start',
    FlexDir: 'Column',
    titles: {Color: '#2A2C2F', FontWeight: '600'},
};

export const contentGarments = {FlexDir: 'column', Align: 'start', Gap: '0.25rem'};

export const tabsGarments = {
    Gap: '1rem',
    FlexDir: 'column',
    Align: 'center',
    Justify: 'start',
};

export const buttonsSwiper = {
    backGround: '#FFFFFF',
    height: '2rem',
    width: '1.5rem',
    padding: '0',
    cursor: 'pointer',
    border: 'none',
    hBackG: '#ffffff',
};

export const styleStatusTransaction = (status: string) => {
    const gralStyles = {
        BorderRadius: '2rem',
        Padding: '0.125rem 0.5rem',
        FontSize: '0.75rem',
        FontWeight: '500',
        Width: 'min-content',
    };
    const STYLE: {[key: string]: ISylesTransactions} = {
        [OPERATIONS.TRANSACTIONS.STATUS.PAID]: {
            ...gralStyles,
            Border: '1px solid #B1E7C9',
            Color: '#174A2E',
            bGround: '#E4F7EC',
        },
        [OPERATIONS.TRANSACTIONS.STATUS.PENDING]: {
            ...gralStyles,
            Border: '1px solid #FFE299',
            Color: '#614500',
            bGround: '#FFF5DB',
        },
        [OPERATIONS.TRANSACTIONS.STATUS.INCIDENCE]: {
            ...gralStyles,
            Border: '1px solid #FFC6F6',
            Color: '#610052',
            bGround: '#FFE5FB',
        },
    };
    return STYLE[status];
};
