import {useForm} from 'react-hook-form';
import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';

import {schema} from '@components/SignUp/validationsYup';
import {ISignUpData} from '@components/SignUp/interfaces';
import {
    ContainerCellGrid,
    ContainerImg,
    Input,
    LinkAtaskate,
    Text,
} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import ButtonGeneral from '@General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {VisibilityOn} from '@components/SignUp/Icons/VisibilityOn';
import {VisibilityOff} from '@components/SignUp/Icons/VisibilityOff';
import {
    catTypeBusiness,
    catTypePlaces,
} from '@components/SignUp/Redux/Actions/catPlaceAndBusinessAction';
import {validateEmailExist} from '@components/SignUp/Redux/Actions/validateEmailAction';
import {infoCompanyBenefit} from '@components/SignUp/Redux/Actions/companyBenefitAction';
import {PERSONAL_FORM, PASSWORD_REGEX} from '@components/SignUp/constants';
import iconError from '@/images/iconError.svg';
import iconSuccessPwd from '@/images/iconSuccessPwd.svg';

export const CreateAccount = () => {
    const dispatch = useDispatch();
    const [viewPassword, setViewPassword] = useState(false);
    const defaultValues = useSelector((state: RootState) => state.saveDataSingUp.accountData);

    const loading = useSelector((state: RootState) => state.validateEmailAdministrator.loading);

    const {
        register,
        handleSubmit,
        formState: {errors},
        setError,
        watch,
        getValues,
    } = useForm<ISignUpData>({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver: yupResolver(schema),
    });

    const onsubmit = (data: ISignUpData) => {
        dispatch(validateEmailExist(data, setError));
    };

    const disabledButton = () => {
        const valuesForm = watch();
        const {password, confirmPassword, ...otherFields} = valuesForm;
        const allFieldsValid = Object.values(otherFields).every((value) => value?.length > 2);
        const isPasswordValid = PASSWORD_REGEX.test(password);
        const isConfirmPasswordValid = confirmPassword === password;
        return allFieldsValid && isPasswordValid && isConfirmPasswordValid;
    };

    useEffect(() => {
        dispatch(catTypeBusiness());
        dispatch(catTypePlaces());
        dispatch(infoCompanyBenefit());
    }, []);
    const passwordValue = getValues('password');
    return (
        <>
            <ContainerCellGrid Gap="8px" FlexDir="column" Align="center" Width="34vw">
                <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="500">
                    {PERSONAL_FORM.TITLE}
                </Text>
                <Text Color="#2A2C2F" FontSize="1rem">
                    {PERSONAL_FORM.SUBTITLE}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="16px" Justify="space-between" Width="34vw">
                <ContainerCellGrid Gap="4px" Width="16.5vw" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        {PERSONAL_FORM.NAME}
                    </Text>
                    <Input {...register('names')} error={errors.names?.message} Cursor="text" />
                    {errors.names?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.names.message}
                        </Text>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid Gap="4px" Width="16.5vw" FlexDir="column">
                    <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                        {PERSONAL_FORM.LASTNAME}
                    </Text>
                    <Input
                        {...register('secondsNames')}
                        error={errors.secondsNames?.message}
                        Cursor="text"
                    />
                    {errors.secondsNames?.message && (
                        <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0" wSpace="normal">
                            {errors.secondsNames.message}
                        </Text>
                    )}
                </ContainerCellGrid>
            </ContainerCellGrid>
            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    {PERSONAL_FORM.EMAIL}
                </Text>
                <Input
                    {...register('email')}
                    error={errors.email?.message}
                    Cursor="text"
                    autoComplete="off"
                />
                {errors.email?.message && (
                    <Text FontSize="0.7rem" Color="#FF6357" Padding="4px 0">
                        {errors.email.message}
                    </Text>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    {PERSONAL_FORM.PASSWORD}
                </Text>
                <SearchInput
                    Height="40px"
                    Position="none"
                    GridColumn="90% 10%"
                    FontSize="1rem"
                    error={errors.password?.message}
                >
                    <input
                        type={viewPassword ? 'text' : 'password'}
                        {...register('password')}
                        autoComplete="off"
                    />
                    <ContainerImg onClick={() => setViewPassword(!viewPassword)} Cursor="pointer">
                        {viewPassword ? <VisibilityOn /> : <VisibilityOff />}
                    </ContainerImg>
                </SearchInput>
                {passwordValue?.length >= 8 ? (
                    <Text FontSize="0.7rem" Color="#309C60" Padding="20px 0 0 0">
                        <img src={iconSuccessPwd} alt="success-matches" />{' '}
                        {PERSONAL_FORM.MESSAGE_PASSWORD_MIN}
                    </Text>
                ) : (
                    <Text FontSize="0.7rem" Color="#FF6357" Padding="20px 0 0 0">
                        <img src={iconError} alt="error-matches" />{' '}
                        {PERSONAL_FORM.MESSAGE_PASSWORD_MIN}
                    </Text>
                )}
                {PASSWORD_REGEX.test(passwordValue) ? (
                    <Text FontSize="0.7rem" Color="#309C60">
                        <img src={iconSuccessPwd} alt="success-matches" />{' '}
                        {PERSONAL_FORM.MESSAGE_PASSWORD_MATCH}{' '}
                    </Text>
                ) : (
                    <Text FontSize="0.7rem" Color="#FF6357">
                        <img src={iconError} alt="error-matches" />{' '}
                        {PERSONAL_FORM.MESSAGE_PASSWORD_MATCH}{' '}
                    </Text>
                )}
            </ContainerCellGrid>
            <ContainerCellGrid Gap="4px" Justify="space-between" Width="34vw" FlexDir="column">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="500">
                    {PERSONAL_FORM.CONFIRMPASS}
                </Text>
                <Input
                    {...register('confirmPassword')}
                    type={viewPassword ? 'text' : 'password'}
                    Cursor="text"
                    error={errors.confirmPassword?.message}
                    autoComplete="off"
                />
                {watch('confirmPassword') && watch('confirmPassword') === watch('password') ? (
                    <Text FontSize="0.7rem" Color="#309C60" Padding="20px 0 0 0">
                        <img src={iconSuccessPwd} alt="success-matches" />{' '}
                        {PERSONAL_FORM.MESSAGE_CONFIRM_NO_MATCH}
                    </Text>
                ) : (
                    <Text FontSize="0.7rem" Color="#FF6357" Padding="20px 0 0 0">
                        <img src={iconError} alt="error-matches" />{' '}
                        {PERSONAL_FORM.MESSAGE_CONFIRM_NO_MATCH}
                    </Text>
                )}
            </ContainerCellGrid>
            {loading ? (
                <LoadingAtaskate />
            ) : (
                <ButtonGeneral
                    text={PERSONAL_FORM.BTNCONTINUE}
                    width="100%"
                    cursor="pointer"
                    clic={handleSubmit(onsubmit)}
                    disabled={!disabledButton()}
                />
            )}
            <LinkAtaskate Color="#5A5AFF" FontWeight="500" Cursor="pointer" to={'/Login'}>
                {PERSONAL_FORM.LOGIN}
            </LinkAtaskate>
        </>
    );
};
