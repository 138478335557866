import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    ADD_WIRE_TRANSFER_LOAN_ERROR,
    ADD_WIRE_TRANSFER_LOAN_START,
    ADD_WIRE_TRANSFER_LOAN_SUCCESS,
    CLEAR_ADD_WIRE_TRANSFER_LOAN_STATE,
} from '@components/PersonalLoans/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';

const addWireTransferStart = () => {
    return {
        type: ADD_WIRE_TRANSFER_LOAN_START,
    };
};

const addWireTransferSuccess = (result: AxiosResponse) => {
    return {
        type: ADD_WIRE_TRANSFER_LOAN_SUCCESS,
        payload: result,
    };
};

const addWireTransferError = (error: AxiosError) => {
    return {
        type: ADD_WIRE_TRANSFER_LOAN_ERROR,
        error: error,
    };
};

function addWireTransferAction({
    token,
    personalCreditId,
}: {
    token: string;
    personalCreditId: number;
}) {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddWireTransferPersonalLoan}`;
    const data = {
        personalCreditId,
    };
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(addWireTransferStart());
        try {
            const response = await axios.put(url, data, {headers});
            dispatch(addWireTransferSuccess(response.data));
        } catch (e) {
            dispatch(addWireTransferError(e as AxiosError));
        }
    };
}

export const clearWireTransferState = () => {
    return {
        type: CLEAR_ADD_WIRE_TRANSFER_LOAN_STATE,
    };
};

export default addWireTransferAction;
