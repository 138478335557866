import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {InstallmentMenuProps} from '@components/ShoppingCart/interface';
import {renewalMenuStyles} from '@components/ShoppingCart/styles';

const InstallmentMenu: React.FC<InstallmentMenuProps> = ({menuOptions}) => {
    return (
        <FlexPanel
            FlexDir="column"
            Align="flex-start"
            Self="stretch"
            Border="1px solid #F3F3F3"
            Background="#FFFFFF"
            BoxS="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
        >
            {menuOptions.map((option, index) => (
                <Text
                    key={index}
                    {...renewalMenuStyles}
                    Color={option.color}
                    onClick={option.onClick}
                    Cursor={option.onClick ? 'pointer' : 'default'}
                >
                    {option.label}
                </Text>
            ))}
        </FlexPanel>
    );
};
export default InstallmentMenu;
