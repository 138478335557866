import {
    GET_ITEMS,
    GET_ITEMS_ERROR,
    GET_ITEMS_SUCCESS,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {updateArticleChildren} from '@components/RequestNewCatalogLevel/Redux/Actions/GetCatGroupAction';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getItems = () => {
    return {
        type: GET_ITEMS,
    };
};

export const getItemsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ITEMS_SUCCESS,
        payload: result,
    };
};

export const getItemsError = (error: AxiosError) => {
    return {
        type: GET_ITEMS_ERROR,
        payload: error,
    };
};

export const getAuctionItemsAction = (brandId: number, companyId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getItems());
        const params = {
            brandId: brandId?.toString(),
            companyId: companyId?.toString(),
        };
        const queryString = new URLSearchParams(params).toString();
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAuctionItems}?${queryString}`;
        try {
            const response = await axios.get(url, {headers});
            dispatch(getItemsSuccess(response.data.data));
            dispatch(updateArticleChildren(brandId, response.data.data));
        } catch (error) {
            dispatch(getItemsError(error as AxiosError));
        }
    };
};
