import React, {useEffect, useState} from 'react';

import {ToastContainer} from '@General/Atoms/AppToast/styles';
import {ToastType, ToastProps, ActionKey} from '@General/Atoms/AppToast/interfaces';
import {toastColors, APPTOAST} from '@General/Atoms/AppToast/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import successIcon from '@images/check_circle.svg';
import errorIcon from '@images/close_circle.svg';
import warningIcon from '@images/warningIconeYellow.svg';
import infoIcon from '@images/Info_blue.svg';
import closeIcon from '@images/close.svg';

const Toast: React.FC<ToastProps> = ({
    id,
    title,
    message,
    type,
    size = APPTOAST.SIZE_LARGE,
    onClose,
    duration,
    button,
}) => {
    const [isVisible, setIsVisible] = useState(true);

    const getIcon = (type: ToastType) => {
        const icons = {
            success: successIcon,
            error: errorIcon,
            warning: warningIcon,
            info: infoIcon,
        };

        return icons[type] || icons.info;
    };

    const handleButtonAction = () => {
        if (button?.action) {
            button.action();
        }
        onClose(id);
    };

    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsVisible(false);
            setTimeout(() => {
                onClose(id);
            }, 500);
        }, duration);

        return () => {
            clearTimeout(timeout);
        };
    }, [onClose, id, duration]);

    const gap = button && size !== APPTOAST.SIZE_SMALL ? '1.5rem' : '1rem';
    const flexDir = (button || !button) && size !== APPTOAST.SIZE_SMALL ? 'row' : 'column';

    const actionMap = {
        smallWithButton: (
            <Text
                Color="#5A5AFF"
                FontWeight="500"
                FontSize="1rem"
                Cursor="pointer"
                TextOverflow="visible"
                onClick={handleButtonAction}
            >
                {button?.text}
            </Text>
        ),
        smallWithoutButton: (
            <Text
                Color="#5A5AFF"
                FontWeight="500"
                FontSize="1rem"
                TextOverflow="visible"
                onClick={() => onClose(id)}
                Cursor="pointer"
            >
                {APPTOAST.CLOSE}
            </Text>
        ),
        largeWithButton: (
            <Text
                Color="#5A5AFF"
                FontWeight="500"
                FontSize="1rem"
                Cursor="pointer"
                TextOverflow="visible"
                Self="center"
                onClick={handleButtonAction}
            >
                {button?.text}
            </Text>
        ),
        largeWithoutButton: (
            <Image
                alt="closeicon"
                src={closeIcon}
                Width="1.5rem"
                Height="1.5rem"
                Self="center"
                onClick={() => onClose(id)}
                Cursor="pointer"
            />
        ),
    };

    const sizeButtonMap: Record<string, ActionKey> = {
        [`${APPTOAST.SIZE_SMALL}_true`]: 'smallWithButton',
        [`${APPTOAST.SIZE_SMALL}_false`]: 'smallWithoutButton',
        [`${APPTOAST.SIZE_LARGE}_true`]: 'largeWithButton',
        [`${APPTOAST.SIZE_LARGE}_false`]: 'largeWithoutButton',
    };
    const actionKey = sizeButtonMap[`${size}_${!!button}`] as ActionKey;

    if (!isVisible) {
        return null;
    }
    return (
        <ToastContainer type={type} isVisible={isVisible} size={size}>
            <ContainerFlex Gap="0.5rem" Align="flex-start">
                <Image alt="toast-icon" src={getIcon(type)} Width="1.25rem" Height="1.25rem" />
                <ContainerFlex Gap={gap} FlexDir={flexDir} Align="flex-start">
                    <ContainerFlex Gap="0.5rem" FlexDir="column" Align="flex-start" Width="100%">
                        <Text Color={toastColors[type].textColor} FontSize="1rem" FontWeight="500">
                            {title}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400" wSpace="normal">
                            {message}
                        </Text>
                    </ContainerFlex>
                    {actionMap[actionKey]}
                </ContainerFlex>
            </ContainerFlex>
        </ToastContainer>
    );
};

export default Toast;
