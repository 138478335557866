import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {IAccordionProps} from '@/components/SideBarMenu/Accordion/interfaces';

const Accordion: React.FC<IAccordionProps> = ({children, expanded, ...rest}) => {
    const [summary, details] = React.Children.toArray(children);
    return (
        <ContainerFlex {...rest}>
            {summary}
            {expanded && details}
        </ContainerFlex>
    );
};

export default Accordion;
