import React from 'react';
import {Text, Image, ContainerFlex} from '@Shopify/Ecommerce/styles';
import arrowTopLeftIcon from '@images/arrowTopLeft.svg';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {IInstallmentSummaryProps} from '@Loan/PaymentQuoter/Interfaces';
import {INSTALLMENTS_SUMMARY} from '@Loan/PaymentQuoter/constants';

const InstallmentSummary: React.FC<IInstallmentSummaryProps> = ({pass}) => {
    return (
        <>
            <ContainerFlex Justify="flex-start" Gap="1rem" Self="stetch">
                <ContainerFlex Width="1.5rem" Height="2.125rem" Padding="0.188rem 0.25rem">
                    <Image src={arrowTopLeftIcon} alt="arrow" Width="0.938rem" Height="1.063rem" />
                </ContainerFlex>
                <Text Color="#1D1E20" FontWeight="700" LetterSpacing="0.019rem">
                    {INSTALLMENTS_SUMMARY.PAYMENT}
                </Text>
                <Text Color="#1D1E20" FontWeight="400" LetterSpacing="0.019rem">
                    {INSTALLMENTS_SUMMARY.OUTSTANDING_BALANCE}
                    {numberToCurrency(pass.totalPass)}
                </Text>
            </ContainerFlex>
        </>
    );
};
export default InstallmentSummary;
