import React from 'react';
import {components} from 'react-select';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {
    IOptionProps,
    IRequestChannelSelect,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {CheckboxSelect} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/CheckBoxSelect';

export const Option: React.FC<IOptionProps> = ({props, selectAll}) => {
    const {Option} = components;
    const data = props.data as IRequestChannelSelect;
    return (
        <Option {...props}>
            <Text Color="#2A2C2F" Height="max-content" Gap="0.5rem">
                {data.value !== 3 && <Text MarginLeft="1rem" />}
                <CheckboxSelect checked={data.value !== 3 ? props.isSelected : selectAll} />
                {data.label}
            </Text>
        </Option>
    );
};
