import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {RootState} from '@/config/store';
import {ContainerFlex, ContainerGrid, Text} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_DETAIL_TABLES} from '@/components/CreditsAndPawns/constants';
import {Select} from '@components/Quoter/Steps/styles';
import {IOptionType} from '@/components/CreditsAndPawns/interfaces';
import CreditDetailTableRow from '@/components/CreditsAndPawns/CreditDetail/CreditDetailTableRow';
import {getCreditDetailPaymentAction} from '@/components/CreditsAndPawns/Redux/Actions/GetCreditDetailPaymentActions';

const CreditDetailFeesTable = () => {
    const {id} = useParams<{id?: string}>();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {creditDetailPayments} = useSelector((state: RootState) => state.getCreditDetailPayment);

    const dispatch = useDispatch();
    const filterOptions: IOptionType[] = [
        {label: CREDIT_DETAIL_TABLES.FILTER.ALL, value: CREDIT_DETAIL_TABLES.FILTER_VALUES.ALL},
        {
            label: CREDIT_DETAIL_TABLES.FILTER.PAID,
            value: CREDIT_DETAIL_TABLES.FILTER_VALUES.PAID,
        },
        {
            label: CREDIT_DETAIL_TABLES.FILTER.UNPAID,
            value: CREDIT_DETAIL_TABLES.FILTER_VALUES.UNPAID,
        },
        {
            label: CREDIT_DETAIL_TABLES.FILTER.OVERDUE,
            value: CREDIT_DETAIL_TABLES.FILTER_VALUES.OVERDUE,
        },
    ];

    useEffect(() => {
        if (token && id) {
            const creditDetailPaymentProps = {
                token,
                creditId: String(id),
                statusId: null,
            };
            dispatch(getCreditDetailPaymentAction(creditDetailPaymentProps));
        }
    }, [token, id]);

    const handleChangeFilter = (selectedOption: IOptionType) => {
        const creditDetailPaymentProps = {
            token,
            creditId: String(id),
            statusId: Number(selectedOption.value) || null,
        };
        dispatch(getCreditDetailPaymentAction(creditDetailPaymentProps));
    };
    return (
        <>
            <ContainerFlex
                Height="40px"
                Width="100%"
                Justify="space-between"
                Align="start"
                Flex="0"
            >
                <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#2A2C2F">
                    {CREDIT_DETAIL_TABLES.FEE_TITLE}
                </Text>
                <Select
                    noOptionsMessage={() => CREDIT_DETAIL_TABLES.FILTER.NO_OPTION}
                    options={filterOptions}
                    width="223px"
                    padding="0px"
                    isSearchable={false}
                    defaultValue={{
                        label: CREDIT_DETAIL_TABLES.FILTER.ALL,
                        value: CREDIT_DETAIL_TABLES.FILTER_VALUES.ALL,
                    }}
                    BorderBOptions="0px"
                    onChange={(e) => handleChangeFilter(e as IOptionType)}
                />
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Width="100%"
                Height="calc(100% - 120px)"
                Radius="8px"
                Border="1px solid #E8E9EA"
                Justify="start"
                Align="start"
                OverFlowY="auto"
            >
                <ContainerGrid
                    BorderR="8px 8px 0 0"
                    bGround="#FAFAFA"
                    BorderBt="1px solid #E8E9EA"
                    Padding="8px 16px"
                    Height="40px"
                    MinH="40px"
                    Width="100%"
                    GridColumns="repeat(6, 1fr)"
                    Justify="space-between"
                >
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.FEE_TABLE.FEE_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.FEE_TABLE.EXPIRATION_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.FEE_TABLE.STATUS_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.FEE_TABLE.PAID_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {CREDIT_DETAIL_TABLES.FEE_TABLE.PAYMENT_METHOD_FIELD}
                    </Text>
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color="#2A2C2F"
                        Align="center"
                        Justify="center"
                    >
                        {' '}
                    </Text>
                </ContainerGrid>
                <ContainerFlex
                    FlexDir="column"
                    Height="100%"
                    MaxH="500px"
                    Width="100%"
                    OverFlowY="auto"
                    Justify="start"
                    Align="start"
                >
                    {creditDetailPayments?.map((data, index) => {
                        const {
                            numberQuotas,
                            dueDate,
                            quotaStatus,
                            paymentDate,
                            cardTypeUrl,
                            cardNumber,
                        } = data;
                        const organizedData = {
                            numberQuotas,
                            dueDate,
                            quotaStatus,
                            paymentDate,
                            cardTypeUrl,
                            cardNumber,
                        };
                        return (
                            <CreditDetailTableRow
                                key={index}
                                data={organizedData}
                                isFeesTable={true}
                            />
                        );
                    })}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};

export default CreditDetailFeesTable;
