import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, ContainerForm, Image} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {
    DATAPASSED,
    formUsers,
    IMAGE_ALT,
    LadaDefault,
    STEP_FORM_BTN,
} from '@components/PersonalLoans/constants';
import {DataPersonalLoans} from '@components/PersonalLoans/interfaces';
import {PersonalDataLoan} from '@components/PersonalLoans/PersonalDataLoan';
import {AdressLoans} from '@components/PersonalLoans/AdressLoans';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaUserLoans} from '@components/PersonalLoans/schemaPersonalLoans';
import {CreatePersonalLoansForm} from '@components/PersonalLoans/Redux/Actions/CreatePersonalLoans';
import {AddPersonalDataLoans} from '@components/PersonalLoans/Redux/Actions/SaveLoansUser';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {UpdatePersonalLoansForm} from '@components/PersonalLoans/Redux/Actions/UpdatePersonalLoans';
import {TransversalLoader} from '@components/General/Atoms/TransversalLoader/TransversalLoader';
import {SelectGetAllGenderPersonalLoan} from '@components/PersonalLoans/Redux/Actions/GetAllGenderPersonalLoan';
import {DivisorLine} from '@/components/MyAccount/styles';
import {useNavigate} from 'react-router-dom';
import arrowPurple from '@images/arrow small.svg';
import arrowWhite from '@images/Arrow_Wihite.svg';

export const PersonalLoansStepOne = () => {
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const saveLoansUser = useSelector((state: RootState) => {
        return state.SaveLoansUser;
    });
    const [nationSelected, setNationSelected] = useState<IGetAllNations>(LadaDefault);
    const IPersonalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId as number;
    });
    const DataPassed = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.passed as number;
    });
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        control,
        setValue,
        trigger,
        watch,
        reset,
        formState: {errors},
    } = useForm<DataPersonalLoans>({
        resolver: yupResolver(schemaUserLoans),
        defaultValues: formUsers,
    });
    const {
        name = formUsers.name,
        lastName = formUsers.lastName,
        maternalSurname = formUsers.maternalSurname,
        catGenderId = formUsers.catGenderId,
        curp = formUsers.curp,
        birthdate = formUsers.birthdate,
        phone = formUsers.phone,
        catLadaId = formUsers.catLadaId,
        addressPersonalLoans = {
            street: formUsers.addressPersonalLoans.street,
            interiorNumber: formUsers.addressPersonalLoans.interiorNumber,
            externalNumber: formUsers.addressPersonalLoans.externalNumber,
            postalCode: formUsers.addressPersonalLoans.postalCode,
            catColonyId: formUsers.addressPersonalLoans.catColonyId,
            catMunicipaliyId: formUsers.addressPersonalLoans.catMunicipaliyId,
            catStateId: formUsers.addressPersonalLoans.catStateId,
        },
    } = saveLoansUser.personal || formUsers;

    useEffect(() => {
        reset({
            name,
            lastName,
            maternalSurname,
            catGenderId,
            catLadaId,
            curp,
            birthdate,
            phone,
            addressPersonalLoans,
        });
        dispatch(SelectGetAllGenderPersonalLoan(token));
    }, [saveLoansUser.personal]);

    const formData = watch();
    useEffect(() => {
        const handleBeforeUnload = (e: {returnValue: string; preventDefault: () => void}) => {
            dispatch(AddPersonalDataLoans(formData));
            e.preventDefault();
            e.returnValue = '';
        };
        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [formData, dispatch]);

    const onSubmit = (data: DataPersonalLoans) => {
        const LoansData = {
            ...data,
            catLadaId: nationSelected?.countryId,
            addressPersonalLoans: {
                ...data.addressPersonalLoans,
                catColonyId: Number(data.addressPersonalLoans.catColonyId.value),
                catMunicipaliyId: Number(data.addressPersonalLoans.catMunicipaliyId.value),
                catStateId: Number(data.addressPersonalLoans.catStateId.value),
            },
        };
        const {interiorNumber, ...restAddresInfo} = LoansData.addressPersonalLoans;
        const updatedLoansData = {
            ...LoansData,
            addressPersonalLoans: {
                ...restAddresInfo,
                internalNumber: interiorNumber,
            },
            personalInformationId: IPersonalInformationId,
        };
        dispatch(AddPersonalDataLoans(data));
        if (IPersonalInformationId && IPersonalInformationId > 0 && DataPassed === DATAPASSED.ONE) {
            dispatch(UpdatePersonalLoansForm(updatedLoansData, token));
        } else {
            dispatch(CreatePersonalLoansForm(LoansData, token));
        }
    };
    const createPersonalLoansLoading = useSelector(
        (state: RootState) => state.CreatePersonalLoans.loading
    );
    const updatePersonalLoanLoading = useSelector(
        (state: RootState) => state.UpdatePersonalLoans.loading
    );
    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            BackG="none"
            Align="start"
            FlexDir="column"
            Justify="start"
            Gap="1.5rem"
        >
            <PersonalDataLoan
                register={register}
                control={control}
                setValue={setValue}
                watch={watch}
                errors={errors}
                setNationSelected={setNationSelected}
            />
            <DivisorLine height="1px" width="100%" />
            <AdressLoans
                register={register}
                control={control}
                trigger={trigger}
                setValue={setValue}
                watch={watch}
                reset={reset}
                errors={errors}
            />
            <ContainerFlex Justify="space-between">
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="700"
                    Width="12.313rem"
                    BackGC="transparent"
                    HBackG="#E5E5FF"
                    Color="#5A5AFF"
                    Border="solid 1px #5A5AFF"
                    type="button"
                    onClick={() => navigate(-1)}
                >
                    <Image
                        src={arrowPurple}
                        Transform="rotate(180deg)"
                        alt={IMAGE_ALT.PURPLE_ARROW}
                    />
                    {STEP_FORM_BTN.CANCEL}
                </ButtonGenerals>
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="700"
                    Width="12.313rem"
                    type="submit"
                >
                    {STEP_FORM_BTN.CONTINUE}
                    <Image
                        src={arrowWhite}
                        Transform="rotate(-90deg)"
                        alt={IMAGE_ALT.WHITE_ARROW}
                    />
                </ButtonGenerals>
            </ContainerFlex>
            {(createPersonalLoansLoading || updatePersonalLoanLoading) && <TransversalLoader />}
        </ContainerForm>
    );
};
