import {WITHOUTPRICE} from '@/components/Quoter/constants';
import {inputStyle} from '@Login/interfaces';
import {DateTime} from 'luxon';
import imageCompression from 'browser-image-compression';

export const PercentageFormat = (num: number) => {
    return num + '%';
};
const regex = /\((\d+)\)/;
export const Quantity = (message: string) => {
    const messageQuantity = message.match(regex);
    if (messageQuantity) return Number(messageQuantity[1]);
    else {
        return 0;
    }
};
export const formatDateData = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};
export const removeNumberInParentheses = (str?: string): string => {
    if (!str) return '';

    const match = str.match(/^(.*?)(?=\s*\(\d+\))/);
    return match ? match[0].trim() : str;
};
export const formatDate = (date: DateTime | null, format?: string): string => {
    if (!date) return '';

    switch (format) {
        case 'MM/yyyy':
            return date.toFormat('MM/yyyy');
        case 'dd/MM':
            return date.toFormat('dd/MM');
        case 'dd/MM/yyyy':
            return date.toFormat('dd/MM/yyyy');
        default:
            return date.setLocale('es').toFormat('d MMMM yyyy');
    }
};

export const QuitNumberFormat = (num: string | number) => {
    if (Number(num) >= 0) {
        return Number(num);
    }
    return Number(String(num).replaceAll('$', '').replaceAll('%', '').replaceAll(',', ''));
};
export const formatterDolar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

export function addSpaceToCurrency(currency: string) {
    return currency.replace('$', '$ ');
}

export const withoutPrices = (value: number, type: string) => {
    let without = WITHOUTPRICE.NOPRICE;
    if (value && type === WITHOUTPRICE.AVG) without = PercentageFormat(value);
    if (value && type === WITHOUTPRICE.DOLLAR) without = formatterDolar.format(value);
    return without;
};

export const handleKeypress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "'" || e.key === '"') {
        e.preventDefault();
    }
};
export const formatPhoneNumber = (value: string) => {
    if (!value) return value;
    const phoneNumber = value.replace(/[^\d]/g, '');
    const phoneNumberLength = phoneNumber.length;
    if (phoneNumberLength < 4) return phoneNumber;
    if (phoneNumberLength < 7) {
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
    }
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};

export const limitNumberFormat = (
    currentValue: number,
    initialValue: number,
    finalValue: number
) => {
    if (currentValue !== undefined) {
        if (currentValue >= initialValue && currentValue <= finalValue) {
            return true;
        }
    }
    return false;
};

export const errorInputStyle = (err: inputStyle) => {
    if (err.nameStyle && err.error) {
        const STYLE_INPUT: {[key: string]: string} = {
            hover: '1px solid #ef4f55',
            outline: err.style ? '2px solid #ef4f55' : '',
            border: '1px solid #ef4f55',
        };
        return STYLE_INPUT[err.nameStyle];
    }

    if (err.nameStyle) {
        const STYLE_INPUT: {[key: string]: string} = {
            hover: '1px solid #35cf44',
            outline: err.style ? '2px solid #35cf44' : '0px 4px 4px rgba(122, 193, 67, 0.2)',
            border: '1px solid #e4e7e9',
        };
        return STYLE_INPUT[err.nameStyle];
    }
};

export const numberToCurrency = (number: number) => {
    return new Intl.NumberFormat('ES-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 2,
    }).format(number);
};

export const numberToCurrencyWithoutCents = (number: number) => {
    return new Intl.NumberFormat('ES-MX', {
        style: 'currency',
        currency: 'MXN',
        maximumFractionDigits: 0,
    }).format(number);
};

export const endeavorsLimit = (currentValue: number, initialValue: number, finalValue: number) => {
    if (currentValue !== undefined) {
        if (currentValue >= initialValue && currentValue <= finalValue) {
            return true;
        }
    }
    return false;
};

export const formatNum = (data: {number: number; fractionDigits: number; money: boolean}) => {
    const formater = {
        currency: 'MXN',
        maximumFractionDigits: data.fractionDigits,
    };

    return new Intl.NumberFormat(
        'ES-MX',
        data.money ? {...formater, style: 'currency'} : formater
    ).format(data.number);
};

export const maskNumberPhone = (value: string) => {
    const numericValue = value.replace(/\D/g, '');

    let formattedPhoneNumber = '';
    numericValue.split('').forEach((char: string, index: number) => {
        if (index === 0) {
            formattedPhoneNumber += '(';
        }
        if (index === 3) {
            formattedPhoneNumber += ') ';
        }
        if (index === 6) {
            formattedPhoneNumber += '-';
        }
        formattedPhoneNumber += char;
    });

    return formattedPhoneNumber;
};

export const formatterWithoutDigits = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
});

export const formatHours = (hour: string) => DateTime.fromISO(hour).toFormat('HH:mm');

export function toCamelCase(text: string): string {
    return text
        .toLowerCase()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .split(' ')
        .map((word, index) => (index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)))
        .join('');
}

export const compressImage = async (file: File): Promise<File> => {
    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    };
    return await imageCompression(file, options);
};

export const isSupportedFileType = (file: File): boolean => {
    const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf', 'image/jpg'];
    return allowedTypes.some((type) => file.type.startsWith(type));
};
