import {IconContainer} from '@/components/Quoter/styles';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import point from '@/images/bluePoint.svg';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {AuctionPassText, DinamicPadding, options} from '@/components/AuctionPass/AuctionConstants';
import SelectGeneral from '@components/General/Atoms/Select/Select';
import {ImageLogo} from '@components/LoginClient/styles';
import {MAXIMUN_LEVEL} from '@components/RequestNewCatalogLevel/operational/constant';
import {idSelected} from '@components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {TableItem} from '@DeadLines/styles';
import closeModal from '@images/CloseModal.svg';
import ConfirmButton from '@images/CheckAuction.svg';
import editIcon from '@images/PencilIcon.svg';
import saveCheckImg from '@images/SaveChangesCheck.svg';
import {TextEllipsis} from '@Shopify/Ecommerce/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ICatalogItems, IEditItem, ISentAuctionData} from '@components/AuctionPass/interfaces';
import {getAuctionFamilyAction} from '@components/AuctionPass/Redux/Actions/GetFamilyAction';
import {putEditCalaogueAction} from '@components/AuctionPass/Redux/Actions/PutEditCatalogAction';

export const TreeComponent = (data: ISentAuctionData) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: ICatalogItems, index: number) => {
                    return (
                        <TreeNode
                            node={tree}
                            level={data.level + 1}
                            isView={data.isView}
                            fatherId={data.fatherId}
                            key={index}
                        />
                    );
                })}
        </>
    );
};

const TreeNode = (node: {
    node: ICatalogItems;
    level: number;
    isView: boolean;
    fatherId: number;
}) => {
    const dispatch = useDispatch();
    const [isEditable, setIsEditable] = useState(false);
    const [editableItemId, setEditableItemId] = useState<number | null>(null);
    const [showMessage, setShowMessage] = useState(false);
    const [data, setData] = useState<IEditItem>({
        item: {
            level: 0,
            commercializationIncrease: 0,
            amount: 0,
            daysOnHold: 0,
            id: 0,
        },
        companyId: 0,
    });

    const [nextChild, setNextChild] = useState(false);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );

    const IdSelected = useSelector((state: RootState) => state.AddNewLevel);
    const sendName =
        node.node.groupName ||
        node.node.familyName ||
        node.node.subFamilyName ||
        node.node.brandName ||
        node.node.itemName;

    const handleEditClick = (id: number, level: number) => {
        if (editableItemId !== id) {
            setEditableItemId(id);
            setIsEditable(true);
        } else {
            setEditableItemId(null);
            setIsEditable(false);
        }
        setData((prevData) => {
            if (companyId) {
                return {
                    ...prevData,
                    companyId: companyId,
                    item: {
                        ...prevData.item,
                        level: level - 1,
                        id: id,
                    },
                };
            }
            return prevData;
        });
    };

    const submitEditItems = async () => {
        try {
            await dispatch(putEditCalaogueAction(data, token));
        } catch (error) {
            return error;
        } finally {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, 5000);
        }
    };

    useEffect(() => {
        if (
            node.level === 1 &&
            IdSelected.id === node.node.id &&
            !node.node.children &&
            token &&
            !node.isView &&
            companyId
        ) {
            dispatch(getAuctionFamilyAction(node.node.id, companyId, token));
        }
    }, [IdSelected.id, dispatch]);

    useEffect(() => {
        const levelMap = new Map<number, keyof typeof IdSelected.breadCrumb>([
            [1, 'groupId'],
            [2, 'familyId'],
            [3, 'subFamilyId'],
            [4, 'brandId'],
        ]);
        const breadCrumbKey = levelMap.get(node.level);

        if (breadCrumbKey && IdSelected.breadCrumb[breadCrumbKey] === node.node.id) {
            dispatch(idSelected(node.node.id));
            setNextChild(true);
        }
    }, [IdSelected.breadCrumb, dispatch]);

    return (
        <>
            <TableRowItems
                GridColumn="1.5fr 1fr 1fr 1.2fr minmax(100px, 0.3fr)"
                Cursor="auto"
                Width="100%"
                Border=""
                Height="4rem"
            >
                <TableItem
                    Justify="start"
                    PaddingLeft={DinamicPadding(node.level)}
                    Gap="15px"
                    onClick={() => {
                        dispatch(idSelected(node.node.id));
                        setNextChild(!nextChild);
                    }}
                    CursorTable="pointer"
                    BorderTop="1px solid #E8E9EA"
                >
                    {!node.node.itemName && node.level !== MAXIMUN_LEVEL && (
                        <IconContainer
                            className="material-icons"
                            FontSize="1.5rem"
                            Color={'#5A5AFF'}
                            Justify="start"
                        >
                            {nextChild ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
                        </IconContainer>
                    )}
                    {(node.node.itemName || node.level === MAXIMUN_LEVEL) && (
                        <img alt="dot" src={point} />
                    )}

                    <TextEllipsis FontWeight="400" FontSize="0.875rem" title={node.node.groupName}>
                        {sendName}
                    </TextEllipsis>
                </TableItem>
                <TableItem Justify="start" Gap="16px" BorderTop="1px solid #E8E9EA">
                    {editableItemId === node.node.id ? (
                        <ContainerFlex Width="70%">
                            <SelectGeneral
                                options={options}
                                onChange={(e) =>
                                    setData((prevData) => ({
                                        ...prevData,
                                        item: {
                                            ...prevData.item,
                                            commercializationIncrease: e?.id,
                                        },
                                    }))
                                }
                            />
                        </ContainerFlex>
                    ) : Number(data.item.commercializationIncrease) === 2 ? (
                        options[1].label
                    ) : (
                        options[0].label
                    )}
                </TableItem>
                <TableItem Justify="start" Gap="16px" BorderTop="1px solid #E8E9EA">
                    {editableItemId === node.node.id ? (
                        <Input
                            Width="70%"
                            type="number"
                            onChange={(e) =>
                                setData((prevData) => ({
                                    ...prevData,
                                    item: {
                                        ...prevData.item,
                                        amount: Number(e.target.value),
                                    },
                                }))
                            }
                        />
                    ) : (
                        `${data.item.amount ? data.item.amount : node.node.amount}%`
                    )}
                </TableItem>
                <TableItem Justify="start" Gap="16px" BorderTop="1px solid #E8E9EA">
                    {editableItemId === node.node.id ? (
                        <Input
                            Width="70%"
                            type="number"
                            onChange={(e) =>
                                setData((prevData) => ({
                                    ...prevData,
                                    item: {
                                        ...prevData.item,
                                        daysOnHold: Number(e.target.value),
                                    },
                                }))
                            }
                        />
                    ) : data.item.daysOnHold ? (
                        `${data.item.daysOnHold} ${AuctionPassText.days}`
                    ) : (
                        `${node.node.daysOnHold} ${AuctionPassText.days}`
                    )}
                </TableItem>
                <TableItem Justify="start" Gap="16px" BorderTop="1px solid #E8E9EA">
                    <ImageLogo
                        src={editableItemId === node.node.id ? ConfirmButton : editIcon}
                        onClick={() => {
                            handleEditClick(node.node.id, node.level);
                            if (isEditable) {
                                submitEditItems();
                            }
                        }}
                        Height={editableItemId === node.node.id ? '20px' : '16px'}
                        Width={editableItemId === node.node.id ? '20px' : '16px'}
                        Position="relative"
                        Cursor="pointer"
                        alt="edit"
                    />
                </TableItem>
                {showMessage && (
                    <ContainerFlex
                        Height="4rem"
                        Width="40rem"
                        backG="#E4F7EC"
                        Position="fixed"
                        PositionTop="10%"
                        PositionLeft="38%"
                        Radius="15px"
                        Bb="3px solid #309C60"
                        Justify="start"
                    >
                        <ContainerFlex Gap="10px" Width="90%" Justify="start" MarginL="1rem">
                            <ImageLogo src={saveCheckImg} Width="20px" Height="20px" />
                            <Text FontWeight="700">{AuctionPassText.SaveChanges}</Text>
                        </ContainerFlex>
                        <ImageLogo
                            src={closeModal}
                            Height="15px"
                            Width="15px"
                            Cursor="pointer"
                            onClick={() => setShowMessage(false)}
                        />
                    </ContainerFlex>
                )}
            </TableRowItems>

            {nextChild && node.node.children && (
                <TreeComponent
                    data={node.node.children}
                    level={node.level}
                    isView={node.isView}
                    fatherId={node.node.id}
                />
            )}
        </>
    );
};
