import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    GET_PRODUCTS_CUSTOMER_USER_START,
    GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
    GET_PRODUCTS_CUSTOMER_USER_ERROR,
    SET_ACTIVE_STATUS,
    RESET_SEARCH_RESULTS,
    SET_PAGE_NUMBER,
} from '@components/LoansProducts/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getProductsCustomerUserStart = () => ({
    type: GET_PRODUCTS_CUSTOMER_USER_START,
});

export const getProductsCustomerUserSuccess = (data: AxiosResponse) => ({
    type: GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
    payload: data,
});

export const getProductsCustomerUserError = (error: AxiosError) => ({
    type: GET_PRODUCTS_CUSTOMER_USER_ERROR,
    payload: error,
});

export const setActiveStatus = (activeStatus: number) => ({
    type: SET_ACTIVE_STATUS,
    activeStatus,
});

export const setPageNumber = (pageNumber: number) => ({
    type: SET_PAGE_NUMBER,
    pageNumber,
});

export const getProductsCustomerUser = (
    token: string,
    companyId: number,
    loanStatus: number,
    pageZise: number,
    pageNumber: number
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getProductsCustomerUserStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetProductsCustomerUser}`,
                {headers, params: {companyId, loanStatus, pageZise, pageNumber}}
            );
            dispatch(getProductsCustomerUserSuccess(response));
        } catch (error) {
            dispatch(getProductsCustomerUserError(error as AxiosError));
        }
    };
};
export const resetSearchLoansProducts = () => {
    return {
        type: RESET_SEARCH_RESULTS,
    };
};
