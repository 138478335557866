import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    FOCUSED_COLORS,
    SCREEN,
    SELECT_TYPE,
    TITLES_USER,
} from '@components/Users/UserProfile/constants';
import {UserCard} from '@components/Users/UserProfile/UserCard';
import {getScreenUsers} from '@components/Users/Redux/Actions/ScreenUseSelector';
import {RootState} from '@/config/store';
import {IScreen} from '@components/Users/Redux/Reducers/ScreenUsers';
import {GeneralInformation} from '@components/Users/UserProfile/GeneralInformation';
import RoleSettings from '@components/Users/UserProfile/RoleSettings/RoleSettings';
import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@General/Moleculs/Breadcrumb/Index';
import {ContactData} from '@components/Users/UserProfile/ContactData';
import {ProfileUserResetPassword} from '@/components/UserProfileResetPassword/ResetPasswordUser';
import {UserBranchAssignment} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchAssignment';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
const itemsBreadcrumb: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Mi empresa'},
    {to: '#/Users', label: 'Usuario'},
    {to: '#/', label: 'Perfil de usuario'},
];
export const HomeUser = () => {
    const dispatch = useDispatch();
    const Screen: IScreen = useSelector((state: RootState) => state.ScreenStateUsers);
    const isLoading = useSelector((state: RootState) => state.UserProfileAdmin.loading);

    return (
        <>
            {isLoading ? (
                <ContainerFlex>
                    <Loading />
                </ContainerFlex>
            ) : (
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Padding="16px"
                    Gap="16px"
                    Height="'calc(100vh - 69px)'"
                >
                    <Breadcrumb items={itemsBreadcrumb} />
                    <Text FontSize="1.5rem" FontWeight="700">
                        {TITLES_USER.USER_PROFILE}
                    </Text>
                    <UserCard />
                    <ContainerFlex Height="40px">
                        <ContainerFlex
                            Padding="16px"
                            Width="91px"
                            HBb="1px solid  #1D1E20"
                            Bb={
                                Screen.screen === SCREEN.GENERAL
                                    ? FOCUSED_COLORS.FOCUSED
                                    : FOCUSED_COLORS.BORDER_LEFT
                            }
                        >
                            <ContainerFlex Width="59px">
                                <Text
                                    Color={
                                        Screen.screen === SCREEN.GENERAL
                                            ? FOCUSED_COLORS.FOCUSED_TEXT
                                            : FOCUSED_COLORS.UNFOCUSED_TEXT
                                    }
                                    Cursor="pointer"
                                    FontWeight={Screen.screen === SCREEN.GENERAL ? '700' : '500'}
                                    onClick={() => dispatch(getScreenUsers(SCREEN.GENERAL))}
                                >
                                    {SELECT_TYPE.GENERAL}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="167px"
                            Padding="16px"
                            HBb="1px solid  #1D1E20"
                            Bb={
                                Screen.screen === SCREEN.CONTACT
                                    ? FOCUSED_COLORS.FOCUSED
                                    : FOCUSED_COLORS.BORDER_LEFT
                            }
                        >
                            <ContainerFlex Width="12.875rem">
                                <Text
                                    Color={
                                        Screen.screen === SCREEN.CONTACT
                                            ? FOCUSED_COLORS.FOCUSED_TEXT
                                            : FOCUSED_COLORS.UNFOCUSED_TEXT
                                    }
                                    Cursor="pointer"
                                    FontWeight={Screen.screen === SCREEN.CONTACT ? '700' : '500'}
                                    onClick={() => dispatch(getScreenUsers(SCREEN.CONTACT))}
                                >
                                    {SELECT_TYPE.CONTACT}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="206px"
                            HBb="1px solid  #1D1E20"
                            Bb={
                                Screen.screen === SCREEN.PASSWORD
                                    ? FOCUSED_COLORS.FOCUSED
                                    : FOCUSED_COLORS.BORDER_LEFT
                            }
                        >
                            <ContainerFlex Width="12.875rem">
                                <Text
                                    Color={
                                        Screen.screen === SCREEN.PASSWORD
                                            ? FOCUSED_COLORS.FOCUSED_TEXT
                                            : FOCUSED_COLORS.UNFOCUSED_TEXT
                                    }
                                    Cursor="pointer"
                                    FontWeight={Screen.screen === SCREEN.PASSWORD ? '700' : '500'}
                                    onClick={() => dispatch(getScreenUsers(SCREEN.PASSWORD))}
                                >
                                    {SELECT_TYPE.PASSWORD}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="7.125rem"
                            HBb="1px solid  #1D1E20"
                            Bb={
                                Screen.screen === SCREEN.BRANCH
                                    ? FOCUSED_COLORS.FOCUSED
                                    : FOCUSED_COLORS.BORDER_LEFT
                            }
                        >
                            <ContainerFlex Width="13.75rem">
                                <Text
                                    Color={
                                        Screen.screen === SCREEN.BRANCH
                                            ? FOCUSED_COLORS.FOCUSED_TEXT
                                            : FOCUSED_COLORS.UNFOCUSED_TEXT
                                    }
                                    Cursor="pointer"
                                    FontWeight={Screen.screen === SCREEN.BRANCH ? '700' : '500'}
                                    onClick={() => dispatch(getScreenUsers(SCREEN.BRANCH))}
                                >
                                    {SELECT_TYPE.BRANCH}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            Width="4.625rem"
                            HBb="1px solid  #1D1E20"
                            Bb={
                                Screen.screen === SCREEN.ROLES
                                    ? FOCUSED_COLORS.FOCUSED
                                    : FOCUSED_COLORS.BORDER_LEFT
                            }
                        >
                            <ContainerFlex Width="183px">
                                <Text
                                    Color={
                                        Screen.screen === SCREEN.ROLES
                                            ? FOCUSED_COLORS.FOCUSED_TEXT
                                            : FOCUSED_COLORS.UNFOCUSED_TEXT
                                    }
                                    Cursor="pointer"
                                    FontWeight={Screen.screen === SCREEN.ROLES ? '700' : '500'}
                                    onClick={() => dispatch(getScreenUsers(SCREEN.ROLES))}
                                >
                                    {SELECT_TYPE.ROLES}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex Bb="1px solid  #E8E9EA" />
                    </ContainerFlex>
                    {Screen.screen === SCREEN.GENERAL && <GeneralInformation />}
                    {Screen.screen === SCREEN.CONTACT && <ContactData />}
                    {Screen.screen === SCREEN.PASSWORD && <ProfileUserResetPassword />}
                    {Screen.screen === SCREEN.BRANCH && <UserBranchAssignment />}
                    {Screen.screen === SCREEN.ROLES && <RoleSettings />}
                </ContainerFlex>
            )}
        </>
    );
};
