import React from 'react';

import {IQuickActionsNavigationProps} from '@Shopify/ClientProfile/interfaces';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {Image} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import arrowSwiper from '@images/arrowDark.svg';

const QuickActionNavigationButtons: React.FC<IQuickActionsNavigationProps> = ({onNext, onPrev}) => (
    <>
        <ButtonGeneral
            {...style.buttonsSwiper}
            clic={onPrev}
            text={<Image alt="arrow" Transform="rotate(180deg)" src={arrowSwiper} />}
        />
        <ButtonGeneral
            {...style.buttonsSwiper}
            clic={onNext}
            text={<Image alt="arrow" src={arrowSwiper} />}
        />
    </>
);

export default QuickActionNavigationButtons;
