import React from 'react';
import {useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';

import {RootState} from '@/config/store';
import {useIdentityDocs} from '@components/Shopify/ClientProfile/hooks/useIdentityDocs';
import LoadingOverlay from '@/components/General/Atoms/LoadingOverlay/LoadingOverlay';
import {IAddIdentityDocsProps} from '@components/Shopify/ClientProfile/interfaces';
import {IDocumentsInfo} from '@components/Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import closeIcon from '@images/closeDark.svg';
import {UPLOAD_DOCUMENTS} from '@components/Shopify/ClientProfile/constants';
import PersonalDataNationalityIdValidityInfo from '@/components/Shopify/ListClient/PersonalDataNationalityIdValidityInfo';
import ImageDocSelection from '@components/Shopify/ClientProfile/ImageDocSelection';
import FormDatePickeSelect from '@/components/General/Atoms/FormDatePickSelect/FormDatePickSelect';

const AddIdentityDocs: React.FC<IAddIdentityDocsProps> = ({
    setShowModal,
    onDocumentProvided,
    closeOnSubmit = true,
}) => {
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });

    const onComplete = (document: IDocumentsInfo) => {
        onDocumentProvided(document);
        if (closeOnSubmit) setShowModal(false);
    };

    const {
        loading,
        documentsInfo,
        setDocumentsInfo,
        setValue,
        control,
        errors,
        handleSave,
        handleDateChange,
        handleTypeChange,
        handleIdNumberChange,
        formattedIdentificationType,
        requireReverseImg,
    } = useIdentityDocs(token, onComplete);

    return (
        <LoadingOverlay isLoading={loading} borderRadius="1rem">
            <ContainerFlex
                FlexDir="column"
                Align="flex-start"
                Justify="flex-start"
                Gap="1rem"
                Radius="1rem"
                backG="#FFF"
                BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
            >
                <ContainerFlex
                    Width="38.313rem"
                    Padding="1rem 1.5rem"
                    Height="5.5rem"
                    Justify="space-between"
                    Align="center"
                >
                    <ContainerFlex
                        FlexDir="column"
                        Justify="flex-start"
                        Align="flex-start"
                        Gap="1rem"
                    >
                        <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                            {UPLOAD_DOCUMENTS.TITLE}
                        </Text>
                        <Text FontSize="1rem" Color="#2A2C2F" FontWeight="400">
                            {UPLOAD_DOCUMENTS.SUBTITLE}
                        </Text>
                    </ContainerFlex>
                    <Image
                        src={closeIcon}
                        Width="1.5rem"
                        Height="1.5rem"
                        Cursor="pointer"
                        onClick={() => setShowModal(false)}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column">
                    <ContainerFlex Padding="1.5rem">
                        <PersonalDataNationalityIdValidityInfo
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            documentsInfo={documentsInfo}
                            onTypeChange={(value) => handleTypeChange(value)}
                            onIdNumberChange={(value) => handleIdNumberChange(value)}
                            identificationOptions={formattedIdentificationType}
                        />
                    </ContainerFlex>
                    {requireReverseImg && (
                        <ContainerFlex
                            Justify="center"
                            Align="center"
                            Gap="1.5rem"
                            Self="stretch"
                            Height="1rem"
                        >
                            <ContainerFlex PaddingL="1.5rem" Justify="flex-start">
                                <ContainerFlex
                                    Width="3.688rem"
                                    Height="1rem"
                                    Padding="0.125rem 0.5rem"
                                    Justify="center"
                                    Align="center"
                                    Radius="3.125rem"
                                    backG="#E5E5FF"
                                    FontSize="0.75rem"
                                    Color="#5A5AFF"
                                    FontWeight="500"
                                    Position="relative"
                                    PositionLeft="1rem"
                                >
                                    {UPLOAD_DOCUMENTS.FRONT}
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex PaddingR="1.5rem" Justify="flex-start">
                                <ContainerFlex
                                    Width="3.688rem"
                                    Height="1rem"
                                    Padding="0.125rem 0.5rem"
                                    Justify="center"
                                    Align="center"
                                    Radius="3.125rem"
                                    backG="#E5E5FF"
                                    FontSize="0.75rem"
                                    Color="#5A5AFF"
                                    FontWeight="500"
                                    Position="relative"
                                    PositionLeft="1rem"
                                >
                                    {UPLOAD_DOCUMENTS.BACK}
                                </ContainerFlex>
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
                    <ContainerFlex
                        Padding="0 1.5rem 1rem"
                        FlexDir="column"
                        Align="center"
                        Justify="flex-start"
                        Gap="1.5rem"
                    >
                        <ContainerFlex Align="flex-start" Gap="1.5rem" Self="stretch">
                            <ImageDocSelection
                                type={UPLOAD_DOCUMENTS.FRONT}
                                maxSize={25}
                                errors={errors}
                                documentsInfo={documentsInfo}
                                setDocumentsInfo={setDocumentsInfo}
                                setValue={setValue}
                            />
                            {requireReverseImg && (
                                <ImageDocSelection
                                    type={UPLOAD_DOCUMENTS.BACK}
                                    maxSize={25}
                                    errors={errors}
                                    documentsInfo={documentsInfo}
                                    setDocumentsInfo={setDocumentsInfo}
                                    setValue={setValue}
                                />
                            )}
                        </ContainerFlex>
                        {(errors?.imageDocFront?.message || errors?.imageDocBack?.message) && (
                            <Text FontSize="1rem" Color="#FF6357" FontWeight="400">
                                {requireReverseImg
                                    ? UPLOAD_DOCUMENTS.IMAGES_ERROR
                                    : UPLOAD_DOCUMENTS.SINGLE_IMAGE_ERROR}
                            </Text>
                        )}
                        <ContainerFlex Gap="1rem" Align="flex-start">
                            <ContainerFlex FlexDir="column" Align="flex-star" Gap="0.25rem">
                                <Text
                                    Color="#1D1E20"
                                    FontSize="1rem"
                                    FontWeight="700"
                                    LetterSpacing="0.019rem"
                                >
                                    {UPLOAD_DOCUMENTS.EFECTIVE_DATE}
                                </Text>
                                <Controller
                                    name="validity"
                                    control={control}
                                    render={({field}) => (
                                        <FormDatePickeSelect
                                            {...field}
                                            placeholder="AAAA"
                                            format="yyyy"
                                            isDate
                                            error={!!errors.validity}
                                            yearsRange={10}
                                            pastYears={9}
                                            onChange={(newValue) =>
                                                handleDateChange('validity', String(newValue))
                                            }
                                        />
                                    )}
                                />
                                {errors?.validity?.message && (
                                    <Text Color="#FF6357" FontSize="0.75rem">
                                        {errors.validity.message}
                                    </Text>
                                )}
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="flex-star" Gap="0.25rem">
                                <Text
                                    Color="#1D1E20"
                                    FontSize="1rem"
                                    FontWeight="700"
                                    LetterSpacing="0.019rem"
                                >
                                    {UPLOAD_DOCUMENTS.EXPIRY_DATE}
                                </Text>
                                <Controller
                                    name="expiryDate"
                                    control={control}
                                    render={({field}) => (
                                        <FormDatePickeSelect
                                            {...field}
                                            placeholder="AAAA"
                                            format="yyyy"
                                            isDate
                                            onChange={(newValue) =>
                                                handleDateChange('expiryDate', String(newValue))
                                            }
                                            error={!!errors.expiryDate}
                                        />
                                    )}
                                />
                                {errors?.expiryDate?.message && (
                                    <Text Color="#FF6357" FontSize="0.75rem">
                                        {errors.expiryDate.message}
                                    </Text>
                                )}
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex
                            Height="2.5rem"
                            Justify="center"
                            Align="center"
                            Gap="1.5rem"
                            Self="stretch"
                        >
                            <ButtonGenerals
                                Radius="2rem"
                                Height="2.5rem"
                                Padding="0.5rem 1rem"
                                BackGC="transparent"
                                Justify="center"
                                Color="#5A5AFF"
                                HBackG="transparent"
                                FontWeight="500"
                                FontSize="1rem"
                                type="button"
                                Cursor="pointer"
                                Border="1px solid #5A5AFF"
                                onClick={() => {
                                    setShowModal(false);
                                }}
                            >
                                {UPLOAD_DOCUMENTS.CANCEL}
                            </ButtonGenerals>
                            <ButtonGenerals
                                Radius="2rem"
                                Height="2.5rem"
                                Padding="0.5rem 1rem"
                                type="button"
                                FontWeight="500"
                                Cursor="pointer"
                                onClick={handleSave}
                            >
                                {UPLOAD_DOCUMENTS.SAVE}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </LoadingOverlay>
    );
};
export default AddIdentityDocs;
