import {useCallback, useEffect} from 'react';
import {RootState} from '@/config/store';
import {FieldValues} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {ISelectReact} from '@/components/Quoter/inferfaces';
import {
    catMunicipality,
    catColony,
    resetPostalCode,
    resetAddressStates,
    catStates,
    catPostalCode,
    resetCatAddress,
} from '@components/LocationsBranch/Redux/Action/CatAddress';
import {DEFAULT_COUNTRY} from '@/components/LocationsBranch/constants';
import {ICustomAddressNew} from '@components/NewUserEmployee/Operational/interface';

export const useAddressFormNew = ({setValue, watch, reset}: ICustomAddressNew) => {
    const dispatch = useDispatch();
    const {token, catAddress} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        catAddress: state.catAddress,
    }));
    const catColonies = catAddress.catColonyByMunicipality;
    const getcatPostalCode = catAddress.catPostalCode;
    const selectConvert = (items: FieldValues) => {
        return items.length > 0
            ? items.map((item: FieldValues) => ({
                  value: item.suburbId || item.municipalityId || item.stateId || item.colonyId,
                  label:
                      item.nameSuburb || item.nameMunicipality || item.stateName || item.colonyName,
              }))
            : [{value: '', label: 'Sin opciones'}];
    };

    const getCatMunicipality = (option: ISelectReact) => {
        dispatch(catMunicipality(token, option));
        dispatch(resetPostalCode());
        dispatch(resetAddressStates({catColonyByMunicipality: []}));

        setValue('cp', '');
        setValue('colony', {value: 0, label: ''});
        setValue('municipality', {value: 0, label: ''});
    };

    const getColony = (option: ISelectReact) => {
        dispatch(catColony(token, option));
        setValue('cp', '');
        setValue('colony', {value: 0, label: ''});
    };
    const getCity = (option: ISelectReact) => {
        const colony = watch('colony');
        if (!colony) return;
        const postalCode = catAddress.catColonyByMunicipality.find(
            (item) =>
                Number(item.suburbId) ===
                (typeof colony === 'object' ? Number(colony.value) : Number(colony))
        );

        setValue('cp', postalCode?.cp || '');
    };
    const getAllServices = useCallback(() => {
        dispatch(catMunicipality(token, {value: getcatPostalCode.stateId, label: ''}));
        dispatch(catColony(token, {value: getcatPostalCode.municipalityId, label: ''}));
    }, [getcatPostalCode.municipalityId]);
    useEffect(() => {
        if (catAddress.catPostalCode.stateName) {
            setValue('colony', {
                value: getcatPostalCode.colonys[0].colonyId,
                label: getcatPostalCode.colonys[0].colonyName,
            });
            setValue('municipality', {
                value: getcatPostalCode.municipalityId,
                label: getcatPostalCode.municipalityName,
            });
            setValue('state', {
                value: getcatPostalCode.stateId,
                label: getcatPostalCode.stateName,
            });
        }
    }, [catAddress.catPostalCode, watch('cp')]);
    useEffect(() => {
        dispatch(catStates(token, DEFAULT_COUNTRY));
        if (watch('cp').length > 4) {
            dispatch(catPostalCode(token, watch('cp')));
            getAllServices();
        }
    }, [watch('cp'), getcatPostalCode.municipalityId]);
    useEffect(() => {
        dispatch(resetCatAddress());
        reset();
    }, []);
    return {
        token,
        catAddress,
        catColonies,
        getColony,
        selectConvert,
        getCatMunicipality,
        getCity,
    };
};
