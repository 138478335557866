import React from 'react';
import {ContainerCellGrid, ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {TEXT_NEW_USER} from '@/components/NewUserEmployee/Operational/constants';
import {Controller} from 'react-hook-form';
import {userDataDataProps} from '@/components/NewUserEmployee/Operational/interface';
import GeneralsInput from '@/components/NewUserEmployee/GeneralsInput';
import closed from '@/images/closeRed.svg';
import {useAddressFormNew} from '@components/NewUserEmployee/Address';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {ISelectReact} from '@/components/Quoter/inferfaces';

export const AdressUser: React.FC<userDataDataProps> = ({
    control,
    formState: {errors},
    watch,
    reset,
    setValue,
}) => {
    const {catAddress, catColonies, selectConvert, getCatMunicipality, getColony, getCity} =
        useAddressFormNew({
            watch,
            reset,
            setValue,
        });
    return (
        <ContainerFlex FlexDir="column" Gap="16px">
            <ContainerFlex Height="max-content" Radius="16px" Border=" 1px solid #E8E9EA">
                <ContainerCellGrid Padding="24px" Gap="32px">
                    <ContainerFlex FlexDir="column" Gap="16px">
                        <ContainerFlex Justify="start">
                            <Text FontSize="1.25rem" FontWeight="600" Color="#2A2C2F">
                                {TEXT_NEW_USER.ADRESS}
                            </Text>
                        </ContainerFlex>

                        <ContainerCellGrid Gap="16px">
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.CP}</Text>
                                <Controller
                                    name="cp"
                                    control={control}
                                    render={({field}) => (
                                        <GeneralsInput
                                            {...field}
                                            error={!!errors.cp}
                                            hasError={!!errors.cp}
                                            helperText={errors.cp?.message}
                                            imgMessage={closed}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.STREET}</Text>
                                <Controller
                                    name="street"
                                    control={control}
                                    render={({field}) => (
                                        <GeneralsInput
                                            maxLength={50}
                                            {...field}
                                            error={!!errors.street}
                                            hasError={!!errors.street}
                                            helperText={errors.street?.message}
                                            imgMessage={closed}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.EXTERIOR_NUMBER}</Text>
                                <Controller
                                    name="externalNumber"
                                    control={control}
                                    render={({field}) => (
                                        <GeneralsInput
                                            {...field}
                                            error={!!errors.externalNumber}
                                            hasError={!!errors.externalNumber}
                                            helperText={errors.externalNumber?.message}
                                            imgMessage={closed}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <ContainerFlex Justify="start" Gap="4px">
                                    <Text FontWeight="700">{TEXT_NEW_USER.INTERIOR_NUMBER}</Text>
                                    <Text FontWeight="400" Color="#54575C">
                                        {TEXT_NEW_USER.OPTIONAL}
                                    </Text>
                                </ContainerFlex>

                                <Controller
                                    name="internalNumber"
                                    control={control}
                                    render={({field}) => (
                                        <GeneralsInput
                                            {...field}
                                            error={!!errors.internalNumber}
                                            hasError={!!errors.internalNumber}
                                            helperText={errors.internalNumber?.message}
                                            imgMessage={closed}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                        </ContainerCellGrid>
                        <ContainerCellGrid Gap="16px">
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.STATE}</Text>
                                <Controller
                                    name="state"
                                    control={control}
                                    render={({field: {onChange, ...field}}) => (
                                        <SelectGeneral
                                            {...field}
                                            placeholder="Selecciona"
                                            isSearchable={false}
                                            options={selectConvert(
                                                catAddress.catStatesByCountry.filter(
                                                    (data) =>
                                                        data.stateId ===
                                                        catAddress.catPostalCode.stateId
                                                )
                                            )}
                                            hasError={!!errors?.state?.message}
                                            onChange={(e) => {
                                                if (onChange) onChange(e);
                                                getCatMunicipality(e as ISelectReact);
                                            }}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.HALL}</Text>
                                <Controller
                                    name="municipality"
                                    control={control}
                                    render={({field: {onChange, ...field}}) => (
                                        <SelectGeneral
                                            {...field}
                                            placeholder="Selecciona"
                                            isSearchable={false}
                                            options={selectConvert(
                                                catAddress.catMunicipalityByState.filter(
                                                    (data) =>
                                                        data.municipalityId ===
                                                        catAddress.catPostalCode.municipalityId
                                                )
                                            )}
                                            hasError={!!errors?.municipality?.message}
                                            onChange={(e) => {
                                                if (onChange) onChange(e);
                                                getColony(e as ISelectReact);
                                            }}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.CITY}</Text>
                                <Controller
                                    name="colony"
                                    control={control}
                                    render={({field: {onChange, ...field}}) => (
                                        <SelectGeneral
                                            {...field}
                                            placeholder="Selecciona"
                                            isSearchable={false}
                                            options={selectConvert(
                                                catColonies.filter(
                                                    (data) =>
                                                        data.cp ===
                                                        catAddress.catPostalCode.postalCode
                                                )
                                            )}
                                            onChange={(e) => {
                                                if (onChange) onChange(e);
                                                getCity(e as ISelectReact);
                                            }}
                                            hasError={!!errors?.colony?.message}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                                <Text FontWeight="700">{TEXT_NEW_USER.COUNTRY}</Text>
                                <Text FontWeight="700">{TEXT_NEW_USER.COUNTRY_SELECTED}</Text>
                            </ContainerFlex>
                        </ContainerCellGrid>
                    </ContainerFlex>
                </ContainerCellGrid>
            </ContainerFlex>
        </ContainerFlex>
    );
};
