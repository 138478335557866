import React from 'react';
import {ContainerFlex, Text, TextEllipsis} from '@components/Shopify/Ecommerce/styles';
import {ImgLocation} from '@components/Locations/AdminLocations/CreateLocation/style';
import nextArrow from '@components/Branches/Images/NextArrow.svg';
import {IServicesInfo} from '@components/Branches/interfaces';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

export const AssociatedCostsInfo = ({showServicesForm, setShowServicesForm}: IServicesInfo) => {
    const servicesInfo = useSelector((state: RootState) => {
        return state.GetServicesComplements?.servicesComplements;
    });
    return (
        <ContainerFlex Justify="flex-start" Align="flex-start" Height="auto" FlexDir="column">
            <ContainerFlex Height="48px" Justify="space-between">
                <Text FontSize="0.875rem" FontWeight="500">
                    Costos asociados a tu sucursal
                </Text>
                <ContainerFlex
                    Width="auto"
                    Justify="start"
                    Height="80%"
                    Cursor="pointer"
                    onClick={() => {
                        setShowServicesForm(!showServicesForm);
                    }}
                >
                    <Text FontSize=".875rem" Color="#35cf44" Cursor="pointer">
                        Editar
                    </Text>
                    <ImgLocation
                        src={nextArrow}
                        Width="0.875rem"
                        Height="0.875rem"
                        Margin="0 0 0 4px"
                        Cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Padding="16px 25px"
                Height="88px"
                FlexDir="column"
                Align="flex-start"
                Width="100%"
                RowGap="14px"
            >
                <ContainerFlex Justify="flex-start">
                    <Text FontSize="0.875rem" Color="#414042" Width="48%">
                        Comisión por avalúo:
                        <TextEllipsis
                            Width="100%"
                            Color="#414042"
                            FontSize="0.875rem"
                            FontWeight="500"
                            Padding="0 0 0 12px"
                            FontStyle={servicesInfo.serviceCostId ? '' : 'italic'}
                        >
                            {servicesInfo.appraisalPercentage
                                ? `${servicesInfo.appraisalPercentage}%`
                                : 'Añadir comisión por avalúo'}
                        </TextEllipsis>
                    </Text>
                    <Text FontSize="0.875rem" Color="#414042" Width="48%">
                        Gastos administrativos:
                        <TextEllipsis
                            Width="100%"
                            Color="#414042"
                            FontSize="0.875rem"
                            FontWeight="500"
                            Padding="0 0 0 12px"
                            FontStyle={servicesInfo.serviceCostId ? '' : 'italic'}
                        >
                            {servicesInfo.administrativeCost
                                ? `${servicesInfo.administrativeCost}%`
                                : 'Añadir gastos administrativos'}
                        </TextEllipsis>
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="flex-start">
                    <Text FontSize="0.875rem" Color="#414042" Width="48%">
                        Costo de reimpresión empeños:
                        <TextEllipsis
                            Width="100%"
                            Color="#414042"
                            FontSize="0.875rem"
                            FontWeight="500"
                            Padding="0 0 0 12px"
                            FontStyle={servicesInfo.serviceCostId ? '' : 'italic'}
                        >
                            {servicesInfo.reprintPledge
                                ? `$${servicesInfo.reprintPledge.toFixed(2)}`
                                : 'Añadir costo reimpresión'}
                        </TextEllipsis>
                    </Text>
                    <Text FontSize="0.875rem" Width="48%" Color="#414042">
                        Costo de reimpresión de apartados:
                        <TextEllipsis
                            Width="100%"
                            Color="#414042"
                            FontSize="0.875rem"
                            FontWeight="500"
                            Padding="0 0 0 12px"
                            FontStyle={servicesInfo.serviceCostId ? '' : 'italic'}
                        >
                            {servicesInfo.reprintPulledApart
                                ? `$${servicesInfo.reprintPulledApart.toFixed(2)}`
                                : 'Añadir costo reimpresión'}
                        </TextEllipsis>
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
