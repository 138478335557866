import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {PAYMENT_METHODS, VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {WarningError} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/WarningError';
import {EditingButtonsForm} from '@components/PersonalLoans/NewLoanProduct/ui/EditingButtonsForm';
import {
    IPaymentMethodsEditingFormProps,
    IPaymentMethodsEditingSubmit,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/paymentMethodsInterfaces';
import {IPaymentMethodState} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {AppDispatch, RootState} from '@/config/store';
import {saveNewLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {RowPaymentMethodEditingForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/EditingForm/RowPaymentMethodEditingForm';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaPaymentMethods} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/schemaPaymentMethods';

export const PaymentMethodsEditingForm: React.FC<IPaymentMethodsEditingFormProps> = ({
    paymentMethodRef,
    isEditing,
    setIsEditing,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData);
    const paymentMethodsData = newLoanData.details.typePaymentsIns;
    const {
        reset,
        control,
        trigger,
        handleSubmit,
        formState: {errors},
    } = useForm<IPaymentMethodsEditingSubmit>({
        resolver: yupResolver(schemaPaymentMethods),
    });
    const [showToolTip, setShowToolTip] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [paymentMethodsSelectedCopy, setPaymentMethodsSelectedCopy] =
        useState<IPaymentMethodState[]>(paymentMethodsData);
    const onSubmit = (data: IPaymentMethodsEditingSubmit) => {
        dispatch(
            saveNewLoanSubmittedData({
                ...newLoanData,
                details: {
                    ...newLoanData.details,
                    typePaymentsIns: data.typePaymentsIns,
                },
            })
        );
        setIsEditing(!isEditing);
    };
    const handleCheckbox = (id: number) => {
        const newSelectedArray = paymentMethodsSelectedCopy.map((item) => {
            if (item.typePaymentId === id) {
                return {
                    ...item,
                    isChecked: !item.isChecked,
                };
            }
            return item;
        });
        setPaymentMethodsSelectedCopy(newSelectedArray);
    };
    useEffect(() => {
        reset({typePaymentsIns: paymentMethodsSelectedCopy});
    }, []);
    return (
        <>
            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                Gap="1.5rem"
                Radius="1.5rem"
                Padding="1.25rem"
                Border="1px solid #E8E9EA"
                ref={paymentMethodRef}
            >
                <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start">
                    <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                        {PAYMENT_METHODS.TITLE}
                    </Text>
                    <Text Color="#2A2C2F">{PAYMENT_METHODS.DESCRIPTION}</Text>
                </ContainerFlex>
                {errors.typePaymentsIns && (
                    <WarningError
                        errorMessage={
                            errors.typePaymentsIns.message ||
                            VALIDATIONS_MSG.PAYMENT_METHODS.AT_LEAST_ONE
                        }
                    />
                )}
                <ContainerForm
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="1.5rem"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <ContainerFlex
                        Gap="1rem"
                        FlexDir="column"
                        Align="start"
                        Justify="start"
                        Height="max-content"
                    >
                        {paymentMethodsSelectedCopy.map((item, index) => (
                            <RowPaymentMethodEditingForm
                                key={index}
                                control={control}
                                isSubmitted={isSubmitted}
                                trigger={trigger}
                                lastIndex={paymentMethodsSelectedCopy.length - 1}
                                setShowToolTip={setShowToolTip}
                                showToolTip={showToolTip}
                                index={index}
                                item={item}
                                handleCheckbox={handleCheckbox}
                            />
                        ))}
                    </ContainerFlex>
                    <EditingButtonsForm
                        onCancel={() => setIsEditing(!isEditing)}
                        onContinue={() => setIsSubmitted(!isSubmitted)}
                    />
                </ContainerForm>
            </ContainerFlex>
        </>
    );
};
