import styled from '@emotion/styled';

export const SearchContainer = styled.div`
    position: relative;
`;
export const OptionsSearchList = styled.div`
    position: absolute;
    background-color: white;
    border: 1px solid #e8e9ea;
    border-radius: 8px;
    z-index: 1000;
    width: 270px;
    max-height: 200px;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
`;
