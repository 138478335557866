import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {
    setEditWarehouse,
    updateWarehouses,
} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {StorageLevelList} from '@components/LocationsBranch/StorageLevelList';
import {getInitialFloor} from '@components/LocationsBranch/utils/warehouse';
import {
    getNextFloorId,
    getNextLevelId,
    getNextRowId,
} from '@components/LocationsBranch/utils/GetNextId';
import {
    COPY_NAME,
    DEFAULT_PARENT_LEVEL_ID,
    FIXED_LEVEL,
    FROM_SECTION,
    MAX_LEVELS,
    START_LEVEL,
    STORAGE,
    STORAGE_NAME_INPUT,
    TOOLTIP_STORAGE,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {
    IFormWarehouseProps,
    ILevel,
    IStorage,
    IStorageFloor,
    IStorageTypeDetailProps,
} from '@components/LocationsBranch/interfaces';
import Tooltip from '@mui/material/Tooltip';
import {SearchInput} from '@Customer/Styles';
import EditIcon from '@images/editIcon.svg';
import backArrowIcon from '@images/backArrowIcon.svg';
import AddIcon from '@images/addUser.svg';
import AddFloor from '@images/addIcon.svg';
import HelpIcon from '@images/help.svg';
import ClearIcon from '@images/createCancel.svg';

export const StorageTypeDetail = ({
    warehouse,
    selectedStorageType,
    setSelectedStorage,
    formProps,
}: IStorageTypeDetailProps) => {
    const dispatch = useDispatch();
    const [editId, setEditId] = useState<number>(1);
    const storageTypes = warehouse.storageTypes || [];
    const {register, watch, handleSubmit, setValue, errors} = formProps;
    const name = watch(STORAGE_NAME_INPUT);

    const onAddNewLevel = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit((values: IFormWarehouseProps) => {
                addNewLevel(values, index);
            })();
        }
    };

    const addNewLevel = (values: IFormWarehouseProps, index: number) => {
        const {storageName} = values;
        const newStorageType = {...selectedStorageType};

        newStorageType?.storageFloor?.[index]?.levels?.forEach((level) => {
            if (level.id === editId) {
                level.parentLevel = newStorageType.levelId ?? DEFAULT_PARENT_LEVEL_ID;
                level.name = storageName;
                level.productQuantity = 0;
                level.showRowLevelInput = false;
                level.showNewLevel = false;
            }
        });

        updateStorage({
            ...newStorageType,
            storageFloor: newStorageType.storageFloor || [],
        });
        handleReset();
    };

    const onAddNewRow = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            event.preventDefault();

            handleSubmit((values: IFormWarehouseProps) => {
                addNewRow(values, index);
            })();
        }
    };

    const addNewRow = (values: IFormWarehouseProps, index: number) => {
        const {storageName} = values;
        const newStorageType = {...selectedStorageType};
        newStorageType?.storageFloor?.[index]?.levels?.forEach((level) => {
            if (level.id === editId) {
                level.showRowLevelInput = false;
                level.showNewLevel = false;
                level.rows?.push({
                    id: getNextRowId(level.rows),
                    name: storageName,
                    parentLevel: level.levelId ?? DEFAULT_PARENT_LEVEL_ID,
                    productQuantity: 0,
                });
            }
        });

        updateStorage({
            ...newStorageType,
            storageFloor: newStorageType.storageFloor || [],
        });
        handleReset();
    };

    const onClickNewRow = (id: number, visible: boolean = false, index: number) => {
        setEditId(id);
        setRowLevelInput(id, visible, index);
    };

    const setRowLevelInput = (id: number, visible: boolean, index: number) => {
        if (!selectedStorageType) return;
        const newStorageType = {...selectedStorageType};
        newStorageType?.storageFloor?.[index]?.levels?.forEach((level) => {
            if (level.id === id) {
                level.showRowLevelInput = visible;
                level.showNewLevel = false;
            }
        });
        updateStorage(newStorageType);
    };

    const onClickNewLevel = (visible: boolean = false, index: number, duplicateLevel?: ILevel) => {
        const curretFloor = selectedStorageType?.storageFloor[index];
        const quantityLevel = curretFloor?.levels?.length ?? 0;
        if (quantityLevel >= MAX_LEVELS) return;
        const currrentLevel =
            curretFloor?.levels && curretFloor?.levels.find((level) => level.name === '');
        let id = currrentLevel?.id ?? START_LEVEL;
        if (!currrentLevel) {
            id = MAX_LEVELS;
            if (quantityLevel < MAX_LEVELS) {
                const newId = getNextLevelId(curretFloor?.levels || []);
                const newLevel = {
                    id: newId,
                    name: duplicateLevel ? duplicateLevel.name : '',
                    levelId: FIXED_LEVEL,
                    productQuantity: duplicateLevel ? duplicateLevel.productQuantity : 0,
                    parentLevel: curretFloor?.id ?? DEFAULT_PARENT_LEVEL_ID,
                    showRowLevelInput: false,
                    showNewLevel: false,
                    rows:
                        duplicateLevel?.rows.map((row) => {
                            return {
                                ...row,
                                parentLevel: newId,
                            };
                        }) || [],
                    storageDescription: duplicateLevel?.storageDescription || '',
                };
                curretFloor?.levels?.push(newLevel);
                id = newId;
            }
        }
        setEditId(id);
        setShowLevelInput(id, visible, index);
    };

    const setShowLevelInput = (id: number, visible: boolean, index: number) => {
        if (!selectedStorageType) return;
        const newStorageType = {...selectedStorageType};
        const curretFloor = newStorageType?.storageFloor[index];

        curretFloor?.levels?.forEach((level) => {
            if (level.id === id) {
                level.showRowLevelInput = false;
                level.showNewLevel = visible;
            }
        });
        updateStorage(newStorageType);
    };

    const updateStorage = (newStorageType: IStorage) => {
        const storageTypeIndex = storageTypes.findIndex(
            (storageType) => storageType.id === newStorageType.id
        );
        const newStorageTypes = [...storageTypes];
        newStorageTypes[storageTypeIndex] = newStorageType;
        const newWarehouse = JSON.parse(JSON.stringify(warehouse));
        newWarehouse.storageTypes = newStorageTypes;

        dispatch(updateWarehouses(newWarehouse));
    };

    const onGoBack = () => {
        setSelectedStorage(null);
        handleReset();
    };

    const onClickNewFloor = () => {
        const newId = getNextFloorId(selectedStorageType?.storageFloor || []);
        const newStorageType = {
            ...selectedStorageType,
            storageFloor: [
                ...(selectedStorageType?.storageFloor || []),
                ...getInitialFloor(newId, true),
            ],
        };
        setSelectedStorage(newStorageType);
        updateStorage(newStorageType);
    };

    const onCreateFloorName = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSubmit((values: IFormWarehouseProps) => {
                addFloor(values, index);
            })();
        }
    };

    const addFloor = (values: IFormWarehouseProps, index: number) => {
        const {storageName} = values;
        const newStorageType = {...selectedStorageType};
        if (newStorageType?.storageFloor?.[index]) {
            newStorageType.storageFloor[index].name = storageName;
        }
        updateStorage({
            ...newStorageType,
            storageFloor: newStorageType.storageFloor || [],
        });
        handleReset();
    };

    const onEditStorageFloorName = (storageType: IStorage, storageFloor: IStorageFloor) => {
        dispatch(
            setEditWarehouse({
                warehouse,
                fromSection: FROM_SECTION.FLOOR_NAME,
                showEditModal: true,
                storageType,
                storageFloor,
            })
        );
    };

    const onDuplicateLevel = (index: number, duplicateLevel: ILevel) => {
        const newDuplicateLevel = {
            ...duplicateLevel,
            name: `${duplicateLevel.name} ${COPY_NAME}`,
        };
        onClickNewLevel(false, index, newDuplicateLevel);
    };

    const onDeleteLevel = (levelId: number, index: number) => {
        const newStorageType = {...selectedStorageType};
        const newLevels = newStorageType?.storageFloor?.[index]?.levels?.filter(
            (level) => level.id !== levelId
        );
        if (newStorageType.storageFloor) {
            newStorageType.storageFloor[index].levels = newLevels;
        }
        updateStorage({
            ...newStorageType,
            storageFloor: newStorageType.storageFloor || [],
        });
    };

    const onEditLevel = (levelId: number, storageFloor: IStorageFloor) => {
        dispatch(
            setEditWarehouse({
                warehouse,
                fromSection: FROM_SECTION.LEVEL_NAME,
                showEditModal: true,
                storageType: selectedStorageType,
                storageFloor,
                levelId,
            })
        );
    };

    const onDeleteFloor = (floorId: number) => {
        const newStorageType = {...selectedStorageType};
        if (newStorageType.storageFloor) {
            newStorageType.storageFloor = newStorageType.storageFloor.filter(
                (floor) => floor.id !== floorId
            );
        }

        const updatedStorageType = {
            ...newStorageType,
            storageFloor: newStorageType.storageFloor || [],
        };

        updateStorage(updatedStorageType);

        setSelectedStorage(updatedStorageType);
    };

    const handleReset = () => {
        setValue(STORAGE_NAME_INPUT, '');
    };

    return (
        <>
            {selectedStorageType?.storageFloor?.map((floor, index) => (
                <ContainerFlex
                    key={index}
                    backG="#FAFAFF"
                    Gap="1rem"
                    FlexDir="column"
                    Padding="1rem 1.5rem 1rem 1.5rem"
                    Align="start"
                    Justify="start"
                    Radius="1rem"
                    MarginBt="1rem"
                >
                    <ContainerFlex FlexDir="column" Justify="start" Align="start" Radius="1rem">
                        {index === 0 && (
                            <Text
                                FontSize="1rem"
                                Color="#5A5AFF"
                                FontWeight="500"
                                PaddingB="1rem"
                                Cursor="pointer"
                                onClick={() => onGoBack()}
                            >
                                <Image
                                    src={backArrowIcon}
                                    alt="add"
                                    Width="1rem"
                                    Height="1rem"
                                    Margin="0 0.313rem 0 0"
                                />
                                {STORAGE.HOME}
                            </Text>
                        )}
                        <ContainerFlex Justify="start" Align="start" Gap="0.5rem" Radius="1rem">
                            {floor?.name !== '' ? (
                                <ContainerFlex Justify="start" Gap="0.5rem">
                                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500">
                                        {floor?.name}
                                    </Text>
                                    <Text FontSize="0.875rem" MarginTop="0.313rem">
                                        {floor?.productQuantity} {STORAGE.PRODUCTS}
                                    </Text>
                                    <Image
                                        src={EditIcon}
                                        alt="add"
                                        Width="1.125rem"
                                        Height="1.125rem"
                                        onClick={() =>
                                            onEditStorageFloorName(selectedStorageType, floor)
                                        }
                                    />
                                </ContainerFlex>
                            ) : (
                                <ContainerFlex FlexDir="column" Height={'3.5rem'} Width="18.75rem">
                                    <SearchInput
                                        GridColumn={name ? '85% 10% 5%' : '90% 10%'}
                                        FontSize="1rem"
                                        Right="none"
                                    >
                                        <input
                                            type="text"
                                            {...register(STORAGE_NAME_INPUT)}
                                            onKeyDown={(e) => {
                                                onCreateFloorName(e, index);
                                            }}
                                            onBlur={() => {
                                                onDeleteFloor(floor?.id ?? 0);
                                                handleReset();
                                            }}
                                            autoFocus
                                        />
                                        <Tooltip title={TOOLTIP_STORAGE}>
                                            <Image
                                                src={HelpIcon}
                                                alt="help"
                                                Cursor="pointer"
                                                Width="1.25rem"
                                                Height="1.25rem"
                                            />
                                        </Tooltip>
                                        {name && (
                                            <Image
                                                src={ClearIcon}
                                                alt="icon-clear"
                                                Cursor="pointer"
                                                Width="0.875rem"
                                                Height="0.875rem"
                                                onClick={handleReset}
                                            />
                                        )}
                                    </SearchInput>
                                    {errors?.storageName?.message && (
                                        <Text Color="#FF6357" FontSize="0.75rem" Self="start">
                                            {errors.storageName.message}
                                        </Text>
                                    )}
                                </ContainerFlex>
                            )}

                            {floor?.name !== '' && (
                                <ContainerFlex Justify="end" Gap="0.5rem">
                                    <ButtonGenerals
                                        Height="2rem"
                                        Radius="24px"
                                        Border="1px solid #5A5AFF"
                                        Cursor="pointer"
                                        FontSize="0.85rem"
                                        FontWeight="500"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onClickNewLevel(true, index);
                                        }}
                                    >
                                        <Image
                                            src={AddIcon}
                                            alt="add"
                                            Width="1.5rem"
                                            Height="1.5rem"
                                        />
                                        {STORAGE.ADD}
                                    </ButtonGenerals>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                        {floor?.name !== '' && (
                            <ContainerFlex Justify="start" Align="start" Radius="1rem">
                                <Text FontSize="0.75rem">{floor.storageDescription}</Text>
                            </ContainerFlex>
                        )}
                        <StorageLevelList
                            warehouse={warehouse}
                            storageFloor={floor}
                            onClickNewRow={onClickNewRow}
                            setEditId={setEditId}
                            floorIndex={index}
                            selectedStorageType={selectedStorageType}
                            setSelectedStorage={setSelectedStorage}
                            onAddNewLevel={(
                                event: React.KeyboardEvent<HTMLInputElement>,
                                floorIndex
                            ) => {
                                onAddNewLevel(event, floorIndex);
                            }}
                            onAddNewRow={(
                                event: React.KeyboardEvent<HTMLInputElement>,
                                floorIndex
                            ) => {
                                onAddNewRow(event, floorIndex);
                            }}
                            onDuplicateLevel={(duplicateLevel: ILevel) => {
                                onDuplicateLevel(index, duplicateLevel);
                            }}
                            onDeleteLevel={(levelId: number) => {
                                onDeleteLevel(levelId, index);
                            }}
                            onEditLevel={(levelId: number) => {
                                onEditLevel(levelId, floor);
                            }}
                            setRowLevelInput={setRowLevelInput}
                            formProps={formProps}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            ))}
            <ContainerFlex Justify="start">
                <ButtonGeneral
                    color="#5A5AFF"
                    backGround="#FFFFFF"
                    hText="#5A5AFF"
                    hShadow=""
                    height="2.5rem"
                    cursor="pointer"
                    border="1px solid #5A5AFF"
                    hBackG="#E5E5FF"
                    clic={(e: React.MouseEvent) => {
                        e.preventDefault();
                        onClickNewFloor();
                    }}
                    text={
                        <>
                            <Image src={AddFloor} alt="add" color="#5A5AFF" />
                            <Text Color="#5A5AFF" FontWeight="500" Cursor="pointer" FontSize="1rem">
                                {STORAGE.NEW_FLOOR}
                            </Text>
                        </>
                    }
                />
            </ContainerFlex>
        </>
    );
};
