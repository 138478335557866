import React, {ChangeEvent, useRef, useState, useCallback, useEffect} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ArchiveInputContainer} from '@/components/Quoter/Steps/styles';
import {isSupportedFileType} from '@/components/Quoter/Search/ArticleRequestInputs/validateArchive';
import {IExpenseDetailFile} from '@/components/CashFlowExpenseDetails/operational/interface';
import {ImageIcon} from '@/components/OrganizationCreation/SubContainer/styles';
import checkMark from '@/images/chekDone.svg';
import draftImg from '@/images/documentIcon.svg';
import syncImg from '@/images/sync.svg';
import uploadImg from '@/images/arrowUpload.svg';
import {UPLOAD_FILE} from '@/components/CashFlowExpenseDetails/operational/constants';

export const UploadFile = ({
    selectedFile,
    setSelectedFile,
    backendFileName,
    needReplaceButton = true,
}: IExpenseDetailFile) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [filePropierties, setPropierties] = useState<File | null>(null);
    const [errorFile, setErrorFile] = useState<boolean>(false);

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            if (file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setPropierties(file);
                        setErrorFile(false);
                    }
                };
            } else {
                setSelectedFile(null);
                setErrorFile(true);
            }
        }
    };

    const handleDrop = useCallback(
        (event: React.DragEvent<HTMLDivElement>) => {
            event.preventDefault();
            const file = event.dataTransfer.files?.[0];
            if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    if (typeof reader.result === 'string') {
                        setSelectedFile(reader.result);
                        setPropierties(file);
                        setErrorFile(false);
                    }
                };
            } else {
                setSelectedFile(null);
                setErrorFile(true);
            }
        },
        [setSelectedFile]
    );

    const handleDragOver = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
    }, []);

    useEffect(() => {
        if (backendFileName) {
            const dbFile = new File([new Blob()], backendFileName, {
                type: 'image/jpeg',
                lastModified: new Date().getTime(),
            });
            setPropierties(dbFile);
            setSelectedFile('');
        } else {
            setSelectedFile(null);
        }
    }, [backendFileName, setSelectedFile]);

    return (
        <ArchiveInputContainer
            Height="56px"
            Width="100%"
            error={errorFile}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
        >
            <input
                type="file"
                style={{display: 'none'}}
                onChange={handleFileChange}
                ref={fileInputRef}
            />
            <ContainerFlex>
                {(selectedFile && selectedFile.length > 0) || filePropierties ? (
                    <ContainerFlex ColumnGap="8px" Justify="start">
                        <ContainerFlex
                            Padding="16px"
                            backG="#F4F5F5"
                            Width="auto"
                            Radius="32px 0 0 32px"
                        >
                            <ImageIcon src={draftImg} />
                        </ContainerFlex>
                        <ContainerFlex
                            Justify="space-between"
                            Padding="0 18px 0 8px"
                            onClick={() => fileInputRef.current?.click()}
                            Cursor="pointer"
                        >
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    {filePropierties?.name}
                                </Text>
                                <Text FontSize="0.75rem" Color="#54575" FontWeight="500">
                                    <ImageIcon src={checkMark} height="12px" width="12px" />
                                    {filePropierties
                                        ? (filePropierties.size / (1024 * 1024)).toFixed(2)
                                        : 0}
                                    {UPLOAD_FILE.mb}
                                </Text>
                            </ContainerFlex>
                            {needReplaceButton && (
                                <ContainerFlex Justify="end">
                                    <ImageIcon src={syncImg} alt={UPLOAD_FILE.alt} />
                                    <Text FontSize="0.75rem" Color="#5A5AFF" FontWeight="500">
                                        {UPLOAD_FILE.replace}
                                    </Text>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                ) : (
                    <ContainerFlex
                        ColumnGap="8px"
                        onClick={() => fileInputRef.current?.click()}
                        Cursor="pointer"
                    >
                        <ImageIcon src={uploadImg} />
                        <Text FontSize="1rem" Color="#54575">
                            {UPLOAD_FILE.drag}
                        </Text>
                        <Text FontSize="1rem" Color="#5A5AFF">
                            {UPLOAD_FILE.search}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ArchiveInputContainer>
    );
};
