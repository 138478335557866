import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {useParams} from 'react-router-dom';
import {useForm, FieldValues} from 'react-hook-form';
import {ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import PersonalLoanSemiApprovedDeposit from '@/components/PersonalLoans/PersonalLoanSemiApproved/PersonalLoanSemiApprovedDeposit';
import PersonalLoanSemiApprovedSummary from '@/components/PersonalLoans/PersonalLoanSemiApproved/PersonalLoanSemiApprovedSummary';
import getCreditLoanSummaryAction from '@/components/PersonalLoans/Redux/Actions/GetCreditLoanSummaryAction';
import {DataPersonalLoanApproved, ICreditLoanSummary} from '@/components/PersonalLoans/interfaces';
import addWireTransferAction from '@/components/PersonalLoans/Redux/Actions/AddWireTransferPersonalLoanActions';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaTransactionLoans} from '@/components/PersonalLoans/schemaPersonalLoans';
import {TransversalLoader} from '@/components/General/Atoms/TransversalLoader/TransversalLoader';

const PersonalLoanSemiApprovedBody = () => {
    const {creditId} = useParams<{creditId?: string}>();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (token && creditId) {
            const creditLoanSummary = {
                token,
                personalCredit: parseInt(creditId, 10),
                termsConditions: true,
            };
            dispatch(getCreditLoanSummaryAction(creditLoanSummary));
        }
    }, [creditId]);
    const {data} = useSelector((state: RootState) => {
        return state.getCreditLoanSummary;
    });

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            keyAccount: '',
            catBank: '',
            terms: false,
        },
        resolver: yupResolver(schemaTransactionLoans),
    });

    const onSubmit = (formData: DataPersonalLoanApproved) => {
        const {keyAccount, catBank} = formData;
        const addWireTransferData = {
            token,
            personalCreditId: parseInt(creditId as string, 10),
            catBankId: parseInt(catBank as string, 10),
            keyAccount: keyAccount as string,
        };
        dispatch(addWireTransferAction(addWireTransferData));
    };
    const addWireTransferLoading = useSelector((state: RootState) => state.addWireTransfer.loading);
    return (
        <ContainerForm
            Height="100%"
            Width="100%"
            Flex={2}
            Justify="start"
            Align="start"
            Gap="24px"
            onSubmit={handleSubmit(onSubmit)}
        >
            <PersonalLoanSemiApprovedDeposit
                {...(data as ICreditLoanSummary)}
                control={control}
                errors={errors}
            />
            <PersonalLoanSemiApprovedSummary
                {...(data as ICreditLoanSummary)}
                control={control}
                errors={errors}
            />
            {addWireTransferLoading && <TransversalLoader />}
        </ContainerForm>
    );
};

export default PersonalLoanSemiApprovedBody;
