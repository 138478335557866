import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import informationIcon from '@components/PersonalLoans/NewLoanProduct/icons/informationIcon.svg';
import {LOAN_PRODUCT_PREVIEW} from '@components/PersonalLoans/NewLoanProduct/constants';
import {useSubmittedLoanData} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/hooks/useSubmittedLoanData';

export const PreviewAlert = () => {
    const {onSubmit} = useSubmittedLoanData();
    return (
        <ContainerFlex
            Padding="1rem"
            Gap="1.5rem"
            backG="#DBF4FF"
            Radius="0.5rem"
            Height="max-content"
        >
            <ContainerFlex Gap="0.5rem" Align="start">
                <Image src={informationIcon} Width="24px" Height="24px" alt="information-icon" />
                <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="0.5rem">
                    <Text FontWeight="700" Color="#004461" Width="100%">
                        {LOAN_PRODUCT_PREVIEW.PREVIEW_ALERT.TITLE}
                    </Text>
                    <Text Color="#2A2C2F" wSpace="wrap" Width="100%">
                        {LOAN_PRODUCT_PREVIEW.PREVIEW_ALERT.DESCRIPTION}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Width="max-content">
                <Text
                    Height="24px"
                    Width="max-content"
                    Color="#5A5AFF"
                    FontWeight="700"
                    Cursor="pointer"
                    onClick={() => onSubmit()}
                >
                    {LOAN_PRODUCT_PREVIEW.PREVIEW_ALERT.BTN_SAVE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
