import {
    IUsersPaginated,
    IGetUserEmployeeRedux,
    IUserData,
} from '@components/LocationsBranch/interfaces';
import {GET_USER_BY_LEVEL} from '@components/LocationsBranch/Redux/types';
import {UserListPageSize, SortDirection} from '@components/LocationsBranch/constants';

const initialState: IUsersPaginated = {
    actualPage: 1,
    allUserData: [],
    error: false,
    loading: false,
    companyUsers: {
        data: [],
        pageNumber: 1,
        pageSize: UserListPageSize,
        totalPages: 0,
        totalItems: 0,
    },
};

const calculatePagination = (userData: IUserData[], pageSize: number) => {
    const totalItems = userData.length;
    const totalPages = Math.ceil(totalItems / pageSize);
    const initialData = userData.slice(0, pageSize);

    return {initialData, totalItems, totalPages};
};

const addUniqueUsers = (existingUsers: IUserData[], newUsers: IUserData[]) => {
    const existingIds = new Set(existingUsers.map((user) => user.employId));
    return newUsers.filter((user) => !existingIds.has(user.employId));
};

const paginate = (data: IUserData[], page: number, pageSize: number) => {
    const startIndex = (page - 1) * pageSize;
    return data.slice(startIndex, startIndex + pageSize);
};

const sortUserData = (data: IUserData[], sortBy: keyof IUserData, sortDirection: string) => {
    return [...data].sort((a, b) => {
        if (a[sortBy] > b[sortBy]) return sortDirection === SortDirection.Asc ? 1 : -1;
        if (a[sortBy] < b[sortBy]) return sortDirection === SortDirection.Asc ? -1 : 1;
        return 0;
    });
};

const removeUsersByIds = (userData: IUserData[], userIds: number[]) => {
    return userData.filter((user) => !userIds.includes(user.employId));
};

const handleCompanyUsersDataUpdate = (
    companyUsersData: IUserData[],
    allUserData: IUserData[],
    currentPage: number,
    pageSize: number,
    userIds: number[]
) => {
    let updatedCompanyUsersData = removeUsersByIds(companyUsersData, userIds);
    let newPage = currentPage;

    if (updatedCompanyUsersData.length < pageSize) {
        const startIndex = (newPage - 1) * pageSize + updatedCompanyUsersData.length;
        const itemsToFill = pageSize - updatedCompanyUsersData.length;
        const additionalItems = allUserData.slice(startIndex, startIndex + itemsToFill);

        updatedCompanyUsersData = [...updatedCompanyUsersData, ...additionalItems];
    }

    if (updatedCompanyUsersData.length === 0 && newPage > 1) {
        newPage -= 1;
        updatedCompanyUsersData = paginate(allUserData, newPage, pageSize);
    }

    return {updatedCompanyUsersData, newPage};
};

const GetUserByLevelId = (state = initialState, action: IGetUserEmployeeRedux) => {
    switch (action.type) {
        case GET_USER_BY_LEVEL.GET_USER_START:
            return {...state, loading: true};
        case GET_USER_BY_LEVEL.GET_USER_SUCCESS: {
            const {initialData, totalItems, totalPages} = calculatePagination(
                action.userData,
                UserListPageSize
            );

            return {
                ...state,
                allUserData: action.userData,
                companyUsers: {
                    ...state.companyUsers,
                    data: initialData,
                    totalItems,
                    totalPages,
                },
                loading: false,
            };
        }
        case GET_USER_BY_LEVEL.GET_USER_ERROR:
            return {...state, loading: false, error: false, allUserData: []};
        case GET_USER_BY_LEVEL.ADD_USERS: {
            const uniqueUsers = addUniqueUsers(state.allUserData, action.userData);
            const updatedAllUserData = [...uniqueUsers, ...state.allUserData];
            const {initialData, totalItems, totalPages} = calculatePagination(
                updatedAllUserData,
                UserListPageSize
            );

            return {
                ...state,
                allUserData: updatedAllUserData,
                companyUsers: {
                    ...state.companyUsers,
                    data: initialData,
                    totalItems,
                    totalPages,
                },
            };
        }
        case GET_USER_BY_LEVEL.UPDATE_PAGINATION:
            return {
                ...state,
                actualPage: action.newPage,
                companyUsers: {
                    ...state.companyUsers,
                    data: paginate(state.allUserData, action.newPage, UserListPageSize),
                },
            };
        case GET_USER_BY_LEVEL.SORT_USERS: {
            const sortedData = sortUserData(
                state.allUserData,
                action.sortBy as keyof IUserData,
                action.sortDirection
            );
            return {
                ...state,
                allUserData: sortedData,
                companyUsers: {
                    ...state.companyUsers,
                    data: paginate(sortedData, state.actualPage, UserListPageSize),
                },
            };
        }
        case GET_USER_BY_LEVEL.TOGGLE_USER_SELECT: {
            const updatedAllUserData = state.allUserData.map((user) =>
                user.employId === action.userId ? {...user, selected: !user.selected} : user
            );
            return {
                ...state,
                allUserData: updatedAllUserData,
                companyUsers: {
                    ...state.companyUsers,
                    data: paginate(updatedAllUserData, state.actualPage, UserListPageSize),
                },
            };
        }
        case GET_USER_BY_LEVEL.TOGGLE_ALL_USERS: {
            const updatedUsers = state.allUserData.map((user) => ({
                ...user,
                selected: action.selectAll,
            }));
            return {
                ...state,
                allUserData: updatedUsers,
                companyUsers: {
                    ...state.companyUsers,
                    data: paginate(updatedUsers, state.actualPage, UserListPageSize),
                },
            };
        }
        case GET_USER_BY_LEVEL.REMOVE_USERS: {
            const updatedAllUserData =
                action.userIds.length === 0
                    ? []
                    : removeUsersByIds(state.allUserData, action.userIds);
            const {updatedCompanyUsersData, newPage} =
                action.userIds.length === 0
                    ? {updatedCompanyUsersData: [], newPage: 1}
                    : handleCompanyUsersDataUpdate(
                          state.companyUsers.data,
                          updatedAllUserData,
                          state.actualPage,
                          UserListPageSize,
                          action.userIds
                      );
            const {totalItems, totalPages} = calculatePagination(
                updatedAllUserData,
                UserListPageSize
            );

            return {
                ...state,
                allUserData: updatedAllUserData,
                actualPage: newPage,
                companyUsers: {
                    ...state.companyUsers,
                    data: updatedCompanyUsersData,
                    totalItems,
                    totalPages,
                },
            };
        }
        case GET_USER_BY_LEVEL.GET_USER_BY_LEVEL_RESET: {
            return {...initialState};
        }
        default:
            return state;
    }
};

export default GetUserByLevelId;
