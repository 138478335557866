import React, {useRef, useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import replace from '@/images/ReturnPhoto.svg';
import rotate from '@/images/RotateImgUsers.svg';
import cut from '@/images/CutIconImg.svg';
import {ImageEditorProps, Position, ImageEditorHandle} from './interfaces';
import {Images, StyledCanvas} from '@/components/Users/stylesAvatar';
import {MODAL_TEXT} from '@/components/Users/constants';

export const ImageEditor = forwardRef<ImageEditorHandle, ImageEditorProps>(
    ({imageElement, onReplace}, ref) => {
        const canvasRef = useRef<HTMLCanvasElement | null>(null);
        const [rotation, setRotation] = useState<number>(0);
        const [scale, setScale] = useState<number>(1);
        const [isDragging, setIsDragging] = useState<boolean>(false);
        const [position, setPosition] = useState<Position>({x: 0, y: 0});
        const [lastPosition, setLastPosition] = useState<Position>({x: 0, y: 0});
        const [isCropping, setIsCropping] = useState<boolean>(false);

        const rotatePoint = (x: number, y: number, angle: number) => {
            const radians = (angle * Math.PI) / 180;
            return {
                x: x * Math.cos(radians) - y * Math.sin(radians),
                y: x * Math.sin(radians) + y * Math.cos(radians),
            };
        };

        const drawImage = (img: HTMLImageElement) => {
            const canvas = canvasRef.current;
            const ctx = canvas?.getContext('2d');

            if (!canvas || !ctx) return;

            const CANVAS_WIDTH = 193;
            const CANVAS_HEIGHT = 244;

            canvas.width = CANVAS_WIDTH;
            canvas.height = CANVAS_HEIGHT;

            try {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.save();

                let targetWidth = img.width;
                let targetHeight = img.height;
                const imageRatio = img.width / img.height;
                const canvasRatio = CANVAS_WIDTH / CANVAS_HEIGHT;

                if (imageRatio > canvasRatio) {
                    targetWidth = CANVAS_WIDTH;
                    targetHeight = CANVAS_WIDTH / imageRatio;
                } else {
                    targetHeight = CANVAS_HEIGHT;
                    targetWidth = CANVAS_HEIGHT * imageRatio;
                }
                const x = (CANVAS_WIDTH - targetWidth) / 2;
                const y = (CANVAS_HEIGHT - targetHeight) / 2;
                ctx.translate(CANVAS_WIDTH / 2, CANVAS_HEIGHT / 2);
                ctx.rotate((rotation * Math.PI) / 180);
                ctx.scale(scale, scale);
                const rotatedPosition = rotatePoint(position.x, position.y, -rotation);
                ctx.translate(
                    -CANVAS_WIDTH / 2 + rotatedPosition.x,
                    -CANVAS_HEIGHT / 2 + rotatedPosition.y
                );
                ctx.drawImage(img, x, y, targetWidth, targetHeight);
                ctx.restore();

                if (isCropping) {
                    ctx.strokeStyle = 'rgba(255, 255, 255, 0.5)';
                    ctx.lineWidth = 2;
                    ctx.setLineDash([5, 5]);
                    ctx.strokeRect(10, 10, CANVAS_WIDTH - 20, CANVAS_HEIGHT - 20);
                }
            } catch (error) {
                return error;
            }
        };

        const getEditedImage = (): string | null => {
            const canvas = canvasRef.current;
            if (!canvas) return null;

            try {
                if (isCropping) {
                    const croppedCanvas = document.createElement('canvas');
                    const ctx = croppedCanvas.getContext('2d');
                    if (!ctx) return null;

                    croppedCanvas.width = 173;
                    croppedCanvas.height = 224;

                    const originalCtx = canvas.getContext('2d');
                    if (!originalCtx) return null;
                    ctx.drawImage(
                        canvas,
                        10,
                        10,
                        canvas.width - 20,
                        canvas.height - 20,
                        0,
                        0,
                        croppedCanvas.width,
                        croppedCanvas.height
                    );

                    return croppedCanvas.toDataURL('image/jpeg', 0.8);
                }
                return canvas.toDataURL('image/jpeg', 0.8);
            } catch (error) {
                return null;
            }
        };

        useImperativeHandle(ref, () => ({
            getEditedImage,
        }));

        useEffect(() => {
            if (imageElement) {
                drawImage(imageElement);
            }
        }, [imageElement, rotation, scale, position.x, position.y, isCropping]);

        const handleMouseDown = (e: React.MouseEvent<HTMLCanvasElement>) => {
            if (isCropping) return;
            setIsDragging(true);
            setLastPosition({
                x: e.clientX - position.x,
                y: e.clientY - position.y,
            });
        };

        const handleMouseMove = (e: React.MouseEvent<HTMLCanvasElement>) => {
            if (!isDragging || !imageElement || isCropping) return;

            const dx = e.clientX - lastPosition.x;
            const dy = e.clientY - lastPosition.y;

            setPosition({
                x: dx,
                y: dy,
            });

            drawImage(imageElement);
        };

        const handleMouseUp = () => {
            setIsDragging(false);
        };

        const handleMouseWheel = (e: React.WheelEvent<HTMLCanvasElement>) => {
            e.preventDefault();
            if (!imageElement || isCropping) return;

            const delta = e.deltaY;
            const newScale = scale + (delta > 0 ? -0.1 : 0.1);

            if (newScale >= 0.5 && newScale <= 3) {
                setScale(newScale);
                drawImage(imageElement);
            }
        };

        const handleRotate = () => {
            if (!imageElement) return;
            const newRotation = (rotation + 90) % 360;
            const rotatedPosition = rotatePoint(position.x, position.y, 90);
            setPosition(rotatedPosition);
            setRotation(newRotation);
            drawImage(imageElement);
        };

        const handleCrop = () => {
            setIsCropping(!isCropping);
            if (imageElement) {
                drawImage(imageElement);
            }
        };

        return (
            <ContainerFlex FlexDir="column" Gap="16px" Align="center">
                <ContainerFlex
                    Width="613px"
                    Height="244px"
                    Display="flex"
                    Justify="center"
                    Align="center"
                    backG="#f5f5f5"
                    Radius="16px"
                >
                    <ContainerFlex
                        Width="193px"
                        Height="244px"
                        Border="1px solid #ccc"
                        backG="white"
                    >
                        <StyledCanvas
                            ref={canvasRef}
                            isDragging={isDragging}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseLeave={handleMouseUp}
                            onWheel={handleMouseWheel}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="space-between">
                    <ContainerFlex Height="max-content" Justify="start">
                        <Images src={replace} alt="replace-icone" />
                        <Text
                            Cursor="pointer"
                            Color="#5A5AFF"
                            FontSize="0.75rem"
                            FontWeight="700"
                            onClick={onReplace}
                        >
                            {MODAL_TEXT.REPLACE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Gap="16px" Justify="end">
                        <ContainerFlex Width="max-content">
                            <Images src={rotate} alt="rotate-icone" />
                            <Text
                                Cursor="pointer"
                                Color="#5A5AFF"
                                FontSize="0.75rem"
                                FontWeight="700"
                                onClick={handleRotate}
                            >
                                {MODAL_TEXT.ROTATE}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex Width="max-content" Justify="end" Align="end">
                            <Images src={cut} alt="cut-icone" />
                            <Text
                                onClick={handleCrop}
                                Cursor="pointer"
                                Color="#5A5AFF"
                                FontSize="0.75rem"
                                FontWeight="700"
                            >
                                {MODAL_TEXT.CUT}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        );
    }
);

ImageEditor.displayName = 'ImageEditor';
