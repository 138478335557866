import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {IStyleMessageSetup} from '@/components/CreditsAndPawns/interfaces';

const CreditDetailMessage: React.FC<IStyleMessageSetup> = ({
    backgroundMessage,
    icon,
    alt,
    colorTitle,
    title,
    colorParagraph,
    message,
}) => {
    return (
        <ContainerFlex
            Width="100%"
            Height="90px"
            Padding="16px"
            Radius="8px"
            Gap="8px"
            Justify="start"
            Align="center"
            backG={backgroundMessage}
        >
            <ContainerFlex Flex="0" Justify="start" Align="start">
                <Image src={icon} Height="24px" Width="24px" alt={alt} />
            </ContainerFlex>
            <ContainerFlex
                Flex="1"
                FlexDir="column"
                Justify={message ? 'start' : 'center'}
                Align="start"
                Gap="8px"
            >
                <Text FontFamily="Nunito" FontWeight="700" FontSize="0.88rem" Color={colorTitle}>
                    {title}
                </Text>
                {message && (
                    <Text
                        FontFamily="Nunito"
                        FontWeight="700"
                        FontSize="0.88rem"
                        Color={colorParagraph}
                    >
                        {message}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailMessage;
