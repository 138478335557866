import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    CREATE_AUTOMATIC_LOAN_ACCEPTANCE_ERROR,
    CREATE_AUTOMATIC_LOAN_ACCEPTANCE_START,
    CREATE_AUTOMATIC_LOAN_ACCEPTANCE_SUCCESS,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {AutomaticLoanAcceptanceProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';

export const fetchPostAutomaticLoanStart = () => {
    return {
        type: CREATE_AUTOMATIC_LOAN_ACCEPTANCE_START,
    };
};
export const fetchPostAutomaticLoanSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_AUTOMATIC_LOAN_ACCEPTANCE_SUCCESS,
        payload: result.data,
    };
};
export const fetchPostAutomaticLoanError = (error: AxiosError) => {
    return {
        type: CREATE_AUTOMATIC_LOAN_ACCEPTANCE_ERROR,
        error,
    };
};

export const postAutomaticLoanAcceptanceCommand = (
    data: AutomaticLoanAcceptanceProps,
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchPostAutomaticLoanStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AutomaticLoanAcceptanceCommand}`,
                data,
                {headers}
            );
            dispatch(fetchPostAutomaticLoanSuccess(response));
            dispatch(ActualDataStepLoans(5));
        } catch (error) {
            dispatch(fetchPostAutomaticLoanError(error as AxiosError));
        }
    };
};
