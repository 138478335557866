import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {LOAN_PRODUCT_PREVIEW} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ILoanProductPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {NewProductDetails} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Details/NewProductDetails';
import {NewProductPaymentFrequencyDetails} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/NewProductPaymentFrequencyDetails';
import {NewProductClientsAndDocuments} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/NewProductClientsAndDocuments';
import {NewProductDisbursement} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/NewProductDisbursement';
import {NewProductPaymentMethods} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/NewProductPaymentMethods';
import {NewProductChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/NewProductChargesAndPenalties';
import {useSubmittedLoanData} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/hooks/useSubmittedLoanData';
import {resetLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {resetFrequencyPaymentTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {resetChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {resetDisbursementDocuments} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/DocumentDisbursementActions';
import {resetFrequencyPaymentsReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetFrequencyPaymentsActions';
import {updateDocumentsCopyArrayReset} from '@components/PersonalLoans/Redux/Actions/UpdatePersonalDocumentsActions';

export const LoanProductPreview: React.FC<ILoanProductPreviewProps> = ({
    refs,
    showCancelProductModal,
    setShowCancelProductModal,
}) => {
    const {onSubmit} = useSubmittedLoanData();
    const DataLoans = useSelector((state: RootState) => state.GetTermPersonalLoan.dataLoans);
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const isEditingLoan: boolean = useSelector((state: RootState) => state.setIsEditingLoanReducer);
    const handleCancel = () => {
        navigate('/LoansProducts');
        dispatch(resetLoanSubmittedData());
        dispatch(resetFrequencyPaymentTable());
        dispatch(resetChargesAndPenalties());
        dispatch(resetDisbursementDocuments());
        dispatch(resetFrequencyPaymentsReducer());
        dispatch(updateDocumentsCopyArrayReset());
    };
    const handleSave = () => {
        onSubmit();
        dispatch(updateDocumentsCopyArrayReset());
    };

    return (
        <>
            <NewProductDetails detailsRef={refs.detailsRef} />
            <NewProductPaymentFrequencyDetails frequencyRef={refs.frequencyRef} />
            <NewProductClientsAndDocuments clientAndDocumentsRef={refs.clientAndDocumentsRef} />
            <NewProductDisbursement disbursementsRef={refs.disbursementsRef} />
            <NewProductPaymentMethods paymentMethodRef={refs.paymentMethodRef} />
            <NewProductChargesAndPenalties chargesRef={refs.chargesRef} />

            <ContainerFlex
                Radius="1rem"
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Padding="1.5rem"
                Gap="1rem"
            >
                {(DataLoans && DataLoans?.isEdit === true) || !isEditingLoan ? (
                    <>
                        <ButtonGenerals
                            SecondaryButton="#FFFFFF"
                            Width="147px"
                            type="button"
                            onClick={() => setShowCancelProductModal(!showCancelProductModal)}
                        >
                            {LOAN_PRODUCT_PREVIEW.BTN_CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals type="submit" onClick={() => handleSave()}>
                            {LOAN_PRODUCT_PREVIEW.BTN_SAVE_PRODUCT}
                        </ButtonGenerals>
                    </>
                ) : (
                    <ButtonGenerals
                        SecondaryButton="#FFFFFF"
                        Width="147px"
                        type="button"
                        onClick={() => handleCancel()}
                    >
                        {LOAN_PRODUCT_PREVIEW.BTN_CLOSE}
                    </ButtonGenerals>
                )}
            </ContainerFlex>
        </>
    );
};
