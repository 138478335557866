import React from 'react';
import Logo from '@images/logoAtaskate.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {ImageLogo} from '@Login/style';
import {
    ButtonContainer,
    CardSelectionProps,
    TITLES,
    WhiteText,
} from '@Login/Administrative/DesignConstants';
import AdminCard from '@images/AdminPlat.png';
import PosCard from '@images/PosPlat.png';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {alreadySavedData} from '@Login/Redux/Actions/GetValidateUsers';
import {UserData} from '@Login/interfaces';
import {USER_TYPES} from '@Login/Administrative/constants';
import {GetRouter} from '@/components/AdminNavbar/Redux/Actions/loginTypeRoute';

export const PlataformSelection = () => {
    const userData = useSelector((state: RootState) => state.getUsersValidation.preData);
    const dispatch = useDispatch();
    const handleSelection = (type: number, data: UserData) => {
        const newUserData: UserData = {...data};
        switch (type) {
            case 1:
                newUserData.userType = USER_TYPES.ADMIN;
                break;
            case 2:
                newUserData.userType = USER_TYPES.EMPLOYEE;
                break;
            default:
                break;
        }
        dispatch(alreadySavedData(newUserData));
    };

    return (
        <ContainerFlex FlexDir="column" Height="100vh" backG="#ACACFF">
            {userData && (
                <ContainerFlex
                    backG="#FFFFFF"
                    FlexDir="column"
                    Width="45.313rem"
                    Height="475px"
                    Radius="24px"
                    BoxS="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
                    Padding="24px"
                    Gap="24px"
                >
                    <ImageLogo Height="29px" Width="156px" src={Logo} />
                    <ContainerFlex FlexDir="column" Height="auto">
                        <Text
                            FontSize="1.5rem"
                            FontWeight="700"
                            Color="#54575C"
                            FontFamily="Nunito"
                        >
                            {TITLES.PLAT_FORM_SELECTION}
                        </Text>
                        <Text FontSize="1rem" FontWeight="700" Color="#54575C" FontFamily="Nunito">
                            {TITLES.WICH_PLAT}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex ColumnGap="16px">
                        <ContainerFlex {...CardSelectionProps}>
                            <Text
                                FontSize="1rem"
                                FontWeight="700"
                                Color="#54575C"
                                FontFamily="Nunito"
                            >
                                {TITLES.ADMIN}
                            </Text>
                            <Image Width="60x" Height="60px" src={AdminCard} />
                            <Text
                                FontSize="1rem"
                                Color="#54575C"
                                FontFamily="Nunito"
                                wSpace=""
                                TextAlign="center"
                            >
                                {TITLES.INTEGER}
                            </Text>
                            <ContainerFlex
                                {...ButtonContainer}
                                onClick={() => {
                                    handleSelection(1, userData);
                                    dispatch(GetRouter('/login'));
                                }}
                            >
                                <Text {...WhiteText}>{TITLES.LOGIN}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex {...CardSelectionProps}>
                            <Text
                                FontSize="1rem"
                                FontWeight="700"
                                Color="#54575C"
                                FontFamily="Nunito"
                            >
                                {TITLES.OPERATIVE}
                            </Text>
                            <Image Width="60x" Height="60px" src={PosCard} />
                            <Text
                                FontSize="1rem"
                                Color="#54575C"
                                FontFamily="Nunito"
                                wSpace=""
                                TextAlign="center"
                            >
                                {TITLES.CONTROL}
                            </Text>
                            <ContainerFlex
                                {...ButtonContainer}
                                onClick={() => {
                                    handleSelection(2, userData);
                                    dispatch(GetRouter('/login'));
                                }}
                            >
                                <Text {...WhiteText}>{TITLES.LOGIN}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex Width="199px" Height="auto">
                        <Text
                            FontSize="0.75rem"
                            FontWeight="300"
                            Color="#414042"
                            FontFamily="Nunito"
                            wSpace=""
                            TextDecoration="underline"
                            Cursor="pointer"
                        >
                            {TITLES.TERMS}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
