import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {ContainerCellGrid, ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {routers} from '@/appRoute';
import {getShoppingCartContracts} from '@/components/ShoppingCart/Redux/Actions/getCartContractsActions';
import {QuitNumberFormat, formatterDolar} from '@/hooks/currentFormatUtils';
import {StyleNumericFormat} from '@Loan/styles';
import {IClienctContactPay} from '@/components/Shopify/Products/redux/interface';
import {IconSvg} from '@/components/Quoter/Icons/styles';
import {CART_MESSAGES, PAYMENT_GATEWAY} from '@Loan/PaymentQuoter/constants';
import {addPayment} from '@components/Loan/Redux/actions/AddPayment';
import addCartPayment, {setPaymentPosted} from '@components/Loan/Redux/actions/AddCartPayment';
import {toggleLoading} from '@/components/General/Atoms/LoadingAtaskate/Redux/Actions/loadingActions';
import {changeActualStep} from '@/components/Quoter/Redux/Actions/saveEndeavors';
import {useCloseTransaction} from '@/hooks/useCloseTransaction';

export const ResumePayment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const onCloseTransactionHandler = useCloseTransaction();

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const [Change, setChange] = useState('');
    const [payErrorMessage, setPayErrorMessage] = useState({
        message: '',
        showServiceError: false,
        showInputError: false,
    });
    const paymentMade = useSelector((state: RootState) => state.cartPaymentReducer.paid);
    if (paymentMade) {
        onCloseTransactionHandler();
    }

    const Contracts: IClienctContactPay = useSelector((state: RootState) => state.getCarContracts);
    const branchId = useSelector((state: RootState) => {
        const branchDetails = state.getUsersValidation.userData?.branchDetails;
        if (Array.isArray(branchDetails) && branchDetails.length > 0) {
            return branchDetails[0]?.branchId;
        }
        return undefined;
    });

    const handlePay = async () => {
        if (token && carShoppingId && Contracts.data.clientId) {
            const CartPaymentData = {
                shoppingCarId: carShoppingId,
                branchId: branchId,
            };
            const SendPay = {
                carShoppingId: carShoppingId,
                clientId: Contracts.data.clientId,
                amount: Contracts.data.total,
            };
            setPayErrorMessage({message: '', showServiceError: false, showInputError: false});
            if (Contracts.data.total > QuitNumberFormat(Change) || QuitNumberFormat(Change) === 0) {
                setPayErrorMessage({
                    message: CART_MESSAGES.PAYMENT_ERROR,
                    showServiceError: false,
                    showInputError: true,
                });
            } else {
                try {
                    dispatch(toggleLoading(true, CART_MESSAGES.REDIRECT_TO_SUMMARY));

                    await dispatch(addCartPayment(token, CartPaymentData));
                    await dispatch(addPayment(token, SendPay));

                    navigate(routers.PaymentQuoterConfirmation);
                    dispatch(setPaymentPosted(true));
                } catch (error) {
                    setPayErrorMessage({
                        message: PAYMENT_GATEWAY.PROCESS_PAYMENT_ERROR,
                        showServiceError: true,
                        showInputError: false,
                    });
                }

                dispatch(toggleLoading(false));
            }
        }
    };

    useEffect(() => {
        if (token) {
            dispatch(getShoppingCartContracts(token, {carshopingId: carShoppingId}));
        }
    }, []);

    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="25px">
            <FlexPanel Justify="end" Width="100%" Padding="20px 0 8px 0">
                <Text
                    Color="#A82424"
                    FontWeight="500"
                    Cursor="pointer"
                    onClick={() => {
                        dispatch(changeActualStep(3));
                        navigate(routers.Quoter);
                    }}
                >
                    {CART_MESSAGES.CANCEL_ALL}
                </Text>
            </FlexPanel>
            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                Height="auto"
                Radius="24px"
                Padding="24px"
                Border=" 1px solid #E8E9EA"
                PositionRight="24px"
                Gap="24px"
            >
                <ContainerCellGrid Height="40px" Justify="start" Align="center">
                    <Text Color="#2A2C2F" FontWeight="500" FontSize="1.25rem">
                        {CART_MESSAGES.PAYMENT}
                    </Text>
                </ContainerCellGrid>
                <ContainerFlex
                    Height="auto"
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="8px"
                    Bb="1px solid #E8E9EA"
                    Padding="0 0 24px 0"
                >
                    <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                        {CART_MESSAGES.MONEY}
                    </Text>
                    <StyleNumericFormat
                        type="text"
                        width="90%"
                        maxLength={12}
                        value={Change}
                        prefix="$"
                        hasError={payErrorMessage.showInputError}
                        displayType="input"
                        thousandSeparator={true}
                        allowNegative={false}
                        onValueChange={(values) => {
                            setChange(values.value);
                        }}
                    />
                    {payErrorMessage.showInputError && (
                        <ContainerFlex Justify="start" Align="start" Gap="8px" Height="auto">
                            <IconSvg Height="16px" width="16px">
                                <path
                                    d="M4.26732 12.6666L3.33398 11.7333L7.06732 7.99998L3.33398 4.26665L4.26732 3.33331L8.00065 7.06665L11.734 3.33331L12.6673 4.26665L8.93398 7.99998L12.6673 11.7333L11.734 12.6666L8.00065 8.93331L4.26732 12.6666Z"
                                    fill="#FF6357"
                                />
                            </IconSvg>
                            <Text Color="#FF6357" FontSize=".875rem">
                                {payErrorMessage.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerFlex>
                <ContainerCellGrid
                    Height="auto"
                    Padding="0 0 24px 0"
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="8px"
                    BorderBt="#E8E9EA"
                >
                    <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                        {CART_MESSAGES.CHANGE}
                    </Text>
                    <Text FontWeight="500" Color="#54575C">
                        {Contracts.data.total < QuitNumberFormat(Change)
                            ? formatterDolar.format(QuitNumberFormat(Change) - Contracts.data.total)
                            : formatterDolar.format(0)}
                    </Text>{' '}
                </ContainerCellGrid>
                <ContainerFlex
                    Height="40px"
                    Width="auto"
                    Padding="8px 16px"
                    Radius="32px"
                    Cursor="pointer"
                    backG="#5A5AFF"
                    onClick={handlePay}
                >
                    <Text Color="#FFFFFF" FontWeight="500" Cursor="pointer">
                        {CART_MESSAGES.PAY}
                    </Text>
                </ContainerFlex>
                {payErrorMessage.showServiceError && (
                    <ContainerFlex Justify="start" Align="start" Gap="8px" Height="auto">
                        <IconSvg Height="16px" width="16px">
                            <path
                                d="M4.26732 12.6666L3.33398 11.7333L7.06732 7.99998L3.33398 4.26665L4.26732 3.33331L8.00065 7.06665L11.734 3.33331L12.6673 4.26665L8.93398 7.99998L12.6673 11.7333L11.734 12.6666L8.00065 8.93331L4.26732 12.6666Z"
                                fill="#FF6357"
                            />
                        </IconSvg>
                        <Text Color="#FF6357" FontSize=".875rem" wSpace="normal">
                            {payErrorMessage.message}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
