import {components, MultiValueGenericProps} from 'react-select';
import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';

export const MultiValueContainer = (props: MultiValueGenericProps) => {
    const {children} = props;
    return (
        <components.MultiValueContainer {...props}>
            <ContainerFlex
                Height="32px"
                Padding="0.25rem 0.5rem"
                Gap="0.25rem"
                Radius="8px"
                backG="#F4F5F5"
            >
                {children}
            </ContainerFlex>
        </components.MultiValueContainer>
    );
};
