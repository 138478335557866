import React from 'react';

import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {routers} from '@/appRoute';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ILocationsGroupsAction} from '@components/LocationsBranch/interfaces';
import {INIT_LEVEL, LOCATIONGROUP} from '@components/LocationsBranch/constants';
import plusIcon from '@components/Branches/Images/PlusIcon.svg';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {
    GetCompanyLevelsByCompanyId,
    resetGroups,
} from '@components/LocationsBranch/Redux/Action/GetCompanyLevels';

export const LocationsGroupsAction = ({
    companyName,
    setShowLocationsModal,
    group,
    index,
}: ILocationsGroupsAction) => {
    const location = useLocation();
    const inInherit =
        location.pathname === routers.CreateNewGroup || location.pathname === routers.EditLocation;
    const dispatch = useDispatch();
    const {companyId, token} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token,
        companyId: state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId,
    }));
    const allowInitialLevel = index === 0 && !inInherit;

    const getInitialLevel = () => {
        if (companyId && token && allowInitialLevel) {
            dispatch(resetGroups());
            dispatch(GetCompanyLevelsByCompanyId(companyId, token, INIT_LEVEL));
        }
    };
    return (
        <FlexPanel
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="0.5rem"
            Gap="0.5rem"
            Width="100%"
        >
            <ContainerFlex Justify="start" Align="start">
                <Text
                    FontSize="0.875rem"
                    Color={allowInitialLevel ? '#5A5AFF' : '#2A2C2F'}
                    Cursor={allowInitialLevel ? 'pointer' : 'default'}
                    FontWeight="500"
                    onClick={() => {
                        getInitialLevel();
                    }}
                >
                    {companyName}
                </Text>
            </ContainerFlex>
            {!inInherit && (
                <ContainerFlex
                    Justify="start"
                    Cursor="pointer"
                    onClick={() => setShowLocationsModal(group?.id || INIT_LEVEL)}
                >
                    <Image src={plusIcon} alt="add" Width="2.5rem" Height="1.5rem" />
                    <Text Color="#5A5AFF" FontWeight="700" FontSize="0.875rem" Cursor="pointer">
                        {LOCATIONGROUP.ADDGROUP}
                    </Text>
                </ContainerFlex>
            )}
        </FlexPanel>
    );
};
