import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {URL} from '@config/constants/index';

import {GET_SCHEDULE_LOCATION_ID} from '@components/LocationsBranch/Redux/types';
import {IOpeningHours} from '@components/LocationsBranch/Redux/interfaces';

export const getScheduleLocationStart = () => ({
    type: GET_SCHEDULE_LOCATION_ID.SCHEDULE_LOCATION_START,
});

export const getScheduleLocationSuccess = (result: {data: AxiosResponse}) => ({
    type: GET_SCHEDULE_LOCATION_ID.SCHEDULE_LOCATION_SUCCESS,
    payload: {
        scheduleLocation: result.data,
    },
});

export const getScheduleLocationError = (error: AxiosError) => ({
    type: GET_SCHEDULE_LOCATION_ID.SCHEDULE_LOCATION_ERROR,
    error: error,
});

export const getScheduleLocation = (
    data: {
        LocationId: number;
        TypeLocation: number;
    },
    token: string,
    setSchedule: (data: IOpeningHours[]) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getScheduleLocationStart());
        const queryString = StringUtils.jsonToQueryString({...data});

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetOpenHoursByLocationId}${queryString}`,
                {headers}
            );
            dispatch(getScheduleLocationSuccess(response.data));
            setSchedule(response.data.data);
        } catch (error) {
            dispatch(getScheduleLocationError(error as AxiosError));
        }
    };
};
