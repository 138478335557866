import React from 'react';
import {ContainerFlex, Text, Image, TextEllipsis} from '@components/Shopify/Ecommerce/styles';
import {TableItem, TableRowItems} from '@components/Shopify/Endeavors/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {IApplicationRowProps} from '@components/CreditsAndPawns/interfaces';
import blueSmallArrowRight from '@components/CreditsAndPawns/icons/blueSmallArrowRight.svg';
import clientIcon from '@components/CreditsAndPawns/icons/clientIcon.svg';
import {DateTime} from 'luxon';
import {useNavigate} from 'react-router-dom';
import {
    TABLE_STATUS_FILTER_VALUES,
    statusColorsMap,
    CREDIT_REQUEST_STATUS_ID,
} from '@components/CreditsAndPawns/constants';
import {URL} from '@/config/constants';
import {routers} from '@/appRoute';

export const getCreditURL = (solicitudeId: string) => {
    return `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditRequest}`.replace(
        '{solicitudeId}',
        solicitudeId
    );
};

export const statusColorHandler = (statusId: number) => {
    return statusColorsMap[statusId] || statusColorsMap.default;
};

export const ApplicationRow = ({application, handleApplicationCheck}: IApplicationRowProps) => {
    const navigate = useNavigate();
    const requestedDate = application.requestDate
        ? DateTime.fromISO(application.requestDate).toFormat('dd/MM/yyyy')
        : 'Invalid Date';
    const paymentDate = application.paymentDate
        ? DateTime.fromISO(application.paymentDate).toFormat('dd/MM/yyyy')
        : 'Invalid Date';

    const updateDate = application.updateDate
        ? DateTime.fromISO(application.updateDate).toFormat('dd/MM/yyyy')
        : paymentDate;

    const {color, backgroundColor, borderColor} = statusColorHandler(application.statusId);

    const needAdditionalDateColumn =
        application.statusId !== TABLE_STATUS_FILTER_VALUES.SOLICITUDES;

    const addtionalColumn = needAdditionalDateColumn ? '5fr' : '';

    const navigateTo = () => {
        navigate(
            application.statusId !== CREDIT_REQUEST_STATUS_ID
                ? routers.CreditDetail.replace(':id', String(application.applicationId))
                : routers.CreditRequest.replace(':id', String(application.applicationId))
        );
    };

    return (
        <>
            <TableRowItems
                Height="48px"
                GridColumn={`2fr 142px minmax(210px, 250px) minmax(70px,3fr) minmax(70px,3fr) minmax(110px,4fr) 100px minmax(125px,5fr) ${addtionalColumn} minmax(133px,7fr) 136px`}
                Margin="0px"
                BorderL="none"
                BorderT="none"
                BorderR="none"
                Radius="0px"
            >
                <TableItem Padding="1.125rem 1rem">
                    <Checkbox
                        checked={application.isChecked}
                        onChange={() => handleApplicationCheck(application.applicationId)}
                    />
                </TableItem>
                <TableItem Padding="1.125rem 1rem" Justify="start">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {application.applicationNumber}
                    </Text>
                </TableItem>
                <TableItem Padding="1.125rem 1rem" Justify="start" Align="center" Gap="0.5rem">
                    <Image Width="18px" Height="18px" src={clientIcon} />
                    <TextEllipsis
                        FontFamily="Nunito"
                        FontWeight="400"
                        FontSize="0.875rem"
                        Color="#2A2C2F"
                        TextAlign="start"
                    >
                        {application.clientName}
                    </TextEllipsis>
                </TableItem>
                <TableItem Padding="1.125rem 1rem">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {application.score}
                    </Text>
                </TableItem>
                <TableItem Padding="1.125rem 1rem">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {`${application.fee}%`}
                    </Text>
                </TableItem>
                <TableItem Padding="1.125rem 1rem" Justify="start">
                    <TextEllipsis
                        FontFamily="Nunito"
                        FontWeight="400"
                        TextAlign="start"
                        FontSize="0.875rem"
                        Color="#2A2C2F"
                        Width="100%"
                    >
                        {application.nameTerm}
                    </TextEllipsis>
                </TableItem>
                <TableItem Padding="1.125rem 1rem" Justify="start">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {`$${application.amount}`}
                    </Text>
                </TableItem>
                <TableItem Padding="1.125rem 1rem" Justify="start">
                    <Text FontSize="0.875rem" Color="#2A2C2F">
                        {requestedDate}
                    </Text>
                </TableItem>
                {needAdditionalDateColumn && (
                    <TableItem Padding="1.125rem 1rem" Justify="start">
                        <Text FontSize="0.875rem" Color="#2A2C2F">
                            {updateDate}
                        </Text>
                    </TableItem>
                )}
                <TableItem Padding="1.125rem 1rem" Justify="start">
                    <Text
                        FontSize="0.75rem"
                        FontWeight="700"
                        Color={color}
                        BorderRadius="32px"
                        bGround={backgroundColor}
                        Border={`1px solid ${borderColor}`}
                        Padding="0.125rem 1rem"
                        tOver="ellipsis"
                        oFlow="hidden"
                    >
                        {application.statusName}
                    </Text>
                </TableItem>
                <TableItem Padding="1.125rem 1rem" onClick={navigateTo}>
                    <ContainerFlex Gap="0.25rem" Justify="end" Cursor="pointer">
                        <Text Color="#5A5AFF" FontSize="0.875rem" FontWeight="700" Cursor="pointer">
                            {'Ver detalle'}
                        </Text>
                        <Image Width="24px" Height="24px" src={blueSmallArrowRight} />
                    </ContainerFlex>
                </TableItem>
            </TableRowItems>
        </>
    );
};
