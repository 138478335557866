import React, {useState} from 'react';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerForm,
    Text,
    Image,
    ContainerImg,
} from '@/components/Shopify/Ecommerce/styles';
import {
    PASSWORD_CONFIG,
    RESET_PASSWORD,
} from '@/components/UserProfileResetPassword/Operational/Constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {shemaResetPassword} from '@/components/UserProfileResetPassword/Operational/ValidateYup';
import {NewPassword} from '@/components/UserProfileResetPassword/Operational/interface';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {ErrorPassword} from '@/components/UserProfileResetPassword/ErrorMessagePass';
import {ErrorMessagesPassword} from '@/components/UserProfileResetPassword/ErrorMessage';
import {NewPasswordUserData} from './Redux/Actions/ResetPasswordUserAction';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {SearchInputCherry} from './InputNew';
import SplitInput from './InputMounth';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';

interface ResetPasswordFormProps {
    setIsEdit: (value: boolean) => void;
}

export const ResetPasswordForm = ({setIsEdit}: ResetPasswordFormProps) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const employeeId = useSelector((state: RootState) => state.UserProfileAdmin.selectedEmployId);
    const [viewPassword, setViewPassword] = useState<boolean>(false);
    const [viewPasswordActualy, setviewPasswordActualy] = useState<boolean>(false);

    const {
        reset,
        handleSubmit,
        register,
        formState: {errors},
        watch,
    } = useForm<NewPassword>({
        resolver: yupResolver(shemaResetPassword),
        mode: 'onChange',
    });

    const password = watch('password');

    const onSubmit = (values: NewPassword) => {
        const newUserData = {
            employeeId: employeeId,
            password: values.password,
        };
        dispatch(NewPasswordUserData(newUserData, token));
        reset({
            password: '',
        });
        setIsEdit(false);
    };

    const handleReset = () => {
        setIsEdit(false);
        reset({
            password: '',
        });
    };

    return (
        <ContainerForm onSubmit={handleSubmit(onSubmit)} FlexDir="column" Gap="24px">
            <ContainerFlex FlexDir="column" Align="start">
                <Text FontWeight="700" FontSize="1.25rem" Color="#2A2C2F">
                    {RESET_PASSWORD.TITLE_TWO}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="24px">
                <ContainerCellGrid Gap="32px" Justify="start" Align="start">
                    <ContainerFlex FlexDir="column" Align="start">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.ACTUAL_PASS}
                        </Text>
                        <SearchInputCherry
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            Margin="0 0 4px 0"
                            Padding="0 1rem"
                            BackG="#FFFFFF"
                            Radius="32px"
                        >
                            <input
                                type={viewPasswordActualy ? 'text' : 'password'}
                                defaultValue={PASSWORD_CONFIG.currentPassword}
                                readOnly
                            />

                            <ContainerImg
                                onClick={() => setviewPasswordActualy(!viewPasswordActualy)}
                                Cursor="pointer"
                            >
                                <Image
                                    src={viewPasswordActualy ? VisibilityOn : VisibilityOff}
                                    alt="icon-visibility"
                                    Cursor="pointer"
                                />
                            </ContainerImg>
                        </SearchInputCherry>
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.TEX_PASS}
                        </Text>
                        <SearchInputCherry
                            Height="40px"
                            Position="none"
                            GridColumn="90% 10%"
                            Margin="0 0 4px 0"
                            Padding="0 1rem"
                            BackG="#FFFFFF"
                            error={errors.password?.message}
                            Radius="32px"
                        >
                            <input
                                type={viewPassword ? 'text' : 'password'}
                                {...register('password')}
                            />

                            <ContainerImg
                                onClick={() => setViewPassword(!viewPassword)}
                                Cursor="pointer"
                            >
                                <Image
                                    src={viewPassword ? VisibilityOn : VisibilityOff}
                                    alt="icon-visibility"
                                    Cursor="pointer"
                                />
                            </ContainerImg>
                        </SearchInputCherry>
                        {password && password.length > 0 && (
                            <ErrorPassword error={!!errors.password?.message} password={password} />
                        )}
                        {errors.password?.message && (!password || password.length === 0) && (
                            <ErrorMessagesPassword message={errors.password?.message} />
                        )}
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start">
                        <Text FontWeight="700" Color="#2A2C2F">
                            {RESET_PASSWORD.NEW_PASS}
                        </Text>
                        <SplitInput />
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerFlex
                    backG="#FFF"
                    Gap="16px"
                    Radius="16px"
                    Height="max-content"
                    Justify="end"
                >
                    <ButtonGenerals
                        type="button"
                        onClick={handleReset}
                        Width="147px"
                        SecondaryButton="#FFFFFF"
                    >
                        {RESET_PASSWORD.BTN}
                    </ButtonGenerals>
                    <ButtonGenerals type="submit" Width="147px">
                        {RESET_PASSWORD.BTN_KEEP}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};
