import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import * as style from '@Shopify/ClientProfile/slylesClient';
import {IdetailsContract, ITabGarments} from '@Shopify/ClientProfile/interfaces';
import {CONTRACT_DETAIL} from '@Shopify/ClientProfile/constants';
import {ContainerFlex, Text, Image, ContainerGrid} from '@Shopify/Ecommerce/styles';
import {saveContractsImg} from '@Shopify/ClientProfile/Redux/Action/DetailsContract';

export const ContractTabArticles = ({setModalImg}: ITabGarments) => {
    const dispatch = useDispatch();
    const {details} = useSelector((state: RootState) => state.detailContract);

    const showThumbnail = (article: IdetailsContract) => {
        setModalImg(true);
        const json = [
            ...article.image,
            ...[{imageId: 1, position: 0, isPrincipal: true, urlImage: article.catalogImage}],
        ];
        dispatch(saveContractsImg(json));
    };

    return (
        <ContainerFlex FlexDir="column" Align="start" Gap="1rem" Height="min-content">
            <Text Color="#000000" FontWeight="700" FontSize="1.25rem">
                {CONTRACT_DETAIL.GARMENTS}
            </Text>
            <ContainerFlex
                Padding="1.5rem"
                Gap="1.5rem"
                Border="1px solid #E8E9EA"
                Radius="0.5rem"
                FlexDir="Column"
            >
                {details &&
                    details.contractDetail &&
                    details.contractDetail.garmentsInTheContract &&
                    details.contractDetail.garmentsInTheContract.map((article) => (
                        <ContainerGrid key={article.garmentId} GridColumns="1fr 9fr" Gap="0.625rem">
                            <ContainerFlex
                                Cursor="pointer"
                                onClick={() => showThumbnail(article)}
                                {...style.contentImg}
                                Radius="0.25rem"
                                Width="5.5rem"
                            >
                                <Image
                                    src={article.catalogImage}
                                    {...style.img}
                                    Width="5.438rem"
                                    Height="5.438rem"
                                    alt="garment"
                                />
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Align="start">
                                <Text
                                    Padding="0.25rem 0 0.5rem 0"
                                    {...style.garments.titles}
                                    FontWeight="700"
                                >
                                    {article.garmentsName}
                                </Text>
                                <ContainerGrid
                                    Gap="1.5rem"
                                    GridColumns="3fr repeat(3,2fr)"
                                    Height="min-content"
                                    Padding="0.25rem 0 0.125rem 0"
                                    BorderTp="1px solid #E8E9EA"
                                >
                                    {CONTRACT_DETAIL.ARTICLES_HEADERS.map((header, index) => (
                                        <Text key={index} Color="#2A2C2F">
                                            {header}
                                        </Text>
                                    ))}
                                </ContainerGrid>
                                <ContainerGrid
                                    Gap="1.5rem"
                                    GridColumns="3fr repeat(3,2fr)"
                                    Align="start"
                                >
                                    <Text Color="#5A5AFF" FontWeight="600">
                                        {article.houseAndBranch}
                                    </Text>
                                    <Text {...style.garments.titles}>{article.appraisal}</Text>
                                    <Text {...style.garments.titles}>{article.loan}</Text>
                                    <Text {...style.garments.titles} wSpace="normal">
                                        {article.terms.toLowerCase()}
                                    </Text>
                                </ContainerGrid>
                            </ContainerFlex>
                        </ContainerGrid>
                    ))}
            </ContainerFlex>
        </ContainerFlex>
    );
};
