import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {
    EditForm,
    GroupEditForm,
    IBranchHolidays,
    IFormBranch,
    IHolidays,
    User,
} from '@components/LocationsBranch/interfaces';
import {INITIAL_OPENING_HOURS} from '@components/LocationsBranch/constants';
import {formatHours} from '@/hooks/currentFormatUtils';
import {IOpeningHours} from '@components/LocationsBranch/Redux/interfaces';
import {setAddedUsers} from '@components/LocationsBranch/Redux/Action/AddUser';
import {getDayHoursInterval} from '@components/LocationsBranch/utils/GetIntervals';
import {addAddress} from '@components/LocationsBranch/Redux/Action/AddAddressLocation';
import {scheduleUpdate} from '@components/LocationsBranch/Redux/Action/GetSchedule';
import {ISchedule} from '@/components/LocationsBranch/interfaces';
import {transformData, addWarehouses} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {addHoliday} from '@components/LocationsBranch/Redux/Action/GetHolidays';

export const useInitializeForm = () => {
    const dispatch = useDispatch();
    const {AddUserEmployee} = useSelector((state: RootState) => ({
        AddUserEmployee: state.AddUserEmployee,
    }));
    const initialize = (
        source: EditForm | GroupEditForm | IFormBranch | null,
        nameOf: string,
        setValue: (name: string, value: string) => void
    ) => {
        if (!source) return;
        const {name = '', users} = source || {};
        const newUsers =
            (users &&
                users.map((user: User) => ({
                    employeeId: user.usersId,
                    imageURL: user.imageUrl,
                    name: user.userName,
                    role: user.rolName,
                    dateSession: new Date(user.lastLogin),
                    active: user.status,
                }))) ||
            [];
        if (name) setValue(nameOf, name);
        if (newUsers.length) {
            dispatch(setAddedUsers([...AddUserEmployee.addedUsers, ...newUsers]));
        }
        if ('address' in source && source.address) {
            const {
                state = '',
                stateId = '',
                municipal = '',
                municipalId = '',
                colony = '',
                colonyId = '',
                interiorNumber = '',
                outerNumber = '',
                street = '',
                postalCode = '',
            } = source.address;

            const newAddress = {
                state: {label: state, value: stateId.toString()},
                municipaly: {label: municipal, value: municipalId.toString()},
                colony: {label: colony, value: colonyId.toString()},
                interNum: interiorNumber,
                outerNum: outerNumber,
                street,
                postalCode,
            };

            dispatch(addAddress(newAddress));
        }
        if ('openingHours' in source && source.openingHours) {
            const intervals = getDayHoursInterval(0, 23, 30);
            const intervalsOptions = intervals.map((item: {time: string; id: number}) => ({
                label: item.time,
                value: String(item.id),
            }));
            const newSchedule: ISchedule[] =
                source.openingHours.map((hours: IOpeningHours) => ({
                    id: hours.openingHourId,
                    levelId: hours.openingHourId,
                    openingTime:
                        intervalsOptions.find(
                            (item) => item.label === formatHours(hours.openingAttentionSchedule)
                        )?.label || INITIAL_OPENING_HOURS.OPENING.label,
                    closingTime:
                        intervalsOptions.find(
                            (item) => item.label === formatHours(hours.closingAttentionSchedule)
                        )?.label || INITIAL_OPENING_HOURS.CLOSING.label,
                    dayName: hours.dayName,
                    dayOfWeek: hours.weekDay,
                    status: hours.status,
                })) || [];
            dispatch(scheduleUpdate(newSchedule));
        }

        if ('holidays' in source && source.holidays) {
            const intervals = getDayHoursInterval(0, 23, 30);
            const intervalsOptions = intervals.map((item: {time: string; id: number}) => ({
                label: item.time,
                value: String(item.id),
            }));
            const newHoliday: IHolidays[] =
                source.holidays.map((hour: IBranchHolidays) => ({
                    id: hour.holidayId,
                    date:
                        intervalsOptions.find((item) => item.label === formatHours(hour.date))
                            ?.label || INITIAL_OPENING_HOURS.OPENING.label,
                    halfDay: false,
                    endTime:
                        intervalsOptions.find(
                            (item) => item.label === formatHours(hour.endActivities)
                        )?.label || INITIAL_OPENING_HOURS.CLOSING.label,
                    reason: hour.reason,
                    status: hour.status,
                    levelId: hour.holidayId,
                })) || [];
            dispatch(addHoliday(newHoliday));
        }

        if ('storagesDetail' in source && source.storagesDetail) {
            const fromService = transformData(source.storagesDetail);
            dispatch(addWarehouses(fromService));
        }
    };
    return {initialize};
};
