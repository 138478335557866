import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {FilterDeadline} from '@Deadlinesindex/FilterDeadline';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {TableItemsContainer, FontAwesomeIcon, TableItems, TableItem} from '@DeadLines/styles';
import {TableRowHead, TableRowItems, Table} from '@CashFlow/FundingInquiry/styles';
import {IconContainer} from '@/components/Quoter/styles';
import {
    deleteDataDeadline,
    deleteDeadlines,
    getCopy,
    getEdit,
    postAddDeadline,
} from '@ActionsCreateDeadline/GetAllPromotionServices';
import {FalseDeadlines, RestartRoute, RestartSearchArticle} from '@ActionsDeadlinesindex/Search';
import {LoadingGeneral} from '@/components/General/Atoms/Loading/LoadingGeneral';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {DeadlineTable} from '@/components/DeadLines/DeadlinesIndex/redux/Interface';
import * as style from '@Articles/style';
import {ContextualMenuDeadline} from '@DeadLines/DeadlinesIndex/ContextualMenuDeadlines';
import {deadlines} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import {DeadLineTable} from '@CreateDeadline/interfaces';
import {
    filterHead,
    orderType,
} from '@components/DeadLines/DeadlinesIndex/redux/actions/DeadlineAction';

export default function Deadline(props: DeadlineTable): JSX.Element {
    const {Branch, filterDeadline, searchTerm, setsearchTerm, pageSize, setPageSize, deadline} =
        props;
    const login = useSelector((state: RootState) => state.getUsersValidation.userData);
    const dispatch: AppDispatch = useDispatch();
    const [sortBy, setSortBy] = useState(true);
    const [selected, setSelected] = useState(0);
    const [contextualMenu, setContextualMenu] = useState(false);

    const orders = (order: boolean) => {
        const ORDERTYPE: {[key: string]: number} = {
            true: 1,
            false: 0,
        };
        return ORDERTYPE[String(order)];
    };

    const handleClickAway = () => {
        setContextualMenu(false);
        setSelected(0);
    };

    const resetInfo = () => {
        dispatch(deleteDataDeadline());
        dispatch(RestartRoute());
        dispatch(RestartSearchArticle());
        dispatch(FalseDeadlines());
        dispatch(deadlines());
        dispatch(getEdit(false));
        dispatch(getCopy(false));
        dispatch(postAddDeadline());
    };

    const updateStatusDeadline = async (id: number) => {
        await dispatch(deleteDeadlines(id, String(login?.token)));
        filterDeadline(false);
    };
    const setOrderFilters = (data: {filter: number; order: boolean}) => {
        dispatch(filterHead(data.filter));
        dispatch(orderType(orders(data.order)));
    };

    useEffect(() => {
        resetInfo();
    }, []);

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex Justify="space-between">
                <Text FontSize="1rem" Justify="flex-start">
                    Plazos disponibles para
                    <Text Padding="0 0 0 8px" FontWeight="bold">
                        {login?.enterpriceDetails?.[0]?.enterpriceName}
                    </Text>
                </Text>
            </ContainerFlex>

            {Branch.length === 0 ? (
                <ContainerFlex {...style.Card}>
                    <Text Padding="2rem 1.5rem">Seleccione una Sucursal.</Text>
                </ContainerFlex>
            ) : (
                <>
                    <FilterDeadline
                        filterDeadline={filterDeadline}
                        searchTerm={searchTerm}
                        setsearchTerm={setsearchTerm}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        deadline={deadline}
                    />
                    <ContainerFlex FlexDir="column" MarginBt="12px">
                        {deadline.loading ? (
                            <ContainerFlex Width="100%">
                                <LoadingGeneral />
                            </ContainerFlex>
                        ) : deadline.searchDeadlines && deadline.deadlineCount.data.length === 0 ? (
                            <ContainerFlex Padding="1rem">
                                No se encontraron resultados, intenta modificar los parámetros de
                                búsqueda.
                            </ContainerFlex>
                        ) : (
                            <Table>
                                <TableRowHead GridColumn="1fr 2fr 2fr 1fr 1fr 1fr" Height="48px">
                                    <TableItem
                                        Justify="center"
                                        FontSize="0.875rem"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSortBy(!sortBy);
                                            setOrderFilters({filter: 0, order: sortBy});
                                        }}
                                    >
                                        Plazo
                                        <IconContainer FontSize="1rem" className="material-icons">
                                            unfold_more
                                        </IconContainer>
                                    </TableItem>
                                    <TableItem
                                        Justify="flex-start"
                                        FontSize="0.875rem"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSortBy(!sortBy);
                                            setOrderFilters({filter: 1, order: sortBy});
                                        }}
                                    >
                                        Nombre del Plazo
                                        <IconContainer FontSize="1rem" className="material-icons">
                                            unfold_more
                                        </IconContainer>
                                    </TableItem>
                                    <TableItem
                                        Justify="center"
                                        FontSize="0.875rem"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSortBy(!sortBy);
                                            setOrderFilters({filter: 2, order: sortBy});
                                        }}
                                    >
                                        Sucursal
                                        <IconContainer FontSize="1rem" className="material-icons">
                                            unfold_more
                                        </IconContainer>
                                    </TableItem>
                                    <TableItem
                                        Justify="center"
                                        FontSize="0.875rem"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSortBy(!sortBy);
                                            setOrderFilters({filter: 3, order: sortBy});
                                        }}
                                    >
                                        Fecha Inicio
                                        <IconContainer FontSize="1rem" className="material-icons">
                                            unfold_more
                                        </IconContainer>
                                    </TableItem>
                                    <TableItem
                                        Justify="center"
                                        FontSize="0.875rem"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSortBy(!sortBy);
                                            setOrderFilters({filter: 4, order: sortBy});
                                        }}
                                    >
                                        Fecha Fin
                                        <IconContainer FontSize="1rem" className="material-icons">
                                            unfold_more
                                        </IconContainer>
                                    </TableItem>
                                    <TableItem
                                        Justify="center"
                                        FontSize="0.875rem"
                                        Cursor="pointer"
                                        onClick={() => {
                                            setSortBy(!sortBy);
                                            setOrderFilters({filter: 5, order: sortBy});
                                        }}
                                    >
                                        Activo
                                        <IconContainer FontSize="1rem" className="material-icons">
                                            unfold_more
                                        </IconContainer>
                                    </TableItem>
                                </TableRowHead>
                                {deadline.deadline &&
                                    deadline.deadline.map((item: DeadLineTable, index: number) => {
                                        return (
                                            <Fragment key={index}>
                                                <TableItemsContainer Margin="0 0 0.25rem 0">
                                                    <TableRowItems
                                                        Height="40px"
                                                        GridColumn="1fr 2fr 2fr 1fr 1fr 1fr"
                                                    >
                                                        <TableItems
                                                            FontSize="0.875rem"
                                                            Justify="center"
                                                        >
                                                            {item.productNumber}
                                                        </TableItems>
                                                        <TableItems
                                                            FontSize="0.875rem"
                                                            Justify="flex-start"
                                                            title={item.nameTerm}
                                                        >
                                                            {item.nameTerm.length > 30
                                                                ? `${item.nameTerm.slice(0, 30)}...`
                                                                : item.nameTerm}
                                                        </TableItems>
                                                        <TableItems
                                                            FontSize="0.875rem"
                                                            Justify="center"
                                                        >
                                                            {item.branchName}
                                                        </TableItems>
                                                        <TableItems Justify="center">
                                                            {item.startDate}
                                                        </TableItems>
                                                        <TableItems Justify="center">
                                                            {item.finalDate}
                                                        </TableItems>
                                                        <TableItems Justify="center">
                                                            <ContainerFlex
                                                                Justify="flex-end"
                                                                Width="60%"
                                                                Cursor="pointer"
                                                                onClick={() =>
                                                                    updateStatusDeadline(item.id)
                                                                }
                                                            >
                                                                <GreenSwitch
                                                                    disabled
                                                                    checked={item.status}
                                                                    size="small"
                                                                />{' '}
                                                            </ContainerFlex>

                                                            {selected === item.id && (
                                                                <ContextualMenuDeadline
                                                                    handleClickAway={
                                                                        handleClickAway
                                                                    }
                                                                    contextualMenu={contextualMenu}
                                                                    dinamicTop={item.id}
                                                                    id={item.id}
                                                                />
                                                            )}

                                                            <ContainerFlex
                                                                Justify="flex-end"
                                                                Width="40%"
                                                            >
                                                                <FontAwesomeIcon
                                                                    ColorH="#7ebb48"
                                                                    onClick={() => {
                                                                        setSelected(item.id);
                                                                        setContextualMenu(true);
                                                                    }}
                                                                >
                                                                    <i className="material-icons">
                                                                        more_vert
                                                                    </i>
                                                                </FontAwesomeIcon>
                                                            </ContainerFlex>
                                                        </TableItems>
                                                    </TableRowItems>
                                                </TableItemsContainer>
                                            </Fragment>
                                        );
                                    })}
                            </Table>
                        )}
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
}
