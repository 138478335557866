import {COMPANY_AVATAR_DEFAULT_COLORS} from '@/components/CreditQuoter/constants';
import {
    TOGGLE_FILTER_MODAL,
    SET_FILTER_VALUES,
    SET_VISUAL_FILTER_VALUES,
    CLEAR_FILTERS_VALUES,
    GET_LOAN_LIST_START,
    GET_LOAN_LIST_SUCCESS,
    GET_LOAN_LIST_ERROR,
    RESET_MEDIA_LOAN_INFO,
    SET_FILTER_LIST,
} from '@/components/GlobalQuoter/Redux/Types/Types';
import {
    ICompaniesOffert,
    IGlobalFilterReducerInitialState,
    IGlobaQuoterFilterValues,
} from '@/components/GlobalQuoter/interface';
import {AxiosResponse, AxiosError} from 'axios';

const initialFiltersValues = {
    rateMin: 0,
    rateMax: 10,
    company: [],
    disburstmentType: [],
};
const initialState: IGlobalFilterReducerInitialState = {
    isLoading: false,
    error: false,
    isFilterModalOpen: false,
    filtersValues: initialFiltersValues,
    filterVisualStatus: initialFiltersValues,
    disbursementList: [],
    companyList: [],
    maxIinterestRate: 0,
    quoterCompleteList: [],
    filterList: [],
    mediaAnualRate: 0,
    monthtlyPayments: 0,
    offertLoansCount: 0,
    companiesCount: 0,
};

const clearFiltersValues = (state: IGlobalFilterReducerInitialState) => {
    return {
        ...state,
        filtersValues: {
            rateMin: 0,
            rateMax: state.maxIinterestRate,
            company: state.companyList.map((company) => company.value),
            disburstmentType: state.disbursementList.map((disbursement) => disbursement.value),
        },
        filterVisualStatus: {
            rateMin: 0,
            rateMax: state.maxIinterestRate,
            company: state.companyList.map((company) => company.value),
            disburstmentType: state.disbursementList.map((disbursement) => disbursement.value),
        },
        filterList: [],
    };
};

const filterQuoterList = (
    state: IGlobalFilterReducerInitialState,
    action: {
        type: string;
        filterValues: IGlobaQuoterFilterValues;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    return {
        ...state,
        filterList: state.quoterCompleteList.filter((quoter) => {
            return (
                quoter.anualRate >= action.filterValues.rateMin &&
                quoter.anualRate <= action.filterValues.rateMax &&
                (action.filterValues.company.length === 0 ||
                    action.filterValues.company.includes(quoter.companyId)) &&
                (action.filterValues.disburstmentType.length === 0 ||
                    action.filterValues.disburstmentType.some((disbursement) =>
                        quoter.disbursementsCompany.some(
                            (disbursementCompany) =>
                                disbursementCompany.disbursementId === disbursement
                        )
                    ))
            );
        }),
    };
};

const globalQuoterFilterRedcuer = (
    state = initialState,
    action: {
        type: string;
        filterValues: IGlobaQuoterFilterValues;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    switch (action.type) {
        case TOGGLE_FILTER_MODAL:
            return {
                ...state,
                isFilterModalOpen: !state.isFilterModalOpen,
            };
        case SET_FILTER_VALUES:
            return {
                ...state,
                filtersValues: action.filterValues,
            };
        case SET_VISUAL_FILTER_VALUES:
            return {
                ...state,
                filterVisualStatus: action.filterValues,
            };
        case CLEAR_FILTERS_VALUES:
            return clearFiltersValues(state);
        case GET_LOAN_LIST_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_LOAN_LIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                error: false,
                quoterCompleteList: getQuoterCompleteList(
                    action.payload.data.data.companiesOfferts
                ),
                mediaAnualRate: action.payload.data.data.mediaAnualRate,
                monthtlyPayments: action.payload.data.data.monthtlyPayments,
                offertLoansCount: action.payload.data.data.offertLoansCount,
                companiesCount: action.payload.data.data.companiesCount,
                maxIinterestRate: action.payload.data.data.maxIinterestRate,
                disbursementList: action.payload.data.data.disbursementList.map(
                    (disbursement: {namedDisbursement: string; disbursementId: number}) => {
                        return {
                            label: disbursement.namedDisbursement,
                            value: disbursement.disbursementId,
                        };
                    }
                ),
                companyList: action.payload.data.data.companyList.map(
                    (company: {companyName: string; companyId: number}) => {
                        return {
                            label: company.companyName,
                            value: company.companyId,
                        };
                    }
                ),
                filterVisualStatus: {
                    rateMin: 0,
                    rateMax: action.payload.data.data.maxIinterestRate,
                    company: action.payload.data.data.companyList.map(
                        (company: {companyName: string; companyId: number}) => company.companyId
                    ),
                    disburstmentType: action.payload.data.data.disbursementList.map(
                        (disbursement: {namedDisbursement: string; disbursementId: number}) =>
                            disbursement.disbursementId
                    ),
                },
                filtersValues: {
                    rateMin: 0,
                    rateMax: action.payload.data.data.maxIinterestRate,
                    company: action.payload.data.data.companyList.map(
                        (company: {companyName: string; companyId: number}) => company.companyId
                    ),
                    disburstmentType: action.payload.data.data.disbursementList.map(
                        (disbursement: {namedDisbursement: string; disbursementId: number}) =>
                            disbursement.disbursementId
                    ),
                },
            };
        case GET_LOAN_LIST_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        case RESET_MEDIA_LOAN_INFO:
            return {
                ...state,
                mediaAnualRate: 0,
                monthtlyPayments: 0,
                offertLoansCount: 0,
                companiesCount: 0,
                quoterCompleteList: [],
                filterList: [],
                disbursementList: [],
                companyList: [],
                maxIinterestRate: 0,
            };
        case SET_FILTER_LIST:
            return filterQuoterList(state, action);
        default:
            return state;
    }
};

const getQuoterCompleteList = (companiesOfferts: ICompaniesOffert[]): ICompaniesOffert[] => {
    const companyAvatarColors = new Map<number, string>();
    return companiesOfferts.map((companyOffer) => {
        let color = companyAvatarColors.get(companyOffer.companyId);

        if (!color) {
            color =
                COMPANY_AVATAR_DEFAULT_COLORS[
                    Math.floor(Math.random() * COMPANY_AVATAR_DEFAULT_COLORS.length)
                ];
            companyAvatarColors.set(companyOffer.companyId, color);
        }
        return {...companyOffer, companyAvatarColor: color};
    });
};

export default globalQuoterFilterRedcuer;
