import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_LEVEL_TREE_BRANCH_START,
    GET_LEVEL_TREE_BRANCH_SUCCESS,
    GET_LEVEL_TREE_BRANCH_ERROR,
} from '@components/NewUserEmployee/Redux/Types/Types';

import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';

export const getLevelTreeBranchStart = () => {
    return {
        type: GET_LEVEL_TREE_BRANCH_START,
    };
};

export const getLevelTreeBranchSuccess = (result: AxiosResponse) => {
    return {
        type: GET_LEVEL_TREE_BRANCH_SUCCESS,
        payload: result.data,
    };
};

export const getLevelTreeBranchError = (error: AxiosError) => {
    return {
        type: GET_LEVEL_TREE_BRANCH_ERROR,
        error,
    };
};

export const getLevelTreeBranch = (token: string, companyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {companyId};
    return async (dispatch: AppDispatch) => {
        dispatch(getLevelTreeBranchStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetLevelTreeBranch}`,
                {headers, params}
            );
            dispatch(getLevelTreeBranchSuccess(response));
        } catch (error) {
            dispatch(getLevelTreeBranchError(error as AxiosError));
        }
    };
};
