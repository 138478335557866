import {components, MultiValueGenericProps} from 'react-select';
import React from 'react';
import {Text} from '@/components/Shopify/Ecommerce/styles';

export const MultiValueLabel = (props: MultiValueGenericProps) => {
    const {children} = props;
    return (
        <components.MultiValueLabel {...props}>
            <Text Color="#2A2C2F" FontWeight="700" FontSize="16px" FontFamily="Nunito">
                {children}
            </Text>
        </components.MultiValueLabel>
    );
};
