import {
    GET_ARTICLE_SUCCESS,
    GET_BRAND_CONCAT_SUCCESS,
    GET_CAT_GROUP_SUCCESS,
    GET_FAMILY_CONCAT_LEVEL,
    GET_ITEMS,
    GET_ITEMS_ERROR,
    GET_SUBFAMILY_SUCCESS,
    TOTAL_PAGES_ITEM,
} from '@/components/RequestNewCatalogLevel/Types/Types';
import {AxiosError} from 'axios';
import {ICatalogItems} from '@components/AuctionPass/interfaces';

export interface ICatGruop {
    loading: boolean;
    error: boolean;
    catGroup: ICatalogItems[];
}
const initialState: ICatGruop = {
    loading: false,
    catGroup: [
        {
            id: 0,
            groupName: '',
            familyName: '',
            brandName: '',
            subFamilyName: '',
            itemName: '',
            commercializationIncreaseType: '',
            amount: '',
            daysOnHold: '',
            itemsCount: 0,
            children: [],
        },
    ],
    error: false,
};

const updateChildren = (
    items: ICatalogItems[],
    payload: ICatalogItems,
    level: number
): ICatalogItems[] => {
    return items.map((item) => {
        if (item.id === payload.id && level === 0) {
            return {...item, children: payload.children};
        } else if (item.children && level > 0) {
            return {
                ...item,
                children: updateChildren(item.children, payload, level - 1),
            };
        }
        return item;
    });
};

export const getGroupsAuctionReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: ICatalogItems;
        page: number;
    }
) => {
    switch (action.type) {
        case GET_ITEMS:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_CAT_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                catGroup: action.payload,
            };
        case TOTAL_PAGES_ITEM:
            return {
                ...state,
                page: action.page,
            };
        case GET_FAMILY_CONCAT_LEVEL:
            return {
                ...state,
                catGroup: updateChildren(state.catGroup, action.payload, 0),
            };
        case GET_SUBFAMILY_SUCCESS:
            return {
                ...state,
                catGroup: updateChildren(state.catGroup, action.payload, 1),
            };
        case GET_BRAND_CONCAT_SUCCESS:
            return {
                ...state,
                catGroup: updateChildren(state.catGroup, action.payload, 2),
            };
        case GET_ARTICLE_SUCCESS:
            return {
                ...state,
                catGroup: updateChildren(state.catGroup, action.payload, 3),
            };
        case GET_ITEMS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};
