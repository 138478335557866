import React from 'react';

import {
    ContainerFlex,
    ContainerCellGrid,
    Text,
    Image,
    ImgAvatar,
    TextEllipsis,
} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import deleteIcon from '@images/delete - trash.svg';
import useFormatDate from '@/hooks/useFormatDate';
import {UserListItemProps} from '@components/LocationsBranch/interfaces';

const UserListItem: React.FC<UserListItemProps> = ({user, onDelete, onSelect, formattedRoles}) => {
    return (
        <ContainerFlex
            key={user.employId}
            Display="grid"
            GridColumns="18px repeat(3, minmax(80px, 1fr)) 24px"
            Justify="start"
            Align="center"
            Gap="1rem"
            Padding="0.5rem"
            Bb="1px solid #F4F5F5"
        >
            <Checkbox checked={user.selected} onChange={() => onSelect(user.employId)} />
            <ContainerCellGrid Justify="start" Align="center" Gap="0.5rem">
                <ImgAvatar
                    src={user.image}
                    Height="2rem"
                    Width="2rem"
                    Radius="1.875rem"
                    alt="avatar"
                />
                <TextEllipsis
                    Color="#5A5AFF"
                    FontSize="0.875rem"
                    FontWeight="500"
                    Width="fit-content"
                >
                    {user.names || user.userName}
                </TextEllipsis>
            </ContainerCellGrid>
            <ContainerCellGrid Justify="start" Align="center">
                <TextEllipsis
                    Color="#2A2C2F"
                    FontSize="0.875rem"
                    FontWeight="400"
                    Width="fit-content"
                >
                    {formattedRoles}
                </TextEllipsis>
            </ContainerCellGrid>
            <ContainerCellGrid Justify="start" Align="center">
                <Text Color="#2A2C2F" FontSize="0.875rem" FontWeight="400">
                    {useFormatDate(user.lastSession)}
                </Text>
            </ContainerCellGrid>
            <ContainerCellGrid Justify="start" Align="center">
                <Image
                    onClick={() => onDelete(user.employId)}
                    src={deleteIcon}
                    alt="delete"
                    Width="1.5rem"
                    Height="1.5rem"
                    Cursor="pointer"
                />
            </ContainerCellGrid>
        </ContainerFlex>
    );
};

export default UserListItem;
