import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {
    GET_FILTER_LOAN_PRODUCTS_ERROR,
    GET_FILTER_LOAN_PRODUCTS_START,
    GET_FILTER_LOAN_PRODUCTS_SUCCESS,
} from '@components/LoansProducts/Redux/Types/Types';
import {fetchDataLoanStart} from '@/components/PersonalLoans/Redux/Actions/CreatePersonalLoans';
import StringUtils from '@/GenericScripts/utils';
import {GetFilterLoanAction} from '@components/LoansProducts/interfaces';

export const fecthSearchLoansStart = () => {
    return {type: GET_FILTER_LOAN_PRODUCTS_START};
};

export const fecthSearchLoansSuccess = (data: AxiosResponse) => {
    return {
        type: GET_FILTER_LOAN_PRODUCTS_SUCCESS,
        payload: data,
    };
};

export const fecthSearchLoansError = (error: AxiosError) => {
    return {type: GET_FILTER_LOAN_PRODUCTS_ERROR, error: error};
};

export const SearchLoansProductsInput = (
    filters: GetFilterLoanAction,
    token: string,
    pageSize: number,
    pageNumber: number,
    status: number
) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: AppDispatch) => {
        dispatch(fetchDataLoanStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetFilterLoanProducts}${queryString}
            `,
                {headers, params: {pageSize, pageNumber, status}}
            );
            dispatch(fecthSearchLoansSuccess(response));
        } catch (error) {
            dispatch(fecthSearchLoansError(error as AxiosError));
        }
    };
};
