import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ImgLocation} from '@CreateLocation/style';

import {Modal} from '@/components/CreateBranch/BranchInformation/Modals/ReusableModal';
import ButtonGeneral from '@/components/General/Atoms/Button';
import * as style from '@components/CreateUsers/Styles';
import exitDoor from '@images/exit_door.svg';
import {ADD_CLIET_WARNING} from './constants';
import {AddNewClientWarningProps} from '@Shopify/ListClient/interfaces';

const AddNewClientWarning = ({showModal, setShowModal, onProceed}: AddNewClientWarningProps) => {
    return (
        <>
            <Modal
                modalState={showModal}
                changeModalState={() => setShowModal}
                titleModalState={true}
                {...style.modalContect}
                Width="31.255rem"
                HeightDialog="24.063rem"
                Height="24.063rem"
            >
                <ContainerFlex Gap="1.5rem" Padding="1.5rem" FlexDir="column" MaxH="70%">
                    <ContainerFlex Gap="1rem" Align="center" FlexDir="column">
                        <ImgLocation
                            Width="98rem"
                            Height="9.375rem"
                            src={exitDoor}
                            alt="Exit door"
                        />
                        <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                            {ADD_CLIET_WARNING.TITLE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="flex-start" Align="flex-start" Display="block">
                        <Text FontSize="1rem" Color="#54575C" FontWeight="400">
                            {ADD_CLIET_WARNING.DESCRIPTION}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Padding="24px" Height="15%" Gap="20px">
                        <ButtonGeneral
                            width="10.625rem"
                            secondaryButton={true}
                            text={ADD_CLIET_WARNING.RETURN}
                            clic={() => setShowModal(false)}
                        />
                        <ButtonGeneral
                            width="10.625rem"
                            text={ADD_CLIET_WARNING.PROCEED}
                            clic={() => {
                                onProceed();
                                setShowModal(false);
                            }}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
export default AddNewClientWarning;
