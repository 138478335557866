import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import {MODAL_REJECT_LOAN} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {
    closeRejectModalAction,
    RejectPersonalCreditAction,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/GetPersonalCreditAmountActions';
import {routers} from '@/appRoute';
import {useNavigate} from 'react-router-dom';

export const RejectLoanModal = ({creditId}: {creditId: number}) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });

    const handleContinue = () => {
        dispatch(RejectPersonalCreditAction(creditId, token));
        navigate(routers.MyAccount);
    };

    const handleCancel = () => {
        dispatch(closeRejectModalAction());
    };

    return (
        <>
            <Modal Top="0" BackC="rgba(0, 0, 0, 0.40)">
                <ContainerFlex
                    Radius="1.5rem"
                    backG="#FFF"
                    BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Padding="1.5rem"
                    FlexDir="column"
                    Width="600px"
                    Height="max-content"
                    Align="start"
                >
                    <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="700" MarginB="12px">
                        {MODAL_REJECT_LOAN.TITLE}
                    </Text>
                    <Text Color="#000" Padding="12px 0px 24px 0px" wSpace="wrap">
                        {MODAL_REJECT_LOAN.MESSAGE}
                    </Text>
                    <ContainerFlex Gap="16px" PaddingT="12px">
                        <ButtonGenerals
                            SecondaryButton="#FFFFFF"
                            Width="100%"
                            Height="40px"
                            FontWeight="700"
                            onClick={() => handleCancel()}
                        >
                            {MODAL_REJECT_LOAN.CTA_CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals
                            Width="100%"
                            Height="40px"
                            BackGC="#A82424"
                            onClick={() => handleContinue()}
                        >
                            {MODAL_REJECT_LOAN.CTA_REJECT}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
