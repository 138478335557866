import React from 'react';
import {useDispatch} from 'react-redux';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {clearFiltersValues} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';

const FilterModalHeader = () => {
    const dispatch = useDispatch();
    return (
        <ContainerFlex Height={'29px'} Justify="space-between">
            <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="700" FontFamily="Nunito">
                Filtros
            </Text>
            <ButtonGenerals
                BackGC="transparent"
                HBackG="transparent"
                onClick={() => dispatch(clearFiltersValues())}
                Cursor="pointer"
            >
                <Text
                    LHeight="24px"
                    LetterSpacing="0.3px"
                    Color="#5A5AFF"
                    FontFamily="Nunito"
                    FontWeight="700"
                >
                    Limpiar filtros
                </Text>
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default FilterModalHeader;
