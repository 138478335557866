import {
    PUT_PICTURE,
    PUT_PICTURE_SOUCCES,
    PUT_PICTURE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const PutNewPictureUser = (state = initialState, action) => {
    switch (action.type) {
        case PUT_PICTURE:
            return {...state, loading: true};
        case PUT_PICTURE_SOUCCES:
            return {...state, loading: false, userData: action.payload.data};
        case PUT_PICTURE_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
