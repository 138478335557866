import React from 'react';
import {Controller} from 'react-hook-form';

import {useAddressForm} from '@/hooks/useAddressForm';
import {ISelectReact} from '@/components/Quoter/inferfaces';
import {IAddressForm} from '@components/LocationsBranch/interfaces';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {ADDRESS_MODAL, errorExist} from '@components/LocationsBranch/constants';

export const AddressForm = ({control, errors, register, watch, setValue, reset}: IAddressForm) => {
    const {catAddress, catColonies, selectConvert, getCatMunicipality, getColony} = useAddressForm({
        watch,
        reset,
        setValue,
    });
    const postalCode = watch('postalCode') || '-';

    return (
        <ContainerFlex FlexDir="column" OverFlowY="auto" Justify="start" Gap="1rem">
            <ContainerFlex
                Height="5.4rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Gap="0.25rem"
            >
                <Text Color="#1D1E20" FontWeight="700">
                    {ADDRESS_MODAL.STREET}
                </Text>
                <Input {...register('street')} error={errors?.street?.message} />
                {errors?.street?.message && (
                    <Text Color="#FF6357" FontSize="0.75rem">
                        {errors.street.message}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="5.4rem" Gap="1rem" Align="start">
                <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="0.25rem">
                    <Text Color="#1D1E20" FontWeight="700">
                        {ADDRESS_MODAL.OUTERNUM}
                    </Text>
                    <Input {...register('outerNum')} error={errors?.outerNum?.message} />
                    {errors?.outerNum?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors.outerNum.message}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="start" Justify="start" Gap="0.25rem">
                    <Text Color="#1D1E20" FontWeight="700">
                        {ADDRESS_MODAL.INETERNALNUM}
                        <Text Color="#54575C" FontWeight="400" MarginLeft="0.5rem">
                            {ADDRESS_MODAL.OPTIONAL}
                        </Text>
                    </Text>
                    <Input {...register('interNum')} error={errors?.interNum?.message} />
                    {errors?.interNum?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors.interNum.message}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            {catAddress.loading ? (
                <ContainerFlex Height="3rem">
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <>
                    <ContainerFlex
                        Height="5.4rem"
                        Gap="0.25rem"
                        Justify="start"
                        Align="start"
                        FlexDir="Column"
                    >
                        <Text Color="#1D1E20" FontWeight="700">
                            {ADDRESS_MODAL.STATE}
                        </Text>
                        <Controller
                            name="state"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={selectConvert(catAddress.catStatesByCountry)}
                                    hasError={errorExist(errors?.state?.message)}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        getCatMunicipality(e as ISelectReact);
                                    }}
                                />
                            )}
                        />
                        {errors?.state?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors.state.message}
                            </Text>
                        )}
                    </ContainerFlex>
                    <ContainerFlex
                        Height="5.4rem"
                        Gap="0.25rem"
                        Justify="start"
                        Align="start"
                        FlexDir="Column"
                    >
                        <Text Color="#1D1E20" FontWeight="700">
                            {ADDRESS_MODAL.MUNICIPALY}
                        </Text>
                        <Controller
                            name="municipaly"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={selectConvert(catAddress.catMunicipalityByState)}
                                    hasError={errorExist(errors?.municipaly?.message)}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        getColony(e as ISelectReact);
                                    }}
                                />
                            )}
                        />
                        {errors?.municipaly?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors.municipaly.message}
                            </Text>
                        )}
                    </ContainerFlex>

                    <ContainerFlex
                        Height="5.4rem"
                        Gap="0.25rem"
                        Justify="start"
                        Align="start"
                        FlexDir="Column"
                    >
                        <Text Color="#1D1E20" FontWeight="700">
                            {ADDRESS_MODAL.COLONY}
                        </Text>
                        <Controller
                            name="colony"
                            control={control}
                            render={({field}) => (
                                <SelectGeneral
                                    {...field}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={selectConvert(catColonies)}
                                    hasError={errorExist(errors?.colony?.message)}
                                />
                            )}
                        />
                        {errors?.colony?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors.colony.message}
                            </Text>
                        )}
                    </ContainerFlex>
                    <ContainerFlex Gap="0.25rem" Justify="start" Align="start" FlexDir="Column">
                        <Text Color="#1D1E20" FontWeight="700">
                            {ADDRESS_MODAL.POSTAL_CODE}
                        </Text>
                        <Text
                            Color="#2A2C2F"
                            FontWeight="400"
                            MarginLeft="1rem"
                            MarginTop="0.75rem"
                        >
                            {postalCode}
                        </Text>
                    </ContainerFlex>
                </>
            )}
        </ContainerFlex>
    );
};
