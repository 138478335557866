import axios from 'axios';
import {
    STATUS_ACTIVE_USER_REQUEST,
    STATUS_ACTIVE_USER_SOUCCES,
    STATUS_ACTIVE_USER_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

import process from 'process';

import {URL} from '@config/constants/index';

export const OnStatusUserResquest = () => {
    return {
        type: STATUS_ACTIVE_USER_REQUEST,
    };
};
export const OnStatusUserSuccess = (userData) => {
    return {
        type: STATUS_ACTIVE_USER_SOUCCES,
        payload: userData,
    };
};

export const OnStatusUserFailure = (err) => {
    return {
        type: STATUS_ACTIVE_USER_ERROR,
        payload: err,
    };
};
export const OnStatusActive = (token, employeeId) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(OnStatusUserResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ActivateEmployeeById.replace(
                    '{employeeId}',
                    employeeId
                )}`,
                {
                    headers,
                }
            );
            dispatch(OnStatusUserSuccess(response.data));
        } catch (error) {
            dispatch(OnStatusUserFailure(error));
        }
    };
};
