import React, {useState, useEffect} from 'react';
import {
    ContainerFlex,
    Text,
    Modal,
    ContainerCellGrid,
    Image,
    Line,
} from '@/components/Shopify/Ecommerce/styles';
import closed from '@/images/CloseModal.svg';
import elipseIcon from '@images/Ellipse 975.svg';
import elipseIconRole from '@images/Ellipse 976.svg';
import {MODAL_TEXT} from '@/components/Users/UserProfile/RoleSettings/constants';
import {
    isModulesUpdater,
    ModalRolesProps,
    ModuleGroup,
    ModulesState,
    ModulesUpdater,
} from '@/components/Users/UserProfile/RoleSettings/interfaces';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';
import {IListData, IRoleModules} from '@/components/RolesPermissions/NewRole/interfaces';
import TableHeader from '@components/RolesPermissions/NewRole/TableHeader';
import {TableItemsContainer} from '@components/CashFlow/FundingInquiry/styles';

import {
    calculateCheckedModules,
    calculateTotalModules,
} from '@/components/RolesPermissions/NewRole/ModuleFunctions';
import {
    StylesContainerTitle,
    StylesModal,
    StylesModalContainer,
    StylesModalContainerTitle,
} from '@/components/Users/UserProfile/Operational/styles';
import ModuleTree from '@/components/Users/UserProfile/RoleSettings/ModuleTreeUsers';
const markModulesWithPermissions = (
    modules: IRoleModules[],
    permittedModuleIds: number[]
): IRoleModules[] => {
    return modules.map((module) => {
        const isPermitted = permittedModuleIds.includes(Number(module.moduleId));
        const updatedNextLevel = module.nextLevel?.length
            ? markModulesWithPermissions(module.nextLevel, permittedModuleIds)
            : [];
        return {
            ...module,
            isChecked: isPermitted,
            nextLevel: updatedNextLevel,
            isOpen: module.isOpen || false,
        };
    });
};
const initializeModulesState = (
    modules: ModuleGroup[],
    permittedModuleIds: number[]
): ModulesState => {
    const updatedModulesState: ModulesState = {};
    modules.forEach((moduleGroup) => {
        const markedModules = markModulesWithPermissions(
            JSON.parse(JSON.stringify(moduleGroup.modules)),
            permittedModuleIds
        );
        updatedModulesState[moduleGroup.permissionName] = markedModules;
    });
    return updatedModulesState;
};
const getContainerStyle = (permissionName: string) => {
    return permissionName === MODAL_TEXT.ADMIN ? StylesModalContainerTitle : StylesContainerTitle;
};
export const ModalRoles: React.FC<ModalRolesProps> = ({isOpen, closeModal, selectedRole}) => {
    const [modulesState, setModulesState] = useState<ModulesState>({});
    const modules = useSelector((state: RootState) => state.GetAllModules.modules) as ModuleGroup[];
    const rolesId = useSelector((state: RootState) => state.getRoleAllUser.userData);

    const [listData, setListData] = useState<IListData>({
        adminModules: [],
        operationalModules: [],
        users: [],
    });
    useEffect(() => {
        if (modules?.length > 0 && rolesId?.modules?.length > 0) {
            const initialState = initializeModulesState(modules, rolesId.modules);
            setModulesState(initialState);
            setListData((prevData) => ({
                ...prevData,
                operationalModules: rolesId.modules,
            }));
        }
    }, [modules, rolesId, selectedRole]);
    const handleModulesUpdate = (
        permissionName: string,
        updatedModulesOrUpdater: IRoleModules[] | ModulesUpdater
    ): void => {
        setModulesState((prevState) => {
            const currentModules = isModulesUpdater(updatedModulesOrUpdater)
                ? updatedModulesOrUpdater(prevState[permissionName] || [])
                : updatedModulesOrUpdater;
            return {
                ...prevState,
                [permissionName]: currentModules,
            };
        });
    };
    if (!selectedRole || !isOpen) return null;
    return (
        <Modal>
            <ContainerFlex Align="end">
                <ContainerFlex {...StylesModal}>
                    <ContainerFlex Justify="space-between">
                        <Text Color="#1D1E20" FontWeight="700" FontSize="1.75rem">
                            {MODAL_TEXT.TITLE}
                        </Text>
                        <Image
                            onClick={closeModal}
                            Cursor="pointer"
                            src={closed}
                            alt="close-icon"
                        />
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Align="center" Gap="8px">
                        <Text FontWeight="700">{MODAL_TEXT.ROL}</Text>
                        <Text FontWeight="400">{selectedRole.roleName}</Text>
                    </ContainerFlex>
                    <Line />
                    <ContainerCellGrid Gap="16px">
                        {modules.map((moduleGroup) => {
                            const totalModules = calculateTotalModules(moduleGroup.modules);
                            const checkedModules = calculateCheckedModules(
                                modulesState[moduleGroup.permissionName] || []
                            );
                            const containerStyle = getContainerStyle(moduleGroup.permissionName);
                            return (
                                <ContainerFlex
                                    key={moduleGroup.permissionName}
                                    {...StylesModalContainer}
                                >
                                    <ContainerFlex {...containerStyle}>
                                        <ContainerFlex Justify="start" Gap="4px" Bt="">
                                            <Image
                                                src={
                                                    moduleGroup.permissionName === MODAL_TEXT.ADMIN
                                                        ? elipseIcon
                                                        : elipseIconRole
                                                }
                                                alt="icon"
                                            />
                                            <Text Color="#1D1E20" FontWeight="700">
                                                {moduleGroup.permissionName}
                                            </Text>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                    <ContainerFlex FlexDir="column" Justify="start" MinH="320px">
                                        <TableHeader
                                            totalCheckedModules={checkedModules}
                                            totalModules={totalModules}
                                        />
                                        <TableItemsContainer
                                            Background="none"
                                            Width="100%"
                                            Overflow="auto"
                                        >
                                            <ModuleTree
                                                modules={
                                                    modulesState[moduleGroup.permissionName] || []
                                                }
                                                level={0}
                                                isExpand={false}
                                                totalModules={totalModules}
                                                setModules={(updatedModulesOrUpdater) => {
                                                    handleModulesUpdate(
                                                        moduleGroup.permissionName,
                                                        updatedModulesOrUpdater
                                                    );
                                                }}
                                                isAdmin={false}
                                                listData={listData}
                                                setListData={setListData}
                                            />
                                        </TableItemsContainer>
                                    </ContainerFlex>
                                </ContainerFlex>
                            );
                        })}
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
export default ModalRoles;
