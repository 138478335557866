import {Controller, useFormContext} from 'react-hook-form';
import React, {useRef} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IPorcentAnualRateFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {StyleNumericFormat} from '@/components/Loan/styles';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';

export const PorcentAnualRateField: React.FC<IPorcentAnualRateFieldProps> = ({name}) => {
    const {
        control,
        formState: {errors},
    } = useFormContext();
    const errorMessage = errors[name]?.message?.toString() || '';
    const porcentAnualRateRef = useRef<HTMLInputElement>(null);
    return (
        <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.INTEREST}
            </Text>
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, value, ...field}}) => (
                    <StyleNumericFormat
                        {...field}
                        placeholder="%"
                        autoComplete="off"
                        type="text"
                        suffix=" %"
                        decimalSeparator="."
                        thousandSeparator={false}
                        decimalScale={2}
                        allowNegative={false}
                        value={value}
                        height="38px"
                        width="90%"
                        textAlign="start"
                        fontSize="1rem"
                        FontFamily="Nunito"
                        FontWeight="400"
                        Color={errors[name] === undefined ? '#2A2C2F' : '#ef4f55'}
                        radius="2rem"
                        getInputRef={porcentAnualRateRef}
                        onValueChange={(values) => {
                            onChange(values.floatValue);
                        }}
                    />
                )}
            />
            {errorMessage && <ErrorMessage message={errorMessage} />}
        </ContainerFlex>
    );
};
