import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {routers} from '@/appRoute';
import {ALTS, MODALCART} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Text, Modal, Image} from '@/components/Shopify/Ecommerce/styles';
import {useNavigate} from 'react-router-dom';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {
    closeModalAction,
    deleteCreditToShoppingCarAction,
    getDetailsShoppingCarAction,
    removeCreditSelectedAction,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';
import {IDetailLoansCarCredit} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import Delete from '@images/delete.svg';

export const ModalCartShopping = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {token, carShoopingId} = useSelector((state: RootState) => {
        const userData = state.getUsersValidation.userData;
        return {
            token: String(userData?.token),
            carShoopingId: Number(userData?.carShoopingId),
        };
    });
    const {detailsLoansCarCredits, loansCarCreditsSelected, mustBeUpdate} = useSelector(
        (state: RootState) => state.shoppingCarClient
    );
    useEffect(() => {
        if (token && carShoopingId && mustBeUpdate) {
            dispatch(getDetailsShoppingCarAction(token, carShoopingId.toString()));
        }
    }, [token, carShoopingId, mustBeUpdate]);

    const handleRemoveCredit = (creditSelected: IDetailLoansCarCredit) => {
        const {paymentCreditId} = creditSelected;
        if (loansCarCreditsSelected.some((credit) => credit.paymentCreditId === paymentCreditId))
            dispatch(removeCreditSelectedAction(creditSelected));

        const creditToDelete = {
            token: String(token),
            paymentCreditId,
            carClienteId: Number(carShoopingId),
        };
        dispatch(deleteCreditToShoppingCarAction(creditToDelete));
    };

    const handleShowShoppingCar = () => navigate(routers.CreditsCartShopping);
    const handleCloseModal = () => dispatch(closeModalAction());

    return (
        <Modal Justify="end" Align="start">
            <ContainerFlex
                FlexDir="column"
                Justify="space-between"
                Align="start"
                Width="310PX"
                MaxH="300PX"
                Border="1px solid #E8E9EA"
                backG="#FFFFFF"
                Radius="1rem"
            >
                <ContainerFlex
                    Justify="space-between"
                    Align="start"
                    Padding="0.5rem 1rem"
                    Bb="1px solid #E8E9EA"
                    Height="40px"
                    Flex="0"
                >
                    <Text oFlow="normal" FontWeight="700" Color="#1D1E20">
                        {MODALCART.CART}
                    </Text>
                    <Text
                        oFlow="normal"
                        onClick={handleCloseModal}
                        Cursor="pointer"
                        FontWeight="700"
                        Color="#1D1E20"
                    >
                        {MODALCART.CLOSE_MODAL}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    FlexDir="column"
                    OverFlowY="auto"
                    Justify="start"
                    Align="start"
                >
                    {detailsLoansCarCredits?.map((credit) => (
                        <ContainerFlex
                            key={credit.paymentCreditId}
                            Justify="space-around"
                            Height="80px"
                            Padding="1rem"
                            Bb="1px solid #E8E9EA"
                        >
                            <ContainerFlex FlexDir="column" Align="start">
                                <Text
                                    oFlow="normal"
                                    wSpace="normal"
                                    Color="#1D1E20"
                                    FontWeight="700"
                                >
                                    {MODALCART.PAY}{' '}
                                </Text>
                                <Text
                                    oFlow="normal"
                                    wSpace="normal"
                                    Color="#2A2C2F"
                                    FontSize="0.875rem"
                                >
                                    {`${MODALCART.LOAN} #${credit.solicitudeNumber}`}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Justify="end" Gap="1rem">
                                <Text
                                    oFlow="normal"
                                    wSpace="normal"
                                    Color="#1D1E20"
                                    FontWeight="700"
                                >
                                    {numberToCurrencyWithoutCents(credit.amountQuote)}
                                </Text>
                                <Image
                                    src={Delete}
                                    width="24px"
                                    Height="24px"
                                    Cursor="pointer"
                                    onClick={() => handleRemoveCredit(credit)}
                                    alt={ALTS.DELETE_ICON}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
                <ContainerFlex
                    Justify="end"
                    Align="end"
                    Padding="1rem 1.5rem"
                    Height="74px"
                    Flex="0"
                    Bt="1px solid #E8E9EA"
                >
                    <ButtonGenerals
                        Width="16.125rem"
                        SecondaryButton="#FFFFFF"
                        FontWeight="700"
                        FontSize="0.875rem"
                        onClick={handleShowShoppingCar}
                        Cursor="pointer"
                    >
                        {MODALCART.SHOW_CAR}
                    </ButtonGenerals>
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
