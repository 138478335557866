export const GET_CONTACT_COMPANY_START = 'GET_CONTACT_COMPANY_START';
export const GET_CONTACT_COMPANY_SUCCESS = 'GET_CONTACT_COMPANY_SUCCESS';
export const GET_CONTACT_COMPANY_ERROR = 'GET_CONTACT_COMPANY_ERROR';

export const GET_DATA_SELECT_REASON_START = 'GET_DATA_SELECT_REASON_START';
export const GET_DATA_SELECT_REASON_SUCCESS = 'GET_DATA_SELECT_REASON_SUCCESS';
export const GET_DATA_SELECT_REASON_ERROR = 'GET_DATA_SELECT_REASON_ERROR';

export const POST_DATA_REASON_START = 'POST_DATA_REASON_START';
export const POST_DATA_REASON_SUCCESS = 'POST_DATA_REASON_SUCCESS';
export const POST_DATA_REASON_ERROR = 'POST_DATA_REASON_ERROR';

export const DELETE_REASON_START = 'DELETE_REASON_START';
export const DELETE_REASON_SUCCESS = 'DELETE_REASON_SUCCESS';
export const DELETE_REASON_ERROR = 'DELETE_REASON_ERROR';

export const GET_DATA_CONTACT_PONZU_START = 'GET_DATA_CONTACT_PONZU_START';
export const GET_DATA_CONTACT_PONZU_SUCCESS = 'GET_DATA_CONTACT_PONZU_SUCCESS';
export const GET_DATA_CONTACT_PONZU_ERROR = 'GET_DATA_CONTACT_PONZU_ERROR';

export const GET_DATA_CONTACT_COMPANY_START = 'GET_DATA_CONTACT_COMPANY_START';
export const GET_DATA_CONTACT_COMPANY_SUCCESS = 'GET_DATA_CONTACT_COMPANY_SUCCESS';
export const GET_DATA_CONTACT_COMPANY_ERROR = 'GET_DATA_CONTACT_COMPANY_ERROR';

export const GET_ALL_DATA_CONTACT_COMPANY_START = 'GET_ALL_DATA_CONTACT_COMPANY_START';
export const GET_ALL_DATA_CONTACT_COMPANY_SUCCESS = 'GET_ALL_DATA_CONTACT_COMPANY_SUCCESS';
export const GET_ALL_DATA_CONTACT_COMPANY_ERROR = 'GET_ALL_DATA_CONTACT_COMPANY_ERROR';

export const PUT_DATA_CONTACT_START = 'PUT_DATA_CONTACT_START';
export const PUT_DATA_CONTACT_SUCCESS = 'PUT_DATA_CONTACT_SUCCES';
export const PUT_DATA_CONTACT_ERROR = 'PUT_DATA_CONTACT_ERROR';

export const POST_DATA_CONTACT_ADD_START = 'POST_DATA_CONTACT_ADD_START';
export const POST_DATA_CONTACT_ADD_SUCCESS = 'POST_DATA_CONTACT_ADD_SUCCES';
export const POST_DATA_CONTACT_ADD_ERROR = 'POST_DATA_CONTACT_ADD_ERROR';

export const POST_EMAIL_CONTACT_START = 'POST_EMAIL_CONTACT_START';
export const POST_EMAIL_CONTACT_SUCCESS = 'POST_EMAIL_CONTACT_SUCCESS';
export const POST_EMAIL_CONTACT_ERROR = 'POST_EMAIL_CONTACT_ERROR';

export const UPDATE_COMPANY_DATA = {
    START_DATA: 'START_DATA',
    SUCCESS_DATA: 'SUCCESS_DATA',
    ERROR_DATA: 'ERROR_DATA',
    COMPANY_NOT_UPDATED: 'COMPANY_NOT_UPDATED',
};

export const GET_COMPANY_DATA = {
    START_DATA_EXIST: 'START_DATA_EXIST',
    SUCCESS_DATA_EXIST: 'SUCCESS_DATA_EXIST',
    ERROR_DATA_EXIST: 'ERROR_DATA_EXIST',
};

export const COMPANY_DETAILS = {
    START_COMPANY: 'START_COMPANY',
    SUCCESS_COMPANY: 'SUCCESS_COMPANY',
    ERROR_COMPANY: 'ERROR_COMPANY',
};

export const COMPANY_TAX = {
    START_COMPANY_TAX: 'START_COMPANY_TAX',
    SUCCESS_COMPANY_TAX: 'SUCCESS_COMPANY_TAX',
    ERROR_COMPANY_TAX: 'ERROR_COMPANY_TAX',
};

export const COMPANY_PRIVACY = {
    START_COMPANY_PRIVACY: 'START_COMPANY_PRIVACY',
    SUCCESS_COMPANY_PRIVACY: 'SUCCESS_COMPANY_PRIVACY',
    ERROR_COMPANY_PRIVACY: 'ERROR_COMPANY_PRIVACY',
};

export const UPDATE_IMG_COMPANY = {
    START_COMPANY_IMG: 'START_COMPANY_IMG',
    SUCCESS_COMPANY_IMG: 'SUCCESS_COMPANY_IMG',
    ERROR_COMPANY_IMG: 'ERROR_COMPANY_IMG',
};

export const DOWNLOAD_INFO_COMPANY = {
    START_COMPANY_INFO: 'START_COMPANY_INFO',
    SUCCESS_COMPANY_INFO: 'SUCCESS_COMPANY_INFO',
    ERROR_COMPANY_INFO: 'ERROR_COMPANY_INFO',
};
