import styled from '@emotion/styled';
import React, {useState} from 'react';

const PercentageInput = () => {
    const [value, setValue] = useState('');

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (/^[0-9]*\.?[0-9]*$/.test(inputValue)) {
            setValue(inputValue);
        }
    };

    const handleBlur = () => {
        if (value && !value.endsWith('%')) {
            setValue(`${value}%`);
        }
    };

    const handleFocus = () => {
        if (value.endsWith('%')) {
            setValue(value.slice(0, -1));
        }
    };

    return (
        <StyledInput
            type="text"
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            placeholder="0.0%"
        />
    );
};

const StyledInput = styled.input`
    padding-left: 16px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 2rem;
    width: 170px;
    text-align: start;

    &:focus {
        border-color: #4a90e2;
        outline: none;
    }
`;

export default PercentageInput;
