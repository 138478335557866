import {getAuctionGroupAction} from '@/components/AuctionPass/Redux/Actions/GetGroupsAction';
import {ICatAuctionGruop} from '@/components/AuctionPass/interfaces';
import {SearchInput} from '@/components/Customer/Styles';
import {TableItem} from '@/components/DeadLines/styles';
import {
    CATALOG,
    INITIAL_SEARCH_ITEM,
} from '@/components/InventoryManagement/CatalogManagement/Operational/Constant';
import {ImageLogo} from '@/components/LoginClient/styles';
import {
    getBreadCrumbs,
    idSelected,
} from '@/components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {getSearchBarAction} from '@/components/RequestNewCatalogLevel/Redux/Actions/GetSearchBarAction';
import {ResetSelectedNodeLevel} from '@/components/RequestNewCatalogLevel/Redux/Actions/GetSelectGroup';
import {TreeComponent} from '@/components/RequestNewCatalogLevel/TableTreeRequestCatalog';
import {LEVEL, NO_ARTICLES} from '@/components/RequestNewCatalogLevel/operational/constant';
import {ContainerFlex, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import close from '@/images/close.svg';
import point from '@/images/iconSearch.svg';
import {SearchArticles} from '@components/InventoryManagement/CatalogManagement/SearchDialog';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

export function CatalogManagement() {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const [search, setSearch] = useState('');
    const [show, setShow] = useState(false);
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );
    const TreeGroup: ICatAuctionGruop = useSelector(
        (state: RootState) => state.getGroupsAuctionReducer
    );

    const handleSearch = () => {
        if (token && companyId) {
            dispatch(getAuctionGroupAction(companyId, token));
            setShow(true);
        }
    };

    const resetAll = () => {
        setSearch('');
        setShow(false);
        dispatch(getBreadCrumbs({}));
    };

    useEffect(() => {
        if (companyId && token) {
            dispatch(getAuctionGroupAction(companyId, token));
        }
    }, [companyId, search]);
    useEffect(() => {
        if (companyId && token) {
            dispatch(getAuctionGroupAction(companyId, token));
            dispatch(ResetSelectedNodeLevel());
            dispatch(idSelected(0));
        }
    }, []);
    useEffect(() => {
        if (search.length > INITIAL_SEARCH_ITEM && token) {
            dispatch(getSearchBarAction(search, token));
        }
    }, [search]);
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Padding="10px" Gap="16px">
            <ContainerFlex Height="max-content" FlexDir="column" Align="start">
                <Text FontSize="1.5rem">{CATALOG.TITLE}</Text>
            </ContainerFlex>
            <SearchInput
                Height="40px"
                GridColumn="95% 5%"
                Padding="8px 16px"
                Right=""
                Cursor="pointer"
            >
                <Input
                    type="textfield"
                    placeholder={CATALOG.SEARCH}
                    value={search}
                    autoFocus
                    onChange={(e) => setSearch(e.target.value)}
                    onClick={handleSearch}
                />
                {search.length > INITIAL_SEARCH_ITEM ? (
                    <ImageLogo
                        Cursor="pointer"
                        Width="24px"
                        Height=""
                        src={close}
                        alt="close"
                        onClick={resetAll}
                    />
                ) : (
                    <ImageLogo Cursor="pointer" Width="24px" Height="" src={point} alt="point" />
                )}
                {show && search.length > INITIAL_SEARCH_ITEM && (
                    <SearchArticles searchTerm={search} setShow={setShow} />
                )}
            </SearchInput>
            <ContainerFlex
                Justify="start"
                FlexDir="column"
                Border="1px solid #E8E9EA"
                Radius="24px"
                Height=""
            >
                <ContainerFlex
                    Border=""
                    Display="grid"
                    GridColumns="3fr 1fr 1fr"
                    Padding="8px"
                    Bb="1px solid #E8E9EA"
                >
                    <TableItem Padding="0 0 0 40px" Justify="start">
                        <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                            {LEVEL}
                        </Text>
                    </TableItem>
                    <TableItem>
                        <Text
                            Padding="0 0 0 16px"
                            FontSize="0.875rem"
                            Color="#2A2C2F"
                            FontWeight="700"
                        >
                            {NO_ARTICLES}
                        </Text>
                    </TableItem>
                </ContainerFlex>
                <ContainerFlex />
                <ContainerFlex Justify="start" Align="start" FlexDir="column">
                    {
                        <TreeComponent
                            data={TreeGroup.catGroup}
                            level={0}
                            isView={false}
                            fatherId={0}
                        />
                    }
                    <ContainerFlex Height="1px" />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
}
