import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {addWarehouses} from '@/components/LocationsBranch/Redux/Action/Warehouse';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material';
import ButtonGeneral from '@/components/General/Atoms/Button';
import useInitInheritGroups from '@/hooks/useInitInheritGroups';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ACCORDION_STYLES, INITIAL_WAREHOUSE, STORAGE} from '@components/LocationsBranch/constants';
import {InheritFrom} from '@/components/LocationsBranch/InheritFrom';
import {InventoryStorage} from '@/components/LocationsBranch/InventoryStorage';
import arrowDownIcon from '@images/arrowDownIcon.svg';
import {IStorage, IWarehouseProps} from '@components/LocationsBranch/interfaces';
import {getNextWarehouseId} from '@components/LocationsBranch/utils/GetNextId';
import AddIcon from '@images/addIcon.svg';
import * as style from '@components/LocationsBranch/stylesLocations';

export const Warehouse = ({isOptional, onRefresh, onClickTransfer, errors}: IWarehouseProps) => {
    const dispatch = useDispatch();
    const warehouses = useSelector((state: RootState) => state.Warehouse.warehouses);
    const [selectedStorageType, setSelectedStorage] = useState<IStorage | null>(null);

    const onNewWarehouse = () => {
        const newId = getNextWarehouseId(warehouses);
        const newName = `${INITIAL_WAREHOUSE.name} ${newId}`;

        const newWarehouse = JSON.parse(JSON.stringify(warehouses));
        const newStorageType = JSON.parse(
            JSON.stringify({...INITIAL_WAREHOUSE, id: newId, name: newName})
        );

        newWarehouse.push(newStorageType);
        dispatch(addWarehouses(newWarehouse));
    };

    const inheritType = 'location';

    const selectedLevel = useSelector((state: RootState) => state.levelGroups.groupTypes).find(
        (groups) => groups.groupType === inheritType
    )?.selectedLevel;

    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    useInitInheritGroups(inheritType);

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Border="solid 0.063rem #F4F5F5"
            Radius="1rem"
            Gap="1rem"
            Height="fit-content"
        >
            <Accordion sx={ACCORDION_STYLES} defaultExpanded>
                <AccordionSummary
                    expandIcon={
                        <Image src={arrowDownIcon} alt="add" Width="1.5rem" Height="1.5rem" />
                    }
                >
                    <ContainerFlex Justify="space-between" Align="center" Self="stretch">
                        <Text {...style.contentGralTitle}>
                            {STORAGE.TITLE}
                            {isOptional && (
                                <Text {...style.contentGralTitleOp} MarginLeft="0.5rem">
                                    {STORAGE.OPTIONAL}
                                </Text>
                            )}
                        </Text>
                        <div onClick={stopPropagation}>
                            <InheritFrom
                                inheriterName={selectedLevel?.levelName as string}
                                type="location"
                                onClickTransfer={onClickTransfer}
                            />
                        </div>
                    </ContainerFlex>
                </AccordionSummary>
                <AccordionDetails>
                    {errors && errors.storages && (
                        <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                            {String(errors.storages?.message)}
                        </Text>
                    )}
                    {warehouses.map((warehouse, index) => (
                        <ContainerFlex Padding="0.5rem" FlexDir="Column" key={index}>
                            <InventoryStorage
                                warehouse={warehouse}
                                onNewWarehouse={onNewWarehouse}
                                setSelectedStorage={setSelectedStorage}
                                selectedStorageType={selectedStorageType}
                            />
                        </ContainerFlex>
                    ))}
                    {!selectedStorageType && (
                        <ButtonGeneral
                            color="#5A5AFF"
                            backGround="#FFFFFF"
                            hText="#5A5AFF"
                            hShadow=""
                            height="2.5rem"
                            cursor="pointer"
                            border="1px solid #5A5AFF"
                            hBackG="#E5E5FF"
                            clic={(e: React.MouseEvent) => {
                                e.preventDefault();
                                onNewWarehouse();
                            }}
                            text={
                                <>
                                    <Image src={AddIcon} alt="add" color="#5A5AFF" />
                                    <Text
                                        Color="#5A5AFF"
                                        FontWeight="700"
                                        Cursor="pointer"
                                        FontSize="1rem"
                                    >
                                        {STORAGE.NEW_WAREHOUSE}
                                    </Text>
                                </>
                            }
                        />
                    )}
                </AccordionDetails>
            </Accordion>
        </ContainerFlex>
    );
};
