import {useSelector} from 'react-redux';
import React from 'react';
import {RootState} from '@/config/store';
import {
    CHARGES_AND_PENALTIES,
    tableHeaders,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IChargesAndPenalitesPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/chargesAndPenaltiesInterfaces';
import {HeaderPreview} from '@components/PersonalLoans/NewLoanProduct/ui/HeaderPreview';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RowCharge} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/Preview/RowCharge';

export const ChargesAndPenaltiesPreview: React.FC<IChargesAndPenalitesPreviewProps> = ({
    chargesRef,
    isEditing,
    setIsEditing,
}) => {
    const {details} = useSelector((state: RootState) => state.newLoanSubmittedData);
    const selectedChargesAndPenalties = details.chargesPenalties.filter((item) => item.isChecked);
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1.5rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={chargesRef}
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="0.5rem">
                    <HeaderPreview
                        title={CHARGES_AND_PENALTIES.TITLE}
                        isEditing={isEditing}
                        setIsEditing={setIsEditing}
                    />
                    <Text Color="#2A2C2F" oFlow="visible">
                        {CHARGES_AND_PENALTIES.DESCRIPTION}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Radius="1rem"
                    Border="1px solid #E8E9EA"
                    backG="#FFF"
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    OverFlow="none"
                >
                    <ContainerFlex
                        Gap="1rem"
                        Justify="start"
                        Align="start"
                        Bb="1px solid #E8E9EA"
                        Padding="0.5rem 1rem"
                    >
                        {tableHeaders.map((item, index) => (
                            <Text
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                FontWeight="700"
                                Width="100%"
                                Padding="0.25rem 0px"
                                key={index}
                            >
                                {item.title}
                            </Text>
                        ))}
                    </ContainerFlex>
                    {selectedChargesAndPenalties.length > 0 ? (
                        selectedChargesAndPenalties.map((item, index) => (
                            <RowCharge
                                item={item}
                                index={index}
                                lastIndex={selectedChargesAndPenalties.length - 1}
                                key={index}
                            />
                        ))
                    ) : (
                        <Text FontSize="0.875rem" Color="#2A2C2F" Padding="4px 16px">
                            {CHARGES_AND_PENALTIES.NO_CHARGES_SELECTED}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
