import {ContainerCellGrid, ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {TEXT_NEW_USER} from '@/components/NewUserEmployee/Operational/constants';
import {userDataDataProps} from '@/components/NewUserEmployee/Operational/interface';
import PhoneInput from '@/components/NewUserEmployee/inputPhone';
import question from '@/images/CuestionIconeUser.svg';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import {DateTime} from 'luxon';
import {GeneralsInput} from '@/components/NewUserEmployee/GeneralsInput';
import action from '@/images/AcctionErrorIcone.svg';
import closed from '@/images/closeRed.svg';
import {hastStyles, personalStyles} from '@/components/NewUserEmployee/Operational/styles';
import React, {useState} from 'react';
import {Controller} from 'react-hook-form';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';

export const PersonalInformation = ({
    control,
    setValue,
    formState: {errors},
}: userDataDataProps) => {
    const today: Date = DateTime.now().toJSDate();
    const [date] = useState<Date | null>(today);

    const hasErrors = () => {
        return Object.keys(errors).length > 0;
    };

    return (
        <ContainerFlex {...personalStyles}>
            <ContainerCellGrid Padding="24px" Gap="32px">
                <ContainerFlex FlexDir="column" Gap="16px">
                    <ContainerFlex Justify="start">
                        <Text FontSize="1.25rem" FontWeight="600" Color="#2A2C2F">
                            {TEXT_NEW_USER.INFORMATION}
                        </Text>
                    </ContainerFlex>
                    {hasErrors() && (
                        <ContainerFlex {...hastStyles}>
                            <Image width="24px" height="24px" src={action} alt="action-icon" />
                            <Text FontWeight="700" Color="#501111">
                                {TEXT_NEW_USER.ACTION}
                            </Text>
                        </ContainerFlex>
                    )}
                    <ContainerCellGrid Gap="16px">
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                            <Text Color="#1D1E20" FontWeight="700">
                                {TEXT_NEW_USER.NAME}
                            </Text>
                            <Controller
                                name="name"
                                control={control}
                                render={({field}) => (
                                    <GeneralsInput
                                        maxLength={50}
                                        {...field}
                                        helperText={errors.name?.message}
                                        error={!!errors.name}
                                        imgMessage={closed}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                            <Text Color="#1D1E20" FontWeight="700">
                                {TEXT_NEW_USER.LAST_NAME}
                            </Text>
                            <Controller
                                name="parental_surnames"
                                control={control}
                                render={({field}) => (
                                    <>
                                        <GeneralsInput
                                            maxLength={50}
                                            {...field}
                                            error={!!errors.parental_surnames}
                                            helperText={errors.parental_surnames?.message}
                                            imgMessage={closed}
                                        />
                                    </>
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                            <Text Color="#1D1E20" FontWeight="700">
                                {TEXT_NEW_USER.MATERNAL_SURNAME}
                            </Text>
                            <Controller
                                name="maternal_surname"
                                control={control}
                                render={({field}) => (
                                    <GeneralsInput
                                        maxLength={50}
                                        {...field}
                                        error={!!errors.maternal_surname}
                                        helperText={errors.maternal_surname?.message}
                                        imgMessage={closed}
                                    />
                                )}
                            />
                        </ContainerFlex>
                    </ContainerCellGrid>
                    <ContainerCellGrid Gap="16px">
                        <ContainerFlex Align="start" FlexDir="column" Gap="4px">
                            <Text Color="#1D1E20" FontWeight="700">
                                {TEXT_NEW_USER.DATE}
                            </Text>
                            <Controller
                                name="birthdate"
                                control={control}
                                render={({field: {onChange, ref, value, ...field}}) => (
                                    <>
                                        <CalendarDateSelection
                                            {...field}
                                            placeholder=" /  /"
                                            format="dd/MM/yyyy"
                                            value={date}
                                            error={!!errors.birthdate?.message}
                                            limitDate={true}
                                            datePast={18}
                                            onSelect={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                        {errors.birthdate && (
                                            <ContainerFlex Justify="start" Gap="4px">
                                                <Image src={closed} alt="closed" />
                                                <Text Color="#ef4f55" FontSize="0.75rem">
                                                    {errors.birthdate.message}
                                                </Text>
                                            </ContainerFlex>
                                        )}
                                    </>
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                            <Text Color="#1D1E20" FontWeight="700">
                                {TEXT_NEW_USER.PHONE_NUMBER}
                            </Text>
                            <Controller
                                name="phone"
                                control={control}
                                render={({field: {onChange, value, ...field}}) => (
                                    <>
                                        <PhoneInput
                                            error={!!errors.phone}
                                            defaultDialCode="+52"
                                            value={value?.number || ''}
                                            onChange={(phoneValue) => {
                                                onChange({
                                                    dialCode: phoneValue.dialCode || '+52',
                                                    number: phoneValue.number || '',
                                                });
                                            }}
                                            {...field}
                                        />
                                        {errors.phone?.number && (
                                            <ContainerFlex Justify="start" Gap="4px">
                                                <Image src={closed} alt="closed" />
                                                <Text Color="#ef4f55" FontSize="0.75rem">
                                                    {errors.phone.number.message}
                                                </Text>
                                            </ContainerFlex>
                                        )}
                                    </>
                                )}
                            />
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                            <Text Color="#1D1E20" FontWeight="700">
                                {TEXT_NEW_USER.EMAI_USER}
                            </Text>
                            <Controller
                                name="email"
                                control={control}
                                render={({field}) => (
                                    <>
                                        <GeneralsInput
                                            {...field}
                                            error={!!errors.email}
                                            helperText={errors.email?.message}
                                            Img={question}
                                            imgMessage={closed}
                                        />
                                    </>
                                )}
                            />
                        </ContainerFlex>
                    </ContainerCellGrid>
                </ContainerFlex>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
export const DateIcon = () => {
    return (
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            calendar_today
        </Icons>
    );
};
