import React, {useState, useEffect, useCallback} from 'react';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {LocalizationProvider, CalendarPicker} from '@mui/x-date-pickers';
import {DateTime} from 'luxon';

import {
    FUTURE_YEARS,
    months,
    SpanishLocaleDayText,
} from '@General/Atoms/CalendarDateSelection/constants';
import {CustomDatePickerProps} from '@General/Atoms/CalendarDateSelection/interfaces';
import {ContainerFlex} from '@EcommerceShopify/styles';
import {
    CalendarModal,
    CalendarSelect,
    CalendarWrapper,
} from '@General/Atoms/CalendarDateSelection/Styles/styles';
import DatePickerInput from '@General/Atoms/CalendarDateSelection/DatePickerInput';
import CalendarButtons from '@General/Atoms/CalendarDateSelection/CalendarButtons';

const CalendarDateSelection: React.FC<CustomDatePickerProps> = ({
    value,
    placeholder,
    type,
    gap,
    format,
    error,
    isReset,
    inputGridCols,
    disabled = false,
    click,
    datePast,
    limitDate,
    onSelect,
}) => {
    const [selectedDate, setSelectedDate] = useState<DateTime | null>(
        value ? DateTime.fromJSDate(value) : null
    );
    const [initialDate, setInitialDate] = useState<DateTime | null>(
        value ? DateTime.fromJSDate(value) : null
    );
    const years = Array.from({length: 120}, (_, i) => {
        const year = DateTime.now().year + (datePast ? -datePast : FUTURE_YEARS) - i;
        return {value: year, label: year.toString()};
    });
    const [selectedMonth, setSelectedMonth] = useState<number>(DateTime.now().month);
    const [selectedYear, setSelectedYear] = useState<number>(DateTime.now().year);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    useEffect(() => {
        if (value) {
            const date = DateTime.fromJSDate(value);
            setSelectedDate(date);
            setInitialDate(date);
            setSelectedMonth(date.month);
            setSelectedYear(date.year);
        }
    }, [value]);

    const handleMonthChange = useCallback(
        (selectedOption: {value: number; label: string} | null) => {
            if (selectedOption) {
                const newMonth = selectedOption.value;
                setSelectedMonth(newMonth);
                const updatedDate = DateTime.local(selectedYear, newMonth, 1);
                setSelectedDate(updatedDate);
            }
        },
        [selectedYear]
    );

    const handleYearChange = useCallback(
        (selectedOption: {value: number; label: string} | null) => {
            if (selectedOption) {
                const newYear = selectedOption.value;
                setSelectedYear(newYear);
                const updatedDate = DateTime.local(newYear, selectedMonth, 1);
                setSelectedDate(updatedDate);
            }
        },
        [selectedMonth]
    );

    const handleDateChange = useCallback((date: DateTime | null) => {
        setSelectedDate(date);
    }, []);

    const handleCancel = useCallback(() => {
        setSelectedDate(initialDate);
        setSelectedMonth(initialDate ? initialDate.month : DateTime.now().month);
        setSelectedYear(initialDate ? initialDate.year : DateTime.now().year);
        setAnchorEl(null);
    }, [initialDate]);

    const handleContinue = useCallback(() => {
        const date = selectedDate ? selectedDate.toJSDate() : null;
        if (onSelect) onSelect(date);
        setAnchorEl(null);
    }, [selectedDate]);

    const handleClick = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            if (disabled) {
                return;
            }

            setInitialDate(selectedDate);
            setAnchorEl(event.currentTarget);
        },
        [disabled, selectedDate]
    );

    const handleClose = useCallback(() => {
        handleCancel();
    }, [handleCancel]);

    const dayOfWeekFormatter = (day: string): string => {
        const englishDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const index = englishDays.indexOf(day);
        if (index !== -1) {
            return SpanishLocaleDayText.weekDays[index];
        }
        return '';
    };

    const formatDate = (date: DateTime | null): string => {
        if (!date) return '';

        switch (format) {
            case 'MM/yyyy':
                return date.toFormat('MM/yyyy');
            case 'dd/MM':
                return date.toFormat('dd/MM');
            case 'dd/MM/yyyy':
            default:
                return date.toFormat('dd/MM/yyyy');
        }
    };

    const open = Boolean(anchorEl);
    const id = open ? 'date-picker-popover' : undefined;
    const handleReset = useCallback(() => {
        setSelectedDate(initialDate);
        setSelectedMonth(initialDate ? initialDate.month : DateTime.now().month);
        setSelectedYear(initialDate ? initialDate.year : DateTime.now().year);
    }, [initialDate]);
    useEffect(() => {
        if (isReset) {
            handleCancel();
        }
    }, [isReset]);
    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="flex-start"
                Gap="0.25rem"
                Height="auto"
                Flex="1 0 0"
                onClick={click}
                Cursor="pointer"
            >
                <DatePickerInput
                    disabled={disabled}
                    placeholder={placeholder}
                    value={formatDate(selectedDate)}
                    error={error}
                    gap={gap}
                    type={type}
                    inputGridCols={inputGridCols}
                    onClick={handleClick}
                />
                <CalendarModal
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <ContainerFlex
                        Radius="0.5rem"
                        FlexDir="column"
                        Align="flex-start"
                        Border="1px solid #F4F5F5"
                        backG="#FFF"
                        BoxShadow="0 0.688rem 1.663rem 0 rgba(0, 0, 0, 0.06)"
                    >
                        <ContainerFlex
                            Padding="0.5rem"
                            Align="flex-start"
                            Gap="0.5rem"
                            Self="stretch"
                        >
                            <CalendarSelect
                                value={
                                    months.find((month) => month.value === selectedMonth) || null
                                }
                                options={months}
                                onChange={(option) =>
                                    handleMonthChange(option as {value: number; label: string})
                                }
                            />
                            <CalendarSelect
                                value={years.find((year) => year.value === selectedYear) || null}
                                options={years}
                                onChange={(option) =>
                                    handleYearChange(option as {value: number; label: string})
                                }
                            />
                        </ContainerFlex>
                        <ContainerFlex Padding="0.5rem" Align="flex-end">
                            <CalendarWrapper>
                                <CalendarPicker
                                    dayOfWeekFormatter={dayOfWeekFormatter}
                                    date={selectedDate}
                                    onChange={handleDateChange}
                                    minDate={DateTime.local(selectedYear, selectedMonth, 1)}
                                    maxDate={
                                        limitDate
                                            ? DateTime.now()
                                            : DateTime.local(
                                                  selectedYear,
                                                  selectedMonth,
                                                  DateTime.local(selectedYear, selectedMonth)
                                                      .daysInMonth
                                              )
                                    }
                                    className="calendar-options"
                                />
                            </CalendarWrapper>
                        </ContainerFlex>
                        <CalendarButtons
                            onCancel={handleCancel}
                            onContinue={handleContinue}
                            continueDisabled={!selectedDate}
                            onReset={handleReset}
                        />
                    </ContainerFlex>
                </CalendarModal>
            </ContainerFlex>
        </LocalizationProvider>
    );
};

export default CalendarDateSelection;
