import * as yup from 'yup';
import {VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';

export const schemaDisbursementsForm = yup.object().shape({
    disbursements: yup
        .array()
        .of(
            yup.object().shape({
                disbursementsId: yup.number(),
                isChecked: yup.boolean(),
            })
        )
        .test(
            'at-least-one-true-disbusements',
            VALIDATIONS_MSG.DISBURSEMENT.AT_LEAST_ONE,
            function (value) {
                return value && value.some((item) => item.isChecked === true);
            }
        ),
});
