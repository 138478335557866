import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {changeActualStep, restarQuoter} from '@Quoter/Redux/Actions/saveEndeavors';
import {resetSearchClient} from '@components/ShoppingCart/Redux/Actions/SearchClientList';
import {setRefreshCart} from '@/components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {clearAllContracts} from '@components/Shopify/Products/redux/actions/GetCarContracts';
import {resetPaymentPosted} from '@components/Loan/Redux/actions/AddCartPayment';

export const useCloseTransaction = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const onCloseTransactionHandler = async () => {
        if (token) {
            await dispatch(
                DeleteTemporalyPawns(token, {
                    deleteAllPawns: true,
                    operationType: 1,
                    contractPawnId: 0,
                })
            );
            dispatch(changeActualStep(1));
            dispatch(restarQuoter());
            dispatch(resetSearchClient());
            navigate('/');
            dispatch(resetPaymentPosted());
            dispatch(clearAllContracts());
            dispatch(setRefreshCart());
        }
    };

    return onCloseTransactionHandler;
};
