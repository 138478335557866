import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {
    SHOPPING_CAR_CLIENT_ERROR,
    SHOPPING_CAR_CLIENT_START,
    SHOPPING_CAR_CLIENT_SUCCESS,
    GET_DETAILS_SHOPPING_CAR_SUCCESS,
    SHOW_MODAL,
    CLOSE_MODAL,
    ADD_CREDIT_SELECTED,
    UNSELECT_CREDITS_SELECTED,
    REMOVE_CREDIT_SELECTED,
    SHOW_WARNING_MESSAGE,
    ADD_SELECTED_PAYMENT_METHOD,
    REMOVE_SELECTED_PAYMENT_METHOD,
    CREDIT_PAYMENT_PROCESS_SUCCESS,
    SHOW_MODAL_TO_FALSE,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {
    ICreditPaymentProcessProps,
    IDetailLoansCarCredit,
    IPersonalCreditDetails,
    ISelectedPaymentMethod,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {NavigateFunction} from 'react-router-dom';
import {routers} from '@/appRoute';

const creditPaymentProcessStart = () => {
    return {
        type: SHOPPING_CAR_CLIENT_START,
    };
};

const creditPaymentProcessSuccess = (result: AxiosResponse) => {
    return {
        type: CREDIT_PAYMENT_PROCESS_SUCCESS,
        payload: result,
    };
};

const shoppingCarClientError = (error: AxiosError) => {
    return {
        type: SHOPPING_CAR_CLIENT_ERROR,
        error: error,
    };
};

const shoppingCarClientStart = () => {
    return {
        type: SHOPPING_CAR_CLIENT_START,
    };
};

const shoppingCarClientSuccess = (result: AxiosResponse) => {
    return {
        type: SHOPPING_CAR_CLIENT_SUCCESS,
        payload: result,
    };
};

const getDetailsShoppingCarSuccess = (result: AxiosResponse) => {
    return {
        type: GET_DETAILS_SHOPPING_CAR_SUCCESS,
        payload: result,
    };
};

export const getDetailsShoppingCarAction = (token: string, carClientId: string) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DetailsCar.replace(
        '{carClientId}',
        carClientId
    )}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(shoppingCarClientStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getDetailsShoppingCarSuccess(response.data));
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

const addCreditToBackPackAction = ({
    token,
    carClientId,
    personalCredit,
    navigate,
}: {
    token: string;
    carClientId: string;
    personalCredit: IPersonalCreditDetails;
    navigate?: NavigateFunction;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddBackPackClient}`;
    const {personalCreditId, crontactNumber, paymentId, paymentAmount} = personalCredit;
    const params = {
        carShoppingId: carClientId,
        paymentCredits: [
            {
                solicitudeId: personalCreditId,
                solicitudeNumber: crontactNumber,
                details: {
                    amountAbono: paymentAmount,
                    paymentCreditId: paymentId,
                },
            },
        ],
    };
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        try {
            const response = await axios.post(url, params, {headers});
            if (!navigate) dispatch(showModalAction());
            dispatch(shoppingCarClientSuccess(response.data));
            if (navigate) {
                navigate(routers.CreditsCartShopping);
            }
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

export const addCreditToShoppingCarAction = (
    personalCreditId: number,
    token: string,
    carClientId: string,
    navigate?: NavigateFunction
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(shoppingCarClientStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetByIdPersonalCreditAmount}`,
                {headers, params: {personalCreditId}}
            );
            const personalCredit: IPersonalCreditDetails = response?.data?.data ?? null;
            if (personalCredit) {
                dispatch(addCreditToBackPackAction({token, carClientId, personalCredit, navigate}));
            }
        } catch (error) {
            dispatch(shoppingCarClientError(error as AxiosError));
        }
    };
};

export const deleteCreditToShoppingCarAction = ({
    token,
    paymentCreditId,
    carClienteId,
}: {
    token: string;
    paymentCreditId: number;
    carClienteId: number;
}) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteItemBackPackClient}`;
    const params = {carClienteId, paymentCreditId};
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(shoppingCarClientStart());
        try {
            const response = await axios.delete(url, {
                headers,
                data: params,
            });
            dispatch(shoppingCarClientSuccess(response.data));
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

export const showModalAction = () => {
    return {
        type: SHOW_MODAL,
    };
};

export const closeModalAction = () => {
    return {
        type: CLOSE_MODAL,
    };
};

export const showWarningMessageAction = () => {
    return {
        type: SHOW_WARNING_MESSAGE,
    };
};

export const addCreditSelectedAction = (credit: IDetailLoansCarCredit) => {
    return {
        type: ADD_CREDIT_SELECTED,
        credit,
    };
};

export const removeCreditSelectedAction = (credit: IDetailLoansCarCredit) => {
    return {
        type: REMOVE_CREDIT_SELECTED,
        credit,
    };
};

export const unselectCreditsSelectedAction = () => {
    return {
        type: UNSELECT_CREDITS_SELECTED,
    };
};

export const addSelectedPaymentMethodAction = (selectedPaymentMethod: ISelectedPaymentMethod) => {
    return {
        type: ADD_SELECTED_PAYMENT_METHOD,
        selectedPaymentMethod,
    };
};

export const removeSelectedPaymentMethodAction = () => {
    return {
        type: REMOVE_SELECTED_PAYMENT_METHOD,
    };
};

export const creditPaymentProcessAction = (
    creditPaymentProcessProps: ICreditPaymentProcessProps
) => {
    const {token, loansCarCreditsSelected, cardNumber, cardOwner, cvv, vigency} =
        creditPaymentProcessProps;
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreditPaymentProcess}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const data = {
        personalCreditsPayments: loansCarCreditsSelected.map((credit) => ({
            personalCreditId: credit.solicitudeId,
            paymentId: credit.paymentCreditId,
            amount: credit.amountQuote,
        })),
        cardNumber,
        names: cardOwner,
        expiration: vigency,
        cvv: cvv,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(creditPaymentProcessStart());
        try {
            const response = await axios.post(url, data, {headers});
            dispatch(creditPaymentProcessSuccess(response.data));
        } catch (e) {
            dispatch(shoppingCarClientError(e as AxiosError));
        }
    };
};

export const showResumeToFalseAction = () => {
    return {
        type: SHOW_MODAL_TO_FALSE,
    };
};
