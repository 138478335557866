import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {itemsModulesAdmistrator} from '@/components/SideBarMenu/Redux/modulesAdministratorActions';
import {RootState} from '@/config/store';
import {IModulesAdministrator} from '@components/SideBarMenu/interfaces';
import {ISidebarProps} from '@components/SideBarMenu/interfaces';
import {MenuAdminSettings} from '@/components/SideBarMenu/MenuAdminSettings';
import MenuItem from '@/components/SideBarMenu/MenuItem';
import directoryIcon from '@/components/SideBarMenu/Icons/directoryIcon.svg';
import AccordionSideBarMenu from '@/components/SideBarMenu/AccordionSideBarMenu';
import {ClickAwayListener} from '@mui/material';

export const MenuAdmin: React.FC<ISidebarProps> = ({handleClickAway}) => {
    const [menuSettings] = useState(false);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);

    const dispatch = useDispatch();
    useEffect(() => {
        if (token) dispatch(itemsModulesAdmistrator(token));
    }, []);

    const modulesAdministrator: IModulesAdministrator[] = useSelector(
        (state: RootState) => state.modulesAdmistrator.data
    );
    return (
        <ClickAwayListener onClickAway={() => handleClickAway()}>
            <ContainerFlex
                Align="start"
                Justify="start"
                backG="#FAFAFF"
                Width="100%"
                MaxW="256px"
                OverFlowY="auto"
                OverFlowX="hidden"
            >
                <ContainerFlex
                    Padding="16px 0px 16px 16px"
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="8px"
                >
                    {modulesAdministrator
                        ?.filter((category) => category.item.length > 0)
                        ?.map((category) => (
                            <>
                                <Text
                                    FontFamily="Nunito"
                                    FontSize="0.88rem"
                                    FontWeight="700"
                                    Color="#2A2C2F"
                                    Padding="0px 8px 8px 8px"
                                >
                                    {category.title}
                                </Text>
                                {category?.item?.map((subCategory, index) => (
                                    <AccordionSideBarMenu
                                        key={index}
                                        summaryIcon={subCategory?.cssIcon || directoryIcon}
                                        summaryTitle={subCategory.title}
                                    >
                                        {subCategory?.item.map((item, index) => (
                                            <MenuItem key={index} {...item} />
                                        ))}
                                    </AccordionSideBarMenu>
                                ))}
                            </>
                        ))}
                </ContainerFlex>
                {menuSettings && <MenuAdminSettings />}
            </ContainerFlex>
        </ClickAwayListener>
    );
};
