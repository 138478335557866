import * as yup from 'yup';
const cleanCurrencyString = (value?: string): number => {
    return value ? parseFloat(value.replace(/[$,]/g, '').trim()) || 0 : 0;
};

export const schemaFilterLoans = yup.object().shape({
    minimumAmount: yup.string().optional(),
    maximumAmount: yup
        .string()
        .optional()
        .test(
            'max-greater-than-min',
            'La cantidad máxima no debe ser menor a la cantidad mínima.',
            function (value) {
                const {minimumAmount} = this.parent;
                const minQty = cleanCurrencyString(minimumAmount);
                const maxAmount = cleanCurrencyString(value);
                return maxAmount === 0 || maxAmount >= minQty;
            }
        ),
    minimumTime: yup
        .object()
        .shape({
            value: yup.number().optional(),
            label: yup.string().optional(),
        })
        .optional(),
    maximumTime: yup
        .object()
        .shape({
            value: yup.number().optional(),
            label: yup.string().optional(),
        })
        .optional(),
});
