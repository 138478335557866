import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    UPDATE_TERM_PERSONAL_LOANS_ERROR,
    UPDATE_TERM_PERSONAL_LOANS_START,
    UPDATE_TERM_PERSONAL_LOANS_SUCCESS,
} from '@components/LoansProducts/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {UpdateTermPersonalLoanAction} from '@components/LoansProducts/interfaces';
import {NavigateFunction} from 'react-router-dom';

export const fetchUpdateTermPersonalLoanStart = () => ({
    type: UPDATE_TERM_PERSONAL_LOANS_START,
});
export const fetchUpdateTermPersonalLoanSuccess = (data: AxiosResponse) => {
    return {
        type: UPDATE_TERM_PERSONAL_LOANS_SUCCESS,
        payload: data,
    };
};
export const fetchUpdateTermPersonalLoanError = (error: AxiosError) => {
    return {
        type: UPDATE_TERM_PERSONAL_LOANS_ERROR,
        payload: error,
    };
};

export function UpdateTermPersonalLoansForm(
    data: UpdateTermPersonalLoanAction,
    token: string,
    navigate: NavigateFunction
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchUpdateTermPersonalLoanStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateTermPersonalLoan}`,
                data,
                {headers}
            );
            dispatch(fetchUpdateTermPersonalLoanSuccess(response));
            navigate('/LoansProducts');
        } catch (error) {
            dispatch(fetchUpdateTermPersonalLoanError(error as AxiosError));
        }
    };
}
