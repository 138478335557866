import React from 'react';

import {IQuickActionsHeaderProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import arrowUpIcon from '@images/arrowUpIcon.svg';
import arrowDownIcon from '@images/arrowDownIcon.svg';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {QUICK_ACTIONS} from '@Shopify/ClientProfile/constants';

const QuickActionsHeader: React.FC<IQuickActionsHeaderProps> = ({quickActions, onToggle}) => (
    <ContainerFlex Cursor="pointer" Justify="space-between" onClick={onToggle}>
        <Text {...style.titles}>{QUICK_ACTIONS.TITLE}</Text>
        {quickActions ? <Image src={arrowUpIcon} /> : <Image src={arrowDownIcon} />}
    </ContainerFlex>
);

export default QuickActionsHeader;
