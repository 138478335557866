export const containerFlexProps = {
    Justify: 'start',
    Align: 'start',
    FlexDir: 'column',
    Width: '410px',
    Padding: '1rem 1.5rem',
    Border: '1px solid #E8E9EA',
    backG: '#FFFFFF',
    Radius: '1rem 0px 0px 1rem',
    Gap: '1.5rem',
    OverFlowY: 'scroll',
    as: 'form' as React.ElementType,
};

export const textHeaderProps = {
    oFlow: 'normal',
    FontSize: '1.5rem',
    FontWeight: '700',
    Color: '#1D1E20',
};

export const cleanButtonTextProps = {
    oFlow: 'normal',
    FontWeight: '700',
    Color: '#5A5AFF',
    Cursor: 'pointer',
};

export const inputNumericProps = {
    height: '40px',
    width: '338px',
    padding: '0 0 0 13px',
    color: '#a7a9ac',
    radius: '32px',
    colorplace: '#A1A5AA',
    hborder: '1px solid #5A5AFF',
    borderf: '',
    prefix: '$ ',
    decimalSeparator: '.',
    thousandSeparator: true,
    decimalScale: 2,
    allowNegative: false,
    placeholder: '$',
    allowLeadingZeros: false,
    fixedDecimalScale: true,
    maxLength: 16,
};

export const FilterTextInputs = {
    oFlow: 'none',
    wSpace: 'normal',
    Color: '#2A2C2F',
    FontWeight: '700',
};
export const CheckBoxContainer = {
    Justify: 'start',
    Height: '2.5rem',
    Padding: '0.5rem',
    Gap: '0.5rem',
};
export const CheckBoxText = {
    oFlow: 'none',
    wSpace: 'normal',
    Color: '#2A2C2F',
};
export const buttonGeneralsProps = {
    Width: '10.5rem',
    FontWeight: '700',
};

export const cancelButtonProps = {
    ...buttonGeneralsProps,
    SecondaryButton: '#FFFFFF',
};

export const errorTextProps = {
    Color: '#ef4f55',
    FontSize: '0.875rem',
    Align: 'start',
    wSpace: 'normal',
    FontWeight: '400',
};
