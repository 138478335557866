import axios from 'axios';
import {
    VERIFY_CODE_REQUEST,
    VERIFY_CODE_SUCCESS,
    VERIFY_CODE_ERROR,
    VERIFY_CODE_RESET_ERROR,
} from '@components/MarketLogin/redux/types/types';
import process from 'process';
import {URL} from '@config/constants/index';

export const ResetVerifyCode = () => {
    return {
        type: VERIFY_CODE_RESET_ERROR,
    };
};
export const PostVerifyCodeResquest = () => {
    return {
        type: VERIFY_CODE_REQUEST,
    };
};
export const PostVeryfyCodeSuccess = (data) => {
    return {
        type: VERIFY_CODE_SUCCESS,
        payload: data,
    };
};

export const PostVeryfyCodeFailure = (err) => {
    return {
        type: VERIFY_CODE_ERROR,
        payload: err,
    };
};

export const PostCode = (token, json, navigate) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(PostVerifyCodeResquest());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ValidateCodeUserClient}`,
                json,
                {headers}
            );
            dispatch(PostVeryfyCodeSuccess(response));
            navigate('/Login/MarketUser');
        } catch (error) {
            dispatch(PostVeryfyCodeFailure(error));
        }
    };
};
