import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';
import Swal from 'sweetalert2';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {Table, TableItems, TableItemsContainer} from '@DeadLines/styles';
import {
    TableRowHeadContract,
    TableRowItemsContract,
} from '@DeadLines/CreateDeadline/DeadlineSteps/AmountAndTimes/styles';
import {StyleNumericFormat} from '@Loan/styles';
import {limitNumberFormat, PercentageFormat, QuitNumberFormat} from '@/hooks/currentFormatUtils';
import {amountsRetorn, tableAmount, tableAmountTimes} from '@CreateDeadline/interfaces';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {MenuIcons} from '@/components/Roles/styles';
import {tableAmounts} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import edit from '@images/edit.svg';
import {interested, titleAmount} from '@CreateDeadline/redux/Constants';

export const TableAmounts = (props: tableAmountTimes) => {
    const {hasDynamicInterest, deadlineById, deadlinesGeneral, validateAmounts} = props;
    const [edited, setEdited] = useState('Save');
    const [disable, setDisable] = useState(true);
    const [positionTable, setpositionTable] = useState(0);
    const dispatch: AppDispatch = useDispatch();
    const {reset, getValues, handleSubmit, control, watch} = useForm({
        defaultValues: {
            lowerLoanPercentage: '',
            higherLoanPercentage: '',
            interestPercentage: '',
            marketingPercentage: '',
            discountPercentage: '',
            order: 1,
        },
    });
    const valuesForms = watch();

    const handleSaveAmountTime = (value: tableAmount) => {
        setEdited('Save');
        setDisable(true);
        if (
            (value.lowerLoanPercentage &&
                value.higherLoanPercentage &&
                value.interestPercentage &&
                value.marketingPercentage &&
                value.discountPercentage) !== ''
        ) {
            if (
                QuitNumberFormat(value.lowerLoanPercentage) <
                QuitNumberFormat(value.higherLoanPercentage)
            ) {
                const table = {
                    order:
                        edited === 'Edit' ? value.order : deadlinesGeneral.rangeInterest.length + 1,
                    lowerLoanPercentage: QuitNumberFormat(value.lowerLoanPercentage),
                    higherLoanPercentage: QuitNumberFormat(value.higherLoanPercentage),
                    interestPercentage: QuitNumberFormat(value.interestPercentage),
                    marketingPercentage: QuitNumberFormat(value.marketingPercentage),
                    discountPercentage: QuitNumberFormat(value.discountPercentage),
                };

                dispatch(tableAmounts([...deadlinesGeneral.rangeInterest, table]));
                reset({
                    lowerLoanPercentage: '',
                    higherLoanPercentage: '',
                    interestPercentage: '',
                    marketingPercentage: '',
                    discountPercentage: '',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    text: `Rango inferior no puede ser mayor o igual al Rango Superior`,
                    confirmButtonText: 'Continuar',
                });
            }
        }
    };

    const handleEdit = (id: number) => {
        setpositionTable(id - 1);
        if (id === 1) setDisable(false);
        setEdited('Edit');
        const editAmount = deadlinesGeneral.rangeInterest.filter(
            (item: {order: number}) => item.order === id
        )[0];

        reset({
            order: editAmount.order,
            higherLoanPercentage: PercentageFormat(editAmount.higherLoanPercentage),
            lowerLoanPercentage: PercentageFormat(editAmount.lowerLoanPercentage),
            interestPercentage: PercentageFormat(editAmount.interestPercentage),
            marketingPercentage: PercentageFormat(editAmount.marketingPercentage),
            discountPercentage: PercentageFormat(editAmount.discountPercentage),
        });
        dispatch(
            tableAmounts(
                deadlinesGeneral.rangeInterest.filter((item: {order: number}) => item.order !== id)
            )
        );
    };

    const filterAmount = () => {
        if (deadlinesGeneral.rangeInterest.length > 0) {
            const newData = deadlinesGeneral.rangeInterest.reduce(
                (acc: interested<amountsRetorn>, item, index) => {
                    acc[item.order] = {
                        lowerLoanPercentage:
                            item.order === 1
                                ? deadlinesGeneral.rangeInterest[0].lowerLoanPercentage
                                : deadlinesGeneral.rangeInterest[index - 1]?.higherLoanPercentage
                                ? deadlinesGeneral.rangeInterest[index - 1].higherLoanPercentage +
                                  0.01
                                : 0,
                        order: item.order,
                        higherLoanPercentage: item.higherLoanPercentage,
                        interestPercentage: item.interestPercentage,
                        marketingPercentage: item.marketingPercentage,
                        discountPercentage: item.discountPercentage,
                    };
                    return acc;
                },
                {}
            );
            const position = Object.values(newData)
                .map((item, index) => {
                    return item.lowerLoanPercentage > item.higherLoanPercentage ? index : undefined;
                })
                .filter((item) => item !== undefined)[0];
            dispatch(tableAmounts(Object.values(newData).slice(0, position)));
        }
    };

    const changedColor = (higherPercent: number, type: string, state: number, row?: number) => {
        const higher = QuitNumberFormat(
            valuesForms.higherLoanPercentage ? valuesForms.higherLoanPercentage : ''
        );
        if (edited === 'Edit') {
            if (higher < higherPercent && type === 'color' && state === positionTable && row === 0)
                return '#ef4f55';
            if (higher > higherPercent && state === positionTable && type === 'color')
                return '#ef4f55';
            if (higher > higherPercent && state === positionTable && type === 'border')
                return '1px solid #ef4f55';
            return '1px solid #E4E7E9';
        }
    };
    const onSubmit = () => {
        if (hasDynamicInterest) dispatch(tableAmounts(deadlinesGeneral.rangeInterest));
    };

    useEffect(() => {
        if (edited === 'Save') {
            const newValue = deadlinesGeneral.rangeInterest;
            reset({
                lowerLoanPercentage: PercentageFormat(
                    newValue[newValue.length - 1]?.higherLoanPercentage + 0.01
                ),
            });
        }
        validateAmounts();
    }, [deadlinesGeneral.rangeInterest]);

    useEffect(() => {
        filterAmount();
    }, [edited === 'Save']);

    return (
        <ContainerFlex Width="100%" Padding="0.25rem 1.5rem 0 1.5rem" MarginBt="1rem">
            <ContainerFlex>
                <FormContainer
                    key={1}
                    onSubmit={handleSubmit(onSubmit)}
                    Width="100%"
                    MarginTp="0"
                    autoComplete="off"
                >
                    <Table>
                        <TableRowHeadContract
                            Height="48px"
                            Grid={'0.4fr 0.4fr 0.4fr 0.45fr 0.4fr 0.28fr'}
                        >
                            {' '}
                            {titleAmount.map((item: {title: string}, index) => (
                                <TableItems Height="48px" Justify="center" key={index}>
                                    <Text FontSize="0.857rem" FontWeight="500">
                                        {item.title}
                                    </Text>
                                </TableItems>
                            ))}
                        </TableRowHeadContract>

                        {!deadlineById?.edit && (
                            <TableItemsContainer>
                                <TableRowItemsContract
                                    Margin="0.5rem 0 0 0"
                                    Height="60px"
                                    Align="start"
                                    Grid={
                                        deadlineById?.edit
                                            ? '0.4fr 0.4fr 0.4fr 0.45fr 0.4fr'
                                            : '0.4fr 0.4fr 0.4fr 0.45fr 0.4fr 0.28fr'
                                    }
                                >
                                    <TableItems InputHeight="40px" Width="90%">
                                        <Controller
                                            name="lowerLoanPercentage"
                                            control={control}
                                            render={({field: {ref, ...field}}) => (
                                                <StyleNumericFormat
                                                    {...field}
                                                    getInputRef={ref}
                                                    textAlign="center"
                                                    type="text"
                                                    suffix="%"
                                                    disabled={
                                                        deadlinesGeneral.rangeInterest.length > 0 &&
                                                        disable
                                                    }
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    allowLeadingZeros={false}
                                                    isAllowed={(values: {value: string}) =>
                                                        limitNumberFormat(
                                                            QuitNumberFormat(values.value),
                                                            0,
                                                            1000
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </TableItems>
                                    <TableItems InputHeight="40px" Width="90%">
                                        <Controller
                                            name="higherLoanPercentage"
                                            control={control}
                                            render={({field: {ref, ...field}}) => (
                                                <StyleNumericFormat
                                                    {...field}
                                                    getInputRef={ref}
                                                    textAlign="center"
                                                    type="text"
                                                    suffix="%"
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    allowLeadingZeros={false}
                                                    isAllowed={(values: {value: string}) =>
                                                        limitNumberFormat(
                                                            QuitNumberFormat(values.value),
                                                            0,
                                                            1000
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </TableItems>
                                    <TableItems InputHeight="40px" Width="90%">
                                        <Controller
                                            name="interestPercentage"
                                            control={control}
                                            render={({field: {ref, ...field}}) => (
                                                <StyleNumericFormat
                                                    {...field}
                                                    getInputRef={ref}
                                                    textAlign="center"
                                                    type="text"
                                                    suffix="%"
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    allowLeadingZeros={false}
                                                    isAllowed={(values: {value: string}) =>
                                                        limitNumberFormat(
                                                            QuitNumberFormat(values.value),
                                                            0,
                                                            1000
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </TableItems>
                                    <TableItems InputHeight="40px" Width="85%">
                                        <Controller
                                            name="marketingPercentage"
                                            control={control}
                                            render={({field: {ref, ...field}}) => (
                                                <StyleNumericFormat
                                                    {...field}
                                                    getInputRef={ref}
                                                    textAlign="center"
                                                    type="text"
                                                    suffix="%"
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    allowLeadingZeros={false}
                                                    isAllowed={(values: {value: string}) =>
                                                        limitNumberFormat(
                                                            QuitNumberFormat(values.value),
                                                            0,
                                                            1000
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </TableItems>
                                    <TableItems InputHeight="40px" Width="90%">
                                        <Controller
                                            name="discountPercentage"
                                            control={control}
                                            render={({field: {ref, ...field}}) => (
                                                <StyleNumericFormat
                                                    {...field}
                                                    getInputRef={ref}
                                                    textAlign="center"
                                                    type="text"
                                                    suffix="%"
                                                    decimalSeparator="."
                                                    displayType="input"
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    allowNegative={false}
                                                    allowLeadingZeros={false}
                                                    isAllowed={(values: {value: string}) =>
                                                        limitNumberFormat(
                                                            QuitNumberFormat(values.value),
                                                            0,
                                                            1000
                                                        )
                                                    }
                                                />
                                            )}
                                        />
                                    </TableItems>
                                    <TableItems Height="40px" Justify="center">
                                        <span
                                            className="material-icons"
                                            onClick={() => {
                                                getValues([
                                                    'lowerLoanPercentage',
                                                    'higherLoanPercentage',
                                                    'interestPercentage',
                                                    'marketingPercentage',
                                                    'discountPercentage',
                                                ]);
                                                const getData = getValues();
                                                handleSaveAmountTime(getData);
                                            }}
                                        >
                                            save
                                        </span>
                                    </TableItems>
                                </TableRowItemsContract>
                            </TableItemsContainer>
                        )}

                        {deadlinesGeneral.rangeInterest
                            ?.sort((a, b) => a.order - b.order)
                            .map((item, index: number) => (
                                <TableItemsContainer key={index}>
                                    {QuitNumberFormat(
                                        valuesForms.higherLoanPercentage
                                            ? valuesForms.higherLoanPercentage
                                            : ''
                                    ) < item.higherLoanPercentage &&
                                        edited === 'Edit' &&
                                        index === positionTable && (
                                            <Text Color="#ef4f55" FontSize="0.625rem">
                                                Con este rango del{' '}
                                                {valuesForms.higherLoanPercentage}, se realizará una
                                                modificación en la fila que previamente se había
                                                guardado.
                                            </Text>
                                        )}
                                    {QuitNumberFormat(
                                        valuesForms.higherLoanPercentage
                                            ? valuesForms.higherLoanPercentage
                                            : ''
                                    ) > item.higherLoanPercentage &&
                                        index === positionTable &&
                                        edited === 'Edit' && (
                                            <Text Color="#ef4f55" FontSize="0.625rem">
                                                El valor de interés en la fila que habías guardado
                                                se ve superado por este rango del{' '}
                                                {valuesForms.higherLoanPercentage}. Por favor,
                                                ajústalo o el registro será eliminado.
                                            </Text>
                                        )}
                                    <TableRowItemsContract
                                        Height="40px"
                                        Grid="0.4fr 0.4fr 0.4fr 0.45fr 0.4fr 0.28fr"
                                        Border={changedColor(
                                            item.higherLoanPercentage,
                                            'border',
                                            index
                                        )}
                                        key={index}
                                    >
                                        <TableItems
                                            FontSize="0.875rem"
                                            Justify="center"
                                            Color={changedColor(
                                                item.higherLoanPercentage,
                                                'color',
                                                index,
                                                0
                                            )}
                                        >
                                            {PercentageFormat(item.lowerLoanPercentage)}
                                        </TableItems>
                                        <TableItems
                                            FontSize="0.875rem"
                                            Justify="center"
                                            Color={changedColor(
                                                item.higherLoanPercentage,
                                                'color',
                                                index
                                            )}
                                        >
                                            {PercentageFormat(item.higherLoanPercentage)}
                                        </TableItems>
                                        <TableItems
                                            FontSize="0.875rem"
                                            Justify="center"
                                            Color={changedColor(
                                                item.higherLoanPercentage,
                                                'color',
                                                index
                                            )}
                                        >
                                            {PercentageFormat(item.interestPercentage)}
                                        </TableItems>
                                        <TableItems
                                            FontSize="0.875rem"
                                            Justify="center"
                                            Color={changedColor(
                                                item.higherLoanPercentage,
                                                'color',
                                                index
                                            )}
                                        >
                                            {PercentageFormat(item.marketingPercentage)}
                                        </TableItems>
                                        <TableItems
                                            FontSize="0.875rem"
                                            Justify="center"
                                            Color={changedColor(
                                                item.higherLoanPercentage,
                                                'color',
                                                index
                                            )}
                                        >
                                            {PercentageFormat(item.discountPercentage)}
                                        </TableItems>
                                        {!deadlineById?.edit && (
                                            <TableItems>
                                                <MenuIcons
                                                    Width="1.25rem"
                                                    Height="1.25rem"
                                                    onClick={() => handleEdit(item.order)}
                                                    src={edit}
                                                    Cursor="pointer"
                                                />
                                            </TableItems>
                                        )}
                                    </TableRowItemsContract>
                                </TableItemsContainer>
                            ))}
                    </Table>
                </FormContainer>
            </ContainerFlex>
        </ContainerFlex>
    );
};
