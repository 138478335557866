import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIcon from '@images/clousedGIcone.svg';
import doneIcon from '@images/DoneGreen.svg';
import {Icon} from '@/components/UserProfileResetPassword/Operational/styles';
import {RESTORE_NEW_PASSWORD} from '@/components/NewUserEmployee/Operational/constants';
import {IErrors} from '@/components/NewUserEmployee/Operational/interface';
import {MesageRepeat} from '@/components/NewUserEmployee/Operational/styles';

export const ErrorPassword = ({error, password, repeatPassword, errorMessage}: IErrors) => {
    if (!password && error && errorMessage) {
        return (
            <Text Color="#A82424" FontSize="0.85rem" FontFamily="Nunito">
                {errorMessage}
            </Text>
        );
    }
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumberOrSpecial = /[!@#$%^&*]/.test(password);

    const getIconProps = (condition: boolean) => ({
        src: condition ? doneIcon : closeIcon,
        alt: condition ? 'Done icon' : 'Close icon',
        color: condition ? '#309C60' : 'A82424',
    });

    const lengthIcon = getIconProps(hasMinLength);
    const upperCaseIcon = getIconProps(hasUpperCase);
    const numberSpecialIcon = getIconProps(hasNumberOrSpecial);

    return (
        <ContainerFlex
            Height="auto"
            ColumnGap="4px"
            Align="start"
            Justify="start"
            Width="21.688rem"
        >
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Width="auto" Gap="4px">
                <Icon {...lengthIcon} />
                <Icon {...upperCaseIcon} />
                <Icon {...numberSpecialIcon} />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                <Text Color={hasMinLength ? '#309C60' : '#A82424'} {...MesageRepeat}>
                    {RESTORE_NEW_PASSWORD.MESSAGE}
                </Text>

                <Text Color={hasUpperCase ? '#309C60' : '#A82424'} {...MesageRepeat}>
                    {RESTORE_NEW_PASSWORD.MESSAGE_ONE}
                </Text>

                <Text Color={hasNumberOrSpecial ? '#309C60' : '#A82424'} {...MesageRepeat}>
                    {RESTORE_NEW_PASSWORD.MESSAGE_TWO}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
