import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import React, {useState} from 'react';
import {Table, TableRowHead, TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@DeadLines/styles';
import {
    OPTION_TABLE,
    TEXT_GENERAL,
    TOOLTIP_PAUSE,
    data,
    statusStyles,
} from './Operational/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import pencil from '@images/pecilEdit.svg';
import {ImgProducto} from './Operational/style';
import {useNavigate} from 'react-router-dom';
import TooltipSpan from './tooltip';

export const AuctionSettings = () => {
    const navigate = useNavigate();
    const [allSelected, setAllSelected] = useState(false);
    const [selectedItems, setSelectedItems] = useState(new Array(data.length).fill(false));
    const [activeStates, setActiveStates] = useState(data.map((user) => user.isActive));

    const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked;
        setAllSelected(checked);
        setSelectedItems(new Array(data.length).fill(checked));
    };

    const handleSelectItem = (index: number) => {
        const updatedSelection = [...selectedItems];
        updatedSelection[index] = !updatedSelection[index];
        setSelectedItems(updatedSelection);
        setAllSelected(updatedSelection.every((item) => item));
    };

    const handleToggleActive = (index: number) => {
        const updatedActiveStates = [...activeStates];
        updatedActiveStates[index] = !updatedActiveStates[index];
        setActiveStates(updatedActiveStates);
    };

    const handleRedirect = () => {
        navigate('/BranchCreation/ComercialCicle/AuctionDetails');
    };
    return (
        <ContainerFlex FlexDir="column">
            <Table>
                <TableRowHead
                    BackG="none"
                    GridColumn="24px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 60px 1fr;"
                    Height="32px"
                    BorderB="0.5px solid #e4e7e9"
                    Border=""
                    Radius=""
                    Padding="16px"
                >
                    <TableItem Justify="center">
                        <Checkbox checked={allSelected} onChange={handleSelectAll} />
                    </TableItem>
                    {OPTION_TABLE.map((option, index) => (
                        <TableItem key={index} Justify={index === 0 ? 'center' : 'start'}>
                            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                                {option.name}
                            </Text>
                        </TableItem>
                    ))}
                    <TableItem Justify="start" Gap="4px">
                        <TooltipSpan
                            title={TOOLTIP_PAUSE.TITLE}
                            description={TOOLTIP_PAUSE.DESCRIPTION}
                        />
                    </TableItem>
                </TableRowHead>
                <TableItemsContainer>
                    {data.map((user, rowIndex) => {
                        const styles = statusStyles[user.status] || {};
                        return (
                            <TableRowItems
                                GridColumn="24px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 60px 1fr;"
                                BorderB="0.5px solid #e4e7e9"
                                Border=""
                                key={rowIndex}
                                Padding="16px"
                                BorderRadius=""
                            >
                                <TableItem Justify="center">
                                    <Checkbox
                                        checked={selectedItems[rowIndex]}
                                        onChange={() => handleSelectItem(rowIndex)}
                                    />
                                </TableItem>
                                <TableItem Justify="center">
                                    <ImgProducto src={user.img} alt="product" />
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text FontFamily="Nunito" FontWeight="500" FontSize="1rem">
                                        {user.name}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text
                                        FontFamily="Nunito"
                                        FontWeight="400"
                                        FontSize="1rem"
                                        Color="#A1A5AA"
                                    >
                                        {user.sku}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text
                                        FontFamily="Nunito"
                                        FontWeight="400"
                                        FontSize="1rem"
                                        Color="#A1A5AA"
                                    >
                                        {user.category}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text
                                        FontFamily="Nunito"
                                        FontWeight="400"
                                        FontSize="1rem"
                                        Color="#A1A5AA"
                                    >
                                        {user.date}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Padding="4px 0px" Gap="4px">
                                    <Text
                                        FontFamily="Nunito"
                                        FontWeight="400"
                                        FontSize="1rem"
                                        Color="#A1A5AA"
                                    >
                                        {user.price}
                                    </Text>
                                    <Text
                                        FontFamily="Nunito"
                                        FontWeight="400"
                                        FontSize="0.75rem"
                                        Color="#A1A5AA"
                                    >
                                        {user.percent}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start">
                                    <Text
                                        Justify="center"
                                        BorderRadius="32px"
                                        Border={styles.Border}
                                        BGColor={styles.BGColor}
                                        Color={styles.Color}
                                        FontWeight="500"
                                        FontSize="0.75rem"
                                        Width="91px"
                                        FontFamily="Nunito"
                                        Padding="2px 8px"
                                    >
                                        {user.status}
                                    </Text>
                                </TableItem>
                                <TableItem Justify="start" Gap="24px">
                                    <GreenSwitch
                                        checked={activeStates[rowIndex]}
                                        onChange={() => handleToggleActive(rowIndex)}
                                    />
                                </TableItem>
                                <TableItem Justify="center" Gap="24px">
                                    <ContainerFlex
                                        Radius="32px"
                                        Border="1px solid #5A5AFF"
                                        Padding="8px 16px"
                                        Width="99px"
                                        Height="32px"
                                        Cursor="pointer"
                                        Gap="4px"
                                        onClick={handleRedirect}
                                    >
                                        <Image src={pencil} Cursor="pointer" alt="pencil-icone" />
                                        <Text FontWeight="500" Color="#5A5AFF" Cursor="pointer">
                                            {TEXT_GENERAL.EDIT}
                                        </Text>
                                    </ContainerFlex>
                                </TableItem>
                            </TableRowItems>
                        );
                    })}
                </TableItemsContainer>
            </Table>
        </ContainerFlex>
    );
};
