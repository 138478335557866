import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {URL} from '@config/constants/index';

import {STORAGE_TYPES} from '@components/LocationsBranch/Redux/types';
import {
    ILevel,
    IRow,
    IStorage,
    IStorageFloor,
    IWarehouse,
} from '@components/LocationsBranch/interfaces';
import {editWarehouse, IInventories} from '@components/LocationsBranch/Redux/interfaces';
import {getInitialFloor} from '@components/LocationsBranch/utils/warehouse';
import {STORAGE_STATUS, STORAGE_TYPE_LEVELS} from '@components/LocationsBranch/constants';

export const addWarehouses = (warehouses: IWarehouse[]) => ({
    type: STORAGE_TYPES.NEW_WAREHOUSE,
    payload: {
        warehouses,
    },
});

export const updateWarehouses = (warehouse: IWarehouse) => ({
    type: STORAGE_TYPES.UPDATE_WAREHOUSE,
    payload: {
        warehouse,
    },
});

export const getStorageStart = () => ({type: STORAGE_TYPES.START});

export const getStorageSuccess = (result: {data: AxiosResponse}) => ({
    type: STORAGE_TYPES.SUCCESS,
    savedStorage: result.data,
});

export const getStorageError = (error: AxiosError) => ({
    type: STORAGE_TYPES.ERROR,
    error: error,
});

export const setEditWarehouse = (modalProperties: editWarehouse) => {
    return {
        type: STORAGE_TYPES.SET_EDIT_STORAGE,
        payload: {
            editWarehouse: {
                ...modalProperties,
            },
        },
    };
};

const transformRows = (rows: IInventories[], parentLevelId: number, isPreload: boolean): IRow[] => {
    return rows.map(
        (row): IRow => ({
            id: row.storageId,
            name: row.storageName,
            productQuantity: parseInt(row.numberArticles.split(' ')[0], 10),
            parentLevel: parentLevelId,
            from: !isPreload ? STORAGE_STATUS.OLD : STORAGE_STATUS.NEW,
        })
    );
};

const transformFloors = (levels: IInventories[], isPreload: boolean): IStorageFloor[] => {
    let newFloors = levels.map(
        (level): IStorageFloor => ({
            id: level.storageId,
            name: level.storageName,
            from: !isPreload ? STORAGE_STATUS.OLD : STORAGE_STATUS.NEW,
            storageDescription: STORAGE_TYPE_LEVELS.LEVEL_ONE,
            productQuantity: parseInt(level.numberArticles.split(' ')[0], 10),
            levels: transformLevels(level.inventories, level.storageId, isPreload),
        })
    );

    if (newFloors.length === 0) {
        newFloors = getInitialFloor(1);
    }

    return newFloors;
};

const transformLevels = (
    levels: IInventories[],
    parentLevelId: number,
    isPreload: boolean
): ILevel[] => {
    const newLevels = levels.map(
        (level): ILevel => ({
            id: level.storageId,
            name: level.storageName,
            levelId: 2,
            storageDescription: STORAGE_TYPE_LEVELS.LEVEL_TWO,
            productQuantity: parseInt(level.numberArticles.split(' ')[0], 10),
            parentLevel: parentLevelId,
            showRowLevelInput: false,
            showNewLevel: false,
            from: !isPreload ? STORAGE_STATUS.OLD : STORAGE_STATUS.NEW,
            rows: transformRows(level.inventories, level.storageId, isPreload),
        })
    );

    return newLevels;
};

const transformStorageTypes = (
    inventories: IInventories[],
    parentLevelId: number,
    isPreload: boolean
): IStorage[] => {
    return inventories.map(
        (inventory): IStorage => ({
            id: inventory.storageId,
            name: inventory.storageName,
            from: !isPreload ? STORAGE_STATUS.OLD : STORAGE_STATUS.NEW,
            productQuantity: parseInt(inventory.numberArticles.split(' ')[0], 10),
            levelId: 1,
            parentLevel: parentLevelId,
            storageFloor: transformFloors(inventory.inventories, isPreload),
        })
    );
};

export const transformData = (data: IInventories[], isPreload: boolean = false): IWarehouse[] => {
    return data.map((item: IInventories): IWarehouse => {
        return {
            id: item.storageId,
            name: item.storageName,
            from: !isPreload ? STORAGE_STATUS.OLD : STORAGE_STATUS.NEW,
            storageDescription: STORAGE_TYPE_LEVELS.LEVEL_ZERO,
            storageTypes: transformStorageTypes(item.inventories, item.storageId, isPreload),
        };
    });
};

export const resetStorage = () => {
    return {
        type: STORAGE_TYPES.RESET_STORAGE,
    };
};

export const getStorages = (
    data: {
        LevelId: number;
    },
    token: string,
    filterStoradeId?: number,
    isPreload: boolean = false
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getStorageStart());
        const queryString = StringUtils.jsonToQueryString({...data});
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetInventaryStorage}${queryString}`,
                {headers}
            );
            let filteredStorages = response.data.data;
            if (filterStoradeId !== undefined) {
                filteredStorages = filteredStorages.filter(
                    (storage: IInventories) => storage.storageId === filterStoradeId
                );
            }

            const fromService = transformData(filteredStorages, isPreload);
            dispatch(getStorageSuccess(response.data));
            dispatch(addWarehouses(fromService));
        } catch (error) {
            dispatch(getStorageError(error as AxiosError));
        }
    };
};
