import styled from '@emotion/styled';

export const Sidebar = styled.div`
    position: fixed;
    top: 56px;
    right: 0;
    width: 25rem;
    height: calc(100vh - 92px);
    display: flex;
    flex-direction: column;
    background-color: #f4f5f5;
    box-shadow: 0px 0px 3px 0px rgba(167, 169, 172, 0.25);
`;

export const TopSection = styled.div`
    background-color: #fff;
    border-bottom: 1px solid #e8e9ea;
    display: flex;
    flex-direction: column;
`;

export const MiddleSection = styled.div`
    flex-grow: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 1rem 0.5rem;

    &::-webkit-scrollbar {
        width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;

export const BottomSection = styled.div`
    background-color: #fff;
    border-radius: 1rem 1rem 0px 0px;
    box-shadow: 0px -3px 26.6px 0px rgba(0, 0, 0, 0.06);
    position: sticky;
`;

export const CartTotals = styled.div`
    display: flex;
    border-bottom: 1px solid #e8e9ea;
    flex-direction: column;
    min-height: none;
    box-sizing: border-box;
    align-self: stretch;
    justify-content: center;
    padding: 0.5rem 1.5rem;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 0.25rem;
`;

export const renewalMenuStyles = {
    Padding: '0.5rem',
    Width: '100%',
    Align: 'center',
    FontSize: '1rem',
    FontWeight: '400',
    LetterSpacing: '0.019rem',
    Cursor: 'default',
    BorderL: '2px solid transparent',
    HColor: '#5A5AFF',
    HBackColor: '#F0F0FF',
    HBorderL: '2px solid #5A5AFF',
};
