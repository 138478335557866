import React, {useRef} from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {
    IAccordionProps,
    ISelectedPaymentMethod,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {
    AccordionStyled,
    AccordionDetailsStyled,
    AccordionSummaryStyled,
} from '@/components/PersonalLoans/styles';
import {useDispatch} from 'react-redux';
import {
    addSelectedPaymentMethodAction,
    removeSelectedPaymentMethodAction,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';

const Accordion: React.FC<IAccordionProps> = ({
    summaryIcon,
    summaryTitle,
    summaryParagraph,
    children,
    expanded,
    onChange,
    expandedIcon,
    collapsedIcon,
}) => {
    const detailsRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch();
    const handleExpandAccordion =
        (panelId: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            if (isExpanded) {
                const selectedPaymentMethod: ISelectedPaymentMethod = {
                    panelID: panelId,
                };
                dispatch(addSelectedPaymentMethodAction(selectedPaymentMethod));
            } else {
                dispatch(removeSelectedPaymentMethodAction());
            }

            onChange(event, isExpanded);
        };
    return (
        <AccordionStyled
            Width="100%"
            BorderColor={`${expanded ? '#5A5AFF' : '#E8E9EA'}`}
            BorderWidth="1px"
            expanded={expanded}
            onChange={handleExpandAccordion(summaryTitle)}
        >
            <AccordionSummaryStyled
                expandIcon={
                    <Image
                        src={expanded ? expandedIcon : collapsedIcon}
                        height="24px"
                        width="25px"
                        alt="expanded-icon"
                    />
                }
            >
                <ContainerFlex
                    Justify="start"
                    Align={summaryParagraph ? 'start' : 'center'}
                    Width="100%"
                    Gap="8px"
                >
                    <ContainerFlex
                        Width="44px"
                        Height="44px"
                        Justify="center"
                        Align="center"
                        backG="#F4F5F5"
                        Radius="50%"
                    >
                        <Image src={summaryIcon} width="24px" height="24px" alt="expanded-icon" />
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Height="100%"
                        Width="100%"
                        Gap="4px"
                        Align="start"
                        Justify="start"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                        >
                            {summaryTitle}
                        </Text>
                        {summaryParagraph && (
                            <Text
                                FontFamily="Nunito"
                                FontWeight="400"
                                FontSize="0.88rem"
                                Color="#54575C"
                            >
                                {summaryParagraph}
                            </Text>
                        )}
                    </ContainerFlex>
                </ContainerFlex>
            </AccordionSummaryStyled>
            <AccordionDetailsStyled>
                <ContainerFlex ref={detailsRef}>{children}</ContainerFlex>
            </AccordionDetailsStyled>
        </AccordionStyled>
    );
};

export default Accordion;
