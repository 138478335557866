import {TableRowItems} from '@/components/CashFlow/FundingInquiry/styles';
import {InputCheckbox} from '@/components/Loan/styles';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import roleArrowImg from '@/images/roleIcon_Arrow.svg';
import {tableRowItemProps} from '@components/RolesPermissions/NewRole/constants';
import {IModuleTree, IRoleModules} from '@components/RolesPermissions/NewRole/interfaces';
import React, {useEffect} from 'react';

const ModuleTree = ({
    modules,
    level,
    isExpand,
    totalModules,
    setModules,
    isEdit,
    listData,
    setListData,
    isAdmin,
    allModules,
    setIsAdmin,
    selectedRole,
    wasAssigned,
    setWasAssigned,
}: IModuleTree) => {
    function findParentOfItem(modules: IRoleModules[], targetId: number): IRoleModules | null {
        for (const module of modules) {
            const foundChild = module.nextLevel.find((child) => child.moduleId === targetId);

            if (foundChild) {
                return module;
            }
            const foundParent = findParentOfItem(module.nextLevel, targetId);
            if (foundParent) {
                return foundParent;
            }
        }

        return null;
    }

    const toggleChecked = (item: IRoleModules) => {
        if (!isAdmin) {
            const isSelected = listData.operationalModules.includes(item.moduleId);

            let updatedOperationalModules = isSelected
                ? listData.operationalModules.filter((moduleId) => moduleId !== item.moduleId)
                : [...listData.operationalModules, item.moduleId];

            if (item.nextLevel) {
                item.nextLevel.forEach((child) => {
                    updatedOperationalModules = checkAllChildren(
                        child,
                        !isSelected,
                        updatedOperationalModules
                    );
                });
            }

            const parent = findParentOfItem(allModules, item.moduleId);
            if (parent) {
                updatedOperationalModules = checkAllFather(parent, updatedOperationalModules);
            }

            setListData((prev) => ({
                ...prev,
                operationalModules: updatedOperationalModules,
            }));
        } else {
            const isSelected = listData.adminModules.includes(item.moduleId);
            let updatedAdminModules = isSelected
                ? listData.adminModules.filter((moduleId) => moduleId !== item.moduleId)
                : [...listData.adminModules, item.moduleId];

            if (item.nextLevel) {
                item.nextLevel.forEach((child) => {
                    updatedAdminModules = checkAllChildren(child, !isSelected, updatedAdminModules);
                });
            }

            const parent = findParentOfItem(allModules, item.moduleId);
            if (parent) {
                updatedAdminModules = checkAllFather(parent, updatedAdminModules);
            }

            setListData((prev) => ({
                ...prev,
                adminModules: updatedAdminModules,
            }));
        }
    };

    const checkAllChildren = (
        child: IRoleModules,
        isSelected: boolean,
        updatedModules: number[]
    ): number[] => {
        if (isSelected && !updatedModules.includes(child.moduleId)) {
            updatedModules = [...updatedModules, child.moduleId];
        } else if (!isSelected) {
            updatedModules = updatedModules.filter((id) => id !== child.moduleId);
        }

        if (child.nextLevel) {
            child.nextLevel.forEach((nextChild) => {
                updatedModules = checkAllChildren(nextChild, isSelected, updatedModules);
            });
        }

        return updatedModules;
    };

    const checkAllFather = (parent: IRoleModules, updatedModules: number[]): number[] => {
        const areSomeChecked = parent.nextLevel?.some((child) =>
            updatedModules.includes(child.moduleId)
        );

        if (areSomeChecked && !updatedModules.includes(parent.moduleId)) {
            updatedModules = [...updatedModules, parent.moduleId];
        } else if (!areSomeChecked) {
            updatedModules = updatedModules.filter((id) => id !== parent.moduleId);
        }

        const grandParent = findParentOfItem(allModules, parent.moduleId);
        if (grandParent) {
            updatedModules = checkAllFather(grandParent, updatedModules);
        }

        return updatedModules;
    };

    const toggleModuleOpen = (id: number) => {
        const toggleRecursively = (
            modules: IRoleModules[],
            targetId: number,
            toggleState?: boolean
        ): IRoleModules[] => {
            return modules.map((module) => {
                if (module.moduleId === targetId) {
                    const newToggleState = toggleState ?? !module.isOpen;
                    return {
                        ...module,
                        isOpen: newToggleState,
                        nextLevel: toggleRecursively(module.nextLevel, targetId, newToggleState),
                    };
                }
                return {
                    ...module,
                    nextLevel: module.nextLevel
                        ? toggleRecursively(module.nextLevel, targetId, toggleState)
                        : [],
                };
            });
        };

        setModules((prevModules) => toggleRecursively(prevModules, id));
    };

    const calculateMargin = (level: number) => {
        return level * 25;
    };

    useEffect(() => {
        if (selectedRole && isEdit && selectedRole.id !== 0) {
            setListData((prev) => {
                const updatedAdminModules =
                    prev.adminModules.length === 0 && !wasAssigned.admin
                        ? selectedRole.modules.adminModules.map((item) => {
                              setWasAssigned((prevState) => ({...prevState, admin: true}));
                              return item.moduleId;
                          })
                        : prev.adminModules;

                const updatedOperationalModules =
                    prev.operationalModules.length === 0 && !wasAssigned.operational
                        ? selectedRole.modules.operationalModules.map((item) => {
                              setWasAssigned((prev) => ({...prev, operational: true}));
                              return item.moduleId;
                          })
                        : prev.operationalModules;

                return {
                    ...prev,
                    adminModules: updatedAdminModules,
                    operationalModules: updatedOperationalModules,
                };
            });
        }
    }, [selectedRole]);

    return (
        <>
            {modules.map((i: IRoleModules) => {
                return (
                    <React.Fragment key={i.moduleId}>
                        <TableRowItems
                            {...tableRowItemProps}
                            BackGround={level !== 0 ? '#FAFAFA' : 'none'}
                        >
                            <ContainerFlex
                                Justify="start"
                                Width="80%"
                                MarginL={`${calculateMargin(level)}px`}
                            >
                                <ContainerFlex Width="8%">
                                    {i.nextLevel.length > 0 && (
                                        <Image
                                            src={i.nextLevel && roleArrowImg}
                                            Cursor="pointer"
                                            onClick={() => toggleModuleOpen(i.moduleId)}
                                            Transform={i.isOpen ? 'rotate(0.5turn)' : ''}
                                            alt="arrow"
                                        />
                                    )}
                                </ContainerFlex>
                                <ContainerFlex Justify="start" Gap="10px">
                                    {i.icon && <Image src={i.icon} alt="logo" width="20px" />}
                                    <Text FontWeight={i.nextLevel.length > 0 ? '700' : '400'}>
                                        {i.moduleName}
                                    </Text>
                                    {i.nextLevel.length > 0 && (
                                        <Text FontWeight={'700'}>
                                            {`${
                                                isAdmin
                                                    ? i.nextLevel?.filter((child) =>
                                                          listData.adminModules.includes(
                                                              child.moduleId
                                                          )
                                                      ).length
                                                    : i.nextLevel?.filter((child) =>
                                                          listData.operationalModules.includes(
                                                              child.moduleId
                                                          )
                                                      ).length
                                            } de ${i.nextLevel?.length}`}
                                        </Text>
                                    )}
                                </ContainerFlex>
                            </ContainerFlex>
                            <ContainerFlex>
                                <InputCheckbox
                                    type="checkbox"
                                    checked={
                                        !isAdmin
                                            ? listData.operationalModules.includes(i.moduleId)
                                            : listData.adminModules.includes(i.moduleId)
                                    }
                                    onChange={() => toggleChecked(i)}
                                    BackG="white"
                                    Border="1px solid #5A5AFF"
                                />
                            </ContainerFlex>
                        </TableRowItems>
                        {i.isOpen && i.nextLevel && (
                            <ModuleTree
                                level={level + 1}
                                modules={i.nextLevel}
                                isExpand={isExpand}
                                totalModules={totalModules}
                                setModules={setModules}
                                listData={listData}
                                setListData={setListData}
                                isAdmin={isAdmin}
                                allModules={allModules}
                                setIsAdmin={setIsAdmin}
                                selectedRole={selectedRole}
                                wasAssigned={wasAssigned}
                                setWasAssigned={setWasAssigned}
                            />
                        )}
                    </React.Fragment>
                );
            })}
        </>
    );
};

export default ModuleTree;
