import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {URL} from '@config/constants';
import {
    GET_ADDITIONAL_DOCUMENTS_START,
    GET_ADDITIONAL_DOCUMENTS_SUCCESS,
    GET_ADDITIONAL_DOCUMENTS_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';

export const fetchAdditionalDocuments = () => {
    return {
        type: GET_ADDITIONAL_DOCUMENTS_START,
    };
};

export const getAdditionalDocumentsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ADDITIONAL_DOCUMENTS_SUCCESS,
        payload: result.data,
    };
};

export const getAdditionalDocumentsError = (error: AxiosError) => {
    return {
        type: GET_ADDITIONAL_DOCUMENTS_ERROR,
        payload: error,
    };
};

export const getAdditionalDocuments = (token: string, PersonalInformationId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchAdditionalDocuments());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetAdditionalCreditDocuments}`,
                {headers, params: {PersonalInformationId}}
            );
            dispatch(getAdditionalDocumentsSuccess(response));
        } catch (error) {
            dispatch(getAdditionalDocumentsError(error as AxiosError));
        }
    };
};
