import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    ContainerFlex,
    ContainerForm,
    Text,
    Image,
    InputColor,
} from '@/components/Shopify/Ecommerce/styles';
import {schemaUploadAutomaticLoan} from '@components/PersonalLoans/schemaPersonalLoans';
import getAllCatBankAction from '@/components/PersonalLoans/Redux/Actions/GetAllCatBankActions';
import WalletIcon from '@components/PersonalLoans/icons/walletIcon.svg';
import {DefaultValuesLoans, STEPFOURPAYMENT} from './constants';
import {AppDispatch, RootState} from '@/config/store';
import {AutomaticLoanAcceptanceProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {postAutomaticLoanAcceptanceCommand} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/PostAutomaticLoanAcceptanceCommand';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import ArrowLeft from '@images/ArrowLeftBlue.svg';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import TransferPaymentDetails from '@components/PersonalLoans/TransferPaymentDetails';
import * as content from '@components/PersonalLoans/styles';

export const PersonalLoansStepFourPayment = () => {
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const IPersonalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId as number;
    });
    const dispatch: AppDispatch = useDispatch();
    const {data} = useSelector((state: RootState) => {
        return state.getAllCatBank;
    });
    const options =
        data?.map((bank) => ({
            label: bank.description,
            value: bank.id,
        })) ?? [];
    useEffect(() => {
        if (token) {
            dispatch(getAllCatBankAction(token));
        }
    }, []);
    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schemaUploadAutomaticLoan),
        defaultValues: DefaultValuesLoans,
    });
    const [showPayment, setShowPayment] = useState(0);
    const handleRadioChange = (value: number) => {
        setShowPayment(value);
    };
    const onSubmit = async (data: AutomaticLoanAcceptanceProps) => {
        const loansData = {...data, personalCreditApplicationId: IPersonalInformationId};
        dispatch(postAutomaticLoanAcceptanceCommand(loansData, token));
    };

    return (
        <ContainerForm onSubmit={handleSubmit(onSubmit)} {...content.ContainerPayment}>
            <Text Color="#1D1E20" FontWeight="600" FontSize="1.25rem">
                {STEPFOURPAYMENT.TITLE}
            </Text>
            <ContainerFlex {...content.ContainerWallet}>
                <ContainerFlex backG="#F4F5F5" Radius="3.75rem" Width="2.75rem" Height="2.75rem">
                    <Image alt="icon-payment" src={WalletIcon} />
                </ContainerFlex>
                <ContainerFlex Align="start" FlexDir="column" Justify="start" Gap="0.25rem">
                    <Text Color="#2A2C2F" FontWeight="700">
                        {STEPFOURPAYMENT.WALLET}
                    </Text>
                    <Text Color="#54575C" FontSize="0.875rem">
                        {STEPFOURPAYMENT.WALLETDES}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="end">
                    <InputColor
                        type="radio"
                        name="paymentOption"
                        onChange={() => handleRadioChange(1)}
                    />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex {...content.ContainerTypePayment}>
                <ContainerFlex Gap="0.5rem" Height="2.813rem" Justify="space-between">
                    <ContainerFlex {...content.IconWallet}>
                        <Image alt="icon-payment" src={WalletIcon} />
                    </ContainerFlex>
                    <ContainerFlex {...content.StepPaymentTypes}>
                        <Text Color="#2A2C2F" FontWeight="700">
                            {STEPFOURPAYMENT.TRANSFER}
                        </Text>
                        <Text Color="#54575C" FontSize="0.875rem">
                            {STEPFOURPAYMENT.TRANSFERDES}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="end">
                        <InputColor
                            type="radio"
                            name="paymentOption"
                            onChange={() => handleRadioChange(2)}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                {showPayment === STEPFOURPAYMENT.TWO && (
                    <TransferPaymentDetails control={control} errors={errors} options={options} />
                )}
            </ContainerFlex>
            <ContainerFlex {...content.ContainerBorderPayment} />
            <ContainerFlex {...content.ContainerPaymentForm}>
                <ContainerFlex Justify="start" Align="start" Gap="1rem">
                    <Controller
                        control={control}
                        name="acceptLoan"
                        render={({field}) => <Checkbox checked={field.value} {...field} />}
                    />
                    <ContainerFlex {...content.ContainerAdvise}>
                        <Text {...content.TextAdvise}>{STEPFOURPAYMENT.ADVISE}</Text>
                        <Text {...content.TextAdviseDescription}>{STEPFOURPAYMENT.ADVISEDES}</Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="space-between">
                <ButtonGenerals
                    {...content.ButtonBefore}
                    onClick={() => dispatch(ActualDataStepLoans(3))}
                >
                    <Image alt="icon-arrow" src={ArrowLeft} />
                    {STEPFOURPAYMENT.BEFORE}
                </ButtonGenerals>
                <ButtonGenerals {...content.ButtonValidate} type="submit">
                    {STEPFOURPAYMENT.VALIDATE}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerForm>
    );
};
