import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {IconContainer} from '@/components/Quoter/styles';
import {RootState} from '@/config/store';
import point from '@/images/bluePoint.svg';
import {TableRowItems} from '@CashFlow/FundingInquiry/styles';
import {
    BackGroundSelector,
    DinamicBorder,
    DinamicPadding,
} from '@components/RequestNewCatalogLevel/constanst';
import {MAXIMUN_LEVEL} from '@components/RequestNewCatalogLevel/operational/constant';
import {idSelected} from '@components/RequestNewCatalogLevel/Redux/Actions/AddNewLevelReducerAction';
import {
    getSelectedGroupNode,
    getSelectedGroupNodeLevel,
    getSelectedGroupNodeName,
} from '@components/RequestNewCatalogLevel/Redux/Actions/GetSelectGroup';
import {ChangeStatusLevel} from '@components/RequestNewCatalogLevel/Redux/Actions/PostChangeStatusLevel';
import {TableItem} from '@DeadLines/styles';
import {Image, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {ICatalogItems, ISentAuctionData} from '@components/AuctionPass/interfaces';
import {getAuctionFamilyAction} from '@components/AuctionPass/Redux/Actions/GetFamilyAction';

export const TreeComponent = (data: ISentAuctionData) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: ICatalogItems, index: number) => {
                    return (
                        <TreeNode
                            node={tree}
                            level={data.level + 1}
                            isView={data.isView}
                            fatherId={data.fatherId}
                            key={index}
                        />
                    );
                })}
        </>
    );
};

const TreeNode = (node: {
    node: ICatalogItems;
    level: number;
    isView: boolean;
    fatherId: number;
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [nextChild, setNextChild] = useState(false);
    const [status, setState] = useState(node.node.status);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );
    const sendName =
        node.node.groupName ||
        node.node.familyName ||
        node.node.subFamilyName ||
        node.node.brandName ||
        node.node.itemName;

    const ChangeStatus = () => {
        const newStatus = !status;
        setState(newStatus);

        if (token && companyId) {
            const statusChangePayload = {
                level: node.level - 1,
                companyId,
                categoryId: node.node.id,
            };
            dispatch(ChangeStatusLevel(token, statusChangePayload));
        }
    };
    const IdSelected = useSelector((state: RootState) => state.AddNewLevel);

    const selectNode = () => {
        if (companyId) {
            dispatch(getAuctionFamilyAction(node.node.id, companyId, token));
            dispatch(idSelected(node.node.id));
            dispatch(getSelectedGroupNode(node.node.children));
            dispatch(getSelectedGroupNodeName(sendName));
            dispatch(getSelectedGroupNodeLevel(node.level));
            navigate('/RequestNewCatalogLevel');
        }
    };

    useEffect(() => {
        setState(node.node.status);
    }, [node.node.status]);
    useEffect(() => {
        if (
            node.level === 1 &&
            IdSelected.id === node.node.id &&
            !node.node.children &&
            token &&
            !node.isView &&
            companyId
        ) {
            dispatch(getAuctionFamilyAction(node.node.id, companyId, token));
        }
    }, [IdSelected.id, dispatch]);
    useEffect(() => {
        const levelMap = new Map<number, keyof typeof IdSelected.breadCrumb>([
            [1, 'groupId'],
            [2, 'familyId'],
            [3, 'subFamilyId'],
            [4, 'brandId'],
        ]);
        const breadCrumbKey = levelMap.get(node.level);

        if (breadCrumbKey && IdSelected.breadCrumb[breadCrumbKey] === node.node.id) {
            dispatch(idSelected(node.node.id));
            setNextChild(true);
        }
    }, [IdSelected.breadCrumb, dispatch]);
    return (
        <>
            <TableRowItems
                GridColumn="3fr 1fr 1fr"
                Cursor="auto"
                Height="40px"
                Width="100%"
                BackGH=""
                Border=""
                BorderB={DinamicBorder(node.level)}
                BorderRadius=""
                BackGround={BackGroundSelector(node.level)}
            >
                <TableItem
                    Width="16rem"
                    Justify="start"
                    PaddingLeft={DinamicPadding(node.level)}
                    Gap="16px"
                    Filter={status ? '' : '0.5'}
                >
                    {node.level !== MAXIMUN_LEVEL && (
                        <IconContainer
                            className="material-icons"
                            FontSize="1.5rem"
                            Color={nextChild ? '#5A5AFF' : '#54575C'}
                            Justify="start"
                            onClick={() => {
                                dispatch(idSelected(node.node.id));
                                setNextChild(!nextChild);
                            }}
                            Cursor="pointer"
                        >
                            {nextChild ? 'keyboard_arrow_up' : 'keyboard_arrow_right'}
                        </IconContainer>
                    )}
                    {node.level === MAXIMUN_LEVEL && (
                        <Image alt="point" src={point} Opacity={node.node.status ? '' : '0.5'} />
                    )}

                    <TextEllipsis
                        FontWeight="500"
                        FontSize="0.875rem"
                        Color="#5A5AFF"
                        Cursor="pointer"
                        onClick={() => {
                            selectNode();
                        }}
                    >
                        {sendName}
                    </TextEllipsis>
                </TableItem>
                <TableItem
                    Padding={node.level ? '0 0 0 16px' : '0 0 0 16px'}
                    Filter="grayscale(100%)"
                >
                    <Text FontWeight="400" FontSize="0.875rem" Color="#414042">
                        {node.node.itemsCount}
                    </Text>
                </TableItem>
                <TableItem Justify="end" Padding="0 8px">
                    <GreenSwitch checked={status} onChange={ChangeStatus} />
                </TableItem>
            </TableRowItems>
            {nextChild && node.node.children && (
                <TreeComponent
                    data={node.node.children}
                    level={node.level}
                    isView={node.isView}
                    fatherId={node.node.id}
                />
            )}
        </>
    );
};
