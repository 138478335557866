import {
    GET_BRANCH_TREE_REQUEST,
    GET_BRANCH_TREE_SOUCCES,
    GET_BRANCH_TREE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const GetBrancheTreeUser = (state = initialState, action) => {
    switch (action.type) {
        case GET_BRANCH_TREE_REQUEST:
            return {...state, loading: true};
        case GET_BRANCH_TREE_SOUCCES:
            return {...state, loading: false, userData: action.payload.data};
        case GET_BRANCH_TREE_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
