import {useDispatch, useSelector} from 'react-redux';
import {FormProvider, useForm, FieldValues} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {DateTime} from 'luxon';
import {AppDispatch, RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IDetailsFormProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {EditingButtonsForm} from '@components/PersonalLoans/NewLoanProduct/ui/EditingButtonsForm';
import {schemaProductDetails} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/schemaProductDetails';
import {IShowOptionalFields} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {BtnShowField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/BtnShowField';
import {TermNameField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/TermNameField';
import {DescriptionField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DescriptionField';
import {MinAmountField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/MinAmountField';
import {MaxAmountField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/MaxAmountField';
import {PorcentAnualRateField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/PorcentAnualRateField';
import {PeriodMaxField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/PeriodMaxField';
import {PeriodMinField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/PeriodMinField';
import {RequestChannelField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/RequestChannelField/RequestChannelField';
import {CountLimitLoansField} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/CountLimitLoansField';
import {DatePickerRange} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/DatePickerRange';
import {saveNewLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {GridContainer} from '@/components/CreditQuoter/styles';

export const DetailsForm: React.FC<IDetailsFormProps> = ({setIsEditing, isEditing}) => {
    const loanData = useSelector((state: RootState) => state.newLoanSubmittedData);
    const newLoanData = loanData.details;
    const dispatch: AppDispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const selectedChannels = newLoanData.requestChannel.filter(
        (item) => item.label !== 'Todos los canales'
    );
    const [showOptionalFields, setShowOptionalFields] = useState<IShowOptionalFields>({
        showMaxLoanTerm: newLoanData.periodMax.value !== 0,
        showNoLoanLimit: newLoanData.countLimitLoans !== 0,
    });
    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schemaProductDetails),
    });
    const {handleSubmit, reset} = formMethods;
    const onSubmit = (data: FieldValues) => {
        dispatch(
            saveNewLoanSubmittedData({
                ...loanData,
                details: {
                    ...newLoanData,
                    termName: data.termName,
                    description: data.description,
                    minAmount: data.minAmount,
                    maxAmount: data.maxAmount,
                    dateDueInit: data.dateDueInit,
                    dateDueEnd: data.dateDueEnd,
                    porcentAnualRate: data.porcentAnualRate,
                    periodMin: data.periodMin,
                    periodMax: data.periodMax,
                    requestChannel: data.requestChannel,
                    countLimitLoans: data.countLimitLoans,
                },
            })
        );
        setIsEditing(!isEditing);
    };
    useEffect(() => {
        reset({
            termName: newLoanData.termName,
            description: newLoanData.description,
            minAmount: newLoanData.minAmount,
            maxAmount: newLoanData.maxAmount,
            porcentAnualRate: newLoanData.porcentAnualRate,
            dateDueInit: newLoanData.dateDueInit,
            dateDueEnd: newLoanData.dateDueEnd,
            periodMin: newLoanData.periodMin,
            periodMax: newLoanData.periodMax,
            countLimitLoans: newLoanData.countLimitLoans,
            requestChannel: selectedChannels,
        });
    }, []);
    const initDate = DateTime.fromISO(newLoanData.dateDueInit as string).toJSDate();
    const endDate = newLoanData.dateDueEnd
        ? DateTime.fromISO(newLoanData.dateDueEnd as string).toJSDate()
        : null;

    return (
        <FormProvider {...formMethods}>
            <ContainerForm
                Gap="1.5rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                onSubmit={handleSubmit(onSubmit)}
            >
                <ContainerFlex FlexDir="column" Gap="1.5rem" Justify="start" Align="start">
                    <GridContainer
                        Gap="1.5rem"
                        GridColumns="repeat(3,1fr)"
                        Justify="start"
                        Align="start"
                    >
                        <TermNameField name="termName" />
                        <DescriptionField name="description" />
                    </GridContainer>

                    <ContainerFlex Gap="1.5rem" Justify="start" Align="start">
                        <MinAmountField name="minAmount" isSubmitted={isSubmitted} />
                        <MaxAmountField name="maxAmount" isSubmitted={isSubmitted} />
                        <DatePickerRange
                            nameInitialDate="dateDueInit"
                            nameEndDate="dateDueEnd"
                            defaultValue={initDate}
                            endDefaultValue={endDate}
                            isSubmitted={isSubmitted}
                        />
                    </ContainerFlex>

                    <ContainerFlex Gap="1.5rem" Justify="start" Align="start">
                        <PorcentAnualRateField name="porcentAnualRate" />
                        <PeriodMinField name="periodMin" isSubmitted={isSubmitted} />
                        {showOptionalFields.showMaxLoanTerm ? (
                            <PeriodMaxField
                                name="periodMax"
                                showOptionalFields={showOptionalFields}
                                setShowOptionalFields={setShowOptionalFields}
                                isSubmitted={isSubmitted}
                            />
                        ) : (
                            <BtnShowField
                                text={LOAN_DETAILS.FIELDS.MAX_TERM_BTN}
                                width={'100%'}
                                onHandleClick={() =>
                                    setShowOptionalFields({
                                        ...showOptionalFields,
                                        showMaxLoanTerm: true,
                                    })
                                }
                            />
                        )}
                    </ContainerFlex>
                    <GridContainer
                        GridColumns="repeat(3,1fr)"
                        Gap="1.5rem"
                        Justify="start"
                        Align="start"
                    >
                        <RequestChannelField name="requestChannel" />
                        {showOptionalFields.showNoLoanLimit ? (
                            <CountLimitLoansField
                                name="countLimitLoans"
                                showOptionalFields={showOptionalFields}
                                setShowOptionalFields={setShowOptionalFields}
                            />
                        ) : (
                            <BtnShowField
                                text={LOAN_DETAILS.FIELDS.NO_LOANS_LIMIT_BTN}
                                width="max-content"
                                onHandleClick={() =>
                                    setShowOptionalFields({
                                        ...showOptionalFields,
                                        showNoLoanLimit: true,
                                    })
                                }
                            />
                        )}
                    </GridContainer>
                </ContainerFlex>
                <EditingButtonsForm
                    onCancel={() => setIsEditing(!isEditing)}
                    onContinue={() => setIsSubmitted(!isSubmitted)}
                />
            </ContainerForm>
        </FormProvider>
    );
};
