import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, ContainerForm, Text} from '@components/Shopify/Ecommerce/styles';
import {IBreadcrumbItem} from '@components/General/Interfaces/IBreadcrumb';
import Breadcrumb from '@components/General/Moleculs/Breadcrumb/Index';
import {ImagesAuction} from '@components/AuctionPass/ImageAuction';
import {FormAuctionInputs} from '@components/AuctionPass/FormInputs';
import {SaleInputs} from '@components/AuctionPass/SalesInputs';
import {ProductFormValues} from '@components/AuctionPass/interface';
import {schemaArticle} from '@components/AuctionPass/SchemaArticle';
import ButtonGeneral from '@components/General/Atoms/Button/index';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {SaleChannel} from '@components/AuctionPass/SaleChannel';
import {PublicationAuction} from '@components/AuctionPass/MessageTool';
import {defaultValues, TITLES} from '@/components/AuctionPass/AuctionConstants';

export const AuctionDetails = () => {
    const itemsBreadcrumb: Array<IBreadcrumbItem> = [
        {to: '/BranchCreation', label: 'Creación de sucursales'},
        {to: '/BranchCreation/ComercialCicle', label: 'Ciclo comercial'},
        {to: '/BranchCreation/ComercialCicle/AuctionDetails', label: 'Almoneda'},
    ];
    const [show, setShow] = useState(true);
    const {
        register,
        handleSubmit,
        formState: {errors},
        control,
    } = useForm<ProductFormValues>({
        defaultValues,
        resolver: yupResolver(schemaArticle),
    });
    const onSubmit = (data: ProductFormValues) => {};
    return (
        <ContainerForm
            Gap="16px"
            onSubmit={handleSubmit(onSubmit)}
            FlexDir="column"
            Height=""
            Justify="start"
            Align="start"
        >
            <ContainerFlex
                Height="100%"
                OverFlowY="scroll"
                backG="#FAFAFA"
                Justify="start"
                Align="start"
            >
                <ContainerFlex
                    Padding="32px"
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="16px"
                    Width="70%"
                    backG="white"
                >
                    <Breadcrumb items={itemsBreadcrumb} />
                    <ContainerFlex Justify="start" Align="start" Height="1.5rem">
                        <Text FontSize="1.5rem" FontWeight="700">
                            {TITLES.HEADER}
                        </Text>
                    </ContainerFlex>

                    <ImagesAuction />
                    <ContainerFlex Justify="start" Align="start" Height="1rem">
                        <Text Color="#0D166B">{TITLES.BREAD_CRUMBS}</Text>
                    </ContainerFlex>
                    <FormAuctionInputs register={register} errors={errors} control={control} />
                    <SaleInputs register={register} errors={errors} control={control} />
                    <SaleChannel register={register} errors={errors} control={control} />
                </ContainerFlex>
                <ContainerFlex
                    Padding="32px"
                    backG="#FAFAFA"
                    Align="start"
                    Justify="start"
                    FlexDir="column"
                    Width="30%"
                >
                    {show && <PublicationAuction setShow={setShow} />}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Height="72px"
                Position="sticky"
                ColumnGap="32px"
                PositionBottom="0"
                Bt="1px solid #E8E9EA"
                backG="#FFFFFF"
                BoxShadow=" 0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
            >
                <ButtonGenerals Color="#FF6357" BackGC="#FFFFFF" HBackG="" Width="10.625rem">
                    {TITLES.CANCEL}
                </ButtonGenerals>
                <ButtonGeneral text={TITLES.SAVE} type="submit" width="10.625rem" />
            </ContainerFlex>
        </ContainerForm>
    );
};
