import {
    GET_ALL_MODULES,
    GET_ALL_MODULES_ERROR,
    GET_ALL_MODULES_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import {AxiosError, AxiosResponse} from 'axios';

const initialState = {
    loading: false,
    error: false,
    modules: [],
};

const GetAllModulesReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
    }
) => {
    switch (action.type) {
        case GET_ALL_MODULES:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_ALL_MODULES_SUCCESS:
            return {
                ...state,
                loading: false,
                modules: action.payload.data.data,
            };
        case GET_ALL_MODULES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default GetAllModulesReducer;
