import React from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {INHERIT_FROM} from '@components/LocationsBranch/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {IInheritActionButtonsProps} from '@components/LocationsBranch/interfaces';

export const InheritActionButtons: React.FC<IInheritActionButtonsProps> = ({onCancel, onSave}) => {
    return (
        <ContainerFlex
            Width="352px"
            Justify="center"
            Align="center"
            Gap="24px"
            Height="fit-content"
        >
            <ButtonGenerals
                Width="100%"
                Height="40px"
                Padding="8px 16px"
                BackGC="transparent"
                Justify="center"
                Color="#5A5AFF"
                HBackG="transparent"
                FontWeight="500"
                type="button"
                Border="1px solid #5A5AFF"
                onClick={onCancel}
                Cursor="pointer"
            >
                {INHERIT_FROM.CANCEL}
            </ButtonGenerals>
            <ButtonGenerals
                Width="100%"
                Height="40px"
                Padding="8px 16px"
                type="button"
                FontWeight="500"
                onClick={onSave}
                Cursor="pointer"
            >
                {INHERIT_FROM.TRANSFER}
            </ButtonGenerals>
        </ContainerFlex>
    );
};
