import React, {useState} from 'react';
import {FILTER_TEXTS} from '@components/AuctionPass/AuctionConstants';
import {IFormInputs} from '@components/AuctionPass/interfaces';
import {
    ContainerFlex,
    ContainerForm,
    Input,
    Text,
    Image,
} from '@components/Shopify/Ecommerce/styles';
import Redo from '@images/Redo.svg';
import CalendarDateSelection from '@/components/General/Atoms/CalendarDateSelection/CalendarDateSelection';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {
    CATEGORY_OPTIONS,
    initalState,
    validationSchema,
} from '@components/AuctionPass/Filters/SchemaFilter';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ClosedModalProps} from './interface';
import {Overlay, Modal} from '@/components/CashFlowActions/Operational/styles';

export const FilterForAuction = ({isOpen, closeModal}: ClosedModalProps) => {
    const [isPubliced, setIsPubliced] = useState(false);
    const [isPaused, setIsPaused] = useState(false);
    const [isReserved, setIsReserved] = useState(false);
    const onSubmit = (data: IFormInputs) => {
        closeModal();
    };
    const {
        register,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<IFormInputs>({
        defaultValues: initalState,
        resolver: yupResolver(validationSchema),
    });
    const handleClickOutside = (event: React.MouseEvent<HTMLDivElement>) => {
        if (event.target === event.currentTarget) {
            closeModal();
        }
    };
    return (
        <>
            <Overlay isVisible={isOpen} onClick={handleClickOutside} />
            <Modal IsVisible={isOpen} Justify="end" onClick={handleClickOutside}>
                <ContainerFlex Width="max-content">
                    <ContainerForm
                        Justify="start"
                        Align=""
                        background="white"
                        Padding="24px"
                        Border="1px solid #E8E9EA"
                        Width="28rem"
                        FlexDir="column"
                        Gap="24px"
                        Height="100vh"
                        onSubmit={handleSubmit(onSubmit)}
                        Radius="8px 0px 0px 8px"
                    >
                        <ContainerFlex Justify="space-between" Height="1.5rem">
                            <Text FontWeight="700" FontSize="1.75rem" MinH="1.5rem" Color="#5A5AFF">
                                {FILTER_TEXTS.TITLE}
                            </Text>
                            <ContainerFlex Justify="end" ColumnGap="8px" Width="50%">
                                <Image src={Redo} alt="redo" />
                                <Text FontWeight="700" MinH="1rem" Color="#5A5AFF">
                                    {FILTER_TEXTS.CLEAR}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                        <Text FontWeight="700" FontSize="1.25rem" MinH="2rem">
                            {FILTER_TEXTS.PUBLICATION_DATE.LABEL}
                        </Text>
                        <ContainerFlex
                            Justify="start"
                            Gap="4px"
                            Height="58px"
                            FlexDir="column"
                            Align="start"
                            Width="50%"
                        >
                            <Text FontWeight="700" MinH="1rem">
                                {FILTER_TEXTS.TITLE}
                            </Text>
                            <Controller
                                name="publicationDate"
                                control={control}
                                render={({field: {onChange, value, ...field}}) => (
                                    <CalendarDateSelection
                                        {...field}
                                        placeholder=" /  /"
                                        format="dd/MM/yyyy"
                                        error={!!errors.publicationDate?.message}
                                        limitDate={false}
                                        value={value ? new Date(value) : null}
                                        onSelect={(e) => {
                                            onChange(e);
                                        }}
                                    />
                                )}
                            />
                        </ContainerFlex>
                        <Text FontWeight="700" FontSize="1.25rem" MinH="2rem">
                            {FILTER_TEXTS.PRICE_RANGE.LABEL}
                        </Text>
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            Height="68px"
                            ColumnGap="16px"
                            Padding="0 50px 0 0"
                        >
                            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                                <Text FontWeight="700" MinH="1rem">
                                    {FILTER_TEXTS.PRICE_RANGE.FROM}
                                </Text>
                                <Input
                                    type="number"
                                    {...register('priceFrom')}
                                    error={errors.priceFrom?.message}
                                />
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                                {' '}
                                <Text FontWeight="700" MinH="1rem">
                                    {FILTER_TEXTS.PRICE_RANGE.TO}
                                </Text>
                                <Input
                                    type="number"
                                    {...register('priceTo')}
                                    error={errors.priceTo?.message}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                        <Text FontWeight="700" FontSize="1.25rem" MinH="2rem">
                            {FILTER_TEXTS.ARTICLE_STATUS.LABEL}
                        </Text>
                        <ContainerFlex Justify="start" Align="start" Height="24px" ColumnGap="16px">
                            <Checkbox
                                checked={isPubliced}
                                onChange={() => setIsPubliced(!isPubliced)}
                            />
                            <Text>{FILTER_TEXTS.ARTICLE_STATUS.PUBLISHED}</Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" Height="24px" ColumnGap="16px">
                            <Checkbox checked={isPaused} onChange={() => setIsPaused(!isPaused)} />
                            <Text>{FILTER_TEXTS.ARTICLE_STATUS.PAUSED}</Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" Height="24px" ColumnGap="16px">
                            <Checkbox
                                checked={isReserved}
                                onChange={() => setIsReserved(isReserved)}
                            />
                            <Text>{FILTER_TEXTS.ARTICLE_STATUS.RESERVED}</Text>
                        </ContainerFlex>
                        <Text FontWeight="700" FontSize="1.25rem" MinH="2rem">
                            {FILTER_TEXTS.CATEGORY.LABEL}
                        </Text>
                        <ContainerFlex Padding="0 50px 0 0" ColumnGap="16px">
                            <ContainerFlex
                                FlexDir="column"
                                Justify="start"
                                Align="start"
                                Gap="4px"
                                ColumnGap="16px"
                            >
                                <Text FontWeight="700" MinH="1rem">
                                    {FILTER_TEXTS.CATEGORY.CATEGORY_PLACEHOLDER}
                                </Text>
                                <Controller
                                    name="category"
                                    control={control}
                                    render={({field: {onChange, ...field}}) => (
                                        <SelectGeneral
                                            {...field}
                                            placeholder={FILTER_TEXTS.SELECT}
                                            isSearchable={false}
                                            hasError={!!errors.category?.label?.message}
                                            options={CATEGORY_OPTIONS}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                                <Text FontWeight="700" MinH="1rem">
                                    {FILTER_TEXTS.CATEGORY.SUBCATEGORY_PLACEHOLDER}
                                </Text>
                                <Controller
                                    name="subCategory"
                                    control={control}
                                    render={({field: {onChange, ...field}}) => (
                                        <SelectGeneral
                                            {...field}
                                            placeholder={FILTER_TEXTS.SELECT}
                                            isSearchable={false}
                                            hasError={!!errors.subCategory?.label?.message}
                                            options={CATEGORY_OPTIONS}
                                            onChange={(e) => {
                                                onChange(e);
                                            }}
                                        />
                                    )}
                                />
                            </ContainerFlex>
                        </ContainerFlex>
                        <ContainerFlex Align="end" Height="171px">
                            <ContainerFlex Width="50%" Height="40px">
                                <Text onClick={handleClickOutside} Color="#FF6357" Cursor="pointer">
                                    {FILTER_TEXTS.BUTTONS.CANCEL}
                                </Text>
                            </ContainerFlex>
                            <ButtonGeneral width="50%" text={FILTER_TEXTS.BUTTONS.APPLY} />
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
