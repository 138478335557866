import React, {ChangeEvent, useState, useRef, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@config/store';
import {addFinger} from '@ListClient/redux/Actions/newClientProcessAction';

import * as content from '@components/CreateUsers/Styles';
import {
    FingerPrintSelectorProps,
    ClientFingerprints,
    HandFingerprintImages,
    FingerData,
    HANDS,
} from '@ListClient/interfaces';
import {IFingerData} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import SelectFingerprint from '@ListClient/FingerprintRegistration/SelectFingerprint';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import {
    FingersOnHand,
    getCurrentSelection,
    findFingerId,
    FINGERPRINT_SELECTION,
} from '@ListClient/constants';

const FingerPrintSelector: React.FC<FingerPrintSelectorProps> = ({
    selectedFinger,
    errors,
    setValue,
    clientEditId,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [imageFileName, setImageFileName] = useState('');
    const [fileSizeInMB, setFileSizeInMB] = useState('');
    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const newClientId = useSelector((state: RootState) => state.newClientProcess.newClientId);
    const clientFingerprints: ClientFingerprints = useSelector(
        (state: RootState) => state.newClientProcess.fingerprints
    );
    const fingers = useSelector((state: RootState) => state.getHandsFingers);
    const clientDetails = useSelector((state: RootState) => state.clientDetails.clientDetails);
    const fingerprintData = clientDetails?.fingers ?? null;

    useEffect(() => {
        const {hand, finger} = selectedFinger;
        const currentSelection =
            hand === HANDS.LEFT ? clientFingerprints.left : clientFingerprints.right;
        const fingerKey = getCurrentSelection(
            finger as keyof typeof FingersOnHand
        ) as keyof HandFingerprintImages;

        const fingerData = currentSelection?.[fingerKey];
        let existingFingerData: IFingerData | null = null;
        if (fingerprintData) {
            const selectedFingerId = findFingerId(fingers.fingerData, selectedFinger);
            existingFingerData =
                fingerprintData.find((fingerprint) => fingerprint.fingerId === selectedFingerId) ||
                null;
        }
        if (fingerData || existingFingerData) {
            setImageSrc(fingerData?.imageSrc || existingFingerData?.fingerURL || null);
            setImageFileName(fingerData?.imageFileName || `${finger}: ${hand}`);
            setFileSizeInMB(fingerData?.fileSizeInMB || '');
            setValue('fingerPrint', fingerData?.imageBase64 || existingFingerData?.fingerURL || '');
        } else {
            clearImageSelection();
        }
    }, [selectedFinger, clientFingerprints]);

    useEffect(() => {
        setErrorMessage(errors.fingerPrint ? (errors.fingerPrint?.message as string) : '');
    }, [errors.fingerPrint]);

    const handleButtonClick = () => {
        fileInputRef.current?.click();
    };
    const clearImageSelection = () => {
        setImageSrc(null);
        setImageFileName('');
        setFileSizeInMB('');
        setValue('fingerPrint', '');
        setValue('imageFileExtension', '');
    };

    const imgAdd = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            if (!fileExtension || !['jpg', 'png'].includes(fileExtension)) {
                setErrorMessage('Imagen no es valida (.png, .jpg). Por favor, intenta nuevamente.');
                clearImageSelection();
                return;
            }

            const fileSizeInBytes = file.size;
            setFileSizeInMB((fileSizeInBytes / (1024 * 1024)).toFixed(2));

            setErrorMessage('');
            setImageFileName(file.name);
            const readFileAsDataURL = (file: File): Promise<string> => {
                return new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result as string);
                    reader.onerror = (error) => reject(error);
                });
            };
            try {
                const fileContent = await readFileAsDataURL(file);
                const base64Content = fileContent.split(',')[1];
                const fingerName = getCurrentSelection(selectedFinger.finger);
                const newFingerprints = {
                    ...clientFingerprints,
                    [selectedFinger.hand === HANDS.LEFT ? 'left' : 'right']: {
                        ...clientFingerprints[
                            selectedFinger.hand === HANDS.LEFT ? 'left' : 'right'
                        ],
                        [fingerName]: {
                            imageSrc: fileContent,
                            imageBase64: base64Content,
                            imageFileName: file.name,
                            fileSizeInMB: (fileSizeInBytes / (1024 * 1024)).toFixed(2),
                            fileExtension,
                        },
                    },
                };

                const fingerData: FingerData = {
                    data: newFingerprints,
                    hand: selectedFinger.hand,
                    finger: fingerName,
                    fingerId: findFingerId(fingers.fingerData, selectedFinger) as number,
                    clientId: clientEditId ? clientEditId : newClientId,
                };
                dispatch(addFinger(fingerData, token));

                setImageSrc(fileContent);
                setValue('fingerPrint', base64Content);
                setValue('imageFileExtension', fileExtension);
            } catch (error) {
                setErrorMessage(
                    errors.fingerPrint
                        ? (errors.fingerPrint?.message as string)
                        : FINGERPRINT_SELECTION.PLEASE_TRY_AGAIN
                );
                clearImageSelection();
            }
        }
    };

    return (
        <ContainerFlex
            Padding="0.813rem 0.813rem"
            Gap="0.75rem"
            FlexDir="column"
            Justify="center"
            Align="center"
            backG="#FFF"
            Radius="0.813rem"
        >
            <ContainerFlex
                Justify="center"
                Align="center"
                FlexDir="column"
                Gap="0.5rem"
                Padding="0.188rem 0.5rem 0.438rem"
            >
                <FileUpLoad accept=".jpg,.png" type="file" onChange={imgAdd} ref={fileInputRef} />
                <FileUpLoadContent
                    Margin="auto"
                    Width="10.813rem"
                    Height="14.063rem"
                    RABorder="0.5rem"
                    Display="flex"
                    Align="center"
                    Border="1px solid #D4D6D8"
                    Cursor="auto"
                    BackG="transparent"
                >
                    {imageSrc && (
                        <Image
                            alt="fingerprint"
                            src={imageSrc}
                            {...content.contentImg.imgStart}
                            Height="100%"
                            Width="100%"
                            ObjectFit="contain"
                            Radius="0"
                            Margin="0"
                        />
                    )}
                </FileUpLoadContent>

                <Text
                    Color="red"
                    FontWeight="400"
                    FontSize="0.875rem"
                    Margin="auto"
                    wSpace="normal"
                >
                    {errorMessage as string}
                </Text>
            </ContainerFlex>
            <SelectFingerprint
                selectedFinger={selectedFinger}
                fileSizeInMB={fileSizeInMB}
                imageFileName={imageFileName}
                imageSrc={imageSrc}
                onSelectClick={handleButtonClick}
            />
        </ContainerFlex>
    );
};
export default FingerPrintSelector;
