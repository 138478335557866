import React, {useState} from 'react';
import {ContainerFlex, Text, Image, ContainerGrid} from '@/components/Shopify/Ecommerce/styles';
import {LOANSLIST, NEW_PRODUCT_URL} from '@components/LoansProducts/constants';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import ProductsTable from '@components/LoansProducts/ProductsTable';
import {IProductsLoansProps} from '@components/LoansProducts/interfaces';
import StatusFilter from '@components/LoansProducts/StatusFilter';
import SearchProductsLoans from '@components/LoansProducts/SearchProducts';
import Add from '@images/add - plus.svg';
import ProductImage from '@images/ProductsAtaskate.svg';
import FilterIcon from '@images/filter.svg';
import {useNavigate} from 'react-router-dom';
import {resetTermPersonalLoan} from '@components/LoansProducts/Redux/Actions/GetTermPersonalLoan';
import {setIsEditingLoan} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/isEditingLoanStateActions';

export const ProductsLoans = ({
    setShowActionModal,
    setShowArchiveModal,
    setShowModal,
}: IProductsLoansProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const enterpriceId = useSelector((state: RootState) =>
        Number(state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId)
    );
    const {data, searchResults, message, loading} = useSelector(
        (state: RootState) => state.getProductsCustomerUserReducer
    );
    const [isMessage, setIsMessage] = useState(false);

    const addNewProductHandler = () => {
        dispatch(resetTermPersonalLoan());
        dispatch(setIsEditingLoan(false));
        navigate(NEW_PRODUCT_URL);
    };

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Padding="1rem 1.5rem"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Gap="1.5rem"
            Position="relative"
        >
            <ContainerFlex Justify="space-between" Align="center" MinH="40px" Height="40px">
                <Text Color="#1D1E20" FontSize="1.5rem" FontWeight="700">
                    {LOANSLIST.PRODCUTS}
                </Text>
                <SearchProductsLoans
                    enterpriceId={enterpriceId}
                    token={token}
                    searchResults={searchResults}
                    message={message}
                    loading={loading}
                    setIsMessage={setIsMessage}
                />
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Height="48px">
                <StatusFilter />
                <ContainerFlex
                    Justify="center"
                    Border="1px solid #5A5AFF"
                    Radius="32px"
                    Width="120px"
                    Height="32px"
                    Padding="4px 16px"
                    Cursor="pointer"
                    onClick={() => setShowModal(true)}
                >
                    <Image
                        alt="icon-filter"
                        src={FilterIcon}
                        width="24px"
                        height="24px"
                        Cursor="pointer"
                    />
                    <Text Color="#5A5AFF" FontSize="0.875rem" FontWeight="700" Cursor="pointer">
                        {LOANSLIST.FILT}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start">
                <ContainerGrid
                    GridColumns="2fr 2fr 2fr 2fr 2fr 1.29fr"
                    Height="2.625rem"
                    Padding="0.5rem 1rem"
                    Gap="1rem"
                    BorderBt="1px solid #E8E9EA"
                >
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.NAME}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MIN}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MAX}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MINTIME}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.MAXTIME}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontWeight="700">
                        {LOANSLIST.ACTIVE}
                    </Text>
                </ContainerGrid>
                {data.length > 0 ? (
                    isMessage === true && message.length > 0 ? (
                        <ContainerFlex Height="20rem" FlexDir="column" Gap="0.5rem">
                            <Image src={ProductImage} alt="image" />
                            <ContainerFlex Height="1.5rem" Gap="0.25rem">
                                <Text Color="#2A2C2F">{message}</Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    ) : (
                        <ProductsTable
                            dataTable={data}
                            setShowActionModal={setShowActionModal}
                            setShowArchiveModal={setShowArchiveModal}
                        />
                    )
                ) : (
                    <ContainerFlex FlexDir="column" Gap="0.5rem">
                        <Image src={ProductImage} alt="image" />
                        <ContainerFlex
                            Height="1.5rem"
                            Gap="0.25rem"
                            Cursor="pointer"
                            onClick={addNewProductHandler}
                        >
                            <Image src={Add} alt="add" Cursor="pointer" />
                            <Text
                                FontSize="0.875rem"
                                FontWeight="700"
                                Color="#5A5AFF"
                                Cursor="pointer"
                            >
                                {LOANSLIST.CREATE}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
