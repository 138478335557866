import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    GET_ROLES,
    GET_ROLES_ERROR,
    GET_ROLES_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const getRoles = () => {
    return {
        type: GET_ROLES,
    };
};

export const getRolesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ROLES_SUCCESS,
        payload: result,
    };
};

export const getRolesError = (error: AxiosError) => {
    return {
        type: GET_ROLES_ERROR,
        payload: error,
    };
};

type IRolesData = {
    searchRoleName?: string;
    enterpriseId: number;
    filterRoles: number;
};

export const GetRolesAction = (data: IRolesData, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getRoles());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRolesAndPermissions}`;
        try {
            const response = await axios.post(url, data, {headers});
            dispatch(getRolesSuccess(response));
        } catch (error) {
            dispatch(getRolesError(error as AxiosError));
        }
    };
};

export default GetRolesAction;
