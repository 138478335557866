import React, {useState} from 'react';
import {INewProductClientsAndDocumentsProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/clientInterfaces';
import {ClientAndDocumentsPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/Preview/ClientAndDocumentsPreview';
import {ClientsAndDocumentsEditingForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/EditingForm/ClientsAndDocumentsEditingForm';

export const NewProductClientsAndDocuments: React.FC<INewProductClientsAndDocumentsProps> = ({
    clientAndDocumentsRef,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    return (
        <>
            {isEditing ? (
                <ClientsAndDocumentsEditingForm
                    clientAndDocumentsRef={clientAndDocumentsRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <ClientAndDocumentsPreview
                    clientAndDocumentsRef={clientAndDocumentsRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </>
    );
};
