import {AppDispatch} from '@/config/store';
import {
    editFloorSchema,
    editLevelSchema,
    editRowSchema,
    editStorageTypeSchema,
    editWarehouseSchema,
} from '@components/LocationsBranch/yupEditWarehouse';
import {
    setEditWarehouse,
    updateWarehouses,
} from '@components/LocationsBranch/Redux/Action/Warehouse';

import {
    IEditWarehouseForm,
    IGetEditModalConfig,
    IStorage,
} from '@components/LocationsBranch/interfaces';
import {FROM_SECTION} from '@components/LocationsBranch/constants';

import {UseFormReset, UseFormWatch} from 'react-hook-form';

export const getEditModalConfig = ({editWarehouse, warehouses}: IGetEditModalConfig) => {
    const {fromSection, warehouse, storageType, storageFloor, levelId, selectedRow} = editWarehouse;

    if (!fromSection || !warehouse)
        return {
            initialName: '',
            schema: editWarehouseSchema(warehouses, ''),
            submit: () => {},
            defaultValues: {
                warehouseName: '',
            },
        };
    const CONFIG_MAP = {
        [FROM_SECTION.WAREHOUSE]: () => ({
            initialName: warehouse ? warehouse.name : '',
            schema: editWarehouseSchema(warehouses, warehouse.name),
            defaultValues: {
                warehouseName: warehouse ? warehouse.name : '',
            },
            submit: (
                watch: UseFormWatch<IEditWarehouseForm>,
                dispatch: AppDispatch,
                reset: UseFormReset<IEditWarehouseForm>
            ) => {
                const name = watch('warehouseName');
                const newWarehouse = {...warehouse, name};
                dispatch(updateWarehouses(newWarehouse));
                dispatch(
                    setEditWarehouse({warehouse: null, fromSection: '', showEditModal: false})
                );

                reset({warehouseName: ''});
            },
        }),
        [FROM_SECTION.STORAGE_TYPE_NAME]: () => ({
            initialName: storageType ? storageType.name : '',
            schema: editStorageTypeSchema(warehouse.storageTypes || [], warehouse.name),
            defaultValues: {
                warehouseName: storageType ? storageType.name : '',
            },
            submit: (
                watch: UseFormWatch<IEditWarehouseForm>,
                dispatch: AppDispatch,
                reset: UseFormReset<IEditWarehouseForm>
            ) => {
                const name = watch('warehouseName');
                const newStorageType: IStorage = {
                    ...storageType,
                    name,
                    id: storageType?.id || 0,
                    levelId: storageType?.levelId || 0,
                    parentLevel: storageType?.parentLevel || 0,
                    productQuantity: storageType?.productQuantity || 0,
                    storageFloor: storageType?.storageFloor || [],
                };
                const newWarehouse = {
                    ...warehouse,
                    storageTypes: warehouse.storageTypes?.map((storage) =>
                        storage.id === storageType?.id ? newStorageType : storage
                    ),
                };
                dispatch(updateWarehouses(newWarehouse));
                dispatch(
                    setEditWarehouse({warehouse: null, fromSection: '', showEditModal: false})
                );

                reset({warehouseName: ''});
            },
        }),
        [FROM_SECTION.FLOOR_NAME]: () => ({
            initialName: storageFloor?.name ?? '',
            schema: editFloorSchema(storageType, storageFloor?.name ?? ''),
            defaultValues: {
                warehouseName: storageFloor?.name ?? '',
            },
            submit: (
                watch: UseFormWatch<IEditWarehouseForm>,
                dispatch: AppDispatch,
                reset: UseFormReset<IEditWarehouseForm>,
                setSelectedStorage?: React.Dispatch<React.SetStateAction<IStorage | null>>
            ) => {
                const name = watch('warehouseName');
                const newFloor = {
                    ...storageFloor,
                    name,
                };
                const newStorageType: IStorage = {
                    ...storageType,
                    storageFloor: (storageType?.storageFloor ?? []).map((floor) =>
                        floor.id === storageFloor?.id ? newFloor : floor
                    ),
                };
                const newWarehouse = {
                    ...warehouse,
                    storageTypes: warehouse.storageTypes?.map((storage) =>
                        storage.id === storageType?.id ? newStorageType : storage
                    ),
                };
                if (setSelectedStorage) {
                    setSelectedStorage(newStorageType);
                }
                dispatch(updateWarehouses(newWarehouse));
                dispatch(
                    setEditWarehouse({warehouse: null, fromSection: '', showEditModal: false})
                );

                reset({warehouseName: ''});
            },
        }),
        [FROM_SECTION.LEVEL_NAME]: () => {
            const initialName =
                storageFloor?.levels?.find((level) => level.id === levelId)?.name ?? '';
            return {
                initialName,
                schema: editLevelSchema(storageFloor, initialName),
                defaultValues: {
                    warehouseName: initialName,
                },
                submit: (
                    watch: UseFormWatch<IEditWarehouseForm>,
                    dispatch: AppDispatch,
                    reset: UseFormReset<IEditWarehouseForm>,
                    setSelectedStorage?: React.Dispatch<React.SetStateAction<IStorage | null>>
                ) => {
                    const name = watch('warehouseName');
                    const newLevel = {
                        ...storageFloor?.levels?.find((level) => level.id === levelId),
                        name,
                    };

                    const newFloor = {
                        ...storageFloor,
                        levels: (storageFloor?.levels ?? []).map((level) => {
                            const newFloorlevel = {
                                ...(newLevel.id === level.id ? newLevel : level),
                            };
                            return {
                                id: newFloorlevel.id || 0,
                                name: newFloorlevel.name || '',
                                levelId: newFloorlevel.levelId || 0,
                                parentLevel: newFloorlevel.parentLevel || 0,
                                productQuantity: newFloorlevel.productQuantity || 0,
                                showRowLevelInput: newFloorlevel.showRowLevelInput || false,
                                showNewLevel: newFloorlevel.showNewLevel || false,
                                rows: newFloorlevel.rows || [],
                                storageDescription: newFloorlevel.storageDescription || '',
                            };
                        }),
                    };

                    const newStorageType: IStorage = {
                        ...storageType,
                        storageFloor: (storageType?.storageFloor ?? []).map((floor) =>
                            floor.id === storageFloor?.id ? newFloor : floor
                        ),
                    };

                    const newWarehouse = {
                        ...warehouse,
                        storageTypes: warehouse.storageTypes?.map((storage) =>
                            storage.id === storageType?.id ? newStorageType : storage
                        ),
                    };

                    if (setSelectedStorage) {
                        setSelectedStorage(newStorageType);
                    }
                    dispatch(updateWarehouses(newWarehouse));
                    dispatch(
                        setEditWarehouse({
                            warehouse: null,
                            fromSection: '',
                            showEditModal: false,
                            storageFloor: null,
                            levelId: null,
                        })
                    );

                    reset({warehouseName: ''});
                },
            };
        },
        [FROM_SECTION.ROW_NAME]: () => {
            const initialName = selectedRow?.name ?? '';
            const level = storageFloor?.levels?.find((level) => level.id === levelId);
            return {
                initialName,
                schema: editRowSchema(level, initialName),
                defaultValues: {
                    warehouseName: initialName,
                },
                submit: (
                    watch: UseFormWatch<IEditWarehouseForm>,
                    dispatch: AppDispatch,
                    reset: UseFormReset<IEditWarehouseForm>,
                    setSelectedStorage?: React.Dispatch<React.SetStateAction<IStorage | null>>
                ) => {
                    const name = watch('warehouseName');
                    const newRow = {
                        ...selectedRow,
                        name,
                    };
                    const newLevel = {
                        ...level,
                        rows: (level?.rows || []).map((row) => {
                            const finalRow = {
                                ...(row.id === newRow.id ? newRow : row),
                            };
                            return {
                                id: finalRow.id || 0,
                                name: finalRow.name || '',
                                parentLevel: finalRow.parentLevel || 0,
                                productQuantity: finalRow.productQuantity || 0,
                            };
                        }),
                    };

                    const newFloor = {
                        ...storageFloor,
                        levels: (storageFloor?.levels ?? []).map((level) => {
                            const newFloorlevel = {
                                ...(newLevel.id === level.id ? newLevel : level),
                            };
                            return {
                                id: newFloorlevel.id || 0,
                                name: newFloorlevel.name || '',
                                levelId: newFloorlevel.levelId || 0,
                                parentLevel: newFloorlevel.parentLevel || 0,
                                productQuantity: newFloorlevel.productQuantity || 0,
                                showRowLevelInput: newFloorlevel.showRowLevelInput || false,
                                showNewLevel: newFloorlevel.showNewLevel || false,
                                rows: newFloorlevel.rows || [],
                                storageDescription: newFloorlevel.storageDescription || '',
                            };
                        }),
                    };

                    const newStorageType: IStorage = {
                        ...storageType,
                        storageFloor: (storageType?.storageFloor ?? []).map((floor) =>
                            floor.id === storageFloor?.id ? newFloor : floor
                        ),
                    };

                    const newWarehouse = {
                        ...warehouse,
                        storageTypes: warehouse.storageTypes?.map((storage) =>
                            storage.id === storageType?.id ? newStorageType : storage
                        ),
                    };

                    if (setSelectedStorage) {
                        setSelectedStorage(newStorageType);
                    }
                    dispatch(updateWarehouses(newWarehouse));
                    dispatch(
                        setEditWarehouse({
                            warehouse: null,
                            fromSection: '',
                            showEditModal: false,
                            levelId: null,
                            storageFloor: null,
                            selectedRow: null,
                        })
                    );

                    reset({warehouseName: ''});
                },
            };
        },
    };

    return CONFIG_MAP[fromSection]();
};
