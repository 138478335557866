import {SelectOption} from '@General/Atoms/LabeledSelectDropdown/interfaces';

export const CART_MESSAGES = {
    LOAN: 'Préstamo:',
    VALUE: 'Avalúo',
    PAYMENT: 'Pago',
    MONEY: 'Efectivo',
    PAYMENT_ERROR: ' Por favor, ingrese el valor es obligatorio',
    CANCEL_ALL: 'Cancelar transacción',
    CHANGE: 'Cambio',
    PAY: 'Pago',
    SUB_TOTAL: 'Sub-total',
    IVA: 'IVA',
    TOTAL: 'Total',
    CONTRACT: 'Contrato',
    FINALIZE_PAY: 'Finalizar pago',
    SUMMARY: 'Resumen',
    FOLIO: 'Folio',
    REDIRECT_TO_PAY: 'Redireccionando a la pasarela de pago',
    REDIRECT_TO_SUMMARY:
        'Por favor, espera unos minutos mientras finalizamos la construcción de los contratos',
};

export const CLIENT_INFO = {
    NUC: 'NUC',
};

export const PAYMENT_GATEWAY = {
    TITLE: 'Pasarela de pago',
    CANCEL_TRANSACTION: 'Cancelar transacción',
    PAYMENT_METHODS: 'Métodos de pago',
    SELECT_METHOD: 'Selecciona el método de pago que desea realizar el cliente,',
    CARD: 'Tarjeta',
    ENTER_CARD_AMOUNT: 'Ingresa el monto en tarjeta',
    LAST_FOUR_DIGITS: 'Ultimos cuatro numeros',
    CVE_REGISTRY: 'CVE registro',
    CVE_FORMAT: 'Formato CVE-2022-1234',
    DEBIT_CREDIT: 'Debito/Credito',
    SELECT_CARD_TYPE: 'Seleccionar tipo de tarjeta',
    CARD_TYPE_TOOLTIP:
        'Se debe escoger una de las siguientes opciones para validar el tipo de tarjeta que se esta escogiendo.',
    CASH: 'Efectivo',
    ENTER_CASH_AMOUNT: 'Ingresa el monto en efectivo',
    CHANGE: 'Cambio',
    OTHER_PAYMENT_METHODS: 'Otros métodos de pago',
    CLIENT: 'Cliente',
    PURCHASE_SUMMARY: 'Resumen de la compra',
    ARTICLE_COUNT: 'No artículos:',
    RENEWAL: 'Refrendo',
    CONTRACT: 'Contrato:',
    PAYMENT_SUMMARY: 'Resumen del pago',
    SUBTOTAL: 'Sub-total',
    IVA: 'I.V.A',
    TOTAL: 'Total',
    TOTAL_PASS: 'Abono',
    PAY: 'Pagar',
    PROCESSING: 'Procesando',
    EXTENSION: 'Extensión',
    ENDORSEMENTS_PAYMENT: 'Refrendo',
    DAYS: 'Días :',
    GENERAL_MESSAGE: 'Por favor proporcione un método de pago.',
    FULFILLING: 'Desempeño',
    PLEASE_WAIT: 'Espera unos segundos',
    PROCESS_PAYMENT_ERROR: 'Ocurrió un error al procesar tu pago. Por favor, intenta nuevamente.',
    TOTAL_PAYMENT: 'El pago total',
    LESS_THAN_PAYMENT_AMOUNT: ' es menor que el monto requerido de',
};

export const InputFormStyle = {
    labelFontSize: '1rem',
    labelFontWeight: '700',
    labelColor: '#1D1E20',
    letterSpacing: '0.019rem',
};

export const cardTypeOptions: SelectOption[] = [
    {
        value: 1,
        label: 'Debito',
    },
    {
        value: 2,
        label: 'Credito',
    },
];

export const creditTypeOptions: SelectOption[] = [
    {
        value: 1,
        label: 'Visa',
    },
    {
        value: 2,
        label: 'Master Card',
    },
    {
        value: 3,
        label: 'Amex',
    },
    {
        value: 4,
        label: 'Debito Maestro',
    },
    {
        value: 5,
        label: 'Debito Visa',
    },
];

export const INSTALLMENTS_SUMMARY = {
    PAYMENT: 'Abono',
    OUTSTANDING_BALANCE: 'Saldo mutuo : ',
};
