import React from 'react';
import {Text} from '@/components/Shopify/Ecommerce/styles';
import {TableItem, TableRowItems} from '@/components/Shopify/Endeavors/styles';
import {ITableRowDataProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';

export const TableRowData: React.FC<ITableRowDataProps> = ({
    index,
    tableLength,
    frecuncyName,
    countPaymentMin,
    countPaymentMax,
}) => {
    return (
        <TableRowItems
            key={index}
            GridColumn="75px 165px 169px"
            Gap="60px"
            Margin="0px"
            BorderL="none"
            BorderT="none"
            Border=""
            Radius=""
            BorderB={index === tableLength ? '' : '1px solid #E8E9EA'}
            Padding={index === tableLength ? '1rem 0px 0px 0px' : '1rem 0px'}
            BackGColor=""
            Cursor="default"
            MinHeight=""
        >
            <TableItem Justify="start" Gap="0.5rem">
                <Text Color="#2A2C2F">{frecuncyName}</Text>
            </TableItem>
            <TableItem Justify="start" Gap="0.5rem">
                <Text Color="#2A2C2F">{countPaymentMin}</Text>
            </TableItem>
            <TableItem Justify="start" Gap="0.5rem">
                <Text Color="#2A2C2F">{countPaymentMax}</Text>
            </TableItem>
        </TableRowItems>
    );
};
