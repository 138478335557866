import {DateTime} from 'luxon';
import {Controller} from 'react-hook-form';
import React from 'react';
import {DatePickerCustom} from '@/components/CreateUsers/DatePiker/datePickerStyles';
import {Input} from '@/components/Shopify/Ecommerce/styles';
import {IDateDueInputProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';

export const DateDueInitInput: React.FC<IDateDueInputProps> = ({
    control,
    handleManualInput,
    name,
}) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {value, onChange, ...field}}) => (
                <DatePickerCustom
                    mask="__/__/____"
                    {...field}
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={(e) => {
                        onChange(e);
                        if (e === null) return;
                        handleManualInput(e as DateTime, true);
                    }}
                    minDate={
                        new Date(
                            new Date().getFullYear(),
                            new Date().getMonth(),
                            new Date().getDate() + 0
                        )
                    }
                    disableMaskedInput={false}
                    renderInput={({inputRef, inputProps, InputProps}) => (
                        <Input
                            ref={inputRef}
                            {...inputProps}
                            placeholder={'00/00/0000'}
                            Width="95px"
                        />
                    )}
                />
            )}
        />
    );
};
