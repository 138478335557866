import {ROLE_ID_TO_EDIT} from '@components/RolesPermissions/Redux/Types';

interface IRoleToEdit {
    number: number;
}

const initialState: IRoleToEdit = {
    number: 0,
};

const RoleIdToEditReducer = (
    state = initialState,
    action: {
        type: string;
        payload: number;
    }
): IRoleToEdit => {
    switch (action.type) {
        case ROLE_ID_TO_EDIT:
            return {
                ...state,
                number: action.payload,
            };
        default:
            return state;
    }
};

export default RoleIdToEditReducer;
