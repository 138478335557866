import React, {useState} from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import LoanLisCard from '@components/GlobalQuoter/LoanLisCard';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ICompaniesOffert} from '@/components/GlobalQuoter/interface';
import {QUOTE_DATA_LABELS, SHOW_BTN} from '@/components/GlobalQuoter/TempConstants';

const LoanCardsListContainer = () => {
    const {quoterCompleteList, filterList} = useSelector(
        (state: RootState) => state.globalQuoterFilterRedcuer
    );
    const activeLoansList = filterList.length > 0 ? filterList : quoterCompleteList;
    const [showMore, setShowMore] = useState(false);
    const activeLoans = showMore ? activeLoansList : activeLoansList.slice(0, 5);

    return (
        <ContainerFlex
            FlexDir="column"
            Justify="flex-start"
            Align="flex-start"
            Radius="0px 0px 16px 16px"
            Border="1px solid #E8E9EA"
            Padding="16px"
            Gap="16px"
            id={QUOTE_DATA_LABELS.SCROLL_ID}
        >
            {activeLoans.map((loan: ICompaniesOffert, index: number) => (
                <LoanLisCard key={index} loan={loan} />
            ))}
            {activeLoansList.length > 5 && (
                <ContainerFlex Height="49px" Width="100%">
                    <ButtonGenerals
                        Height="49px"
                        FontSize="1rem"
                        FontWeight="500"
                        Radius="0"
                        Border="none"
                        HBackG="none"
                        BackGC="transparent"
                        onClick={() => setShowMore(!showMore)}
                        Cursor="pointer"
                    >
                        <Text
                            Color="#5A5AFF"
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.5rem"
                            Cursor="pointer"
                        >
                            {showMore ? '-' : '+'}
                        </Text>
                        <Text
                            Color="#5A5AFF"
                            FontFamily="Nunito"
                            FontWeight="700"
                            LetterSpacing="0.3px"
                            Cursor="pointer"
                        >
                            {showMore ? SHOW_BTN.LESS : SHOW_BTN.MORE}
                        </Text>
                    </ButtonGenerals>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default LoanCardsListContainer;
