import {
    Control,
    FieldErrors,
    FieldValues,
    UseFormRegister,
    UseFormReset,
    UseFormResetField,
    UseFormSetValue,
    UseFormGetValues,
    UseFormWatch,
} from 'react-hook-form';
import {UploadDocsProps, IDocumentsInfo} from '@components/Shopify/ClientProfile/interfaces';

export interface clients {
    id: number;
    name: string;
    nuc: number | string;
    status: true;
    roleId: number;
    email: string;
    stars: number;
    apartsNumber: number;
    pawnsNumber: number;
    apartsAvailable: number;
    phoneNumber: string;
    score: string;
    urlImageProfile: string;
    isTrustworthyPerson: boolean;
    firstFolio: string;
    companyId: number;
    expirationDate: string;
    percentScore: string;
    pointsScore: string;
    contractExpiration: string | null;
}

export interface clientCard {
    error: boolean;
    loading: boolean;
    clients: {
        tokenExpiration: number;
        succeeded: boolean;
        message: null;
        errors: null;
        token: string;
        pageNumber: number;
        pageSize: number;
        totalItems: number;
        totalPages: number;
        data: [clients];
    };
    table: number;
    actualPage: number;
    searchCustomer: string;
}

export interface IClientProofStep {
    token: string;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    control: Control<FieldValues, FieldValues>;
    reset: UseFormReset<FieldValues>;
    resetField: UseFormResetField<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    salePrice?: (value: string) => void;
    setValue: UseFormSetValue<FieldValues>;
    getValues?: UseFormGetValues<FieldValues>;
    selectedFile?: string | null;
    setSelectedFile?: (e: string | null) => void;
    isPopup?: boolean;
}

export interface IPersonalDataNationRfcCurpInfoProps {
    control: Control<UploadDocsProps, UploadDocsProps>;
    errors: FieldErrors<UploadDocsProps>;
    setValue: UseFormSetValue<UploadDocsProps>;
    onTypeChange: (e: string) => void;
    onIdNumberChange: (e: string) => void;
    documentsInfo: IDocumentsInfo;
    identificationOptions: SelectOption[] | [];
}

export interface ProofType {
    id: number;
    catDocumentCategoryId: number;
    catDocumentCategoryName: string;
    status: string;
    description: string;
}

export interface CivilStatus {
    id: number;
    nameStatus: string;
}

export interface EconomicActivity {
    id: number;
    activityName: string;
}

export interface Genre {
    id: number;
    genderName: string;
}
export interface IdentificationType {
    id: number;
    identificationName: string;
    status: true;
}

export interface MarketingClient {
    id: number;
    descriptionMarketing: string;
}

export interface SelectOption {
    value: number;
    label: string;
}

export interface ScoreStyle {
    border: string;
    backgroundColor: string;
    color: string;
}

export interface IAddNewClientModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IAddNewClientFormProps {
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface IFingerprintsStep {
    token: string;
    register: UseFormRegister<FieldValues>;
    errors: FieldErrors<FieldValues>;
    control: Control<FieldValues, FieldValues>;
    reset: UseFormReset<FieldValues>;
    watch: UseFormWatch<FieldValues>;
    salePrice?: (value: string) => void;
    setValue: UseFormSetValue<FieldValues>;
    inPopup?: boolean;
    clientEditId?: number;
}

export interface CustomDatePickerProps {
    name: string;
    placeholder: string;
    openOnError?: boolean;
    width?: string;
    format?: string;
}
export interface FingerprintImage {
    imageSrc: string;
    imageBase64: string;
    imageFileName: string;
    fileSizeInMB: string;
    fileExtension: string;
}
export interface HandFingerprintImages {
    thumb: FingerprintImage | null;
    index: FingerprintImage | null;
    middle: FingerprintImage | null;
    ring: FingerprintImage | null;
    pinkie: FingerprintImage | null;
}
export interface ClientFingerprints {
    left: HandFingerprintImages;
    right: HandFingerprintImages;
}
export interface FingerPrintSelectorProps {
    selectedFinger: FingerClickEvent;
    errors: FieldErrors<FieldValues>;
    setValue: UseFormSetValue<FieldValues>;
    clientEditId?: number;
}
export interface SelectFingerprintProps {
    selectedFinger: FingerClickEvent;
    imageFileName: string;
    fileSizeInMB: string;
    imageSrc: string | null;
    onSelectClick: () => void;
}
export interface FingersPositions {
    id: string;
    top: string;
    left?: string;
    right?: string;
}
export interface FingerProps {
    id: string;
    hand: string;
    top: string;
    left?: string;
    right?: string;
    onClick: (id: string) => void;
    isSelected: boolean;
}
export const HANDS = {
    LEFT: 'Izquierda',
    RIGHT: 'Derecha',
} as const;

export type HandId = (typeof HANDS)[keyof typeof HANDS];
export type FingerKey = 'thumb' | 'index' | 'middle' | 'ring' | 'pinkie';
export interface FingerClickEvent {
    hand: HandId;
    finger: string;
}
export interface HandProps {
    label: string;
    hand: HandId;
    imageSrc: string;
    fingers: FingersPositions[];
    onFingerClick: (event: FingerClickEvent) => void;
    selectedFinger: FingerClickEvent;
}

export interface FingerData {
    data: ClientFingerprints;
    hand: string;
    finger: string;
    fingerId: number;
    clientId: number;
    width?: string;
    format?: string;
}

export interface ProfileValuateProps {
    idClient: number;
    showModal: boolean;
    setShowModal: (e: boolean) => void;
}
export interface IClientSorting {
    sortedCol: number;
    direction: number;
}
export interface TableCardProps {
    sortConfig: IClientSorting;
    onSortChange: (sorting: IClientSorting) => void;
}
export interface AddNewClientWarningProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    onProceed: () => void;
}
