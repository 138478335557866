import {RootState} from '@/config/store';
import ButtonGeneral from '@components/General/Atoms/Button/index';
import {IDisableUserModal} from '@components/MyCompanyUser/interface';
import {DISABLE_MENU} from '@components/MyCompanyUser/Operational/Constants';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {updateStatusAllUsers} from '@components/Users/Redux/Actions/updateUsersStatusAction';
import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

const DisableUserModal = ({id, setDisableMenu}: IDisableUserModal) => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const handleDisable = () => {
        const data = {userId: id};
        if (token) {
            dispatch(updateStatusAllUsers(token, data));
            setDisableMenu(false);
        }
    };
    return (
        <ContainerFlex
            Position="absolute"
            Gap="30px"
            ZIndex="2"
            FlexDir="column"
            backG="white"
            Width="40rem"
            Height="15rem"
            PositionTop="40%"
            PositionRight="28.8%"
            Justify="start"
            Align="start"
            Padding="25px"
            Radius="24px"
            BoxS="rgba(223, 221, 221, 0.2) 0px 2px 8px 0px"
        >
            <Text FontSize="1.5rem" FontWeight="700" MinH="1.5rem" MarginTop=".5rem">
                {DISABLE_MENU.TITLE}
            </Text>
            <Text>{DISABLE_MENU.TEXT}</Text>
            <ContainerFlex Gap="15px">
                <ButtonGeneral
                    text={DISABLE_MENU.CANCEL}
                    border="1px solid #5A5AFF"
                    color="#5A5AFF"
                    backGround="white"
                    width="10rem"
                    hBackG="#ddddddbb"
                    clic={() => setDisableMenu(false)}
                    mt="1rem"
                />
                <ButtonGeneral
                    text={DISABLE_MENU.CONFIRM}
                    backGround="#5A5AFF"
                    width="10rem"
                    clic={handleDisable}
                    mt="1rem"
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default DisableUserModal;
