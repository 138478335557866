import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {GET_STORAGE_BY_LOCATION_ID} from '@components/LocationsBranch/Redux/types';
import StringUtils from '@/GenericScripts/utils';
import {IInventories} from '@components/LocationsBranch/Redux/interfaces';

const fetchStorageStart = () => ({
    type: GET_STORAGE_BY_LOCATION_ID.START,
});

export const fetchStorageSuccess = (result: AxiosResponse) => ({
    type: GET_STORAGE_BY_LOCATION_ID.SUCCESS,
    holidays: result.data,
});

export const fetchStorageError = () => ({
    type: GET_STORAGE_BY_LOCATION_ID.ERROR,
});

export const fetchStorageByLocationId = (
    data: {
        LocationId: number;
        TypeLocation: number;
    },
    token: string,
    onSetStorage: (data: IInventories[]) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const queryString = StringUtils.jsonToQueryString({...data});
    return async (dispatch: AppDispatch) => {
        dispatch(fetchStorageStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetStorageByLocationId}${queryString}`,
                {headers}
            );
            dispatch(fetchStorageSuccess(response.data.data));
            onSetStorage(response.data.data);
        } catch (error) {
            dispatch(fetchStorageError());
        }
    };
};
