import React, {useCallback} from 'react';
import {Controller, FieldValues} from 'react-hook-form';
import {ContainerFlex, Input} from '@/components/Shopify/Ecommerce/styles';
import {SelectLada} from '@Quoter/CoownerBeneficiary/SelectLada';

const formatPhoneNumber = (value: string): string => {
    if (!value) return '';
    const numbers = value.replace(/[^\d]/g, '');
    const parts: string[] = [];
    for (let i = 0; i < numbers.length; i += 4) {
        parts.push(numbers.slice(i, i + 4));
    }
    return parts.join('-');
};

const formatPhoneNumberForSubmission = (value: string): string => {
    return value.replace(/[^\d]/g, '');
};

const PhoneFormatInput = ({control, name, handleNationSelected}: FieldValues) => {
    const handleChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
            const inputValue = e.target.value;
            const cleanedValue = formatPhoneNumberForSubmission(inputValue);
            onChange(cleanedValue);
        },
        []
    );

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => {
                const stringValue = typeof value === 'string' ? value : '';

                return (
                    <ContainerFlex>
                        <SelectLada handleNationSelected={handleNationSelected} />
                        <Input
                            onChange={(e) => handleChange(e, onChange)}
                            onBlur={onBlur}
                            value={formatPhoneNumber(stringValue)}
                            name={name}
                            ref={ref}
                            type="tel"
                            placeholder=""
                            Radius="0"
                            RadiusTR="50px"
                            RadiusBR="50px"
                            Padding="12px"
                            FontSize="1rem"
                            maxLength={18}
                            error={error?.message}
                        />
                    </ContainerFlex>
                );
            }}
        />
    );
};

export default React.memo(PhoneFormatInput);
