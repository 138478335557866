import React from 'react';
import {Controller} from 'react-hook-form';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {CustomSelect} from './InputSelect';
import {CustomSelectOptions} from '@/components/MyCompanyUser/SelectOptions';
import {MOD_FILTER, stateOptions} from './Operational/Constants';
import {FilterDefaultFormProps} from '@/components/MyCompanyUser/Operational/interfaceType';

const FilterForm: React.FC<FilterDefaultFormProps> = ({
    control,
    errors,
    handleSubmit,
    onSubmit,
    handleClose,
    roleOptions,
}) => {
    return (
        <ContainerForm Gap="24px" FlexDir="column" onSubmit={handleSubmit(onSubmit)}>
            <ContainerFlex Justify="start" Height="max-content">
                <Text FontWeight="700" FontSize="1.5rem">
                    {MOD_FILTER.TITLE}
                </Text>
            </ContainerFlex>

            <ContainerFlex Justify="start" Height="max-content">
                <Text color="#54575C" FontSize="1.25rem" FontWeight="600">
                    {MOD_FILTER.INDICATION}
                </Text>
            </ContainerFlex>

            <ContainerFlex Gap="8px" Height="max-content" FlexDir="column" Align="start">
                <Text color="#54575C" FontSize="1rem" FontWeight="700">
                    {MOD_FILTER.ROL}
                </Text>
                <Controller
                    name="Rol"
                    control={control}
                    render={({field}) => (
                        <CustomSelect
                            placeholder="Selecciona"
                            options={roleOptions}
                            value={field.value}
                            onChange={(value) => {
                                if (value === field.value) {
                                    field.onChange('');
                                } else {
                                    field.onChange(value);
                                }
                            }}
                        />
                    )}
                />
                {errors.Rol && <Text Color="#FF6357">{errors.Rol.message}</Text>}
            </ContainerFlex>

            <ContainerFlex Gap="8px" Height="" FlexDir="column" Align="start">
                <Text color="#54575C" FontSize="1rem" FontWeight="700">
                    {MOD_FILTER.STATE}
                </Text>
                <Controller
                    name="State"
                    control={control}
                    render={({field}) => (
                        <CustomSelectOptions
                            placeholder="Selecciona"
                            options={stateOptions}
                            value={field.value}
                            onChange={(value) => {
                                if (value === field.value) {
                                    field.onChange('');
                                } else {
                                    field.onChange(value);
                                }
                            }}
                        />
                    )}
                />
                {errors.State && <Text Color="#FF6357">{errors.State.message}</Text>}
            </ContainerFlex>

            <ContainerFlex Align="end" ColumnGap="16px">
                <ButtonGenerals
                    onClick={handleClose}
                    SecondaryButton="#FFFFFF"
                    Width="100%"
                    type="button"
                    Cursor="pointer"
                >
                    {MOD_FILTER.BTN}
                </ButtonGenerals>

                <ButtonGenerals Width="100%" type="submit">
                    {MOD_FILTER.BTNS}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerForm>
    );
};

export default FilterForm;
