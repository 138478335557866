import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import React from 'react';
import {IDisbursementPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/disbursementInterfaces';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {DISBURSEMENT} from '@components/PersonalLoans/NewLoanProduct/constants';
import {HeaderPreview} from '@components/PersonalLoans/NewLoanProduct/ui/HeaderPreview';
import checkDisbursementIcon from '@components/PersonalLoans/NewLoanProduct/icons/checkDisbursementIcon.svg';

export const DisbursementPreview: React.FC<IDisbursementPreviewProps> = ({
    disbursementsRef,
    isEditing,
    setIsEditing,
}) => {
    const disbursementData = useSelector(
        (state: RootState) => state.newLoanSubmittedData.details.disbursements
    );
    const disbursementCheckedData = disbursementData.filter((item) => item.isChecked);
    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={disbursementsRef}
            >
                <HeaderPreview
                    title={DISBURSEMENT.TITLE}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
                {disbursementCheckedData.map((item, index) => (
                    <ContainerFlex key={index} Justify="start" Align="start" Gap="0.5rem">
                        <ContainerFlex Padding="0.25rem" Width="max-content" Height="max-content">
                            <Image
                                src={checkDisbursementIcon}
                                Width="16px"
                                Height="16px"
                                alt="check-icon"
                            />
                        </ContainerFlex>
                        <ContainerFlex Justify="start" Align="start" Gap="0.25rem" FlexDir="column">
                            <Text Color="#2A2C2F">{item.disbursementName}</Text>
                            <Text Color="#54575C" FontSize="0.875rem">
                                {item.disbursementDetails}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                ))}
            </ContainerFlex>
        </>
    );
};
