import React from 'react';
import {IPropsInput} from '@/components/NewUserEmployee/Operational/interface';
import {
    HelperText,
    InputContainer,
    InputImage,
    InputWrapper,
    StyledInput,
} from '@/components/NewUserEmployee/stylesInput';
import {ContainerFlex, Image} from '@/components/Shopify/Ecommerce/styles';

export const GeneralsInput = React.forwardRef<
    HTMLInputElement,
    IPropsInput & React.InputHTMLAttributes<HTMLInputElement>
>(({hasError, helperText, error, Img, imgMessage, ...props}, ref) => {
    const isError = Boolean(hasError || error);
    const errorMessage = helperText || (typeof error === 'string' ? error : '');

    return (
        <InputContainer>
            <InputWrapper>
                <StyledInput ref={ref} isError={isError} hasImg={!!Img} error={error} {...props} />
                {Img && <InputImage src={Img} alt="input-icon" />}
            </InputWrapper>
            {errorMessage && (
                <ContainerFlex Height="max-content" Justify="start" Padding="4px">
                    {imgMessage && <Image src={imgMessage} alt="error-icon" />}
                    <HelperText isError={isError}>{errorMessage}</HelperText>
                </ContainerFlex>
            )}
        </InputContainer>
    );
});

GeneralsInput.displayName = 'Input';

export default GeneralsInput;
