import {IStorageFloor} from '@components/LocationsBranch/interfaces';

export const getInitialFloor = (id: number, emptyName?: boolean): IStorageFloor[] => {
    return [
        {
            id,
            name: emptyName ? '' : `Piso ${id}`,
            productQuantity: 0,
            storageDescription: 'Nivel 1',
            levels: [],
        },
    ];
};
