import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import FilterTag from '@/components/CreditsAndPawns/FilterModal/FilterTag';
import {RootState} from '@/config/store';
import {setFiltersValues} from '@/components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';
import {FILTER_LABELS_AND_VALUES} from '@/components/GlobalQuoter/TempConstants';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';

const FilterTagContainer = () => {
    const dispatch = useDispatch();
    const {filtersValues, companyList, disbursementList, maxIinterestRate} = useSelector(
        (state: RootState) => state.globalQuoterFilterRedcuer
    );
    const resetFilter = (
        keyStart: string,
        startValue: unknown,
        keyEnd?: string,
        endValue?: unknown
    ) => {
        dispatch(
            setFiltersValues({
                ...filtersValues,
                [keyStart]: startValue,
                ...(keyEnd && {[keyEnd]: endValue}),
            })
        );
    };

    return (
        <ContainerFlex Justify="flex-start" Align="flex-start" FlexWrap="wrap" Height="fit-content">
            <FilterTag
                tagLabel={`Entre ${filtersValues.rateMin}% y ${filtersValues.rateMax}%`}
                handleFilter={() =>
                    resetFilter(
                        FILTER_LABELS_AND_VALUES.RATE.VALUE_MIN,
                        0,
                        FILTER_LABELS_AND_VALUES.RATE.VALUE_MAX,
                        maxIinterestRate
                    )
                }
            />
            {filtersValues.company.length > 0 ? (
                filtersValues.company.length === companyList.length ? (
                    <FilterTag
                        tagLabel={FILTER_LABELS_AND_VALUES.COMPANY.ALL_TAG}
                        handleFilter={() => resetFilter(FILTER_LABELS_AND_VALUES.COMPANY.VALUE, [])}
                    />
                ) : (
                    filtersValues.company.map((company: number) => (
                        <FilterTag
                            key={company}
                            tagLabel={
                                companyList.find(
                                    (c: {value: number; label: string}) => c.value === company
                                )?.label || ''
                            }
                            handleFilter={() =>
                                resetFilter(
                                    FILTER_LABELS_AND_VALUES.COMPANY.VALUE,
                                    filtersValues.company.filter((c: number) => c !== company)
                                )
                            }
                        />
                    ))
                )
            ) : null}
            {filtersValues.disburstmentType.length > 0 ? (
                filtersValues.disburstmentType.length === disbursementList.length ? (
                    <FilterTag
                        tagLabel={FILTER_LABELS_AND_VALUES.DISBURSTMENT_TYPE.ALL_TAG}
                        handleFilter={() =>
                            resetFilter(FILTER_LABELS_AND_VALUES.DISBURSTMENT_TYPE.VALUE, [])
                        }
                    />
                ) : (
                    filtersValues.disburstmentType.map((disburstmentType: number) => (
                        <FilterTag
                            key={disburstmentType}
                            tagLabel={
                                disbursementList.find(
                                    (d: {value: number; label: string}) =>
                                        d.value === disburstmentType
                                )?.label || ''
                            }
                            handleFilter={() =>
                                resetFilter(
                                    FILTER_LABELS_AND_VALUES.DISBURSTMENT_TYPE.VALUE,
                                    filtersValues.disburstmentType.filter(
                                        (d: number) => d !== disburstmentType
                                    )
                                )
                            }
                        />
                    ))
                )
            ) : null}
        </ContainerFlex>
    );
};

export default FilterTagContainer;
