import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from '@/config/store';
import {setEditWarehouse} from '@components/LocationsBranch/Redux/Action/Warehouse';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import {IEditWarehouseForm, IEditWarehouseModal} from '@components/LocationsBranch/interfaces';
import {EDIT_WAREHOUSE_MODAL} from '@components/LocationsBranch/constants';
import {getEditModalConfig} from '@components/LocationsBranch/utils/GetEditModalConfig';

export const EditWarehouseModal = ({setSelectedStorage}: IEditWarehouseModal) => {
    const dispatch = useDispatch();
    const {warehouses, editWarehouse} = useSelector((state: RootState) => ({
        warehouses: state.Warehouse.warehouses,
        editWarehouse: state.Warehouse.editWarehouse,
    }));

    const {schema, submit, defaultValues} = getEditModalConfig({editWarehouse, warehouses});
    const {
        register,
        watch,
        reset,
        handleSubmit,
        formState: {errors},
    } = useForm<IEditWarehouseForm>({
        resolver: yupResolver(schema),
        defaultValues,
    });

    const onCancelModal = () => {
        dispatch(setEditWarehouse({warehouse: null, fromSection: '', showEditModal: false}));
    };

    const onSubmitModal = () => {
        submit(watch, dispatch, reset, setSelectedStorage);
    };

    useEffect(() => {
        return () => {
            if (editWarehouse.showEditModal)
                dispatch(
                    setEditWarehouse({
                        warehouse: null,
                        fromSection: '',
                        showEditModal: false,
                        storageType: null,
                        storageFloor: null,
                    })
                );
        };
    }, [editWarehouse.showEditModal]);
    return (
        <Modal
            Top="0"
            modalState={editWarehouse.showEditModal}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem 0 0 1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerFlex
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
            >
                <ContainerFlex FlexDir="column" Align="start" Height={'1.5rem'} MarginBt="1.5rem">
                    <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                        {EDIT_WAREHOUSE_MODAL.TITLE}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Position="relative"
                    FlexDir="column"
                    Align="start"
                    Height={'6.5rem'}
                    Justify="top"
                >
                    <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                        {EDIT_WAREHOUSE_MODAL.INPUT_NAME}
                    </Text>
                    <Input
                        type="text"
                        {...register('warehouseName')}
                        error={errors?.warehouseName?.message}
                    />
                    {errors?.warehouseName?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors.warehouseName.message as string}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex Gap="1.5rem" Align="end">
                    <ButtonGeneral
                        width="50%"
                        text={EDIT_WAREHOUSE_MODAL.CANCEL}
                        clic={() => onCancelModal()}
                        color="#5A5AFF"
                        backGround="#FFFFFF"
                        hText="#5A5AFF"
                        hShadow=""
                        height="2.5rem"
                        cursor="pointer"
                        border="0.063rem solid #5A5AFF"
                        hBackG="#E5E5FF"
                    />
                    <ButtonGeneral
                        width="50%"
                        padding="1rem 0.5rem"
                        text={EDIT_WAREHOUSE_MODAL.SAVE}
                        clic={handleSubmit(onSubmitModal)}
                        height="2.5rem"
                        cursor="pointer"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};
