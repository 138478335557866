import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';
import {IconContainer} from '@/components/Quoter/styles';
import {SearchInput} from '@Customer/Styles';
import {DropDownMenu} from '@General/MenuAmazon/ReutilizableComponent/FloatMenu';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {getAllLevelsOrganization} from '@ActionsOrgCreation/GetAllLevels';
import {searchData} from '@ActionsOrgCreation/LevelTree';
import {SearchLevelOrganization} from '@ActionsOrgCreation/SearchLevels';
import {Highlight} from '@TreeTableUtils/CoincidenceFunction';
import {resetPetition} from '@TreeTableUtils/LevelsAddFunctions';
import {ImageIcon, MenuItem} from '@SubContainerOrgCreation/styles';
import CloseHover from '@SubContainerOrgCreation/Icons/close.svg';
import {ILevelSearchedItem} from '@OrganizationCreation/interface';

export const SearchOrganization = ({
    setCurrentPage,
}: {
    setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
    const dispatch: AppDispatch = useDispatch();
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const {levelSearched} = useSelector((state: RootState) => {
        return state.searchLevels;
    });
    const PageSize = useSelector((state: RootState) => {
        return state.GetStatmentData.pageNumber;
    });
    const [searchVisible, setVisible] = useState(false);
    const [searchedValue, setSearch] = useState('');

    const handleClickAway = () => {
        setVisible(false);
    };
    const HandleSearch = (e: {target: {value: string}}) => {
        setSearch(e.target.value);
        let visibility = false;
        if (e.target.value.length >= 3) {
            const SearchData = {
                companyId: companyId,
                search: e.target.value,
            };
            visibility = true;
            dispatch(SearchLevelOrganization(SearchData, token));
        }
        setVisible(visibility);
    };
    const handlePetition = (LevelName: string) => {
        dispatch(searchData(LevelName));
        setCurrentPage(1);
        const paginated = {
            companyId: companyId,
            search: LevelName,
            pageSize: 20,

            pageNumber: PageSize === 0 ? 1 : PageSize,
        };
        setSearch(LevelName);
        dispatch(getAllLevelsOrganization(paginated, token));
    };
    const ResetAll = () => {
        setVisible(false);
        resetPetition(companyId, PageSize, token, setSearch, dispatch, setCurrentPage);
    };
    const CanceledFunction = () => {
        switch (searchedValue && searchVisible) {
            case true:
                return <ImageIcon src={CloseHover} onClick={ResetAll} cursor="pointer" />;
            case false:
                return (
                    <IconContainer className="material-icons" onClick={ResetAll} Cursor="pointer">
                        close
                    </IconContainer>
                );
            default:
                return null;
        }
    };
    return (
        <ContainerFlex Padding="24px 0 0 0" Justify="flex-start">
            <SearchInput
                Width="24rem"
                Height="40px"
                Position="none"
                GridColumn="90% 10%"
                Padding="0 8px"
                BorderH="1px solid #35CF44"
                BorderF="2px solid #35CF44"
            >
                <input
                    placeholder="Buscar por nivel o descripción"
                    onChange={HandleSearch}
                    value={searchedValue}
                />
                {searchedValue.length >= 2 ? (
                    <CanceledFunction />
                ) : (
                    <IconContainer Width="100%" className="material-icons" Height="100%">
                        search
                    </IconContainer>
                )}
            </SearchInput>
            <DropDownMenu
                OutSideClick={handleClickAway}
                Height="auto"
                Top="6.7rem"
                MenuDisplayStatus={searchVisible}
                Position="absolute"
                Width="30rem"
                BoxShadow=""
                Padding="0"
                Values={
                    levelSearched && levelSearched.length > 0 ? (
                        levelSearched.map((items: ILevelSearchedItem, index: number) => {
                            return (
                                <MenuItem
                                    key={index}
                                    onClick={() => {
                                        handleClickAway();
                                        handlePetition(items.levelName);
                                    }}
                                    FlexDir="column"
                                    Height="60px"
                                    Padding="12px 16px"
                                    Width="auto"
                                >
                                    <Highlight text={items.levelName} highlight={searchedValue} />
                                    <Text FontSize="0.75rem" Color="#a7a9ac" Cursor="pointer">
                                        {items.levelDescription}
                                    </Text>
                                </MenuItem>
                            );
                        })
                    ) : (
                        <ContainerFlex Height="40px" Padding="16px" Justify="start">
                            <Text FontSize="0.875rem" Color="#414042">
                                Sin coincidencias
                            </Text>
                        </ContainerFlex>
                    )
                }
            />
        </ContainerFlex>
    );
};
