import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {ILoanInfo} from '@/components/GlobalQuoter/interface';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {LOAN_INFO} from '@/components/GlobalQuoter/TempConstants';

const LoanInfo = ({maxAmount, countPayments, frecuencyName, anualRate}: ILoanInfo) => {
    const amount = numberToCurrencyWithoutCents(maxAmount);
    return (
        <ContainerFlex Width="685px" Justify="center" Align="flex-start" Height="129px">
            <ContainerFlex FlexDir="column" Justify="center" Align="flex-start" Width="156px">
                <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                    {LOAN_INFO.LABELS.MAX_AMOUNT}
                </Text>
                <Text Color="#1D1E20" FontSize="1.5rem" FontFamily="Nunito" FontWeight="700">
                    {amount}
                </Text>
            </ContainerFlex>
            <ContainerFlex
                Gap="16px"
                backG="#FAFAFA"
                Radius="16px"
                Height="62px"
                Margin="32px 16px 8px 16px"
                Justify="space-between"
                Align="center"
            >
                <ContainerFlex FlexDir="column" Justify="center" Align="center" Width="156px">
                    <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                        {LOAN_INFO.LABELS.PAYMENT_NUM}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontFamily="Nunito" FontWeight="700">
                        {countPayments}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="center" Align="center" Width="156px">
                    <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                        {LOAN_INFO.LABELS.LOAN_FREQ}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontFamily="Nunito" FontWeight="700">
                        {frecuencyName}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="center" Align="center" Width="156px">
                    <Text Color="#54575C" FontSize="0.875rem" FontFamily="Nunito">
                        {LOAN_INFO.LABELS.LOAN_RATE}
                    </Text>
                    <Text Color="#1D1E20" FontSize="0.875rem" FontFamily="Nunito" FontWeight="700">
                        {`${anualRate}${LOAN_INFO.VALUES.LOAN_RATE}`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default LoanInfo;
