import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {TITLES} from '@/components/AuctionPass/AuctionConstants';
import {IMessage} from '@components/AuctionPass/interface';
import questionImg from '@images/searchAtaskate.svg';
import close from '@images/closeDark.svg';

export const PublicationAuction = ({setShow}: IMessage) => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Padding="24px"
            Height="385px"
            FlexDir="column"
            Radius="24px"
            Border="1px solid #E5E7E9"
            Gap="32px"
            backG="#FFFFFF"
        >
            <ContainerFlex Justify="start" Align="start" ColumnGap="8px" Height="56px">
                <Image alt="question" src={questionImg} Height="32px" />
                <ContainerFlex Justify="start" Align="start">
                    <Text
                        Color="#0D166B"
                        FontSize="1.5rem"
                        FontWeight="700"
                        Width="12rem"
                        wSpace=""
                    >
                        {TITLES.PUBLICATION}
                    </Text>
                </ContainerFlex>
                <Image alt="question" src={close} Height="24px" onClick={() => setShow(false)} />
            </ContainerFlex>
            <ContainerFlex Gap="24px" FlexDir="column">
                <ContainerFlex Justify="start" Align="start" Height="1.125rem" ColumnGap="8px">
                    <Text>{TITLES.DOT}</Text>
                    <Text
                        Color="#0D166B"
                        FontSize="1.125rem"
                        FontWeight="700"
                        Width="15rem"
                        wSpace=""
                    >
                        {TITLES.RECOMMEND}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    Gap="8px"
                    Height="190px"
                    FlexDir="column"
                >
                    <ContainerFlex Justify="start" Align="start">
                        <Text Width="5%">{TITLES.DOT}</Text>
                        <Text Width="95%" wSpace="">
                            {TITLES.TITUL_A}
                        </Text>
                    </ContainerFlex>{' '}
                    <ContainerFlex Justify="start" Align="start">
                        <Text Width="5%">{TITLES.DOT}</Text>
                        <Text Width="95%" wSpace="">
                            {TITLES.TITUL_B}
                        </Text>
                    </ContainerFlex>{' '}
                    <ContainerFlex Justify="start" Align="start">
                        <Text Width="5%">{TITLES.DOT}</Text>
                        <Text Width="95%" wSpace="">
                            {TITLES.TITUL_C}
                        </Text>
                    </ContainerFlex>{' '}
                    <ContainerFlex Justify="start" Align="start">
                        <Text Width="5%">{TITLES.DOT}</Text>
                        <Text Width="95%" wSpace="">
                            {TITLES.TITUL_D}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
