import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import DropdownWithIcon from '@components/GlobalQuoter/DropdownWithIcon';
import {
    FILTER_BOX,
    quantityFormOptions,
    QUOTE_DATA_LABELS,
} from '@/components/GlobalQuoter/TempConstants';
import calendarFrequency from '@images/calendar_frequency.svg';
import calendarQuantity from '@images/calendar_quantity.svg';
import {QuantityDrodpown, QuantityInput} from '@components/GlobalQuoter/styles';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {setVisualFormValues} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFormActions';
import {resetMediaLoanInfo} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';

const QuoterForm = () => {
    const {frequencyOptions} = useSelector(
        (state: RootState) => state.globalQuoterFrequencyReducer
    );
    const dispatch = useDispatch();
    const visualState = useSelector((state: RootState) => state.globalQuoterFormReducer);
    const quantityOptionsArray = Array.from({length: 28}, (_, i) => i + 1);
    const arrayWtithValues = quantityOptionsArray.map((value) => {
        return {
            label: (value * 3).toString(),
            value: value * 3,
        };
    });
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (isNaN(+inputValue.replace(/[^0-9]/g, ''))) return;
        dispatch(
            setVisualFormValues({
                ...visualState,
                visualAmount: inputValue,
                parsedAmount: parseFloat(inputValue.replace(/[^0-9]/g, '')),
                accountPayments: null,
                frequencyId: null,
            })
        );
        dispatch(resetMediaLoanInfo());
    };

    const handleChangefrecuency = (newValue: number) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                frequencyId: newValue,
            })
        );
    };

    const handleChangePaymentNum = (newValue: number) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                accountPayments: newValue,
            })
        );
    };

    const handleChangeTypeDropdown = (newValue: unknown) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                typeDropdown: newValue as {label: string; value: number},
            })
        );
    };

    return (
        <ContainerFlex
            Width="50%"
            Padding="14px 0"
            Gap="48px"
            FlexDir="column"
            Height="337px"
            Justify="flex-start"
            Align="flex-start"
        >
            <ContainerFlex
                FlexDir="column"
                Gap="8px"
                Justify="flex-start"
                Align="flex-start"
                Height="80px"
            >
                <Text FontSize="2.5rem" FontFamily="Nunito" FontWeight="500" Color="#1D1E20">
                    {QUOTE_DATA_LABELS.QUOTE_YOUR_LOAN}
                </Text>
                <Text FontSize="1.25rem" FontFamily="Nunito" FontWeight="600" Color="#1D1E20">
                    {QUOTE_DATA_LABELS.SIMULATE_LOAN}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="32px" Justify="flex-start" Align="flex-start">
                <ContainerFlex FlexDir="column" Gap="4px" Justify="flex-start" Align="flex-start">
                    <Text FontSize="1rem" FontFamily="Nunito" Color="#2A2C2F" LetterSpacing="0.3px">
                        {QUOTE_DATA_LABELS.LOAN_AMOUNT}
                    </Text>
                    <ContainerFlex
                        Justify="flex-start"
                        Align="center"
                        Gap="8px"
                        Height="58px"
                        Padding="12px 16px"
                        Bb="1px solid #E8E9EA"
                    >
                        <QuantityInput
                            value={visualState.visualAmount}
                            onChange={handleChange}
                            prefix="$"
                            allowNegative={false}
                            decimalSeparator="."
                            thousandSeparator={true}
                        />
                        <QuantityDrodpown
                            width="405px"
                            border="none"
                            borderf="none"
                            hborder="none"
                            isSearchable={false}
                            padding="0"
                            options={quantityFormOptions}
                            value={visualState.typeDropdown}
                            onChange={handleChangeTypeDropdown}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="flex-start" Align="center" Gap="16px">
                    <DropdownWithIcon
                        label={FILTER_BOX.LABELS.PAYMENT_FREQ}
                        icon={calendarFrequency}
                        iconAlt={FILTER_BOX.ICONS.PAYMENT_FREQ}
                        options={frequencyOptions}
                        value={visualState.frequencyId}
                        name={FILTER_BOX.FORM_NAME.PAYMENT_FREQ}
                        onChange={handleChangefrecuency}
                    />
                    <DropdownWithIcon
                        label={FILTER_BOX.LABELS.PAYMENT_NUM}
                        icon={calendarQuantity}
                        iconAlt={FILTER_BOX.ICONS.PAYMENT_NUM}
                        options={arrayWtithValues}
                        initialValue={arrayWtithValues[0].label}
                        value={visualState.accountPayments}
                        name={FILTER_BOX.FORM_NAME.PAYMENT_NUM}
                        onChange={handleChangePaymentNum}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default QuoterForm;
