import React, {useState} from 'react';

import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@Customer/Styles';
import {
    addWarehouses,
    setEditWarehouse,
    updateWarehouses,
} from '@components/LocationsBranch/Redux/Action/Warehouse';
import {StorageData} from '@components/LocationsBranch/StorageData';
import {
    COPY_NAME,
    FROM_SECTION,
    INITIAL_STORAGE,
    STORAGE,
    STORAGE_NAME_INPUT,
    STORAGE_TYPE_LEVELS,
    TOOLTIP_STORAGE,
} from '@components/LocationsBranch/constants';
import {
    IRow,
    IStorage,
    IWarehouseDetailProps,
    IWarehouse,
    IFormWarehouseProps,
} from '@components/LocationsBranch/interfaces';
import {getInitialFloor} from '@components/LocationsBranch/utils/warehouse';
import {ActionMenu} from '@components/LocationsBranch/Menu';
import {getNextWarehouseId} from '@components/LocationsBranch/utils/GetNextId';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@images/addIcon.svg';
import HelpIcon from '@images/help.svg';
import ClearIcon from '@images/createCancel.svg';

export const WarehouseDetail = ({
    warehouse,
    setSelectedStorage,
    formProps,
}: IWarehouseDetailProps) => {
    const dispatch = useDispatch();
    const warehouses = useSelector((state: RootState) => state.Warehouse.warehouses);
    const [showNewLevelButton, setShowNewLevelButton] = useState(false);
    const storageTypes = warehouse.storageTypes || [];
    const {register, watch, handleSubmit, errors, reset} = formProps;
    const {storageName} = watch();

    const onAddNewStorage = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSubmit(addNewStorage)();
        }
    };

    const addNewStorage = (values: IFormWarehouseProps) => {
        const {storageName} = values;
        const newId = storageTypes.length + 1;
        const newStorageType = JSON.parse(
            JSON.stringify({
                ...INITIAL_STORAGE,
                id: newId,
                name: storageName,
                parentLevel: warehouse.id,
                warehouseId: warehouse.id,
                storageFloor: [...getInitialFloor(1, false)],
            })
        );
        warehouse.storageTypes = [...storageTypes, newStorageType];
        dispatch(updateWarehouses(warehouse));
        setShowNewLevelButton(false);
        handleReset();
    };

    const onNewLevel = () => {
        setShowNewLevelButton(true);
    };

    const onSelectStorageType = (storageType: IStorage | IRow) => {
        handleReset();
        setSelectedStorage(JSON.parse(JSON.stringify(storageType)));
    };

    const onEditWarehouseName = () => {
        dispatch(
            setEditWarehouse({warehouse, fromSection: FROM_SECTION.WAREHOUSE, showEditModal: true})
        );
    };

    const onDuplicateWarehouse = (warehouse: IWarehouse) => {
        const newId = getNextWarehouseId(warehouses);
        const newWarehouses = JSON.parse(JSON.stringify(warehouses));

        const newStorageType = JSON.parse(
            JSON.stringify({
                ...warehouse,
                id: newId,
                name: `${warehouse.name} ${COPY_NAME}`,
                storageTypes:
                    warehouse.storageTypes &&
                    warehouse.storageTypes.map((storageType: IStorage) => {
                        const newStorageType = JSON.parse(JSON.stringify(storageType));
                        newStorageType.parentLevel = newId;
                        return newStorageType;
                    }),
            })
        );
        const finalWarehouses = [...newWarehouses, newStorageType];
        dispatch(addWarehouses(finalWarehouses));
    };

    const onDeleteWarehouse = (warehouse: IWarehouse) => {
        const newWarehouse = JSON.parse(JSON.stringify(warehouses));
        const index = newWarehouse.findIndex((item: IWarehouse) => item.id === warehouse.id);
        newWarehouse.splice(index, 1);
        dispatch(addWarehouses(newWarehouse));
    };

    const onEditStorageTypeName = (storageType: IStorage) => {
        dispatch(
            setEditWarehouse({
                warehouse,
                fromSection: FROM_SECTION.STORAGE_TYPE_NAME,
                showEditModal: true,
                storageType,
            })
        );
    };

    const onDeleteStorageType = (storageType: IStorage) => {
        const newStorageTypes = JSON.parse(JSON.stringify(storageTypes));
        const index = newStorageTypes.findIndex((item: IStorage) => item.id === storageType.id);
        newStorageTypes.splice(index, 1);
        warehouse.storageTypes = newStorageTypes;
        dispatch(updateWarehouses(warehouse));
    };

    const onDuplicateStorageType = (storageType: IStorage) => {
        const newId = getNextWarehouseId(warehouses);
        const newStorageType = JSON.parse(
            JSON.stringify({
                ...storageType,
                id: newId,
                name: `${storageType.name} ${COPY_NAME}`,
                parentLevel: warehouse.id,
            })
        );
        warehouse.storageTypes = [...storageTypes, newStorageType];
        dispatch(updateWarehouses(warehouse));
    };

    const handleReset = () => {
        reset({[STORAGE_NAME_INPUT]: ''});
    };

    return (
        <>
            <ContainerFlex
                backG="#FAFAFA"
                Gap="1rem"
                FlexDir="column"
                Padding="1rem 1.5rem 1rem 1.5rem"
                Align="start"
                Justify="start"
                Radius="1rem"
                MarginBt="1rem"
            >
                <ContainerFlex Justify="start" Align="center" Gap="0.5rem" Radius="1rem">
                    <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                        {warehouse.name}
                    </Text>
                    <Text FontSize="0.75rem" MarginTop="0.313rem">
                        {warehouse.storageDescription}
                    </Text>{' '}
                    <ActionMenu
                        warehouse={warehouse}
                        onEdit={onEditWarehouseName}
                        onDuplicate={onDuplicateWarehouse}
                        onDelete={onDeleteWarehouse}
                    />
                </ContainerFlex>
                <ContainerFlex
                    backG="#FFFFFF"
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="0.5rem"
                    Height="14rem"
                    Padding="0.5rem"
                    Radius="1rem"
                >
                    <Text FontSize="1rem" Color="#2A2C2F" FontWeight="700" oFlow="none">
                        {STORAGE.TYPE}
                        <Text FontSize="0.75rem" MarginLeft="0.5rem">
                            {STORAGE_TYPE_LEVELS.LEVEL_ONE}
                        </Text>
                    </Text>
                    <ContainerFlex Justify="start" Gap="0.5rem" Height="2.5rem" backG="#FFFFFF">
                        <Image src={AddIcon} alt="add" />
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            Cursor="pointer"
                            FontSize="1rem"
                            onClick={onNewLevel}
                        >
                            {STORAGE.ADD}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Justify="start"
                        FlexDir="column"
                        OverFlowY="auto"
                        Height={'none'}
                    >
                        {storageTypes &&
                            storageTypes.map((storageType, index) => (
                                <StorageData
                                    key={index}
                                    storageType={storageType}
                                    onSelectStorageType={onSelectStorageType}
                                    warehouse={warehouse}
                                    onEdit={() => {
                                        onEditStorageTypeName(storageType);
                                    }}
                                    onDelete={() => {
                                        onDeleteStorageType(storageType);
                                    }}
                                    onDuplicate={() => {
                                        onDuplicateStorageType(storageType);
                                    }}
                                />
                            ))}
                    </ContainerFlex>
                    {showNewLevelButton && (
                        <ContainerFlex
                            Position="relative"
                            FlexDir="column"
                            Height={'3.5rem'}
                            Width="21rem"
                        >
                            <SearchInput
                                GridColumn={storageName ? '85% 10% 5%' : '90% 10%'}
                                FontSize="1rem"
                                Right="none"
                            >
                                <input
                                    type="text"
                                    {...register(STORAGE_NAME_INPUT)}
                                    onKeyDown={onAddNewStorage}
                                    onBlur={() => {
                                        setShowNewLevelButton(false);
                                        handleReset();
                                    }}
                                    autoFocus
                                />
                                <Tooltip title={TOOLTIP_STORAGE}>
                                    <Image
                                        src={HelpIcon}
                                        alt="help"
                                        Cursor="pointer"
                                        Width="1.25rem"
                                        Height="1.25rem"
                                    />
                                </Tooltip>
                                {storageName && (
                                    <Image
                                        src={ClearIcon}
                                        alt="icon-clear"
                                        Cursor="pointer"
                                        Width="0.875rem"
                                        Height="0.875rem"
                                        onClick={handleReset}
                                    />
                                )}
                            </SearchInput>
                            {errors?.storageName?.message && (
                                <Text Color="#FF6357" FontSize="0.75rem" Self="start">
                                    {errors.storageName.message}
                                </Text>
                            )}
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
