import {ToastType} from '@General/Atoms/AppToast/interfaces';

export const toastColors: Record<ToastType, {background: string; textColor: string}> = {
    success: {
        background: '#E4F7EC',
        textColor: '#004461',
    },
    error: {
        background: '#FFE6E6',
        textColor: '#501111',
    },
    warning: {
        background: '#FFF5DB',
        textColor: '#614500',
    },
    info: {
        background: '#DBF4FF',
        textColor: '#004461',
    },
};

export const APPTOAST = {
    CLOSE: 'Cerrar',
    SIZE_SMALL: 'small',
    SIZE_LARGE: 'large',
};
