export const roleSettingsText = {
    roleSettings: 'Configuración de roles',
    roleSelect: 'Selecciona el rol del usuario',
    roleassign:
        'Asigna un rol administrativo que este usuario fungirá en la empresa, esto afectará los permisos y accesos que tiene dentro de la plataforma.',
    role: 'Rol',
    roleMessage: 'Si deseas configurar los acceso a roles ve a roles',
    roleLink: 'Ir a configurar roles',
    cancel: 'Descartar',
    save: 'Guardar',
    SELECT: 'Selecciona un rol',
    NOT_ROLES: 'El empleado no existe ó esta inactivo',
    MESSAGE_ERROR: 'No se puede enviar los cambios ya que el empleado no existe está ó inactivo ',
    NEW_ROL: 'Nuevo rol',
    ERROR: 'Usuario desactivado o eliminado',
};

export interface Option {
    id: number;
    label: string;
}

export const options: Option[] = [
    {id: 1, label: 'Admin'},
    {id: 2, label: 'Cajero'},
    {id: 3, label: 'Depositario'},
    {id: 4, label: 'Director'},
    {id: 5, label: 'Financiero'},
    {id: 6, label: 'Gerente divisional'},
    {id: 7, label: 'Gerente general'},
    {id: 8, label: 'Gerente regional'},
];
export const MAX_ROLES = 3;
export const MODAL_TEXT = {
    TITLE: 'Accesos de rol',
    ROL: 'Rol: ',
    ADMIN: 'Administrativo',
    OP: 'Operativo',
};
export const FUNCTION_TYPE = 'function';
