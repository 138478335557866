import React, {useEffect} from 'react';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import LoanList from '@components/GlobalQuoter/LoanList';
import QuoterContaier from '@components/GlobalQuoter/QuoterContaier';
import {getQuoterCompaniesLoans} from './Redux/Actions/GlobalQuoterFilterActions';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getFrequencyOptions} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFrequencyActions';

const GlobalQuoter = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {parsedAmount, accountPayments, frequencyId} = useSelector(
        (state: RootState) => state.globalQuoterFormReducer
    );

    useEffect(() => {
        if (token && accountPayments && frequencyId && parsedAmount) {
            dispatch(getQuoterCompaniesLoans(token, parsedAmount, frequencyId, accountPayments));
        }
    }, [token, parsedAmount, accountPayments, frequencyId]);

    useEffect(() => {
        if (token) dispatch(getFrequencyOptions(token));
    }, [token]);

    return (
        <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
            <QuoterContaier />
            <LoanList />
        </ContainerFlex>
    );
};

export default GlobalQuoter;
