import React, {useEffect} from 'react';
import {Controller} from 'react-hook-form';

import SelectGeneral from '@General/Atoms/Select/Select';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {HOLIDAYS_DEFAULT_VALUES, SCHEDULE} from '@components/LocationsBranch/constants';
import {IScheduleProps, IScheduleSlot} from '@components/ScheduleTable/interfaces/interfaces';

export const ScheduleTable = ({
    headers,
    scheduleSlots,
    control,
    errors,
    watch,
    setValue,
    isHolidays,
}: IScheduleProps) => {
    const dynamicSwitches = scheduleSlots?.map(
        (item) => item.toggleName.includes(`${HOLIDAYS_DEFAULT_VALUES.switch}`) && item.toggleName
    );

    const activeHolidays = () => {
        const switchHolidayGral = watch('switchHolidayGral');
        dynamicSwitches?.forEach((switchName) => {
            if (setValue && switchName) setValue(switchName, switchHolidayGral);
        });
    };

    const checkGralToggle = () => {
        if (setValue && isHolidays && scheduleSlots) {
            const allActive = scheduleSlots.every((item) => watch(item.toggleName) === true);
            setValue('switchHolidayGral', allActive);
        }
    };

    useEffect(() => {
        checkGralToggle();
    }, [scheduleSlots]);

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex
                Display="grid"
                GridColumns="0.8fr 0.8fr 0.8fr 0.8fr"
                Padding="0.5rem"
                Height="2.5rem"
                Gap="1rem"
                Bb="1px solid #E8E9EA"
            >
                {headers.map((header: string, index: number) => {
                    return (
                        <ContainerFlex Gap="0.5rem" key={index} Justify="start">
                            <Text
                                key={index}
                                FontSize="0.875rem"
                                Color="#2A2C2F"
                                FontWeight="700"
                                oFlow="none"
                            >
                                {header}
                            </Text>
                            {header.includes(SCHEDULE.STATE) && isHolidays && (
                                <Controller
                                    name={'switchHolidayGral'}
                                    control={control}
                                    defaultValue={false}
                                    render={({field: {onChange, ref, value, ...field}}) => (
                                        <GreenSwitch
                                            {...field}
                                            checked={value}
                                            inputRef={ref}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                if (onChange) onChange(e);
                                                activeHolidays();
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </ContainerFlex>
                    );
                })}
            </ContainerFlex>
            {scheduleSlots &&
                scheduleSlots.length > 0 &&
                scheduleSlots.map((item: IScheduleSlot, dayName) => (
                    <ContainerFlex
                        key={item.id}
                        Display="grid"
                        GridColumns="0.8fr 0.8fr 0.8fr 0.8fr"
                        Justify="start"
                        Height="3.5rem"
                        Gap="1rem"
                        Padding="0.5rem"
                    >
                        <Text FontSize="0.875rem" Color="#54575C">
                            {item.dayName}
                        </Text>
                        <Controller
                            name={item.toggleName}
                            control={control}
                            defaultValue={item.status}
                            render={({field: {onChange, ref, value = item.status, ...field}}) => (
                                <GreenSwitch
                                    {...field}
                                    checked={value}
                                    inputRef={ref}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        if (onChange) onChange(e);
                                        checkGralToggle();
                                    }}
                                />
                            )}
                        />

                        <Controller
                            name={item.openingSelectName}
                            control={control}
                            defaultValue={item.opening}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    defaultValue={item.opening}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={item.intervals}
                                    isDisabled={!watch(item.toggleName, item.status)}
                                    hasError={(errors?.opening?.message as string) ? true : false}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                    }}
                                />
                            )}
                        />
                        <Controller
                            name={item.closingSelectName}
                            control={control}
                            defaultValue={item.closing}
                            render={({field: {onChange, ...field}}) => (
                                <SelectGeneral
                                    {...field}
                                    defaultValue={item.closing}
                                    placeholder="Selecciona"
                                    isSearchable={false}
                                    options={item.intervals}
                                    hasError={(errors?.opening?.message as string) ? true : false}
                                    isDisabled={!watch(item.toggleName, item.status)}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                    }}
                                />
                            )}
                        />
                    </ContainerFlex>
                ))}
        </ContainerFlex>
    );
};
