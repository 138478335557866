import styled from '@emotion/styled';
import {Text} from '@Shopify/Ecommerce/styles';
import arrowDown from '@images/arrowDonw.svg';
import {
    IPropsDeadlineNew,
    IPropsDivConsult,
    IPropsDivMenu,
    IPropsGridColum,
    IPropsGridColumn,
    IPropsGridContainer,
    IPropsRowContainer,
    IPropsRowDemoGrid,
    IPropsTable,
    IPropsTableItem,
    IPropsTableItems,
    IPropsTableItemsContainer,
    IPropsTableRowHeadContract,
    IPropsTableRowItemsContract,
    IPropsUpperColumn,
} from '@DeadLines/interfacesStyles';

export const ContainerDeadline = styled.div`
    width: 100%;
    margin: auto;
`;
export const FilterList = styled.div`
    margin: auto;
    width: 100%;
    display: flex;
    font-weight: 400;
    justify-content: flex-end;
    cursor: pointer;
    padding-bottom: 0.5rem;
    padding-right: 1rem;
    i {
        font-size: 20px;
        margin-right: 0.5rem;
    }
`;

export const FontAwesomeIcon = styled.div<IPropsDivMenu>`
    cursor: pointer;
    i {
        font-size: 20px;
        margin-right: 0.5rem;
    }

    i:hover {
        color: #35cf44;
    }
`;
export const CardConsult = styled.div`
    max-width: 90vw;
    margin: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: none !important;
    border-radius: 0.25rem;
    margin-bottom: 2rem;
`;
export const ContainerFilter = styled.div`
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem 0;
    form {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 0;
    }
`;

export const DivConsult = styled.div<IPropsDivConsult>`
    display: flex;
    flex-direction: column;
    justify-content: ${(props) => props.end && 'flex-end'};
    height: ${(props) => props.ht && '30px'};
    padding-bottom: 1.5rem;
    width: ${(props) => (props.width ? '20%' : '13%')};
    padding-left: ${(props) => (props.pl ? '1.5rem' : '1.5rem')};
    padding-left: ${(props) => props.PL && '1.5rem'} !important;
    padding-right: ${(props) => (props.pr ? '1rem' : '1.5rem')};
    margin-top: ${(props) => (props.mt ? '1rem' : '3rem')};
    .css-g1d714-ValueContainer {
        height: 40px;
        align-content: center;
    }
    .css-1wa3eu0-placeholder {
        margin: auto;
    }
    .red {
        color: red;
    }
`;
export const ButtonConsult = styled.div`
    display: flex;
    justify-content: flex-end;
    height: 40px;
    width: 30%;
    margin-top: 3rem;
    padding-right: 1.5rem;
    button {
        border: 1px solid var(--black);
        text-transform: uppercase;
        border-radius: 5px;
        width: 160px;
        height: 40px;
        align-items: center;
        background-color: white !important;
        box-shadow: 0px 5px 10px rgba(167, 169, 172, 0.5);
    }
    button:hover {
        border-color: var(--green);
    }
`;
export const Inputs = styled.input`
    background: fixed;
    height: 40px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--gray-3);
    padding: 2px 12px;
`;
export const ArrowAuction = styled.div`
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
`;
export const CreateService = styled.div`
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: auto;
    width: 100%;

    button {
        text-align: center;
        letter-spacing: 0;
        text-transform: uppercase;
        cursor: pointer;
        border: 2px solid #414042;
        font-weight: 500;
        border-radius: 5px;
        padding: 7px 15px;
        box-shadow: inherit;
        background: #ffffff 0% 0% no-repeat padding-box;
        opacity: 1;
    }
    label {
        color: #4a4a4a;
        text-align: left;
        font: normal normal 14px/16px Work Sans;
        opacity: 1;
    }
`;
export const TableRowHeadContract = styled.div<IPropsTableRowHeadContract>`
    display: grid;
    grid-template-columns: ${(props) => props.Grid};
    height: ${(props) => props.Height};
    background: ${(props) => props.BGColor};
    border: ${(props) => props.Border};
    border-bottom: ${(props) => props.Bb};
    border-radius: 5px;
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};

    img {
        width: 10px;
        height: 10px;
        margin-left: 1rem;
        cursor: pointer;
    }
`;

TableRowHeadContract.defaultProps = {
    Grid: '0.3fr 0.5fr 1.5fr 1.5fr 1fr 1fr 1fr 0.2fr',
    Padding: '0.5rem 1rem',
    Bb: '1px solid #E8E9EA',
    Height: 'auto',
};

export const TableRowItemsContract = styled.div<IPropsTableRowItemsContract>`
    display: grid;
    grid-template-columns: ${(props) => props.Grid};
    background: ${(props) => props.Background};
    border: ${(props) => props.Border};
    border-bottom: ${(props) => props.Bb};
    padding: ${(props) => props.Padding};
    height: ${(props) => props.Height};

    &:hover {
        cursor: pointer;
        background-color: ${(props) => props.HBColor};
    }
    i {
        color: #757575;
        font-size: 13px;
    }
`;
TableRowItemsContract.defaultProps = {
    Bb: '1px solid #E8E9EA',
    Grid: '0.3fr 0.5fr 1.5fr 1.5fr 1fr 1fr 1fr 0.2fr',
    Padding: '0 1rem',
    Background: '#fff',
};

export const Table = styled.div<IPropsTable>`
    width: 100%;
    display: grid;
    box-sizing: border-box;
    box-shadow: 0px 0px 2px 0px rgba(167, 169, 172, 0.25);
    background: #fff;
    border-radius: 1rem;
    padding: ${(props) => props.Padding};
`;

export const TableItems = styled.div<IPropsTableItems>`
    display: flex;
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    justify-content: ${(props) => props.Justify};
    flex-direction: ${(props) => props.FlexDir};
    text-align: ${(props) => props.TextAlign};
    padding-bottom: ${(props) => props.PaddingBT};
    box-sizing: border-box;
    align-items: center;
    text-overflow: ellipsis;
    font-family: Work Sans;
    font-weight: 400;
    font-size: ${(props) => props.FontSize};
    padding: ${(props) => props.Padding};
    line-height: 20px;
    color: ${(props) => props.Color};
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
        box-shadow: none;
    }
    margin: ${(props) => props.Margin};
    span {
        cursor: pointer;
    }
    .inputForm {
        height: auto;
    }
    .number-format {
        align-items: center;
        text-overflow: ellipsis;
        font-family: Work Sans;
        font-weight: 400;
        font-size: 14px;
        height: 25px;
        line-height: 20px;
        color: #4a4a4a;
    }

    input,
    select {
        height: ${(props) => props.InputHeight};
        border: 1px solid var(--gray-4);
        outline-color: #35cf44;
    }

    select {
        width: 100%;
        color: #4a4a4a;
        font-size: 0.875rem;
        border-radius: 5px;
        padding: 0 0.5rem;
        background: url(${arrowDown}) no-repeat right 0.2rem center;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    select::-ms-expand {
        display: none;
    }

    input:disabled {
        background: var(--gray-5);
    }
`;

TableItems.defaultProps = {
    FontSize: '1rem',
    InputHeight: '40px',
    Color: '#4a4a4a',
};
export const Checkbox = styled.input`
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
`;

export const TableItem = styled.div<IPropsTableItem>`
    display: flex;
    position: ${(props) => props.Position};
    margin-left: ${(props) => props.MarginLeft};
    width: ${(props) => props.Width};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    font-size: ${(props) => props.FontSize};
    font-weight: ${(props) => props.FontWeight};
    height: ${(props) => props.Height};
    flex-direction: ${(props) => props.FlexDir};
    padding: ${(props) => props.Padding};
    padding-left: ${(props) => props.PaddingLeft};
    letter-spacing: 0px;
    max-width: ${(props) => props.MaxW};
    color: ${(props) => props.Color};
    box-sizing: border-box;
    cursor: ${(props) => props.CursorTable};
    gap: ${(props) => props.Gap};
    overflow: visible;
    border-top: ${(props) => props.BorderTop};
    span {
        cursor: ${(props) => props.Cursor};
        text-align: ${(props) => props.TextAlign};
        text-overflow: ellipsis;
        opacity: ${(props) => props.Filter};
    }
    > p {
        text-align: center;
        text-overflow: ellipsis;
    }
    &:hover div {
        visibility: ${(props) => props.HVisivilityDiv};
    }
`;
TableItem.defaultProps = {
    FontSize: '0.875rem',
    Cursor: 'pointer',
    Justify: 'center',
    Height: 'auto',
    Align: 'center',
};
export const TableItemsContainer = styled.div<IPropsTableItemsContainer>`
    background: #fff;
    display: grid;
    width: 100%;
    height: ${(props) => props.Height};
    max-height: ${(props) => props.MaxHeight};
    margin: ${(props) => props.Margin};
    overflow-y: ${(props) => props.OverY};
    grid-template-rows: ${(props) => props.GridRow};
    box-sizing: border-box;
    overflow: ${(props) => props.Overflow};
    justify-content: ${(props) => props.Justify};
    &::-webkit-scrollbar {
        width: 0.5rem;
    }
    &::-webkit-scrollbar-track {
        background-color: #a7a9ac00;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0.15rem 0.15rem;
        background-color: #cbced1;
    }
`;
TableItemsContainer.defaultProps = {
    GridRow: 'repeat(auto,4.313rem)',
};

export const DivPaginate = styled.div`
    .pagination {
        justify-content: center;
    }

    ul {
        list-style: none;
        padding: 0;
    }

    ul.pagination li {
        display: inline-block;
        width: 30px;
        border: 1px solid #e2e2e2;
        display: flex;
        justify-content: center;
        font-size: 25px;
        border-radius: 5px;
    }

    ul.pagination li a {
        text-decoration: none;
        color: #000;
        font-size: 14px;
    }

    ul.pagination li.active a {
        color: #7ac143;
    }
    ul.pagination li.active {
        background-color: #fff;
        border: 1px solid #7ac143;
    }

    ul.pagination li a:hover,
    ul.pagination li a.active {
        color: #7ac143;
    }

    .page-selection {
        width: 48px;
        height: 30px;
        color: #7ac143;
    }

    .pagination-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
`;

export const DeadlineNew = styled.button<IPropsDeadlineNew>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: none;
    border-bottom: 1px solid #eff0f2;
    font-size: 16px;
    width: 40%;
    height: 35px;
    background-color: #fff !important;
    &:focus {
        box-shadow: ${({Shadow}) => (Shadow ? '0px 5px 10px rgba(167, 169, 172, 0.5)' : 'none')};
        border-bottom: ${({Border}) => (Border ? '4px solid #7ac143' : 'none')};
        .material-icons,
        .text-focus {
            color: ${({Color}) => (Color ? 'var(--green)' : 'var(--black)')};
        }
        span {
            font-weight: ${({FontW}) => (FontW ? '300' : '500')};
            color: ${({Color}) => (Color ? '#cbced1' : 'var(--black)')};
        }
    }

    i {
        font-size: 50px;
    }
`;

export const TestExample = styled(Text)`
    &:hover {
        cursor: pointer;
    }
`;
export const IconContainer = styled.img`
    width: 24px;
    height: 16px;
`;
export const ContextMenu = styled.div`
    position: absolute;
    background: white;
    padding: 0 0;
    box-shadow: 0px 1px 3px #00000033;
`;
export const OptionsMenu = styled.div`
    padding: 6px 50px 5px 10px;
    width: 100%;
    cursor: default;
    font-size: 1rem;
    &:hover {
        background: rgba(122, 193, 67, 0.2);
        color: white;
    }

    &:active {
        color: white;
        background: rgba(122, 193, 67, 0.2);
    }
`;
export const MenuSeparator = styled.div`
    width: 100%;
    height: 1px;
    background: white;
    margin: 0 0 0 0;
`;
export const RowDemoGrid = styled.div<IPropsRowDemoGrid>`
    grid-column: ${(props) => props.GridColumn};
    justify-content: flex-start;
    align-items: flex-start;
    background: #fff;
    height: 41px;
`;
export const GridColum = styled.div<IPropsGridColum>`
    justify-content: flex-start;
    align-items: flex-start;
    grid-column: ${(props) => props.GridCol};
    font-size: ${({gridColFont}) => gridColFont};
    justify-content: ${({gridJustCont}) => gridJustCont};
    margin-left: ${(props) => props.MarginLf};
    padding: ${({padding}) => padding};
    border-left: ${(props) => props.BorderLf};
`;

export const UpperColumn = styled.div<IPropsUpperColumn>`
    justify-content: flex-start;
    align-items: flex-start;
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    grid-row-gap: 20px;
    grid-column-gap: 12px;
    padding: 0;
    /* margin-top: 15px; */
    border-top: ${(props) => props.Bt};
    border-bottom: ${(props) => props.Bb};
`;

export const GridContainer = styled.div<IPropsGridContainer>`
    background: #fff;
    display: grid;
    grid-template-rows: ${(props) => props.GridRow};
`;
export const GridColumn = styled.div<IPropsGridColumn>`
    display: grid;
    margin-top: 5px;
    padding-top: 25px;
    border: ${(props) => props.Border};
    grid-template-columns: ${(props) => props.GridColumn};
    background: #fff;
    cursor: pointer;
    min-height: ${(props) => props.MinH};
    max-height: ${(props) => props.MaxH};
    height: ${(props) => props.Height};
`;

export const RowContainer = styled.div<IPropsRowContainer>`
    display: flex;
    text-transform: lowercase;
    width: ${(props) => props.Width};
    justify-content: ${(props) => props.Justify};
    padding-bottom: ${(props) => props.PaddingBT};
    align-items: ${(props) => props.Align};
    font-size: 1rem;
`;
RowContainer.defaultProps = {
    Align: 'flex-start',
};
export const MenuContainer = styled.div`
    padding: 0.5rem;
    border-radius: 0.286rem;
    width: 20rem;
    border: 1px solid #e4e7e9;
    background: #fff;
    flex-direction: column;
    position: absolute;
    box-shadow: 0px 1px 3px #00000033;
    height: 224px;
    overflow-y: auto;
    top: 300px;
`;
