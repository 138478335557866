import React from 'react';
import Switch from '@mui/material/Switch';
import {PurpleSwitchProps} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';

export const PurpleSwitchBlue: React.FC<PurpleSwitchProps> = ({checked, onChange}) => {
    return (
        <Switch
            checked={checked}
            onChange={onChange}
            sx={{
                width: 48,
                height: 24,
                padding: 0,
                borderRadius: 16,
                '& .MuiSwitch-switchBase': {
                    color: '#FFF',
                    padding: 0,
                    margin: 0.25,
                    boxShadow: '1px 1px 4px rgba(212, 214, 216, 0.88)',
                    '&.Mui-checked': {
                        color: '#5A5AFF',
                        transform: 'translateX(24px)',
                        boxShadow: '1px 1px 4px rgba(172, 172, 255, 0.88)',
                    },
                    '&.Mui-checked + .MuiSwitch-track': {
                        backgroundColor: '#E5E5FF',
                        opacity: 1,
                    },
                },
                '& .MuiSwitch-track': {
                    backgroundColor: '#F4F5F5',
                    borderRadius: 16,
                    opacity: 1,
                    boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset',
                },
                '& .MuiSwitch-thumb': {
                    boxShadow: 'none',
                },
            }}
        />
    );
};
