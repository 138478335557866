import React from 'react';
import {ContainerFlex, Image} from '@components/Shopify/Ecommerce/styles';
import Article from '@images/270493_ycoxxa.png';

export const ImagesAuction = () => {
    return (
        <ContainerFlex Justify="center" Align="center" ColumnGap="46.5px" Height="418px">
            <ContainerFlex Gap="16px" FlexDir="column" Align="end" Justify="center" Width="10.9rem">
                <Image
                    Padding="2px"
                    Height="76.5px"
                    Width="7.125rem"
                    Radius="8px"
                    Border="1px solid #A1A5AA"
                    alt="article"
                    ObjectFit="contain"
                    src={Article}
                />
                <Image
                    Padding="2px"
                    Height="76.5px"
                    Width="7.125rem"
                    Radius="8px"
                    Border="1px solid #A1A5AA"
                    alt="article"
                    ObjectFit="contain"
                    src={Article}
                />
                <Image
                    Padding="2px"
                    Height="76.5px"
                    Width="7.125rem"
                    Radius="8px"
                    Border="1px solid #A1A5AA"
                    alt="article"
                    ObjectFit="contain"
                    src={Article}
                />

                <Image
                    Padding="2px"
                    Height="76.5px"
                    Width="7.125rem"
                    Radius="8px"
                    Border="1px solid #A1A5AA"
                    alt="article"
                    ObjectFit="contain"
                    src={Article}
                />
            </ContainerFlex>
            <ContainerFlex Justify="end" Width="37.5%">
                <Image
                    Radius="8px"
                    Border="1px solid #A1A5AA"
                    Width="100%"
                    Height="100%"
                    ObjectFit="contain"
                    alt="article"
                    src={Article}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
