import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_QUOTER_COMPANIES_LOAN_ERROR,
    GET_QUOTER_COMPANIES_LOAN_START,
    GET_QUOTER_COMPANIES_LOAN_SUCCESS,
    GET_SELECTED_QUOTER_CREDIT,
} from '@components/CreditQuoter/Redux/Types/Types';
import {IQuoterCompaniesLoans} from '@components/CreditQuoter/interfaces';

const initialState: IQuoterCompaniesLoans = {
    dataQuoter: null,
    loading: false,
    error: false,
    errorData: null,
    termPersonalLoanId: 0,
};

const GetQuoterCompaniesLoansAditionalInf = (
    state = initialState,
    action: {type: string; error: AxiosError; payload: AxiosResponse; item: number}
): IQuoterCompaniesLoans => {
    switch (action.type) {
        case GET_QUOTER_COMPANIES_LOAN_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_QUOTER_COMPANIES_LOAN_SUCCESS:
            return {...state, dataQuoter: action.payload.data, loading: false, error: false};
        case GET_QUOTER_COMPANIES_LOAN_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
                errorData: action.error,
            };
        case GET_SELECTED_QUOTER_CREDIT:
            return {...state, termPersonalLoanId: action.item, loading: false, error: false};
        default:
            return state;
    }
};

export default GetQuoterCompaniesLoansAditionalInf;
