import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_TERM_PERSONAL_LOAN_ERROR,
    GET_TERM_PERSONAL_LOAN_START,
    GET_TERM_PERSONAL_LOAN_SUCCESS,
    RESET_GET_TERM_PERSONAL_LOAN,
} from '@components/LoansProducts/Redux/Types/Types';
import {GetPersonalTermReducer} from '@components/LoansProducts/interfaces';

const initialState: GetPersonalTermReducer = {
    dataLoans: null,
    loading: false,
    error: false,
    errorData: null,
};

export const GetTermPersonalLoan = (
    state = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): GetPersonalTermReducer => {
    switch (action.type) {
        case GET_TERM_PERSONAL_LOAN_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_TERM_PERSONAL_LOAN_SUCCESS:
            return {...state, dataLoans: action.payload.data.data, loading: false, error: false};
        case GET_TERM_PERSONAL_LOAN_ERROR:
            return {
                ...state,
                errorData: action.error,
                loading: false,
                error: true,
            };
        case RESET_GET_TERM_PERSONAL_LOAN:
            return initialState;
        default:
            return state;
    }
};
