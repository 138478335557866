import {
    CREATE_REQUEST_LOAN_START,
    CREATE_REQUEST_LOAN_SUCCESS,
    CREATE_REQUEST_LOAN_ERROR,
    GET_CREDIT_LOAN_SUMMARY_START,
    GET_CREDIT_LOAN_SUMMARY_SUCCESS,
    GET_CREDIT_LOAN_SUMMARY_ERROR,
} from '@components/PersonalLoans/Redux/Types/Types';
import {IPersonalLoanRequestSummaryReducer} from '@components/PersonalLoans/interfaces';
import {AxiosResponse, AxiosError} from 'axios';

const initialState: IPersonalLoanRequestSummaryReducer = {
    solicitudeId: null,
    numberSolicitude: null,
    amountRequested: null,
    acoutnPayments: null,
    frecuencyId: null,
    frecuencyName: null,
    isLoading: false,
    error: false,
};

const personalLoanRquestSummaryReducer = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        error: AxiosError;
    }
) => {
    switch (action.type) {
        case CREATE_REQUEST_LOAN_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case CREATE_REQUEST_LOAN_SUCCESS:
            return {
                ...state,
                solicitudeId: action.payload.data.solicitudeId,
                isLoading: false,
                error: false,
            };
        case CREATE_REQUEST_LOAN_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        case GET_CREDIT_LOAN_SUMMARY_START:
            return {
                ...state,
                isLoading: true,
                error: false,
            };
        case GET_CREDIT_LOAN_SUMMARY_SUCCESS:
            return {
                ...state,
                numberSolicitude: action.payload.data.data.numberSolicitude,
                amountRequested: action.payload.data.data.amountRequested,
                acoutnPayments: action.payload.data.data.acoutnPayments,
                frecuencyId: action.payload.data.data.frecuencyId,
                frecuencyName: action.payload.data.data.frecuencyName,
                isLoading: false,
                error: false,
            };
        case GET_CREDIT_LOAN_SUMMARY_ERROR:
            return {
                ...state,
                isLoading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default personalLoanRquestSummaryReducer;
