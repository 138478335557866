import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CREDIT_PERSONAL_LOAN_SUMMARY_ERROR,
    GET_CREDIT_PERSONAL_LOAN_SUMMARY_START,
    GET_CREDIT_PERSONAL_LOAN_SUMMARY_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    ICreditLoanSummaryAPIParams,
    ICreditLoanSummaryParams,
} from '@/components/PersonalLoans/interfaces';

const getCreditLoanSummaryStart = () => {
    return {
        type: GET_CREDIT_PERSONAL_LOAN_SUMMARY_START,
    };
};

const getCreditLoanSummarySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CREDIT_PERSONAL_LOAN_SUMMARY_SUCCESS,
        payload: result,
    };
};

const getCreditLoanSummaryError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_PERSONAL_LOAN_SUMMARY_ERROR,
        error: error,
    };
};

function getCreditLoanSummaryAction({
    token,
    personalCredit,
    termsConditions,
}: ICreditLoanSummaryParams) {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditLoanSummary}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const params: ICreditLoanSummaryAPIParams = {
        personalCredit,
        termsConditions,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditLoanSummaryStart());
        try {
            const response = await axios.get(url, {headers, params});
            dispatch(getCreditLoanSummarySuccess(response.data));
        } catch (e) {
            dispatch(getCreditLoanSummaryError(e as AxiosError));
        }
    };
}

export default getCreditLoanSummaryAction;
