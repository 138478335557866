import React from 'react';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IErrorMessageProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const ErrorMessage: React.FC<IErrorMessageProps> = ({message = ''}) => {
    return (
        <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem" Height="max-content" oFlow="none">
            <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
            {message}
        </Text>
    );
};
