import {
    GET_LEVEL_TREE_REQUEST,
    GET_LEVEL_TREE_SOUCCES,
    GET_LEVEL_TREE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

const initialState = {
    loading: false,
    userData: [],
    error: null,
};

export const GetLevelBranchUser = (state = initialState, action) => {
    switch (action.type) {
        case GET_LEVEL_TREE_REQUEST:
            return {...state, loading: true, error: null};
        case GET_LEVEL_TREE_SOUCCES:
            return {...state, loading: false, userData: action.payload.data || []};
        case GET_LEVEL_TREE_ERROR:
            return {...state, loading: false, error: action.payload.error};
        default:
            return state;
    }
};
