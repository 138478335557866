import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import StringUtils from '@/GenericScripts/utils';
import {URL} from '@config/constants/index';

import {GET_USER_LOCATION_ID} from '@components/LocationsBranch/Redux/types';
import {User} from '@components/LocationsBranch/interfaces';

export const getUsersLocationStart = () => ({type: GET_USER_LOCATION_ID.USER_LOCATION_START});

export const getUsersLocationSuccess = (result: {data: AxiosResponse}) => ({
    type: GET_USER_LOCATION_ID.USER_LOCATION_SUCCESS,
    payload: {
        usersLocation: result.data,
    },
});

export const getUsersLocationError = (error: AxiosError) => ({
    type: GET_USER_LOCATION_ID.USER_LOCATION_ERROR,
    error: error,
});

export const getUsersByLocation = (
    data: {
        LocationId: number;
        TypeLocation: number;
    },
    token: string,
    setUsers: (data: User[]) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getUsersLocationStart());
        const queryString = StringUtils.jsonToQueryString({...data});

        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUsersByLocationId}${queryString}`,
                {headers}
            );
            dispatch(getUsersLocationSuccess(response.data));
            if (setUsers) setUsers(response.data.data);
        } catch (error) {
            dispatch(getUsersLocationError(error as AxiosError));
        }
    };
};
