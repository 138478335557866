import {IRoleModules} from '@components/RolesPermissions/NewRole/interfaces';

export const calculateTotalModules = (modules: IRoleModules[]): number => {
    let count = 0;
    modules.forEach((module) => {
        if (module.nextLevel.length > 0) {
            count += 1;
            count += calculateTotalModules(module.nextLevel);
        } else {
            count += 1;
        }
    });
    return count;
};

export const calculateCheckedModules = (modules: IRoleModules[]): number => {
    let total: number = 0;
    modules.forEach((module) => {
        if (module.nextLevel.length > 0) {
            total += 1;
        }
    });
    return total;
};
