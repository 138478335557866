import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';
import {Controller, useForm} from 'react-hook-form';

import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {
    TableRowHeadContract,
    TableRowItemsContract,
} from '@DeadLines/CreateDeadline/DeadlineSteps/AmountAndTimes/styles';
import {Table, TableItem, TableItems, TableItemsContainer} from '@DeadLines/styles';
import {dealinesTable, tableDeadline} from '@CreateDeadline/interfaces';
import {StyleNumericFormat} from '@Loan/styles';
import {limitNumberFormat, PercentageFormat, QuitNumberFormat} from '@/hooks/currentFormatUtils';
import {MenuIcons} from '@/components/Roles/styles';
import edit from '@images/edit.svg';
import {tableDeadlines} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import {Icons} from '@/components/Articles/style';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ClickAwayListener} from '@mui/material';

export const TableDealines = (props: dealinesTable) => {
    const dispatch: AppDispatch = useDispatch();
    const {deadlinesGeneral, deadlineById} = props;
    const [select, setSelect] = useState(false);
    const [visible, setVisible] = useState(false);
    const [fixed, setFixed] = useState(false);
    const {handleSubmit, register, reset, getValues, control} = useForm({
        defaultValues: {
            interestPercentage: '',
            daysGrace: '',
            interestMoratoriums: '',
            typePeriod: '',
            numberPeriods: '',
            printedPrefix: '',
            visible: visible,
            fixedInterest: fixed,
        },
    });

    const onSubmit = () => {
        dispatch(tableDeadlines(deadlinesGeneral.gracePhase));
    };

    const handleSaveDeadlines = (value: tableDeadline) => {
        if (
            value.interestPercentage !== '' &&
            value.daysGrace !== '' &&
            value.interestMoratoriums !== '' &&
            value.typePeriod !== '' &&
            value.numberPeriods !== '' &&
            value.printedPrefix !== ''
        ) {
            const table = {
                order: deadlinesGeneral.gracePhase.length + 1,
                interestPercentage: QuitNumberFormat(value.interestPercentage),
                daysGrace: QuitNumberFormat(value.daysGrace),
                interestMoratoriums: QuitNumberFormat(value.interestMoratoriums),
                typePeriod: QuitNumberFormat(value.typePeriod),
                numberPeriods: QuitNumberFormat(value.numberPeriods),
                printedPrefix: value.printedPrefix.replace(/['"]+/g, ''),
                visible: visible,
                fixedInterest: fixed,
            };
            dispatch(tableDeadlines([...deadlinesGeneral.gracePhase, table]));
            setFixed(false);
            setVisible(false);
            reset({
                interestPercentage: '',
                daysGrace: '',
                interestMoratoriums: '',
                typePeriod: '',
                numberPeriods: '',
                printedPrefix: '',
                visible: visible,
                fixedInterest: fixed,
            });
        }
    };
    const handleEdit = (id: number) => {
        const editDealines = deadlinesGeneral.gracePhase.filter(
            (item: {order: number}) => item.order === id
        )[0];

        if (
            deadlinesGeneral.gracePhase.filter((item: {order: number}) => item.order === id)
                .length > 0
        ) {
            reset({
                interestPercentage: PercentageFormat(editDealines.interestPercentage),
                daysGrace: String(editDealines.daysGrace),
                interestMoratoriums: PercentageFormat(editDealines.interestMoratoriums),
                typePeriod: String(editDealines.typePeriod),
                numberPeriods: String(editDealines.numberPeriods),
                printedPrefix: editDealines.printedPrefix,
            });
            setFixed(editDealines.fixedInterest);
            setVisible(editDealines.visible);
            dispatch(
                tableDeadlines(
                    deadlinesGeneral.gracePhase.filter((item: {order: number}) => item.order !== id)
                )
            );
        }
    };

    const actions = () => {
        let label = 'Seleccione';
        if (visible && !fixed) return (label = 'Visible');
        if (fixed && !visible) return (label = 'Fijo');
        if (visible && fixed) return (label = 'Visible, fijo');
        return label;
    };

    const periodType = (position: number) => {
        switch (position) {
            case 2:
                return 'Semanal';
            case 3:
                return 'Quincenal';
            case 4:
                return 'Mensual';
            default:
                return 'Diario';
        }
    };

    const handleClickAway = () => setSelect(false);

    return (
        <FormContainer
            MarginTp="0"
            onSubmit={handleSubmit(onSubmit)}
            Width="100%"
            autoComplete="off"
        >
            <Table>
                <TableRowHeadContract
                    Height="48px"
                    Grid={
                        deadlineById?.edit
                            ? '0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr'
                            : '0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.20fr'
                    }
                >
                    <TableItem Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Tasa Interés
                        </Text>
                    </TableItem>
                    <TableItem Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Días de gracia
                        </Text>
                    </TableItem>
                    <TableItem Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Interés moratorio
                        </Text>
                    </TableItem>
                    <TableItem Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Periodo
                        </Text>
                    </TableItem>
                    <TableItem Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Cantidad de periodos
                        </Text>
                    </TableItem>
                    <TableItem Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Prefijo impreso
                        </Text>
                    </TableItem>
                    <TableItem Padding="0 1rem" Justify="center">
                        <Text wSpace="normal" Height="auto" FontSize="0.857rem" FontWeight="500">
                            Acción
                        </Text>
                    </TableItem>
                    <TableItem />
                </TableRowHeadContract>
                {!deadlineById?.edit && (
                    <TableItemsContainer>
                        <TableRowItemsContract
                            Height="60px"
                            Grid={
                                deadlineById?.edit
                                    ? '0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr'
                                    : '0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.20fr'
                            }
                        >
                            <TableItems InputHeight="40px" Width="85%">
                                <Controller
                                    name="interestPercentage"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            textAlign="center"
                                            type="text"
                                            suffix="%"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            isAllowed={(values) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </TableItems>
                            <TableItems InputHeight="40px" Width="85%">
                                <Controller
                                    name="daysGrace"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            textAlign="center"
                                            id="daysGrace"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            maxLength={16}
                                            onKeyDown={(e: {
                                                keyCode: number;
                                                preventDefault: () => void;
                                            }) => {
                                                if (e.keyCode === 190) e.preventDefault();
                                            }}
                                        />
                                    )}
                                />
                            </TableItems>
                            <TableItems InputHeight="40px" Width="85%">
                                <Controller
                                    name="interestMoratoriums"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            textAlign="center"
                                            type="text"
                                            suffix="%"
                                            decimalSeparator="."
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            isAllowed={(values) =>
                                                limitNumberFormat(
                                                    QuitNumberFormat(values.value),
                                                    0,
                                                    1000
                                                )
                                            }
                                        />
                                    )}
                                />
                            </TableItems>
                            <TableItems Width="85%">
                                <select id="typePeriod" {...register('typePeriod')}>
                                    <option selected disabled value="">
                                        Seleccione
                                    </option>
                                    <option value={1}>Diario</option>
                                    <option value={2}>Semanal</option>
                                    <option value={3}>Quincenal</option>
                                    <option value={4}>Mensual</option>
                                </select>
                            </TableItems>
                            <TableItems InputHeight="40px" Width="85%">
                                <Controller
                                    name="numberPeriods"
                                    control={control}
                                    render={({field: {ref, ...field}}) => (
                                        <StyleNumericFormat
                                            {...field}
                                            getInputRef={ref}
                                            textAlign="center"
                                            type="text"
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            allowLeadingZeros={false}
                                            maxLength={16}
                                            onKeyDown={(e: {
                                                keyCode: number;
                                                preventDefault: () => void;
                                            }) => {
                                                if (e.keyCode === 190) e.preventDefault();
                                            }}
                                        />
                                    )}
                                />
                            </TableItems>
                            <TableItems InputHeight="40px" Width="85%">
                                <Input
                                    Color="#414042"
                                    FontSize="0.875rem"
                                    HolderColor="#e4e7e9"
                                    TextAlign="center"
                                    {...register('printedPrefix')}
                                    maxLength={1}
                                    onKeyDown={(e: {
                                        keyCode: number;
                                        preventDefault: () => void;
                                    }) => {
                                        if (e.keyCode >= 96 && e.keyCode <= 105) e.preventDefault();
                                    }}
                                />
                            </TableItems>
                            <TableItems Width="85%">
                                <ContainerFlex
                                    ZIndex="1"
                                    Position="relative"
                                    onClick={() => setSelect(true)}
                                    FlexDir="column"
                                >
                                    <ContainerFlex
                                        Radius="5px"
                                        Border="1px solid #E4E7E9"
                                        Height="40px"
                                        ZIndex="1"
                                        Position="relative"
                                        Justify="space-between"
                                    >
                                        <Text FontSize="0.875rem" MarginLeft="0.571rem">
                                            {actions()}
                                        </Text>
                                        <Icons
                                            className="material-icons"
                                            FontSize="1.5rem"
                                            Margin="0 0.2rem 0 0"
                                        >
                                            keyboard_arrow_down
                                        </Icons>
                                    </ContainerFlex>
                                    {select && (
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <ContainerFlex
                                                backG="#fff"
                                                ZIndex="2"
                                                Padding="0.75rem"
                                                Border="1px solid #E4E7E9"
                                                FlexDir="column"
                                                Radius="5px"
                                                PositionTop="52px"
                                                Position="absolute"
                                            >
                                                <ContainerFlex Justify="start">
                                                    <Checkbox
                                                        checked={visible}
                                                        onChange={() => setVisible(!visible)}
                                                    />
                                                    <Text MarginLeft="0.571rem" wSpace="normal">
                                                        Visible
                                                    </Text>
                                                </ContainerFlex>
                                                <ContainerFlex Justify="start">
                                                    <Checkbox
                                                        checked={fixed}
                                                        onChange={() => setFixed(!fixed)}
                                                    />
                                                    <Text MarginLeft="0.571rem" wSpace="normal">
                                                        Fijo
                                                    </Text>
                                                </ContainerFlex>
                                            </ContainerFlex>
                                        </ClickAwayListener>
                                    )}
                                </ContainerFlex>
                            </TableItems>

                            {!deadlineById?.edit && (
                                <TableItems Justify="center">
                                    <span
                                        className="material-icons"
                                        onClick={() => {
                                            getValues([
                                                'interestPercentage',
                                                'daysGrace',
                                                'interestMoratoriums',
                                                'typePeriod',
                                                'numberPeriods',
                                                'printedPrefix',
                                                'visible',
                                                'fixedInterest',
                                            ]);
                                            const getData = getValues();
                                            handleSaveDeadlines(getData);
                                        }}
                                    >
                                        save
                                    </span>
                                </TableItems>
                            )}
                        </TableRowItemsContract>
                    </TableItemsContainer>
                )}
                {deadlinesGeneral.gracePhase.map((item, index: number) => (
                    <TableItemsContainer key={index}>
                        <TableRowItemsContract
                            Height="48px"
                            Border="1px solid #E4E7E9"
                            key={index}
                            Grid={
                                deadlineById?.edit
                                    ? '0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr'
                                    : '0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.35fr 0.20fr'
                            }
                        >
                            <TableItems FontSize="0.875rem" Justify="center">
                                {PercentageFormat(item.interestPercentage)}
                            </TableItems>
                            <TableItems FontSize="0.875rem" Justify="center">
                                {item.daysGrace}
                            </TableItems>
                            <TableItems FontSize="0.875rem" Justify="center">
                                {PercentageFormat(item.interestMoratoriums)}
                            </TableItems>
                            <TableItems FontSize="0.875rem" Justify="center">
                                {periodType(item.typePeriod)}
                            </TableItems>
                            <TableItems FontSize="0.875rem" Justify="center">
                                {item.numberPeriods}
                            </TableItems>
                            <TableItems FontSize="0.875rem" Justify="center">
                                {item.printedPrefix}
                            </TableItems>
                            <TableItems FontSize="0.875rem" Justify="center">
                                {item.fixedInterest && item.visible && 'Visible, Fijo'}
                                {item.visible && !item.fixedInterest && 'Visible'}
                                {item.fixedInterest && !item.visible && 'Fijo'}
                                {!item.fixedInterest && !item.visible && 'Ninguno'}
                            </TableItems>
                            {!deadlineById?.edit && (
                                <TableItems Justify="center">
                                    <MenuIcons
                                        Cursor="pointer"
                                        Width="1.25rem"
                                        Height="1.25rem"
                                        onClick={() => handleEdit(item.order)}
                                        src={edit}
                                    />
                                </TableItems>
                            )}
                        </TableRowItemsContract>
                    </TableItemsContainer>
                ))}
            </Table>
        </FormContainer>
    );
};
