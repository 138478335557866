import {AxiosResponse} from 'axios';
import {
    CREATE_TERM_PERSONAL_LOAN_START,
    CREATE_TERM_PERSONAL_LOAN_SUCCESS,
    CREATE_TERM_PERSONAL_LOAN_ERROR,
    VALIDATE_TERM_NAME_ERROR,
    VALIDATE_TERM_NAME_START,
    VALIDATE_TERM_NAME_SUCCESS,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {ICreateTermPersonalLoanReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/reducerInterfaces';

const initialState: ICreateTermPersonalLoanReducer = {
    data: {
        tokenExpiration: '',
        succeeded: false,
        message: null,
        errors: null,
        token: '',
        data: 0,
    },
    loading: false,
    error: false,
    isTermNameUnique: true,
};

const createTermPersonalLoanReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): ICreateTermPersonalLoanReducer => {
    switch (action.type) {
        case CREATE_TERM_PERSONAL_LOAN_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case CREATE_TERM_PERSONAL_LOAN_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case CREATE_TERM_PERSONAL_LOAN_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case VALIDATE_TERM_NAME_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case VALIDATE_TERM_NAME_SUCCESS:
            return {
                ...state,
                isTermNameUnique: action.payload.data.trim() === '',
                loading: false,
                error: false,
            };
        case VALIDATE_TERM_NAME_ERROR:
            return {
                ...initialState,
                error: true,
            };
        default:
            return state;
    }
};

export default createTermPersonalLoanReducer;
