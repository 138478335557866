import {GET_USER_LOCATION_ID} from '@components/LocationsBranch/Redux/types';
import {UsersLocations, UsersLocationsAction} from '@components/LocationsBranch/Redux/interfaces';

const initialState: UsersLocations = {
    error: false,
    loading: false,
    usersLocation: [],
};

const getUsersLocation = (state = initialState, action: UsersLocationsAction): UsersLocations => {
    switch (action.type) {
        case GET_USER_LOCATION_ID.USER_LOCATION_ERROR:
            return {...state, loading: false, error: false};

        case GET_USER_LOCATION_ID.USER_LOCATION_START:
            return {...state, loading: true};
        case GET_USER_LOCATION_ID.USER_LOCATION_SUCCESS:
            return {
                ...state,
                usersLocation: action.payload.usersLocation,
                loading: false,
                error: false,
            };
        default:
            return state;
    }
};

export default getUsersLocation;
