import React from 'react';
import {ContainerFlex, Input, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {
    BRAND,
    CATEGORIES,
    COLOR,
    MODEL,
    STATE,
    TITLES,
} from '@/components/AuctionPass/AuctionConstants';
import SelectGeneral from '@components/General/Atoms/Select/Select';
import questionImg from '@images/searchAtaskate.svg';
import {IinputTypes} from '@components/AuctionPass/interface';
import {Controller} from 'react-hook-form';
import {Select} from '@/components/Quoter/Steps/styles';
import arrowDown from '@components/PersonalLoans/NewLoanProduct/icons/arrowDown.svg';
import {DropdownIndicatorProps, components} from 'react-select';
import CalendarDateSelection from '@components/General/Atoms/CalendarDateSelection/CalendarDateSelection';
import {DateTime} from 'luxon';
const DropdownIndicator: React.FC<DropdownIndicatorProps> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <ContainerFlex Gap="0.25rem" Width="max-content" Height="max-content">
                <ContainerFlex
                    Width="24px"
                    Height="24px"
                    FlexDir="column"
                    Position="relative"
                    Justify="start"
                >
                    <Image
                        src={questionImg}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        alt="question-icon"
                    />
                </ContainerFlex>
                <Image src={arrowDown} Width="24px" Height="24px" alt="arrow-down-icon" />
            </ContainerFlex>
        </components.DropdownIndicator>
    );
};
export const FormAuctionInputs = ({register, control, errors}: IinputTypes) => {
    const today: Date = DateTime.now().toJSDate();

    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Padding="0 16px"
            FlexDir="column"
            Height="268px"
        >
            <ContainerFlex Gap="32px" FlexDir="column" Display="grid" GridColumns="repeat(3,1fr)">
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.NAME}</Text>
                    <Input
                        Height="40px"
                        error={errors.name?.message ? true : false}
                        {...register('name')}
                        type="text"
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.CATEGORY}</Text>
                    <Controller
                        name="category"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder={TITLES.SELECT}
                                isSearchable={false}
                                hasError={!!errors.category?.label?.message}
                                options={CATEGORIES}
                                isDisabled
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.BRAND}</Text>
                    <Controller
                        name="brand"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder={TITLES.SELECT}
                                isSearchable={false}
                                hasError={!!errors.brand?.label?.message}
                                options={BRAND}
                                isDisabled
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.MODEL}</Text>
                    <Controller
                        name="model"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder={TITLES.SELECT}
                                isSearchable={false}
                                hasError={!!errors.model?.label?.message}
                                options={MODEL}
                                isDisabled
                                onChange={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.SKU}</Text>
                    <Input Height="40px" error={errors.sku?.message} disabled type="text" />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.COLOR}</Text>
                    <ContainerFlex Position="relative">
                        <Controller
                            name="color"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Select
                                    {...field}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    options={COLOR}
                                    placeholder={TITLES.SELECT}
                                    padding="0px"
                                    controlTop="-6px"
                                    radius="2rem"
                                    isDisabled
                                    components={{DropdownIndicator}}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.STATE}</Text>
                    <ContainerFlex Position="relative">
                        <Controller
                            name="condition"
                            control={control}
                            render={({field: {onChange, ...field}}) => (
                                <Select
                                    {...field}
                                    noOptionsMessage={() => 'Sin opciones'}
                                    options={COLOR}
                                    placeholder={TITLES.SELECT}
                                    padding="0px"
                                    controlTop="-6px"
                                    radius="2rem"
                                    isDisabled
                                    components={{DropdownIndicator}}
                                    onChange={(e) => {
                                        onChange(e);
                                    }}
                                />
                            )}
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Height="68px"
                    Gap="4px"
                >
                    <Text FontSize="0.75rem">{TITLES.DATE}</Text>
                    <Controller
                        name="date"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <CalendarDateSelection
                                {...field}
                                placeholder=" /  /"
                                format="dd/MM/yyyy"
                                value={today}
                                type="1"
                                gap="0.5rem"
                                error={!!errors.date?.message}
                                onSelect={(e) => {
                                    onChange(e);
                                }}
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontSize="0.75rem">{TITLES.LOCATION}</Text>
                    <Controller
                        name="ubication"
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <SelectGeneral
                                {...field}
                                placeholder={TITLES.SELECT}
                                isSearchable={false}
                                hasError={!!errors.condition?.label?.message}
                                options={STATE}
                                onChange={(e) => {
                                    onChange(e);
                                }}
                                isDisabled
                            />
                        )}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
