import {AxiosResponse} from 'axios';
import {
    GET_LEVEL_TREE_BRANCH_START,
    GET_LEVEL_TREE_BRANCH_SUCCESS,
    GET_LEVEL_TREE_BRANCH_ERROR,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {IGetLevelTreeBranchReducer} from '@components/NewUserEmployee/Redux//interfaces';
const initialState: IGetLevelTreeBranchReducer = {
    data: [],
    loading: false,
    error: false,
};
const getLevelTreeBranchReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetLevelTreeBranchReducer => {
    switch (action.type) {
        case GET_LEVEL_TREE_BRANCH_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_LEVEL_TREE_BRANCH_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_LEVEL_TREE_BRANCH_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default getLevelTreeBranchReducer;
