import React, {useEffect} from 'react';
import {useFieldArray} from 'react-hook-form';

import {Icons} from '@Articles/style';
import {IUpdateCompanyForm} from '@MyCompany/Interface';
import {UPDATE_COMPANY, orderContact} from '@MyCompany/constants';
import {ContainerFlex, Input, Text} from '@Shopify/Ecommerce/styles';

export const UpdateCompanyForm = ({
    register,
    errors,
    control,
    emails,
    phones,
}: IUpdateCompanyForm) => {
    const {
        fields: emailFields,
        append: appendEmail,
        remove: removeEmail,
    } = useFieldArray({
        control,
        name: 'mail',
    });

    const {
        fields: phoneFields,
        append: appendPhone,
        remove: removePhone,
    } = useFieldArray({
        control,
        name: 'phones',
    });

    const addContact = (type: string) => {
        if (type === UPDATE_COMPANY.MAIL && emailFields.length < 3) {
            appendEmail({email: '', id: emailFields.length + 1});
        }
        if (type === UPDATE_COMPANY.PHONES && phoneFields.length < 3) {
            appendPhone({phone: '', id: phoneFields.length + 1});
        }
    };

    useEffect(() => {
        removeEmail();
        emails.forEach((emailItem, index) => {
            appendEmail({id: index + 1, email: emailItem.emailPhoneValue});
        });
    }, [emails]);

    useEffect(() => {
        removePhone();
        phones.forEach((phoneItem, index) => {
            appendPhone({id: index + 1, phone: phoneItem.emailPhoneValue});
        });
    }, [phones]);

    return (
        <ContainerFlex OverFlowY="scroll" Gap="1.5rem" FlexDir="column" Justify="start">
            <ContainerFlex Height="100%" Gap="1rem" FlexDir="Column" Justify="start" Align="start">
                <Text Height="100%" FontSize="1.25rem" FontWeight="500" Color="#54575C">
                    {UPDATE_COMPANY.NAME}
                </Text>
                <ContainerFlex Height="100%" Gap="0.25rem" FlexDir="Column" Align="start">
                    <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                        {UPDATE_COMPANY.NAME_COMPANY}
                    </Text>
                    <Input
                        type="text"
                        Padding="12px"
                        FontSize="0.875rem"
                        placeholder=""
                        {...register('nameEnterprice')}
                        error={errors?.nameEnterprice?.message}
                    />
                    {errors?.nameEnterprice?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors?.nameEnterprice?.message}
                        </Text>
                    )}
                </ContainerFlex>
                <ContainerFlex Height="100%" Gap="0.25rem" FlexDir="Column" Align="start">
                    <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                        {UPDATE_COMPANY.NATURAL_NAME}
                    </Text>
                    <Input
                        type="text"
                        Padding="12px"
                        FontSize="0.875rem"
                        placeholder=""
                        {...register('naturalEnterprice')}
                        error={errors?.naturalEnterprice?.message}
                    />
                    {errors?.naturalEnterprice?.message && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errors?.naturalEnterprice?.message}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Height="100%" Gap="1rem" FlexDir="Column" Justify="start" Align="start">
                <Text Height="100%" FontSize="1.25rem" FontWeight="500" Color="#54575C">
                    {UPDATE_COMPANY.MAIL}
                </Text>
                {emailFields.map((item, index) => (
                    <ContainerFlex
                        Height="100%"
                        key={item.id}
                        Gap="0.25rem"
                        FlexDir="Column"
                        Align="start"
                    >
                        <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                            {orderContact(index + 1) + UPDATE_COMPANY.EMAIL}
                        </Text>
                        <Input
                            type="text"
                            Padding="12px"
                            FontSize="0.875rem"
                            placeholder="Sin Información"

                            {...register(`mail.${index}.email`)}
                            error={errors?.mail?.[index]?.email?.message}
                        />
                        {errors?.mail?.[index]?.email?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors?.mail?.[index]?.email?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                ))}
                {emailFields.length < 3 && (
                    <Text
                        onClick={() => addContact(UPDATE_COMPANY.MAIL)}
                        FontWeight="500"
                        Color="#5A5AFF"
                        Cursor="pointer"
                        Height="100%"
                    >
                        <Icons className="material-icons" Color="#5A5AFF" MarginRight="0.5rem">
                            add
                        </Icons>
                        {UPDATE_COMPANY.ADD_MAIL}
                    </Text>
                )}
            </ContainerFlex>
            <ContainerFlex Height="100%" Gap="1rem" FlexDir="Column" Justify="start" Align="start">
                <Text Height="100%" FontSize="1.25rem" FontWeight="500" Color="#54575C">
                    {UPDATE_COMPANY.PHONES}
                </Text>
                {phoneFields.map((item, index) => (
                    <ContainerFlex
                        key={index}
                        Height="100%"
                        Gap="0.25rem"
                        FlexDir="Column"
                        Align="start"
                    >
                        <Text FontSize="0.75rem" FontWeight="500" Color="#54575C">
                            {orderContact(index + 1) + UPDATE_COMPANY.PHONE}
                        </Text>
                        <Input
                            type="text"
                            Padding="12px"
                            FontSize="0.875rem"
                            placeholder="Sin Información"
                            {...register(`phones.${index}.phone`)}
                            error={errors?.phones?.[index]?.phone?.message}
                        />
                        {errors?.phones?.[index]?.phone?.message && (
                            <Text Color="#FF6357" FontSize="0.75rem">
                                {errors?.phones?.[index]?.phone?.message}
                            </Text>
                        )}
                    </ContainerFlex>
                ))}
                {phoneFields.length < 3 && (
                    <Text
                        onClick={() => addContact(UPDATE_COMPANY.PHONES)}
                        FontWeight="500"
                        Color="#5A5AFF"
                        Cursor="pointer"
                    >
                        <Icons className="material-icons" Color="#5A5AFF" MarginRight="0.5rem">
                            add
                        </Icons>
                        {UPDATE_COMPANY.ADD_PHONE}
                    </Text>
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
