import React, {RefObject, useEffect, useRef, useState} from 'react';
import {ListOptions, OptionPicker, TextPlacelHolder, Option} from '@components/Roles/styles';
import {ICatCountry, ISelectCountry, WrapperRefType} from '@components/Branches/interfaces';

export const MainInfoSelect = ({data, optionSelect, setValue, errors, trigger}: ISelectCountry) => {
    const [openList, setOpenList] = useState(false);
    const wrapperRef = useRef<WrapperRefType>(null);
    const [selectOption, setSelectOption] = useState<null | string>(null);
    const handleClickOutside = (event: MouseEvent) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target as HTMLElement)) {
            setOpenList(false);
        }
    };
    const selectValue = (value: number, country: string) => {
        setValue('countryId', value);
        setValue('countryName', country);
        setSelectOption(country);
        setOpenList(false);
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        setSelectOption(optionSelect);
    }, [optionSelect]);
    return (
        <OptionPicker
            onClick={() => {
                setOpenList(!openList);
            }}
            Margin={errors.countryName ? '' : '0 0 24px 0'}
            Border={openList ? '2px solid #35CF44' : '1px solid #e5e7e9'}
            Rotate={openList ? '-180deg' : ''}
            Fill={openList ? '#35cf44' : '#6D6E71'}
            BackGround={'#FFF'}
            ref={wrapperRef as RefObject<HTMLDivElement>}
            Width="300px"
            Cursor="pointer"
        >
            <TextPlacelHolder Color={selectOption ? '#414042' : '#e5e7e9'}>
                {selectOption ? selectOption : 'Seleccionar'}
            </TextPlacelHolder>
            <svg
                width="9"
                height="6"
                viewBox="0 0 9 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M0.799988 1.46666L1.73332 0.533325L4.79999 3.59999L7.86665 0.533325L8.79999 1.46666L4.79999 5.46666L0.799988 1.46666Z"
                    fill="#6D6E71"
                />
            </svg>
            {openList && (
                <ListOptions>
                    {data.length === 0 ? (
                        <Option onClick={() => setOpenList(false)}>Ninguna opción</Option>
                    ) : (
                        <>
                            {data.map((option: ICatCountry, index: number) => (
                                <Option
                                    key={index}
                                    onClick={() => {
                                        selectValue(option.countryId, option.countryName);
                                        trigger('countryName');
                                        setOpenList(!openList);
                                    }}
                                    BackGround="#FFF"
                                >
                                    {option.countryName}
                                </Option>
                            ))}
                        </>
                    )}
                </ListOptions>
            )}
        </OptionPicker>
    );
};
