import {ILevel, IRow, IStorageFloor, IWarehouse} from '@components/LocationsBranch/interfaces';

export const getNextWarehouseId = (warehouses: IWarehouse[]) => {
    return (
        warehouses.reduce((acc, warehouse) => {
            return warehouse.id > acc ? warehouse.id : acc;
        }, 0) + 1
    );
};

export const getNextLevelId = (levels: ILevel[]) => {
    return (
        levels.reduce((acc, level) => {
            return level.id > acc ? level.id : acc;
        }, 0) + 1
    );
};

export const getNextRowId = (rows: IRow[]) => {
    return (
        rows.reduce((acc, row) => {
            return row.id > acc ? row.id : acc;
        }, 0) + 1
    );
};

export const getNextFloorId = (floors: IStorageFloor[]) => {
    return (
        floors.reduce((acc, floor) => {
            return (floor.id ?? 0) > acc ? floor.id ?? 0 : acc;
        }, 0) + 1
    );
};
