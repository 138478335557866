import {AccessRoleUser, CountryCode, IHeader, rolesAccessList} from './interface';
import mexico from '@/images/Ecommerce/mexico.svg';
import colombia from '@/images/Ecommerce/colombia.svg';

export const TEXT_NEW_USER = {
    TITLE: 'Nuevo usuario',
    NAME_USER: 'Nombre de usuario',
    ACCESS: 'Acceso en: ',
    ROL: 'Rol:  ',
    PHONE: '+52 -  ',
    MAIL: '--@mailcom  ',
    COUNTRY: 'Pais',
    COUNTRY_SELECTED: 'México',
    INFORMATION: 'Información personal',
    NAME: 'Nombre(s)',
    LAST_NAME: 'Apellido Paterno',
    MATERNAL_SURNAME: 'Apellido materno',
    DATE: 'Fecha de nacimiento',
    PHONE_NUMBER: 'Numero de teléfono',
    EMAI_USER: 'Correo electrónico ',

    ADRESS: 'Dirección',
    CP: 'Código postal',
    STREET: 'Calle',
    INTERIOR_NUMBER: 'Numero int.',
    EXTERIOR_NUMBER: 'Numero ext.',
    OPTIONAL: '(Opcional)',
    STATE: 'Estado',
    HALL: 'Alcaldía/Municipio',
    CITY: 'Colonia',

    ACCESS_TTILE: 'Credenciales de acceso',
    ACCESS_C: 'Crea una contraseña',
    ACCESS_R: 'Confirmar contraseña',
    AUTO_ACCESS: 'Auto-generar',
    AUTO_NEW_ACCESS: 'Pedir actualización de contraseña',
    MONTH: 'Mes',

    PIN: 'PIN de acceso para POS',
    EMPITY_SEARCH:
        'No encontramos esta ubicación. Por favor, verifica los datos e inténtalo nuevamente.',

    ASSIGNMENT_TTILE: 'Asignación a ubicaciones',
    EXPAND: 'Expandir todo',

    ASSIGNMENT_Role: 'Asignación de rol',
    ROLE_USER_ASSIGNMENT:
        'Asigna un rol administrativo que este usuario fungirá en la empresa, esto afectará los permisos y accesos que tiene dentro de la plataforma.',

    WARNING_ROL: 'Solo se permite un máximo de 3 roles por usuario.',

    UNASSIGNED: ' Sin asignar',
    ACTION: 'Acción requerida: Información incompleta',
};

export const MODAL_TEXT = {
    TITLE: 'Accesos de rol',
    ROL: 'Rol: ',
    ADMIN: 'Administrativo',
    OP: 'Operativo',

    ADD: 'Subir o remplazar imagen',
    ADD_INDICATION: 'Acomoda en el marco la parte de la imagen que se mostrará',
    BUTTON: 'Busca archivos',

    REPLACE: 'Remplazar imagen',
    ROTATE: 'Girar 90°',
    CUT: 'Recortar',

    ADD_FILE: 'Arrastra elementos aqui',
};

export const BUTTONS = {
    KEEP: 'Guardar',
    CANCEL: 'Cancelar',
};

export const TREE_TEXT = {
    EXPAND: 'Expandir todo',
    CONTRACT: ' Contraer todo',
};

export const countryCodes: CountryCode[] = [
    {
        code: '+52',
        country: 'MX',
        flag: mexico,
    },
    {
        code: '++57',
        country: 'US',
        flag: colombia,
    },
];

export const TreeForLocations: LocationTreeData[] = [
    {
        id: 1,
        stateName: 'Jalisco',
        status: true,
        children: [
            {
                id: 101,
                cityName: 'Guadalajara',
                stateName: '',
                branchName: '',
                status: true,
                children: [
                    {
                        id: 1001,
                        branchName: 'Sucursal Plaza Patria',
                        stateName: '',
                        cityName: '',
                        address: 'Av. Patria 1234',
                        phone: '33-1234-5678',
                        status: true,
                        children: [],
                    },
                    {
                        id: 1002,
                        branchName: 'Sucursal Centro',
                        stateName: '',
                        cityName: '',
                        address: 'Av. 16 de Septiembre 789',
                        phone: '33-8765-4321',
                        status: true,
                        children: [],
                    },
                ],
            },
            {
                id: 102,
                cityName: 'Zapopan',
                stateName: '',
                branchName: '',
                status: true,
                children: [
                    {
                        id: 1003,
                        branchName: 'Sucursal Andares',
                        stateName: '',
                        cityName: '',
                        address: 'Blvd. Puerta de Hierro 4965',
                        phone: '33-9876-5432',
                        status: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 2,
        stateName: 'Nuevo León',
        status: true,
        children: [
            {
                id: 201,
                cityName: 'Monterrey',
                stateName: '',
                branchName: '',
                status: true,
                children: [
                    {
                        id: 2001,
                        branchName: 'Sucursal San Pedro',
                        stateName: '',
                        cityName: '',
                        address: 'Av. Vasconcelos 1000',
                        phone: '81-1234-5678',
                        status: true,
                        children: [],
                    },
                ],
            },
        ],
    },
    {
        id: 3,
        stateName: 'Ciudad de México',
        status: true,
        children: [
            {
                id: 301,
                cityName: 'Polanco',
                stateName: '',
                branchName: '',
                status: true,
                children: [
                    {
                        id: 3001,
                        branchName: 'Sucursal Masaryk',
                        stateName: '',
                        cityName: '',
                        address: 'Av. Presidente Masaryk 123',
                        phone: '55-1234-5678',
                        status: true,
                        children: [],
                    },
                    {
                        id: 3002,
                        branchName: 'Sucursal Antara',
                        stateName: '',
                        cityName: '',
                        address: 'Av. Ejército Nacional 843',
                        phone: '55-8765-4321',
                        status: true,
                        children: [],
                    },
                ],
            },
            {
                id: 302,
                cityName: 'Santa Fe',
                stateName: '',
                branchName: '',
                status: true,
                children: [
                    {
                        id: 3003,
                        branchName: 'Sucursal Centro Santa Fe',
                        stateName: '',
                        cityName: '',
                        address: 'Av. Vasco de Quiroga 3800',
                        phone: '55-9876-5432',
                        status: true,
                        children: [],
                    },
                ],
            },
        ],
    },
];

interface LocationTreeData {
    id: number;
    stateName: string;
    cityName?: string;
    branchName?: string;
    status: boolean;
    address?: string;
    phone?: string;
    children: LocationTreeData[];
}

export const OPTION_TABLE: IHeader[] = [{name: 'Rol'}, {name: 'Acceso en'}, {name: 'Activo'}];

export const data: AccessRoleUser[] = [
    {
        rol: 'Valuador',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Analista de créditos',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Consultor',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Diseñador gráfico',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Especialista en promociones',
        typeAccess: 'Operativo',
        isActive: false,
    },
];

export const rolesAccess: rolesAccessList[] = [
    {
        rol: 'Valuador',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Analista de créditos',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Consultor',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Diseñador gráfico',
        typeAccess: 'Operativo',
        isActive: false,
    },
    {
        rol: 'Especialista en promociones',
        typeAccess: 'Operativo',
        isActive: false,
    },
];

export const ERRORS = {
    SELECT: 'Por favor, selecciona un archivo de imagen válido (JPEG, PNG, GIF, WEBP)',
    MB: 'La imagen no debe superar los 5 MB',
    RESELECT: 'Error al cargar la imagen. Por favor, intenta con otra.',
    NOT: 'Error al leer el archivo. Por favor, intenta de nuevo.',
    NEW: 'No se seleccionó ningún archivo',
};

export const DinamicBorder = (BorderLevel: number) => {
    const borderMap: {[key: number]: string} = {
        1: '1px solid #E8E9EA',
        2: '1px solid #E8E9EA',
        3: '1px solid #E8E9EA',
        4: '1px solid white',
        5: '1px solid white',
    };
    return borderMap[BorderLevel];
};

export const RESET_PASSWORD = {
    TITLE: 'Restablecer contraseña',
    TITLE_TWO: 'Cambio de contraseña',
    INDICATION:
        'Puedes cambiar la contraseña o configurar que la contraseña se actualice determinado tiempo para seguridad.',
    TEX_PASS: 'Contraseña',
    REPEAT_PASS: 'Repetir contraseña',
    GENERATE: 'Genera automática mente',
    TEX_PIN: 'Quieres agregar un PIN? (Solo aplica para POS)',
    DATA_PIN: 'Datos del PIN',
    BTN: 'Descartar',
    BTN_KEEP: 'Guardar',
};

export const RESTORE_NEW_PASSWORD = {
    MESSAGE: '8 caracteres como minimo.',
    PASSWORD: 'Contraseña',
    MESSAGE_ONE: 'Incluye una letra mayúscula.',
    MESSAGE_TWO: 'Incluye un símbolo (por ejemplo, @, #, $).',
    REPEAT_PASS: '8 caracteres como minimo',
    SAME_PASSWORD: '8 caracteres como minimo.',
    CLOSE: 'Close icon',
    DONE: 'Close icon',
};

export const VALIDA_FORM = {
    MIN: '8 caracteres como minimo.',
    REQUIRED: 'Campo sin llenar',
    MATCHES: 'Debe incluir una mayúscula, una minúscula, un número y un carácter ',
    ONE_OF: 'Las contraseñas coinciden.',
    PASS: 'password',
};

export const DEFAULT_USER_VALUES = {
    img: '',
    name: '',
    lastNames: '',
    parental_surnames: '',
    maternal_surname: '',
    birthdate: '',
    phone: {
        dialCode: '+52',
        number: '',
    },
    email: '',
    cp: '',
    street: '',
    externalNumber: '',
    internalNumber: '',
    country: {value: 0, label: ''},
    colony: {value: 0, label: ''},
    municipality: {value: 0, label: ''},
    state: {value: 0, label: ''},
    password: '',
    repeatPassword: '',
    dayNewPassword: '',
};

export const MONTHS = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
] as const;

export const DAYS_IN_MONTH = {
    Enero: 31,
    Febrero: 28,
    Marzo: 31,
    Abril: 30,
    Mayo: 31,
    Junio: 30,
    Julio: 31,
    Agosto: 31,
    Septiembre: 30,
    Octubre: 31,
    Noviembre: 30,
    Diciembre: 31,
};

export const TEXTS = {
    PLACEHOLDER_DAY: 'Día',
    PLACEHOLDER_MONTH: 'Mes',
};

export const TEXT_YUP = {
    NAME_REQUIRED: 'Campo sin llenar',
    NAME_MIN: 'El nombre debe tener al menos 2 caracteres',
    NAME_MATCH: 'El nombre solo puede contener letras y números',

    LAST_NAME_REQUIRED: 'Campo sin llenar',
    LAST_NAME_MIN: 'El apellido debe tener al menos 2 caracteres',
    LAST_NAME_MATCH: 'El apellido solo puede contener letras y números',

    BIRTHDATE_REQUIRED: 'Campo sin llenar',
    BIRTHDATE_FORMAT: 'La fecha debe tener el formato DD/MM/AAAA',

    PHONE_DIAL_REQUIRED: 'Campo sin llenar',
    PHONE_REQUIRED: 'Campo sin llenar',
    PHONE_FORMAT: 'El teléfono debe tener 10 dígitos',
    PHONE_OBJECT_REQUIRED: 'El teléfono es requerido',

    EMAIL_REQUIRED: 'Campo sin llenar',
    EMAIL_FORMAT: 'El correo electrónico debe tener el formato example@example.com',

    CP_REQUIRED: 'Campo sin llenar',
    CP_FORMAT: 'El código postal debe tener 5 dígitos',

    STREET_REQUIRED: 'Campo sin llenar',
    STREET_MIN: 'La calle debe tener al menos 3 caracteres',
    STREET_MATCH: 'La calle solo puede contener letras y números',
    EXTERNAL_NUMBER_REQUIRED: 'Campo sin llenar',
    EXTERNAL_NUMBER_MAX: 'Ingresar máximo 5 caracteres',

    COLONY_REQUIRED: 'Campo sin llenar',
    COLONY_TYPE: 'Selecciona una Colonia',
    MUNICIPALITY_REQUIRED: 'Campo sin llenar',
    MUNICIPALITY_TYPE: 'Selecciona una Alcaldía',

    PASSWORD_MIN: VALIDA_FORM.MIN,
    PASSWORD_UPPERCASE: 'Debe contener al menos una letra mayúscula',
    PASSWORD_SPECIAL: 'Debe contener al menos un número o carácter especial',
    PASSWORD_REQUIRED: VALIDA_FORM.REQUIRED,

    REPEAT_PASSWORD_MIN: VALIDA_FORM.MIN,
    REPEAT_PASSWORD_MATCH: VALIDA_FORM.ONE_OF,
    REPEAT_PASSWORD_REQUIRED: VALIDA_FORM.REQUIRED,

    NEW_PASSWORD_DAY_REQUIRED: 'Campo sin llenar',
    NEW_PASSWORD_DAY_TIME: 'Selecciona un periodo de tiempo',
    NEW_PASSWORD_VALID_NUMBER: 'Ingrese una asignación válida',
};
