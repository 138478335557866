import React, {useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';

import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {AppDispatch} from '@config/store';
import {DeadlineGeneral} from '@CreateDeadline/interfaces';
import {limitNumberFormat, QuitNumberFormat} from '@/hooks/currentFormatUtils';
import {StyleNumericFormat} from '@/components/Loan/styles';
import {GreenSwitch} from '@/components/CreateUsers/Form/styles';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {extensionsProducts, setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';

export const Extensions = (props: DeadlineGeneral) => {
    const {deadlineById, deadlinesGeneral} = props;
    const [showItem, setShowItem] = useState(true);
    const [haveExtensions, setHaveExtensions] = useState(true);
    const dispatch: AppDispatch = useDispatch();

    const extensions = () => {
        setHaveExtensions((prev) => !prev);
        reset({
            dailyCost: '',
            loanLimit: '',
            interestRate: '',
        });
    };

    const onSubmit = (data: {dailyCost: string; loanLimit: string; interestRate: string}) => {
        const json = {
            haveExtension: haveExtensions,
            promotionExtensions: haveExtensions
                ? {
                      dailyCost: QuitNumberFormat(data.dailyCost),
                      loanLimit: QuitNumberFormat(data.loanLimit),
                      interestRate: QuitNumberFormat(data.interestRate),
                  }
                : {},
        };
        dispatch(extensionsProducts(json));
        dispatch(setStep(8));
    };

    const handleContinue = () => {
        setShowItem(!showItem);
        if (
            (deadlinesGeneral.promotionExtensions.promotionExtensions?.dailyCost &&
                deadlinesGeneral.promotionExtensions.haveExtension) ||
            !deadlinesGeneral.promotionExtensions.haveExtension
        ) {
            dispatch(setStep(7));
        }
    };
    const {handleSubmit, control, reset, watch} = useForm({
        defaultValues: {
            dailyCost: '',
            loanLimit: '',
            interestRate: '',
        },
    });

    const values = watch();

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            !(
                deadlinesGeneral.promotionExtensions.promotionExtensions?.dailyCost ||
                deadlinesGeneral.promotionExtensions.promotionExtensions?.loanLimit ||
                deadlinesGeneral.promotionExtensions.promotionExtensions?.interestRate
            )
        ) {
            const vDeadlines = deadlineById.deadlineId.data;
            setHaveExtensions(vDeadlines.haveExtension);
            const json = {
                haveExtension: vDeadlines.haveExtension,
                promotionExtensions: vDeadlines.haveExtension
                    ? {
                          dailyCost: vDeadlines.promotionExtensions.dailyCost,
                          loanLimit: vDeadlines.promotionExtensions.loanLimit,
                          interestRate: vDeadlines.promotionExtensions.interestRate,
                      }
                    : {},
            };
            dispatch(extensionsProducts(json));
        }

        if (
            deadlinesGeneral.promotionExtensions.haveExtension &&
            (!deadlineById.edit || !deadlineById.copy)
        ) {
            reset({
                dailyCost: String(
                    deadlinesGeneral.promotionExtensions.promotionExtensions.dailyCost
                ),
                loanLimit: String(
                    deadlinesGeneral.promotionExtensions.promotionExtensions.loanLimit
                ),
                interestRate: String(
                    deadlinesGeneral.promotionExtensions.promotionExtensions.interestRate
                ),
            });
        }
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
            onClick={() => handleContinue()}
            Cursor="pointer"
        >
            <TitleDeadline
                title={'Extensiones'}
                step={deadlinesGeneral.step}
                stepCurrent={7}
                stepDependence={0}
                dependence={
                    (deadlinesGeneral.promotionExtensions.promotionExtensions?.dailyCost !==
                        undefined &&
                        haveExtensions) ||
                    !haveExtensions
                }
            />

            {deadlinesGeneral.step === 7 && (
                <ContainerFlex Padding="1.5rem 2.5rem" smFlexWrap="wrap" FlexDir="column">
                    <FormContainer
                        onSubmit={handleSubmit(onSubmit)}
                        autoComplete="off"
                        Width="100%"
                        Justify="space-between"
                    >
                        <ContainerFlex Width="100%" MarginBt="2.5rem" FlexDir="column">
                            <Text Self="flex-start" FontSize="0.875rem">
                                ¿Tendrá extensiones?
                            </Text>
                            <Text Self="flex-start" FontSize="0.875rem" Margin="5px">
                                No
                                <FormControlLabel
                                    className="control-label"
                                    control={
                                        <GreenSwitch
                                            disabled={deadlineById?.edit}
                                            checked={haveExtensions}
                                            onChange={extensions}
                                            size="small"
                                        />
                                    }
                                    label=""
                                />
                                Sí
                            </Text>
                        </ContainerFlex>
                        {haveExtensions && (
                            <>
                                <ContainerFlex Width="31%" FlexDir="column" Align="start">
                                    <Text FontSize="0.75rem" required>
                                        Costo Diario
                                    </Text>
                                    <Controller
                                        name="dailyCost"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: haveExtensions ? true : false,
                                                message: 'Campo Obligatorio.',
                                            },
                                        }}
                                        render={({field: {ref, ...field}}) => (
                                            <StyleNumericFormat
                                                {...field}
                                                getInputRef={ref}
                                                disabled={
                                                    deadlineById?.edit || !haveExtensions
                                                        ? true
                                                        : false
                                                }
                                                id="dailyCost"
                                                type="text"
                                                prefix="$ "
                                                decimalSeparator="."
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                placeholder="Costo Diario"
                                                maxLength={12}
                                            />
                                        )}
                                    />
                                </ContainerFlex>
                                <ContainerFlex Width="31%" FlexDir="column" Align="start">
                                    <Text FontSize="0.75rem" required>
                                        Préstamo Límite
                                    </Text>
                                    <Controller
                                        name="loanLimit"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: haveExtensions ? true : false,
                                                message: 'Campo Obligatorio.',
                                            },
                                        }}
                                        render={({field: {ref, ...field}}) => (
                                            <StyleNumericFormat
                                                {...field}
                                                getInputRef={ref}
                                                type="text"
                                                disabled={
                                                    deadlineById?.edit || !haveExtensions
                                                        ? true
                                                        : false
                                                }
                                                prefix="$ "
                                                decimalSeparator="."
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                placeholder="Préstamo Límite"
                                                maxLength={12}
                                            />
                                        )}
                                    />
                                </ContainerFlex>
                                <ContainerFlex Width="31%" FlexDir="column" Align="start">
                                    <Text FontSize="0.75rem" required>
                                        % tasa máxima intereses
                                    </Text>
                                    <Controller
                                        name="interestRate"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: haveExtensions ? true : false,
                                                message: 'Campo Obligatorio.',
                                            },
                                        }}
                                        render={({field: {ref, ...field}}) => (
                                            <StyleNumericFormat
                                                {...field}
                                                getInputRef={ref}
                                                type="text"
                                                suffix="%"
                                                disabled={
                                                    deadlineById?.edit || !haveExtensions
                                                        ? true
                                                        : false
                                                }
                                                decimalSeparator="."
                                                thousandSeparator={true}
                                                decimalScale={2}
                                                allowLeadingZeros={false}
                                                allowNegative={false}
                                                isAllowed={(values: {value: string}) =>
                                                    limitNumberFormat(
                                                        QuitNumberFormat(values.value),
                                                        0,
                                                        100
                                                    )
                                                }
                                                placeholder="% tasa máxima intereses"
                                            />
                                        )}
                                    />
                                </ContainerFlex>
                            </>
                        )}

                        {!deadlineById?.edit && (
                            <ContainerFlex Width="100%" Margin="1.5rem 0 0 0">
                                <ButtonGeneral
                                    FontSize="0.875rem"
                                    hColor="#35cf44"
                                    text="Continuar"
                                    disabled={
                                        (!values.dailyCost ||
                                            !values.interestRate ||
                                            !values.loanLimit) &&
                                        haveExtensions
                                    }
                                />
                            </ContainerFlex>
                        )}
                    </FormContainer>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
