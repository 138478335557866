import React, {useRef, useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {toggleLoading} from '@/components/General/Atoms/LoadingAtaskate/Redux/Actions/loadingActions';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {routers} from '@/appRoute';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {restarQuoter} from '@Quoter/Redux/Actions/saveEndeavors';
import addCartPayment, {
    setPaymentPosted,
    resetPaymentPosted,
} from '@components/Loan/Redux/actions/AddCartPayment';
import {IAddCartPayment} from '@components/Loan/Redux/interfaces';
import {PaymentGatewayFormMethods} from '@Loan/PaymentQuoter/Interfaces';
import closeIcon from '@images/closeDarkRed.svg';
import {PAYMENT_GATEWAY} from '@Loan/PaymentQuoter/constants';
import {ContainerFlex, Text, Image, ContainerGrid} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import PaymentGatewaySummary from '@Loan/PaymentQuoter/PaymentGatewaySummary';
import PaymentGatewayCardEntry from '@Loan/PaymentQuoter/PaymentGatewayCardEntry';
import PaymentGatewayCashEntry from '@Loan/PaymentQuoter/PaymentGatewayCashEntry';
import PaymentGatewayOtherEntry from '@Loan/PaymentQuoter/PaymentGatewayOtherEntry';
import {setRefreshCart} from '@/components/ShoppingCart/Redux/Actions/GetCartByCartId';

const PaymentGateway = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [noMethodSelected, setNoMethodSelected] = useState(false);
    const [payErrorMessage, setPayErrorMessage] = useState('');

    const {clientProfile} = useSelector((state: RootState) => state.cardClientProfile);
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token.toString() || '';
    });
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    const branchId = useSelector((state: RootState) => {
        const branchDetails = state.getUsersValidation.userData?.branchDetails;
        if (Array.isArray(branchDetails) && branchDetails.length > 0) {
            return branchDetails[0]?.branchId;
        }
        return undefined;
    });

    const paymentMade = useSelector((state: RootState) => state.cartPaymentReducer.paid);
    useEffect(() => {
        if (paymentMade) {
            dispatch(resetPaymentPosted());
            dispatch(setRefreshCart());
            navigate('/');
        }
    }, [paymentMade]);

    const handleCancelTransaction = async () => {
        if (token)
            await dispatch(
                DeleteTemporalyPawns(token, {deleteAllPawns: true, operationType: 1}, carShoppingId)
            );
        navigate('/');
        dispatch(restarQuoter());
    };

    const cardEntryRef = useRef<PaymentGatewayFormMethods>(null);
    const cashEntryRef = useRef<PaymentGatewayFormMethods>(null);

    const clearForms = () => {
        cardEntryRef.current?.reset();
        cashEntryRef.current?.reset();
    };

    const onValidateForms = async () => {
        setNoMethodSelected(false);
        setPayErrorMessage('');
        const methodsCardEntry = cardEntryRef.current?.getValues();
        const methodsCashEntry = cashEntryRef.current?.getValues();

        const hasCardData =
            methodsCardEntry && Object.values(methodsCardEntry).some((value) => value !== '');
        const hasCashData =
            methodsCashEntry && Object.values(methodsCashEntry).some((value) => value !== '');

        if (!hasCardData && !hasCashData) {
            setNoMethodSelected(true);
            clearForms();
            return;
        }

        const cardAmount = Number(methodsCardEntry?.cardAmount || 0);
        const cashAmount = Number(methodsCashEntry?.cashAmount || 0);
        const totalAmount = cardAmount + cashAmount;

        if (totalAmount < cartData?.total!) {
            setPayErrorMessage(
                `${PAYMENT_GATEWAY.TOTAL_PAYMENT} ${numberToCurrency(totalAmount)} ${
                    PAYMENT_GATEWAY.LESS_THAN_PAYMENT_AMOUNT
                } ${numberToCurrency(cartData?.total!)}.`
            );
            return;
        }

        if (hasCardData || hasCashData) {
            let proceed = true;
            const paymentData: IAddCartPayment = {
                shoppingCarId: Number(carShoppingId),
                branchId: branchId,
                cardPayment: 0,
                cardAuthorization: '',
                cardTermination: '',
                cardType: 0,
                cashPayment: 0,
            };
            if (hasCardData) {
                await cardEntryRef.current?.submitForm();
                if (!cardEntryRef.current?.hasErrors()) {
                    paymentData.cardPayment = Number(methodsCardEntry.cardAmount);
                    paymentData.cardAuthorization = methodsCardEntry.cveRegistry;
                    paymentData.cardTermination = methodsCardEntry.cardLastFourDigits;
                    paymentData.cardType = methodsCardEntry.cardType.value;
                } else {
                    proceed = false;
                }
            } else {
                cardEntryRef.current?.reset();
            }
            if (hasCashData) {
                await cashEntryRef.current?.submitForm();
                if (!cashEntryRef.current?.hasErrors()) {
                    paymentData.cashPayment = Number(methodsCashEntry.cashAmount);
                } else {
                    proceed = false;
                }
            } else {
                cashEntryRef.current?.reset();
            }
            if (proceed) {
                try {
                    dispatch(toggleLoading(true, PAYMENT_GATEWAY.PLEASE_WAIT));
                    await dispatch(addCartPayment(token, paymentData));
                    clearForms();
                    navigate(routers.PaymentQuoterConfirmation);
                    dispatch(setPaymentPosted(true));
                } catch (error) {
                    setPayErrorMessage(PAYMENT_GATEWAY.PROCESS_PAYMENT_ERROR);
                }
                dispatch(toggleLoading(false));
            }
        }
    };

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            Justify="start"
            Padding="1rem 1.5rem"
            Gap="1rem"
        >
            <FlexPanel Justify="space-between" Align="center" Width="100%">
                <Text FontSize="1.75rem" FontWeight="700" Color="#2A2C2F">
                    {PAYMENT_GATEWAY.TITLE}
                </Text>
                <FlexPanel
                    Gap="0.5rem"
                    Self="stretch"
                    Cursor="pointer"
                    onClick={handleCancelTransaction}
                >
                    <Image src={closeIcon} alt="cancel" Width="1.5rem" Height="1.5rem" />
                    <Text
                        FontSize="1rem"
                        FontWeight="700"
                        Color="#A82424"
                        LetterSpacing="0.019rem"
                        Cursor="inherit"
                    >
                        {PAYMENT_GATEWAY.CANCEL_TRANSACTION}
                    </Text>
                </FlexPanel>
            </FlexPanel>
            <ContainerGrid
                Justify="start"
                Align="flex-start"
                Gap="1rem"
                GridColumns="minmax(28rem, 1fr) minmax(25rem, 0fr)"
            >
                <FlexPanel Width="100%">
                    <FlexPanel
                        FlexDir="column"
                        Align="start"
                        Gap="1.5rem"
                        Padding="1.5rem"
                        Self="stretch"
                        Background="#FFF"
                        Border="1px solid #E8E9EA"
                        Radius="1.5rem"
                        Width="100%"
                    >
                        <Text FontSize="1.25rem" FontWeight="700" Color="#2A2C2F">
                            {PAYMENT_GATEWAY.PAYMENT_METHODS}
                        </Text>
                        <Text
                            FontSize="1rem"
                            FontWeight="400"
                            Color="#2A2C2F"
                            LetterSpacing="0.019rem"
                        >
                            {PAYMENT_GATEWAY.SELECT_METHOD}
                        </Text>
                        <PaymentGatewayCardEntry ref={cardEntryRef} />
                        <PaymentGatewayCashEntry ref={cashEntryRef} />
                        <PaymentGatewayOtherEntry />
                        {(noMethodSelected || payErrorMessage) && (
                            <ContainerFlex
                                Color="#FF6357"
                                FontSize="1rem"
                                Height="1rem"
                                Border="1px solid #FF6357"
                                Radius="0.5rem"
                                Width="90%"
                                Self="center"
                                Padding="1rem"
                            >
                                {payErrorMessage || PAYMENT_GATEWAY.GENERAL_MESSAGE}
                            </ContainerFlex>
                        )}
                    </FlexPanel>
                </FlexPanel>
                <FlexPanel>
                    <PaymentGatewaySummary clientData={clientProfile} onPay={onValidateForms} />
                </FlexPanel>
            </ContainerGrid>
        </ContainerFlex>
    );
};
export default PaymentGateway;
