import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import StringUtils from '@GenericScripts/utils';
import {IPawns} from '@Shopify/ClientProfile/interfaces';
import {CAT_STATUS_CONTRACT, ENDEAVORS_CONTRACTS} from '@Shopify/ClientProfile/Redux/types';

export const endeavorsStart = () => ({type: ENDEAVORS_CONTRACTS.START});
export const endeavorsSuccess = (result: AxiosResponse) => ({
    type: ENDEAVORS_CONTRACTS.SUCCESS,
    contracts: result.data,
});
export const endeavorsError = (error: AxiosError) => ({
    type: ENDEAVORS_CONTRACTS.ERROR,
    error: error,
});
export const getEndeavours = (token: string, params: IPawns) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    const queryString = StringUtils.jsonToQueryString({...params});

    return async (dispatch: AppDispatch) => {
        dispatch(endeavorsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetPawnContracts}${queryString}`,
                {headers}
            );
            dispatch(endeavorsSuccess(response.data));
        } catch (error) {
            dispatch(endeavorsError(error as AxiosError));
        }
    };
};

export const catStatusContractStart = () => ({type: CAT_STATUS_CONTRACT.START_PAWN});
export const catStatusContractSuccess = (result: AxiosResponse) => ({
    type: CAT_STATUS_CONTRACT.SUCCESS_PAWN,
    catStatusContract: result.data,
});
export const catStatusContractError = (error: AxiosError) => ({
    type: CAT_STATUS_CONTRACT.ERROR_PAWN,
    error: error,
});
export const catStatusContract = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch: AppDispatch) => {
        dispatch(catStatusContractStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetContractStatusActive}`,
                {headers}
            );
            dispatch(catStatusContractSuccess(response.data));
        } catch (error) {
            dispatch(catStatusContractError(error as AxiosError));
        }
    };
};
