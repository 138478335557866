import React, {useEffect} from 'react';
import {AppDispatch, RootState} from '@config/store';
import {getAllNation} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/getAllNationsActions';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {IClientProofStep, SelectOption} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, Input} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {GetCatState} from '@/components/Branches/ManageBranch/Redux/Actions/GetCatState';

const PersonalDataNationRfcCurpInfo = (props: IClientProofStep) => {
    const dispatch: AppDispatch = useDispatch();
    const {token, register, errors, control, setValue} = props;

    const nations: IGetAllNations[] = useSelector((state: RootState) => state.getAllNations.data);
    let formattedNations: SelectOption[] = [];
    if (nations) {
        formattedNations = nations.map((nation: IGetAllNations) => ({
            value: nation.countryId,
            label: nation.name,
        }));
    }

    useEffect(() => {
        if (token) {
            dispatch(getAllNation(token));
        }
    }, [dispatch, token]);

    return (
        <ContainerFlex Justify="space-between" Gap="1rem">
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.PAIS_DONDE_NACIO}</Text>
                <Controller
                    name="countryOfBirthId"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder="Selecciona"
                            isSearchable={false}
                            hasError={!!errors.countryOfBirthId?.message}
                            options={formattedNations}
                            onChange={(selectedOption) => {
                                setValue('countryOfBirthId', selectedOption);
                                dispatch(GetCatState(selectedOption.value, token));
                                if (onChange) onChange(selectedOption);
                            }}
                        />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.countryOfBirthId?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.RFC}</Text>
                <Controller
                    name="rfc"
                    control={control}
                    render={() => <Input {...register('rfc')} error={!!errors.rfc?.message} />}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.rfc?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.CURP}</Text>
                <Controller
                    name="curp"
                    control={control}
                    render={() => <Input {...register('curp')} error={!!errors.curp?.message} />}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.curp?.message as string}
                </Text>
            </ContainerFlex>
            <ContainerFlex {...style.selectContent}>
                <Text {...style.level}>{CREATE_CLIENT_LABELS.NACIONALIDAD}</Text>
                <Controller
                    name="nationality"
                    control={control}
                    render={() => (
                        <Input {...register('nationality')} error={!!errors.nationality?.message} />
                    )}
                />
                <Text Color="#FF6357" FontSize="0.875rem">
                    {errors.nationality?.message as string}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalDataNationRfcCurpInfo;
