import React, {useState} from 'react';
import {INewProductChargesAndPenaltiesProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/chargesAndPenaltiesInterfaces';
import {ChargesAndPenaltiesPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/Preview/ChargesAndPenaltiesPreview';
import {ChargesAndPenaltiesEditingForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/EditingForm/ChargesAndPenaltiesEditingForm';

export const NewProductChargesAndPenalties: React.FC<INewProductChargesAndPenaltiesProps> = ({
    chargesRef,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    return (
        <>
            {isEditing ? (
                <ChargesAndPenaltiesEditingForm
                    chargesRef={chargesRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <ChargesAndPenaltiesPreview
                    chargesRef={chargesRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </>
    );
};
