import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {PERSONAL_LOAN_APPROVED} from '@/components/PersonalLoans/constants';
import {ICreditLoanDeposit} from '@/components/PersonalLoans/interfaces';
import ataskateWolfIcon from '@/components/PersonalLoans/icons/ataskateWolfIcon.svg';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const PersonalLoanApprovedDeposit: React.FC<ICreditLoanDeposit> = ({
    nameClient,
    requestedLoan,
    loanGranted,
}) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Height="100%"
            Width="100%"
            Justify="start"
            Align="start"
            Gap="24px"
        >
            <ContainerFlex
                Flex="0"
                Width="100%"
                Height="100%"
                Padding="20px 32px"
                Justify="space-between"
                Align="center"
                backG="#FAFAFF"
                Radius="24px"
            >
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    Gap="16px"
                    FlexDir="column"
                    Justify="center"
                    Align="start"
                >
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.5rem" Color="#1D1E20">
                        {`!${nameClient}, ${PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.BANNER_MESSAGE_NAME_SA}`}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.75rem" Color="#1D1E20">
                        {`${
                            PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                                .BANNER_MESSAGE_HAS_APPROVED_SA
                        }
                    ${numberToCurrencyWithoutCents(loanGranted)}
                    ${
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                            .BANNER_MESSAGE_HAS_APPROVED_OF_SA
                    }
                    ${numberToCurrencyWithoutCents(requestedLoan)}
                    ${
                        PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                            .BANNER_MESSAGE_CREDIT_REQUESTED_SA
                    }`}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Flex="0"
                Width="100%"
                Height="100%"
                Padding="16px 32px 16px 16px"
                Justify="space-between"
                Border="1px solid #00B4DE"
                Align="center"
                backG="#D0F6FF"
                Radius="24px"
            >
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    Gap="16px"
                    FlexDir="column"
                    Justify="center"
                    Align="start"
                >
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.25rem" Color="#1D1E20">
                        {PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION.BANNER_MESSAGE_SA}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {
                            PERSONAL_LOAN_APPROVED.BODY.DEPOSIT_INFORMATION
                                .BANNER_MESSAGE_PARAGRAPH_SA
                        }
                    </Text>
                </ContainerFlex>
                <Image height="112px" src={ataskateWolfIcon} alt="wolf-animate-image" />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default PersonalLoanApprovedDeposit;
