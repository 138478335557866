import React from 'react';
import {ContainerFlex, Text, Image} from '@components/Shopify/Ecommerce/styles';
import {CREDIT_DETAILS_HEADER, ALTS} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import arrowLeft from '@/components/MyAccount/MyCredit/CreditDetails/icons/arrowLeftIcon.svg';
import {useNavigate} from 'react-router-dom';
import {routers} from '@/appRoute';

const CreditDetailsHeader: React.FC = () => {
    const navigate = useNavigate();
    return (
        <ContainerFlex Align="start" Width="100%" Flex="0">
            <ContainerFlex
                FlexDir="row"
                Justify="start"
                Gap="8px"
                onClick={() => navigate(routers.MyAccount)}
                Cursor="pointer"
            >
                <Image Width="24px" Height="24px" src={arrowLeft} alt={ALTS.ARROW_LEFT} />
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.0rem"
                    Color="#5A5AFF"
                    Cursor="pointer"
                >
                    {CREDIT_DETAILS_HEADER.CTA_BACK_TO}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailsHeader;
