import React from 'react';
import {ContainerFlex, Text, TextArea} from '@components/Shopify/Ecommerce/styles';
import {REASON, TITLES} from '@/components/AuctionPass/AuctionConstants';
import {IinputTypes} from '@components/AuctionPass/interface';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import SelectGeneral from '@components/General/Atoms/Select/Select';
import {Controller} from 'react-hook-form';

export const SaleChannel = ({register, control, errors}: IinputTypes) => {
    return (
        <ContainerFlex Justify="start" Align="start" Padding="0 16px" FlexDir="column" Gap="32px">
            <ContainerFlex Justify="start" Align="start" Height="52px" FlexDir="column" Gap="8px">
                <Text Color="#0D166B" FontSize="1.25rem" FontWeight="700">
                    {TITLES.SALE_CHANNEL}
                </Text>
                <ContainerFlex
                    Gap="8px"
                    Justify="start"
                    Align="start"
                    ColumnGap="8px"
                    Height="24px"
                >
                    <Checkbox />
                    <Text>{TITLES.SALE_CHANNEL}</Text>
                    <Checkbox />
                    <Text>{TITLES.SHOW}</Text>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex
                Gap="8px"
                Justify="start"
                Align="start"
                ColumnGap="8px"
                Height="132px"
                FlexDir="column"
            >
                <Text FontSize="1rem" FontWeight="700">
                    {TITLES.DESCRIPTION}
                </Text>
                <TextArea Height="104px" />
            </ContainerFlex>
            <ContainerFlex Justify="start" Align="start" ColumnGap="8px" Height="24px">
                <Checkbox />
                <Text>{TITLES.PAUSE}</Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Justify="start" Align="start">
                <Text FontWeight="700">{TITLES.MODEL}</Text>
                <Controller
                    name="reason"
                    control={control}
                    render={({field: {onChange, ...field}}) => (
                        <SelectGeneral
                            {...field}
                            placeholder={TITLES.SELECT}
                            isSearchable={false}
                            hasError={!!errors.reason?.label?.message}
                            options={REASON}
                            isDisabled
                            onChange={(e) => {
                                onChange(e);
                            }}
                        />
                    )}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
