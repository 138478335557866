import {AppDispatch} from '@/config/store';
import {CART_STATE} from '@components/ShoppingCart/Redux/types';
import {setRefreshCart} from '@/components/ShoppingCart/Redux/Actions/GetCartByCartId';

export const setIsCartOpen = (isOpen: boolean, refresh: boolean = true) => {
    return (dispatch: AppDispatch) => {
        dispatch({
            type: CART_STATE.SET_IS_CART_OPEN,
            payload: isOpen,
        });

        if (isOpen && refresh) {
            dispatch(setRefreshCart());
        }
    };
};

export const setDocked = (docked: boolean) => ({
    type: CART_STATE.SET_DOCKED,
    payload: docked,
});
