import {GET_COMPANY_LEVELS} from '@components/LocationsBranch/Redux/types';
import {
    CompanyLevels,
    CompanyLevelsState,
    GetCompanyLevelsAction,
    IGroupList,
} from '@components/LocationsBranch/Redux/interfaces';

const initialState: CompanyLevelsState = {
    data: {
        branchId: 0,
        branchName: '',
        groupName: '',
        countLevels: '',
        message: '',
        levelId: 0,
        levels: [],
        branchs: [],
    },
    groupList: null,
    selectedGroup: null,
    selectedOptionList: null,
    loading: false,
    error: null,
};

const generateGroupListArray = (
    data: CompanyLevels,
    groupList: IGroupList[],
    internalGroupId?: number,
    internalRowId?: number
): IGroupList[] => {
    const index = groupList.findIndex((group) => group.internalId === internalGroupId);
    const newGroupList = [...groupList];

    if (index !== -1) {
        newGroupList[index] = {
            ...newGroupList[index],
            rows: newGroupList[index].rows.map((row) => ({
                ...row,
                selected: internalRowId === row.levelId,
            })),
        };
        newGroupList.splice(index + 1);
    }
    const newGroup = {
        name: data.groupName,
        id: data.levelId,
        internalId: newGroupList.length + 1,
        rows: data.levels.map((level) => ({
            levelId: level.levelId,
            levelName: level.levelName,
            parentLevelId: level.parentLevelId,
            countLevels: level.countLevels,
            typeLocationId: level.typeLocationId,
            typeLocation: level.typeLocation,
            selected: internalRowId === level.levelId,
        })),
    };
    return [...newGroupList, {...newGroup}];
};

const getCompanyLevels = (
    state = initialState,
    action: GetCompanyLevelsAction
): CompanyLevelsState => {
    switch (action.type) {
        case GET_COMPANY_LEVELS.GET_LEVELS_START:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_COMPANY_LEVELS.GET_LEVELS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_COMPANY_LEVELS.GET_LEVELS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case GET_COMPANY_LEVELS.UPDATE_GROUPS_LEVELS: {
            const {selectedGroup, internalGroupId, internalRowId} = action.selectedGroupPayload;
            const groupList = generateGroupListArray(
                selectedGroup,
                state.groupList || [],
                internalGroupId,
                internalRowId
            );

            return {
                ...state,
                loading: false,
                groupList,
                selectedGroup,
            };
        }
        case GET_COMPANY_LEVELS.RESET_GROUPS_LEVELS: {
            return {
                ...initialState,
            };
        }
        case GET_COMPANY_LEVELS.SET_SELECTED_OPTION_LIST: {
            return {
                ...state,
                selectedOptionList: action.selectedOptionList,
            };
        }
        default:
            return state;
    }
};

export default getCompanyLevels;
