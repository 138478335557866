import React, {ChangeEvent, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {FORM_ERRORS} from '@components/Shopify/ListClient/constants';
import {AppDispatch, RootState} from '@config/store';
import {validateProofAddressClient} from '@components/Shopify/ListClient/redux/Actions/newClientProcessAction';
import * as content from '@components/CreateUsers/Styles';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import AddClient from '@images/AddClient.png';
import {IValidateProofAddress} from '@components/Shopify/ListClient/redux/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import PersonalDataContactInfo from '@/components/Shopify/ListClient/PersonalDataContactInfo';
import PersonalDataNameInfo from '@/components/Shopify/ListClient/PersonalDataNameInfo';
import PersonalDataCivilStatusGenreInfo from '@/components/Shopify/ListClient/PersonalDataCivilStatusGenreInfo';
import PersonalDataNationRfcCurpInfo from '@/components/Shopify/ListClient/PersonalDataNationRfcCurpInfo';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {isSupportedFileType} from '@/components/Quoter/Search/ArticleRequestInputs/validateArchive';
import IdentityDocsModal from '@Shopify/ClientProfile/IdentityDocsModal';
import IdentityDocInputBox from '@Shopify/ClientProfile/IdentityDocInputBox';
import LoadingOverlay from '@/components/General/Atoms/LoadingOverlay/LoadingOverlay';
import imageCompression from 'browser-image-compression';
import {IDocumentsInfo} from '@components/Shopify/ClientProfile/interfaces';

const PersonalData = (props: IClientProofStep) => {
    const dispatch: AppDispatch = useDispatch();
    const [showIdDocumentModal, setShowIdDocumentModal] = useState(false);

    const {token, errors, setValue} = props;
    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [processingImage, setprocessingImage] = useState(false);

    const loadingClientInfo: boolean = useSelector(
        (state: RootState) => state.newClientProcess.loadingClientInfo
    );

    const imgAdd = async (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            setprocessingImage(true);
            let file = e.target.files[0];

            try {
                const options = {
                    maxSizeMB: 5,
                    maxWidthOrHeight: 1920,
                    useWebWorker: true,
                };
                file = await imageCompression(file, options);
            } catch (error) {
                setprocessingImage(false);
                setErrorMessage(FORM_ERRORS.MEDIA_TYPE_ERROR);
                return;
            }

            if (file && file.size <= 25 * 1024 * 1024 && isSupportedFileType(file)) {
                const fileExtension = file.name.split('.').pop()?.toLowerCase();

                const readFileAsDataURL = (file: File): Promise<string> => {
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => resolve(reader.result as string);
                        reader.onerror = (error) => reject(error);
                    });
                };

                try {
                    const fileContent = await readFileAsDataURL(file);
                    const base64Content = fileContent.split(',')[1];

                    const imageData: IValidateProofAddress = {
                        fileExtension: fileExtension as string,
                        fileContent: base64Content,
                    };

                    await dispatch(validateProofAddressClient(imageData, token));
                    setImageSrc(fileContent);
                    setValue('imageSrcFile', fileContent);
                    setValue('imageUser', base64Content);
                    setValue('imageFileExtension', fileExtension);
                    setErrorMessage('');
                } catch (error) {
                    setprocessingImage(false);
                    setErrorMessage(FORM_ERRORS.MEDIA_TYPE_ERROR);
                }
            }
            setprocessingImage(false);
        }
    };

    const addIdDocument = (document: IDocumentsInfo) => {
        setValue('catIdentificationTypeId', document.catIdentificationTypeId);
        setValue('identificationNumber', document.identificationNumber);
        setValue('idDocument', document.idDocument);
        setValue('validity', document.validity);
    };

    return (
        <>
            {loadingClientInfo && (
                <ContainerFlex Position="fixed" backG="#ffffff87" ZIndex="2">
                    <LoadingAtaskate width="3.75rem" padding="0.938rem" />
                </ContainerFlex>
            )}
            <ContainerFlex
                FlexDir="column"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1rem"
                Align="start"
                backG="#FFF"
                Width="1233px"
                Margin="0 auto"
                Justify="start"
            >
                <Text FontWeight="500" FontSize="1.25rem" Color="#2A2C2F">
                    Datos Personales
                </Text>
                <ContainerFlex Align="flex-start" ColumnGap="2rem">
                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Width="auto">
                        <LoadingOverlay isLoading={processingImage} borderRadius="1rem">
                            <FileUpLoad
                                accept="image/jpg image/jpeg"
                                type="file"
                                onChange={imgAdd}
                                id="file"
                            />
                            <FileUpLoadContent htmlFor="file" BackG="" Width="150px" Height="150px">
                                {imageSrc ? (
                                    <Image src={imageSrc} Height="100%" Width="100%" Radius="50%" />
                                ) : (
                                    <>
                                        <Image
                                            src={AddClient}
                                            {...content.contentImg.imgStart}
                                            Height="100%"
                                            Width="100%"
                                            Radius="50%"
                                        />
                                    </>
                                )}
                            </FileUpLoadContent>
                            <Text
                                Color="red"
                                FontWeight="400"
                                FontSize="0.875rem"
                                wSpace=""
                                Width="10rem"
                            >
                                {errors.imageUser?.message as string}
                                {errorMessage === FORM_ERRORS.MEDIA_TYPE_ERROR && (
                                    <>{errorMessage}</>
                                )}
                            </Text>
                        </LoadingOverlay>
                    </ContainerFlex>

                    <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1.5rem">
                        <PersonalDataNameInfo {...props} />

                        <PersonalDataContactInfo {...props} />

                        <PersonalDataCivilStatusGenreInfo {...props} />

                        <PersonalDataNationRfcCurpInfo {...props} />

                        <IdentityDocInputBox
                            onOpenSelection={setShowIdDocumentModal}
                            error={!!errors.idDocument?.message}
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {showIdDocumentModal && (
                <IdentityDocsModal
                    showModal={showIdDocumentModal}
                    setShowModal={setShowIdDocumentModal}
                    onDocumentProvided={addIdDocument}
                />
            )}
        </>
    );
};

export default PersonalData;
