import {AppDispatch} from '@/config/store';
import axios, {AxiosResponse} from 'axios';

import {
    START_SHARE_TICKETS_RESUME,
    SUCCESS_SHARE_TICKETS_RESUME,
    ERROR_SHARE_TICKETS_RESUME,
} from '@components/ShoppingCart/Redux/types';
import {URL} from '@config/constants/index';

const shareTicketsStart = () => ({type: START_SHARE_TICKETS_RESUME});
const shareTicketsSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: SUCCESS_SHARE_TICKETS_RESUME,
        payload: result.data,
    };
};
const shareTicketsError = () => {
    return {
        type: ERROR_SHARE_TICKETS_RESUME,
    };
};

export function shareTicketsResume(token: string, clientId: number) {
    return async (dispatch: AppDispatch) => {
        const headers = {
            Accept: 'application/json',
            Authorization: 'Bearer ' + token,
        };
        dispatch(shareTicketsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.SendingReceipt}?clientId=${clientId}`,
                {headers}
            );
            dispatch(shareTicketsSuccess(response));
        } catch (error) {
            dispatch(shareTicketsError());
        }
    };
}
