import React from 'react';

import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import {Holidays} from '@/components/LocationsBranch/Holidays';
import * as style from '@components/LocationsBranch/stylesLocations';
import {ScheduleGroup} from '@components/LocationsBranch/ScheduleGroup';
import {IHolidaysSchedules} from '@components/LocationsBranch/interfaces';

export const HolidaysSchedule = ({
    register,
    control,
    errors,
    watch,
    setValue,
    setShowHolidaysModal,
    isOptional,
    isEdit = false,
    onClickTransfer,
}: IHolidaysSchedules) => {
    return (
        <ContainerFlex {...style.contentGral}>
            <ScheduleGroup
                register={register}
                control={control}
                errors={errors}
                watch={watch}
                isOptional={isOptional}
                setValue={setValue}
                isEdit={isEdit}
                onClickTransfer={onClickTransfer}
            />
            <Holidays
                register={register}
                control={control}
                errors={errors}
                setShowHolidaysModal={setShowHolidaysModal}
                watch={watch}
                setValue={setValue}
            />
        </ContainerFlex>
    );
};
