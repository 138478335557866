import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';

import {IconContainer} from '@/components/Quoter/styles';
import {ContainerFlex, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {BranchL, location, NodeData} from '@Locations/AdminLocations/redux/Interfaces';
import {saveBranch} from '@Locations/AdminLocations/redux/actions/LocationAction';

export const TreeBranchLocations = (data: {data: NodeData[]}) => {
    return (
        <>
            {data.data.length > 0 &&
                data.data.map((tree: NodeData, index: number) => {
                    return <TreebranchLocations node={tree} key={index} />;
                })}
        </>
    );
};

const TreebranchLocations = (node: {node: NodeData}) => {
    const [childVisible, setChildVisible] = useState(false);
    const hasChild = node.node.nextLevel ? true : false;
    const dispatch: AppDispatch = useDispatch();
    const locations: location = useSelector((state: RootState) => {
        return state.locationReducers;
    });
    const paddingLevel = (id: number) => {
        const LEVEL_ID: {[key: number]: string} = {
            0: '0 0 0 0.5rem',
            1: '0 0 0 1rem',
            2: '0 0 0 1.5rem',
            3: '0 0 0 2rem',
            4: '0 0 0 2.5rem',
            5: '0 0 0 3rem',
        };
        return LEVEL_ID[id];
    };
    const paddingBranch = (id: number) => {
        const LEVEL_ID: {[key: number]: string} = {
            0: '0 0 0 2.5rem',
            1: '0 0 0 3rem',
            2: '0 0 0 3.5rem',
            3: '0 0 0 4rem',
            4: '0 0 0 4.5rem',
            5: '0 0 0 5rem',
        };
        return LEVEL_ID[id];
    };

    useEffect(() => {
        if (node.node.level === 0) setChildVisible(true);
    }, []);

    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column" Height="auto">
            <ContainerFlex Padding={paddingLevel(node.node.level)} Height="2rem" Justify="start">
                {hasChild && (
                    <IconContainer
                        className="material-icons"
                        FontSize="1.25rem"
                        Color="#414042"
                        Justify="start"
                        Padding="0 2px"
                        Cursor="pointer"
                        onClick={() => {
                            setChildVisible(!childVisible);
                        }}
                    >
                        {hasChild && childVisible ? 'arrow_drop_down' : 'arrow_right'}
                    </IconContainer>
                )}
                <TextEllipsis
                    FontWeight={node.node.level === 0 ? '500' : '400'}
                    Width="10rem"
                    FontSize="0.875rem"
                    Padding="0 0 0 0.2rem"
                    Color="#414042"
                    TextAlign="left"
                >
                    {node.node.levelName}
                </TextEllipsis>
            </ContainerFlex>
            {childVisible &&
                hasChild &&
                node.node.branches &&
                node.node.branches?.map((item: BranchL, index: number) => {
                    return (
                        <ContainerFlex
                            key={index}
                            Height="2rem"
                            Justify="start"
                            Padding={paddingBranch(node.node.level)}
                        >
                            <Checkbox
                                checked={
                                    locations.saveBranch?.idBranch === item.idBranch ? true : false
                                }
                                onChange={() =>
                                    dispatch(saveBranch(item, locations.saveBranch.corporation))
                                }
                            />
                            <TextEllipsis
                                Padding="0 0.3rem"
                                Width="10rem"
                                FontSize="0.875rem"
                                FontWeight={'400'}
                            >
                                {item.branchName}
                            </TextEllipsis>
                        </ContainerFlex>
                    );
                })}

            {hasChild && childVisible && (
                <ContainerFlex Height="auto" FlexDir="column">
                    <TreeBranchLocations data={node.node.nextLevel} />
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
