import React from 'react';
import {IChildrenProp} from '@/components/SideBarMenu/Accordion/interfaces';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';

const AccordionDetails: React.FC<IChildrenProp> = ({children, ...rest}) => {
    return (
        <ContainerFlex FlexDir="column" {...rest}>
            {children}
        </ContainerFlex>
    );
};

export default AccordionDetails;
