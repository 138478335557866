import {GET_ALL_FORM, GET_ALL_FORM_ADDRESS} from '@components/Users/Redux/types';

import {IContactValues} from '@components/Users/UserProfile/yupSchema';
import {
    IPersonalInfo,
    IScreenFormPersonalInfo,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';

const initialState: IScreenFormPersonalInfo = {
    data: {
        name: '',
        lastName: '',
        otherLastName: '',
        date: '',
        gender: {label: ''},
        email: '',
        otherEmail: '',
        cel: '',
        otherCelphone: '',
        postal: '',
    },
    address: {
        postal: 0,
        street: '',
        outsideNumber: '',
        insideNumber: '',
        colony: {
            label: '',
            value: 0,
        },
        town: '',
        state: '',
        catColony: {
            label: '',
            value: 0,
        },
    },
};

const ScreenStateUsersForm = (
    state = initialState,
    action: {type: string; payload: number; form: IPersonalInfo; formAddress: IContactValues}
) => {
    switch (action.type) {
        case GET_ALL_FORM:
            return {
                data: action.form,
            };
        case GET_ALL_FORM_ADDRESS:
            return {
                address: action.formAddress,
            };

        default:
            return state;
    }
};
export default ScreenStateUsersForm;
