import {COMPANY_OFFER_SELECTED} from '@components/CreditQuoter/Redux/Types/Types';
import {CompaniesOffertProps, ICompaniesOffert} from '@/components/GlobalQuoter/interface';
import {DEFAULT_COMPANY_OFFER} from '@components/CreditQuoter/constants';

const initialState: CompaniesOffertProps = {
    data: DEFAULT_COMPANY_OFFER,
};
const CompanyOfferSelected = (
    state = initialState,
    action: {type: string; data: ICompaniesOffert}
): CompaniesOffertProps => {
    switch (action.type) {
        case COMPANY_OFFER_SELECTED:
            return {
                ...state,
                data: action.data,
            };
        default:
            return state;
    }
};

export default CompanyOfferSelected;
