import {useDispatch, useSelector} from 'react-redux';
import {useForm, useFieldArray} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, ContainerForm, Modal} from '@Shopify/Ecommerce/styles';
import {NEWFEATURE} from '@components/Catalogue/constants';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import SeleccionIcon from '@components/Catalogue/icons/seleccion.svg';
import AlternateIcon from '@components/Catalogue/icons/alternarIcon.svg';
import OpenTextIcon from '@components/Catalogue/icons/openTextIcon.svg';
import {DataNewFeature, ICatArticleType} from '@components/Catalogue/interfaces';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaNewFeature} from '@components/Catalogue/schemaCataloguePersonalLoans';
import {AppDispatch, RootState} from '@/config/store';
import {getAllCatArticleType} from '@/components/Catalogue/Redux/Actions/GetAllCatArticleTypeDetailsActions';
import {saveNewDynamicFeature} from '@components/Catalogue/Redux/Actions/SaveNewDynamicFeatureActions';
import {NewFeatureFields} from '@components/Catalogue/NewFeature/NewFeatureFields';
import {NewFeatureProps} from '@/components/Articles/interface';

export const NewFeature = ({showModal, setShowModal}: NewFeatureProps) => {
    const [articleTypes, setArticleTypes] = useState<ICatArticleType[]>([]);
    const {
        register,
        reset,
        handleSubmit,
        control,
        watch,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schemaNewFeature),
        defaultValues: {
            featureName: '',
            catArticleType: {label: '', value: '', icon: ''},
            mandatoryFeature: false,
            printEtiquette: false,
            details: [{valueDescription: ''}, {valueDescription: ''}],
        },
    });
    const {fields, remove, append} = useFieldArray({
        control,
        name: 'details',
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch: AppDispatch = useDispatch();
    const handleAddOption = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        append({valueDescription: ''});
    };
    const onSubmit = (data: DataNewFeature) => {
        const details = data.catArticleType.id === 3 ? data.details : [{valueDescription: ''}];
        const output = {
            idTypeCharacteristics: data.catArticleType.id || 0,
            typeCharacteristics: data.catArticleType.value,
            nameCharacteristics: data.featureName,
            isRequerid: data.mandatoryFeature ? 1 : 0,
            includeLabelPrinter: data.printEtiquette,
            details: details,
            isInherited: false,
            enabled: true,
        };
        dispatch(saveNewDynamicFeature(output));
        reset();
        setShowModal(!showModal);
    };

    const formData = watch();

    useEffect(() => {
        if (token) {
            dispatch(getAllCatArticleType(token));
        }
    }, []);

    const articleTypeOptions = useSelector((state: RootState) => {
        return state.GetAllCatArticleTypeDetailsReducer;
    });
    useEffect(() => {
        if (!articleTypeOptions.loading && articleTypeOptions.data.length !== 0) {
            filterArticleTypes();
        }
    }, [articleTypeOptions.data]);

    const SelectHash: {[key: string]: string} = {
        'Input - Texto': 'Texto abierto',
        'Select - Lista desplegable': 'Selección',
        'Checkbox - 2 opciones': 'Alternar (si/no)',
    };
    const getOptionName = (Name: string) => {
        return SelectHash[Name];
    };

    const filterArticleTypes = () => {
        const {data} = articleTypeOptions;
        const articleTypes = data.data?.filter(
            (articleType: {id: number; descripcion: string}) => articleType.id !== 2
        );
        setArticleTypes([
            {
                label: getOptionName(articleTypes[1].descripcion),
                value: getOptionName(articleTypes[1].descripcion),
                id: articleTypes[1].id,
                icon: SeleccionIcon,
            },
            {
                label: getOptionName(articleTypes[2].descripcion),
                value: getOptionName(articleTypes[2].descripcion),
                id: articleTypes[2].id,
                icon: AlternateIcon,
            },
            {
                label: getOptionName(articleTypes[0].descripcion),
                value: getOptionName(articleTypes[0].descripcion),
                id: articleTypes[0].id,
                icon: OpenTextIcon,
            },
        ]);
    };
    return (
        <>
            <Modal Top="0">
                <ContainerFlex
                    FlexDir="column"
                    Align="start"
                    Width="661px"
                    Height="auto"
                    MaxH="679px"
                    OverFlowY="scroll"
                    Justify="start"
                    Padding="1.5rem"
                    backG="#FFFF"
                    Radius="24px"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    Gap="1.5rem"
                >
                    <ContainerFlex FlexDir="column" Align="start" Gap="1rem">
                        <Text FontSize="1.5rem" FontWeight="500" LHeight="1.5rem" Color="#2A2C2F">
                            {NEWFEATURE.TITLE}
                        </Text>
                        <Text Color="#54575C" FontWeight="400" FlexWrap="wrap" wSpace="normal">
                            {NEWFEATURE.DESC}
                        </Text>
                    </ContainerFlex>

                    <ContainerForm
                        onSubmit={handleSubmit(onSubmit)}
                        BackG="none"
                        Align="start"
                        FlexDir="column"
                        Justify="start"
                        Height="auto"
                        Gap="1.5rem"
                    >
                        <NewFeatureFields
                            register={register}
                            errors={errors}
                            control={control}
                            articleTypes={articleTypes}
                            formData={formData}
                            fields={fields}
                            remove={remove}
                            handleAddOption={handleAddOption}
                        />

                        <ContainerFlex
                            Gap="1.5rem"
                            Justify="center"
                            Align="center"
                            Width="352px"
                            Margin="0px auto"
                        >
                            <ButtonGenerals
                                FontFamily="Nunito"
                                Height="40px"
                                Padding="0.5rem 1rem"
                                Width="100%"
                                Gap="0.5rem"
                                BackGC="rgba(255, 255, 255, 0.05)"
                                Color="#FF6357"
                                HBackG="rgba(255, 255, 255, 0.05)"
                                Cursor="pointer"
                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                    e.preventDefault();
                                    setShowModal(!showModal);
                                }}
                            >
                                {'Cancelar'}
                            </ButtonGenerals>
                            <ButtonGenerals
                                FontFamily="Nunito"
                                Height="40px"
                                Padding="0.5rem 1rem"
                                Width="100%"
                                Gap="0.5rem"
                                Cursor="pointer"
                                type="submit"
                            >
                                {formData.catArticleType.value === ''
                                    ? 'Continuar'
                                    : 'Guardar y aplicar'}
                            </ButtonGenerals>
                        </ContainerFlex>
                    </ContainerForm>
                </ContainerFlex>
            </Modal>
        </>
    );
};
