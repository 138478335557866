export const GET_TYPES_OF_ROLE = 'GET_TYPES_OF_ROLE';
export const GET_TYPES_OF_ROLE_SUCCEED = 'GET_TYPES_OF_ROLE_SUCCEED';
export const GET_TYPES_OF_ROLE_ERROR = 'GET_TYPES_OF_ROLE_ERROR';

export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCEED = 'GET_STATES_SUCCEED';
export const GET_STATES_ERROR = 'GET_STATES_ERROR';

export const GET_MUNICIPALITY = 'GET_MUNICIPALITY';
export const GET_MUNICIPALITY_SUCCEED = 'GET_MUNICIPALITY_SUCCEED';
export const GET_MUNICIPALITY_ERROR = 'GET_MUNICIPALITY_ERROR';

export const GET_COLONY = 'GET_COLONY';
export const GET_COLONY_SUCCEED = 'GET_COLONY_SUCCEED';
export const GET_COLONY_ERROR = 'GET_COLONY_ERROR';

export const POST_CREATE_USER = 'POST_CREATE_USER';
export const POST_CREATE_USER_SUCCEED = 'POST_CREATE_USER_SUCCEED';
export const POST_CREATE_USER_ERROR = 'POST_CREATE_USER_ERROR';
