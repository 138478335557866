import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {SearchBranchLevel} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Redux/Actions/SearchLevelBranchActions';
import {SearchTextbox} from '@/components/General/Atoms/SearchBoxResultList/SearchTextbox';
import {BRANCH_ASSIGNMENT} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import arrow from '@images/arrow_small.svg';
import {
    SearchFilterAssignmentProps,
    SearchItem,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/interface';

export const SearchFilterAssignment: React.FC<SearchFilterAssignmentProps> = ({
    onSearchResult,
    onTreePath,
}) => {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState<SearchItem | null>(null);

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const companyId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.branchDetails?.[0]?.companyId
    );
    const searchResults = useSelector(
        (state: RootState) => state.SearchLevelBranchUser.userData || []
    );
    const handleSearch = (searchText: string) => {
        if (token && companyId && searchText.length >= 3) {
            dispatch(SearchBranchLevel(token, companyId, searchText));
        } else {
            onSearchResult([]);
        }
    };
    const getLastTitlePart = (title: string): string => {
        const parts = title.split('>');
        return parts[parts.length - 1].trim();
    };

    const renderItem = (item: SearchItem): JSX.Element => {
        const parts = item.title.split('>');
        return (
            <ContainerFlex PaddingR="4px" Align="center" Justify="flex-start" Gap="4px">
                {parts.map((part, index) => (
                    <React.Fragment key={index}>
                        <Text>{part.trim()}</Text>
                        {index < parts.length - 1 && (
                            <Image
                                src={arrow}
                                Width="1.125rem"
                                Height="1.125rem"
                                Transform="rotate(180deg)"
                                alt="arrow-icone"
                            />
                        )}
                    </React.Fragment>
                ))}
            </ContainerFlex>
        );
    };

    const handleSelect = (item: SearchItem) => {
        setSelectedItem(item);
        const idsArray = item.ids.split(',').map(Number);
        const titlesArray = item.title.split('>').map((title) => title.trim());

        if (onTreePath) {
            onTreePath({
                ids: idsArray,
                titles: titlesArray,
            });
        }
        const lastId = idsArray[idsArray.length - 1];
        const secondLastId = idsArray[idsArray.length - 2];
        onSearchResult([
            {
                ...item,
                ids: `${secondLastId},${lastId}`,
            },
        ]);
    };

    const handleReset = () => {
        setSelectedItem(null);
        onSearchResult([]);
        if (onTreePath) {
            onTreePath({ids: [], titles: []});
        }
    };

    return (
        <ContainerFlex FlexDir="column" Gap="16px">
            <ContainerFlex Height="40px" Justify="start" Gap="16px">
                <ContainerFlex Width="300px" Align="baseline" Justify="start" FlexDir="column">
                    <SearchTextbox<SearchItem>
                        placeholder={BRANCH_ASSIGNMENT.SEARCH}
                        searchAction={handleSearch}
                        resetAction={handleReset}
                        results={searchResults}
                        renderItem={renderItem}
                        onSelect={handleSelect}
                        showSelection={true}
                        defaultValue={selectedItem ? getLastTitlePart(selectedItem.title) : ''}
                        noDataContent={
                            <ContainerFlex
                                Padding="8px"
                                Align="center"
                                Justify="start"
                                Self="stretch"
                            >
                                <Text Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                                    No se encontraron resultados
                                </Text>
                            </ContainerFlex>
                        }
                        minSearchCharacters={3}
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
