import {AppDispatch} from '@/config/store';
import {
    GET_USERS_VALIDATION_DATA,
    GET_USERS_VALIDATION_SUCCESS,
    GET_USERS_VALIDATION_ERROR,
    RESET_USER_DATA,
    PRE_GET_USERS_VALIDATION_SUCCESS,
    ALREADY_GET_USERS_VALIDATION_SUCCESS,
    REFRESH_TOKEN,
} from '@components/Login/Redux/Types/Types';

import {URL} from '@config/constants/index';

import axios, {AxiosError, AxiosResponse} from 'axios';
import {IDataLoginAdministrator} from '@components/Login/Administrative/interfaces';
import {IDataLoginOperational} from '@components/Login/Operational/interfaces';
import {NavigateFunction} from 'react-router-dom';
import {UserData} from '@Login/interfaces';

export const getValidateUsersData = () => {
    return {
        type: GET_USERS_VALIDATION_DATA,
    };
};
export const alreadySavedData = (result: UserData) => {
    return {
        type: ALREADY_GET_USERS_VALIDATION_SUCCESS,
        already: result,
    };
};
export const preValidateSuccess = (result: AxiosResponse) => {
    return {
        type: PRE_GET_USERS_VALIDATION_SUCCESS,
        payload: result,
    };
};
export const getValidateUsersSuccess = (result: AxiosResponse) => {
    return {
        type: GET_USERS_VALIDATION_SUCCESS,
        payload: result,
    };
};
export const getValidateUsersError = (error: AxiosError) => {
    return {
        type: GET_USERS_VALIDATION_ERROR,
        error: error,
    };
};

export const logoutUser = () => {
    return {
        type: RESET_USER_DATA,
    };
};

export const refreshTokenAction = (newToken: string) => {
    return {
        type: REFRESH_TOKEN,
        newToken,
    };
};
export function getValidateLoginUsersAdministrative(
    data: IDataLoginAdministrator,
    navigate: NavigateFunction,
    setUserExists: React.Dispatch<React.SetStateAction<boolean>>
) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getValidateUsersData());
        setUserExists(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UserAccess}`,
                {
                    headers,
                    params: data,
                }
            );
            const loginAccessType = response.data.data.loginAccessType;
            if (loginAccessType === 4) {
                navigate('/Login/platform-selection');
                dispatch(preValidateSuccess(response));
            } else {
                dispatch(getValidateUsersSuccess(response));
            }
        } catch (error) {
            dispatch(getValidateUsersError(error as AxiosError));
            setUserExists(false);
        }
    };
}

export function getValidateLoginUsersOperative(
    data: IDataLoginOperational,
    setUserExists: React.Dispatch<React.SetStateAction<boolean>>
) {
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getValidateUsersData());
        setUserExists(true);
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AuthenticationOperative}`,
                {
                    headers,
                    params: data,
                }
            );
            dispatch(getValidateUsersSuccess(response));
        } catch (error) {
            dispatch(getValidateUsersError(error as AxiosError));
            setUserExists(false);
        }
    };
}
