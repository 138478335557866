import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect, useState} from 'react';
import {PaymentFrequencyEditForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/EditingForm/PaymentFrequencyEditForm';
import {PaymentFrequencyPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/Preview/PaymentFrequencyPreview';
import {AppDispatch, RootState} from '@/config/store';
import {getFrequencyPayments} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetFrequencyPaymentsActions';
import {setFrequencyPaymentsTable} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/FrequencyPaymentsTableActions';
import {updatePaymentFrequency} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {IGetFrequencyPaymentsParms} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {INewProductPaymentFrequencyDetailsProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/paymentFrequencyInterfaces';

export const NewProductPaymentFrequencyDetails: React.FC<
    INewProductPaymentFrequencyDetailsProps
> = ({frequencyRef}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData);
    const dispatch: AppDispatch = useDispatch();
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const periodMin = newLoanData.details.periodMin;
    const periodMax = newLoanData.details.periodMax;
    useEffect(() => {
        const params: IGetFrequencyPaymentsParms = {
            frequencyCountMin: periodMin.value,
            frequencyCountMax: periodMax.value,
        };
        if (token) {
            dispatch(getFrequencyPayments(token, params));
        }
    }, [periodMin, periodMax]);
    const frequencyPaymentReducer = useSelector((state: RootState) => state.getFrequencyPayments);
    useEffect(() => {
        const noWeeklyItemArray = frequencyPaymentReducer.data.filter(
            (item) => item.frecuancyId !== 3
        );
        const weeklyItemArray = frequencyPaymentReducer.data.filter(
            (item) => item.frecuancyId === 3
        );
        const termOrderArray = [...weeklyItemArray, ...noWeeklyItemArray];
        if (frequencyPaymentReducer.data.length > 0) {
            dispatch(setFrequencyPaymentsTable(termOrderArray));
        }
    }, [frequencyPaymentReducer.data]);
    const {frequencyPaymentsTable} = useSelector(
        (state: RootState) => state.setFrequencyPaymentsTable
    );
    useEffect(() => {
        if (!isEditing) return;
        dispatch(
            updatePaymentFrequency({
                ...newLoanData,
                details: {...newLoanData.details, frequencyTerm: frequencyPaymentsTable},
            })
        );
    }, [frequencyPaymentsTable]);
    return (
        <>
            {isEditing ? (
                <PaymentFrequencyEditForm
                    frequencyRef={frequencyRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <PaymentFrequencyPreview
                    frequencyRef={frequencyRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </>
    );
};
