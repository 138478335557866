import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';

import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerForm,
    Input,
    Text,
} from '@Shopify/Ecommerce/styles';
import {addCoOwnerSchema} from '@components/Quoter/CoownerBeneficiary/yupAddcoOwner';
import {COOWNEREDIT, DEFAULT_LADA} from '@Quoter/Steps/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import * as styles from '@/components/Quoter/stylesConstants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {CoOwnerData, showEditCoOwner} from '@Quoter/Steps/interface';
import {SelectLada} from '@Quoter/CoownerBeneficiary/SelectLada';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {
    PutUpdateCoOwner,
    fetchSelectEditCoOwner,
} from '@Quoter/CoownerBeneficiary/Redux/Actions/UpdateCoOwner';

export const EditCoOwnerBenefist: React.FC<showEditCoOwner> = ({showEdit, setShowEdit}) => {
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const SelectedCoOwner = useSelector((state: RootState) => state.UpdateCoOwner.targetCoOwner);

    const dispatch = useDispatch();
    const clientList = useSelector((state: RootState) => state.clientList);
    const {client} = clientList;
    const {id} = client;

    const {
        handleSubmit,
        register,
        setValue,
        reset,
        formState: {errors},
    } = useForm<CoOwnerData>({
        defaultValues: {
            coOwnerId: 0,
            name: '',
            lastName: '',
            secondLastName: '',
            flag: '',
            phone: '',
            lada: '',
            email: '',
        },
        resolver: yupResolver(addCoOwnerSchema),
    });

    useEffect(() => {
        if (SelectedCoOwner) {
            reset({
                coOwnerId: SelectedCoOwner.contactId || 0,
                name: SelectedCoOwner.names || '',
                lastName: SelectedCoOwner.lastName || '',
                secondLastName: SelectedCoOwner.secondLastName || '',
                flag: SelectedCoOwner.flag || DEFAULT_LADA.urlFlag,
                phone: SelectedCoOwner.phone || '',
                lada: SelectedCoOwner.lada || DEFAULT_LADA.lada,
                email: SelectedCoOwner.email || '',
            });
        }
    }, [SelectedCoOwner]);

    const handleNationSelected = (nation: IGetAllNations | null) => {
        if (!nation) return;
        setValue('lada', nation!.lada);
        setValue('flag', nation!.urlFlag);
    };

    const onSubmitEdit = (data: CoOwnerData) => {
        dispatch(PutUpdateCoOwner(data, token, setShowEdit, id));
    };

    return (
        <Modal
            Top="0"
            modalState={showEdit}
            changeModalState={() => {}}
            titleModalState={true}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmitEdit)}
                FlexDir="column"
                Width="26.875rem"
                Height="100vh"
                Padding="1rem"
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1.5rem">
                    <ContainerFlex Justify="space-between" Height="auto">
                        <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                            {COOWNEREDIT.TITLE}
                        </Text>
                        <Icons
                            {...styles.iconSearch}
                            onClick={() => setShowEdit(false)}
                            Cursor="pointer"
                        >
                            {'close'}
                        </Icons>
                    </ContainerFlex>
                    <ContainerCellGrid Align="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.FIRSTNAME}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('name')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.name?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid Align="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.LASTNAME_PATERNAL}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('lastName')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.lastName?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.LASTNAME_MATERNAL}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('secondLastName')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.secondLastName?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid Align="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.EMAIL}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('email')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.email?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid Align="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.PHONE}
                        </Text>
                        <ContainerFlex>
                            <SelectLada
                                handleNationSelected={handleNationSelected}
                                selectedLada={SelectedCoOwner?.lada}
                            />
                            <Input
                                type="number"
                                Padding="0.75rem"
                                FontSize="0.875rem"
                                Radius="0 3rem 3rem 0"
                                {...register('phone')}
                            />
                        </ContainerFlex>
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.phone?.message}
                        </Text>
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex Gap="1rem" Height="2.5rem">
                    <ButtonGenerals
                        Width="12rem"
                        BackGC="#FFFFFF"
                        Border="1px solid #5A5AFF"
                        Color="#5A5AFF"
                        HColor="#FFFFFF"
                        FontWeight="500"
                        Cursor="pointer"
                        onClick={() => {
                            setShowEdit(false);
                            dispatch(fetchSelectEditCoOwner(null));
                        }}
                    >
                        {'Cancelar'}
                    </ButtonGenerals>
                    <ButtonGeneral
                        width="12rem"
                        text={COOWNEREDIT.SAVE_CHANGES}
                        height="2.5rem"
                        cursor="pointer"
                        type="submit"
                    />
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
