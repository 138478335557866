import {
    SAVE_FILTER_LOANS_RESET,
    SAVE_FILTER_LOANS_USER,
} from '@components/LoansProducts/Redux/Types/Types';
import {SaveFilterLoansProps, ISaveFilterLoansReducer} from '@components/LoansProducts/interfaces';

const initialState: SaveFilterLoansProps = {
    saveFiltered: {
        companyId: 0,
        minimumAmount: 0,
        maximumAmount: 0,
        minimumTime: {label: '', value: 0},
        maximumTime: {label: '', value: 0},
        loanStatus: 0,
    },
};

const SaveFilterLoans = (
    state = initialState,
    action: ISaveFilterLoansReducer
): SaveFilterLoansProps => {
    switch (action.type) {
        case SAVE_FILTER_LOANS_USER:
            return {
                ...state,
                saveFiltered: action.saveFiltered,
            };
        case SAVE_FILTER_LOANS_RESET:
            return initialState;
        default:
            return state;
    }
};
export default SaveFilterLoans;
