import React from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {AdditionalDocumentTitleProps} from '@/components/PersonalLoans/interfaces';
import checkIcon from '@components/PersonalLoans/icons/checkIcon.svg';
import credentialIcon from '@components/PersonalLoans/icons/credentialIcon.svg';

const AdditionalDocumentTitle: React.FC<AdditionalDocumentTitleProps> = ({title, documentFile}) => {
    return (
        <ContainerFlex Justify="start" Gap="0.5rem" Width="35rem" Cursor="pointer">
            <Image
                alt="icon-check"
                Height="1.5rem"
                Width="1.5rem"
                src={documentFile ? checkIcon : credentialIcon}
                Cursor="pointer"
            />
            <ContainerFlex FlexDir="column" Width="max-content" Align="start" Cursor="pointer">
                <Text
                    Color="#2A2C2F"
                    FontSize="1rem"
                    FontWeight="400"
                    oFlow="none"
                    Cursor="pointer"
                >
                    {title}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default AdditionalDocumentTitle;
