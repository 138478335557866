import React from 'react';
import {
    ContainerCellGrid,
    ContainerFlex,
    Text,
    Input,
    Image,
} from '@/components/Shopify/Ecommerce/styles';

import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import {PIN_TEX, RESET_PASSWORD} from '@/components/UserProfileResetPassword/Operational/Constants';
import autoicono from '@/images/autoIconeUser.svg';

export const PinUser = () => {
    return (
        <ContainerFlex
            Padding="16px"
            backG="#FFF"
            Border="1px solid #E8E9EA"
            Gap="16px"
            Radius="16px"
            Height="max-content"
        >
            <ContainerCellGrid Gap="16px" FlexDir="column">
                <ContainerFlex Justify="start" Align="start" FlexDir="column">
                    <ContainerFlex Justify="space-between">
                        <Text Color="#2A2C2F" FontWeight="600" FontSize="1.25rem">
                            {PIN_TEX.TITLE}
                        </Text>
                        <GreenSwitch />
                    </ContainerFlex>
                    <ContainerFlex Justify="start">
                        <Text>{PIN_TEX.DESCRIPTION}</Text>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerCellGrid Gap="16px">
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text FontWeight="700">{RESET_PASSWORD.DATA_PIN}</Text>
                        <Input />
                    </ContainerFlex>
                    <ContainerFlex Justify="start" PaddingT="25px">
                        <Image Width="24px" Height="24px" src={autoicono} alt="icon-auto" />

                        <Text Color="#5A5AFF" FontSize="1rem" FontWeight="700">
                            {PIN_TEX.AUTO}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex />
                </ContainerCellGrid>
            </ContainerCellGrid>
        </ContainerFlex>
    );
};
