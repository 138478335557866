import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CREDIT_DETAIL_HISTORY_ERROR,
    GET_CREDIT_DETAIL_HISTORY_START,
    GET_CREDIT_DETAIL_HISTORY_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {ICreditDetailHistoryAction} from '@components/CreditsAndPawns/interfaces';

const getCreditDetailHistoryStart = () => {
    return {
        type: GET_CREDIT_DETAIL_HISTORY_START,
    };
};

const getCreditDetailHistorySuccess = (result: AxiosResponse) => {
    return {
        type: GET_CREDIT_DETAIL_HISTORY_SUCCESS,
        payload: result,
    };
};

const getCreditDetailHistoryError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_DETAIL_HISTORY_ERROR,
        error: error,
    };
};

export const getCreditDetailHistoryAction = ({
    token,
    creditId,
    PaidTypeId,
}: ICreditDetailHistoryAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditDetailHistory}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const params = {
        personalCreditApplicationId: creditId,
        paymentPaidTypeId: PaidTypeId,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditDetailHistoryStart());
        try {
            const response = await axios.get(url, {headers, params});
            dispatch(getCreditDetailHistorySuccess(response.data));
        } catch (e) {
            dispatch(getCreditDetailHistoryError(e as AxiosError));
        }
    };
};
