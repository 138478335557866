import {AppDispatch, RootState} from '@config/store';
import {useDispatch, useSelector} from 'react-redux';
import React, {ChangeEvent, useEffect, useState} from 'react';

import AddImage from '@images/AddImage.svg';
import {ICompanyFields, ICompanyImg, IOutCompanyFields} from '@MyCompany/Interface';
import * as content from '@components/CreateUsers/Styles';
import {FileUpLoad, FileUpLoadContent} from '@Steps/styles';
import {COMPANY_DATA, MY_COMPANY} from '@MyCompany/constants';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UpdateDataCompany} from '@MyCompany/UpdateDataCompany';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {updateIMGCompany} from '@MyCompany/Redux/Actions/UpdateImgCompany';
import {detailsCompany} from '@MyCompany/Redux/Actions/DetailsCompany';
import {updateCompanyNotUpdated} from '@MyCompany/Redux/Actions/UpdateCompanyData';

export const CompanyData = () => {
    const dispatch: AppDispatch = useDispatch();
    const [updateDataCompany, setUpdateDataCompany] = useState(false);
    const [imgCompany, setImgCompany] = React.useState<ICompanyImg>({img: null, err: ''});
    const {company} = useSelector((state: RootState) => state.detailsCompany);
    const {loading} = useSelector((state: RootState) => state.updateImgCompany);
    const {wasDataCompanyUpdated} = useSelector((state: RootState) => state.updateCompanyData);
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );
    const emailsArray: IOutCompanyFields[] = [];
    const phonesArray: IOutCompanyFields[] = [];
    const imgAdd = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const getImgBase64 = new FileReader();
            getImgBase64.readAsDataURL(e.target.files[0] as Blob);
            getImgBase64.onload = function () {
                const string64 = String(getImgBase64.result);
                const file = string64.split(',')[1];
                const extension = string64.split(';')[0].split('/')[1];
                if (MY_COMPANY.EXTENCIONS.includes(extension)) {
                    setImgCompany({img: string64, err: ''});
                    dispatch(
                        updateIMGCompany(token, {
                            companyId: companyId,
                            fileExtension: extension.toLocaleUpperCase(),
                            fileContent: file,
                        })
                    );
                } else setImgCompany({...imgCompany, err: MY_COMPANY.ERR_EXT});
            };
        }
    };

    const emailsPhonesToArray = (companyFields: ICompanyFields, outResult: IOutCompanyFields[]) => {
        const {contactCompanyId, typeContacto} = companyFields;

        const newArrayFields: Array<{key: string; value: string}> = [];

        if ('email' in companyFields) {
            newArrayFields.push({
                key: COMPANY_DATA.EMAIL_PHONE_FIELDS.MAIN,
                value: 'Sin información',
            });
            newArrayFields.push({
                key: COMPANY_DATA.EMAIL_PHONE_FIELDS.ALTERNATIVE,
                value:
                    companyFields.alternativeEmail === null
                        ? ''
                        : String(companyFields.alternativeEmail),
            });
            newArrayFields.push({
                key: COMPANY_DATA.EMAIL_PHONE_FIELDS.OPTIONAL,
                value:
                    companyFields.optionalEmail === null
                        ? ''
                        : String(companyFields.optionalEmail),
            });
        } else if ('phone' in companyFields) {
            newArrayFields.push({
                key: COMPANY_DATA.EMAIL_PHONE_FIELDS.MAIN,
                value:
                    companyFields.phone === null
                        ? ''
                        : String(companyFields.phone),
            });
            newArrayFields.push({
                key: COMPANY_DATA.EMAIL_PHONE_FIELDS.ALTERNATIVE,
                value:
                    companyFields.alternativePhone === null
                        ? ''
                        : String(companyFields.alternativePhone),
            });
            newArrayFields.push({
                key: COMPANY_DATA.EMAIL_PHONE_FIELDS.OPTIONAL,
                value:
                    companyFields.optionalPhone === null
                        ? ''
                        : String(companyFields.optionalPhone),
            });
        }

        const filterdFields = newArrayFields
            .filter((field) => field.value)
            .map((field) => ({
                contactCompanyId,
                typeContacto,
                emailPhoneValue: field.value,
            }));
        outResult.push(...filterdFields);
        return filterdFields;
    };
    useEffect(() => {
        if (company && company.companyImage)
            setImgCompany({...imgCompany, img: company.companyImage});
    }, [company.companyImage]);

    useEffect(() => {
        if (wasDataCompanyUpdated && token && companyId) {
            dispatch(detailsCompany(token, companyId));
            dispatch(updateCompanyNotUpdated());
        }
    }, [wasDataCompanyUpdated, token, companyId]);

    return (
        <ContainerFlex
            backG="#FFF"
            Radius="1rem"
            Height="auto"
            Gap="1rem"
            Padding="1rem"
            FlexDir="Column"
        >
            <ContainerFlex Justify="space-between">
                <Text FontSize="1.25rem" FontWeight="500" Color="#2A2C2F">
                    {MY_COMPANY.DETAILS_ENTERPRICE}
                </Text>
                <Text
                    FontWeight="500"
                    Color="#5A5AFF"
                    Cursor="pointer"
                    onClick={() => setUpdateDataCompany(true)}
                >
                    {MY_COMPANY.EDIT}
                </Text>
            </ContainerFlex>
            {company && (
                <>
                    <ContainerFlex Gap="1rem" Display="grid" GridColumns="auto 5fr auto">
                        <FileUpLoad accept="image/*" type="file" onChange={imgAdd} id="file" />
                        {loading ? (
                            <ContainerFlex Width="64px" Height="64px">
                                <LoadingAtaskate />
                            </ContainerFlex>
                        ) : (
                            <FileUpLoadContent {...content.contentImg}>
                                {imgCompany.img ? (
                                    <Image src={imgCompany.img} {...content.contentImg.imgStart} />
                                ) : (
                                    <Image src={AddImage} {...content.contentImg.imgAdd} />
                                )}
                            </FileUpLoadContent>
                        )}

                        <ContainerFlex FlexDir="Column" Align="start">
                            <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                                {company.nameCompany}
                            </Text>
                            <Text FontWeight="500" Color="#2A2C2F">
                                {company.businessName}
                            </Text>
                        </ContainerFlex>
                        {company.startCompany && (
                            <Text FontSize="0.75rem" Color="#54575C">
                                {`${MY_COMPANY.ACTIVE_FROM}: ${company.startCompany}`}
                            </Text>
                        )}
                    </ContainerFlex>
                    {imgCompany.err && (
                        <Text
                            Height="100%"
                            Width="100%"
                            Justify="start"
                            FontSize="0.7rem"
                            Color="#FF6357"
                            Padding="4px 0"
                            wSpace="normal"
                        >
                            {imgCompany.err}
                        </Text>
                    )}
                    <ContainerFlex Gap="1rem" Display="grid" GridColumns="repeat(3, 1fr)">
                        {company.emailsCompanies &&
                            company.emailsCompanies.map((emailItem) =>
                                emailsPhonesToArray(emailItem, emailsArray).map((item, index) => (
                                    <ContainerFlex
                                        key={index}
                                        FlexDir="Column"
                                        Align="start"
                                        Gap="0.25rem"
                                    >
                                        <Text Color="#54575C">{item.typeContacto}</Text>
                                        <Text>
                                            {item.emailPhoneValue}
                                        </Text>
                                    </ContainerFlex>
                                ))
                            )}
                    </ContainerFlex>
                    <ContainerFlex Gap="1rem" Display="grid" GridColumns="repeat(3, 1fr)">
                        {company.phoneCompanies &&
                            company.phoneCompanies.map((phoneItem) =>
                                emailsPhonesToArray(phoneItem, phonesArray).map((item, index) => (
                                    <ContainerFlex
                                        key={index}
                                        FlexDir="Column"
                                        Align="start"
                                        Gap="0.25rem"
                                    >
                                        <Text Color="#54575C">{item.typeContacto}</Text>
                                        <Text >
                                            {item.emailPhoneValue}
                                        </Text>
                                    </ContainerFlex>
                                ))
                            )}
                    </ContainerFlex>
                </>
            )}
            <UpdateDataCompany
                updateDataCompany={updateDataCompany}
                setUpdateDataCompany={setUpdateDataCompany}
                emails={emailsArray}
                phones={phonesArray}
            />
        </ContainerFlex>
    );
};
