import {Controller} from 'react-hook-form';
import React from 'react';
import {Checkbox} from '@/components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';
import {PAYMENT_METHODS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IRowPaymentMethodEditingFormProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/paymentMethodsInterfaces';

export const RowPaymentMethodEditingForm: React.FC<IRowPaymentMethodEditingFormProps> = ({
    control,
    index,
    isSubmitted,
    lastIndex,
    item,
    showToolTip,
    setShowToolTip,
    trigger,
    handleCheckbox,
}) => {
    return (
        <ContainerFlex Gap="0.5rem" Justify="start" Align="start">
            <Controller
                name={`typePaymentsIns.${index}.isChecked`}
                control={control}
                render={({field: {onChange, ...field}}) => (
                    <Checkbox
                        {...field}
                        checked={item.isChecked}
                        onChange={(e) => {
                            onChange(e);
                            handleCheckbox(item.typePaymentId);
                            if (isSubmitted) trigger('typePaymentsIns');
                        }}
                    />
                )}
            />
            <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                {index !== lastIndex ? (
                    <Text Color="#2A2C2F">{item.typePaymentName}</Text>
                ) : (
                    <ContainerFlex Gap="0.5rem" Width="max-content" Height="max-content">
                        <Text Color="#2A2C2F">{item.typePaymentName}</Text>
                        <ContainerFlex
                            Width="24px"
                            Height="24px"
                            FlexDir="column"
                            Justify="start"
                            Position="relative"
                            onMouseEnter={() => setShowToolTip(true)}
                            onMouseLeave={() => setShowToolTip(false)}
                        >
                            <Image
                                src={questionIcon}
                                Width="24px"
                                Height="24px"
                                Cursor="pointer"
                                alt="tooltip-icon"
                            />
                            {showToolTip && (
                                <ToolTip description={PAYMENT_METHODS.TOOLTIP_DESCRIPTION} />
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                )}
                <Text Color="#54575C" FontSize="0.875rem">
                    {item.typePaymentDescription}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
