import {AppDispatch} from '@/config/store';
import axios from 'axios';
import {URL} from '@config/constants/index';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {DELETE_EXTENSION} from '@components/ShoppingCart/Redux/types';

const deleteStart = () => ({
    type: DELETE_EXTENSION.START,
});
const deleteSuccess = () => ({
    type: DELETE_EXTENSION.SUCCESS,
});
const deleteError = () => ({
    type: DELETE_EXTENSION.ERROR,
});
export function removeExtension(token: string, extensionId: number, refreshCartId?: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(deleteStart());
        try {
            await axios.delete(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteExtensionTemporaly}`,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                    data: {extensionId},
                }
            );
            dispatch(deleteSuccess());
            if (refreshCartId) dispatch(fetchCartById(token, refreshCartId));
        } catch (error) {
            dispatch(deleteError());
        }
    };
}
