import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {IFilterCheckboxProps} from '@/components/GlobalQuoter/interface';
import {SELECT_ALL} from '@/components/GlobalQuoter/TempConstants';

const FilterCheckbox = ({
    filterOptions,
    filters,
    handleFilter,
    title,
    keyName,
    filtersValues,
}: IFilterCheckboxProps) => {
    const optionsHeight = filterOptions.length * 40;
    const handleCheckbox = (value: number) => {
        const isChecked = filtersValues.includes(value);
        const updatedOptions = isChecked
            ? filtersValues.filter((id) => id !== value)
            : [...filtersValues, value];
        handleFilter({
            ...filters,
            [keyName]: updatedOptions,
        });
    };
    const toggleSelectAllHandler = () => {
        const allValues = filterOptions.map((option) => option.value);
        const updatedOptions = filtersValues.length === allValues.length ? [] : allValues;
        handleFilter({
            ...filters,
            [keyName]: updatedOptions,
        });
    };
    const areAllSelected = filterOptions.every((option) => filtersValues.includes(option.value));
    return (
        <ContainerFlex
            FlexDir="column"
            Gap="8px"
            Justify="flex-start"
            Align="flex-start"
            Height={`${50 + optionsHeight}px`}
        >
            <Text Color="#1D1E20" FontFamily="Nunito" FontWeight="700" LetterSpacing="0.3px">
                {title}
            </Text>
            <ContainerFlex Justify="flex-start" Align="center" Gap="4px" Height="40px">
                <Checkbox onChange={toggleSelectAllHandler} checked={areAllSelected} />
                <Text Color="#1D1E20" FontFamily="Nunito" FontWeight="400" LetterSpacing="0.3px">
                    {SELECT_ALL}
                </Text>
            </ContainerFlex>
            {filterOptions.map((option) => {
                return (
                    <ContainerFlex
                        Justify="flex-start"
                        Align="center"
                        Gap="4px"
                        Height="40px"
                        key={option.value}
                    >
                        <Checkbox
                            onChange={() => handleCheckbox(option.value)}
                            checked={filtersValues.includes(option.value)}
                            id={option.label}
                        />
                        <Text
                            Color="#1D1E20"
                            FontFamily="Nunito"
                            FontWeight="400"
                            LetterSpacing="0.3px"
                        >
                            {option.label}
                        </Text>
                    </ContainerFlex>
                );
            })}
        </ContainerFlex>
    );
};

export default FilterCheckbox;
