import {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {
    BranchOption,
    SearchItem,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';

export const useBranchTree = (
    checkedOptions: Set<number>,
    setCheckedOptions: React.Dispatch<React.SetStateAction<Set<number>>>
) => {
    const [columns, setColumns] = useState<BranchOption[][]>([]);
    const [selectedOptions, setSelectedOptions] = useState<BranchOption[]>([]);

    const search = useSelector(
        (state: RootState) => state.SearchLevelBranchUser.userData?.data as SearchItem[] | undefined
    );
    const TreeBranchsData = useSelector((state: RootState) => state.GetLevelBranchUser?.userData);
    const treeUser = useSelector(
        (state: RootState) => state.GetBrancheTreeUser.userData as number[]
    );

    useEffect(() => {
        const processSearchData = (searchItems: SearchItem[]) => {
            const searchMap = new Map<number, BranchOption[]>();

            searchItems.forEach((item: SearchItem) => {
                const ids = item.ids.split(',').map(Number);
                const levels = item.title.split(' > ');

                ids.forEach((id, index) => {
                    if (!searchMap.has(id)) {
                        searchMap.set(id, []);
                    }
                    searchMap.get(id)?.push({
                        idLevel: id,
                        levelName: levels[index],
                    });
                });
            });

            return Array.from(searchMap.values());
        };

        const searchColumns = search?.length ? processSearchData(search) : [];

        const columnStrategies = {
            hasSearchColumns: () => searchColumns,
            hasTreeData: () => [TreeBranchsData],
            default: () => [],
        };

        const getColumns = () => {
            if (searchColumns.length) return columnStrategies.hasSearchColumns();
            if (TreeBranchsData.length) return columnStrategies.hasTreeData();
            return columnStrategies.default();
        };

        const newColumns = getColumns();
        const newSelectedOptions = searchColumns.length ? searchColumns[0] : [];

        setColumns(newColumns);
        setSelectedOptions(newSelectedOptions);
    }, [search, TreeBranchsData]);

    useEffect(() => {
        if (treeUser && treeUser.length > 0) {
            setCheckedOptions(new Set(treeUser));
        }
    }, [treeUser]);

    const getAllChildIds = (option: BranchOption): number[] => {
        const ids = option.idBranch ? [option.idBranch] : [];
        const nextLevelIds = option.nextLevel ? option.nextLevel.flatMap(getAllChildIds) : [];
        const branchIds = option.branches
            ? option.branches.flatMap((branch) => (branch.idBranch ? [branch.idBranch] : []))
            : [];

        return [...ids, ...nextLevelIds, ...branchIds];
    };

    const isParentChecked = (option: BranchOption): boolean => {
        const childIds = getAllChildIds(option);
        if (childIds.length === 0) return false;

        return childIds.every((id) => checkedOptions.has(id));
    };

    const getNextLevel = (search: SearchItem[], columnIndex: number) => {
        const nextLevelIndex = columnIndex + 1;
        return search.map((item: SearchItem) => ({
            idLevel: Number(item.ids.split(',')[nextLevelIndex]),
            levelName: item.title.split(' > ')[nextLevelIndex],
            typeLocation: item.typeLocation,
        }));
    };

    const getNextItems = (option: BranchOption) => [
        ...(option.nextLevel || []),
        ...(option.branches?.map((branch) => ({
            ...branch,
            levelName: branch.branchName,
            isBranch: true,
        })) || []),
    ];

    const handleSelect = (option: BranchOption, columnIndex: number) => {
        const newSelectedOptions = [...selectedOptions.slice(0, columnIndex), option];
        setSelectedOptions(newSelectedOptions);

        const baseColumns = columns.slice(0, columnIndex + 1);
        const nextItems = getNextItems(option);

        const hasMoreSearchLevels = () => {
            return search?.length && columnIndex < search[0].ids.split(',').length - 1;
        };

        const nextColumnStrategies = {
            hasNextItems: () => nextItems,
            hasSearchLevels: () => (search ? getNextLevel(search, columnIndex) : []),
            default: () => [],
        };

        const getNextColumns = () => {
            if (nextItems.length > 0) return nextColumnStrategies.hasNextItems();
            if (hasMoreSearchLevels()) return nextColumnStrategies.hasSearchLevels();
            return nextColumnStrategies.default();
        };

        const nextColumns = getNextColumns();
        setColumns(nextColumns.length ? [...baseColumns, nextColumns] : baseColumns);
    };

    const handleCheckboxChange = (option: BranchOption) => {
        const childIds = getAllChildIds(option);
        const checkboxActions = new Map([
            [
                true,
                (ids: number[], set: Set<number>) => {
                    ids.forEach((id) => set.delete(id));
                    return set;
                },
            ],
            [
                false,
                (ids: number[], set: Set<number>) => {
                    ids.forEach((id) => {
                        if (id) set.add(id);
                    });
                    return set;
                },
            ],
        ]);

        setCheckedOptions((prev) => {
            const newSet = new Set(prev);
            const isCurrentlyChecked = isParentChecked(option);
            const allIds = option.idBranch ? [option.idBranch, ...childIds] : [...childIds];

            return checkboxActions.get(isCurrentlyChecked)?.(allIds, newSet) ?? newSet;
        });
    };

    return {
        columns,
        selectedOptions,
        handleSelect,
        handleCheckboxChange,
        isParentChecked,
        setColumns,
        setSelectedOptions,
    };
};
