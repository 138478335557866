import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useSelector} from 'react-redux';

import AddIconBlue from '@images/addIcon.svg';
import {ADDRESS} from '@components/LocationsBranch/constants';
import {IAddressData, ICreateAddress} from '@components/LocationsBranch/interfaces';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import * as style from '@components/LocationsBranch/stylesLocations';

export const AddressLocations = ({
    nameAddress,
    setValue,
    setModalAddress,
    errors,
    onClientPage = false,
}: ICreateAddress) => {
    const {address} = useSelector((state: RootState) => state.getAddress);

    const formatAddress = (address: IAddressData) => {
        if (!address) return '';

        const interNumPart = address.interNum ? `${address.interNum}, ` : '';

        return `${address.street} ${address.outerNum}, ${interNumPart}${address.colony?.label}, ${address.postalCode}, ${address.municipaly?.label}, ${address.state?.label}`;
    };

    const setAddress = () => {
        if (address)
            setValue(nameAddress, {
                colony: Number(address.colony?.value),
                street: address.street,
                outerNumber: address.outerNum,
                interiorNumber: address.interNum,
                municipal: address.municipaly?.label,
                state: address.state?.label,
                postalCode: address.postalCode,
            });
    };

    const newCustomStyles = {
        ...style.contentGral,
        ...(onClientPage && {Border: 'none', Padding: '0'}),
    };

    const newCustomTextStyles = {
        ...style.contentGralTitle,
        ...(onClientPage && {FontSize: '1rem'}),
    };

    useEffect(() => setAddress(), [address]);

    return (
        <ContainerFlex {...newCustomStyles}>
            <Text {...newCustomTextStyles}>{ADDRESS.TITLE}</Text>
            <ContainerFlex
                Justify="start"
                backG="#FFFFFF"
                Gap="0.5rem"
                onClick={() => setModalAddress(true)}
                Cursor="pointer"
                Flex="12"
            >
                {address ? (
                    <>
                        <ContainerFlex Justify="start">
                            <Text Color="#54575C">{formatAddress(address)}</Text>
                        </ContainerFlex>
                        <ContainerFlex Justify="end" FlexBasis="10%">
                            <Text
                                onClick={() => setModalAddress(true)}
                                Color="#5A5AFF"
                                FontWeight="700"
                                Cursor="pointer"
                                FontSize="0.875rem"
                            >
                                {ADDRESS.MODIFY}
                            </Text>
                        </ContainerFlex>
                    </>
                ) : (
                    <>
                        <Image src={AddIconBlue} alt="add" color="#5A5AFF" />
                        <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" FontSize="1rem">
                            {ADDRESS.ADD}
                        </Text>
                    </>
                )}
            </ContainerFlex>
            {errors[nameAddress] && !address && (
                <Text Color="#FF6357" FontSize="0.75rem">
                    {ADDRESS.ERR}
                </Text>
            )}
        </ContainerFlex>
    );
};
