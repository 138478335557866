import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {removeExtension} from '@components/ShoppingCart/Redux/Actions/deleteExtension';
import {ContainerFlex, Image, ContainerImg, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import moreIcon from '@images/MoreMenuBlack.svg';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {RenewalItemProps} from '@components/ShoppingCart/interface';
import RenewealItemMenu from '@components/ShoppingCart/RenewealItemMenu';
import {ENDORSEMENTS, OPTIONS} from '@components/ShoppingCart/constants';
import useTransferEndorseOrDischarge from '@/hooks/useTransferEndorseOrDischarge';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import RenewalExtend from '@components/ShoppingCart/RenewalMenuOption/RenewalExtend';
import RenewalExtension from '@components/ShoppingCart/RenewalExtension';
import Installments from '@/components/ShoppingCart/RenewalMenuOption/Installments';
import InstallmentItem from '@/components/ShoppingCart/RenewalMenuOption/InstalmentItem';
import {removeDischargeExtension} from '@components/ShoppingCart/Redux/Actions/deleteDischargeEndorsement';
import {
    fetchUpdateEndorsementCart,
    clearEndorsementCartError,
} from '@components/ShoppingCart/Redux/Actions/EndorsementsReducer';
import useToast from '@/hooks/useToast';

const RenewalItem: React.FC<RenewalItemProps> = ({
    carShoppingId,
    endorsement,
    isOpen,
    onMenuToggle,
    isSubMenuOpen,
    onSubMenuToggle,
    isSelected,
    onSelectOption,
    selectedSubMenuOption,
    openedMenu,
}) => {
    const dispatch = useDispatch();
    const [hasError, setHasError] = React.useState<boolean>(false);
    const {errorMessage} = useSelector((state: RootState) => state.endorsementsReducer);

    const [onMenuOption, setOnMenuOption] = useState<string | null>(openedMenu ? openedMenu : null);
    const {showToast, ToastContainer} = useToast();

    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );

    const {handleTransfer} = useTransferEndorseOrDischarge({
        tranferData: {
            itemId: endorsement.endorsementId,
            contractName: endorsement.contractName,
            extensionId: endorsement.extension?.extensionId || 0,
        },
        token: token,
        carShoppingId: carShoppingId,
        onSuccess: () => {
            onSelectOption();
            onSubMenuToggle('');
            getCart();
        },
        onError: (message) => {
            handleError(message);
        },
    });

    const handleError = (message: string) => {
        showToast({
            title: 'Error',
            message: message,
            type: 'error',
            duration: 5000,
        });
    };

    const handleSubMenuOptionSelect = (option: string) => {
        onSubMenuToggle(option);
        setOnMenuOption(option);
        onSelectOption();
    };

    const handleExtend = () => {
        setOnMenuOption(OPTIONS.EXTENSION);
        onSelectOption();
    };

    const handlePayTowardsPrincipal = () => {
        setOnMenuOption(OPTIONS.PAY_TOWARDS_PRINCIPAL);
        onSelectOption();
    };

    const handleRemoveExtension = async (reload: boolean = true) => {
        try {
            await dispatch(
                removeExtension(token, Number(endorsement.extension?.extensionId), carShoppingId)
            );
            onSelectOption();
            onMenuToggle();
            setOnMenuOption(null);
            onSubMenuToggle('');
        } catch (error) {
            showToast({
                title: ENDORSEMENTS.ERROR,
                message: ENDORSEMENTS.REMOVE_EXTENSION_ERROR,
                type: 'error',
                duration: 5000,
            });
        }
    };

    useEffect(() => {
        if (errorMessage !== '' && hasError) {
            showToast({
                title: ENDORSEMENTS.ERROR_ADDING_PAYMENT,
                message: errorMessage,
                type: 'error',
                duration: 5000,
                onTimeoutCloseAction: () => {
                    dispatch(clearEndorsementCartError());
                    setHasError(false);
                },
            });
        }
    }, [errorMessage]);

    const onError = () => {
        setHasError(true);
    };

    const handleChangeToPayment = async () => {
        const body = {
            grossAmount: Number(endorsement.extension?.totalExtension),
            carShoppingId,
            contractId: endorsement.contractId,
        };
        await dispatch(fetchUpdateEndorsementCart(token, body, handleRemoveExtension, onError));
    };

    const getCart = () => {
        if (token && carShoppingId) {
            dispatch(fetchCartById(token, carShoppingId));
        }
    };

    const handleRemovePayment = () => {
        const body = {
            grossAmount: 0,
            carShoppingId,
            contractId: endorsement.contractId,
        };
        dispatch(fetchUpdateEndorsementCart(token, body, getCart));
        onSelectOption();
        onSubMenuToggle('');
    };

    const onRenewalExit = (refresh: boolean) => {
        setOnMenuOption(null);
        onMenuToggle();
        if (refresh) getCart();
    };

    const handleCancel = async () => {
        const extensionId = endorsement.extension?.extensionId || 0;
        await dispatch(
            removeDischargeExtension(token, endorsement.endorsementId, carShoppingId, extensionId)
        );
        onSelectOption();
        onSubMenuToggle('');
    };

    const renderSelectedOption = (option: string | null) => {
        if (!option) return null;

        switch (option) {
            case OPTIONS.EXTENSION:
                return <RenewalExtend onExit={onRenewalExit} endorsement={endorsement} />;
            case OPTIONS.PAY_TOWARDS_PRINCIPAL:
                return <Installments onExit={onRenewalExit} endorsement={endorsement} />;
            default:
                return null;
        }
    };

    return (
        <>
            <FlexPanel
                Width="24rem"
                Padding="1rem 0.5rem"
                Align="center"
                Gap="1.5rem"
                Radius="0.25rem"
                Background={isOpen ? '#FAFAFF' : '#FFFFFF'}
            >
                <ContainerFlex
                    FlexDir="column"
                    Justify="center"
                    Align="flex-start"
                    Gap="0.5rem"
                    Flex="1 0 0"
                >
                    <FlexPanel
                        Justify="center"
                        Align="center"
                        Radius="2rem"
                        Padding="0.125rem 0.5rem"
                        Height="1.125rem"
                        Border="1px solid #99E0FF"
                        Background="#DBF4FF"
                    >
                        <Text FontWeight="700" FontSize="0.75rem" Color="#004461">
                            {ENDORSEMENTS.ENDORSEMENT}
                        </Text>
                    </FlexPanel>
                    <FlexPanel Justify="flex-start" Align="flex-start" Gap="0.5rem" Self="stretch">
                        <ContainerImg Height="3rem" Width="3rem" Justify="flex-start">
                            <Image
                                src={endorsement.imgUrl}
                                alt="article"
                                Cursor="pointer"
                                Width="3rem"
                                Height="3rem"
                                Border="1px solid #5A5AFF"
                                Radius="0.5rem"
                            />
                        </ContainerImg>
                        <FlexPanel FlexDir="column" Align="flex-start" Gap="0.5rem">
                            <Text
                                Color="#1D1E20"
                                FontSize="1rem"
                                FontWeight="700"
                                LetterSpacing="0.019rem"
                            >
                                {ENDORSEMENTS.ARTICLES_COUNT}
                                {endorsement.pledgeNumber}
                            </Text>
                            <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                {ENDORSEMENTS.CONTRACT_NUM}
                                {endorsement.contractName}
                            </Text>
                        </FlexPanel>
                    </FlexPanel>
                </ContainerFlex>
                <Text
                    Color="#1D1E20"
                    TextAlign="right"
                    FontSize="1rem"
                    FontWeight="700"
                    LetterSpacing="0.019rem"
                >
                    {numberToCurrency(endorsement.subEndorsement)}
                </Text>
                <Image
                    src={moreIcon}
                    alt="options"
                    Cursor="pointer"
                    Width="1.5rem"
                    Height="1.5rem"
                    onClick={() => {
                        setOnMenuOption(null);
                        onMenuToggle();
                    }}
                />
            </FlexPanel>
            {(endorsement.extension || onMenuOption === OPTIONS.EXTENSION) && (
                <>
                    {endorsement.extension && (
                        <>
                            <RenewalExtension
                                endorsement={endorsement}
                                isMenuOpen={isSubMenuOpen}
                                onMenuToggle={onSubMenuToggle}
                                onSelectOption={handleSubMenuOptionSelect}
                                onRemoveExtension={handleRemoveExtension}
                                selectedSubMenuOption={selectedSubMenuOption}
                                onChangeToPayment={handleChangeToPayment}
                            />
                        </>
                    )}
                    {isSelected &&
                        onMenuOption === OPTIONS.EXTENSION &&
                        renderSelectedOption(onMenuOption)}
                </>
            )}
            {endorsement.pass && onMenuOption !== OPTIONS.PAY_TOWARDS_PRINCIPAL ? (
                <InstallmentItem
                    pass={endorsement.pass}
                    isMenuOpen={isSubMenuOpen}
                    onMenuToggle={onSubMenuToggle}
                    onSelectOption={handleSubMenuOptionSelect}
                    onRemovePayment={handleRemovePayment}
                    selectedSubMenuOption={selectedSubMenuOption}
                />
            ) : (
                isSelected &&
                onMenuOption === OPTIONS.PAY_TOWARDS_PRINCIPAL &&
                renderSelectedOption(onMenuOption)
            )}
            {!isSelected && isOpen && (
                <RenewealItemMenu
                    endorsement={endorsement}
                    setViewingMenu={() => {
                        onMenuToggle();
                        setOnMenuOption(null);
                    }}
                    onDischargeClick={() => handleTransfer(TYPE_MOVEMENT_ADD_CART.DISCHARGE)}
                    onExtendClick={handleExtend}
                    onPayTowardsPrincipalClick={handlePayTowardsPrincipal}
                    onCancelClick={handleCancel}
                />
            )}
            <ToastContainer />
        </>
    );
};
export default RenewalItem;
