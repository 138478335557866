import React, {useEffect, useState, useMemo, useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {
    toggleAllUsers,
    toggleUserSelect,
} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import {
    mergeAddedUsers,
    getIDUser,
    sortUsers,
} from '@components/LocationsBranch/Redux/Action/GetUserByLevel';
import {ContainerFlex, ContainerCellGrid, Text, Image} from '@Shopify/Ecommerce/styles';
import sortIcon from '@images/ListSortArrows.svg';
import sortAscIcon from '@images/ListSortArrowsAsc.svg';
import sortDescIcon from '@images/ListSortArrowsDesc.svg';
import {NEWGROUP, SortDirection as Sorting} from '@components/LocationsBranch/constants';
import {ILocationsUser, IUserData} from '@components/LocationsBranch/interfaces';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import UserListItem from '@components/LocationsBranch/UserListItem';

const UsersGroupList = ({modal, setModal, setValue}: ILocationsUser) => {
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [sortColumn, setSortColumn] = useState<string>('');
    const [sortDirection, setSortDirection] = useState('');
    const dispatch = useDispatch();

    const {addedUsers, usersList} = useSelector((state: RootState) => ({
        addedUsers: state.AddUserEmployee.addedUsers,
        usersList: state.GetUserByLevelId,
        levels: state.getLevels.level,
    }));

    const companyUsersData = useMemo(
        () => (Array.isArray(usersList.companyUsers.data) ? usersList.companyUsers.data : []),
        [usersList]
    );

    const handleSort = useCallback(
        (column: keyof IUserData) => {
            const newDirection =
                sortColumn === column && sortDirection === Sorting.Asc ? Sorting.Desc : Sorting.Asc;
            setSortColumn(column);
            setSortDirection(newDirection);
            dispatch(sortUsers(column, newDirection));
        },
        [sortColumn, sortDirection, dispatch]
    );

    const renderSortIcon = useCallback(
        (column: keyof IUserData) => {
            const isActive = sortColumn === column;
            const iconMap = {
                [Sorting.Asc]: sortAscIcon,
                [Sorting.Desc]: sortDescIcon,
            };
            const icon = isActive ? iconMap[sortDirection] : sortIcon;

            return (
                <Image
                    src={icon}
                    alt={`sort ${isActive ? sortDirection : ''}`}
                    Width="0.875rem"
                    Height="0.875rem"
                    Cursor="pointer"
                    onClick={() => handleSort(column)}
                />
            );
        },
        [sortColumn, sortDirection, handleSort]
    );

    const formatRoles = useCallback(
        (roles: string[]): string =>
            roles.length === 1 ? roles[0] : `${roles[0]} y ${roles.length - 1} más`,
        []
    );

    const delUser = (id: number) => {
        dispatch(getIDUser(id));
        setModal({...modal, locations: true});
    };

    useEffect(() => {
        if (addedUsers.length > 0) {
            const mappedAddedUsers: IUserData[] = addedUsers.map((user) => ({
                employId: user.employeeId,
                userName: user.name,
                names: user.name,
                image: user.imageURL,
                deleteIcon: '',
                lastSession: user.dateSession.toString(),
                roleName: user.role,
                selected: false,
            }));
            dispatch(mergeAddedUsers(mappedAddedUsers));
        }
    }, [addedUsers, dispatch]);

    useEffect(() => {
        if (companyUsersData.length > 0) {
            const allSelected = companyUsersData.every((user) => user.selected);
            setIsAllSelected(allSelected);
        }
    }, [companyUsersData]);

    const ColumnHeader = ({
        label,
        renderSortIcon,
    }: {
        label: string;
        renderSortIcon: () => JSX.Element;
    }) => (
        <ContainerCellGrid Justify="start" Align="center" Gap="0.25rem">
            <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="500" oFlow="none">
                {label}
            </Text>
            {renderSortIcon()}
        </ContainerCellGrid>
    );

    const handleSelectAll = () => {
        setIsAllSelected((prevIsAllSelected) => {
            const newIsAllSelected = !prevIsAllSelected;
            dispatch(toggleAllUsers(newIsAllSelected));
            return newIsAllSelected;
        });
    };
    const handleUserSelect = (userId: number) => {
        dispatch(toggleUserSelect(userId));
    };

    const users = usersList.allUserData
        .filter((user) => user.selected === true)
        .map((idUsers) => idUsers.employId);

    useEffect(() => setValue('users', users), [users.length]);

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="center">
            <ContainerFlex
                Display="grid"
                GridColumns="18px repeat(3, minmax(80px, 1fr)) 24px"
                Gap="1rem"
                Justify="start"
                Align="center"
                Padding="0.5rem"
                Bb="solid 1px #E8E9EA"
            >
                <Checkbox checked={isAllSelected} onChange={handleSelectAll} />
                <ColumnHeader
                    label={NEWGROUP.USERNAME}
                    renderSortIcon={() => renderSortIcon('userName')}
                />
                <ColumnHeader
                    label={NEWGROUP.ROL}
                    renderSortIcon={() => renderSortIcon('roleName')}
                />
                <ColumnHeader
                    label={NEWGROUP.LASTSESSION}
                    renderSortIcon={() => renderSortIcon('lastSession')}
                />
            </ContainerFlex>
            {companyUsersData &&
                companyUsersData.length > 0 &&
                companyUsersData.map((user) => (
                    <UserListItem
                        key={user.employId}
                        user={user}
                        onDelete={delUser}
                        onSelect={handleUserSelect}
                        formattedRoles={formatRoles(user.roleName)}
                    />
                ))}
        </ContainerFlex>
    );
};

export default UsersGroupList;
