import React from 'react';
import {ContainerFlex, Modal} from '@/components/Shopify/Ecommerce/styles';
import Header from '@/components/GlobalQuoter/FilterModal/Header';
import FilterContainer from '@/components/GlobalQuoter/FilterModal/FilterContainer';
import Footer from '@/components/GlobalQuoter/FilterModal/Footer';
import FilterTagContainer from '@/components/GlobalQuoter/FilterModal/FilterTagContainer';

const FilterModal = () => {
    return (
        <Modal Justify="end" BackC="rgba(0, 0, 0, 0.40)">
            <ContainerFlex
                FlexDir="column"
                Width="400px"
                Height="100%"
                Radius="16px 0px 0px 16px"
                backG="#fff"
                Justify="space-between"
                Padding="16px 24px"
                Gap="24px"
            >
                <Header />
                <FilterTagContainer />
                <FilterContainer />
                <Footer />
            </ContainerFlex>
        </Modal>
    );
};

export default FilterModal;
