import React, {useEffect} from 'react';
import {FieldValues, useForm, FormProvider} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from '@config/store';
import {IIdentityDocsModalProps} from '@components/Shopify/ClientProfile/interfaces';
import * as style from '@/components/LocationsBranch/style';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import closeIcon from '@images/closeDark.svg';
import {PERSONAL_INFORMATION} from '@Shopify/ClientProfile/constants';
import {resetNewClientProcess} from '@ListClient/redux/Actions/newClientProcessAction';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerForm, ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import FingerprintRegistration from '@/components/Shopify/ListClient/FingerprintRegistration/ClientFingerprintRegistration';
import {schemaCreateClientLevelThree} from '@Shopify/ListClient/yupAddClientProof';
import AddIdentityDocs from '@components/Shopify/ClientProfile/AddIdentityDocs';

export const IdentityDocsModal: React.FC<IIdentityDocsModalProps> = ({
    clientId,
    showModal,
    setShowModal,
    onDocumentProvided,
    fingerPrints = false,
    onExit = () => {},
    closeOnSubmit = true,
}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const clientDetails = useSelector((state: RootState) => state.clientDetails.clientDetails);
    const fingerprintData = clientDetails?.fingers ?? null;
    const newFingerprints = useSelector(
        (state: RootState) => state.newClientProcess.fingerPrintAdded
    );

    const formMethods = useForm<FieldValues>({
        mode: 'onChange',
        resolver: yupResolver(schemaCreateClientLevelThree),
        defaultValues: {fingerPrint: ''},
    });

    const {
        register,
        reset,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: {errors},
    } = formMethods;

    const clearAndExit = () => {
        dispatch(resetNewClientProcess());
        setShowModal(false);
        if (newFingerprints) onExit();
    };
    const onSubmit = async () => {
        clearAndExit();
    };

    useEffect(() => {
        if (fingerprintData && fingerprintData.length > 0) {
            const latestFinger = fingerprintData[fingerprintData.length - 1];
            if (latestFinger) {
                reset({fingerPrint: latestFinger.fingerURL});
            }
        }
    }, [fingerprintData, reset]);

    return (
        <Modal
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            {...style.modalContent}
            background="rgb(0 0 0 / 60%)"
            closeOnOutsideClick={false}
        >
            {fingerPrints ? (
                <FormProvider {...formMethods}>
                    <ContainerFlex
                        FlexDir="column"
                        Radius="1rem"
                        backG="#FFF"
                        BoxShadow="0px 0px 9px 0px rgba(167, 169, 172, 0.25)"
                    >
                        <ContainerFlex Padding="1rem 1.5rem" Justify="space-between" Align="center">
                            <ContainerFlex
                                FlexDir="column"
                                Justify="flex-start"
                                Align="flex-start"
                                Gap="1rem"
                            >
                                <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                                    {PERSONAL_INFORMATION.ADD_FINGERPRINTS}
                                </Text>
                                <Text FontSize="1rem" Color="#2A2C2F" FontWeight="400">
                                    {PERSONAL_INFORMATION.REGISTER_FINGERPRINTS}
                                </Text>
                            </ContainerFlex>
                            <Image
                                src={closeIcon}
                                Width="1.5rem"
                                Height="1.5rem"
                                Cursor="pointer"
                                onClick={clearAndExit}
                            />
                        </ContainerFlex>
                        <ContainerForm
                            onSubmit={handleSubmit(onSubmit)}
                            FlexDir="column"
                            Padding="1rem 1.5rem"
                            Gap="1rem"
                            Align="start"
                            Justify="start"
                            Radius="1rem"
                        >
                            <FingerprintRegistration
                                token={token}
                                register={register}
                                errors={errors}
                                control={control}
                                reset={reset}
                                watch={watch}
                                setValue={setValue}
                                inPopup={true}
                                clientEditId={clientId}
                            />
                            <ContainerFlex
                                Height="2.5rem"
                                Justify="center"
                                Align="center"
                                Gap="1.5rem"
                                Self="stretch"
                            >
                                <ButtonGenerals
                                    Radius="2rem"
                                    Height="2.5rem"
                                    Padding="0.5rem 1rem"
                                    BackGC="transparent"
                                    Justify="center"
                                    Color="#5A5AFF"
                                    HBackG="transparent"
                                    FontWeight="500"
                                    FontSize="1rem"
                                    type="button"
                                    Cursor="pointer"
                                    Border="1px solid #5A5AFF"
                                    onClick={clearAndExit}
                                >
                                    {PERSONAL_INFORMATION.CLOSE}
                                </ButtonGenerals>
                            </ContainerFlex>
                        </ContainerForm>
                    </ContainerFlex>
                </FormProvider>
            ) : (
                <AddIdentityDocs
                    clientId={clientId}
                    setShowModal={setShowModal}
                    onDocumentProvided={(document) => {
                        if (onDocumentProvided) onDocumentProvided(document);
                    }}
                    closeOnSubmit={closeOnSubmit}
                />
            )}
        </Modal>
    );
};
export default IdentityDocsModal;
