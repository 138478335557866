import {INewLoanSubmittedDataReducer} from '@/components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {
    SAVE_NEW_LOAN_PRODUCT_DATA,
    UPDATE_LOAN_PAYMENT_FREQUENCY,
    RESET_NEW_LOAN_PRODUCT_DATA,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';

export const saveNewLoanSubmittedData = (newLoanData: INewLoanSubmittedDataReducer) => {
    return {
        type: SAVE_NEW_LOAN_PRODUCT_DATA,
        newLoanData,
    };
};

export const updatePaymentFrequency = (newLoanData: INewLoanSubmittedDataReducer) => {
    return {
        type: UPDATE_LOAN_PAYMENT_FREQUENCY,
        newLoanData,
    };
};

export const resetLoanSubmittedData = () => {
    return {
        type: RESET_NEW_LOAN_PRODUCT_DATA,
    };
};
