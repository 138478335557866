import {useState, useEffect} from 'react';
import {AddressFormProps} from './UserProfileBranchAssignment/Operational/typeInterface';

type UseAddressFormReturn = {
    selectedState: string;
    selectedTown: string;
    selectedColony: {value: number; label: string} | null;
    setSelectedState: (state: string) => void;
    setSelectedTown: (town: string) => void;
    setSelectedColony: (colony: {value: number; label: string} | null) => void;
};

const useAddressForm = (
    addressData: AddressFormProps['addressData'],
    addressUser: AddressFormProps['addressUser'],
    isEdit: boolean
): UseAddressFormReturn => {
    const [selectedState, setSelectedState] = useState<string>('');
    const [selectedTown, setSelectedTown] = useState<string>('');
    const [selectedColony, setSelectedColony] = useState<{value: number; label: string} | null>(
        null
    );

    useEffect(() => {
        if (addressData) {
            setSelectedState(addressData.stateName || '');
            setSelectedTown(addressData.municipalityName || '');
            if (addressUser?.suburbName && addressData.colonys) {
                const matchingColony = addressData.colonys.find(
                    (colony) => colony.colonyName === addressUser.suburbName
                );
                if (matchingColony) {
                    setSelectedColony({
                        value: parseInt(matchingColony.colonyId),
                        label: matchingColony.colonyName,
                    });
                } else {
                    setSelectedColony(null);
                }
            } else {
                setSelectedColony(null);
            }
        }
    }, [addressData, addressUser]);

    useEffect(() => {
        if (addressUser && isEdit) {
            setSelectedState(addressUser.stateName || '');
            setSelectedTown(addressUser.municipalityName || '');
            if (addressUser.suburbName && addressUser.suburbId) {
                setSelectedColony({
                    value: parseInt(addressUser.suburbId),
                    label: addressUser.suburbName,
                });
            }
        }
    }, [addressUser, isEdit]);

    return {
        selectedState,
        selectedTown,
        selectedColony,
        setSelectedState,
        setSelectedTown,
        setSelectedColony,
    };
};

export default useAddressForm;
