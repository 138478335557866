import React from 'react';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import CreditDetailsInformationLoan from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformationLoan';
import CreditDetailsInformationStatus from '@/components/MyAccount/MyCredit/CreditDetails/CreditDetailsInformationStatus';
import {TermsDetails} from '@/components/MyAccount/MyCredit/CreditDetails/TermsDetails/TermsDetails';
import {PaymentMethod} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/PaymentMethod';
import {ICreditStatus} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {CREDIT_STATUS} from '@/components/MyAccount/MyCredit/CreditDetails/constants';

const CreditDetailsInformation: React.FC<ICreditStatus> = ({
    creditStatus,
    creditStatusId,
    creditUrlNavigation,
    hasButtons,
    cta_primary,
    cta_secondary,
}) => {
    const creditDetailsStatus = {
        creditStatus,
        creditStatusId,
        creditUrlNavigation,
        hasButtons,
        cta_primary,
        cta_secondary,
    };
    const hasTermsTable =
        creditStatus !== CREDIT_STATUS.APPROVED &&
        creditStatus !== CREDIT_STATUS.REJECTED &&
        creditStatus !== CREDIT_STATUS.UNDER_REVIEW;
    return (
        <ContainerFlex
            Padding="24px"
            Border="1px solid #E8E9EA"
            backG="#fff"
            Width="100%"
            //TODO:REVIEW PEDING
            Height="fit-content"
            Radius="24px"
            Justify="space-between"
            Gap="24px"
            FlexDir="column"
        >
            <CreditDetailsInformationStatus {...creditDetailsStatus} />
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <CreditDetailsInformationLoan {...creditDetailsStatus} />
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            {hasTermsTable && (
                <>
                    <TermsDetails />
                    <PaymentMethod />
                </>
            )}
        </ContainerFlex>
    );
};

export default CreditDetailsInformation;
