import {
    GET_NEW_ROL_USER_REQUEST,
    GET_NEW_ROL_USER_SOUCCES,
    GET_NEW_ROL_USER_RROR,
} from '@components/Users/UserProfile/Redux/types';
const initialState = {
    loading: false,
    userData: [],
    message: '',
    error: null,
};
export const getRoleCompanyUser = (state = initialState, action) => {
    switch (action.type) {
        case GET_NEW_ROL_USER_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case GET_NEW_ROL_USER_SOUCCES:
            return {
                ...state,
                loading: false,
                userData: action.payload?.data ?? [],
                error: null,
            };
        case GET_NEW_ROL_USER_RROR:
            return {
                ...state,
                loading: false,
                userData: [],
                error: action.payload?.data?.data,
            };
        default:
            return state;
    }
};
