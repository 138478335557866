import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {CreditStatus} from '@components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {STYLE_SETUP_MAP} from '@components/MyAccount/MyCredit/CreditDetails/constants';

const CreditDetailsMessage = ({creditStatus}: {creditStatus: CreditStatus}) => {
    const setup = STYLE_SETUP_MAP.get(creditStatus);
    return (
        <ContainerFlex
            Width="100%"
            Height="90px"
            Padding="16px"
            Radius="8px"
            Gap="8px"
            Justify="start"
            Align="center"
            backG={setup?.backgroundMessage}
        >
            <ContainerFlex Flex="0" Justify="start" Align="start">
                <Image src={setup?.icon} Height="24px" Width="24px" alt={setup?.alt} />
            </ContainerFlex>
            <ContainerFlex Flex="1" FlexDir="column" Justify="start" Align="start" Gap="8px">
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="0.88rem"
                    Color={setup?.colorTitle}
                >
                    {setup?.title}
                </Text>
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="0.88rem"
                    Color={setup?.colorParagraph}
                >
                    {setup?.message}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailsMessage;
