import * as yup from 'yup';
import {VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';

export const schemaPaymentMethods = yup.object().shape({
    typePaymentsIns: yup
        .array()
        .of(
            yup.object().shape({
                typePaymentId: yup.number(),
                typePaymentName: yup.string(),
                typePaymentDescription: yup.string(),
                isChecked: yup.boolean(),
            })
        )
        .test(
            'at-least-one-true-payment',
            VALIDATIONS_MSG.PAYMENT_METHODS.AT_LEAST_ONE,
            function (value) {
                return value && value.some((item) => item.isChecked === true);
            }
        ),
});
