import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {ISelectContractUrl} from '@components/ShoppingCart/interface';
import emailTicket from '@images/mailIcon.svg';
import ButtonGeneral from '@General/Atoms/Button';
import downloadTicket from '@images/download_focused.svg';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {IContractsFinaly} from '@components/Loan/Redux/interfaces';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import SelectGeneral from '@/components/General/Atoms/Select/Select';
import {WITHOUT_OPTIONS_MESSAGE} from '@components/ScheduleTable/constants';
import {shareTicketsResume} from '@components/ShoppingCart/Redux/Actions/ShareTicketsResume';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';

export const PrintTicket = ({summaryContracts}: {summaryContracts: IContractsFinaly}) => {
    const dispatch = useDispatch();
    const [tickets, setTickets] = useState<ISelectContractUrl[]>([]);
    const [ticketSelect, setTicketSelect] = useState<ISelectContractUrl>({
        value: 0,
        label: '',
        ticketURL: '',
    });

    const {token, clientInfo, sendingEmail} = useSelector((state: RootState) => ({
        clientInfo: state.cardClientProfile.clientProfile,
        token: String(state.getUsersValidation.userData?.token),
        sendingEmail: state.shareTicketsResume.loading,
    }));

    const getTickets = () => {
        if (summaryContracts) {
            const endorsementTickets = summaryContracts.endorsementsPay.map((contract, index) => ({
                value: index + 1,
                label: contract.contractName,
                ticketURL: contract.ticketURL,
            }));
            const dischargeTickets = summaryContracts.dischargesPay.map((contract, index) => ({
                value: index + 1 + endorsementTickets.length,
                label: contract.contractName,
                ticketURL: contract.ticketURL,
            }));

            const combinedTickets = [...endorsementTickets, ...dischargeTickets];

            if (combinedTickets.length === 1) {
                setTickets(combinedTickets);
                setTicketSelect(combinedTickets[0]);
            } else {
                const defaultTickets = {value: 0, label: SHOPPING_CART.ALL_TICKETS, ticketURL: ''};
                const allTickets = [defaultTickets, ...combinedTickets];
                setTickets(allTickets);
                setTicketSelect(allTickets[0]);
            }
        } else {
            const defaultTickets = {value: 0, label: SHOPPING_CART.ALL_TICKETS, ticketURL: ''};
            setTickets([defaultTickets]);
            setTicketSelect(defaultTickets);
        }
    };

    const printTickets = () => {
        if (ticketSelect.label === SHOPPING_CART.ALL_TICKETS && tickets && tickets.length > 1)
            tickets.slice(1).forEach((ticket) => {
                if (ticket.ticketURL) {
                    window.open(ticket.ticketURL, '_blank');
                }
            });
        else window.open(ticketSelect.ticketURL, '_blank');
    };

    const shareTickets = () => {
        dispatch(shareTicketsResume(token, clientInfo.clientId));
    };

    useEffect(() => getTickets(), []);

    return (
        <ContainerFlex Gap="1.5rem">
            <SelectGeneral
                width="20rem"
                placeholder={SHOPPING_CART.PLACEHOLDER_SELECT}
                isSearchable={false}
                options={tickets}
                noOptionsMessage={() => WITHOUT_OPTIONS_MESSAGE}
                value={tickets.length === 2 ? tickets[1] : ticketSelect || tickets[0]}
                onChange={(newValue) => {
                    if (newValue) {
                        setTicketSelect(newValue);
                    } else {
                        setTicketSelect({value: 0, label: '', ticketURL: ''});
                    }
                }}
            />
            <ButtonGeneral
                secondaryButton
                width="13.531rem"
                clic={() => shareTickets()}
                disabled={sendingEmail}
                text={
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700" Gap="0.5rem">
                        {sendingEmail ? (
                            <LoadingAtaskate width="0.625rem" padding="0.375rem" />
                        ) : (
                            <Image src={emailTicket} alt="email-icon" />
                        )}
                        {SHOPPING_CART.SHARE_TICKET}
                    </Text>
                }
            />
            <ButtonGeneral
                secondaryButton
                width="13.531rem"
                clic={() => printTickets()}
                text={
                    <Text Cursor="pointer" Color="#5A5AFF" FontWeight="700" Gap="0.5rem">
                        <Image src={downloadTicket} alt="download-icon" />
                        {SHOPPING_CART.DOWNLOAD_TICKET}
                    </Text>
                }
            />
        </ContainerFlex>
    );
};
