import {SET_IS_EDITING_LOAN_VALUE} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
const initialState: boolean = false;

const setIsEditingLoanReducer = (
    state = initialState,
    action: {type: string; payload: boolean}
): boolean => {
    switch (action.type) {
        case SET_IS_EDITING_LOAN_VALUE:
            return action.payload;
        default:
            return state;
    }
};

export default setIsEditingLoanReducer;
