import {AxiosResponse} from 'axios';
import {
    GET_TYPE_AMOUNT_CHARGE_PENALTIES_START,
    GET_TYPE_AMOUNT_CHARGE_PENALTIES_SUCCESS,
    GET_TYPE_AMOUNT_CHARGE_PENALTIES_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IGetTypeAmountChargePenaltiesReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/reducerInterfaces';

const initialState: IGetTypeAmountChargePenaltiesReducer = {
    data: [],
    loading: false,
    error: false,
};

const getTypeAmountChargePenaltiesReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetTypeAmountChargePenaltiesReducer => {
    switch (action.type) {
        case GET_TYPE_AMOUNT_CHARGE_PENALTIES_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_TYPE_AMOUNT_CHARGE_PENALTIES_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_TYPE_AMOUNT_CHARGE_PENALTIES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getTypeAmountChargePenaltiesReducer;
