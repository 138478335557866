import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import close from '@images/close.svg';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {CONTRACT_DETAIL} from '@Shopify/ClientProfile/constants';
import {IModalImgContract} from '@Shopify/ClientProfile/interfaces';
import {ContainerGrid, ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';

export const ImagesDetails = ({modalImg, setModalImg}: IModalImgContract) => {
    const {imagesContract} = useSelector((state: RootState) => state.detailContract);

    return (
        <Modal
            Top="2%"
            background="none"
            BorderRadius="1.5rem"
            Width="36.188rem"
            Height="32.563rem"
            Border="1px solid #E8E9EA"
            modalState={modalImg}
            titleModalState={false}
            changeModalState={() => setModalImg(true)}
        >
            <ContainerFlex FlexDir="column" Gap="1.5rem" Padding="1rem 1.5rem">
                <ContainerFlex Justify="space-between">
                    <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                        {CONTRACT_DETAIL.ARTICLE_PHOTOS}
                    </Text>
                    <Image
                        src={close}
                        alt="icon-close"
                        Cursor="pointer"
                        onClick={() => setModalImg(false)}
                    />
                </ContainerFlex>
                <ContainerGrid
                    GridColumns="repeat(3, 1fr)"
                    Gap="1rem"
                    JustifyItems="center"
                    OverFlowY="auto"
                    Height="25rem"
                >
                    {imagesContract.reverse().map((img) => (
                        <ContainerFlex
                            key={img.imageId}
                            {...style.contentImg}
                            Radius="0.25rem"
                            Width="9.438rem"
                            Height="9.375rem"
                            OverFlow="hidden"
                            Position="relative"
                        >
                            <Image
                                src={img.urlImage}
                                {...style.img}
                                Width="9.375rem"
                                Height="9.313rem"
                                alt="garment"
                            />
                        </ContainerFlex>
                    ))}
                </ContainerGrid>
            </ContainerFlex>
        </Modal>
    );
};
