import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {InputWithIconProps} from '@components/GlobalQuoter/interface';
import {InputWithIconStyles} from '@components/GlobalQuoter/styles';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import {setVisualFormValues} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFormActions';
import {resetMediaLoanInfo} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';

const InputWithIcon = ({icon, iconAlt, label}: InputWithIconProps) => {
    const dispatch = useDispatch();
    const visualState = useSelector((state: RootState) => state.globalQuoterFormReducer);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        if (isNaN(+inputValue.replace(/[^0-9]/g, ''))) return;
        dispatch(
            setVisualFormValues({
                ...visualState,
                visualAmount: inputValue,
                parsedAmount: parseFloat(inputValue.replace(/[^0-9]/g, '')),
                accountPayments: null,
                frequencyId: null,
            })
        );
        dispatch(resetMediaLoanInfo());
    };

    return (
        <ContainerFlex
            Width="311px"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="16px"
            Height="56px"
            PaddingR="16px"
            Position="relative"
        >
            <ContainerFlex Padding="8px" backG="#FAFAFA" Radius="16px 0 0 16px" Width="40px">
                <Image src={icon} alt={iconAlt} />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px" PaddingL="8px">
                <Text FontSize="0.875rem" Color="#54575C" FontFamily="Nunito">
                    {label}
                </Text>
                <InputWithIconStyles
                    value={visualState.visualAmount}
                    onChange={handleChange}
                    prefix="$"
                    allowNegative={false}
                    decimalSeparator="."
                    thousandSeparator={true}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default InputWithIcon;
