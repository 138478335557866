import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState, AppDispatch} from '@config/store';

import {IconContainer} from '@/components/Quoter/styles';
import {ContainerFlex, InputColor, Text} from '@Shopify/Ecommerce/styles';
import {fetchUpdateSelectNodeId} from '@ActionsOrgCreation/CheckedState';
import {SubLevels} from '@SubContainerOrgCreation/interface';

export const LevelsTreeOrganization = (data: {data: SubLevels[]}) => {
    return (
        <>
            {data.data.map((tree: SubLevels, index: number) => {
                return <TreeNode node={tree} count={0} key={index} />;
            })}
        </>
    );
};
const TreeNode = (node: {node: SubLevels; count: number}) => {
    const [childVisible, setChildVisible] = useState(false);
    const DinamicPadding = (PaddingSize: number) => {
        switch (PaddingSize) {
            case 1:
                return '8px';
            case 2:
                return '10px';
            case 3:
                return '14px';
            case 4:
                return '18px';
            case 5:
                return '22px';
            case 6:
                return '26px';
        }
    };
    const Levels = useSelector((state: RootState) => {
        return state.GetStatmentData;
    });
    const hasChild = node.node.nextLevel ? true : false;
    const dispatch: AppDispatch = useDispatch();
    useEffect(() => {
        if (node.node.nextLevel.length > 0 && node.node.level === 0) {
            setChildVisible(!childVisible);
        }
    }, []);

    return (
        <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start">
            <ContainerFlex
                Justify="flex-start"
                Height="40px"
                PaddingL={node.node.level ? DinamicPadding(node.node.level) : '10px'}
            >
                {hasChild && node.node.nextLevel.length > 0 ? (
                    <IconContainer
                        className="material-icons"
                        FontSize="1rem"
                        Width="16px"
                        Color="#414042"
                        Justify="flex-start"
                        Cursor="pointer"
                        onClick={() => {
                            setChildVisible(!childVisible);
                        }}
                    >
                        {childVisible ? 'arrow_drop_down' : 'arrow_right'}
                    </IconContainer>
                ) : (
                    <ContainerFlex Width="16px" />
                )}
                <InputColor
                    type="radio"
                    checked={Levels.updateStatement.idLevel === node.node.idLevel ? true : false}
                    onChange={() => dispatch(fetchUpdateSelectNodeId(node?.node))}
                />
                <Text
                    FontWeight={node.node.step === 1 ? '500' : '400'}
                    FontSize="0.875rem"
                    Color="#414042"
                    Padding="0 4px"
                >
                    {node.node.levelName}
                </Text>
            </ContainerFlex>
            {hasChild && childVisible && (
                <ContainerFlex
                    Justify="flex-start"
                    Align="flex-start"
                    FlexDir="column"
                    Height="auto"
                    Padding="0 24px"
                >
                    <LevelsTreeOrganization data={node.node.nextLevel} />
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
