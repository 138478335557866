import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {RESUMEPAYMENT, STEP_PAYMENTS} from '@components/QuickPayment/Constants';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {selectStepPayment} from '@components/QuickPayment/Redux/Actions/GetActionPayment';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

export const ResumePayment = () => {
    const dispatch: AppDispatch = useDispatch();
    const {totalLateLoan, totalLoan, totalPayment, personalCreditsSelected, loading} = useSelector(
        (state: RootState) => {
            return state.addFastPayment;
        }
    );
    const StepPayment = useSelector((state: RootState) => state.GetActionPayment.stepPayment);

    const handleMethodPayment = () => {
        if (personalCreditsSelected.length > 0)
            dispatch(selectStepPayment(STEP_PAYMENTS.PAYMENT_METHOD));
    };
    return (
        <ContainerFlex
            Justify="space-between"
            Align="start"
            Width="400px"
            MaxH="300px"
            FlexDir="column"
            backG="#FFFFFF"
            Padding="24px"
            Border="solid 1px #0D166B"
            Radius="24px"
            Gap="24px"
        >
            <ContainerFlex Width="100%" Align="start" Justify="start" Flex="0">
                <Text wSpace="normal" FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                    {RESUMEPAYMENT.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="8px">
                <ContainerFlex FlexDir="column" Gap="8px">
                    <ContainerFlex Justify="space-between">
                        <Text wSpace="normal" FontSize="0.88rem" FontWeight="400" Color="#2A2C2F">
                            {RESUMEPAYMENT.TOTAL_LOAN}
                        </Text>
                        <Text wSpace="normal" FontSize="0.88rem" FontWeight="400" Color="#2A2C2F">
                            {numberToCurrency(totalLoan)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between">
                        <Text wSpace="normal" FontSize="0.88rem" FontWeight="400" Color="#2A2C2F">
                            {RESUMEPAYMENT.TOTAL_LATE_LOAN}
                        </Text>
                        <Text wSpace="normal" FontSize="0.88rem" FontWeight="400" Color="#2A2C2F">
                            {numberToCurrency(totalLateLoan)}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
                <ContainerFlex
                    Width="100%"
                    Height="100%"
                    Justify="space-between"
                    Align="start"
                    Padding="16px 0px"
                >
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                        {RESUMEPAYMENT.RESUME}
                    </Text>
                    <Text wSpace="normal" FontSize="1rem" FontWeight="700" Color="#2A2C2F">
                        {numberToCurrency(totalPayment)}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="100%" Flex="0">
                <ButtonGenerals
                    Width="100%"
                    Height="40px"
                    Padding="8px 16px"
                    FontWeight="500"
                    onClick={handleMethodPayment}
                    type={StepPayment === STEP_PAYMENTS.PAYMENTS_TO_MADE ? 'button' : 'submit'}
                    disabled={StepPayment === STEP_PAYMENTS.PAYMENT_METHOD && loading}
                    Cursor={
                        StepPayment === STEP_PAYMENTS.PAYMENT_METHOD && loading
                            ? 'not-allowed'
                            : 'pointer'
                    }
                >
                    {StepPayment === STEP_PAYMENTS.PAYMENTS_TO_MADE
                        ? RESUMEPAYMENT.CHOOSE
                        : StepPayment === STEP_PAYMENTS.PAYMENT_METHOD && loading
                        ? RESUMEPAYMENT.LOADING
                        : RESUMEPAYMENT.PAY}
                </ButtonGenerals>
            </ContainerFlex>
        </ContainerFlex>
    );
};
