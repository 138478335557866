import {BoxInfoExpense, IHeader} from '@/components/CashFlowExpenseDetails/operational/interface';

export const EXPENSE_DETAIL_COMPONENT = {
    EXPENSE: 'Gastos',
    TOTAL: 'Total:',
    INFORMATION: 'Información principal',
    EXPENSE_TYPE: 'Tipo de gasto',
    SUPPLIER: 'Proveedor',
    PAYMENT_METHOD: 'Metodo de pago',
    REFERENCE: 'ID/ No de referencia',
    EXPENSE_DATE: 'Fecha de gasto',
    DETAILS: 'Detalles del gasto',
    OBSERVATIONS: 'Observaciones',
    ATTACHMENTS: 'Adjuntos',
    MB: 'Hasta 10 MB',
    BILL: 'Factura',
    VOUCHER: 'Comprobante de pago',
    OP: '(Opcional)',
    SIN: '(Sin IVA)',
    LINE: '-',
    BT_ONE: 'Rechazar',
    BT_TWO: 'Autorizar',
};
export const MODAL_INFO = {
    TITTLE: 'Rechazar gasto',
    TEXT: 'Puedes rechazar la solicitud o solicitar que el usuario ',
    TEXT_ONE: 'haga una corrección',
    TEXT_TWO: 'El usuario deberá registrar un nuevo gasto.',
    WRITES: 'Escribe un cambio',
    BT_C: 'Cancelar',
    BT_S: 'Solo rechazar',
    BT_E: 'Enviar corrección',
};

export const INFO_EXPENSE = {
    ID: '12345678',
    MONEY: '$928',
    SERVICE: 'Servicios',
    STORE: 'OXXO',
    DATE: '04/04/2024',
    PAYMENT_METHOD: 'Tarjeta de crédito o débito',
};

export const UPLOAD_FILE = {
    mb: 'MB',
    replace: 'Remplazar',
    drag: 'Arrastre elementos aquí o',
    search: 'Busca archivos',
    alt: 'Imagen de reemplazo',
};

export const HeaderTitleExpenseDetail: IHeader[] = [
    {
        name: 'Item',
    },
    {
        name: 'Artículo o servicio',
    },
    {
        name: 'Cantidad',
    },
    {
        name: 'Monto',
    },
    {
        name: 'IVA',
    },
];

export const boxDataExpenseDetails: BoxInfoExpense[] = [
    {
        item: 1,
        article: 'Agua garrafón',
        amount: 3,
        quantity: '$ 200',
        IVA: '$ 32',
    },
    {
        item: 2,
        article: 'Factura de gas',
        amount: 1,
        quantity: '$ 600',
        IVA: '$ 96',
    },
];

export const GET_EXPENSE_DETAILS = 'GET_EXPENSE_DETAILS';
export const GET_EXPENSE_DETAILS_SUCCESS = 'GET_EXPENSE_DETAILS_SUCCESS';
export const GET_EXPENSE_DETAILS_ERROR = 'GET_EXPENSE_DETAILS_ERROR';

export const PUT_EXPENSE_AUTH = 'PUT_EXPENSE_AUTH';
export const PUT_EXPENSE_AUTH_SUCCESS = 'PUT_EXPENSE_AUTH_SUCCESS';
export const PUT_EXPENSE_AUTH_ERROR = 'PUT_EXPENSE_AUTH_ERROR';
