import styled from '@emotion/styled';
import {CanvasProps} from './UserProfile/UserProfileBranchAssignment/Operational/typeInterface';

export const Images = styled.img`
    height: 16px;
    width: 16px;
`;

export const Icon = styled.img<{color: string}>`
    font-size: 0.875rem;
    color: ${(props) => props.color};
    width: 16px;
    height: 16px;
`;
export const AvatarContainer = styled.div`
    position: relative;
    display: inline-block;
`;

export const AvatarImage = styled.img`
    width: 62px;
    height: 62px;
    border-radius: 32px;
    object-fit: cover;
`;

export const EditButton = styled.div`
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(5%, -15%);
    width: 24px;
    height: 24px;
    border-radius: 32px;
    border: 1px solid #5a5aff;
    background-color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    z-index: 1;

    &:hover {
        border: 1px solid #000061;
        background-color: rgba(240, 242, 245, 0.5);
    }
`;

export const EditIcon = styled.img`
    width: 16px;
    height: 16px;
`;

export const StyledCanvas = styled.canvas<CanvasProps>`
    width: 100%;
    height: 100%;
    cursor: ${(props) => (props.isDragging ? 'grabbing' : 'grab')};
`;
