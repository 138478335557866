import {INewLoanSubmittedDataReducer} from '@/components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {
    SAVE_NEW_LOAN_PRODUCT_DATA,
    UPDATE_LOAN_PAYMENT_FREQUENCY,
    RESET_NEW_LOAN_PRODUCT_DATA,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';

const initialState: INewLoanSubmittedDataReducer = {
    details: {
        termName: '',
        description: '',
        minAmount: 0,
        maxAmount: 0,
        dateDueInit: '',
        dateDueEnd: '',
        porcentAnualRate: 0,
        periodMin: {value: 0, label: ''},
        periodMax: {value: 0, label: ''},
        requestChannel: [],
        countLimitLoans: 0,
        frequencyTerm: [],
        genderTerms: [],
        ageMin: '',
        ageMax: '',
        creditScore: [],
        reviewCreditVoul: false,
        documents: [],
        typePaymentsIns: [],
        disbursements: [],
        chargesPenalties: [],
    },
    companyId: 0,
};

const newLoanSubmittedData = (
    state = initialState,
    action: {
        type: string;
        newLoanData: INewLoanSubmittedDataReducer;
    }
): INewLoanSubmittedDataReducer => {
    switch (action.type) {
        case SAVE_NEW_LOAN_PRODUCT_DATA:
            return {...state, ...action.newLoanData};
        case UPDATE_LOAN_PAYMENT_FREQUENCY:
            return {
                ...state,
                details: {
                    ...state.details,
                    frequencyTerm: action.newLoanData.details.frequencyTerm.map((newItem) => {
                        const existingItem = state.details.frequencyTerm.find(
                            (item) => item.frecuancyId === newItem.frecuancyId
                        );
                        const isIntegerAndNotZero =
                            newItem.countPaymentMin % 1 === 0 &&
                            newItem.countPaymentMax % 1 === 0 &&
                            newItem.countPaymentMax !== 0 &&
                            newItem.countPaymentMin !== 0;
                        return {
                            ...newItem,
                            isChecked: existingItem
                                ? isIntegerAndNotZero
                                    ? existingItem.isChecked
                                    : false
                                : false,
                        };
                    }),
                },
            };
        case RESET_NEW_LOAN_PRODUCT_DATA:
            return initialState;
        default:
            return state;
    }
};

export default newLoanSubmittedData;
