import {
    STATUS_OF_USER_REQUEST,
    STATUS_OF_USER_SOUCCES,
    STATUS_OF_USER_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};

export const OfStatusUser = (state = initialState, action) => {
    switch (action.type) {
        case STATUS_OF_USER_REQUEST:
            return {...state, loading: true};
        case STATUS_OF_USER_SOUCCES:
            return {...state, loading: false, userData: action.payload.data};
        case STATUS_OF_USER_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
