import {
    ContainerCellGrid,
    ContainerFlex,
    Image,
    Input,
    Text,
} from '@/components/Shopify/Ecommerce/styles';
import React from 'react';
import {UPLOADDROPDATA, UPLOADIMAGESLOANS} from '@components/PersonalLoans/constants';
import {HiddenFileInput} from '@components/PersonalLoans/HiddenFileInput';
import * as content from '@components/PersonalLoans/styles';
import AdditionalDocumentErrorMessage from '@components/PersonalLoans/PersonalLoansAdditionalDocument/AdditionalDocumentErrorMessage';
import errorCrossIcon from '@components/PersonalLoans/icons/errorCrossIcon.svg';
import RemplaceIcon from '@images/RefreshIconBlue.svg';
import {PreviewFileUploaderProps} from '@components/PersonalLoans/interfaces';
import pdfIcon from '@images/pdfIcon.svg';

const PreviewFileUploader = ({
    documentFile,
    fileErrors,
    handleIncomeFileChange,
    fileInputRef,
    registerName,
    errors,
    register,
}: PreviewFileUploaderProps) => {
    return (
        <>
            <ContainerFlex Justify="start" Align="start" Gap="2rem">
                <ContainerFlex
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Width="fit-content"
                    Gap="0.75rem"
                >
                    <Image
                        alt="icon-image"
                        src={
                            documentFile.fileName.includes('.pdf') ? pdfIcon : documentFile.formFile
                        }
                        {...content.ImageUpload}
                        ObjectFit={documentFile.fileName.includes('.pdf') ? 'contain' : 'cover'}
                    />
                    <ContainerFlex
                        {...content.ContainerUploadRef}
                        onClick={() => fileInputRef.current?.click()}
                    >
                        <HiddenFileInput onChange={handleIncomeFileChange} ref={fileInputRef} />
                        <Image alt="icon-remplace" src={RemplaceIcon} />
                        <Text {...content.UploadRemplace}>{UPLOADDROPDATA.REMPLACE}</Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Justify="start" Align="start" FlexDir="column">
                    <ContainerCellGrid {...content.TextUploadName}>
                        <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                            {UPLOADIMAGESLOANS.NAME}
                        </Text>
                        <Input
                            maxLength={100}
                            Width="100%"
                            Margin="0 0 8px 0"
                            {...register(registerName)}
                            error={errors.registerName?.message}
                        />
                    </ContainerCellGrid>
                    {errors?.registerName?.message && (
                        <ContainerFlex Justify="start" Align="center" Gap="0.25rem">
                            <Image
                                alt="icon-close"
                                Height="1rem"
                                Width="1rem"
                                src={errorCrossIcon}
                            />
                            <Text {...content.TextError}>{errors.registerName?.message}</Text>
                        </ContainerFlex>
                    )}
                </ContainerFlex>
            </ContainerFlex>
            {fileErrors.imageSize && (
                <AdditionalDocumentErrorMessage message={UPLOADDROPDATA.ERROR} />
            )}
            {fileErrors.validImageFormat && (
                <AdditionalDocumentErrorMessage message={UPLOADDROPDATA.FORMAT_ERROR} />
            )}
        </>
    );
};

export default PreviewFileUploader;
