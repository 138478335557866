import React from 'react';
import {useNavigate} from 'react-router-dom';
import {
    ContainerFlex,
    Image,
    Text,
    ImgAvatar,
    ContainerGrid,
    TextEllipsis,
} from '@components/Shopify/Ecommerce/styles';
import arrowLeftIcon from '@components/CreditQuoter/icons/arrowLeftIcon.svg';
import {CREDIT_QUOTER} from '@components/CreditQuoter/constants';
import Rating from '@components/CreditQuoter/Rating';
import CreditPaymentDetails from '@/components/CreditQuoter/CreditPaymentDetails';
import CreditCalculator from '@/components/CreditQuoter/CreditCalculator';
import {routers} from '@/appRoute';
import {IQuoterProps} from '@/components/CreditQuoter/interfaces';
import CompanyAvatarDefault from '@/components/CreditQuoter/CompanyAvatarDefault';
import TooltipGeneral from '@components/General/Atoms/Tooltip/TooltipGeneral';

const Quoter: React.FC<IQuoterProps> = ({
    companyOffer,
    companyId,
    parsedAmount,
    frequencyTermCreditId,
}) => {
    const navigate = useNavigate();
    return (
        <ContainerGrid
            Padding="0px 0px 0px 60px"
            GridColumns="2fr 1.5fr"
            Height="400px"
            Width="100%"
            bGround="#FAFAFA"
        >
            <ContainerFlex FlexDir="column" backG="#FAFAFA" Padding="24px" Gap="32px">
                <ContainerFlex Justify="start" Align="start" Gap="8px" Flex="0">
                    <Image
                        src={arrowLeftIcon}
                        alt="left-arrow-icon"
                        Width="24px"
                        Height="24px"
                        Cursor="Pointer"
                    />
                    <Text
                        FontWeight="700"
                        Color="#5A5AFF"
                        FontFamily="Nunito"
                        FontSize="1.0rem"
                        Cursor="Pointer"
                        onClick={() =>
                            navigate(routers.GlobalQuoter, {
                                state: {frequencyTermCreditId: 0, companyId: 0},
                            })
                        }
                    >
                        {CREDIT_QUOTER.GO_BACK_TO_RESULTS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Width="100%" Height="71px" Justify="space-between">
                    <ContainerFlex Gap="16px" Justify="start" Align="start">
                        <ContainerFlex
                            FlexDir="column"
                            Align="center"
                            Justify="center"
                            Width="52px"
                            Padding="8px"
                        >
                            {companyOffer.companyLogo ? (
                                <ImgAvatar
                                    Width="52px"
                                    Height="52px"
                                    Radius="50%"
                                    src={companyOffer.companyLogo}
                                    Border="1px solid #E8E9EA"
                                    alt="brand-logo-icon"
                                />
                            ) : (
                                <CompanyAvatarDefault
                                    companyName={companyOffer.companyName}
                                    color={companyOffer.companyAvatarColor}
                                />
                            )}
                        </ContainerFlex>

                        <ContainerFlex FlexDir="column" Gap="4px" Justify="start" Align="start">
                            <ContainerFlex Justify="space-between" Align="center" Flex="0">
                                <Text
                                    FontFamily="Nunito"
                                    FontSize="1.0rem"
                                    FontWeight="400"
                                    Color="#2A2C2F"
                                >
                                    {CREDIT_QUOTER.COMPANY}
                                </Text>
                                <Rating startsCount={companyOffer.startsCount} />
                            </ContainerFlex>
                            {companyOffer.companyName.length > 30 ? (
                                <ContainerFlex Justify="flex-start" Align="center" Gap="4px">
                                    <TextEllipsis
                                        FontFamily="Nunito"
                                        FontSize="1.75rem"
                                        FontWeight="600"
                                        Color="#2A2C2F"
                                    >
                                        {companyOffer.companyName}
                                    </TextEllipsis>
                                    <TooltipGeneral
                                        icon={'help_outline'}
                                        titleTooltip={CREDIT_QUOTER.COMPANY}
                                        descriptionTooltip={companyOffer.companyName}
                                    />
                                </ContainerFlex>
                            ) : (
                                <Text
                                    FontFamily="Nunito"
                                    FontSize="1.75rem"
                                    FontWeight="600"
                                    Color="#2A2C2F"
                                >
                                    {companyOffer.companyName}
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <CreditPaymentDetails
                    amount={Number(parsedAmount)}
                    frequencyName={companyOffer.frecuencyName}
                    countPayments={companyOffer.countPayments}
                />
            </ContainerFlex>
            <ContainerFlex>
                <CreditCalculator
                    amount={Number(parsedAmount)}
                    frequencyTermCreditId={frequencyTermCreditId}
                    countPayments={companyOffer.countPayments}
                    companyId={companyId}
                />
            </ContainerFlex>
        </ContainerGrid>
    );
};

export default Quoter;
