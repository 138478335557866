import React from 'react';

import {IActionButtonsProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex} from '@Shopify/Ecommerce/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {BUTTON_CONFIG, TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';

const QuickActionButtons: React.FC<IActionButtonsProps> = ({card, handleEndorsementDischarge}) => (
    <ContainerFlex Gap="0.5rem" FlexWrap="wrap">
        {BUTTON_CONFIG({
            discharge: () => handleEndorsementDischarge(card, TYPE_MOVEMENT_ADD_CART.DISCHARGE),
            endorsement: () => handleEndorsementDischarge(card, TYPE_MOVEMENT_ADD_CART.RENEWAL),
            card,
        }).map((option, index) => (
            <ButtonGeneral
                key={index}
                width="8.125rem"
                height="2rem"
                secondaryButton={option.secondaryButton}
                text={option.text}
                clic={option.action}
            />
        ))}
    </ContainerFlex>
);

export default QuickActionButtons;
