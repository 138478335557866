import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import useInitInheritGroups from '@/hooks/useInitInheritGroups';
import {getDayHoursInterval} from '@components/LocationsBranch/utils/GetIntervals';
import {ScheduleTable} from '@components/ScheduleTable';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {
    SCHEDULE,
    SCHEDULE_DEFAULT_VALUES,
    INHERIT_TYPE,
    DAY_OF_WEEK,
    INITIAL_OPENING_HOURS,
} from '@components/LocationsBranch/constants';
import {ICreateGroupBranche, IScheduleSlot} from '@components/ScheduleTable/interfaces/interfaces';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import * as style from '@components/LocationsBranch/stylesLocations';
import {InheritFrom} from '@/components/LocationsBranch/InheritFrom';

export const ScheduleGroup = ({
    control,
    errors,
    watch,
    isOptional,
    setValue,
    onClickTransfer,
    isEdit,
}: ICreateGroupBranche) => {
    const inheritType = INHERIT_TYPE.SCHEDULE;

    const selectedLevel = useSelector((state: RootState) => state.levelGroups.groupTypes).find(
        (groups) => groups.groupType === inheritType
    )?.selectedLevel;
    const {schedule} = useSelector((state: RootState) => ({
        schedule: state.getCreateGroupBranch.schedule,
    }));

    useInitInheritGroups(inheritType);

    const intervals = getDayHoursInterval(0, 23, 30);
    const intervalsOptions = intervals.map((item: {time: string; id: number}) => ({
        label: item.time,
        value: String(item.id),
    }));
    const [scheduleSlots, setScheduleSlots] = React.useState<IScheduleSlot[]>([]);

    const headers = [SCHEDULE.DAY, SCHEDULE.STATE, SCHEDULE.OPEN, SCHEDULE.CLOSE];
    const defaultValues = {...SCHEDULE_DEFAULT_VALUES};

    const getScheduleSlots = () => {
        return Array.from({length: 7}, (_, index): IScheduleSlot => {
            const day = getValuesOfDays(index + 1);

            const selectedOpeningTime =
                intervalsOptions.find((item) => item.label === day?.openingTime) ||
                INITIAL_OPENING_HOURS.OPENING;

            const selectedClosingTime =
                intervalsOptions.find((item) => item.label === day?.closingTime) ||
                INITIAL_OPENING_HOURS.CLOSING;

            const openingSelectName = `openingHours.${[DAY_OF_WEEK[index].value]}.${
                defaultValues.opening
            }`;

            const closingSelectName = `openingHours.${[DAY_OF_WEEK[index].value]}.${
                defaultValues.closing
            }`;

            const toggleName = `openingHours.${[DAY_OF_WEEK[index].value]}.${defaultValues.switch}`;

            const status =
                isEdit || selectedLevel?.breadcrum?.branch
                    ? day?.status
                        ? day.status
                        : false
                    : !!day?.status;

            setValue(openingSelectName, selectedOpeningTime);
            setValue(closingSelectName, selectedClosingTime);
            setValue(toggleName, status);

            return {
                id: index,
                opening: selectedOpeningTime,
                closing: selectedClosingTime,
                dayName: DAY_OF_WEEK[index].label,
                status,
                dayOfWeek: DAY_OF_WEEK[index].value,
                openingSelectName,
                closingSelectName,
                toggleName,
                intervals: intervalsOptions,
            };
        });
    };

    const getValuesOfDays = (day: number) => {
        return schedule && schedule.find((item) => item.dayOfWeek === day);
    };

    useEffect(() => {
        setScheduleSlots(getScheduleSlots());
    }, [schedule]);

    return (
        <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1rem">
            <ContainerFlex Justify="space-between" Align="center" Self="stretch">
                <Text {...style.contentGralTitle}>
                    {SCHEDULE.TITLE}
                    {isOptional && (
                        <Text {...style.contentGralTitleOp} MarginLeft="0.5rem" wSpace="pre">
                            {SCHEDULE.OPTIONAL}
                        </Text>
                    )}
                </Text>
                <InheritFrom
                    inheriterName={selectedLevel?.levelName as string}
                    type={inheritType}
                    onClickTransfer={onClickTransfer}
                />
            </ContainerFlex>
            <ContainerFlex Justify="start" Gap="0.5rem">
                <Checkbox />
                <Text Color="#2A2C2F" oFlow="none">
                    {SCHEDULE.SUBTITLE}
                </Text>
            </ContainerFlex>
            {scheduleSlots && scheduleSlots.length > 0 && (
                <ScheduleTable
                    headers={headers}
                    scheduleSlots={scheduleSlots}
                    control={control}
                    errors={errors}
                    watch={watch}
                />
            )}
            {errors && errors.openingHours && (
                <Text Color="#FF6357" FontSize="0.75rem" Padding="0.375rem">
                    {String(errors.openingHours?.message)}
                </Text>
            )}
        </ContainerFlex>
    );
};
