import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import {ClickAwayListener} from '@mui/material';
import {useSelector} from 'react-redux';
import {Highlight} from '@components/OrganizationCreation/SubContainer/TreeTableUtils/CoincidenceFunction';
import {IconSvg} from '@components/Quoter/Icons/styles';
import * as style from '@/components/Quoter/stylesConstants';
import {Level} from '@components/NewUserEmployee/interface';
import {TEXT_NEW_USER} from '@components/NewUserEmployee/Operational/constants';
import Warning from '@images/warningIconeYellow.svg';

interface IFastMenu {
    setOpenFastMenu: (e: boolean) => void;
    search: string;
    TreeData: Level[];
    setTree: (e: Level[]) => void;
}
export const SearchUbication = ({setOpenFastMenu, search, setTree, TreeData}: IFastMenu) => {
    const oponeFastMenu = () => {
        setOpenFastMenu(false);
    };
    const searchResults = useSelector((state: RootState) => state.getSearchLevelsAndBranch.data);
    const findLastNodeByIds = (nodes: Level[], validIds: number[]): Level | null => {
        let last: Level | null = null;

        for (const node of nodes) {
            if (validIds.includes(node.idLevel)) {
                last = node;
            }

            if (node.nextLevel && node.nextLevel.length > 0) {
                const deeperNode = findLastNodeByIds(node.nextLevel, validIds);
                if (deeperNode) {
                    last = deeperNode;
                }
            }
        }

        return last;
    };

    const getLastName = (text: string): string => {
        const parts = text.split('>').map((part) => part.trim());
        return parts[parts.length - 1];
    };
    const formatBreadComes = (breadComes: string) => {
        return breadComes.split(' -> ').map((part, index) => (
            <ContainerFlex
                Justify="center"
                Align="center"
                Width="auto"
                key={index}
                Cursor="pointer"
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="4px">
                    <Highlight text={getLastName(part)} highlight={search} Cursor="pointer" />
                    <Highlight text={part} highlight={search} Cursor="pointer" />
                </ContainerFlex>

                {index < breadComes.split(' -> ').length - 1 && (
                    <IconSvg Height="18px" Width="18px" cursor="pointer">
                        <path
                            d="M7.05 13.5L6 12.45L9.45 9L6 5.55L7.05 4.5L11.55 9L7.05 13.5Z"
                            fill="#5A5AFF"
                        />
                    </IconSvg>
                )}
            </ContainerFlex>
        ));
    };
    const ProductComponent = (breadComes: string, ids: number[]) => {
        return (
            <ContainerFlex
                {...style.contentList}
                HBackground="#f0f0ff"
                HBorderLeft="2px solid #5a5aff"
                Height="60px"
                Justify="start"
                Cursor="pointer"
                onClick={() => {
                    const result = findLastNodeByIds(TreeData, ids);
                    if (result) setTree([result]);
                    setOpenFastMenu(false);
                }}
            >
                {formatBreadComes(breadComes)}
            </ContainerFlex>
        );
    };
    return (
        <ClickAwayListener onClickAway={oponeFastMenu}>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                backG="#FFFFFF"
                MaxH="300px"
                Position="relative"
                Border="#F3F3F3"
                MinH="40px"
                Align="start"
                PositionTop="0"
                Radius="8px"
                BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                ZIndex="3"
            >
                {searchResults &&
                    searchResults.map((data, index) => {
                        return (
                            <ContainerFlex
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                                Gap="8px"
                                Cursor="pointer"
                                key={index}
                            >
                                {ProductComponent(data.title, data.ids)}
                            </ContainerFlex>
                        );
                    })}
                {searchResults.length === 0 && (
                    <ContainerFlex
                        Justify="start"
                        Align="start"
                        Padding="8px 16px"
                        ColumnGap="8px"
                        Cursor="pointer"
                        Height="40px"
                    >
                        <Image alt="warning" src={Warning} />
                        <Text>{TEXT_NEW_USER.EMPITY_SEARCH}</Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
        </ClickAwayListener>
    );
};
