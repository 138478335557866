import {
    GET_CAT_PAYMENT_TYPES_START,
    GET_CAT_PAYMENT_TYPES_SUCCESS,
    GET_CAT_PAYMENT_TYPES_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {AxiosResponse} from 'axios';
import {IGetCatPaymentTypesReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/reducerInterfaces';

const initialState: IGetCatPaymentTypesReducer = {
    data: [],
    loading: false,
    error: false,
};

export const getCatPaymentTypes = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetCatPaymentTypesReducer => {
    switch (action.type) {
        case GET_CAT_PAYMENT_TYPES_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CAT_PAYMENT_TYPES_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CAT_PAYMENT_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getCatPaymentTypes;
