import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import DoubleRangeSlider from '@components/CreditsAndPawns/FilterModal/DoubleRangeSlider';
import FilterSearchClient from '@components/CreditsAndPawns/FilterModal/FilterSearchClient';
import CheckboxFilter from '@components/CreditsAndPawns/FilterModal/CheckboxFilter';
import {setFiltersValues} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';
import {IFilterValues} from '@components/CreditsAndPawns/interfaces';
import {FILTER_NAMES, FILTER_TITLES, SLIDER_TYPE} from '@components/CreditsAndPawns/constants';
import FilterRangePicker from '@components/CreditsAndPawns/FilterModal/FilterRangePicker';
import {IGlobaQuoterFilterValues} from '@/components/GlobalQuoter/interface';

const FiltersContainer = () => {
    const dispatch = useDispatch();
    const {filtersOptions, filtersValues} = useSelector((state: RootState) => {
        return state.getFilterListLoans;
    });
    const dispatchFilterValues = useCallback(
        (filtersValues: IFilterValues | IGlobaQuoterFilterValues) =>
            dispatch(setFiltersValues(filtersValues as IFilterValues)),
        [dispatch]
    );
    useEffect(() => {
        dispatch(
            setFiltersValues({
                ...filtersValues,
                requestedDateEnd: filtersValues.requestedDateStart,
            })
        );
    }, [filtersValues.requestedDateStart]);
    useEffect(() => {
        dispatch(
            setFiltersValues({
                ...filtersValues,
                paymentDateEnd: filtersValues.paymentDateStart,
            })
        );
    }, [filtersValues.paymentDateStart]);

    return (
        <>
            <ContainerFlex
                Height={'calc(100vh - 69px)'}
                FlexDir="column"
                Gap="24px"
                OverFlowY="auto"
                Justify="space-between"
            >
                <FilterSearchClient />
                <ContainerFlex
                    FlexDir="column"
                    Gap="8px"
                    Justify="flex-start"
                    Align="flex-start"
                    Height="88px"
                >
                    <Text
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        FontWeight="700"
                        LHeight="24px"
                        LetterSpacing="0.3px"
                    >
                        {FILTER_TITLES.SCORE}
                    </Text>
                    <DoubleRangeSlider
                        min={filtersOptions.scoreStart}
                        max={1000}
                        sliderWidth="100%"
                        step={10}
                        minVal={filtersValues.scoreStart}
                        maxVal={filtersValues.scoreEnd}
                        setVal={dispatchFilterValues}
                        filtersValues={filtersValues}
                        nameMin={FILTER_NAMES.SCORE_START}
                        nameMax={FILTER_NAMES.SCORE_END}
                    />
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Gap="8px"
                    Justify="flex-start"
                    Align="flex-start"
                    Height="88px"
                >
                    <Text
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        FontWeight="700"
                        LHeight="24px"
                        LetterSpacing="0.3px"
                    >
                        {FILTER_TITLES.INTERES_RATE}
                    </Text>
                    <DoubleRangeSlider
                        min={Math.floor(filtersOptions.interestAnualStart)}
                        max={Math.ceil(filtersOptions.interestAnualEnd)}
                        type={SLIDER_TYPE.PERCENTAGE}
                        sliderWidth="100%"
                        nameMax={FILTER_NAMES.ANUAL_INTEREST_END}
                        nameMin={FILTER_NAMES.ANUAL_INTEREST_START}
                        minVal={filtersValues.interestAnualStart}
                        maxVal={filtersValues.interestAnualEnd}
                        setVal={dispatchFilterValues}
                        filtersValues={filtersValues}
                    />
                </ContainerFlex>
                <CheckboxFilter
                    filterOptions={filtersOptions.frequencyIds}
                    title={FILTER_TITLES.FREQUENCY}
                    handleFilter={dispatchFilterValues}
                    filters={filtersValues}
                />
                <ContainerFlex
                    FlexDir="column"
                    Gap="8px"
                    Justify="flex-start"
                    Align="flex-start"
                    Height="88px"
                >
                    <Text
                        Color="#1D1E20"
                        FontFamily="Nunito"
                        FontWeight="700"
                        LHeight="24px"
                        LetterSpacing="0.3px"
                    >
                        {FILTER_TITLES.AMOUNT}
                    </Text>
                    <DoubleRangeSlider
                        min={0}
                        max={filtersOptions.amontMaxCreditEnd}
                        step={1000}
                        type={SLIDER_TYPE.CURRENCY}
                        sliderWidth="100%"
                        nameMax={FILTER_NAMES.AMOUNT_MAX_CREDIT_END}
                        nameMin={FILTER_NAMES.AMOUNT_MAX_CREDIT_START}
                        minVal={filtersValues.amountMaxCreditStart}
                        maxVal={filtersValues.amontMaxCreditEnd}
                        setVal={dispatchFilterValues}
                        filtersValues={filtersValues}
                    />
                </ContainerFlex>
                <FilterRangePicker
                    dispatchFilterValues={dispatchFilterValues}
                    filtersValues={filtersValues}
                    title={FILTER_TITLES.REQUEST_DATE}
                    startFilterName={FILTER_NAMES.REQUESTED_DATE_START}
                    endFilterName={FILTER_NAMES.REQUESTED_DATE_END}
                    startFilterValue={filtersValues.requestedDateStart}
                    endFilterValue={filtersValues.requestedDateEnd}
                    disabled={filtersValues.requestedDateStart === null}
                    minDate={filtersValues.requestedDateStart}
                />
                <FilterRangePicker
                    dispatchFilterValues={dispatchFilterValues}
                    filtersValues={filtersValues}
                    title={FILTER_TITLES.PAYMENT_DATE}
                    startFilterName={FILTER_NAMES.PAYMENT_DATE_START}
                    endFilterName={FILTER_NAMES.PAYMENT_DATE_END}
                    startFilterValue={filtersValues.paymentDateStart}
                    endFilterValue={filtersValues.paymentDateEnd}
                    disabled={filtersValues.paymentDateStart === null}
                    minDate={filtersValues.paymentDateStart}
                />
            </ContainerFlex>
        </>
    );
};

export default FiltersContainer;
