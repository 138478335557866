import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IconSvg} from '@Quoter/Icons/styles';
import * as style from '@/components/Quoter/stylesConstants';
import {IClient} from '@components/ShoppingCart/interfaces';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {IClientData, IClientList} from '@components/ShoppingCart/Redux/interfaces';
import {SHOPPING_CART} from '@components/ShoppingCart/constants';
import {ClickAwayListener} from '@mui/material';

export const SearchClient = ({setDisplay, selectClient}: IClient) => {
    const {error, data}: IClientData = useSelector((state: RootState) => state.clientList);
    return (
        <ClickAwayListener onClickAway={() => setDisplay(false)}>
            <ContainerFlex
                Height=""
                Width="90%"
                Padding="8px"
                Position="absolute"
                ZIndex="3"
                MaxH="280px"
                FlexDir="column"
                Justify="start"
                PositionTop="54px"
                backG="#FFFFFF"
                Border=" 1px solid  #F3F3F3"
                BoxS="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
                OverFlow="auto"
                Gap="8px"
            >
                {error ||
                    (data && data.length === 0 && (
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            ColumnGap="8px"
                            {...style.contentList}
                        >
                            <IconSvg Height="24px" Width="24px">
                                <path
                                    d="M1 21L12 2L23 21H1ZM12 18C12.2833 18 12.5208 17.9042 12.7125 17.7125C12.9042 17.5208 13 17.2833 13 17C13 16.7167 12.9042 16.4792 12.7125 16.2875C12.5208 16.0958 12.2833 16 12 16C11.7167 16 11.4792 16.0958 11.2875 16.2875C11.0958 16.4792 11 16.7167 11 17C11 17.2833 11.0958 17.5208 11.2875 17.7125C11.4792 17.9042 11.7167 18 12 18ZM11 15H13V10H11V15Z"
                                    fill="#CC9200"
                                />
                            </IconSvg>
                            <Text Color="#2A2C2F" FontSize="1rem">
                                {SHOPPING_CART.No_results}
                            </Text>
                        </ContainerFlex>
                    ))}

                <ContainerFlex
                    Justify="start"
                    Align="start"
                    ColumnGap="8px"
                    {...style.contentListBorderLess}
                    HBackground="#f0f0ff"
                    HBorderLeft="2px solid #5a5aff"
                >
                    <IconSvg Height="24px" Width="24px">
                        <path
                            d="M18 14V11H15V9H18V6H20V9H23V11H20V14H18ZM9 12C7.9 12 6.95833 11.6083 6.175 10.825C5.39167 10.0417 5 9.1 5 8C5 6.9 5.39167 5.95833 6.175 5.175C6.95833 4.39167 7.9 4 9 4C10.1 4 11.0417 4.39167 11.825 5.175C12.6083 5.95833 13 6.9 13 8C13 9.1 12.6083 10.0417 11.825 10.825C11.0417 11.6083 10.1 12 9 12ZM1 20V17.2C1 16.6333 1.146 16.1123 1.438 15.637C1.72933 15.1623 2.11667 14.8 2.6 14.55C3.63333 14.0333 4.68333 13.6457 5.75 13.387C6.81667 13.129 7.9 13 9 13C10.1 13 11.1833 13.129 12.25 13.387C13.3167 13.6457 14.3667 14.0333 15.4 14.55C15.8833 14.8 16.2707 15.1623 16.562 15.637C16.854 16.1123 17 16.6333 17 17.2V20H1ZM3 18H15V17.2C15 17.0167 14.9543 16.85 14.863 16.7C14.771 16.55 14.65 16.4333 14.5 16.35C13.6 15.9 12.6917 15.5623 11.775 15.337C10.8583 15.1123 9.93333 15 9 15C8.06667 15 7.14167 15.1123 6.225 15.337C5.30833 15.5623 4.4 15.9 3.5 16.35C3.35 16.4333 3.22933 16.55 3.138 16.7C3.046 16.85 3 17.0167 3 17.2V18ZM9 10C9.55 10 10.021 9.804 10.413 9.412C10.8043 9.02067 11 8.55 11 8C11 7.45 10.8043 6.97933 10.413 6.588C10.021 6.196 9.55 6 9 6C8.45 6 7.97933 6.196 7.588 6.588C7.196 6.97933 7 7.45 7 8C7 8.55 7.196 9.02067 7.588 9.412C7.97933 9.804 8.45 10 9 10Z"
                            fill="#5A5AFF"
                        />
                    </IconSvg>
                    <Text Color="#2A2C2F" FontSize="1rem" Cursor="pointer">
                        {SHOPPING_CART.Add_to_cart}
                    </Text>
                </ContainerFlex>
                {data &&
                    data.length > 0 &&
                    data.map((item: IClientList) => {
                        return (
                            <ContainerFlex
                                key={item.id}
                                Justify="start"
                                Align="start"
                                ColumnGap="8px"
                                {...style.ResultList}
                                HBackground="#f0f0ff"
                                HBorderLeft="2px solid #5a5aff"
                                FlexDir="Column"
                                Gap="8px"
                                Padding="0 8px"
                                onClick={() => selectClient(item)}
                                Cursor="pointer"
                                Height="100%"
                            >
                                <Text
                                    Color="#2A2C2F"
                                    FontSize="1rem"
                                    Cursor="pointer"
                                    oFlow="visible"
                                >
                                    {item.name}
                                </Text>
                                <Text Color="#71767D" FontSize="0.75rem" Cursor="pointer">
                                    Tel: {item.phone} - NUC: {item.nuc} - {item.email}
                                </Text>
                            </ContainerFlex>
                        );
                    })}
            </ContainerFlex>
        </ClickAwayListener>
    );
};
