import React from 'react';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {StatusPayment} from '@/components/MyAccount/MyCredit/CreditDetails/StatusPayment';
import ArrowRight from '@images/arrow right gray.svg';
import {ActionPaymentCredits} from '@/components/MyAccount/MyCredit/CreditDetails//ActionPaymentCredits';
import {CREDITSROUTE} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
export const CreditsPayment = () => {
    const {showResume} = useSelector((state: RootState) => state.shoppingCarClient);
    return (
        <ContainerFlex
            FlexDir="column"
            Width="100%"
            Justify="start"
            Align="start"
            Padding="1.5rem 3.75rem"
            backG="#FAFAFF"
            Gap="1.2rem"
        >
            <ContainerFlex Justify="start" Gap="0.25rem" Height="24px">
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.INITIAL} <Image src={ArrowRight} alt="arrowright" />
                </Text>
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.ACCOUNT} <Image src={ArrowRight} alt="arrowright" />
                </Text>
                <Text Color="#54575C" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.LOANS} <Image src={ArrowRight} alt="arrowright" />
                </Text>
                <Text Color="#0D166B" FontSize="0.75rem" FontWeight="700">
                    {CREDITSROUTE.PAY}
                </Text>
            </ContainerFlex>
            {showResume ? (
                <ContainerFlex
                    Padding="0px 90px"
                    Height="100%"
                    Width="100%"
                    Justify="start"
                    Align="start"
                >
                    <StatusPayment />
                </ContainerFlex>
            ) : (
                <ActionPaymentCredits />
            )}
        </ContainerFlex>
    );
};
