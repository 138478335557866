import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {newNameSchema} from '@components/LocationsBranch/yupNewName';
import {WarehouseDetail} from '@components/LocationsBranch/WarehouseDetail';
import {StorageTypeDetail} from '@components/LocationsBranch/StorageTypeDetail';
import {IFormWarehouseProps, IInventoryStorageProps} from '@components/LocationsBranch//interfaces';
import {EditWarehouseModal} from '@components/LocationsBranch/EditWarehouse';
import {STORAGE_NAME_INPUT} from '@components/LocationsBranch/constants';

export const InventoryStorage = ({
    warehouse,
    setSelectedStorage,
    selectedStorageType,
}: IInventoryStorageProps) => {
    const {editWarehouse} = useSelector((state: RootState) => ({
        editWarehouse: state.Warehouse.editWarehouse,
    }));

    const {
        register,
        control,
        watch,
        reset,
        setValue,
        handleSubmit,
        formState: {errors},
        trigger,
    } = useForm<IFormWarehouseProps>({
        resolver: yupResolver(newNameSchema),
        defaultValues: {
            [STORAGE_NAME_INPUT]: '',
        },
    });

    const formProps = {
        register,
        control,
        watch,
        reset,
        setValue,
        handleSubmit,
        errors,
        trigger,
    };

    return (
        <>
            {!selectedStorageType || warehouse.id !== selectedStorageType.parentLevel ? (
                <WarehouseDetail
                    warehouse={warehouse}
                    setSelectedStorage={setSelectedStorage}
                    formProps={formProps}
                />
            ) : (
                <StorageTypeDetail
                    warehouse={warehouse}
                    selectedStorageType={selectedStorageType}
                    setSelectedStorage={setSelectedStorage}
                    formProps={formProps}
                />
            )}
            {editWarehouse && editWarehouse.showEditModal && (
                <EditWarehouseModal setSelectedStorage={setSelectedStorage} />
            )}
        </>
    );
};
