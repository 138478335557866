import axios, {AxiosError} from 'axios';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@config/store';

import {
    setCurrentSelectedLevelId,
    selectGroupLevel,
    addGroupLevel,
    updateGroup,
} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import {CompanyLevels} from '@components/LocationsBranch/Redux/interfaces';
import {GET_COMPANY_LEVELS} from '@components/LocationsBranch/Redux/types';
import {Branch} from '@components/LocationsBranch/Redux/interfaces';
import {Levels} from '@components/LocationsBranch/Redux/interfaces';

const getCompanyLevelsStart = () => ({
    type: GET_COMPANY_LEVELS.GET_LEVELS_START,
});

const getCompanyLevelsSuccess = (result: CompanyLevels) => ({
    type: GET_COMPANY_LEVELS.GET_LEVELS_SUCCESS,
    payload: result,
});

const getCompanyLevelsError = (error: AxiosError) => ({
    type: GET_COMPANY_LEVELS.GET_LEVELS_ERROR,
    error: error,
});

const updateGroupsLevels = (
    result: CompanyLevels,
    internalGroupId?: number,
    internalRowId?: number
) => ({
    type: GET_COMPANY_LEVELS.UPDATE_GROUPS_LEVELS,
    selectedGroupPayload: {
        selectedGroup: result,
        internalGroupId,
        internalRowId,
    },
});

export const resetGroups = () => ({
    type: GET_COMPANY_LEVELS.RESET_GROUPS_LEVELS,
});

export const setSelectedOptionList = (selectedOptionList: Branch | null) => ({
    type: GET_COMPANY_LEVELS.SET_SELECTED_OPTION_LIST,
    selectedOptionList,
});

export const GetCompanyLevelsByCompanyId = (
    companyId: number,
    token: string,
    levelId?: number | null,
    filters?: {
        branchSearch?: [number, number];
        inheritType?: string | null;
        groupNumber?: number;
        reloadingSelection?: boolean;
        addLevelGroup?: boolean;
        fillLevelsOnly?: boolean;
    } | null,
    internalGroupId?: number,
    internalRowId?: number
) => {
    const headers = {
        accept: 'text/plain',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCompanyLevelsStart());
        try {
            let searchId = levelId;
            let branchId = 0;

            const {
                branchSearch,
                inheritType = null,
                groupNumber,
                reloadingSelection = false,
                addLevelGroup = false,
                fillLevelsOnly = false,
            } = filters || {};

            if (branchSearch && branchSearch[0] > 0) {
                branchId = branchSearch[0];
                searchId = branchSearch[1];
            }
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetLevelsByCompanyId}`,
                {
                    headers,
                    params: {
                        companyId,
                        levelId: searchId,
                    },
                }
            );
            const responseData: CompanyLevels = response.data.data;

            if (branchId > 0) {
                const filteredBranches = responseData.branchs.filter(
                    (branch) => branch.branchId === branchId
                );
                responseData.branchs = filteredBranches;
            }

            if (!fillLevelsOnly) {
                await dispatch(getCompanyLevelsSuccess(responseData));
                await dispatch(updateGroupsLevels(responseData, internalGroupId, internalRowId));
            }

            if (inheritType && groupNumber !== undefined) {
                const levelsData: Levels[] = responseData.levels;
                const levelIdnum = levelId ? levelId : responseData.levelId;
                const selectedLevel = {
                    levelId: levelIdnum,
                    levelName: responseData.branchName,
                    breadcrum: null,
                };
                if (!reloadingSelection) {
                    dispatch(await setCurrentSelectedLevelId(inheritType, selectedLevel));
                }
                if (fillLevelsOnly) {
                    await dispatch(updateGroup(groupNumber, levelsData, inheritType));
                }
                if (addLevelGroup && levelsData) {
                    await dispatch(
                        selectGroupLevel(groupNumber, selectedLevel.levelId, inheritType)
                    );
                    await dispatch(addGroupLevel(inheritType, groupNumber, levelsData));
                }
            }
        } catch (error: unknown) {
            dispatch(getCompanyLevelsError(error as AxiosError));
        }
    };
};
