import {
    GET_SEARCH_LOAN_PRODUCTS_START,
    GET_SEARCH_LOAN_PRODUCTS_SUCCESS,
    GET_SEARCH_LOAN_PRODUCTS_ERROR,
    GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
} from '@components/LoansProducts/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {Dispatch} from 'redux';
import {URL} from '@config/constants/index';
import StringUtils from '@/GenericScripts/utils';
import {SearchLoansProductsAction, SuggestedList} from '@components/LoansProducts/interfaces';

export const getSearchLoanProductsStart = () => ({
    type: GET_SEARCH_LOAN_PRODUCTS_START,
});

export const getSearchLoanProductsSuccess = (payload: AxiosResponse) => ({
    type: GET_SEARCH_LOAN_PRODUCTS_SUCCESS,
    payload: payload,
});

export const getSearchLoanProductsError = (error: AxiosError) => ({
    type: GET_SEARCH_LOAN_PRODUCTS_ERROR,
    error,
});

export const fetchSearchLoanProducts = (filters: SearchLoansProductsAction, token: string) => {
    const headers = {
        accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    const params = {...filters};
    const queryString = StringUtils.jsonToQueryString(params);
    return async (dispatch: Dispatch) => {
        dispatch(getSearchLoanProductsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetLoanProductsSearchEngine}${queryString}
            `,
                {headers}
            );
            dispatch(getSearchLoanProductsSuccess(response));
        } catch (error) {
            dispatch(getSearchLoanProductsError(error as AxiosError));
        }
    };
};

export const SelectedSearchLoanProductsSuccess = (data: SuggestedList) => {
    return {
        type: GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
        payload: [data],
    };
};
export const SelectedSearchLoanProductsList = (data: SuggestedList[]) => {
    return {
        type: GET_PRODUCTS_CUSTOMER_USER_SUCCESS,
        payload: data,
    };
};
