import React from 'react';
import {ContainerFlex, Image, Text, ContainerImg} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {INSTALLMENTS, OPTIONS} from '@components/ShoppingCart/constants';
import moreIcon from '@images/MoreMenuBlack.svg';
import arrowTopLeftIcon from '@images/arrowTopLeft.svg';
import {IInstallmentItem} from '@components/ShoppingCart/interfaces';
import InstallmentMenu from '@components/ShoppingCart/RenewalMenuOption/InstallmentMenu';
import {INSTALLMENT_MENU_OPTIONS} from '@components/ShoppingCart/constants';
import {numberToCurrency} from '@/hooks/currentFormatUtils';

const InstallmentItem: React.FC<IInstallmentItem> = ({
    pass,
    isMenuOpen,
    onMenuToggle,
    onSelectOption,
    onRemovePayment,
    selectedSubMenuOption,
}) => {
    const menuOptions = [...INSTALLMENT_MENU_OPTIONS];
    const isActive = isMenuOpen && selectedSubMenuOption === OPTIONS.INSTALLMENT;

    const onClickEdit = () => {
        onSelectOption(OPTIONS.PAY_TOWARDS_PRINCIPAL);
    };

    const onClickInstallmentMenu = () => {
        onMenuToggle(OPTIONS.INSTALLMENT);
    };

    menuOptions[0].onClick = onClickEdit;
    menuOptions[1].onClick = onRemovePayment;

    return (
        <>
            <FlexPanel
                Width="100%"
                Align="center"
                Gap="1.5rem"
                Radius="0.25rem"
                Padding="1rem 0.5rem 1rem 0.5rem"
                Background={isActive ? '#FAFAFF' : '#FFFFFF'}
            >
                <ContainerFlex FlexDir="column" Justify="center" Align="flex-start" Flex="1 0 0">
                    <FlexPanel Justify="flex-start" Align="flex-start" Self="stretch" Gap="0.5rem">
                        <ContainerImg Height="3rem" Width="3.813rem">
                            <Image
                                src={arrowTopLeftIcon}
                                alt="article"
                                Cursor="pointer"
                                Width="0.688rem"
                                Height="0.688rem"
                            />
                        </ContainerImg>
                        <FlexPanel FlexDir="column" Align="flex-start" Gap="0.5rem">
                            <Text
                                Color="#1D1E20"
                                FontSize="1rem"
                                FontWeight="700"
                                LetterSpacing="0.019rem"
                            >
                                {INSTALLMENTS.PAYMENT}
                            </Text>
                            <Text Color="#2A2C2F" FontSize="0.75rem" FontWeight="400">
                                {INSTALLMENTS.OUTSTANDING_BALANCE}
                                {numberToCurrency(pass.loanBalance)}
                            </Text>
                        </FlexPanel>
                    </FlexPanel>
                </ContainerFlex>
                <Text
                    Color="#1D1E20"
                    TextAlign="right"
                    FontSize="1rem"
                    FontWeight="700"
                    LetterSpacing="0.019rem"
                >
                    {numberToCurrency(pass.totalPass)}
                </Text>
                <Image
                    src={moreIcon}
                    alt="options"
                    Cursor="pointer"
                    Width="1.5rem"
                    onClick={() => onClickInstallmentMenu()}
                    Height="1.5rem"
                />
            </FlexPanel>
            {isActive && <InstallmentMenu menuOptions={menuOptions} />}
        </>
    );
};
export default InstallmentItem;
