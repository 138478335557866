import {RootState} from '@/config/store';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import filter from '@images/filter.svg';
import expanded from '@images/expande.svg';
import {DATE_FORMATS} from '@Shopify/ListClient/constants';
import * as style from '@Shopify/ClientProfile/slylesClient';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {IPawnContracts} from '@Shopify/ClientProfile/interfaces';
import {formatDate, formatNum, numberToCurrency} from '@/hooks/currentFormatUtils';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {convertDate, OPERATIONS} from '@Shopify/ClientProfile/constants';
import {FiltersContracts} from '@Shopify/ClientProfile/FiltersContracts';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {MoreOptionsEndeavors} from '@Shopify/ClientProfile/MoreOptionsEndeavors';
import {
    catStatusContract,
    getEndeavours,
} from '@Shopify/ClientProfile/Redux/Action/EndeavorsContracts';

export const EndeavorContracts = () => {
    const dispatch = useDispatch();
    const [openOption, setOpenOption] = useState<IPawnContracts | null>(null);

    const {pawn, token, clientId, loading} = useSelector((state: RootState) => ({
        clientId: state.cardClientProfile.clientProfile.clientId,
        pawn: state.endeavorsContracs.contracts,
        loading: state.endeavorsContracs.loading,
        token: String(state.getUsersValidation.userData?.token),
    }));
    const [filters, setFilters] = useState(false);

    const handleOptionClick = (contract: IPawnContracts) =>
        setOpenOption((prevContract) => (prevContract === contract ? null : contract));

    useEffect(() => {
        dispatch(catStatusContract(token));
        dispatch(getEndeavours(token, {clientId: clientId}));
    }, []);

    return (
        <ContainerFlex FlexDir="column" Align="end" Gap="1rem">
            {!loading && pawn && (
                <ContainerFlex {...style.contractsResume.tab} JustifyItems="center">
                    {OPERATIONS.ENDEAVORS.TOTALS.map((header: string, index: number) => (
                        <Text Color="#54575C" FontSize="0.875rem" key={index}>
                            {header}
                        </Text>
                    ))}
                    <Text {...style.contractsResume.content}>
                        {formatNum({
                            number: pawn.totalBalance,
                            fractionDigits: 2,
                            money: true,
                        })}
                    </Text>
                    <Text {...style.contractsResume.content}>{pawn.totalContracts}</Text>
                    <Text {...style.contractsResume.content}>
                        {formatNum({
                            number: pawn.totalPayment,
                            fractionDigits: 2,
                            money: true,
                        })}
                    </Text>
                </ContainerFlex>
            )}
            <FlexPanel
                {...style.filter}
                Border="1px solid #5A5AFF"
                Radius="2rem"
                onClick={() => setFilters(true)}
            >
                <Image Height="1.5rem" Width="1.5rem" src={filter} alt="filters-icon" />
                <Text {...style.filter.text}>{OPERATIONS.FILTER}</Text>
            </FlexPanel>
            <ContainerFlex
                {...style.table.content}
                Padding="0.5rem"
                GridColumns="repeat(10, 1fr) .4fr"
            >
                {OPERATIONS.ENDEAVORS.HEADERS.map((header: string, index: number) => (
                    <ContainerFlex
                        key={index}
                        Cursor={index === 0 ? 'pointer' : 'auto'}
                        Gap="0.25rem"
                        Justify="start"
                    >
                        <Text
                            {...style.table.header}
                            Padding="0.25rem 0"
                            Cursor={index === 0 ? 'pointer' : 'auto'}
                        >
                            {header}
                        </Text>
                        {index === 0 && <Image src={expanded} alt="expanded-icon" />}
                    </ContainerFlex>
                ))}
            </ContainerFlex>
            {loading && (
                <ContainerFlex>
                    <LoadingAtaskate />
                </ContainerFlex>
            )}
            {!loading &&
                pawn &&
                pawn.pawnContracts &&
                (pawn.pawnContracts.length > 0 ? (
                    pawn.pawnContracts.map((item) => (
                        <ContainerFlex
                            key={item.contractId}
                            {...style.table.content}
                            GridColumns="repeat(10, 1fr) .2fr"
                            Height="5.063rem"
                        >
                            <Text {...style.table.header} Color="#5A5AFF">
                                {item.contractNumber}
                            </Text>
                            <Text {...style.table.details}>{item.numberGarments}</Text>
                            <ContainerFlex {...style.contentImg} Radius="0.25rem">
                                <Image src={item.urlGarment} {...style.img} alt="articles-img" />
                            </ContainerFlex>
                            <Text {...style.table.details}>{numberToCurrency(item.renewal)}</Text>
                            <Text {...style.table.details}>
                                {numberToCurrency(item.performance)}
                            </Text>
                            <Text {...style.table.details}>{numberToCurrency(item.loan)}</Text>
                            <Text {...style.table.details}>{numberToCurrency(item.appraisal)}</Text>
                            <Text {...style.table.details}>{item.branchName}</Text>
                            <Text {...style.table.details}>
                                {formatDate(convertDate(item.endDate), DATE_FORMATS.FULL_DATE)}
                            </Text>
                            <Text {...style.colorStatusContract(item.status)}>{item.status}</Text>
                            <MoreOptionsEndeavors
                                pawn={item}
                                isOpen={openOption === item}
                                onOptionClick={() => handleOptionClick(item)}
                            />
                        </ContainerFlex>
                    ))
                ) : (
                    <ContainerFlex FontWeight="500" Color="#2A2C2F" FontSize="0.875rem">
                        {OPERATIONS.ENDEAVORS.NO_CONTRACTS}
                    </ContainerFlex>
                ))}

            {filters && <FiltersContracts filters={filters} setFilters={setFilters} />}
        </ContainerFlex>
    );
};
