import axios, {AxiosError, AxiosResponse} from 'axios';
import {AppDispatch} from '@/config/store';
import {
    GET_TYPE_EVENT_CONDITION_START,
    GET_TYPE_EVENT_CONDITION_SUCCESS,
    GET_TYPE_EVENT_CONDITION_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IParamsGetTypeEvent} from '@components/PersonalLoans/NewLoanProduct/Redux/reducerInterfaces';
import {URL} from '@config/constants';

export const getTypeEventConditionStart = () => {
    return {
        type: GET_TYPE_EVENT_CONDITION_START,
    };
};

export const getTypeEventConditionSuccess = (result: AxiosResponse) => {
    return {
        type: GET_TYPE_EVENT_CONDITION_SUCCESS,
        payload: result.data,
    };
};

export const getTypeEventConditionError = (error: AxiosError) => {
    return {
        type: GET_TYPE_EVENT_CONDITION_ERROR,
        error,
    };
};

export const getTypeEventCondition = (token: string, params: IParamsGetTypeEvent) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getTypeEventConditionStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTypeEventCondition}`,
                {headers, params}
            );
            dispatch(getTypeEventConditionSuccess(response));
        } catch (error) {
            dispatch(getTypeEventConditionError(error as AxiosError));
        }
    };
};
