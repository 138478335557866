import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React, {useState} from 'react';
import {CONFIG_MENU} from '@components/Home/Admin/constants';
import RadioButton from '@components/Home/style';
import {ProgressBar} from '@components/Home/Admin/ProgressBar';
import {IconSvg} from '@/components/Quoter/styles';
export const EnterpriceConfiguration = () => {
    const [selectedValue, setSelectedValue] = useState<string>('1');
    const handleRadioButtonChange = (value: string) => {
        setSelectedValue(value);
    };

    return (
        <ContainerFlex Padding="16px 0" Width="100%" Justify="start" Align="start" Height="">
            <ContainerFlex
                backG="#FAFAFA"
                Padding="16px"
                Height="auto"
                FlexDir="column"
                Align="start"
                Radius="16px"
            >
                <Text FontSize="1.25rem" Color="#2A2C2F" FontWeight="500">
                    Termina de configurar tu empresa
                </Text>
                <ProgressBar />
                {CONFIG_MENU.map((data: {name: string; id: number}) => {
                    return (
                        <ContainerFlex
                            Justify="start"
                            Align="start"
                            key={data.id}
                            Padding="12px 16px"
                        >
                            <RadioButton
                                value={selectedValue}
                                onChange={(value) => handleRadioButtonChange(value)}
                            />
                            <Text Width="100%" FontSize="1rem" Color="#2A2C2F" FontWeight="500">
                                {data.name}
                            </Text>
                            <IconSvg
                                width="24"
                                height="24"
                                viewBox="0 0 24 25"
                                xmlns="http://www.w3.org/2000/svg"
                                cursor="pointer"
                            >
                                <path
                                    d="M9.4 18L8 16.6L12.6 12L8 7.4L9.4 6L15.4 12L9.4 18Z"
                                    fill="#5A5AFF"
                                />
                            </IconSvg>
                        </ContainerFlex>
                    );
                })}
            </ContainerFlex>
        </ContainerFlex>
    );
};
