import {useSelector} from 'react-redux';
import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DOCUMENTCLIENTS, TYPEDOCUMENTS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IClientAndDocumentsPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/clientInterfaces';
import {HeaderPreview} from '@components/PersonalLoans/NewLoanProduct/ui/HeaderPreview';
import {RootState} from '@/config/store';
import {ClientData} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ClientsAndDocuments/Preview/ClientData';

export const ClientAndDocumentsPreview: React.FC<IClientAndDocumentsPreviewProps> = ({
    clientAndDocumentsRef,
    setIsEditing,
    isEditing,
}) => {
    const {updatedDocuments} = useSelector((state: RootState) => {
        return state.UpdatePersonalDocumentsReducer;
    });
    const documentClientData = useSelector(
        (state: RootState) => state.newLoanSubmittedData.details
    );
    const activeDocumentList = [...new Set([...documentClientData.documents, ...updatedDocuments])];
    const documents = activeDocumentList.filter((item) => item.isChecked);

    return (
        <>
            <ContainerFlex
                backG="#FFF"
                Border="1px solid #E8E9EA"
                Radius="1.5rem"
                Padding="1.5rem"
                Gap="1.5rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                Height="max-content"
                ref={clientAndDocumentsRef}
            >
                <HeaderPreview
                    title={DOCUMENTCLIENTS.TITLE}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
                <ClientData />
                <ContainerFlex
                    Radius="1rem"
                    Border="1px solid #E8E9EA"
                    backG="#FFF"
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    OverFlow="none"
                >
                    <ContainerFlex
                        Gap="1rem"
                        Justify="start"
                        Align="start"
                        Bb="1px solid #E8E9EA"
                        Padding="0.5rem 1rem"
                    >
                        <Text
                            oFlow="normal"
                            wSpace="pre"
                            Color="#2A2C2F"
                            FontWeight="700"
                            Width="100%"
                            Padding="0.25rem 0px"
                        >
                            {TYPEDOCUMENTS.DOCUMENT}
                        </Text>
                        <Text
                            oFlow="normal"
                            wSpace="pre"
                            Color="#2A2C2F"
                            FontWeight="700"
                            Width="100%"
                            Padding="0.25rem 0px"
                        >
                            {TYPEDOCUMENTS.REQUIRED}
                            <Text oFlow="normal" wSpace="pre" FontSize="1rem" Color="#54575C">
                                {TYPEDOCUMENTS.OPTIONAL}
                            </Text>
                        </Text>
                    </ContainerFlex>

                    {documents.map((item, index) => (
                        <ContainerFlex
                            Gap="1rem"
                            Justify="start"
                            Align="start"
                            Bb={index === documents.length - 1 ? '' : '1px solid #E8E9EA'}
                            Padding="0.5rem 1rem"
                            key={index}
                        >
                            <ContainerFlex
                                Gap="0.25rem"
                                Justify="start"
                                Align="start"
                                FlexDir="column"
                            >
                                <Text Color="#2A2C2F">{item.docName}</Text>
                                <Text Color="#54575C" FontSize="0.875rem">
                                    {item.docDescription}
                                </Text>
                            </ContainerFlex>
                            {item.documentId === 3 && (
                                <ContainerFlex Gap="1rem" Justify="start" Padding="0.25rem 0px">
                                    <Text Color="#1D1E20" FontSize="0.875rem">
                                        {TYPEDOCUMENTS.TIME}
                                    </Text>
                                    <Text Color="#1D1E20" Padding="0.25rem 0px">
                                        {`${
                                            item.seniorityJob
                                        } ${item.seniorityJobTime.label.toLocaleLowerCase()}`}
                                    </Text>
                                </ContainerFlex>
                            )}
                        </ContainerFlex>
                    ))}
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
