import {GET_STORAGE_BY_LOCATION_ID} from '@components/LocationsBranch/Redux/types';
import {
    IStorageByLocationState,
    IStorageByLocationRedux,
} from '@components/LocationsBranch/Redux/interfaces';

const initialState: IStorageByLocationState = {
    data: [],
    loading: false,
    error: false,
};

const storageByLocationId = (
    state = initialState,
    action: IStorageByLocationRedux
): IStorageByLocationState => {
    switch (action.type) {
        case GET_STORAGE_BY_LOCATION_ID.START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_STORAGE_BY_LOCATION_ID.SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case GET_STORAGE_BY_LOCATION_ID.ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default storageByLocationId;
