import React, {useState} from 'react';

import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import arrowIcon from '@images/arrowDownIcon.svg';
import LocationsList from '@components/LocationsBranch//LocationsList';
import {ILocationsProps} from '@components/LocationsBranch/interfaces';
import {LOCATIONGROUP} from '@components/LocationsBranch/constants';
import {LocationsGroupsList} from '@components/LocationsBranch/LocationsGroupsList';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';

const Locations: React.FC<ILocationsProps> = ({onClickNewButton, inheritType}) => {
    const [showGroup, setShowGroup] = useState(true);
    const {loadingGroups} = useSelector((state: RootState) => ({
        loadingGroups: state.getCompanyLevels.loading,
    }));

    const handleShowLocation = (id: number) => {
        if (onClickNewButton) onClickNewButton(id);
    };

    const typeFilter = 1;

    return (
        <FlexPanel
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="0.5rem"
            Padding="1rem"
            backG="#FAFAFA"
            Border="solid 1px #F4F5F5"
            Radius="1rem"
            Width="100%"
        >
            {loadingGroups ? (
                <ContainerFlex Justify="center" Align="center" Height="12.5rem">
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <>
                    <LocationsGroupsList
                        setShowLocationsModal={handleShowLocation}
                        showGroup={showGroup}
                        inheritType={inheritType}
                    />
                    <ContainerFlex Align="start" Height="1.5rem">
                        <Text
                            Color="#5A5AFF"
                            FontWeight="700"
                            Cursor="pointer"
                            onClick={() => setShowGroup(!showGroup)}
                        >
                            {showGroup ? LOCATIONGROUP.HIDDENGROUP : LOCATIONGROUP.SHOWGROUP}
                        </Text>
                        <Image
                            src={arrowIcon}
                            alt="type"
                            Width="1.5rem"
                            Height="1.5rem"
                            Cursor="pointer"
                            Transform={showGroup ? 'rotate(180deg)' : 'rotate(0deg)'}
                            onClick={() => setShowGroup(!showGroup)}
                        />
                    </ContainerFlex>
                    <LocationsList typeFilter={typeFilter} inheritType={inheritType} />
                </>
            )}
        </FlexPanel>
    );
};
export default Locations;
