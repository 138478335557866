import React, {useEffect} from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import CardProfile from '@Shopify/ClientProfile/CardProfile';
import {Operations} from '@Shopify/ClientProfile/Operations';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {QuickActions} from '@Shopify/ClientProfile/QuickActions';
import Breadcrumb from '@components/General/Moleculs/Breadcrumb/Index';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';
import {BreadClient, CLIENT_PROFILE} from '@Shopify/ClientProfile/constants';
import {clientQuickActions} from '@Shopify/ClientProfile/Redux/Action/QuickAction';
import {clientProfileId} from '@Shopify/ClientProfile/Redux/Action/CardProfileById';
import {getClientDetails} from '@Shopify/ClientProfile/Redux/Action/GetClientDetails';
import {clearIdentificationImageData} from '@components/Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';

export const ClientProfile = () => {
    const dispatch = useDispatch();
    const {clientProfile, token} = useSelector((state: RootState) => ({
        clientProfile: state.cardClientProfile,
        token: state.getUsersValidation.userData?.token as string,
    }));

    const clientProperties = () => {
        dispatch(clientProfileId(token, {clientId: clientProfile.idClient}));
        dispatch(getClientDetails(token, {clientId: clientProfile.idClient}));
        dispatch(clearIdentificationImageData());
        dispatch(
            clientQuickActions(token, {
                clientId: clientProfile.idClient,
                pageSize: 10,
            })
        );
    };

    useEffect(() => clientProperties(), []);

    return (
        <ContainerFlex
            Gap="1rem"
            Padding="1rem 1rem 1rem 1.5rem"
            Align="flex-start"
            Justify="start"
            FlexDir="Column"
        >
            <Breadcrumb fontSize="0.75rem" fontWeight="700" color="#0D166B" items={BreadClient} />
            <Text Color="#2A2C2F" FontSize="1.5rem" FontWeight="700">
                {CLIENT_PROFILE.PROFILE}
            </Text>
            {clientProfile.loading ? (
                <ContainerFlex>
                    <LoadingAtaskate />
                </ContainerFlex>
            ) : (
                <>
                    <CardProfile />
                    <QuickActions />
                    <Operations />
                </>
            )}
        </ContainerFlex>
    );
};
