import axios from 'axios';
import {
    GET_BRANCH_TREE_REQUEST,
    GET_BRANCH_TREE_SOUCCES,
    GET_BRANCH_TREE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

import process from 'process';

import {URL} from '@config/constants/index';

export const DeleteUserResquest = () => {
    return {
        type: GET_BRANCH_TREE_REQUEST,
    };
};
export const DeleteUserSuccess = (userData) => {
    return {
        type: GET_BRANCH_TREE_SOUCCES,
        payload: userData,
    };
};

export const DeleteUserFailure = (err) => {
    return {
        type: GET_BRANCH_TREE_ERROR,
        payload: err,
    };
};
export const GetTreeUser = (token, employeeId) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };

    return async (dispatch) => {
        dispatch(DeleteUserResquest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetBranchesByEmployee}`,
                {
                    headers,
                    params: {employeeId},
                }
            );
            dispatch(DeleteUserSuccess(response.data));
        } catch (error) {
            dispatch(DeleteUserFailure(error));
        }
    };
};
