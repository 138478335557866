import React from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {Controller, FieldValues, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {StyleNumericFormat} from '@Loan/styles';
import {paymentAmountSchema} from '@/components/ShoppingCart/RenewalMenuOption/yupInstallments';
import {INSTALLMENTS} from '@/components/ShoppingCart/constants';
import DeleteIcon from '@images/DarkDeleteIcon.svg';
import DarkCheckIcon from '@images/DarkCheck.svg';
import {IInstallments} from '@components/ShoppingCart/interfaces';
import {fetchUpdateEndorsementCart} from '@components/ShoppingCart/Redux/Actions/EndorsementsReducer';
import {QuitNumberFormat} from '@/hooks/currentFormatUtils';

const Installments: React.FC<IInstallments> = ({endorsement, onExit}) => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    const {error, errorMessage} = useSelector((state: RootState) => state.endorsementsReducer);
    const [isFocused, setIsFocused] = React.useState<boolean>(false);
    const [isExceededAmount, setIsExceededAmount] = React.useState<boolean>(false);

    const {watch, control, getValues} = useForm<FieldValues>({
        resolver: yupResolver(paymentAmountSchema),
        defaultValues: {
            paymentAmount: endorsement.pass?.totalPass,
        },
    });

    const onSuccess = () => {
        setIsExceededAmount(false);
        onExit(true);
    };

    const onError = () => {
        setIsExceededAmount(true);
    };

    const onAddPayment = () => {
        dispatch(
            fetchUpdateEndorsementCart(
                token,
                {
                    ...getEndorsementData(),
                },
                onSuccess,
                onError
            )
        );
    };

    const onCancelEdit = () => {
        onExit(false);
    };

    const getEndorsementData = (isDeleting?: boolean) => {
        const grossAmount = !isDeleting ? QuitNumberFormat(getValues('paymentAmount')) : 0;
        const carShoppingId = cartData?.carShoppingId ?? 0;
        const contractId = endorsement.contractId;

        return {grossAmount, carShoppingId, contractId};
    };

    const paymentAmount = watch('paymentAmount');

    return (
        <FlexPanel Width="24rem" Align="center" Background="#FFFFFF" FlexDir={'Column'}>
            <ContainerFlex
                Justify="flex-start"
                Align="flex-start"
                FlexDir="Column"
                Padding="0 0 0.5rem 0.5rem"
            >
                <FlexPanel
                    Width="24rem"
                    Justify="flex-start"
                    Align="flex-start"
                    Background="#FFFFFF"
                >
                    <Text Color="#1D1E20" FontSize="1rem" FontWeight="700" LetterSpacing="0.019rem">
                        {INSTALLMENTS.TITLE}
                    </Text>
                </FlexPanel>
                <ContainerFlex Justify="flex-start" Align="center" Self="stretch" Gap="1.5rem">
                    <FlexPanel
                        Width="20rem"
                        Justify="flex-start"
                        Align="flex-start"
                        Background="#FFFFFF"
                    >
                        <Controller
                            name="paymentAmount"
                            control={control}
                            render={({field: {ref, ...field}}) => (
                                <StyleNumericFormat
                                    {...field}
                                    getInputRef={ref}
                                    width="100%"
                                    type="text"
                                    prefix="$ "
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    allowNegative={false}
                                    autoComplete="off"
                                    autoFocus
                                    onFocus={() => setIsFocused(true)}
                                />
                            )}
                        />
                    </FlexPanel>
                    <FlexPanel Justify="flex-end" Align="flex-start" Background="#FFFFFF">
                        {isFocused && paymentAmount ? (
                            <Image
                                src={DarkCheckIcon}
                                alt="add-payment"
                                onClick={() => {
                                    onAddPayment();
                                }}
                                Cursor="pointer"
                            />
                        ) : (
                            <Image
                                src={DeleteIcon}
                                alt="cancel-add-payment"
                                onClick={() => {
                                    onCancelEdit();
                                }}
                                Cursor="pointer"
                            />
                        )}
                    </FlexPanel>
                </ContainerFlex>
                <ContainerFlex Justify="flex-start" Align="center" Self="stretch">
                    {isExceededAmount && error && errorMessage && (
                        <Text Color="#FF6357" FontSize="0.75rem">
                            {errorMessage}
                        </Text>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </FlexPanel>
    );
};
export default Installments;
