import axios, {AxiosError} from 'axios';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IChangeStatus} from '@components/RequestNewCatalogLevel/interfacesTree';

import {
    CHANGE_STATUS,
    CHANGE_STATUS_SUCCESS,
    CHANGE_STATUS_ERROR,
} from '@/components/RequestNewCatalogLevel/Types/Types';
const changeStatus = () => {
    return {type: CHANGE_STATUS};
};
const changeStatusSuccess = () => {
    return {type: CHANGE_STATUS_SUCCESS};
};

const changeStatusError = (error: AxiosError) => {
    return {type: CHANGE_STATUS_ERROR, error: error};
};

export function ChangeStatusLevel(token: string, data: IChangeStatus) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(changeStatus());
        try {
            await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ChangeCatalogStatus}`,
                data,
                {
                    headers,
                }
            );
            dispatch(changeStatusSuccess());
        } catch (error) {
            dispatch(changeStatusError(error as AxiosError));
        }
    };
}
