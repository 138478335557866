import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {IRowChargeProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/ChargesAndPenalties/chargesAndPenaltiesInterfaces';

export const RowCharge: React.FC<IRowChargeProps> = ({item, index, lastIndex}) => {
    const amountText: string =
        item.chargeAmount.typeChargeAmountId === 0
            ? `$${item.chargeAmount.amount}`
            : `${item.chargeAmount.amount}%`;
    return (
        <ContainerFlex
            Gap="1rem"
            Justify="start"
            Align="start"
            Bb={index === lastIndex ? '' : '1px solid #E8E9EA'}
            Padding="0.5rem 1rem"
            key={index}
        >
            <Text
                FontSize="0.875rem"
                Color="#2A2C2F"
                Width="100%"
                Padding="0.25rem 0px"
                wSpace="wrap"
            >
                {item.typeCharge.label}
            </Text>
            <Text
                FontSize="0.875rem"
                Color="#2A2C2F"
                Width="100%"
                Padding="0.25rem 0px"
                wSpace="wrap"
            >
                {item.chargeName}
            </Text>
            <Text
                FontSize="0.875rem"
                Color="#2A2C2F"
                Width="100%"
                Padding="0.25rem 0px"
                wSpace="wrap"
            >
                {amountText}
            </Text>
            <Text
                FontSize="0.875rem"
                Color="#2A2C2F"
                Width="100%"
                Padding="0.25rem 0px"
                wSpace="wrap"
            >
                {item.condition.label}
            </Text>
            <Text
                FontSize="0.875rem"
                Color="#2A2C2F"
                Width="100%"
                Padding="0.25rem 0px"
                wSpace="wrap"
            >
                {item.eventType.label}
            </Text>
        </ContainerFlex>
    );
};
