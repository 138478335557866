import {
    ADD_NEW_CHARGE,
    HANDLE_ALL_CHARGES_CHECKBOXS,
    HANDLE_CHARGE_CHECKBOX,
    SHOW_EDIT_BUTTONS,
    SELECT_CHARGE,
    EDIT_CHARGE,
    DELETE_CHARGE,
    TOGGLE_ALL_SELECT_CHECKBOX,
    SET_DEFAULT_CHARGES_AND_PENALTIES,
    RESET_CHARGES_AND_PENALTIES,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {
    IChargeAndPenaltyItem,
    IEditedCharge,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const handleAllChargesCheckboxs = () => {
    return {
        type: HANDLE_ALL_CHARGES_CHECKBOXS,
    };
};

export const handleChargeCheckbox = (id: number) => {
    return {
        type: HANDLE_CHARGE_CHECKBOX,
        id,
    };
};

export const showEditButtons = (id: number) => {
    return {
        type: SHOW_EDIT_BUTTONS,
        id,
    };
};

export const addNewCharge = (newCharge: IChargeAndPenaltyItem) => {
    return {
        type: ADD_NEW_CHARGE,
        newCharge,
    };
};

export const selectedCharge = (selectedChargeAndPenalty: IChargeAndPenaltyItem) => {
    return {
        type: SELECT_CHARGE,
        selectedChargeAndPenalty,
    };
};

export const editCharge = (editedCharge: IEditedCharge) => {
    return {
        type: EDIT_CHARGE,
        editedCharge,
    };
};

export const deleteCharge = () => {
    return {
        type: DELETE_CHARGE,
    };
};

export const toggleAllSelectCheckbox = () => {
    return {
        type: TOGGLE_ALL_SELECT_CHECKBOX,
    };
};

export const setDefaultChargesAndPenalties = (defaultCharges: IChargeAndPenaltyItem[]) => {
    return {
        type: SET_DEFAULT_CHARGES_AND_PENALTIES,
        defaultCharges,
    };
};

export const resetChargesAndPenalties = () => {
    return {
        type: RESET_CHARGES_AND_PENALTIES,
    };
};
