import styled from '@emotion/styled';
import Switch from '@mui/material/Switch';
import done from '@images/done.svg';

export const GreenSwitch = styled(Switch)(({theme}) => ({
    width: 48,
    height: 24,
    padding: 0,
    borderRadius: 16,

    '& .MuiSwitch-switchBase': {
        color: '#FFF',
        padding: 2,
        boxShadow: '1px 1px 4px rgba(212, 214, 216, 0.88)',
        '&.Mui-checked': {
            color: '#5A5AFF',
            transform: 'translateX(24px)',
            boxShadow: '1px 1px 4px rgba(172, 172, 255, 0.88)',
        },
        '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: '#E5E5FF',
            opacity: 1,
        },
        '&.Mui-checked.Mui-disabled': {
            color: 'rgba(172, 172, 255, 0.88)',
        },
    },
    '& .MuiSwitch-track': {
        backgroundColor: '#F4F5F5',
        borderRadius: 16,
        opacity: 1,
        boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.10) inset',
    },
    '& .MuiSwitch-thumb': {
        boxShadow: 'none',
        alignContent: 'center',
        alignItems: 'center',
    },
}));

export const DoneSwitch = styled(Switch)`
    &.MuiSwitch-root {
        width: 48px;
        height: 24px;
        padding: 0;
    }

    .MuiSwitch-switchBase {
        padding: 2px;
        &.Mui-checked {
            transform: translateX(24px);
            color: #fff;
            .MuiSwitch-thumb {
                background-color: #5a5aff;
            }
            & + .MuiSwitch-track {
                background-color: #e5e5ff;
                opacity: 1;
            }
        }
    }

    .MuiSwitch-thumb {
        width: 20px;
        height: 20px;
        background-color: #d4d6d8;
        background-image: url(${done});
        background-repeat: no-repeat;
        background-position: center;
    }

    .MuiSwitch-track {
        border-radius: 12px;
        background-color: #f4f5f5;
        opacity: 1;
    }
`;
