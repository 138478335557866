import * as yup from 'yup';
import {VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';

export const regexPatterns = {
    name: /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑüÜ ]*$/,
    description: /^[a-zA-Z0-9áéíóúÁÉÍÓÚñÑüÜ,.\-_():; ]*$/,
};

export const schemaAddNewDocumentForm = yup.object().shape({
    name: yup
        .string()
        .required(VALIDATIONS_MSG.MANDATORY_FIELD)
        .matches(regexPatterns.name, VALIDATIONS_MSG.DOCUMENTS.INVALID_CHARACTERS)
        .max(100, VALIDATIONS_MSG.DOCUMENTS.MAX_LENGTH),
    description: yup
        .string()
        .matches(regexPatterns.description, VALIDATIONS_MSG.DOCUMENTS.INVALID_CHARACTERS)
        .max(100, VALIDATIONS_MSG.DOCUMENTS.MAX_LENGTH),
});
