import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    EMAIL_PAYMENT_MADE_ERROR,
    EMAIL_PAYMENT_MADE_START,
    EMAIL_PAYMENT_MADE_SUCCESS,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IEmailPaymentMade} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

const emailPaymentMadeStart = () => {
    return {
        type: EMAIL_PAYMENT_MADE_START,
    };
};

const emailPaymentMadeSuccess = (result: AxiosResponse) => {
    return {
        type: EMAIL_PAYMENT_MADE_SUCCESS,
        payload: result,
    };
};

const emailPaymentMadeError = (error: AxiosError) => {
    return {
        type: EMAIL_PAYMENT_MADE_ERROR,
        error: error,
    };
};

function emailPaymentMadeAction(token: string, emailPaymentMade: IEmailPaymentMade) {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.EmailPaymentMadeSeveralCreditsLoans}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const params = {...emailPaymentMade};
    return async (dispatch: AppDispatch) => {
        dispatch(emailPaymentMadeStart());
        try {
            const response = await axios.get(url, {headers, params});
            dispatch(emailPaymentMadeSuccess(response.data));
        } catch (e) {
            dispatch(emailPaymentMadeError(e as AxiosError));
        }
    };
}

export default emailPaymentMadeAction;
