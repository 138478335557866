import React from 'react';
import {ContainerFlex, ContainerGrid, Text} from '@components/Shopify/Ecommerce/styles';
import {TITLES} from '@/components/AuctionPass/AuctionConstants';
import {ImageLogo} from '@components/LoginClient/styles';
import questionImg from '@images/searchAtaskate.svg';
import {IinputTypes} from '@components/AuctionPass/interface';
import {PurpleSwitch} from '@components/CashFlow/SwitchComponent';
import {Controller} from 'react-hook-form';
import {StyleNumericFormat} from '@components/Loan/styles';

export const SaleInputs = ({register, control, errors}: IinputTypes) => {
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            Padding="0 16px"
            FlexDir="column"
            Height="246px"
            Gap="8px"
        >
            <Text Color="#0D166B" FontSize="1.25rem" FontWeight="700">
                {TITLES.PRICE}
            </Text>
            <ContainerGrid Gap="8px" FlexDir="column" GridColumns="repeat(3,1fr)" ColumnGap="32px">
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Height="68px"
                    Gap="4px"
                >
                    <Text FontWeight="700">{TITLES.SALE_PRICE}</Text>
                    <Controller
                        name={`salePrice`}
                        control={control}
                        render={({field}) => (
                            <StyleNumericFormat
                                {...field}
                                name="value"
                                type="text"
                                autoComplete="off"
                                decimalSeparator="."
                                thousandSeparator={true}
                                decimalScale={2}
                                hasError={!!errors.salePrice?.message}
                                allowNegative={false}
                                prefix="$ "
                                width="85%"
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Height="68px"
                    Gap="4px"
                >
                    <Text FontWeight="700">{TITLES.MIN_SALE}</Text>
                    <Controller
                        name={`minAppartPrice`}
                        control={control}
                        render={({field}) => (
                            <StyleNumericFormat
                                {...field}
                                name="value"
                                type="text"
                                autoComplete="off"
                                decimalSeparator="."
                                thousandSeparator={true}
                                decimalScale={2}
                                hasError={!!errors.salePrice?.message}
                                allowNegative={false}
                                prefix="$ "
                                width="85%"
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Height="68px"
                    Gap="4px"
                >
                    <Text FontWeight="700">{TITLES.SALE_PRICE_TOTAL}</Text>
                    <Controller
                        name={`minPrice`}
                        control={control}
                        render={({field}) => (
                            <StyleNumericFormat
                                {...field}
                                name="value"
                                type="text"
                                autoComplete="off"
                                decimalSeparator="."
                                thousandSeparator={true}
                                decimalScale={2}
                                hasError={!!errors.salePrice?.message}
                                allowNegative={false}
                                prefix="$ "
                                width="85%"
                            />
                        )}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="center" Height="68px">
                    <Text FontWeight>{TITLES.OFFERTE_ACTIVE}</Text>
                    <PurpleSwitch />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontWeight="700">{TITLES.OFFER_MIN}</Text>
                    <ContainerFlex Position="relative">
                        <Controller
                            name={`minOffer`}
                            control={control}
                            render={({field}) => (
                                <StyleNumericFormat
                                    {...field}
                                    name="value"
                                    type="text"
                                    autoComplete="off"
                                    decimalSeparator="."
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    hasError={!!errors.salePrice?.message}
                                    allowNegative={false}
                                    prefix="$ "
                                    width="85%"
                                />
                            )}
                        />
                        <ImageLogo
                            Position="relative"
                            Cursor="pointer"
                            src={questionImg}
                            Height="24px"
                            Width="24px"
                            Margin="0 0 0 -2.5rem"
                            alt="question-marks"
                        />
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="68px">
                    <Text FontWeight="700">{TITLES.OFFER_MIN_APPRT_PERCENT}</Text>
                    <Controller
                        name={`minAppartPercent`}
                        control={control}
                        render={({field}) => (
                            <StyleNumericFormat
                                {...field}
                                name="value"
                                type="text"
                                autoComplete="off"
                                decimalSeparator="."
                                thousandSeparator={true}
                                decimalScale={2}
                                hasError={!!errors.salePrice?.message}
                                allowNegative={false}
                                prefix="% "
                                width="85%"
                            />
                        )}
                    />
                </ContainerFlex>
            </ContainerGrid>
        </ContainerFlex>
    );
};
