import React, {useMemo, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {ContainerFlex, Text, ContainerForm} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@General/Atoms/Button/styles';
import {IconSvg} from '@Quoter/Icons/styles';
import {
    UploadLoansProps,
    IDocumentsInfo,
    IIdentificationData,
    ImageCharge,
    IAdditonalCreditDocuments,
} from '@components/PersonalLoans/interfaces';
import {PersonalLoansDocument} from '@components/PersonalLoans/PersonalLoansDocument';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {IDENTIFICATION_DOCUMENT, UPLOADDATA} from '@components/PersonalLoans/constants';
import {schemaUploadLoans} from '@components/PersonalLoans/schemaPersonalLoans';
import {ActualDataStepLoans} from '@components/PersonalLoans/Redux/Actions/DataStepLoans';
import {AppDispatch, RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';
import postAddCheckIdentity from '@components/PersonalLoans/Redux/Actions/PostAddCheckIdentityActions';
import {updateSaveDocumentsId} from '@components/PersonalLoans/Redux/Actions/PutUpdateSaveDocumentsIdActions';
import {TransversalLoader} from '@components/General/Atoms/TransversalLoader/TransversalLoader';
import {toCamelCase} from '@/hooks/currentFormatUtils';

export const PersonalLoansStepThree = () => {
    const {data} = useSelector((state: RootState) => {
        return state.getAdditionalCreditDocumentsReducer;
    });
    const [documentsInfo, setDocumentsInfo] = useState<IDocumentsInfo>({
        idDocument: 1,
        frontImage: null,
        backImage: null,
        incomeImage: null,
        ...(data.length > 0
            ? data.reduce((acc: {[x: string]: null}, item: IAdditonalCreditDocuments) => {
                  acc[toCamelCase(item.documentName)] = null;
                  return acc;
              }, {})
            : {}),
    });
    const {
        register,
        reset,
        handleSubmit,
        control,
        setValue,
        formState: {errors},
    } = useForm({
        resolver: yupResolver(schemaUploadLoans),
        defaultValues: {
            idDocument: documentsInfo.idDocument,
            name: '',
            date: '',
            signature: false,
            authorized: false,
            nameIncomeDocument: '',
            ...data.reduce((acc: {[x: string]: string}, item: IAdditonalCreditDocuments) => {
                acc['name' + toCamelCase(item.documentName)] = '';
                return acc;
            }, {}),
        },
    });
    const dispatch: AppDispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });

    const personalInformationId = useSelector((state: RootState) => {
        return state.CreatePersonalLoans?.dataLoans?.data?.personalInformationId;
    });

    const validateUploadDocuments: boolean = useMemo(() => {
        if (
            documentsInfo.idDocument === 1 &&
            (!documentsInfo.frontImage || !documentsInfo.backImage)
        )
            return false;
        if (!documentsInfo.frontImage) return false;
        if (data.length > 0) {
            const additionalDocuments = data.filter(
                (item: IAdditonalCreditDocuments) => item.documentName !== IDENTIFICATION_DOCUMENT
            );
            const result = additionalDocuments.some((item: IAdditonalCreditDocuments) => {
                return !documentsInfo[toCamelCase(item.documentName)];
            });
            if (result) return false;
        }
        return true;
    }, [documentsInfo]);

    const fetchUploadDocuments = (
        identificationData: IIdentificationData,
        type: number,
        file: ImageCharge,
        documentId: number
    ) => {
        if (file) {
            dispatch(
                updateSaveDocumentsId(
                    {
                        ...identificationData,
                        documentId,
                        type,
                        fileExtension: file.fileName.substring(file.fileName.lastIndexOf('.') + 1),
                        fileContent: file.formFile.substring(file.formFile.lastIndexOf(',') + 1),
                    },
                    token
                )
            );
        }
    };
    const onSubmit = async (uploadData: UploadLoansProps) => {
        if (validateUploadDocuments) {
            const vigencyFormatted = Number(uploadData.date) - 1;
            const output = {
                personalInformationId,
                catIdentificationTypeId: documentsInfo.idDocument,
                signApplication: uploadData.signature,
                consultBureau: uploadData.authorized,
            };
            const identificationData: IIdentificationData = {
                name: uploadData.name,
                vigency: String(vigencyFormatted),
                personalInformationId,
            };
            await dispatch(postAddCheckIdentity(output, token));
            fetchUploadDocuments(identificationData, 1, documentsInfo.frontImage!, 0);
            if (documentsInfo.idDocument === 1)
                fetchUploadDocuments(identificationData, 2, documentsInfo.backImage!, 0);

            if (data.length > 0) {
                data.forEach((item: IAdditonalCreditDocuments) => {
                    fetchUploadDocuments(
                        {
                            ...identificationData,
                            name: String(uploadData['name' + toCamelCase(item.documentName)]),
                        },
                        4,
                        documentsInfo[toCamelCase(item.documentName)]! as ImageCharge,
                        item.documentId
                    );
                });
            }
        }
    };
    const postAddCheckIdentityLoading = useSelector(
        (state: RootState) => state.postAddCheckIdentity.loading
    );

    return (
        <ContainerForm
            onSubmit={handleSubmit(onSubmit)}
            BackG="none"
            Align="start"
            FlexDir="column"
            Justify="start"
            Height="auto"
            Gap="1.5rem"
        >
            <PersonalLoansDocument
                register={register}
                errors={errors}
                reset={reset}
                documentsInfo={documentsInfo}
                setDocumentsInfo={setDocumentsInfo}
                control={control}
                setValue={setValue}
            />
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
                <ContainerFlex Justify="start" Gap="0.5rem">
                    <Controller
                        name="signature"
                        control={control}
                        render={({field}) => <Checkbox {...field} checked={field.value} />}
                    />
                    <Text Color="#2A2C2F">{UPLOADDATA.SIGNATURE}</Text>
                </ContainerFlex>
                {errors.signature?.message && (
                    <Text Color="red" oFlow="none">
                        {errors.signature?.message as string}
                    </Text>
                )}
                <ContainerFlex Justify="start" Gap="0.5rem">
                    <Controller
                        name="authorized"
                        control={control}
                        render={({field}) => <Checkbox {...field} checked={field.value} />}
                    />
                    <Text Color="#2A2C2F">{UPLOADDATA.AUTHORIZED}</Text>
                </ContainerFlex>
                {errors.authorized && (
                    <Text Color="red" oFlow="none">
                        {errors.authorized.message as string}
                    </Text>
                )}
                {errors.acceptLoan && (
                    <Text Color="red" oFlow="none">
                        {errors.acceptLoan.message as string}
                    </Text>
                )}
            </ContainerFlex>
            <Text Color="#54575C" FontSize="0.875rem" oFlow="none">
                {UPLOADDATA.VALIDATE}
            </Text>
            <ContainerFlex Justify="space-between">
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="700"
                    Width="12.313rem"
                    onClick={() => dispatch(ActualDataStepLoans(2))}
                    SecondaryButton="#FFFFFF"
                    FillIcon="#5A5AFF"
                    Cursor="pointer"
                >
                    <IconSvg Height="1.5rem" Width="1.5rem">
                        <path
                            d="M14.6 5.99994L16 7.39994L11.4 11.9999L16 16.5999L14.6 17.9999L8.6 11.9999L14.6 5.99994Z"
                            fill="#5A5AFF"
                        />
                    </IconSvg>
                    {'Paso anterior'}
                </ButtonGenerals>
                <ButtonGenerals
                    Height="2.5rem"
                    Padding="0.5rem 1rem"
                    Gap="0.5rem"
                    FontSize="1rem"
                    FontWeight="700"
                    type="submit"
                    Cursor="pointer"
                >
                    {'Validar identidad'}
                    <IconSvg Height="1.5rem" Width="1.5rem">
                        <path
                            d="M9.9 18.0314L8.5 16.6314L13.1 12.0314L8.5 7.43137L9.9 6.03137L15.9 12.0314L9.9 18.0314Z"
                            fill="white"
                        />
                    </IconSvg>
                </ButtonGenerals>
            </ContainerFlex>
            {postAddCheckIdentityLoading && <TransversalLoader />}
        </ContainerForm>
    );
};
