export const GET_ROLES = 'GET_ROLES';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const PUT_UPDATE_ROLE_STATUS = 'PUT_UPDATE_ROLE_STATUS';
export const PUT_UPDATE_ROLE_STATUS_SUCCESS = 'PUT_UPDATE_ROLE_STATUS_SUCCESS';
export const PUT_UPDATE_ROLE_STATUS_ERROR = 'PUT_UPDATE_ROLE_STATUS_ERROR';

export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const POST_DUPLICATE_ROLE = 'POST_DUPLICATE_ROLE';
export const POST_DUPLICATE_ROLE_SUCCESS = 'POST_DUPLICATE_ROLE_SUCCESS';
export const POST_DUPLICATE_ROLE_ERROR = 'POST_DUPLICATE_ROLE_ERROR';

export const GET_ROLE_USERS = 'GET_ROLE_USERS';
export const GET_ROLE_USERS_SUCCESS = 'GET_ROLE_USERS_SUCCESS';
export const GET_ROLE_USERS_ERROR = 'GET_ROLE_USERS_ERROR';

export const POST_NEW_ROLE = 'POST_NEW_ROLE';
export const POST_NEW_ROLE_SUCCESS = 'POST_NEW_ROLE_SUCCESS';
export const POST_NEW_ROLE_ERROR = 'POST_NEW_ROLE_ERROR';

export const GET_ALL_MODULES = 'GET_ALL_MODULES';
export const GET_ALL_MODULES_SUCCESS = 'GET_ALL_MODULES_SUCCESS';
export const GET_ALL_MODULES_ERROR = 'GET_ALL_MODULES_ERROR';

export const PUT_UPDATE_ROLE = 'PUT_UPDATE_ROLE';
export const PUT_UPDATE_ROLE_SUCCESS = 'PUT_UPDATE_ROLE_SUCCESS';
export const PUT_UPDATE_ROLE_ERROR = 'PUT_UPDATE_ROLE_ERROR';

export const GET_ROLE_BY_ID = 'GET_ROLE_BY_ID';
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS';
export const GET_ROLE_BY_ID_ERROR = 'GET_ROLE_BY_ID_ERROR';
export const GET_ROLE_BY_ID_RESET = 'GET_ROLE_BY_ID_RESET';

export const ROLE_ID_TO_EDIT = 'ROLE_ID_TO_EDIT';
