import React, {useState} from 'react';

import {Controller} from 'react-hook-form';

import {IClientProofStep} from '@components/Shopify/ListClient/interfaces';
import {CREATE_CLIENT_LABELS} from '@components/Shopify/ListClient/constants';
import * as style from '@Quoter/stylesConstants';
import {ContainerFlex, Text, TextArea} from '@Shopify/Ecommerce/styles';
import AdditionalInformationContact from '@/components/Shopify/ListClient/AdditionalInformationContact';
import {AddressLocations} from '@/components/LocationsBranch/AddressLocations';
import {AddressAdd} from '@/components/LocationsBranch/AddAddress/AddressAdd';

const AdditionalInformation = (props: IClientProofStep) => {
    const {register, errors, control, setValue} = props;
    const [modalAddress, setModalAddress] = useState(false);

    return (
        <ContainerFlex
            FlexDir="column"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1rem"
            Align="start"
            backG="#FFF"
            Width="1233px"
            Margin="0 auto"
        >
            <Text FontWeight="500" FontSize="1.25rem" Color="#2A2C2F">
                Información complementaria
            </Text>
            <ContainerFlex Padding=" 1.75rem 1rem" Radius="1rem" Align="start">
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1.5rem">
                    <AdditionalInformationContact {...props} />

                    <ContainerFlex Justify="space-between" Gap="1rem">
                        <AddressLocations
                            errors={errors}
                            setValue={setValue}
                            nameAddress={'address'}
                            setModalAddress={() => setModalAddress(true)}
                            onClientPage={true}
                        />
                    </ContainerFlex>

                    <ContainerFlex FlexDir="column" Align="start">
                        <Text {...style.level}>{CREATE_CLIENT_LABELS.NOTAS}</Text>
                        <Controller
                            name="notes"
                            control={control}
                            render={() => (
                                <TextArea
                                    Cursor="auto"
                                    rows={5}
                                    {...register('notes')}
                                    error={!!errors.notes?.message}
                                    onChange={(e) => {
                                        setValue('notes', e.target.value);
                                    }}
                                />
                            )}
                        />
                        <Text Color="red" FontWeight="400" FontSize="0.7rem">
                            {errors?.notes?.message as string}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            {modalAddress && (
                <AddressAdd modalAddress={modalAddress} setModalAddress={setModalAddress} />
            )}
        </ContainerFlex>
    );
};

export default AdditionalInformation;
