import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CREDIT_DETAILS_ERROR,
    GET_CREDIT_DETAILS_START,
    GET_CREDIT_DETAILS_SUCCESS,
    GET_CREDIT_REQUEST_DETAILS_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {IDetailsCreditReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: IDetailsCreditReducer = {
    creditRequestDetail: null,
    loading: false,
    error: false,
    creditDetail: null,
};

const getDetailsCredit = (
    state: IDetailsCreditReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): IDetailsCreditReducer => {
    switch (action.type) {
        case GET_CREDIT_DETAILS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CREDIT_REQUEST_DETAILS_SUCCESS:
            return {
                ...state,
                creditRequestDetail: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CREDIT_DETAILS_SUCCESS:
            return {
                ...state,
                creditDetail: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CREDIT_DETAILS_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getDetailsCredit;
