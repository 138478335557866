import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {initGroups} from '@/components/LocationsBranch/Redux/Action/LevelGroups';
import {setCurrentSelectedLevelId} from '@/components/LocationsBranch/Redux/Action/LevelGroups';

const useInitInheritGroups = (inheritType: string) => {
    const dispatch = useDispatch();
    const {currentBranch, selectedLevelId, companyId, token} = useSelector((state: RootState) => ({
        currentBranch: state.getCompanyLevels.data,
        selectedLevelId: state.getLevels.selectedLevelId,
        token: state.getUsersValidation.userData?.token,
        companyId: state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId,
    }));

    useEffect(() => {
        dispatch(initGroups(inheritType));
        const selectedLevel = {
            levelId: currentBranch.levelId,
            levelName: currentBranch.branchName,
            breadcrum: null,
        };
        dispatch(setCurrentSelectedLevelId(inheritType, selectedLevel));
    }, [dispatch, companyId, token, inheritType, selectedLevelId]);
};

export default useInitInheritGroups;
