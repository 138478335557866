import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {METHODPAYMENT} from '@/components/MyAccount/MyCredit/CreditDetails/Letter';
import iCard from '@images/billingblack.svg';
import {MethodPaymentCreditsProps} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import Accordion from './Accordion';
import bankIcon from '@/components/PersonalLoans/icons/bankIcon.svg';
import walletIcon from '@/components/PersonalLoans/icons/walletIcon.svg';
import unCheckIcon from '@/components/PersonalLoans/icons/unCheckIcon.svg';
import checkedIcon from '@/components/PersonalLoans/icons/checkedIcon.svg';
import CreditCardMethod from './CreditCardMethod';

export const MethodPaymentCredits: React.FC<MethodPaymentCreditsProps> = ({
    register,
    setValue,
    control,
    errors,
}) => {
    const [expandedPanel, setExpandedPanel] = useState<string | false>(false);
    const handleExpandAccordion =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpandedPanel(isExpanded ? panel : false);
        };
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            backG="#FFFFFF"
            Width="100%"
            Height="100%"
            Padding="1.5rem"
            Radius="1rem"
            Gap="1.5rem"
            Border="1px solid #E8E9EA"
        >
            <Text oFlow="none" wSpace="normal" Color="#2A2C2F" FontWeight="700">
                {METHODPAYMENT.TITLE}
            </Text>

            <Accordion
                summaryIcon={iCard}
                expandedIcon={checkedIcon}
                collapsedIcon={unCheckIcon}
                summaryTitle={METHODPAYMENT.CARD}
                expanded={expandedPanel === METHODPAYMENT.CARD}
                onChange={handleExpandAccordion(METHODPAYMENT.CARD)}
            >
                <CreditCardMethod
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    control={control}
                />
            </Accordion>
            <Accordion
                summaryIcon={bankIcon}
                expandedIcon={checkedIcon}
                collapsedIcon={unCheckIcon}
                summaryTitle={METHODPAYMENT.BANK}
                expanded={expandedPanel === METHODPAYMENT.BANK}
                onChange={handleExpandAccordion(METHODPAYMENT.BANK)}
            />
            <Accordion
                summaryIcon={walletIcon}
                expandedIcon={checkedIcon}
                collapsedIcon={unCheckIcon}
                summaryTitle={METHODPAYMENT.WALLET}
                expanded={expandedPanel === METHODPAYMENT.WALLET}
                onChange={handleExpandAccordion(METHODPAYMENT.WALLET)}
            />
        </ContainerFlex>
    );
};
