import React from 'react';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IStorageDataProps} from '@components/LocationsBranch/interfaces';
import {ActionMenu} from '@components/LocationsBranch/Menu';
import {STORAGE} from '@components/LocationsBranch/constants';

export const StorageData = ({
    storageType,
    onSelectStorageType,
    onClickEditName,
    warehouse,
    onEdit,
    onDuplicate,
    onDelete,
}: IStorageDataProps) => {
    const shouldShowActionMenu = warehouse && onEdit && onDuplicate && onDelete && !onClickEditName;
    const stopPropagation = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <ContainerFlex
            Cursor="pointer"
            Justify="start"
            Align="start"
            ColumnGap="0.5rem"
            HBackground="#f0f0ff"
            HBorderLeft="0.125rem solid #5a5aff"
            FlexDir="Column"
            Gap="0.5rem"
            Padding="0.5rem"
            Height={'2.5rem'}
            onClick={() => {
                if (onSelectStorageType) onSelectStorageType(storageType);
            }}
        >
            <ContainerFlex
                Justify="start"
                Align="start"
                ColumnGap="0.5rem"
                FlexDir="row"
                Gap="0.5rem"
                Padding="0 0.5rem"
                Display="grid"
                GridColumns="50% 35% 15%"
            >
                <ContainerFlex Justify="start">
                    <Text Color="#2A2C2F" FontWeight="500" Cursor="pointer" FontSize="1rem">
                        {storageType.name}
                    </Text>
                </ContainerFlex>
                <ContainerFlex FontSize="0.75rem">
                    <Text Color="#54575C" FontWeight="400" Cursor="pointer" FontSize="0.75rem">
                        {storageType.productQuantity} {STORAGE.PRODUCTS}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="end" PaddingR="1rem">
                    {shouldShowActionMenu && (
                        <div onClick={stopPropagation}>
                            <ActionMenu
                                warehouse={warehouse}
                                onEdit={onEdit}
                                onDuplicate={onDuplicate}
                                onDelete={onDelete}
                            />
                        </div>
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
