import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import uploadIcon from '@images/UploadFile.svg';
import checkMark from '@images/checkMark.svg';
import warningIcon from '@images/warning-red.svg';
import {clearDocumentsError} from '@Shopify/ClientProfile/Redux/Action/AddIdentificationDoc';
import {DOCUMENTS, UPLOAD_DOCUMENTS, formatFileSize} from '@Shopify/ClientProfile/constants';
import {IIdentityDocInputBoxProps} from '@components/Shopify/ClientProfile/interfaces';
import ImageInputBox from '@/components/General/Atoms/ImageInputBox/ImageInputBox';

const IdentityDocInputBox = ({onOpenSelection, error}: IIdentityDocInputBoxProps) => {
    const dispatch = useDispatch();
    const {imageData: addedImageDocs, message: idDocMessage} = useSelector(
        (state: RootState) => state.addIdentificationDoc
    );

    const existingClientIdDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.identityDocuments
    );

    const hasImages = (): boolean => {
        return (existingClientIdDocs && existingClientIdDocs.length > 0) ||
            (addedImageDocs && (addedImageDocs.frontImage || addedImageDocs.backImage))
            ? true
            : false;
    };

    const handleOpenSelection = () => {
        dispatch(clearDocumentsError());
        onOpenSelection(true);
    };

    const renderImageInputContent = () => {
        if (existingClientIdDocs && existingClientIdDocs.length > 0) {
            const front = existingClientIdDocs.find((doc) => !doc.isReverse && doc.nameInDocuments);
            const back = existingClientIdDocs.find((doc) => doc.isReverse && doc.nameInDocuments);
            if (front) {
                return (
                    <>
                        <Text FontWeight="500" FontSize="0.875rem" Color="#2A2C2F" oFlow="visible">
                            {`${front.typeOfDocuments} ${UPLOAD_DOCUMENTS.FRONT}`}
                            {back && ` | ${back.typeOfDocuments} ${UPLOAD_DOCUMENTS.BACK}`}
                        </Text>
                        <ContainerFlex Gap="0.25rem" Justify="flex-start">
                            <Image alt="checkmark" src={checkMark} Width="1rem" Height="1rem" />
                            <Text FontSize="0.75rem" FontWeight="400" Color="#54575C">
                                {front.size && `${formatFileSize(front.size)}`}
                                {back && ` | ${formatFileSize(back.size)}`}
                            </Text>
                        </ContainerFlex>
                    </>
                );
            }
        }

        if (addedImageDocs && (addedImageDocs.frontImage || addedImageDocs.backImage)) {
            return (
                <>
                    <Text FontWeight="500" FontSize="0.875rem" Color="#2A2C2F" oFlow="visible">
                        {addedImageDocs ? addedImageDocs.frontImage?.fileName : ''}
                        {addedImageDocs?.backImage?.fileName &&
                            ` | ${addedImageDocs.backImage.fileName}`}
                    </Text>
                    <ContainerFlex Gap="0.25rem" Justify="flex-start">
                        <Image alt="checkmark" src={checkMark} Width="1rem" Height="1rem" />
                        <Text FontSize="0.75rem" FontWeight="400" Color="#54575C">
                            {addedImageDocs ? `${addedImageDocs.frontImage?.fileSize || ''}` : ''}
                            {addedImageDocs?.backImage?.fileSize &&
                                ` | ${addedImageDocs.backImage.fileSize}`}
                        </Text>
                    </ContainerFlex>
                </>
            );
        }

        return (
            <ContainerFlex Gap="0.8rem" Cursor="inherit">
                <Image
                    src={uploadIcon}
                    Width="1.5rem"
                    Height="1.5rem"
                    ObjectFit="cover"
                    Cursor="inherit"
                />
                <Text FontSize="1rem" Color="#54575" Cursor="inherit">
                    {DOCUMENTS.ADD_IDENTITY_DOCUMENT}
                </Text>
            </ContainerFlex>
        );
    };

    useEffect(() => {
        renderImageInputContent();
    }, [addedImageDocs]);

    return (
        <ContainerFlex FlexDir="column" Gap="0.5rem" Align="flex-start">
            <ImageInputBox
                hasImages={hasImages()}
                onOpenSelection={handleOpenSelection}
                error={error}
            >
                {renderImageInputContent()}
            </ImageInputBox>
            <Text FontSize="0.875rem" FontWeight="400">
                {DOCUMENTS.MAX_SIZE_17}
                {idDocMessage && (
                    <Text FontSize="0.875rem" Color="#FF6357" FontWeight="600" MarginLeft="0.5rem">
                        <Image
                            src={warningIcon}
                            Width="1rem"
                            Height="1rem"
                            Padding="0 0.25rem"
                            ObjectFit="cover"
                        />
                        {DOCUMENTS.ERROR}: {idDocMessage}
                    </Text>
                )}
            </Text>
        </ContainerFlex>
    );
};

export default IdentityDocInputBox;
