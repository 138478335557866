import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, ContainerForm, Image} from '@/components/Shopify/Ecommerce/styles';
import closeIconCharges from '@components/PersonalLoans/NewLoanProduct/icons/closeIconCharges.svg';
import {AppDispatch, RootState} from '@/config/store';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {
    IChargeAndPenaltyItem,
    IChargesAndPenaltiesForm,
    IDynamicFieldsChargesProps,
    ISelectOption,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaChargesAndPenalties} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/schemaChargesAndPenalties';
import {
    addNewCharge,
    editCharge,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/ChargesAndPenaltiesTableActions';
import {ChargeTypeSelect} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/Fields/ChargeTypeSelect';
import {ChargeNameInput} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/Fields/ChargeNameInput';
import {AmountSelectInput} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/Fields/AmountSelectInput';
import {ConditionSelect} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/Fields/ConditionSelect';
import {EventTypeSelect} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/Fields/EventTypeSelect';
import {SubmitButton} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/DynamicFieldsCharges/SubmitButton';
import {getTypeEventCondition} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/GetTypeEventConditionActions';

export const DynamicFieldsCharges: React.FC<IDynamicFieldsChargesProps> = ({
    chargeState,
    setChargeState,
}) => {
    const selectedCharge: IChargeAndPenaltyItem = useSelector(
        (state: RootState) => state.setChargesAndPenaltiesTable.selectedCharge
    );
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token;
    });
    const {
        register,
        control,
        handleSubmit,
        formState: {errors},
        watch,
        reset,
    } = useForm<IChargesAndPenaltiesForm>({
        resolver: yupResolver(schemaChargesAndPenalties),
    });
    const dispatch: AppDispatch = useDispatch();
    const [eventTypeOptions, setEventTypeOptions] = useState<ISelectOption[]>([]);
    const [showEventType, setShowEventType] = useState<boolean>(false);
    const onSubmit = (data: IChargesAndPenaltiesForm) => {
        if (chargeState.isCreating) {
            dispatch(
                addNewCharge({
                    id: new Date().getTime(),
                    typeCharge: {typeChargeId: data.typeCharge.value, label: data.typeCharge.label},
                    chargeName: data.chargeName,
                    chargeAmount: {
                        typeChargeAmountId: data.typeChargeAmount.value,
                        amount: data.amount,
                        label: data.typeChargeAmount.label,
                    },
                    condition: {conditionId: data.condition.value, label: data.condition.label},
                    eventType: {eventTypeId: data.eventType.value, label: data.eventType.label},
                    isChecked: false,
                    isShowingEditButton: false,
                })
            );
        } else {
            dispatch(
                editCharge({
                    typeCharge: {typeChargeId: data.typeCharge.value, label: data.typeCharge.label},
                    chargeName: data.chargeName,
                    chargeAmount: {
                        typeChargeAmountId: data.typeChargeAmount.value,
                        amount: data.amount,
                        label: data.typeChargeAmount.label,
                    },
                    condition: {conditionId: data.condition.value, label: data.condition.label},
                    eventType: {eventTypeId: data.eventType.value, label: data.eventType.label},
                })
            );
        }
        setChargeState({
            ...chargeState,
            isCreating: true,
            showCreateCharge: !chargeState.showCreateCharge,
        });
    };
    const formState = watch();
    useEffect(() => {
        if (formState.condition !== undefined && token) {
            const params = {conditionId: formState.condition.value};
            dispatch(getTypeEventCondition(token, params));
            setShowEventType(true);
        }
    }, [formState.condition, token]);
    const eventTypes = useSelector((state: RootState) => state.getTypeEventConditionReducer.data);
    useEffect(() => {
        const newArrayOptions = eventTypes.map((item) => {
            return {label: item.nameEvent, value: item.eventId};
        });
        setEventTypeOptions(newArrayOptions);
    }, [eventTypes, formState.condition]);
    useEffect(() => {
        if (!chargeState.isCreating) {
            reset({
                typeCharge: {
                    value: selectedCharge.typeCharge.typeChargeId,
                    label: selectedCharge.typeCharge.label,
                },
                chargeName: selectedCharge.chargeName,
                condition: {
                    value: selectedCharge.condition.conditionId,
                    label: selectedCharge.condition.label,
                },
                typeChargeAmount: {
                    value: selectedCharge.chargeAmount.typeChargeAmountId,
                    label: selectedCharge.chargeAmount.label,
                },
                amount: selectedCharge.chargeAmount.amount,
                eventType: {
                    value: selectedCharge.eventType.eventTypeId,
                    label: selectedCharge.eventType.label,
                },
            });
        }
    }, []);
    return (
        <>
            <ContainerForm
                Padding="0px 1rem 1rem 1rem"
                Border="1px solid #5A5AFF"
                background="#FFF"
                Radius="1rem"
                Height="max-content"
                FlexDir="column"
                Position="relative"
            >
                <ContainerFlex Justify="end" Height="24px" Position="relative" PositionTop="8px">
                    <Image
                        alt="close-icon"
                        src={closeIconCharges}
                        Width="24px"
                        Height="24px"
                        Cursor="pointer"
                        onClick={() => {
                            setChargeState({
                                ...chargeState,
                                showCreateCharge: !chargeState.showCreateCharge,
                                isCreating: true,
                            });
                        }}
                    />
                </ContainerFlex>
                <ContainerFlex Gap="1rem" FlexDir="column">
                    <GridContainer Gap="1rem" GridColumns="repeat(3, 1fr)">
                        <ChargeTypeSelect control={control} errors={errors} />
                        <ChargeNameInput register={register} errors={errors} />
                        <AmountSelectInput control={control} errors={errors} watch={watch} />
                    </GridContainer>
                    <GridContainer GridColumns="repeat(3, 1fr)" Gap="1rem">
                        <ConditionSelect control={control} errors={errors} />
                        {showEventType ? (
                            <EventTypeSelect
                                control={control}
                                errors={errors}
                                eventTypeOptions={eventTypeOptions}
                            />
                        ) : (
                            <ContainerFlex />
                        )}

                        <ContainerFlex Align="end" Justify="end">
                            <SubmitButton handleClick={handleSubmit(onSubmit)} />
                        </ContainerFlex>
                    </GridContainer>
                </ContainerFlex>
            </ContainerForm>
        </>
    );
};
