import React, {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {ShoppingCart} from '@components/ShoppingCart/index';
import {ResumePayment} from '@Loan/PaymentQuoter/ResumePayment';
import {routers} from '@/appRoute';

export const CarSelector = () => {
    const location = useLocation();
    const isPathActive = (...paths: string[]) => paths.includes(location.pathname);
    const ScreenCatch = () => {
        let ViewCart: ReactNode;
        if (!isPathActive(routers.PaymentQuoter, routers.PaymentQuoterConfirmation))
            ViewCart = <ShoppingCart />;

        if (location.pathname === routers.PaymentQuoter) ViewCart = <ResumePayment />;
        if (
            isPathActive(
                routers.PaymentQuoterConfirmation,
                routers.ClientAddNew,
                routers.CashFlow,
                routers.ProductDetailsContainer,
                routers.ExpenseDetails,
                routers.ExpenseDetailsCreate,
                routers.CashFlow
            )
        )
            ViewCart = <></>;
        return ViewCart;
    };

    return <>{ScreenCatch()}</>;
};
