import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IDetailsEditFormProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {DetailsForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Details/DetailsForm/DetailsForm';

export const DetailsEditForm: React.FC<IDetailsEditFormProps> = ({
    detailsRef,
    setIsEditing,
    isEditing,
}) => {
    return (
        <ContainerFlex
            backG="#FFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1.5rem"
            Gap="1.5rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Height="max-content"
            ref={detailsRef}
        >
            <ContainerFlex Gap="0.5rem" FlexDir="column" Align="start" Justify="start">
                <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20">
                    {LOAN_DETAILS.TITLE}
                </Text>
                <Text Color="#2A2C2F">{LOAN_DETAILS.DESCRIPTION}</Text>
            </ContainerFlex>
            <DetailsForm setIsEditing={setIsEditing} isEditing={isEditing} />
        </ContainerFlex>
    );
};
