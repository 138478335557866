import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    POST_CREATE_PROVIDER,
    POST_CREATE_PROVIDER_SUCCESS,
    POST_CREATE_PROVIDER_ERROR,
} from '@components/CashFlow/Expenses/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {ISubmitProviderData} from '@components/CashFlow/Expenses/interfaces';

const PostCreateProvider = () => {
    return {
        type: POST_CREATE_PROVIDER,
    };
};

const PostCreateProviderSuccess = (result: AxiosResponse) => {
    return {
        type: POST_CREATE_PROVIDER_SUCCESS,
        payload: result,
    };
};

const PostCreateProviderError = (error: AxiosError) => {
    return {
        type: POST_CREATE_PROVIDER_ERROR,
        error: error,
    };
};

export function PostCreateProviderAction(
    data: ISubmitProviderData,
    token: string,
    onClose: () => void
) {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(PostCreateProvider());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PostCreateProvider}`;
        try {
            const response = await axios.post(url, data, {headers});
            dispatch(PostCreateProviderSuccess(response));
            onClose();
        } catch (error) {
            dispatch(PostCreateProviderError(error as AxiosError));
        }
    };
}
