import {
    IDetailLoansCarCredit,
    IShoppingCarClientReducer,
    ISelectedPaymentMethod,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {
    SHOPPING_CAR_CLIENT_ERROR,
    SHOPPING_CAR_CLIENT_START,
    SHOPPING_CAR_CLIENT_SUCCESS,
    SHOW_MODAL,
    CLOSE_MODAL,
    GET_DETAILS_SHOPPING_CAR_SUCCESS,
    ADD_CREDIT_SELECTED,
    REMOVE_CREDIT_SELECTED,
    UNSELECT_CREDITS_SELECTED,
    SHOW_WARNING_MESSAGE,
    ADD_SELECTED_PAYMENT_METHOD,
    REMOVE_SELECTED_PAYMENT_METHOD,
    CREDIT_PAYMENT_PROCESS_SUCCESS,
    SHOW_MODAL_TO_FALSE,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {AxiosResponse} from 'axios';

const initialState: IShoppingCarClientReducer = {
    detailsLoansCarCredits: null,
    loansCarCreditsSelected: [],
    subTotal: 0,
    loading: false,
    error: false,
    mustBeUpdate: true,
    showWarningMessage: false,
    showModal: false,
    selectedPaymentMethod: null,
    showResume: false,
    creditPaymentProcessData: null,
};

const shoppingCarClient = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
        credit: IDetailLoansCarCredit;
        selectedPaymentMethod: ISelectedPaymentMethod;
    }
): IShoppingCarClientReducer => {
    switch (action.type) {
        case SHOPPING_CAR_CLIENT_START:
            return {
                ...state,
                loading: true,
                showResume: false,
            };
        case GET_DETAILS_SHOPPING_CAR_SUCCESS:
            return {
                ...state,
                loading: false,
                detailsLoansCarCredits: action.payload.data.detailLoansCarCredits,
                mustBeUpdate: false,
            };
        case SHOPPING_CAR_CLIENT_SUCCESS:
            return {
                ...state,
                loading: false,
                mustBeUpdate: true,
            };
        case SHOPPING_CAR_CLIENT_ERROR:
            return {
                ...initialState,
                error: true,
            };
        case SHOW_MODAL:
            return {
                ...state,
                showModal: true,
            };
        case CLOSE_MODAL:
            return {
                ...state,
                showModal: false,
            };
        case ADD_CREDIT_SELECTED:
            return handleAddPersonalCredit(state, action.credit);
        case REMOVE_CREDIT_SELECTED:
            return handleRemovePersonalCredit(state, action.credit);
        case UNSELECT_CREDITS_SELECTED:
            return {
                ...state,
                loansCarCreditsSelected: [],
                subTotal: 0,
            };
        case SHOW_WARNING_MESSAGE:
            return {
                ...initialState,
                showWarningMessage: true,
            };
        case ADD_SELECTED_PAYMENT_METHOD:
            return {
                ...state,
                selectedPaymentMethod: action.selectedPaymentMethod,
            };
        case REMOVE_SELECTED_PAYMENT_METHOD:
            return {
                ...state,
                selectedPaymentMethod: null,
            };
        case CREDIT_PAYMENT_PROCESS_SUCCESS:
            return {
                ...state,
                creditPaymentProcessData: action.payload.data,
                loansCarCreditsSelected: [],
                subTotal: 0,
                showResume: true,
                mustBeUpdate: true,
            };
        case SHOW_MODAL_TO_FALSE:
            return {
                ...state,
                showModal: false,
            };
        default:
            return state;
    }
};

const handleAddPersonalCredit = (
    state: IShoppingCarClientReducer,
    personalCredit: IDetailLoansCarCredit
): IShoppingCarClientReducer => {
    const {loansCarCreditsSelected} = state;
    if (
        loansCarCreditsSelected.some(
            (creditSelected) => creditSelected.paymentCreditId === personalCredit.paymentCreditId
        )
    )
        return state;

    const newCreditsSelected = [...loansCarCreditsSelected, personalCredit];
    const newState: IShoppingCarClientReducer = {
        ...state,
        loansCarCreditsSelected: newCreditsSelected,
        subTotal: calculateNewTotals(newCreditsSelected),
        showWarningMessage: false,
    };
    return newState;
};

const handleRemovePersonalCredit = (
    state: IShoppingCarClientReducer,
    personalCredit: IDetailLoansCarCredit
): IShoppingCarClientReducer => {
    const {loansCarCreditsSelected} = state;
    const newCreditsSelected = loansCarCreditsSelected.filter(
        (creditSelected) => creditSelected.paymentCreditId !== personalCredit.paymentCreditId
    );
    const newState: IShoppingCarClientReducer = {
        ...state,
        loansCarCreditsSelected: newCreditsSelected,
        subTotal: calculateNewTotals(newCreditsSelected),
    };
    return newState;
};

const calculateNewTotals = (personalCreditsSelected: IDetailLoansCarCredit[]): number => {
    return personalCreditsSelected.reduce((subtotal, credit) => subtotal + credit.amountQuote, 0);
};

export default shoppingCarClient;
