import React from 'react';

import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import documentIcon from '@images/documentIconDark.svg';
import refreshIcon from '@images/icon-refresh.svg';
import {ImageInputBoxProps} from '@General/Atoms/ImageInputBox/interfaces';
import {DOCUMENTS} from '@Shopify/ClientProfile/constants';

const ImageInputBox: React.FC<ImageInputBoxProps> = ({
    hasImages,
    onOpenSelection,
    error,
    children,
}) => {
    return (
        <ContainerFlex
            Gap="1rem"
            Height="3.5rem"
            Border={!error ? '1px solid #E8E9EA' : '1px solid #ef4f55'}
            Radius="1rem"
            PaddingR="1rem"
        >
            <ContainerFlex
                Padding="1rem"
                Justify="flex-start"
                Align="center"
                backG="#FAFAFA"
                BottomLeftRad="1rem"
                TopLeftRad="1rem"
                Height="3.438rem"
                Width="3.5rem"
                Self="center"
            >
                <Image
                    alt="document"
                    src={documentIcon}
                    Width="1.5rem"
                    Height="1.5rem"
                    ObjectFit="cover"
                />
            </ContainerFlex>
            <ContainerFlex
                Padding="0.5rem 0 0.25rem 0"
                FlexDir="column"
                Align="flex-start"
                Gap="0.25rem"
                Flex="1 0 0"
                Self="stretch"
                OverFlowX="scroll"
                OverFlowY="hidden"
                HeightScroll="0.3rem"
                Cursor={!hasImages ? 'pointer' : 'default'}
                onClick={() => {
                    if (!hasImages) {
                        onOpenSelection();
                    }
                }}
            >
                {children}
            </ContainerFlex>
            {hasImages && (
                <ContainerFlex
                    Width="6.096rem"
                    Align="center"
                    Justify="center"
                    Gap="0.25rem"
                    Cursor="pointer"
                    onClick={() => {
                        onOpenSelection();
                    }}
                >
                    <Image src={refreshIcon} Width="1.5rem" Height="1.5rem" />
                    <Text
                        FontWeight="700"
                        FontSize="0.875rem"
                        Color="#5A5AFF"
                        TextAlign="center"
                        Cursor="inherit"
                    >
                        {DOCUMENTS.REPLACE}
                    </Text>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default ImageInputBox;
