import React from 'react';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '@config/store';

import {ContainerFlex} from '@EcommerceShopify/styles';
import {SearchInput} from '@Customer/Styles';
import {IconContainer} from '@/components/Quoter/styles';
import {actualPageCustomer, searchCustomers} from '@Quoter/Redux/Actions/SearchClientActions';
import {ClientDefaultPrompts} from '@components/Shopify/ListClient/constants';
import {clientCard} from '@/components/Shopify/ListClient/redux/interfaces';

export function FilterListClient(props: {AllClientsHome: clientCard}) {
    const {AllClientsHome} = props;
    const dispatch: AppDispatch = useDispatch();

    const CloseSearch = () => {
        dispatch(actualPageCustomer(1));
    };

    return (
        <>
            <ContainerFlex Align="center" Gap="16px" Justify="flex-start" Flex="0 0 45%">
                <SearchInput Display="flex" Height="40px" Width="100%" Position="none">
                    <input
                        value={AllClientsHome.searchCustomer}
                        onChange={(e) => {
                            dispatch(searchCustomers(e.target.value));
                            CloseSearch();
                        }}
                        type="textfield"
                        placeholder={ClientDefaultPrompts.SearchPlaceHolder}
                        autoFocus
                    />
                    {AllClientsHome.searchCustomer ? (
                        <IconContainer
                            onClick={() => {
                                dispatch(searchCustomers(''));
                                CloseSearch();
                            }}
                            JustifySelf="end"
                            FontSize="1.5rem"
                            Color="#6d6e71"
                            className="material-icons"
                            Cursor="pointer"
                        >
                            close
                        </IconContainer>
                    ) : (
                        <IconContainer FontSize="1.5rem" className="material-icons" Color="#5A5AFF">
                            search
                        </IconContainer>
                    )}
                </SearchInput>
            </ContainerFlex>
        </>
    );
}
