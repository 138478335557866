import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useForm} from 'react-hook-form';
import {RootState, AppDispatch} from '@config/store';

import {DeadlineGeneral, productsChecked} from '@CreateDeadline/interfaces';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {FormContainer} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/InitialInformation/styles';
import {
    TableRowHeadContract,
    TableRowItemsContract,
} from '@/components/DeadLines/CreateDeadline/DeadlineSteps/ChangeOfProducts/styles';
import {Table, TableItem, TableItems, TableItemsContainer} from '@DeadLines/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {TitleDeadline} from '@CreateDeadline/DeadlineSteps/TitleDealine';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {changeProduct, setStep} from '@CreateDeadline/redux/actions/DeadlinesGeneral';
import {
    getProductsSimilar,
    fetchGetProductsCheck,
} from '@CreateDeadline/redux/actions/ProductsExchangeAction';

export const ChangeOfProducts = (props: DeadlineGeneral) => {
    const productsExchange: productsChecked = useSelector((state: RootState) => {
        return state.ProductsExchange;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const [zeroProducts, setZeroProducts] = useState(false);
    const [selectProducts, setSelectProducts] = useState<number[]>([]);
    const dispatch: AppDispatch = useDispatch();
    const {deadlineById, deadlinesGeneral} = props;
    const {handleSubmit} = useForm({
        defaultValues: {
            allowedTermID: 0,
        },
    });

    const handleContinue = () => {
        if (
            deadlinesGeneral.possibleProductChanges.length > 0 ||
            (deadlinesGeneral.promotionDiscount.length > 0 && deadlinesGeneral.haveDiscount) ||
            !deadlinesGeneral.haveDiscount
        ) {
            dispatch(setStep(6));
        }
    };

    const onSelectRequest = (item: number) => {
        if (selectProducts?.some((data: number) => data === item)) {
            setSelectProducts(selectProducts?.filter((data: number) => data !== item));
        } else {
            setSelectProducts([item, ...selectProducts]);
        }
    };

    const onSubmit = () => {
        const ProductId = selectProducts.map((item: number) => {
            return item;
        });
        dispatch(changeProduct(ProductId));
        dispatch(setStep(7));

        if (selectProducts.length === 0) {
            setZeroProducts(true);
        }
    };
    const handleOnChange = (position: number) => {
        const updatedCheckedState = productsExchange?.productsCheck?.map(
            (item: number, index: number) => (index === position ? !item : item)
        );
        dispatch(fetchGetProductsCheck(updatedCheckedState));
    };

    useEffect(() => {
        if (deadlinesGeneral.initialInformation.companyId && token) {
            dispatch(
                getProductsSimilar(token, {
                    companyId: deadlinesGeneral.initialInformation.companyId,
                    catalogLevelType: deadlinesGeneral.initialInformation.catalogLevelType,
                    catalogLevel: deadlinesGeneral.initialInformation.catalogLevel,
                })
            );
        }
    }, [deadlinesGeneral.initialInformation.catalogLevel]);

    useEffect(() => {
        if (
            deadlineById.deadlineId !== null &&
            (deadlineById.edit || deadlineById.copy) &&
            deadlinesGeneral.possibleProductChanges.length === 0
        ) {
            const json = deadlineById.deadlineId.data.possibleProductChanges.map(
                (item: {productNumber: number}) => {
                    return item.productNumber;
                }
            );
            dispatch(changeProduct(json));
            setSelectProducts(json);
        }

        if (
            deadlinesGeneral.possibleProductChanges.length > 0 &&
            (!deadlineById.edit || !deadlineById.copy)
        ) {
            setSelectProducts(deadlinesGeneral.possibleProductChanges);
        }
    }, []);

    return (
        <ContainerFlex
            FlexDir="column"
            Align="flex-start"
            MarginBt="1rem"
            Radius="5px"
            Border="1px solid var(--gray-4)"
            Cursor="pointer"
            onClick={() => {
                handleContinue();
            }}
        >
            <TitleDeadline
                title={'Cambio de productos'}
                step={deadlinesGeneral.step}
                stepCurrent={6}
                stepDependence={0}
                dependence={
                    deadlinesGeneral.possibleProductChanges.length > 0 ||
                    zeroProducts ||
                    deadlineById.edit ||
                    deadlineById.copy
                }
            />
            {deadlinesGeneral.step === 6 && (
                <ContainerFlex Padding="1rem 2.5rem" smFlexWrap="wrap" FlexDir="column">
                    <FormContainer
                        Width="80%"
                        MarginTp="0"
                        Justify="center"
                        autoComplete="off"
                        Height=""
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <ContainerFlex FlexDir="column">
                            <ContainerFlex Justify="flex-start" MarginBt="1rem">
                                <Text Align="flex-start">
                                    Seleccione a que productos prendarios puede migrar este producto
                                </Text>
                            </ContainerFlex>

                            <ContainerFlex Align="center">
                                <Table>
                                    <TableRowHeadContract GridColumn=".4fr 1.5fr 1.5fr 1fr">
                                        <TableItem>{''}</TableItem>
                                        <TableItem Justify="center" FontSize="0.875rem">
                                            No. productos vigentes
                                        </TableItem>
                                        <TableItem Justify="center" FontSize="0.875rem">
                                            Nombre producto
                                        </TableItem>
                                        <TableItem Justify="center" FontSize="0.875rem">
                                            Plazo
                                        </TableItem>
                                    </TableRowHeadContract>
                                    <TableItemsContainer Height="200px" OverY="auto">
                                        {productsExchange.ProductsExchange.data &&
                                            productsExchange.ProductsExchange.data.map(
                                                (
                                                    item: {
                                                        productNumber: number;
                                                        nameTerm: string;
                                                        periodName: string;
                                                    },
                                                    index: number
                                                ) => {
                                                    return (
                                                        <TableRowItemsContract
                                                            GridColumn=".4fr 1.5fr 1.5fr 1fr"
                                                            key={index}
                                                        >
                                                            <TableItems
                                                                InputHeight="16px"
                                                                Justify="center"
                                                            >
                                                                <Checkbox
                                                                    checked={selectProducts.some(
                                                                        (value) =>
                                                                            value ===
                                                                            item.productNumber
                                                                    )}
                                                                    onChange={() => {
                                                                        if (!deadlineById.edit) {
                                                                            onSelectRequest(
                                                                                item.productNumber
                                                                            );
                                                                            handleOnChange(index);
                                                                        }
                                                                    }}
                                                                />
                                                            </TableItems>
                                                            <TableItems
                                                                Width="100%"
                                                                Justify="center"
                                                            >
                                                                {item.productNumber}
                                                            </TableItems>
                                                            <TableItems
                                                                Width="100%"
                                                                Justify="center"
                                                            >
                                                                {item.nameTerm}
                                                            </TableItems>
                                                            <TableItems
                                                                Width="100%"
                                                                Justify="center"
                                                            >
                                                                {item.periodName}
                                                            </TableItems>
                                                        </TableRowItemsContract>
                                                    );
                                                }
                                            )}
                                    </TableItemsContainer>
                                </Table>
                            </ContainerFlex>
                        </ContainerFlex>
                        {!deadlineById?.edit && (
                            <ButtonGeneral FontSize="0.875rem" hColor="#35cf44" text="Continuar" />
                        )}
                    </FormContainer>
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};
