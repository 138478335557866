import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import arrow from '@/images/chevronRight.svg';
import Harrow from '@/images/arrowCherry.svg';
import lastPg from '@/images/lastPage.svg';
import HlastPg from '@/images/NexPageHover.svg';
import {ImageLogo} from '@/components/LoginClient/styles';

const Paginate = ({setCurrentPage, currentPage, totalPages, totalItems}) => {
    const page = Number(currentPage) || 0;
    const total = Number(totalPages) || 0;
    const items = Number(totalItems) || 0;
    const [isHoverFirst, setIsHoverFirst] = useState(false);
    const [isHoverLast, setIsHoverLast] = useState(false);
    const [isHoverFirstOne, setIsHoverFirstOne] = useState(false);
    const [isHoverLastOne, setIsHoverLastOne] = useState(false);

    return (
        <ContainerFlex Justify="center">
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                HBackG="rgba(240, 242, 245, 0.50)"
                Color="#fff"
                Cursor="pointer"
                onClick={() => setCurrentPage(1)}
                disabled={page <= 1}
                onMouseEnter={() => setIsHoverFirst(true)}
                onMouseLeave={() => setIsHoverFirst(false)}
            >
                <ImageLogo
                    src={isHoverFirst ? HlastPg : lastPg}
                    alt="lastpage-icon"
                    Width="15px"
                    Height="15px"
                    Transform={isHoverFirst ? 'rotate(180deg)' : ''}
                />
            </ButtonGenerals>
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                HBackG="rgba(240, 242, 245, 0.50)"
                onClick={() => setCurrentPage(page - 1)}
                disabled={page <= 1}
                onMouseEnter={() => setIsHoverFirstOne(true)}
                onMouseLeave={() => setIsHoverFirstOne(false)}
            >
                <ImageLogo
                    src={isHoverFirstOne ? Harrow : arrow}
                    alt="arrow-icon"
                    Width="15px"
                    Height="15px"
                    Transform="rotate(180deg)"
                />
            </ButtonGenerals>

            <Text FontSize="1rem" Padding="0 20px">
                {total > 0 ? `${page}-${total} de ${items} ` : 'No hay resultados'}
            </Text>
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                DBackG="transparent"
                HBackG="rgba(240, 242, 245, 0.50)"
                onClick={() => setCurrentPage(page + 1)}
                disabled={page >= total}
                onMouseEnter={() => setIsHoverLastOne(true)}
                onMouseLeave={() => setIsHoverLastOne(false)}
            >
                <ImageLogo
                    src={isHoverLastOne ? Harrow : arrow}
                    alt="arrow-next"
                    Width="15px"
                    Height="15px"
                />
            </ButtonGenerals>
            <ButtonGenerals
                Width="10px"
                BackGC="transparent"
                HBackG="rgba(240, 242, 245, 0.50)"
                DBackG="transparent"
                onClick={() => setCurrentPage(total)}
                disabled={page >= total}
                onMouseEnter={() => setIsHoverLast(true)}
                onMouseLeave={() => setIsHoverLast(false)}
            >
                <ImageLogo
                    src={isHoverLast ? HlastPg : lastPg}
                    alt="lastpage-next"
                    Width="15px"
                    Height="15px"
                    Transform={isHoverLast ? '' : 'rotate(180deg)'}
                />
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default Paginate;
