import React, {useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import * as styles from '@/components/Quoter/stylesConstants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import filterImage from '@/images/filter.svg';
import {SEARCH_FILTER} from '@/components/MyAccount/constants';
import {DropDownMenu} from '@/components/General/MenuAmazon/ReutilizableComponent/FloatMenu';
import FilterModal from '@/components/MyAccount/MyCredit/FilterModal';

const SearchAndFilterSubMenu = () => {
    const [openFilterModal, setOpenFilterModal] = useState(false);
    return (
        <ContainerFlex Gap="16px" Width="800px" Position="relative">
            <SearchInput Display="flex" FontSize="1rem" Right="0" Width="280px">
                <input type="text" autoComplete="off" placeholder={SEARCH_FILTER.PLACEHOLDER} />
                <Icons {...styles.iconSearch}>search</Icons>
            </SearchInput>
            <ButtonGenerals
                BackGC="transparent"
                Height="24px"
                Padding="0"
                HBackG="transparent"
                Radius="0"
                onClick={() => setOpenFilterModal(!openFilterModal)}
                Cursor="pointer"
            >
                <img src={filterImage} alt="filter icon" />
                <Text Color="#5A5AFF" FontFamily="Nunito" FontWeight="700" Cursor="pointer">
                    {SEARCH_FILTER.BTN_TEXT}
                </Text>
            </ButtonGenerals>
            {openFilterModal && (
                <DropDownMenu
                    OutSideClick={() => setOpenFilterModal(false)}
                    MenuDisplayStatus={openFilterModal}
                    Values={[]}
                    Valued={<FilterModal setOpenFilterModal={setOpenFilterModal} />}
                    Bottom="-250px"
                    Right="-100px"
                    Width="831px"
                    Height="240px"
                    Position="absolute"
                    BoxShadow="none"
                    BorderRadius="16px"
                />
            )}
        </ContainerFlex>
    );
};

export default SearchAndFilterSubMenu;
