import React from 'react';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex} from '@/components/Shopify/Ecommerce/styles';
import bentobox from '@/images/bentobox.svg';
import bentoboxAlt from '@/images/bentoboxAlt.svg';
import list from '@/images/list.svg';
import listAlt from '@/images/listAlt.svg';
import {VIEWS} from '@/components/MyAccount/constants';

const ViewFormat = () => {
    const [aciveView, setActiveView] = React.useState(VIEWS.GRID);
    const gridIcon = aciveView === VIEWS.GRID ? bentobox : bentoboxAlt;
    const listIcon = aciveView === VIEWS.LIST ? listAlt : list;

    const gridButtonStyles = {
        padding: '12px 16px',
        borderRadius: '8px 0px 0px 8px',
        backgroundColor: aciveView === VIEWS.GRID ? '#F0F0FF' : '#FFFFFF',
        borderColor: aciveView === VIEWS.GRID ? '#5A5AFF' : '#E8E9EA',
        height: '48px',
        width: '49px',
        hoverBackgroundColor: aciveView === VIEWS.GRID ? '#F0F0FF' : '#FFFFFF',
    };

    const listButtonStyles = {
        padding: '12px 16px',
        borderRadius: '0px 8px 8px 0px',
        backgroundColor: aciveView === VIEWS.LIST ? '#F0F0FF' : '#FFFFFF',
        borderColor: aciveView === VIEWS.LIST ? '#5A5AFF' : '#E8E9EA',
        borderLeft: aciveView === VIEWS.LIST ? '1px solid #5A5AFF' : 'none',
        height: '48px',
        width: '49px',
        hoverBackgroundColor: aciveView === VIEWS.LIST ? '#F0F0FF' : '#FFFFFF',
    };

    return (
        <ContainerFlex Width="98px">
            <ButtonGenerals
                Radius={gridButtonStyles.borderRadius}
                BackGC={gridButtonStyles.backgroundColor}
                Padding={gridButtonStyles.padding}
                Border={`1px solid ${gridButtonStyles.borderColor}`}
                Height={gridButtonStyles.height}
                Width={gridButtonStyles.width}
                HBackG={gridButtonStyles.hoverBackgroundColor}
                onClick={() => setActiveView(VIEWS.GRID)}
                Cursor="pointer"
            >
                <img src={gridIcon} alt="grid view icon" />
            </ButtonGenerals>
            <ButtonGenerals
                Radius={listButtonStyles.borderRadius}
                BackGC={listButtonStyles.backgroundColor}
                Padding={listButtonStyles.padding}
                Border={`1px solid ${listButtonStyles.borderColor}`}
                BorderL={listButtonStyles.borderLeft}
                Height={listButtonStyles.height}
                Width={listButtonStyles.width}
                HBackG={aciveView === VIEWS.LIST ? '#F0F0FF' : '#FFFFFF'}
                onClick={() => setActiveView(VIEWS.LIST)}
                Cursor="pointer"
            >
                <img src={listIcon} alt="grid view icon" />
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default ViewFormat;
