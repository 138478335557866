import React from 'react';

import * as style from '@Shopify/ClientProfile/slylesClient';
import {QUICK_ACTIONS} from '@Shopify/ClientProfile/constants';
import {IGarmentImagesProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';

const GarmentImages: React.FC<IGarmentImagesProps> = ({images}) => (
    <ContainerFlex {...style.sectionImg}>
        <Text Color="#2A2C2F" FontSize="0.875rem">
            {QUICK_ACTIONS.GARMENTS}
        </Text>
        <ContainerFlex {...style.tabsImg}>
            {images.map((img, index) => (
                <ContainerFlex key={index} {...style.contentImg}>
                    <Image
                        src={img.image}
                        height="47rem"
                        width="47rem"
                        alt="product"
                        ObjectFit="contain"
                        Radius="0.5rem"
                    />
                </ContainerFlex>
            ))}
        </ContainerFlex>
    </ContainerFlex>
);

export default GarmentImages;
