export const GET_CLIENT_LIST = 'GET_CLIENT_LIST';
export const GET_CLIENT_LIST_SUCCESS = 'GET_CLIENT_LIST_SUCCESS';
export const GET_CLIENT_LIST_ERROR = 'GET_CLIENT_LIST_ERROR';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const ERROR_CLIENT = 'ERROR_CLIENT';
export const RESET_INFO_STADISTICS_EDIT = 'RESET_INFO_STADISTICS_EDIT';

export const RESET_ONLY_CLIENT = 'RESET_ONLY_CLIENT';

export const GET_CART_CONTRACTS = 'GET_CART_CONTRACTS';
export const GET_CART_CONTRACTS_SUCCESS = 'GET_CART_CONTRACTS_SUCCESS';
export const GET_CART_CONTRACTS_ERROR = 'GET_CART_CONTRACTS_ERROR';

export const DELETE_CART_CONTRATS = 'DELETE_CART_CONTRACTS';

export const PUT_CONTRACTS = 'PUT_CONTRACTS';
export const PUT_CONTRACTS_SUCCESS = 'PUT_CONTRACTS_SUCCESS';
export const PUT_CONTRACTS_ERROR = 'PUT_CONTRACTS_ERROR';
export const ADD_CLIENT_LIST = 'ADD_CLIENT_LIST';
export const ADD_CLIENT_LIST_SUCCESS = 'ADD_CLIENT_LIST_SUCCESS';
export const ADD_CLIENT_LIST_ERROR = 'ADD_CLIENT_LIST_ERROR';
export const DELETE_TEMPORALY_PAWN = 'DELETE_TEMPORALY_PAWN';
export const DELETE_TEMPORALY_PAWN_SUCCESS = 'DELETE_TEMPORALY_PAWN_SUCCESS';
export const DELETE_TEMPORALY_PAWN_ERROR = 'DELETE_TEMPORALY_PAWN_ERROR';

export const GET_INFO_CONTRACT = 'GET_INFO_CONTRACT';
export const GET_INFO_CONTRACT_SUCCESS = 'GET_INFO_CONTRACT_SUCCESS';
export const GET_INFO_CONTRACT_ERROR = 'GET_INFO_CONTRACT_ERROR';
export const EDIT_INFO = 'EDIT_INFO';
export const CANCEL_EDIT_INFO = 'CANCEL_EDIT_INFO';

export const DELETE_ITEM_PLEDGE = 'DELETE_ITEM_PLEDGE';
export const DELETE_ITEM_PLEDGE_SUCCESS = 'DELETE_ITEM_PLEDGE_SUCCESS';
export const DELETE_ITEM_PLEDGE_ERROR = 'DELETE_ITEM_PLEDGE_ERROR';

export const FETCH_CART_BY_ID = {
    FETCH_CART_START: 'FETCH_CART_START',
    FETCH_CART_SUCCESS: 'FETCH_CART_SUCCESS',
    FETCH_CART_ERROR: 'FETCH_CART_ERROR',
    REMOVE_EXTENSION: 'REMOVE_EXTENSION',
    SET_REFRESH: 'SET_REFRESH',
};

export const DELETE_DISCHARGE_ENDORSEMENT = {
    START: 'DELETE_DISCHARGE_ENDORSEMENT_START',
    SUCCESS: 'DELETE_DISCHARGE_ENDORSEMENT_SUCCESS',
    ERROR: 'DELETE_DISCHARGE_ENDORSEMENT_ERROR',
};

export const ADD_EXTENSION = {
    ADD_EXTENSION_START: 'ADD_EXTENSION_START',
    ADD_EXTENSION_SUCCESS: 'ADD_EXTENSION_SUCCESS',
    ADD_EXTENSION_ERROR: 'ADD_EXTENSION_ERROR',
};

export const TOGGLE_VIEW_MENU = 'TOGGLE_VIEW_MENU';
export const TOGGLE_VIEW_INSTALLMENT_MENU = 'TOGGLE_VIEW_INSTALLMENT_MENU';
export const SET_PAYMENT_ADDED = 'SET_PAYMENT_ADDED';
export const SET_PAYMENT_DELETED = 'SET_PAYMENT_DELETED';

export const FETCH_UPDATE_ENDORSEMENTS_CART = {
    FETCH_UPDATE_ENDORSEMENTS_CART_START: 'FETCH_UPDATE_ENDORSEMENTS_CART_START',
    FETCH_UPDATE_ENDORSEMENTS_CART_SUCCESS: 'FETCH_UPDATE_ENDORSEMENTS_CART_SUCCESS',
    FETCH_UPDATE_ENDORSEMENTS_CART_ERROR: 'FETCH_UPDATE_ENDORSEMENTS_CART_ERROR',
    CLEAR_ERROR: 'ENDORSEMENTS_CART_CLEAR_ERROR',
    ADD_OPTIONS: 'ADD_ENDORSEMENT_OPTIONS',
};

export const DELETE_EXTENSION = {
    START: 'DELETE_EXTENSION_START',
    SUCCESS: 'DELETE_EXTENSION_SUCCESS',
    ERROR: 'DELETE_EXTENSION_ERROR',
};

export const START_SHARE_TICKETS_RESUME = 'START_SHARE_TICKETS_RESUME';
export const SUCCESS_SHARE_TICKETS_RESUME = 'SUCCESS_SHARE_TICKETS_RESUME';
export const ERROR_SHARE_TICKETS_RESUME = 'ERROR_SHARE_TICKETS_RESUME';

export const CART_STATE = {
    SET_IS_CART_OPEN: 'SET_IS_CART_OPEN',
    SET_DOCKED: 'SET_DOCKED',
};
