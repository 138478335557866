import * as yup from 'yup';
import {TEXT_YUP} from './constants';

const regexAlphanumeric = /^[A-Za-z0-9ñÑáéíóúÁÉÍÓÚ ]+$/;

export const InfoUser = yup.object().shape({
    name: yup
        .string()
        .required(TEXT_YUP.NAME_REQUIRED)
        .min(2, TEXT_YUP.NAME_MIN)
        .matches(regexAlphanumeric, TEXT_YUP.NAME_MATCH),

    parental_surnames: yup
        .string()
        .required(TEXT_YUP.LAST_NAME_REQUIRED)
        .min(2, TEXT_YUP.LAST_NAME_MIN)
        .matches(regexAlphanumeric, TEXT_YUP.LAST_NAME_MATCH),

    maternal_surname: yup
        .string()
        .required(TEXT_YUP.LAST_NAME_REQUIRED)
        .min(2, TEXT_YUP.LAST_NAME_MIN)
        .matches(regexAlphanumeric, TEXT_YUP.LAST_NAME_MATCH),

    birthdate: yup.string().required(TEXT_YUP.BIRTHDATE_REQUIRED),

    phone: yup
        .object()
        .shape({
            dialCode: yup.string().required(TEXT_YUP.PHONE_DIAL_REQUIRED),
            number: yup
                .string()
                .required(TEXT_YUP.PHONE_REQUIRED)
                .matches(/^\d{10}$/, TEXT_YUP.PHONE_FORMAT),
        })
        .required(TEXT_YUP.PHONE_OBJECT_REQUIRED),

    email: yup
        .string()
        .required(TEXT_YUP.EMAIL_REQUIRED)
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, TEXT_YUP.EMAIL_FORMAT),

    cp: yup
        .string()
        .required(TEXT_YUP.CP_REQUIRED)
        .matches(/^\d{5}$/, TEXT_YUP.CP_FORMAT),

    street: yup
        .string()
        .required(TEXT_YUP.STREET_REQUIRED)
        .min(3, TEXT_YUP.STREET_MIN)
        .matches(regexAlphanumeric, TEXT_YUP.STREET_MATCH),

    externalNumber: yup
        .string()
        .required(TEXT_YUP.EXTERNAL_NUMBER_REQUIRED)
        .max(5, TEXT_YUP.EXTERNAL_NUMBER_MAX),

    internalNumber: yup.string().optional(),

    colony: yup.object().shape({
        value: yup.string().required(TEXT_YUP.COLONY_REQUIRED),
        label: yup.string().required(TEXT_YUP.COLONY_REQUIRED),
    }),

    municipality: yup.object().shape({
        value: yup.string().required(TEXT_YUP.MUNICIPALITY_REQUIRED),
        label: yup.string().required(TEXT_YUP.MUNICIPALITY_REQUIRED),
    }),
    state: yup.object().shape({
        value: yup.string().required(TEXT_YUP.MUNICIPALITY_REQUIRED),
        label: yup.string().required(TEXT_YUP.MUNICIPALITY_REQUIRED),
    }),
    country: yup.object().shape({
        value: yup.string(),
        label: yup.string(),
    }),

    password: yup.string().required(TEXT_YUP.PASSWORD_REQUIRED),

    repeatPassword: yup.string().required(TEXT_YUP.REPEAT_PASSWORD_REQUIRED),
    numberPeriods: yup
        .string()
        .required(TEXT_YUP.NEW_PASSWORD_DAY_REQUIRED)
        .matches(/^[0-9]{2}$/, TEXT_YUP.NEW_PASSWORD_VALID_NUMBER),
    catPasswordUpdateId: yup.object().shape({
        value: yup.number(),
        label: yup.string().required(TEXT_YUP.NEW_PASSWORD_DAY_TIME),
    }),
});
