import {useDispatch, useSelector} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {AppDispatch, RootState} from '@/config/store';
import {ContainerFlex, ContainerForm, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    IDisbursementEditingFormProps,
    IDisbursementsForm,
} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/disbursementInterfaces';
import {DISBURSEMENT, VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {EditingButtonsForm} from '@components/PersonalLoans/NewLoanProduct/ui/EditingButtonsForm';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {WarningError} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/WarningError';
import {yupResolver} from '@hookform/resolvers/yup';
import {schemaDisbursementsForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Disbursement/schemaDisbursementsForm';
import {saveNewLoanSubmittedData} from '@components/PersonalLoans/NewLoanProduct/Redux/Actions/NewLoanSubmittedDataActions';
import {IDisbursements} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';

export const DisbursementEditingForm: React.FC<IDisbursementEditingFormProps> = ({
    disbursementsRef,
    isEditing,
    setIsEditing,
}) => {
    const {
        reset,
        trigger,
        control,
        formState: {errors},
        handleSubmit,
    } = useForm<IDisbursementsForm>({
        resolver: yupResolver(schemaDisbursementsForm),
    });
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData);
    const disbursementData = useSelector(
        (state: RootState) => state.newLoanSubmittedData.details.disbursements
    );
    const dispatch: AppDispatch = useDispatch();
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [disbursementItems, setDisbursementItems] = useState<IDisbursements[]>(disbursementData);
    const handleCheckboxChange = (disbursementItemId: number) => {
        const newDisbursementItemsArray = disbursementItems.map((item) => {
            if (item.disbursementId === disbursementItemId) {
                return {...item, isChecked: !item.isChecked};
            }
            return item;
        });
        setDisbursementItems(newDisbursementItemsArray);
    };
    const onSubmit = (data: IDisbursementsForm) => {
        dispatch(
            saveNewLoanSubmittedData({
                ...newLoanData,
                details: {...newLoanData.details, disbursements: data.disbursements},
            })
        );
        setIsEditing(!isEditing);
    };
    const errorMessage = errors.disbursements?.message || '';
    useEffect(() => {
        reset({disbursements: disbursementData});
    }, []);
    return (
        <>
            <ContainerFlex
                Justify="start"
                Align="start"
                FlexDir="column"
                Gap="1.5rem"
                Radius="1.5rem"
                Padding="1.25rem"
                Border="1px solid #E8E9EA"
                ref={disbursementsRef}
            >
                <ContainerFlex FlexDir="column" Gap="0.5rem" Height="max-content" Align="start">
                    <Text oFlow="normal" Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                        {DISBURSEMENT.TITLE}
                    </Text>
                    <Text oFlow="normal" Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                        {DISBURSEMENT.SUB}
                    </Text>
                </ContainerFlex>
                {errorMessage && (
                    <WarningError
                        errorMessage={
                            errorMessage.toString() || VALIDATIONS_MSG.DISBURSEMENT.AT_LEAST_ONE
                        }
                    />
                )}
                <ContainerForm
                    Justify="start"
                    Align="start"
                    FlexDir="column"
                    Gap="1.5rem"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    {disbursementItems.map((item, index) => (
                        <ContainerFlex
                            key={item.disbursementId}
                            Justify="start"
                            Align="start"
                            Gap="8px"
                            Height="45px"
                        >
                            <Controller
                                name={`disbursements.${index}.isChecked`}
                                control={control}
                                render={({field: {onChange, ...field}}) => (
                                    <Checkbox
                                        {...field}
                                        checked={item.isChecked}
                                        onChange={(e) => {
                                            onChange(e);
                                            handleCheckboxChange(item.disbursementId);
                                            if (isSubmitted) trigger('disbursements');
                                        }}
                                    />
                                )}
                            />
                            <ContainerFlex Align="start" FlexDir="column">
                                <Text Color="#2A2C2F">{item.disbursementName}</Text>
                                <Text Color="#54575C" FontSize="0.875rem">
                                    {item.disbursementDetails}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    ))}
                    <EditingButtonsForm
                        onCancel={() => setIsEditing(!isEditing)}
                        onContinue={() => setIsSubmitted(!isSubmitted)}
                    />
                </ContainerForm>
            </ContainerFlex>
        </>
    );
};
