import {
    ILevel,
    IRow,
    IStorage,
    IStorageFloor,
    IWarehouse,
} from '@components/LocationsBranch/interfaces';
import {IInventories} from '@components/LocationsBranch/Redux/interfaces';
import {STORAGE_STATUS} from '@components/LocationsBranch/constants';

const reverseTransformRows = (rows: IRow[]): IInventories[] => {
    return rows.map(
        (row): IInventories => ({
            storageId: row.from === STORAGE_STATUS.OLD ? row.id : 0,
            storageName: row.name ?? '',
            numberArticles: `${row.productQuantity} items`,
            inventories: [],
            storageDescrption: row.name ?? '',
            parentStorageId: row.parentLevel ?? 0,
        })
    );
};

const reverseTransformLevels = (levels: ILevel[]): IInventories[] => {
    return levels.map(
        (level): IInventories => ({
            storageId: level.from === STORAGE_STATUS.OLD ? level.id : 0,
            storageName: level.name ?? '',
            numberArticles: `${level.productQuantity} items`,
            inventories: reverseTransformRows(level.rows),
            storageDescrption: level.name ?? '',
            parentStorageId: level.parentLevel ?? 0,
        })
    );
};

const reverseTransformFloors = (
    floors: IStorageFloor[],
    parentStorageId: number
): IInventories[] => {
    return floors.map(
        (floor): IInventories => ({
            storageId: floor.from === STORAGE_STATUS.OLD ? floor.id ?? 0 : 0,
            storageName: floor.name ?? '',
            numberArticles: `${floor.productQuantity} items`,
            inventories: reverseTransformLevels(floor.levels ?? []),
            storageDescrption: floor.name ?? '',
            parentStorageId,
        })
    );
};

const reverseTransformStorageTypes = (storages: IStorage[]): IInventories[] => {
    return storages.map(
        (storage): IInventories => ({
            storageId: storage.from === STORAGE_STATUS.OLD ? storage.id ?? 0 : 0,
            storageName: storage.name ?? '',
            numberArticles: `${storage.productQuantity} Articles`,
            inventories: reverseTransformFloors(storage.storageFloor, storage.id ?? 0),
            storageDescrption: storage.name ?? '',
            parentStorageId: storage.parentLevel ?? 0,
        })
    );
};

export const reverseTransformData = (data: IWarehouse[]): IInventories[] => {
    return data.map(
        (item): IInventories => ({
            storageId: item.from === STORAGE_STATUS.OLD ? item.id : 0,
            storageName: item.name,
            numberArticles: `${(item.storageTypes ?? []).reduce(
                (acc, storage) => acc + (storage.productQuantity ?? 0),
                0
            )} Articles`,
            inventories: reverseTransformStorageTypes(item.storageTypes ?? []),
            storageDescrption: item.name,
            parentStorageId: 0,
        })
    );
};
