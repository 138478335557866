import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_ROLE_BY_COMPANY_ERROR,
    GET_ROLE_BY_COMPANY_START,
    GET_ROLE_BY_COMPANY_SUCCESS,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {URL} from '@config/constants';
import {AppDispatch} from '@/config/store';
import {IGetRolByCompanyParams} from '@components/NewUserEmployee/Redux/interfaces';
export const getRolByCompanyStart = () => {
    return {
        type: GET_ROLE_BY_COMPANY_START,
    };
};
export const getRolByCompanySuccess = (result: AxiosResponse) => {
    return {
        type: GET_ROLE_BY_COMPANY_SUCCESS,
        payload: result.data,
    };
};
export const getRolByCompanyError = (error: AxiosError) => {
    return {
        type: GET_ROLE_BY_COMPANY_ERROR,
        error,
    };
};
export const getRolByCompany = (token: string, params: IGetRolByCompanyParams) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getRolByCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetRolByCompany}`,
                {headers, params}
            );
            dispatch(getRolByCompanySuccess(response));
        } catch (error) {
            dispatch(getRolByCompanyError(error as AxiosError));
        }
    };
};
