import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ICarrouselCard} from '@components/GlobalQuoter/interface';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {CARROUSEL_CARD} from '@components/GlobalQuoter/TempConstants';

const CarrouselCard = ({loanData}: ICarrouselCard) => {
    return (
        <ContainerFlex
            FlexDir="column"
            Width="318px"
            BoxShadow="0px 4px 16px 0px rgba(0, 0, 0, 0.02)"
            backG="#FFFFFF"
            Justify="flex-start"
            Align="flex-start"
            Radius="16px"
            MinW="318px"
        >
            <ContainerFlex
                Justify="space-between"
                Align="center"
                Padding="8px 16px"
                backG="#FAFAFA"
                Radius="16px 16px 0 0"
            >
                <Image src={loanData.logo} alt={loanData.alt} Width="77px" Height="24px" />
                <ButtonGenerals
                    Height="32px"
                    FontSize="0.875rem"
                    FontWeight="700"
                    Radius="32px"
                    Border="none"
                    HBackG="none"
                    BackGC="#F0F0FF"
                    Color="#5A5AFF"
                    Padding="4px 16px"
                    Cursor="pointer"
                >
                    {CARROUSEL_CARD.LABELS.REQUEST}
                </ButtonGenerals>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" Padding="16px">
                <ContainerFlex FlexDir="column" Justify="flex-start" Align="flex-start" Gap="4px">
                    <Text FontFamily="Nunito" FontSize="0.875rem" Color="#54575C">
                        {CARROUSEL_CARD.LABELS.LOAN_AMOUNT}
                    </Text>
                    <Text FontSize="1.25rem" Color="#1D1E20" FontWeight="700">
                        {numberToCurrencyWithoutCents(loanData.amount)}
                    </Text>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="flex-start"
                    Align="flex-start"
                    Gap="8px"
                    Width="67%"
                >
                    <Text FontFamily="Nunito" FontSize="0.875rem" Color="#54575C">
                        {loanData.term} {CARROUSEL_CARD.LABELS.LOAN_TERM}
                    </Text>
                    <Text FontFamily="Nunito" FontSize="0.875rem" Color="#54575C">
                        {loanData.rate}% {CARROUSEL_CARD.LABELS.LOAN_RATE}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CarrouselCard;
