import React from 'react';
import {
    Option,
    Img,
    ScrollableOptionsContainer,
    Imge,
    renderstyles,
    rendercolumn,
    StyleTextrendercolumn,
    Stylerendercolumn,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/style';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import arrow from '@images/ArrowLeftIcone.svg';
import topArrow from '@images/iconeTopArrow.svg';
import {BRANCH_ASSIGNMENT} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/Constants';
import {
    BranchAssignmentTreeProps,
    BranchOption,
} from '@/components/Users/UserProfile/UserProfileBranchAssignment/Operational/typeInterface';
import {useBranchTree} from '@/components/Users/UserProfile/UserProfileBranchAssignment/BranchLogicTree';

const Tree = ({
    checkedOptions,
    setCheckedOptions,
}: {
    checkedOptions: Set<number>;
    setCheckedOptions: React.Dispatch<React.SetStateAction<Set<number>>>;
}) => {
    const {
        columns,
        selectedOptions,
        handleSelect,
        handleCheckboxChange,
        isParentChecked,
        setColumns,
        setSelectedOptions,
    } = useBranchTree(checkedOptions, setCheckedOptions);

    const renderOption = (option: BranchOption, columnIndex: number) => {
        const hasSubItems =
            (option.nextLevel && option.nextLevel.length > 0) ||
            (option.branches && option.branches.length > 0);
        const optionId = `${option.idLevel}-${option.idBranch || ''}`;
        const isChecked = checkedOptions.has(option.idBranch ?? 0) || isParentChecked(option);

        return (
            <Option
                key={optionId}
                isSelected={
                    selectedOptions[columnIndex]?.idLevel === option.idLevel &&
                    selectedOptions[columnIndex]?.idBranch === option.idBranch
                }
            >
                <ContainerCellGrid Padding="8px">
                    <ContainerFlex Gap="8px">
                        <Checkbox
                            checked={isChecked}
                            onChange={() => handleCheckboxChange(option)}
                        />
                        <ContainerFlex
                            Cursor="pointer"
                            Align="start"
                            FlexDir="column"
                            onClick={() => handleSelect(option, columnIndex)}
                        >
                            <Text
                                Cursor="pointer"
                                onClick={() => handleSelect(option, columnIndex)}
                            >
                                {option.levelName || option.branchName}
                            </Text>
                            {hasSubItems && (
                                <Text Color="#71767D" FontSize="12px">
                                    {`${
                                        (option.nextLevel?.length || 0) +
                                        (option.branches?.length || 0)
                                    } ${BRANCH_ASSIGNMENT.ELEMENT}`}
                                </Text>
                            )}
                            {!hasSubItems && option.isBranch && (
                                <Text Color="#71767D" FontSize="12px">
                                    {BRANCH_ASSIGNMENT.IS_BRANCH}
                                </Text>
                            )}
                        </ContainerFlex>
                        {hasSubItems && <Imge src={topArrow} alt="icono-topArrow" />}
                    </ContainerFlex>
                </ContainerCellGrid>
            </Option>
        );
    };

    const renderColumn = (options: BranchOption[], columnIndex: number) => (
        <ContainerFlex Radius="1rem" Width="100%" Justify="start" Align="start" backG="#FFFFFF">
            <ContainerFlex {...Stylerendercolumn} key={columnIndex}>
                <Text FontWeight="700" FontSize="14px">
                    {columnIndex === 0
                        ? options[0]?.levelName
                        : selectedOptions[columnIndex - 1]?.levelName ||
                          selectedOptions[columnIndex - 1]?.branchName}
                </Text>
                <ContainerFlex Width="auto" Justify="start" Gap="8px" Padding="8px">
                    <Img height="24px" src={arrow} alt="icone-Arrow" />
                    <Text
                        {...StyleTextrendercolumn}
                        onClick={() => {
                            if (columnIndex > 0) {
                                setColumns(columns.slice(0, columnIndex));
                                setSelectedOptions(selectedOptions.slice(0, columnIndex));
                            }
                        }}
                    >
                        {BRANCH_ASSIGNMENT.TYPE_ASSIGNMENT}
                    </Text>
                </ContainerFlex>
                <ScrollableOptionsContainer>
                    {options.map((option) => renderOption(option, columnIndex))}
                </ScrollableOptionsContainer>
            </ContainerFlex>
        </ContainerFlex>
    );

    const renderLayout = () => {
        let columnCount = 0;

        const rowElements = columns.reduce(
            (acc: JSX.Element[][], columnOptions, index) => {
                if (columnCount && columnCount % 4 === 0) {
                    const emptyNeeded = 4 - acc[acc.length - 1].length;
                    acc[acc.length - 1] = [
                        ...acc[acc.length - 1],
                        ...[...Array(emptyNeeded)].map((_, i) => (
                            <ContainerFlex
                                key={`empty-${acc.length}-${i}`}
                                Width="100%"
                                Height="100%"
                            />
                        )),
                    ];
                    acc = [...acc, []];
                }

                acc[acc.length - 1] = [
                    ...acc[acc.length - 1],
                    <ContainerFlex key={`col-${index}`} {...rendercolumn}>
                        {renderColumn(columnOptions, index)}
                    </ContainerFlex>,
                ];
                columnCount++;
                return acc;
            },
            [[]]
        );

        const lastRow = rowElements[rowElements.length - 1];
        const emptyNeeded = 4 - lastRow.length;

        rowElements[rowElements.length - 1] = [
            ...lastRow,
            ...[...Array(emptyNeeded)].map((_, i) => (
                <ContainerFlex key={`empty-last-${i}`} Width="100%" Height="100%" />
            )),
        ];

        return (
            <ContainerFlex FlexDir="column" Gap="8px" Width="100%">
                {rowElements.map((row, rowIndex) => (
                    <ContainerFlex key={`row-${rowIndex}`} {...renderstyles}>
                        {row}
                    </ContainerFlex>
                ))}
            </ContainerFlex>
        );
    };

    return (
        <ContainerFlex FlexDir="column" Width="100%">
            {renderLayout()}
        </ContainerFlex>
    );
};

export const BranchAssignmentTree: React.FC<BranchAssignmentTreeProps> = ({
    checkedOptions,
    setCheckedOptions,
}) => {
    return (
        <ContainerFlex Justify="start" Width="100%">
            <Tree checkedOptions={checkedOptions} setCheckedOptions={setCheckedOptions} />
        </ContainerFlex>
    );
};

export default BranchAssignmentTree;
