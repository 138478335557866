import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    DELETE_ROLE,
    DELETE_ROLE_ERROR,
    DELETE_ROLE_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const deleteRole = () => {
    return {
        type: DELETE_ROLE,
    };
};

export const deleteRoleSuccess = (result: AxiosResponse) => {
    return {
        type: DELETE_ROLE_SUCCESS,
        payload: result,
    };
};

export const deleteRoleError = (error: AxiosError) => {
    return {
        type: DELETE_ROLE_ERROR,
        payload: error,
    };
};

export const deleteRoleAction = (id: number, navigate: (route: string) => void, token: string) => {
    return async (dispatch: AppDispatch) => {
        dispatch(deleteRole());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeleteRole}`;
        try {
            const response = await axios.delete(url, {
                data: {roleId: id},
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            });
            dispatch(deleteRoleSuccess(response));
            navigate('/RolesAndPermissions');
        } catch (error) {
            dispatch(deleteRoleError(error as AxiosError));
        }
    };
};

export default deleteRoleAction;
