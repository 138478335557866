import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {
    HOLIDAYS,
    INHERIT_TYPE,
    isOptional,
    LAST_LOCATION,
    NEWSTORAGE,
} from '@components/LocationsBranch/constants';
import ButtonGeneral from '@components/General/Atoms/Button';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate';
import {UserGroup} from '@components/LocationsBranch/UserGroup';
import * as style from '@components/LocationsBranch/stylesLocations';
import {NameLocation} from '@components/LocationsBranch/NameLocation';
import {AddUserGroupModal} from '@components/LocationsBranch/AddUser';
import {
    EditForm,
    IAddStorage,
    INewStorageProps,
    User,
} from '@components/LocationsBranch/interfaces';
import {RoutesLocations} from '@components/LocationsBranch/RoutesLocations';
import {LocationStorage} from '@components/LocationsBranch/LocationStorage';
import {ContainerFlex, Text, ContainerForm} from '@Shopify/Ecommerce/styles';
import {AddressLocations} from '@components/LocationsBranch/AddressLocations';
import {
    getStorage,
    newStorage,
    updateStorage,
} from '@components/LocationsBranch/Redux/Action/NewStorage';
import {newStorageShema} from '@components/LocationsBranch/createLocationsErrors';
import {DelUserLocations} from '@components/LocationsBranch/DelUser/DelUsersLocations';
import {useInitializeForm} from '@components/LocationsBranch/utils/useFormInitialization';
import {getUsersByLocation} from '@components/LocationsBranch/Redux/Action/GetUsersByLocationId';
import {fetchStorageByLocationId} from './Redux/Action/GetStorageByLocationId';
import {getLocationValues} from '@components/LocationsBranch/utils/GetLocationValues';
import {
    SelectedLevel,
    IInventories,
    UpdateStorage,
} from '@components/LocationsBranch/Redux/interfaces';
import {routers} from '@/appRoute';
import {reverseTransformData} from '@components/LocationsBranch/utils/TransformStorageData';
import {getUserIds} from '@components/LocationsBranch/utils/GetUserIds';

export const NewStorage = ({setModalAddress, storageId}: INewStorageProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState({addUser: false, locations: false});

    const {storages, addStorage, token, inventory, levels, selectedLevelId, userList} = useSelector(
        (state: RootState) => ({
            storages: state.getCreateGroupBranch,
            levels: state.getLevels.level,
            selectedLevelId: state.getLevels.selectedLevelId,
            token: String(state.getUsersValidation.userData?.token),
            addStorage: state.newStorage,
            inventory: state.Warehouse.warehouses,
            AddUserEmployee: state.AddUserEmployee,
            getCompanyLevels: state.getCompanyLevels,
            userList: state.GetUserByLevelId.allUserData,
        })
    );

    const {
        watch,
        reset,
        register,
        setValue,
        handleSubmit,
        formState: {errors},
    } = useForm<FieldValues>({
        defaultValues: {
            nameStorage: '',
            storageDirection: {},
            users: [],
            storages: [],
        },
        resolver: yupResolver(newStorageShema),
    });

    const {initialize} = useInitializeForm();
    const isEditing = storageId !== undefined;

    const parseToUpdateStorage = (inventory: IInventories[]): UpdateStorage[] => {
        return inventory.map((item) => ({
            id: item.storageId,
            nameStorage: item.storageName,
            description: item.storageDescrption,
            storageDetail: parseToUpdateStorage(item.inventories),
        }));
    };

    const onsubmit = (values: FieldValues) => {
        const {nameStorage, storageDirection} = values;
        const storageUsers = getUserIds(userList);
        const commonBody = {
            storageDirection: {
                ...storageDirection,
                colonyId: storageDirection.colony,
            },
            ...isOptional('storageDetail', parseToUpdateStorage(reverseTransformData(inventory))),
        };

        const createBody = {
            ...commonBody,
            levelId: levels?.levelId,
        };

        const actionBody: IAddStorage = storageId
            ? {...commonBody, storageId, storageName: nameStorage, storageUsersP: storageUsers}
            : {...createBody, nameStorage, storageUsers};

        const action = storageId
            ? updateStorage(token, navigate, actionBody)
            : newStorage(token, navigate, actionBody);

        dispatch(action);
    };

    const cancelOperation = async () => {
        navigate(routers.LocationBranch);
    };

    const getEditStorage = () => {
        if (!selectedLevelId || !storageId) return;

        const body = {
            LevelId: selectedLevelId,
            StorageId: storageId,
        };
        dispatch(getStorage(token, body));
    };

    const initializeStorage = () => {
        const {
            storageName = '',
            storageDirection = null,
            description = '',
            storagesDetail = null,
            userStorage = [],
        } = addStorage.editStorage || {};

        const source: EditForm = {
            name: storageName,
            levelId: levels.levelId,
            description,
            storagesDetail,
            users: userStorage,
            address: storageDirection,
        };
        initialize(source, NEWSTORAGE.NAME_FORM, setValue);
    };

    const onSetUsers = (users: User[]) => {
        const source: EditForm = {
            users,
        };
        initialize(source, '', setValue);
    };

    const onSetStorage = (storagesDetail: IInventories[]) => {
        if (storagesDetail) {
            const source: EditForm = {
                storagesDetail,
            };
            initialize(source, '', setValue);
        }
    };

    const onClickTransfer = async (transferSelection: SelectedLevel, type: string) => {
        const data = getLocationValues(transferSelection);

        const transferActions: Record<string, () => Promise<void>> = {
            [INHERIT_TYPE.USER]: async () => {
                dispatch(getUsersByLocation(data, token, onSetUsers));
            },
            [INHERIT_TYPE.LOCATION]: async () => {
                await dispatch(fetchStorageByLocationId(data, token, onSetStorage));
            },
        };

        const action = transferActions[type];
        if (action) {
            await action();
        }
    };

    useEffect(() => {
        if (!selectedLevelId || !storageId) return;
        getEditStorage();
    }, [storageId]);

    useEffect(() => {
        if (addStorage.editStorage && storageId) {
            initializeStorage();
        }
    }, [addStorage.editStorage]);

    return (
        <>
            <ContainerForm {...style.contentForm} onSubmit={handleSubmit(onsubmit)}>
                {storages.loading ? (
                    <ContainerFlex>
                        <LoadingAtaskate />
                    </ContainerFlex>
                ) : (
                    <>
                        <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="700">
                            {storageId ? addStorage.editStorage?.storageName : NEWSTORAGE.TITLE}
                        </Text>
                        <NameLocation
                            newName={addStorage}
                            nameLocation={NEWSTORAGE.NAME_FORM}
                            register={register}
                            errors={errors}
                            watch={watch}
                            reset={reset}
                        />
                        <RoutesLocations lastLevel={LAST_LOCATION.NEW_STORAGE} />
                        <AddressLocations
                            errors={errors}
                            setValue={setValue}
                            nameAddress={NEWSTORAGE.ADDRESS_FORM}
                            setModalAddress={setModalAddress}
                        />
                        <UserGroup
                            modal={modal}
                            setModal={setModal}
                            setValue={setValue}
                            onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.USER)}
                        />
                        <LocationStorage
                            isOptional={true}
                            isEdit={isEditing}
                            onClickTransfer={(e) => onClickTransfer(e, INHERIT_TYPE.LOCATION)}
                            errors={errors}
                        />
                        {addStorage.loading ? (
                            <ContainerFlex Height="2.5rem">
                                <LoadingAtaskate />
                            </ContainerFlex>
                        ) : (
                            <ContainerFlex Gap="1.5rem" Height="2.5rem">
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.CANCEL}
                                    clic={() => cancelOperation()}
                                    color="#5A5AFF"
                                    backGround="#FFFFFF"
                                    hText="#5A5AFF"
                                    hShadow=""
                                    height="2.5rem"
                                    cursor="pointer"
                                    border="1px solid #5A5AFF"
                                    hBackG="#E5E5FF"
                                />
                                <ButtonGeneral
                                    width="6.25rem"
                                    text={HOLIDAYS.SAVE}
                                    type="submit"
                                    height="2.5rem"
                                    cursor="pointer"
                                />
                            </ContainerFlex>
                        )}
                    </>
                )}
            </ContainerForm>
            {modal.locations && <DelUserLocations modal={modal} setModal={setModal} />}
            {modal.addUser && <AddUserGroupModal modal={modal} setModal={setModal} />}
        </>
    );
};
