import {useSelector} from 'react-redux';
import {Controller} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    IConditionSelectProps,
    ISelectOption,
} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';
import {RootState} from '@/config/store';

export const ConditionSelect: React.FC<IConditionSelectProps> = ({control, errors}) => {
    const conditionTypes = useSelector(
        (state: RootState) => state.getCatConditionsTermsReducer.data
    );
    const [conditionSelectOptions, setConditionSelectOptions] = useState<ISelectOption[]>([]);
    useEffect(() => {
        const newConditionSelectArray = conditionTypes.map((item) => {
            return {label: item.conditionName, value: item.conditionId};
        });
        setConditionSelectOptions(newConditionSelectArray);
    }, [conditionTypes]);
    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="0.5rem">
            <Text Color="#1D1E20" FontWeight="700">
                {CHARGES_AND_PENALTIES.FIELDS_TITLES.CONDITION}
            </Text>
            <Controller
                name="condition"
                control={control}
                render={({field}) => (
                    <Select
                        {...field}
                        options={conditionSelectOptions}
                        noOptionsMessage={() => 'Sin opciones'}
                        isSearchable={false}
                        placeholder="Selecciona"
                        controlTop=""
                        ControlBottom="2px"
                        HeigthOptions="max-content"
                    />
                )}
            />
            {errors.condition && <ErrorMessage message={errors.condition.value?.message || ''} />}
        </ContainerFlex>
    );
};
