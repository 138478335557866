import React from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {ICreditDetailProfileProps} from '@components/CreditsAndPawns/interfaces';
import {
    CREDIT_DETAIL_PROFILE,
    TABLE_STATUS_FILTER_VALUES,
} from '@/components/CreditsAndPawns/constants';
import CreditDetailContract from '@/components/CreditsAndPawns/CreditDetail/CreditDetailContract';
import PrinterIcon from '@/components/CreditsAndPawns/icons/printIcon.svg';
import userIcon from '@/components/CreditsAndPawns/icons/userIcon.svg';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';

const CreditDetailProfile: React.FC<ICreditDetailProfileProps> = ({
    clientName,
    fotoPerfilUrl,
    contractNumber,
    applicationDate,
    lastUpdatedDate,
    status,
    applicationForm,
    totalPaidQuotas,
    totalQuotas,
    paymentFrequency,
    contractType,
    amountPaid,
    amountOwed,
    statusId,
    creditAmount,
}) => {
    const contractInfo = {
        contractNumber,
        status,
        paymentFrequency,
        contractType,
        statusId,
        applicationDate,
        lastUpdatedDate,
        totalPaidQuotas,
        totalQuotas,
        applicationForm,
    };
    return (
        <ContainerFlex
            FlexDir="column"
            Align="start"
            Justify="space-between"
            Width="400px"
            MaxH="600px"
            Radius="16px"
            Border="1px solid #E8E9EA"
            backG="#FFFFFF"
            Gap="16px"
            Padding="24px"
        >
            <ContainerFlex Gap="16px" Justify="start" Align="center" Flex="0">
                <ContainerFlex
                    Padding="10px"
                    Height="32px"
                    Width="32px"
                    backG="#FAFAFF"
                    Radius="100px"
                    Flex="0"
                    Align="center"
                    Justify="center"
                >
                    <Image
                        Height="18px"
                        Width="18px"
                        src={fotoPerfilUrl ?? userIcon}
                        alt={CREDIT_DETAIL_PROFILE.ALT.USER}
                    />
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="4px">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="0.88rem" Color="#2A2C2F">
                        {CREDIT_DETAIL_PROFILE.CLIENT}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.0rem" Color="#5A5AFF">
                        {clientName}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <ContainerFlex FlexDir="column" Gap="8px" Flex="0">
                <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="8px">
                    <Text FontFamily="Nunito" FontWeight="400" FontSize="1.0rem" Color="#2A2C2F">
                        {CREDIT_DETAIL_PROFILE.CREDIT_VALUE}
                    </Text>
                    <Text FontFamily="Nunito" FontWeight="700" FontSize="1.75rem" Color="#0D166B">
                        {numberToCurrencyWithoutCents(Number(creditAmount ?? 0))}
                    </Text>
                </ContainerFlex>
                {statusId !== TABLE_STATUS_FILTER_VALUES.APPROVED &&
                    statusId !== TABLE_STATUS_FILTER_VALUES.REJECTED && (
                        <ContainerFlex Gap="16px" Justify="start" Align="start">
                            <ContainerFlex
                                FlexDir="column"
                                Gap="4px"
                                Justify="start"
                                Align="start"
                                Width="116px"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="400"
                                    FontSize="1.0rem"
                                    Color="#2A2C2F"
                                >
                                    {CREDIT_DETAIL_PROFILE.PAID}
                                </Text>
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="700"
                                    FontSize="1.25rem"
                                    Color="#1D1E20"
                                >
                                    {numberToCurrencyWithoutCents(Number(amountPaid ?? 0))}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex Width="1px" Height="100%" backG="#E8E9EA" />
                            <ContainerFlex
                                FlexDir="column"
                                Gap="4px"
                                Justify="start"
                                Align="start"
                                Width="116px"
                            >
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="400"
                                    FontSize="1.0rem"
                                    Color="#2A2C2F"
                                >
                                    {CREDIT_DETAIL_PROFILE.OWES}
                                </Text>
                                <Text
                                    FontFamily="Nunito"
                                    FontWeight="700"
                                    FontSize="1.25rem"
                                    Color="#1D1E20"
                                >
                                    {numberToCurrencyWithoutCents(Number(amountOwed ?? 0))}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    )}
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <CreditDetailContract {...contractInfo} />
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <ContainerFlex Gap="8px" Flex="0">
                <Image
                    Height="24px"
                    Width="24px"
                    src={PrinterIcon}
                    alt={CREDIT_DETAIL_PROFILE.ALT.PRINTER}
                    Cursor="pointer"
                />
                <Text
                    FontFamily="Nunito"
                    FontWeight="700"
                    FontSize="1.0rem"
                    Color="#5A5AFF"
                    Cursor="pointer"
                >
                    {CREDIT_DETAIL_PROFILE.PRINTER_CTA}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default CreditDetailProfile;
