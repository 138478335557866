export const ACTIONS_SEARCH = {
    GetSearch: 'GET_SEARCH',
    GetSteps: 'GET_STEPS',
    GetItem: 'GET_ITEM',
    Selected: 'SELECTED',
    Level: 'LEVEL',
    GetRoute: 'GET_ROUTE',
    AddRoute: 'ADD_ROUTE',
    DeleteRoute: 'DELETE_ROUTE',
    AxiosSearch: 'AXIOS_SEARCH',
    ErrorSearch: 'ERROR_SEARCH',
    RestartSearch: 'RESTART_SEARCH',
};
export const ACTIONS_DINAMICS = {
    GetDinamics: 'GET_DINAMICS',
    SaveDinamics: 'SAVE_DINAMICS',
    SaveApprasial: 'SAVE_APPRASIAL',
    SaveLoan: 'SAVE_LOAN',
    GetApprasial: 'GET_APPRASIAL',
    GetLoan: 'GET_LOAN',
    GetMaxMin: 'GET_MAX_MIN',
    ErrorDinamics: 'ERROR_DINAMICS',
    RestartDinamics: 'RESTART_DINAMICS',
    SaveData: 'SAVE_DATA',
    AddArticle: 'ADD_ARTICLE',
    UpdateDinamics: 'UPDATE_DINAMICS',
    UpdateSaveData: 'UPDATE_SAVE_DATA',
};

export const ACTIONS_PRODUCTS_LOAN = {
    GetProduct: 'GET_PRODUCT',
    AddProduct: 'ADD_PRODUCT',
    DeleteProductLoan: 'DELETE_PRODUCT_LOAN',
    MoveProductContract: 'MOVE_PRODUCT_CONTRACT_LOAN',
    SelectedContract: 'SELECTED_CONTRACT',
    ContractLeave: 'CONTRACT_LEAVE',
    ContractEnter: 'CONTRACT_ENTER',
    ContractExtra: 'CONTRACT_EXTRA',
    RestartProducts: 'RESTART_PRODUCTS',
    Product: 'PRODUCT_LOAN',
    DeadLine: 'DEAD_LINE_LOAN',
    ErrorDeadLine: 'ERROR_DEAD_LINE',
    AddImages: 'ADD_IMAGES',
    DeleteImages: 'DELETE_IMAGES',
};

export const ACTIONS_ITEMS = {
    GetGroup: 'GET_GROUP',
    OnlyGroup: 'ONLY_GROUP',
    GetFamily: 'GET_FAMILY',
    OnlyFamily: 'ONLY_FAMILY',
    GetSub: 'GET_SUB',
    OnlySub: 'ONLY_SUB',
    GetBrend: 'GET_BRAND',
    OnlyBrand: 'ONLY_BRAND',
    GetArticle: 'GET_ARTICLE',
    OnlyArticle: 'ONLY_ARTICLE',
    ErrorItems: 'ERROR_ITEMS',
    RestartItems: 'RESTART_ITEMS',
};

export const ACTIONS_PLEDGE = {
    POST_PROMOTIONS: 'POST_PROMOTIONS',
    POST_PROMOTIONS_SUCCESS: 'POST_PROMOTIONS_SUCCESS',
    POST_PROMOTIONS_ERROR: 'POST_PROMOTIONS_ERROR',

    GET_DEADLINES: 'GET_DEADLINES',
    GET_DEADLINES_SUCCES: 'GET_DEADLINES_SUCCES',
    GET_DEADLINES_ERROR: 'GET_DEADLINES_ERROR',

    GET_SELECTPROMO: 'GET_SELECT_PROMO',
    DATA_SEARCH: 'DATA_SEARCH',
};

export const ACTIONS_ORIGIN = {
    GET_NATIONALITY: 'GET_NATIONALITY',
    GET_NATIONALITY_SUCCESS: 'GET_NATIONALITY_SUCCESS',
    GET_NATIONALITY_ERROR: 'GET_NATIONALITY_ERROR',
    SaveBeneficiary: 'SAVE_BENEFICIARY',
};

export const ACTIONS_PHASES = {
    SaveBeneficiary: 'SAVE_BENEFICIARY',

    POST_COBE: 'POST_COBE',
    POST_COBE_SUCCESS: 'POST_COBE_SUCCESS',
    POST_COBE_ERROR: 'POST_COBE_ERROR',

    GET_COHBEN: 'GET_COHBEN',
    GET_COHBEN_SUCCESS: 'GET_COHBEN_SUCCESS',
    GET_COHBEN_ERROR: 'GET_COHBEN_ERROR',
};

export const POST_CARD_QUOTER = 'POST_CARD_QUOTER';
export const POST_CARD_QUOTERSUCCESS = 'POST_CARD_QUOTERSUCCESS';
export const POST_CARD_QUOTER_ERROR = 'POST_CARD_QUOTER_ERROR';
export const POST_CARD_BLOCK = 'POST_CARD_BLOCK';

export const BENEFICIARY_SELECTED = 'BENEFICIARY_SELECTED';

export const GETPRODUCTSCARD = 'GETPRODUCTSCARD';
export const GETPRODUCTSCARD_SUCCESS = 'GETPRODUCTSCARD_SUCCESS';
export const GETPRODUCTSCARD_ERROR = 'GETPRODUCTSCARD_ERROR';

export const DELETEPRODUCTSCARD = 'DELETEPRODUCTSCARD';
export const DELETEPRODUCTSCARD_SUCCESS = 'DELETEPRODUCTSCARD_SUCCESS';
export const DELETEPRODUCTSCARD_ERROR = 'DELETEPRODUCTSCARD_ERROR';

export const ADD_PAYMENT = 'ADD_PAYMENT';
export const ADD_PAYMENT_SUCCESS = 'ADD_PAYMENT_SUCCESS';
export const ADD_PAYMENT_ERROR = 'ADD_PAYMENT_ERROR';

export const ADD_CART_PAYMENT = 'ADD_CART_PAYMENT';
export const ADD_CART_PAYMENT_SUCCESS = 'ADD_CART_PAYMENT_SUCCESS';
export const ADD_CART_PAYMENT_ERROR = 'ADD_CART_PAYMENT_ERROR';
export const CART_PAYMENT_ENTERED = 'CART_PAYMENT_ENTERED';
export const ADD_PAYMENT_RESET = 'ADD_PAYMENT_RESET_VALUES';
