import styled from '@emotion/styled';
import {Select} from '@components/Quoter/Steps/styles';
import {IPropsStyleReactSelect} from '@components/Quoter/Steps/interfacesStyles';
import {ISelctWithIconProps} from '@components/GlobalQuoter/interface';
import {NumericFormat} from 'react-number-format';

export const InputWithIconStyles = styled(NumericFormat)`
    border: none;
    border-radius: 0;
    opacity: 1;
    height: 24px;
    margin: 0;
    width: 100%;
    padding: 0;
    color: #2a2c2f;
    cursor: pointer;
    font-size: 1rem;
    font-family: Nunito;
    outline: none;
    font-weight: 700;
`;

export const SelectWithIconStyles = styled(Select)<ISelctWithIconProps & IPropsStyleReactSelect>`
    [class$='-control'] {
        padding: 0;
        cursor: pointer;
    }
    .css-1nmdiq5-menu {
        width: ${(props) => props.optionContainerWidth};
        left: ${(props) => props.optionContainerLeft};
    }

    .css-1nmdiq5-menu div div {
        border: 2px solid transparent;
        padding: 8px 16px;
        &:hover {
            background-color: #f0f0ff;
            border-left: 2px solid #5a5aff;
            color: #5a5aff;
        }
    }

    .css-1dimb5e-singleValue {
        color: #2a2c2f;
        font-weight: 700;
        font-family: Nunito;
    }

    .css-tj5bde-Svg {
        fill: #2a2c2f;
        height: 20px;
        margin-bottom: 16px;
        transform: ${(props) => (props.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    }
`;

SelectWithIconStyles.defaultProps = {
    optionContainerWidth: '252px',
    optionContainerLeft: '-39px',
};

export const QuantityInput = styled(NumericFormat)`
    border: 1px solid #e8e9ea;
    border-radius: 0;
    height: 100%;
    margin: 0;
    width: 100%;
    padding: 0;
    color: #050505;
    cursor: pointer;
    font-size: 1.75rem;
    font-family: Nunito;
    outline: none;
    font-weight: 700;
    width: 100%;
    border: none;
    cursor: default;
`;

export const QuantityDrodpown = styled(Select)`
    .css-1dimb5e-singleValue {
        color: #0d166b;
        font-weight: 700;
        letter-spacing: 0.3px;
        font-family: Nunito;
    }
    div.css-1wy0on6 > div > svg {
        fill: #5a5aff;
    }
    .css-1nmdiq5-menu div div {
        border: 2px solid transparent;
        padding: 8px 16px;
        &:hover {
            background-color: #f0f0ff;
            border-left: 2px solid #5a5aff;
            color: #5a5aff;
        }
    }
`;
