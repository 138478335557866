import React, {useState} from 'react';
import {INewProductPaymentMethodsProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/paymentMethodsInterfaces';
import {PaymentMethodsPreview} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/Preview/PaymentMethodsPreview';
import {PaymentMethodsEditingForm} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentMethods/EditingForm/PaymentMethodsEditingForm';

export const NewProductPaymentMethods: React.FC<INewProductPaymentMethodsProps> = ({
    paymentMethodRef,
}) => {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    return (
        <>
            {isEditing ? (
                <PaymentMethodsEditingForm
                    paymentMethodRef={paymentMethodRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            ) : (
                <PaymentMethodsPreview
                    paymentMethodRef={paymentMethodRef}
                    isEditing={isEditing}
                    setIsEditing={setIsEditing}
                />
            )}
        </>
    );
};
