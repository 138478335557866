import product from '@/components/AuctionPass/AuctionSettings/img/product1.jpg';
import product2 from '@/components/AuctionPass/AuctionSettings/img/product2.jpg';
import product3 from '@/components/AuctionPass/AuctionSettings/img/product3.jpg';
import product4 from '@/components/AuctionPass/AuctionSettings/img/product4.jpg';
import product5 from '@/components/AuctionPass/AuctionSettings/img/product5.jpg';
import {IHeader, OptionType, User} from './interface';

export const TEXT_GENERAL = {
    TITLE: 'Pase almoneda',
    TEXT: 'Aquí veras los artículos de los contratos de empeño vencidos',
    EDIT: 'Editar',
    FILTER: 'Filtrar',
    FILTER_NAME: 'Nombre ascendente',
    SEARCH: 'Buscar',
    SElECT: 'Seleccionar',
};

export const TOOLTIP_PAUSE = {
    TITLE: 'Pausar publicación',
    DESCRIPTION:
        'El pausar publicación es para poder hacer alguna corrección del artículo y no se publicó en la tienda en línea.',
};

export const TOOLTIP_ARTICLE = {
    TITLE: 'Revisión de artículos',
    DESCRIPTION:
        'La revisión de un artículo es para ver si los detalles de cada publicación no tengan errores en la descripción, precio, etc.',
};
export const TOOLTIP_AUCTION = {
    TITLE: 'Incremento al pase almoneda',
    DESCRIPTION: 'Artículos que subirán un porcentaje de valor al salir a almoneda.',
};

export const TOOLTIP_PROMOTIONS = {
    TITLE: 'Promociones integradas',
    DESCRIPTION:
        'Promociones o descuentos que quieres que afecten artículos que salen de almoneda.',
};

export const OPTION_TABLE: IHeader[] = [
    {name: 'Imágen'},
    {name: 'Nombre'},
    {name: 'SKU'},
    {name: 'Categoría'},
    {name: 'Fecha'},
    {name: 'Precio'},
    {name: 'Estado'},
    {name: 'Pausar'},
];

export const data_example: OptionType[] = [
    {label: 'option1', value: '1'},
    {label: 'option2', value: '2'},
    {label: 'option3', value: '3'},
];

export const data: User[] = [
    {
        img: product3,
        name: 'Audífonos SONY',
        sku: '86728143',
        category: 'Audio',
        date: 'Oct 30',
        price: '$2,500 ',
        percent: '+1.3%',
        status: 'Publicado',
        isActive: true,
    },
    {
        img: product2,
        name: 'Galaxy A2',
        sku: '23728275',
        category: 'Telefonia',
        date: 'Jul 12',
        price: '$6,000 ',
        percent: '+1.3%',
        status: 'Publicado',
        isActive: true,
    },
    {
        img: product,
        name: 'Apple watch',
        sku: '69728038',
        category: 'Smartwatch',
        date: 'Sep 16',
        price: '$6,000 ',
        percent: '+1.3%',
        status: 'Publicado',
        isActive: true,
    },
    {
        img: product4,
        name: 'IPad 9',
        sku: '69728038',
        category: 'Tablets',
        date: 'Sep 16',
        price: '$6,000 ',
        percent: '+1.3%',
        status: 'Publicado',
        isActive: true,
    },
    {
        img: product5,
        name: 'Xbox serie x',
        sku: '69728038',
        category: 'Video juegos',
        date: 'Sep 16',
        price: '$7,200',
        percent: '+1.3%',
        status: 'Pausado',
        isActive: false,
    },
];

export const statusStyles = {
    Publicado: {
        Border: '1px solid #00C200',
        BGColor: '#D1FFD1',
        Color: '#414042',
    },
    Pausado: {
        Border: '1px solid #819900',
        BGColor: '#F9FFD6',
        Color: '#414042',
    },
};
