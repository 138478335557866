import React, {useState, useEffect} from 'react';
import {ContainerFlex, ContainerGrid, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {IProductsTableProps, SuggestedList} from '@/components/LoansProducts/interfaces';
import {GreenSwitch} from '@components/CreateUsers/Form/styles';
import threePoints from '@images/threeDotsBlack.svg';
import {DetailAndDeleteLoans} from '@components/LoansProducts/DetailAndDeleteLoans';
import {useDispatch, useSelector} from 'react-redux';
import {
    ActiveDesactiveTermCreditStatus,
    SelectedTermCredit,
} from '@components/LoansProducts/Redux/Actions/ActiveDesactiveTermCredit';
import {RootState} from '@/config/store';
import {
    getProductsCustomerUser,
    setPageNumber,
} from '@components/LoansProducts/Redux/Actions/getProductsCustomerUserActions';
import {getAllTotalAvailables} from '@components/LoansProducts/Redux/Actions/getPersonalCreditProductsActions';
import {LOAN_PRODUCT_PER_PAGE, STATUS_PRODUCTS} from '@components/LoansProducts/constants';
import {ApplicationsPagination} from '@components/CreditsAndPawns/ApplicationsPagination';

const ProductsTable = ({
    dataTable,
    setShowActionModal,
    setShowArchiveModal,
}: IProductsTableProps) => {
    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const enterpriceId = useSelector((state: RootState) =>
        Number(state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId)
    );
    const {selectedCredit, errorData} = useSelector(
        (state: RootState) => state.ActiveDesactiveTermCredit
    );
    const {page, totalItems, totalPages, activeStatus} = useSelector(
        (state: RootState) => state.getProductsCustomerUserReducer
    );
    const dispatch = useDispatch();

    const setPageHandler = (pageNumber: number) => {
        dispatch(setPageNumber(pageNumber));
    };

    const formatCurrency = (value: number): string => {
        return value?.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        });
    };

    const formatTimeValue = (value: number | string): string | null => {
        if (!value) return null;
        return Number(value) === 1 ? `${value} mes` : `${value} meses`;
    };
    const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
    const [itemToActivate, setItemToActivate] = useState<SuggestedList | null>(null);
    const handleThreeDotsClick = (itemId: number) => {
        setSelectedItemId(itemId === selectedItemId ? null : itemId);
    };

    const isItemActive = (active: number | undefined): boolean => active === STATUS_PRODUCTS.ACTIVE;

    const handleSwitchChange = (item: SuggestedList) => {
        if (item.active === STATUS_PRODUCTS.ACTIVE) {
            setShowActionModal(true);
            dispatch(SelectedTermCredit(item));
        } else if (item.active === STATUS_PRODUCTS.INACTIVE) {
            setItemToActivate(item);
            dispatch(SelectedTermCredit(item));
        }
    };

    useEffect(() => {
        const updateCreditStatus = async () => {
            if (
                selectedCredit &&
                itemToActivate &&
                selectedCredit.active === STATUS_PRODUCTS.INACTIVE
            ) {
                const newStatus = STATUS_PRODUCTS.ACTIVE;
                try {
                    await dispatch(
                        ActiveDesactiveTermCreditStatus(
                            {termCreditId: itemToActivate.termId, statusTermId: newStatus},
                            token,
                            setShowActionModal
                        )
                    );
                    await dispatch(
                        getProductsCustomerUser(
                            token,
                            enterpriceId,
                            activeStatus,
                            LOAN_PRODUCT_PER_PAGE,
                            page
                        )
                    );
                    await dispatch(getAllTotalAvailables(token, enterpriceId));
                } catch (error) {
                    return errorData;
                }
            }
        };

        updateCreditStatus();
    }, [selectedCredit, itemToActivate, dispatch, token, enterpriceId]);
    return (
        <>
            <ContainerFlex
                FlexDir="column"
                Height={totalItems > 8 ? '350px' : 'fit-content'}
                Justify="start"
                Align="start"
            >
                {dataTable.map((item) => (
                    <ContainerGrid
                        key={item.termId}
                        GridColumns="2fr 2fr 2fr 2fr 2fr 1.25fr"
                        Height="2.75rem"
                        BorderBt="1px solid #E8E9EA"
                        Padding="0.5rem 1rem"
                        Gap="1rem"
                    >
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {item.termName}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {formatCurrency(item.minimumAmount)}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {formatCurrency(item.maximumAmount)}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {formatTimeValue(item.minimumTime)}
                        </Text>
                        <Text Color="#2A2C2F" FontSize="0.875rem">
                            {formatTimeValue(item.maximumTime)}
                        </Text>
                        <ContainerFlex Justify="end" Align="center" Gap="16px" Width="88px">
                            {item.active === STATUS_PRODUCTS.ACTIVE ||
                            item.active === STATUS_PRODUCTS.INACTIVE ? (
                                <>
                                    <GreenSwitch
                                        checked={isItemActive(item.active)}
                                        onChange={() => handleSwitchChange(item)}
                                    />
                                    <ContainerFlex
                                        Width="24px"
                                        Height="24px"
                                        Cursor="pointer"
                                        Position="relative"
                                        FlexDir="column"
                                        Justify="start"
                                        Align="end"
                                    >
                                        <Image
                                            src={threePoints}
                                            alt="add"
                                            onClick={() => handleThreeDotsClick(item.termId)}
                                            Cursor="pointer"
                                        />
                                        {selectedItemId === item.termId && (
                                            <DetailAndDeleteLoans
                                                item={item}
                                                setSelectedItemId={setSelectedItemId}
                                                setShowArchiveModal={setShowArchiveModal}
                                            />
                                        )}
                                    </ContainerFlex>
                                </>
                            ) : (
                                <></>
                            )}
                        </ContainerFlex>
                    </ContainerGrid>
                ))}
            </ContainerFlex>
            {totalItems > 8 && (
                <ApplicationsPagination
                    pageNumber={page}
                    setPageNumber={(pageNumber: number) => setPageHandler(pageNumber)}
                    quantityPerPage={LOAN_PRODUCT_PER_PAGE}
                    totalPages={totalPages}
                    totalItems={totalItems}
                />
            )}
        </>
    );
};

export default ProductsTable;
