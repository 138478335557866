import {DateTime} from 'luxon';
import {LocalizationProvider} from '@mui/x-date-pickers';
import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {
    IDatePickerRangeProps,
    IEndDueDateState,
    IInitialDateState,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {Input} from '@/components/Loan/styles';
import calendarIcon from '@components/Branches/Images/CalendarIcon.svg';
import {DatePickerInput, InputsContainer} from '@components/PersonalLoans/NewLoanProduct/styles';
import {months, SpanishLocaleDayText} from '@General/Atoms/CalendarDateSelection/constants';
import {CalendarInitDate} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/CalendarInitDate';
import {DateDueInitInput} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/DateDueInitInput';
import {DateDueEndInput} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/DateDueEndInput';
import {RangeCalendars} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/RangeCalendars';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/DatePicker/ErrorMessage';
import {useFormContext} from 'react-hook-form';

const years = Array.from({length: 10}, (_, i) => {
    const year = DateTime.now().year + i;
    return {value: year, label: year.toString()};
});
export const DatePickerRange: React.FC<IDatePickerRangeProps> = ({
    nameInitialDate,
    nameEndDate,
    defaultValue,
    endDefaultValue,
    isSubmitted,
}) => {
    const {
        control,
        trigger,
        setValue,
        formState: {errors},
    } = useFormContext();
    const errorMessageInitialDate = errors[nameInitialDate]?.message || '';
    const errorMessageEndDate = errors[nameEndDate]?.message || '';
    const [showCalendarModal, setShowCalendarModal] = useState(false);
    const [isInitData, setIsInitDate] = useState(endDefaultValue === null);
    const [initialDueDate, setInitialDueDate] = useState<IInitialDateState>({
        selectedMonth: defaultValue ? defaultValue.getMonth() + 1 : DateTime.now().month,
        selectedYear: defaultValue ? defaultValue.getFullYear() : DateTime.now().year,
        selectedDate: defaultValue ? DateTime.fromJSDate(defaultValue) : null,
        prevDate: defaultValue ? DateTime.fromJSDate(defaultValue) : null,
    });
    const [endDueDate, setEndDueDate] = useState<IEndDueDateState>({
        selectedMonth: endDefaultValue
            ? endDefaultValue.getMonth() + 1
            : initialDueDate.selectedMonth,
        selectedYear: endDefaultValue ? endDefaultValue.getFullYear() : initialDueDate.selectedYear,
        selectedDate: endDefaultValue ? DateTime.fromJSDate(endDefaultValue) : null,
        prevDate: endDefaultValue ? DateTime.fromJSDate(endDefaultValue) : null,
    });
    const dayOfWeekFormatter = (day: string): string => {
        const englishDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        const index = englishDays.indexOf(day);
        if (index !== -1) {
            return SpanishLocaleDayText.weekDays[index];
        }
        return '';
    };
    const handleManualInput = (e: DateTime, isInitDate: boolean) => {
        const limitYear = years[years.length - 1].value;
        const currentYear = years[0].value;
        const isValidYear = e.year >= currentYear && e.year <= limitYear;
        if (e.isValid && isValidYear) {
            if (isInitDate) {
                setInitialDueDate({
                    ...initialDueDate,
                    selectedDate: e,
                    selectedMonth: e.month,
                    selectedYear: e.year,
                    prevDate: e,
                });
            } else {
                setEndDueDate({
                    ...endDueDate,
                    selectedDate: e,
                    selectedMonth: e.month,
                    selectedYear: e.year,
                    prevDate: e,
                });
                setIsInitDate(false);
            }
        }
    };
    const handleClearRange = () => {
        setValue('dateDueEnd', null);
        setIsInitDate(true);
        if (isSubmitted) {
            trigger('dateDueInit');
            trigger('dateDueEnd');
        }
    };
    return (
        <>
            <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                <Text Color="#2A2C2F" FontWeight="700">
                    {LOAN_DETAILS.FIELDS.LOAN_VIGENCY}
                </Text>
                <DatePickerInput
                    Height="40px"
                    Position="relative"
                    Right="0px"
                    GridColumn="1fr 24px"
                    BoxShadowH=""
                    BoxShadowF=""
                    Padding="8px 16px"
                    BackG="#FFFFFF"
                    Radius="32px"
                >
                    <InputsContainer Gap="0.5rem" FontSize="1rem" Justify="start">
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DateDueInitInput
                                name={nameInitialDate}
                                control={control}
                                handleManualInput={handleManualInput}
                            />
                            <Text Color="#54575C" Height="max-content">
                                {'-'}
                            </Text>
                            <DateDueEndInput
                                name={nameEndDate}
                                control={control}
                                handleManualInput={handleManualInput}
                            />
                        </LocalizationProvider>
                    </InputsContainer>
                    <Image
                        src={calendarIcon}
                        alt="icon-calendar"
                        Cursor="pointer"
                        Width="24px"
                        Height="24px"
                        onClick={() => setShowCalendarModal(!showCalendarModal)}
                    />

                    {showCalendarModal && (
                        <ContainerFlex
                            Padding="1rem 1rem 0px 1rem"
                            Radius="1rem"
                            FlexDir="column"
                            backG="#FFF"
                            BoxS="0px 11px 26.6px 0px rgba(0, 0, 0, 0.06)"
                            Gap="0.5rem"
                            Position="absolute"
                            PositionTop="40px"
                            PositionRight="0px"
                            Height="max-content"
                            Width="max-content"
                            ZIndex="1"
                        >
                            <ContainerFlex Gap="1rem" Justify="start">
                                <ContainerFlex Gap="0.5rem" Width="max-content">
                                    <Input
                                        type="radio"
                                        Width="24px"
                                        Height="24px"
                                        checked={isInitData}
                                        onChange={() => {
                                            setIsInitDate(true);
                                            setValue('dateDueEnd', null);
                                            if (isSubmitted) trigger('dateDueEnd');
                                        }}
                                    />
                                    <Text Color="#2A2C2F">
                                        {LOAN_DETAILS.DATE_PICKER.RADIO_INIT_DATE}
                                    </Text>
                                </ContainerFlex>
                                <ContainerFlex Gap="0.5rem" Width="max-content">
                                    <Input
                                        type="radio"
                                        Width="24px"
                                        Height="24px"
                                        checked={!isInitData}
                                        onChange={() => setIsInitDate(false)}
                                    />
                                    <Text Color="#2A2C2F">
                                        {LOAN_DETAILS.DATE_PICKER.RADIO_RANGE_DATES}
                                    </Text>
                                </ContainerFlex>
                            </ContainerFlex>
                            {isInitData ? (
                                <CalendarInitDate
                                    dayOfWeekFormatter={dayOfWeekFormatter}
                                    years={years}
                                    months={months}
                                    setValue={setValue}
                                    showCalendarModal={showCalendarModal}
                                    setShowCalendarModal={setShowCalendarModal}
                                    initialDueDate={initialDueDate}
                                    setInitialDueDate={setInitialDueDate}
                                    isSubmitted={isSubmitted}
                                    trigger={trigger}
                                />
                            ) : (
                                <RangeCalendars
                                    dayOfWeekFormatter={dayOfWeekFormatter}
                                    years={years}
                                    months={months}
                                    setValue={setValue}
                                    showCalendarModal={showCalendarModal}
                                    setShowCalendarModal={setShowCalendarModal}
                                    initialDueDate={initialDueDate}
                                    setInitialDueDate={setInitialDueDate}
                                    endDueDate={endDueDate}
                                    setEndDueDate={setEndDueDate}
                                    handleClearRange={handleClearRange}
                                    isSubmitted={isSubmitted}
                                    trigger={trigger}
                                />
                            )}
                        </ContainerFlex>
                    )}
                </DatePickerInput>
                {errorMessageInitialDate && (
                    <ErrorMessage message={errorMessageInitialDate.toString()} />
                )}
                {errorMessageEndDate && <ErrorMessage message={errorMessageEndDate.toString()} />}
            </ContainerFlex>
        </>
    );
};
