import React from 'react';

import {IQuickActionCardProps} from '@Shopify/ClientProfile/interfaces';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import * as style from '@Shopify/ClientProfile/slylesClient';
import ActionDetails from '@Shopify/ClientProfile/QuickActionDetails';
import GarmentImages from '@Shopify/ClientProfile/QuickActionGarmentImages';
import QuickActionButtons from '@Shopify/ClientProfile/QuickActionButtons';

const QuickActionCard: React.FC<IQuickActionCardProps> = ({card, handleEndorsementDischarge}) => (
    <ContainerFlex {...style.cards}>
        <ContainerFlex FlexDir="column" Align="start">
            <ContainerFlex Justify="space-between">
                <Text Color="#5A5AFF" FontWeight="700" wSpace="nowrap">
                    {card.contract}
                </Text>
                <Text {...style.colorProduct(card.productLabel)}>{card.productLabel}</Text>
            </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex Justify="space-between" Align="start">
            <ContainerFlex Gap="0.25rem" Align="start" Justify="flex-start">
                <Text {...style.cardsTitle} wSpace="nowrap">
                    {`${card.productType}:`}
                </Text>
                <TextEllipsis Color="#2A2C2F" FontWeight="400">
                    {card.branchName}
                </TextEllipsis>
            </ContainerFlex>
        </ContainerFlex>
        <ActionDetails card={card} />
        <GarmentImages images={card.garmentImages} />
        <QuickActionButtons card={card} handleEndorsementDischarge={handleEndorsementDischarge} />
    </ContainerFlex>
);

export default QuickActionCard;
