import axios, {AxiosResponse} from 'axios';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    GET_ALL_CONTRACTS,
    GET_ALL_CONTRACTS_ERROR,
    GET_ALL_CONTRACTS_SUCCESS,
    GET_ALL_CONTRACTS_CLEAR,
} from '@components/Shopify/Products/redux/types/types';

export const getContract = () => {
    return {
        type: GET_ALL_CONTRACTS,
    };
};
export const getContractSuccess = (result: AxiosResponse) => {
    return {
        type: GET_ALL_CONTRACTS_SUCCESS,
        payload: result,
    };
};
export const getContractError = () => {
    return {
        type: GET_ALL_CONTRACTS_ERROR,
    };
};

export function getAllContract(id: string, token: string) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getContract());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCarEmploye.replace(
                    '{carShoopingId}',
                    id
                )}`,
                {
                    headers,
                }
            );
            dispatch(getContractSuccess(response.data.data));
        } catch (error) {
            dispatch(getContractError());
        }
    };
}

export const clearAllContracts = () => ({
    type: GET_ALL_CONTRACTS_CLEAR,
});
