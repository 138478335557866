import {AxiosResponse} from 'axios';
import {
    GET_CAT_CONDITIONS_TERMS_START,
    GET_CAT_CONDITIONS_TERMS_SUCCESS,
    GET_CAT_CONDITIONS_TERMS_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IGetCatConditionsTermsReducer} from '@components/PersonalLoans/NewLoanProduct/Redux/reducerInterfaces';

const initialState: IGetCatConditionsTermsReducer = {
    data: [],
    loading: false,
    error: false,
};

const getCatConditionsTermsReducer = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
): IGetCatConditionsTermsReducer => {
    switch (action.type) {
        case GET_CAT_CONDITIONS_TERMS_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case GET_CAT_CONDITIONS_TERMS_SUCCESS:
            return {
                data: action.payload.data,
                loading: false,
                error: false,
            };
        case GET_CAT_CONDITIONS_TERMS_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default getCatConditionsTermsReducer;
