import {AppDispatch} from '@/config/store';

import {ADD_EXTENSION} from '@components/ShoppingCart/Redux/types';
import {IRenewalExtension} from '@components/ShoppingCart/RenewalMenuOption/interface';
import {URL} from '@config/constants/index';
import axios, {AxiosResponse} from 'axios';

const addRenewalExtensionStart = () => {
    return {
        type: ADD_EXTENSION.ADD_EXTENSION_START,
    };
};
const addRenewalExtensionSuccess = (result: {data: AxiosResponse<string>}) => {
    return {
        type: ADD_EXTENSION.ADD_EXTENSION_SUCCESS,
        payload: result.data,
    };
};
const addRenewalExtensionError = () => {
    return {
        type: ADD_EXTENSION.ADD_EXTENSION_ERROR,
    };
};

export function addRenewalExtension(
    token: string,
    extension: IRenewalExtension,
    refresh?: () => void
) {
    return async (dispatch: AppDispatch) => {
        dispatch(addRenewalExtensionStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddExtensionTemporaly}`,
                extension,
                {
                    headers: {
                        'Content-Type': 'application/json-patch+json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            dispatch(addRenewalExtensionSuccess(response));
            if (refresh) refresh();
        } catch (error) {
            dispatch(addRenewalExtensionError());
        }
    };
}
