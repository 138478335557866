import React, {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';

import ButtonGeneral from '@General/Atoms/Button';
import {UPDATE_COMPANY, VERIFY_FIELD} from '@MyCompany/constants';
import {updateCompany} from '@MyCompany/updateCompany';
import {UpdateCompanyForm} from '@MyCompany/UpdateCompanyForm';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text} from '@Shopify/Ecommerce/styles';
import {IFormCompany, IUpdateCompanyInformation, IUpdateDataCompany} from '@MyCompany/Interface';
import {updateCompanyAction} from '@MyCompany/Redux/Actions/UpdateCompanyData';

export const UpdateDataCompany = ({
    updateDataCompany,
    setUpdateDataCompany,
    emails,
    phones,
}: IUpdateDataCompany) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const {
        company: {companyId, nameCompany, businessName, emailsCompanies, phoneCompanies},
    } = useSelector((state: RootState) => state.detailsCompany);
    const {
        handleSubmit,
        register,
        reset,
        control,
        formState: {errors},
    } = useForm<IFormCompany>({
        defaultValues: {
            nameEnterprice: '',
            naturalEnterprice: '',
            mail: [],
            phones: [],
        },
        resolver: yupResolver(updateCompany),
    });
    useEffect(() => {
        if (nameCompany || businessName || emailsCompanies || phoneCompanies) {
            reset({
                nameEnterprice: nameCompany || '',
                naturalEnterprice: businessName || '',
                mail: emailsCompanies || [],
                phones: phoneCompanies || [],
            });
        }
    }, [nameCompany, businessName, emailsCompanies, phoneCompanies, reset]);
    const cancelOperation = () => {
        setUpdateDataCompany(false);
        reset();
    };

    const onSubmit = (dataForm: IFormCompany) => {
        const {nameEnterprice, naturalEnterprice, phones, mail} = dataForm;
        const companyInformation: IUpdateCompanyInformation = {
            companyId,
            companyName: nameEnterprice,
            businessName: naturalEnterprice,
            phoneNumber: String(phones[0].phone ?? ''),
            email: String(mail[0].email ?? ''),
            phoneNumber2: String(phones[1]?.phone ?? ''),
            phoneNumber3: String(phones[2]?.phone ?? ''),
            email2: String(mail[1]?.email ?? ''),
            email3: String(mail[2]?.email ?? ''),
        };
        dispatch(updateCompanyAction(token, companyInformation));
        setUpdateDataCompany(false);
        reset();
    };

    return (
        <Modal
            Top="0"
            modalState={updateDataCompany}
            changeModalState={() => {}}
            titleModalState={false}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="25rem"
                Height="100vh"
                Align="start"
                Justify="start"
                Padding="1.5rem"
                Gap="1.5rem"
            >
                <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                    {UPDATE_COMPANY.DATA_COMPANY}
                </Text>
                <UpdateCompanyForm
                    register={register}
                    errors={errors}
                    control={control}
                    emails={emails}
                    phones={phones}
                />
                {errors &&
                    (errors.nameEnterprice ||
                        errors.naturalEnterprice ||
                        errors.mail?.length ||
                        errors.phones?.length) && (
                        <Text Self="center" Color="#FF6357">
                            {VERIFY_FIELD}
                        </Text>
                    )}
                <ContainerFlex Height="40px" Gap="1.5rem">
                    <Text
                        Color="#FF6357"
                        Width="100%"
                        Cursor="pointer"
                        Justify="center"
                        onClick={() => cancelOperation()}
                    >
                        {UPDATE_COMPANY.CANCEL}
                    </Text>
                    <ButtonGeneral width="100%" text={UPDATE_COMPANY.SAVE} />
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
