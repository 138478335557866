import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text, Image, ContainerImg, ContainerForm} from '@Shopify/Ecommerce/styles';
import {CREATE_CLIENTS} from '@components/Login/constants';
import {USER_CLIENTS_LOGIN} from '@/components/MarketAccessLogin/SubModules/Contants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {SearchInput} from '@/components/Customer/Styles';
import VisibilityOn from '@images/VisibilityOn.svg';
import VisibilityOff from '@images/VisibilityOff.svg';
import {useNavigate} from 'react-router-dom';
import {useForm, SubmitHandler} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {SchemaLogin} from '@/components/MarketAccessLogin/Operational/MarketAccessLoginYup';
import {IFormInput} from '@/components/MarketAccessLogin/Operational/Interfaces';
import {getValidateLoginUserClients} from '@/components/MarketAccessLogin/Redux/actions/AccesLoginAction';
import {useDispatch} from 'react-redux';
import {ButtonLoginGenerals} from '@/components/MarketAccessLogin/ButtonLogins';
import {ErrorUser} from '@/components/MarketAccessLogin/ErrorUser';
import {getUserData} from '@Login/Redux/Actions/ChangeScreen';
import {ResetUserDataMarket} from '@components/Login/MarketPlaceLogin/Redux/Actions/ValidateEmailForMarketPlace';
import {GetRouter} from '@components/AdminNavbar/Redux/Actions/loginTypeRoute';

export const LoginClientMarket = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [viewPassword, setViewPassword] = useState(false);
    const [error, setErrors] = useState(false);

    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInput>({
        resolver: yupResolver(SchemaLogin),
    });

    const onSubmit: SubmitHandler<IFormInput> = (data) => {
        dispatch(getValidateLoginUserClients(data, navigate, setErrors));
        dispatch(GetRouter('/Login/MarketUser'));
    };
    useEffect(() => {
        dispatch(
            getUserData({
                firstName: '',
                paternalSurname: '',
                maternalSurname: '',
                phoneNumber: '',
                email: '',
                password: '',
                repeatPassword: '',
            })
        );
        dispatch(ResetUserDataMarket());
    }, []);

    return (
        <ContainerFlex>
            <ContainerFlex
                backG="#FFFFFF"
                FlexDir="column"
                Width="407px"
                Radius="24px"
                Height="auto"
                Padding="32px"
                Border="1px solid #0D166B"
                Gap="24px"
            >
                <ContainerFlex>
                    <Text FontSize="1.5rem" FontWeight="700" Color="#1D1E20" FontFamily="Nunito">
                        {USER_CLIENTS_LOGIN.TITLE}
                    </Text>
                </ContainerFlex>

                <ContainerForm FlexDir="column" Gap="12px" onSubmit={handleSubmit(onSubmit)}>
                    <ContainerFlex>
                        <ContainerFlex
                            Justify="start"
                            FlexDir="column"
                            Align="start"
                            Gap="4px"
                            Height="auto"
                        >
                            <Text
                                FontSize="1rem"
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                            >
                                {USER_CLIENTS_LOGIN.EMAIL}
                            </Text>
                            <SearchInput
                                Height="40px"
                                Position="none"
                                GridColumn="90% 10%"
                                Margin="0 0 4px 0"
                                BoxShadowH=""
                                BoxShadowF=""
                                Padding="0 1rem"
                                BackG="#FFFFFF"
                                Radius="32px"
                            >
                                <input type="text" {...register('email')} />
                            </SearchInput>
                            {errors.email && (
                                <Text FontFamily="Nunito" FontSize="0.75rem" Color="#FF6357">
                                    {errors.email.message}
                                </Text>
                            )}
                            <Text
                                FontSize="1rem"
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                            >
                                {USER_CLIENTS_LOGIN.PASS}
                            </Text>
                            <SearchInput
                                Height="40px"
                                Position="none"
                                GridColumn="90% 10%"
                                Margin="0 0 4px 0"
                                Padding="0 1rem"
                                BackG="#FFFFFF"
                                Radius="32px"
                            >
                                <input
                                    type={viewPassword ? 'text' : 'password'}
                                    {...register('password')}
                                />
                                <ContainerImg onClick={() => setViewPassword(!viewPassword)}>
                                    <Image
                                        src={viewPassword ? VisibilityOn : VisibilityOff}
                                        alt="icon-warning"
                                        Cursor="pointer"
                                    />
                                </ContainerImg>
                            </SearchInput>
                            {errors.password && (
                                <Text FontFamily="Nunito" FontSize="0.75rem" Color="#FF6357">
                                    {errors.password.message}
                                </Text>
                            )}
                            {error && <ErrorUser />}
                            <Text
                                FontSize="0.75rem"
                                Color="#5A5AFF"
                                FontWeight="700"
                                FontFamily="Nunito"
                                Cursor="pointer"
                                onClick={() => navigate('/Login/ResetAccount')}
                            >
                                {USER_CLIENTS_LOGIN.FORGET_PASS}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex>
                        <ButtonGeneral
                            type="submit"
                            FontSize="1rem"
                            padding="8px 16px"
                            height="40px"
                            width="100%"
                            text="Iniciar sesión"
                        />
                    </ContainerFlex>
                </ContainerForm>
                <ContainerFlex FlexDir="column" Gap="12px">
                    <ContainerFlex Justify="space-between" ColumnGap="8px">
                        <ContainerFlex backG="#E8E9EA" Height="1px" Width="50%" />
                        <Text FontSize="1rem" Color="#54575C" FontFamily="Nunito" Cursor="pointer">
                            {CREATE_CLIENTS.OR}
                        </Text>
                        <ContainerFlex backG="#E8E9EA" Height="1px" Width="50%" />
                    </ContainerFlex>
                    <ButtonLoginGenerals />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
