import {IVisualGlobalQuoterForm} from '@components/GlobalQuoter/interface';
import {SET_VISUAL_FORM_VALUES} from '@/components/GlobalQuoter/Redux/Types/Types';
import {quantityFormOptions} from '@/components/GlobalQuoter/TempConstants';

const initialState: IVisualGlobalQuoterForm = {
    visualAmount: '',
    parsedAmount: null,
    frequencyId: null,
    accountPayments: null,
    typeDropdown: quantityFormOptions[0],
};
const globalQuoterFormReducer = (
    state = initialState,
    action: {
        payload: IVisualGlobalQuoterForm;
        type: string;
    }
) => {
    switch (action.type) {
        case SET_VISUAL_FORM_VALUES:
            return {
                visualAmount: action.payload.visualAmount,
                parsedAmount: action.payload.parsedAmount,
                frequencyId: action.payload.frequencyId,
                accountPayments: action.payload.accountPayments,
                typeDropdown: action.payload.typeDropdown,
            };
        default:
            return state;
    }
};

export default globalQuoterFormReducer;
