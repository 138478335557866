import React from 'react';

import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {LoadingOverlayProps} from '@General/Atoms/LoadingOverlay/interfaces';
import {
    OverlayContainer,
    Content,
    LoaderOverlay,
    LoadingBox,
} from '@General/Atoms/LoadingOverlay/styles';

const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
    isLoading,
    children,
    borderRadius,
    loaderWidth = '3.75rem',
    loaderPadding = '0.938rem',
}) => {
    return (
        <OverlayContainer>
            <Content>{children}</Content>
            <LoaderOverlay isVisible={isLoading} borderRadius={borderRadius}>
                <LoadingBox>
                    <LoadingAtaskate width={loaderWidth} padding={loaderPadding} />
                </LoadingBox>
            </LoaderOverlay>
        </OverlayContainer>
    );
};

export default LoadingOverlay;
