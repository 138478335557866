import * as yup from 'yup';
import {
    VALIDATION_CLIENT_DOCUMENT,
    ONE_IMAGE_DOCS,
} from '@components/Shopify/ClientProfile/constants';
import {NEW_CLIENT_VALIDATION_MSSGS} from '@components/Shopify/ListClient/constants';

export const yupClientDocuments = yup.object().shape({
    catIdentificationTypeId: yup.string().required(NEW_CLIENT_VALIDATION_MSSGS.ID_TYPE_REQUIRED),
    identificationNumber: yup
        .string()
        .required(NEW_CLIENT_VALIDATION_MSSGS.IDENTIFICATION_NUMBER_REQUIRED),
    imageDocFront: yup
        .string()
        .required(VALIDATION_CLIENT_DOCUMENT.FRONT_IMAGE)
        .test('fileType', VALIDATION_CLIENT_DOCUMENT.JPG_OR_PNG, function (value) {
            return !!value && /\.(jpeg|jpg|png)$/i.test(value);
        }),
    imageDocBack: yup.string().when('catIdentificationTypeId', {
        is: (value: string) => !ONE_IMAGE_DOCS.includes(value),
        then: (schema) =>
            schema
                .required(VALIDATION_CLIENT_DOCUMENT.REVERSE_IMAGE)
                .test('fileType', VALIDATION_CLIENT_DOCUMENT.JPG_OR_PNG, function (value) {
                    return !!value && /\.(jpeg|jpg|png)$/i.test(value);
                }),
        otherwise: (schema) => schema.notRequired(),
    }),
    expiryDate: yup.string().required(VALIDATION_CLIENT_DOCUMENT.SELECT_A_YEAR),
    validity: yup.string().required(VALIDATION_CLIENT_DOCUMENT.SELECT_A_YEAR),
});
