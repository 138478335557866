import {useSelector} from 'react-redux';
import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@components/Shopify/Ecommerce/styles';
import {HeaderPreview} from '@components/PersonalLoans/NewLoanProduct/ui/HeaderPreview';
import {
    frequencyTableHeaders,
    PAYMENT_FREQUENCY,
} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Table, TableRowHead} from '@components/CashFlow/FundingInquiry/styles';
import {TableItem, TableItemsContainer} from '@/components/Shopify/Endeavors/styles';
import {RootState} from '@/config/store';
import {ToolTip} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/ToolTip';
import questionIcon from '@components/PersonalLoans/NewLoanProduct/icons/questionIcon.svg';
import {TableRowData} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/Preview/TableRowData';
import {IPaymentFrequencyPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/PaymentFrequency/paymentFrequencyInterfaces';

export const PaymentFrequencyPreview: React.FC<IPaymentFrequencyPreviewProps> = ({
    frequencyRef,
    isEditing,
    setIsEditing,
}) => {
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData.details);
    const selectedData = newLoanData.frequencyTerm
        .filter((item) => item.isChecked)
        .sort((a, b) => b.countPaymentMin - a.countPaymentMin);
    const [headers, setHeaders] = useState(frequencyTableHeaders);
    const handleToolTipVisibility = (id: number, isShowing: boolean) => {
        const newArray = headers.map((header) => {
            if (header.id === id) {
                return {
                    ...header,
                    isShowingToolTip: isShowing,
                };
            }
            return header;
        });
        setHeaders(newArray);
    };
    return (
        <ContainerFlex
            backG="#FFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1.5rem"
            Gap="1.5rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Height="max-content"
            ref={frequencyRef}
        >
            <HeaderPreview
                title={PAYMENT_FREQUENCY.TITLE}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
            />
            <Table>
                <TableRowHead
                    Height="max-content"
                    GridColumn="75px 165px 169px"
                    Gap="60px"
                    Radius="0px"
                    Width="100%"
                    BackG="#fff"
                    BorderB="1px solid #E8E9EA"
                >
                    {headers.map((item, index) => (
                        <TableItem
                            Justify={index !== 0 ? 'start' : 'center'}
                            Padding="0px 0px 0.5rem 0px"
                            Gap="0.5rem"
                            key={index}
                        >
                            <Text
                                Width={index === 0 ? '100%' : 'max-content'}
                                Color="#1D1E20"
                                FontSize="0.875rem"
                                FontWeight="700"
                                Height="100%"
                            >
                                {item.title}
                            </Text>
                            {index !== 0 && (
                                <ContainerFlex
                                    Width="24px"
                                    Height="24px"
                                    FlexDir="column"
                                    Position="relative"
                                    Justify="start"
                                    onMouseEnter={() => handleToolTipVisibility(item.id, true)}
                                    onMouseLeave={() => handleToolTipVisibility(item.id, false)}
                                >
                                    <Image
                                        src={questionIcon}
                                        Width="24px"
                                        Height="24px"
                                        Cursor="pointer"
                                        alt="tooltip-icon"
                                    />
                                    {item.isShowingToolTip && (
                                        <ToolTip description={item.tooltipDescription} />
                                    )}
                                </ContainerFlex>
                            )}
                        </TableItem>
                    ))}
                </TableRowHead>
                <TableItemsContainer>
                    {selectedData.map((item, index) => (
                        <TableRowData
                            key={index}
                            tableLength={selectedData.length - 1}
                            index={index}
                            frecuncyName={item.frecuncyName}
                            countPaymentMin={item.countPaymentMin}
                            countPaymentMax={item.countPaymentMax}
                        />
                    ))}
                </TableItemsContainer>
            </Table>
        </ContainerFlex>
    );
};
