import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@components/LoginClient/styles';
import breadCrumbImg from '@images/arrowBreadCrumb.svg';
import {editBreadCrumb} from '@/components/RolesPermissions/EditRole/constants';
import {useNavigate} from 'react-router-dom';

export const EditBreadCrumb = () => {
    const Navigate = useNavigate();
    return (
        <ContainerFlex Justify="start" Gap="10px" Height="2rem">
            <Text onClick={() => Navigate('/')} Cursor="pointer" HColor="#5A5AFF">
                {editBreadCrumb.myCompany}
            </Text>
            <ImageLogo src={breadCrumbImg} Height="8px" Width="8px" alt="arrow" />
            <Text
                onClick={() => Navigate('/RolesAndPermissions')}
                Cursor="pointer"
                HColor="#5A5AFF"
            >
                {editBreadCrumb.roles}
            </Text>
            <ImageLogo src={breadCrumbImg} Height="8px" Width="8px" alt="arrow" />
            <Text FontWeight="700">{editBreadCrumb.editRole}</Text>
        </ContainerFlex>
    );
};
