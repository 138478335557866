import {OptionType} from '@components/MyCompanyUser/Operational/interfaceType';
export const EMPTY_VALUE = 0;
export const TEXT_USER = {
    TITLE: 'Usuarios',
    LIST: 'Lista',
    EMPTY: 'Sin roles asignados',
    SEACH: 'Busca un nombre',
    BTN_PRIMARY: 'Ir a roles',
    BTN_SECUNDARY: 'Nuevo usuario',
    OP_FIRST: 'Todos',
    OP_SECOND: 'Administrativos',
    OP_THIRD: 'P.O.S',
    FILTER: 'Filtrar por',
    FILTERT: 'Limpiar filtros',

    NO_DATA: 'No hay datos disponibles',
    FILTER_BTN: 'Filtrar por',

    Home: 'Inicio',
    Company: 'Mi empresa',
    user: 'Usuarios',

    MESSAGE: 'No hay usuarios con accesos tipo POS.',
    MESSAGE_ADMIN: 'No hay usuarios con accesos tipo Admin.',
    MESSAGE_SEARCH: 'No se encontraron usuarios disponibles.',
};
export const header = ['Nombre', 'Rol', 'Último acceso', 'Activado'];

export const MOD_FILTER = {
    TITLE: 'Filtrar por',
    INDICATION: 'Filtra por tipo de usuario',
    ROL: 'Roles',
    ACCESS: 'Accesos',
    STATE: 'Estado',
    BTN: 'Cancelar',
    BTNS: 'Continuar',
};

export const data_options: OptionType[] = [
    {value: '', label: 'Seleccionar'},
    {value: 'ALL', label: 'Todo'},
    {label: 'Activo', value: 'Activo'},
    {label: 'Inactivo', value: 'Inactivo'},
];

export const data_type = [
    {label: 'Admin', value: '2'},
    {label: 'Pos', value: '1'},
];
export const styles = {
    Admin: {
        color: '#000061',
        border: '1px solid #ACACFF',
        backgroundColor: '#E5E5FF',
    },
    Pos: {
        color: '#174A2E',
        border: '1px solid #B1E7C9',
        backgroundColor: '#E4F7EC',
    },
};

export type UserAccessType = 'Admin' | 'Pos';

export const getStylesByType = (type: UserAccessType) => {
    return styles[type];
};

export const USER_TYPE_ADMIN = 'Admin';
export const VALUE_POS = 1;
export const VALUE_ADMIN = 2;

export const UserListPageSize = 10;

export const stateOptions = [
    {value: 'all', label: 'Todos'},
    {value: 'true', label: 'Activos'},
    {value: 'false', label: 'Inactivos'},
];

const ACCESS_TYPES = {
    ADMINISTRATOR: 'Administrador',
    POS: 'POS',
};

export type AccessType = (typeof ACCESS_TYPES)[keyof typeof ACCESS_TYPES];
export type MappedAccessType = 'Admin' | 'Pos';

export const ACCESS_TYPE_MAP: Record<AccessType, MappedAccessType> = {
    [ACCESS_TYPES.ADMINISTRATOR]: 'Admin',
    [ACCESS_TYPES.POS]: 'Pos',
};

export const isValidAccessType = (value: string): value is AccessType => {
    return Object.values(ACCESS_TYPES).includes(value as AccessType);
};

export const stateLabels: Record<string, string> = {
    all: 'Todos',
    true: 'Activos',
    false: 'Inactivos',
};

export const EMPTY_VALUE_LABEL = '';

export const USER_STATES = {
    EMPTY: '',
    ALL: 'all',
    TRUE: 'true',
};

export const SIDE_MENU = {
    EDIT: 'Editar',
    DELETE: 'Eliminar',
};

export const DISABLE_MENU = {
    TITLE: 'Desactivar Usuario',
    TEXT: 'Esto desactivará la cuenta del usuario. ¿Estás seguro de que quieres continuar? Podrás reactivarla más adelante, si lo necesitas.',
    CANCEL: 'Cancelar',
    CONFIRM: 'Desactivar',
};
