import React, {useState} from 'react';
import {ContainerFlex, Image, Input, Text} from '@/components/Shopify/Ecommerce/styles';
import SearchImg from '@/images/search.svg';
import {TableItemsContainer} from '@components/CashFlow/FundingInquiry/styles';
import {
    containerProps,
    MIN_SEARCH_LENGTH,
    modulesTable,
    searchInputProps,
} from '@components/RolesPermissions/NewRole/constants';
import {IModuleProps, IRoleModules} from '@components/RolesPermissions/NewRole/interfaces';
import {calculateTotalModules} from '@components/RolesPermissions/NewRole/ModuleFunctions';
import ModuleTree from '@components/RolesPermissions/NewRole/ModulesTree';
import TableHeader from '@components/RolesPermissions/NewRole/TableHeader';
import {SearchInput} from '@Customer/Styles';

const debounce = <T extends (...args: string[]) => void>(
    func: T,
    delay: number
): ((...args: Parameters<T>) => void) => {
    let timer: NodeJS.Timeout;
    return (...args: Parameters<T>) => {
        clearTimeout(timer);
        timer = setTimeout(() => func(...args), delay);
    };
};

const Modules = ({
    data,
    isAdmin,
    isEdit,
    listData,
    setListData,
    setIsAdmin,
    selectedRole,
}: IModuleProps) => {
    const [modules, setModules] = useState<IRoleModules[]>(data);
    const [searchTerm, setSearchTerm] = useState('');
    const [isExpand, setIsExpand] = useState(false);
    const [wasAssigned, setWasAssigned] = useState({admin: false, operational: false});

    const expandEverything = (modules: IRoleModules[]): IRoleModules[] => {
        return modules.map((module) => ({
            ...module,
            isOpen: isExpand,
            nextLevel: module.nextLevel ? expandEverything(module.nextLevel) : [],
        }));
    };

    const handleExpand = () => {
        setIsExpand(!isExpand);
        setModules((prevModules) => expandEverything(prevModules));
    };

    const totalModules: number = calculateTotalModules(modules);

    const debounceSearch = debounce((searchTerm: string) => {
        const term = searchTerm.toLowerCase();
        if (term.length >= MIN_SEARCH_LENGTH) {
            const searchModules = (modules: IRoleModules[]): IRoleModules[] => {
                return modules
                    .map((module) => {
                        const moduleMatches = module.moduleName.toLowerCase().includes(term);
                        const matchingNextLevel = searchModules(module.nextLevel);

                        if (moduleMatches || matchingNextLevel.length > 0) {
                            return {
                                ...module,
                                isOpen: true,
                                nextLevel: module.nextLevel.map((item) => ({
                                    ...item,
                                    isOpen: true,
                                })),
                            };
                        }
                        return null;
                    })
                    .filter((module): module is IRoleModules => module !== null);
            };

            const filteredModules = searchModules(data);
            setModules(filteredModules);
        } else {
            setModules(data);
        }
    }, 500);

    return (
        <ContainerFlex {...containerProps}>
            <ContainerFlex
                Gap="15px"
                backG="#FAFAFA"
                Bb="1px solid #E8E9EA"
                Height="4rem"
                MinH="4rem"
            >
                <SearchInput {...searchInputProps}>
                    <Input
                        type="text"
                        placeholder={modulesTable.placeHolder}
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            debounceSearch(e.target.value);
                        }}
                    />
                    <Image src={SearchImg} Cursor="pointer" alt="search" />
                </SearchInput>
                <Text Color="#5A5AFF" FontWeight="700" Cursor="pointer" onClick={handleExpand}>
                    {modulesTable.expand}
                </Text>
            </ContainerFlex>

            <TableHeader
                totalCheckedModules={
                    isAdmin ? listData.adminModules.length : listData.operationalModules.length
                }
                totalModules={totalModules}
            />
            <TableItemsContainer Background="none" Width="100%">
                <ModuleTree
                    modules={modules}
                    level={0}
                    isExpand={isExpand}
                    totalModules={totalModules}
                    setModules={setModules}
                    isEdit={isEdit}
                    listData={listData}
                    setListData={setListData}
                    isAdmin={isAdmin}
                    allModules={modules}
                    setIsAdmin={setIsAdmin}
                    selectedRole={selectedRole}
                    wasAssigned={wasAssigned}
                    setWasAssigned={setWasAssigned}
                />
            </TableItemsContainer>
        </ContainerFlex>
    );
};

export default Modules;
