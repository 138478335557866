import {ADD_DISCHARGE} from '@Shopify/ClientProfile/Redux/types';
import {IAddDischargeState, IAddDischargeRedux} from '@Shopify/ClientProfile/interfaces';

const initialState: IAddDischargeState = {
    loading: false,
    data: null,
    error: false,
};

const addDischargeEndorsement = (
    state = initialState,
    action: IAddDischargeRedux
): IAddDischargeState => {
    switch (action.type) {
        case ADD_DISCHARGE.ADD_START:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADD_DISCHARGE.ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case ADD_DISCHARGE.ADD_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};
export default addDischargeEndorsement;
