import * as Yup from 'yup';
import {COOWNER_VALIDATION_MSG} from '@components/Quoter/CoownerBeneficiary/constants';

export const addCoOwnerSchema = Yup.object().shape({
    name: Yup.string()
        .required(COOWNER_VALIDATION_MSG.ENTER_FIRSTNAME)
        .matches(/^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/, COOWNER_VALIDATION_MSG.NOT_VALID),
    lastName: Yup.string()
        .required(COOWNER_VALIDATION_MSG.ENTER_LASTNAME)
        .matches(/^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/, COOWNER_VALIDATION_MSG.NOT_VALID),
    secondLastName: Yup.string()
        .required(COOWNER_VALIDATION_MSG.ENTER_LASTNAME)
        .matches(/^[A-Za-záéíóúÁÉÍÓÚäëïöüÄËÏÖÜñÑ\s]+$/, COOWNER_VALIDATION_MSG.NOT_VALID),
    email: Yup.string()
        .required(COOWNER_VALIDATION_MSG.ENTER_EMAIL)
        .email(COOWNER_VALIDATION_MSG.EMAIL_NOTVALID),
    phone: Yup.string()
        .required(COOWNER_VALIDATION_MSG.ENTER_PHONE)
        .min(10, COOWNER_VALIDATION_MSG.PHONE_MIN),
});
