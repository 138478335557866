import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    CREATE_USER_COMMAND_START,
    CREATE_USER_COMMAND_SUCCESS,
    CREATE_USER_COMMAND_ERROR,
} from '@components/NewUserEmployee/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';
import {ICreateUserEmployee} from '@components/NewUserEmployee/Redux/interfaces';
import {UseFormReset} from 'react-hook-form';
import {DataPersonalUsers} from '@components/NewUserEmployee/Operational/interface';
import {NavigateFunction} from 'react-router-dom';
import {routers} from '@/appRoute';
import { TableAllUserData } from '@/components/MyCompanyUser/Redux/Actions/DataTableUserActions';

export const createUserCommandStart = () => {
    return {
        type: CREATE_USER_COMMAND_START,
    };
};

export const createUserCommandSuccess = (result: AxiosResponse) => {
    return {
        type: CREATE_USER_COMMAND_SUCCESS,
        payload: result.data,
    };
};

export const createUserCommandError = (error: AxiosError) => {
    return {
        type: CREATE_USER_COMMAND_ERROR,
        error,
    };
};

export const createUserCommand = (
    token: string,
    companyId: number,
    newUser: ICreateUserEmployee,
    reset: UseFormReset<DataPersonalUsers>,
    navigate: NavigateFunction,
    setErrorBranches: (branches: boolean) => void
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(createUserCommandStart());
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.CreateUserCommand}`,
                newUser,
                {headers}
            );
            await dispatch(createUserCommandSuccess(response));
            setErrorBranches(false);
            if (token && companyId) {
                const requestData = {
                    companyId,
                    pageSize: 10,
                    pageNumber: 1,
                    employeeName: '',
                    roleId: '',
                    accessId: '',
                    isActive: true,
                };
                dispatch(TableAllUserData(token, requestData));
            }
            navigate(routers.Users);
            reset();
        } catch (error) {
            setErrorBranches(true);
            dispatch(createUserCommandError(error as AxiosError));
        }
    };
};
