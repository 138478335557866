import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {logoutSesion} from '@/components/Login/Interceptor/reduxLogOut';
import {
    ACCOUNTMENU,
    containerStylesClient,
    stylesTextProps,
} from '@components/AdminNavbar/constants';
import {ClickAwayListener} from '@mui/material';
import {INavContextualMenu} from '@components/AdminNavbar/interfaces';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {DELETECARTCONTRACTS} from '@/components/ShoppingCart/Redux/Actions/getCartContractsActions';
import logOut from '@images/Tertiary.svg';
import price from '@images/price_check.svg';
import users from '@images/UsersPink.svg';
import {useNavigate} from 'react-router-dom';

export const AccountMenuClient = ({handleClickAway}: INavContextualMenu) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const useData = useSelector((state: RootState) => state.getUsersValidation.userData);

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <ContainerFlex {...containerStylesClient}>
                <ContainerFlex
                    FlexDir="column"
                    Height="auto"
                    RowGap="10px"
                    backG="#FFFF"
                    Radius="0.75rem 0.75rem 0  0"
                    Gap="16px"
                >
                    <ContainerFlex
                        Gap="0.625rem"
                        Justify="start"
                        Bb="1px solid #E8E9EA"
                        Height="80px"
                    >
                        <ContainerFlex Radius="50%" Width="48px" Height="48px" backG="#ACACFF">
                            <Image
                                alt="user-image"
                                src={users}
                                width="32px"
                                height="32px"
                                Radius="50%"
                                ObjectFit="cover"
                                AspectRatio="1 / 1"
                            />
                        </ContainerFlex>

                        <ContainerFlex FlexDir="column" Align="start" Width="70%" Height="auto">
                            <Text
                                FontSize="1.25rem"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontWeight="700"
                            >
                                {useData && useData.names}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="16px">
                    <ContainerFlex
                        Justify="start"
                        Height="36px"
                        ColumnGap="8px"
                        Padding="8px"
                        onClick={() => navigate('/MyAccount')}
                    >
                        <Image
                            alt="user-image"
                            src={price}
                            width="20px"
                            height="20px"
                            ObjectFit="cover"
                            AspectRatio="1 / 1"
                        />
                        <Text {...stylesTextProps}>{ACCOUNTMENU.CONTRACTS}</Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="start" Height="36px" ColumnGap="8px" Padding="8px">
                        <Image
                            alt="user-image"
                            src={logOut}
                            width="20px"
                            height="20px"
                            ObjectFit="cover"
                            AspectRatio="1 / 1"
                        />
                        <Text
                            {...stylesTextProps}
                            onClick={async () => {
                                if (token) {
                                    dispatch(DELETECARTCONTRACTS());
                                    await dispatch(
                                        DeleteTemporalyPawns(token, {
                                            deleteAllPawns: true,
                                            operationType: 1,
                                        })
                                    );
                                }
                                logoutSesion(dispatch);
                            }}
                        >
                            {ACCOUNTMENU.LOGOUT}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ClickAwayListener>
    );
};
