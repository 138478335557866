import {Dispatch, SetStateAction} from 'react';

export interface Role {
    id: number;
    roleName: string;
}

export interface DropdownCheckboxProps {
    onChange: (selectedOptions: number[]) => void;
}

export interface Role {
    id: number;
    roleName: string;
    accessType: string;
    isActive: boolean;
}
export interface UpdateRolePayload {
    userId: number;
    roleId: number;
    isActive: boolean;
}
export interface ModuleGroup {
    permissionName: string;
    modules: IRoleModules[];
}
export type ModulesState = {
    [key: string]: IRoleModules[];
};
export type ModulesUpdater = (prevModules: IRoleModules[]) => IRoleModules[];
export interface ModalRolesProps {
    isOpen: boolean;
    closeModal: () => void;
    selectedRole: Role | null;
}
export const isModulesUpdater = (
    value: IRoleModules[] | ModulesUpdater
): value is ModulesUpdater => {
    return value instanceof Function;
};

export interface IListData {
    adminModules: number[];
    operationalModules: number[];
    users: number[];
}

export type IModuleTree = {
    level: number;
    modules: IRoleModules[];
    setModules: Dispatch<SetStateAction<IRoleModules[]>>;
    isExpand: boolean;
    totalModules: number;
    isEdit?: boolean;
    setListData: Dispatch<SetStateAction<IListData>>;
    listData: IListData;
    isAdmin: boolean;
};

export interface IRoleModules {
    moduleId: number;
    moduleName: string;
    status: boolean;
    step: number;
    idFather: number;
    icon: string;
    isChecked: boolean;
    isOpen: boolean;
    nextLevel: IRoleModules[];
}
