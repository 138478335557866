import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_APPROVED_CREDIT_SOLICITUDE_ERROR,
    GET_APPROVED_CREDIT_SOLICITUDE_START,
    GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS,
    CLEAR_APPROVED_CREDIT_REQUEST,
    INITIAL_APPROVED_CREDIT_REQUEST,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {
    IApprovedCreditSolicitudeReducer,
    ITableFilterTabs,
} from '@components/CreditsAndPawns/interfaces';
import {TABLE_FILTERS, TABLE_STATUS_FILTER_VALUES} from '@components/CreditsAndPawns/constants';

const initialState: IApprovedCreditSolicitudeReducer = {
    data: null,
    loading: false,
    error: false,
    creditRequestStatus: null,
};

const getDetailsCredit = (
    state: IApprovedCreditSolicitudeReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError; isCreditApproved: boolean}
): IApprovedCreditSolicitudeReducer => {
    switch (action.type) {
        case GET_APPROVED_CREDIT_SOLICITUDE_START:
            return {
                ...state,
                loading: true,
                error: false,
                creditRequestStatus: null,
            };
        case GET_APPROVED_CREDIT_SOLICITUDE_SUCCESS:
            return {
                ...state,
                data: action.payload.data,
                creditRequestStatus: getCreditRequestStatus(action.isCreditApproved),
                loading: false,
                error: false,
            };
        case GET_APPROVED_CREDIT_SOLICITUDE_ERROR:
            return {
                ...initialState,
                loading: false,
                error: true,
            };
        case CLEAR_APPROVED_CREDIT_REQUEST: {
            return {...state, data: null};
        }
        case INITIAL_APPROVED_CREDIT_REQUEST: {
            return initialState;
        }
        default:
            return state;
    }
};

const getCreditRequestStatus = (isCreditApproved: boolean): ITableFilterTabs => {
    const creditApprovedStatus = {
        title: isCreditApproved ? TABLE_FILTERS.APPROVED : TABLE_FILTERS.REJECTED,
        value: isCreditApproved
            ? TABLE_STATUS_FILTER_VALUES.APPROVED
            : TABLE_STATUS_FILTER_VALUES.REJECTED,
    };
    return creditApprovedStatus;
};

export default getDetailsCredit;
