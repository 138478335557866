export const USER_TABLE_REQUEST = 'USER_TABLE_REQUEST';
export const USER_TABLE_SOUCCES = 'USER_TABLE_SOUCCES';
export const USER_TABLE_ERROR = 'USER_TABLE_ERROR';
export const USER_TABLE_RESET = 'USER_TABLE_RESET';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SOUCCES = 'USER_PROFILE_SOUCCES';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const SET_SELECTED_EMPLOYEE_ID = 'SET_SELECTED_EMPLOYEE_ID';

export const SET_FILTER_VALUES = 'SET_FILTER_VALUES';
export const RESET_FILTER_VALUES = 'RESET_FILTER_VALUES';
