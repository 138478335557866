import {ImageLogo} from '@/components/LoginClient/styles';
import {ModalRequestNewLevel} from '@/components/RequestNewCatalogLevel/ModalRequestNewLevel';
import {
    CATALOG,
    NAME_CAT,
    NO_ARTICLES,
} from '@/components/RequestNewCatalogLevel/operational/constant';
import {TreeComponent} from '@/components/RequestNewCatalogLevel/TableTreeRequestCatalog';
import {ContainerCellGrid, ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {RootState} from '@/config/store';
import arrow from '@/images/arrow_back.svg';
import add from '@/images/IconeAdd.svg';
import {ICatalogItems} from '@components/AuctionPass/interfaces';
import {TableItem} from '@components/DeadLines/styles';
import {GroupLevel} from '@/components/Articles/Redux/Actions/ArticlesLevel';
import {getSelectedGroupNode} from '@components/RequestNewCatalogLevel/Redux/Actions/GetSelectGroup';
import {ICatGruop} from '@components/RequestNewCatalogLevel/Redux/Reducers/GetCatGroups';
import {ICatSelectedNode} from '@components/RequestNewCatalogLevel/Redux/Reducers/GetSelectGroup';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

export function RequestNewCatalogLevel() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isModalOpen, setModalOpen] = useState(false);
    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);
    const TreSelected: ICatSelectedNode = useSelector(
        (state: RootState) => state.getSelectedGroupPath
    );

    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId
    );

    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );

    const IdSelected = useSelector((state: RootState) => state.AddNewLevel);
    const TreeGroup: ICatGruop = useSelector((state: RootState) => state.getCatGroups);
    const findAndDispatchNodeById = (nodes: ICatalogItems[], targetId: number): void => {
        nodes.forEach((node) => {
            if (node.children && node.id === targetId) {
                dispatch(getSelectedGroupNode(node.children));
                return;
            }

            if (node.children && node.children.length > 0) {
                findAndDispatchNodeById(node.children, targetId);
            }
        });
    };

    useEffect(() => {
        const branchId = companyId?.toString();
        if (branchId) {
            dispatch(GroupLevel(token, branchId));
        }
    }, [companyId]);
    useEffect(() => {
        if (TreSelected.numberLevel === 1) {
            findAndDispatchNodeById(TreeGroup.catGroup, IdSelected.id);
        }
    }, [dispatch, TreeGroup.catGroup]);

    return (
        <ContainerCellGrid FlexDir="column" Padding="16px 24px" Gap="16px">
            <ContainerFlex
                onClick={() => {
                    navigate('/InventoryManagement/CatalogManagement');
                }}
                Justify="start"
                Height="max-content"
                Gap="8px"
                Cursor="pointer"
            >
                <ImageLogo Cursor="pointer" Height="16px" Width="16px" src={arrow} />
                <Text Cursor="pointer" Color="#5A5AFF" FontSize="1rem" FontWeight="700">
                    {CATALOG.BACK}
                </Text>
            </ContainerFlex>
            <ContainerFlex Height="max-content" Align="start" Justify="space-between">
                <ContainerFlex Justify="start">
                    <Text FontSize="1.5rem" FontWeight="700">
                        {TreSelected.name}
                    </Text>
                </ContainerFlex>

                <ContainerFlex Justify="end" Gap="8px" onClick={openModal} Cursor="pointer">
                    <ImageLogo Cursor="pointer" Height="" Width="24px" src={add} alt="add" />
                    <Text Cursor="pointer" Color="#5A5AFF" FontSize="1rem" FontWeight="700">
                        {CATALOG.ADD}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                Justify="start"
                FlexDir="column"
                Border="1px solid #E8E9EA"
                Radius="24px"
                Height=""
            >
                <ContainerFlex Border="" Display="grid" GridColumns="3fr 1fr 1fr" Padding="8px">
                    <TableItem Padding="0 0 0 40px" Justify="start">
                        <Text FontSize="0.875rem" Color="#2A2C2F" FontWeight="700">
                            {NAME_CAT}
                        </Text>
                    </TableItem>
                    <TableItem>
                        <Text
                            Padding="0 0 0 16px"
                            FontSize="0.875rem"
                            Color="#2A2C2F"
                            FontWeight="700"
                        >
                            {NO_ARTICLES}
                        </Text>
                    </TableItem>
                </ContainerFlex>
                <ContainerFlex />
                <ContainerFlex Justify="start" Align="start" FlexDir="column">
                    {TreSelected.catSelectGroups && (
                        <TreeComponent
                            data={TreSelected.catSelectGroups}
                            level={0}
                            isView={true}
                            fatherId={1}
                        />
                    )}
                </ContainerFlex>
            </ContainerFlex>

            {isModalOpen && <ModalRequestNewLevel isOpen={isModalOpen} closeModal={closeModal} />}
        </ContainerCellGrid>
    );
}
