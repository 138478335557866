import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import {
    ACTIVE_DESACTIVE_CREDIT_ERROR,
    ACTIVE_DESACTIVE_CREDIT_START,
    ACTIVE_DESACTIVE_CREDIT_SUCCESS,
    SELECTED_ACTIVE_DESACTIVE_CREDIT,
} from '@components/LoansProducts/Redux/Types/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';
import {SuggestedList, TermCreditsProps} from '@components/LoansProducts/interfaces';

export const fetchTermCreditStart = () => ({
    type: ACTIVE_DESACTIVE_CREDIT_START,
});

export const fetchTermCreditSuccess = (data: AxiosResponse) => ({
    type: ACTIVE_DESACTIVE_CREDIT_SUCCESS,
    payload: data,
});

export const fetchTermCreditError = (error: AxiosError) => ({
    type: ACTIVE_DESACTIVE_CREDIT_ERROR,
    payload: error,
});

export function ActiveDesactiveTermCreditStatus(
    data: TermCreditsProps,
    token: string,
    setShowActionModal: (show: boolean) => void
) {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(fetchTermCreditStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.ActiveDesactiveTermCredit}
            `,
                data,
                {headers}
            );
            dispatch(fetchTermCreditSuccess(response));
            setShowActionModal(false);
        } catch (error) {
            dispatch(fetchTermCreditError(error as AxiosError));
        }
    };
}

export const SelectedTermCredit = (data: SuggestedList) => ({
    type: SELECTED_ACTIVE_DESACTIVE_CREDIT,
    data: data,
});
