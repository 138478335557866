import {
    GET_CREDIT_DETAIL_CUSTOMER_PROFILE_ERROR,
    GET_CREDIT_DETAIL_CUSTOMER_PROFILE_START,
    GET_CREDIT_DETAIL_CUSTOMER_PROFILE_SUCCESS,
    REJECT_PERSONAL_CREDIT_START,
    REJECT_PERSONAL_CREDIT_SUCCESS,
    REJECT_PERSONAL_CREDIT_ERROR,
    SHOW_REJECT_MODAL,
    CLOSE_REJECT_MODAL,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {URL} from '@/config/constants';
import {AppDispatch} from '@/config/store';
import axios, {AxiosError, AxiosResponse} from 'axios';

const rejectPersonalCreditStart = () => {
    return {
        type: REJECT_PERSONAL_CREDIT_START,
    };
};

const rejectPersonalCreditSuccess = (payload: AxiosResponse) => {
    return {
        type: REJECT_PERSONAL_CREDIT_SUCCESS,
        payload: payload,
    };
};

const rejectPersonalCreditError = (error: AxiosError) => {
    return {
        type: REJECT_PERSONAL_CREDIT_ERROR,
        error: error,
    };
};

const getCreditDetailCustomerProfileStart = (personalCreditId: number) => {
    return {
        type: GET_CREDIT_DETAIL_CUSTOMER_PROFILE_START,
        personalCreditId,
    };
};

const getCreditDetailCustomerProfileSuccess = (payload: AxiosResponse) => {
    return {
        type: GET_CREDIT_DETAIL_CUSTOMER_PROFILE_SUCCESS,
        payload: payload,
    };
};

const getCreditDetailCustomerProfileError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_DETAIL_CUSTOMER_PROFILE_ERROR,
        error: error,
    };
};

export const GetCreditDetailCustomerProfileAction = (personalCreditId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditDetailCustomerProfileStart(personalCreditId));
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditDetailCustomerProfile}`,
                {headers, params: {personalCreditApplicationId: personalCreditId}}
            );
            dispatch(getCreditDetailCustomerProfileSuccess(response));
        } catch (error) {
            dispatch(getCreditDetailCustomerProfileError(error as AxiosError));
        }
    };
};

export const RejectPersonalCreditAction = (personalCreditId: number, token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    const data = {
        personalCreditId,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(rejectPersonalCreditStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.DeletePersonalLoan}`,
                data,
                {headers}
            );
            dispatch(rejectPersonalCreditSuccess(response));
        } catch (error) {
            dispatch(rejectPersonalCreditError(error as AxiosError));
        }
    };
};

export const showRejectModalAction = () => {
    return {
        type: SHOW_REJECT_MODAL,
    };
};
export const closeRejectModalAction = () => {
    return {
        type: CLOSE_REJECT_MODAL,
    };
};
