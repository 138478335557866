import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CREDIT_DETAILS_ERROR,
    GET_CREDIT_DETAILS_START,
    GET_CREDIT_DETAILS_SUCCESS,
    GET_CREDIT_REQUEST_DETAILS_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {IDetailsCreditAction} from '@components/CreditsAndPawns/interfaces';

const getCreditDetailStart = () => {
    return {
        type: GET_CREDIT_DETAILS_START,
    };
};

const getCreditDetailSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CREDIT_DETAILS_SUCCESS,
        payload: result,
    };
};

const getCreditRequestDetailSuccess = (result: AxiosResponse) => {
    return {
        type: GET_CREDIT_REQUEST_DETAILS_SUCCESS,
        payload: result,
    };
};
const getCreditDetailError = (error: AxiosError) => {
    return {
        type: GET_CREDIT_DETAILS_ERROR,
        error: error,
    };
};

export const getCreditRequestDetailsAction = ({token, creditId}: IDetailsCreditAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetDetailsCredit}?solicitudeId=${creditId}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditDetailStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getCreditRequestDetailSuccess(response.data));
        } catch (e) {
            dispatch(getCreditDetailError(e as AxiosError));
        }
    };
};

export const getCreditDetailAction = ({token, creditId}: IDetailsCreditAction) => {
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetCreditDetail}?personalCreditApplicationId=${creditId}`;
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getCreditDetailStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getCreditDetailSuccess(response.data));
        } catch (e) {
            dispatch(getCreditDetailError(e as AxiosError));
        }
    };
};
