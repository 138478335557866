import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CREDIT_DETAIL_PAYMENT_ERROR,
    GET_CREDIT_DETAIL_PAYMENT_START,
    GET_CREDIT_DETAIL_PAYMENT_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {ICreditDetailPaymentReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: ICreditDetailPaymentReducer = {
    creditDetailPayments: null,
    loading: false,
    error: false,
};

const getCreditDetailPayment = (
    state: ICreditDetailPaymentReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): ICreditDetailPaymentReducer => {
    switch (action.type) {
        case GET_CREDIT_DETAIL_PAYMENT_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CREDIT_DETAIL_PAYMENT_SUCCESS:
            return {
                ...state,
                creditDetailPayments: action.payload.data,
                loading: false,
            };
        case GET_CREDIT_DETAIL_PAYMENT_ERROR:
            return {
                ...initialState,
                error: true,
            };
        default:
            return state;
    }
};

export default getCreditDetailPayment;
