import {ADRESS_USER, ADRESS_USER_SUCCESS, ADRESS_USERERROR} from '@components/Users/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    message: '',
    error: null,
};
export const getAdressUser = (state = initialState, action) => {
    switch (action.type) {
        case ADRESS_USER:
            return {...state, loading: true};
        case ADRESS_USER_SUCCESS:
            return {...state, loading: false, userData: action.payload.data};
        case ADRESS_USERERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
