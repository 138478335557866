export const TableText = {
    Users: 'Usuarios',
    NewUser: 'Nuevo usuario',
    NoUsers: 'No se encontraron usuarios',
};

export const MoreOptionsText = {
    ManageAccount: 'GestionarCuenta',
};

export const PopUpDeleteText = {
    DeleteUser: 'Eliminar usuario',
    WarningDeleteUser:
        'Estás a punto de eliminar permanentemente la cuenta de este usuario. Esta acción no se puede deshacer. Al eliminar su cuenta, perderá acceso a todos los servicios, información, y datos asociados.',
    Cancel: 'Cancelar',
    DeletePermanently: 'Eliminar usuario permanentemente',
};

export const TableHeaderText = {
    UserName: 'Nombre de usuario',
    Role: 'Rol',
    Access: 'Accesos',
    States: 'Estados',
};

export const FilterText = {
    All: 'Todos',
    Administrative: 'Administrativos',
    POS: 'P.O.S',
    Admin: 'Admin',
    Pos: 'POS',
};

export const StateFilter = {
    All: 'Todos',
    Inactive: 'Inactivos',
    Active: 'Activos',
};

export const MODAL_TEXT = {
    TITLE: 'Accesos de rol',
    ROL: 'Rol: ',
    ADMIN: 'Administrativo',
    OP: 'Operativo',
    ADD: 'Subir o remplazar imagen',
    ADD_INDICATION: 'Acomoda en el marco la parte de la imagen que se mostrará',
    BUTTON: 'Busca archivos',
    REPLACE: 'Remplazar imagen',
    ROTATE: 'Girar 90°',
    CUT: 'Recortar',
    ADD_FILE: 'Arrastra elementos aqui',
    SAVE: 'Accesos de rol',
};

export const ERRORS = {
    SELECT: 'Por favor, selecciona un archivo de imagen válido (JPEG, PNG, GIF, WEBP)',
    MB: 'La imagen no debe superar los 5 MB',
    RESELECT: 'Error al cargar la imagen. Por favor, intenta con otra.',
    NOT: 'Error al leer el archivo. Por favor, intenta de nuevo.',
    NEW: 'No se seleccionó ningún archivo',
};

export const BUTTONS = {
    KEEP: 'Guardar',
    CANCEL: 'Cancelar',
};
export const TREE_TEXT = {
    EXPAND: 'Expandir todo',
    CONTRACT: ' Contraer todo',
};
