import React, {useState, useCallback, useEffect} from 'react';
import SearchImg from '@/images/search.svg';
import closed from '@/images/closeBlueIcone.svg';
import {EMPTY_VALUE_LABEL} from '@/components/MyCompanyUser/Operational/Constants';
import {ModalFilter} from '@/components/MyCompanyUser/ModalFilter';
import {
    FilterState,
    SearchFilterOfUserProps,
} from '@/components/MyCompanyUser/Operational/interfaceType';
import {SearchFilterView} from '@/components/MyCompanyUser/filtersOfUserForm';
import {useForm} from 'react-hook-form';
import {FormValues} from './interface';

export const SearchFilterOfUser: React.FC<SearchFilterOfUserProps> = ({
    onSearch,
    onFilter,
    onClearFilters,
    activeFilters,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [imageSrc, setImageSrc] = useState(SearchImg);
    const [localFilters, setLocalFilters] = useState(activeFilters);
    const {
        reset,
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<FormValues>({
        defaultValues: {
            Rol: '',
            Access: '',
            State: '',
        },
    });
    useEffect(() => {
        setLocalFilters(activeFilters);
    }, [activeFilters]);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            setSearchTerm(value);
            setImageSrc(value ? closed : SearchImg);
            if (value.length >= 3) {
                onSearch(value);
            } else if (value.length === 0) {
                onSearch('');
            }
        },
        [onSearch]
    );

    const handleImageClick = useCallback(() => {
        if (imageSrc === closed) {
            setSearchTerm('');
            setImageSrc(SearchImg);
            onSearch('');
        }
    }, [imageSrc, onSearch]);

    const applyFilters = useCallback(
        (filters: FilterState) => {
            setLocalFilters(filters);
            onFilter(filters);
            closeModal();
        },
        [onFilter]
    );

    const clearFilters = useCallback(() => {
        const clearedFilters = {roleId: 0, Rol: '', RolLabel: '', Access: '', State: ''};
        setLocalFilters(clearedFilters);
        setSearchTerm('');
        setImageSrc(SearchImg);
        onClearFilters();
        onFilter(clearedFilters);
        reset();
    }, [onClearFilters, onFilter]);

    const resetFilter = (filterType: string) => {
        const updatedFilters = {...localFilters};
        const filterMap: Record<string, () => void> = {
            Access: () => {
                updatedFilters.Access = EMPTY_VALUE_LABEL;
            },
            Rol: () => {
                updatedFilters.Rol = EMPTY_VALUE_LABEL;
                updatedFilters.RolLabel = EMPTY_VALUE_LABEL;
            },
            State: () => {
                updatedFilters.State = EMPTY_VALUE_LABEL;
            },
        };

        const resetFunction = filterMap[filterType];
        if (resetFunction) {
            resetFunction();
        }

        return updatedFilters;
    };

    const handleResetFilter = (filterType: string) => {
        const updatedFilters = resetFilter(filterType);
        setLocalFilters(updatedFilters);
        onFilter(updatedFilters);
    };

    const isFilterActive =
        localFilters.Rol !== EMPTY_VALUE_LABEL ||
        localFilters.Access !== EMPTY_VALUE_LABEL ||
        localFilters.State !== EMPTY_VALUE_LABEL;
    const isSearchActive = searchTerm.length > 0;

    return (
        <>
            <SearchFilterView
                searchTerm={searchTerm}
                imageSrc={imageSrc}
                localFilters={localFilters}
                isFilterActive={isFilterActive}
                isSearchActive={isSearchActive}
                handleInputChange={handleInputChange}
                handleImageClick={handleImageClick}
                openModal={openModal}
                clearFilters={clearFilters}
                handleResetFilter={handleResetFilter}
            />
            <ModalFilter
                isOpen={isModalOpen}
                closeModal={closeModal}
                applyFilters={applyFilters}
                initialFilters={localFilters}
                reset={reset}
                handleSubmit={handleSubmit}
                control={control}
                errors={errors}
            />
        </>
    );
};
