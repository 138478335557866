import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_TYPE_CHARGE_AND_PENALTIES_START,
    GET_TYPE_CHARGE_AND_PENALTIES_SUCCESS,
    GET_TYPE_CHARGE_AND_PENALTIES_ERROR,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';

export const getTypeChargesAndPenaltiesStart = () => {
    return {
        type: GET_TYPE_CHARGE_AND_PENALTIES_START,
    };
};

export const getTypeChargesAndPenaltiesSuccess = (result: AxiosResponse) => {
    return {
        type: GET_TYPE_CHARGE_AND_PENALTIES_SUCCESS,
        payload: result.data,
    };
};

export const getTypeChargesAndPenaltiesError = (error: AxiosError) => {
    return {
        type: GET_TYPE_CHARGE_AND_PENALTIES_ERROR,
        error,
    };
};

export const getTypeChargesAndPenalties = (token: string) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getTypeChargesAndPenaltiesStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetTypeChargeAndPenalties}`,
                {headers}
            );
            dispatch(getTypeChargesAndPenaltiesSuccess(response));
        } catch (error) {
            dispatch(getTypeChargesAndPenaltiesError(error as AxiosError));
        }
    };
};
