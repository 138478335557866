import React, {useEffect} from 'react';
import Facebook from '@images/Facebook_Logo.svg';
import Google from '@images/Google_Logo.svg';
import Apple from '@images/Apple_Logo.svg';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {useDispatch, useSelector} from 'react-redux';
import {getScreenState} from '@components/Login/Redux/Actions/ChangeScreen';
import {CREATE_CLIENTS} from '@components/Login/constants';
import {FigureContainer} from '@/components/Quoter/Steps/styles';
import {useNavigate} from 'react-router-dom';
import {getUserData} from '@Login/Redux/Actions/ChangeScreen';
import {ResetUserDataMarket} from '@components/Login/MarketPlaceLogin/Redux/Actions/ValidateEmailForMarketPlace';
import {IChangeScreen} from '@components/Login/interfaces';
import {RootState} from '@/config/store';

export const CreateAccount = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const UserData: IChangeScreen = useSelector((state: RootState) => state.getScreenSelect);

    useEffect(() => {
        dispatch(
            getUserData({
                firstName: '',
                paternalSurname: '',
                maternalSurname: '',
                phoneNumber: '',
                email: '',
                password: '',
                repeatPassword: '',
            })
        );
        dispatch(ResetUserDataMarket());
        if (
            UserData.dataUser.firstName.length === 0 &&
            UserData.dataUser.email.length === 0 &&
            UserData.dataUser.password.length === 0 &&
            UserData.dataUser.repeatPassword.length === 0
        ) {
            dispatch(getScreenState(1));
        }
    }, []);
    return (
        <ContainerFlex
            backG="#FFFFFF"
            FlexDir="column"
            Width="auto"
            Radius="24px"
            Height="auto"
            Padding="32px"
            Gap="24px"
            Border="1px solid #0D166B"
        >
            <ContainerFlex FlexDir="column" Gap="8px">
                <Text FontSize="1.5rem" FontWeight="700" Color="#1D1E20" FontFamily="Nunito">
                    {CREATE_CLIENTS.CREATE_ACCOUNT}
                </Text>
                <Text FontSize="1rem" Color="#2A2C2F" FontFamily="Nunito">
                    {CREATE_CLIENTS.EXPERIENCE}
                </Text>
                <Text FontSize="1rem" Color="#2A2C2F" FontFamily="Nunito">
                    {CREATE_CLIENTS.EXPERIENDE_SECOND}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="8px">
                <ContainerFlex
                    Radius="32px"
                    Height="40px"
                    Border="1px solid #5A5AFF"
                    Cursor="pointer"
                    Padding="8px 16px"
                    onClick={() => dispatch(getScreenState(2))}
                >
                    <Text
                        FontSize="1rem"
                        Color="#5A5AFF"
                        FontWeight="700"
                        FontFamily="Nunito"
                        Cursor="pointer"
                    >
                        {CREATE_CLIENTS.EMAIL}
                    </Text>
                </ContainerFlex>
                <ContainerFlex Justify="space-between" ColumnGap="8px">
                    <ContainerFlex backG="#E8E9EA" Height="1px" Width="50%" />
                    <Text FontSize="1rem" Color="#54575C" FontFamily="Nunito" Cursor="pointer">
                        {CREATE_CLIENTS.OR}
                    </Text>
                    <ContainerFlex backG="#E8E9EA" Height="1px" Width="50%" />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Justify="space-between" ColumnGap="16px">
                <FigureContainer
                    Radius="10px"
                    backG="#1877F2"
                    Height="54px"
                    Cursor="pointer"
                    Width="100%"
                >
                    <Image src={Facebook} />
                </FigureContainer>
                <FigureContainer
                    Radius="10px"
                    backG="#FFFFFF"
                    Height="54px"
                    Cursor="pointer"
                    BoxS="0px 0px 3px 0px rgba(0, 0, 0, 0.08), 0px 2px 3px 0px rgba(0, 0, 0, 0.17)"
                    Width="100%"
                >
                    <Image src={Google} />
                </FigureContainer>
                <FigureContainer
                    Radius="10px"
                    backG="#000000"
                    Height="54px"
                    Cursor="pointer"
                    Width="100%"
                >
                    <Image src={Apple} />
                </FigureContainer>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="8px">
                <Text FontSize="1rem" Color="#2A2C2F" FontFamily="Nunito">
                    {CREATE_CLIENTS.HAVE_ACCOUNT}
                </Text>
                <Text
                    FontSize="1rem"
                    Color="#5A5AFF"
                    FontWeight="700"
                    FontFamily="Nunito"
                    Cursor="pointer"
                    onClick={() => navigate('/Login/MarketUser')}
                >
                    {CREATE_CLIENTS.LOGIN}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};
