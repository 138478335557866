import {Controller, useFormContext} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {Select} from '@/components/Quoter/Steps/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {
    IRequestChannelFieldProps,
    IRequestChannelSelect,
} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import errorField from '@components/PersonalLoans/NewLoanProduct/icons/errorField.svg';
import {Option} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/RequestChannelField/Option';
import {MultiValueContainer} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/RequestChannelField/MultiValueContainer';
import {MultiValueRemove} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/RequestChannelField/MultiValueRemove';
import {MultiValueLabel} from '@components/PersonalLoans/NewLoanProduct/LoanProductDetails/Fields/RequestChannelField/MultiValueLabel';

export const RequestChannelField: React.FC<IRequestChannelFieldProps> = ({name}) => {
    const {
        control,
        watch,
        setValue,
        resetField,
        formState: {errors},
    } = useFormContext();
    const [selectAll, setSelectAll] = useState<boolean>(false);
    const requestChannelOptions = [
        {value: 3, label: 'Todos los canales'},
        {value: 1, label: 'Online'},
        {value: 2, label: 'Sucursal'},
    ];
    const formState = watch();
    const errorMessage = errors[name]?.message || '';
    const handleRequestChannelChange = (selected: IRequestChannelSelect[]) => {
        const allOptions = requestChannelOptions.filter((option) => option.value !== 3);
        if (selected.length > 0 && selected[selected.length - 1].value === 3) {
            if (selectAll) {
                return resetField('requestChannel');
            } else {
                setSelectAll(true);
                return setValue('requestChannel', allOptions);
            }
        } else if (selected.length === allOptions.length) {
            setSelectAll(true);
        }
    };
    useEffect(() => {
        if (!formState.requestChannel) return;
        if (formState.requestChannel.length === 0) {
            setSelectAll(false);
        }
    }, [formState.requestChannel]);
    return (
        <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
            <Text Color="#2A2C2F" FontWeight="700">
                {LOAN_DETAILS.FIELDS.APPLICATION_CHANNEL}
            </Text>
            <Controller
                name={name}
                control={control}
                render={({field: {value, onChange, ...field}}) => (
                    <Select
                        {...field}
                        noOptionsMessage={() => 'Sin opciones'}
                        options={requestChannelOptions}
                        placeholder="Selecciona"
                        padding="0px"
                        radius="2rem"
                        components={{
                            Option: (props) => <Option props={props} selectAll={selectAll} />,
                            MultiValueContainer,
                            MultiValueRemove,
                            MultiValueLabel,
                        }}
                        value={value}
                        isMulti
                        hideSelectedOptions={false}
                        isSearchable={false}
                        closeMenuOnSelect={false}
                        onChange={(e) => {
                            onChange(e);
                            const selected = e as IRequestChannelSelect[];
                            handleRequestChannelChange(selected);
                        }}
                        isClearable={false}
                        MultiRadius="0.5rem"
                        MultiIconHover="transparent"
                        ControlBottom="3px"
                        controlTop=""
                    />
                )}
            />
            {errorMessage && (
                <Text FontSize="0.875rem" Color="#A82424" Gap="0.25rem">
                    <Image src={errorField} Width="16px" Height="16px" alt="error-icon" />
                    {errorMessage.toString()}
                </Text>
            )}
        </ContainerFlex>
    );
};
