import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {
    IAmortizationTableFilters,
    IAmortizationTableReducer,
} from '@components/PersonalLoans/interfaces';
import {ContainerFlex, Image, Modal, Text} from '@/components/Shopify/Ecommerce/styles';
import closeIcon from '@components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/icons/closeIcon.svg';
import {AppDispatch, RootState} from '@/config/store';
import {getAmortizationTableLoan} from '@components/PersonalLoans/Redux/Actions/GetAmortizationTableLoanActions';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {AmortizationTable} from '@components/PersonalLoans/PersonalLoanApproved/AmortizationTableLoan/AmortizationTable';
import {AMORTIZATION_TABLE_LOANS} from '@components/PersonalLoans/constants';
import {IAmortizationTableCreditRequestProps} from '@components/CreditsAndPawns/interfaces';

export const AmortizationTableCreditRequest: React.FC<IAmortizationTableCreditRequestProps> = ({
    showTable,
    setShowTable,
}) => {
    const dispatch: AppDispatch = useDispatch();
    const token: string = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const creditDetails = useSelector((state: RootState) => {
        return state.getDetailsCredit.creditRequestDetail;
    });
    useEffect(() => {
        if (token && creditDetails) {
            const filters: IAmortizationTableFilters = {
                ammountRequested: creditDetails.amountSolicutude,
                frecuencyTermId: creditDetails.frecuncyId,
                periodsNumber: creditDetails.countPeriod,
            };
            dispatch(getAmortizationTableLoan(token, filters));
        }
    }, []);
    const tableData: IAmortizationTableReducer = useSelector(
        (state: RootState) => state.getAmortizationTableLoan
    );
    return (
        <>
            <Modal BackC="rgba(0, 0, 0, 0.40)">
                <ContainerFlex
                    Width="777px"
                    Height="max-content"
                    Padding="1.5rem"
                    FlexDir="column"
                    Justify="start"
                    Radius="1.5rem"
                    Gap="1rem"
                    BoxShadow="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                    backG="#FFF"
                    Border="1px solid #E8E9EA"
                    OverFlow="hidden"
                >
                    <ContainerFlex Justify="space-between">
                        <Text
                            Width="560px"
                            Height="16px"
                            FontSize="1.25rem"
                            Color="#1D1E20"
                            FontWeight="500"
                        >
                            {AMORTIZATION_TABLE_LOANS.TITLE}
                        </Text>
                        <Image
                            src={closeIcon}
                            Width="24px"
                            Height="24px"
                            Cursor="pointer"
                            onClick={() => setShowTable(!showTable)}
                            alt="close-icon"
                        />
                    </ContainerFlex>
                    {tableData.loading ? (
                        <ContainerFlex Height="336px">
                            <Loading />
                        </ContainerFlex>
                    ) : (
                        <AmortizationTable tableData={tableData.data} error={tableData.error} />
                    )}
                </ContainerFlex>
            </Modal>
        </>
    );
};
