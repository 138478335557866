import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {ContainerFlex, Image, Text, Modal} from '@components/Shopify/Ecommerce/styles';
import {
    DESACTIVATE_LEVEL,
    ACTIVATE_LEVEL,
    ACTIVATE_ARTICLE,
    DESACTIVATE_ARTICLE,
} from '@components/Catalogue/constants';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import worldIcon from '@components/Catalogue/icons/desactivateModal.svg';
import {ListItem, UnorderedList} from '@components/Catalogue/styles';
import {
    IDesactivateActivateModal,
    IFiltersActivateLevelOrItem,
} from '@components/Catalogue/interfaces';
import {AppDispatch, RootState} from '@/config/store';
import {getActivateLevelOrItem} from '@components/Catalogue/Redux/Actions/GetActivateLevelOrItemActions';
import {Loading} from '@General/Atoms/LoadingAtaskate/styles';
import {ItemRoot} from '@components/Catalogue/ItemRoot';

export const DesactivateActivateModal = ({
    isActivating,
    isLevel,
    level,
    levelId,
    showActivateModal,
    setShowActivateModal,
    addSwitch,
    setAddSwitch,
}: IDesactivateActivateModal) => {
    const levelDescriptions: string[] = [
        isActivating
            ? ACTIVATE_LEVEL.DESCRIPTIONS.DESCRIPTION_1
            : DESACTIVATE_LEVEL.DESCRIPTIONS.DESCRIPTION_1,
        isActivating
            ? ACTIVATE_LEVEL.DESCRIPTIONS.DESCRIPTION_2
            : DESACTIVATE_LEVEL.DESCRIPTIONS.DESCRIPTION_2,
    ];
    const articleDescriptions: string[] = [
        isActivating
            ? ACTIVATE_ARTICLE.DESCRIPTIONS.DESCRIPTION_1
            : DESACTIVATE_ARTICLE.DESCRIPTIONS.DESCRIPTION_1,
        isActivating
            ? ACTIVATE_ARTICLE.DESCRIPTIONS.DESCRIPTION_2
            : DESACTIVATE_ARTICLE.DESCRIPTIONS.DESCRIPTION_2,
    ];
    const getTitleModal = (isLevel: boolean, isActivating: boolean): string => {
        if (isLevel) {
            return isActivating ? ACTIVATE_LEVEL.TITLE : DESACTIVATE_LEVEL.TITLE;
        }
        return isActivating ? ACTIVATE_ARTICLE.TITLE : DESACTIVATE_ARTICLE.TITLE;
    };
    const descriptions: string[] = isLevel ? levelDescriptions : articleDescriptions;
    const modalTitle: string = getTitleModal(isLevel, isActivating);
    const token: string = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const dispatch: AppDispatch = useDispatch();
    const itemRootReducer = useSelector((state: RootState) => state.getActivateLevelOrItem);
    const itemRoot: string[] =
        itemRootReducer.data?.migaPan?.split('->').map((item: string) => item.trim()) || [];
    const onCancel = () => {
        setShowActivateModal(false);
    };
    const onContinue = () => {
        setAddSwitch(!addSwitch);
        setShowActivateModal(false);
    };
    useEffect(() => {
        const filters: IFiltersActivateLevelOrItem = {
            level,
            levelId,
        };
        if (token) {
            dispatch(getActivateLevelOrItem(token, filters));
        }
    }, []);

    return (
        <>
            <Modal>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Width="500px"
                    Height="351px"
                    Padding="1.5rem"
                    Gap="1.5rem"
                    Radius="1.5rem"
                    backG="#fff"
                    BoxS="0px 10px 60px 0px rgba(0, 0, 0, 0.25)"
                >
                    <Image src={worldIcon} />

                    <ContainerFlex FlexDir="column" Gap="1rem">
                        <Text
                            FontFamily="Nunito"
                            FontSize="1.5rem"
                            FontWeight="700"
                            Color="#2A2C2F"
                        >
                            {modalTitle}
                        </Text>

                        {!itemRootReducer.loading ? (
                            <ItemRoot itemRoot={itemRoot} />
                        ) : (
                            <Loading width="12px" padding="5px" />
                        )}

                        <UnorderedList Height="max-content">
                            {descriptions.map((description, index) => (
                                <ListItem
                                    FontFamily="Nunito"
                                    ListStyle="inside"
                                    Color="#54575C"
                                    TextAlign="center"
                                    key={index}
                                >
                                    {description}
                                </ListItem>
                            ))}
                        </UnorderedList>
                    </ContainerFlex>

                    <ContainerFlex Width="376px" Gap="1.5rem">
                        <ButtonGenerals
                            SecondaryButton="#FFFFFF"
                            Width="100%"
                            onClick={() => onCancel()}
                            Cursor="pointer"
                        >
                            {'Cancelar'}
                        </ButtonGenerals>
                        <ButtonGenerals Width="100%" onClick={() => onContinue()} Cursor="pointer">
                            {isActivating ? 'Activar' : 'Desactivar'}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </Modal>
        </>
    );
};
