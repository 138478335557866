import {
    PUT_ROL_USER_REQUEST,
    PUT_ROL_USER_SOUCCES,
    PUT_ROL_USER_RROR,
} from '@components/Users/UserProfile/Redux/types';

const initialState = {
    loading: false,
    userData: {},
    message: '',
    error: null,
};

export const PutRolesUser = (state = initialState, action) => {
    switch (action.type) {
        case PUT_ROL_USER_REQUEST:
            return {...state, loading: true};
        case PUT_ROL_USER_SOUCCES:
            return {...state, loading: false, userData: action.payload?.data || null};
        case PUT_ROL_USER_RROR:
            return {...state, loading: false, error: action.payload.data.data};
        default:
            return state;
    }
};
