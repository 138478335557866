import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {
    toggleFiltersMenu,
    getPersonalLoansByFilters,
    setFiltersValues,
} from '@components/CreditsAndPawns/Redux/Actions/GetFilterListLoansActions';
import {RootState} from '@/config/store';
import {ISearchParamsFilter} from '@components/CreditsAndPawns/interfaces';

const FilterModalFooter = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const enterpriceId = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.enterpriceDetails![0]?.enterpriceId as number;
    });
    const {status} = useSelector((state: RootState) => {
        return state.getListLoans;
    });
    const {filtersValues, filterVisualStatus} = useSelector((state: RootState) => {
        return state.getFilterListLoans;
    });

    const handleApplyFilters = () => {
        dispatch(
            getPersonalLoansByFilters(
                token,
                enterpriceId,
                status,
                filtersValues as ISearchParamsFilter
            )
        );
        dispatch(toggleFiltersMenu());
    };

    const closeModalHandler = () => {
        dispatch(setFiltersValues(filterVisualStatus));
        dispatch(toggleFiltersMenu());
    };

    return (
        <ContainerFlex Height={'40px'} Gap="16px">
            <ButtonGenerals
                BackGC="transparent"
                HBackG="transparent"
                Width="50%"
                Border="1px solid #5A5AFF"
                onClick={closeModalHandler}
                Cursor="pointer"
            >
                <Text
                    LHeight="24px"
                    LetterSpacing="0.3px"
                    Color="#5A5AFF"
                    FontFamily="Nunito"
                    FontWeight="700"
                    Cursor="pointer"
                >
                    Cancelar
                </Text>
            </ButtonGenerals>
            <ButtonGenerals Width="50%" onClick={handleApplyFilters} Cursor="pointer">
                <Text
                    LHeight="24px"
                    LetterSpacing="0.3px"
                    FontFamily="Nunito"
                    FontWeight="700"
                    Color="#FFFFFF"
                    Cursor="pointer"
                >
                    Aplicar
                </Text>
            </ButtonGenerals>
        </ContainerFlex>
    );
};

export default FilterModalFooter;
