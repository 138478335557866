import {Controller, useFormContext} from 'react-hook-form';
import React, {useEffect, useState} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {DISBURSEMENT, VALIDATIONS_MSG} from '@components/PersonalLoans/NewLoanProduct/constants';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {INewProductDisbursementOptions} from '@components/PersonalLoans/interfaces';
import {IDisbursementProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {WarningError} from '@components/PersonalLoans/NewLoanProduct/PaymentMethods/WarningError';

export const Disbursement: React.FC<IDisbursementProps> = ({disbursementsRef, isSubmitted}) => {
    const {
        control,
        resetField,
        trigger,
        formState: {errors},
    } = useFormContext();
    const {disbursementOptions} = useSelector((state: RootState) => {
        return state.DocumentDisbursementReducer;
    });
    const [disbursementItems, setDisbursementItems] = useState<INewProductDisbursementOptions[]>(
        []
    );
    const handleCheckboxChange = (disbursementItem: INewProductDisbursementOptions) => {
        if (disbursementItems.some((i) => i.disbursementId === disbursementItem.disbursementId)) {
            setDisbursementItems(
                disbursementItems.filter(
                    (i) => i.disbursementId !== disbursementItem.disbursementId
                )
            );
        } else {
            setDisbursementItems([...disbursementItems, disbursementItem]);
        }
    };
    useEffect(() => {
        const newFieldArray = disbursementOptions.map((item) => {
            return {
                disbursementId: item.disbursementId,
                disbursementName: item.disbursementName,
                disbursementDetails: item.disbursementDetails,
                isChecked: false,
            };
        });
        resetField('disbursements', {defaultValue: newFieldArray});
    }, [disbursementOptions]);
    const errorMessage = errors.disbursements?.message || '';
    return (
        <ContainerFlex
            Justify="start"
            Align="start"
            FlexDir="column"
            Gap="1.5rem"
            Radius="1.5rem"
            Padding="1.25rem"
            Border="1px solid #E8E9EA"
            ref={disbursementsRef}
        >
            <ContainerFlex FlexDir="column" Gap="0.5rem" Height="max-content" Align="start">
                <Text oFlow="normal" Color="#1D1E20" FontSize="1.25rem" FontWeight="700">
                    {DISBURSEMENT.TITLE}
                </Text>
                <Text oFlow="normal" Color="#2A2C2F" FontSize="1rem" FontWeight="400">
                    {DISBURSEMENT.SUB}
                </Text>
            </ContainerFlex>
            {errorMessage && (
                <WarningError
                    errorMessage={
                        errorMessage.toString() || VALIDATIONS_MSG.DISBURSEMENT.AT_LEAST_ONE
                    }
                />
            )}
            {disbursementOptions.map((item, index) => (
                <ContainerFlex key={item.disbursementId} Justify="start" Gap="8px" Height="45px">
                    <Controller
                        name={`disbursements[${index}].isChecked`}
                        control={control}
                        render={({field: {onChange, ...field}}) => (
                            <Checkbox
                                {...field}
                                checked={disbursementItems.some(
                                    (i) => i.disbursementId === item.disbursementId
                                )}
                                onChange={(e) => {
                                    onChange(e);
                                    handleCheckboxChange(item);
                                    if (isSubmitted) trigger('disbursements');
                                }}
                            />
                        )}
                    />
                    <ContainerFlex Align="start" FlexDir="column">
                        <Text Color="#2A2C2F">{item.disbursementName}</Text>
                        <Text Color="#54575C" FontSize="0.875rem">
                            {item.disbursementDetails}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            ))}
        </ContainerFlex>
    );
};
