import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import axios, {AxiosError, AxiosResponse} from 'axios';

import {UPDATE_COMPANY_DATA, GET_COMPANY_DATA} from '@MyCompany/Redux/Types/types';
import {IUpdateCompanyInformation} from '@/components/MyCompany/Interface';

export const updateCompanyStart = () => ({
    type: UPDATE_COMPANY_DATA.START_DATA,
});

export const updateCompanySuccess = (result: AxiosResponse) => ({
    type: UPDATE_COMPANY_DATA.SUCCESS_DATA,
    updateCompany: result,
});

export const updateCompanyError = (err: AxiosError) => ({
    type: UPDATE_COMPANY_DATA.ERROR_DATA,
    error: err,
});

export const updateCompanyNotUpdated = () => ({
    type: UPDATE_COMPANY_DATA.COMPANY_NOT_UPDATED,
});
export const updateCompanyAction = (
    token: string,
    companyInformation: IUpdateCompanyInformation
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(updateCompanyStart());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateCompanyInformation}`,
                companyInformation,
                {headers}
            );
            dispatch(updateCompanySuccess(response));
        } catch (error) {
            dispatch(updateCompanyError(error as AxiosError));
        }
    };
};

export const loadDataCompanyStart = () => ({
    type: GET_COMPANY_DATA.START_DATA_EXIST,
});

export const loadDataCompanySuccess = (result: AxiosResponse) => ({
    type: GET_COMPANY_DATA.SUCCESS_DATA_EXIST,
    companyData: result.data,
});

export const loadDataCompanyError = (err: AxiosError) => ({
    type: GET_COMPANY_DATA.ERROR_DATA_EXIST,
    error: err,
});

export const loadDataCompany = (token: string, CompanyId: number) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(loadDataCompanyStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.AddBackEmployee}?CompanyId=${CompanyId}`,
                {headers}
            );
            dispatch(loadDataCompanySuccess(response.data));
        } catch (error) {
            dispatch(loadDataCompanyError(error as AxiosError));
        }
    };
};
