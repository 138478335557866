import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {numberToCurrency} from '@/hooks/currentFormatUtils';
import {cartContracts} from '@components/ShoppingCart/interface';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {IPayGatewaySummaryProps} from '@Loan/PaymentQuoter/Interfaces';
import {PAYMENT_GATEWAY} from '@Loan/PaymentQuoter/constants';
import {ContainerFlex, Text, Image, Line} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import EndorsementSummary from '@Loan/PaymentQuoter/EndorsementSummary';
import FulfillingSummary from '@Loan/PaymentQuoter/FulfillingSummary';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {IEndorsement} from '@/components/ShoppingCart/Redux/interfaces';

const PaymentGatewaySummary = ({clientData, onPay}: IPayGatewaySummaryProps) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const carShoppingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const {cartData} = useSelector((state: RootState) => state.getCartByCartId);
    const loading = useSelector((state: RootState) => state.cartPaymentReducer.loading);
    const contracts: cartContracts[] | null = useSelector(
        (state: RootState) => state.getCartContracts.data
    );

    const calculateTotalEndorsements = (endorsements: IEndorsement[]): number => {
        return endorsements.reduce((total: number, endorsement: IEndorsement) => {
            return total + (endorsement?.subEndorsement ?? 0);
        }, 0);
    };

    const totalEndorsementsSum: number = calculateTotalEndorsements(cartData?.endorsements || []);

    useEffect(() => {
        if (token && carShoppingId && contracts) {
            dispatch(fetchCartById(token, carShoppingId));
        }
    }, [contracts]);

    return (
        <ContainerFlex
            Padding="1.5rem"
            FlexDir="column"
            Align="start"
            Gap="1.5rem"
            Self="stretch"
            backG="#FFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Width="100%"
        >
            <Text FontSize="1.25rem" FontWeight="700" Color="#1D1E20" LetterSpacing="0.019rem">
                {PAYMENT_GATEWAY.CLIENT}
            </Text>
            <FlexPanel FlexDir="column" Align="flex-start" Gap="1rem" Self="stretch">
                <FlexPanel Align="center" Gap="0.5rem" Flex="1 0 0">
                    <Image
                        alt="client-img"
                        src={clientData.image}
                        Width="2.375rem"
                        Height="2.375rem"
                        Radius="3rem"
                        Background="#ACACFF"
                    />
                    <Text
                        FontSize="1.25rem"
                        FontWeight="700"
                        Color="#1D1E20"
                        LetterSpacing="0.019rem"
                        Transform="capitalize"
                    >
                        {clientData.clientName}
                    </Text>
                </FlexPanel>
                <Line />
                <ContainerFlex FlexDir="column" Justify="flex-start" Gap="0.5rem">
                    <EndorsementSummary />
                    <FulfillingSummary />
                </ContainerFlex>
                <Text
                    Color="#2A2C2F"
                    FontSize="1rem"
                    FontWeight="700"
                    LetterSpacing="0.019rem"
                    oFlow="visible"
                >
                    {PAYMENT_GATEWAY.PAYMENT_SUMMARY}
                </Text>
                <ContainerFlex FlexDir="column" Gap="0.5rem" backG="#FFF" Width="90%">
                    {totalEndorsementsSum > 0 ? (
                        <ContainerFlex Justify="space-between">
                            <Text
                                Color="#2A2C2F"
                                TextAlign="right"
                                FontSize="1rem"
                                FontWeight="400"
                                LetterSpacing="0.019rem"
                            >
                                {PAYMENT_GATEWAY.ENDORSEMENTS_PAYMENT}
                            </Text>
                            <Text
                                Color="#1D1E20"
                                TextAlign="right"
                                FontSize="1rem"
                                FontWeight="400"
                                LetterSpacing="0.019rem"
                            >
                                {numberToCurrency(totalEndorsementsSum)}
                            </Text>
                        </ContainerFlex>
                    ) : null}
                    {cartData?.totalExtension && cartData?.totalExtension > 0 ? (
                        <ContainerFlex Justify="space-between">
                            <Text
                                Color="#2A2C2F"
                                TextAlign="right"
                                FontSize="1rem"
                                FontWeight="400"
                                LetterSpacing="0.019rem"
                            >
                                {PAYMENT_GATEWAY.EXTENSION}
                            </Text>
                            <Text
                                Color="#1D1E20"
                                TextAlign="right"
                                FontSize="1rem"
                                FontWeight="400"
                                LetterSpacing="0.019rem"
                            >
                                {numberToCurrency(cartData?.totalExtension || 0)}
                            </Text>
                        </ContainerFlex>
                    ) : null}
                    <ContainerFlex Justify="space-between">
                        <Text
                            Color="#2A2C2F"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {PAYMENT_GATEWAY.SUBTOTAL}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {numberToCurrency(cartData?.subTotal || 0)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex Justify="space-between">
                        <Text
                            Color="#2A2C2F"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {PAYMENT_GATEWAY.IVA}
                        </Text>
                        <Text
                            Color="#1D1E20"
                            TextAlign="right"
                            FontSize="1rem"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {numberToCurrency(cartData?.iva || 0)}
                        </Text>
                    </ContainerFlex>
                    {cartData?.totalPass && cartData?.totalPass > 0 ? (
                        <ContainerFlex
                            Padding="0.5rem"
                            Justify="space-between"
                            AlignSelf="stretch"
                            MinH="none"
                            backG="#F0F0FF"
                            Radius="0.5rem"
                        >
                            <Text
                                Color="#2A2C2F"
                                TextAlign="right"
                                FontSize="1rem"
                                FontWeight="400"
                                LetterSpacing="0.019rem"
                            >
                                {PAYMENT_GATEWAY.TOTAL_PASS}
                            </Text>
                            <Text
                                Color="#1D1E20"
                                TextAlign="right"
                                FontSize="1rem"
                                FontWeight="400"
                                LetterSpacing="0.019rem"
                            >
                                {numberToCurrency(cartData?.totalPass || 0)}
                            </Text>
                        </ContainerFlex>
                    ) : null}
                    <ContainerFlex Justify="space-between">
                        <Text Color="#2A2C2F" TextAlign="right" FontSize="1.25rem" FontWeight="700">
                            {PAYMENT_GATEWAY.TOTAL}
                        </Text>
                        <Text Color="#1D1E20" TextAlign="right" FontSize="1.25rem" FontWeight="700">
                            {numberToCurrency(cartData?.total || 0)}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <Line />
                <ButtonGenerals
                    Width="100%"
                    Cursor="pointer"
                    type="button"
                    onClick={onPay}
                    disabled={loading}
                >
                    {loading && <LoadingAtaskate width="0.625rem" padding="0.375rem" />}
                    {loading ? PAYMENT_GATEWAY.PROCESSING : PAYMENT_GATEWAY.PAY}
                </ButtonGenerals>
            </FlexPanel>
        </ContainerFlex>
    );
};
export default PaymentGatewaySummary;
