export const GET_ALL_CAT_FREQUENCY_START = 'GET_ALL_CAT_FREQUENCY_START';
export const GET_ALL_CAT_FREQUENCY_SUCCESS = 'GET_ALL_CAT_FREQUENCY_SUCCESS';
export const GET_ALL_CAT_FREQUENCY_ERROR = 'GET_ALL_CAT_FREQUENCY_ERROR';

export const GET_ALL_CAT_PERSONAL_LOANS_PERIOD_START = 'GET_ALL_CAT_PERSONAL_LOANS_PERIOD_START';
export const GET_ALL_CAT_PERSONAL_LOANS_PERIOD_SUCCESS =
    'GET_ALL_CAT_PERSONAL_LOANS_PERIOD_SUCCESS';
export const GET_ALL_CAT_PERSONAL_LOANS_PERIOD_ERROR = 'GET_ALL_CAT_PERSONAL_LOANS_PERIOD_ERROR';

export const GET_QUOTE_CREDIT_PERSONAL_LOANS_START = 'GET_QUOTE_CREDIT_PERSONAL_LOANS_START';
export const GET_QUOTE_CREDIT_PERSONAL_LOANS_SUCCESS = 'GET_QUOTE_CREDIT_PERSONAL_LOANS_SUCCESS';
export const GET_QUOTE_CREDIT_PERSONAL_LOANS_ERROR = 'GET_QUOTE_CREDIT_PERSONAL_LOANS_ERROR';

export const GET_TABLE_AMORTIZATION_START = 'GET_TABLE_AMORTIZATION_START';
export const GET_TABLE_AMORTIZATION_SUCCESS = 'GET_TABLE_AMORTIZATION_SUCCESS';
export const GET_TABLE_AMORTIZATION_ERROR = 'GET_TABLE_AMORTIZATION_ERROR';

export const GET_AMOUNT_TERM_ID_START = 'GET_AMOUNT_TERM_ID_START';
export const GET_AMOUNT_TERM_ID_SUCCESS = 'GET_AMOUNT_TERM_ID_SUCCESS';
export const GET_AMOUNT_TERM_ID_ERROR = 'GET_AMOUNT_TERM_ID_ERROR';

export const SET_FREQUENCY_TERM_CREDIT_ID = 'SET_FREQUENCY_TERM_CREDIT_ID';

export const GET_QUOTER_COMPANIES_LOAN_START = 'GET_QUOTER_COMPANIES_LOAN_START';
export const GET_QUOTER_COMPANIES_LOAN_SUCCESS = 'GET_QUOTER_COMPANIES_LOAN_SUCCESS';
export const GET_QUOTER_COMPANIES_LOAN_ERROR = 'GET_QUOTER_COMPANIES_LOAN_ERROR';
export const GET_SELECTED_QUOTER_CREDIT = 'GET_SELECTED_QUOTER_CREDIT';

export const QUOTER_OTHER_LOANS_COMPANIES_START = 'QUOTER_OTHER_LOANS_COMPANIES_START';
export const QUOTER_OTHER_LOANS_COMPANIES_SUCCESS = 'QUOTER_OTHER_LOANS_COMPANIES_SUCCESS';
export const QUOTER_OTHER_LOANS_COMPANIES_ERROR = 'QUOTER_OTHER_LOANS_COMPANIES_ERROR';
export const COMPANY_OFFER_SELECTED = 'COMPANY_OFFER_SELECTED';
