import React, {useEffect, useState} from 'react';
import {
    ContainerFlex,
    Text,
    ContainerCellGrid,
    Input,
    ContainerForm,
    Image,
} from '@/components/Shopify/Ecommerce/styles';
import {useSelector, useDispatch} from 'react-redux';
import {AppDispatch, RootState} from '@/config/store';
import {
    ACCESSPAYMENT,
    IMAGE_ATL_DESCRIPTION,
    TYPE_ACCESS,
} from '@components/QuickPayment/Constants';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {SelectLada} from '@Quoter/CoownerBeneficiary/SelectLada';
import {AccessDataProps, ITypeAccess} from '@components/QuickPayment/interfaces';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import {getSchemaAccessPayment} from '@components/QuickPayment/schemaQuickPayment';
import ButtonGeneral from '@/components/General/Atoms/Button';
import * as content from '@/components/QuickPayment/Styles';
import ErrorMessage from '@/components/QuickPayment/ErrorMessage';
import {selectActionState} from '@/components/QuickPayment/Redux/Actions/GetActionPayment';
import {
    getFastPaymentPersonalAction,
    updateTypeAccessSelected,
} from '@/components/QuickPayment/Redux/Actions/GetFastPaymentPersonalActions';
import infoIcon from '@/components/QuickPayment/icons/infoIcon.svg';

export const AccessPayment = () => {
    const dispatch: AppDispatch = useDispatch();
    const {doesExist, hasPayments, data, typeAccessSelected} = useSelector(
        (state: RootState) => state.getFastPaymentPersonal
    );
    const [nationSelected, setNationSelected] = useState<IGetAllNations | null>(null);
    const {
        handleSubmit,
        formState: {errors},
        control,
    } = useForm<AccessDataProps>({
        resolver: yupResolver(getSchemaAccessPayment(typeAccessSelected.id, nationSelected)),
        defaultValues: {data: ''},
    });

    useEffect(() => {
        if (data && data.length > 0) {
            dispatch(selectActionState(2));
        }
    }, [data]);

    const onSubmit = (formData: AccessDataProps) => {
        let searchQuery = String(formData.data);
        if (typeAccessSelected.id === ACCESSPAYMENT.TYPE_OF_DATA.TELEPHONE.ID) {
            searchQuery = `${nationSelected?.lada}${searchQuery.replace(/\D/g, '')}`;
        }
        const newSelectedItem = {...typeAccessSelected, value: searchQuery};
        dispatch(updateTypeAccessSelected(newSelectedItem));
        dispatch(getFastPaymentPersonalAction({searchQuery}));
    };

    const handleTypeAccessSelected = (selectedItem: ITypeAccess) => {
        setNationSelected(null);
        dispatch(updateTypeAccessSelected(selectedItem));
    };

    const formatPhoneNumber = (value: string) => {
        return typeAccessSelected.id === ACCESSPAYMENT.TYPE_OF_DATA.TELEPHONE.ID
            ? value
                  .replace(/\D/g, '')
                  .replace(/^(\d{2})(\d)/, '($1) $2')
                  .replace(/(\d{4})(\d)/, '$1-$2')
            : value;
    };

    return (
        <ContainerForm
            BackG="transparent"
            Justify="center"
            Align="center"
            onSubmit={handleSubmit(onSubmit)}
            Gap="1.5rem"
        >
            <ContainerFlex {...content.AccessPay}>
                <ContainerFlex
                    Width="100%"
                    FlexDir="column"
                    Gap="16px"
                    Justify="start"
                    Align="start"
                >
                    <Text wSpace="normal" FontSize="1.5rem" FontWeight="700" Color="#1D1E20">
                        {ACCESSPAYMENT.TITLE}
                    </Text>
                    <ContainerFlex backG="#E8E9EA" Height="1px" FlexWrap="wrap" Color="#2A2C2F" />
                </ContainerFlex>
                <ContainerFlex
                    Width="100%"
                    FlexDir="column"
                    Gap="16px"
                    Justify="start"
                    Align="start"
                >
                    <Text wSpace="normal">{ACCESSPAYMENT.ACTION}</Text>
                </ContainerFlex>
                {!hasPayments && (
                    <ContainerFlex
                        Padding="16px"
                        backG="#DBF4FF"
                        Gap="8px"
                        Radius="8px"
                        Width="100%"
                        Align="start"
                        Justify="start"
                    >
                        <Image
                            src={infoIcon}
                            Width="24px"
                            Height="24px"
                            alt={IMAGE_ATL_DESCRIPTION.INFO_ICON}
                        />
                        <ContainerFlex FlexDir="column" Align="start" Justify="start">
                            <Text
                                Color="#004461"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                                FontWeight="700"
                            >
                                {' '}
                                {ACCESSPAYMENT.MSG_DOES_NOT_HAVE_PAYMENTS_BASE}
                            </Text>
                            <Text
                                Color="#004461"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                                FontWeight="700"
                            >
                                {' '}
                                {`${ACCESSPAYMENT.MSG_DOES_NOT_HAVE_PAYMENTS_COMPLEMENT}${typeAccessSelected.name}`}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                )}
                <ContainerFlex Width="100%" FlexDir="column" Gap="16px">
                    <ContainerFlex Height="3rem">
                        {TYPE_ACCESS.map((item) => (
                            <ContainerFlex
                                key={item.id}
                                Width="8.75rem"
                                Radius={content.AccessPay.accesstype(item.id)}
                                Border={`solid 1px ${
                                    typeAccessSelected.id === item.id ? '#5A5AFF' : '#E8E9EA'
                                }`}
                                onClick={() => handleTypeAccessSelected(item)}
                                Cursor="pointer"
                            >
                                <Text
                                    wSpace="normal"
                                    Color={
                                        typeAccessSelected.id === item.id ? '#5A5AFF' : '#1D1E20'
                                    }
                                    FontWeight={typeAccessSelected.id === item.id ? '700' : '400'}
                                    Cursor="pointer"
                                >
                                    {item.name}
                                </Text>
                            </ContainerFlex>
                        ))}
                    </ContainerFlex>
                    <ContainerFlex Height="40px">
                        <ContainerCellGrid {...content.AccessPay.grid}>
                            <ContainerFlex>
                                {typeAccessSelected.id ===
                                    ACCESSPAYMENT.TYPE_OF_DATA.TELEPHONE.ID && (
                                    <SelectLada
                                        handleNationSelected={setNationSelected}
                                        withoutToken={true}
                                    />
                                )}
                                <Controller
                                    control={control}
                                    name="data"
                                    render={({field}) => (
                                        <Input
                                            {...content.AccessPay.input}
                                            Radius={
                                                typeAccessSelected.id ===
                                                ACCESSPAYMENT.TYPE_OF_DATA.TELEPHONE.ID
                                                    ? '0px'
                                                    : '50px'
                                            }
                                            type="text"
                                            value={field.value}
                                            onChange={(e) =>
                                                field.onChange(formatPhoneNumber(e.target.value))
                                            }
                                        />
                                    )}
                                />
                            </ContainerFlex>
                            <ErrorMessage>
                                {errors?.data?.message ||
                                    (!doesExist &&
                                        `${ACCESSPAYMENT.MSG_DOES_NOT_EXIST_BASE} ${typeAccessSelected.name}`)}
                            </ErrorMessage>
                        </ContainerCellGrid>
                    </ContainerFlex>
                </ContainerFlex>

                <ContainerFlex>
                    <ButtonGeneral
                        width="100%"
                        height="40px"
                        text={ACCESSPAYMENT.CTA_PAYMENT}
                        cursor="pointer"
                        type="submit"
                    />
                </ContainerFlex>
            </ContainerFlex>
        </ContainerForm>
    );
};
