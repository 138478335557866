import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getAllCoowners} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/GetAllCoOwnerActions';
import {ContainerFlex, Text} from '@Shopify/Ecommerce/styles';
import {IDataAllCoowner} from '@components/Quoter/CoownerBeneficiary/Redux/interfaces';
import {cartContracts} from '@components/ShoppingCart/interface';
import {saveIdContract} from '@Quoter/Redux/Actions/saveEndeavors';
import {IconSvg} from '@/components/Quoter/styles';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {
    coOwnerContractProps,
    IContractCoowners,
} from '@components/Quoter/CoownerBeneficiary/interface';
import {COOWNER_CONTRACT} from '@components/Quoter/CoownerBeneficiary/constants';
import {fetchSelectEditCoOwner} from '@components/Quoter/CoownerBeneficiary/Redux/Actions/UpdateCoOwner';
import {ErrorCoOwnerBeneficiary} from '@/components/Quoter/CoownerBeneficiary/ErrorCoOwnerBeneficiary';

export const CoOwnerContract: React.FC<coOwnerContractProps> = ({
    setShowEdit,
    contractCoownersList,
    setContractCoownersList,
    switchCheked,
}) => {
    const [height, setHeight] = useState('auto');

    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const dispatch = useDispatch();

    const clientList = useSelector((state: RootState) => state.clientList);
    const {client} = clientList;
    const {id} = client;

    const getAllCoOwners = () => {
        if (token) {
            dispatch(getAllCoowners(token, {clientId: id}));
        }
    };

    const AllCoOwners: IDataAllCoowner[] = useSelector(
        (state: RootState) => state.getAllCoowner.data
    );
    const getAllCoowner = useSelector((state: RootState) => state.getAllCoowner);
    const {idContract} = useSelector((state: RootState) => state.saveEndeavor);

    const contracts: cartContracts[] | null = useSelector(
        (state: RootState) => state.getCartContracts.data
    );

    const handleClickContract = (contractid: number) => {
        dispatch(saveIdContract(contractid));
        getAllCoOwners();
    };

    useEffect(() => {
        const contractId = contracts![0].contractId;
        if (contracts!.length && contractId !== idContract) {
            handleClickContract(contractId);
        }
    }, []);

    const [coOwnerItems, setCoOwnerItems] = useState(1);

    const handleClickShowMore = () => {
        setCoOwnerItems(AllCoOwners.length);
        setHeight('410px');
    };

    const handleClikShowLess = () => {
        setCoOwnerItems(1);
        setHeight('auto');
    };

    const handleChecked = (
        contractId: number,
        contactId: number,
        contact1: 'coownerId1' | 'beneficiaryId1',
        contact2: 'coownerId2' | 'beneficiaryId2'
    ) => {
        const contractCoownersCopy: IContractCoowners[] = JSON.parse(
            JSON.stringify(contractCoownersList)
        );

        const index = contractCoownersCopy.findIndex((item) => item.contractId === contractId);
        if (index === -1) {
            setContractCoownersList([
                ...contractCoownersCopy,
                {
                    contractId,
                    coownerId1: contact1 === 'coownerId1' ? contactId : 0,
                    coownerId2: 0,
                    beneficiaryId1: contact1 === 'beneficiaryId1' ? contactId : 0,
                    beneficiaryId2: 0,
                },
            ]);
        } else {
            const contractCoownersElement = contractCoownersCopy[index];

            if (contractCoownersElement[contact1] === contactId) {
                contractCoownersElement[contact1] = 0;
            } else if (contractCoownersElement[contact2] === contactId) {
                contractCoownersElement[contact2] = 0;
            } else if (contractCoownersElement[contact1] === 0) {
                contractCoownersElement[contact1] = contactId;
            } else {
                contractCoownersElement[contact2] = contactId;
            }

            const {coownerId1, coownerId2, beneficiaryId1, beneficiaryId2} =
                contractCoownersElement;
            if (
                coownerId1 === 0 &&
                coownerId2 === 0 &&
                beneficiaryId1 === 0 &&
                beneficiaryId2 === 0
            ) {
                contractCoownersCopy.splice(index, 1);
            }

            setContractCoownersList([...contractCoownersCopy]);
        }
    };

    const indexCoowners = contractCoownersList.findIndex((item) => item.contractId === idContract);
    const {
        coownerId1 = 0,
        coownerId2 = 0,
        beneficiaryId1 = 0,
        beneficiaryId2 = 0,
    } = contractCoownersList[indexCoowners] || {};

    const showError =
        indexCoowners !== -1 &&
        coownerId1 !== 0 &&
        coownerId2 !== 0 &&
        beneficiaryId1 !== 0 &&
        beneficiaryId2 !== 0;

    return (
        <ContainerFlex FlexDir="column">
            <ContainerFlex Justify="start" MaxH="100vh" OverFlowX="scroll">
                {contracts &&
                    contracts.length > 0 &&
                    contracts.map((contract, index: number) => (
                        <ContainerFlex
                            key={index}
                            Width="auto"
                            Padding="0.5rem 1rem"
                            Bb={
                                contract.contractId === idContract
                                    ? '1px solid #5A5AFF'
                                    : '1px solid #D4D6D8'
                            }
                        >
                            <Text
                                Color={contract.contractId === idContract ? '#5A5AFF' : '#54575C'}
                                BorderBt={
                                    contract.contractId === idContract ? '#5A5AFF' : '#54575C'
                                }
                                FontSize="1rem"
                                FontWeight="500"
                                Cursor="pointer"
                                onClick={() => handleClickContract(contract.contractId)}
                            >
                                {contract.contractName}
                            </Text>
                        </ContainerFlex>
                    ))}
            </ContainerFlex>

            {showError && <ErrorCoOwnerBeneficiary />}
            {switchCheked && getAllCoowner && getAllCoowner?.data[0]?.contactId !== 0 && (
                <>
                    <ContainerFlex
                        Justify="start"
                        Align="start"
                        Height={height}
                        FlexDir="column"
                        OverFlow="auto"
                        Position="relative"
                        Gap=".4rem"
                    >
                        {AllCoOwners.slice(0, coOwnerItems).map((coOwner, index: number) => {
                            const {contactId} = coOwner;
                            const isCoownerSelected =
                                contactId === coownerId1 || contactId === coownerId2;
                            const isBeneficiarySelected =
                                contactId === beneficiaryId1 || contactId === beneficiaryId2;

                            const isDisabledCoowner =
                                indexCoowners !== -1 &&
                                coownerId1 !== 0 &&
                                coownerId2 !== 0 &&
                                !isCoownerSelected;
                            const isDisabledBeneficiary =
                                indexCoowners !== -1 &&
                                beneficiaryId1 !== 0 &&
                                beneficiaryId2 !== 0 &&
                                !isBeneficiarySelected;

                            const bgColorCoowner = isDisabledCoowner
                                ? '#E8E9EA'
                                : indexCoowners !== -1 && isCoownerSelected
                                ? 'transparent'
                                : '#FFF';
                            const bgColorBeneficiary = isDisabledBeneficiary
                                ? '#E8E9EA'
                                : indexCoowners !== -1 && isBeneficiarySelected
                                ? 'transparent'
                                : '#FFF';
                            const borderCoowner = `1px solid ${
                                isDisabledCoowner ? '#D4D6D8' : '#5a5aff'
                            }`;
                            const borderBeneficiary = `1px solid ${
                                isDisabledBeneficiary ? '#D4D6D8' : '#5a5aff'
                            }`;

                            return (
                                <ContainerFlex
                                    FlexDir="column"
                                    Padding="0.75rem 1rem"
                                    Gap="1rem"
                                    key={coOwner.contactId}
                                    backG="#FAFAFA"
                                    Justify="start"
                                    Align="start"
                                    Height="auto"
                                    Radius="0.5rem"
                                >
                                    <ContainerFlex Justify="space-between">
                                        <ContainerFlex
                                            Align="start"
                                            Justify="start"
                                            Gap="0.5rem"
                                            Height="auto"
                                        >
                                            <Checkbox
                                                onChange={() =>
                                                    handleChecked(
                                                        idContract,
                                                        coOwner.contactId,
                                                        'coownerId1',
                                                        'coownerId2'
                                                    )
                                                }
                                                disabled={isDisabledCoowner}
                                                checked={
                                                    indexCoowners !== -1 &&
                                                    (coownerId1 === coOwner.contactId ||
                                                        coownerId2 === coOwner.contactId)
                                                }
                                                border={borderCoowner}
                                                backgroundColor={bgColorCoowner}
                                            />
                                            <Text MarginRight="0.5rem">
                                                {COOWNER_CONTRACT.CoOwner}
                                            </Text>
                                            <Checkbox
                                                onChange={() =>
                                                    handleChecked(
                                                        idContract,
                                                        coOwner.contactId,
                                                        'beneficiaryId1',
                                                        'beneficiaryId2'
                                                    )
                                                }
                                                disabled={isDisabledBeneficiary}
                                                checked={
                                                    indexCoowners !== -1 &&
                                                    (beneficiaryId1 === coOwner.contactId ||
                                                        beneficiaryId2 === coOwner.contactId)
                                                }
                                                border={borderBeneficiary}
                                                backgroundColor={bgColorBeneficiary}
                                            />
                                            <Text>{COOWNER_CONTRACT.Beneficiary}</Text>
                                        </ContainerFlex>
                                        <IconSvg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            Cursor="pointer"
                                            onClick={() => {
                                                setShowEdit(true);
                                                dispatch(fetchSelectEditCoOwner(coOwner));
                                            }}
                                        >
                                            <path
                                                d="M5 19H6.4L15.025 10.375L13.625 8.975L5 17.6V19ZM19.3 8.925L15.05 4.725L16.45 3.325C16.8333 2.94167 17.3043 2.75 17.863 2.75C18.421 2.75 18.8917 2.94167 19.275 3.325L20.675 4.725C21.0583 5.10833 21.2583 5.571 21.275 6.113C21.2917 6.65433 21.1083 7.11667 20.725 7.5L19.3 8.925ZM17.85 10.4L7.25 21H3V16.75L13.6 6.15L17.85 10.4Z"
                                                fill="#5A5AFF"
                                            />
                                        </IconSvg>
                                    </ContainerFlex>
                                    <ContainerFlex
                                        FlexDir="column"
                                        Align="start"
                                        Gap="0.25rem"
                                        Height="auto"
                                    >
                                        <Text Color="#2A2C2F" FontSize="1rem" FontWeight="500">
                                            {`${coOwner.names} ${coOwner.lastName} ${coOwner.secondLastName}`}
                                        </Text>
                                        <Text>
                                            <Text PaddingR="0.5rem">{COOWNER_CONTRACT.Phone}:</Text>
                                            <Text PaddingR="0.6rem">{coOwner.phone}</Text>
                                            <Text PaddingR="0.5rem">{COOWNER_CONTRACT.Email}:</Text>
                                            <Text>{coOwner.email}</Text>
                                        </Text>
                                    </ContainerFlex>
                                </ContainerFlex>
                            );
                        })}
                    </ContainerFlex>
                    {coOwnerItems < AllCoOwners.length && (
                        <ContainerFlex
                            Cursor="pointer"
                            Gap="0.5rem"
                            Height="1.5rem"
                            MarginBt="1rem"
                            MarginTop="1rem"
                            onClick={() => handleClickShowMore()}
                        >
                            <Text Color="#5A5AFF" FontSize="1rem" FontWeight="500" Cursor="pointer">
                                {COOWNER_CONTRACT.Show_More}
                            </Text>
                            <IconSvg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                Cursor="pointer"
                            >
                                <path
                                    d="M5.69995 9.6998L7.09995 8.2998L11.7 12.8998L16.3 8.2998L17.7 9.6998L11.7 15.6998L5.69995 9.6998Z"
                                    fill="#5A5AFF"
                                />
                            </IconSvg>
                        </ContainerFlex>
                    )}
                    {coOwnerItems > 2 && (
                        <ContainerFlex
                            Cursor="pointer"
                            Gap="0.5rem"
                            Height="1.5rem"
                            MarginBt="1rem"
                            MarginTop="1rem"
                            onClick={() => handleClikShowLess()}
                        >
                            <Text Color="#5A5AFF" FontSize="1rem" FontWeight="500" Cursor="pointer">
                                {COOWNER_CONTRACT.Show_Less}
                            </Text>
                            <IconSvg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="24"
                                viewBox="0 0 25 24"
                                fill="none"
                                Cursor="pointer"
                            >
                                <path
                                    d="M18.5 13.9999L17.1 15.3999L12.5 10.7999L7.9 15.3999L6.5 13.9999L12.5 7.9999L18.5 13.9999Z"
                                    fill="#5A5AFF"
                                />
                            </IconSvg>
                        </ContainerFlex>
                    )}
                </>
            )}
        </ContainerFlex>
    );
};
