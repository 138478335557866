export const PAYMENT_AND_BILLING = {
    TITLE: 'Formas de pago',
    MY_METHODS: 'Mis métodos de pago',
    MY_TAXINFO: 'Mis datos fiscales',
    ADD_PAYMENT_METHOD: 'Agregar método de pago',
    MISSING_PAYMENT_PROMPT: 'Ingresa un método de pago',
    EDIT: 'Editar',
    COMPLETE_TAX_INFO: 'Completa tus datos fiscales',
    NO_INFO: 'Sin información',
    PRINCIPAL: 'Principal',
    ERROR: 'Error añadiendo método de pago.',
    TAX_INFO_ERROR: 'Error editando datos fiscales.',
};

export const PAYMENT_METHOD_LABELS = {
    TITLE: 'Nuevo método de pago',
    CARDHOLDER: 'Titular de tarjeta',
    CARDNUMBER: 'Número de tarjeta',
    MONTH: 'Mes',
    YEAR: 'Año',
    CVV: 'CVV',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
};

export const formNewPaymentMethod = {
    cardHolder: '',
    cardNumber: '',
    expiryMonth: '',
    expiryYear: '',
    cvv: '',
};

export const TAX_INFO_LABELS = {
    TITLE: 'Mis datos fiscales',
    RFC: 'RFC',
    EMAIL: 'Correo electrónico',
    POSTALCODE: 'C.P.',
    STREET: 'Calle',
    EXTERIOR_NUM: 'No. exterior',
    OPTIONAL: 'Opcional',
    INTERIOR_NUM: 'No. interior',
    COLONY: 'Colonia',
    MUNICUPALITY: 'Alcaldía',
    STATE: 'Estado',
    ADDRESS: 'Dirección',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
};

export const formEditTaxInfo = {
    rfc: '',
    email: '',
    cp: '',
    street: '',
    externalNumber: '',
    internalNumber: '',
    colonyId: '',
    municipalityId: '',
    stateId: '',
};

export const PaymentAdminOptionsText = [
    {adminOptions: 'Volver principal'},
    {adminOptions: 'Eliminar'},
];

export const MAX_PAYMENT_METHODS_QUANTITY = 5;
export const NO_DATA = 'Sin información';
