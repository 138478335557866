import React from 'react';

import {ClientInfoProps} from '@Shopify/ClientProfile/interfaces';
import {CLIENT_PROFILE} from '@Shopify/ClientProfile/constants';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import arrowIcon from '@images/arrowUpIcon.svg';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import ContactInfo from '@Shopify/ClientProfile/ContactInfo';
import FinancialInfo from '@Shopify/ClientProfile/FinancialInfo';

const ClientInfo: React.FC<ClientInfoProps> = ({clientProfile, viewingInfo, toggleInfo}) => {
    return (
        <>
            <div>
                <Image
                    src={clientProfile.image}
                    Width="4rem"
                    Height="4rem"
                    Radius="50%"
                    ObjectFit="cover"
                />
            </div>
            <FlexPanel Align="flex-start" FlexDir="column" Gap="0.5rem">
                <ContainerFlex Gap="0.5rem" Align="center">
                    <Text
                        Transform="capitalize"
                        FontSize="1.5rem"
                        Color="#1D1E20"
                        FontWeight="700"
                        wSpace="nowrap"
                    >
                        {clientProfile.clientName}
                    </Text>
                    <ContainerFlex Padding="0.125rem 0" Gap="0.25rem" Justify="start">
                        <Text
                            FontSize="1rem"
                            Color="#2A2C2F"
                            FontWeight="400"
                            LetterSpacing="0.019rem"
                        >
                            {CLIENT_PROFILE.NUC}:
                        </Text>
                        <Text
                            FontSize="1rem"
                            Color="#2A2C2F"
                            FontWeight="700"
                            LetterSpacing="0.019rem"
                        >
                            {clientProfile.nuc}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContactInfo clientProfile={clientProfile} />
                <FinancialInfo />
                <ContainerFlex
                    Align="center"
                    Gap="0.25rem"
                    Width="7rem"
                    Cursor="pointer"
                    onClick={() => toggleInfo(!viewingInfo)}
                >
                    <Text Cursor="pointer" FontSize="0.75rem" Color="#5A5AFF" FontWeight="700">
                        {CLIENT_PROFILE.MORE_INFORMATION}
                    </Text>
                    <Image
                        src={arrowIcon}
                        Width="1rem"
                        Height="1rem"
                        Transition="transform 0.3s ease"
                        Transform={viewingInfo ? 'rotate(180deg)' : 'rotate(0deg)'}
                    />
                </ContainerFlex>
            </FlexPanel>
        </>
    );
};
export default ClientInfo;
