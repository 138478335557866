import {Controller} from 'react-hook-form';
import React from 'react';
import {Checkbox} from '@components/General/Atoms/Checkbox/Checkbox';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {ICheckboxGenderProps} from '@components/PersonalLoans/NewLoanProduct/LoanDocuments/loanDocumentsInterfaces';

export const CheckboxGender: React.FC<ICheckboxGenderProps> = ({
    control,
    name,
    label,
    handleChange,
    isChecked,
}) => {
    return (
        <ContainerFlex Justify="start" Gap="0.5rem" Width="5.75rem">
            <Controller
                name={name}
                control={control}
                render={({field: {onChange, ...field}}) => (
                    <Checkbox
                        {...field}
                        checked={isChecked}
                        onChange={(e) => {
                            onChange(e);
                            handleChange();
                        }}
                    />
                )}
            />
            <Text Color="#2A2C2F">{label}</Text>
        </ContainerFlex>
    );
};
