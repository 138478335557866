import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_FAST_PAYMENT_PL_ERROR,
    GET_FAST_PAYMENT_PL_START,
    GET_FAST_PAYMENT_PL_SUCCESS,
    SELECT_TYPE_ACCESS,
    RESET_FAST_PAYMENT_PL_DATA,
} from '@/components/QuickPayment/Redux/Types/Types';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants/index';
import {ITypeAccess} from '@/components/QuickPayment/interfaces';

const getFastPaymentPersonalStart = () => {
    return {
        type: GET_FAST_PAYMENT_PL_START,
    };
};

const getFastPaymentPersonalSuccess = (result: AxiosResponse) => {
    return {
        type: GET_FAST_PAYMENT_PL_SUCCESS,
        payload: result,
    };
};

const getFastPaymentPersonalError = (error: AxiosError) => {
    return {
        type: GET_FAST_PAYMENT_PL_ERROR,
        error: error,
    };
};

export const resetFastPaymentPlData = () => {
    return {
        type: RESET_FAST_PAYMENT_PL_DATA,
    };
};

export const updateTypeAccessSelected = (typeAccessSelected: ITypeAccess) => {
    return {
        type: SELECT_TYPE_ACCESS,
        payload: typeAccessSelected,
    };
};

export const getFastPaymentPersonalAction = ({searchQuery}: {searchQuery: string}) => {
    const encodedSearchQuery = encodeURIComponent(searchQuery);
    const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetFastPaymentPersonalLoans}?search=${encodedSearchQuery}`;
    const headers = {
        Accept: 'application/json',
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getFastPaymentPersonalStart());
        try {
            const response = await axios.get(url, {headers});
            dispatch(getFastPaymentPersonalSuccess(response));
        } catch (e) {
            dispatch(getFastPaymentPersonalError(e as AxiosError));
        }
    };
};
