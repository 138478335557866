import React from 'react';
import {ContainerFlex, Image} from '@components/Shopify/Ecommerce/styles';
import {
    ALTS,
    CREDIT_ID,
    CREDIT_STATUS,
} from '@/components/MyAccount/MyCredit/CreditDetails/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import addShoppingCartIcon from '@/components/MyAccount/MyCredit/CreditDetails/icons/addShoppingCartIcon.svg';
import {RootState} from '@/config/store';
import {useSelector, useDispatch} from 'react-redux';
import {routers} from '@/appRoute';
import {useNavigate, useParams, NavigateFunction} from 'react-router-dom';
import {addCreditToShoppingCarAction} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Actions/ShoppingCarClientActions';
import {ICreditStatus} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';
import {showRejectModalAction} from './Redux/Actions/GetPersonalCreditAmountActions';

const CreditDetailsInformationStatusButtons: React.FC<ICreditStatus> = ({
    creditStatus,
    hasButtons,
    cta_primary,
    cta_secondary,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {creditId: personalCreditId} = useParams<{creditId?: string}>();
    const {token, carShoopingId} = useSelector((state: RootState) => {
        const userData = state.getUsersValidation.userData;
        return {
            token: String(userData?.token),
            carShoopingId: Number(userData?.carShoopingId),
        };
    });
    const handleAddShoppingCart = (navigateFun?: NavigateFunction) => {
        if (token && carShoopingId && personalCreditId) {
            dispatch(
                addCreditToShoppingCarAction(
                    Number(personalCreditId),
                    String(token),
                    String(carShoopingId),
                    navigateFun
                )
            );
        }
    };

    const handleNavigateTo = () => {
        if (creditStatus === CREDIT_STATUS.REJECTED) {
            navigate(routers.CreditQuoter);
        } else {
            handleAddShoppingCart(navigate);
        }
        handleAddShoppingCart(navigate);
    };
    const handleCreditAccepted = () => {
        const PersonalLoanApprovedUrl = routers.PersonalLoansApproved.replace(
            CREDIT_ID,
            String(personalCreditId)
        );
        navigate(PersonalLoanApprovedUrl);
    };

    const handleOpenRejectModal = () => dispatch(showRejectModalAction());

    return (
        <ContainerFlex Width="100%" Gap="16px" Justify="end">
            {hasButtons && (
                <>
                    {cta_secondary && (
                        <ButtonGenerals
                            SecondaryButton={
                                creditStatus !== CREDIT_STATUS.APPROVED ? '#FFFFFF' : ''
                            }
                            Color={creditStatus === CREDIT_STATUS.APPROVED ? '#A82424' : ''}
                            BackGC={creditStatus === CREDIT_STATUS.APPROVED ? '#FFFFFF' : ''}
                            HBackG={creditStatus === CREDIT_STATUS.APPROVED ? '#FFFFFF' : ''}
                            Width="100%"
                            Height="40px"
                            Gap="4px"
                            Padding="4px 16px"
                            Justify="center"
                            FontFamily="Nunito"
                            FontSize="0.88rem"
                            FontWeight="700"
                            onClick={
                                creditStatus === CREDIT_STATUS.APPROVED
                                    ? handleOpenRejectModal
                                    : () => handleAddShoppingCart()
                            }
                        >
                            {(creditStatus === CREDIT_STATUS.UP_TO_DATE ||
                                creditStatus === CREDIT_STATUS.LATE) && (
                                <Image
                                    Width="24px"
                                    Height="24px"
                                    src={addShoppingCartIcon}
                                    alt={ALTS.ADD_SHOPPING_CART}
                                />
                            )}
                            {cta_secondary?.text}
                        </ButtonGenerals>
                    )}
                    <ButtonGenerals
                        BackGC="#5A5AFF"
                        Color="#FFF"
                        Width={creditStatus === CREDIT_STATUS.REJECTED ? '192px' : '100%'}
                        Height="40px"
                        Gap="8px"
                        Padding="8px 16px"
                        Justify="center"
                        FontFamily="Nunito"
                        FontSize="0.88rem"
                        FontWeight="700"
                        onClick={
                            creditStatus === CREDIT_STATUS.APPROVED
                                ? handleCreditAccepted
                                : handleNavigateTo
                        }
                    >
                        {cta_primary?.text}
                    </ButtonGenerals>
                </>
            )}
        </ContainerFlex>
    );
};

export default CreditDetailsInformationStatusButtons;
