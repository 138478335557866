import React from 'react';
import {IModulesAdministrator} from '@/components/SideBarMenu/interfaces';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {useNavigate} from 'react-router-dom';

const MenuItem: React.FC<IModulesAdministrator> = ({title, route}) => {
    const navigate = useNavigate();
    return (
        <ContainerFlex Justify="start" Align="start" Padding="8px 8px 8px 32px" Height="40px">
            <Text
                FontFamily="Work Sans"
                FontSize="1.0rem"
                FontWeight="400"
                Color="#2A2C2F"
                Cursor="pointer"
                onClick={() => navigate(route)}
            >
                {title}
            </Text>
        </ContainerFlex>
    );
};

export default MenuItem;
