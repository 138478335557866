import React from 'react';
import checkIcon from '@components/PersonalLoans/NewLoanProduct/icons/checkIcon.svg';
import {ButtonSubmit} from '@components/PersonalLoans/NewLoanProduct/styles';
import {Image, Text} from '@/components/Shopify/Ecommerce/styles';
import {CHARGES_AND_PENALTIES} from '@components/PersonalLoans/NewLoanProduct/constants';
import {ISubmitButtonProps} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/interfaces';

export const SubmitButton: React.FC<ISubmitButtonProps> = ({handleClick}) => {
    return (
        <ButtonSubmit
            Gap="0.5rem"
            Padding="0.5rem 1rem"
            Height="32px"
            Width="max-content"
            type="button"
            onClick={handleClick}
            Cursor="pointer"
        >
            <Image src={checkIcon} Width="24px" Height="24px" alt="check-icon" />
            <Text FontSize="0.875rem" Color="#5A5AFF" FontWeight="700" Cursor="pointer">
                {CHARGES_AND_PENALTIES.BTN_APPLY}
            </Text>
        </ButtonSubmit>
    );
};
