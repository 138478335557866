import styled from '@emotion/styled';
import {ToastType} from '@General/Atoms/AppToast/interfaces';
import {toastColors} from '@General/Atoms/AppToast/constants';

export const ToastContainer = styled.div<{type: ToastType; isVisible: boolean; size: string}>`
    display: flex;
    flex-direction: ${({size}) => (size === 'small' ? 'column' : 'row')};
    width: ${({size}) => (size === 'small' ? '19.5rem' : '33.125rem')};
    gap: 1.5rem;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem;
    border-radius: 0.5rem;
    background-color: ${({type}) => toastColors[type].background};
    margin: 0.5rem 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
    transform: translateY(${({isVisible}) => (isVisible ? '0' : '-20px')});
    transition: transform 0.3s ease-in-out;
`;

export const ToastsWrapper = styled.div`
    position: fixed;
    top: 20px;
    right: 20px;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
