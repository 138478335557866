import {AxiosResponse} from 'axios';
import {
    POST_ADD_CHECK_IDENTITY_ERROR,
    POST_ADD_CHECK_IDENTITY_START,
    POST_ADD_CHECK_IDENTITY_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';

const initialState = {
    loading: false,
    error: false,
    data: {},
};

const postAddCheckIdentity = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case POST_ADD_CHECK_IDENTITY_START:
            return {
                ...state,
                loading: true,
            };
        case POST_ADD_CHECK_IDENTITY_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case POST_ADD_CHECK_IDENTITY_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        default:
            return state;
    }
};

export default postAddCheckIdentity;
