import styled from '@emotion/styled/macro';
import hoverCheck from '@images/done.svg';
import {
    IButtonSubmit,
    IInputCustomContainer,
    IDatePickerInput,
    IInputsContainer,
} from '@components/PersonalLoans/NewLoanProduct/stylesInterfaces';
import {GridContainer} from '@/components/CreditQuoter/styles';

export const CheckBoxInput = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    border: 1px solid #5a5aff;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
`;

export const StyledCheckbox = styled.span`
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    cursor: pointer;
    box-sizing: border-box;
    z-index: 0;
`;
export const InputCheckbox = styled.input`
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    width: 18px;
    height: 18px;
    max-width: 18px;
    max-height: 18px;
    border: 1px solid #5a5aff;
    box-sizing: border-box;
    border-radius: 6px;
    cursor: pointer;
    display: grid;
    z-index: ${(props) => (props.disabled ? 0 : 1)};
    place-content: center;
    &:checked {
        border: none;
        &:hover {
            background-image: none;
        }
    }
    &:hover {
        border: 1px solid #5a5aff;
        background-image: url(${hoverCheck});
    }
`;

export const CheckedBox = styled.img`
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0;
    ${InputCheckbox}:checked + & {
        animation: checkAnimation 0.3s forwards;
        opacity: 1;
    }
    @keyframes checkAnimation {
        0% {
            transform: scale(0);
        }
        50% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(1);
        }
    }
`;

export const DatePickerInput = styled.div<IDatePickerInput>`
    background: #ffffff 0% 0% no-repeat padding-box;
    background-color: ${(props) => props.BackG};
    justify-content: ${(props) => props.Justify};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    position: ${(props) => props.Position};
    margin: ${(props) => props.Margin};
    margin-top: ${(props) => props.MarginTop};
    right: ${(props) => props.Right};
    top: 0;
    border: ${(props) => props.Border};
    border-radius: ${(props) => props.Radius};
    display: ${(props) => (props.Display ? props.Display : 'grid')};
    grid-template-columns: ${(props) => props.GridColumn};
    flex: ${(props) => props.Flex};
    align-items: center;
    padding: ${(props) => props.Padding};
    min-height: ${(props) => props.MinH};
    cursor: ${(props) => props.Cursor};
    border-color: ${(props) => (props.error ? '#ef4f55' : '#E8E9EA')};
    box-sizing: border-box;
    overflow: ${(props) => props.OverFlow};

    &:hover {
        border-color: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
        box-shadow: ${(props) => props.BoxShadowH};
    }
    &:focus-within {
        border-color: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
        box-shadow: ${(props) => props.BoxShadowF};
    }

    figure > svg > path {
        fill: ${(props) => (props.error ? '#ef4f55' : '#5A5AFF')};
    }
`;

DatePickerInput.defaultProps = {
    Height: '40px',
    Position: 'relative',
    GridColumn: '85% 15%',
    Width: '100%',
    Padding: '0 1rem',
    PlaceholderColor: '#A7A9AC',
    PlaceholderLHeight: '1rem',
    PlaceholderFontSize: '0.875rem',
    PlaceholderWeight: '400',
    BorderH: '1px solid #5A5AFF',
    BorderF: '1px solid #5A5AFF',
    Border: '1px solid #E8E9EA',
    Right: '1rem',
    MinH: '40px',
    Radius: '32px',
    WBoxShadow: '0 0 0px 1000px #fff inset',
    FontSize: '1rem',
    InputWidth: 'inherit',
};

export const InputsContainer = styled.div<IInputsContainer>`
    display: ${(props) => props.Display};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    flex-direction: ${(props) => props.FlexDir};
    gap: ${(props) => props.Gap};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    & > input {
        border: none;
        outline: none;
        font-size: ${(props) => props.FontSize};
        color: #050505;
        line-height: 16px;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: ${(props) => (props.InputPadding ? props.InputPadding : '0')};
        width: ${(props) => props.InputWidth};
        ::placeholder {
            color: ${(props) => props.PlaceholderColor};
            line-height: ${(props) => props.PlaceholderLHeight};
            font-size: ${(props) => props.PlaceholderFontSize};
            font-weight: ${(props) => props.PlaceholderWeight};
        }
        :disabled {
            background: #ffffff;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus {
            -webkit-box-shadow: ${(props) => props.WBoxShadow};
        }
    }
`;

InputsContainer.defaultProps = {
    Display: 'flex',
    Justify: 'center',
    Align: 'center',
};

export const InputCustomContainer = styled.div<IInputCustomContainer>`
    display: ${(props) => props.Display};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    flex-direction: ${(props) => props.FlexDir};
    gap: ${(props) => props.Gap};
    grid-template-columns: ${(props) => props.GridColumns};
    height: ${(props) => props.Height};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    border: ${(props) => props.Border};
    border-color: ${(props) => (props.error ? '#ef4f55' : '#E8E9EA')};
    border-radius: ${(props) => props.Radius};
    &:hover {
        border: ${(props) => props.BorderHover};
        box-shadow: ${(props) => props.BShadowHover};
    }
    &:focus {
        border: ${(props) => props.BorderFocus};
        box-shadow: ${(props) => props.BShadowFocus};
        outline: ${(props) => props.OutlineFocus};
    }
    & > input {
        border: none;
        outline: none;
        font-size: ${(props) => props.FontSize};
        color: #050505;
        line-height: 16px;
        height: 100%;
        box-sizing: border-box;
        margin: 0;
        padding: ${(props) => (props.InputPadding ? props.InputPadding : '0')};
        width: ${(props) => props.InputWidth};
        ::placeholder {
            color: ${(props) => props.PlaceholderColor};
            line-height: ${(props) => props.PlaceholderLHeight};
            font-size: ${(props) => props.PlaceholderFontSize};
            font-weight: ${(props) => props.PlaceholderWeight};
        }
        :disabled {
            background: #ffffff;
        }
        :-webkit-autofill,
        :-webkit-autofill:hover,
        :-webkit-autofill:focus {
            -webkit-box-shadow: ${(props) => props.WBoxShadow};
        }
    }
`;

InputCustomContainer.defaultProps = {
    Display: 'flex',
    Justify: 'center',
    Align: 'center',
    Border: '1px solid #E8E9EA',
    Radius: '2rem',
    BorderHover: '1px solid #5A5AFF',
};

export const ButtonSubmit = styled.button<IButtonSubmit>`
    display: ${(props) => props.Display};
    justify-content: ${(props) => props.Justify};
    align-items: ${(props) => props.Align};
    width: ${(props) => props.Width};
    height: ${(props) => props.Height};
    background: ${(props) => props.Background};
    gap: ${(props) => props.Gap};
    padding: ${(props) => props.Padding};
    margin: ${(props) => props.Margin};
    border-radius: ${(props) => props.Radius};
    border: ${(props) => props.Border};
    cursor: ${(props) => props.Cursor};
    outline: ${(props) => props.Outline};
`;

ButtonSubmit.defaultProps = {
    Display: 'flex',
    Justify: 'center',
    Align: 'center',
    Cursor: 'pointer',
    Radius: '2rem',
    Border: '1px solid #5A5AFF',
    Background: '#FFF',
};

export const RowTable = styled(GridContainer)`
    &:last-child {
        border-bottom: none;
    }
`;
