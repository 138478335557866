import styled from '@emotion/styled';

export const ImgProducto = styled.img`
    width: 64px;
    height: 64px;
    border-radius: 8px;
`;

export const TooltipContainer = styled.div`
    position: relative;
    display: inline-block;
    z-index: 1;
`;

export const TooltipContent = styled.div`
    position: absolute;
    bottom: calc(100% + 16px);
    left: 50%;
    transform: translateX(-50%);
    background: white;
    padding: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    min-width: 280px;
    max-width: 320px;
    width: max-content;
    z-index: 2;

    &::after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -8px;
        transform: translateX(-50%) rotate(45deg);
        width: 16px;
        height: 16px;
        background: white;
        box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
    }
`;

export const Description = styled.p`
    margin: 0;
    font-size: 12px;
    color: #676e76;
    font-family: Nunito;
`;

export const IconContainer = styled.div`
    position: relative;
    display: inline-block;
`;
