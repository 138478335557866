import React from 'react';
import {Controller} from 'react-hook-form';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon';
import {DateTime} from 'luxon';
import {
    ContainerFlex,
    ContainerCellGrid,
    Text,
    Input,
    InputColor,
    Image,
    ContainerGrid,
} from '@/components/Shopify/Ecommerce/styles';
import {
    CalendarFieldComponent,
    CalendarIconContainer,
    DatePickerCustom,
    InputCalendarField,
} from '@components/CreateUsers/DatePiker/datePickerStyles';
import {Icons} from '@components/CreateBranch/BranchInformation/style';
import PhoneFormatInput from '@components/PersonalLoans/PhoneFormat';
import {PERSONALDATA} from '@components/PersonalLoans/constants';
import {LoansDataProps, IDateLoan, IGetAllGenderSelect} from '@components/PersonalLoans/interfaces';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import CloseIcon from '@images/closeRed.svg';

export const PersonalDataLoan = ({
    control,
    setValue,
    register,
    errors,
    setNationSelected,
}: LoansDataProps) => {
    const dataGender = useSelector((state: RootState) => {
        return state.GetAllGenderPersonalLoan?.dataGender?.data;
    });

    const converDate = (e: IDateLoan) => {
        if (e && e.c) {
            const date = DateTime.utc(e.c.year, e.c.month, e.c.day, e.c.hour, e.c.minute).toISO();
            setValue('birthdate', date);
        }
    };

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="1rem">
            <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700" oFlow="none">
                {PERSONALDATA.ABOUT}
            </Text>
            <ContainerGrid Justify="start" Align="start" Gap="16px" GridColumns="1fr 1fr">
                <ContainerCellGrid FlexDir="column" Align="start" Gap="0.25rem">
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {PERSONALDATA.NAME}
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('name')}
                        error={errors.name?.message}
                    />
                    {errors?.name?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <Image alt="icon-close" src={CloseIcon} />
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                FontWeight="400"
                            >
                                {errors.name?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="21.875rem" Gap="0.25rem">
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {PERSONALDATA.LASTNAME}
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('lastName')}
                        error={errors.lastName?.message}
                    />
                    {errors?.lastName?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <Image alt="icon-close" src={CloseIcon} />
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                FontWeight="400"
                            >
                                {errors.lastName?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Gap="0.25rem">
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {PERSONALDATA.SECONDLASTNAME}
                    </Text>
                    <Input
                        maxLength={100}
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('maternalSurname')}
                        error={errors.maternalSurname?.message}
                    />
                    {errors?.maternalSurname?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <Image alt="icon-close" src={CloseIcon} />
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                FontWeight="400"
                            >
                                {errors.maternalSurname?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid
                    FlexDir="column"
                    Align="start"
                    Width="21.875rem"
                    Gap="0.25rem"
                    Height="68px"
                >
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20">
                        {PERSONALDATA.SEX}
                    </Text>
                    <ContainerFlex Justify="start" Gap="1rem" Height="40px">
                        {dataGender &&
                            dataGender.map((item: IGetAllGenderSelect) => (
                                <ContainerFlex
                                    key={item.id}
                                    Justify="start"
                                    Width="fit-content"
                                    Gap="0.5rem"
                                >
                                    <Controller
                                        name={`catGenderId`}
                                        control={control}
                                        render={({field: {onChange, ref, value, ...field}}) => (
                                            <InputColor
                                                {...field}
                                                type="radio"
                                                value={item.id}
                                                checked={value === item.id}
                                                onChange={() => onChange(item.id)}
                                            />
                                        )}
                                    />
                                    <Text Color="#2A2C2F">{item.description}</Text>
                                </ContainerFlex>
                            ))}
                    </ContainerFlex>
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Gap="0.25rem">
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" oFlow="none">
                        {PERSONALDATA.BIRTHDAY}
                    </Text>{' '}
                    <Controller
                        name={`birthdate`}
                        control={control}
                        render={({field: {onChange, ref, value, ...field}}) => (
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DatePickerCustom
                                    mask="__/__/____"
                                    {...field}
                                    inputFormat="dd/MM/yyyy"
                                    value={value}
                                    onChange={(e) => {
                                        if (onChange) onChange(e);
                                        converDate(e as IDateLoan);
                                    }}
                                    maxDate={
                                        new Date(
                                            new Date().getFullYear(),
                                            new Date().getMonth(),
                                            new Date().getDate() + 0
                                        )
                                    }
                                    components={{OpenPickerIcon: DateIcon}}
                                    renderInput={({inputRef, inputProps, InputProps}) => (
                                        <CalendarFieldComponent
                                            error={errors.birthdate?.message ? true : false}
                                            Margin="0 0 8px 0"
                                            Width="calc(100% - 16px)"
                                        >
                                            <InputCalendarField
                                                ref={inputRef}
                                                {...inputProps}
                                                FontSize="1rem"
                                            />
                                            <CalendarIconContainer>
                                                {InputProps?.endAdornment}
                                            </CalendarIconContainer>
                                        </CalendarFieldComponent>
                                    )}
                                />
                            </LocalizationProvider>
                        )}
                    />
                    {errors?.birthdate?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <Image alt="icon-close" src={CloseIcon} />
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                FontWeight="400"
                            >
                                {errors.birthdate?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="21.875rem" Gap="0.25rem">
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" oFlow="none">
                        {PERSONALDATA.CURP}
                    </Text>
                    <Input
                        maxLength={18}
                        Height="40px"
                        Width="100%"
                        Margin="0 0 8px 0"
                        {...register('curp')}
                        error={errors.curp?.message}
                    />
                    {errors?.curp?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <Image alt="icon-close" src={CloseIcon} />
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                Height="auto"
                                FontWeight="400"
                            >
                                {errors.curp?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
                <ContainerCellGrid FlexDir="column" Align="start" Width="21.875rem" Gap="0.25rem">
                    <Text FontWeight="700" FontSize="1rem" Color="#1D1E20" oFlow="none">
                        {PERSONALDATA.PHONE}
                    </Text>
                    <PhoneFormatInput
                        control={control}
                        name="phone"
                        handleNationSelected={setNationSelected}
                    />
                    {errors?.phone?.message && (
                        <ContainerFlex Justify="start" Gap="0.25rem">
                            <Image alt="icon-close" src={CloseIcon} />
                            <Text
                                Color="#ef4f55"
                                FontSize="0.75rem"
                                Align="start"
                                wSpace="normal"
                                FontWeight="400"
                            >
                                {errors.phone?.message}
                            </Text>
                        </ContainerFlex>
                    )}
                </ContainerCellGrid>
            </ContainerGrid>
        </ContainerFlex>
    );
};
export const DateIcon = () => {
    return (
        <Icons className="material-icons" FontSize="1.5rem" Color="#ACACFF">
            calendar_today
        </Icons>
    );
};
