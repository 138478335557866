import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import React, {useState} from 'react';
import {
    CREDIT_DETAIL_TABLES,
    TABLE_STATUS_FILTER_VALUES,
} from '@/components/CreditsAndPawns/constants';
import CreditDetailFeesTables from '@/components/CreditsAndPawns/CreditDetail/CreditDetailFeesTable';
import CreditDetailHistoryTables from '@/components/CreditsAndPawns/CreditDetail/CreditDetailHistoryTable';
import illustrationIcon from '@/components/CreditsAndPawns/icons/Ilustraciones.svg';
import {ICreditStatusID} from '@components/CreditsAndPawns/interfaces';

const CreditDetailTables: React.FC<ICreditStatusID> = ({statusId}) => {
    const [showHistoryTable, setShowHistoryTable] = useState<boolean>(false);
    return (
        <ContainerFlex
            FlexDir="column"
            Align="start"
            Justify="start"
            Width="100%"
            Height="600px"
            Radius="16px"
            Border="1px solid #E8E9EA"
            backG="#FFFFFF"
            Gap="24px"
            Padding="16px"
        >
            {statusId !== TABLE_STATUS_FILTER_VALUES.REJECTED &&
            statusId !== TABLE_STATUS_FILTER_VALUES.APPROVED ? (
                <>
                    <ContainerFlex
                        Bb="1px solid #E8E9EA"
                        Height="40px"
                        Width="100%"
                        Justify="start"
                        Align="start"
                    >
                        <Text
                            Padding="8px 16px"
                            FontFamily="Nunito"
                            FontWeight={!showHistoryTable ? '700' : '400'}
                            FontSize="1.0rem"
                            Color={!showHistoryTable ? '#5A5AFF' : '#2A2C2F'}
                            BorderBt={!showHistoryTable ? 'solid 1px #5A5AFF' : ''}
                            onClick={() => setShowHistoryTable(!showHistoryTable)}
                            Cursor="pointer"
                        >
                            {CREDIT_DETAIL_TABLES.FEE_TITLE}
                        </Text>
                        <Text
                            Padding="8px 16px"
                            FontFamily="Nunito"
                            FontWeight={showHistoryTable ? '700' : '400'}
                            FontSize="1.0rem"
                            Color={showHistoryTable ? '#5A5AFF' : '#2A2C2F'}
                            BorderBt={showHistoryTable ? 'solid 1px #5A5AFF' : ''}
                            onClick={() => setShowHistoryTable(!showHistoryTable)}
                            Cursor="pointer"
                        >
                            {CREDIT_DETAIL_TABLES.HISTORY_TITLE}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        FlexDir="column"
                        Gap="16px"
                        Height="100%"
                        Width="100%"
                        Justify="start"
                        Align="start"
                    >
                        {!showHistoryTable ? (
                            <CreditDetailFeesTables />
                        ) : (
                            <CreditDetailHistoryTables />
                        )}
                    </ContainerFlex>
                </>
            ) : (
                <ContainerFlex
                    Height="100%"
                    Width="100%"
                    backG={`url(${illustrationIcon}) no-repeat center`}
                />
            )}
        </ContainerFlex>
    );
};

export default CreditDetailTables;
