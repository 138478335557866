export const PAYMENT_METHOD = {
    TITLE: 'Método de pago',
    DESCRIPTION:
        'Agregando un método de pago podrás domiciliar tu pago y evitar asi cobros extra por demora.',
    ADD_METHOD_BTN: 'Agregar método de pago',
    DEFAULT_CARD: 'Tarjeta predeterminada:',
    CREDIT_OR_DEBIT: 'Tarjeta de crédito o débito',
    ADD_NEW_CARD: 'Agregar una tarjeta nueva',
    DEFAULT_CARD_DESCRIPTION:
        'Selecciona una tarjeta o agrega una nueva para tener un método de pago predeterminado',
    OTHER_CARDS_TITLE: 'Otras tarjetas:',
    FIELDS: {
        CARD_NUMBER: 'Número de la tarjeta',
        CARD_OWNER: 'Nombre del titular de la tarjeta',
        CARD_VIGENCY: 'Fecha de caducidad',
        CARD_CVV: 'CVV',
    },
    CANCEL: 'Cancelar',
    SAVE_CARD: 'Guardar tarjeta',
    MODAL_DELETE: {
        TITLE: 'Eliminar tarjeta',
        DESCRIPTION:
            'Haciendo click en eliminar tarjeta, la tarjeta se eliminará de tus métodos de pago.',
        DELETE_BTN: 'Eliminar tarjeta',
    },
    MODAL_CHANGE: {
        TITLE: 'Cambio de tarjeta predeterminada',
        DESCRIPTION:
            'Haciendo click en cambiar tarjeta, la tarjeta predeterminada se cambiará por la nueva selección.',
        CHANGE_BTN: 'Cambiar tarjeta',
    },
    DELETE_CARD: 'Eliminar tarjeta',
    DATE_FORMAT: 'MM/yyyy',
    SCHEMA_PAYMENT_METHODS: {
        REQUIRED: 'Campo obligatorio',
        CARD_NUMBER: 'Número de tarjeta no válido',
        CARD_OWNER: {
            MIN: 'El nombre debe de tener mínimo 3 caracteres',
            MATCH: 'Ingresa un nombre válido. Solo letras, puntos y espacios',
        },
        VALIDITY: {
            VALID_DATE: 'Ingrese una fecha válida',
            FUTURE_YEAR: 'La fecha de caducidad debe de ser futura',
        },
        CVV: 'El CVV debe ser de 3 o 4 dígitos.',
    },
};
