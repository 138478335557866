import styled from '@emotion/styled';
import {TextProps} from './interface';
import {IPropsInput} from './typeInterface';
export const TreeContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 250px;
`;

export const Option = styled.div<{isSelected: boolean}>`
    cursor: pointer;
    width: 100%;
    background-color: ${({isSelected}) => (isSelected ? '#E5E5FF' : '#FFF')};
    border-left: ${({isSelected}) => (isSelected ? '2px solid #5A5AFF ' : '2px solid #fff')};
    &:hover {
        background-color: #f5f5f5;
        border-left: 2px solid #5a5aff;
    }
`;

export const Img = styled.img`
    width: 24px;
    height: 24px;
`;
export const Imge = styled.img`
    width: 18px;
    height: 18px;
`;
export const ScrollableOptionsContainer = styled.div`
    width: 100%;
    height: max-content;
    max-height: 320px;
    overflow-y: auto;
    overflow-x: clip;
    padding-right: 8px;
    box-sizing: border-box;

    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
        border-radius: 10px;
        opacity: 0;
        transition: opacity 0.2s;
    }
    &::-webkit-scrollbar-track {
        background: #fff;
        opacity: 0;
        transition: opacity 0.2s;
    }

    &:hover {
        &::-webkit-scrollbar-thumb,
        &::-webkit-scrollbar-track {
            opacity: 1;
        }
    }
`;

export const StyledText = styled.span<TextProps>`
    font-family: ${(props) => props.FontFamily || 'Nunito'};
    width: ${(props) => props.Width || 'auto'};
    font-weight: ${(props) => props.FontWeight || 'normal'};
    white-space: ${(props) => props.wSpace || 'normal'};
`;
export const BoldText = styled.span<TextProps>`
    font-family: ${(props) => props.FontFamily || 'Nunito'};
    font-weight: 700;
`;

export const LateralLine = styled.div`
    width: 1px;
    height: 16px;
    background-color: #e8e9ea;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    align-self: center;
`;

export const InputWrapper = styled.div`
    position: relative;
    width: 100%;
`;
export const StyledInput = styled.input<IPropsInput & {isError: boolean; hasImg: boolean}>`
    background: ${(props) => props.BackGroundColor} 0% 0% no-repeat padding-box;
    border: ${(props) => (props.isError ? '1px solid #ef4f55' : '1px solid #E8E9EA')};
    border-radius: ${(props) => props.Radius};
    text-align: ${(props) => props.TextAlign};
    opacity: 1;
    height: ${(props) => props.Height};
    margin: ${(props) => props.Margin};
    width: ${(props) => props.Width};
    padding: ${(props) => props.Padding};
    padding-right: ${(props) =>
        props.hasImg ? '40px' : props.Padding}; /* Ajuste para la imagen */
    color: ${(props) => props.Color};
    cursor: ${(props) => props.Cursor};
    font-size: ${(props) => props.FontSize};
    font-family: ${(props) => props.FontFamily};
    outline: ${(props) => props.Outline};
    font-weight: ${(props) => props.FontWeight};
    border-top-right-radius: ${(props) => props.RadiusTR};
    border-bottom-right-radius: ${(props) => props.RadiusBR};
    box-sizing: border-box;
    ::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }
    ${(props) =>
        props.disabled
            ? `&:disabled {
                border-color: #E8E9EA;
                background-color: ${props.disabledBackG || '#F4F5F5'};
            }`
            : `&:focus {
                border-color: ${props.isError ? '#ef4f55' : '#5A5AFF'};
            }
            &:hover {
                border-color: ${props.isError ? '#ef4f55' : '#5A5AFF'};
            }`}
    &::placeholder {
        color: ${(props) => props.HolderColor};
        font-size: ${(props) => props.HolderFontSize};
        line-height: ${(props) => props.HolderHeight};
        font-weight: ${(props) => props.HolderWeight};
    }
`;
export const InputImage = styled.img`
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    object-fit: contain;
    pointer-events: none;
`;
StyledInput.defaultProps = {
    Margin: '0',
    Height: '40px',
    Width: '100%',
    Cursor: 'pointer',
    HolderHeight: '',
    HolderWeight: '400',
    FontWeight: '400',
    HolderFontSize: '1rem',
    FontSize: '1rem',
    HolderColor: '#cbced1',
    Radius: '32px',
    Padding: '8px 16px',
    Color: '#050505',
    Outline: 'none',
    BackGroundColor: '#fff',
};
export const HelperText = styled.span<{isError: boolean}>`
    display: block;
    margin-top: 4px;
    font-size: 0.75rem;
    color: ${(props) => (props.isError ? '#ef4f55' : '#6B7280')};
`;
export const InputContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const renderstyles = {
    Width: '100%',
    GridColumns: 'repeat(4, 1fr)',
    MinH: '400px',
    Gap: '8px',
};

export const rendercolumn = {
    Radius: '1rem',
    Border: 'solid 1px #F4F5F5',
    Width: '100%',
    Height: '27.813rem',
};

export const StyleTextrendercolumn = {
    FontWeight: '700',
    Color: '#5A5AFF',
    Cursor: 'pointer',
};
export const Stylerendercolumn = {
    Padding: '8px',
    Align: 'start',
    FlexDir: 'column',
    Height: 'max-content',
};
