import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {FieldValues, UseFormReset} from 'react-hook-form';
import React, {useEffect, useState} from 'react';

import * as content from '@components/CreateUsers/Styles';
import {contentLocation} from '@components/CreateUsers/Styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import {ContainerFlex, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {BRANCHLOCATION, CREATEUSER} from '@components/CreateUsers/constants';
import {BranchLocation} from '@components/CreateUsers/Location/BranchLocation';
import {EmployeeData, RoleAdmin, RoleEmployee, Roles} from '@components/CreateUsers/interfaces';

const LocationUser = (props: {reset: UseFormReset<EmployeeData>}) => {
    const {reset} = props;
    const [location, setLocation] = useState(false);
    const saveUser = useSelector((state: RootState) => state.saveUser);
    const {showTreeBranches, treeGroup, treeBranch} = useSelector(
        (state: RootState) => state.TreeGroupBranches
    );
    const lastBranch = showTreeBranches.branches[showTreeBranches.branches?.length - 1]?.branchId;
    const nameBranches = showTreeBranches.branches
        .map((item) => (item.branchId === lastBranch ? item.nameBranch : item.nameBranch + ', '))
        .filter((i) => i !== '');

    const allOptions = () => {
        let AllOptions = [BRANCHLOCATION.ALLOPTIONS];
        if (
            showTreeBranches.copyBranches.length !==
            (nameBranches.length && nameBranches.length > 0)
        )
            AllOptions = nameBranches;

        if (showTreeBranches.copyBranches.length === nameBranches.length)
            AllOptions = [BRANCHLOCATION.ALLOPTIONS];

        return AllOptions;
    };

    const selectConvert = (items: RoleAdmin[] | RoleEmployee[] | Roles[]) => {
        let select = {value: 0, label: ''};
        if (items)
            select = items.map((item: FieldValues) => ({
                value: item.rolId || item.roleIdEmployee || item.roleIdAdmin,
                label: item.rolName || item.roleNameEmployee || item.roleNameAdmin,
            }))[0];
        return select;
    };

    useEffect(() => {
        reset({
            ...saveUser.personal,
            ...saveUser.credential,
            roleAdmins: selectConvert(saveUser.roleAdmins),
            roleEmployees: selectConvert(saveUser.roleEmployees),
            aditionalInformationEmployee: saveUser.aditionalInformationEmployee,
        });
    }, [saveUser.credential.userType]);

    useEffect(() => {
        allOptions();
    }, [treeGroup.length, treeBranch.length]);

    return (
        <>
            <ContainerFlex
                {...content.contentSection}
                onClick={() => setLocation(true)}
                Cursor="pointer"
            >
                <Text {...content.subtitles} Padding="0">
                    {CREATEUSER.LOCATION}
                </Text>
                <Text {...content.instructions}>{CREATEUSER.LOCATIONDETAILS}</Text>
                <ContainerFlex {...contentLocation}>
                    <TextEllipsis Color="#2A2C2F" FontWeight="400" Width="250px">
                        {allOptions().length > 0 ? allOptions() : BRANCHLOCATION.ALLOPTIONS}
                    </TextEllipsis>
                    <Icons className="material-icons" FontSize="1.5rem" Color="#0D166B">
                        {CREATEUSER.ICONKEYDOWN}
                    </Icons>
                </ContainerFlex>
            </ContainerFlex>
            {location && <BranchLocation location={location} setLocation={setLocation} />}
        </>
    );
};

export default LocationUser;
