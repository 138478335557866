import {
    PASSWORD_USER_REQUEST,
    PASSWORD_USER_SUCCESS,
    PASSWORD_USER_ERROR,
} from '@components/UserProfileResetPassword/Redux/types';

const initialState = {
    loading: false,
    userData: null,
    password: null,
    error: null,
};

export const ResetPassUserofAdmin = (state = initialState, action) => {
    switch (action.type) {
        case PASSWORD_USER_REQUEST:
            return {...state, loading: true};
        case PASSWORD_USER_SUCCESS:
            return {...state, loading: false};
        case PASSWORD_USER_ERROR:
            return {...state, loading: false};
        default:
            return state;
    }
};
