import {AxiosResponse, AxiosError} from 'axios';
import {
    GET_CREDIT_DETAIL_HISTORY_ERROR,
    GET_CREDIT_DETAIL_HISTORY_START,
    GET_CREDIT_DETAIL_HISTORY_SUCCESS,
} from '@components/CreditsAndPawns/Redux/Types/Types';
import {ICreditDetailHistoryReducer} from '@components/CreditsAndPawns/interfaces';

const initialState: ICreditDetailHistoryReducer = {
    creditDetailHistory: null,
    loading: false,
    error: false,
};

const getCreditDetailHistory = (
    state: ICreditDetailHistoryReducer = initialState,
    action: {type: string; payload: AxiosResponse; error: AxiosError}
): ICreditDetailHistoryReducer => {
    switch (action.type) {
        case GET_CREDIT_DETAIL_HISTORY_START:
            return {
                ...state,
                loading: true,
            };
        case GET_CREDIT_DETAIL_HISTORY_SUCCESS:
            return {
                ...state,
                creditDetailHistory: action.payload.data,
                loading: false,
            };
        case GET_CREDIT_DETAIL_HISTORY_ERROR:
            return {
                ...initialState,
                error: true,
            };
        default:
            return state;
    }
};

export default getCreditDetailHistory;
