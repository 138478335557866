import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {setIsCartOpen} from '@/components/ShoppingCart/Redux/Actions/CartState';
import {fetchCartById} from '@components/ShoppingCart/Redux/Actions/GetCartByCartId';
import {DISCHARGE_ENDORSEMENT_ERRORS} from '@Shopify/ClientProfile/constants';

const useCheckPawnsOnCart = () => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) =>
        String(state.getUsersValidation.userData?.token)
    );
    const carShoppingId = useSelector((state: RootState) =>
        Number(state.getUsersValidation.userData?.carShoopingId)
    );
    const cartData = useSelector((state: RootState) => state.getCartByCartId.cartData);
    const cartFetched = useSelector((state: RootState) => state.getCartByCartId.fetched);
    const isCartOpen = useSelector((state: RootState) => state.cartState.isCartOpen);

    useEffect(() => {
        if (token && carShoppingId && !cartFetched) {
            dispatch(fetchCartById(token, carShoppingId));
        }
    }, [cartFetched]);

    const checkCartAndPawns = async () => {
        if (!isCartOpen) {
            await dispatch(setIsCartOpen(true, false));
        }

        if (!cartData?.pawns || cartData.pawns.length === 0) {
            return {hasPawns: false, message: null};
        }

        return {hasPawns: true, message: DISCHARGE_ENDORSEMENT_ERRORS.PAWN_IN_CART};
    };

    return {checkCartAndPawns};
};

export default useCheckPawnsOnCart;
