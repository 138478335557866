import * as yup from 'yup';

export const DEFAULT_VALUES = {
    name: '',
    lastName: '',
    otherLastName: '',
    date: '',
    gender: {label: '', value: 0},
    email: '',
    otherEmail: '',
    cel: '',
    otherCelphone: '',
};

export const DEFAULT_VALUES_CONTACT = {
    postal: 0,
    street: '',
    outsideNumber: '',
    insideNumber: '',
    colony: {label: '', value: 0},
    town: '',
    state: '',
    catColony: {label: '', value: 0},
};
export interface IContactValues {
    postal: number;
    street: string;
    outsideNumber: string;
    insideNumber: string;
    colony: {
        label: string;
        value: number;
    };
    town: string;
    state: string;
    catColony: {
        label: string;
        value: number;
    };
}

export const ERROR_MESSAGES = {
    POSTAL: 'El código postal es requerido',
    FORMAT_POSTAL: 'El código postal debe tener exactamente 5 dígitos',
    STREET: 'La calle es requerida',
    OUTSIDE: 'El número exterior es requerido',
    INSIDE: 'El número interior es requerido',
    COLONY: 'La colonia es requerida',
    TOWN: 'El municipio es requerido',
    STATE: 'El estado es requerido',
    NAME: 'Escriba un nombre',
    LASTNAME: 'Escriba un apellido paterno',
    OTHERLASTNAME: 'Escriba un apellido materno',
    DATE: 'Inserte una fecha',
    GENDER: 'Campo obligatorio',
    EMAIL: 'El correo electrónico es requerido',
    EMAIL_VAL: 'Correo electrónico inválido',
    CEL: 'El número de celular es requerido',
    CEL_VAL: 'El número de teléfono no es válido',
    DATE_FUTURE: 'La fecha no puede ser mayor o igual a la actual',
};
export const contactSchema = yup.object().shape({
    postal: yup.string().required(ERROR_MESSAGES.POSTAL),
    street: yup.string().required(ERROR_MESSAGES.STREET),
    colony: yup.string().required(ERROR_MESSAGES.COLONY),
    town: yup.string().required(ERROR_MESSAGES.TOWN),
    state: yup.string().required(ERROR_MESSAGES.STATE),
    catColony: yup.string().required(ERROR_MESSAGES.COLONY),
});

export const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const phoneRegExp = /^(\+52)?(1)?\d{10}$/;
export const schemaPersonalInfo = yup.object().shape({
    name: yup.string().required(ERROR_MESSAGES.NAME),
    lastName: yup.string().required(ERROR_MESSAGES.LASTNAME),
    otherLastName: yup.string().required(ERROR_MESSAGES.OTHERLASTNAME),
    date: yup
        .date()
        .typeError(ERROR_MESSAGES.DATE)
        .max(new Date(), ERROR_MESSAGES.DATE_FUTURE)
        .required(ERROR_MESSAGES.DATE),
    gender: yup
        .object()
        .shape({
            label: yup.string().required(ERROR_MESSAGES.GENDER),
        })
        .required(ERROR_MESSAGES.GENDER),
    email: yup
        .string()
        .matches(emailRegExp, ERROR_MESSAGES.EMAIL_VAL)
        .required(ERROR_MESSAGES.EMAIL),

    cel: yup.string().matches(phoneRegExp, ERROR_MESSAGES.CEL_VAL).required(ERROR_MESSAGES.CEL),
});
