import React from 'react';

import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import checkIcon from '@images/checkmarkPlus.svg';
import moneyIcon from '@images/moneyPurple.svg';
import {CLIENT_PROFILE} from '@Shopify/ClientProfile/constants';

const FinancialInfo: React.FC = () => (
    <FlexPanel Align="center" Gap="1.188rem">
        <ContainerFlex Padding="0.5rem 0" Align="center" Gap="0.5rem">
            <Image src={checkIcon} width="24px" Height="24px" />
            <ContainerFlex Align="center" Gap="0.5rem">
                <Text FontSize="0.875rem" Color="#54575C" FontWeight="400" wSpace="nowrap">
                    {CLIENT_PROFILE.TOTAL_OWED}
                </Text>
                <Text FontSize="1rem" Color="#54575C" FontWeight="700">
                    {CLIENT_PROFILE.TEMP_TOTAL}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
        <ContainerFlex Padding="0.5rem 0" Align="center" Gap="0.5rem">
            <Image src={moneyIcon} width="24px" Height="24px" />
            <ContainerFlex Align="center" Gap="0.5rem">
                <Text FontSize="0.875rem" Color="#54575C" FontWeight="400" wSpace="nowrap">
                    {CLIENT_PROFILE.TOTAL_INCOME}
                </Text>
                <Text FontSize="1rem" Color="#54575C" FontWeight="700">
                    {CLIENT_PROFILE.TEMP_TOTAL}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    </FlexPanel>
);

export default FinancialInfo;
