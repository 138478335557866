import {
    ALL_GENDER_PERSONAL_LOAN_ERROR,
    ALL_GENDER_PERSONAL_LOAN_START,
    ALL_GENDER_PERSONAL_LOAN_SUCCESS,
} from '@components/PersonalLoans/Redux/Types/Types';
import {AxiosResponse} from 'axios';
import {GetAllGenderReducer} from '@components/PersonalLoans/interfaces';

const initialState: GetAllGenderReducer = {
    error: false,
    loading: false,
    dataGender: null,
    errorData: null,
};

const GetAllGenderPersonalLoan = (
    state = initialState,
    action: {
        type: string;
        payload: AxiosResponse;
    }
): GetAllGenderReducer => {
    switch (action.type) {
        case ALL_GENDER_PERSONAL_LOAN_START:
            return {...state, loading: true, error: false};
        case ALL_GENDER_PERSONAL_LOAN_SUCCESS:
            return {...state, loading: false, error: false, dataGender: action.payload.data};
        case ALL_GENDER_PERSONAL_LOAN_ERROR:
            return {...state, loading: false, error: true};
        default:
            return state;
    }
};

export default GetAllGenderPersonalLoan;
