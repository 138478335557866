import React, {useState} from 'react';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import CarrouselCard from '@components/GlobalQuoter/CarrouselCard';
import {carrouselData, IMAGE_ALT} from '@/components/GlobalQuoter/TempConstants';
import arrowLeft from '@images/arrow small.svg';
const LoanCarrousel = () => {
    const [current, setCurrent] = useState(0);

    const moveCarrouselHandler = () => {
        setCurrent((prev) => Math.min(prev + 1, carrouselData.length - 1));
    };

    const moveCarrouselHandlerBack = () => {
        setCurrent((prev) => Math.max(prev - 1, 0));
    };

    const cardWidthWithGap = 342;
    const visibleCards = 3.4;
    const carrouselWidth = cardWidthWithGap * visibleCards;

    return (
        <ContainerFlex
            Justify="flex-start"
            Height="130px"
            OverFlowX="hidden"
            Position="relative"
            Width={`${carrouselWidth}px`}
        >
            {current !== 0 && (
                <ContainerFlex
                    onClick={moveCarrouselHandlerBack}
                    Position="absolute"
                    PositionLeft="0"
                    PositionTop="45px"
                    ZIndex="1"
                    Width="40px"
                    Height="40px"
                    backG="#FFFFFF"
                    Justify="center"
                    Align="center"
                    Radius="50%"
                    Border="1px solid #5A5AFF"
                    BoxShadow="0px 0px 16px 0px rgba(0, 0, 0, 0.08)"
                    Cursor="pointer"
                >
                    <Image src={arrowLeft} alt={IMAGE_ALT.ARROW_LEFT} Transform="rotate(180deg)" />
                </ContainerFlex>
            )}
            <ContainerFlex
                Justify="flex-start"
                Gap="24px"
                Transform={`translateX(-${current * cardWidthWithGap}px)`}
            >
                {carrouselData.map((loanData, index) => (
                    <CarrouselCard key={index} loanData={loanData} />
                ))}
            </ContainerFlex>
            {current !== carrouselData.length - 1 && (
                <ContainerFlex
                    onClick={moveCarrouselHandler}
                    Position="absolute"
                    PositionRight="0"
                    PositionTop="45px"
                    ZIndex="1"
                    Width="40px"
                    Height="40px"
                    backG="#FFFFFF"
                    Justify="center"
                    Align="center"
                    Radius="50%"
                    Border="1px solid #5A5AFF"
                    BoxShadow="0px 0px 16px 0px rgba(0, 0, 0, 0.08)"
                    Cursor="pointer"
                >
                    <Image src={arrowLeft} alt={IMAGE_ALT.ARROW_RIGHT} />
                </ContainerFlex>
            )}
        </ContainerFlex>
    );
};

export default LoanCarrousel;
