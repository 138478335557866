import styled from '@emotion/styled';

export const DropdownContainer = styled.div`
    position: relative;
    width: max-content;
`;

export const DropdownButton = styled.div`
    width: 100%;
    background-color: #ffff;
    border: none;
    cursor: pointer;
    justify-content: start;
    font-family: nunito;
    font-size: 1rem;
`;

export const ButtonContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: max-content;
    gap: 8px;
`;

export const DropdownList = styled.div`
    position: absolute;
    top: 100%;
    left: -50%;
    width: 250px;
    background-color: white;
    border: 1px solid #ccc;
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 0 0 8px 8px;
`;

export const DropdownItem = styled.div`
    padding: 10px;
    cursor: pointer;



    &:hover {
        background-color: #E5E5FF;
        border-left: 2px solid #5A5AFF };
    }

   
`;
