import React from 'react';
import {RootState} from '@/config/store';
import {useDispatch, useSelector} from 'react-redux';

import JoinIcon from '@images/JoinDeadline.svg';
import {JOINTEXTDEADLINE} from '@Quoter/Steps/constants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {IconSvg} from '@/components/General/Atoms/NotificationsList/styles';
import {ContainerFlex, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {DataContract, DataPledge, IMergeContract} from '@Quoter/Steps/interface';
import {IClientData} from '@components/ShoppingCart/Redux/interfaces';
import {
    getShoppingCartContracts,
    updateContract,
} from '@/components/ShoppingCart/Redux/Actions/getCartContractsActions';
import {LoadingAtaskate} from '@/components/General/Atoms/LoadingAtaskate';

const JoinDeadlines = (props: IMergeContract) => {
    const {showModal, setShowModal, contract, setContracts} = props;
    const dispatch = useDispatch();
    const {loading, loadingUpdate} = useSelector((state: RootState) => state.getCartContracts);
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const carshopingId = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.carShoopingId
    );
    const ContractsCarShopping: DataContract[] = useSelector(
        (state: RootState) => state.getCartContracts.data
    );
    const {client: selectedClient}: IClientData = useSelector(
        (state: RootState) => state.clientList
    );

    const handleDragStart = (
        e: React.DragEvent<HTMLDivElement>,
        contractItem: DataContract,
        pledge: DataPledge
    ) => setContracts({...contract, draggedItem: {contractItem, pledge}});

    const handleDragOver = (e: {preventDefault: () => void}) => e.preventDefault();

    const handleDrop = (e: React.DragEvent<HTMLDivElement>, itemContract: DataContract) => {
        e.preventDefault();

        if (contract.draggedItem) {
            const sourceContract = contract.draggedItem.contractItem;
            const pledge = contract.draggedItem.pledge;

            const updatedContracts = contract.initialPledges
                .map((contract) => {
                    if (contract.contractId === sourceContract.contractId)
                        contract.pledges = contract.pledges.filter(
                            (p) => p.pledgeId !== pledge.pledgeId
                        );

                    if (contract.contractId === itemContract.contractId)
                        contract.pledges.push(pledge);

                    return contract;
                })
                .filter((i) => i.pledges.length !== 0)
                .map((contract, index: number) => {
                    return {...contract, contractName: `Contrato ${index + 1}`};
                });

            const lastContract = updatedContracts[updatedContracts.length - 1];
            const addLastContract = [
                ...updatedContracts,
                {
                    carShoppingId: lastContract.carShoppingId,
                    contractId: lastContract.contractId + 1,
                    contractName: `Contrato ${updatedContracts.length + 1}`,
                    promotionId: lastContract.promotionId,
                    pledges: [],
                },
            ];
            setContracts({...contract, initialPledges: addLastContract});
        }
    };

    const updateContracts = async () => {
        if (token && carshopingId) {
            await dispatch(
                updateContract(
                    token,
                    {
                        pawns: contract.initialPledges
                            .filter((i) => i.pledges.length !== 0)
                            .map((items, index) => ({
                                carShoppingId: items.carShoppingId,
                                contractId:
                                    ContractsCarShopping[index]?.contractId === undefined
                                        ? 0
                                        : ContractsCarShopping[index].contractId,
                                contractName: items.contractName,
                                promotionId: items.promotionId,
                                pledges: items.pledges,
                            })),
                    },
                    carshopingId,
                    selectedClient?.id,
                    setShowModal
                )
            );
        }
    };

    const cancelMerge = async () => {
        if (token && carshopingId) {
            await dispatch(getShoppingCartContracts(token, {carshopingId: carshopingId}));
            setShowModal(false);
        }
    };

    return (
        <Modal
            Top="0"
            modalState={showModal}
            changeModalState={() => {}}
            titleModalState={true}
            BorderRadius="1rem"
            Width="41.313rem"
        >
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Padding="1.5rem"
                Gap="1.5rem"
                Height="80vh"
                Radius="1rem"
            >
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="0.5rem"
                    Height="auto"
                >
                    <Text FontSize="1.5rem" FontWeight="500">
                        {JOINTEXTDEADLINE.TITLE}
                    </Text>
                    <Text>{JOINTEXTDEADLINE.SUBTITLE}</Text>
                </ContainerFlex>
                <ContainerFlex
                    FlexDir="column"
                    Justify="start"
                    Align="start"
                    Gap="1rem"
                    OverFlow="auto"
                    Height="auto"
                >
                    {contract.initialPledges &&
                        contract.initialPledges.map((data: DataContract, index: number) => (
                            <ContainerFlex
                                FlexDir="column"
                                Justify="start"
                                Align="start"
                                key={index}
                                Height="auto"
                                Gap="0.5rem"
                                Cursor="pointer"
                                onDragOver={handleDragOver}
                                onDrop={(e) => handleDrop(e, data)}
                            >
                                <ContainerFlex FontWeight="500" Color="#2A2C2F" Justify="start">
                                    {data?.contractName}
                                </ContainerFlex>
                                {data.pledges.map((dataItem: DataPledge) => (
                                    <ContainerFlex
                                        Display="grid"
                                        GridColumns="auto 6fr auto"
                                        Padding="1rem"
                                        Gap="0.5rem"
                                        Border="solid 1px #F4F5F5"
                                        Radius="1rem"
                                        Cursor="pointer"
                                        key={dataItem.pledgeId}
                                        draggable
                                        onDragStart={(e) => handleDragStart(e, data, dataItem)}
                                    >
                                        <Image src={dataItem?.imgurl} width="42px" height="42px" />
                                        <ContainerFlex
                                            FlexDir="column"
                                            Justify="start"
                                            Align="start"
                                            Gap="0.5rem"
                                            Cursor="pointer"
                                        >
                                            <Text Cursor="pointer" FontWeight="500">
                                                {dataItem.articleName}
                                            </Text>
                                            <Text Cursor="pointer" wSpace="pre">
                                                {JOINTEXTDEADLINE.VALUATION}{' '}
                                                <Text Cursor="pointer" FontWeight="500">
                                                    {dataItem.articleValue}
                                                </Text>
                                            </Text>
                                            <Text Cursor="pointer" wSpace="pre">
                                                {JOINTEXTDEADLINE.LOAN}{' '}
                                                <Text FontWeight="500">{dataItem.articleLoan}</Text>
                                            </Text>
                                        </ContainerFlex>
                                        <ContainerFlex Justify="end" Align="start">
                                            <IconSvg height="24px" width="24px" cursor="pointer">
                                                <path
                                                    d="M4 15V13H20V15H4ZM4 11V9H20V11H4Z"
                                                    fill="#5A5AFF"
                                                />
                                            </IconSvg>
                                        </ContainerFlex>
                                    </ContainerFlex>
                                ))}
                                {data.pledges.length === 0 && (
                                    <ContainerFlex
                                        Border="1px dashed #E8E9EA"
                                        Padding="1rem"
                                        Radius="1rem"
                                        FlexDir="column"
                                    >
                                        <Image src={JoinIcon} width="64px" height="64px" />
                                        <Text Color="#54575C">{JOINTEXTDEADLINE.ADD}</Text>
                                    </ContainerFlex>
                                )}
                            </ContainerFlex>
                        ))}
                </ContainerFlex>
                <ContainerFlex Gap="1.5rem" Height="2.5rem">
                    <ButtonGenerals
                        Width="11.563rem"
                        BackGC="#FFFFFF"
                        Border="1px solid #5A5AFF"
                        Color="#5A5AFF"
                        HColor="#FFFFFF"
                        FontWeight="500"
                        onClick={() => cancelMerge()}
                        Cursor="pointer"
                    >
                        {'Cancelar'}
                    </ButtonGenerals>
                    s
                    {(loading && loadingUpdate) || loadingUpdate ? (
                        <ContainerFlex Width="11.563rem">
                            <LoadingAtaskate />
                        </ContainerFlex>
                    ) : (
                        <ButtonGeneral
                            width="11.563rem"
                            text="Guardar cambios"
                            type="submit"
                            height="40px"
                            clic={() => updateContracts()}
                            cursor="pointer"
                        />
                    )}
                </ContainerFlex>
            </ContainerFlex>
        </Modal>
    );
};

export default JoinDeadlines;
