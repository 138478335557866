import React, {useEffect, useState} from 'react';
import {RootState} from '@/config/store';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {
    ContainerCellGrid,
    ContainerFlex,
    ContainerForm,
    Input,
    Text,
} from '@Shopify/Ecommerce/styles';
import {addCoOwnerSchema} from '@components/Quoter/CoownerBeneficiary/yupAddcoOwner';
import {COOWNEREDIT, DEFAULT_LADA} from '@Quoter/Steps/constants';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {Icons} from '@/components/CreateBranch/BranchInformation/style';
import * as styles from '@/components/Quoter/stylesConstants';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {showAddCoOwner} from '@Quoter/Steps/interface';
import {SelectLada} from '@Quoter/CoownerBeneficiary/SelectLada';
import {IGetAllNations} from '@components/Quoter/CoownerBeneficiary/interface';
import {formAddCoowner} from '@Quoter/CoownerBeneficiary/Redux/Actions/FormAddCoOwnerActions';
import {IFormAddCoowner} from '@components/Quoter/CoownerBeneficiary/Redux/interfaces';
import {IClientData} from '@components/ShoppingCart/Redux/interfaces';

export const AddCoOwnerBenefist: React.FC<showAddCoOwner> = ({showAdd, setShowAdd}) => {
    const token = useSelector(
        (state: RootState) => state.getUsersValidation.userData?.token as string
    );
    const {
        client: {id},
    } = useSelector((state: RootState) => state.clientList) as IClientData;

    const dispatch = useDispatch();
    const [addCoownerError, setAddCoownerError] = useState<string>('');
    const [nationSelected, setNationSelected] = useState<IGetAllNations | null>(DEFAULT_LADA);
    const {
        handleSubmit,
        register,
        setValue,
        formState: {errors},
    } = useForm<IFormAddCoowner>({
        defaultValues: {
            clientId: id,
            name: '',
            lastName: '',
            secondLastName: '',
            flag: '',
            lada: '',
            phone: '',
            email: '',
            contactType: 0,
        },
        resolver: yupResolver(addCoOwnerSchema),
    });

    useEffect(() => {
        if (nationSelected) {
            setValue('lada', nationSelected.lada);
            setValue('flag', nationSelected.urlFlag);
        }
    }, [nationSelected]);

    const onSubmitAdd = async (data: IFormAddCoowner) => {
        setAddCoownerError('');
        try {
            await dispatch(formAddCoowner(token, data));
            setShowAdd(false);
        } catch (error) {
            setAddCoownerError(COOWNEREDIT.ADDING_ERROR);
        }
    };

    return (
        <Modal
            Top="0"
            modalState={showAdd}
            changeModalState={() => {}}
            titleModalState={true}
            BorderRadius="1rem"
            background="none"
            Justify="end"
            applyAnimation={true}
        >
            <ContainerForm
                onSubmit={handleSubmit(onSubmitAdd)}
                FlexDir="column"
                Width="26.875rem"
                Height="100vh"
                Padding="1rem"
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="1.5rem">
                    <ContainerFlex Justify="space-between" Height="auto">
                        <Text FontSize="1.5rem" FontWeight="500" Color="#2A2C2F">
                            {COOWNEREDIT.TITLE_ADD}
                        </Text>
                        <Icons
                            {...styles.iconSearch}
                            onClick={() => setShowAdd(false)}
                            Cursor="pointer"
                        >
                            {'close'}
                        </Icons>
                    </ContainerFlex>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.FIRSTNAME}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('name')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.name?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.LASTNAME_PATERNAL}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('lastName')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.lastName?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.LASTNAME_MATERNAL}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('secondLastName')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.secondLastName?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.EMAIL}
                        </Text>
                        <Input
                            type="text"
                            Padding="0.75rem"
                            FontSize="0.875rem"
                            {...register('email')}
                        />
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.email?.message}
                        </Text>
                    </ContainerCellGrid>
                    <ContainerCellGrid AlignContent="start" FlexDir="column" Height="5rem">
                        <Text Color="#54575C" FontWeight="500" FontSize="1rem">
                            {COOWNEREDIT.PHONE}
                        </Text>
                        <ContainerFlex>
                            <SelectLada handleNationSelected={setNationSelected} />
                            <Input
                                Radius="0"
                                RadiusTR="3rem"
                                RadiusBR="3rem"
                                type="number"
                                Padding="0.75rem"
                                FontSize="0.875rem"
                                placeholder=""
                                {...register('phone')}
                            />
                        </ContainerFlex>
                        <Text Color="#FF6357" FontSize="0.875rem">
                            {errors?.phone?.message}
                        </Text>
                    </ContainerCellGrid>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="center" Gap="1rem" Height="4.831rem">
                    {addCoownerError && (
                        <Text Color="#FF6357" FontSize="1rem">
                            {addCoownerError}
                        </Text>
                    )}
                    <ContainerFlex Gap="1rem" Height="2.5rem">
                        <ButtonGenerals
                            Width="12rem"
                            BackGC="#FFFFFF"
                            Border="1px solid #5A5AFF"
                            Color="#5A5AFF"
                            HColor="#FFFFFF"
                            FontWeight="500"
                            Cursor="pointer"
                            onClick={() => {
                                setShowAdd(false);
                            }}
                        >
                            {COOWNEREDIT.CANCEL}
                        </ButtonGenerals>
                        <ButtonGeneral
                            width="12rem"
                            text={COOWNEREDIT.SAVE_CHANGES}
                            height="2.5rem"
                            cursor="pointer"
                            type="submit"
                        />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerForm>
        </Modal>
    );
};
