import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_REQUEST_INFORMATION} from '@/components/CreditsAndPawns/constants';
import {ICreditRequestInformationProps} from '@/components/CreditsAndPawns/interfaces';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {useFormatDateAndHour} from '@/hooks/useFormatDate';
import CreditRequestPercentage from '@/components/CreditsAndPawns/CreditRequest/CreditRequestPercentage';

const CreditRequestInformationBody: React.FC<ICreditRequestInformationProps> = ({
    amountSolicutude,
    termName,
    anualRate,
    solicitudeDate,
    frecuncyId,
    countPeriod,
    showTable,
    setShowTable,
}) => {
    const percentageData = {
        amountSolicutude,
        frecuncyId,
        anualRate,
        countPeriod,
    };
    return (
        <ContainerFlex Height="100%" Width="100%" FlexDir="column" Gap="24px">
            <ContainerFlex Width="100%" FlexDir="column" Gap="16px">
                <ContainerFlex Align="start" Justify="start" Width="100%" Height="68px" Gap="16px">
                    <ContainerFlex
                        Align="start"
                        Justify="start"
                        Width="100%"
                        Height="100%"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {CREDIT_REQUEST_INFORMATION.AMOUNT}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.75rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="2.10rem"
                        >
                            {numberToCurrencyWithoutCents(amountSolicutude)}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Align="start"
                        Justify="start"
                        Width="100%"
                        Height="100%"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {CREDIT_REQUEST_INFORMATION.TERM}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#5A5AFF"
                            FontStyle="normal"
                            LHeight="1.5rem"
                            Cursor="pointer"
                            onClick={() => setShowTable(!showTable)}
                        >
                            {termName}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex Align="start" Justify="start" Width="100%" Height="68px" Gap="16px">
                    <ContainerFlex
                        Align="start"
                        Justify="start"
                        Width="100%"
                        Height="100%"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {CREDIT_REQUEST_INFORMATION.ANNUAL_INTEREST_RATE}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {`${anualRate}%`}
                        </Text>
                    </ContainerFlex>
                    <ContainerFlex
                        Align="start"
                        Justify="start"
                        Width="100%"
                        Height="100%"
                        FlexDir="column"
                        Gap="8px"
                    >
                        <Text
                            FontFamily="Nunito"
                            FontWeight="700"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {CREDIT_REQUEST_INFORMATION.REQUEST_DATE}
                        </Text>
                        <Text
                            FontFamily="Nunito"
                            FontWeight="400"
                            FontSize="1.0rem"
                            Color="#2A2C2F"
                            FontStyle="normal"
                            LHeight="1.5rem"
                        >
                            {useFormatDateAndHour(solicitudeDate)}
                        </Text>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex Width="100%" Height="1px" backG="#E8E9EA" />
            <CreditRequestPercentage {...percentageData} />
        </ContainerFlex>
    );
};

export default CreditRequestInformationBody;
