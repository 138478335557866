import {Controller, useFormContext} from 'react-hook-form';
import React from 'react';
import {ContainerFlex, Text, TextArea} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IDescriptionFieldProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {ErrorMessage} from '@components/PersonalLoans/NewLoanProduct/ChargesAndPenalties/ErrorMessage';

export const DescriptionField: React.FC<IDescriptionFieldProps> = ({name}) => {
    const {
        control,
        formState: {errors},
    } = useFormContext();
    const errorMessage = errors[name]?.message?.toString() || '';
    return (
        <>
            <ContainerFlex
                Gap="0.25rem"
                FlexDir="column"
                Align="start"
                Justify="start"
                gridColumnEnd="span 2"
            >
                <Text Color="#2A2C2F" FontWeight="700">
                    {LOAN_DETAILS.FIELDS.DESCRIPTION}
                    <Text Color="#54575C" MarginLeft="0.25rem">
                        {LOAN_DETAILS.FIELDS.OPTIONAL}
                    </Text>
                </Text>
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => (
                        <TextArea
                            {...field}
                            Height="40px"
                            FontSize="1rem"
                            Padding="0.6rem 1rem"
                            maxLength={500}
                            Radius="2rem"
                        />
                    )}
                />
                {errorMessage && <ErrorMessage message={errorMessage} />}
            </ContainerFlex>
        </>
    );
};
