import * as yup from 'yup';
import {ILevel, IStorage, IStorageFloor, IWarehouse} from '@components/LocationsBranch/interfaces';
import {YUP_EDIT_NAME_SCHEMA} from '@components/LocationsBranch/constants';

export const editWarehouseSchema = (warehouses: IWarehouse[], initialName: string) => {
    return yup.object().shape({
        warehouseName: yup
            .string()
            .required(YUP_EDIT_NAME_SCHEMA.REQUIRED)
            .min(YUP_EDIT_NAME_SCHEMA.MIN.VALUE, YUP_EDIT_NAME_SCHEMA.MIN.MESSAGE)
            .max(YUP_EDIT_NAME_SCHEMA.MAX.VALUE, YUP_EDIT_NAME_SCHEMA.MAX.MESSAGE)
            .matches(YUP_EDIT_NAME_SCHEMA.MATCHES.REGEX, YUP_EDIT_NAME_SCHEMA.MATCHES.MESSAGE)
            .test(YUP_EDIT_NAME_SCHEMA.TEST.NAME, YUP_EDIT_NAME_SCHEMA.TEST.MESSAGE, (value) => {
                return (
                    !warehouses.some((warehouse) => warehouse.name.trim() === value.trim()) ||
                    value.trim() === initialName.trim()
                );
            }),
    });
};
export const editStorageTypeSchema = (storagesType: IStorage[], initialName: string) => {
    return yup.object().shape({
        warehouseName: yup
            .string()
            .required(YUP_EDIT_NAME_SCHEMA.REQUIRED)
            .min(YUP_EDIT_NAME_SCHEMA.MIN.VALUE, YUP_EDIT_NAME_SCHEMA.MIN.MESSAGE)
            .max(YUP_EDIT_NAME_SCHEMA.MAX.VALUE, YUP_EDIT_NAME_SCHEMA.MAX.MESSAGE)
            .matches(YUP_EDIT_NAME_SCHEMA.MATCHES.REGEX, YUP_EDIT_NAME_SCHEMA.MATCHES.MESSAGE)
            .test(YUP_EDIT_NAME_SCHEMA.TEST.NAME, YUP_EDIT_NAME_SCHEMA.TEST.MESSAGE, (value) => {
                return (
                    !storagesType.some(
                        (storageType) => (storageType.name ?? '').trim() === value.trim()
                    ) || value.trim() === initialName.trim()
                );
            }),
    });
};
export const editFloorSchema = (storagesType: IStorage | null | undefined, initialName: string) => {
    return yup.object().shape({
        warehouseName: yup
            .string()
            .required(YUP_EDIT_NAME_SCHEMA.REQUIRED)
            .min(YUP_EDIT_NAME_SCHEMA.MIN.VALUE, YUP_EDIT_NAME_SCHEMA.MIN.MESSAGE)
            .max(YUP_EDIT_NAME_SCHEMA.MAX.VALUE, YUP_EDIT_NAME_SCHEMA.MAX.MESSAGE)
            .matches(YUP_EDIT_NAME_SCHEMA.MATCHES.REGEX, YUP_EDIT_NAME_SCHEMA.MATCHES.MESSAGE)
            .test(YUP_EDIT_NAME_SCHEMA.TEST.NAME, YUP_EDIT_NAME_SCHEMA.TEST.MESSAGE, (value) => {
                if (!storagesType) return false;
                return (
                    !storagesType.storageFloor.some(
                        (floor) => (floor.name ?? '').trim() === value.trim()
                    ) || value.trim() === initialName.trim()
                );
            }),
    });
};
export const editLevelSchema = (
    storageFloor: IStorageFloor | null | undefined,
    initialName: string
) => {
    return yup.object().shape({
        warehouseName: yup
            .string()
            .required(YUP_EDIT_NAME_SCHEMA.REQUIRED)
            .min(YUP_EDIT_NAME_SCHEMA.MIN.VALUE, YUP_EDIT_NAME_SCHEMA.MIN.MESSAGE)
            .max(YUP_EDIT_NAME_SCHEMA.MAX.VALUE, YUP_EDIT_NAME_SCHEMA.MAX.MESSAGE)
            .matches(YUP_EDIT_NAME_SCHEMA.MATCHES.REGEX, YUP_EDIT_NAME_SCHEMA.MATCHES.MESSAGE)
            .test(YUP_EDIT_NAME_SCHEMA.TEST.NAME, YUP_EDIT_NAME_SCHEMA.TEST.MESSAGE, (value) => {
                if (!storageFloor) return false;
                return (
                    !(storageFloor.levels ?? []).some(
                        (level) => (level.name ?? '').trim() === value.trim()
                    ) || value.trim() === initialName.trim()
                );
            }),
    });
};
export const editRowSchema = (level: ILevel | null | undefined, initialName: string) => {
    return yup.object().shape({
        warehouseName: yup
            .string()
            .required(YUP_EDIT_NAME_SCHEMA.REQUIRED)
            .min(YUP_EDIT_NAME_SCHEMA.MIN.VALUE, YUP_EDIT_NAME_SCHEMA.MIN.MESSAGE)
            .max(YUP_EDIT_NAME_SCHEMA.MAX.VALUE, YUP_EDIT_NAME_SCHEMA.MAX.MESSAGE)
            .matches(YUP_EDIT_NAME_SCHEMA.MATCHES.REGEX, YUP_EDIT_NAME_SCHEMA.MATCHES.MESSAGE)
            .test(YUP_EDIT_NAME_SCHEMA.TEST.NAME, YUP_EDIT_NAME_SCHEMA.TEST.MESSAGE, (value) => {
                if (!level) return false;
                return (
                    !(level.rows ?? []).some((row) => (row.name ?? '').trim() === value.trim()) ||
                    value.trim() === initialName.trim()
                );
            }),
    });
};
