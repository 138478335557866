import {URL} from '@/config/constants/index';
import {AppDispatch} from '@/config/store';
import {
    PUT_UPDATE_ROLE,
    PUT_UPDATE_ROLE_ERROR,
    PUT_UPDATE_ROLE_SUCCESS,
} from '@components/RolesPermissions/Redux/Types';
import axios, {AxiosError, AxiosResponse} from 'axios';

export const PutUpdateRole = () => {
    return {
        type: PUT_UPDATE_ROLE,
    };
};

export const PutUpdateRoleSuccess = (result: AxiosResponse) => {
    return {
        type: PUT_UPDATE_ROLE_SUCCESS,
        payload: result,
    };
};

export const PutUpdateRoleError = (error: AxiosError) => {
    return {
        type: PUT_UPDATE_ROLE_ERROR,
        payload: error,
    };
};

type IPutRoleStatus = {
    id: number;
    operationalModules?: number[];
    adminModules?: number[];
    users?: number[];
    name: string;
};

export const PutUpdateRoleAction = (
    data: IPutRoleStatus,
    navigate: (url: string) => void,
    token: string
) => {
    const headers = {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(PutUpdateRole());
        const url = `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.PutUpdateRole}`;
        try {
            const response = await axios.put(url, data, {headers});
            dispatch(PutUpdateRoleSuccess(response));
            navigate('/RolesAndPermissions');
        } catch (error) {
            dispatch(PutUpdateRoleError(error as AxiosError));
        }
    };
};

export default PutUpdateRoleAction;
