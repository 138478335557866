import {useSelector} from 'react-redux';
import {DateTime} from 'luxon';
import React from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {LOAN_DETAILS} from '@components/PersonalLoans/NewLoanProduct/constants';
import {IDetailsPreviewProps} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/interfaces';
import {RootState} from '@/config/store';
import {HeaderPreview} from '@components/PersonalLoans/NewLoanProduct/ui/HeaderPreview';
import {ProductDetail} from '@components/PersonalLoans/NewLoanProduct/LoanProductPreview/Details/ProductDetail';

export const DetailsPreview: React.FC<IDetailsPreviewProps> = ({
    detailsRef,
    isEditing,
    setIsEditing,
}) => {
    const newLoanData = useSelector((state: RootState) => state.newLoanSubmittedData.details);
    const numberToCurrency = (number: number) => {
        return new Intl.NumberFormat('ES-MX', {
            style: 'currency',
            currency: 'MXN',
            maximumFractionDigits: 0,
        }).format(number);
    };
    const formatDate = (dateString: string | null | DateTime): string | null => {
        if (dateString && typeof dateString === 'string') {
            const date = new Date(dateString);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const year = date.getFullYear();
            return `${day}/${month}/${year}`;
        }
        return null;
    };
    const vigencyInit = formatDate(newLoanData.dateDueInit);
    const vigencyEnd = formatDate(newLoanData.dateDueEnd);
    const selectedChannels = newLoanData.requestChannel.filter((item) => item.value !== 3);
    return (
        <ContainerFlex
            backG="#FFF"
            Border="1px solid #E8E9EA"
            Radius="1.5rem"
            Padding="1.5rem"
            Gap="1.5rem"
            FlexDir="column"
            Align="start"
            Justify="start"
            Height="max-content"
            ref={detailsRef}
        >
            <HeaderPreview
                title={LOAN_DETAILS.TITLE}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
            />
            <ContainerFlex FlexDir="column" Gap="1.5rem" Justify="start" Align="start">
                <ContainerFlex Gap="1.5rem">
                    <ProductDetail
                        label={LOAN_DETAILS.FIELDS.PRODUCT_NAME}
                        value={newLoanData.termName}
                    />
                    {newLoanData.description.length > 0 && (
                        <ProductDetail
                            label={LOAN_DETAILS.FIELDS.DESCRIPTION}
                            value={newLoanData.description}
                        />
                    )}
                    <ContainerFlex />
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="1.5rem" Justify="start" Align="start">
                <ContainerFlex Gap="1.5rem">
                    {[
                        {
                            label: LOAN_DETAILS.FIELDS.MIN_AMOUNT,
                            value: numberToCurrency(newLoanData.minAmount),
                        },
                        {
                            label: LOAN_DETAILS.FIELDS.MAX_AMOUNT,
                            value: numberToCurrency(newLoanData.maxAmount),
                        },
                    ].map((item, index) => (
                        <ProductDetail label={item.label} value={item.value} key={index} />
                    ))}
                    <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                        <Text Color="#2A2C2F" FontWeight="700">
                            {LOAN_DETAILS.FIELDS.LOAN_VIGENCY}
                        </Text>
                        <ContainerFlex Justify="start">
                            {vigencyInit && (
                                <Text Color="#2A2C2F" Height="40px" Padding="0.5rem 0px">
                                    {vigencyInit} &nbsp;
                                </Text>
                            )}
                            {vigencyEnd && (
                                <Text Color="#2A2C2F" Height="40px" Padding="0.5rem 0px">
                                    {`- ${vigencyEnd}`}
                                </Text>
                            )}
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex FlexDir="column" Gap="1.5rem" Justify="start" Align="start">
                <ContainerFlex Gap="1.5rem">
                    {[
                        {
                            label: LOAN_DETAILS.FIELDS.INTEREST,
                            value: `${newLoanData.porcentAnualRate}%`,
                        },
                        {label: LOAN_DETAILS.FIELDS.TERMS, value: newLoanData.periodMin.label},
                    ].map((item, index) => (
                        <ProductDetail label={item.label} value={item.value} key={index} />
                    ))}
                    {newLoanData.periodMax.value > 0 ? (
                        <ProductDetail
                            label={LOAN_DETAILS.FIELDS.MAX_TERM}
                            value={newLoanData.periodMax.label}
                        />
                    ) : (
                        <ContainerFlex />
                    )}
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Gap="1.5rem" Justify="start" Align="start">
                    <ContainerFlex Gap="1.5rem">
                        <ContainerFlex Gap="0.25rem" FlexDir="column" Align="start" Justify="start">
                            <Text Color="#2A2C2F" FontWeight="700">
                                {LOAN_DETAILS.FIELDS.APPLICATION_CHANNEL}
                            </Text>
                            <ContainerFlex Gap="0.5rem" Justify="start">
                                {selectedChannels.map((item, index) => (
                                    <Text
                                        Color="#2A2C2F"
                                        FontWeight="700"
                                        Height="32px"
                                        Padding="0.25rem 0.5rem"
                                        bGround="#F4F5F5"
                                        BorderRadius="0.5rem"
                                        key={index}
                                    >
                                        {item.label}
                                    </Text>
                                ))}
                            </ContainerFlex>
                        </ContainerFlex>
                        <ProductDetail
                            label={LOAN_DETAILS.FIELDS.NO_LOANS_LIMIT}
                            value={String(newLoanData.countLimitLoans)}
                        />
                        <ContainerFlex />
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};
