import {AxiosResponse} from 'axios';
import {
    GET_ALL_DATA_CONTACT_COMPANY_START,
    GET_ALL_DATA_CONTACT_COMPANY_SUCCESS,
    GET_ALL_DATA_CONTACT_COMPANY_ERROR,
} from '@MyCompany/Redux/Types/types';

const initialState = {
    contact: null,
    loading: false,
    error: false,
};

const GetAllContactData = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case GET_ALL_DATA_CONTACT_COMPANY_START:
            return {
                ...state,
                loading: true,
            };
        case GET_ALL_DATA_CONTACT_COMPANY_SUCCESS:
            return {
                ...state,
                contact: action.payload.data,
                loading: false,
            };
        case GET_ALL_DATA_CONTACT_COMPANY_ERROR:
            return {
                ...state,
                error: true,
                loading: false,
            };
        default:
            return state;
    }
};

export default GetAllContactData;
