import React from 'react';

import {Text} from '@Shopify/Ecommerce/styles';
import {FlexPanel} from '@Shopify/ClientProfile/Styles/styles';
import {TabProps} from '@General/ModuleTabs/interfaces';

const Tab: React.FC<TabProps> = ({label, activeTab, onClick, fontWeight}) => {
    const isActive = activeTab === label;

    return (
        <FlexPanel
            Padding="8px 16px"
            Bb={isActive ? '1.5px solid #5A5AFF' : '0'}
            Align="center"
            onClick={() => onClick(label)}
            Cursor="pointer"
        >
            <Text
                FontSize="1rem"
                FontWeight={fontWeight && isActive ? fontWeight : '400'}
                Cursor="pointer"
                Color={isActive ? '#5A5AFF' : '#54575C'}
                wSpace="normal"
            >
                {label}
            </Text>
        </FlexPanel>
    );
};

export default Tab;
