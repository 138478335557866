import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import QuoterForm from '@components/GlobalQuoter/QuoterForm';
import QuoterData from '@components/GlobalQuoter/QuoterData';
import barIcon from '@images/bar_icon.svg';
import {GENERAL_MESSAGE} from '@/components/GlobalQuoter/TempConstants';

const Quoter = () => {
    return (
        <ContainerFlex
            Height="485px"
            Width="1160px"
            backG="#FFFFFF"
            Radius="16px"
            Padding="32px 60px 16px 60px"
            Gap="16px"
            Justify="flex-start"
            Align="flex-start"
            FlexDir="column"
        >
            <ContainerFlex Gap="48px" Justify="flex-start" Align="flex-start">
                <QuoterForm />
                <QuoterData />
            </ContainerFlex>
            <ContainerFlex Gap="8px" Justify="center" Align="center">
                <Image src={barIcon} />
                <Text FontSize="0.875rem" Color="#54575C" FontFamily="Nunito">
                    {GENERAL_MESSAGE}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Quoter;
