import React from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {useNavigate} from 'react-router-dom';
import {ContainerFlex, ContainerGrid, Text, Image} from '@/components/Shopify/Ecommerce/styles';
import {CREDIT_QUOTER} from '@/components/CreditQuoter/constants';
import DetailsQuoterDocuments from './DetailsQuoterDocuments';
import DetailsQuoterConditions from './DetailsQuoterConditions';
import {ButtonGenerals} from '@components/General/Atoms/Button/styles';
import {numberToCurrencyWithoutCents} from '@/hooks/currentFormatUtils';
import {routers} from '@/appRoute';

const DetailsQuoter = () => {
    const QuoterCompaniesLoans = useSelector((state: RootState) => {
        return state.GetQuoterCompaniesLoansAditionalInf?.dataQuoter;
    });
    const navigate = useNavigate();
    const ValuateRangeTime = () => {
        const {periodMin, periodMax} = QuoterCompaniesLoans || {};
        const validPeriods = [periodMin, periodMax]
            .filter((value) => value && value !== 0)
            .filter((value, index, self) => self.indexOf(value) === index);
        if (validPeriods.length === CREDIT_QUOTER.DETAILS.TWO) {
            return `${CREDIT_QUOTER.DETAILS.RANGE_VAL} ${validPeriods[0]} ${CREDIT_QUOTER.DETAILS.RANGE_AND} ${validPeriods[1]} ${CREDIT_QUOTER.DETAILS.RANGE_MONTHS}`;
        }
        if (validPeriods.length === CREDIT_QUOTER.DETAILS.ONE || periodMin === periodMax) {
            return `${validPeriods[0]} ${
                validPeriods[0] === CREDIT_QUOTER.DETAILS.ONE
                    ? CREDIT_QUOTER.DETAILS.RANGE_MONTH
                    : CREDIT_QUOTER.DETAILS.RANGE_MONTHS
            }`;
        }
    };

    return (
        <ContainerFlex
            Padding="40px 60px"
            FlexDir="column"
            backG="#FAFAFF"
            Gap="24px"
            Justify="start"
            Align="start"
        >
            <ContainerFlex Justify="start" Align="center">
                <Text FontWeight="600" Color="#2A2C2F" FontFamily="Nunito" FontSize="1.75rem">
                    {CREDIT_QUOTER.DETAILS.TITLE}
                </Text>
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Gap="32px">
                <ContainerFlex
                    FlexDir="column"
                    Gap="32px"
                    Radius="16px"
                    Justify="start"
                    Align="center"
                    backG="#fff"
                    Padding="24px 16px"
                >
                    <ContainerGrid
                        GridColumns="repeat(3,1fr)"
                        Justify="center"
                        Padding="0px 0px 32px 0px"
                        BorderBt="1px solid #E8E9EA"
                    >
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="0.88rem"
                            >
                                {CREDIT_QUOTER.DETAILS.LOAN}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.5rem"
                            >
                                {numberToCurrencyWithoutCents(
                                    Number(QuoterCompaniesLoans?.minAmount)
                                ) +
                                    CREDIT_QUOTER.DETAILS.LOAN_EVELYN +
                                    numberToCurrencyWithoutCents(
                                        Number(QuoterCompaniesLoans?.maxAmount)
                                    )}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="0.88rem"
                            >
                                {CREDIT_QUOTER.DETAILS.RATE}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.5rem"
                            >
                                {QuoterCompaniesLoans?.porcentAnualRate +
                                    CREDIT_QUOTER.DETAILS.RATE_VAL}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="400"
                                Color="#54575C"
                                FontFamily="Nunito"
                                FontSize="0.88rem"
                            >
                                {CREDIT_QUOTER.DETAILS.RANGE}
                            </Text>
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.5rem"
                            >
                                {ValuateRangeTime()}
                            </Text>
                        </ContainerFlex>
                    </ContainerGrid>

                    <ContainerGrid
                        GridColumns="repeat(4,1fr)"
                        Justify="center"
                        Padding="32px 0px 32px 0px"
                        BorderBt="1px solid #E8E9EA"
                    >
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {CREDIT_QUOTER.DETAILS.TYPE_OF_CREDIT}
                            </Text>
                            <Text
                                FontWeight="400"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {QuoterCompaniesLoans?.creditType}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {CREDIT_QUOTER.DETAILS.CURRENCY}
                            </Text>
                            <Text
                                FontWeight="400"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                                Gap="4px"
                            >
                                <Image
                                    width="24px"
                                    Height="24px"
                                    alt="mexico-flag-icon"
                                    src={QuoterCompaniesLoans?.currency.currencyUrl}
                                />{' '}
                                {QuoterCompaniesLoans?.currency.currencyCode}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {CREDIT_QUOTER.DETAILS.COMMISSION}
                            </Text>
                            <Text
                                FontWeight="400"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {QuoterCompaniesLoans?.totalPenalties +
                                    CREDIT_QUOTER.DETAILS.RATE_VAL}
                            </Text>
                        </ContainerFlex>
                        <ContainerFlex FlexDir="column" Justify="center" Align="center" Gap="4px">
                            <Text
                                FontWeight="700"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {CREDIT_QUOTER.DETAILS.METHOD}
                            </Text>
                            <Text
                                FontWeight="400"
                                Color="#2A2C2F"
                                FontFamily="Nunito"
                                FontSize="1.0rem"
                            >
                                {QuoterCompaniesLoans?.processType}
                            </Text>
                        </ContainerFlex>
                    </ContainerGrid>
                    <ButtonGenerals
                        Width="320px"
                        FontSize="1rem"
                        FontWeight="700"
                        Cursor="pointer"
                        property="8px 16px"
                        MHeight="40px"
                        onClick={() => navigate(routers.PersonalLoans)}
                    >
                        {CREDIT_QUOTER.DETAILS.REQUEST_NOW_CTA}
                    </ButtonGenerals>
                </ContainerFlex>
                <ContainerFlex backG="#E8E9EA" Width="100%" Height="1px" />
                <DetailsQuoterDocuments />
                <DetailsQuoterConditions />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default DetailsQuoter;
