import React from 'react';
import {ContainerFlex, Text} from '@components/Shopify/Ecommerce/styles';
import {ImageLogo} from '@components/LoginClient/styles';
import breadCrumbImg from '@images/arrowBreadCrumb.svg';
import {breadCrumb} from '@components/RolesPermissions/constants';

export const BreadCrumb = () => {
    return (
        <ContainerFlex Justify="start" Gap="10px" Height="1.5rem">
            <Text>{breadCrumb.Company}</Text>
            <ImageLogo src={breadCrumbImg} Height="8px" Width="8px" alt="arrow" />
            <Text FontWeight="700">{breadCrumb.Roles}</Text>
        </ContainerFlex>
    );
};
