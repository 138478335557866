import * as yup from 'yup';
import {FILTER_TEXTS} from '@components/AuctionPass/AuctionConstants';
export const initalState = {
    publicationDate: '',
    priceFrom: 0,
    priceTo: 0,
    status: [],
    category: {value: 0, label: ''},
    subCategory: {value: 0, label: ''},
};
export const CATEGORY_OPTIONS = [
    {value: 1, label: 'Electrónica'},
    {value: 2, label: 'Ropa'},
    {value: 3, label: 'Hogar'},
    {value: 4, label: 'Deportes'},
    {value: 5, label: 'Juguetes'},
];

export const SUBCATEGORY_OPTIONS = [
    {value: 1, label: 'Teléfonos'},
    {value: 2, label: 'Laptops'},
    {value: 3, label: 'Televisores'},
    {value: 4, label: 'Camisetas'},
    {value: 5, label: 'Pantalones'},
    {value: 6, label: 'Sillas'},
    {value: 7, label: 'Mesas'},
    {value: 8, label: 'Balones'},
    {value: 9, label: 'Bicicletas'},
    {value: 10, label: 'Muñecas'},
];

export const validationSchema = yup.object().shape({
    publicationDate: yup.string().required(FILTER_TEXTS.EMPITY_MESSAGE),
    priceFrom: yup
        .number()
        .min(0, FILTER_TEXTS.EMPITY_MESSAGE)
        .required(FILTER_TEXTS.EMPITY_MESSAGE)
        .max(9999999, FILTER_TEXTS.EMPITY_MESSAGE),
    priceTo: yup
        .number()
        .min(0, FILTER_TEXTS.EMPITY_MESSAGE)
        .required(FILTER_TEXTS.EMPITY_MESSAGE)
        .max(9999999, FILTER_TEXTS.EMPITY_MESSAGE),
    status: yup.array().min(1, FILTER_TEXTS.EMPITY_MESSAGE).optional(),
    category: yup
        .object()
        .shape({
            value: yup.number().required(FILTER_TEXTS.EMPITY_MESSAGE),
            label: yup.string().required(FILTER_TEXTS.EMPITY_MESSAGE),
        })
        .required(FILTER_TEXTS.EMPITY_MESSAGE),
    subCategory: yup
        .object()
        .shape({
            value: yup.number().required(FILTER_TEXTS.EMPITY_MESSAGE),
            label: yup.string().required(FILTER_TEXTS.EMPITY_MESSAGE),
        })
        .required(FILTER_TEXTS.EMPITY_MESSAGE),
});
