import React from 'react';
import {ContainerFlex, Image, ImgAvatar} from '@Shopify/Ecommerce/styles';
import {IAddUserAvatar} from '@components/LocationsBranch/interfaces';
import Remove from '@images/remove.svg';
import {ElipsedText} from '@Roles/styles';

export const AddUserAvtar = ({item, onSelectUser, onRemoveUser}: IAddUserAvatar) => {
    return (
        <ContainerFlex
            Height={'1.7rem'}
            Justify="start"
            Align="start"
            ColumnGap="0.5rem"
            FlexDir="row"
            Gap="0.5rem"
            Padding="0 0.5rem"
            onClick={() => {
                if (onSelectUser) onSelectUser(item);
            }}
            Cursor={onSelectUser ? 'pointer' : 'auto'}
        >
            <ContainerFlex
                Height={''}
                Justify="start"
                Align="start"
                ColumnGap="0.5rem"
                Padding="0 0.5rem"
            >
                <ImgAvatar
                    src={item.imageURL}
                    Height="2rem"
                    Width="2rem"
                    Radius="1.875rem"
                    alt="avatar"
                />
                <ElipsedText
                    Color="#1D1E20"
                    FontSize="0.875rem"
                    Cursor={onSelectUser ? 'pointer' : 'auto'}
                    FontWeight={'500'}
                    Height="1rem"
                    Padding="0.563rem 0.563rem 0.563rem 0.5rem"
                    FontFamily="Nunito"
                >
                    {item.name}
                </ElipsedText>
            </ContainerFlex>
            <ContainerFlex
                Height={''}
                Justify="end"
                Align="end"
                ColumnGap="0.5rem"
                Padding="0.4rem 0.5rem"
                Cursor="pointer"
            >
                {onRemoveUser && (
                    <Image
                        Cursor="pointer"
                        src={Remove}
                        width={'24px'}
                        height={'24px'}
                        onClick={() => {
                            onRemoveUser(item.employeeId);
                        }}
                    />
                )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
