import {
    ADD_CART_PAYMENT,
    ADD_CART_PAYMENT_SUCCESS,
    ADD_CART_PAYMENT_ERROR,
    CART_PAYMENT_ENTERED,
    ADD_PAYMENT_RESET,
} from '@TypesLoan/types';
import {ISummaryResult, ISummaryResultRedux} from '@components/Loan/Redux/interfaces';

const initialState: ISummaryResult = {
    loading: false,
    error: false,
    paid: false,
    paymentResult: {
        succeeded: false,
        message: '',
        errors: [],
        token: '',
        data: {
            pawnsPay: [],
            endorsementsPay: [],
            dischargesPay: [],
            subTotal: 0,
            iva: 0,
            total: 0,
            cashPayment: 0,
            cardPayment: 0,
            changePayment: 0,
            cardAuthorization: '',
        },
    },
};

const cartPaymentReducer = (state = initialState, action: ISummaryResultRedux): ISummaryResult => {
    switch (action.type) {
        case ADD_CART_PAYMENT:
            return {
                ...state,
                loading: true,
                error: false,
            };
        case ADD_CART_PAYMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                paymentResult: action.paymentResult,
            };
        case ADD_CART_PAYMENT_ERROR:
            return {
                ...state,
                loading: false,
                error: true,
            };
        case CART_PAYMENT_ENTERED:
            return {
                ...state,
                paid: action.paid,
            };
        case ADD_PAYMENT_RESET:
            return initialState;
        default:
            return state;
    }
};

export default cartPaymentReducer;
