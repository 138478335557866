import axios from 'axios';
import {
    PUT_PICTURE,
    PUT_PICTURE_SOUCCES,
    PUT_PICTURE_ERROR,
} from '@components/Users/UserProfile/UserProfileBranchAssignment/Redux/type';

import process from 'process';

import {URL} from '@config/constants/index';

export const PutNewLevelhResquest = () => {
    return {
        type: PUT_PICTURE,
    };
};
export const PutNewLevelBranchSuccess = (userData) => {
    return {
        type: PUT_PICTURE_SOUCCES,
        payload: userData,
    };
};

export const PutNewLevelBranchFailure = (err) => {
    return {
        type: PUT_PICTURE_ERROR,
        payload: err,
    };
};
export const addPictureUser = (token, pictureData) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(PutNewLevelhResquest());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateEmployeePicture}`,
                pictureData,
                {
                    headers,
                }
            );
            dispatch(PutNewLevelBranchSuccess(response.data));
        } catch (error) {
            dispatch(PutNewLevelBranchFailure(error));
        }
    };
};
