import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {DISCHARGE_ENDORSEMENT_ERRORS} from '@Shopify/ClientProfile/constants';
import {addDischargeOrEndorsement} from '@Shopify/ClientProfile/Redux/Action/AddDischargeEndorsement';
import {clientQuickActions} from '@Shopify/ClientProfile/Redux/Action/QuickAction';
import {removeDischargeExtension} from '@components/ShoppingCart/Redux/Actions/deleteDischargeEndorsement';
import {TYPE_MOVEMENT_ADD_CART} from '@Shopify/ClientProfile/constants';
import {UseDischargeOrEndorsementProps} from '@/hooks/interfaces';

const useTransferEndorseOrDischarge = ({
    tranferData,
    token,
    carShoppingId,
    onSuccess,
    onError,
}: UseDischargeOrEndorsementProps) => {
    const dispatch = useDispatch();
    const {actions} = useSelector((state: RootState) => state.quickAction);
    const {clientProfile} = useSelector((state: RootState) => ({
        clientProfile: state.cardClientProfile,
    }));
    const shopData = actions.find((item) => item.contract === tranferData.contractName);
    const branchId = useSelector((state: RootState) => {
        const branchDetails = state.getUsersValidation.userData?.branchDetails;
        if (Array.isArray(branchDetails) && branchDetails.length > 0) {
            return branchDetails[0]?.branchId;
        }
        return undefined;
    });

    useEffect(() => {
        if (!shopData) {
            dispatch(clientQuickActions(token, {clientId: clientProfile.idClient}, true));
        }
    }, [shopData]);

    const processTransaction = async (
        typeMovement: number,
        callbackSuccess: () => void,
        callbackError: (message: string) => void
    ) => {
        try {
            await dispatch(
                removeDischargeExtension(token, tranferData.itemId, 0, tranferData.extensionId)
            );

            const shopValuator = shopData?.importCarShopValuator!;
            const payment =
                typeMovement === TYPE_MOVEMENT_ADD_CART.RENEWAL
                    ? shopData?.payment!
                    : shopData?.liquidation!;
            const transactionData = {
                carShoppingId: Number(carShoppingId),
                typeMovement,
                branchId: Number(branchId),
                pawnId: shopData?.pawnId!,
                paymentAmount: payment,
                iva: shopValuator?.iva,
                moratoriumAmount: shopValuator?.moratoriumAmount,
                amountStorage: shopValuator?.amountStorage,
                amountInterest: shopValuator?.maximumInterest,
                insuranceAmount: shopValuator?.insuranceAmount,
                amountReposition: shopValuator?.amountReposition,
                amountRepositionIVA: shopValuator?.amountRepositionIVA,
                amountDiscountInterest: shopValuator?.amountDiscountInterest,
                amountDiscountInterestIVA: shopValuator?.amountDiscountInterestIVA,
                maximumInterest: shopValuator?.maximumInterest,
            };
            await dispatch(
                addDischargeOrEndorsement(transactionData, token, callbackSuccess, callbackError)
            );
        } catch (error) {
            callbackError(DISCHARGE_ENDORSEMENT_ERRORS.GENERAL);
        }
    };

    const handleTransfer = async (transferType: number) => {
        await processTransaction(transferType, onSuccess, onError);
    };

    return {
        handleTransfer,
    };
};

export default useTransferEndorseOrDischarge;
