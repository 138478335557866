import {useCallback} from 'react';

import {GENERAL_TITLES} from '@components/Users/UserProfile/constants';

export const usePersonalInfoForm = () => {
    const formatPhoneNumber = useCallback((value: string) => {
        const phoneNumber = value?.replace(/\D/g, '') || '';
        if (phoneNumber.length === 10) {
            return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
        return phoneNumber;
    }, []);

    const unformatPhoneNumber = useCallback((value: string) => {
        return value.replace(/\D/g, '');
    }, []);

    const formatDate = useCallback((dateString: string | undefined) => {
        if (!dateString) return GENERAL_TITLES.EMPITY;
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    }, []);

    const isEmpty = useCallback((data: string | undefined) => {
        return data && data.length ? data : GENERAL_TITLES.EMPITY;
    }, []);

    const handlePhoneChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>, onChange: (value: string) => void) => {
            const formattedValue = formatPhoneNumber(e.target.value);
            const unformattedValue = unformatPhoneNumber(formattedValue);
            onChange(unformattedValue);
        },
        [formatPhoneNumber, unformatPhoneNumber]
    );

    const getGenderOptions = useCallback((genders: Array<{label: string}>) => {
        return genders.map((gender) => ({
            label: gender.label,
            value: gender.label,
        }));
    }, []);

    const handleGenderChange = useCallback(
        (selectedOption: {label: string} | null, onChange: (value: {label: string}) => void) => {
            onChange({label: selectedOption?.label || ''});
        },
        []
    );

    return {
        formatPhoneNumber,
        unformatPhoneNumber,
        formatDate,
        isEmpty,
        handlePhoneChange,
        getGenderOptions,
        handleGenderChange,
    };
};
