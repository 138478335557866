import {DateTime} from 'luxon';
import * as yup from 'yup';
import {PAYMENT_METHOD} from '@/components/MyAccount/MyCredit/CreditDetails/PaymentMethod/constants';

const isValidDate = (dateString: string | undefined) => {
    if (dateString) {
        const date = DateTime.fromISO(dateString!);
        return date.isValid;
    }
    return false;
};
const isFutureYear = (dateString: string | undefined) => {
    if (dateString) {
        const date = DateTime.fromISO(dateString!);
        return date > DateTime.now();
    }
    return false;
};

const validateCardNumber = (value: string | undefined): boolean => {
    if (!value) return false;

    const cleanValue = value.replace(/\D/g, '');

    const isVisaOrMasterCard = /^4\d{15}$|^5[1-5]\d{14}$/.test(cleanValue);
    const isAmex = /^3[47]\d{13}$/.test(cleanValue);

    return (
        (cleanValue.length === 16 && isVisaOrMasterCard) ||
        (cleanValue.length === 15 && isAmex) ||
        (cleanValue.length === 12 && isVisaOrMasterCard)
    );
};

export const schemaPaymentMethod = yup.object().shape({
    cardNumber: yup
        .string()
        .required(PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.REQUIRED)
        .test(
            'is-valid-card-number',
            PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.CARD_NUMBER,
            validateCardNumber
        ),
    cardOwner: yup
        .string()
        .required(PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.REQUIRED)
        .min(3, PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.CARD_OWNER.MIN)
        .matches(
            /^[a-zA-Z.\sáéíóúñÁÉÍÓÚÑ\-']+$/,
            PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.CARD_OWNER.MATCH
        ),
    vigency: yup
        .string()
        .required(PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.REQUIRED)
        .test(
            'is-valid-date',
            PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.VALIDITY.VALID_DATE,
            isValidDate
        )
        .test(
            'is-future-date',
            PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.VALIDITY.FUTURE_YEAR,
            isFutureYear
        ),
    cvv: yup
        .string()
        .required(PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.REQUIRED)
        .matches(/^[0-9]{3,4}$/, PAYMENT_METHOD.SCHEMA_PAYMENT_METHODS.CVV),
});
