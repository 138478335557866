import React from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import DropdownWithIcon from '@components/GlobalQuoter/DropdownWithIcon';
import {FILTER_BOX, IMAGE_ALT} from '@/components/GlobalQuoter/TempConstants';
import calendarFrequency from '@images/calendar_frequency.svg';
import calendarQuantity from '@images/calendar_quantity.svg';
import moneySymbolCircle from '@images/money_symbol_circle.svg';
import filterIcon from '@images/filter.svg';
import InputWithIcon from '@components/GlobalQuoter/InputWithIcon';
import {useDispatch, useSelector} from 'react-redux';
import {toggleFilterModal} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFilterActions';
import {RootState} from '@/config/store';
import {setVisualFormValues} from '@components/GlobalQuoter/Redux/Actions/GlobalQuoterFormActions';

const FilterBox = () => {
    const {frequencyOptions} = useSelector(
        (state: RootState) => state.globalQuoterFrequencyReducer
    );
    const {quoterCompleteList} = useSelector((state: RootState) => state.globalQuoterFilterRedcuer);
    const visualState = useSelector((state: RootState) => state.globalQuoterFormReducer);
    const dispatch = useDispatch();
    const quantityOptionsArray = Array.from({length: 28}, (_, i) => i + 1);
    const arrayWtithValues = quantityOptionsArray.map((value) => {
        return {
            label: (value * 3).toString(),
            value: value * 3,
        };
    });
    const toggleFilterModalHandler = () => {
        if (quoterCompleteList.length === 0) {
            return;
        }
        dispatch(toggleFilterModal());
    };

    const handleChangefrecuency = (newValue: number) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                frequencyId: newValue,
            })
        );
    };

    const handleChangePaymentNum = (newValue: number) => {
        dispatch(
            setVisualFormValues({
                ...visualState,
                accountPayments: newValue,
            })
        );
    };

    return (
        <ContainerFlex
            backG="#F4F5F5"
            Radius="16px 16px 0px 0px"
            Padding="16px"
            Justify="space-between"
            Align="center"
            Gap="24px"
            Height="88px"
        >
            <ContainerFlex Justify="flex-start" Align="center" Gap="16px">
                <InputWithIcon
                    label={FILTER_BOX.LABELS.AMOUNT}
                    icon={moneySymbolCircle}
                    iconAlt={FILTER_BOX.ICONS.AMOUNT}
                />
                <DropdownWithIcon
                    label={FILTER_BOX.LABELS.PAYMENT_NUM}
                    icon={calendarQuantity}
                    iconAlt={FILTER_BOX.ICONS.PAYMENT_NUM}
                    options={arrayWtithValues}
                    initialValue={arrayWtithValues[0].label}
                    optionContainerWidth="311px"
                    value={visualState.accountPayments}
                    name={FILTER_BOX.FORM_NAME.PAYMENT_NUM}
                    onChange={handleChangePaymentNum}
                />
                <DropdownWithIcon
                    label={FILTER_BOX.LABELS.PAYMENT_FREQ}
                    icon={calendarFrequency}
                    iconAlt={FILTER_BOX.ICONS.PAYMENT_FREQ}
                    options={frequencyOptions}
                    optionContainerWidth="311px"
                    value={visualState.frequencyId}
                    name={FILTER_BOX.FORM_NAME.PAYMENT_FREQ}
                    onChange={handleChangefrecuency}
                />
            </ContainerFlex>
            <ContainerFlex
                Justify="flex-start"
                Align="center"
                Gap="8px"
                Cursor={quoterCompleteList.length === 0 ? 'not-allowed' : 'pointer'}
                onClick={toggleFilterModalHandler}
            >
                <Image
                    src={filterIcon}
                    alt={IMAGE_ALT.FILTER_ICON}
                    Cursor={quoterCompleteList.length === 0 ? 'not-allowed' : 'pointer'}
                />
                <Text
                    FontSize="1rem"
                    Color="#5A5AFF"
                    FontFamily="Nunito"
                    FontWeight="700"
                    Cursor={quoterCompleteList.length === 0 ? 'not-allowed' : 'pointer'}
                >
                    {FILTER_BOX.LABELS.MORE_FILTERS}
                </Text>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default FilterBox;
