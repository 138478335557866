import {
    USER_TABLE_REQUEST,
    USER_TABLE_SOUCCES,
    USER_TABLE_ERROR,
    USER_TABLE_RESET,
} from '@components/MyCompanyUser/Redux/types';
import axios, {AxiosError, AxiosResponse} from 'axios';

import process from 'process';

import {URL} from '@config/constants/index';
import {AppDispatch} from '@/config/store';
import {IDListUser} from '@/components/MyCompanyUser/Operational/interfaceType';

export const getUserTableRequest = () => {
    return {
        type: USER_TABLE_REQUEST,
    };
};
export const getUserTableSuccess = (userData: AxiosResponse) => {
    return {
        type: USER_TABLE_SOUCCES,
        payload: userData,
    };
};
export const getUserTableFailure = (error: AxiosError) => {
    return {
        type: USER_TABLE_ERROR,
        payload: error,
    };
};

export const getUserTableReset = () => {
    return {
        type: USER_TABLE_RESET,
    };
};

export const TableAllUserData = (token: string, requestData: IDListUser) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getUserTableRequest());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetUserBCompanyIdPaged}`,
                {
                    headers,
                    params: requestData,
                }
            );
            dispatch(getUserTableSuccess(response));
        } catch (error) {
            dispatch(getUserTableFailure(error as AxiosError));
        }
    };
};
