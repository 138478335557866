import {ADD_IDATAFACE} from './interface';

export const EXTRA_TEXT = {
    RESTORE: 'Restaurar',
    ARTICLE: 'Artículos',
};

export const FOOTER_ONE = {
    RESTORE: 'Dias de pausa',
    ASSIGNED: '(3) días asignados',
};

export const FOOTER_TWO = {
    RESTORE: 'Porcentaje de incremento',
    ASSIGNED: 'Cero por ciento.',
};

export const AUCTION_TITLES = {
    PAGE_TITLE: 'Configurador Almoneda',
    ALL: 'Todas',
    CANCEL: 'Cancelar',
    SAVE: 'Aplicar',
    SELECT: '0',
    DAYS: '(3) días asignados',
    SELECT_ERROR: 'No hay opciones disponibles',
    SUBTITLE: 'Selecciona la forma de pase almoneda.',
    OTHE_TITLE: 'Pase almoneda',
    CARD1: {
        TITLE: 'Revisión de artículos',
        CONFIGURE: 'Configurar',
        DESCRIPTION: 'Editar detalles',
        APPLY: 'Aplicar',
        APPLY_CHANGES: 'Cambios en los artículos',
    },
    CARD2: {
        TITLE: 'Incremento al pase almoneda',
        CONFIGURE: 'Configurar',
        DESCRIPTION: 'Porcentaje agregado',
        APPLY: 'Aplicar',
        APPLY_CHANGES: 'Cambios en los artículos',
    },
    CARD3: {
        TITLE: 'Promociones integradas',
        CONFIGURE: 'Configurar',
        DESCRIPTION: 'Promociones asignadas',
        APPLY: 'Aplicar',
        APPLY_CHANGES: 'Cambios en los artículos',
    },
    TABLE_HEADERS: {
        FILTER_LABEL: 'Filtrar',
        COLUMNS: {
            IMAGE: 'Imagen',
            NAME: 'Nombre',
            SKU: 'SKU',
            CATEGORY: 'Categoría',
            DATE: 'Fecha',
            PRICE: 'Precio',
            STATUS: 'Estado',
            PAUSE: 'Pausar',
        },
    },
    BUTTONS: {
        EDIT: 'Editar',
        RESTORE: 'Restaurar',
    },
    FILTER_OPTIONS: 'Nombre ascendente...',
    SEARCH_PLACEHOLDER: 'Buscar',
    STATUS_OPTIONS: {
        PUBLISHED: 'Publicado',
        RESERVED: 'Apartado',
    },
};

export const TABLE_HEADERS = [
    {NAME: 'Imágen'},
    {NAME: 'Nombre'},
    {NAME: 'SKU'},
    {NAME: 'Categoría'},
    {NAME: 'Fecha'},
    {NAME: 'Precio'},
    {NAME: 'Estado'},
    {NAME: 'Pausar'},
];
interface IArticle {
    id: number;
    name: string;
    brand: string;
    category: string;
    date: string;
    sku: string;
    price: number;
    priceChange: string;
    status: string;
    published: boolean;
    actions: string;
}

export const TABLE_ARTICLES: IArticle[] = [
    {
        id: 1,
        name: 'Audífonos',
        brand: 'SONY',
        sku: '1334ASDASD',
        category: 'Audio',
        date: 'Oct 30',
        price: 2500,
        priceChange: '+1.3%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
    {
        id: 2,
        name: 'Televisor',
        brand: 'Samsung',
        sku: '1334ASDASD',

        category: 'Video',
        date: 'Sep 15',
        price: 50000,
        priceChange: '-0.5%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
    {
        id: 3,
        name: 'Cámara',
        brand: 'Canon',
        sku: '1334ASDASD',

        category: 'Fotografía',
        date: 'Ago 22',
        price: 18000,
        priceChange: '0.0%',
        status: 'No publicado',
        published: false,
        actions: 'Editar',
    },
    {
        id: 4,
        name: 'Laptop',
        brand: 'Apple',
        sku: '1334ASDASD',

        category: 'Computación',
        date: 'Jul 11',
        price: 30000,
        priceChange: '+2.1%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
    {
        id: 5,
        name: 'Smartphone',
        brand: 'Xiaomi',
        sku: '1334ASDASD',

        category: 'Teléfonos',
        date: 'Jun 05',
        price: 12000,
        priceChange: '-0.7%',
        status: 'No publicado',
        published: false,
        actions: 'Editar',
    },
    {
        id: 6,
        name: 'Tablet',
        brand: 'Huawei',
        sku: '1334ASDASD',

        category: 'Tabletas',
        date: 'May 20',
        price: 10000,
        priceChange: '+0.9%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
    {
        id: 7,
        name: 'Consola de videojuegos',
        brand: 'Nintendo',
        category: 'Videojuegos',
        date: 'Abr 10',
        sku: '1334ASDASD',

        price: 9500,
        priceChange: '+1.0%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
    {
        id: 8,
        name: 'Monitor',
        brand: 'LG',
        category: 'Periféricos',
        date: 'Mar 14',
        price: 7000,
        sku: '1334ASDASD',

        priceChange: '-1.2%',
        status: 'No publicado',
        published: false,

        actions: 'Editar',
    },
    {
        id: 9,
        name: 'Impresora',
        brand: 'HP',
        category: 'Impresión',
        date: 'Feb 02',
        price: 3000,
        sku: '1334ASDASD',

        priceChange: '+0.5%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
    {
        id: 10,
        name: 'Auriculares',
        brand: 'Bose',
        sku: '1334ASDASD',

        category: 'Audio',
        date: 'Ene 15',
        price: 15000,
        priceChange: '+1.7%',
        status: 'Publicado',
        published: true,
        actions: 'Editar',
    },
];

export const CATEGORIES = [{value: 1, label: 'Nombres asínados'}];
export const INITAL_VALUE = 0;
export const FINAL_VALUE = 7;
export const EditModalAuctionArticle = {
    title: 'Revisión de artículos',
    description:
        'La revisión de un artículo te permite verificar la descripción, precio y otros detalles de cada publicación.',
    subtitle: 'Selecciona las categorías que se pausaran.',
    bottomTitle: 'Dias de pausa',
};
export const EditModalAuction = {
    title: 'Incremento al pase almoneda',
    description:
        'Con el incremento del pase de almoneda, puedes aplicar un aumento porcentual al precio una vez que haya pasado a la venta.',
    subtitle: 'Selecciona la categoría que se incrementará',
    bottomTitle: 'Porcentaje de incremento',
};

export const EditIntegratedPromotions = {
    title: 'Promociones integradas',
    description:
        'Las promociones integradas te permiten vincular las promociones vigentes que tengas activas en tu empresa.',
    subtitle: 'Busca una promoción vigente',
    bottomTitle: 'Porcentaje de incremento',
};

export const TreeForAuctionArticles: ADD_IDATAFACE[] = [
    {
        id: 13,
        nameGroup: 'Eletronicaa',
        percent: '',
        validity: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 14,
        nameGroup: 'Tecnologia',
        percent: '',
        validity: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 25,
        nameGroup: 'Hogar',
        validity: '',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 11,
        nameGroup: 'Entretenimiento',
        validity: '',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 18,
        nameGroup: 'Exclusivos',
        validity: '',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 19,
        nameGroup: 'Joyeria',
        validity: '',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
];

export const TreeForAuctionIncrease: ADD_IDATAFACE[] = [
    {
        id: 111,
        nameGroup: 'Eletronica',
        percent: '1%',
        validity: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '1%',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 10,
        nameGroup: 'Tecnologia',
        percent: '1%',
        validity: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '1%',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 16,
        nameGroup: 'Hogar',
        validity: '',
        percent: '1%',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '1%',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 234,
        nameGroup: 'Entretenimiento',
        validity: '',
        percent: '1%',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '1%',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 137,
        nameGroup: 'Exclusivos',
        validity: '',
        percent: '1%',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '1%',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 132,
        nameGroup: 'Joyeria',
        validity: '',
        percent: '1%',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                validity: '',
                percent: '1%',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                validity: '',
                percent: '1%',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
];

export const TreeForIntegratedPromotions: ADD_IDATAFACE[] = [
    {
        id: 178,
        nameGroup: 'Buen Fin',
        validity: 'Vigencia 08/02/2023',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                validity: '',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                validity: '',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                validity: '',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 122,
        nameGroup: 'Dia del padre',
        validity: 'Vigencia 08/02/2023',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 456,
        nameGroup: 'Dia de la madre',
        validity: 'Vigencia 10/06/2023',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                validity: '',
                nameBrand: '',
                nameGroup: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
                percent: '',
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
    {
        id: 1223,
        nameGroup: 'Dia del niño',
        validity: 'Vigencia 18/07/2023',
        percent: '',
        status: true,
        iconUrl: '',
        children: [
            {
                id: 6,
                nameFamily: 'Familia prueba 1 (Inactivo)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: false,
                children: [],
                percent: '',
            },
            {
                id: 41,
                nameFamily: 'OMENMORE (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 37,
                nameFamily: 'otravez (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                name: '',
                nameSubFamily: '',
                markId: 0,
                iconUrl: '',
                status: true,
                children: [],
                percent: '',
            },
            {
                id: 1046,
                nameFamily: 'RETER (0)',
                nameBrand: '',
                nameGroup: '',
                validity: '',
                percent: '',
                name: '',
                nameSubFamily: '',
                status: true,
                markId: 0,
                iconUrl: '',
                children: [],
            },
        ],
        nameFamily: '',
        nameSubFamily: '',
        nameBrand: '',
        name: '',
        markId: 0,
    },
];
