import {CART_STATE} from '@components/ShoppingCart/Redux/types';
import {ICartState, ICartStateRedux} from '@components/ShoppingCart/Redux/interfaces';

const initialState: ICartState = {
    isCartOpen: false,
    docked: false,
};

const cartState = (state = initialState, action: ICartStateRedux): ICartState => {
    switch (action.type) {
        case CART_STATE.SET_IS_CART_OPEN:
            return {
                ...state,
                isCartOpen: action.payload,
                docked: action.payload ? state.docked : false,
            };
        case CART_STATE.SET_DOCKED:
            if (state.isCartOpen) {
                return {
                    ...state,
                    docked: action.payload,
                };
            }
            return {
                ...state,
                docked: false,
            };
        default:
            return state;
    }
};
export default cartState;
