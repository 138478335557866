import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Text, ContainerFlex} from '@EcommerceShopify/styles';
import {RootState, AppDispatch} from '@config/store';
import {IconContainer} from '@/components/Quoter/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {SelectForm} from '@/components/Loan/styles';
import ButtonGeneral from '@/components/General/Atoms/Button';
import {
    endeavorsActualPage,
    endeavorsFolio,
    endeavorsNumberBranch,
} from '@Endeavors/redux/actions/EndeavorsClientActions';
import {endeavors} from '@/components/Shopify/Endeavors/redux/interfaces';

export function EndeavorsFilters() {
    const dispatch: AppDispatch = useDispatch();
    const [pageSize, setPageSize] = useState(1);
    const endeavorsClient: endeavors = useSelector((state: RootState) => {
        return state.endeavorsClient;
    });

    const paginate = (button: string) => {
        switch (button) {
            case 'back':
                if (endeavorsClient.actualPage > 1) {
                    dispatch(endeavorsActualPage(endeavorsClient.actualPage - 1));
                    setPageSize(pageSize - endeavorsClient.endeavors.pageSize);
                }
                break;
            case 'next':
                if (endeavorsClient.actualPage < endeavorsClient.endeavors.totalPages) {
                    dispatch(endeavorsActualPage(endeavorsClient.actualPage + 1));
                    setPageSize(pageSize + endeavorsClient.endeavors.pageSize);
                }
                break;
        }
    };

    const CloseSearch = () => {
        dispatch(endeavorsActualPage(1));
        setPageSize(1);
    };

    return (
        <ContainerFlex Padding="2rem 0 0.875rem 0" Justify="space-between">
            <ContainerFlex Justify="start" Width="45%">
                <SearchInput
                    Height="40px"
                    Width="45%"
                    GridColumn="90% 10%"
                    Position="none"
                    Margin="0 1rem 0 0"
                    BackG={
                        !(endeavorsClient.endeavors?.data.length > 0) &&
                        !endeavorsClient.folioEndeavor &&
                        endeavorsClient.numberBranch === 0
                            ? '#f3f3f3'
                            : ''
                    }
                >
                    <input
                        value={endeavorsClient.folioEndeavor}
                        onChange={(e) => {
                            dispatch(endeavorsFolio(e.target.value));
                            CloseSearch();
                        }}
                        type="textfield"
                        placeholder="No. Folio"
                        disabled={
                            !(endeavorsClient.endeavors?.data.length > 0) &&
                            !endeavorsClient.folioEndeavor &&
                            endeavorsClient.numberBranch === 0
                        }
                    />
                    {endeavorsClient.folioEndeavor ? (
                        <IconContainer
                            onClick={() => {
                                dispatch(endeavorsFolio(''));
                                CloseSearch();
                            }}
                            JustifySelf="end"
                            FontSize="1.5rem"
                            Color="#6d6e71"
                            className="material-icons"
                            Cursor="pointer"
                        >
                            close
                        </IconContainer>
                    ) : (
                        <IconContainer FontSize="1.5rem" className="material-icons">
                            search
                        </IconContainer>
                    )}
                </SearchInput>
                <SelectForm
                    Height="40px"
                    Width="55%"
                    Margin="0"
                    TextA="start"
                    BackGround={
                        !(endeavorsClient.endeavors?.data.length > 0) &&
                        !endeavorsClient.folioEndeavor &&
                        endeavorsClient.numberBranch === 0
                            ? '#f3f3f3'
                            : ''
                    }
                    onChange={(e) => {
                        dispatch(endeavorsNumberBranch(Number(e.target.value)));
                        CloseSearch();
                    }}
                    disabled={
                        !(endeavorsClient.endeavors?.data.length > 0) &&
                        !endeavorsClient.folioEndeavor &&
                        endeavorsClient.numberBranch === 0
                    }
                >
                    <option value="" disabled selected>
                        Sucursal
                    </option>
                    {endeavorsClient.branch?.map((item, index: number) => {
                        return (
                            <option value={item.branchId} key={index}>
                                {item.branchName}
                            </option>
                        );
                    })}
                </SelectForm>
            </ContainerFlex>
            {endeavorsClient.endeavors !== null && endeavorsClient.endeavors.data?.length > 0 && (
                <ContainerFlex Width="20%">
                    <IconContainer
                        Color={endeavorsClient.actualPage > 1 ? '' : '#e5e7e9'}
                        onClick={() => paginate('back')}
                        FontSize="1.25rem"
                        className="material-icons"
                        Cursor="pointer"
                    >
                        keyboard_arrow_left
                    </IconContainer>
                    <Text FontSize="0.75rem">
                        {endeavorsClient.actualPage +
                            ' - ' +
                            pageSize +
                            ' de ' +
                            endeavorsClient.endeavors.totalPages}
                    </Text>
                    <IconContainer
                        Color={
                            endeavorsClient.actualPage < endeavorsClient.endeavors.totalPages
                                ? ''
                                : '#e5e7e9'
                        }
                        onClick={() => paginate('next')}
                        FontSize="1.25rem"
                        className="material-icons"
                        Cursor="pointer"
                    >
                        keyboard_arrow_right
                    </IconContainer>
                </ContainerFlex>
            )}
            <ButtonGeneral
                FontSize="0.875rem"
                text="Nuevo Empeño"
                hColor="#35cf44"
                clic={() => {
                    window.location.href = '#/Quoter';
                }}
            />
        </ContainerFlex>
    );
}
