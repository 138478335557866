import {useEffect} from 'react';
import {RootState} from '@/config/store';
import {FieldValues} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';

import {ISelectReact} from '@/components/Quoter/inferfaces';
import {
    catMunicipality,
    catColony,
    resetPostalCode,
    resetAddressStates,
    catStates,
} from '@components/LocationsBranch/Redux/Action/CatAddress';

import {ICustomAddress} from '@components/LocationsBranch/interfaces';
import {DEFAULT_COUNTRY} from '@/components/LocationsBranch/constants';

export const useAddressForm = ({setValue, watch}: ICustomAddress) => {
    const dispatch = useDispatch();
    const {token, catAddress} = useSelector((state: RootState) => ({
        token: state.getUsersValidation.userData?.token as string,
        catAddress: state.catAddress,
    }));

    const catColonies = catAddress.catColonyByMunicipality;

    const selectConvert = (items: FieldValues) => {
        return items.length > 0
            ? items.map((item: FieldValues) => ({
                  value: item.suburbId || item.municipalityId || item.stateId || item.colonyId,
                  label:
                      item.nameSuburb || item.nameMunicipality || item.stateName || item.colonyName,
              }))
            : [{value: '', label: 'Sin opciones'}];
    };

    const getCatMunicipality = (option: ISelectReact) => {
        dispatch(catMunicipality(token, option));
        dispatch(resetPostalCode());
        dispatch(resetAddressStates({catColonyByMunicipality: []}));

        setValue('postalCode', '');
        setValue('colony', '');
        setValue('municipaly', '');
    };

    const getColony = (option: ISelectReact) => {
        dispatch(catColony(token, option));

        setValue('postalCode', '');
        setValue('colony', '');
    };

    useEffect(() => {
        const colony = watch('colony');
        if (!colony) return;
        const postalCode = catAddress.catColonyByMunicipality.find(
            (item) =>
                Number(item.suburbId) ===
                (typeof colony === 'object' ? Number(colony.value) : Number(colony))
        );

        setValue('postalCode', postalCode?.cp || '');
    }, [watch('colony'), catAddress.catColonyByMunicipality]);

    useEffect(() => {
        dispatch(catStates(token, DEFAULT_COUNTRY));
        const state = watch('state');
        if (state && typeof state === 'object') {
            getCatMunicipality(state);
        }
        if (watch('municipaly')) {
            setValue('municipaly', watch('municipaly'));
            const municipaly = watch('municipaly');
            if (municipaly && typeof municipaly === 'object') {
                getColony(municipaly);
            }
        }
        if (watch('colony')) {
            setValue('colony', watch('colony'));
        }
    }, []);

    return {
        token,
        catAddress,
        catColonies,
        getColony,
        selectConvert,
        getCatMunicipality,
    };
};
