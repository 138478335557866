import {AxiosResponse} from 'axios';
import {
    POST_DATA_CONTACT_ADD_START,
    POST_DATA_CONTACT_ADD_SUCCESS,
    POST_DATA_CONTACT_ADD_ERROR,
} from '@MyCompany/Redux/Types/types';

const initialState = {
    loading: false,
    error: false,
    data: {},
};

export const PostDataContactAdd = (
    state = initialState,
    action: {type: string; payload: AxiosResponse}
) => {
    switch (action.type) {
        case POST_DATA_CONTACT_ADD_START:
            return {
                ...state,
                loading: true,
            };

        case POST_DATA_CONTACT_ADD_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
            };
        case POST_DATA_CONTACT_ADD_ERROR:
            return {
                ...state,
                error: true,
            };

        default:
            return state;
    }
};
export default PostDataContactAdd;
