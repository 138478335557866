import React from 'react';
import {useSelector} from 'react-redux';

import {RootState} from '@/config/store';
import {ContainerFlex, Text, Image} from '@Shopify/Ecommerce/styles';
import uploadIcon from '@images/UploadFile.svg';
import checkMark from '@images/checkMark.svg';
import warningIcon from '@images/warning-red.svg';
import {DOCUMENTS} from '@Shopify/ClientProfile/constants';
import {IDomiciliaryProofInputBoxProps} from '@components/Shopify/ClientProfile/interfaces';
import ImageInputBox from '@/components/General/Atoms/ImageInputBox/ImageInputBox';

const DomiciliaryProofInputBox = ({onOpenSelection, error}: IDomiciliaryProofInputBoxProps) => {
    const domiciliaryDocs = useSelector(
        (state: RootState) => state.clientDetails.clientDetails?.domiciliaryProof
    );
    const proofDocument =
        domiciliaryDocs && domiciliaryDocs?.length > 0
            ? domiciliaryDocs[domiciliaryDocs.length - 1]
            : null;

    const handleOpenSelection = () => {
        onOpenSelection(true);
    };

    const renderImageInputContent = () => {
        return proofDocument ? (
            <>
                <Text FontWeight="500" FontSize="0.875rem" Color="#2A2C2F" oFlow="visible">
                    {proofDocument.name}
                </Text>
                <ContainerFlex Gap="0.25rem" Justify="flex-start">
                    <Image alt="check" src={checkMark} Width="1rem" Height="1rem" />
                    <Text FontSize="0.75rem" FontWeight="400" Color="#54575C">
                        {proofDocument.size}
                    </Text>
                </ContainerFlex>
            </>
        ) : (
            <ContainerFlex Gap="0.8rem" Cursor="inherit">
                <Image
                    alt="upload"
                    src={uploadIcon}
                    Width="1.5rem"
                    Height="1.5rem"
                    ObjectFit="cover"
                    Cursor="inherit"
                />
                <Text FontSize="1rem" Color="#54575" Cursor="inherit">
                    {DOCUMENTS.ADD_PROOF_OF_ADDRESS}
                </Text>
            </ContainerFlex>
        );
    };

    return (
        <ContainerFlex FlexDir="column" Gap="0.5rem" Align="flex-start" Width="36.5rem">
            <Text
                Padding="0.125rem 0"
                Color="#1D1E20"
                FontSize="1rem"
                FontWeight="700"
                LetterSpacing="0.019rem"
            >
                {DOCUMENTS.ADD_PROOF_OF_ADDRESS}
            </Text>
            <ImageInputBox
                hasImages={proofDocument ? true : false}
                onOpenSelection={handleOpenSelection}
                error={error ? true : false}
            >
                {renderImageInputContent()}
            </ImageInputBox>
            <Text FontSize="0.875rem" FontWeight="400" LHeight="120%">
                {DOCUMENTS.MAX_SIZE_25}
                {error && (
                    <Text FontSize="0.875rem" Color="#FF6357" FontWeight="600" MarginLeft="0.5rem">
                        <Image
                            alt="warning"
                            src={warningIcon}
                            Width="1rem"
                            Height="1rem"
                            Padding="0 0.25rem"
                            ObjectFit="cover"
                        />
                        {error}
                    </Text>
                )}
            </Text>
        </ContainerFlex>
    );
};

export default DomiciliaryProofInputBox;
