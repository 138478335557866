import React, {useEffect} from 'react';
import {ContainerFlex, Text} from '@/components/Shopify/Ecommerce/styles';
import {GridContainer} from '@/components/CreditQuoter/styles';
import {INavbarLoansProps} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {navBarHeaders} from '@components/PersonalLoans/NewLoanProduct/constants';

export const NavbarLoans: React.FC<INavbarLoansProps> = ({
    containerRef,
    refs,
    navbarSelected,
    setNavbarSelected,
}) => {
    const handleSelectedNavbar = (refKey: string) => {
        const refElement = refs[refKey];
        if (refElement.current && containerRef.current) {
            containerRef.current.scrollTo({
                top: refElement.current.offsetTop - 70,
                behavior: 'smooth',
            });
        }
    };
    const handleScroll = () => {
        if (!containerRef.current) return;
        const currentScrollY = containerRef.current.scrollTop;
        const containerHeight = containerRef.current.clientHeight;
        const contentHeight = containerRef.current.scrollHeight;

        if (currentScrollY + containerHeight >= contentHeight) {
            setNavbarSelected(navBarHeaders[navBarHeaders.length - 1].title);
            return;
        }
        navBarHeaders.forEach((header) => {
            const refElement = refs[header.ref];
            if (refElement.current) {
                const offsetTop = refElement.current.offsetTop;
                const offsetHeight = refElement.current.offsetHeight;
                if (
                    currentScrollY >= offsetTop - 200 &&
                    currentScrollY < offsetTop + offsetHeight
                ) {
                    setNavbarSelected(header.title);
                }
            }
        });
    };
    useEffect(() => {
        const currentContainer = containerRef.current;
        if (currentContainer) {
            currentContainer.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (currentContainer) {
                currentContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, [navBarHeaders]);
    return (
        <>
            <ContainerFlex
                Padding="0px 0px 21px 0px"
                Height="85px"
                Position="sticky"
                backG="rgba(250, 250, 250, 0.08)"
                FlexDir="column"
                PositionTop="-25px"
                ZIndex="2"
            >
                <ContainerFlex
                    BackdropF="blur(10px)"
                    Position="relative"
                    Height="100%"
                    Padding="21px 0px 0px 0px"
                    Radius="0px 0px 29px 29px"
                >
                    <GridContainer
                        Position="relative"
                        Height="43px"
                        Justify="space-between"
                        Border="1px solid #E8E9EA"
                        BoxS="0px 2px 7px 0px rgba(0, 0, 0, 0.10)"
                        Radius="29px"
                        backG="#FFF"
                        GridColumns="repeat(6,1fr)"
                    >
                        {navBarHeaders.map((value, index) => (
                            <Text
                                Color={value.title === navbarSelected ? '#0D166B' : '#2A2C2F'}
                                key={index}
                                Height="100%"
                                Cursor="Pointer"
                                Justify="center"
                                FontWeight={value.title === navbarSelected ? '700' : '400'}
                                BorderRadius={value.title === navbarSelected ? '32px' : '0px'}
                                bGround={value.title === navbarSelected ? '#E5E5FF' : ''}
                                onClick={() => handleSelectedNavbar(value.ref)}
                            >
                                {value.title}
                            </Text>
                        ))}
                    </GridContainer>
                </ContainerFlex>
            </ContainerFlex>
        </>
    );
};
