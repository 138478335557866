import React, {useEffect} from 'react';
import {ContainerFlex, Text, InputColor} from '@/components/Shopify/Ecommerce/styles';
import {IDENTIFICATION_DOCUMENT, UPLOADDATA} from '@components/PersonalLoans/constants';
import {
    IAdditonalCreditDocuments,
    UploadLoansDataProps,
} from '@components/PersonalLoans/interfaces';
import {PersonalLoansBackDocument} from '@components/PersonalLoans/PersonalLoansBackDocument';
import {PersonalLoansFrontDocument} from '@components/PersonalLoans//PersonalLoansFrontDocument';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '@/config/store';
import {getAllValidateType} from '@components/PersonalLoans/Redux/Actions/GetAllValidateIdTypeActions';
import PersonalLoansAdditiionalDocument from '@/components/PersonalLoans/PersonalLoansAdditionalDocument/PersonalLoansAdditionalDocument';

export const PersonalLoansDocument = ({
    register,
    errors,
    reset,
    documentsInfo,
    setDocumentsInfo,
    control,
    setValue,
}: UploadLoansDataProps) => {
    const {data} = useSelector((state: RootState) => {
        return state.getAdditionalCreditDocumentsReducer;
    });
    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const dispatch = useDispatch();
    const TypeDocument = useSelector((state: RootState) => state.getAllValidateType.data);

    const getTypeDocument = () => {
        if (token) {
            dispatch(getAllValidateType(token));
        }
    };
    useEffect(() => {
        getTypeDocument();
    }, []);

    return (
        <ContainerFlex FlexDir="column" Justify="start" Align="start" Height="auto" Gap="1rem">
            <Text Color="#1D1E20" FontSize="1.25rem" FontWeight="700" oFlow="none">
                {UPLOADDATA.VERIFY}
            </Text>
            <ContainerFlex FlexDir="column" Justify="start" Align="start" Gap="1rem">
                <Text Color="#1D1E20" FontSize="1" FontWeight="400" oFlow="none">
                    {UPLOADDATA.SELECT}
                </Text>
                <ContainerFlex Justify="start" Gap="1.5rem">
                    {TypeDocument.map(
                        (data: {description: string; identificationTypeId: number}) => (
                            <ContainerFlex
                                Justify="start"
                                Gap="0.5rem"
                                key={data.identificationTypeId}
                                Width="auto"
                                Cursor="pointer"
                                onClick={() =>
                                    setDocumentsInfo((prevState) => ({
                                        ...prevState,
                                        idDocument: data.identificationTypeId,
                                    }))
                                }
                            >
                                <InputColor
                                    type="radio"
                                    checked={documentsInfo.idDocument === data.identificationTypeId}
                                />
                                <Text
                                    Cursor="pointer"
                                    Color="#1D1E20"
                                    FontSize="1rem"
                                    FontWeight="400"
                                    oFlow="none"
                                >
                                    {data.description}
                                </Text>
                            </ContainerFlex>
                        )
                    )}
                </ContainerFlex>
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Justify="start"
                Align="start"
                Gap="1rem"
                backG="#F0F0FF"
                Radius="1rem"
                Padding="1rem"
            >
                <Text Color="#1D1E20" FontSize="1rem" FontWeight="700" oFlow="none">
                    {UPLOADDATA.TITLE}
                </Text>
                <PersonalLoansFrontDocument
                    register={register}
                    errors={errors}
                    documentsInfo={documentsInfo}
                    setDocumentsInfo={setDocumentsInfo}
                    control={control}
                    setValue={setValue}
                />
                {documentsInfo.idDocument === 1 && (
                    <PersonalLoansBackDocument
                        register={register}
                        errors={errors}
                        documentsInfo={documentsInfo}
                        setDocumentsInfo={setDocumentsInfo}
                    />
                )}
                {data.length > 0 &&
                    data.map(
                        (item: IAdditonalCreditDocuments) =>
                            item.documentName !== IDENTIFICATION_DOCUMENT && (
                                <PersonalLoansAdditiionalDocument
                                    register={register}
                                    errors={errors}
                                    documentsInfo={documentsInfo}
                                    setDocumentsInfo={setDocumentsInfo}
                                    key={item.documentId}
                                    additionalDocument={item}
                                />
                            )
                    )}
            </ContainerFlex>
        </ContainerFlex>
    );
};
