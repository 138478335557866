import axios from 'axios';
import {
    PUT_NEW_ROL_USER_REQUEST,
    PUT_NEW_ROL_USER_SOUCCES,
    PUT_NEW_ROL_USER_RROR,
} from '@components/Users/UserProfile/Redux/types';
import process from 'process';
import {URL} from '@config/constants/index';
export const newRoleResquest = () => {
    return {
        type: PUT_NEW_ROL_USER_REQUEST,
    };
};
export const newRoleSuccess = (userData) => {
    return {
        type: PUT_NEW_ROL_USER_SOUCCES,
        payload: userData,
    };
};
export const newRoleFailure = (err) => {
    return {
        type: PUT_NEW_ROL_USER_RROR,
        payload: err,
    };
};
export const newRolesUserList = (token, newRoles) => {
    const headers = {
        accept: 'text/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch) => {
        dispatch(newRoleResquest());
        try {
            const response = await axios.put(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.UpdateUserRole}`,
                newRoles,
                {
                    headers,
                }
            );
            dispatch(newRoleSuccess(response.data));
        } catch (error) {
            dispatch(newRoleFailure(error));
        }
    };
};
