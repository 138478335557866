import React from 'react';
import {FieldValues, useForm, FormProvider} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';

import {RootState} from '@config/store';
import {IClientProofDocsModalProps} from '@components/Shopify/ClientProfile/interfaces';
import {IAddProofAddressClient} from '@components/Shopify/ListClient/redux/interfaces';
import {shemaCreateClientLevelTwo} from '@Shopify/ListClient/yupAddClientProof';
import * as style from '@/components/LocationsBranch/style';
import {Modal} from '@CreateBranch/BranchInformation/Modals/ReusableModal';
import {ContainerFlex, ContainerForm, Text, Image} from '@Shopify/Ecommerce/styles';
import closeIcon from '@images/closeDark.svg';
import ClientProof from '@components/Shopify/ListClient/ClientProof';
import {addProofAddressClient} from '@ListClient/redux/Actions/newClientProcessAction';
import {getClientDetails} from '@Shopify/ClientProfile/Redux/Action/GetClientDetails';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import LoadingOverlay from '@/components/General/Atoms/LoadingOverlay/LoadingOverlay';
import {UPLOAD_DOCUMENTS, DOCUMENTS} from '@components/Shopify/ClientProfile/constants';

export const ClientProofDocsModal: React.FC<IClientProofDocsModalProps> = ({
    clientId,
    showModal,
    setShowModal,
    onSuccess,
    onError,
}) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => {
        return String(state.getUsersValidation.userData?.token);
    });
    const loading: boolean = useSelector((state: RootState) => state.newClientProcess.loading);

    const formMethods = useForm<FieldValues>({
        mode: 'onChange',
        resolver: yupResolver(shemaCreateClientLevelTwo),
        defaultValues: {
            typeOfReceiptId: '',
            effectiveDate: '',
            imageProof: '',
            imageFileExtension: '',
        },
    });

    const {
        register,
        reset,
        resetField,
        handleSubmit,
        watch,
        setValue,
        control,
        formState: {errors},
    } = formMethods;

    const onSubmit = async (values: FieldValues) => {
        try {
            const proofClientData: IAddProofAddressClient = {
                clientId: Number(clientId),
                typeOfReceiptId: Number(values.typeOfReceiptId.value),
                fileExtension: values.imageFileExtension,
                fileContent: values.imageProof,
                vigency: values.effectiveDate.toISOString(),
            };
            await dispatch(addProofAddressClient(proofClientData, token));
            await dispatch(getClientDetails(token, {clientId: proofClientData.clientId}));
            onSuccess();
        } catch (error) {
            onError();
        }
    };

    return (
        <Modal
            modalState={showModal}
            changeModalState={setShowModal}
            titleModalState={true}
            {...style.modalContent}
            background="rgb(0 0 0 / 60%)"
            closeOnOutsideClick={false}
        >
            <LoadingOverlay isLoading={loading} borderRadius="1rem">
                <ContainerFlex
                    FlexDir="column"
                    Align="flex-start"
                    Justify="flex-start"
                    Gap="1rem"
                    Radius="1rem"
                    backG="#FFF"
                    BoxShadow="0 0 0.563rem 0 rgba(167, 169, 172, 0.25)"
                >
                    <ContainerFlex
                        Width="45rem"
                        Padding="1rem 1.5rem 0rem"
                        Justify="space-between"
                        Align="center"
                    >
                        <ContainerFlex
                            FlexDir="column"
                            Justify="flex-start"
                            Align="flex-start"
                            Gap="0.5rem"
                        >
                            <Text FontSize="1.5rem" Color="#2A2C2F" FontWeight="500">
                                {UPLOAD_DOCUMENTS.TITLE}
                            </Text>
                            <Text FontSize="1rem" Color="#2A2C2F" FontWeight="400">
                                {DOCUMENTS.ADD_ADDRESS_PROOF}
                            </Text>
                        </ContainerFlex>
                        <Image
                            alt="close"
                            src={closeIcon}
                            width="1.5rem"
                            height="1.5rem"
                            Cursor="pointer"
                            onClick={() => setShowModal(false)}
                        />
                    </ContainerFlex>
                    <FormProvider {...formMethods}>
                        <ContainerForm
                            onSubmit={handleSubmit(onSubmit)}
                            FlexDir="column"
                            Padding="1.5rem"
                            Gap="1.5rem"
                            Align="start"
                            Justify="start"
                            BackG="transparent"
                        >
                            <ClientProof
                                token={token}
                                register={register}
                                errors={errors}
                                control={control}
                                reset={reset}
                                watch={watch}
                                setValue={setValue}
                                resetField={resetField}
                                isPopup={true}
                            />
                            <ContainerFlex
                                Height="2.5rem"
                                Justify="center"
                                Align="center"
                                Gap="1.5rem"
                                Self="stretch"
                            >
                                <ButtonGenerals
                                    Width="13.531rem"
                                    Radius="2rem"
                                    Height="2.5rem"
                                    Padding="0.5rem 1rem"
                                    BackGC="transparent"
                                    Justify="center"
                                    Color="#5A5AFF"
                                    HBackG="transparent"
                                    FontWeight="500"
                                    FontSize="1rem"
                                    type="button"
                                    Cursor="pointer"
                                    Border="1px solid #5A5AFF"
                                    onClick={() => {
                                        setShowModal(false);
                                    }}
                                >
                                    {UPLOAD_DOCUMENTS.CANCEL}
                                </ButtonGenerals>
                                <ButtonGenerals
                                    Width="13.531rem"
                                    Radius="2rem"
                                    Height="2.5rem"
                                    Padding="0.5rem 1rem"
                                    type="submit"
                                    FontWeight="500"
                                    Cursor="pointer"
                                    disabled={loading}
                                >
                                    {UPLOAD_DOCUMENTS.SAVE}
                                </ButtonGenerals>
                            </ContainerFlex>
                        </ContainerForm>
                    </FormProvider>
                </ContainerFlex>
            </LoadingOverlay>
        </Modal>
    );
};
export default ClientProofDocsModal;
