import {AxiosError, AxiosResponse} from 'axios';
import {
    GET_CREDIT_DETAIL_CUSTOMER_PROFILE_ERROR,
    GET_CREDIT_DETAIL_CUSTOMER_PROFILE_START,
    GET_CREDIT_DETAIL_CUSTOMER_PROFILE_SUCCESS,
    REJECT_PERSONAL_CREDIT_START,
    REJECT_PERSONAL_CREDIT_SUCCESS,
    REJECT_PERSONAL_CREDIT_ERROR,
    SHOW_REJECT_MODAL,
    CLOSE_REJECT_MODAL,
} from '@/components/MyAccount/MyCredit/CreditDetails/Redux/Types/Types';
import {
    IPersonalCreditDetailsReducer,
    ICreditDetailCustomerProfileAPI,
} from '@/components/MyAccount/MyCredit/CreditDetails/interfaces';

const initialState: IPersonalCreditDetailsReducer = {
    loading: false,
    error: null,
    personalCreditAmountData: null,
    creditLoanDetailData: null,
    showRejectModal: false,
    personalCreditId: 0,
};

const GetPersonalCreditAmountReducer = (
    state = initialState,
    action: {
        type: string;
        error: AxiosError;
        payload: AxiosResponse;
        personalCreditId: number;
    }
) => {
    switch (action.type) {
        case REJECT_PERSONAL_CREDIT_START:
            return {
                ...state,
                loading: true,
            };
        case REJECT_PERSONAL_CREDIT_SUCCESS:
            return {
                ...state,
                showRejectModal: false,
                loading: false,
            };
        case REJECT_PERSONAL_CREDIT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case GET_CREDIT_DETAIL_CUSTOMER_PROFILE_START:
            return {
                ...state,
                loading: true,
                personalCreditId: action?.personalCreditId ?? 0,
            };
        case GET_CREDIT_DETAIL_CUSTOMER_PROFILE_SUCCESS:
            return populatePersonalCreditData(state, action.payload.data);
        case GET_CREDIT_DETAIL_CUSTOMER_PROFILE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        case SHOW_REJECT_MODAL:
            return {
                ...state,
                showRejectModal: true,
            };
        case CLOSE_REJECT_MODAL:
            return {
                ...state,
                showRejectModal: false,
            };
        default:
            return state;
    }
};

const populatePersonalCreditData = (
    state: IPersonalCreditDetailsReducer,
    response: AxiosResponse
): IPersonalCreditDetailsReducer => {
    const creditDetailCustomerProfile: ICreditDetailCustomerProfileAPI = response.data;
    const {
        amountRequired,
        quotasNumber,
        totalOwed,
        totalPaidQuotas,
        applicationType,
        valueQuota,
        companyName,
        applicationNumber,
        paymentFrecuency,
        applicationDate,
        applicationStatus,
        nextPaymentId,
        statusId,
        totalPaid,
    } = creditDetailCustomerProfile;

    const newState: IPersonalCreditDetailsReducer = {
        ...state,
        personalCreditAmountData: {
            personalCreditId: state.personalCreditId,
            crontactNumber: applicationNumber,
            paymentId: nextPaymentId,
            paymentAmount: valueQuota,
            amount: valueQuota,
            indebtedness: totalOwed,
            quotesCount: `${totalPaidQuotas}/${quotasNumber}`,
            totalPaid,
        },
        creditLoanDetailData: {
            personalCreditId: state.personalCreditId,
            requestedQuantity: amountRequired,
            applicationDate,
            stateId: statusId,
            stateDescription: applicationStatus,
            companyName,
            applicationFormDescription: applicationType,
            cuotas: quotasNumber,
            valueQuota,
            frequencyDescription: paymentFrecuency,
        },
    };
    return {...newState};
};
export default GetPersonalCreditAmountReducer;
