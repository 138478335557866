import {routers} from '@/appRoute';

export const CLIENT_TYPE = 'Client';
export const HOME_PATH = '/home';

export const OPERATIONAL_WORKFLOW = [
    routers.CreditsAndPawns,
    routers.ClientAddNew,
    routers.PaymentQuoterConfirmation,
    routers.PaymentGateway,
    `${routers.CreditsAndPawns}/*`,
];
