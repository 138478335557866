import {IBreadcrumbItem} from '@/components/General/Interfaces/IBreadcrumb';
import {StatusStyle, IActions} from '@components/Shopify/ClientProfile/interfaces';
import {DateTime} from 'luxon';

export const CLIENT_PROFILE = {
    PROFILE: 'Perfil de cliente',
    NUC: 'NUC',
    INE: 'INE',
    TOTAL_OWED: 'Total de Deuda',
    TOTAL_INCOME: 'Total de Ingresos',
    MORE_INFORMATION: 'Más información',
    SCORE: 'Puntuación',
    TEMP_TOTAL: '$45,000',
    SHOW_EDIT_PERSONALDATA: 'Ver o editar datos personales',
    TEST_PROFILE: 'Ver evaluación',
};

export const SCORE = {
    EXCELENT: 'Excelente',
    VERY_GOOD: 'Muy Bueno',
    VERY_GOOD_LIGHT: 'Muy bueno',
    GOOD: 'Bueno',
    REGULAR: 'Regular',
    POOR: 'Pobre',
    LOW: 'Bajo',
};

export const scoreColor = (score: string) => {
    switch (score) {
        case SCORE.EXCELENT:
            return {Border: '1px solid #B1E7C9', bGround: '#E4F7EC', Color: '#174A2E'};
        case SCORE.VERY_GOOD:
            return {Border: '1px solid #ACACFF', bGround: '#E5E5ff', Color: '#0D166B'};
        case SCORE.VERY_GOOD_LIGHT:
            return {Border: '1px solid #ACACFF', bGround: '#E5E5ff', Color: '#0D166B'};
        case SCORE.GOOD:
            return {Border: '1px solid #FFE299', bGround: '#FFF5DB', Color: '#614500'};
        case SCORE.REGULAR:
            return {Border: '1px solid #FF6AE8', bGround: '#FFE5FB', Color: '#2A2C2F'};
        case SCORE.POOR:
            return {Border: '1px solid #FF8E85', bGround: '#FFE9E8', Color: '#2A2C2F'};
        case SCORE.LOW:
            return {Border: '1px solid #FF8E85', bGround: '#FFE9E8', Color: '#2A2C2F'};
    }
};

export const VALUTATE_PROFILE = {
    TOTAL_PAWN: 'Total de deuda',
    TOTAL_CONTRACTS: 'Total de empeños',
    TOTAL_MORTGAGE: 'Total de hipotecas',
    TOTAL_LOAN_PERSONALITY: 'Total de prestamos personales',
    ACCOUN_PAWN: 'No. contratos',
    AVG_DISCHARGE: '% Promedio de refrendos',
    AVG_CAPITAL: '% Promedio abono a capital',
    TOTAL_PAWNS_DISCHARGE: 'Total de contratos desempeñados',
    TOTAL_IN_SELLING: 'Total de contratos en almoneda',
    CLOSE_BTN: 'Cerrar',
};

export const QUICK_ACTIONS = {
    TITLE: 'Acciones rápidas',
    DATE_EXPIRATION: 'Vencimiento',
    PAYMENT: 'Refrendo',
    LIQUIDATION: 'Desempeño',
    GARMENTS: 'Prendas',
    NO_CONTRACTS: 'No hay contratos vinculados con este cliente',
    DISCHARGE_ERROR:
        'Hubo un problema al Desempeñar el contrato. Por favor, intenta de nuevo o verifica tu entrada y conexión.',
    CLOSE: 'Cerrar',
};

export const BreadClient: Array<IBreadcrumbItem> = [
    {to: '#/', label: 'Inicio'},
    {to: '#/Clients/ClientList', label: 'Clientes'},
    {to: '#/Clients/ClientProfile', label: 'Perfil del cliente'},
];

export const PRODUCTS = {
    DEFEATED: 'Vencido',
    TO_WIN: 'Por vencer',
    NEXT_PAYMENT: 'Próximo pago',
};

const BTN_ACTIONS = {
    DISCHARGE: 'Desempeñar',
    ENDORSMENT: 'Refrendar',
    LIQUIDATE: 'Liquidar',
    PAY: 'Pagar cuota',
    REACTIVATE: 'Reactivar',
};

export type ButtonActions = {
    discharge?: (card: IActions) => void;
    endorsement?: (card: IActions) => void;
    reactivate?: () => void;
    liquidate?: () => void;
    pay?: () => void;
};

export const BUTTON_CONFIG = ({discharge, endorsement, card}: ButtonActions & {card: IActions}) => {
    const options = [
        {
            text: BTN_ACTIONS.DISCHARGE,
            secondaryButton: true,
            action: discharge,
            show: card.actions.discharge,
        },
        {text: BTN_ACTIONS.ENDORSMENT, action: endorsement, show: card.actions.endorsement},
        {text: BTN_ACTIONS.REACTIVATE, show: card.actions.reactivate},
        {text: BTN_ACTIONS.LIQUIDATE, show: card.actions.liquidate},
        {text: BTN_ACTIONS.PAY, show: card.actions.pay},
    ];

    return options.filter((option) => option.show !== false);
};

export const OPERATIONS = {
    CONTINUE: 'Continuar',
    TITLE: 'Operaciones',
    FILTER: 'Filtrar por',
    TABS_TITLE: ['Transacciones', 'Órdenes', 'Contratos de empeño', 'Créditos personales'],
    TABS_PLACEHOLDER: [
        'Buscar transacción',
        'Buscar por numero de orden',
        'Buscar contrato',
        'Buscar no. préstamo',
    ],
    TRANSACTIONS: {
        PRINT: 'Imprimir comprobante',
        CLIPBOARD_COPY: 'Copiado al portapapeles',
        DETAIL: 'Detalles',
        HEADERS: [
            'No. transacción',
            'Fecha de pago',
            'Monto del pago',
            'Tipo de pago',
            'Método de pago',
            'Estado',
            '',
        ],
        DETAIL_TRANSACTION_MODAL: {
            LABELS: {
                TITLE: 'Detalles de la transacción',
                REFERENCE_NUMBER: 'Folio #',
                TYPE: 'Tipo',
                PAWN_SHOP: 'Casa de empeño',
                RENEWAL_VALUE: 'Valor de refrendo',
                LOAN_REDEMPTION_VALUE: 'Valor de desempeño',
                PAYMENT_DATE: 'Fecha de pago',
                PAYMENT_METHOD: 'Método de pago',
                CLOSE: 'Cerrar',
            },
            VALUES: {
                REFERENCE_NUMBER: '123456',
                TOTAL: 12000,
                TYPE: 'Refrendo, Desempeño',
                PAWN_SHOP: 'San Juanito - Multiapoyo',
                RENEWAL: 1000,
                LOAN_REDEMPTION: 11000,
                PAYMENT_DATE: '01/01/2023',
                PAYMENT_METHOD: '11119785',
            },
        },
        ALL_TRANSACTIONS: [
            {
                id: 1,
                noTransaction: '24081',
                datePay: '18/04/2024',
                pay: '$1,000',
                typePay: 'Refrendo, Desempeño',
                payment: '12339785',
                status: 'Pagado',
            },
            {
                id: 2,
                noTransaction: '24081',
                datePay: '18/04/2024',
                pay: '$1,000',
                typePay: 'Refrendo, Desempeño',
                payment: '12339785',
                status: 'Pendiente',
            },
            {
                id: 3,
                noTransaction: '24081',
                datePay: '18/04/2024',
                pay: '$1,000',
                typePay: 'Refrendo, Desempeño',
                payment: '12339785',
                status: 'Incidencia',
            },
        ],
        NO_RESULTS: 'No se encontró ninguna transacción',
        STATUS: {
            PAID: 'Pagado',
            PENDING: 'Pendiente',
            INCIDENCE: 'Incidencia',
        },
    },
    ENDEAVORS: {
        TOTALS: ['Total de deuda', 'Total contratos', 'Total de ingresos'],
        HEADERS: [
            'No contrato',
            'No de artículos',
            'Artículos',
            'Refrendo',
            'Desempeño',
            'Préstamo',
            'Valor avalúo',
            'Vendedor / sucursal',
            'Fecha de vencimiento',
            'Estado',
            '',
        ],
        NO_CONTRACTS: 'No hay contratos disponibles.',
        FILTER: 'Filtro',
        CONTRACT_DATA: 'Ingresar datos del contrato',
        EXPIRATION: 'Vencimiento',
        CONTRACT_STATE: 'Estado del contrato',
    },
};

export const hideDigits = (num: string | number) => {
    const numStr = String(num);
    if (numStr.length <= 4) return numStr;
    return '*'.repeat(numStr.length - 4) + numStr.slice(-4);
};

export const GaugeChartColorData = [
    {value: 250, color: '#CC9200'},
    {value: 250, color: '#A82424'},
    {value: 250, color: '#008FCC'},
    {value: 250, color: '#309C60'},
];

export const GaugeMaxScore = 1000;

export const MOREINFO_TABS = [
    'Información del cliente',
    'Domicilio',
    'Información laboral',
    'Documento de identidad',
    'Comprobantes',
    'Beneficiario o Cotitular',
];

export const PERSONAL_INFORMATION = {
    TITLE: 'Información personal',
    NAMES: 'Nombre(s)',
    PATERNAL_SURNAME: 'Apellido paterno',
    MATERNAL_SURNAME: 'Apellido materno',
    EMAIL: 'Correo electrónico',
    CELLULAR_PHONE: 'Teléfono celular',
    BIRTH_DATE: 'Fecha de naciemiento',
    ADD_FINGERPRINTS: 'Agregar huellas',
    REGISTER_FINGERPRINTS: 'Tomar registro de huellas',
    MODIFY: 'Modificar',
    SAVE: 'Guardar',
    CLOSE: 'Cerrar',
};

export const ADDRESS_INFORMATION = {
    TITLE: 'Datos de domicilio',
    STREET: 'Calle',
    EXTERIOR_NO: 'No. exterior',
    INTERIOR_NO: 'No. interior',
    POSTAL_CODE: 'Código postal',
    COLONY: 'Colonia',
    MUNICIPALITY: 'Alcaldía o municipio',
    STATE: 'Estado',
};

export const EMPLOYMENT_INFORMATION = {
    TITLE: 'Información laboral',
    EMPLOYMENT_STATUS: 'Situación laboral',
    COMPANY_NAME: 'Nombre de la empresa',
    COMPANY_PHONE: 'Teléfono de la empresa',
    POSITION: 'Puesto',
    OPTIONAL: '(Opcional)',
    SENIORITY: 'Antigüedad',
    MONTHLY_INCOME: 'Ingreso mensual',
};

export const IDENTITY_DOCUMENT = {
    TITLE: 'Documento de identidad',
    DOCUMENT_TYPE: 'Tipo de documento',
    NUMBER: 'Número',
    NAME_IN_DOCUMENT: 'Nombre en el documento',
    EXPIRATION_DATE: 'Fecha de caducidad',
};

export const DOCUMENTS = {
    TITLE: 'Comprobantes',
    ADD_IDENTITY_DOCUMENT: 'Agrega documento de identidad',
    ADD_PROOF_OF_ADDRESS: 'Agrega comprobante de domicilio',
    ADD_PROOF_OF_INCOME: 'Agrega comprobante de ingresos',
    REPLACE: 'Remplazar',
    TEMP_FILESIZE: '17 MB',
    MAX_SIZE_17: 'Hasta 17 MB',
    MAX_SIZE_25: 'Hasta 25 MB',
    MB: 'MB',
    ERROR: 'Error al agregar imágenes',
    ID_DOCUMENT_TYPE: 'Documento de identidad',
    INCOME_DOCUMENT_TYPE: 'Comprobante de ingresos',
    ADDRESS_DOCUMENT_TYPE: 'Comprobante de domicilio',
    ADD_ADDRESS_PROOF: 'Añade comprobante de domicilio',
    ADDRESS_PROOF_ERROR: 'Error al agregar imágen de comprobante de domicilio',
};

export const CO_OWNER = {
    TITLE: 'Beneficiario',
    NAMES: 'Nombre(s)',
    LASTNAMES: 'Apellido(s)',
    PHONE_NUMBER: 'Numero de telefono',
    EMAIL: 'Correo electrónico',
};

export const UPLOAD_DOCUMENTS = {
    TITLE: 'Subir documentos',
    SUBTITLE: 'Añade documento(s) de identificación',
    FRONT: 'Frontal',
    BACK: 'Reverso',
    DRAG_ELEMENTS_HERE: 'Arrastra elementos aquí',
    OR: 'o',
    SEARCH_FILES: 'Busca Archivos',
    EFECTIVE_DATE: 'Expedición',
    EXPIRY_DATE: 'Expiración',
    CANCEL: 'Cancelar',
    SAVE: 'Guardar',
    ADD_IMAGE_ERROR: 'No se pudo cargar la imagen. Por favor, inténtalo de nuevo.',
    IMAGES_ERROR: 'Por favor agregar las dos imagenes del documento',
    SINGLE_IMAGE_ERROR: 'Por favor agregar la imagen del documento',
    SELECT_DATE: 'Selecciona una fecha',
};

export const VALIDATION_CLIENT_DOCUMENT = {
    FRONT_IMAGE: 'Imagen frontal de identificación es requerida.',
    REVERSE_IMAGE: 'Imagen reversa de identificación es requerida.',
    JPG_OR_PNG: 'La imagen debe ser .jpg o .png',
    SELECT_EXPIRY: 'Seleccione fecha de expiración',
    MUST_BE_FUTURE: 'La fecha de expiración debe ser en el futuro.',
    SELECT_A_YEAR: 'Selecciona un año',
};

export const ONE_IMAGE_DOCS = ['2', '4'];

export const formatFileSize = (sizeStr: string): string => {
    const sizeInMB = parseFloat(sizeStr);

    const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    let sizeInBytes = sizeInMB * 1024 * 1024;
    let unitIndex = 0;

    while (sizeInBytes >= 1024 && unitIndex < units.length - 1) {
        sizeInBytes /= 1024;
        unitIndex++;
    }

    return `${sizeInBytes.toFixed(2)} ${units[unitIndex]}`;
};

export const BORDER_ERROR = '1px dashed #FF6357';
export const BORDER_DEFAULT = '1px dashed #D4D6D8';
export const ALLOWED_EXTENSIONS = ['jpg', 'png'];

export const STATUS_CONTRACT = {
    TO_WIN: 'Vigente',
    DEFEATED: 'Vencido',
    PAYMENTED: 'Desempeño',
    CANCELED: 'Cancelado',
    SALE: 'Almoneda',
};

export const MENU_CONTRACT = {
    VIEW_DETAILS: 'Ver detalle',
    DISCHARGE: 'Desempeñar',
    RENEW: 'Refrendar',
    MAKE_A_PAYMENT: 'Abonar',
    EXTEND: 'Extender',
    REACTIVATE: 'Reactivar',
    ACTIVE_INCIDENT: 'Activar incidencia',
};

export const TYPE_MOVEMENT_ADD_CART = {
    RENEWAL: 1,
    DISCHARGE: 2,
};

export const PERSONAL_CREDITS = {
    TITLE: 'Créditos personales',
    APPLY_FOR_LOAN: 'Solicitar crédito',
    FILTER_BY: 'Filtrar por',
};

export const CREDITS_LISTING = {
    LOAN_NO: 'No. Préstamo',
    STATUS: 'Estado',
    QUANTITY_LOANED: 'Cantidad prestada',
    INSTALLMENTS: 'Cuotas',
    INTEREST_RATE: 'Tasa de interes',
    ACTIONS: 'Acciones',
    NO_RECORDS: 'No hay créditos personales disponibles.',
    MAKE_PAYMENT: 'Realizar pago',
};

export const CreditStateText = {
    Active: 'Activo',
    Liquidado: 'Liquidado',
};

export const scoreStyles: {[key: string]: StatusStyle} = {
    [CreditStateText.Active]: {
        border: '1px solid #B1E7C9',
        backgroundColor: '#E4F7EC',
        color: '#174A2E',
    },
    [CreditStateText.Liquidado]: {
        border: '1px solid #8c8c91',
        backgroundColor: '#d9d9dc',
        color: '#3f3f3f',
    },
};

export const defaultStyle: StatusStyle = {
    border: '1px solid black',
    backgroundColor: 'white',
    color: 'black',
};

export const getStyle = (status: string): StatusStyle => {
    return scoreStyles[status] || defaultStyle;
};

export const CONTRACT_DETAIL = {
    RETURN: 'Regresar',
    PAWN_DETAIL: 'Detalle del empeño',
    ENDEAVOR: 'Empeño',
    LENDED: 'Prestado',
    ARTICLES: 'Prendas',
    DATE_ENDEAVOR: 'Prox. Refrendo',
    NOW_DISCHARGE: 'Desempeñar ahora',
    NOW_PAY: 'Refrendar ahora',
    NO_CONTRACT: 'No. Contrato',
    BRANCH: 'Casa y sucursal',
    PAY: 'Refrendo',
    DISCHARGE: 'Desempeño',
    NO_PAY: 'No. Refrendos',
    END_DATE: 'Vencimiento',
    STATE: 'Estado',
    LOCATION: 'Ubicación',
    TAB_CONTRACT: ['Prendas en el contrato', 'Historial de movimientos'],
    GARMENTS: 'Prendas en el contrato',
    DOWNLOAD_CONTRACT: 'Descargar contrato',
    ARTICLES_HEADERS: ['Casa y sucursal', 'Avalúo', 'Préstamo', 'Plazos'],
    ONLINE_STORE: 'Visitar la tienda oficial',
    PAY_METHOD: 'Método de pago',
    PAY_DECRIPTION:
        'Agregando un método de pago podrás domiciliar tu pago y evitar asi cobros extra por mora.',
    ARTICLE_PHOTOS: 'Fotos del producto',
    ADD_PAY_METHOD: 'Agregar método de pago',
};

export const DISCHARGE_ENDORSEMENT_ERRORS = {
    DISCHARGE: 'Hubo un problema al Desempeñar el contrato. ',
    ENDORSEMENT: 'Hubo un problema al Refrendar el contrato. ',
    INSTRUCTION: 'Por favor, intenta de nuevo o verifica tu entrada y conexión.',
    GENERAL: 'Se ha encontrado un error. Por favor, inténtelo de nuevo más tarde.',
    PAWN_IN_CART:
        'La operación no se puede completar porque hay artículos empeñados en el carrito.',
};

export const ERROR_MESSAGE_MAP = {
    [TYPE_MOVEMENT_ADD_CART.RENEWAL]: DISCHARGE_ENDORSEMENT_ERRORS.ENDORSEMENT,
    [TYPE_MOVEMENT_ADD_CART.DISCHARGE]: DISCHARGE_ENDORSEMENT_ERRORS.DISCHARGE,
};

export const convertDate = (date: string) => DateTime.fromJSDate(new Date(date));
export const dellWords = (value: string, word: string) => {
    return value.replace(word.toLowerCase(), '');
};

export const SLIDER_BREAKPOINT = [
    {width: 320, slides: 1},
    {width: 640, slides: 2},
    {width: 768, slides: 2},
    {width: 1024, slides: 3},
    {width: 1280, slides: 4},
    {width: 1440, slides: 4},
    {width: 2150, slides: 5},
];
