import axios, {AxiosError, AxiosResponse} from 'axios';
import {
    GET_FREQUENCY_PAYMENTS_START,
    GET_FREQUENCY_PAYMENTS_SUCESS,
    GET_FREQUENCY_PAYMENTS_ERROR,
    RESET_FREQUENCY_PAYMENTS_REDUCER,
} from '@components/PersonalLoans/NewLoanProduct/Redux/Types/Types';
import {IGetFrequencyPaymentsParms} from '@components/PersonalLoans/NewLoanProduct/interfaces';
import {AppDispatch} from '@/config/store';
import {URL} from '@config/constants';

export const getFrequencyPaymentsStart = () => {
    return {
        type: GET_FREQUENCY_PAYMENTS_START,
    };
};

export const getFrequencyPaymentsSuccess = (result: AxiosResponse) => {
    return {
        type: GET_FREQUENCY_PAYMENTS_SUCESS,
        payload: result.data,
    };
};

export const getFrequencyPaymentsError = (error: AxiosError) => {
    return {
        type: GET_FREQUENCY_PAYMENTS_ERROR,
        error,
    };
};

export const resetFrequencyPaymentsReducer = () => {
    return {
        type: RESET_FREQUENCY_PAYMENTS_REDUCER,
    };
};

export const getFrequencyPayments = (token: string, params: IGetFrequencyPaymentsParms) => {
    const headers = {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
    };
    return async (dispatch: AppDispatch) => {
        dispatch(getFrequencyPaymentsStart());
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVICE_WEB}${URL.urlWeb.GetFrecuencyPayments}`,
                {headers, params}
            );
            dispatch(getFrequencyPaymentsSuccess(response));
        } catch (error) {
            dispatch(getFrequencyPaymentsError(error as AxiosError));
        }
    };
};
