import styled from '@emotion/styled';
import {Image} from '@components/Shopify/Ecommerce/styles';

export const Icon = styled.img<{color: string}>`
    font-size: 0.875rem;
    color: ${(props) => props.color};
    width: 16px;
    height: 16px;
`;
export const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #e8e9ea;
`;

export const InputContainer = styled.div<{hasError?: boolean}>`
    display: flex;
    width: 100%;
    border: 1px solid ${(props) => (props.hasError ? '#ef4f55' : '#e1e1e1')};
    border-radius: 32px;
    transition: border-color 0.2s;
    height: 40px;

    &:focus-within {
        border-color: ${(props) => (props.hasError ? ' #ef4f55' : '#5a5aff')};
    }
`;
export const DayInput = styled.input`
    width: 50%;
    font-size: 16px;
    border: none;
    outline: none;
    height: 40px;
    padding: 0 16px;
    border-radius: 32px 0 0 32px;
    background: white;
    text-align: start;
`;
export const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    background-color: #fafafa;
    width: 50%;
    position: relative;
    border-radius: 0 32px 32px 0;
    cursor: pointer;
`;
export const DropdownButton = styled.div`
    width: calc(100% - 24px);
    font-size: 16px;
    font-family: nunito;
    border: none;
    outline: none;
    background: transparent;
    padding: 16px;
    color: #333;
    height: 40px;
    display: flex;
    align-items: center;
    user-select: none;
`;
export const DropdownOptions = styled.div`
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    height: 280px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
        margin: 4px;
    }

    &::-webkit-scrollbar-thumb {
        background: #c1c1c1;
        border-radius: 4px;

        &:hover {
            background: #a1a1a1;
        }
    }
`;

export const Option = styled.div`
    padding: 8px;
    cursor: pointer;
    height: 24px;
    display: flex;
    align-items: center;
    font-family: nunito;

    &:hover {
        background-color: #f5f5f5;
        color: #5a5aff;
    }

    &:first-child {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
    }

    &:last-child {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
    }
`;
export const ArrowIcon = styled(Image)<{isOpen: boolean}>`
    right: 12px;
    transform: ${(props) => (props.isOpen ? 'rotate(180deg)' : 'rotate(0)')};
    transition: transform 0.2s ease;
`;
