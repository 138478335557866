import React, {useState} from 'react';
import {ContainerFlex, Image, Text} from '@Shopify/Ecommerce/styles';
import {DropdownWithIconProps} from '@components/GlobalQuoter/interface';
import {SelectWithIconStyles} from '@components/GlobalQuoter/styles';

const DropdownWithIcon = ({
    label,
    options,
    icon,
    iconAlt,
    optionContainerWidth,
    name,
    value,
    onChange,
}: DropdownWithIconProps) => {
    const [open, setOpen] = useState(false);
    const selectOption = options.find((option) => option.value === value) || null;
    return (
        <ContainerFlex
            Width="311px"
            backG="#FFFFFF"
            Border="1px solid #E8E9EA"
            Radius="16px"
            Height="56px"
            PaddingR="16px"
            Position="relative"
            onClick={() => setOpen(!open)}
            Cursor="pointer"
        >
            <ContainerFlex
                Padding="8px"
                backG="#FAFAFA"
                Radius="16px 0 0 16px"
                Width="40px"
                Cursor="pointer"
            >
                <Image src={icon} alt={iconAlt} />
            </ContainerFlex>
            <ContainerFlex FlexDir="column" Align="flex-start" Gap="4px" Cursor="pointer">
                <Text
                    FontSize="0.875rem"
                    Color="#54575C"
                    FontFamily="Nunito"
                    Cursor="pointer"
                    PaddingL="8px"
                >
                    {label}
                </Text>
                <SelectWithIconStyles
                    name={name}
                    options={options}
                    value={selectOption}
                    onChange={(newValue: unknown) =>
                        onChange((newValue as {label: string; value: number}).value)
                    }
                    height="24px"
                    width="100%"
                    padding="0 0 0 8px"
                    border="none"
                    hborder="none"
                    margin="0"
                    controlTop="-10px"
                    PaddingSelection="0"
                    isSearchable={false}
                    optionContainerWidth={optionContainerWidth}
                    menuIsOpen={open}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default DropdownWithIcon;
