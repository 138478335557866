import React, {useState, useEffect} from 'react';
import {Controller} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {yupResolver} from '@hookform/resolvers/yup';
import {FieldValues, useForm, FormProvider} from 'react-hook-form';

import {RootState} from '@/config/store';
import {schemaAddNewPaymentMethod} from '@components/AccountSettings/yupAddNewPaymentMethod';
import {ContainerFlex, ContainerForm, Input, Text} from '@Shopify/Ecommerce/styles';
import {SearchInput} from '@/components/Customer/Styles';
import {ButtonGenerals} from '@/components/General/Atoms/Button/styles';
import {IAddPaymentFormProps} from '@components/AccountSettings/interfaces';
import {
    PAYMENT_METHOD_LABELS,
    PAYMENT_AND_BILLING,
    formNewPaymentMethod,
} from '@components/AccountSettings/constants';
import {LoadingAtaskate} from '@General/Atoms/LoadingAtaskate/index';
import {IAddPaymentMethod} from '@components/AccountSettings/Redux/interfaces';
import {ValidateBankCard} from '@components/AccountSettings/Redux/Actions/ValidateBankCardType';
import {AddPaymentMethod} from '@components/AccountSettings/Redux/Actions/AddPaymentMethod';
import FormInput from '@components/AccountSettings/FormInput';
import FormDatePickerField from '@components/AccountSettings/FormDatePickerField';

const AddPaymentMethodForm: React.FC<IAddPaymentFormProps> = ({onClose, onSuccess}) => {
    const dispatch = useDispatch();
    const [submissionError, setSubmissionError] = useState(false);

    const token = useSelector((state: RootState) => {
        return state.getUsersValidation.userData?.token as string;
    });
    const companyId = useSelector(
        (state: RootState) =>
            state.getUsersValidation?.userData?.enterpriceDetails?.[0]?.enterpriceId as number
    );

    const addPaymentErr = useSelector((state: RootState) => state.AddCompanyPaymentMethod.error);
    const loading = useSelector((state: RootState) => state.AddCompanyPaymentMethod.loading);
    const formMethods = useForm<FieldValues>({
        resolver: yupResolver(schemaAddNewPaymentMethod),
        defaultValues: formNewPaymentMethod,
    });

    useEffect(() => {
        if (addPaymentErr) setSubmissionError(false);
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: {errors},
    } = formMethods;

    const formatCardNumber = (value: string): string => {
        return value
            .replace(/\D/g, '')
            .replace(/(.{4})/g, '$1 ')
            .trim();
    };

    const handleCreditCardBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const formattedValue = formatCardNumber(e.target.value);
        setValue('cardNumber', formattedValue, {shouldValidate: true});
        const unformattedValue = formattedValue.replace(/\s+/g, '');

        if ((unformattedValue.length === 15 || unformattedValue.length === 16) && token) {
            dispatch(ValidateBankCard(unformattedValue, token));
        }
    };

    const bankCardValidation = useSelector((state: RootState) => state.ValidateBankCardType);
    const onSubmit = async (values: FieldValues) => {
        setSubmissionError(false);
        const newPaymentData: IAddPaymentMethod = {
            companyId: companyId,
            cardHolder: values.cardHolder,
            cardNumber: values.cardNumber.replace(/\s+/g, ''),
            cardTypeId: bankCardValidation.cardData.id,
            cardExpirationMonth: values.expiryMonth,
            cardExpirationYear: values.expiryYear,
            cvv: values.cvv,
            default: true,
        };
        try {
            await dispatch(AddPaymentMethod(newPaymentData, token));
            if (onSuccess) onSuccess();
        } catch (error) {
            setSubmissionError(true);
        }
    };

    return (
        <FormProvider {...formMethods}>
            <ContainerForm
                onSubmit={handleSubmit(onSubmit)}
                Radius="16px 0px 0px 16px"
                FlexDir="column"
                Width="400px"
                Height="100vh"
                Padding="24px"
                Justify="space-between"
                Align="center"
            >
                <ContainerFlex Justify="start" Align="start" FlexDir="column" Gap="24px">
                    <Text FontSize="24px" FontWeight="700" Color="#2A2C2F">
                        {PAYMENT_METHOD_LABELS.TITLE}
                    </Text>
                    <ContainerFlex FlexDir="column" Justify="start" Align="flex-start" Gap="16px">
                        <FormInput
                            name="cardHolder"
                            fontSize={'1rem'}
                            label={PAYMENT_METHOD_LABELS.CARDHOLDER}
                            control={control}
                            fontWeight="700"
                            register={register}
                            error={errors.cardHolder?.message as string}
                        />
                        <FormInput
                            name="cardNumber"
                            fontSize={'1rem'}
                            label={PAYMENT_METHOD_LABELS.CARDNUMBER}
                            control={control}
                            fontWeight="700"
                            register={register}
                            error={errors.cardNumber?.message as string}
                            onBlur={handleCreditCardBlur}
                            maxLength={16}
                        />

                        <ContainerFlex Align="flex-start" Height="68px" Gap="16px">
                            <FormDatePickerField
                                name="expiryMonth"
                                placeholder="MM"
                                format="MM"
                                fontSize="1rem"
                                isDate
                                label={PAYMENT_METHOD_LABELS.MONTH}
                                control={control}
                                error={errors.expiryMonth?.message as string}
                            />
                            <FormDatePickerField
                                name="expiryYear"
                                placeholder="AAAA"
                                format="yyyy"
                                fontSize="1rem"
                                isDate
                                label={PAYMENT_METHOD_LABELS.YEAR}
                                control={control}
                                error={errors.expiryYear?.message as string}
                            />
                        </ContainerFlex>
                        <ContainerFlex
                            FlexDir="column"
                            Justify="start"
                            Align="flex-start"
                            Gap="4px"
                            Flex="1 0 0"
                        >
                            <Text FontSize="1rem" FontWeight="700" Color="#6D6E71">
                                {PAYMENT_METHOD_LABELS.CVV}
                            </Text>
                            <Controller
                                name="cvv"
                                control={control}
                                render={({field}) => (
                                    <SearchInput
                                        Height="40px"
                                        Position="none"
                                        GridColumn="90% 10%"
                                        Margin="0 0 4px 0"
                                        BoxShadowH=""
                                        BoxShadowF=""
                                        Padding="0 1rem 0 0"
                                        BackG="#FFFFFF"
                                        Radius="32px"
                                        InputPadding="0 1rem"
                                        error={!!errors.cvv?.message}
                                    >
                                        <Input
                                            {...field}
                                            {...register('cvv')}
                                            type="password"
                                            maxLength={4}
                                        />
                                    </SearchInput>
                                )}
                            />
                            <Text Color="#FF6357" FontSize="12px">
                                {errors.cvv?.message as string}
                            </Text>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                <ContainerFlex FlexDir="column" Align="center" Gap="1rem" Height="4.831rem">
                    <Text Color="#FF6357" FontSize="1rem">
                        {errors['at-least-one'] && (errors['at-least-one']?.message as string)}
                        {submissionError && PAYMENT_AND_BILLING.ERROR}
                    </Text>
                    <ContainerFlex Justify="center" Align="center" Gap="24px" Height="40px">
                        <ButtonGenerals
                            Width="100%"
                            Height="40px"
                            Padding="8px 16px"
                            BackGC="#A82424"
                            Justify="center"
                            Color="white"
                            HBackG="#A82424"
                            FontWeight="500"
                            type="button"
                            Cursor="pointer"
                            onClick={() => {
                                onClose(false);
                            }}
                        >
                            {PAYMENT_METHOD_LABELS.CANCEL}
                        </ButtonGenerals>
                        <ButtonGenerals
                            Width="100%"
                            Height="40px"
                            Padding="8px 16px"
                            type="submit"
                            FontWeight="500"
                            disabled={loading}
                        >
                            {loading && <LoadingAtaskate width="0.625rem" padding="0.375rem" />}
                            {PAYMENT_METHOD_LABELS.SAVE}
                        </ButtonGenerals>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerForm>
        </FormProvider>
    );
};

export default AddPaymentMethodForm;
