import React from 'react';

import {ICartViewToggleProps} from '@components/AdminNavbar/interfaces';
import {ContainerFlex, Image} from '@Shopify/Ecommerce/styles';
import openCartIcon from '@images/CartIconSelected.svg';
import closedCartIcon from '@images/cartIconWhite.svg';

const CartViewToggle: React.FC<ICartViewToggleProps> = ({isCartOpen, setCartView}) => {
    return (
        <ContainerFlex
            Height="2.5rem"
            Width="2.5rem"
            Padding="0.5rem"
            Align="center"
            Radius="2rem"
            backG={!isCartOpen ? '#FFF' : '#ACACFF'}
            onClick={() => setCartView(!isCartOpen)}
            Cursor="pointer"
        >
            <Image
                alt="cart-toggle"
                src={!isCartOpen ? openCartIcon : closedCartIcon}
                Width="1.5rem"
                Height="1.5rem"
                Cursor="pointer"
            />
        </ContainerFlex>
    );
};
export default CartViewToggle;
