import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {RootState} from '@/config/store';
import {ContainerFlex, Image, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {logoutSesion} from '@/components/Login/Interceptor/reduxLogOut';
import {
    ACCOUNTMENU,
    ADMINT_TYPE,
    LOGIN_TO,
    LOGIN_TO_ONE,
    POS_TYPE,
    TYPE_USERS,
    containerStyles,
    stylesTextProps,
} from '@components/AdminNavbar/constants';
import {ClickAwayListener} from '@mui/material';
import {INavContextualMenu} from '@components/AdminNavbar/interfaces';
import {DeleteTemporalyPawns} from '@components/ShoppingCart/Redux/Actions/deleteTemporalyPawnActions';
import {DELETECARTCONTRACTS} from '@/components/ShoppingCart/Redux/Actions/getCartContractsActions';
import logOut from '@images/Tertiary.svg';
import {UserData} from '@Login/interfaces';
import {USER_TYPES} from '@Login/Administrative/constants';
import {alreadySavedData} from '@Login/Redux/Actions/GetValidateUsers';

export const AccountMenu = ({handleClickAway}: INavContextualMenu) => {
    const dispatch = useDispatch();
    const token = useSelector((state: RootState) => state.getUsersValidation.userData?.token);
    const UserData = useSelector((state: RootState) => state.getUsersValidation.userData);

    const posProfile = useSelector((state: RootState) => state.getNavBarPos);
    const {adminProfile} = useSelector((state: RootState) => state.adminUserProfileSettings);
    const userType = useSelector((state: RootState) => state.getUsersValidation.userData?.userType);
    const userData = useSelector((state: RootState) => state.getUsersValidation.userData);
    const PreData = useSelector((state: RootState) => state.getUsersValidation.preData);
    const ChangeTypeAccess = (type: number, data: UserData) => {
        const userTypeMap = new Map<number, string>([
            [1, USER_TYPES.ADMIN],
            [2, USER_TYPES.EMPLOYEE],
        ]);

        const newUserData: UserData = {...data};
        if (userTypeMap.has(type)) {
            newUserData.userType = userTypeMap.get(type)!;
        }

        dispatch(alreadySavedData(newUserData));
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <ContainerFlex {...containerStyles}>
                <ContainerFlex
                    Padding="16px 16px 8px 16px"
                    FlexDir="column"
                    Height="auto"
                    RowGap="10px"
                    backG="#FFFF"
                    Radius="0.75rem 0.75rem 0  0"
                    Gap="16px"
                >
                    <ContainerFlex Gap="0.625rem" Justify="start">
                        <Image
                            alt="user-image"
                            src={
                                userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.picture
                                    : adminProfile && adminProfile.picture
                            }
                            width="32px"
                            height="32px"
                            Border="1px solid #0D166B"
                            Radius="50%"
                            ObjectFit="cover"
                            AspectRatio="1 / 1"
                        />

                        <ContainerFlex FlexDir="column" Align="start" Width="70%" Height="auto">
                            <Text
                                FontSize="1rem"
                                Color="#1D1E20"
                                FontFamily="Nunito"
                                FontWeight="700"
                            >
                                {ACCOUNTMENU.WELCOME}{' '}
                                {userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.userName
                                    : adminProfile && UserData && UserData.names}
                            </Text>
                            <TextEllipsis
                                FontSize="0.75rem"
                                FontWeight="400"
                                Color="#54575C"
                                Width="100%"
                                FontFamily="Nunito"
                            >
                                {userType === TYPE_USERS.POS
                                    ? posProfile.NavPosData && posProfile.NavPosData.email
                                    : adminProfile && UserData && UserData.email}
                            </TextEllipsis>
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
                {PreData &&
                    userData &&
                    userData.loginAccessType === 4 && (
                        <ContainerFlex backG="#F0F0FF" FlexDir="column">
                            <ContainerFlex
                                Padding="0.75rem 1.5rem"
                                Gap="0.625rem"
                                Justify="start"
                                backG={
                                    userType === ADMINT_TYPE
                                        ? 'linear-gradient(90deg, #ACACFF 20.79%, rgba(255, 255, 255, 0.00) 59.93%)'
                                        : '#F0F0FF'
                                }
                                Height="48px"
                                Cursor="pointer"
                                onClick={() => {
                                    if (userData) ChangeTypeAccess(LOGIN_TO_ONE, userData);
                                }}
                            >
                                <Image
                                    alt="rol-image"
                                    width="32px"
                                    height="32px"
                                    src={
                                        userType === TYPE_USERS.POS
                                            ? posProfile.NavPosData &&
                                              posProfile.NavPosData.submenuPos.pictureAdministrator
                                            : adminProfile && adminProfile.pictureAdministrator
                                    }
                                    ObjectFit="cover"
                                />
                                <Text
                                    FontSize="0.85rem"
                                    Cursor="pointer"
                                    Color="#000"
                                    FontFamily="Nunito"
                                >
                                    {ACCOUNTMENU.ADMIN}
                                </Text>
                            </ContainerFlex>
                            <ContainerFlex
                                Padding="0.75rem 1.5rem"
                                Gap="0.625rem"
                                Justify="start"
                                Height="48px"
                                Cursor="pointer"
                                backG={
                                    userType === POS_TYPE
                                        ? 'linear-gradient(90deg, #81E7FF 20.79%, rgba(255, 255, 255, 0.00) 59.93%)'
                                        : '#F0F0FF'
                                }
                                onClick={() => {
                                    if (userData) ChangeTypeAccess(LOGIN_TO, userData);
                                }}
                            >
                                <Image
                                    alt="rol-image"
                                    width="32px"
                                    height="32px"
                                    src={
                                        userType === TYPE_USERS.POS
                                            ? posProfile.NavPosData &&
                                              posProfile.NavPosData.submenuPos.picturePos
                                            : adminProfile && adminProfile.picturePos
                                    }
                                />
                                <Text
                                    FontSize="0.85rem"
                                    Cursor="pointer"
                                    Color="#000"
                                    FontFamily="Nunito"
                                >
                                    {ACCOUNTMENU.POS}
                                </Text>
                            </ContainerFlex>
                        </ContainerFlex>
                    )}

                <ContainerFlex
                    Justify="start"
                    Height="auto"
                    ColumnGap="8px"
                    Padding="0.75rem 1.5rem"
                >
                    <Image
                        alt="user-image"
                        src={logOut}
                        width="20px"
                        height="20px"
                        ObjectFit="cover"
                        AspectRatio="1 / 1"
                    />
                    <Text
                        {...stylesTextProps}
                        onClick={async () => {
                            if (token) {
                                dispatch(DELETECARTCONTRACTS());
                                await dispatch(
                                    DeleteTemporalyPawns(token, {
                                        deleteAllPawns: true,
                                        operationType: 1,
                                    })
                                );
                            }
                            logoutSesion(dispatch);
                        }}
                    >
                        {ACCOUNTMENU.LOGOUT}
                    </Text>
                </ContainerFlex>
            </ContainerFlex>
        </ClickAwayListener>
    );
};
