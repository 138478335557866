import React from 'react';
import {ContainerFlex, Figure, Image, Input, Text, TextEllipsis} from '@Shopify/Ecommerce/styles';
import {IPropsEnterUserNameAndOrganization} from '@components/CreateUsers/Interface';
import arrowDown from '@images/arrowDonw.svg';
import {InputStyles} from '@components/CreateUsers/Styles';
import {useSelector} from 'react-redux';
import {RootState} from '@config/store';
import {ModalOrganizations} from '@components/CreateUsers/ModalOrganizations/ModalOrganizations';

export const EnterUserAndOrganization = ({
    usernameAvailable,
    getUserOptions,
    register,
    setValue,
    setModalStateOrg,
    levelsBranch,
    trigger,
    modalStateOrg,
}: IPropsEnterUserNameAndOrganization) => {
    const optionsUserNames: {userName: string}[] = useSelector(
        (state: RootState) => state.GetOptionsUserNames.contact
    );
    const enterpriceName = useSelector(
        (state: RootState) =>
            state.getUsersValidation.userData?.enterpriceDetails?.[0].enterpriceName
    );
    return (
        <ContainerFlex MarginTop="24px" Height="auto" Align="start">
            <ContainerFlex FlexDir="column" Align="start" FlexWrap="wrap">
                <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                    Nombre de usuario
                </Text>
                <Input
                    Cursor="text"
                    placeholder="Nombre de usuario"
                    error={usernameAvailable}
                    onClick={() => {
                        getUserOptions();
                    }}
                    {...InputStyles}
                    {...register('userName')}
                    onKeyUp={() => trigger('userName')}
                />
                {usernameAvailable && (
                    <Text FontSize="0.625rem" FontWeight="normal" Color="#ef4f55">
                        Este nombre de usuario ya existe. Elige otro.
                    </Text>
                )}
                {optionsUserNames[0] && (
                    <ContainerFlex FlexWrap="wrap">
                        <Text
                            Height="auto"
                            FontSize="0.75rem"
                            Width="300px"
                            FlexWrap="wrap"
                            MarginTop={usernameAvailable ? '0' : '5px'}
                        >
                            Disponible:
                            {optionsUserNames.slice(1, 4).map((user, index: number) => (
                                <Text
                                    FontWeight="500"
                                    Cursor="pointer"
                                    Width="auto"
                                    Color="#35cf44"
                                    key={index}
                                    MarginLeft="4px"
                                    onKeyUp={() => trigger('userName')}
                                    onClick={() => {
                                        setValue('userName', user.userName);
                                    }}
                                    FontSize="0.75rem"
                                >
                                    {user.userName} {index < 2 && ' - '}
                                </Text>
                            ))}
                        </Text>
                    </ContainerFlex>
                )}
            </ContainerFlex>
            <ContainerFlex
                FlexDir="column"
                Align="start"
                AlignContent="end"
                FlexWrap="wrap"
                Cursor="pointer"
                onClick={() => setModalStateOrg(true)}
            >
                <Text FontSize="0.75rem" Color="#6d6e71" FontWeight="normal" required>
                    Organización
                </Text>

                <ContainerFlex
                    Height="40px"
                    Width="300px"
                    Border="1px solid #E5E7E9"
                    BoxShadow="0px 4px 4px rgba(53, 207, 68, 0.2)"
                    HBorderColor="#35CF44"
                    Radius="4px"
                    Padding="0 12px"
                    Cursor="pointer"
                    Justify="space-between"
                >
                    <TextEllipsis
                        Cursor="pointer"
                        FontSize="0.875rem"
                        Color="#414042"
                        Width="90%"
                        FontWeight="400"
                    >
                        {levelsBranch.length > 0
                            ? levelsBranch.map(
                                  (level, index) =>
                                      `${level.nameBranch}  ${
                                          index === levelsBranch.length - 1 ? '' : ' > '
                                      }`
                              )
                            : enterpriceName}
                    </TextEllipsis>
                    <Figure>
                        <Image src={arrowDown} />
                    </Figure>
                </ContainerFlex>
                <ModalOrganizations
                    setModalStateOrg={setModalStateOrg}
                    modalStateOrg={modalStateOrg}
                    setValue={setValue}
                    trigger={trigger}
                    levelsBranch={levelsBranch}
                />
            </ContainerFlex>
        </ContainerFlex>
    );
};
